import { FormItem } from "@components/FormCollection";
import { AInputMoney } from "@a-components";
import CategoryGoodsSelector from "./CategoryGoodsSelector";

const ProductLaunch = () => {
  const inpChange = e =>{
  }
  return (
    <div>
      <FormItem
        label="默认竞价"
        tooltip="默认竞价适用于所有点击，除非您为关键词设置不同的竞价。您的竞价可能会发生变化，具体取决于您选择的竞价策略和广告位竞价涨幅。"
        name="productLaunchDefaultBidding"
      >
        <AInputMoney onChange={(e)=>{inpChange(e)}} />
      </FormItem>

      <CategoryGoodsSelector />
    </div>
  );
};

export default ProductLaunch;
