import React from 'react';
import {Container,Logo,Deliver} from './style';

export default () => {

    return <Container>
        <Logo/>
        <Deliver>
            <p>Deliver to</p>
            <p>China</p>
        </Deliver>
    </Container>
}