import React from 'react'
import './business.css'
import Header from 'components/home_header'
import IconFont from 'components/Icon/Icon'


function business(){
        return(
            <div>
                <Header/>
                <div className="business_Body">

                    <div className="business_Drawer_box">
                    <div className="business_Drawer">
                        <div>
                            <div className="business_Drawer_titel">图表</div>
                            <div className="business_Drawer_nav">销售图表</div>
                        </div>
                        <div className="business_Drawer_bottom">
                            <div className="business_Drawer_titel">业务报告</div>
                            <div className="business_Drawer_bottom_titel">根据日期</div>
                                <div className="business_Drawer_bottom_nav">销售量与访问量</div>    
                                <div className="business_Drawer_bottom_nav">详情页面上的销售量与访问量</div>    
                                <div className="business_Drawer_bottom_nav">卖家业绩</div>    
                            <div>
                            <div className="business_Drawer_bottom_titel">根据ASIN</div>
                                <div className="business_Drawer_bottom_nav">详情页面上的销售量与访问量</div>
                                <div className="business_Drawer_bottom_nav">父商品详情页面上的销量与访问量</div>
                                <div className="business_Drawer_bottom_nav">子商品详情页面上的销售量与访问量</div>
                                <div className="business_Drawer_bottom_nav">品牌绩效</div>
                            </div>
                            <div>
                                <div className="business_Drawer_bottom_titel">其他</div>    
                                <div className="business_Drawer_bottom_nav">每月销量和订单量</div>    
                            </div>                       
                        </div>
                      
                    </div>
                    <div className="business_Drawer_btn">
                        <IconFont type="icon-sanjiaoxing" />
                        <div>报告</div>
                    </div>
                    </div>

                    <div className="business_content">
                        <div className="business_content_hint">
                            <IconFont type="icon-008296"/>
                            拥有品牌且品牌内部的卖家现在可以做出更明智的战略决策，通过品牌分析发展业务。 转到“报告”下拉列表下的“品牌分析”或点击此处开始。
                        </div>
                        <div className="business_content_chart">
                            <div className="business_content_titel">
                                <div className="business_content_titelIIBox">
                                <div className="business_content_titelII">销售图表</div>
                                <span>了解更多信息</span>
                                </div>
                                <div className="business_content_btn">
                                <div className="business_content_date_btn">
                                    <button>刷新</button>
                                </div>
                                <div className="business_content_date_btn">
                                    <button>下载</button>
                                </div>
                                </div>
                            </div>
                            <div className="business_content_date">

                                <div className="business_content_date_bd"> 
                                    <div>日期</div>
                                    <div>
                                        <select className="business_content_date_select">
                                            <option>今天-2021-1-24</option>
                                            <option>本周-2021-1-24</option>
                                            <option>本月-2021-1-24</option>
                                            <option>今年-2021-1-24</option>
                                            <option>自定义</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="business_content_date_bd">
                                    <div>销售细分</div>
                                    <div>
                                        <select className="business_content_date_select">
                                            <option>商城总会</option>
                                            <option>企业买家</option>
                                            <option>非企业买家</option>
                                            
                                        </select>
                                    </div>
                                </div>

                                <div className="business_content_date_bd">
                                    <div>配送渠道</div>
                                    <div>
                                        <select className="business_content_date_select">
                                            <option>两种（亚马逊和卖家）</option>
                                            <option>亚马逊</option>
                                            <option>卖家</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="business_content_date_btn">
                                    <button>应用</button>
                                </div>
                            </div>

                            <div className="business_content_order">
                                <div className="business_content_order_head">
                                    <div>销售概览</div>
                                    <span>2021年1月24日</span>
                                    <span>21时30分55秒</span>
                                </div>
                                <div className="business_content_order_content">
                                    <div className="business_content_order_contentBox">
                                        <div>订单商品种类数</div>
                                        <div>0</div>
                                    </div>
                                    <div className="business_content_order_contentBox">
                                        <div>已顶购商品数量</div>
                                        <div>0</div>
                                    </div>
                                    <div className="business_content_order_contentBox">
                                        <div>已顶购商品销售额</div>
                                        <div>US$0.00</div>
                                    </div>
                                    <div className="business_content_order_contentBox">
                                        <div>平均每种订单商品的数量 </div>
                                        <div>0</div>
                                    </div>
                                    <div className="business_content_order_contentBox">
                                        <div>平均每种订单商品的金额 </div>
                                        <div>US$0.00</div>
                                    </div>
                                </div>
                            </div>

                            <div className="business_content_contrastBox">
                                <div className="business_content_contrastBox_titel">
                                    <div className="business_content_contrastBox_head">销售对比</div>
                                    <div className="business_content_contrastBox_btn">
                                    <div><IconFont/>图形视图 </div>
                                    <div><IconFont/>列表视图 </div>
                                    </div>
                                </div>


                                <div className="business_content_contrastBox_chart"> 
                                    <div>已订购商品数量</div>
                                    <div>已订购商品销售额</div>
                                </div>

                                <div className="business_chartbody">
                                <div className="business_chartBox">
                                    <div className="business_chartBoxII">
                                        <div className="business_chartBox_content">
                                            <div>1.00</div>
                                            <div>0.75</div>
                                            <div>0.50</div>
                                            <div>0.25</div>
                                        </div>
                                        <div className="business_chartBox_contentII"></div>
                                    </div>
                                    <div className="business_chartBox_date">
                                        <div>12上午</div>
                                        <div>2上午</div>
                                        <div>4上午</div>
                                        <div>6上午</div>
                                        <div>8上午</div>
                                        <div>10上午</div>
                                        <div>12下午</div>
                                        <div>2下午</div>
                                        <div>4下午</div>
                                        <div>6下午</div>
                                        <div>8下午</div>
                                        <div>10下午</div>
                                    </div>
                                </div>

                                <div className="business_chartBox">
                                    <div className="business_chartBoxII">
                                        <div className="business_chartBox_content">
                                            <div>1.00</div>
                                            <div>0.75</div>
                                            <div>0.50</div>
                                            <div>0.25</div>
                                        </div>
                                        <div className="business_chartBox_contentII"></div>
                                    </div>
                                    <div className="business_chartBox_date">
                                        <div>12上午</div>
                                        <div>2上午</div>
                                        <div>4上午</div>
                                        <div>6上午</div>
                                        <div>8上午</div>
                                        <div>10上午</div>
                                        <div>12下午</div>
                                        <div>2下午</div>
                                        <div>4下午</div>
                                        <div>6下午</div>
                                        <div>8下午</div>
                                        <div>10下午</div>
                                    </div>
                                </div>
                                </div>

                                <div className="business_chartBox_bottom">
                                    <div className="business_bottom_content">
                                        <div>对比</div>
                                        <div className="business_bottom_Link">这是什么?</div>
                                    </div>
                                    <div className="business_bottom_content">
                                        <div><input type="checkbox" /></div>
                                        <div style={{color:"#3333FF"}}>今天</div>
                                        <div>至今</div>
                                        <div>0商品数量</div>
                                        <div>US$0.00</div>
                                    </div>
                                    <div className="business_bottom_content">
                                        <div><input type="checkbox" /></div>
                                        <div style={{color:"#CC0000"}}>昨天</div>
                                        <div>到当天结束</div>
                                        <div>0商品数量</div>
                                        <div>US$0.00</div>
                                    </div>
                                    <div className="business_bottom_content">
                                        <div><input type="checkbox" /></div>
                                        <div style={{color:"#F38822"}}>上周的今天</div>
                                        <div>到当天结束</div>
                                        <div>0 商品数量</div>
                                        <div>US$0.00</div>
                                    </div>
                                    <div className="business_bottom_content">
                                        <div><input type="checkbox" /></div>
                                        <div style={{color:"#AAAAAA"}}>去年的今天</div>
                                        <div>到当天结束</div>
                                        <div>0 商品数量</div>
                                        <div>US$0.00</div>
                                    </div>
                                </div>

                                </div>
                        </div>
                    </div>
                       
                    </div>
            </div>
        )
}
export default business