import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    border-bottom: 1px solid #ccc;
    text-align: left;
    `;
export const Main = styled.div`    
    display: flex;
    justify-content: space-between;
    padding: 6px 2.5% 0 2.5%;
    &>div{
        display: flex;
    }
`;
export const Logo = styled.div`
    width: 160px;
    height: 38px;
    background: url(${require('@assets/img/home/logo.png').default}) no-repeat;
    background-size: 100% auto; 
    background-position: 0 6px; 
    margin-right: 20px;
`;