import {
  createElement,
  useCallback,
  useState,
  useEffect,
} from "react";
import classNames from "classnames";
import { AText, ASwitch } from "@a-components";
import { Space } from "antd";
import useQuery from "@hooks/useQuery";
import Apis from "@api/apis";

import AdvertisingPageLayout from "../AdvertisingPageLayout";
import style from "./style.module.css";

import AdGroup from "./AdGroup";
import AdPosition from "./AdPosition";
import NegativeDelivery from "./NegativeDelivery";
import AdActivitySetting from "./AdActivitySetting";
import OperaHistory from "./OperaHistory";

const menuList = [
  {
    title: "广告组",
    type: "adGroup",
  },
  {
    title: "广告位",
    type: "adPosition",
  },
  {
    title: "否定投放",
    type: "negativeDelivery",
  },
  {
    title: "广告活动设置",
    type: "adActivitySetting",
  },
  {
    title: "历史",
    type: "adOperaHistory",
  },
];

const AdvertisingDetail = () => {
  const [activeMenu, setActiveMenu] = useState("adGroup");
  const { advertisingId } = useQuery();
  const [advertisingHeadInfo, setAdvertisingHeadInfo] = useState({});

  const getAdvertisingDetailHead = useCallback(() => {
    return Apis.advertising
      .getAdvertisingDetailHead({ advertisingId })
      .then((res) => {
        if (res.code !== 1) return res;

        setAdvertisingHeadInfo(res.data);
        return res;
      });
  }, [advertisingId]);

  const renderMainContent = useCallback(() => {
    const mapping = {
      adGroup: AdGroup,
      adPosition: AdPosition,
      negativeDelivery: NegativeDelivery,
      adActivitySetting: AdActivitySetting,
      adOperaHistory: OperaHistory,
    };

    const cur = mapping[activeMenu];

    return createElement(cur, {
      advertisingId,
      onAdvertisingUpdated: getAdvertisingDetailHead,
    });
  }, [activeMenu, advertisingId, getAdvertisingDetailHead]);

  useEffect(() => {
    getAdvertisingDetailHead();
  }, [getAdvertisingDetailHead]);

  const updateAdvertisingStatus = useCallback(
    (advertisingStatus) => {
      return Apis.advertising
        .updateAdvertisingStatus({
          advertisingIds: advertisingId,
          advertisingStatus,
        })
        .then((res) => {
          if (res.code === 1) {
            getAdvertisingDetailHead();
          }
        });
    },
    [advertisingId, getAdvertisingDetailHead]
  );

  const {
    advertisingName,
    advertisingStatusName,
    advertisingTypeName,
    startTime,
    endTime,
    dailyBudgetPrice = 0,
    advertisingStatus,
  } = advertisingHeadInfo;
  return (
    <div>
      <AdvertisingPageLayout>
        <div className="bg-white" style={{ minHeight: 720 }}>
          <div
            className="bg-f8 box-shadow-cd p-r ph-24 pt-12 pb-12 df fd-c jc-sb"
            style={{ height: 120 }}
          >
            <AText className="fs-32  text-ellipsis-1">
              广告活动:
              <AText strong className="fs-32" title={advertisingName}>
                {advertisingName}
              </AText>
            </AText>
            <Space style={{ marginTop: 16 }} size="middle">
              <ASwitch
                checked={advertisingStatus === 1}
                onChange={(checked) => updateAdvertisingStatus(checked ? 1 : 2)}
              />
              <AText>状态: {advertisingStatusName}</AText>
              <AText>类型:{advertisingTypeName}</AText>
              <AText>
                自动投放安排: {startTime} - {endTime || "无结束日期预算"}
              </AText>
              <AText>预算: ${dailyBudgetPrice.toFixed(2)} - 每天</AText>
            </Space>
          </div>
          <div className="df" style={{ width: "100%" }}>
            <div className="bg-f8 p-12" style={{ minWidth: 220 }}>
              {menuList.map((item) => {
                return (
                  <div
                    className={classNames(
                      style.menuItem,
                      "df ai-c ph-12 border-radius-4",
                      {
                        active: activeMenu === item.type,
                      }
                    )}
                    key={item.type}
                    onClick={() => setActiveMenu(item.type)}
                  >
                    {item.title}
                  </div>
                );
              })}
            </div>
            <div className="f-1 p-12" style={{ width: "calc(100vw - 250px)" }}>{renderMainContent()}</div>
          </div>
        </div>
      </AdvertisingPageLayout>
    </div>
  );
};

export default AdvertisingDetail;
