import styled from "styled-components";
import { Form } from "antd";

const FormItem = styled(Form.Item)`




`;

export { FormItem };
