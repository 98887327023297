import React from 'react'
import './BrandHealth.css'
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
function BrandHealth(){
    return(
        <div>
            <Header/>
<div className="kc22_content">
			<div className="kc22_back">
				<button type="button">
					{"<"} 返回至库存</button> </div> <div className="kc22_navChild">
						<ul>
							<li className="kc22_one"><span>品牌健康</span></li>
							<li>了解更多信息</li>
							<li>为此页评价</li>
						</ul>
			</div>
			<div>
				没有匹配的商品
			</div>
		</div>
		<div className="kc22_search">
			<div className="kc22_search_l">
				筛选条件
				<button type="button">品牌健康</button>
			</div>
			<div className="kc22_search_r">
				<div>排序依据</div>
				<select>
					<option value="">到期日期： 最早</option>
				</select>
			</div>
		</div>
		<ul className="kc22_data">
			<li>
				<div className="kc22_data_one">商品详情</div>
				<div>亚马逊商品编码(ASIN)、SKU</div>
			</li>
			<li>
				<div>品牌健康</div>
				<div>描述</div>
			</li>
			<li>
				<div>到期日期</div>
				<div>时间</div>
			</li>
			<li>
				<div>价格</div>
				<div>+运费</div>
			</li>
			<li>
				<div>竞争价格 <span className="kc22_icon">？</span></div>
				<div></div>
			</li>
			<li>
				<div>操作</div>
			</li>
		</ul>

        <Footer/>

        </div>
    )
}
export default BrandHealth