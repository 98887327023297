import React from 'react'
import './QualityInformation.css'
import '../../view/default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'

function QualityInformation() {
    return (
        <div>

            <Header />
            {/* <!--tab切换--> */}
            <div className="kc26_tab">
                {/* <!--管理冗余库存--> */}
                <div className="kc26_rongyu">
                    <div className="kc26_lllff">
                        <ul className="kc26_rongyutit">
                            <li>
                                <h2>商品信息质量控制面板</h2>
                            </li>
                        </ul>
                    </div>
                    <p>确定并修复影响商品曝光率、详细页体检和买家退货的商品信息问题。 <span>了解更多信息</span></p>
                </div>
                {/* <!--信息--> */}
                <div className="kc26_xinxi">
                    <div className="kc26_xin_box1">
                        <div className="kc26_box1_l">
                            <span>改善商品信息质量(0)</span>
                            <span>审核存在风险的商品(0)</span>
                        </div>
                        <div className="kc26_box1_r">
                            <input type="text" placeholder="在受限的SKU、ASIN、商品名称中进行搜索" /> <img src="../../assets/images/kc26_ss3.png" alt="" />
                        </div>
                    </div>
                    <div className="kc26_xin_box2">
                        <p>ASIN：0</p>
                    </div>
                    <div className="kc26_xin_box3">
                        <ul>
                            <li>商品<p>商品名称、ASIN、SKU</p></li>
                            <li>页面浏览量<p>最近 30天</p></li>
                            <li>销售额<p>最近 30天</p></li>
                            <li>建议</li>
                        </ul>
                    </div>
                </div>
                <div className="kc26_jianyi">
                    <div>
                        <p>找到0条建议</p>
                        <ul>
                            <li>商品信息质量控制面板未来将包含更多商品类型。请稍后查看。</li>
                        </ul>
                    </div>
                </div>
                <div className="kc26_fanye">
                    <div className="kc26_box">
                        <img src="../../assets/images/kc26_fanye1.png" alt="" />
                        <img src="../../assets/images/kc26_fanye2.png" alt="" />
                    </div>
                    <div className="kc26_aboBox">
                        <select>
                            <option>每页显示25个结果</option>
                        </select>
                    </div>
                </div>
            </div>
            <Footer />



        </div>
    )
}

export default QualityInformation