import React from 'react'
import AJAX from "api/apis";
import './handlingCargo.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"
import '../default.css/default.css'
import { Link } from 'react-router-dom';
import { Button, Collapse, Steps, Radio, Input } from 'antd';
import { fbaAddress } from "@/utils/utils";
import getUrlData, { buildRouteSearch } from "@/utils/getUrlData";

const { Panel } = Collapse;
const { Step } = Steps;
class handlingCargo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            goodsList: [],
            planInfoList: [],
            addressDetails: '',
            classContent: '',
            classType: 1,
            classIndex: null,
            isModalVisible: false,
            ModalText: '',
            isReturn: false,
            isTourOpen: true,
            steps1: [
                {
                    selector: '.handlingCargoBox .kc06_it-r button',
                    content: '点击处理货件',
                }
            ],
            isActive: false
        }
    }
    componentWillMount() {
        let planId = getUrlData('planId');
        planId && this.getSeparateInfo(planId)
        this.getStoreRegisterInfo()
    }
    awakenTips = (type) => {
        this.setState({
            isActive: type
        })
    }
    getSeparateInfo = (id) => {
        let { planInfoList } = this.state
        AJAX.fba.getSeparateInfo({ id }).then((res) => {
            if (res.code == 1 && res.data) {
                planInfoList = res.data
                for (let items of planInfoList.fbaAllCommodityPlanRespList) {
                    items.isShowChild = false;
                }
                this.setState({
                    planInfoList: planInfoList,
                    stepControl: planInfoList.storagePlanStep == 5 ? false : true,

                })
            }
        })
    }
    getStoreRegisterInfo = () => {
        AJAX.login.getStoreRegisterInfo([{
            propertyKey: 'paytaxes_organization_site'
        }], 'isRegister=0').then((res) => {
            if (res.code == 1 && res.data) {
                this.setState({
                    addressDetails: res.data[0].propertyValue
                })
            }
        })
    }
    childShowClick = (index) => {
        let { planInfoList } = this.state
        planInfoList.fbaAllCommodityPlanRespList[index].isShowChild = !planInfoList.fbaAllCommodityPlanRespList[index].isShowChild
        this.setState({
            planInfoList: planInfoList
        })
    }
    render() {
        let { addressDetails, planInfoList, steps1, isTourOpen, isActive } = this.state
        return <div className="handlingCargoBox">
            <Header />
            <div>
                <div className="kc06_box1">
                    <a href="#">Send to Amazon</a>
                    <ul className="kc06_box1-ul">
                        <li>新功能</li>
                        <li><a href="#">货件处理进度</a></li>
                        <li><a href="#">入库问题一览</a></li>
                        <li><a href="#">扫描和贴标</a></li>
                        <li><a href="#">上传入库计划文件</a></li>
                    </ul>
                </div>
                <div className="kc06_mybody">
                    <div className="kc06_center">
                        <p className="kc06_h1">
                            发/补货
                            <span>了解更多信息</span>
                        </p>
                        <div className="kc06_body-border">
                            <ul className="kc06_body-ul">
                                <li>设置数量</li>
                                <li>预处理商品</li>
                                <li>为商品贴标</li>
                                <li>查看货件<img src={require("@/assets/img/home/kc12_topsj.png").default} /></li>
                                <li>预处理货件</li>
                                <li>一览 </li>
                            </ul>
                        </div>
                        <div className="kc06_body-table">
                            <table className="kc06_table1">
                                <thead>
                                    <tr>
                                        <th>发货地址</th>
                                        <th>包装类型 <span>这是什么？</span></th>
                                        <th>主题</th>
                                        <th>可选服务费用(预计)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{fbaAddress(addressDetails)}</td>
                                        <td>混装商品</td>
                                        <td>{planInfoList.storagePlanMskuCount} MSKU</td>
                                        <td>
                                            <div>
                                                <div>准备:${planInfoList.storagePlanPackMoney}</div>
                                                <div>标签:${planInfoList.storagePlanTagMoney}</div>
                                                <div>放置:无费用</div>
                                                <div>总计:${planInfoList.storagePlanAllMoney}</div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* <div className="chulihuoijianBox">
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>货件名称</th>
                                            <th>货件编号</th>
                                            <th>MSKU</th>
                                            <th>商品数量</th>
                                            <th>配送地址</th>
                                            <th></th>
                                        </tr>
                                        <tr>
                                            <td>HSCDFVC</td>
                                            <td>FBA15VR87LJ7</td>
                                            <td>1</td>
                                            <td>1</td>
                                            <td>ONT8<p>Moreno Valley,CA</p></td>
                                            <td>
                                                <div className="kc06_it-r">
                                                    <Link to="/completeShipment">
                                                        <button>处理货件</button>
                                                    </Link>
                                                    <div>
                                                        <p className="kc06_it-r-p1">查看货件内商品</p>
                                                        <p className="kc06_it-r-p2">下载SKU列表</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> */}
                            <ul className="kc06_itbox kc06_itbox1">
                                <li>货件名称</li>
                                <li>货件编号</li>
                                <li>MSKU</li>
                                <li>商品数量</li>
                                <li>配送地址</li>
                            </ul>
                            {planInfoList.fbaAllCommodityPlanRespList ? planInfoList.fbaAllCommodityPlanRespList.map((item, index) => {
                                return <div key={index} className="kc06_commodity">
                                    <ul className="kc06_itbox">
                                        <li>{item.commodityPlanName}</li>
                                        <li>{item.commodityPlanNumber}</li>
                                        <li>{item.commodityPlanMskuCount}</li>
                                        <li>{item.commodityPlanAllGoodsCount}</li>
                                        <li>{item.commodityPlanAmazonAddRess}</li>
                                        <li>
                                            <div className="kc06_it-r">
                                                {item.commodityStatus == 1 ? <Link to={{
                                                    pathname: '/completeShipment',
                                                    search: buildRouteSearch({ id: item.id }),
                                                    state: { id: item.id }
                                                }}>
                                                    <button>处理货件<img className="kc_10_btn2" src={require("@/assets/img/home/btns.png").default} /></button>
                                                </Link> : <Link to={{
                                                    pathname: '/atAglance',
                                                    search: buildRouteSearch({ id: item.id }),
                                                    state: { id: item.id }
                                                }}>
                                                    <button>追踪货件<img className="kc_10_btn2" src={require("@/assets/img/home/btns.png").default} /></button>
                                                </Link>}
                                                <div>
                                                    <p className="kc06_it-r-p1" onClick={() => { this.childShowClick(index) }}>{item.isShowChild ? '隐藏货件内容' : '查看货件内商品'}  |</p>
                                                    <p className="kc06_it-r-p2">下载SKU列表</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    {item.isShowChild ? <div >
                                        <div className="childBox">
                                            <p className="kc_10_show">显示{item.fbaCommodityPlanGoodsInfoRespList.length}件商品</p>
                                        </div>
                                        <div className="childBox">
                                            <table className="kc_10_table1">
                                                <thead>
                                                    <tr>
                                                        <th>MSKU</th>
                                                        <th>商品名称</th>
                                                        <th>状况</th>
                                                        <th>已发货</th>
                                                        <th>已收货</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {item.fbaCommodityPlanGoodsInfoRespList ? item.fbaCommodityPlanGoodsInfoRespList.map((items, indexs) => {
                                                        return <tr key={indexs}>
                                                            <td>{items.goodsSku}</td>
                                                            <td><div className="text-ellipsis-3">{items.goodsName}</div></td>
                                                            <td>{items.goodsCondition}</td>
                                                            <td>{item.commodityPlanAllGoodsCount}</td>
                                                            <td>{items.commodityPlanGoodsHarvestCount}</td>
                                                        </tr>
                                                    }) : null}
                                                    <tr>
                                                        <td>总计</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>{item.commodityPlanAllGoodsCount}</td>
                                                        <td>{item.commodityPlanGoodsHarvestCount}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                        : null}
                                </div>
                            }) : null}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            {/* 引导步骤 */}
            <TipsIcon clicks={this.awakenTips} />
            <Reactours routePath="handlingCargo" isActive={isActive} clicks={this.awakenTips} steps={steps1} isOpen={isTourOpen} />
        </div>
    }
}


export default handlingCargo