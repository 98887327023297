import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
    border-top: 1px solid #e7e7e7;
    padding: 14px 2.5% 40px;
`;

export const FooterTop = styled.div`
    padding-bottom: 10px;
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    justify-content: space-between;
    &>div{
        display: flex;
        
    }
    .btns{
        display: flex;
        button{
            border: none;
            outline: none;
            background-color: #fff;
            padding-right: 10px;
            border-right: 1px solid #e7e7e7;
        }
    }
    .language.ant-select {
        margin-left: 20px;
        width: 80px;
        height: 24px;
    }
    .language.ant-select .ant-select-selector{
        height: 24px;
        input{
            height: 22px !important;
            line-height: 22px !important;
        }
        span{
            height: 22px !important;
            line-height: 22px !important;
        }
    }
    p{
        margin: 0;
        color: #aaa;
    }
`;
export const Item = styled.div`
p{
    font-size: 17px;
    line-height: 29px;
    font-weight: 500;
    margin: 0;
}
ul{
    padding: 0;
    li{
        font-size: 13px;
        color: #002f36;
        font-family: "Amazon Ember",Arial,sans-serif;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:not(:nth-last-child(1)){
            border-right: 1px solid #e7e7e7;
        }
        a{
            color: #002f36;
        }
    }
}
    
`;
export const LinksContainer = styled.div`
    display: flex;
    padding-top: 18px;
    &>div{
        padding-left: 10px;
    }
`;