import React, { useRef, useEffect, useCallback, useState } from 'react';
import './index.less'
import { Button, Modal } from 'antd';
import Template from '../Template'
import { regSubmit } from 'utils/PublicLibrary';
import AJAX from "api/apis";


export default (props) => {
  const [value, onChange] = useState(false);
  const [date, setDtae] = useState(new Date());
  const [vTime, setVTime] = useState('');
  const [dateObj, setDateObj] = useState(null);

  const collapseList = [
    {
      header: '为什么需要验证？',
      html: '<p>为了保护买家和卖家在我们商城的安全，我们会验证企业和个人信息。</p>'
    },
    {
      header: '我如何知道自己已通过验证？',
      html: '<p>验证完成后，我们将通过电子邮件告知您进程。</p> '
    },
    {
      header: '验证后该怎么办？',
      html: '<p>在等待验证时，您可以查看通过亚马逊进行全球开店的成功案例。点击<a>此处</a>。</p> '
    },
  ]
  useEffect(() => {
    getRegInfo()
  }, [])
  const getRegInfo = useCallback(() => {
    AJAX.login.getStoreRegisterInfo([]).then((res) => {
      for (let item of res.data) {
        if (item.propertyValue) {
          if (item.propertyKey == "authentication_videoconferencing_time") {
            let data = JSON.parse(item.propertyValue)
            setDtae(new Date(data.date))
            setDateObj(data)
            getTime(data.time)
          }
        }
      }
    })
  }, [])
  const getTime = (date) => {
    let end = date
    let n = new Date()
    let t = end - n
    if (t <= 0) {
      setVTime(`0 分钟后开始`)
    } else {
      let d = Math.floor(t / 1000 / 60 / 60 / 24)
      let h = Math.floor(t / 1000 / 60 / 60 % 24)
      let m = Math.floor(t / 1000 / 60 % 24)
      setVTime(`${d}天 ${h}时 ${m}分钟后开始`)
    }
  }

  let nextEvent = useCallback(() => {
    return {
      arr: [],
      localArr: null,
      whichPage: 'videoAuthentication/VideoVerification',
      step: 12
    }
  })
  const openModal = () => {
    Modal.info({
      title: '正在模拟视频验证中.....',
      width: '800px',
      className: 'VideoVerification_modal',
      icon: false,
      okText: '我了解',
      content: (
        <div>
          <p>说明：如何加入视频通话 登录您的亚马逊卖家平台账户，然后点击“加入通话"。在浏览器请求您的许可时启用您的摄像头和麦克风。 建议您在视频通话前5分钟测试您的摄像头和音频连接。请确保您满足以下要求︰ --互联网连接稳定、可靠。 --带前置网络摄像头、麦克风、扬声器和Safari浏览器（ iOS 设备）或 Google Chrome/360浏览器（其他设备)的个人电脑或笔记本电脑。</p>
          <div style={{ textAlign: 'center' }}>--------------------------------------------------------------------------------------------------------------</div>
          <div style={{ textAlign: 'center' }}>完成视频验证后将会进行下一步地址验证</div>
        </div>
      ),
      onOk() {
        // props.history.push('/videoAuthentication/AddressVerification')
        regSubmit([], null, '', 12).then(res => {
          if (res.code == 1) {
            props.history.push('/videoAuthentication/AddressVerification')
          }
        })
      },
    });
  }

  return (
    <Template
      collapseList={collapseList}
      prePath="/videoAuthentication/meetingMatters"
      nextPath="/completeValidation"
      nextEvent={nextEvent}
      current={5}
      disabled={!value}
      className='VideoVerification'
      footer={false}
      Pstyle={{
        width: '45%',
        margin: '0 auto'
      }}
    >
      <React.Fragment>
        <h3>身份验证</h3>
        <div className='a-alert'>
          <i className="a-icon a-icon-alert"></i>
          <div className='a-alert-content'>
            <h4 className="a-alert-heading ng-binding">感谢您提出请求</h4>
            我们已收到您的信息，正在对其进行验证。
          </div>
        </div>
        <div className='a-text-right'><a>V 显示已提交的信息</a></div>
        <h3>视频通话验证</h3>
        <div className='vi-divider'></div>
        <div className="a-text-center">
          <span>您安排的预约时间为
            <b>{date.getFullYear()}年
              {date.getMonth() + 1}月
              {date.getDate()}日
              中国标准时间
              {dateObj && dateObj.bAction.split(" ")[1] == 'AM' ? " 上午" : ' 下午'}
              {dateObj && dateObj.bAction.split(" ")[0]}。</b>
          </span>
          <div>预约的视频通话将在 <b>{vTime}</b></div>
          <Button onClick={() => {
            openModal()
          }} type='primary' className='next'>加入视频通话</Button>
          <div style={{ margin: '15px 0' }}>如果需要重新安排或取消预约，请点击<a>此处</a></div>
        </div>
        <div className="a-section " style={{ marginTop: '35px' }}>
          <span className="ng-binding">说明：</span>
        </div>
        <div className="a-section" style={{ marginTop: '-15px' }}>
          <ul>
            <li>我们可能会出于审核和质量目的录制您的视频通话。</li>
            <li>使用 iOS 设备上的 Safari 浏览器或其他设备上的 Google Chrome，这些设备须具有前置摄像头和麦克风。</li>
            <li>如果要加入预约通话，在设备询问时为视频通话提供摄像头和麦克风权限。</li>
          </ul></div>
      </React.Fragment>
    </Template>
  )
}