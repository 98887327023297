import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AText,
  ASelect,
  AInputMoney,
  ASlider,
  ARadioGroup,
  ADrawer,
} from "@a-components";
import { Button } from "antd";
import { computedInnerId } from "./../../config";
import Apis from "@api/apis";

const distributionOptions = [
  {
    label: "所有",
    value: 1,
  },
  {
    label: "具备Prime资格",
    value: 2,
  },
  {
    label: "不具备Prime资格",
    value: 3,
  },
];

const brandOptions = [
  {
    label: "品牌1",
    value: "brand1",
  },
  {
    label: "品牌2",
    value: "brand2",
  },
  {
    label: "品牌3",
    value: "brand3",
  },
];

const starInitValue = [0, 5];
const priceInfoInitValue = { minPrice: "", maxPrice: "" };

const RefineDrawer = ({
  visible,
  categoryInfo = {},
  categoryGoodsPositionList = [],
  onOk,
  onCancel,
  ...otherProps
}) => {
  const { classifyName = "测试", classifyId } = categoryInfo;

  const [brands, setBrands] = useState([]);
  const [priceInfo, setPriceInfo] = useState(priceInfoInitValue);
  const [starValue, setStarValue] = useState(starInitValue);
  const [distribution, setDistribution] = useState(1);

  const [brandOptions, setBrandOptions] = useState([]);

  useEffect(() => {
    if (!classifyId) return;
    Apis.brand.getBrandByClassifyId({ classifyId }).then((res) => {
      if (res.code === 1) {
        setBrandOptions(
          res.data.map(({ brandId, brandName }) => ({
            label: brandName,
            value: brandId,
          }))
        );
      }
    });
  }, [classifyId]);

  const titleEl = useMemo(() => {
    return (
      <>
        <AText strong>细化分类</AText> ：{classifyName} <br />
        <AText>根据特定品牌、价格范围、星级和Prime配送资格，细化分类。</AText>
      </>
    );
  }, [classifyName]);

  const reset = useCallback(() => {
    setBrands([]);
    setPriceInfo(priceInfoInitValue);
    setStarValue(starInitValue);
    setDistribution(1);
  }, []);

  const isPriceRangeError = useMemo(() => {
    const { minPrice, maxPrice } = priceInfo;

    if (!minPrice || !maxPrice) return false;

    return Number(minPrice) > Number(maxPrice);
  }, [priceInfo]);

  const outputValue = useMemo(() => {
    const { minPrice, maxPrice } = priceInfo;
    const [starStart, starEnd] = starValue;

    const returnValue = brandOptions
      .filter((item) => brands.includes(item.value))
      .map((item) => {
        const value = {
          ...categoryInfo,
          // priceInfo,
          // starValue,
          distribution,
          goodsPriceStart: minPrice,
          goodsPriceEnd: maxPrice,
          starStart,
          starEnd,
          brandId: item.value,
          brandName: item.label,
        };

        value.$id = computedInnerId(value);
        return value;
      });

    return returnValue;
  }, [priceInfo, starValue, brandOptions, brands, categoryInfo, distribution]);

  const outputValuesIds = useMemo(() => {
    return outputValue.map((v) => v.$id);
  }, [outputValue]);

  const isRepeat = !!categoryGoodsPositionList.find((v) =>
    outputValuesIds.includes(v.$id)
  );

  const isSureBtnDisabled = useMemo(() => {
    return isPriceRangeError || isRepeat || !brands.length;
  }, [brands.length, isPriceRangeError, isRepeat]);

  const handleClose = useCallback(() => {
    reset();
    onCancel?.();
  }, [reset, onCancel]);

  const handleSure = useCallback(() => {
    if (isPriceRangeError) return;

    onOk?.(outputValue);
    handleClose();
  }, [isPriceRangeError, onOk, outputValue, handleClose]);

  return (
    <ADrawer
      visible={visible}
      title={titleEl}
      placement="right"
      closable={false}
      getContainer={false}
      style={{ position: "absolute" }}
      {...otherProps}
      onClose={handleClose}
    >
      <div className="p-r h-100percent">
        <div>
          <AText strong>品牌</AText>
          <br />
          <ASelect
            value={brands}
            onChange={setBrands}
            style={{ width: 180 }}
            options={brandOptions}
            mode="multiple"
            disabled={!brandOptions.length}
            placeholder={brandOptions.length ? "请选择" : "无可用品牌"}
          />
        </div>
        <div>
          <AText strong>商品价格范围</AText>
          <br />
          <AInputMoney
            style={{ width: 180 }}
            value={priceInfo.minPrice}
            placeholder="无最低商品价格"
            onChange={(value) =>
              setPriceInfo((pre) => ({ ...pre, minPrice: value }))
            }
          />
          &nbsp; - &nbsp;
          <AInputMoney
            style={{ width: 180 }}
            value={priceInfo.maxPrice}
            placeholder="没有最高商品价格"
            onChange={(value) =>
              setPriceInfo((pre) => ({ ...pre, maxPrice: value }))
            }
          />
          <br />
          <AText type="danger">
            {isPriceRangeError ? "最低商品价格不能超过最高商品价格。" : ""}
          </AText>
        </div>
        <div>
          <AText strong>查看星级评定</AText>
          <br />
          <div className="df ai-c">
            <ASlider
              style={{ width: 300 }}
              value={starValue}
              onChange={([starStart, starEnd]) =>
                starStart !== starEnd && setStarValue([starStart, starEnd])
              }
              step={1}
              min={0}
              max={5}
              marks={{ 0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5 }}
              range
            />
            <AText style={{ marginLeft: 12 }}>
              {starValue[0]} - {starValue[1]} 星
            </AText>
          </div>
        </div>
        <div>
          <AText strong>配送</AText>
          <br />
          <ARadioGroup
            className="df"
            value={distribution}
            options={distributionOptions}
            onChange={(e) => setDistribution(e.target.value)}
          />
        </div>

        <div className="p-a b-0 r-0 df ai-c">
          {isRepeat ? (
            <AText type="danger">
              采用这些值的定位表达式已存在。 请调整值以创建唯一表达式
            </AText>
          ) : null}

          <Button type="link" onClick={handleClose}>
            取消
          </Button>
          <Button disabled={isSureBtnDisabled} onClick={handleSure}>
            定位
          </Button>
        </div>
      </div>
    </ADrawer>
  );
};

export default RefineDrawer;
