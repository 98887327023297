import { ATabs } from "@a-components/";
import { useState } from "react";

import NegativeKeywords from "@view/advertisement/module/AdvertisingDetail/NegativeDelivery/NegativeKeywords";
import NegativeGoods from "@view/advertisement/module/AdvertisingDetail/NegativeDelivery/NegativeGoods";

const NegativeDelivery = ({ advertisingGroupId }) => {
  const [activeKey, setActiveKey] = useState("keywords");

  return (
    <div>
      <ATabs activeKey={activeKey} onTabClick={setActiveKey}>
        <ATabs.TabPane tab="否定关键词" key="keywords">
          <NegativeKeywords
            businessType="advertisingGroup"
            businessId={advertisingGroupId}
          />
        </ATabs.TabPane>
        <ATabs.TabPane tab="否定商品" key="goods">
          <NegativeGoods
            businessType="advertisingGroup"
            businessId={advertisingGroupId}
          />
        </ATabs.TabPane>
      </ATabs>
    </div>
  );
};

export default NegativeDelivery;
