import AdvertisingSuperTable from "@view/advertisement/AdvertisingSuperTable";

const SearchWords = ({ advertisingGroupId }) => {
  return (
    <AdvertisingSuperTable
      businessType="adGroupSearchWords"
      businessId={advertisingGroupId}
    />
  );
};

export default SearchWords;
