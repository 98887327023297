import React from 'react'
import './ReturnReports.css'
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'


function ReturnReports(){
    return(
        <div>
            <Header/>
                <div style={{padding:"0 0 300px 0"}}>

                <div className="end20_content">
			<div className="end20_content_title">
				<div>
					<span>退货报告</span>查看亚马逊物流报告
				</div>
				使用此页面请求和下载退货报告。
			</div>
			<div className="end20_content_report">
				<div>
					<p>生成一次报告</p>
					<p>预定生成报告 > &nbsp;</p>
				</div>
				<div>
					<select>
						<option value="">所有退货</option>
					</select>
					<span>时间范围</span>
					<select>
						<option value="">最近天</option>
					</select>
					<button type="button">请求</button>
				</div>
			</div>
			<table>
				<tr className="end20_content_hader">
					<td colspan="5"></td>
				</tr>
				<tr>
					<td className="end20_content_table_one">类型</td>
					<td className="end20_content_table_two">已涵盖此日期范围</td>
					<td className="end20_content_table_three">请求时间</td>
					<td className="end20_content_table_four">XML</td>
					<td className="end20_content_table_five">TSv</td>
				</tr>
				<tr>
					<td>所有退货 [一次性]</td>
					<td>2020-11-15 更改为 2020-12-15</td>
					<td>2020年12月15日 上午08时00分38秒</td>
					<td></td>
					<td>无记录</td>
				</tr>
				<tr>
					<td>所有退货 [一次性]</td>
					<td>2020-11-15 更改为 2020-12-15</td>
					<td>2020年12月15日 上午08时00分38秒</td>
					<td></td>
					<td>无记录</td>
				</tr>
				<tr>
					<td>所有退货 [一次性]</td>
					<td>2020-11-15 更改为 2020-12-15</td>
					<td>2020年12月15日 上午08时00分38秒</td>
					<td></td>
					<td>无记录</td>
				</tr>
				<tr>
					<td>所有退货 [一次性]</td>
					<td>2020-11-15 更改为 2020-12-15</td>
					<td>2020年12月15日 上午08时00分38秒</td>
					<td>无记录</td>
					<td>无记录</td>
				</tr>
				<tr className="end20_content_table_vo">
					<td colspan="5">
						<button className="end20_content_table_btn" type="button">显示更多 > ></button>
					</td>
				</tr>
				
				
			</table>
		</div>


                </div>
            <Footer/>
        </div>
    )
}

export default ReturnReports