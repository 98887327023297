import React from 'react'
import './activity.css'


function activity(){
    return(
<div>

        <div className="activity_navBox">
            <div className="activity_navBoxII">
            <div>No.</div>
            <div>Name</div>
            <div>Segment</div>
            <div>date from</div>
            <div>Date to</div>
            <div>Gener ated on</div>
            <div>Notes</div>
            <div>Download</div>
            </div>
        </div>




</div>


    )
}

export default activity