import React from 'react'
import './deliverGoods.css'
import AJAX from "api/apis";
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import Reactours from "components/reactours"
import TipsIcon from "components/tipsIcon"
import '../default.css/default.css'
import { Link } from 'react-router-dom';
import { Button, Collapse, Steps, Radio, Input } from 'antd';
import { fbaAddress } from "@/utils/utils";

const { Panel } = Collapse;
const { Step } = Steps;
class deliverGoods extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            goodsList: [],
            addressDetails: '',
            isDisabled: true,
            tipsChcked: false,
            isModalVisible: false,
            ModalText: '',
            isReturn: false,
            isDeliveryStock: false,
            isTourOpen: true,
            steps1: [
                // {
                //     selector: '.deliverGoodsBox .stepGuidanceBox1',
                //     content: '添加一个有效地址',
                // },
                {
                    selector: '.deliverGoodsBox .stepGuidanceBox2',
                    content: '选择包装类型，单个发货选择原厂包装商品，多个发货选择混装商品',
                }
            ],
            isActive: false
        }
    }
    componentWillMount() {
        this.getStoreRegisterInfo()
        let goodsList = JSON.parse(sessionStorage.getItem('goodsList'))
        this.setState({
            goodsList: goodsList,
        })
    }
    awakenTips = (type) => {
        this.setState({
            isActive: type
        })
    }
    getStoreRegisterInfo = () => {
        AJAX.login.getStoreRegisterInfo([{
            propertyKey: 'paytaxes_organization_site'
        }], 'isRegister=0').then((res) => {
            if (res.code == 1 && res.data) {
                this.setState({
                    addressDetails: res.data[0].propertyValue
                })
            }
        })
    }
    goodsType = (e) => {
        let { goodsList } = this.state
        goodsList.PackingType = e.target.value
        sessionStorage.setItem('goodsList', JSON.stringify(goodsList))
        this.setState({
            goodsList: goodsList,
            isDisabled: false,
            tipsChcked: false
        })
    }
    nextEvent = () => {
        let { addressDetails, goodsList, isDisabled } = this.state;
        let goodsIdList = [];
        if (isDisabled) {
            this.setState({
                tipsChcked: true
            })
            return;
        }
        for (let items of goodsList.list) {
            goodsIdList.push(items.goodsId)
        }
        AJAX.fba.addStoragePlan({
            goodsId: goodsIdList,
            storagePlanAddRess: addressDetails,
            storagePlanPackType: goodsList.PackingType
        }).then((res) => {
            if (res.code == 1 && res.data) {
                // goodsList.planId = res.data;
                // goodsList.targetMall = res.extend;
                // sessionStorage.setItem('goodsList', JSON.stringify(goodsList))
                // this.props.history.push('/setQuantity')
                this.props.history.push({ pathname: '/setQuantity', state: { planId: res.data }, search: `?planId=${res.data}` })
            }
        })
    }
    render() {
        let { goodsList, addressDetails, tipsChcked, isTourOpen, steps1, isActive } = this.state
        return <div className="deliverGoodsBox">
            <Header />
            <div>
                <div className="kc06_box1">
                    <a href="#">Send to Amazon</a>
                    <ul className="kc06_box1-ul">
                        <li>新功能</li>
                        <li><a href="#">货件处理进度</a></li>
                        <li><a href="#">入库问题一览</a></li>
                        <li><a href="#">扫描和贴标</a></li>
                        <li><a href="#">上传入库计划文件</a></li>
                    </ul>
                </div>
                <div className="kc06_mybody">
                    <div className="kc06_center">
                        <p className="kc06_h1">发/补货
                            <span>了解更多信息</span>
                        </p>
                        <div className="kc06_body-table">
                            <p className="rukujihuaBox">要创建新入库计划还是添加至现有入库计划？</p>
                            <div className="kc06_commodity">
                                <ul className="kc06_itbox">
                                    <li>
                                        <div className="jihuaBox">
                                            <div>
                                                <span>入库计划</span>
                                                <span>这是什么？</span>
                                            </div>
                                            <Radio.Group defaultValue={1}>
                                                <Radio value={1}>创建新的入库计划</Radio>
                                            </Radio.Group>
                                        </div>
                                    </li>
                                    <li className="stepGuidanceBox1">
                                        <div className="jihuaBox">
                                            <div>
                                                <span>发货地址</span>
                                            </div>
                                            <p>{fbaAddress(addressDetails)}</p>
                                            <p className="collan">从另一地址发货</p>
                                        </div>
                                    </li>
                                    <li className="stepGuidanceBox2">
                                        <div className="jihuaBox">
                                            <div>
                                                <span>包装类型</span>
                                                <span>这是什么？</span>
                                            </div>
                                            <Radio.Group onChange={this.goodsType}>
                                                <Radio value={0}>混装商品</Radio>
                                                <Radio value={1}>原厂包装发货商品</Radio>
                                            </Radio.Group>
                                            <div>
                                                <img src={require("@/assets/img/home/1.png").default} />
                                                <img src={require("@/assets/img/home/2.png").default} />
                                            </div>
                                            {tipsChcked ? <div className="errorTipsText">请选择包装类型</div> : null}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="bottomDesc">
                                虽然您可以向亚马逊运营中心运送所有商品，但我们目前临时限制您可以运送的商品数量。<span className="collan">了解更多信息。</span>
                            </div>
                            <div className="kc06_it-r">
                                <div className="bottomDesc">
                                    我们正在构建一个速度更快的新工作流程，目的是简化库存补货过程并减少库存补货步骤。有关这一新工作流程“Send to Amazon”的介绍，<span className="collan">请观看此短片教程</span>。
                                </div>
                                <button onClick={this.nextEvent}>继续处理入库计划</button>
                            </div>
                        </div>
                        <p className="tips">提供缺少的信息，删除不合格商品，并指定要包括在此入库计划中的每种商品的数量。</p>
                        <p>显示1到1件1商品。</p>

                        <table className="kc06_table1">
                            <thead>
                                <tr>
                                    <th>卖家SKU</th>
                                    <th>商品名称</th>
                                    <th>状况</th>
                                    <th>需要操作</th>
                                    <th>数量</th>
                                    <th>删除</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>显示 ASINFNSKU</td>
                                    <td></td>
                                    <td>请输入您将发送的可售商品的数里。</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                {goodsList.list ? goodsList.list.map((item, index) => {
                                    return <tr key={index}>
                                        <td>{item.goodsSku}</td>
                                        <td>
                                            <div className="text-ellipsis-3">{item.goodsName}</div>
                                        </td>
                                        <td>{item.goodsCondition}</td>
                                        <td>--</td>
                                        <td><input disabled></input></td>
                                        <td style={{ width: '3%' }}>X</td>
                                    </tr>
                                }) : null}
                            </tbody>
                        </table>
                        <p className="totles">
                            <span>总计</span>
                            <span>0</span>
                        </p>
                        <p className="c199">显示1到1件1商品。</p>
                    </div>
                </div>
            </div>
            <Footer />
            {/* 引导步骤 */}
            <TipsIcon clicks={this.awakenTips} />
            <Reactours routePath="deliverGoods" isActive={isActive} clicks={this.awakenTips} steps={steps1} isOpen={isTourOpen} />
        </div>
    }
}


export default deliverGoods