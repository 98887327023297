import { ATabs } from "@a-components";
import { useState } from "react";
import CategoryRecommend from "./CategoryRecommend";
import CategorySearch from "./CategorySearch";
import { FormItem } from "@components/FormCollection";

const { TabPane } = ATabs;
const CategorySelector = () => {
  const [categoryType, setCategoryType] = useState("1");

  return (
    <ATabs value={categoryType} onChange={(e) => setCategoryType(e)}>
      <TabPane tab="建议" key="1">
        <FormItem
          noStyle
          shouldUpdate={(pre, old) => {
            return (
              pre.selectedGoodsList !== old.selectedGoodsList ||
              pre.categoryGoodsPosition !== old.categoryGoodsPosition
            );
          }}
        >
          {(form) => {
            const selectedGoodsIds = form
              .getFieldValue("selectedGoodsList")
              .map((item) => item.goodsId);
            const categoryGoodsPosition = form.getFieldValue(
              "categoryGoodsPosition"
            );

            const addPosition = (item) => {
              const ids = categoryGoodsPosition.map((v) => v.$id);
              let arr = [];

              const items = [].concat(item);

              items.forEach((item) => {
                if (!ids.includes(item.$id)) {
                  arr.push(item);
                }
              });

              form.setFieldsValue({
                categoryGoodsPosition: [...arr, ...categoryGoodsPosition],
              });
            };

            return (
              <CategoryRecommend
                onAddPosition={addPosition}
                selectedGoodsIds={selectedGoodsIds}
                categoryGoodsPositionList={categoryGoodsPosition}
              />
            );
          }}
        </FormItem>
      </TabPane>
      <TabPane tab="搜索" key="2">
        <CategorySearch />
      </TabPane>
    </ATabs>
  );
};

export default CategorySelector;
