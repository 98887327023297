import React from 'react'
import './Commission.css'
import '../default.css/default.css'
import Footer from 'components/addlisting_footer/FooterCom'
import Header from 'components/home_header'

function pupops() {
	return (
		<div>
			<Header />
			<div className="qr13_contcent">
				<ul className="qr13_contcent-ul">
					<li>提高商品价格的竞争力</li>
					<li>定价建议</li>
					<li>增加商品</li>
					<li>商品</li>
					<li></li>
				</ul>
				<div className="qr13_contcent-box">
					<div>
						佣金折扣
						<span>测试版</span>
						<span>了解更多信息</span>
						<span>发表反馈</span>
						<span>下载所有佣金折扣报告</span>
					</div>
					<div>销售这些商品进而获得佣金折扣</div>
					<div>为帮助您添加新的畅销商品，我们针对下列 ASIN 的销售向你提供限时销售佣金折扣。如果您有渠道可获得这些商品，可考虑向数以百万计的亚马逊买家销售这些商品。</div>
					<div><span>﹀</span>显示示例</div>
					<ul className="qr13_contcent-box-ul">
						<li>图片</li>
						<li>商品名称
							<p>亚马逊商品编码(ASIN)</p>
						</li>
						<li>报价</li>
						<li>销售佣金折扣</li>
						<li>操作</li>
					</ul>
					<div>
						<div className="qr13_boxbac">
							<svg t="1610540358971" className="icon qr13_img" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2690" width="20" height="20"><path d="M497.267461 388.629181c-38.59604 0-71.491241 31.30396-71.491241 69.914326l0 291.405883c0 38.610366 40.168862 69.914326 78.758762 69.914326 38.59604 0 78.251202-31.30396 78.251202-69.914326L582.786183 458.543508C582.786183 419.933141 535.863501 388.629181 497.267461 388.629181zM505.650385 0c-278.353612 0-505.617639 225.778186-505.617639 504.275062S227.296773 1008.538867 505.650385 1008.538867c278.347472 0 502.381945-225.76693 502.381945-504.263805S783.997857 0 505.650385 0zM505.288135 896.478651c-216.493729 0-393.254525-175.592181-393.254525-392.20359S288.793382 112.060215 505.288135 112.060215s390.739239 175.603437 390.739239 392.214846S721.781864 896.478651 505.288135 896.478651zM506.911098 192.703907c-43.626613 0-78.993099 35.382858-78.993099 79.024821 0 43.664475 35.366486 79.041194 78.993099 79.041194s78.993099-35.377742 78.993099-79.041194C585.904197 228.086765 550.537711 192.703907 506.911098 192.703907z" p-id="2691" fill="#1296db"></path></svg>
							<p>目前没有可用的佣金折扣</p>
						</div>
					</div>
				</div>
			</div>
			<Footer />

		</div>
	)
}

export default pupops