import React from "react";
import qs from "qs";
import cookieUtils from "utils/cookie";
import getUrlData from "utils/getUrlData";
import { Redirect } from 'react-router-dom';
import axios from "axios";
import { message, Button, Modal } from "antd";
import { stepControl } from "../utils/PublicLibrary";
// import { withRouter } from 'react-router-dom'
import { baseURL } from "./config";
axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;
// const receptionStoreId = window.parent?.location

export default {

  baseURL,
  ajaxs(getUrl, params = {}, methods, isShow) {
    return new Promise((resolve, reject) => {
      // let data = Object.assign({}, params);
      // console.log(window,window.receptionStoreId);
      let data = params;
      let query = {}
      query.receptionStoreId = window.receptionStoreId

      let axiosParams = {
        url: this.baseURL + getUrl,
        method: methods ? methods : "POST",
        timeout: 1000 * 60 * 5,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      };
      //添加参数
      if (methods === "GET") {
        data.receptionStoreId = window.receptionStoreId
        Object.defineProperty(axiosParams, "params", {
          value: data,
          enumerable: true,
        });
      } else if (methods === "PUT") {
        Object.defineProperty(axiosParams, "data", {
          value: data,
          enumerable: true,
        });
        Object.defineProperty(axiosParams, "params", {
          value: query,
          enumerable: true,
        });
      } else if (methods === "DELETE") {
        data.receptionStoreId = window.receptionStoreId
        Object.defineProperty(axiosParams, "params", {
          value: data,
          enumerable: true,
        });
      } else {
        Object.defineProperty(axiosParams, "data", {
          value: data,
          enumerable: true,
        });
        Object.defineProperty(axiosParams, "params", {
          value: query,
          enumerable: true,
        });
      }
      // console.log(getUrl)
      axios(axiosParams).then((res) => {
        // console.log(this.props, window, "this")

        if (res.data.code === 1 || res.data.code === -100) {
          let amazon_url = cookieUtils.getCookie('amazon_url') || '';
          amazon_url = amazon_url.split(',')
          // console.log(window.location.pathname);
          let dfurl = window.location.hash.replace('#', '')
          if (dfurl.includes('?')) {
            dfurl = dfurl.split('?')[0]
          }
          // console.log(dfurl, amazon_url.includes(dfurl));
          if (isShow == 1 && amazon_url.includes(dfurl)) {  //判断学生端打分弹框是否弹出
            const modal = Modal.info();
            modal.update({
              centered: true,
              footer: [null],
              className: 'unregisteredCompleteTipsBox amazonDialogBox',
              content: (
                <div className="expire-modal-content">
                  <div className="expire-modal-content-text">
                    <div className="p-24">
                      <div class="scoreTipsText">
                        <img src={require("@/assets/img/public/maskjg.png").default} />
                        <span>实操流程《{cookieUtils.getCookie("process_name")}》已完成，是否进行打分？</span>
                      </div>
                    </div>
                  </div>
                  <div className="expire-modal-btn">
                    <Button type="primary" className="cancelBtn" onClick={() => { modal.destroy(); resolve(res.data); }} data="hovers-c">取消</Button>
                    <Button type="primary" onClick={() => {
                      window.parent.postMessage({
                        cmd: 'amazonDialog',
                        params: {
                          success: true,
                          data: true
                        }
                      }, '*');
                      modal.destroy();
                      resolve(res.data);
                    }} data="hovers-7">进行打分</Button>
                  </div>
                </div>
              )
            });
          } else {  //axios请求正常返回数据
            resolve(res.data);
          }
        } else if (res.data.code === -200 && getUrl != '/orderMsg/getOrderMsgUnreadCount') {
          stepControl(res.data.data.data, res.data.data.extend);
        } else if (res.data.code === 401) {
          // 退出登录状态
          let config = {
            content: res.data.msg,
            style: {
              zIndex: 2070
            }
          }
          message.destroy()
          message.warning(config);
          let propsRouter = window.location.hash
          propsRouter = propsRouter.replace('#', '')
          // console.log(propsRouter, 'propsRouter');
          if (!propsRouter.includes('/login')) {
            window.location.href = `/practice_amazon/#/login?receptionStoreId=${window.receptionStoreId}&propsRouter=${propsRouter}`;
          }

        } else {
          if (res.data.msg) message.warning(res.data.msg);
          reject(res.data);
        }
      },
        (err) => {
          console.error("哎呀呀，后台系统出错啦！");
        }
      )
        .catch((err) => {
          console.error("哎呀呀，前端系统出错啦！");
        });
    });
  },

  //文件上传
  uploadFile(getUrl, params, name = "file") {
    return new Promise((resolve, reject) => {
      let param = new FormData(); // 创建form对象
      param.append(name, params);
      let config = {
        url: this.baseURL + getUrl,
        data: param,
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios(config)
        .then(
          (res) => {
            if (res.data.code === 1) {
              resolve(res.data);
            } else {
              message.error(res.data.message);
              reject(res.data);
            }
          },
          (err) => {
            reject(err);
          }
        )
        .catch((err) => {
          throw new Error(err);
        });
    });
  },

  //文件上传
  uploadFiless(getUrl, params, name = "file") {
    return new Promise((resolve, reject) => {
      let param = new FormData(); // 创建form对象
      let domain = document.domain;
      param.append(name, params);
      let config = {
        url: getUrl,
        data: param,
        method: "post",
        headers: {
          "x-oss-meta-author": 'aliysss',
          // "Access-Control-Allow-Origin":"*",
          'Content-Type': 'multipart/form-data',
          // "Access-Control-Allow-Headers":"Content-Type",
          // "Access-Control-Allow-Methods": "*",
          "Access-Control-Max-Age": "3600",
          // "Origin":'http://baidu.com'
        },
      };
      axios(config)
        .then(
          (res) => {
            if (res.data.code === 1) {
              resolve(res.data);
            } else {
              message.error(res.data.message);
              reject(res.data);
            }
          },
          (err) => {
            reject(err);
          }
        )
        .catch((err) => {
          throw new Error(JSON.stringify(err), "err");
        });
    });
  },
};
