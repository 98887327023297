import React from 'react'
import AJAX from "api/apis";
import { Link } from 'react-router-dom';
import './completeShipment.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import Reactours from "components/reactours"
import TipsIcon from "components/tipsIcon"
import '../default.css/default.css'
import { Button, Radio, Input, Form, Select, DatePicker, Checkbox } from 'antd';
import { fbaAddress, getUrlData } from "@/utils/utils";
const { Option } = Select

class completeShipment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            goodsList: [],
            planInfoList: [],
            extend: {},
            planInfo: {},
            dataInfo: {
                distributionExecute: 0,
                boxType: null,
                sendGoodsDate: null,
                fbaCommodityPlanGoodsInfoRespList: []
            },
            addressDetails: '',
            classContent: '',
            classType: 1,
            classIndex: null,
            isModalVisible: false,
            ModalText: '',
            isReturn: false,
            isDeliveryStock: false,
            isCalculated: false,
            acceptanceFee: false,
            isCheckbox: true,
            isgoodsdisble: true,
            rules: [{ required: true, message: '' }],
            isSetGoodsShow: false,
            isOthercarriers: true,
            isboxClick: false,
            isTourOpen: true,
            steps1: [
                {
                    selector: '.completeShipmentBox .psTypeBox',
                    content: '选择配送商和配送方式',
                },
                {
                    selector: '.completeShipmentBox .bzBox',
                    content: '选择商品包装方式',
                },
                {
                    selector: '.completeShipmentBox .kc06_table3',
                    content: '打印箱子标签，选择打印纸张类型、大小',
                },
                {
                    selector: '.completeShipmentBox .stepGuidanceBox1',
                    content: '选择发货日期',
                },
                {
                    selector: '.completeShipmentBox .btn-r button',
                    content: '继续按同样的方式处理另一个货件/完成货件',
                }
            ],
            isActive: false
        }
    }
    componentWillMount() {
        let planId = getUrlData('id')
        planId && this.getCommodityPlanInfo(planId)
        // console.log(planId);
        // let goodsList = JSON.parse(sessionStorage.getItem('goodsList'))
        this.getStoreRegisterInfo()

        // for (let item of planInfo.fbaCommodityPlanGoodsInfoRespList) {
        //     item.goodsSum = 0
        //     item.goodsTotal = 0
        //     item.boxSum = 0
        //     item.boxWeigth = 0
        //     item.boxSize1 = 0
        //     item.boxSize2 = 0
        //     item.boxSize3 = 0
        // }
        // this.setState({
        //     goodsList: goodsList,
        //     extend: extend,
        //     planInfo: planInfo,
        //     req: req
        // })
    }
    getFormRef = el => {
        this.form = el;
    }
    awakenTips = (type) => {
        this.setState({
            isActive: type
        })
    }
    getCommodityPlanInfo = (id) => {
        let { planInfo } = this.state;
        AJAX.fba.getCommodityPlanInfo({ id }).then((res) => {
            if (res.code == 1 && res.data) {
                planInfo = res.data
                planInfo.boxAllWeight = 0;
                planInfo.boxAllNumber = 0;
                planInfo.BillingAllWeight = 0;
                planInfo.commodityPlanSendMoney = 0;
                for (let item of planInfo.fbaCommodityPlanGoodsInfoRespList) {
                    item.goodsSum = 0
                    item.goodsTotal = 0
                    item.boxSum = 0
                    item.boxWeigth = 0
                    item.boxSize1 = 0
                    item.boxSize2 = 0
                    item.boxSize3 = 0

                }
                // console.log(planInfo,"planInfo")
                // this.form.setFieldsValue({
                //     distributionExecute:planInfo.commodityPlanDistributionExecute,
                //     distributionType:planInfo.commodityPlanDistributionType,
                //     transportType:planInfo.commodityPlanTransportType,
                //     sendGoodsDate:planInfo.commodityPlanSendGoodsDate,
                //     person:planInfo.commodityPlanPerson,
                //     boxType:planInfo.commodityPlanBoxType
                // })
                this.setState({
                    planInfo: planInfo
                })
            }
        })
    }
    getStoreRegisterInfo = () => {
        AJAX.login.getStoreRegisterInfo([{
            propertyKey: 'paytaxes_organization_site'
        }], 'isRegister=0').then((res) => {
            if (res.code == 1 && res.data) {
                this.setState({
                    addressDetails: res.data[0].propertyValue
                })
            }
        })
    }
    formChange = () => {
        let { isOthercarriers, dataInfo, planInfo } = this.state
        let dataInfo1 = this.form.getFieldsValue();
        if (dataInfo1.distributionExecute == 1) {
            isOthercarriers = false
        } else {
            isOthercarriers = true
        }
        if (dataInfo.boxType != dataInfo1.boxType) {
            for (let item of planInfo.fbaCommodityPlanGoodsInfoRespList) {
                let obj = {}
                obj[`${item.goodsAsin}GoodsSum`] = ""
                obj[`${item.goodsAsin}GoodsTotal`] = ""
                obj[`${item.goodsAsin}BoxSum`] = ""
                obj[`${item.goodsAsin}BoxWeight`] = ""
                obj[`${item.goodsAsin}BoxSize1`] = ""
                obj[`${item.goodsAsin}BoxSize2`] = ""
                obj[`${item.goodsAsin}BoxSize3`] = ""
                this.form.setFieldsValue(obj);
            }
            // console.log(planInfo, "planInfo-----------")
        }
        this.setState({
            dataInfo: dataInfo1,
            planInfo: planInfo,
            isOthercarriers: isOthercarriers
        })
    }
    setGoodsChange = (e, item) => {
        let { planInfo, req } = this.state
        let allInGoodsTagMoney = 0;
        let allInPackMoney = 0;
        let reqAllInGoodsTagMoney = 0;
        let reqAllInPackMoney = 0;
        for (let items of planInfo.fbaCommodityPlanGoodsInfoRespList) {
            if (items.goodsId == item.goodsId) {
                items.goodsCount = e.target.value
                allInPackMoney += Number(items.goodsCount * items.inPackMoney)
                allInGoodsTagMoney += Number(items.goodsCount * items.inGoodsTagMoney)
            }
        }
        planInfo.allInPackMoney = allInPackMoney
        planInfo.allInGoodsTagMoney = allInGoodsTagMoney
        for (let items of req.fbaGoodsInfoList) {
            if (items.goodsId == item.goodsId) {
                items.goodsCount = e.target.value
                reqAllInGoodsTagMoney += Number(items.goodsCount * items.inPackMoney)
                reqAllInPackMoney += Number(items.goodsCount * items.inGoodsTagMoney)
            }
        }
        req.allInPackMoney = reqAllInGoodsTagMoney
        req.allInGoodsTagMoney = reqAllInPackMoney
        this.setState({
            planInfo: planInfo,
            req: req
        })
    }
    setGoodsClick = () => {
        let { isSetGoodsShow } = this.state;
        isSetGoodsShow = !isSetGoodsShow
        this.setState({
            isSetGoodsShow: isSetGoodsShow
        })
    }

    sussecsBtn = () => {
        let { addressDetails, req, planInfo, extend, dataInfo, loading } = this.state
        if (loading) return
        this.setState({ loading: true })
        this.form.validateFields().then((res) => {
            // let boxCount = [];
            // let boxSizi = [];
            // let boxWeight = [];
            let commodityPlanGoodsReqList = [];
            let commodityPlanBoxReq = {
                boxSize: 0,
                boxWeight: 0,
                commodityPlanGoodsIdList: []
            };
            for (let items of planInfo.fbaCommodityPlanGoodsInfoRespList) {
                if (res.boxType == 1) {
                    commodityPlanBoxReq.boxSize = `${planInfo.fbaCommodityPlanGoodsInfoRespList[0].boxSize1} X ${planInfo.fbaCommodityPlanGoodsInfoRespList[0].boxSize2} X ${planInfo.fbaCommodityPlanGoodsInfoRespList[0].boxSize3}`;
                    commodityPlanBoxReq.boxWeight = planInfo.fbaCommodityPlanGoodsInfoRespList[0].boxWeigth;
                    commodityPlanBoxReq.commodityPlanGoodsIdList.push(items.id)
                } else if (res.boxType == 2) {
                    commodityPlanGoodsReqList.push({
                        commodityPlanBoxBaseReqList: [
                            {
                                "boxQuantity": items.boxSum,
                                "boxSize": `${items.boxSize1} X ${items.boxSize2} X ${items.boxSize3}`,
                                "boxWeight": items.boxWeigth,
                                "singleBoxGoodsQuantity": items.goodsSum
                            }
                        ],
                        commodityPlanGoodsId: items.id
                    })
                }

                // boxCount.push(res.boxType == 2 ? items.goodsTotal : 1)
                // boxWeight.push(items.boxWeigth)
                // boxSizi.push(`${items.boxSize1} X ${items.boxSize2} X ${items.boxSize3}`)
            }
            let obj = {
                id: planInfo.id,  //货件计划主键id
                storagePlanId: planInfo.storagePlanId,  //货件计划id
                "commodityPlanAddRess": addressDetails, //发货地址
                // "allBoxCount": boxCount,  //箱子数量
                "commodityPlanBoxJson": "string",
                // "boxSizi": boxSizi,  //箱子大小
                "commodityPlanGoodsReqList": commodityPlanGoodsReqList,
                "commodityPlanBoxReq": commodityPlanBoxReq,
                "commodityPlanBoxType": Number(res.boxType),
                // "boxWeight": boxWeight,  //箱子重量
                "commodityPlanDistributionExecute": Number(res.distributionExecute) || 0,  //配送商
                "commodityPlanDistributionType": Number(res.distributionType) || 0,  //配送方式
                "commodityPlanPerson": res.person,  //承运人
                "commodityPlanSendGoodsDate": dataInfo.sendGoodsDate,  //发货日期
                "commodityPlanTransportType": Number(res.transportType),  //运送方式
                commodityPlanSendMoney: res.distributionType == 1 ? planInfo.commodityPlanSendMoney : 0,  //运费

            }
            AJAX.fba.upCommodityPlan(obj).then((data) => {
                if (data.code == 1) {
                    this.setState({ loading: false })
                    this.props.history.push({
                        pathname: '/atAglance',
                        state: {
                            id: planInfo.id
                        }
                    })
                }
            })
        }).catch((err) => {
            this.setState({ loading: false })
        })
    }
    regEvent = () => {
        let { planInfo, dataInfo } = this.state
        for (let item of planInfo.fbaCommodityPlanGoodsInfoRespList) {
            if (dataInfo.boxType == 2) {
                if (item.goodsSum != 0 && item.goodsTotal != 0 && item.boxSum != 0 && item.boxWeigth != 0 && item.boxSize1 != 0 && item.boxSize2 != 0 && item.boxSize3 != 0) {
                    this.setState({
                        isgoodsdisble: false
                    })
                } else {
                    this.setState({
                        isgoodsdisble: true
                    })
                }
            } else {
                if (planInfo.fbaCommodityPlanGoodsInfoRespList[0].boxWeigth != 0 && planInfo.fbaCommodityPlanGoodsInfoRespList[0].boxSize1 != 0 && planInfo.fbaCommodityPlanGoodsInfoRespList[0].boxSize2 != 0 && planInfo.fbaCommodityPlanGoodsInfoRespList[0].boxSize3 != 0) {
                    this.setState({
                        isgoodsdisble: false
                    })
                } else {
                    this.setState({
                        isgoodsdisble: true
                    })
                }
            }
        }
    }
    checkboxChange = e => {
        this.setState({
            isCheckbox: !e.target.checked
        })
    }
    DatepickerChange = (date, dateString) => {
        let { dataInfo } = this.state;
        dataInfo.sendGoodsDate = dateString
    }
    goodsSumChange = (e, index) => {
        let { planInfo } = this.state
        planInfo.fbaCommodityPlanGoodsInfoRespList[index].goodsSum = e.target.value;
        planInfo.fbaCommodityPlanGoodsInfoRespList[index].goodsTotal = Number(planInfo.fbaCommodityPlanGoodsInfoRespList[index].goodsSum * planInfo.fbaCommodityPlanGoodsInfoRespList[index].boxSum)
        this.regEvent()
        this.setState({
            planInfo: planInfo
        })
    }
    boxSumChange = (e, index) => {
        let { planInfo } = this.state
        planInfo.fbaCommodityPlanGoodsInfoRespList[index].boxSum = e.target.value;
        planInfo.fbaCommodityPlanGoodsInfoRespList[index].goodsTotal = Number(planInfo.fbaCommodityPlanGoodsInfoRespList[index].goodsSum * planInfo.fbaCommodityPlanGoodsInfoRespList[index].boxSum)
        this.regEvent()
        this.setState({
            planInfo: planInfo
        })
    }
    boxWeightChange = (e, index) => {
        let { planInfo } = this.state
        planInfo.fbaCommodityPlanGoodsInfoRespList[index].boxWeigth = e.target.value;
        this.regEvent()
        this.setState({
            planInfo: planInfo
        })
    }
    boxSizeChange = (e, index, type) => {
        let { planInfo } = this.state
        if (type == 1) {
            planInfo.fbaCommodityPlanGoodsInfoRespList[index].boxSize1 = e.target.value;
        } else if (type == 2) {
            planInfo.fbaCommodityPlanGoodsInfoRespList[index].boxSize2 = e.target.value;
        } else if (type == 3) {
            planInfo.fbaCommodityPlanGoodsInfoRespList[index].boxSize3 = e.target.value;
        }
        this.regEvent()
        this.setState({
            planInfo: planInfo
        })
    }
    boxClick = () => {
        let { planInfo } = this.state;
        for (let items of planInfo.fbaCommodityPlanGoodsInfoRespList) {
            planInfo.boxAllWeight += Number(items.boxWeigth)
            planInfo.boxAllNumber += Number(items.boxSum);
            planInfo.BillingAllWeight += Number(items.boxWeigth);
        }
        this.setState({
            isboxClick: true,
            planInfo: planInfo
        })
    }
    calculatingCosts = () => {
        let { planInfo } = this.state
        planInfo.commodityPlanSendMoney = Number(planInfo.BillingAllWeight * 3)
        this.setState({ isCalculated: true, planInfo: planInfo })
    }

    deleteCommdityPlan = () => {
        AJAX.fba.deleteCommdityPlan({ id: getUrlData('id') }).then((res) => {
            if (res.code == 1) {
                this.getCommodityPlanInfoView(getUrlData('id'))
                this.getStoreRegisterInfo()
            }
        })
    }

    render() {
        let { addressDetails, isboxClick, planInfo, dataInfo, rules, isCalculated, acceptanceFee, isCheckbox, isActive, isgoodsdisble, isSetGoodsShow, isOthercarriers, steps1, isTourOpen } = this.state
        return <div className="completeShipmentBox">
            <Header />
            <div>
                <div className="kc06_box1">
                    <a href="#">Send to Amazon</a>
                    <ul className="kc06_box1-ul">
                        <li>新功能</li>
                        <li><a href="#">货件处理进度</a></li>
                        <li><a href="#">入库问题一览</a></li>
                        <li><a href="#">扫描和贴标</a></li>
                        <li><a href="#">上传入库计划文件</a></li>
                    </ul>
                </div>
                <div className="kc06_mybody">
                    <div className="kc06_center">
                        <p className="kc06_h1">
                            发/补货
                            <span>了解更多信息</span>
                        </p>
                        <div className="kc06_body-border">
                            <ul className="kc06_body-ul">
                                <li>设置数量</li>
                                <li>预处理商品</li>
                                <li>为商品贴标</li>
                                <li>查看货件</li>
                                <li>预处理货件<img src={require("@/assets/img/home/kc12_topsj.png").default} /></li>
                                <li>一览 </li>
                            </ul>
                        </div>
                        <div className="kc06_body-table">
                            <table className="kc06_table1">
                                <thead>
                                    <tr>
                                        <th>货件名称/编号</th>
                                        <th>发货地址</th>
                                        <th>配送地址</th>
                                        {/* <th>包装类型 <span>这是什么？</span></th> */}
                                        {/* <th>主题</th> */}
                                        <th>货件内商品</th>
                                        <th>可选服务费用(预计)</th>
                                        <th>货件状态</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div>
                                                <div>
                                                    <span className="bolds">货件名称:</span>{planInfo.commodityPlanName}
                                                </div>
                                                <div>
                                                    <span className="bolds">编号:</span>{planInfo.commodityPlanNumber}
                                                </div>
                                                <div>
                                                    <span className="bolds">货件追踪编号:</span>{planInfo.commodityPlanTraceNumber || '--'}
                                                </div>
                                            </div>
                                        </td>
                                        <td>{fbaAddress(addressDetails)}</td>
                                        <td>{planInfo.commodityPlanAmazonAddRess}</td>
                                        <td>
                                            <p>{planInfo.commodityPlanMskuCount} MSKU</p>
                                            <p>{planInfo.commodityPlanMskuCount} 商品数量</p>
                                        </td>
                                        <td>
                                            <div>
                                                <div>亚马逊物流人工处理费用:${planInfo.commodityPlanManpowerMoney || 0}</div>
                                                <div>亚马逊合作承运人运费:${planInfo.commodityPlanSendMoney || 0}</div>
                                                <div>贴标和预处理:已针对总体入库计划给予计算</div>
                                            </div>
                                        </td>
                                        {/* <td>{planInfo.fbaCommodityPlanGoodsInfoRespList.length}件商品</td> */}
                                        <td>
                                            <div>
                                                <div>
                                                    <span className="bolds">
                                                        {planInfo.commodityStatus == 1 ? '处理中' : planInfo.commodityStatus == 2 ? '即将发货' : '已发货'}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span className="bolds">已创建:</span>{planInfo.createTime}
                                                </div>
                                                <div>
                                                    <span className="bolds">已更新:</span>{planInfo.updateTime}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <Form ref={this.getFormRef}>
                                <div className="jcContent">1.检查货件内容</div>
                                <div className="body-tab-div">
                                    <img src={require("@/assets/img/home/kc12_i-blue.png").default} className="i" />
                                    <p>您可以在此处查看和修改商品数量，但更改范围只能是数量的 5% 或 6 件。如果您想要向货件添加更多商品或新商品，则必须复制货件或创建新的货件。 除非您已经在运输流程的准备货件步骤中提供了箱内物品信息，否则我们建议您不要打包和密封包装箱。 了解更多信息</p>
                                </div>
                                <div className="kc06_it-r" onClick={this.setGoodsClick}>
                                    <button>{isSetGoodsShow ? '隐藏商品' : '检查并修改商品'}</button>
                                </div>
                                {isSetGoodsShow ? <div className="setGoodsBox">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>MSKU</th>
                                                <th>商品名称</th>
                                                <th>状况</th>
                                                <th>已发货	</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td>
                                                    <Checkbox>显示 ASIN/FNSKU</Checkbox>
                                                </td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            {planInfo.fbaCommodityPlanGoodsInfoRespList ? planInfo.fbaCommodityPlanGoodsInfoRespList.map((item, index) => {
                                                return <tr key={index}>
                                                    <td>{planInfo.mskuCount}</td>
                                                    <td>{item.goodsName}</td>
                                                    <td>{item.goodsCondition}</td>
                                                    <td>
                                                        {/* <Form.Item name={`${item.goodsName}Sum`} style={{ marginBottom: '0' }}> */}
                                                        <Input value={item.goodsCount} onChange={(e) => { this.setGoodsChange(e, item) }} style={{ width: '50%' }} />
                                                        {/* </Form.Item> */}
                                                    </td>
                                                </tr>
                                            }) : null}
                                            <tr>
                                                <td>总计</td>
                                                <td></td>
                                                <td></td>
                                                <td>{planInfo.planAllGoodsCount}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> : null}
                                <div className="jcContent">2.配送服务</div>
                                <div className="psTypeBox">
                                    <div className="psType">
                                        <p>配送方式</p>
                                        <Form.Item name="distributionType">
                                            <Radio.Group defaultValue={0} onChange={this.formChange}>
                                                <Radio value={0}>小包裹快递（SPD）</Radio>
                                                <div></div>
                                                <Radio value={1}>汽运零担 (LTL)</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        <p>我应该选择哪种方法？</p>
                                    </div>
                                    <div className="psType">
                                        <p>配送商</p>
                                        <Form.Item name="distributionExecute">
                                            <Radio.Group defaultValue="0" onChange={this.formChange}>
                                                <Radio value="0">亚马逊物流通过亚马逊运输服务取件 (UPS)</Radio>
                                                <div></div>
                                                <Radio value="1">其他承运人</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        <div>
                                            <div style={{ display: "flex" }} className="psshang">
                                                <div>
                                                    <p>运送方式</p>
                                                    <Form.Item name="transportType" rules={dataInfo.distributionExecute == 1 ? rules : null} style={{ minWidth: '200px' }}>
                                                        <Select disabled={isOthercarriers} placeholder="请选择">
                                                            <Option value="1">海</Option>
                                                            <Option value="2">陆</Option>
                                                            <Option value="3">空</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div>
                                                    <p>承运人</p>
                                                    <Form.Item name="person" rules={dataInfo.distributionExecute == 1 ? rules : null} style={{ minWidth: '200px' }}>
                                                        <Select disabled={isOthercarriers} placeholder="请选择">
                                                            <Option value="United States Postal Service">United States Postal Service</Option>
                                                            <Option value="FEDERAL EXPRESS CORP">FEDERAL EXPRESS CORP</Option>
                                                            <Option value="FEDEX GROUND (CORPORATE)">FEDEX GROUND (CORPORATE)</Option>
                                                            <Option value="DHL EXPRESS (USA) INC">DHL EXPRESS (USA) INC</Option>
                                                            <Option value="UNITED PARCEL SERVICE INC">UNITED PARCEL SERVICE INC</Option>
                                                            <Option value="Other">Other</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <p>我应该选择哪种方法？</p>
                                    </div>
                                </div>
                                <div className="bzBox">
                                    <p className="bolds">此货件如何包装？</p>
                                    <Form.Item name="boxType" rules={rules} style={{ maxWidth: '200px' }}>
                                        <Select placeholder="请选择" onChange={this.formChange}>
                                            <Option value="1">所有商品装于一个箱子</Option>
                                            <Option value="2">多个箱子</Option>
                                        </Select>
                                    </Form.Item>
                                </div>

                                {dataInfo.boxType ? <>
                                    <div className="wpInfoBox">
                                        <p className="bolds">您如何提供箱内物品信息？观看教程</p>
                                        <div className="wpInfoRadioBox">
                                            <Radio.Group>
                                                <Radio value={1}>
                                                    <p>使用网页表格</p>
                                                    <p>在网页表格中填写箱子信息</p>
                                                </Radio>
                                                <Radio value={2}>
                                                    <p>上传文件</p>
                                                    <p>填写装箱单，并上传到卖家平台</p>
                                                </Radio>
                                                <Radio value={3}>
                                                    <p>条过箱子信息并收取人工处理费用</p>
                                                    <p>亚马逊将手动处理您的箱内物品<span style={{ color: '#004b91' }}>了解更多信息</span></p>
                                                </Radio>
                                            </Radio.Group>
                                        </div>
                                    </div>
                                    <table className="wpInfoTableBox">
                                        <thead>
                                            <tr>
                                                <th>货件内商品</th>
                                                <th>货件数量</th>
                                                {dataInfo.boxType == 2 ? <><th>每个箱子配置的商品数</th><th>箱子数量</th><th>总量</th></> : null}
                                                <th>箱子重量（lb）</th>
                                                <th>箱子尺寸（ln）</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><input style={{ width: '13px' }} type="checkbox" />显示 ASIN/FNSKU</td>
                                                <td></td>
                                                {dataInfo.boxType == 2 ? <><td></td><td></td>
                                                    <td></td></> : null}
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            {planInfo.fbaCommodityPlanGoodsInfoRespList ? planInfo.fbaCommodityPlanGoodsInfoRespList.map((item, index) => {
                                                return <tr key={index}>
                                                    <td>
                                                        <p>{item.goodsSku}</p>
                                                        <p className="text-ellipsis-3">{item.goodsName}</p>
                                                    </td>
                                                    <td style={{ borderRight: dataInfo.boxType == 1 ? '1px solid rgba(0, 0,.0, 0.1)' : '' }}>{item.commodityPlanGoodsCount}</td>
                                                    {dataInfo.boxType == 2 ? <>
                                                        <td>
                                                            <Form.Item rules={rules} name={`${item.goodsAsin}GoodsSum`}>
                                                                <Input onChange={(e) => { this.goodsSumChange(e, index) }} />
                                                            </Form.Item>
                                                        </td>
                                                        <td>
                                                            <Form.Item rules={rules} name={`${item.goodsAsin}BoxSum`}>
                                                                <Input onChange={(e) => { this.boxSumChange(e, index) }} />
                                                            </Form.Item>
                                                        </td><td>{item.goodsTotal}</td></> : null}
                                                    {dataInfo.boxType == 2 ? <><td><Form.Item rules={rules} name={`${item.goodsAsin}BoxWeight`}>
                                                        <Input onChange={(e) => { this.boxWeightChange(e, index) }} />
                                                    </Form.Item></td>
                                                        <td className="sizeBox">
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Form.Item rules={rules} name={`${item.goodsAsin}BoxSize1`} style={{ minWidth: '30%' }}>
                                                                    <Input onChange={(e) => { this.boxSizeChange(e, index, 1) }} />
                                                                </Form.Item><span style={{ margin: '0 3px' }}>X</span>
                                                                <Form.Item rules={rules} name={`${item.goodsAsin}BoxSize2`} style={{ minWidth: '30%' }}>
                                                                    <Input onChange={(e) => { this.boxSizeChange(e, index, 2) }} />
                                                                </Form.Item><span style={{ margin: '0 3px' }}>X</span>
                                                                <Form.Item rules={rules} name={`${item.goodsAsin}BoxSize3`} style={{ minWidth: '30%' }}>
                                                                    <Input onChange={(e) => { this.boxSizeChange(e, index, 3) }} />
                                                                </Form.Item>
                                                            </div>
                                                        </td></> : dataInfo.boxType == 1 && index == 0 ? <><td rowSpan="3"><Form.Item rules={rules} name={`${item.goodsAsin}BoxWeight`}>
                                                            <Input onChange={(e) => { this.boxWeightChange(e, index) }} />
                                                        </Form.Item></td>
                                                            <td rowSpan="3" className="sizeBox">
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Form.Item rules={rules} name={`${item.goodsAsin}BoxSize1`} style={{ minWidth: '30%' }}>
                                                                        <Input onChange={(e) => { this.boxSizeChange(e, index, 1) }} />
                                                                    </Form.Item><span style={{ margin: '0 3px' }}>X</span>
                                                                    <Form.Item rules={rules} name={`${item.goodsAsin}BoxSize2`} style={{ minWidth: '30%' }}>
                                                                        <Input onChange={(e) => { this.boxSizeChange(e, index, 2) }} />
                                                                    </Form.Item><span style={{ margin: '0 3px' }}>X</span>
                                                                    <Form.Item rules={rules} name={`${item.goodsAsin}BoxSize3`} style={{ minWidth: '30%' }}>
                                                                        <Input onChange={(e) => { this.boxSizeChange(e, index, 3) }} />
                                                                    </Form.Item>
                                                                </div>
                                                            </td></> : null}
                                                </tr>
                                            }) : null}
                                        </tbody>
                                    </table>
                                    <div className="surplus">剩余箱子数199</div>
                                    <div className="kc06_it-r submitBox">
                                        {isboxClick ? <div style={{ color: 'red' }}>您成功提交箱子信息</div> : null}
                                        <Button onClick={this.boxClick} disabled={isgoodsdisble}>确认</Button>
                                    </div></> : null}
                                {!dataInfo.distributionExecute || dataInfo.distributionExecute == 0 ?
                                    <><div className="jcContent">4.运费</div>
                                        <p>您负责提供准确的货件信息和实际货件成本。如果提供的信息不准确，我们可能会阻止今后向运营中心发送货件，或针对违规行为收取额外的费用。</p>
                                        <div className="kc06_body-table">
                                            <table className="kc06_table1 kc06_table2">
                                                <thead>
                                                    <tr>
                                                        <th>配送商</th>
                                                        <th>箱子数量</th>
                                                        <th>货件重量</th>
                                                        <th>计费重量</th>
                                                        <th>预估的运费</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td></td>
                                                        <td>{planInfo.boxAllNumber}</td>
                                                        <td>{planInfo.boxAllWeight} lb.</td>
                                                        <td>{planInfo.BillingAllWeight} lb.</td>
                                                        <td>{isCalculated ? planInfo.commodityPlanSendMoney : <div onClick={this.calculatingCosts} className="kc06_it-r submitBox submitBox1">
                                                            <button>计算费用</button>
                                                        </div>}
                                                        </td>
                                                    </tr>
                                                    {isCalculated ? acceptanceFee ? <><tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td colspan="2" style={{ color: 'rgb(59, 153, 90)', textAlign: 'right' }}>已接受费用</td>
                                                        <td style={{ textAlign: 'left' }}>
                                                            <div className="kc06_it-r submitBox">
                                                                <button onClick={() => { this.setState({ acceptanceFee: false, isCheckbox: true }) }}>取消费用</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                        <tr>
                                                            <td colspan="5" style={{ color: 'rgb(0, 0, 153)', textAlign: 'right' }}>如果您想要取消运费，您还有23小时，59分钟时间。</td>
                                                        </tr></> : <tr>
                                                        <td></td>
                                                        <td colspan="3" style={{ color: 'rgb(59, 153, 90)', textAlign: 'right' }}>
                                                            <Form.Item>
                                                                <Checkbox onChange={this.checkboxChange}> 我同意亚马逊合作承运人计划条款和条件。</Checkbox>
                                                            </Form.Item>
                                                        </td>
                                                        <td style={{ textAlign: 'left' }}>
                                                            <div className="kc06_it-r submitBox submitBox1">
                                                                <Button onClick={() => { this.setState({ acceptanceFee: true }) }} disabled={isCheckbox}>接受费用</Button>
                                                            </div>
                                                        </td>
                                                    </tr> : null}
                                                </tbody>
                                            </table>
                                        </div></> : null}
                                <div className="jcContent">4.货件标签</div>
                                <p>选择与打印机格式兼容的纸张类型。由于每个包装箱标签都是唯一的，请务必打印所有包装箱标签。请不要影印、重复使用或修改标签并将其用于其他包装箱。即使包装箱内含单件可售商品，也需要提供包装箱编号标签。对于内含多件标准尺寸商品的包装箱，其任何一-侧的长度均不得超过25.00英寸。了 解更多信息</p>
                                <div className="kc06_body-table">
                                    <table className="kc06_table1 kc06_table3">
                                        <thead>
                                            <tr>
                                                <th>箱子数量</th>
                                                <th>纸张类型</th>
                                                <th>纸张大小</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>
                                                    <select>
                                                        <option>空白矩形标签</option>
                                                        <option>热敏打印纸</option>
                                                    </select>
                                                </td>
                                                <td>8.5 x 8.5英寸（US信纸）</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="bolds">发货日期</div>
                                <p>发货日期是指您要将库存交给承运人的日期。此日期可以帮助我们准备接收您的库存。在您确认发货之后:可以更改此日期。</p>
                                <div className="stepGuidanceBox1">
                                    <div>
                                        <DatePicker onChange={this.DatepickerChange} placeholder="请选择日期" />
                                    </div>
                                    <div className="kc06_it-r">
                                        <button>打印箱子标签</button>
                                    </div>
                                </div>
                                <p className='m20'>出于安全原因，内含多件商品的箱子重里不得超过50磅。内含一件商品且重里超过50磅的箱子必须在项部和侧面标上“多人合搬”。</p>
                                <div className="body-tab-div">
                                    <img src={require("@/assets/img/home/kc12_i-blue.png").default} className="i" />
                                    <div>
                                        <p style={{ fontSize: '20px', marginBottom: '5px' }}>包装箱标签粘贴说明新功能</p>
                                        <p>仅在包装箱的一侧粘贴亚马逊物流箱号标签和承运人标签。请参阅以下内容，了 解如何在包装箱上粘贴标签。</p>
                                        <p style={{ color: 'rgb(0, 75, 145)' }}>了解有关货件准备和路线安排要求的更多信息</p>
                                        <img src={require("@/assets/img/home/biaoqian.png").default} className="biaoqian" />

                                    </div>
                                </div>
                                <div className="body-box2">
                                    <div className="btn-l">
                                        <Link to={{
                                            pathname: '/handlingCargo',
                                            search: `?planId=${planInfo.storagePlanId}`,
                                            state: {
                                                planId: planInfo.storagePlanId
                                            }
                                        }}><button>处理另一个货件</button></Link>
                                        {!planInfo.deleteFlag ? planInfo.commodityStatus == 2 ? <button onClick={this.deleteCommdityPlan}>删除货件</button> : planInfo.commodityStatus == 3 ? <button onClick={this.deleteCommdityPlan}>取消货件</button> : null : null}
                                        <button>复制</button>
                                    </div>
                                    <div className="btn-r">
                                        <button style={{ paddingRight: '20px', borderRadius: '10px 15px 15px 10px' }} onClick={this.sussecsBtn}>
                                            完成货件<img className="kc_10_btn2" src={require("@/assets/img/home/btns.png").default} />
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            {/* 引导步骤 */}
            <TipsIcon clicks={this.awakenTips} />
            <Reactours steps={steps1} isActive={isActive} clicks={this.awakenTips} routePath="completeShipment" isOpen={isTourOpen} />
        </div>
    }
}


export default completeShipment