import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./advertising.css";
import "../../../default.css/default.css";
import { Link, useHistory } from "react-router-dom";
import Sidebar from "../AdvertisingCampaign/component/sidebar/index";
import { AButton, ATable } from "@a-components/";
import { message, Space } from "antd";
import Apis from "@api/apis";

function Advertising() {
  const [activeAdvertisingId, setAdvertisingActive] = useState(0);
  const [draftList, setDraftList] = useState([]);

  const history = useHistory();

  const editDraft = useCallback(
    (advertisingId) => {
      const query = new URLSearchParams({
        type: "edit",
        advertisingId,
      }).toString();

      history.push("/CommodityPromotion?" + query);
    },
    [history]
  );

  const getAdvertisingDraftList = useCallback(() => {
    return Apis.advertising.getAdvertisingDraftList().then((res) => {
      if (res.code === 1) {
        setDraftList(res.data);
      }
    });
  }, []);

  const handleDelete = useCallback(
    (advertisingIds) => {
      const idsString = [].concat(advertisingIds).join(",");

      return Apis.advertising
        .deleteAdvertisingDraft({
          advertisingIds: idsString,
        })
        .then((res) => {
          if (res.code === 1) {
            message.success("删除成功");
            getAdvertisingDraftList();
          }
        });
    },
    [getAdvertisingDraftList]
  );

  const columns = useMemo(() => {
    return [
      {
        title: "广告活动",
        dataIndex: "advertisingName",
      },
      {
        title: "类型",
        width: "150px",
        dataIndex: "advertisingTypeName",
      },
      {
        title: "状态",
        width: "150px",
        dataIndex: "reviewStatusName",
      },
      {
        title: "操作",
        width: "10%",
        render(_, record) {
          return (
            <Space>
              <AButton
                type="link fs-12 h-12 p-0"
                onClick={() => editDraft(record.advertisingId)}
              >
                编辑
              </AButton>
              <AButton
                type="link fs-12 h-12 p-0"
                onClick={() => handleDelete(record.advertisingId)}
              >
                删除
              </AButton>
            </Space>
          );
        },
      },
    ];
  }, [editDraft, handleDelete]);

  useEffect(() => {
    getAdvertisingDraftList();
  }, [getAdvertisingDraftList]);

  return (
    <div className="advertising_box bg-white">
      {/* 侧边栏 */}
      <div className="advertising_boxII">
        <Sidebar value={activeAdvertisingId} onChange={setAdvertisingActive} />
      </div>
      <div className="gg5_myrightII p-12">
        <div className="gg5_top_btn mb-12">
          <Link to="/NewAdvertisingCampaign">
            <AButton
              type="primary"
              className="AdvertisingCampaign-list-head-left-button"
            >
              创建广告活动
            </AButton>
          </Link>
        </div>

        <div className="gg5_table pr-24">
          <ATable columns={columns} dataSource={draftList} pagination={false} />
        </div>
      </div>
    </div>
  );
}

export default Advertising;
