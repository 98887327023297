import withProps from "@a-components/withProps";
import { Drawer as AntDrawer } from "antd";

const Drawer = withProps(
  (props) => {
    return <AntDrawer {...props} />;
  },
  {
    className: "a-drawer",
  }
);

export default Drawer;
