import React from 'react'
import './uploadSKU.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import '../default.css/default.css'
import { Link } from 'react-router-dom';


function Early() {
    return (
        <div>
            <Header />
            <div className="CommentatorBox">
                <div className="end06_mybody">
                    <ul className="mybody-ul">
                        <li>
                            <Link to="/Commentator">早期评论者计划</Link>
                        </li>
                        <li>
                            <Link to="/RegCommentator">注册加入计划</Link>
                        </li>
                        <li>
                            <Link to="/Early">控制面板</Link>
                        </li>
                        <li>常见问题</li>
                    </ul>
                </div>
                <div className="a-section">
                    <h1 className="aok-inline-block">上传 SKU 进行注册</h1>
                </div>
                <div className="skutitle">
                    欢迎访问早期评论者计划门户。早期评论者计划是一个鼓励已购买某款商品的买家通过评论来分享其真实体验的新计划。此计划旨在帮助销售合作伙伴获得早期评论，这有助于买家做出更明智的购买决定，并且可以提高页面浏览量、搜索点击率以及销售额。要了解有关此计划的更多信息，请访问 帮助页面。
                    要开始加入此计划，只需按照以下说明上传符合条件的商品即可：
                </div>
                <div>
                    <div className="a-box-inner a-padding-small"> 1.准备 CSV 模板</div>
                    <div className="oneBox">
                        <p>开始下载下面的 CSV 模板。输入您的 SKU，并将文件保存为 .csv 文件。
                            <b>请注意，您必须在上传的文件中保留“SKU”标题行。</b>
                            您一次最多可上传 1000 个 SKU。商品资格标准是<br />：
                            <ol>
                                <li>网站上评论必须少于 5 条。</li>
                                <li>SKU 必须是父级或独立 SKU。不允许使用变体。子 SKU 将自动随父 SKU 一起注册。</li>
                                <li>每种商品的报价必须高于 9 美元。如果报价低于 9 美元，我们可能会停止征集买家评论。</li>
                            </ol>
                        </p>
                        <div className="oneColumn">下载模板</div>
                    </div>
                </div>
                <div className="twoBox">
                    <div className="a-box-inner a-padding-small">2.向早期评论者计划上传商品</div>
                    <div className="oneBox">
                        <p style={{ lineHeight: '40px' }}>
                            您同意为注册加入此计划的每个合格父 SKU（或独立 SKU）支付 60 美元以及所有适用税费。注册完成后，您将无法从此计划中移除 SKU。<br />
                            此外,
                            <b>请注意，您必须在上传的文件中保留“SKU”标题行。</b>
                            您同意为注册加入此计划的每个合格父 SKU（或独立 SKU）支付 60 美元以及所有适用税费。注册完成后，您将无法从此计划中移除 SKU。
                        </p>
                        <div className="fileClass">找到要上传的 CSV 文件：<input accept=".csv" type="file" /></div>
                        <div className="oneColumn fileBtn">立即上传</div>
                    </div>
                </div>
                <div className="twoBox">
                    <div className="a-box-inner a-padding-small"> 3.刷新提交状态</div>
                    <div className="oneBox">
                        <p style={{ lineHeight: '40px' }}>上传完成时，您的文件将出现在下面的“提交历史记录”部分，该文件将提供注册详情。上传最长可能需要 15 分钟的处理时间。</p>
                        <div className="oneColumn">刷新页面</div>
                    </div>
                </div>
                <div className="a-section a-spacing-medium fourBox">
                    <h2>提交历史记录和商品注册详情</h2>
                    <table border="1" cellspacing="0" cellpadding="0" bordercolor="#EEEEEE">
                        <tr className="mytr">
                            <th>提交日期</th>
                            <th>文件名</th>
                            <th>可接受产品数量</th>
                            <th>未接受产品数量</th>
                            <th>上传总计</th>
                        </tr>
                        <tr className="mytr2">
                            <td>2021-02-23T00:56:56.674</td>
                            <td>EarlyReviewerTemplate.csv</td>
                            <td>0</td>
                            <td className="xlcor">0</td>
                            <td>0</td>
                        </tr>
                        <tr className="mytr2">
                            <td>2021-02-23T00:56:56.674</td>
                            <td>EarlyReviewerTemplate.csv</td>
                            <td>0</td>
                            <td className="xlcor">0</td>
                            <td>0</td>
                        </tr>
                        <tr className="mytr2">
                            <td>2021-02-23T00:56:56.674</td>
                            <td>EarlyReviewerTemplate.csv</td>
                            <td>0</td>
                            <td className="xlcor">0</td>
                            <td>0</td>
                        </tr>
                    </table>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default Early