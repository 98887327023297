import { uglifyValue } from "@utils/utils";
export const negateKeywordMatchTypeMapping = {
  1: "否定精准",
  2: "否定词组",
};

export const negateKeywordMatchTypeOptions = [
  {
    label: "否定精准",
    value: 1,
  },
  {
    label: "否定词组",
    value: 2,
  },
];

export const negateKeywordInitValues = {
  negateKeywordMatchType: 1,
  selectedNegateKeywordList: [
    // {
    //   keywords: "123123",
    //   matchType: 2,
    //   matchTypeName: "否定词组",
    // },
    // {
    //   keywords: "2333",
    //   matchType: 1,
    //   matchTypeName: "否定精准",
    // },
  ],
};

export const computedNegateKeywordId = ({ keywords, matchType }) => {
  return uglifyValue({ keywords, matchType });
};
