import React, { useRef, useEffect, useCallback, useState } from 'react';
import AJAX from "api/apis";
import './sellerInfo.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"
import '../../default.css/default.css'
import RegisterSteps from 'components/registerSteps';
import RegisterCollapse from 'components/registerCollapse';
import { Link } from 'react-router-dom';
import { getYear, getMonth, getDay, getYearValidity, getSellerNationality, getIdentityList, getCheckAddressList } from 'utils/regDate';
import { Button, Collapse, Steps, Radio, Input, Form, Select, Modal, Checkbox, Popover } from 'antd';
import { regSubmit, getStoreRegisterInfo } from 'utils/PublicLibrary';
import { QuestionCircleFilled } from '@ant-design/icons';


const { Panel } = Collapse;
const { Step } = Steps;
export default (props) => {
    let formRef = useRef();
    let formRef1 = useRef();
    let [isShowAddress, setIsShowAddress] = useState(false)
    let [verifyTips, setVerifyTips] = useState(false)
    let [verify, setVerify] = useState(false)
    let [isShowPhone, setIsShowPhone] = useState(false)
    let [phonePrefix, setPhonePrefix] = useState('+86')
    let [isCodeVisible, setIsCodeVisible] = useState(false)
    let [phoneCode, setPhoneCode] = useState(false)
    let [randoms, setRandoms] = useState();
    let [isCodeShow, setIsCodeShow] = useState();
    let [address, setAddress] = useState("")
    let [userName, setUserName] = useState("")
    let [showName, setShowName] = useState(false)
    let [phone, setPhone] = useState()
    let [isItValid, setIsItValid] = useState(false)
    let [sellerShow1, setsellerShow1] = useState(false)
    let [sellerShow2, setsellerShow2] = useState(false)
    const [isTourOpen, setIsTourOpen] = useState(true);
    let [isReturn, setIsReturn] = useState(props.location?.state?.isReturn || 0);
    const steps = [
        {
            selector: '.stepGuidanceBox0',
            content: '法人的国籍',
        },
        {
            selector: '.stepGuidanceBox1',
            content: '身份证上面的国家/地区',
        },
        {
            selector: '.stepGuidanceBox2',
            content: '身份证上面的出生年月',
        },
        {
            selector: '.stepGuidanceBox3',
            content: '选择护照/身份证',
        },
        {
            selector: '.stepGuidanceBox4',
            content: '证件号/身份证号',
        },
        {
            selector: '.stepGuidanceBox5',
            content: '身份证国徽一面的有效期',
        },
        {
            selector: '.stepGuidanceBox6',
            content: '证件签发国家/地区',
        },
        {
            selector: '.natureBox',
            content: '身份证上面的名字，填写中文',
        },
        {
            selector: '.stepGuidanceBox8',
            content: '居住地址/可添加新的地址，填写中文',
        },
        {
            selector: '.stepGuidanceBox9',
            content: '法人手机号/可添加新的手机号',
        },
        {
            selector: '.stepGuidanceBox10',
            content: '选择法人/企业受益人',
        },
        {
            selector: '.stepGuidanceBox11',
            content: '选择实际情况',
        }
    ];
    const collapseList = [
        {
            header: '为什么“主要联系人”需要提供他们的个人信息？',
            html: '<p>主要联系人是指有权访问“亚马逊销售”付款帐户，代表帐户持有人（注册卖方）提供注册信息并发起交易（如付款和退款）的人。主要联系人采取的行动被视为帐户持有人采取的行动。</p>'
        },
        {
            header: '谁是“受益人”？',
            html: '<p>受益所有人是通过直接或间接拥有公司超过 25% 或更多股权或表决权而拥有或控制业务的人，或以其他方式对业务管理实施控制的其他任何人。</p> '
        },
        {
            header: '如果我的业务由另一家公司持有该怎么办？',
            html: '<p>如果另一家公司（母公司）在注册业务中拥有足够的股份（25％或以上）或拥有投票权，则必须将母公司的受益所有人添加到受益所有人名单中。您完成注册后，如有需要，我们可能会询问此信息。</p>'
        },
        {
            header: '谁是企业“法人代表”。',
            html: '<p>“公司法人代表”是一个拥有特定权力并获得您的企业合法授权，能够代表企业开展业务（例如接受条款和条件、开设付款账户等）的人。 法人代表可能是（也可能不是）公司的所有人</p>'
        },
        {
            header: '如果我是主要联系人，但不是法人代表，该怎么办？',
            html: '<p>如果注册为主要联系人的人不是法人代表，则已注册公司的法人代表应提供授权书。 此文件授予主要联系人代表公司行事的权利。 需要此文件时，我们会向您发送通知。</p>'
        },
    ]
    const [isActive, setIsActive] = useState(false);
    const awakenTips = (type) => {
        setIsActive(type);
        let content = formRef.current.getFieldsValue();
        if (type) {
            if (content.sellerNationality != '中国') setShowName(true)
        } else {
            if (content.sellerNationality != '中国') setShowName(false)
        }

    }
    const yearList = getYear();
    const monthList = getMonth();
    const dayList = getDay();
    const yearValidityList = getYearValidity();
    const sellerNationality = getSellerNationality()
    const checkAddressList = getCheckAddressList()
    const identityList = getIdentityList()
    let nextEvent = useCallback(() => {
        formRef.current.validateFields().then((res) => {
            if (res.phone == 2 && !verify) {
                setTimeout(() => {
                    setVerifyTips(true);
                }, 300);
                return
            }
            if (res.termMonth && res.termYear) {
                let date = new Date();
                let currentYear = date.getFullYear(); //获取完整的年份(4位)
                let currentMonth = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
                let currentDate = date.getDate();
                let selectMonth = res.termMonth.split("月")[0];
                let selectYear = res.termYear.split("年")[0]
                let selectDay = res.termDays.split("日")[0]
                if (currentYear < selectYear) {
                    setIsItValid(false)
                } else if (currentYear == selectYear) {
                    if (selectMonth < currentMonth) {
                        // if (selectDay < currentDate) {
                        setIsItValid(true)
                        return;
                        // }
                    } else if (selectMonth == currentMonth) {
                        if (selectDay < currentDate) {
                            setIsItValid(true)
                            return;
                        }
                    } else {
                        setIsItValid(false)
                    }
                } else {
                    setIsItValid(true)
                    return;
                }
            }
            let arr = [
                { "propertyKey": "seller_nationality", "propertyValue": res.sellerNationality },
                { "propertyKey": "seller_birth_state", "propertyValue": res.sellerBirthState },
                { "propertyKey": "seller_birth_date", "propertyValue": res.birthYear + "------" + res.birthMonth + "------" + res.birthDays },
                { "propertyKey": "seller_identity_type", "propertyValue": res.identity },
                { "propertyKey": "seller_identity_number", "propertyValue": res.numberId },
                { "propertyKey": "seller_identity_date", "propertyValue": res.termYear + "------" + res.termMonth + "------" + res.termDays },
                { "propertyKey": "seller_identity_state", "propertyValue": res.countryOfIssue },

                { "propertyKey": "seller_dwell_site", "propertyValue": res.address == 2 ? res.detailedAddressD + "------" + res.detailedAddressJ + "------" + res.area + "------" + res.city + "------" + res.province + "------" + res.postalCode + "------" + res.companyAddress : address },
                { "propertyKey": "seller_phone", "propertyValue": res.phone == 1 ? phone : res.verificationArea + "------" + res.jyphone },
                { "propertyKey": "seller_is_enterprise_benefit_person", "propertyValue": res.beneficiary.includes('1') ? 1 : 2 },
                { "propertyKey": "seller_is_enterprise_legal_person", "propertyValue": res.beneficiary.includes('2') ? 1 : 2 },
                { "propertyKey": "seller_is_enterprise_all_benefit_person", "propertyValue": res.beneficiaryS },
                { "propertyKey": "seller_dwell_site_option", "propertyValue": res.address },
                { "propertyKey": "seller_phone_option", "propertyValue": res.phone }
            ]
            if (res.nature && res.name) {
                //res.nature || '' + "------" + res.name || ''
                arr.push({ "propertyKey": "seller_identity_name", "propertyValue": `${res.nature || ''}------${res.name || ''}` })
            }
            regSubmit(arr, null, 'sellerInfo', 4).then((data) => {
                if (data.code == 1) props.history.push('/deposit')
            })
        })
    }, [address, phone, verify])
    useEffect(() => {
        getRegInfo()
        MathRand()
        let step = localStorage.getItem('stepList') || ''
        if (!step.includes('sellerinfo')) {
            setShowName(true)
        }
    }, [])
    let getRegInfo = () => {
        getStoreRegisterInfo().then((res) => {
            let beneficiary = []
            for (let item of res.data) {
                if (item.propertyValue) {
                    if (item.propertyKey == "seller_nationality") {
                        formRef.current.setFieldsValue({
                            sellerNationality: item.propertyValue
                        })
                        setShowName(item.propertyValue == '中国')
                    }
                    if (item.propertyKey == "seller_birth_date") {
                        formRef.current.setFieldsValue({
                            birthYear: item.propertyValue.split("------")[0],
                            birthMonth: item.propertyValue.split("------")[1],
                            birthDays: item.propertyValue.split("------")[2]
                        })
                    }
                    if (item.propertyKey == "seller_birth_state") {
                        formRef.current.setFieldsValue({
                            sellerBirthState: item.propertyValue
                        })
                    }

                    if (item.propertyKey == "seller_identity_type") {
                        formRef.current.setFieldsValue({
                            identity: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "seller_identity_number") {
                        formRef.current.setFieldsValue({
                            numberId: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "seller_identity_state") {
                        formRef.current.setFieldsValue({
                            countryOfIssue: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "seller_identity_date") {
                        formRef.current.setFieldsValue({
                            termYear: item.propertyValue.split("------")[0],
                            termMonth: item.propertyValue.split("------")[1],
                            termDays: item.propertyValue.split("------")[2],
                        })
                    }
                    if (item.propertyKey == "seller_identity_name") {
                        formRef.current.setFieldsValue({
                            nature: item.propertyValue.split("------")[0],
                            name: item.propertyValue.split("------")[1],
                        })
                    }
                    if (item.propertyKey == "seller_dwell_site") {
                        formRef.current.setFieldsValue({
                            companyAddress: item.propertyValue.split("------")[6],
                            detailedAddressJ: item.propertyValue.split("------")[1],
                            detailedAddressD: item.propertyValue.split("------")[0],
                            postalCode: item.propertyValue.split("------")[5],
                            province: item.propertyValue.split("------")[4],
                            city: item.propertyValue.split("------")[3],
                            area: item.propertyValue.split("------")[2],
                        })
                    }
                    if (item.propertyKey == "seller_dwell_site_option") {
                        formRef.current.setFieldsValue({
                            address: item.propertyValue
                        })
                        addressChange(item.propertyValue)
                    }
                    if (item.propertyKey == "seller_phone_option") {
                        formRef.current.setFieldsValue({
                            phone: item.propertyValue,
                        })
                        setIsShowPhone(item.propertyValue == 1 ? false : true)
                    }
                    if (item.propertyKey == "seller_phone") {
                        formRef.current.setFieldsValue({
                            verificationArea: item.propertyValue.includes('------') ? item.propertyValue.split("------")[0] : '中国',
                            jyphone: item.propertyValue.split("------")[1],
                        })
                        // setIsShowPhone(item.propertyValue == 1 ? false : true)
                    }
                    if (item.propertyKey == "seller_is_enterprise_benefit_person") {
                        if (item.propertyValue == 1) beneficiary.push('1')
                    }
                    if (item.propertyKey == "seller_is_enterprise_legal_person") {
                        if (item.propertyValue == 1) beneficiary.push('2')
                    }
                    if (item.propertyKey == "seller_is_enterprise_all_benefit_person") {
                        formRef.current.setFieldsValue({
                            beneficiaryS: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "company_business_site") {
                        // company_business_site
                        setAddress(item.propertyValue)
                    }
                    if (item.propertyKey == "company_legal_person_name") {
                        setUserName(item.propertyValue)
                    }
                    if (item.propertyKey == "company_phone") {
                        setPhone(item.propertyValue)
                    }
                }
            }
            formRef.current.setFieldsValue({
                beneficiary: beneficiary
            })
        })
        AJAX.login.getStoreRegisterInfo([]).then((data) => {
            if (data.code == 1 && data.data) {
                for (let item of data.data) {
                    if (item.propertyValue) {
                        if (item.propertyKey == "company_business_site") {
                            // company_business_site
                            setAddress(item.propertyValue)
                        }
                        if (item.propertyKey == "company_legal_person_name") {
                            setUserName(item.propertyValue)
                        }
                        if (item.propertyKey == "company_phone") {
                            setPhone(item.propertyValue)
                        }
                    }
                }
            }
        })
        if (isReturn) {
            AJAX.login.getStoreRegisterInfo([]).then((data) => {
                let beneficiary = []
                if (data.code == 1 && data.data) {
                    for (let item of data.data) {
                        if (item.propertyValue) {
                            if (item.propertyKey == "seller_nationality") {
                                formRef.current.setFieldsValue({
                                    sellerNationality: item.propertyValue
                                })
                                setShowName(item.propertyValue == '中国')
                            }
                            if (item.propertyKey == "seller_birth_date") {
                                formRef.current.setFieldsValue({
                                    birthYear: item.propertyValue.split("------")[0],
                                    birthMonth: item.propertyValue.split("------")[1],
                                    birthDays: item.propertyValue.split("------")[2]
                                })
                            }
                            if (item.propertyKey == "seller_birth_state") {
                                formRef.current.setFieldsValue({
                                    sellerBirthState: item.propertyValue
                                })
                            }

                            if (item.propertyKey == "seller_identity_type") {
                                formRef.current.setFieldsValue({
                                    identity: item.propertyValue
                                })
                            }
                            if (item.propertyKey == "seller_identity_number") {
                                formRef.current.setFieldsValue({
                                    numberId: item.propertyValue
                                })
                            }
                            if (item.propertyKey == "seller_identity_state") {
                                formRef.current.setFieldsValue({
                                    countryOfIssue: item.propertyValue
                                })
                            }
                            if (item.propertyKey == "seller_phone_option") {
                                formRef.current.setFieldsValue({
                                    phone: item.propertyValue,
                                })
                                setIsShowPhone(item.propertyValue == 1 ? false : true)
                            }
                            if (item.propertyKey == "seller_phone") {
                                formRef.current.setFieldsValue({
                                    verificationArea: item.propertyValue.includes('------') ? item.propertyValue.split("------")[0] : '中国',
                                    jyphone: item.propertyValue.split("------")[1],

                                })
                                // setIsShowPhone(item.propertyValue == 1 ? false : true)
                            }
                            if (item.propertyKey == "seller_identity_date") {
                                formRef.current.setFieldsValue({
                                    termYear: item.propertyValue.split("------")[0],
                                    termMonth: item.propertyValue.split("------")[1],
                                    termDays: item.propertyValue.split("------")[2],
                                })
                            }
                            if (item.propertyKey == "seller_identity_name") {
                                formRef.current.setFieldsValue({
                                    nature: item.propertyValue.split("------")[0] || '',
                                    name: item.propertyValue.split("------")[1] || '',
                                })
                            }
                            if (item.propertyKey == "seller_dwell_site") {
                                formRef.current.setFieldsValue({
                                    companyAddress: item.propertyValue.split("------")[6],
                                    detailedAddressJ: item.propertyValue.split("------")[1],
                                    detailedAddressD: item.propertyValue.split("------")[0],
                                    postalCode: item.propertyValue.split("------")[5],
                                    province: item.propertyValue.split("------")[4],
                                    city: item.propertyValue.split("------")[3],
                                    area: item.propertyValue.split("------")[2],
                                })
                            }
                            if (item.propertyKey == "seller_dwell_site_option") {
                                formRef.current.setFieldsValue({
                                    address: item.propertyValue
                                })
                                addressChange(item.propertyValue)
                            }
                            if (item.propertyKey == "seller_is_enterprise_benefit_person") {
                                if (item.propertyValue == 1) beneficiary.push('1')
                            }
                            if (item.propertyKey == "seller_is_enterprise_legal_person") {
                                if (item.propertyValue == 1) beneficiary.push('2')
                            }
                            if (item.propertyKey == "seller_is_enterprise_all_benefit_person") {
                                formRef.current.setFieldsValue({
                                    beneficiaryS: item.propertyValue
                                })
                            }
                            if (item.propertyKey == "company_business_site") {
                                // company_business_site
                                setAddress(item.propertyValue)
                            }
                            if (item.propertyKey == "company_legal_person_name") {
                                setUserName(item.propertyValue)
                            }
                            if (item.propertyKey == "company_phone") {
                                setPhone(item.propertyValue)
                            }
                        }
                    }
                }
                formRef.current.setFieldsValue({
                    beneficiary,
                })
            })
        }
    }
    let MathRand = useCallback(() => {
        let Num = "";
        for (let i = 0; i < 6; i++) {
            Num += Math.floor(Math.random() * 10);
        }
        setRandoms(Num)
    })
    let addressChange = useCallback((e) => {
        let content = formRef.current.getFieldsValue();
        if (content.address == 2) {
            setIsShowAddress(true)
        } else {
            setIsShowAddress(false)
        }
    }, [])
    let addressPhone = useCallback((e) => {
        let content = formRef.current.getFieldsValue();
        if (content.phone == 2) {
            setIsShowPhone(true)
        } else {
            setIsShowPhone(false)
        }
    }, [])
    let showCodeVisible = useCallback(() => {
        let FieldsValue = formRef.current.getFieldsValue()
        if (!FieldsValue.jyphone) {
            formRef.current.validateFields(['jyphone'])
        } else {
            MathRand()
            setIsCodeShow(true)
            setIsCodeVisible(true)
        }
    }, [])
    let VerifyCode = useCallback(() => {
        formRef1.current.validateFields().then((res) => {
            setVerifyTips(false)
            setVerify(true)
            setIsCodeVisible(false)
        })
    }, [])
    const hideModalVisible = useCallback(() => {
        setIsCodeVisible(false)
    })
    const hideModalVisibleCode = useCallback(() => {
        setPhoneCode(false)
    })
    let languageSwitching = useCallback((e) => {
        setPhonePrefix(e.split(",")[1])
    }, [])
    let emailModal = useCallback(() => {
        setPhoneCode(true)
    }, [])
    const hide = useCallback((type, v) => {
        if (type == '1') {
            setsellerShow1(v)
        } else {
            setsellerShow2(v)
        }
    });
    const tipDom = useCallback((type) => {
        return (
            <div className='seller-tip'>
                {/* <div className='close' onClick={() => { hide(type, false) }}>x</div> */}
                {
                    type == '1' ? (
                        <p>
                            受益所有人是指直接或间接拥有或控制企业超过25%的股份或表决权,或通过其他方式拥有企业的自然人。如果没有个人符合上述标准，那么任何拥有高级经理职位的个人都是受益所有人。
                        </p>
                    ) : (
                        <p>
                            “公司法人代表”是一个拥有特定权力并获得您的企业合法授权，能够代表企业开展业务(例如接受条款和条件、开设付款账户等)的人。法人代表可能;是(也可能不是)公司的所有人。

                        </p>
                    )
                }
            </div>
        )
    }, [sellerShow1, sellerShow2])
    return (
        <div className="sellerInfoBox register_style">
            {/* 引导步骤 */}
            <TipsIcon clicks={awakenTips} />
            <Reactours routePath="sellerinfo" isActive={isActive} clicks={awakenTips} steps={steps} isOpen={isTourOpen} />
            <Header />
            <div style={{ display: 'flex' }}>
                <div className="a-section program-overview-values">
                    <RegisterSteps current={2} />
                    <div className="CompanyAddress">
                        <Form ref={formRef}
                            initialValues={{
                                address: "1",
                                phone: '1',
                            }}
                        >
                            <div>
                                <p style={{ fontWeight: 'bold' }}>个人信息：{userName.replace(/------/g, "")}</p>
                                <div className='kat-divider'></div>
                                <div>
                                    <div className="inpBox stepGuidanceBox0">
                                        <p style={{ fontWeight: 'bold' }}>国籍</p>
                                        <Form.Item name="sellerNationality" rules={[{ required: true, message: '请选择国家/地区' }]}>
                                            <Select placeholder="选择国家/地区" onChange={(e) => {
                                                setShowName(e == '中国')
                                            }} options={sellerNationality} />
                                        </Form.Item>
                                    </div>
                                    <div className="inpBox inpBox1">
                                        <div className="stepGuidanceBox1">
                                            <p>出生地</p>
                                            <Form.Item name="sellerBirthState" rules={[{ required: true, message: '请选择国家/地区' }]}>
                                                <Select placeholder="选择国家/地区" options={sellerNationality} />
                                            </Form.Item>
                                        </div>
                                        <div className="dates stepGuidanceBox2">
                                            <p>出生日期</p>
                                            <div>
                                                <div className="birthBox">
                                                    <Form.Item name="birthDays" rules={[{ required: true, message: '请选择天数' }]}>
                                                        <Select placeholder="天" options={dayList} />
                                                    </Form.Item>
                                                    <Form.Item name="birthMonth" rules={[{ required: true, message: '请选择月份' }]}>
                                                        <Select placeholder="月" options={monthList} />
                                                    </Form.Item>
                                                    <Form.Item name="birthYear" rules={[{ required: true, message: '请选择年份' }]}>
                                                        <Select placeholder="年" options={yearList} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="inpBox inpBox1">
                                        <div>
                                            <p style={{ fontWeight: 'bold' }}>身份证明</p>
                                            <div className="yanzPhone">
                                                <Form.Item className="stepGuidanceBox3" initialValue='身份证' name="identity" rules={[{ required: true, message: '请选择身份证明' }]}>
                                                    <Select placeholder="身份证明" options={identityList} />
                                                </Form.Item>
                                                <Form.Item
                                                    className="stepGuidanceBox4"
                                                    name="numberId"
                                                    rules={[{
                                                        required: true,
                                                        validator: (rule, value, fun) => {
                                                            let reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
                                                            if (!value) {
                                                                fun('请输入身份证明编号')
                                                            } else if (!reg.test(value)) {
                                                                fun('身份证明编号无效')
                                                            }
                                                            fun()
                                                        }
                                                    }]}
                                                >
                                                    <Input placeholder="编号" maxLength="18" />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="dates stepGuidanceBox5">
                                            <p>有效期</p>
                                            <div>
                                                <div className="termBox">
                                                    <Form.Item name="termDays" rules={[{ required: true, message: '请选择天数' }]}>
                                                        <Select placeholder="天" options={dayList} />
                                                    </Form.Item>
                                                    <Form.Item name="termMonth" rules={[{ required: true, message: '请选择月份' }]}>
                                                        <Select placeholder="月" options={monthList} />
                                                    </Form.Item>
                                                    <Form.Item name="termYear" rules={[{ required: true, message: '请选择年份' }]}>
                                                        <Select placeholder="年" options={yearValidityList} />
                                                    </Form.Item>
                                                </div>
                                                {isItValid ? <div style={{ color: '#ff4d4f' }}>有效期无效</div> : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="inpBox">
                                        <p style={{ fontWeight: 'bold' }}>签发国</p>
                                        <Form.Item className="stepGuidanceBox6" name="countryOfIssue" rules={[{ required: true, message: '请选择国家/地区' }]}>
                                            <Select placeholder="选择国家/地区" options={sellerNationality} />
                                        </Form.Item>
                                    </div>
                                </div>
                                {
                                    showName && (
                                        <div className="inpBox">
                                            <p style={{ fontWeight: 'bold', marginBottom: 0 }}>身份证上显示的名称</p>
                                            <div className="flex0 natureBox">
                                                <Form.Item name="nature" rules={[{
                                                    required: true,
                                                    validator: (rule, value, fun) => {
                                                        let reg = /[\u4e00-\u9fa5]+/g;
                                                        if (!value) {
                                                            fun('请输入有效姓氏')
                                                        } else if (!reg.test(value)) {
                                                            fun('请用简体中文输入')
                                                        }
                                                        fun()
                                                    }
                                                }]}>
                                                    <Input placeholder="姓" maxLength="10" />
                                                </Form.Item>
                                                <Form.Item name="name" rules={[{
                                                    required: true, validator: (rule, value, fun) => {
                                                        let reg = /[\u4e00-\u9fa5]+/g;
                                                        if (!value) {
                                                            fun('请输入有效名字')
                                                        } else if (!reg.test(value)) {
                                                            fun('请用简体中文输入')
                                                        }
                                                        fun()
                                                    }
                                                }]}>
                                                    <Input placeholder="名" maxLength="10" />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    )
                                }


                                <div className='kat-divider'></div>
                                <div className="receivingMode">
                                    <p>居住地址</p>
                                    <Form.Item className="stepGuidanceBox8" name="address" onChange={addressChange} rules={[{ required: true, message: '请选择居住地址' }]}>
                                        <Radio.Group defaultValue="1">
                                            <Radio value="1">{address.replace(/------/g, "")}</Radio>
                                            <div></div>
                                            <Radio style={{ color: '#0066c0' }} value="2">添加其他地址</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    {isShowAddress ? <div>
                                        <div className="inpBox1">
                                            <Form.Item name="companyAddress" rules={[{ required: true, message: '请选择国家/地区' }]}>
                                                <Select placeholder="选择国家/地区" options={sellerNationality} />
                                            </Form.Item>
                                            <Form.Item
                                                name="postalCode"
                                                rules={[{
                                                    required: true,
                                                    validator: (rule, value, fun) => {
                                                        let reg = /^[1-9][0-9]{5}$/;
                                                        if (!value) {
                                                            fun('请输入邮政编码')
                                                        } else if (!reg.test(value)) {
                                                            fun('邮政编码验证失败')
                                                        }
                                                        fun()
                                                    }
                                                }]}>
                                                <Input placeholder="邮政编码" maxLength="6" />
                                            </Form.Item>
                                        </div>
                                        <div className="inpBox1">
                                            <Form.Item name="province" rules={[{
                                                required: true,
                                                validator: (rule, value, fun) => {
                                                    let reg = /[\u4E00-\u9FA5\uFE30-\uFFA0]/;
                                                    if (!value) {
                                                        fun('请输入省/自治区/直辖市')
                                                    } else if (!reg.test(value)) {
                                                        fun('请用中文填写')
                                                    }
                                                    fun()
                                                }
                                            }]}>
                                                <Input placeholder="省/自治区/直辖市" maxLength="50" />
                                            </Form.Item>
                                            <Form.Item name="city" rules={[{
                                                required: true,
                                                validator: (rule, value, fun) => {
                                                    let reg = /[\u4E00-\u9FA5\uFE30-\uFFA0]/;
                                                    if (!value) {
                                                        fun('请输入城市')
                                                    } else if (!reg.test(value)) {
                                                        fun('请用中文填写')
                                                    }
                                                    fun()
                                                }
                                            }]}>
                                                <Input placeholder="城市" maxLength="50" />
                                            </Form.Item>
                                        </div>
                                        <div className="inpBox1">
                                            <Form.Item name="area" rules={[{
                                                required: true,
                                                validator: (rule, value, fun) => {
                                                    let reg = /[\u4E00-\u9FA5\uFE30-\uFFA0]/;
                                                    if (!value) {
                                                        fun('请输入区')
                                                    } else if (!reg.test(value)) {
                                                        fun('请用中文填写')
                                                    }
                                                    fun()
                                                }
                                            }]}>
                                                <Input placeholder="区" maxLength="50" />
                                            </Form.Item>
                                            <Form.Item name="detailedAddressJ" rules={[{
                                                required: true,
                                                validator: (rule, value, fun) => {
                                                    let reg = /[\u4E00-\u9FA5\uFE30-\uFFA0]/;
                                                    if (!value) {
                                                        fun('请输入详细地址，如道路、街道、门牌号等')
                                                    } else if (!reg.test(value)) {
                                                        fun('请用中文填写')
                                                    }
                                                    fun()
                                                }
                                            }]}>
                                                <Input placeholder="详细地址，如道路、街道、门牌号等" maxLength="100" />
                                            </Form.Item>
                                        </div>
                                        <div className="inpBox1">
                                            <Form.Item name="detailedAddressD" rules={[{
                                                required: true,
                                                validator: (rule, value, fun) => {
                                                    let reg = /[\u4E00-\u9FA5\uFE30-\uFFA0]/;
                                                    if (!value) {
                                                        fun('请输入详细地址，如大厦、楼层、房间号等')
                                                    } else if (!reg.test(value)) {
                                                        fun('请用中文填写')
                                                    }
                                                    fun()
                                                }
                                            }]}>
                                                <Input placeholder="详细地址，如大厦、楼层、房间号等" maxLength="100" />
                                            </Form.Item>
                                        </div>
                                    </div> : null}
                                </div>
                                <div className='kat-divider'></div>
                                <div className="receivingMode">
                                    <p>手机号码</p>
                                    <Form.Item className="stepGuidanceBox9" name="phone" rules={[{ required: true, message: '请选择手机号码' }]}>
                                        <Radio.Group defaultValue="1" onChange={addressPhone}>
                                            <Radio value="1">+86 {phone}</Radio>
                                            <div></div>
                                            <Radio value="2" style={{ color: '#0066c0' }}>添加新的手机号码</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    {isShowPhone ? <div>
                                        <p>用于验证的电话号码</p>
                                        <div className="yanzPhone">
                                            <Form.Item name="verificationArea" rules={[{ required: true, message: '请选择选择国家/地区' }]}>
                                                <Select onChange={languageSwitching} placeholder="选择国家/地区" value="中国" options={checkAddressList} />
                                            </Form.Item>
                                            <Form.Item name="jyphone" rules={[{
                                                required: true,
                                                validator: (rule, value, fun) => {
                                                    let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                                                    if (!value) {
                                                        fun('请输入电话号码')
                                                    } else if (!reg.test(value)) {
                                                        fun('手机号码格式验证失败')
                                                    }
                                                    fun()
                                                }
                                            }]}>
                                                <Input prefix={phonePrefix} maxLength="11" />
                                            </Form.Item>
                                            <div style={{ width: '50%' }}>
                                                <Button className='next' onClick={showCodeVisible} type="primary"> 发送短信 </Button>
                                                {verifyTips ? <p style={{ marginLeft: '2%', color: '#ff4d4f' }}>请验证手机号码</p> : null}
                                            </div>
                                            {/* <Button className='next' onClick={showCodeVisible} type="primary"> 发送短信 </Button> */}
                                        </div>
                                        <p style={{ color: '#565959' }}>示例：+86 133 4546 6455</p>
                                    </div> : null}
                                </div>
                                <div className='kat-divider'></div>
                                <div className="receivingMode">
                                    <p>{userName.replace(/------/g, "") || ''}</p>
                                    <Form.Item className="stepGuidanceBox10" name="beneficiary" rules={[{ required: true, message: '请选择企业角色' }]}>
                                        <Checkbox.Group>
                                            <div>
                                                <Checkbox value="1" style={{ lineHeight: '32px' }}>
                                                    是企业的受益所有人
                                                    <Popover
                                                        content={tipDom('1')}
                                                        trigger="hover"
                                                        visible={sellerShow1}
                                                        onVisibleChange={(e) => { hide('1', e) }}
                                                    >
                                                        <QuestionCircleFilled />
                                                    </Popover>

                                                </Checkbox>
                                            </div>
                                            <Checkbox value="2" style={{ lineHeight: '32px' }}>
                                                是企业的法人代表
                                                <Popover
                                                    content={tipDom('2')}
                                                    trigger="hover"
                                                    visible={sellerShow2}
                                                    onVisibleChange={(e) => { hide('2', e) }}
                                                >
                                                    <QuestionCircleFilled />
                                                </Popover>
                                            </Checkbox>
                                        </Checkbox.Group>
                                    </Form.Item>
                                </div>
                                <div className="receivingMode">
                                    <p>我是企业唯一的受益所有人。</p>
                                    <Form.Item className="stepGuidanceBox11" name="beneficiaryS" rules={[{ required: true, message: '请选择是否添加公司所有受益人' }]}>
                                        <Radio.Group>
                                            <Radio value="1">是</Radio>
                                            <Radio value="2">否</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <div className='kat-divider'></div>
                                </div>
                                <Form.Item
                                    label=""
                                    valuePropName="checked"
                                    name="agreem"
                                    rules={[
                                        {
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject(new Error('! 请确认您的选择是否正确')),
                                        },
                                    ]}
                                >
                                    <Checkbox>我确认代表自己或注册企业行事，并承诺在发生更改时更新受益所有人信息。</Checkbox>
                                </Form.Item>
                                <div className='kat-divider'></div>
                                <div className="operationBtn" style={{ textAlign: 'center' }}>
                                    <Button className='previousPage' type="primary">
                                        <Link to={{ pathname: '/enterprise', state: { isReturn: 1 } }}>上一页</Link>
                                    </Button>
                                    <Button onClick={nextEvent} className='next' type="primary">保存</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                    {isCodeShow ? <div className="elailTips" onClick={emailModal}>
                        <img src={require("@/assets/img/register/phone_pinComing.png").default} />
                    </div> : null}
                </div>
                <div className="rightCollapse">
                    <p >常见问题</p>
                    <RegisterCollapse list={collapseList} />
                </div>
            </div >
            <Modal className="codeModalBox" maskClosable={true} visible={isCodeVisible} footer={null} onCancel={hideModalVisible}>
                <p className="codeTitle">输入通过 SMS 收到的 PIN</p>
                <div className="codeContentBox">
                    <p>一次性 PIN</p>
                    <div className="verificationBox">
                        <Form ref={formRef1}>
                            <Form.Item name="verificationCode" rules={[{ required: true, validator: (rule, value, cbfn) => { if (!value) { return Promise.reject('请输入一次性验证码'); } else if (value != randoms) { return Promise.reject('验证码错误'); } return Promise.resolve(); } }]}>
                                <Input />
                            </Form.Item>
                        </Form>
                        <button onClick={VerifyCode} className="VerifyBtn">验证</button>
                    </div>
                    <p>是更改号码还是希望我们改用电话联系？ <span style={{ color: '#4f7dd1', cursor: 'pointer' }} onClick={() => { setIsCodeVisible(false) }}>取消</span></p>
                </div>
            </Modal>
            <Modal title="验证码" className="meailModalBox" centered visible={phoneCode} footer={null} onCancel={hideModalVisibleCode}>
                <p className="otp">{randoms}</p>
                <p>【亚马逊】Message from Amazon:Your one-time PIN is 324560</p>
            </Modal>
            <Footer />
        </div >
    )
}