import withProps from "@a-components/withProps";
import { Radio as AntRadio } from "antd";
import { renderTooltip } from '@a-components/viewFragment'
import classNames from "classnames";
import "./style.css";

const Radio = withProps(
  ({
    style,
    className,
    extra,
    description,
    tooltip,
    children,
    ...otherProps
  }) => {
    return (
      <div style={style} className={classNames(className, "p-r")}>
        <AntRadio {...otherProps}>
          {children}
          {renderTooltip(tooltip)}
          {description ? (
            <>
              <br />
              <div className="description">{description}</div>
            </>
          ) : null}
        </AntRadio>
        <div className="extra p-a r-0 t-0">{extra}</div>
      </div>
    );
  },
  {
    className: "a-radio",
  }
);

export default Radio;
