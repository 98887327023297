import React, { useState } from "react"
import AJAX from "api/apis";
import "./OrderForm.css"
import { Link } from 'react-router-dom';
import IconFont from "../../../../components/Icon/Icon";
import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"
import { Select, Radio, Input, Form, Pagination, Checkbox, Modal, Button, message, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
// import Form from "antd/lib/form/Form";
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export const debounce = (fn, wait) => {
    var timeout = null;
    return function (e) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            fn.apply(this, arguments);
        }, wait);
    };
}

class OrderForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                ManageInventoryList: []
            },
            goodsId: null,
            spareList: [],
            statusValue: null,
            typeValue: null,
            keyWord: null,
            page: 1,
            size: 10,
            total: 10,
            checkedList: [],
            indeterminate: true,
            checkAll: false,
            goodsTotal: 0,
            isModalVisible: false,
            isTourOpen: true,
            steps1: [
                {
                    selector: '.orderFormBox .manageInventory-Search',
                    content: '用SKU、ASIN、标题等搜索需要发货商品',
                },
                {
                    selector: '.orderFormBox .manage-orderForm-dingDan',
                    content: '勾选选定商品，发货的商品，必须要跟操作资料中的对应',
                }
            ],
            isActive: false,
            deleteGoodsLoading: true,
            tableselectValue: "/createListing"
        }
    }
    componentWillMount() {
        this.getAllGoodsList(true, null, this.state.typeValue, this.state.statusValue, this.state.keyWord);
        window.addEventListener('beforeunload', this.beforeunload);
    }
    componentWillUnmount() {
        // 销毁拦截判断是否离开当前页面
        window.removeEventListener('beforeunload', this.beforeunload);
    }
    beforeunload(event) { }
    awakenTips = (type) => {
        this.setState({
            isActive: type
        })
    }
    getAllGoodsList(goodsIsMaster, goodsMasterId = null, goodsDispatchingType = null, goodsStatus = null, goodsNameGoodsSkuGoodsAsinGoodsIsbn = null, page = this.state.page, size = this.state.size) {
        AJAX.addProduct.getAllGoodsList({ goodsMasterId, page, size, goodsDispatchingType, goodsStatus, goodsNameGoodsSkuGoodsAsinGoodsIsbn }).then((res) => {
            if (res.code == 1 && res.data) {
                this.getAllGoodsCount();
                let datas = res.data.list
                for (let items of datas) {
                    items.plainOptions = [];
                    items.plainOptions.push(items.goodsId.toString());
                    items.isShow = false;
                    items.isChangedata = false;
                    items.selectDefault = '编辑'
                }
                let arr = this.state.spareList.concat(datas);
                for (var i = 0; i < arr.length; i++) {
                    for (var j = i + 1; j < arr.length; j++) {
                        if (arr[i].goodsId == arr[j].goodsId) {         //第一个等同于第二个，splice方法删除第二个
                            arr.splice(j, 1);
                            j--;
                        }
                    }
                }
                this.setState({
                    data: {
                        ...this.state.data,
                        ManageInventoryList: datas,
                    },
                    spareList: arr,
                    page: res.data.pageNum,
                    total: res.data.total
                })
            }
        })
    }
    getAllGoodsCount = () => {
        AJAX.addProduct.getAllGoodsCount().then((res) => {
            if (res.code == 1) {
                this.setState({
                    goodsTotal: res.data
                })
            }
        })
    }
    statusChonge = (e) => {
        let { keyWord, typeValue } = this.state
        if (e.target.value == 0) {
            this.getAllGoodsList(true, null, typeValue, null, keyWord)
        } else {
            this.getAllGoodsList(true, null, typeValue, e.target.value, keyWord)
        }
        this.setState({
            statusValue: e.target.value
        })
    }
    typeChonge = (e) => {
        let { keyWord, statusValue } = this.state
        if (e.target.value == null) {
            this.getAllGoodsList(true, null, null, statusValue, keyWord)
        } else {
            this.getAllGoodsList(true, null, e.target.value, statusValue, keyWord)
        }
        this.setState({
            typeValue: e.target.value
        })
    }
    inputChange = debounce((e) => {
        let { typeValue, statusValue } = this.state
        let content = this.form.getFieldsValue()
        this.getAllGoodsList(true, null, typeValue, statusValue, content.inputValue)
        this.setState({
            keyWord: content.inputValue
        })
    }, 300)
    getFormRef = el => {
        this.form = el;
    }
    pageChonge = (page, pageSize) => {
        let { typeValue, statusValue, keyWord } = this.state
        this.setState({
            page: page,
            size: pageSize
        })
        this.getAllGoodsList(true, null, typeValue, statusValue, keyWord, page, pageSize)
    }
    selectChonge = (e, item) => {
        let { ManageInventoryList } = this.state.data
        // console.log(e)
        if (e == '保存') {
            let obj = [
                {
                    id: item.goodsId
                }
            ]
            if (item.goodsEnterpriseMoney != 0 && item.goodsEnterpriseMoney) obj[0].goodsEnterpriseMoney = Number(item.goodsEnterpriseMoney)
            if (item.goodsInventoryCount != 0 && item.goodsInventoryCount) obj[0].goodsInventory = Number(item.goodsInventoryCount)
            if (item.goodsMoney != 0 && item.goodsMoney) obj[0].goodsMoney = Number(item.goodsMoney)
            AJAX.addProduct.upGoodsInventoryAndMoney(obj).then((res) => {
                if (res.code == 1) {
                    window.location.replace("/inventory")
                }
            })
        } else if (e != '...') {
            if (e == 9) {
                // deleteGoods
                this.setState({
                    goodsId: item.goodsId,
                    isModalVisible: true
                })
            } else {
                if (e == '/fbaInbound') {
                    let goodsList = {
                        list: []
                    }
                    goodsList.list.push(item)
                    sessionStorage.setItem('goodsList', JSON.stringify(goodsList))
                    this.props.props.history.push({
                        pathname: '/fbaInbound',
                        search: `?type=${1}`,
                        state: {
                            type: 1
                        }
                    })
                } else {
                    this.props.props.history.push({
                        pathname: e,
                        search: `?goodsId=${item.goodsId}&classifyId=${item.classifyId}`,
                        state: {
                            goodsId: item.goodsId,
                            classifyId: item.classifyId
                        }
                    })
                }
            }
        }

    }
    pathChange = (e) => {
        let { checkedList, spareList } = this.state
        let type = 1;
        if (e.target.value != '...') {
            let goodsList = {}
            let list = []
            for (let checkedItem of checkedList) {
                for (let items of spareList) {
                    if (checkedItem == items.goodsId) {
                        list.push(items)
                    }
                }
            }
            goodsList.list = list;
            sessionStorage.setItem('goodsList', JSON.stringify(goodsList))
            if (e.target.value == "2") {
                let isytuer = goodsList.list.every((item) => {
                    return item.goodsDispatchingName == '亚马逊'
                })
                if (isytuer) {
                    this.props.props.history.push('/deliverGoods')
                } else {
                    this.props.props.history.push({
                        pathname: '/fbaInbound',
                        search: `?type=${1}`,
                        state: {
                            type: 1
                        }
                    })
                }
            } else {
                if (e.target.value == "0") {
                    type = 1
                } else if (e.target.value == "1") {
                    type = 2
                }
                this.props.props.history.push({
                    pathname: '/fbaInbound',
                    search: `?type=${1}`,
                    state: {
                        type: type
                    }
                })

            }
        }
    }
    checkboxChange = (e, item) => {
        let { checkedList, indeterminate, checkAll } = this.state
        let { ManageInventoryList } = this.state.data
        if (e.length == 0) {
            for (let items in checkedList) {
                if (checkedList[items] == item.goodsId) {
                    checkedList.splice(items, 1)
                } else {
                    if (e[0]) {
                        checkedList.push(e[0])
                    }
                }
            }
        } else {
            checkedList.push(e[0])
        }
        indeterminate = !!checkedList.length && checkedList.length < ManageInventoryList.length
        checkAll = checkedList.length === ManageInventoryList.length
        this.setState({
            checkedList: checkedList,
            indeterminate: indeterminate,
            checkAll: checkAll
        })
    };

    onCheckAllChange = e => {
        let { checkedList, checkAll } = this.state
        let { ManageInventoryList } = this.state.data
        let plainOptions = [];
        for (let items of ManageInventoryList) {
            plainOptions.push(items.goodsId.toString())
        }
        checkedList = e.target.checked ? plainOptions : [];
        checkAll = e.target.checked
        this.setState({
            checkedList: checkedList,
            indeterminate: false,
            checkAll: checkAll,
            ManageInventoryList: ManageInventoryList
        })
    };
    variantClick = (item, index) => {
        let { typeValue, statusValue, keyWord, page, size } = this.state;
        let { ManageInventoryList } = this.state.data
        let obj = {
            // goodsIsMaster: false,
            goodsMasterId: item.goodsId,
            page,
            size,
            goodsDispatchingType: typeValue,
            goodsStatus: statusValue,
            goodsNameGoodsSkuGoodsAsinGoodsIsbn: keyWord
        }
        ManageInventoryList[index].isShow = !ManageInventoryList[index].isShow
        if (!ManageInventoryList[index].isShow) {
            ManageInventoryList.splice(index + 1, ManageInventoryList[index].childLength);
            this.setState({
                ManageInventoryList: ManageInventoryList
            })
        } else {
            AJAX.addProduct.getAllGoodsList(obj).then((res) => {
                if (res.code == 1 && res.data) {
                    let datas = res.data.list
                    ManageInventoryList[index].childLength = datas.length
                    for (let items of datas) {
                        items.plainOptions = [];
                        items.plainOptions.push(items.goodsId.toString())
                        items.selectDefault = "编辑"
                    }
                    for (let i = 0; i < datas.length; i++) {
                        ManageInventoryList.splice(index + i + 1, 0, datas[i]);
                    }
                    let arr = this.state.spareList.concat(datas);
                    for (var i = 0; i < arr.length; i++) {
                        for (var j = i + 1; j < arr.length; j++) {
                            if (arr[i].goodsId == arr[j].goodsId) {         //第一个等同于第二个，splice方法删除第二个
                                arr.splice(j, 1);
                                j--;
                            }
                        }
                    }
                    this.setState({
                        ManageInventoryList: ManageInventoryList,
                        spareList: arr

                    })
                }
            })
        }

    }
    inputSumChange = (e, index) => {
        let { ManageInventoryList } = this.state.data
        ManageInventoryList[index].goodsInventoryCount = e.target.value
        ManageInventoryList[index].isChangedata = true
        ManageInventoryList[index].selectDefault = '保存'
        this.setState({
            data: {
                ...this.state.data,
                ManageInventoryList: ManageInventoryList
            },
        })
    }
    goodsMoneyChange = (e, index) => {
        let { ManageInventoryList } = this.state.data
        ManageInventoryList[index].goodsMoney = e.target.value
        ManageInventoryList[index].isChangedata = true
        ManageInventoryList[index].selectDefault = '保存'
        this.setState({
            data: {
                ...this.state.data,
                ManageInventoryList: ManageInventoryList
            },
        })
    }
    goodsEnterpriseMoneyChange = (e, index) => {
        let { ManageInventoryList } = this.state.data
        ManageInventoryList[index].goodsEnterpriseMoney = e.target.value
        ManageInventoryList[index].isChangedata = true
        ManageInventoryList[index].selectDefault = '保存'
        this.setState({
            data: {
                ...this.state.data,
                ManageInventoryList: ManageInventoryList
            },
        })
    }
    ShowSizeChange = (current, size) => {
        let { typeValue, statusValue, keyWord } = this.state
        this.setState({
            page: current,
            size: size
        })
        this.getAllGoodsList(true, null, typeValue, statusValue, keyWord, current, size)
    }
    handleOk = () => {
        let { goodsId, deleteGoodsLoading } = this.state
        let { ManageInventoryList } = this.state.data
        if (deleteGoodsLoading) {
            this.setState({ deleteGoodsLoading: false })
            AJAX.addProduct.deleteGoods({ goodsId }).then(res => {
                if (res.code == 1) {
                    message.success(res.msg)
                    let newArr = ManageInventoryList.filter((item, i, data) => {
                        //函数本身返回布尔值，只有当返回值为true时，当前项存入新数组。
                        return item.goodsId != goodsId
                    })
                    this.setState({
                        data: {
                            ...this.state.data,
                            ManageInventoryList: newArr
                        },
                        isModalVisible: false,
                        deleteGoodsLoading: true,
                    })
                }
            }).catch(err => {
                setTimeout(() => {
                    this.setState({ deleteGoodsLoading: true })
                }, 3000)

            })
        }

    }
    menu = (item) => {
        // console.log(item)
        return (
            <Menu>
                {item.isChangedata ? <Option value={`保存`}>保存</Option> : null}
                <Menu.Item key={`/createListing`} onClick={(e) => { this.selectChonge(e.key, item) }}>编辑</Menu.Item>
                <Menu.Item key="..." >停售商品</Menu.Item>
                <Menu.Item key="...." >管理图片</Menu.Item>
                <Menu.Item key="....." >复制到新的商品</Menu.Item>
                <Menu.Item key="......" >添加其他状况</Menu.Item>
                <Menu.Item key={`/fbaInbound`} onClick={(e) => { this.selectChonge(e.key, item) }}>转换为"亚马逊配送"</Menu.Item>
                <Menu.Item key="......." >匹配最低价</Menu.Item>
                <Menu.Item key="9" onClick={(e) => { this.selectChonge(e.key, item) }}>删除商品和报价</Menu.Item>
                <Menu.Item key="........">合并复制商品</Menu.Item>
            </Menu>
        )
    };
    render() {
        let { data, statusValue, typeValue, keyWord, tableselectValue, isActive, page, total, checkedList, indeterminate, checkAll, goodsTotal, isModalVisible, steps1, isTourOpen } = this.state
        return <div className="orderFormBox">
            <div>
                <div className="manageInventory-header">
                    <li>所有库存</li>
                    <span>|</span>
                    <li>不活动(7)</li>
                    <span>|</span>
                    <li>修复无在售信息的亚马逊库存(1)</li>
                    <span>|</span>
                    <li>修复价格警告(1)</li>
                    <span>|</span>
                    <li>品牌健康度(0)</li>
                    <span>|</span>
                    <li>禁止显示</li>
                    <span>|</span>
                    <li>管理定价</li>
                    <span>|</span>
                    <li>亚马逊存库</li>
                    <span>|</span>
                    <li>货件处理进度</li>
                    <span>|</span>
                    <li>库存控制面板</li>
                    <span>|</span>
                    <li>商品信息质量控制面板</li>
                    <li>新功能</li>
                </div>
                <div className="manageInventory-Title">
                    <div className="manage-Title-left">
                        <div className="manage-Tit-l">管理库存</div>
                        <div className="manage-Tit-r">
                            <span>了解更多信息</span>
                            <span>|</span>
                            <span>浏览</span>
                        </div>
                    </div>
                    <div className="manage-Title-right">
                        <Link to="/addlisting"><button>添加一个商品变体</button></Link>
                        <Link to="/addlisting"><button>添加新商品</button></Link>
                        <button>首选项：隐藏2列</button>
                        <span>新功能</span>
                    </div>
                </div>
                <div className="manageInventory-DropdownSearch">
                    <select onChange={this.pathChange} disabled={checkedList.length > 0 ? false : true}>
                        <option value="...">应用于{checkedList.length}件选定商品</option>
                        <option value="0">转换为"亚马逊配送"</option>
                        <option value="1">转换为"卖家自行配送"</option>
                        <option value="2">发/补货</option>
                        <option value="...">设置补货提醒</option>
                        <option value="...">匹配最低价</option>
                        <option value="...">创建移出订单</option>
                        <option value="...">创建多渠道配送订单</option>
                        <option value="...">打印商品标签</option>
                        <option value="...">停售商品</option>
                        <option value="...">删除商品和报价</option>
                        <option value="...">广告列表</option>
                    </select>
                    <div className="manageInventory-Search">
                        <IconFont type="icon-sousuo" />
                        <Form ref={this.getFormRef} style={{ width: '87%', height: '22px' }} >
                            <Form.Item name="inputValue" >
                                <Input placeholder="搜索 SKU、标题、ISBN" style={{ height: '22px' }} onPressEnter={this.inputChange} />
                            </Form.Item>
                        </Form>
                    </div>
                    <button onClick={this.inputChange}>搜索</button>
                    <div className="manageInventory-Piece">{goodsTotal}件商品</div>
                </div>
                <div className="manageInventory-radio">
                    <div className="manageInventory-screen">筛选条件:</div>
                    <div className="manageInventory-state">
                        商品状态:
                        <Radio.Group onChange={this.statusChonge} defaultValue={0} value={statusValue}>
                            <Radio value={0}>所有</Radio>
                            <Radio value={1}>在售</Radio>
                            <Radio value={2}>不可售</Radio>
                            <Radio value={3}>不完整</Radio>
                            <Radio value={4}>商品信息已删除</Radio>
                        </Radio.Group>
                    </div>
                    <span>|</span>
                    <div className="manageInventory-state">
                        配送类型:
                        <Radio.Group onChange={this.typeChonge} value={typeValue}>
                            <Radio checked value={null}>所有</Radio>
                            <Radio value={0}>亚马逊</Radio>
                            <Radio value={1}>卖家</Radio>
                        </Radio.Group>
                    </div>
                    <span>|</span>
                    <select>
                        <option value="...">筛选其他条件</option>
                        <option value="...">搜索开始日期</option>
                        <option value="...">搜索结束日期</option>
                        <option value="...">价格高于</option>
                        <option value="...">价格低于</option>
                    </select>
                </div>
            </div>
            <div className="manageInventory-orderForm" >
                <div className="manage-orderForm-top">
                    <div className="manage-orderForm-radio manage-orderForm-margin">
                        <Checkbox indeterminate={indeterminate} onChange={this.onCheckAllChange} checked={checkAll} />
                    </div>
                    <div className="manage-orderForm-color manage-orderForm-state manage-orderForm-margin">
                        状态
                    </div>
                    <div className="manage-orderForm-image manage-orderForm-margin">
                        图片
                    </div>
                    <div className="manage-orderForm-condition manage-orderForm-margin">
                        <div>SKU</div>
                        <div className="manage-orderForm-weight">状况</div>
                    </div>
                    <div className="manage-orderForm-serialNumber manage-orderForm-margin">
                        商品编号
                    </div>
                    <div className="manage-orderForm-name manage-orderForm-margin">
                        <div className="manage-orderForm-color">
                            商品名称
                        </div>
                        <div className="manage-orderForm-weight">
                            ASIN
                        </div>
                    </div>
                    <div className="manage-orderForm-date manage-orderForm-margin">
                        <div>创建日期</div>
                        <div className="manage-orderForm-weight">状况更新日期</div>
                    </div>
                    <div className="manage-orderForm-color manage-orderForm-endibility manage-orderForm-margin">
                        可售
                    </div>
                    <div className="manage-orderForm-color manage-orderForm-bePutInStorage manage-orderForm-margin">
                        入库数量
                    </div>
                    <div className="manage-orderForm-color manage-orderForm-doNotSell manage-orderForm-margin">
                        不可售数量
                    </div>
                    <div className="manage-orderForm-color manage-orderForm-reserved manage-orderForm-margin">
                        预留数量
                    </div>
                    <div className="manage-orderForm-estimatedCost manage-orderForm-margin">
                        每件售出商品的预计费用
                    </div>
                    <div className="manage-orderForm-price manage-orderForm-margin">
                        <div className="manage-orderForm-color">
                            价格
                        </div>
                        <div className="manage-orderForm-weight">
                            + 配送费
                        </div>
                    </div>
                    <div className="manage-orderForm-enterprisePrice manage-orderForm-margin">
                        <div>
                            企业商品价格
                        </div>
                        <div className="manage-orderForm-weight">
                            + 配送费
                        </div>
                    </div>
                    <div className="manage-orderForm-reservePrice manage-orderForm-margin">
                        <div>
                            最低价
                        </div>
                        <div className="manage-orderForm-weight">
                            + 配送费
                        </div>
                    </div>
                    <div className="manage-orderForm-salesRank manage-orderForm-margin">
                        销售排名
                    </div>
                    <div className="manage-orderForm-buttonPrice manage-orderForm-margin">
                        <div>
                            购买按钮价格
                        </div>
                        <div className="manage-orderForm-weight">
                            +配送费
                        </div>
                    </div>
                    <div className="manage-orderForm-buttonStatus manage-orderForm-margin">
                        获得购买按钮的资格
                    </div>
                    <div className="manage-orderForm-fnSku manage-orderForm-margin">
                        FN SKU
                    </div>
                    <div className="manage-orderForm-color manage-orderForm-distribution manage-orderForm-margin">
                        订单配送方
                    </div>
                    <div className="manage-orderForm-upcEan manage-orderForm-margin">
                        UPC/EAN
                    </div>
                    <div className="manage-orderForm-save manage-orderForm-margin">
                        <button>保存所有</button>
                    </div>
                </div>
                <div>
                    {
                        data.ManageInventoryList.map((item, index) => {
                            return (
                                <div className="manage-orderForm-data" key={index}>
                                    <div className="manage-orderForm-radio manage-orderForm-dingDan">
                                        <CheckboxGroup options={item.plainOptions} value={checkedList} onChange={(e) => { this.checkboxChange(e, item) }} />
                                    </div>
                                    <div className=" manage-orderForm-state manage-orderForm-dingDan" onClick={item.goodsIsVariant ? () => (this.variantClick(item, index)) : null}>
                                        {/* {item.goodsStatusVar} */}
                                        {item.goodsIsVariant ? <div>
                                            {item.isShow ? <img width="16" height="16" src={require("@/assets/img/home/buttomarrow.png").default} /> : <img width="16" height="16" src={require("@/assets/img/home/rightarrow.png").default} />}
                                            <span>变体（{item.variantCount}）</span>
                                        </div> : '在售'}
                                    </div>
                                    <div className="manage-orderForm-image manage-orderForm-dingDan">
                                        <img src={item.imgUrl ? item.imgUrl : require('@/assets/img/public/proDefault.png').default}></img>
                                    </div>
                                    <div className="manage-orderForm-condition manage-orderForm-dingDan">
                                        <div>
                                            {item.goodsSku}
                                        </div>
                                        <div className="manage-orderForm-weight">{item.goodsCondition}</div>
                                    </div>
                                    <div className="manage-orderForm-serialNumber manage-orderForm-dingDan">
                                        {item.goodsNumber}
                                    </div>
                                    <div className="manage-orderForm-name manage-orderForm-dingDan">
                                        <div className="limitBox">
                                            {item.goodsName}
                                        </div>
                                        <div className="manage-orderForm-weight">
                                            {item.goodsAsin}
                                        </div>
                                    </div>
                                    <div className="manage-orderForm-date manage-orderForm-dingDan">
                                        <div>{item.createTime}</div>
                                        <div className="manage-orderForm-weight">
                                            {item.updateTime}
                                        </div>
                                    </div>
                                    {/* //卖家亚马逊配送判断 */}
                                    {item.goodsIsVariant ? <><div className=" manage-orderForm-endibility manage-orderForm-dingDan">--</div>
                                        <div className=" manage-orderForm-bePutInStorage manage-orderForm-dingDan">--</div>
                                        <div className=" manage-orderForm-doNotSell manage-orderForm-dingDan">--</div>
                                        <div className=" manage-orderForm-reserved manage-orderForm-dingDan">--</div></>
                                        :
                                        item.goodsDispatchingType ? <><div className=" manage-orderForm-endibility">
                                            <Input type="number" min="0.1" value={item.goodsInventoryCount} onChange={(e) => { this.inputSumChange(e, index) }} />
                                        </div>
                                            <div className=" manage-orderForm-bePutInStorage manage-orderForm-dingDan">
                                                {item.setStorageCount || '--'}
                                            </div>
                                            <div className=" manage-orderForm-doNotSell manage-orderForm-dingDan">
                                                {item.notVendibilityCount || '--'}
                                            </div>
                                            <div className=" manage-orderForm-reserved manage-orderForm-dingDan">
                                                {item.retainCount || '--'}
                                            </div></>
                                            :
                                            <><div className=" manage-orderForm-endibility manage-orderForm-dingDan">0</div>
                                                <div className=" manage-orderForm-bePutInStorage manage-orderForm-dingDan">0</div>
                                                <div className=" manage-orderForm-doNotSell manage-orderForm-dingDan">0</div>
                                                <div className=" manage-orderForm-reserved manage-orderForm-dingDan">0</div>
                                            </>}
                                    <div className="manage-orderForm-estimatedCost manage-orderForm-dingDan">
                                        {item.estimatedCost || '--'}
                                    </div>
                                    {item.goodsIsVariant == false ? <><div className="manage-orderForm-price manage-orderForm-dingDan">
                                        <div className="">
                                            <Input type="number" min="0.1" value={item.goodsMoney || 0} onChange={(e) => { this.goodsMoneyChange(e, index) }} />
                                        </div>
                                        + --
                                        <div className="manage-orderForm-weight">
                                            + 配送费
                                        </div>
                                    </div>
                                        <div className="manage-orderForm-enterprisePrice manage-orderForm-dingDan">
                                            <div>
                                                <Input type="number" min="0.1" value={item.goodsEnterpriseMoney || 0} onChange={(e) => { this.goodsEnterpriseMoneyChange(e, index) }} />
                                            </div>
                                            <div className="manage-orderForm-weight">
                                                + 配送费
                                            </div>
                                        </div></> : <>
                                        <div className="manage-orderForm-price manage-orderForm-dingDan">--</div>
                                        <div className="manage-orderForm-price manage-orderForm-dingDan">--</div>
                                    </>}
                                    <div className="manage-orderForm-reservePrice manage-orderForm-dingDan">
                                        <div>
                                            -
                                        </div>
                                        <div className="manage-orderForm-weight">
                                            + --
                                        </div>
                                    </div>
                                    <div className="manage-orderForm-salesRank manage-orderForm-dingDan">
                                        -
                                    </div>
                                    <div className="manage-orderForm-buttonPrice manage-orderForm-dingDan">
                                        <div>
                                            -
                                        </div>
                                        <div className="manage-orderForm-weight">
                                            + --
                                        </div>
                                    </div>
                                    <div className="manage-orderForm-buttonStatus manage-orderForm-dingDan">
                                        -
                                    </div>
                                    <div className="manage-orderForm-fnSku manage-orderForm-dingDan">
                                        {item.goodsFnsku}
                                    </div>
                                    <div className="manage-orderForm-distribution manage-orderForm-dingDan">
                                        {item.goodsDispatchingName}
                                    </div>
                                    <div className="manage-orderForm-upcEan manage-orderForm-dingDan"></div>
                                    <div className="manage-orderForm-save manage-orderForm-dingDan">
                                        {/* <Select placeholder={item.selectDefault} style={{ width: 120 }} value={tableselectValue} onChange={(e) => { this.selectChonge(e, item) }}>
                                            {item.isChangedata ? <Option value={`保存`}>保存</Option> : null}
                                            <Option value={`/createListing`}>编辑</Option>
                                            <Option value="...">停售商品</Option>
                                            <Option value="....">管理图片</Option>
                                            <Option value=".....">复制到新的商品</Option>
                                            <Option value="......">添加其他状况</Option>
                                            <Option value={`/fbaInbound`}>转换为"亚马逊配送"</Option>
                                            <Option value=".......">匹配最低价</Option>
                                            <Option value="9">删除商品和报价</Option>
                                            <Option value="........">合并复制商品</Option>
                                        </Select> */}
                                        <Dropdown overlayStyle={{ width: 128, fontSize: 12 }} overlay={() => this.menu(item)}>
                                            <Button style={{ width: 128, backgroundColor: "#fff", fontSize: 12 }}>编辑 <DownOutlined /></Button>
                                        </Dropdown>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            </div>
            <Pagination
                current={page}
                total={total}
                showSizeChanger
                showQuickJumper
                className="pageBox"
                onChange={this.pageChonge}
                onShowSizeChange={this.ShowSizeChange}
            />
            <Modal title="提示" mask={false} onCancel={() => { this.setState({ isModalVisible: false }) }} visible={isModalVisible} maskClosable={false} footer={[
                // 定义右下角 按钮的地方 可根据需要使用 一个或者 2个按钮
                <Button data="hovers-7" key="submit" type="primary" onClick={this.handleOk}>确定</Button>,
                <Button data="hovers-f" key="back" onClick={() => { this.setState({ isModalVisible: false }) }}>取消</Button>
            ]}>
                <p>您确定要这样做吗？</p>
                <p>您将从库存商品中永久清除所选商品及其相关SKU。</p>
                <p>而且，如果您选择的商品拥有变体，其变体也会被永久清除</p>
                <p>请注意：从库存中删除SKU后的24小时内，您将无法添加同一SKU及相关商品信息</p>
            </Modal>
            {/* 引导步骤 */}
            <TipsIcon clicks={this.awakenTips} />
            <Reactours routePath="OrderForm" isActive={isActive} clicks={this.awakenTips} steps={steps1} isOpen={isTourOpen} />
        </div>

    }
}

export default OrderForm