import React, { useEffect, useRef, useCallback, useState } from 'react';
import AJAX from "api/apis";
import './AuthenticationTwo.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import Reactours from "components/reactours"
import TipsIcon from "components/tipsIcon"
import '../default.css/default.css'
import { Button, Radio, Input, Form, Select } from 'antd';
import { getYear, getMonth, getDay, getYearValidity, getSellerNationality, getIdentityList } from '../../utils/regDate';
import { regSubmit, getStoreRegisterInfo } from '../../utils/PublicLibrary';


export default (props) => {
    let formRef = useRef();
    const yearList = getYear();
    const monthList = getMonth();
    const dayList = getDay();
    const yearValidityList = getYearValidity();
    const countrylity = getSellerNationality()
    const identityList = getIdentityList()
    let [addressValue, setAddressValue] = useState(1)
    let [companyAddressT, setCompanyAddressT] = useState('')
    let [operationTypes, setOperationTypes] = useState(props.location.state ? props.location.state.operationType : null)
    let [isItValid, setIsItValid] = useState(false)
    let [isReturn, setIsReturn] = useState(props.location?.state?.isReturn || 0);
    const [isTourOpen, setIsTourOpen] = useState(true);
    const steps1 = [
        {
            selector: '.stepGuidanceBox0',
            content: '选择法人',
        },
        {
            selector: '.stepGuidanceBox1',
            content: '法人身份信息，选择护照/身份证',
        },
        {
            selector: '.stepGuidanceBox2',
            content: '身份证件号',
        },
        {
            selector: '.stepGuidanceBox3',
            content: '带国徽一面的有效期限',
        },
        {
            selector: '.stepGuidanceBox4',
            content: '签发国家/地区',
        },
        {
            selector: '.stepGuidanceBox5',
            content: '身份证上面的姓氏，填写英文',
        }, {
            selector: '.stepGuidanceBox6',
            content: '身份证上面的名，填写英文',
        },
        {
            selector: '.stepGuidanceBox7',
            content: '身份证上面的出生年月日',
        }, {
            selector: '.stepGuidanceBox8',
            content: '公司名称，英文/拼音',
        },
        {
            selector: '.stepGuidanceBox9',
            content: '公司名称，中文',
        }, {
            selector: '.stepGuidanceBox10',
            content: '营业执照上面的社会统一信用码',
        },
        {
            selector: '.stepGuidanceBox11',
            content: '选择公司地址，填写英文',
        }
    ];
    const [isActive, setIsActive] = useState(false);
    const awakenTips = (type) => {
        setIsActive(type);
    }
    let regInfo = {}
    useEffect(() => {
        getRegInfo()
    }, [])
    let addressChange = useCallback((e) => {
        setAddressValue(e.target.value)
    }, [])
    let getRegInfo = useCallback(() => {
        getStoreRegisterInfo(operationTypes).then((res) => {
            for (let item of res.data) {
                if (item.propertyValue) {
                    if (item.propertyKey == "is_personage_type") {
                        formRef.current.setFieldsValue({
                            role: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "seller_identity_type") {
                        formRef.current.setFieldsValue({
                            identity: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "is_identity_number") {
                        formRef.current.setFieldsValue({
                            numberId: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "sign_state") {
                        formRef.current.setFieldsValue({
                            sign_state: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "identity_date") {
                        formRef.current.setFieldsValue({
                            identity_date: item.propertyValue.split("------")[0],
                            identity_date1: item.propertyValue.split("------")[1],
                            identity_date2: item.propertyValue.split("------")[2],
                        })
                    }
                    if (item.propertyKey == "identity_name") {
                        formRef.current.setFieldsValue({
                            identity_name: item.propertyValue.split("------")[0],
                            identity_name1: item.propertyValue.split("------")[1],
                        })
                    }
                    if (item.propertyKey == "identity_birth_date") {
                        formRef.current.setFieldsValue({
                            identity_birth_date: item.propertyValue.split("------")[0],
                            identity_birth_date1: item.propertyValue.split("------")[1],
                            identity_birth_date2: item.propertyValue.split("------")[2],
                        })
                    }
                    if (item.propertyKey == "identity_company_chinese_name") {
                        formRef.current.setFieldsValue({
                            identity_company_chinese_name: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "identity_company_english_name") {
                        formRef.current.setFieldsValue({
                            companyNameY: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "identity_business_license_number") {
                        formRef.current.setFieldsValue({
                            identity_business_license_number: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "identity_company_site") {
                        // if (item.propertyValue == companyAddressT) {
                        //     formRef.current.setFieldsValue({
                        //         address: "1"
                        //     })
                        //     setAddressValue("1")
                        // } else {
                        //     formRef.current.setFieldsValue({
                        //         address: "2"
                        //     })
                        //     setAddressValue("2")
                        // }
                        formRef.current.setFieldsValue({
                            companyAddress: item.propertyValue.split("------")[6],
                            detailedAddressJ: item.propertyValue.split("------")[1],
                            detailedAddressD: item.propertyValue.split("------")[0],
                            postalCode: item.propertyValue.split("------")[5],
                            province: item.propertyValue.split("------")[4],
                            city: item.propertyValue.split("------")[3],
                            area: item.propertyValue.split("------")[2],
                        })
                    }
                    if (item.propertyKey == "identity_company_site_option") {
                        formRef.current.setFieldsValue({
                            address: item.propertyValue || 1
                        })
                        setAddressValue(item.propertyValue)
                    }
                    if (item.propertyKey == "proceeds_bill_site") {
                        setCompanyAddressT(item.propertyValue)
                    }
                }
            }
        })
        AJAX.login.getStoreRegisterInfo([]).then((data) => {
            if (data.code == 1 && data.data) {
                for (let item of data.data) {
                    if (item.propertyValue) {
                        if (item.propertyKey == "proceeds_bill_site") {
                            setCompanyAddressT(item.propertyValue)
                        }
                        if (item.propertyKey == "identity_company_site") {
                            // if (item.propertyValue == companyAddressT) {
                            //     formRef.current.setFieldsValue({
                            //         address: "1"
                            //     })
                            //     setAddressValue("1")
                            // } else {
                            //     formRef.current.setFieldsValue({
                            //         address: "2"
                            //     })
                            //     setAddressValue("2")
                            // }
                            formRef.current.setFieldsValue({
                                companyAddress: item.propertyValue.split("------")[6],
                                detailedAddressJ: item.propertyValue.split("------")[1],
                                detailedAddressD: item.propertyValue.split("------")[0],
                                postalCode: item.propertyValue.split("------")[5],
                                province: item.propertyValue.split("------")[4],
                                city: item.propertyValue.split("------")[3],
                                area: item.propertyValue.split("------")[2],
                            })
                        }
                    }
                }
            }
        })
        if (isReturn) {
            AJAX.login.getStoreRegisterInfo([]).then((data) => {
                if (data.code == 1 && data.data) {
                    for (let item of data.data) {
                        if (item.propertyValue) {
                            if (item.propertyKey == "is_personage_type") {
                                formRef.current.setFieldsValue({
                                    role: item.propertyValue
                                })
                            }
                            if (item.propertyKey == "seller_identity_type") {
                                formRef.current.setFieldsValue({
                                    identity: item.propertyValue
                                })
                            }
                            if (item.propertyKey == "is_identity_number") {
                                formRef.current.setFieldsValue({
                                    numberId: item.propertyValue
                                })
                            }
                            if (item.propertyKey == "sign_state") {
                                formRef.current.setFieldsValue({
                                    sign_state: item.propertyValue
                                })
                            }
                            if (item.propertyKey == "identity_date") {
                                formRef.current.setFieldsValue({
                                    identity_date: item.propertyValue.split("------")[0],
                                    identity_date1: item.propertyValue.split("------")[1],
                                    identity_date2: item.propertyValue.split("------")[2],
                                })
                            }
                            if (item.propertyKey == "identity_name") {
                                formRef.current.setFieldsValue({
                                    identity_name: item.propertyValue.split("------")[0],
                                    identity_name1: item.propertyValue.split("------")[1],
                                })
                            }
                            if (item.propertyKey == "identity_birth_date") {
                                formRef.current.setFieldsValue({
                                    identity_birth_date: item.propertyValue.split("------")[0],
                                    identity_birth_date1: item.propertyValue.split("------")[1],
                                    identity_birth_date2: item.propertyValue.split("------")[2],
                                })
                            }
                            if (item.propertyKey == "identity_company_chinese_name") {
                                formRef.current.setFieldsValue({
                                    identity_company_chinese_name: item.propertyValue
                                })
                            }
                            if (item.propertyKey == "identity_company_english_name") {
                                formRef.current.setFieldsValue({
                                    companyNameY: item.propertyValue
                                })
                            }
                            if (item.propertyKey == "identity_business_license_number") {
                                formRef.current.setFieldsValue({
                                    identity_business_license_number: item.propertyValue
                                })
                            }
                            if (item.propertyKey == "identity_company_site") {
                                // if (item.propertyValue == companyAddressT) {
                                //     formRef.current.setFieldsValue({
                                //         address: "1"
                                //     })
                                //     setAddressValue("1")
                                // } else {
                                //     formRef.current.setFieldsValue({
                                //         address: "2"
                                //     })
                                //     setAddressValue("2")
                                // }
                                formRef.current.setFieldsValue({
                                    companyAddress: item.propertyValue.split("------")[0],
                                    detailedAddressJ: item.propertyValue.split("------")[1],
                                    detailedAddressD: item.propertyValue.split("------")[2],
                                    postalCode: item.propertyValue.split("------")[3],
                                    province: item.propertyValue.split("------")[4],
                                    city: item.propertyValue.split("------")[5],
                                    area: item.propertyValue.split("------")[6],
                                })
                            }
                            if (item.propertyKey == "identity_company_site_option") {
                                formRef.current.setFieldsValue({
                                    address: item.propertyValue || 1
                                })
                            }
                            if (item.propertyKey == "proceeds_bill_site") {
                                setCompanyAddressT(item.propertyValue)
                            }
                        }
                    }
                }
            })
        }
    }, [companyAddressT, isReturn])
    let nextEvent = useCallback(() => {
        formRef.current.validateFields().then((res) => {
            if (res.identity_date1 && res.identity_date2 && res.identity_date) {
                let date = new Date();
                let currentYear = date.getFullYear(); //获取完整的年份(4位)
                let currentMonth = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
                let currentDate = date.getDate();
                let selectMonth = res.identity_date1.split("月")[0];
                let selectYear = res.identity_date2.split("年")[0]
                let selectDay = res.identity_date.split("日")[0]
                if (currentYear < selectYear) {
                    setIsItValid(false)
                } else if (currentYear == selectYear) {
                    if (selectMonth < currentMonth) {
                        // if (selectDay < currentDate) {
                        setIsItValid(true)
                        return;
                        // }
                    } else if (selectMonth == currentMonth) {
                        if (selectDay < currentDate) {
                            setIsItValid(true)
                            return;
                        }
                    } else {
                        setIsItValid(false)
                    }
                } else {
                    setIsItValid(true)
                    return;
                }
            }
            let arr = [
                { "propertyKey": "is_personage_type", "propertyValue": res.role ? res.role : '法定代理人' },
                // { "propertyKey": "is_personage_type", "propertyValue": res.identity },
                { "propertyKey": "is_identity_number", "propertyValue": res.numberId },
                { "propertyKey": "sign_state", "propertyValue": res.sign_state },
                { "propertyKey": "identity_date", "propertyValue": res.identity_date + "------" + res.identity_date1 + "------" + res.identity_date2 },
                { "propertyKey": "identity_name", "propertyValue": res.identity_name + "------" + res.identity_name1 },
                { "propertyKey": "identity_birth_date", "propertyValue": res.identity_birth_date + "------" + res.identity_birth_date1 + "------" + res.identity_birth_date2 },
                { "propertyKey": "identity_company_chinese_name", "propertyValue": res.identity_company_chinese_name },
                { "propertyKey": "identity_company_english_name", "propertyValue": res.companyNameY },
                { "propertyKey": "identity_business_license_number", "propertyValue": res.identity_business_license_number },
                { "propertyKey": "identity_company_site", "propertyValue": res.address == 1 ? companyAddressT || '' : res.detailedAddressD + "------" + res.detailedAddressJ + "------" + res.area + "------" + res.city + "------" + res.province + "------" + res.postalCode + "------" + res.companyAddress },
                { "propertyKey": "identity_company_site_option", "propertyValue": res.address },

            ]
            let localArr = [
                { "propertyKey": "address_value", "propertyValue": res.address },
            ]
            regSubmit(arr, localArr, 'AuthenticationTwo', 12).then((data) => {
                if (data.code == 1) {
                    props.history.push({
                        pathname: '/AuthenticationThree',
                        state: {
                            is_personage_type: res.role ? res.role : '法定代理人',
                            // is_personage_type: res.identity,
                            is_identity_number: res.numberId,
                            sign_state: res.sign_state,
                            identity_date: res.identity_date + "------" + res.identity_date1 + "------" + res.identity_date2,
                            identity_name: res.identity_name + "------" + res.identity_name1,
                            identity_birth_date: res.identity_birth_date + "------" + res.identity_birth_date1 + "------" + res.identity_birth_date2,
                            identity_company_chinese_name: res.identity_company_chinese_name,
                            // identity_company_english_name: res.identity_company_english_name,
                            identity_business_license_number: res.identity_business_license_number
                        }
                    })
                }
            })
        })
    }, [regInfo, companyAddressT])
    return (
        <div className="agreementsBox AuthenticationTwoBox">
            <TipsIcon clicks={awakenTips} />
            {/* 引导步骤 */}
            <Reactours routePath="AuthenticationTwo" isActive={isActive} clicks={awakenTips} steps={steps1} isOpen={isTourOpen} />
            <Header />
            <div style={{ display: 'flex' }}>
                <div className="a-section program-overview-values">
                    <div className="CompanyAddress">
                        <Form
                            ref={formRef}
                            initialValues={{
                                address: "1",
                            }}>
                            <div>
                                <div className="ng-scope a-size-medium">身份验证</div>
                                <div className="help">帮助</div>
                                <div className="ng-scope a-size-small">我代表具有有效的营业执照的公司或企业管理该账户</div>
                                <div className="inpBox inpBox1 stepGuidanceBox0">
                                    <Form.Item name="role">
                                        <Radio.Group defaultValue="法定代理人">
                                            <Radio value="法定代理人">法定代理人</Radio>
                                            <Radio value="董事或代表人">董事或代表人</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                                <div className="inpBox inpBox1">
                                    <div className="proveBox">
                                        <p style={{ fontWeight: 'bold' }}>身份信息</p>
                                        <div className="yanzPhone">
                                            <Form.Item className="stepGuidanceBox1" name="identity" rules={[{ required: true, message: '请选择身份证明' }]}>
                                                <Select placeholder="身份证明" options={identityList} />
                                            </Form.Item>
                                            <Form.Item
                                                className="stepGuidanceBox2"
                                                name="numberId"
                                                rules={[{
                                                    required: true,
                                                    validator: (rule, value, fun) => {
                                                        let reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
                                                        if (!value) {
                                                            fun('请输入身份证明编号')
                                                        } else if (!reg.test(value)) {
                                                            fun('身份证明编号无效')
                                                        }
                                                        fun()
                                                    }
                                                }]}
                                            >
                                                <Input placeholder="编号" maxLength="18" />
                                            </Form.Item>
                                        </div>
                                    </div>

                                </div>
                                <div className="inpBox inpBox1">
                                    <div className="dates stepGuidanceBox3">
                                        <p>有效期</p>
                                        <div>
                                            <div className="birthBox">
                                                <Form.Item name="identity_date" rules={[{ required: true, message: '请输入有效期天数' }]}>
                                                    <Select placeholder="天" options={dayList} />
                                                </Form.Item>
                                                <Form.Item name="identity_date1" rules={[{ required: true, message: '请输入有效期月份' }]}>
                                                    <Select placeholder="月" options={monthList} />
                                                </Form.Item>
                                                <Form.Item name="identity_date2" rules={[{ required: true, message: '请选择有效期年份' }]}>
                                                    <Select placeholder="年" options={yearValidityList} />
                                                </Form.Item>
                                            </div>
                                            {isItValid ? <div style={{ color: '#ff4d4f' }}>有效期无效</div> : null}

                                        </div>
                                    </div>
                                    <div className="stepGuidanceBox4">
                                        <p>签发国</p>
                                        <Form.Item name="sign_state" rules={[{ required: true, message: '请选择国家/地区' }]}>
                                            <Select placeholder="选择国家/地区" options={countrylity} />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="inpBox inpBox1">
                                    <div className="stepGuidanceBox5">
                                        <p style={{ fontWeight: 'bold', marginBottom: 0 }}>身份证上显示的名称</p>
                                        <Form.Item
                                            name="identity_name"
                                            rules={[{ required: true, message: '请输入姓氏', }]}
                                        >
                                            <Input placeholder="姓" />
                                        </Form.Item>
                                    </div>
                                    <div className="stepGuidanceBox6">
                                        <p style={{ fontWeight: 'bold', marginBottom: 0 }}>身份证上显示的名称</p>
                                        <Form.Item
                                            name="identity_name1"
                                            rules={[{ required: true, message: '请输入名字', }]}
                                        >
                                            <Input placeholder="名" />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div>
                                    <div className="inpBox inpBox1">
                                        <div className="dates birthTimes stepGuidanceBox7">
                                            <p>出生日期</p>
                                            <div>
                                                <div className="birthBox">
                                                    <Form.Item name="identity_birth_date" rules={[{ required: true, message: '请输入出生日期天数' }]}>
                                                        <Select placeholder="天" options={dayList} />
                                                    </Form.Item>
                                                    <Form.Item name="identity_birth_date1" rules={[{ required: true, message: '请输入出生日期月份' }]}>
                                                        <Select placeholder="月" options={monthList} />
                                                    </Form.Item>
                                                    <Form.Item name="identity_birth_date2" rules={[{ required: true, message: '请输入出生日期年份' }]}>
                                                        <Select placeholder="年" options={yearList} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p style={{ fontWeight: 'bold', paddingTop: '30px' }}>用于向州或联邦政府登记的企业名称</p>
                                    <div>
                                        <div className="stepGuidanceBox8">
                                            <Form.Item
                                                name="companyNameY"
                                                rules={[{
                                                    required: true,
                                                    whitespace: true,
                                                    validator: (rule, value, cbfn) => {
                                                        let reg = /^[ 0-9a-zA-Z`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]+$/
                                                        if (!value) {
                                                            cbfn('请输入公司名称（英语或拼音）')
                                                        } else if (!reg.test(value)) {
                                                            cbfn('公司名称验证错误')
                                                        }
                                                        cbfn()
                                                    }
                                                }]}
                                            >
                                                <Input placeholder="公司名称（英语或拼音）" maxLength="100" />
                                            </Form.Item>
                                        </div>
                                        <div className="stepGuidanceBox9">
                                            <Form.Item
                                                name="identity_company_chinese_name"
                                                rules={[{
                                                    required: true,
                                                    whitespace: true,
                                                    validator: (rule, value, cbfn) => {
                                                        let reg = /^[0-9\u4e00-\u9fa5`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]+$/
                                                        if (!value) {
                                                            cbfn('请输入公司名称（中文）')
                                                        } else if (!reg.test(value)) {
                                                            cbfn('公司名称验证错误')
                                                        }
                                                        cbfn()
                                                    }
                                                }]}
                                            >
                                                <Input placeholder="公司名称（中文）" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <p style={{ fontWeight: 'bold', paddingTop: '30px' }}>营业执照编号</p>
                                    <div className="stepGuidanceBox10">
                                        <div>
                                            <Form.Item
                                                name="identity_business_license_number"
                                                rules={[{
                                                    required: true,
                                                    validator: (rule, value, fun) => {
                                                        let reg = /^[0-9A-Z]+$/
                                                        if (!value) {
                                                            fun('请输入营业执照编号')
                                                        } else if (!reg.test(value)) {
                                                            fun('营业执照编号无效')
                                                        }
                                                        fun()
                                                    }
                                                }]}
                                            >
                                                <Input placeholder="营业执照编号" maxLength="18" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <p style={{ fontWeight: 'bold', paddingTop: '30px' }}>公司地址</p>
                                    <Form.Item className="stepGuidanceBox11" name="address" rules={[{ required: true, message: "请选择公司地址" }]}>
                                        <Radio.Group style={{ width: '100%' }} onChange={addressChange}>
                                            <div className="bottomDesc">
                                                <Radio value="1">{companyAddressT.replace(/------/g, "")}</Radio>
                                            </div>
                                            <div>
                                                <Radio value="2" style={{ width: '100%', padding: '10px 10px 0 10px' }}>添加不同的账单地址</Radio>
                                            </div>
                                        </Radio.Group>
                                    </Form.Item>
                                    {addressValue == 2 ? <div>
                                        <div className="inpBox">
                                            <Form.Item name="companyAddress" rules={[{ required: true, message: '请选择国家/地区' }]}>
                                                <Select placeholder="选择国家/地区" options={countrylity} />
                                            </Form.Item>
                                            <Form.Item
                                                name="postalCode"
                                                rules={[{
                                                    required: true,
                                                    validator: (rule, value, fun) => {
                                                        let reg = /^[1-9][0-9]{5}$/;
                                                        if (!value) {
                                                            fun('请输入邮政编码')
                                                        } else if (!reg.test(value)) {
                                                            fun('邮政编码验证失败')
                                                        }
                                                        fun()
                                                    }
                                                }]}>
                                                <Input placeholder="邮政编码" maxLength="6" />
                                            </Form.Item>
                                        </div>
                                        <div className="inpBox addressBox">
                                            <Form.Item name="province" rules={[{ required: true, message: '请输入省/自治区/直辖市' }]}>
                                                <Input placeholder="省/自治区/直辖市" maxLength="50" />
                                            </Form.Item>
                                            <Form.Item name="city" rules={[{ required: true, message: '请输入城市' }]}>
                                                <Input placeholder="城市" maxLength="50" />
                                            </Form.Item>
                                        </div>
                                        <div className="inpBox">
                                            <Form.Item name="area" rules={[{ required: true, message: '请输入区' }]}>
                                                <Input placeholder="区" maxLength="50" />
                                            </Form.Item>
                                            <Form.Item name="detailedAddressJ" rules={[{ required: true, message: '请输入详细地址，如道路、街道、门牌号等' }]}>
                                                <Input placeholder="详细地址，如道路、街道、门牌号等" maxLength="100" />
                                            </Form.Item>
                                        </div>
                                        <div className="inpBox">
                                            <Form.Item name="detailedAddressD" rules={[{ required: true, message: '请输入详细地址，如大厦、楼层、房间号等' }]}>
                                                <Input placeholder="详细地址，如大厦、楼层、房间号等" maxLength="100" />
                                            </Form.Item>
                                        </div>
                                    </div> : null}
                                </div>
                                <div className="operationBtn" style={{ textAlign: 'center' }}>
                                    <Button onClick={nextEvent} className='next' type="primary">提交</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}