import { AForm } from "@a-components";
import { useCallback, useContext, useMemo } from "react";
import { getFormElementByName } from "./formMapping";
import context from "./context";
const Item = AForm.Item;

const FormItem = ({ template, ...otherFormItemProps }) => {
  const {
    element,
    config,
    name,
    render,
    content,
    $computeProps,
    ...otherTemplate
  } = template || {};

  const { form } = useContext(context);

  const isWrapper = useMemo(() => {
    return element === "Wrapper";
  }, [element]);

  const isText = useMemo(() => {
    return element === "Text";
  }, [element]);

  const renderComponent = useCallback((Component, props, children) => {
    return <Component {...props} children={children} />;
  }, []);

  const renderFormItem = useCallback(
    (props, children) => {
      const realName = typeof children === "function" ? undefined : name;
      return (
        <Item name={realName} {...props}>
          {children}
        </Item>
      );
    },
    [name]
  );

  const renderChild = useCallback(
    (children) => {
      if (typeof render === "function") {
        return () => {
          const value = form.getFieldValue(name);
          return render(value, template, form);
        };
      }

      const Component = getFormElementByName(element);
      if (!Component) {
        throw new Error(`${element} is not registry!`);
      }

      if ($computeProps) {
        return () => {
          const curProps = $computeProps(config, template, form);
          const { label, ...otherProps } = otherTemplate;
          return renderFormItem(
            { ...otherProps, style: { marginBottom: 0 } },
            renderComponent(Component, curProps, children)
          );
        };
      }
      return renderComponent(Component, config, children);
    },
    [
      element,
      config,
      name,
      form,
      template,
      otherTemplate,
      render,
      $computeProps,
      renderComponent,
      renderFormItem,
    ]
  );

  const renderFormItems = useCallback(() => {
    if (!Array.isArray(content)) {
      throw new Error(`'content' is required in Wrapper!`);
    }

    const children = content.map((item, index) => {
      return <FormItem template={item} key={item.name || index} />;
    });
    const wrapper = renderChild(children);

    return renderFormItem(otherTemplate, wrapper);
  }, [content, otherTemplate, renderChild, renderFormItem]);

  if (!template) {
    return renderFormItem(otherFormItemProps, otherFormItemProps.children);
  }

  if (isWrapper) {
    return renderFormItems();
  }

  const children = renderChild();

  if (isText) {
    return children;
  }

  return renderFormItem(otherTemplate, children);
};

export default FormItem;
