import React from 'react'
import './MengkSiderRight.css'
import { Button, Input, Radio } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

function MengkSiderRight() {
  const list = [
    {
      title: "商品",
      imgSrc: "Mengk-right1.png"
    },
    {
      title: "图片",
      imgSrc: "Mengk-right2.png"
    },
    {
      title: "带文本的图片",
      imgSrc: "Mengk-right3.png"
    },
    {
      title: "可购物图片",
      imgSrc: "Mengk-right4.png"
    },
  ]
  return (
    <div className="MengkSiderRight" >
      <h3><LeftOutlined />取消</h3>
      <div>选择拼贴类型</div>
      <div>
        {
          list.map((item, index) => {
            return (
              <div key={index} className="MengkSiderRight-item" >
                <div className="MengkSiderRight-title" >{item.title}</div>
                <img src={require(`@assets/images/${item.imgSrc}`).default} style={{ width: 300 }} ></img>
              </div>
            )
          })
        }
      </div>

    </div>

  )
}

export default MengkSiderRight