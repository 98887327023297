import moment from "moment";
import { computedKeywordsId } from "./KeywordDeliveryCard/config";
import { computedNegateKeywordId } from "./NegateKeywordDelivery/config";
import { computedInnerId } from "./ProductLaunch/config";

function insertId(computedFn) {
  return (item) => {
    return {
      ...item,
      $id: computedFn(item),
    };
  };
}

export const local2Remote = (formValues) => {
  const {
    adActiveName, // 广告活动名称
    adGroup, // 组合
    adStartTime, // 开始时间
    adEndTime, // 结束时间
    budget, // 每日预算
    orientationStrategyType, // 定向策略

    adBiddingStrategy, // 广告活动竞价策略
    searchBiddingRate, // 搜索结果页面竞价
    goodsBiddingRate, // 商品页面竞价

    adGroupName, // 广告组名称
    selectedGoodsList, // 选中的商品列表 - 商品列表
    // positioning: orientationStrategyType === "automatic" ? 1 : 2,
    // putType: orientationStrategyType2 === "keyword" ? 1 : 2,
    orientationStrategyType2, // 定向策略
    categoryGoodsPosition, // 定向策略 - 商品投放 - 定位的分类和商品

    selectedKeywordsList, // 定向策略 - 关键词投放 - 选中的关键词

    autoDeliveryType, // 自动投放
    defaultBidding, // 自动投放 - 默认竞价
    orientationGroupBidding, // 自动投放 - 按定向组设置竞价

    selectedNegateKeywordList, // 否定关键词列表
    excludedNegateProductLaunchList, // 否定商品列表
  } = formValues;

  const payloadData = {
    advertisingType: 1,
    advertisingName: adActiveName,
    advertisingCombinationId: adGroup,
    advertisingGoodsReqList: selectedGoodsList.map((v) => ({
      goodsId: v.goodsId,
    })),
    advertisingGroupName: adGroupName,
    biddingType: adBiddingStrategy,
    biddingWay: autoDeliveryType,
    dailyBudgetPrice: budget,
    defaultPrice: defaultBidding,
    endTime: adEndTime ? adEndTime.format("YYYY-MM-DD") : null,
    isDraft: 1,
    positioning: orientationStrategyType === "automatic" ? 1 : 2,
    putType: orientationStrategyType2 === "keyword" ? 1 : 2,
    searchTopProportion: searchBiddingRate,
    commodityPage: goodsBiddingRate,
    startTime: adStartTime.format("YYYY-MM-DD"),

    advertisingDeliveryReqList: orientationGroupBidding.map(
      ({ biddingPrice, deliveryGroupType, status }) => ({
        biddingPrice,
        deliveryGroupType,
        status,
      })
    ),

    advertisingDirectionalReqList: categoryGoodsPosition.map((item) => {
      const {
        biddingPrice,
        brandId,
        directionalId,
        distribution,
        goodsDirectionalType,
        goodsPriceEnd,
        goodsPriceStart,
        starEnd,
        starStart,
      } = item;
      return {
        biddingPrice,
        brandId,
        directionalId,
        distribution,
        goodsDirectionalType,
        goodsPriceEnd,
        goodsPriceStart,
        starStart,
        starEnd,
      };
    }),

    advertisingKeywordsReqList: selectedKeywordsList.map((item) => {
      const { biddingPrice, keyword, matchType } = item;

      return {
        biddingPrice,
        keyword,
        matchType,
      };
    }),

    advertisingNegativeKeywordsReqList: selectedNegateKeywordList,

    advertisingNegativeDirectionalReqList: excludedNegateProductLaunchList,
  };

  return payloadData;
};

export const remote2Local = (responseData) => {
  const {
    advertisingCombinationId,
    advertisingDeliveryVoList,
    advertisingDirectionalVoList,
    advertisingGoodsVoList,
    advertisingGroupName,
    advertisingKeywordsVoList,
    advertisingName,
    biddingType,
    biddingWay,
    commodityPage,
    dailyBudgetPrice,
    defaultPrice,
    endTime,
    positioning,
    putType,
    searchTopProportion,
    startTime,
    advertisingNegativeKeywordsVoList,
    advertisingNegativeDirectionalVoList,
  } = responseData;

  return {
    adActiveName: advertisingName, // 广告活动名称
    adGroup: advertisingCombinationId || "", // 组合
    adStartTime: moment(startTime), // 开始时间
    adEndTime: endTime ? moment(endTime) : null, // 结束时间
    budget: dailyBudgetPrice, // 每日预算
    orientationStrategyType: positioning === 1 ? "automatic" : "manual", // 定向策略

    adBiddingStrategy: biddingType, // 广告活动竞价策略
    searchBiddingRate: searchTopProportion, // 搜索结果页面竞价
    goodsBiddingRate: commodityPage, // 商品页面竞价

    adGroupName: advertisingGroupName, // 广告组名称

    selectedGoodsList: advertisingGoodsVoList, // 选中的商品列表 - 商品列表

    orientationStrategyType2: putType === 1 ? "keyword" : "goods", // 定向策略
    categoryGoodsPosition: advertisingDirectionalVoList.map(
      insertId(computedInnerId)
    ), // 定向策略 - 商品投放 - 定位的分类和商品

    selectedKeywordsList: advertisingKeywordsVoList.map(
      insertId(computedKeywordsId)
    ), // 定向策略 - 关键词投放 - 选中的关键词

    autoDeliveryType: biddingWay, // 自动投放
    defaultBidding: defaultPrice, // 自动投放 - 默认竞价
    orientationGroupBidding: advertisingDeliveryVoList, // 自动投放 - 按定向组设置竞价
    selectedNegateKeywordList: advertisingNegativeKeywordsVoList.map(
      insertId(computedNegateKeywordId)
    ),

    excludedNegateProductLaunchList: advertisingNegativeDirectionalVoList,
  };
};
