import React from 'react'
import './RegCommentator.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import '../default.css/default.css'
import { Link } from 'react-router-dom';


function Early() {
    return (
        <div>
            <Header />
            <div className="RegCommentatorBox">
                <div className="end06_mybody">
                    <ul className="mybody-ul">
                        <li>
                            <Link to="/Commentator">早期评论者计划</Link>
                        </li>
                        <li>
                            <Link to="/RegCommentator">注册加入计划</Link>
                        </li>
                        <li>
                            <Link to="/Early">控制面板</Link>
                        </li>
                        <li>常见问题</li>
                    </ul>
                </div>
                <div className="a-section">
                    <h1 className="aok-inline-block">注册早期评论者计划</h1>
                </div>
                <div className="a-box a-spacing-extra-large a-text-center">
                    <div className="a-box-inner a-padding-extra-large">
                        <span className="a-text-beside-button">提交 SKU 进行注册</span>
                        <input type="text" placeholder="输入父 SKU/独立 SKU" name="sku" className="a-input-text a-width-large SKUinput" />
                        <span className="a-button a-button-primary zigeClass">
                            <Link to="/uploadSKU">检查资格</Link>
                        </span>
                    </div>
                </div>

                <div className="a-section program-overview-values">
                    <div className="a-row a-grid-vertical-align a-grid-top">
                        <div className="a-column a-span4 a-text-center value-box">
                            <div className="a-icon-row a-spacing-medium reviews-increase-icon"
                                style={{ backgroundImage: `url(${require("@/assets/images/regConnents1.png").default})` }}></div>
                            <div className="a-row a-spacing-small">
                                <span className="a-size-large a-text-bold">步骤 1： 查找符合条件的 SKU</span>
                            </div>
                            <div style={{ textAlign: "center", color: '#0066c0' }}>
                                <span style={{ fontSize: '13px' }}>需要帮助？</span>
                            </div>
                        </div>
                        <div className="a-column a-span4 a-text-center value-box">
                            <div className="a-icon-row a-spacing-medium reviews-faster-icon"
                                style={{ backgroundImage: `url(${require("@/assets/images/regConnents2.png").default})` }}></div>
                            <div className="a-row a-spacing-small">
                                <span className="a-size-large a-text-bold">获取您的第一条评论</span>
                            </div>
                        </div>
                        <div className="a-column a-span4 a-text-center value-box a-span-last">
                            <div className="a-icon-row a-spacing-medium starting-easy-icon"
                                style={{ backgroundImage: `url(${require("@/assets/images/regConnents3.png").default})` }}></div>
                            <div className="a-row a-spacing-small">
                                <span className="a-size-large a-text-bold">轻松入门</span>
                            </div>
                            <div>
                                <span className="a-size-medium">
                                    <Link style={{ textAlign: "center", color: '#0066c0', fontSize: '13px' }} to="/Early">查看控制面板</Link>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="a-section RegCommentatorFooter a-text-center program-overview-footer">
                    <div className="a-row">
                        <div className="a-spacing-base a-size-large" style={{ marginBottom: '8px' }}>商品注册非常简单</div>
                        <div>
                            <Link style={{ color: '#0066c0' }} className="a-size-medium" to="/uploadSKU">尝试批量上传注册内容</Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default Early