import { useCallback, useRef } from "react";

const useMemory = (initValue) => {
  const _ref = useRef(initValue);

  const setMemory = useCallback((value) => {
    if (typeof value === "function") {
      _ref.current = value(_ref.current);
    }
    _ref.current = value;
  }, []);

  return [_ref, setMemory];
};

export default useMemory;
