import React from 'react'
import './AccountHealth.css'
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'

function AccountHealth(){
    return(
        <div>
            <Header/>

            <div>

            <div className="end12_navlist">
			<ul>
				<li>账户状况</li>
				<li>客户服务绩效</li>
				<li>商品政策合规性</li>
				<li>配送绩效</li>
				<li>报告<svg t="1611390779049" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
					 p-id="2525" width="30" height="30">
						<path d="M512.03902167 545.14817828l-85.71858778-85.71712081c-3.59996944-3.60290341-9.44324503-3.60290341-13.04350788 0-3.6031968 3.59996944-3.6031968 9.44324503 0 13.04350786l92.24019503 92.24019503c3.59996944 3.60290341 9.44324503 3.60290341 13.04350788 0l92.23990161-92.24019503c1.80145171-1.80027811 2.70247094-4.16123607 2.70247096-6.52160724s-0.90101924-4.72132912-2.70247096-6.52160721c-3.59996944-3.60290341-9.44324503-3.60290341-13.04350785 0l-85.7182944 85.7171208z"
						 p-id="2526"></path>
					</svg></li>
				<li>资格条件</li>
				<li>买家之声</li>
			</ul>
		</div>

		<div className="end12_title">
			<div className="end12_jinggao">
				<svg t="1611391025518" className="icon" viewBox="0 0 1079 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7110"
				 width="20" height="20">
					<path d="M673.670005 110.658935c-18.353189-33.46758-39.405377-58.838166-62.616763-76.111755-22.671587-17.27359-46.962572-25.910385-71.253558-25.910385s-48.581972 8.636795-71.253559 25.910385c-22.671587 17.27359-43.723774 42.644175-62.616763 76.111755L42.644175 771.373748C5.397997 838.848708 2.159199 899.846073 28.609383 944.649446c26.450185 44.263574 81.509752 71.253558 158.701107 71.253559h704.978387c77.191355 0 132.250923-26.450185 158.701107-71.253559s22.671587-105.800738-14.034792-173.275698L673.670005 110.658935z"
					 fill="#FFCF00" p-id="7111"></path>
					<path d="M892.288877 1024H187.31049c-78.270954 0-137.648919-26.989984-166.258302-75.571956s-23.751186-113.357934 14.034791-181.912493L398.372167 106.340538c18.892989-34.00738 40.484976-60.457565 64.775962-78.810754 49.121771-36.706378 104.721139-36.706378 153.30311 0 24.290986 18.353189 45.882973 44.803374 64.775962 78.810754L1044.512388 766.515551c37.785978 68.55456 42.644175 133.330522 14.034792 181.912493-28.609383 49.121771-87.447549 75.571956-166.258303 75.571956zM539.799684 17.81339c-22.671587 0-44.803374 8.096995-65.855562 24.290985-22.131787 16.73379-42.644175 41.564576-59.917765 73.412757L50.74117 775.692145C16.193991 838.308909 10.795994 896.607275 36.166579 939.791249s78.810754 66.395361 150.604112 66.395361h704.978386c71.793358 0 125.233527-23.751186 150.604112-66.395361 25.370585-43.183975 20.512388-101.482341-14.574591-164.099104L665.57301 114.977333c-17.27359-31.848181-37.785978-56.678967-59.917765-73.412757-21.052188-15.654191-43.183975-23.751186-65.855561-23.751186z"
					 fill="#333333" p-id="7112"></path>
					<path d="M598.637849 786.488139v117.676331H480.961518v-117.676331h117.676331z m-58.838165-529.00369c32.387981 0 58.838166 26.450185 58.838165 58.838166V609.973643c0 32.387981-26.450185 58.838166-58.838165 58.838165S480.961518 642.361624 480.961518 609.973643V316.322615c0-32.387981 26.450185-58.838166 58.838166-58.838166z"
					 fill="#333333" p-id="7113"></path>
				</svg>
				及时解决以下违反政策的行为可避免对您的销售账户造成不必要的影响。
			</div>
			<div className="end12_h2">
				<div>
					账户状况<span>发表反馈</span>
				</div>
				<p>要在亚马逊上销售商品，您必须达到以下绩效目标和政策要求。</p>
				<p>请在<span>这里</span>填入您的紧急联系电话号码，以便我们能在重要、可能危及您的销售权利的情况发生时，能及时与您联系。</p>
			</div>
			<div className="end12_jubao">举报滥用亚马逊政策的行为。</div>
		</div>

		<div className="end12_cenlist">
			<div className="end12_centent">
				<div className="end12_cen1">
					<div className="end12_cen1_tit">
						客户服务绩效
					</div>
					<div className="end12_cen1_text">
						<div className="end12_cen1_text_1">
							<p></p>
							<p>卖家自配送</p>
							<p>亚马逊配送</p>
						</div>
						<div className="end12_cen1_text_2">
							<div className="end12_cen1_text_1">
								<p className="end12_dingdan">订单缺陷率</p>
								<p>不适用<svg t="1611390779049" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
									 p-id="2525" width="30" height="30">
										<path d="M512.03902167 545.14817828l-85.71858778-85.71712081c-3.59996944-3.60290341-9.44324503-3.60290341-13.04350788 0-3.6031968 3.59996944-3.6031968 9.44324503 0 13.04350786l92.24019503 92.24019503c3.59996944 3.60290341 9.44324503 3.60290341 13.04350788 0l92.23990161-92.24019503c1.80145171-1.80027811 2.70247094-4.16123607 2.70247096-6.52160724s-0.90101924-4.72132912-2.70247096-6.52160721c-3.59996944-3.60290341-9.44324503-3.60290341-13.04350785 0l-85.7182944 85.7171208z"
										 p-id="2526"></path>
									</svg></p>
								<p>不适用<svg t="1611390779049" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
									 p-id="2525" width="30" height="30">
										<path d="M512.03902167 545.14817828l-85.71858778-85.71712081c-3.59996944-3.60290341-9.44324503-3.60290341-13.04350788 0-3.6031968 3.59996944-3.6031968 9.44324503 0 13.04350786l92.24019503 92.24019503c3.59996944 3.60290341 9.44324503 3.60290341 13.04350788 0l92.23990161-92.24019503c1.80145171-1.80027811 2.70247094-4.16123607 2.70247096-6.52160724s-0.90101924-4.72132912-2.70247096-6.52160721c-3.59996944-3.60290341-9.44324503-3.60290341-13.04350785 0l-85.7182944 85.7171208z"
										 p-id="2526"></path>
									</svg></p>
							</div>
							<div className="end12_cen1_text_1 end12_text3">
								<p><span className="end12_bold">目标</span>: 低于1%</p>
								<p></p>
								<p></p>
							</div>
						</div>
						<div className="end12_zhibiao">
							<p>订单缺陷率包含三个不同的指标:</p>
							<ul>
								<li>
									<ul className="end12_zb_flex">
										<li>负面缺陷</li>
										<li>不适用</li>
										<li>不适用</li>
									</ul>
								</li>
								<li>
									<ul className="end12_zb_flex">
										<li>亚马逊商城交易保障索赔</li>
										<li>不适用</li>
										<li>不适用</li>
									</ul>
								</li>
								<li>
									<ul className="end12_zb_flex">
										<li>信用卡拒付索赔</li>
										<li>不适用</li>
										<li>不适用</li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
					<div className="end12_cen1_chak">
						<div></div>
						查看详细
					</div>
				</div>
			</div>
			<div className="end12_centent">
				<div className="end12_cen1">
					<div className="end12_cen1_tit end12_zhengce">
						<div className="">
							<p>政策合规性</p>
							<p className="end12_textcc"><span>目标:</span> 0个问题<svg t="1611390779049" className="icon" viewBox="0 0 1024 1024" version="1.1"
								 xmlns="http://www.w3.org/2000/svg" p-id="2525" width="30" height="30">
									<path d="M512.03902167 545.14817828l-85.71858778-85.71712081c-3.59996944-3.60290341-9.44324503-3.60290341-13.04350788 0-3.6031968 3.59996944-3.6031968 9.44324503 0 13.04350786l92.24019503 92.24019503c3.59996944 3.60290341 9.44324503 3.60290341 13.04350788 0l92.23990161-92.24019503c1.80145171-1.80027811 2.70247094-4.16123607 2.70247096-6.52160724s-0.90101924-4.72132912-2.70247096-6.52160721c-3.59996944-3.60290341-9.44324503-3.60290341-13.04350785 0l-85.7182944 85.7171208z"
									 p-id="2526"></path>
								</svg></p>
						</div>
						<div className="end12_textcc">
							账户安全<span>bete<svg t="1611390779049" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
								 p-id="2525" width="30" height="30">
									<path d="M512.03902167 545.14817828l-85.71858778-85.71712081c-3.59996944-3.60290341-9.44324503-3.60290341-13.04350788 0-3.6031968 3.59996944-3.6031968 9.44324503 0 13.04350786l92.24019503 92.24019503c3.59996944 3.60290341 9.44324503 3.60290341 13.04350788 0l92.23990161-92.24019503c1.80145171-1.80027811 2.70247094-4.16123607 2.70247096-6.52160724s-0.90101924-4.72132912-2.70247096-6.52160721c-3.59996944-3.60290341-9.44324503-3.60290341-13.04350785 0l-85.7182944 85.7171208z"
									 p-id="2526"></path>
								</svg></span>
							<p className="end12_zhishu"><span></span><span><span>存在风险<svg t="1611404679298" className="icon" viewBox="0 0 1024 1024"
										 version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8064" width="20" height="20">
											<path d="M896 322L512.7 702 128 322h768z" p-id="8065" fill="#FEC400"></path>
										</svg></span></span><span></span></p>
						</div>
					</div>
					<div className="end12_weigui">
						<p>配送方：卖家和亚马逊</p>
						<ul>
							<li><span>涉嫌侵犯知识产权</span><span>0</span></li>
							<li><span>知识产权投诉</span><span>0</span></li>
							<li><span>商品真实性买家投诉</span><span>0</span></li>
							<li><span>商品状况买家投诉</span><span>0</span></li>
							<li><span>食品和商品安全问题</span><span><svg t="1611405397211" className="icon" viewBox="0 0 1104 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
									 p-id="9500" width="20" height="20">
										<path d="M961.369 1020.986H141.063c-55.01 0-99.202-21.701-121.272-59.552-22.076-37.85-19.39-87.293 7.396-135.606l413.89-746.48c26.941-48.666 67.004-76.561 109.866-76.561 42.867 0 82.89 27.86 109.907 76.483l414.323 746.635c26.78 48.314 29.55 97.72 7.433 135.565-22.035 37.85-66.263 59.516-121.237 59.516zM550.984 81.148c-13.628 0-29.239 13.595-41.894 36.362L95.202 864.067c-12.968 23.43-16.004 44.475-8.258 57.752 7.753 13.244 27.488 20.85 54.119 20.85h820.306c26.673 0 46.372-7.565 54.119-20.85 7.704-13.242 4.71-34.28-8.293-57.71l-414.318-746.6c-12.618-22.766-28.306-36.361-41.893-36.361z m0.233 586.348c-21.488 0-38.93-17.516-38.93-39.182v-352.63c0-21.63 17.442-39.183 38.93-39.183 21.492 0 38.934 17.554 38.934 39.183v352.63c0 21.666-17.442 39.182-38.934 39.182z m-0.279 191.523c33.503 0 60.663-27.382 60.663-61.16 0-33.777-27.16-61.159-60.663-61.159s-60.663 27.382-60.663 61.16c0 33.777 27.16 61.16 60.663 61.16z"
										 fill="#7A2A2B" p-id="9501"></path>
									</svg>1</span></li>
							<li><span>上架政策违规</span><span>0</span></li>
							<li><span>违反受限商品政策</span><span><svg t="1611405397211" className="icon" viewBox="0 0 1104 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
									 p-id="9500" width="20" height="20">
										<path d="M961.369 1020.986H141.063c-55.01 0-99.202-21.701-121.272-59.552-22.076-37.85-19.39-87.293 7.396-135.606l413.89-746.48c26.941-48.666 67.004-76.561 109.866-76.561 42.867 0 82.89 27.86 109.907 76.483l414.323 746.635c26.78 48.314 29.55 97.72 7.433 135.565-22.035 37.85-66.263 59.516-121.237 59.516zM550.984 81.148c-13.628 0-29.239 13.595-41.894 36.362L95.202 864.067c-12.968 23.43-16.004 44.475-8.258 57.752 7.753 13.244 27.488 20.85 54.119 20.85h820.306c26.673 0 46.372-7.565 54.119-20.85 7.704-13.242 4.71-34.28-8.293-57.71l-414.318-746.6c-12.618-22.766-28.306-36.361-41.893-36.361z m0.233 586.348c-21.488 0-38.93-17.516-38.93-39.182v-352.63c0-21.63 17.442-39.183 38.93-39.183 21.492 0 38.934 17.554 38.934 39.183v352.63c0 21.666-17.442 39.182-38.934 39.182z m-0.279 191.523c33.503 0 60.663-27.382 60.663-61.16 0-33.777-27.16-61.159-60.663-61.159s-60.663 27.382-60.663 61.16c0 33.777 27.16 61.16 60.663 61.16z"
										 fill="#7A2A2B" p-id="9501"></path>
									</svg>2</span></li>
							<li><span>违反买家商品评论政策</span><span>0</span></li>
							<li><span>其他违反政策</span><span>0</span></li>
						</ul>
					</div>
					<div className="end12_cen1_chak">
						<div></div>
						查看全部(3)
					</div>
					<div className="end12_centen2_weifan">
						<div>
							<span>违反政策警告</span>
							<span>0</span>
						</div>
					</div>
				</div>
				<div className="end12_cen1 end12_kehu">
					<div className="end12_cen1_tit">
						账户状况新闻
					</div>
					<ul className="end12_xinwen">
						<li><span>发布 "显示所有报价" 以帮助您对比报价</span><span>2021年1月13日</span></li>
						<li><span>获取账户状况问题的答案</span><span>2020年9月22日</span></li>
					</ul>
					<div className="end12_cen1_chak">
						<div></div>
						更多新闻
					</div>
				</div>
			</div>
			<div className="end12_centent">
				<div className="end12_cen1">
					<div className="end12_cen1_tit end12_zhengce">
						<div className="">
							<p>配送绩效</p>
						</div>
					</div>
					<div className="end12_weigui">
						<p className="end12_maijiaps">卖家自配送<svg t="1611390779049" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
							 p-id="2525" width="30" height="30">
								<path d="M512.03902167 545.14817828l-85.71858778-85.71712081c-3.59996944-3.60290341-9.44324503-3.60290341-13.04350788 0-3.6031968 3.59996944-3.6031968 9.44324503 0 13.04350786l92.24019503 92.24019503c3.59996944 3.60290341 9.44324503 3.60290341 13.04350788 0l92.23990161-92.24019503c1.80145171-1.80027811 2.70247094-4.16123607 2.70247096-6.52160724s-0.90101924-4.72132912-2.70247096-6.52160721c-3.59996944-3.60290341-9.44324503-3.60290341-13.04350785 0l-85.7182944 85.7171208z"
								 p-id="2526"></path>
							</svg></p>
						<div className="end12_cen1_text_2 end12_chonggou_text2">
							<div className="end12_cen1_text_1">
								<p className="end12_dingdan ">迟发率</p>
								<p className="end12_chonggou">不适用<svg t="1611390779049" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
									 p-id="2525" width="30" height="30">
										<path d="M512.03902167 545.14817828l-85.71858778-85.71712081c-3.59996944-3.60290341-9.44324503-3.60290341-13.04350788 0-3.6031968 3.59996944-3.6031968 9.44324503 0 13.04350786l92.24019503 92.24019503c3.59996944 3.60290341 9.44324503 3.60290341 13.04350788 0l92.23990161-92.24019503c1.80145171-1.80027811 2.70247094-4.16123607 2.70247096-6.52160724s-0.90101924-4.72132912-2.70247096-6.52160721c-3.59996944-3.60290341-9.44324503-3.60290341-13.04350785 0l-85.7182944 85.7171208z"
										 p-id="2526"></path>
									</svg></p>
							</div>
							<div className="end12_cen1_text_1 end12_text3">
								<p><span className="end12_bold">目标</span>: 低于1%</p>
								<p></p>
								<p></p>
							</div>
						</div>
						<div className="end12_cen1_text_2 end12_chonggou_text2">
							<div className="end12_cen1_text_1">
								<p className="end12_dingdan ">配送前取消率</p>
								<p className="end12_chonggou">不适用<svg t="1611390779049" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
									 p-id="2525" width="30" height="30">
										<path d="M512.03902167 545.14817828l-85.71858778-85.71712081c-3.59996944-3.60290341-9.44324503-3.60290341-13.04350788 0-3.6031968 3.59996944-3.6031968 9.44324503 0 13.04350786l92.24019503 92.24019503c3.59996944 3.60290341 9.44324503 3.60290341 13.04350788 0l92.23990161-92.24019503c1.80145171-1.80027811 2.70247094-4.16123607 2.70247096-6.52160724s-0.90101924-4.72132912-2.70247096-6.52160721c-3.59996944-3.60290341-9.44324503-3.60290341-13.04350785 0l-85.7182944 85.7171208z"
										 p-id="2526"></path>
									</svg></p>
							</div>
							<div className="end12_cen1_text_1 end12_text3">
								<p><span className="end12_bold">目标</span>: 低于2.5%</p>
								<p></p>
								<p></p>
							</div>
						</div>
						<div className="end12_cen1_text_2 end12_chonggou_text2">
							<div className="end12_cen1_text_1">
								<p className="end12_dingdan ">有效追踪率</p>
								<p className="end12_chonggou">不适用<svg t="1611390779049" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
									 p-id="2525" width="30" height="30">
										<path d="M512.03902167 545.14817828l-85.71858778-85.71712081c-3.59996944-3.60290341-9.44324503-3.60290341-13.04350788 0-3.6031968 3.59996944-3.6031968 9.44324503 0 13.04350786l92.24019503 92.24019503c3.59996944 3.60290341 9.44324503 3.60290341 13.04350788 0l92.23990161-92.24019503c1.80145171-1.80027811 2.70247094-4.16123607 2.70247096-6.52160724s-0.90101924-4.72132912-2.70247096-6.52160721c-3.59996944-3.60290341-9.44324503-3.60290341-13.04350785 0l-85.7182944 85.7171208z"
										 p-id="2526"></path>
									</svg></p>
							</div>
							<div className="end12_cen1_text_1 end12_text3">
								<p><span className="end12_bold">目标</span>: 超过95%</p>
								<p></p>
								<p></p>
							</div>
						</div>
						<p className="end12_cichu">在此处查看配送资格</p>
					</div>
					<div className="end12_cen1_chak">
						<div></div>
						查看详细
					</div>
				</div>
				<div className="end12_cen1 end12_kehu">
					<div className="end12_cen1_tit">
						商品合规性请求
					</div>
					<ul className="end12_xinwen">
						<li><span>文档请求</span><span className="end12_boldspan">0</span></li>
					</ul>
					<div className="end12_cen1_chak">
						<div></div>
						查看全部(0)
					</div>
				</div>
			</div>
		</div>

            </div>


            <Footer/>
        </div>
    )
}


export default AccountHealth