import { AInput, AText, AButton } from "@a-components";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import Apis from "@api/apis";
import style from "./style.module.css";

const BrandExclude = ({ excludedList = [], onExclude }) => {
  const [searchValue, setSearchValue] = useState("");

  const [searchBrandList, setSearchBrandList] = useState([]);
  const [myBrandList, setMyBrandList] = useState([]);

  const [isSearching, setSearchStatus] = useState(false);

  const excludedIds = useMemo(() => {
    return excludedList.map((v) => v.directionalId);
  }, [excludedList]);

  const canExcludeBrandList = useMemo(() => {
    return myBrandList.filter((v) => !excludedIds.includes(v.directionalId));
  }, [excludedIds, myBrandList]);

  const queryBrand = useCallback((searchValue) => {
    return Apis.brand.queryBrand({ brandName: searchValue }).then((res) => {
      res.data = res.data.map(({ brandId, brandName }) => {
        return {
          directionalId: brandId,
          directionalName: brandName,
          negativeDirectionalType: 1,
        };
      });

      return res;
    });
  }, []);

  const queryMyBrandList = useCallback(() => {
    return queryBrand("").then((res) => {
      setMyBrandList(res.data);
    });
  }, [queryBrand]);

  const queryBrandList = useCallback(() => {
    if (!searchValue) return Promise.resolve({});
    setSearchStatus(true);
    return queryBrand(searchValue).then((res) => {
      setSearchBrandList(res.data);
    });
  }, [searchValue, queryBrand]);

  const handleSearch = useCallback(() => {
    queryBrandList();
  }, [queryBrandList]);

  useEffect(() => {
    if (!searchValue) {
      setSearchBrandList([]);
      setSearchStatus(false);
    }
  }, [searchValue]);

  useEffect(() => {
    queryMyBrandList();
  }, [queryMyBrandList]);

  const handleExclude = useCallback(
    (item) => {
      onExclude?.([item]);
    },
    [onExclude]
  );

  const handleExcludeAll = useCallback(() => {
    onExclude?.([].concat(canExcludeBrandList));
  }, [canExcludeBrandList, onExclude]);

  const renderBrandItem = useCallback(
    (item) => {
      const { directionalId, directionalName } = item;
      const isExcluded = excludedIds.includes(directionalId);
      return (
        <div
          key={directionalId}
          className="brand-item h-44 df jc-sb ai-c border-b-ccc"
        >
          <AText className="fs-12">{directionalName}</AText>
          <AButton
            disabled={isExcluded}
            size="small"
            onClick={() => handleExclude(item)}
          >
            {isExcluded ? "已" : ""}排除
          </AButton>
        </div>
      );
    },
    [excludedIds, handleExclude]
  );

  const renderSearchBrandListMask = useCallback(() => {
    return (
      <div className={classNames(style.brandList, 'overflow-auto')}>
        {searchBrandList.map(renderBrandItem)}
      </div>
    );
  }, [searchBrandList, renderBrandItem]);

  return (
    <div style={{ minHeight: 400 }}>
      <div className="h-44 border-b-ccc ph-12">
        <AInput.Search
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          allowClear
          onSearch={handleSearch}
          placeholder="按品牌名称进行搜索"
        />
      </div>
      <div className="df fd-c mh-24">
        {isSearching ? (
          renderSearchBrandListMask()
        ) : (
          <>
            <div className="df jc-sb ai-c h-44 border-b-ccc">
              <AText strong>您的品牌</AText>

              <AButton
                disabled={!canExcludeBrandList.length}
                onClick={handleExcludeAll}
              >
                排除全部
              </AButton>
            </div>
            <div
              className={classNames(style.brandList, 'overflow-auto')}
            >
              {myBrandList.map(renderBrandItem)}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BrandExclude;
