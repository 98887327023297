import classNames from "classnames";
import { AButton, ATable } from "@a-components/";

import { Space } from "antd";

const tableColumns = [
  {
    title: "更改类型",
    dataIndex: "goodsAsin",
  },
  {
    title: "发件人",
    dataIndex: "goodsAsin",
  },
  {
    title: "收件人",
    dataIndex: "goodsAsin",
  },
  {
    title: "日期和时间",
    dataIndex: "goodsAsin",
  },
];

const PutNegativeGoods = ({ className }) => {
  return (
    <div className={classNames(className)}>
      {/* 列表头部 */}
      <div className="p-12 df jc-sb border-ccc bg-f8">
        <Space>
          <AButton>筛选条件</AButton>
        </Space>

        <AButton>日期范围 - 全部</AButton>
      </div>
      <ATable columns={tableColumns} dataSource={[]} bordered rowSelection />
    </div>
  );
};

export default PutNegativeGoods;
