import React,{useEffect,useState} from 'react';
import {Container,Main,Logo} from './style';
import Nav from './children/nav';
import HeaderRight from './children/headerRight';
import {Link} from 'react-router-dom';

export default () => {
    let [flag,setFlag] = useState(false);
    useEffect(()=>{
        let resize = (e) => {
            let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
            if(clientWidth <= 1670){
                setFlag(true);
            }else{
                setFlag(false);
            }
        }
        resize();
        window.addEventListener('resize',resize);
        return () => {
            window.removeEventListener('resize',resize);
        }
    },[])

    return <Container>
        <Main>
            <div>
                <Link to='/'><Logo/></Link>
                {!flag && <Nav/>}
            </div>
            <HeaderRight/>
        </Main>
        {flag && <Nav/>}
    </Container>
}