import React from 'react'
import './MengkSiderContent.css'
import { Col, Row, Radio } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

function MengkSiderContent() {

  return (
    <div className="MengkSiderContent" >
      <div className="MengkSiderContent-header" >
        <Radio.Group>
          <Radio.Button value={1}>移动</Radio.Button>
          <Radio.Button value={2}>桌面</Radio.Button>
        </Radio.Group>
        <span>新功能</span>
      </div>
      <div className="MengkSiderContent-body" >
        <h3>Mengk</h3>
        <Row justify="space-around" >
          <Col span={11} className="mengk-hover" >+添加模板</Col>
          <Col span={11} style={{ display: "flex", flexDirection: "column" }} >
            <div style={{ width: "100%", height: "49%" }} className="mengk-hover" ></div>
            <div style={{ width: "100%", height: "49%" }} className="mengk-hover" ></div>
          </Col>
        </Row>
        <Row justify="space-around" >
          <Col span={11} style={{ display: "flex", flexDirection: "column" }} >
            <div style={{ width: "100%", height: "49%" }} className="mengk-hover" >+添加模板</div>
            <div style={{ width: "100%", height: "49%" }} className="mengk-hover" >+添加模板</div>
          </Col>
          <Col span={11} className="mengk-hover" >+添加模板</Col>
        </Row>
        <Row justify="space-around" >
          <Col span={5} className="mengk-hover" style={{ height: "20vh" }} >+添加模板</Col>
          <Col span={5} className="mengk-hover" style={{ height: "20vh" }} >+添加模板</Col>
          <Col span={5} className="mengk-hover" style={{ height: "20vh" }} >+添加模板</Col>
          <Col span={5} className="mengk-hover" style={{ height: "20vh" }} >+添加模板</Col>
        </Row>
        <Row justify="space-around" >
          <Col span={5} className="mengk-hover" style={{ height: "20vh" }} >+添加模板</Col>
          <Col span={5} className="mengk-hover" style={{ height: "20vh" }} >+添加模板</Col>
          <Col span={5} className="mengk-hover" style={{ height: "20vh" }} >+添加模板</Col>
          <Col span={5} className="mengk-hover" style={{ height: "20vh" }} >+添加模板</Col>
        </Row>
        <div style={{ marginBottom: 20 }} >
          <h3 style={{ margin: 0, textAlign: "center", fontSize: 18, fontWeight: 700 }} >为你推荐</h3>
          <div className="MengkSiderContent-package" >
            <div><LeftOutlined style={{ color: "#000" }} /></div>
            <div><RightOutlined /></div>
          </div>
        </div>

        <div>
          <h3 style={{ margin: 0, textAlign: "center", fontSize: 18, fontWeight: 700 }} >Mengk 畅销商品</h3>
          <div className="MengkSiderContent-package" >
            <div><LeftOutlined style={{ color: "#000" }} /></div>
            <div><RightOutlined /></div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default MengkSiderContent