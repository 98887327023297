import React, { useRef, useEffect, useCallback, useState } from 'react';
import AJAX from "api/apis";
import "./sendEmails/index.css";
import cookieUtils from "utils/cookie";
import { Container, FormContainer, FormTitle, FormItemTop, BottomLine, ToLogin } from './sendEmails/style';
import { Form, Input, Button, Modal, message, Select } from 'antd';
import HeaderImage from 'components/headerimage';
import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"
import { getCheckAddressList2 } from 'utils/regDate';
const { Option } = Select;


export default (props) => {
  let formRef = useRef();
  let sbuFormRef = useRef();
  let [loading, setLoading] = useState(false);
  let [randoms, setRandoms] = useState();
  let [isTourOpen, setIsTourOpen] = useState(true);
  let steps = {
    0: [
      {
        selector: '.stepGuidanceBox0',
        content: '请输入注册亚马逊需要验证的手机号码',
      }
    ],
    1: [
      {
        selector: '.stepGuidanceBox0',
        content: '亚马逊会给你提供的手机号码发一个验证码,输入验证码即可',
      }
    ]
  };
  const [isActive, setIsActive] = useState(false);
  const awakenTips = (type) => {
    setIsActive(type);
  }
  const [userMobile, setUserMobile] = useState()
  const [step, setStep] = useState(0)
  const companyAddressList = getCheckAddressList2()

  let [isModalVisible, setIsModalVisible] = useState(false)
  useEffect(() => {
    MathRand()
  }, [])
  let MathRand = useCallback(() => {
    let Num = "";
    for (let i = 0; i < 6; i++) {
      Num += Math.floor(Math.random() * 10);
    }
    setRandoms(Num)
  })
  const handleRegister = useCallback(() => {
    if (loading) return;
    setLoading(true);
    formRef.current.validateFields().then(({ mobile }) => {
      setLoading(false);
      setUserMobile(mobile)
      if (mobile) {
        setStep(1)
        MathRand()
      }
    }).catch(() => {
      setLoading(false);
    })
  })
  const sbu = useCallback(() => {
    if (loading) return;
    setLoading(true);
    sbuFormRef.current.validateFields().then(({ code }) => {
      setLoading(false);
      if (code) {
        // props.history.push('/Agreements');
        AJAX.login.activateUser({ contactNum: userMobile }).then((res) => {
          if (res.code == 1) {
            cookieUtils.setCookie('amazon_token', res.data)
            setTimeout(() => {
              // window.location.href = "/Agreements"
              window.location.href = `/practice_amazon/#/Agreements?receptionStoreId=${window.receptionStoreId}`;
            }, 100)
          };
        }).catch(() => {
          setLoading(false);
        })
      } else {
        message.error("验证码不一致")
      }
    }).catch(() => {
      setLoading(false);
    })
  }, [randoms, userMobile])
  const emailModal = useCallback(() => {
    setIsModalVisible(true)
  })
  const hideModalVisible = useCallback(() => {
    setIsModalVisible(false)
  })
  return <Container className="sendEmailBox mobileNumber">
    {/* 引导步骤 */}
    <TipsIcon clicks={awakenTips} />
    <Reactours routePath="sendEmails" isActive={isActive} clicks={awakenTips} steps={steps[step]} isOpen={isTourOpen} />
    <HeaderImage />
    {
      !step ? (
        <FormContainer>
          <FormTitle>添加手机号码</FormTitle>
          <p>添加手机号码以保护您的亚马逊帐户。注册您的手机号码，即表示您同意接收亚马逊自动发送的与帐户安全相关的短信。您可以通过在“登录和安全”设置中删除手机号码来选择不接收帐户安全相关的消息。可能会收取短信和数据费用。</p>
          <Form ref={formRef}>
            <div className="stepGuidanceBox0 page1">
              <FormItemTop>
                <p style={{ margin: "10px 0" }} className='title'>新手机号码</p>
              </FormItemTop>
              <Form.Item>
                <Input.Group compact>
                  <Form.Item
                    name="province"
                    noStyle
                    // rules={[{ required: true, message: 'Province is required' }]}
                    initialValue="US +1"
                  >
                    <Select placeholder="Select province" options={companyAddressList}></Select>
                  </Form.Item>
                  <Form.Item
                    name="mobile"
                    noStyle
                    rules={[{
                      required: true,
                      validator: (rule, value, cbfn) => {
                        let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                        if (!value) {
                          cbfn(<span style={{ paddingLeft: '90px' }}>请输入手机号码</span>)
                        } else if (!reg.test(value)) {
                          cbfn('！您所输入的电话号码无效。请输入一个有效的电话号码。')
                        }
                        cbfn()
                      }
                    }]}
                  >
                    <Input maxLength="11" />
                  </Form.Item>
                </Input.Group>
              </Form.Item>

            </div>
            <Button style={{ margin: "20px 0" }} className='next' type="primary" loading={loading} onClick={handleRegister}> 添加手机号码 </Button>
          </Form>
          {/* <p onClick={MathRand} style={{ color: '#0066c0', cursor: "pointer", textAlign: 'center', marginTop: '20px' }}>再次发送确认码</p> */}
        </FormContainer>
      ) : (
        <FormContainer>
          <FormTitle>添加手机号码</FormTitle>
          <p>+86{userMobile} <a onClick={() => { setStep(0); MathRand() }}>变更</a></p>
          <p style={{ margin: "10px 0 0" }}>代码已发送至您的手机。请将代码输入下方。</p>
          <Form ref={sbuFormRef}>
            <div className="stepGuidanceBox0">
              <FormItemTop>
                <p style={{ margin: "10px 0" }} className='title'>输入验证码</p>
              </FormItemTop>
              <Form.Item
                name="code"
                rules={[{
                  required: true,
                  validator: (rule, value, cbfn) => {
                    if (!value) {
                      cbfn('请输入6位数验证码')
                    } else if (value.length < 6) {
                      cbfn('验证码应为6位数')
                    } else if (Number(value) !== Number(randoms)) {
                      cbfn('无效的验证码. 请检查您的验证码并重试。')
                    }
                    cbfn()
                  }
                }]}>
                <Input maxLength="6" />
              </Form.Item>
            </div>
            <Button style={{ margin: "20px 0" }} className='next' type="primary" loading={loading} onClick={sbu}> 创建您的亚马逊账户 </Button>
            <p style={{ fontSize: '12px' }}>创建账户，即视为您同意遵守 Amazon 的<a>使用条件</a>和<a>隐私声明</a>。</p>
          </Form>
          <p onClick={MathRand} style={{ textAlign: 'center', marginTop: '20px' }} className="link_a">再次发送确认码</p>
        </FormContainer>
      )
    }
    {
      step ? (
        <div className="elailTips" onClick={emailModal}>
          <img src={require("@/assets/img/register/phone_pinComing.png").default} />
        </div>
      ) : null
    }
    <Modal title="验证码" className="meailModalBox" centered visible={isModalVisible} footer={null} onCancel={hideModalVisible}>
      <p className="otp">{randoms}</p>
      <p>【亚马逊】Message from Amazon:Your one-time PIN is {randoms}</p>
    </Modal>


    <BottomLine>
      <p>© 1996-2022, Amazon.com, Inc. or its affiliates</p>
    </BottomLine>
  </Container>
}