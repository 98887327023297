import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useImperativeHandle,
} from "react";
import { ATable, AButton, ASwitch, AInput, ASelect } from "@a-components/";
import { DatePicker, Space, Menu, Dropdown } from "antd";
import { useHistory } from "react-router-dom";
import { SearchOutlined, DownOutlined } from "@ant-design/icons";
import classNames from "classnames";
import Apis from "@api/apis";

import {
  BusinessTypeMapping,
  customColumnsOptions,
  SuperTableConfigMapping,
} from "./config";
import usePaginationTable from "@hooks/usePaginationTable";
import { useEventEmitter } from "ahooks";
import item from "@view/home/children/cards/item";

const { RangePicker } = DatePicker;

// businessType - 业务类型 campaign-广告活动列表 | goods-商品列表 | group-广告组列表
const AdvertisingSuperTable = ({
  businessId = 0,
  businessType = BusinessTypeMapping.Campaign,
  className,
  otherData = {},
  event$: _event$,
}) => {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState("");
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [loading, setLoading] = useState(false)
  const {
    createBtn: _createBtn,
    searchInput,
    queryFetcher,
    tableColumns,
    tableDataKey,
    tableConfig = {},
    filterConditionOptions,
    $dependencies = [],
  } = SuperTableConfigMapping[businessType];

  const __event$ = useEventEmitter();

  const event$ = _event$ || __event$;

  const {
    pagination,
    tableList: _tableList,
    isTableLoading,
    operator: { reFetch, resetPageIndex, clearTableList, updatePageInfo },
  } = usePaginationTable(
    {
      fetcher: queryFetcher,
      params: { searchValue, businessId: String(businessId || "") },
      onPageIndexChange() {
        setSelectedKeys([]);
      },
    },
    [businessId]
  );

  useEffect(() => {
    setSelectedKeys([]);
    clearTableList();
  }, [businessId, clearTableList]);

  const tableList = useMemo(() => {
    return _tableList.map((item) => ({
      ...item,
      endTime: item.endTime == null ? '无结束日期' : item.endTime,
      key: item[tableDataKey],
    }));
  }, [_tableList, tableDataKey]);

  event$.useSubscription(({ type, payload }) => {
    switch (type) {
      case "reFetch":
        reFetch();
        break;
      case "resetPageIndex":
        resetPageIndex();
        break;
      default:
        break;
    }
  });

  const _otherData = $dependencies.reduce((r, key) => {
    r[key] = otherData[key];
    return r;
  }, {});

  const requireDepKeys = useMemo(() => {
    return Object.keys(otherData).filter((v) => !$dependencies.includes(v));
  }, [$dependencies, otherData]);

  if (requireDepKeys.length) {
    throw new Error(
      `the key '${requireDepKeys[0]}' is required in field '$dependencies'`
    );
  }

  let createBtn =
    typeof _createBtn === "function"
      ? _createBtn({ ..._otherData, event$ }, history)
      : _createBtn;

  const customMenu = (
    <Menu>
      {customColumnsOptions.map((item) => {
        return <Menu.Item key={item.value}>{item.label}</Menu.Item>;
      })}
    </Menu>
  );

  const _realTableColumns = useMemo(() => {
    return tableColumns.map((item) => {
      const part = item.render
        ? {
          render: (text, record, index) =>
            item.render(text, { ...record, event$ }, index),
        }
        : {};
      return {
        ...item,
        ...part,
      };
    });
  }, [event$, tableColumns]);

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedKeys(selectedRowKeys);
    },
  };
  const updateAdvertisingStatus = useCallback(() => {
    if (loading) return;
    setLoading(true)
    Apis.advertising.updateAdvertisingStatus({
      advertisingIds: selectedKeys.join(","),
      advertisingStatus: 3
    }).then(res => {
      if (res.code == 1) {
        reFetch()
        setLoading(false)
      }
    }).catch(() => {
      setLoading(false)
    })
  }, [selectedKeys, loading])

  const deleteAdvertising = useCallback(() => {
    if (loading) return;
    setLoading(true)
    Apis.advertising.deleteAdvertising({
      advertisingIds: selectedKeys.join(","),
    }).then(res => {
      if (res.code == 1) {
        reFetch()
        setLoading(false)
      }
    }).catch(() => {
      setLoading(false)
    })
  }, [selectedKeys, loading])

  return (
    <div className={classNames(className)}>
      {/* 列表头部 */}
      <div className="p-12 df jc-sb border-ccc bg-f8" style={{ width: "100%" }}>
        <Space className="df ai-c">
          {createBtn ? <AButton type="primary" {...createBtn}></AButton> : null}
          {searchInput ? (
            <AInput
              prefix={<SearchOutlined />}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={searchInput.placeholder}
              onPressEnter={() => reFetch()}
            />
          ) : null}

          {filterConditionOptions ? (
            <ASelect
              options={filterConditionOptions}
              style={{ width: 120 }}
              placeholder="筛选条件"
            ></ASelect>
          ) : null}
          {selectedKeys.length > 0 ? <AButton onClick={updateAdvertisingStatus}>存档</AButton> : null}
          {selectedKeys.length > 0 ? <AButton onClick={deleteAdvertising}>删除</AButton> : null}

        </Space>

        <Space className="df ai-c">
          {/* <AButton type="link">隐藏列表</AButton> */}
          <Dropdown overlay={customMenu} trigger={["click"]}>
            <AButton>
              列<DownOutlined />
            </AButton>
          </Dropdown>

          <RangePicker renderExtraFooter={() => "extra footer"} />
          <AButton>导出</AButton>
        </Space>
      </div>
      <ATable
        scroll={{ x: 800 }}
        {...tableConfig}
        loading={isTableLoading}
        columns={_realTableColumns}
        dataSource={tableList}
        pagination={pagination}
        bordered
        rowSelection={rowSelection}
      />
    </div>
  );
};

export default AdvertisingSuperTable;
