import InformationHead from '../informationHead/informationHead'
import React from 'react'
import './informationASIN.css'
import IconFont from 'components/Icon/Icon'
import { Link } from 'react-router-dom'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import { Table, Radio, Divider } from 'antd';

function informationASIN() {

  function ClickBack() {
    window.history.back(-1);
  }
  const columns = [
    {
      title: '',
      dataIndex: 'image',
      with: "10%",
      render: (text) => {
        return (
          <div >
            <img style={{ width: 150 }} alt="example" src={require("@/assets/img/home/card-4.png").default} />
          </div>
        )
      },
    },
    {
      title: '商品名称 ASIN',
      dataIndex: 'name',
      render: (text) => {
        return (
          <div>
            <div style={{ color: "#008296" }} >Wonderlan Pet Styles Fashion Pure Cotton Functionality Life Vest Body Armor Lifeguard Police Dog Small Middle Large Animals</div>
            <div>B097ZHB4565</div>
            <div className="A_alert_new" >父ASIN</div>
          </div>
        )
      },
    },
    {
      title: '应用日期',
      dataIndex: 'age',
      with: "10%",
    },
    {
      title: 'ASIN 状态',
      dataIndex: 'status',
      with: "10%",
    },
  ];

  const data = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      status: '未发布',
      image: "@/assets/img/home/card-4.png",
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div>
      <Header />
      <div className="inforASIN_body">
        <div>
          <div className="Details_head">
            <div className="Details_headI">
              <div >
                <Link to="/Aa" style={{ color: "#008296" }}>A+商品描述管理器</Link>
              </div>
              <span>
                <IconFont type="icon-fanhui-copy-copy" />
              </span>
              <div>增强型商品描述</div>
              <span>
                <IconFont type="icon-fanhui-copy-copy" />
              </span>
              <div>ASIN</div>
              <span>
                <IconFont type="icon-fanhui-copy-copy" />
              </span>
              <div>编辑</div>
            </div>

            <div className="inforASIN_head">
              <div className="informationHead_headII_btn"><button>取消</button></div>
              <div className="informationHead_headII_btn" ><button onClick={ClickBack}>返回</button></div>
              <div className="informationHead_headII_btnI"><button>保存为草稿</button></div>
              <div className="informationHead_headII_btnII"><button><Link to="informationDetails" >下一步：查看并提交</Link></button></div>
            </div>

          </div>
          <div>

          </div>
        </div>
        <InformationHead />

        <div>
          <div className="inforASIN_title"><h2>添加ASIN</h2></div>
          <div>
            <div>搜索要应用到此商品描述的ASIN。请注意，系统会在商品系列的上下文中返回搜索结果。</div>
            <div className="inforASIN_inpBox">
              <div className="inforASIN_inp">
                <IconFont className="ASIN_inp_font" type="icon-sousuo" />
                <input placeholder="搜索ASIN" />
              </div>
              <div className="informationHead_headII_btnI"><button>批量上传</button></div>
            </div>
          </div>
        </div>

        <div>
          <div className="inforASIN_titleII"><h2>已应用的ASIN(0)</h2></div>
          <div>

            <div className="inforASIN_ASINIIBox">
              <div className="inforASIN_ASINIIBox_inpbox">
                <div className="inforASIN_ASINIIBox_inp">
                  <IconFont className="ASIN_inp_font" type="icon-sousuo" />
                  <input placeholder="查找" />
                </div>
                <div className="informationHead_headII_btnI"><button>删除</button></div>
              </div>
              <Table
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={data}
              />
            </div>


          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default informationASIN