import React from 'react'
import './TaxDocument.css'
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'


function TaxDocument(){
    return(
        <div>
            <Header/>
                <div>
                <div className="end19_content">
			<ul>
				<li>税务文件库</li>
				<li>卖家费用税务发票</li>
				<li>Tax-Exemption certificates</li>
				<li>墨西哥税务发票</li>
			</ul>
			<div className="end19_content_title">
				<span>税务文件库</span>
				<a href="#">了解更多信息</a>
			</div>
			<div className="end19_content_report">
				<div> 销售税报告</div>
				<div>
					<span>没有看你需要的文档?</span>
					<button type="button">生成一份纳税报告</button>
				</div>
			</div>
		</div>


                </div>
            <Footer/>
        </div>
    )
}

export default TaxDocument