import { Button } from "antd";
import { ATitleTooltip, ACheckboxGroup, ATable, AText } from "@a-components/";
import style from "./style.module.css";
import classNames from "classnames";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useMemo, useState, useCallback, useEffect } from "react";
import Apis from "@api/apis";
import KeywordCondition from "../KeywordCondition";
import { computedKeywordsId } from "./../config";
import { useReallyValue } from "@hooks";

/**
 * 
  {
    goodsName: "商品名",
    keyword: "keywordddddssss",
    suggestKeywordTypeVoList: [
      {
        matchType: 1,
        matchTypeName: "广泛",
        suggestedBidding: 11,
        suggestedBiddingEnd: 15,
        suggestedBiddingStart: 3,
      },
      {
        matchType: 2,
        matchTypeName: "词组",
        suggestedBidding: 11,
        suggestedBiddingEnd: 15,
        suggestedBiddingStart: 3,
      },
      {
        matchType: 3,
        matchTypeName: "精确",
        suggestedBidding: 11,
        suggestedBiddingEnd: 15,
        suggestedBiddingStart: 3,
      },
    ],
  },
 */
const dataSource = [];

const KeywordSuggestion = ({
  goodsIds,
  selectedKeywordsList,
  onAddKeywords,
}) => {
  const [conditionValues, setConditionValues] = useState({
    biddingType: "建议竞价",
    matchTypes: [1, 2, 3],
  });

  const [keywordsList, setKeywordsList] = useState([]);

  const { biddingType, matchTypes } = conditionValues;

  const innerGoodsId = useReallyValue(goodsIds);

  const queryKeywordsList = useCallback(() => {
    return Apis.advertising
      .getAdvertisingSuggestKeywordList({ goodsIds: innerGoodsId.join(",") })
      .then((res) => {
        if (res.code !== 1) return res;
        setKeywordsList(res.data);
        return res;
      });
  }, [innerGoodsId]);

  useEffect(() => {
    queryKeywordsList();
  }, [queryKeywordsList]);

  const realDataSource = useMemo(() => {
    return matchTypes.length
      ? keywordsList.map((item) => {
        return {
          ...item,
          suggestKeywordTypeVoList: item.suggestKeywordTypeVoList.filter(
            (v) => matchTypes.includes(v.matchType)
          ),
          key: item.keyword,
        };
      })
      : [];
  }, [matchTypes, keywordsList]);

  const unSelectKeywordList = useMemo(() => {
    const selectedIds = selectedKeywordsList.map((v) => v.$id);
    return realDataSource
      .map(({ suggestKeywordTypeVoList, ...otherItem }) => {
        return suggestKeywordTypeVoList.map((subItem) => {
          return {
            ...otherItem,
            ...subItem,
            biddingPrice: subItem.suggestedBidding,
            $id: computedKeywordsId({
              keyword: otherItem.keyword,
              matchType: subItem.matchType,
            }),
          };
        });
      })
      .flat()
      .filter((v) => {
        return !selectedIds.includes(v.$id);
      });
  }, [realDataSource, selectedKeywordsList]);

  const isAllAdded = useMemo(() => {
    return unSelectKeywordList.length === 0;
  }, [unSelectKeywordList]);

  const addAll = useCallback(() => {
    onAddKeywords?.(unSelectKeywordList);
  }, [onAddKeywords, unSelectKeywordList]);

  const columns = useMemo(() => {
    return [
      {
        title: "关键词",
        dataIndex: "keyword",
        key: "keyword",
        width: "30%",
        render(keyword, record) {
          return (
            <div>
              <div className="fs-12 lh-22">{keyword}</div>
              <div className="fs-12 lh-22">{record.goodsName}</div>
            </div>
          );
        },
      },
      {
        title: "匹配类型",
        dataIndex: "suggestKeywordTypeVoList",
        key: "suggestKeywordTypeVoList",
        width: "18%",
        render(keywordTypeList) {
          const list = keywordTypeList.filter((v) =>
            matchTypes.includes(v.matchType)
          );
          return (
            <div>
              {list.map((item) => {
                return (
                  <div className="fs-12 lh-22" key={item.matchType}>
                    {item.matchTypeName}
                  </div>
                );
              })}
            </div>
          );
        },
      },
      {
        title: "建议竞价",
        dataIndex: "suggestKeywordTypeVoList",
        key: "suggestedBidding",
        width: "24%",
        render(keywordTypeList) {
          const list = keywordTypeList.filter((v) =>
            matchTypes.includes(v.matchType)
          );
          return (
            <div>
              {list.map((item) => {
                return (
                  <div className="fs-12 lh-22" key={item.matchType}>
                    ${item.suggestedBiddingStart.toFixed(2)} - $
                    {item.suggestedBiddingEnd.toFixed(2)}
                  </div>
                );
              })}
            </div>
          );
        },
      },
      {
        title: (
          <Button
            type="link"
            style={{ padding: 0 }}
            disabled={!matchTypes.length || isAllAdded}
            onClick={addAll}
          >
            添加全部
          </Button>
        ),
        dataIndex: "suggestKeywordTypeVoList",
        key: "addAll",
        width: "14%",
        align: "right",
        render(keywordsList, record) {
          const list = keywordsList.filter((v) =>
            matchTypes.includes(v.matchType)
          );
          return (
            <div>
              {list.map((item) => {
                const $id = computedKeywordsId({
                  keyword: record.keyword,
                  matchType: item.matchType,
                });

                const isDisabled = !!selectedKeywordsList.find(
                  (v) => v.$id === $id
                );

                const handleAdd = () => {
                  onAddKeywords?.({
                    ...item,
                    biddingPrice: item.suggestedBidding,
                    keyword: record.keyword,
                  });
                };

                return (
                  <div key={item.matchType}>
                    <Button
                      className="d-ib p-0"
                      disabled={isDisabled}
                      type="link"
                      style={{ height: 22 }}
                      onClick={handleAdd}
                    >
                      {isDisabled ? "已" : ""}添加
                    </Button>
                  </div>
                );
              })}
            </div>
          );
        },
      },
    ];
  }, [addAll, isAllAdded, matchTypes, onAddKeywords, selectedKeywordsList]);

  // console.log(123456)
  return (
    <div>
      <KeywordCondition value={conditionValues} onChange={setConditionValues} />
      <div>
        <ATable
          scroll={{ y: 476 }}
          size="small"
          dataSource={realDataSource}
          columns={columns}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default KeywordSuggestion;
