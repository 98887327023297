import clone from 'utils/clone';
const initState = {
    // logging:false
}

export default (state = initState, action) => {
    //防止用户重复点击登陆按钮
    // if (action.type === 'SET_LOGGING') {
    //     return {
    //         ...state,
    //         logging:action.logging
    //     }
    // }
    return state;
}