import React from 'react'
import './VicoOfTheCustomer.css'
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import imgVico from 'assets/images/end13_shuiguoji.png'


function VicoOfTheCustomer() {
	return (
		<div>
			<Header />
			<div>

				<div className="end13_navlist">
					<ul>
						<li>账户状况</li>
						<li>客户服务绩效</li>
						<li>商品政策合规性</li>
						<li>配送绩效</li>
						<li>报告<svg t="1611390779049" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
							p-id="2525" width="30" height="30">
							<path d="M512.03902167 545.14817828l-85.71858778-85.71712081c-3.59996944-3.60290341-9.44324503-3.60290341-13.04350788 0-3.6031968 3.59996944-3.6031968 9.44324503 0 13.04350786l92.24019503 92.24019503c3.59996944 3.60290341 9.44324503 3.60290341 13.04350788 0l92.23990161-92.24019503c1.80145171-1.80027811 2.70247094-4.16123607 2.70247096-6.52160724s-0.90101924-4.72132912-2.70247096-6.52160721c-3.59996944-3.60290341-9.44324503-3.60290341-13.04350785 0l-85.7182944 85.7171208z"
								p-id="2526"></path>
						</svg></li>
						<li>资格条件</li>
						<li>买家之声</li>
					</ul>
				</div>

				<div className="end13_lllff">

					<ul className="end13_rongyutit">
						<li>买家之声</li>
						<li>了解更多信息<span> | </span>对我们的工作做出评价</li>
					</ul>
					<p>使用此页面了解商品的买家满意度状况、查看买家对商品或商品信息的反馈，并采取措施解决商品和商品信息的问题。</p>

					<div className="end13_mingxi">
						<ul>
							<li>
								<p>您商品的买家满意度状况明细</p>
								<p>i 如何衡量买家满意度状况？</p>
							</li>
							<li>

							</li>
							<li className="end13_leftxian">
								<p className="end13_left_but end13_red">极差</p>
								<p>0</p>
								<p>查看商品信息</p>
							</li>
							<li className="end13_leftxian">
								<p className="end13_left_but end13_orange">不合格</p>
								<p>0</p>
								<p>查看商品信息</p>
							</li>
							<li className="end13_leftxian">
								<p className="end13_left_but end13_yell">一般</p>
								<p>1</p>
								<p>查看商品信息</p>
							</li>
							<li className="end13_leftxian">
								<p className="end13_left_but end13_green">良好</p>
								<p>10</p>
								<p>查看商品信息</p>
							</li>
							<li className="end13_leftxian">
								<p className="end13_left_but end13_green2">极好</p>
								<p>3</p>
								<p>查看商品信息</p>
							</li>
						</ul>
					</div>

					<div className="end13_xuanze">
						<div>
							<svg t="1610710871713" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5616"
								width="20" height="15">
								<path d="M999.376 939.84L765.632 706.064a422.752 422.752 0 0 0 96-268.64C861.632 203.232 671.136 12.72 436.928 12.72S12.224 203.232 12.224 437.424 202.72 862.112 436.928 862.112a422.512 422.512 0 0 0 269.744-96.976L940.208 998.88a41.888 41.888 0 0 0 29.776 12.368c10.688 0 21.408-4 29.664-12.16a41.904 41.904 0 0 0-0.272-59.248zM95.936 437.424c0-188.048 153.04-341.088 341.088-341.088 188.032 0 341.104 153.04 341.104 341.088 0 188.032-153.056 341.104-341.104 341.104-188.048 0-341.088-153.056-341.088-341.104z"
									fill="#707070" p-id="5617"></path>
							</svg>
							<input type="text" placeholder="搜索商品名称、ASIN" />
							<button>搜索</button>
						</div>
						<div>
							筛选条件：0项已应用 <svg t="1610710586242" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
								p-id="4145" width="15" height="10">
								<path d="M512.18365184 668.00877702l-403.42645487-403.41955064c-16.9429169-16.9567253-44.44374272-16.9567253-61.38804048 0-16.95810612 16.9429169-16.95810614 44.44374272 0 61.38804046l434.11978468 434.11978467c16.9429169 16.9567253 44.44374272 16.9567253 61.38804047 0l434.11840384-434.11978467c8.47836265-8.4728393 12.71892482-19.58446539 12.71892481-30.69332981s-4.24056217-22.22049053-12.71892479-30.69332982c-16.9429169-16.9567253-44.44374272-16.9567253-61.38804049-1e-8l-403.42507402 403.41955068z"
									p-id="4146" fill="#2c2c2c"></path>
							</svg>
						</div>
						<div>15 商品信息</div>
					</div>

					<div className="end13_dataList">
						<table className="end13_tab" cellspacing="0" cellpadding="0">
							<thead>
								<tr>
									<th>
										<p>图片</p>
									</th>
									<th>
										<p>商品名称</p>
										<p>ASIN</p>
									</th>
									<th>
										<p>SKU</p>
										<p>状况</p>
									</th>
									<th>
										<p>订单配送方</p>
									</th>
									<th>
										<p>买家不满意率</p>
									</th>
									<th>
										<p className="end13_bule">买家不满意订单</p>
									</th>
									<th>
										<p className="end13_bule">订单总数</p>
									</th>
									<th>
										<p>主要退货原因</p>
									</th>
									<th>
										<p className="end13_bule">商品最近的停售日期</p>
									</th>
									<th>
										<p className="end13_bule">上次更新时间</p>
									</th>
									<th>
										<p className="end13_bule">买家满意度状况</p>
									</th>
									<th>
										<p>操作</p>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td><img src={imgVico} /></td>
									<td>
										<p>MengK Smoothie Blenders Professional Blenders Heavy Duty 1400W High Speed Blender for Shakes and Smocthies
											with Timer 64oz Jar BPA-Free Pitcher for Food, vegetables and Frozen Fruits (HS200j</p>
										<p>Bo1NANV312</p>
									</td>
									<td>
										<p>--</p>
										<p>new</p>
									</td>
									<td>
										<p>亚马逊</p>
									</td>
									<td>
										<p>5.88%</p>
									</td>
									<td>
										<p>1</p>
									</td>
									<td>
										<p>17</p>
									</td>
									<td>
										<p>--</p>
									</td>
									<td>
										<p>2019-2-11</p>
									</td>
									<td>
										<p>2019-6-24</p>
									</td>
									<td>
										<p className="end13_left_but end13_yell">一般</p>
										<p className="end13_dele">商品已删除</p>
									</td>
									<td>
										<button className="end13_chakan">查看详细</button>
									</td>
								</tr>
								<tr>
									<td><img src={require("../../assets/images/end13_shuiguoji.png").default} /></td>
									<td>
										<p>MengK Smoothie Blenders Professional Blenders Heavy Duty 1400W High Speed Blender for Shakes and Smocthies
											with Timer 64oz Jar BPA-Free Pitcher for Food, vegetables and Frozen Fruits (HS200j</p>
										<p>Bo1NANV312</p>
									</td>
									<td>
										<p>--</p>
										<p>new</p>
									</td>
									<td>
										<p>亚马逊</p>
									</td>
									<td>
										<p>5.88%</p>
									</td>
									<td>
										<p>1</p>
									</td>
									<td>
										<p>17</p>
									</td>
									<td>
										<p>--</p>
									</td>
									<td>
										<p>2019-2-11</p>
									</td>
									<td>
										<p>2019-6-24</p>
									</td>
									<td>
										<p className="end13_left_but end13_green">良好</p>
										<p className="end13_dele">商品已删除</p>
									</td>
									<td>
										<button className="end13_chakan">查看详细</button>
									</td>
								</tr>
								<tr>
									<td><img src={require("../../assets/images/end13_shuiguoji.png").default} /></td>
									<td>
										<p>MengK Smoothie Blenders Professional Blenders Heavy Duty 1400W High Speed Blender for Shakes and Smocthies
											with Timer 64oz Jar BPA-Free Pitcher for Food, vegetables and Frozen Fruits (HS200j</p>
										<p>Bo1NANV312</p>
									</td>
									<td>
										<p>--</p>
										<p>new</p>
									</td>
									<td>
										<p>亚马逊</p>
									</td>
									<td>
										<p>5.88%</p>
									</td>
									<td>
										<p>1</p>
									</td>
									<td>
										<p>17</p>
									</td>
									<td>
										<p>零件或配件缺失(100%)</p>
									</td>
									<td>
										<p>2019-2-11</p>
									</td>
									<td>
										<p>2019-6-24</p>
									</td>
									<td>
										<p className="end13_left_but end13_green">良好</p>
										<p className="end13_dele">商品已删除</p>
									</td>
									<td>
										<button className="end13_chakan">查看详细</button>
									</td>
								</tr>
								<tr>
									<td><img src={require("../../assets/images/end13_shuiguoji.png").default} /></td>
									<td>
										<p>MengK Smoothie Blenders Professional Blenders Heavy Duty 1400W High Speed Blender for Shakes and Smocthies
											with Timer 64oz Jar BPA-Free Pitcher for Food, vegetables and Frozen Fruits (HS200j</p>
										<p>Bo1NANV312</p>
									</td>
									<td>
										<p>--</p>
										<p>new</p>
									</td>
									<td>
										<p>亚马逊</p>
									</td>
									<td>
										<p>5.88%</p>
									</td>
									<td>
										<p>1</p>
									</td>
									<td>
										<p>17</p>
									</td>
									<td>
										<p>--</p>
									</td>
									<td>
										<p>2019-2-11</p>
									</td>
									<td>
										<p>2019-6-24</p>
									</td>
									<td>
										<p className="end13_left_but end13_green">良好</p>
										<p className="end13_dele">商品已删除</p>
									</td>
									<td>
										<button className="end13_chakan">查看详细</button>
									</td>
								</tr>
								<tr>
									<td><img src={require("../../assets/images/end13_shuiguoji.png").default} /></td>
									<td>
										<p>MengK Smoothie Blenders Professional Blenders Heavy Duty 1400W High Speed Blender for Shakes and Smocthies
											with Timer 64oz Jar BPA-Free Pitcher for Food, vegetables and Frozen Fruits (HS200j</p>
										<p>Bo1NANV312</p>
									</td>
									<td>
										<p>--</p>
										<p>new</p>
									</td>
									<td>
										<p>亚马逊</p>
									</td>
									<td>
										<p>5.88%</p>
									</td>
									<td>
										<p>1</p>
									</td>
									<td>
										<p>17</p>
									</td>
									<td>
										<p>商品存在瑕疵(100%)</p>
									</td>
									<td>
										<p>2019-2-11</p>
									</td>
									<td>
										<p>2019-6-24</p>
									</td>
									<td>
										<p className="end13_left_but end13_green2">极好</p>
										<p className="end13_dele">商品已删除</p>
									</td>
									<td>
										<button className="end13_chakan">查看详细</button>
									</td>
								</tr>
								<tr>
									<td><img src={require("../../assets/images/end13_shuiguoji.png").default} /></td>
									<td>
										<p>MengK Smoothie Blenders Professional Blenders Heavy Duty 1400W High Speed Blender for Shakes and Smocthies
											with Timer 64oz Jar BPA-Free Pitcher for Food, vegetables and Frozen Fruits (HS200j</p>
										<p>Bo1NANV312</p>
									</td>
									<td>
										<p>--</p>
										<p>new</p>
									</td>
									<td>
										<p>亚马逊</p>
									</td>
									<td>
										<p>5.88%</p>
									</td>
									<td>
										<p>1</p>
									</td>
									<td>
										<p>17</p>
									</td>
									<td>
										<p>--</p>
									</td>
									<td>
										<p>2019-2-11</p>
									</td>
									<td>
										<p>2019-6-24</p>
									</td>
									<td>
										<p className="end13_left_but end13_green2">极好</p>
										<p className="end13_dele">商品已删除</p>
									</td>
									<td>
										<button className="end13_chakan">查看详细</button>
									</td>
								</tr>
							</tbody>

						</table>
					</div>
				</div>

			</div>
			<Footer />
		</div>
	)
}

export default VicoOfTheCustomer