import React, { useState } from "react"
import AJAX from "api/apis";
import BannerHeader from "../Banner-header";
import FormData from "../formData/formdata";
import Reactours from "components/reactours"
import TipsIcon from "components/tipsIcon"
import { Button, Form, Input, Select, DatePicker, Space, Radio, Tooltip } from "antd";

import { QuestionCircleOutlined } from "@ant-design/icons";
import "./Tab6-banner.css"
import { Link } from "react-router-dom";
const { Option } = Select;

class Tab6Banner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                formList: [],
                NumbetTypeList: []
            },
            isTourOpen: true,
            steps1: [
                {
                    selector: '.tab6-box .ant-form-item',
                    content: '最多可描述此产品五个不同卖点， 每个卖点最多500字符，操作资料中的五行以逗号隔开，为一行描述',
                },
                {
                    selector: '.tab6-box .stepGuidanceBox60',
                    content: '添加此商品描述(如:商品亮点，商品信息，注意事项等),最多2000字符',
                },
                {
                    selector: '.tab6-box .stepGuidanceBox61',
                    content: '可不填',
                }
            ],
            isActive:false
        }

    }
    getFormRef = el => {
        this.form = el;
    }
    componentDidMount(){
        setTimeout(()=>{
            this.setState({
                isTourOpen:true
            })
        },500)
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.isSubmit) {
            this.form.validateFields().then((res) => {}).catch((errr) => {
                console.log(errr, "errr")
            })
        }
    }
    awakenTips = (type) =>{
        this.setState({
            isActive:type
        })
    }
    Switchse = (e) => {
        this.props.Switchse(e)
    }
    formChonge = () => {
        let { formList } = this.state.data;
        let content = this.form.getFieldsValue()
        let isValue = true;
        for (let items of formList) {
            for (let keys in content) {
                if (items.rules.length > 0) {
                    if (!content[items.basicsKey]) {
                        isValue = false;
                        return;
                    }
                }
            }
        }
        if (isValue) {
            this.props.submitData(content, 6)
        }
    }
    dataInfo = (data,type) =>{
        this.props.submitData(data, 6)
    }
    render() {
        let { steps1,isTourOpen,isActive} = this.state
        return <div className="tab6-box"> 
            {/* 引导步骤 */}
            <TipsIcon clicks={this.awakenTips} />
            <Reactours routePath="Tab6-banner" isActive={isActive} clicks={this.awakenTips} steps={steps1} isOpen={isTourOpen} />
            <BannerHeader checked={this.props.isSenior} id={this.props.id} Switchse={this.Switchse} />
            <FormData id={this.props.id} type="5" classifyId={this.props.classifyId} goodsId={this.props.goodsId} isHighView={this.props.isSenior} dataInfo={this.dataInfo}></FormData>

            {/* <Form labelCol={{ span: 4 }} ref={this.getFormRef} className="formBox" wrapperCol={{ span: 14 }} layout="horizontal">
                {
                    data.formList.map((item, index) => {
                        return (
                            <Form.Item name={item.basicsKey} key={index} extra={item.extra} label={
                                <span className="tab1-form-title">{item.title}&nbsp;<QuestionCircleOutlined style={{ color: "#008296" }} /></span>}
                                rules={item.rules}>
                                {
                                    !item.isSelect ?
                                        <Input onChange={this.formChonge} placeholder={item.placeholder} /> : <Select onChange={this.formChonge} className="tab3-select" placeholder="-选择-">
                                            {item.basicsKeyValue ? item.basicsKeyValue.map((item2, index2) => {
                                                return <Option value={item2}>{item2}</Option>
                                            }) : null}
                                        </Select>
                                }
                                {/* {
                                    item.id === 1 ? <Input placeholder={item.placeholder} /> : ""
                                }
                                {
                                    item.id === 2 || item.id === 3 ? <textarea className="tab6-textarea" placeholder={item.placeholder} /> : ""
                                } *
                            </Form.Item>
                        )
                    })
                }
            </Form> */}
        </div>

    }
}

export default Tab6Banner