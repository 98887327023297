import { Tooltip } from "antd";
import { useCallback, useMemo } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import classnames from "classnames";

const GoodsStatus = ({ type = "outStock" }) => {
  const statusMapping = useMemo(() => {
    return {
      outStock: {
        text: "缺货",
        tooltip:
          "缺货商品通常不会出现在广告中。没有推荐报价的商品也被视为缺货。",
      },
      lowInventory: {
        text: "低库存",
        tooltip: "缺货商品通常不会出现在广告中。",
      },
      inStock: {
        text: "有货",
      },
    };
  }, []);

  const curStatus = statusMapping[type];

  const isShowIcon = useMemo(() => {
    return ["outStock", "lowInventory"].includes(type);
  }, [type]);

  const icon = useMemo(() => {
    if (isShowIcon)
      return (
        <Tooltip title={curStatus.tooltip}>
          <InfoCircleOutlined />
        </Tooltip>
      );

    return null;
  }, [isShowIcon, curStatus]);

  return (
    <span className={classnames("df ai-c")}>
      {icon} &nbsp;
      <span
        className={classnames({
          "c-primary": isShowIcon,
        })}
      >
        {curStatus.text}
      </span>
    </span>
  );
};

export default GoodsStatus;
