import { useCallback, useRef, useState } from "react";
import { Modal } from "antd";
import { AButton } from "@a-components/";
import Apis from "@api/apis";
import NegativeTable from "./../NegativeTable";
import NegativeKeywordsSelector from "@view/advertisement/NegativeKeywordsSelector";

const columns = [
  {
    title: "关键词",
    width: "80%",
    dataIndex: "keywords",
  },
  {
    title: "匹配类型",
    dataIndex: "matchTypeName",
  },
];

const apiMapping = {
  // 广告的查询和更新
  advertising: {
    get: ({ businessId, searchValue, ...otherParams }) => {
      return Apis.advertising.getAdvertisingNegativeKeywordsList({
        ...otherParams,
        advertisingId: businessId,
        keywords: searchValue,
      });
    },
    post: ({ businessId, negativeList }) => {
      return Apis.advertising.addAdvertisingNegativeKeywords({
        advertisingId: businessId,
        advertisingNegativeKeywordsReqList: negativeList.map((item) => ({
          keywords: item.keywords,
          matchType: item.matchType,
        })),
      });
    },
  },
  // 广告组的查询和更新
  advertisingGroup: {
    get: ({ businessId, searchValue, otherParams }) => {
      return Apis.advertising.getAdvertisingGroupNegativeKeywordsList({
        ...otherParams,
        advertisingGroupId: businessId,
        keywords: searchValue,
      });
    },
    post: ({ businessId, negativeList }) => {
      return Apis.advertising.addAdvertisingGroupNegativeKeywords({
        advertisingGroupId: businessId,
        advertisingNegativeKeywordsReqList: negativeList.map((item) => ({
          keywords: item.keywords,
          matchType: item.matchType,
        })),
      });
    },
  },
};

const NegativeKeywords = ({ businessId, businessType }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [negativeList, setNegativeList] = useState([]);

  const _fetcher = apiMapping[businessType];

  const negativeTable = useRef();
  const fetcher = useCallback(
    ({ page, size, searchValue }) => {
      return _fetcher.get({ businessId, page, size, searchValue });
    },
    [_fetcher, businessId]
  );

  const handleShowModal = useCallback(() => {
    setModalVisible(true);
    setNegativeList([]);
  }, []);

  const handleHideModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const handleSaveKeywordsList = useCallback(() => {
    return _fetcher.post({ businessId, negativeList }).then((res) => {
      if (res.code === 1) {
        handleHideModal();
        // eslint-disable-next-line no-undef
        negativeTable.current?.updatePageInfo(1);
      }
    });
  }, [_fetcher, businessId, handleHideModal, negativeList]);

  return (
    <div>
      <NegativeTable
        ref={negativeTable}
        columns={columns}
        fetcher={fetcher}
        createBtn={{
          text: "添加否定关键词",
          onClick: handleShowModal,
        }}
        searchInput={{ placeholder: "查找否定关键词" }}
      />
      <Modal
        title="添加否定关键词"
        style={{ width: "80%", minWidth: 1000 }}
        visible={modalVisible}
        onCancel={handleHideModal}
        destroyOnClose
        footer={
          <>
            <AButton onClick={handleHideModal}>取消</AButton>
            <AButton
              type="primary"
              disabled={!negativeList.length}
              onClick={handleSaveKeywordsList}
            >
              保存
            </AButton>
          </>
        }
      >
        <NegativeKeywordsSelector
          selectedNegateKeywordList={negativeList}
          updateNegateKeywordsList={setNegativeList}
        />
      </Modal>
    </div>
  );
};

export default NegativeKeywords;
