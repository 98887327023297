import React from 'react'
import './AccessSettings.css'
import AdvertisementLeft from '../advertisementLeft/advertisementLeft'
import '../../../default.css/default.css'

function AccessSettings(){
    return(
        <div className="AccessSettings_box">
            <AdvertisementLeft/>


           

            <div className="gg07_ASD_box">
			
			<div className="gg07_contentbox">
				<div className="gg07_contentbox_title">
					<div className="gg07_contentbox_title_font">
						访问和设置
					</div>
					<div className="gg07_iconlist">
						<div className="gg07_iconlist_p1">
							<select>
                 				 <option>Big Corn美国</option>
                			</select>
						</div>
						<svg t="1610781224023" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="22897"
						 width="20" height="20">
							<path d="M919.456 758.384L841.4 625.256V440.768a293.64 293.64 0 0 0-61.208-172.216 350.056 350.056 0 0 0-158.72-118.872l-1.128-0.376c-4.8-1.52-9.632-2.944-14.488-4.264v-9.376c0-51.864-42.04-93.912-93.912-93.912-51.864 0-93.912 42.048-93.912 93.912v8.624a347.22 347.22 0 0 0-12.368 3.608l-0.8 0.24a349.472 349.472 0 0 0-161.48 118.776 294.312 294.312 0 0 0-62.344 173.76v184.496L97.512 767.664a54.064 54.064 0 0 0 0 55.208 35.648 35.648 0 0 0 39.328 18.752h234.368a140.64 140.64 0 0 0 210.936 121.792 140.64 140.64 0 0 0 70.304-121.792h234.376a41.304 41.304 0 0 0 46.864-46.36 53.76 53.76 0 0 0-14.232-36.88M512.016 92.256a44.384 44.384 0 0 1 44.856 41.296 346.832 346.832 0 0 0-15.184-2.48 25.04 25.04 0 0 0-6.472 0l-2.664-0.376a115.816 115.816 0 0 0-42.52 0l-2.296 0.328a26.856 26.856 0 0 0-6.704 0c-4.688 0.656-9.376 1.36-13.776 2.2a44.344 44.344 0 0 1 44.76-40.968m0 842.424a103.12 103.12 0 0 1-93.744-93.104h187.504a103.488 103.488 0 0 1-93.76 93.104m374.992-139.968H137.024c-2.856 0 4.688 6.8 3.144 4.688a7.752 7.752 0 0 1 0-7.872l86.808-148.032a23.74 23.74 0 0 0 3.192-11.768V440.952a282.88 282.88 0 0 1 191.008-247.68 508.72 508.72 0 0 1 60.184-14.064c1.712 0.192 3.448 0.192 5.16 0 3.792-0.376 7.36-0.888 10.776-1.36a96.56 96.56 0 0 1 14.064-1.448c4.712 0.128 9.416 0.616 14.056 1.448 3.432 0.472 6.984 0.984 10.832 1.36 1.56 0.168 3.136 0.168 4.688 0a531.296 531.296 0 0 1 64.128 15.328 283.424 283.424 0 0 1 187.504 246.416v190.776a23.746 23.746 0 0 0 3.176 11.768l82.792 141.136a23.177 23.177 0 0 0 4.264 5.352 7.28 7.28 0 0 1 2.24 5.44c-0.184 3.976 6.656-0.712 1.968-0.712"
							 p-id="22898" fill="#ffffff"></path>
						</svg>
						<svg t="1610781345050" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="23695"
						 width="20" height="20">
							<path d="M510.976182 950.063057c-58.986439 0-116.225071-11.559267-170.124655-34.357505-52.046377-22.013363-98.781794-53.520961-138.9087-93.64889-40.126906-40.126906-71.634504-86.862323-93.64889-138.907677-22.797215-53.900607-34.357506-111.138216-34.357506-170.124655s11.559267-116.225071 34.357506-170.124656c22.014386-52.045353 53.521984-98.781794 93.64889-138.9087s86.862323-71.634504 138.9087-93.64889c53.899584-22.797215 111.138216-34.357506 170.124655-34.357506 58.987463 0 116.225071 11.559267 170.124656 34.357506 52.046377 22.013363 98.781794 53.521984 138.9087 93.64889 40.127929 40.126906 71.635527 86.863347 93.64889 138.9087 22.798239 53.900607 34.357506 111.138216 34.357505 170.124656s-11.559267 116.225071-34.357505 170.124655c-22.013363 52.045353-53.520961 98.780771-93.64889 138.907677-40.126906 40.127929-86.862323 71.635527-138.9087 93.64889-53.899584 22.798239-111.138216 34.357506-170.124656 34.357505z m0-812.680107c-207.129454 0-375.641379 168.511925-375.641379 375.64138s168.511925 375.640356 375.641379 375.640355 375.641379-168.511925 375.641379-375.640355c0-207.129454-168.511925-375.641379-375.641379-375.64138z"
							 p-id="23696" fill="#ffffff"></path>
							<path d="M535.493575 637.20663c0.440022-23.967878 2.098801-41.278125 4.974292-51.930743 2.871397-10.652618 7.296173-20.082384 13.266141-28.29544 5.969968-8.207939 18.681478-20.971637 38.140669-38.281885 28.962635-25.741267 48.421826-47.603181 58.370409-65.578577 9.950629-17.975397 14.924921-37.616736 14.924921-58.920948 0-36.835953-14.598486-67.76436-42.451858-94.207615-64.120366-60.861136-170.124655-56.010665-222.042095-2.662643-25.444509 26.146497-43.725874 59.035558-49.250704 104.526635 0 0 14.483876 14.810311 29.378097 16.588817 15.759939 1.880837 31.978319-9.265014 31.978319-9.265015 5.306866-33.954323 16.46909-58.921971 33.496905-74.899873 33.150004-31.115672 93.210915-33.631982 130.340557 2.496867 18.126846 17.642822 27.196409 38.115086 27.196409 61.417814 0 12.873192-3.099594 24.74866-9.286504 35.619243-6.192026 10.876722-19.623943 25.467021-40.295751 43.774992-20.676925 18.308995-34.989909 32.237215-42.949185 41.777499-10.835789 13.097296-18.686594 26.078958-23.547299 38.948057-6.633071 17.09126-9.949606 37.397748-9.949606 60.918442 0 3.994987 0.109494 9.986445 0.332575 17.975396 0 0 15.228843 9.325389 30.239721 9.32539 13.605879-0.001023 27.133987-9.326413 27.133987-9.326413z m6.964622 119.078049v-45.24139c0-5.122671-2.830465-8.823969-11.745508-8.82397h-49.057299c-3.449565 0-7.186679 2.929726-7.18668 9.913791v46.876633c0 7.806803 5.700839 11.450796 12.636808 11.450797h44.696992c6.644327 0 10.655687-3.75758 10.655687-14.175861z"
							 p-id="23697" fill="#ffffff"></path>
						</svg>
						<svg t="1610781421678" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="28081"
						 width="20" height="20">
							<path d="M513.202 2.101C231.01 2.101 2.253 230.858 2.253 513.051S231.01 1024 513.202 1024s510.949-228.757 510.949-510.949S795.395 2.101 513.202 2.101z m0 69.675c243.726 0 441.274 197.548 441.274 441.274 0 86.595-25.312 167.156-68.428 235.312-72.735-77.772-169.128-133.203-278.132-153.638 67.815-34.543 114.311-104.853 114.311-186.163 0-115.444-93.58-209.024-209.024-209.024s-209.025 93.58-209.025 209.024c0 81.311 46.541 151.62 114.31 186.163-108.958 20.436-205.35 75.866-278.109 153.639-43.116-68.155-68.45-148.717-68.45-235.312-0.001-243.726 197.592-441.275 441.273-441.275z m0 487.747c-83.374 0-150.963-67.589-150.963-150.963s67.589-150.962 150.963-150.962 150.962 67.588 150.962 150.962c0 83.375-67.588 150.963-150.962 150.963z m0 394.802c-131.593 0-249.419-57.949-330.275-149.329 80.789-91.539 198.637-149.511 330.275-149.511 131.684 0 249.532 57.972 330.298 149.487-80.856 91.404-198.659 149.353-330.298 149.353z"
							 p-id="28082" fill="#ffffff"></path>
						</svg>
					</div>
				</div>
				<div className="gg07_contentbox_location">
					<h2>Blue Rose_USA</h2>
					<div className="gg07_location_sp">
						<span>账户编号：：AR61LUBKCHATF</span>
						<span>商城：美国</span>
						<span>Seller account：Blue Rose_USA</span>
					</div>
				</div>
				<div className="gg07_contentbox_operation">
					<div className="gg07_operation_l">
						<ul>
							<li>用户</li>
							<li>管理员账户</li>
							<li>品牌</li>
						</ul>
					</div>
					<div className="gg07_operation_r">
						<div className="gg07_operation_r_p1">
							<p>要邀请用户或删除用户, 请转到您卖家平台账户中的<a href="#">用户权限<svg t="1610783676319" className="icon" viewBox="0 0 1024 1024" version="1.1"
									 xmlns="http://www.w3.org/2000/svg" p-id="29656" width="20" height="20">
										<path d="M250.148571 823.588571c-27.794286 0-51.2-23.405714-51.2-51.2v-438.857142c0-27.794286 23.405714-51.2 51.2-51.2h311.588572c11.702857 0 21.942857 10.24 21.942857 21.942857s-10.24 21.942857-21.942857 21.942857H250.148571c-4.388571 0-7.314286 2.925714-7.314285 7.314286v438.857142c0 4.388571 2.925714 7.314286 7.314285 7.314286h438.857143c4.388571 0 7.314286-2.925714 7.314286-7.314286V454.948571c0-11.702857 10.24-21.942857 21.942857-21.942857s21.942857 10.24 21.942857 21.942857v317.44c0 27.794286-23.405714 51.2-51.2 51.2h-438.857143z"
										 fill="#3091F2" p-id="29657"></path>
										<path d="M801.645714 468.114286c-11.702857 0-21.942857-10.24-21.942857-21.942857V250.148571c0-4.388571-2.925714-7.314286-7.314286-7.314285H576.365714c-11.702857 0-21.942857-10.24-21.942857-21.942857s10.24-21.942857 21.942857-21.942858h196.022857c27.794286 0 51.2 23.405714 51.2 51.2v196.022858c0 13.165714-8.777143 21.942857-21.942857 21.942857z"
										 fill="#3091F2" p-id="29658"></path>
										<path d="M471.04 589.531429c-5.851429 0-10.24-1.462857-14.628571-5.851429-8.777143-8.777143-8.777143-21.942857-1.462858-30.72l292.571429-307.2c8.777143-8.777143 21.942857-8.777143 30.72-1.462857 8.777143 8.777143 8.777143 21.942857 1.462857 30.72l-292.571428 307.2c-5.851429 7.314286-11.702857 7.314286-16.091429 7.314286z"
										 fill="#3091F2" p-id="29659"></path>
									</svg></a></p>
							<div className="gg07_zhijie">
								仅限直接账户访问<svg t="1610783778282" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
								 p-id="30534" width="20" height="20">
									<path d="M512.133 624.981l-292.16-292.155c-12.27-12.28-32.186-12.28-44.457 0-12.28099999 12.27-12.281 32.186 0 44.457l314.388 314.388c12.27 12.28 32.186 12.28 44.457 0l314.387-314.388c6.14-6.136 9.211-14.183 9.211-22.228s-3.071-16.092-9.21099999-22.228c-12.27-12.28-32.186-12.28-44.45700001-1e-8l-292.159 292.15500001z"
									 fill="#686868" p-id="30535"></path>
								</svg>
							</div>
						</div>
						<div className="gg07_operation_r_p1 gg07_guanli">
							<div className="gg07_yh">用户</div>
							<div className="gg07_yj">电子邮件地址</div>
							<div className="gg07_cz">操作</div>
						</div>
						<div className="gg07_operation_r_p1 gg07_data">
							<div className="gg07_yh">Blue Rose</div>
							<div className="gg07_yj">bluerose_usa@163.com</div>
							<div className="gg07_cz gg07_gl">管理权限</div>
						</div>
					</div>
				</div>
				<div className="gg_footr">
					<div>© 2020 Amazon.com.icn.或其附属公司。<span>使用条款</span><span>隐私声明</span><span>Amazon广告协议</span></div>
				</div>
			</div>
		</div>




        








        </div>
    )
}
export default AccessSettings