import equal from "fast-deep-equal";
import { useMemo } from "react";
import useMemory from "./useMemory";

const useReallyValue = (value) => {
  const [lastValue, setLastValue] = useMemory(value);

  return useMemo(() => {
    if (!equal(value, lastValue.current)) {
      setLastValue(value);
      return value;
    }
    return lastValue.current;
  }, [lastValue, setLastValue, value]);
};

export default useReallyValue;
