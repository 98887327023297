import React from 'react'
import './ChargebackClaims.css'
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import imgInp from 'assets/images/5-10-1.png'
import { Tabs } from 'antd';

function ChargebackClaims() {
    const { TabPane } = Tabs;

    function callback(key) {
        console.log(key);
    }

    return (
        <div>
            <Header />
            <div>


                <div className="end09_mybody">
                    <p>顾客中止付款</p>
                    <p>当持卡人联系银行，表示对亚马逊上所下订单存在争议时，便会出现顾客中止付款。顾客中止付款也被
                        称为"付款争议"。提出顾客中止付款的原因有很多，从没有收到所订商品到未经授权使用信用卡等。</p>
                    <div className="end09_mybody_tab">
                        <Tabs defaultActiveKey="1" onChange={callback}>
                            <TabPane tab="要求的操作" key="1">
                                <div>您没有顾客中止付款。</div>
                            </TabPane>
                            <TabPane tab="全部" key="2">
                                <div>您没有顾客中止付款。</div>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )

}

export default ChargebackClaims