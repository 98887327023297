import { InfoCircleOutlined } from "@ant-design/icons";
import "./viewFragment.css";
import ATitleTooltip from '@a-components/ATitleTooltip'

export const renderTooltip = (tooltip, props = {}) => {
  if (!tooltip) return null;
  return (
    <ATitleTooltip desc={tooltip} placement="right" {...props}>
      <InfoCircleOutlined style={{ marginLeft: 4 }} />
    </ATitleTooltip>
  );
};
