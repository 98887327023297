import withProps from "@a-components/withProps";
import { Form as AntForm } from "antd";

const FormList = withProps((props) => {
  return <AntForm.List {...props}/>
}, {
  className: "a-form-list",
});

export default FormList;
