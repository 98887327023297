import React,{Component} from 'react'
import './CreateReport.css'
import '../../../default.css/default.css'
import IconFont from 'components/Icon/Icon'
import {Link} from 'react-router-dom'

class CreateReport extends React.Component{


    render(){
        return(
            <div>

<div className="gg04_header">
			<div className="gg04_header_l">
                <Link to="/AdvertisingReports"><IconFont type="icon-guanbi" /></Link>
				<p>新报告</p>
			</div>
			<div className="gg04_header_r">
				<p>取消</p>
				<button type="button">运行报告</button>
				<svg t="1611644376997" className="gg04_header_r_icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15029" width="32" height="32"><path d="M901.19 347.57c-21.28-50.3-51.73-95.47-90.51-134.25-38.78-38.78-83.95-69.23-134.25-90.51C624.33 100.77 569.01 89.6 512 89.6s-112.33 11.17-164.43 33.21c-50.3 21.28-95.47 51.73-134.25 90.51-38.78 38.78-69.23 83.95-90.51 134.25C100.77 399.67 89.6 454.99 89.6 512s11.17 112.33 33.21 164.43c21.28 50.3 51.73 95.47 90.51 134.25 38.78 38.78 83.95 69.23 134.25 90.51 52.1 22.04 107.42 33.21 164.43 33.21s112.33-11.17 164.43-33.21c50.3-21.28 95.47-51.73 134.25-90.51s69.23-83.95 90.51-134.25c22.04-52.1 33.21-107.42 33.21-164.43s-11.17-112.33-33.21-164.43zM512 857.6c-190.56 0-345.6-155.04-345.6-345.6S321.44 166.4 512 166.4 857.6 321.44 857.6 512 702.56 857.6 512 857.6z" p-id="15030" fill="#515151"></path><path d="M511.99 233.6c-79.98 0.01-145.05 65.08-145.05 145.07 0 21.21 17.19 38.4 38.4 38.4 21.21 0 38.4-17.19 38.4-38.4 0-37.64 30.62-68.26 68.26-68.27 17.63 0 34.61 7.27 47.81 20.46 13.2 13.2 20.46 30.18 20.46 47.81 0 28.21-16.95 53.14-43.17 63.51-19.03 7.53-35.11 20.62-46.5 37.87-11.12 16.83-16.99 36.43-16.99 56.7v33.92c0 21.21 17.19 38.4 38.4 38.4s38.4-17.19 38.4-38.4v-33.92c0-10.31 6-19.61 14.94-23.15 26.75-10.58 49.56-28.7 65.98-52.42 16.84-24.32 25.74-52.85 25.74-82.51 0-38.15-15.25-74.42-42.96-102.12s-63.97-42.95-102.12-42.95z" p-id="15031" fill="#515151"></path><path d="M512 704m-57.6 0a57.6 57.6 0 1 0 115.2 0 57.6 57.6 0 1 0-115.2 0Z" p-id="15032" fill="#515151"></path></svg>
			</div>
		</div>
		<div className="gg04_content">
			<div className="gg04_content_header">
				<p>配置</p>
			</div>
			<div className="gg04_content_con">
				<div>
					<p>广告活动类型</p>
					<ul>
						<li><input type="radio" checked="checked"/>&nbsp;商品推广</li>
						<li><input type="radio" />&nbsp;品牌推广</li>
						<li><input type="radio" />&nbsp;Sponsored Brands Video</li>
						<li>
							<input type="radio" />&nbsp;推广显示
							<svg t="1611645524064" className="gg04_icon_th" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="20494" width="16" height="16"><path d="M512 64a448 448 0 1 0 0 896A448 448 0 0 0 512 64z m32 664a8 8 0 0 1-8 8h-48a8 8 0 0 1-8-8v-272c0-4.416 3.584-8 8-8h48c4.416 0 8 3.584 8 8v272zM512 384a48 48 0 1 1 0-96A48 48 0 0 1 512 384z" p-id="20495" fill="#cdcdcd"></path></svg>
							<span>新功能</span>
						</li>
					</ul>
				</div>
				<div>
					<p>
						报告类型
						<svg t="1611645524064" className="gg04_icon_th" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="20494" width="16" height="16"><path d="M512 64a448 448 0 1 0 0 896A448 448 0 0 0 512 64z m32 664a8 8 0 0 1-8 8h-48a8 8 0 0 1-8-8v-272c0-4.416 3.584-8 8-8h48c4.416 0 8 3.584 8 8v272zM512 384a48 48 0 1 1 0-96A48 48 0 0 1 512 384z" p-id="20495" fill="#cdcdcd"></path></svg>
					</p>
					<div>
						<button type="button">搜索词</button>
						<svg t="1611645877818" className="gg04_icon_bottom" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1118" width="16" height="16"><path d="M52.335 261.072c-31.269 30.397-31.269 79.722 0 110.194l403.212 391.718c31.325 30.382 82.114 30.382 113.377 0l403.197-391.718c31.325-30.466 31.325-79.793 0-110.194-31.28-30.449-82.058-30.449-113.39 0l-346.497 336.64-346.457-336.64c-31.325-30.448-82.105-30.448-113.446 0l0 0z" p-id="1119" fill="#bfbfbf"></path></svg>
					</div>
				</div>
				<div>
					<p>时间单位</p>
					<ul>
						<li><input type="radio" checked="checked"/>&nbsp;一览</li>
						<li><input type="radio" />&nbsp;每日</li>
					</ul>
				</div>
				<div>
					<p>报告期</p>
					<div>
						<button type="button">最近30天</button>
						<svg t="1611645877818" className="gg04_icon_bottom" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1118" width="16" height="16"><path d="M52.335 261.072c-31.269 30.397-31.269 79.722 0 110.194l403.212 391.718c31.325 30.382 82.114 30.382 113.377 0l403.197-391.718c31.325-30.466 31.325-79.793 0-110.194-31.28-30.449-82.058-30.449-113.39 0l-346.497 336.64-346.457-336.64c-31.325-30.448-82.105-30.448-113.446 0l0 0z" p-id="1119" fill="#bfbfbf"></path></svg>
					</div>
				</div>
			</div>
		</div>
		<div className="gg04_content">
			<div className="gg04_content_header">
				<p>报告设置</p>
			</div>
			<div className="gg04_content_con">
				<div className="gg04_nameBar">
					<p>名称</p>
					<div>
						<input type="text" value="商品推广 搜索词 报告" />
					</div>
				</div>
				<p className="gg04_content_title">投放</p>
				<div>
					<p><span>新功能</span>收件人<span>可选</span></p>
					<div className="gg04_content_inpBar">
						<input type="text" placeholder="输入以逗号或空格分隔的电子邮件地址。" />
						<p>添加电子由件地址以便在生成报告时接收电子邮件。</p>
					</div>
				</div>
				<div>
					<p>计划时间</p>
					<ul>
						<li><input type="radio" checked="checked"/>&nbsp;立即</li>
						<li><input type="radio" />&nbsp;未来</li>
						<li><input type="radio" />&nbsp;定期</li>
					</ul>
				</div>
			</div>
		</div>
		<p className="gg04_content_footer">
			<svg t="1611648331429" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4557" width="16" height="16"><path d="M512 64a448 448 0 1 0 0 896A448 448 0 0 0 512 64z m32 664a8 8 0 0 1-8 8h-48a8 8 0 0 1-8-8v-272c0-4.416 3.584-8 8-8h48c4.416 0 8 3.584 8 8v272zM512 384a48 48 0 1 1 0-96A48 48 0 0 1 512 384z" p-id="4558" fill="#0468c1"></path></svg>
			某些产品和关键字不符合定位条件,因比不会展示广告。
			<span className="gg04_blue">進一步瞭解</span>
		</p>
		<div className="gg04_footer">
			© 2020 Amazon.com,Inc.或其附属公司。&nbsp;使用条款&nbsp;隐私声明&nbsp;Amazon广告协议
		</div>

            </div>
        )
    }
}

export default CreateReport