import React from 'react'
import './Logistics.css'
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import IconFont from 'components/Icon/Icon'
import { Link } from 'react-router-dom'

function Logistics() {
	return (
		<div>

			<Header />



			<div className="kc32_content">
				<div className="kc32_cont_tab">
					<ul>
						<li>计划简介</li>
						<li style={{ lineHeight: "52px" }}>商品创建设置</li>
						<li style={{ lineHeight: "52px" }}>卖家自配送设置</li>
					</ul>
				</div>
				<div className="kc32_content_brief">
					<h2>了解亚马逊物流远程配送</h2>
					<div>借助亚马逊物流远程配送，您可以使用美国亚马逊物流库存向加拿大和墨西哥境内的买家销售商品</div>
					<ul>
						<li>使用您现有的美国亚马逊物流库存，将买家群体扩展到加拿大和墨西哥，从而提高销量。</li>
						<li>亚马逊处理跨境配送事宜。</li>
						<li>进口关税和相关费用由买家支付。</li>
					</ul>
				</div>
				<div className="kc32_content-next">
					<h2><IconFont style={{ transform: "rotate(90deg)", color: "#3d3d3d" }} type="icon-fanhui-copy-copy" />  了解计划费用</h2>
					<div>
						<div>注册此计划，即表示您同意如下政策 <span>亚马逊物流远程配送的政策</span> 。请花一点时间阅读这些内容。</div>
						<div><button ><Link to="/LogisticsII" style={{ color: "#000" }}>下一步</Link></button></div>
					</div>
				</div>

			</div>




			<Footer />


		</div>
	)
}

export default Logistics