import React, { useState } from 'react'
import './BrandRegisteredForm.css'
import BrandRegisteredHeader from "../BrandRegisteredHeader"
import BrandRegisteredFooter from "../BrandRegisteredFooter/BrandRegisteredFooter"
import { Button, Steps } from 'antd';
import { useHistory } from 'react-router-dom'
import BrandInformation from "./module/BrandInformation/BrandInformation"
import BrandAccountSale from "./module/BrandAccountSale/BrandAccountSale"
import BrandDistribution from "./module/BrandDistribution/BrandDistribution"
const { Step } = Steps;

function BrandRegisteredForm() {
  const [current, setCurrent] = React.useState(0);
  const history = useHistory();
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    let value = current - 1
    if (value >= 0) {
      setCurrent(value);
    } else {
      history.push("/BrandRegistered")
    }
  };

  return (
    <div className="BrandRegisteredForm" >
      <BrandRegisteredHeader />
      <div className="BrandRegisteredForm_body" >
        <h2>注册品牌</h2>
        <Steps progressDot current={current}>
          <Step title="品牌信息" />
          <Step title="销售账户信息" />
          <Step title="分销信息" />
        </Steps>
        <div style={{ display: current == 0 ? "block" : "none" }} ><BrandInformation /></div>
        <div style={{ display: current == 1 ? "block" : "none" }} ><BrandAccountSale /></div>
        <div style={{ display: current == 2 ? "block" : "none" }} ><BrandDistribution /></div>
        <div className="BrandRegisteredForm_body_but" >
          <Button onClick={prev} >上一步</Button>
          <div className="BrandRegisteredForm_body_but_right" >
            <Button onClick={() => { history.push("/BrandRegistered") }} >取消</Button>
            {
              current == 2 ? (
                <Button onClick={()=>{history.push("/")}} >提交</Button>
              ) : (
                <Button onClick={next} >下一步</Button>
              )
            }
          </div>
        </div>
      </div>

      <BrandRegisteredFooter />
    </div>
  )
}

export default BrandRegisteredForm
