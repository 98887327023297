import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => {
    const query = new URLSearchParams(search).entries();

    const returnValue = {};
    for (let [key, item] of query) {
      returnValue[key] = item;
    }

    return returnValue;
  }, [search]);
};

export default useQuery;
