import React,{useState,useEffect} from 'react';
import {Container,FooterTop,LinksContainer} from './style';
import {Select} from 'antd';
import Item from './item';
const { Option } = Select;

export default () => {

    let [count,setCount] = useState(6);

    let languageData = [
        'English',
        '中文',
        'Deutsch',
        'Español',
        'Français',
        'Italiano',
        '日本語',
        '한국어'
    ]

    let links = [
        {
            title:'目录',
            list:[
                '添加商品',
                '补全您的草稿',
                '查看销售申请',
            ]
        },
        {
            title:'增长',
            list:[
                '配送计划',
                '推荐计划',
                '借贷',
            ]
        },
        {
            title:'其他亚马逊链接',
            list:[
                'Amazon.com 主页',
                '您的回购账户',
                'Kindle Direct Publishing',
                '亚马逊联盟',
                '您的买家账户',
            ]
        },
        {
            title:'库存',
            list:[
                {
                    text:'管理库存',
                    path: '/inventory'
                },
                '管理亚马逊库存',
                '库存规划',
                '亚马逊物流远程配送',
                '添加新商品',
                '批量上传商品',
                '库存报告',
                '全球销售',
                {
                    text:'管理亚马逊货件',
                    path:'/inboundQueue'
                },
                '上传和管理视频',
            ]
        },
        {
            title:'数据报告',
            list:[
                '付款',
                '亚马逊销售指导',
                '业务报告',
                '库存和销售报告',
                '广告',
                '退货报告',
                '自定义报告',
                '税务文件库',
            ]
        },
        {
            title:'确定价格',
            list:[
                '定价状况',
                '管理定价',
                '修复价格警告',
                '自动定价',
                '佣金折扣',
                '协议定价',
            ]
        },
        {
            title:'绩效',
            list:[
                '账户状况',
                '反馈',
                '亚马逊商城交易保障索赔',
                '信用卡拒付索赔',
                '业绩通知',
                '买家之声',
                '卖家大学',
            ]
        },
        {
            title:'应用商店',
            list:[
                '官方移动应用',
                '发现应用',
                '管理您的应用',
                '探索服务',
                '开发应用程序',
            ]
        },
        {
            title:'广告',
            list:[
                '广告活动管理',
                'A+ 页面',
                '早期评论者计划',
                'Vine',
                '秒杀',
                '优惠券',
                'Prime 专享折扣',
                '管理促销',
            ]
        },
        {
            title:'B2B',
            list:[
                'B2B平台',
                '商品机会',
                '管理报价',
                '企业资料',
            ]
        },
        {
            title:'订单',
            list:[
                '管理订单',
                '订单报告',
                '上传订单相关文件',
                '管理退货',
                '管理 SAFE-T 索赔',
            ]
        },
        {
            title:'品牌',
            list:[
                '品牌分析',
                '管理实验',
                '品牌控制面板',
                '品牌目录管理器',
                '买家评论',
                '虚拟捆绑商品',
            ]
        },
        {
            title:'品牌旗舰店',
            list:[
                '管理店铺',
            ]
        },
    ]

    let groupCount = parseInt(links.length / count),
    //取余数
    surplusCount = links.length % count;

    let newDoms = [];
    for(let i = 0; i < count; i++){
        newDoms[i] = [];        
        newDoms[i].push(
            <div key='1'  style={{width:`${100 / count}%`}}>{
                Array.from(new Array(groupCount).keys()).map(j=><Item key={i * groupCount + j} {...links[i * groupCount + j]}/>)
            }</div>
        );
    }

    for(let i = 0; i < surplusCount; i++){
        newDoms[i][0].props.children.push(<Item key={groupCount * count + i} {...links[groupCount * count + i]}/>)
    }

    useEffect(()=>{
        let resize = (e) => {
            let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
            let _count = parseInt(clientWidth / 200);
            if(_count <= 6 && _count >= 1) setCount(_count);
        }
        resize();
        window.addEventListener('resize',resize);
        return () => {
            window.removeEventListener('resize',resize);
        }
    },[]);
    
    return <Container>
        <FooterTop>
            <div>
                <div className='btns'>
                    <button>帮助</button>
                    <button>计划政策</button>
                </div>
                {/* 语言 */}
                <Select className='language' defaultValue="中文">
                    {languageData.map((item,index)=><Option key={index} value={item}>{item}</Option>)}
                </Select>
            </div>
            <p>© 1999-2020 年，亚马逊公司或其附属公司</p>
        </FooterTop>
        <LinksContainer>{newDoms}</LinksContainer>
    </Container>
}