import { ATabs } from "@a-components";
import { useCallback, useState } from "react";

import BrandExclude from "./BrandExclude";
import ExcludedList from "@view/advertisement/NegativeGoodsSelector/ExcludedList";

const NegateProductLaunch = ({ form }) => {
  const [activeTabKey, setActiveTabKey] = useState("1");

  const excludedNegateProductLaunchList = form.getFieldValue(
    "excludedNegateProductLaunchList"
  );

  const handleExcludeList = useCallback(
    (excludeList) => {
      form.setFieldsValue({
        excludedNegateProductLaunchList: [
          ...excludedNegateProductLaunchList,
          ...excludeList,
        ],
      });
    },
    [excludedNegateProductLaunchList, form]
  );

  const handleExcludeListChange = useCallback(
    (newList) => {
      form.setFieldsValue({
        excludedNegateProductLaunchList: newList,
      });
    },
    [form]
  );

  const handleChangeTabKey = useCallback((key) => {
    setActiveTabKey(key);
  }, []);

  return (
    <div>
      <ATabs
        type="card"
        onTabClick={handleChangeTabKey}
        activeKey={activeTabKey}
      >
        <ATabs.TabPane tab="排除品牌" key="1">
          <BrandExclude
            excludedList={excludedNegateProductLaunchList}
            onExclude={handleExcludeList}
          />
        </ATabs.TabPane>
        <ATabs.TabPane tab="排除全部" key="2"></ATabs.TabPane>
      </ATabs>

      <ExcludedList
        dataSource={excludedNegateProductLaunchList}
        onChange={handleExcludeListChange}
      />
    </div>
  );
};

export default NegateProductLaunch;
