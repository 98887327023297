import AJAX from "api/apis";
import { message } from 'antd';
import { encryptBy } from "utils/encryption"
import cookieUtils from "utils/cookie";

export const login = ({ email, password, storeId }) => {
    // return Promise.resolve(true);
    password = encryptBy(password);
    return AJAX.login.login({ email: email, password, storeId })
}

export const getUserInfo = async (e) => {
    let userData = {
        name: 'lhy'
    }
    // console.log(e);
    localStorage.setItem('userInfo', JSON.stringify(userData))
    // await dispatch({
    //     type: 'SET_USER_INFO',
    //     userInfo: userData
    // })
    return true

    // const res = await AJAX.login.getLoginInfo()
    // localStorage.setItem('userInfo', JSON.stringify(res.data))
    // return res
}
//退出
export const loginOut = () => {
    // localStorage.clear();
    // window.location.reload()
    return AJAX.login.loginOut().then(() => {
        // localStorage.clear();
        // cookieUtils.setCookie("token", "", -1)
        // cookieUtils.setCookie("amazon_token", "", -1)
        let propsRouter = window.location.hash
        propsRouter = propsRouter.replace('#', '')
        message.success("退出登录成功，请重新登录", 1, () => {
            // window.location.href = '/login?propsRouter=' + window.location.pathname;
            window.location.href = `/practice_amazon/#/login?receptionStoreId=${window.receptionStoreId}&propsRouter=${propsRouter}`;
        })
    })
}
