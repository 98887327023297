import React from 'react'
import './index.css'
import IconFont from 'components/Icon/Icon'

function All(){
    return(
        <div className="All_box">
            <div className="All_head">
                <div className="All_head_btn">
                    <button>索赔日期 <IconFont type="icon-sanjiaoxing"/></button>
                    <button>最近30天<IconFont type="icon-sanjiaoxing" /></button>
                </div>
                <div className="All_head_right">
                    <div className="All_head_right_divI"  >索赔日期<span><IconFont type="icon-sanjiao" /></span></div>
                    <div>索赔状态</div>
                </div>
            </div>


            <div className="All_content">
                <div>您的搜索未找到任何结果。</div>
            </div>
            <div className="All_bottom">
                <div className="All_bottom_page">
                <div>←</div>
                <div>以前的</div>
                <div>下一个</div>
                <div>→</div>
                </div>
                <div className="All_bottom_btn">
                <button>每页10条结果 <IconFont type="icon-sanjiaoxing"/></button>
                <div className="All_bottom_btn_select">
                    <div><a>每页10条结果</a></div>
                    <div><a>每页30条结果</a></div>
                    <div><a>每页50条结果</a></div>
                    <div><a>每页100条结果</a></div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default All