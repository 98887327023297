import React from 'react'
import AJAX from "api/apis";
import './atAglance.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import Tab1 from "./components/Tab1";
import Tab2 from "./components/Tab2";
import Tab3 from "./components/Tab3";
import Tab4 from "./components/Tab4";
import '../default.css/default.css'
import { Link } from 'react-router-dom';
import { Button, Radio, Input, Form, Select, DatePicker, Checkbox, Tabs } from 'antd';
import { fbaAddress } from "@/utils/utils";
import getUrlData from "@/utils/getUrlData";
const { TabPane } = Tabs;
const { Option } = Select
class completeShipment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            goodsList: [],
            planInfoList: [],
            planInfo: {},
            dataInfo: {
                distributionExecute: 0,
                boxType: null,
                sendGoodsDate: null
            },
            planId: '',
            addressDetails: '',
            classContent: '',
            classType: 1,
            classIndex: null,
            isModalVisible: false,
            ModalText: '',
            isReturn: false,
            isDeliveryStock: false,
            isCalculated: false,
            acceptanceFee: false,
            isCheckbox: true,
            isgoodsdisble: true,
            rules: [{ required: true, message: '' }],
            isSetGoodsShow: false,
            isOthercarriers: true
        }
    }
    componentWillMount() {
        let { planId } = this.state
        planId = getUrlData('id')
        planId && this.getCommodityPlanInfoView(getUrlData('id'))
        this.getStoreRegisterInfo()
    }
    getFormRef = el => {
        this.form = el;
    }
    upCommodityPlanInfoCommodityStatus = (id) => {
        AJAX.fba.upCommodityPlanInfoCommodityStatus({ id }).then((res) => {
            if (res.code == 1) {
                this.getCommodityPlanInfoView(id)
            }
        })

    }
    getCommodityPlanInfoView = (id) => {
        let { planInfo } = this.state
        AJAX.fba.getCommodityPlanInfoView({ id }).then((res) => {
            if (res.code == 1 && res.data) {
                planInfo = res.data
            }
            this.setState({
                planInfo: planInfo
            })
        })
    }
    getStoreRegisterInfo = () => {
        AJAX.login.getStoreRegisterInfo([{
            propertyKey: 'paytaxes_organization_site'
        }], 'isRegister=0').then((res) => {
            if (res.code == 1 && res.data) {
                this.setState({
                    addressDetails: res.data[0].propertyValue
                })
            }
        })
    }
    deleteCommdityPlan = () => {
        AJAX.fba.deleteCommdityPlan({ id: getUrlData('id') }).then((res) => {
            if (res.code == 1) {
                this.getCommodityPlanInfoView(getUrlData('id'))
                this.getStoreRegisterInfo()
            }
        })
    }
    render() {
        let { addressDetails, goodsList, planInfo, extend, dataInfo, rules, isCalculated, acceptanceFee, isCheckbox, isgoodsdisble, isSetGoodsShow, isOthercarriers } = this.state
        return <div className="atAglanceBox">
            <Header />
            <div>
                <div className="kc06_box1">
                    <a href="#">Send to Amazon</a>
                    <ul className="kc06_box1-ul">
                        <li>新功能</li>
                        <li><a href="#">货件处理进度</a></li>
                        <li><a href="#">入库问题一览</a></li>
                        <li><a href="#">扫描和贴标</a></li>
                        <li><a href="#">上传入库计划文件</a></li>
                    </ul>
                </div>
                <div className="kc06_mybody">
                    <div className="kc06_center">
                        <p className="kc06_h1">
                            发/补货
                            <span>了解更多信息</span>
                        </p>
                        <div className="kc06_body-border">
                            <ul className="kc06_body-ul">
                                <li>设置数量</li>
                                <li>预处理商品</li>
                                <li>为商品贴标</li>
                                <li>查看货件</li>
                                <li>预处理货件</li>
                                <li>一览<img src={require("@/assets/img/home/kc12_topsj.png").default} /></li>
                            </ul>
                        </div>
                        <div className="kc06_body-table">
                            <table className="kc06_table1">
                                <thead>
                                    <tr>
                                        <th>货件名称/编号</th>
                                        <th>发货地址</th>
                                        <th>配送地址</th>
                                        <th>货件内商品</th>
                                        <th>可选服务费用(预计)</th>
                                        <th>货件状态</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div>
                                                <div>
                                                    <span className="bolds">货件名称:</span>{planInfo.commodityPlanName}
                                                </div>
                                                <div>
                                                    <span className="bolds">编号:</span>{planInfo.commodityPlanNumber}
                                                </div>
                                                <div>
                                                    <span className="bolds">货件追踪编号:</span>{planInfo.commodityPlanTraceNumber || '--'}
                                                </div>
                                            </div>
                                        </td>
                                        <td>{fbaAddress(addressDetails)}</td>
                                        <td>{planInfo.commodityPlanAmazonAddRess}</td>
                                        <td>
                                            <p>{planInfo.commodityPlanMskuCount} MSKU</p>
                                            <p>{planInfo.commodityPlanMskuCount} 商品数量</p>
                                        </td>
                                        <td>
                                            <div>
                                                <div>亚马逊物流人工处理费用:${planInfo.commodityPlanManpowerMoney || 0}</div>
                                                <div>亚马逊合作承运人运费:${planInfo.commodityPlanSendMoney || 0}</div>
                                                <div>贴标和预处理:已针对总体入库计划给予计算</div>
                                            </div>
                                        </td>
                                        {/* <td>{planInfo.fbaCommodityPlanGoodsInfoRespList.length}件商品</td> */}
                                        <td>
                                            <div>
                                                <div>
                                                    {planInfo.deleteFlag ? '已取消' : planInfo.commodityStatus == 1 ? '处理中' : planInfo.commodityStatus == 2 ? '即将发货' : '已发货'}
                                                </div>
                                                <div>
                                                    <span className="bolds">已创建:</span>{planInfo.createTime}
                                                </div>
                                                <div>
                                                    <span className="bolds">已更新:</span>{planInfo.updateTime}
                                                </div>
                                                {planInfo.commodityStatus < 3 && !planInfo.deleteFlag ? <div className="signBox" onClick={() => { this.upCommodityPlanInfoCommodityStatus(planInfo.id) }}>标记为已发货</div> : null}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="tabsBox">
                                <Tabs type="card">
                                    <TabPane tab="货件活动 NEW!" key="1">
                                        <Tab1 planInfo={planInfo}></Tab1>
                                    </TabPane>
                                    <TabPane tab="追踪货件" key="2">
                                        <Tab2 planInfo={planInfo}></Tab2>
                                    </TabPane>
                                    <TabPane tab="货件内商品" key="3">
                                        <Tab3 planInfo={planInfo}></Tab3>
                                    </TabPane>
                                    <TabPane tab="货件差异查询" key="4">
                                        <Tab4 planInfo={planInfo}></Tab4>
                                    </TabPane>
                                </Tabs>
                            </div>
                            <div className="tabFooter">
                                <div className="btn-l">
                                    <Link to={{
                                        pathname: '/handlingCargo',
                                        search: `?planId=${planInfo.storagePlanId}`,
                                        state: {
                                            planId: planInfo.storagePlanId
                                        }
                                    }}><button>处理另一个货件</button></Link>
                                    {!planInfo.deleteFlag ? planInfo.commodityStatus == 2 ? <button onClick={this.deleteCommdityPlan}>删除货件</button> : planInfo.commodityStatus == 3 ? <button onClick={this.deleteCommdityPlan}>取消货件</button> : null : null}
                                    <button>复制</button>
                                </div>
                                <div className="btn-r">
                                    <Link to="/inboundQueue"><button style={{ paddingRight: '20px', borderRadius: '10px 15px 15px 10px' }}>
                                        完成货件<img className="kc_10_btn2" src={require("@/assets/img/home/btns.png").default} />
                                    </button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    }
}


export default completeShipment