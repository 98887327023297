import { InputNumber as AntInputNumber } from "antd";
import withProps from "./../withProps";

const InputNumber = (props) => {
  return <AntInputNumber {...props} />;
};

export default withProps(InputNumber, {
  className: "a-input-number",
});
