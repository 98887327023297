import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
    // min-width: 728px;
    display:flex;
    align-items: center;
    padding-left: 45px;
    font-size: 12px;
    justify-content: right;
    span.icon{
        padding-right: 35px;
        position: relative;
        &::after{
            width: 16px;
            height: 16px;
            content:'';
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            background: url('assets/images/home/usa.png') no-repeat;
        }
    }
    .websit.ant-select{
        width: 180px !important;
        height: 24px;
    }
    .language.ant-select {
        margin-left: 20px;
        width: 80px;
        height: 24px;
    }
    .websit.ant-select .ant-select-selector,
    .language.ant-select .ant-select-selector{
        height: 24px;
        input{
            height: 22px !important;
            line-height: 22px !important;
        }
        span{
            height: 22px !important;
            line-height: 22px !important;
        }
    }
    .websit.ant-select .ant-select-selector{
        width: 180px;
    }
    .language.ant-select .ant-select-selector{
        width: 80px;
    }
    .search{
        margin-left: 20px;
    }
    .search .ant-input-wrapper input,
    .search .ant-input-wrapper button{
        height: 24px;
    }
`;


export const Btns = styled.div`
    height: 24px;
    line-height: 24px;
    button{
        border: none;
        outline: none;
        background-color: #fff;
        cursor: pointer;
        padding: 0;
        span{
            padding: 1px 6px;
            font-weight: 700;
            color: #5c5c5c;
        }
        &:not(:nth-last-child(1)) span{
            border-right: 1px solid #999;
        }
    }
    .ant-popover .ant-popover-inner-content{
        padding: 5px;
    }
    .ant-popover ul{
        padding: 0;
        margin: 0;
    }
    .ant-popover ul li{
        min-width: 100px;
        text-align: center;
        cursor: pointer;
    }
    .ant-popover ul li:hover{
        background-color: #e4e4e4;
    }
`;