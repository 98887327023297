import React from 'react'
import './ScanLabel.css'
import '../../view/default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'

function ScanLabel() {
	return (
		<div>
			<Header />
			{/* <!--居中导航切换--> */}
			<div className="kc25_cennav">
				<ul>
					<li><a href="#">Send to Amazon</a><span className="kc25_gongn">新功能</span></li>
					<li className="kc25_huojian">货件处理进度</li>
					<li><a href="#">入库问题一览</a></li>
					<li><a href="#">扫描和贴标</a></li>
					<li><a href="#">上传入库计划文件</a></li>
				</ul>
			</div>
			{/* <!--提示信息--> */}
			<div className="kc25_fanhui">
				<div className="kc25_content">
					<div>扫描和贴标</div>
					<div>使用条形码和扫描仪和标签打印机扫描商品并打印标签<a href="#">了解更多信息</a></div>
					<div>
						{/* <div><img src="../../assets/images/kc25_tb.JPG" alt=""/><span>为商品贴标</span></div> */}
						<div className=""><div className="kc25_content_img"></div><div>为商品贴标</div></div>
					</div>
					<div className="kc25_content-foot">
						<div>扫描商品并为商品贴标</div>
						<div>扫描商品条形码以查看匹配的商品。</div>
						<div>扫描商品 UPC/ISBN/EAN/JAN条形码或输入<br />卖家SKU</div>
						<div>
							{/* <img src="../../assets/images/kc25_sku.jpg" alt="" srcset=""/> */}
							<div className="kc25_content_foot_img"></div>
						</div>
					</div>
				</div>
			</div>
			<Footer />



		</div>
	)
}

export default ScanLabel