import React, { useRef, useEffect, useCallback, useState } from 'react';
import "./register.css";
import AJAX from "api/apis";
import cookieUtils from "utils/cookie";
import { continuePath } from "utils/utils";

import { Container, FormContainer, FormTitle, FormItemTop, BottomLine, ToLogin } from './style';
import { Form, Input, Button, Modal } from 'antd';
import HeaderImage from 'components/headerimage';
import { Link, withRouter } from 'react-router-dom';
import { encryptBy } from "utils/encryption"
import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"

export default (props) => {
    let formRef = useRef();
    let [loading, setLoading] = useState(false);
    let [errTips, setErrTips] = useState(false);
    let [isModalVisible, setIsModalVisible] = useState(false);
    let [isEliminate, setIsEliminate] = useState(false);
    let [storeStepData, setStoreStepData] = useState(null)
    let [storeStepExtend, setStoreStepExtend] = useState(null)
    let [tipsList, setTipsList] = useState();
    let [assessment, setAssessment] = useState()
    let [emailRuels, setEmailRules] = useState([{ required: true, pattern: '^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$', message: '' }])
    let [isTourOpen, setIsTourOpen] = useState(false);
    let [okTexts, setOkTexts] = useState('重新考核');
    let [cancelTexts, setCancelText] = useState('继续操作');

    let steps = [
        {
            selector: '.stepGuidanceBox0',
            content: '需要编写一个账号名称， 不能跟其他卖家相同',
        },
        {
            selector: '.stepGuidanceBox1',
            content: '邮箱必须是新注册的邮箱，163,126, qq邮箱都是可以的，但是注意要邮箱必须是新注册的',
        },
        {
            selector: '.stepGuidanceBox2',
            content: '密码，自行设置的密码，6位以上，不要忘记',
        },
        {
            selector: '.stepGuidanceBox3',
            content: '密码，自行设置的密码，6位以上，不要忘记',
        },
    ];
    const [isActive, setIsActive] = useState(false);
    const awakenTips = (type) => {
        setIsActive(type);
    }
    useEffect(() => {
        getcookies()
        getStoreStep()
    }, [])
    let getcookies = useCallback(() => {
        let token = cookieUtils.getCookie('token')
        // if (token) cookieUtils.setCookie('amazon_token', token)
    })
    let getStoreStep = useCallback(() => {
        let sourceCookie = cookieUtils.getCookie('source');
        AJAX.login.getStoreStep().then((res) => {
            if (res.code == 1) {
                if (sourceCookie == 0 || !sourceCookie) {
                    if (res.data == 1) {
                        setIsTourOpen(true)
                    } else if (res.data == 2) {
                        setAssessment('检查到您的账户未激活，是否继续操作')
                        setStoreStepData(res.data)
                        setStoreStepExtend(res.extend)
                        setIsModalVisible(true)
                        setOkTexts(null)
                        setCancelText('去激活')
                    } else if (res.data == 3) {
                        props.history.push('/Agreements')
                    } else if (res.data == 4) {
                        setAssessment('检查到您上次有未完成的考核，是否继续操作')
                        setStoreStepData(res.data)
                        setStoreStepExtend(res.extend)
                        setIsModalVisible(true)
                        setOkTexts('继续操作')
                        setCancelText('重新考核')
                    } else if (res.data == 5) {
                        if (res.extend > 0) {
                            setAssessment('检查到您上次有未完成的考核，是否继续操作')
                            setOkTexts('继续操作')
                        } else {
                            setAssessment('您已考核完毕，可重新考核')
                            setOkTexts(null)
                        }
                        setCancelText('重新考核')
                        setStoreStepData(res.data)
                        setStoreStepExtend(res.extend)
                        setIsModalVisible(true)
                    } else if (res.data == 6) {
                        props.history.push({
                            pathname: '/sendEmails',
                            state: {
                                userEmial: res.extend
                            }
                        })
                    }
                    // console.log(res)
                } else if (sourceCookie == 1 || sourceCookie == 2) {
                    if (res.data == 1) {
                        setIsTourOpen(true)
                    } else if (res.data == 2) {
                        props.history.push({
                            pathname: '/mobileNumber',
                        })
                    } else if (res.data == 3) {
                        props.history.push('/Agreements')
                    } else if (res.data == 4 || res.data == 5) {
                        sessionStorage.setItem("operationType", 1);
                        props.history.push('/Agreements')
                    } else if (res.data == 6) {
                        props.history.push({
                            pathname: '/sendEmails',
                            state: {
                                userEmial: res.extend
                            }
                        })
                    }
                }
            } else {
                setIsTourOpen(true)
            }
        })
    }, [])
    const verifyEmail = useCallback(() => {  //验证邮箱是否存在
        formRef.current.validateFields(['userEmial']).then(({ userEmial }) => {
            AJAX.login.verifyEmail({ email: userEmial }).then((res) => {

            }).catch((err) => {
                if (err.code == -1) {
                    setErrTips(true)
                    setTipsList([err.msg])
                    formRef.current.validateFields(['userEmial'])
                }
            })
        })
    }, [])
    const handleRegister = useCallback(() => {  //用户注册
        // console.log(loading)
        if (loading) return;
        formRef.current.validateFields().then(({ userName, userEmial, password, verifyPassword }) => {
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, 3500)
            if (password == verifyPassword) {
                password = encryptBy(password)
                verifyPassword = encryptBy(verifyPassword)
                AJAX.login.register({ userName, password, email: userEmial, verifyPassword })
                    .then((res) => {
                        setErrTips(false)
                        if (Number(res.code) === 1) {
                            props.history.push({
                                pathname: '/sendEmails',
                                state: {
                                    userEmial: userEmial
                                }
                            })
                        }
                        setTimeout(() => {
                            setLoading(false);
                        }, 3500)
                    }).catch((err) => {
                        console.log(err)
                        setTipsList([err.msg])
                        setTimeout(() => {
                            setLoading(false);
                        }, 3500)
                    })
            } else {
                setErrTips(true)
                setTipsList(["两次密码不一致"])
                formRef.current.validateFields()
            }
        }).catch((err) => {
            let arr = []
            setErrTips(true)
            err.errorFields.map((item) => {
                if (item.name[0] == "userName") {
                    arr.push("请输入账号名称")
                } else if (item.name[0] == 'userEmial') {
                    arr.push("请输入一个有效的电子邮件地址")
                } else if (item.name[0] == 'password') {
                    arr.push("请输入一个有效的密码")
                } else if (item.name[0] == 'verifyPassword') {
                    arr.push("请输入再次输入密码")
                }
            })
            setTipsList(arr)
            setLoading(false);
        })
    }, [tipsList, loading, setLoading])
    const emialChonge = useCallback(() => {
        setEmailRules([{ required: true, pattern: '^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$', message: '邮箱验证失败' }])
    }, [])
    let eliminateHandleOk = useCallback(() => {
        localStorage.setItem('regEliminate', true)
        props.history.push('/Agreements')
    }, [])
    let eliminateHandleCancel = useCallback(() => {
        localStorage.setItem('regEliminate', false)
        props.history.push('/Agreements')
    }, [])

    let handleOk = useCallback(() => {
        if (storeStepData == 2) {
            props.history.push({
                pathname: '/sendEmails',
                state: {
                    userEmial: storeStepExtend
                }
            })
        } else {
            // props.history.push('/Agreements')
            setIsEliminate(true)
        }
    }, [storeStepData, storeStepExtend])
    let handleCancel = useCallback(() => {
        let paths = continuePath(storeStepExtend);
        sessionStorage.setItem("operationType", 1);
        // console.log(paths, storeStepExtend);
        props.history.push(paths)
    }, [storeStepData, storeStepExtend])
    return <Container className='register'>
        {/* 引导步骤 */}
        <TipsIcon clicks={awakenTips} />
        {isTourOpen ? <Reactours routePath="register" isActive={isActive} clicks={awakenTips} steps={steps} isOpen={isTourOpen} /> : null}
        <HeaderImage />
        <div>
            <h4>Please Enable Cookies to Continue</h4>
        </div>
        <FormContainer>
            {errTips ?
                <div className="error-message-box">
                    <div className="errTipsBox">
                        <img src={require("@/assets/img/login/warning.png").default} />
                        <div>
                            <h2 className="fz20 colRed bolds">出问题了</h2>
                            <ul>
                                {tipsList ? tipsList.map((item) => {
                                    return <li>{item}</li>
                                }) : null}
                            </ul>
                        </div>
                    </div>
                </div> : null}
            <FormTitle>创建账号</FormTitle>
            <Form ref={formRef} className="registerForm">
                <div className="stepGuidanceBox0">
                    <FormItemTop>
                        <p className='title'>您的姓名</p>
                    </FormItemTop>
                    <Form.Item
                        name="userName"
                        rules={[{ required: true, message: '请输入账号名称' }]}
                    >
                        <Input maxLength="30" />
                    </Form.Item>
                </div>
                <div className="stepGuidanceBox1">
                    <FormItemTop>
                        <p className='title'>邮箱地址</p>
                    </FormItemTop>
                    <Form.Item
                        name="userEmial"
                        rules={[{
                            required: true, validator: (rule, value, cbfn) => {
                                if (!value) {
                                    cbfn('请输入一个有效的邮件地址')
                                }
                                let reg = /^([a-zA-Z]|[0-9]|[_-])+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
                                if (!reg.test(value)) {
                                    cbfn('邮箱格式不正确')
                                }
                                cbfn()
                            }
                        }]}
                    >
                        {/* onChange={emialChonge} */}
                        <Input onBlur={verifyEmail} />
                    </Form.Item>
                </div>
                <div className="stepGuidanceBox2">
                    <FormItemTop>
                        <p className='title'>密码</p>
                        <p className='forgot'>Forgot your password?</p>
                    </FormItemTop>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, min: 6, message: '请输入一个有效的密码' }]}
                    >
                        <Input.Password maxLength="20" placeholder="至少输入6位字符" />
                    </Form.Item>
                </div>
                <div className="stepGuidanceBox3">
                    <FormItemTop>
                        <p className='title'>重新输入密码</p>
                    </FormItemTop>
                    <Form.Item
                        name="verifyPassword"
                        rules={[{ required: true, message: '请再次输入密码' }]}
                    >
                        <Input.Password maxLength="20" placeholder="至少输入6位字符" />
                    </Form.Item>
                </div>
                <Button className='next' type="primary" loading={loading} onClick={handleRegister}> 下一步 </Button>
            </Form>
            <ToLogin>
                <p>Already have an account? <Link to='/login'>登录</Link></p>
            </ToLogin>
        </FormContainer>
        <BottomLine>
            <p>© 1996-2022, Amazon.com, Inc. or its affiliates</p>
        </BottomLine>
        {/* 继续操作 */}
        <Modal title="提示" className="stepControlBox" closable={false} maskClosable={false} footer={[
            // 定义右下角 按钮的地方 可根据需要使用 一个或者 2个按钮
            <Button data="hovers-f" key="back" className={okTexts ? '' : 'disBtn'} onClick={handleCancel}>{okTexts}</Button>,
            <Button data="hovers-7" key="submit" type="primary" loading={loading} onClick={handleOk}>{cancelTexts}</Button>
        ]} visible={isModalVisible}>
            <div>
                <img src={require("@/assets/img/home/tips.png").default} />
            </div>
            <p style={{ marginTop: '30px' }}>{assessment}</p>
        </Modal>

        {/* 是否清除 */}
        <Modal title="提示" className="stepControlBox" closable={false} maskClosable={false} footer={[
            // 定义右下角 按钮的地方 可根据需要使用 一个或者 2个按钮
            <Button data="hovers-f" key="back" onClick={eliminateHandleCancel}>否</Button>,
            <Button data="hovers-7" key="submit" type="primary" loading={loading} onClick={eliminateHandleOk}>是</Button>
        ]} visible={isEliminate}>
            <div>
                <img src={require("@/assets/img/home/tips.png").default} />
            </div>
            <p style={{ marginTop: '30px' }}>是否清空之前填写的资料？</p>
        </Modal>

    </Container>
}