import { useMemo, useState, useReducer, useCallback, useEffect } from "react";
import Header from "@components/home_header";
import Footer from "@components/addlisting_footer/FooterCom";
import { Steps } from "antd";
import { AButton, AText } from "@a-components";
import GoodsSearchAdd from "./GoodsSearchAdd";
import BudgetDiscount from "./BudgetDiscount";
import PriceTableTarget from "./PriceTableTarget";
import ReviewSubmit from "./ReviewSubmit";
import Reactours from "components/reactours"
import TipsIcon from "components/tipsIcon"
import Apis from "@api/apis";
import { useHistory } from "react-router";
import useQuery from "@hooks/useQuery";
import classNames from "classnames";

import style from "./style.module.css";
import React from "react";
const stepList = [
  {
    title: "搜索和添加商品",
    value: 1,
  },
  {
    title: "预算和折扣",
    value: 2,
  },
  {
    title: "价目表和目标",
    value: 3,
  },
  {
    title: "查看并提交",
    value: 4,
  },
];
// const isTourOpen = true;
// const steps1 = [
//   {
//     selector: '.border-r-ccc .mb-24',
//     content: '输入ANIS筛选产品',
//   },
//   {
//     selector: '.border-r-ccc',
//     content: '从筛选的结果中选择产品添加至优惠券',
//   },
//   {
//     selector: '.pl-24',
//     content: '添加成功，如果添加了错误的产品可选择移除重新添加',
//   },
//   {
//     selector: '.ph-24',
//     content: '点击进行下一步',
//   },
// ]
const computedCouponFullName = ({
  discountChoice,
  discountPrice,
  couponName,
}) => {
  const couponPrefix = `Save ${discountChoice === 1 ? `$${discountPrice}` : `${discountPrice}%`
    } on`;

  return [couponPrefix, couponName].join(" ");
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_SELECTEDGOODSLIST":
      return {
        ...state,
        goodsSearchAdd: {
          selectedGoodsList: action.payload,
          isPass: !!action.payload.length,
        },
      };
    case "SET_BUDGETDISCOUNT": {
      const { discountChoice, discountPrice } = action.payload;
      return {
        ...state,
        budgetDiscount: {
          ...state.budgetDiscount,
          ...action.payload,
        },
        priceTableTarget: {
          ...state.priceTableTarget,
          couponFullName: computedCouponFullName({
            discountChoice,
            discountPrice,
            couponName: state.priceTableTarget.couponName,
          }),
        },
      };
    }
    case "SET_BUDGETDISCOUNT_STATUS":
      return {
        ...state,
        budgetDiscount: {
          ...state.budgetDiscount,
          isPass: action.payload,
        },
      };
    case "SET_PRICETABLETARGET":
      return {
        ...state,
        priceTableTarget: {
          ...state.priceTableTarget,
          ...action.payload,
          couponFullName: computedCouponFullName({
            ...action.payload,
            ...state.budgetDiscount,
          }),
        },
      };
    case "SET_PRICETABLETARGET_STATUS":
      return {
        ...state,
        priceTableTarget: {
          ...state.priceTableTarget,
          isPass: action.payload,
        },
      };
    default:
      throw new Error();
  }
}
const initialState = {
  goodsSearchAdd: {
    selectedGoodsList: [],
    isPass: false,
  },

  budgetDiscount: {
    discountChoice: 1,
    budgetPrice: "",
    discountPrice: "",
    exchangeTimes: 1,

    isPass: false,
  },
  priceTableTarget: {
    startTime: null,
    endTime: null,
    couponName: "",
    couponFullName: "",
    targetCustomers: 1,
    isPass: false,
  },
};

const CreateCoupon = () => {
  const { couponsId } = useQuery();
  const [loading, setLoading] = useState(false)
  const [current, setCurrent] = useState(() => {
    return couponsId ? 3 : 0;
  });
  const [{ goodsSearchAdd, budgetDiscount, priceTableTarget }, dispatch] =
    useReducer(reducer, initialState);

  const history = useHistory();

  const isPassArr = useMemo(() => {
    const arr = [
      goodsSearchAdd.isPass,
      budgetDiscount.isPass,
      priceTableTarget.isPass,
    ];
    return [...arr, arr.every(Boolean)];
  }, [budgetDiscount, goodsSearchAdd, priceTableTarget]);
  const [couponsLoading, setCouponsLoading] = useState(true)

  const isPass = useMemo(() => {
    return isPassArr[current];
  }, [isPassArr, current]);

  const setSelectedGoodsList = useCallback((list) => {
    dispatch({ type: "SET_SELECTEDGOODSLIST", payload: list });
  }, []);

  const handleBudgetDiscountChange = useCallback((payload) => {
    dispatch({ type: "SET_BUDGETDISCOUNT", payload });
  }, []);

  const handleBudgetDiscountPassStatusChange = useCallback((payload) => {
    dispatch({ type: "SET_BUDGETDISCOUNT_STATUS", payload });
  }, []);

  const handlePriceTableTargetChange = useCallback((payload) => {
    dispatch({ type: "SET_PRICETABLETARGET", payload });
  }, []);

  const handlePriceTableTargetPassStatusChange = useCallback((payload) => {
    dispatch({ type: "SET_PRICETABLETARGET_STATUS", payload });
  }, []);

  const computedParams = useMemo(() => {
    return {
      couponsId,
      ...budgetDiscount,
      ...priceTableTarget,
      goodsIds: goodsSearchAdd.selectedGoodsList
        .map((v) => v.goodsId)
        .join(","),
    };
  }, [
    budgetDiscount,
    couponsId,
    goodsSearchAdd.selectedGoodsList,
    priceTableTarget,
  ]);

  const updateCoupons = useCallback(() => {
    return Apis.coupons.updateCoupons(computedParams);
  }, [computedParams]);

  const addCoupons = useCallback(() => {
    return Apis.coupons.addCoupons(computedParams);
  }, [computedParams]);

  const getCouponsEditDetail = useCallback(
    (couponsId) => {
      return Apis.coupons
        .getCouponsEditDetail({
          couponsId,
        })
        .then((res) => {
          if (res.code !== 1) return res;
          const {
            budgetPrice,
            couponName,
            discountChoice,
            discountPrice,
            endTime,
            exchangeTimes,
            startTime,
            targetCustomers,
            goodsCouponsRespList,
          } = res.data;

          setSelectedGoodsList(goodsCouponsRespList);
          handleBudgetDiscountChange({
            discountChoice,
            budgetPrice,
            discountPrice,
            exchangeTimes,
          });

          handlePriceTableTargetChange({
            startTime,
            endTime,
            couponName,
            targetCustomers,
          });
        });
    },
    [
      handleBudgetDiscountChange,
      handlePriceTableTargetChange,
      setSelectedGoodsList,
    ]
  );
  let [isTourOpen, setIsTourOpen] = useState(true)
  let [steps1, setSteps1] = useState([
    {
      selector: '.border-r-ccc .mb-24',
      content: '输入ASIN筛选商品',
    },
    {
      selector: '.border-r-ccc',
      content: '从筛选的结果中选择产品添加至优惠券，选择的商品必须要跟操作资料中的一致',
    },
    {
      selector: '.pl-24',
      content: '添加成功，如果添加了错误的产品可选择移除重新添加',
    }
  ])
  useEffect(() => {
    couponsId && getCouponsEditDetail(couponsId);
  }, [couponsId, getCouponsEditDetail]);
  const swithSteps = (e) => {
    // console.log(e, current)
    let num = e > current ? current : e
    if (!isPassArr[num]) return
    if (e == 0) {
      setSteps1([
        {
          selector: '.border-r-ccc .mb-24',
          content: '输入ASIN筛选商品',
        },
        {
          selector: '.border-r-ccc',
          content: '从筛选的结果中选择产品添加至优惠券',
        },
        {
          selector: '.pl-24',
          content: '添加成功，如果添加了错误的产品可选择移除重新添加',
        }
      ])
    } else if (e == 1) {
      setSteps1([
        {
          selector: '.border-r-ccc .ant-radio-group',
          content: '选择满减或者减免折扣',
        },
        {
          selector: '.a-input-money',
          content: '填写满减金额或者百分比折扣',
        },
        {
          selector: '.border-t-ccc',
          content: '选择是否限制同一个买家只能兑换一次优惠券',
        },
        {
          selector: '.pl-24 .a-input-money',
          content: '设置优惠券折扣预算（最低100美金）'
        }
      ])
    } else if (e == 2) {
      setSteps1([
        {
          selector: '.df .discountName',
          content: '添加优惠券名称（此名称前台页面显示，买家可见）',
        },
        {
          selector: '.df .targetBuyer',
          content: '目标买家，选择哪些买家可使用优惠券（Prime会员/学生/妈妈群体）',
        },
        {
          selector: '.df .times',
          content: '设置折扣开始时间和结束时间（90天内）',
        }
      ])
    } else if (e == 3) {
      setSteps1([
        {
          selector: '.df .discountSubmitBox',
          content: '检查信息是否正确',
        },
        {
          selector: '.ph-24',
          content: '点击提交优惠券',
        },
      ])
    }
    setCurrent(e);
  }
  const [isActive, setIsActive] = useState(false);
  const awakenTips = (type) => {
    setIsActive(type);
  }
  const handleNext = useCallback(() => {
    // console.log(isPassArr,current,loading)
    if (loading) return
    if (!isPassArr[current]) return
    if (current === 3) {
      // if (!isPass) return;
      if (!couponsLoading) return
      setCouponsLoading(false)
      setLoading(true)
      const request = couponsId ? updateCoupons : addCoupons;
      request().then((res) => {
        setLoading(false)
        if (res.code === 1) {
          history.goBack();
        }
      }).catch(err => {
        setTimeout(() => { setCouponsLoading(true) }, 3000)
        setLoading(false)
      });
    } else {
      let e = Number(current) + 1;
      swithSteps(e)
      // isPassArr[current] && setCurrent((pre) => ++pre);
    }
  }, [
    loading,
    addCoupons,
    couponsId,
    current,
    history,
    isPass,
    isPassArr,
    updateCoupons,
    couponsLoading,
    setCouponsLoading,
  ]);

  const renderContent = useCallback(() => {
    return (
      <React.Fragment>
        <GoodsSearchAdd
          className={classNames({ [style.hide]: current !== 0 })}
          selectedGoodsList={goodsSearchAdd.selectedGoodsList}
          updateSelectedGoodsList={setSelectedGoodsList}
        />
        <BudgetDiscount
          className={classNames({ [style.hide]: current !== 1 })}
          {...budgetDiscount}
          onChange={handleBudgetDiscountChange}
          onPassStatusChange={handleBudgetDiscountPassStatusChange}
        />
        <PriceTableTarget
          className={classNames({ [style.hide]: current !== 2 })}
          {...budgetDiscount}
          {...priceTableTarget}
          onChange={handlePriceTableTargetChange}
          onPassStatusChange={handlePriceTableTargetPassStatusChange}
        />
        <ReviewSubmit
          className={classNames({ [style.hide]: current !== 3 })}
          selectedGoodsList={goodsSearchAdd.selectedGoodsList}
          {...budgetDiscount}
          {...priceTableTarget}
        />
      </React.Fragment>
    );
  }, [
    goodsSearchAdd.selectedGoodsList,
    setSelectedGoodsList,
    budgetDiscount,
    handleBudgetDiscountChange,
    handleBudgetDiscountPassStatusChange,
    priceTableTarget,
    handlePriceTableTargetChange,
    handlePriceTableTargetPassStatusChange,
    current,
  ]);
  return (
    <div className={style.createCoupon}>
      <Header />
      <div className="p-24">
        <div className="df ai-c jc-sb pr-24">
          <Steps size="small" current={current} onChange={swithSteps}>
            {stepList.map((item) => {
              return <Steps.Step key={item.title} title={item.title} />;
            })}
          </Steps>

          <div className="ph-24 p-r" style={{ marginLeft: 24 }}>
            <AButton type="primary" onClick={handleNext}>
              {current === 3 ? "提交优惠券" : "继续下一步"}
            </AButton>
            <br />
            {current === 3 ? (
              <div className="p-a" style={{ width: 156 }}>
                <AText className="fs-12">提交此优惠券，即表示您接受 </AText>
                <AButton className="fs-12 p-0 h-12" type="link">
                  亚马逊优惠券条款和条件
                </AButton>
              </div>
            ) : null}
          </div>
        </div>
        <div className="pt-24">{renderContent()}</div>
      </div>
      <Footer />
      {/* 引导步骤 */}
      <TipsIcon clicks={awakenTips} />
      <Reactours steps={steps1} isActive={isActive} clicks={awakenTips} routePath="CreateCoupon" discountStep={current} isOpen={isTourOpen} />
    </div>
  );
};

export default CreateCoupon;
