import React, { forwardRef, useCallback, useMemo, useState } from "react";
import { AButton } from "@a-components";
import { Space } from "antd";

const withProps = (component, config = {}) => {
  const { className: _className } = config;

  return forwardRef(
    (
      {
        className,
        confirmMode,
        onFocus,
        value,
        checked,
        onChange,
        $Sure = () => Promise.resolve({ type: "success" }),
        ...otherProps
      },
      ref
    ) => {
      const $value = checked !== undefined ? checked : value;
      const $valueKey = checked !== undefined ? "checked" : "value";

      const [confirmVisible, setConfirmVisible] = useState(false);
      const [innerValue, setInnerValue] = useState($value);
      const [isLoading, setLoadingStatus] = useState(false);

      const innerClassName = useMemo(() => {
        return [_className, className].join(" ");
      }, [className]);

      const handleFocus = useCallback(
        (e) => {
          if (confirmMode) {
            setConfirmVisible(true);
            setInnerValue($value);
          }
          onFocus?.(e);
        },
        [confirmMode, onFocus, $value]
      );

      const handleValueChange = useCallback(
        (e) => {
          if (!confirmVisible) onChange?.(e);
          const _value = e?.target?.value ?? e?.target?.checked ?? e;

          setInnerValue(_value);
        },
        [confirmVisible, onChange]
      );

      const handleSure = useCallback(() => {
        setLoadingStatus(true);
        $Sure(innerValue)
          .then(({ type }) => {
            if (type === "success") {
              onChange?.(innerValue);
              setConfirmVisible(false);
            }
          })
          .finally(() => {
            setLoadingStatus(false);
          });
      }, [$Sure, innerValue, onChange]);

      const handleCancel = useCallback(() => {
        setInnerValue(value);
        setConfirmVisible(false);
      }, [value]);

      const realValue = useMemo(() => {
        const value = confirmVisible ? innerValue : $value;

        return { [$valueKey]: value };
      }, [$value, $valueKey, confirmVisible, innerValue]);

      const child = useMemo(() => {
        return React.createElement(component, {
          ...otherProps,
          ref,
          className: innerClassName,
          onChange: handleValueChange,
          onFocus: handleFocus,
          ...realValue,
        });
      }, [
        handleFocus,
        handleValueChange,
        innerClassName,
        otherProps,
        realValue,
        ref,
      ]);

      if (!confirmMode) return child;

      return (
        <div className="confirm-container">
          {child}

          {confirmVisible ? (
            <Space style={{ marginLeft: 12 }}>
              <AButton
                type="primary"
                size="middle"
                onClick={handleSure}
                loading={isLoading}
              >
                保存
              </AButton>
              <AButton size="middle" onClick={handleCancel}>
                取消
              </AButton>
            </Space>
          ) : (
            <span />
          )}
        </div>
      );
    }
  );
};

export default withProps;
