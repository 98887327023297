import { Alert, Space, Divider } from "antd";
import { AText } from "@a-components/";
import { Link } from "react-router-dom";
import AdvertisingPageLayout from "@view/advertisement/module/AdvertisingPageLayout";
import { useCallback, useEffect, useMemo, useState } from "react";
import useQuery from "@hooks/useQuery";
import Apis from "@api/apis";
import moment from "moment";

const CreateAdvertisingSuccess = () => {
  const { advertisingId } = useQuery();
  const [advertisingInfo, setAdvertisingInfo] = useState({
    dailyBudgetPrice: 0,
    defaultPrice: 0,
    advertisingGroupGoodsStartUpVoList: [],
  });
  const {
    advertisingGroupName,
    advertisingCombinationName,
    advertisingName,
    startTime,
    endTime,
    areaName,
    dailyBudgetPrice,
    positioningName,
    searchTopProportion,
    commodityPage,
    defaultPrice,
    advertisingGroupGoodsStartUpVoList,
    negativeKeywordsNum,
    negativeDirectionalNum,
    biddingTypeName,
  } = advertisingInfo;

  const getAdvertisingStartUpPage = useCallback(() => {
    return Apis.advertising
      .getAdvertisingStartUpPage({ advertisingId })
      .then((res) => {
        if (res.code !== 1) return res;

        setAdvertisingInfo(res.data);
      });
  }, [advertisingId]);

  useEffect(() => {
    getAdvertisingStartUpPage();
  }, [getAdvertisingStartUpPage]);

  const leftList = useMemo(() => {
    return [
      {
        label: "站点",
        value: areaName,
      },
      {
        label: "组合",
        value: advertisingCombinationName || "-",
      },
      {
        label: "进程安排",
        value: `${moment(startTime).format("YYYY年MM月DD日")} - ${
          endTime ? moment(endTime).format("YYYY年MM月DD日") : "无结束日期"
        }`,
      },
      {
        label: "每日预算",
        value: `$${dailyBudgetPrice.toFixed(2)}`,
      },
      {
        label: "定向策略",
        value: positioningName,
      },
      {
        label: "广告活动竞价策略",
        value: biddingTypeName,
      },
      {
        label: "根据广告位调整竞价",
        value: `搜索结果顶部（首页）: ${searchTopProportion}%， 商品页面: ${commodityPage}%`,
      },
    ];
  }, [
    advertisingCombinationName,
    areaName,
    commodityPage,
    dailyBudgetPrice,
    endTime,
    positioningName,
    searchTopProportion,
    startTime,
  ]);
  const rightList = useMemo(() => {
    return [
      {
        label: "广告组名称",
        value: advertisingGroupName,
      },
      {
        label: "默认竞价",
        value: `$${defaultPrice.toFixed(2)}`,
      },
      {
        label: "推广的商品",
        value: () => {
          return (
            <>
              {advertisingGroupGoodsStartUpVoList.map((item) => {
                return (
                  <img
                    style={{ width: 25, height: 25 }}
                    src={item.imgUrl}
                    key={item.goodsId}
                    alt=""
                  />
                );
              })}
            </>
          );
        },
      },
      {
        label: "否定关键词",
        value: negativeKeywordsNum,
      },
      {
        label: "否定商品",
        value: negativeDirectionalNum,
      },
    ];
  }, [
    advertisingGroupGoodsStartUpVoList,
    advertisingGroupName,
    defaultPrice,
    negativeDirectionalNum,
    negativeKeywordsNum,
  ]);

  return (
    <AdvertisingPageLayout>
      <div className="mh-auto pt-24" style={{ width: 1200 }}>
        <Alert
          message="恭喜，您的广告活动已成功启动。"
          description="注意：处理您的广告活动最多可能需要1小时，之后它才有资格获得展示次数。"
          type="success"
          showIcon
          closable
        />

        <div className="mt-24">
          <div className="fs-24">
            广告活动：
            <AText className="fs-24" strong>
              {advertisingName}
            </AText>
          </div>
          <div className="mt-24 df">
            <div className="mr-24">
              <AText className="fs-18" strong>
                广告活动设置
              </AText>
              {leftList.map((item) => {
                return (
                  <div key={item.label} className="lh-22 fs-14">
                    <AText className="fs-14 lh-24 c-999">{item.label}:</AText>
                    <AText className="fs-14 lh-24 ml-6">{item.value}</AText>
                  </div>
                );
              })}
            </div>
            <div>
              <AText className="fs-18" strong>
                广告组设置
              </AText>
              {rightList.map((item) => {
                return (
                  <div key={item.label} className="df">
                    <AText className="fs-14 lh-24 c-999">{item.label}:</AText>
                    <div>
                      {typeof item.value === "function" ? (
                        item.value()
                      ) : (
                        <AText className="fs-14 lh-24 ml-6">{item.value}</AText>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="df jc-fe">
          <Space split={<Divider type="vertical" />}>
            <Link to="/Advertisement">前往“广告活动管理”</Link>
            <Link
              to={`/Advertisement/advertisingDetail?advertisingId=${advertisingId}`}
            >
              编辑广告活动
            </Link>
          </Space>
        </div>
      </div>
    </AdvertisingPageLayout>
  );
};

export default CreateAdvertisingSuccess;
