import React from 'react'
import './Reports.css'
import {Link} from 'react-router-dom'
import IconFont from 'components/Icon/Icon'
function Reports(){
    return(
        <div className="Reports_Body">
            <div className="Reports_Box">
            <div className="Reports_leftBox">
                <div className="Reports_leftBox_top">
                <div>您的报告</div>
                </div>
                <div className="Reports_leftBox_middle">
                    <Link>搜索推荐</Link>
                </div>
                <div className="Reports_leftBox_bottom">
                    <div>库存（0）</div>
                    <div>销售机会（0）</div>
                    <div>配送（0）</div>
                    <div>广告（0）</div>
                </div>
            </div>
            <div className="Reports_rightBox">
                <div className="Reports_rightBox_head">
                    <div style={{color:"#D8A74B"}}>为了向您提供更准确的信息，现在可以在以下位置找到与库存相关的所有数据：</div>
                    <ul>
                        <li><Link>MFN商品的MYI</Link></li>
                        <li><Link>亚马逊物流商品补货库存报告</Link></li>
                    </ul>
                </div>
                <div className="Reports_rightBox_headII">
                    <div><button><IconFont type="icon-zuosanjiao" /> </button></div>
                    <div>库存建议</div>
                    <div><Link>了解更多信息</Link></div>
                    <div>观看演示</div>
                </div>
                <div className="Reports_rightBox_headIII">
                    <IconFont type="icon-jinggao4" />未找到任何结果.
                </div>

                <div className="Reports_rightBox_headIV">
                    <div className="Reports_rightBox_TopBtn">
                        <div className="Reports_rightBox_TopBtn_inp"><IconFont type="icon-sousuo"/><input placeholder="搜索" /></div>
                        <div className="Reports_rightBox_TopBtn_btn"><button>搜索</button></div>
                        <div className="Reports_rightBox_TopBtn_font">0结果</div>
                        <div className="Reports_rightBox_TopBtn_btnI"><button>下载</button></div>
                        <div className="Reports_rightBox_TopBtn_btnII"><button>补充亚马逊物流库存</button></div>
                    </div>
                    <div className="Reports_rightBox_TopBtn_btnIII">
                        <button>首选项:隐藏 1 列 <IconFont type="icon-fanhui-copy-copy" /></button>
                    </div>
                </div>
                <div className="Reports_rightBox_categoryBox">
                    <div className="Reports_rightBox_categoryBoxI">
                        <div>
                            <div>SKU</div>
                            <div>配送渠道</div>
                        </div>
                        <div>图片</div>
                        <div>
                            <div className="Reports_rightBox_categoryName">商品名称</div>
                            <div>ASIN</div>
                        </div>
                        <div className="Reports_rightBox_categoryName">过去30天内的销售情况</div>
                        <div className="Reports_rightBox_categoryName">过去14天内的销售情况</div>
                        <div className="Reports_rightBox_categoryName">当前库存</div>
                        <div className="Reports_rightBox_categoryName">预计可销售天数</div>
                        <div>入库数量</div>
                        <div>推荐入库数量</div>
                        <div>过去30天的商品缺货天数</div>
                        <div>过去30天内的销售损失($)</div>
                        <div>类型</div>
                        <div>操作</div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}


export default Reports