import React from 'react'
import MengkHeader from './module/MengkHeader/MengkHeader'
import AdvertisementLeft from '@view/advertisement/module/advertisementLeft/advertisementLeft'
import { Button, Row, Col, Carousel, Modal } from 'antd'
import { RightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'
import './index.css'

function Mengk() {
  return (
    <div className="Mengk" >
      <MengkHeader />
      <div className='Mengk-body' >
        <h3>【品牌旗舰店】</h3>
        <div>在亚马逊上免费使用多级页面的品牌旗舰店推广您的品牌和商品。*</div>
        <p>*只有经过注册的品牌才有资格。在亚马逊品牌注册中注册您的品牌</p>
        <div className="Mengk-store" ><Button>创建 Store<RightOutlined /></Button></div>
        <img src={require("@assets/images/Mengk-body.png").default} ></img>
        <Row style={{ textAlign: "left" }} >
          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }} style={{ textAlign: "left" }} >
            <div>讲述您的品牌故事并展示您的商品</div>
            <div className="Mengk-text" >帮助买家在亚马逊上发现您的商品组合和相关商品</div>
          </Col>
          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }}>
            <div>无需编码即可设计您的品牌旗舰店</div>
            <div className="Mengk-text">使用可拖放的模块或预先设计好的模板即可创建自定义的多级页面品牌旗舰店,而无需编写任何代码</div>
          </Col>
          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
            <div>借助广告提升网站流量</div>
            <div className="Mengk-text">您可以通过亚马逊站内广告，结合在亚马逊之外的营销活动利用独特易读的短URL网址来吸引消费者光临您的品牌旗舰店。</div>
          </Col>
        </Row>
        <div>特色品牌旗舰店</div>
        <p>了解其他品牌已创建的内容</p>
        {/* <div style={{ width: 815, margin: "auto" }} >
          <Carousel className="Mengk-carousel" autoplay >
            <div>
              <div className="Mengk-carousel-item1" ></div>
            </div>
            <div>
              <div className="Mengk-carousel-item2" ></div>
            </div>
            <div>
              <div className="Mengk-carousel-item3" ></div>
            </div>
            <div>
              <div className="Mengk-carousel-item4" ></div>
            </div>
          </Carousel>
        </div> */}

      </div>
      <Modal title="" className="Mengk-modal" visible={true} footer={false} width={820} style={{ top: 160 }} >
        <h3>为您的品牌创建和自定义店铺。</h3>
        <p style={{ fontSize: 16, fontWeight: 700 }} >您的品牌</p>
        <p>是否想为此处未列出的品牌建立店铺?<Link to="BrandRegistered" >注册您的品牌</Link></p>
        <div className="Mengk-modal-but" >
          <span style={{ fontSize: 16, fontWeight: 700 }} >Mengk</span>
          <Button><Link to="MengkGenerator" >编辑品牌旗舰店</Link></Button>
        </div>
      </Modal>
    </div>
  )
}

export default Mengk