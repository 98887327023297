import React, { useRef, useEffect, useCallback, useState } from "react";
import cookieUtils from "utils/cookie";
import "./App.css";
import AJAX from "api/apis";
import { connect } from "react-redux";
import BasicRoute from "router";
import getUrlData from "@/utils/getUrlData";
import { IntlProvider } from "react-intl";
import lang from "locales/index";
import { useHistory } from "react-router-dom";
import { historyMemo } from "@utils/memory";
import request from "@api/request";


function App({ language }) {
  let [isSynchronization, setIsSynchronization] = useState(false)
  useEffect(() => {
    getcookies();
  }, []);
  let getcookies = useCallback(() => {
    console.log("先执行getcookies")
    let isImplement = getUrlData('isImplement') || localStorage.getItem('isImplement')
    let token = getUrlData('amazon_token')  //token，注册专用
    let processId = getUrlData('process_id')  //实操id
    let practiceStoreId = getUrlData('practiceStoreId') ? getUrlData('practiceStoreId') : '';  //课程id
    let authorize_key = getUrlData('authorize_key');  //验证登录token
    let source = getUrlData('source') ? getUrlData('source') : '';  //来源0是鲸练，1是实训，2是课程
    let sourceCookie = cookieUtils.getCookie('source');  //本地缓存来源
    let processIdList = ['1', '33', '9', '13'] //判断课程id,注册课程id不走
    let practiceStoreIdCookie = cookieUtils.getCookie('practiceStoreId'); //本地缓存课程id
    if (getUrlData('process_id')) cookieUtils.setCookie('process_id', getUrlData('process_id'));
    if (getUrlData('process_name')) cookieUtils.setCookie("process_name", getUrlData('process_name'));
    if (getUrlData('amazon_url')) cookieUtils.setCookie('amazon_url', getUrlData('amazon_url'));
    // console.log(token);
    localStorage.setItem('stepList', JSON.stringify([]))
    if ((source == sourceCookie && cookieUtils.getCookie('store_amazon_token') == token) || source == "") {  //如果来源等于undefined，说明是内部路由，禁止下一步
      setIsSynchronization(true)

      if (isImplement != 1 && !processIdList.includes(processId)) getUserInfo()
      return;
    } else {  //判断来源是否相同 0是鲸练，1是实训，2是课程
      // cookieUtils.setCookie("token", "", -1)
      console.log(source, 'source');
      cookieUtils.setCookie('source', source)
      cookieUtils.setCookie("amazon_token", "", -1);

      if (source != 0 && processId != 33) {
        // cookieUtils.setCookie("amazon_token", "", -1);
        loginToken(authorize_key)
        cookieUtils.setCookie('practiceStoreId', practiceStoreId)
      } else {
        if (isImplement != 1 && !processIdList.includes(processId)) getUserInfo()
        cookieUtils.setCookie('practiceStoreId', '');
        setIsSynchronization(true)
      }
    }
    if (token) cookieUtils.setCookie('store_amazon_token', token)
  }, [])

  // 验证token,成功返回新token，存入cookies中
  let loginToken = useCallback((token) => {
    AJAX.login.loginToken({ token }).then((res) => {
      if (res.code == 1) {
        // cookieUtils.setCookie('amazon_token', res.data)
        setIsSynchronization(true)
      }
    })
  }, [])

  // 验证是否登录
  let getUserInfo = useCallback(() => {
    console.log("先执行getUserInfo")
    localStorage.setItem('isImplement', 1)
    AJAX.login.getUserInfo().then((res) => { })
  }, [])
  // 接受父页面发来的信息
  window.addEventListener("message", function (event) {
    var data = event.data;
    switch (data.cmd) {
      case 'getFormJson':
        // 处理业务逻辑
        let amanon_token = cookieUtils.getCookie('amazon_token')
        window.parent.postMessage({
          cmd: 'returnHeight',
          params: {
            success: true,
            data: amanon_token
          }
        }, '*');
        break;
    }
  });

  return isSynchronization ? <IntlProvider locale={language} messages={lang[language]}>
    <div className="purposeTipsBox">
      <img src={require('@/assets/img/public/purposeTipsImg.png').default} />
      <span>以下亚马逊模拟实操只为提供学习用途，请勿使用真实资料操作（模拟功能不会和真实亚马逊平台任何资料产生关联）</span>
    </div>
    <BasicRoute />
  </IntlProvider> : <div></div>
}

const mapStateToProps = ({ language }) => {
  return { language };
};

export default connect(mapStateToProps)(App);
