import { useCallback, useEffect, useState } from "react";
import useMemory from "@hooks/useMemory";

import equal from "fast-deep-equal";

const useSearchGoods = ({ fetcher, list }) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchList, setSearchList] = useState([]);

  const [isSearching, setSearchingStatus] = useState(false);
  const [isLoading, setLoadingStatus] = useState(false);

  const handleSearch = useCallback(() => {
    if (!searchValue) return;

    if (fetcher) {
      setLoadingStatus(true);
      fetcher({ searchValue })
        .then((res) => {
          if (res.code === 1) {
            setSearchingStatus(true);
            setSearchList(res.data);
          }
        })
        .finally(() => setLoadingStatus(false));
    } else {
      setSearchingStatus(true);
      setSearchList(
        list.filter((item) => {
          return (
            item.goodsName.includes(searchValue) ||
            item.goodsAsin.includes(searchValue)
          );
        })
      );
    }
    setSearchValue("");
  }, [fetcher, list, searchValue]);

  const clearSearch = useCallback(() => {
    setSearchingStatus(false);
    setSearchList(list || []);
  }, [list]);

  const removeGoodsItem = useCallback((goodsId) => {
    setSearchList((list) => {
      return list.filter((v) => v.goodsId !== goodsId);
    });
  }, []);

  const isRemote = !!fetcher;

  useEffect(() => {
    if (!isRemote) {
      setSearchList((originList) => {
        if (!equal(list, originList)) {
          return list;
        }
        return originList;
      });
    }
  }, [list, isRemote]);

  return {
    searchValue,
    setSearchValue,
    isSearching,
    searchResult: searchList,
    isLoading,
    handleSearch,
    clearSearch,
    removeGoodsItem,
  };
};

export default useSearchGoods;
