import React from 'react'
import './Labeling.css'
import AJAX from "api/apis";
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import '../default.css/default.css'
import { Link } from 'react-router-dom';
import { Button, Collapse, Steps, Radio, Input, Modal } from 'antd';
import { fbaAddress, getUrlData } from "@/utils/utils";

const { Panel } = Collapse;
const { Step } = Steps;
class Labeling extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            goodsList: {
                amazonStoragePlanGoodsInfoList: []
            },
            planId: null,
            addressDetails: '',
            classContent: '',
            classType: 1,
            classIndex: null,
            isModalVisible: false,
            ModalText: '',
            isReturn: false,
            stepControl: false,
            delTipsModal: false
        }
    }
    componentWillMount() {
        let planId = getUrlData('planId');
        this.getStoragePlanInfo(planId)
        this.setState({
            planId: planId
        })
        this.getStoreRegisterInfo()
    }
    getStoragePlanInfo = (planId) => {
        let { goodsList } = this.state
        AJAX.fba.getStoragePlanInfo({ id: planId }).then((res) => {
            if (res.code == 1 && res.data) {
                goodsList = res.data
                goodsList.labelSumTotal = 0
                for (let items of goodsList.amazonStoragePlanGoodsInfoList) {
                    items.isDel = false
                    items.labelSum = 1;
                    items.labelPrice = 0;
                    items.goodsLabelSumTotal = Number(items.labelSum * items.labelPrice);
                    goodsList.labelSumTotal += items.labelSum

                }
                this.setState({
                    goodsList: goodsList,
                    stepControl: goodsList.storagePlanStep == 3 ? false : true,
                })
            }
        })
    }
    getStoreRegisterInfo = () => {
        AJAX.login.getStoreRegisterInfo([{
            propertyKey: 'paytaxes_organization_site'
        }], 'isRegister=0').then((res) => {
            if (res.code == 1 && res.data) {
                this.setState({
                    addressDetails: res.data[0].propertyValue
                })
            }
        })
    }
    inputChange = (e, index) => {
        let { goodsList } = this.state;
        let sum = 0
        goodsList.amazonStoragePlanGoodsInfoList[index].labelSum = e.target.value;
        for (let items of goodsList.amazonStoragePlanGoodsInfoList) {
            sum += Number(items.labelSum)
            if (items.storagePlanTagAction == 1) {
                items.labelPrice = 0.3
            } else {
                items.labelPrice = 0
            }
            items.goodsLabelSumTotal = Number(items.labelSum * items.labelPrice);
        }
        goodsList.labelSumTotal = sum;
        this.setState({
            goodsList: goodsList
        })
    }
    pretreatmentPartyChange = e => {
        let { goodsList } = this.state
        for (let items of goodsList.amazonStoragePlanGoodsInfoList) {
            items.storagePlanTagAction = e.target.value
            if (items.storagePlanTagAction == 1) {
                items.labelPrice = 0.3;
                goodsList.labelSumTotal = 0;
            } else {
                items.labelPrice = 0
            }
            items.goodsLabelSumTotal = Number(items.labelSum * items.labelPrice);
        }
        this.setState({
            goodsList: goodsList
        })
    }
    nextEvent = () => {
        let { goodsList } = this.state
        sessionStorage.setItem('goodsList', JSON.stringify(goodsList))
        this.systemSeparate()
    }
    systemSeparate = () => {
        let { goodsList, addressDetails } = this.state
        goodsList.totalPreparationCost = 0;

        let obj = {
            id: goodsList.id,
            storagePlanAddRess: addressDetails,
            storagePlanPackType: goodsList.storagePlanPackType,
            upStoragePlanGoodsTagInfoReqList: []
        }
        for (let items of goodsList.amazonStoragePlanGoodsInfoList) {
            obj.upStoragePlanGoodsTagInfoReqList.push({
                id: items.id,
                storagePlanTagAction: items.storagePlanTagAction ? Number(items.storagePlanTagAction) : 0,
                storagePlanTagMoney: items.labelPrice,
            })
        }

        AJAX.fba.upStoragePlanGoodsTag(obj).then((res) => {
            if (res.code == 1 && res.data) {
                // let planInfoList = res.data
                // sessionStorage.setItem('planInfoList', JSON.stringify(planInfoList))
                // this.props.history.push('/viewShipment')
                this.props.history.push({ pathname: '/viewShipment', state: { planId: res.data }, search: `?planId=${res.data}` })
            }
        })
    }
    // 操作是否删除该商品
    goodsOperation = (index, content) => {
        let { goodsList } = this.state;
        goodsList.storageAllPlanPackMoney = 0;
        goodsList.amazonStoragePlanGoodsInfoList[index].isDel = content;
        for (let items of goodsList.amazonStoragePlanGoodsInfoList) {
            if (items.storagePlanPackAction == '1' && items.packPerformer == true && !items.isDel) {
                items.preparationCost = 1.5
            } else if (items.storagePlanPackAction == '1' && items.isDel) {
                items.preparationCost = 0
            }
            items.storagePlanPackMoney = Number(items.preparationCost * items.storagePlanGoodsCount)
            goodsList.storageAllPlanPackMoney += items.storagePlanPackMoney
        }
        this.setState({
            goodsList: goodsList
        })
    }
    deleteSchedule = () => {
        this.setState({
            delTipsModal: true
        })
    }
    tipshandleOk = () => {
        let { goodsList } = this.state
        AJAX.fba.deleteStoragePlan({ id: goodsList.id }).then((res) => {
            if (res.code == 1) {
                this.props.history.push('/inboundQueue')

            }
        })
    }

    render() {
        let { addressDetails, goodsList, stepControl, delTipsModal } = this.state
        return <div className="LabelingBox">
            <Header />
            <div>


                <div className="kc_10_box2">
                    <ul>
                        <li><a href="#">Send to Amazon </a><span>新功能</span></li>
                        <li><a href="#">货件处理进度</a></li>
                        <li><a href="#">入库问题一览</a></li>
                        <li><a href="#">扫描和贴标</a></li>
                        <li className="qx"><a href="#">上传入库计划文件</a></li>
                    </ul>
                </div>
                <div id="kc_10_box3">
                    <p className="kc_10_h1">
                        发/补货
                        <span>了解更多信息</span>
                    </p>
                    <div className="kc_10_body-border">
                        <ul className="kc_10_body-ul">
                            <li>设置数量</li>
                            <li>预处理商品</li>
                            <li>为商品贴标 <img src={require("@/assets/img/home/kc12_topsj.png").default} /></li>
                            <li>查看货件</li>
                            <li>预处理货件</li>
                            <li>一览 </li>
                        </ul>
                    </div>
                    <div className="kc_10_body-table">
                        <table className="kc_10_table1">
                            <thead>
                                <tr>
                                    <th>发货地址</th>
                                    <th>目标商城</th>
                                    <th>包装类型 <span style={{ color: '0070B1' }}>这是什么?</span> </th>
                                    <th>主题</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{fbaAddress(addressDetails)}</td>
                                    <td>{goodsList.storagePlanTargetStore}</td>
                                    <td>{goodsList.storagePlanPackType == 1 ? '混装商品' : '原厂包装发货商品'}</td>
                                    <td>{goodsList.storagePlanMskuCount} MSKU</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>


                    <div className="body-tab">
                        <ul className="tab-ul">
                            <li className="kc_10_active">需要贴标</li>
                            <li>所有商品</li>
                            <p className="kc_10_tab-p"> <span className="kc_10_tab_p_span" >卖家大学视频</span></p>
                        </ul>
                    </div>
                    <div className="kc_10_quan">
                        <p style={{ margin: '20px 0' }}>所有商品都需要具有可扫猫条形码◎在"商品名称菜单中，选择"显示 ASIMFNSKU"并遵循以下贴标指南，以避免产生额外费用;</p>

                        <ul className="KC_10_BOO">
                            <li>对于以"BOO"开头的FISKAJ，诗使用现有的由洁商杂形妈(UPOCEAVS95V。仅当杂形码不可见、不可扫瞰或采用气地照也滁时。才精粘N4红马逊惺供的E0~"标签。如果使用亚马逊标签，谪确保制惜商氛形粹不可见。</li>
                            <li>对于以"BOO"开头的FNSKU，请粘贴亚马逊提供的的"XOO"标签。为此，您需要在谢贴标方下拉菜单中选择"卖家﹐然后在此页面上点击打印标签。</li>
                        </ul>

                        <p style={{ marginTop: "20px" }}>或者，您也可以在贴标方菜单中逸择亚马逊′让亚马逊为您的商品贴标，但愈需要支付一定的羡用。对于该选项，请确保有可扫描的LPCEANMSBN标整，并且请勿在箱子上粕沾任何其他标签。</p>
                    </div>
                    <div className="mybox">
                        <table className="kc06_table1">
                            <thead>
                                <tr>
                                    <th>卖家SKU</th>
                                    <th>商品名称</th>
                                    <th>状况</th>
                                    <th>商品数量</th>
                                    <th>贴标方</th>
                                    <th>要打印的标签数量</th>
                                    <th>标签成本</th>
                                    <th>删除</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td><input type="checkbox" />显示 ASINFNSKU</td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <select disabled={stepControl} onChange={this.pretreatmentPartyChange}>
                                            <option value="--">适用于全部</option>
                                            <option value="1">亚马逊</option>
                                            <option value="0">卖家</option>
                                        </select>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                {goodsList.amazonStoragePlanGoodsInfoList ? goodsList.amazonStoragePlanGoodsInfoList.map((item, index) => {
                                    return <tr className={item.isDel ? 'isDel' : null} key={index}>
                                        <td>{item.goodsSku}</td>
                                        <td><div className="text-ellipsis-3">{item.goodsName}</div></td>
                                        <td>{item.goodsCondition}</td>
                                        <td>{item.storagePlanGoodsCount}</td>
                                        <td>{item.storagePlanTagAction == true ? '亚马逊' : <>卖家<p>为什么此商品需要贴标？<span style={{ color: '#0070B1' }}>了解 <br /> 更多信息</span></p></>}</td>
                                        <td>{item.storagePlanTagAction == true || item.isDel ? null : <input disabled={stepControl} value={item.labelSum} type="number" min="1" onChange={(e) => { this.inputChange(e, index) }} />}</td>
                                        <td>{item.storagePlanTagAction == true ? <><div>${Number(item.storagePlanGoodsCount) * 0.3}</div><div>每件商品0.3$</div></> : '--'}</td>
                                        <td>{item.isDel ? <span style={{ cursor: 'pointer' }} onClick={() => { this.goodsOperation(index, false) }}>撤销</span> : <img onClick={() => { this.goodsOperation(index, true) }} src={require("@/assets/img/public/del.png").default} />}</td>
                                    </tr>
                                }) : null}
                            </tbody>
                        </table>
                    </div>
                    <div className="kc_10_US">
                        <select disabled={goodsList.labelSumTotal > 0 ? false : true} className="kc_10_select111" name="">
                            <option value="">每张US信纸最多30个标签（1英寸x 2-5/8英寸）</option>
                        </select>
                        <button disabled={goodsList.labelSumTotal > 0 ? false : true} style={{ position: 'absolute', top: '7px' }} className="body-box2_button2 ">打印次页面上的标签</button>
                        <p style={{ position: 'absolute', display: 'inline-block', top: '30px', marginLeft: '10px', fontSize: '14px' }}>标签总数:{goodsList.labelSumTotal}</p>
                    </div>
                    <div >
                        <p className="kc_10_show">显示 1 到 {goodsList.amazonStoragePlanGoodsInfoList.length || 0} 件商品。</p>
                    </div>

                    <div className="body-box2">
                        <div className="btn-l">
                            <button>
                                <Link to={{ pathname: '/Pretreatment', state: { planId: this.state.planId }, search: `?planId=${this.state.planId}` }}>返回<img className="kc_10_btn2" src={require("@/assets/img/home/btns.png").default} /></Link>
                            </button>
                            <button onClick={this.deleteSchedule}>删除计划</button>
                            <button>复制计划</button>
                        </div>
                        <div className="btn-r">
                            <button onClick={this.nextEvent}>
                                继续<img className="kc_10_btn2" src={require("@/assets/img/home/btns.png").default} />
                            </button>
                        </div>
                    </div>
                </div>
                <Modal title="提示" visible={delTipsModal} onOk={this.tipshandleOk} style={{ width: '50%' }} cancelText="取消" okText="确认" onCancel={() => { this.setState({ delTipsModal: false }) }} >
                    <p>是否确定？删除计划操作无法撤销</p>
                </Modal>
            </div>
            <Footer />
        </div>
    }
}


export default Labeling