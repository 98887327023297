import React, { useState } from 'react';
import "./shopupUL.css";
import IconFont from "../../../../components/Icon/Icon";
import { Button, Modal, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import Reactours from "components/reactours";
import TipsIcon from "components/tipsIcon";
import container from './container';

class ShopupUL extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalVisible: false,
      isActive: false,
      isTourOpen: true,
      steps1: [
        {
          selector: '.ant-tabs-tab-active',
          content: '点击配送模板',
        },
        {
          selector: '.addTemplate button',
          content: '点击创建新配送模板',
        },
      ],
      routePath: "distributionSet_shopupUL"
    }
  }
  setIsModalVisible = () => {
    this.setState({
      isModalVisible: true,
      steps1: [
        {
          selector: '.addTemplate button',
          content: '点击创建新配送模板',
        },
        {
          selector: '.shopupUL_modal p',
          content: '点击创建新配送模板',
        },
        {
          selector: '.shopupUL_modal .ant-btn-primary',
          content: '点击确定',
        },
      ],
    })
    let stepList = JSON.parse(localStorage.getItem('stepList'))
    setTimeout(() => {
      this.setState({
        isActive: stepList.distributionSet_ShopupUL1 ? false : true,
        routePath: "distributionSet_ShopupUL1"
      })
      if (!stepList['distributionSet_ShopupUL1']) {
        stepList["distributionSet_ShopupUL1"] = true
        localStorage.setItem('stepList', JSON.stringify(stepList));
      }
    }, 500)
  }
  handleOk = () => {
    this.setState({
      isModalVisible: false.title,
      steps1: [
        {
          selector: '.ant-tabs-tab-active',
          content: '点击配送模板',
        },
        {
          selector: '.addTemplate button',
          content: '点击创建新配送模板',
        },
      ],
    })
    this.props.history.push("/addTemplate")
    // window.location.href + "/addTemplate"
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
      steps1: [
        {
          selector: '.ant-tabs-tab-active',
          content: '点击配送模板',
        },
        {
          selector: '.addTemplate button',
          content: '点击创建新配送模板',
        },
      ],
    })
  };
  awakenTips = (type) => {
    this.setState({ isActive: type })
  }
  render() {
    const { isModalVisible, isActive, steps1, isTourOpen, routePath } = this.state
    return (
      <div className="distributionTemplate" style={{ paddingBottom: "100px" }}>
        <div className="addTemplate">
          <Button onClick={this.setIsModalVisible} >
            创建新配送模板
          </Button>
          <span className="cc0"> | 将SKU分配给模板</span>
        </div>
        <div className="flex0">
          <div className="shopupDL-rbox">

            {/* 常见问题 */}
            <div className="shopupDL-bor">
              <div className="shopupDL-boxPD">
                <div className="shopupDL-boxHed">
                  <span>美国模板-8美金</span>
                  <span className="detaultBox">默认</span>
                </div>
              </div>
            </div>
          </div>
          <div className="shopupDL-lbox">
            <div
              className="shopupDL-warnBox"
              style={{ border: "1px solid #3479ac", padding: "10px 0" }}
            >
              <div className="shopupDL-rBox-warnHed">
                <IconFont
                  type="icon-jinggao1"
                  className="RepertoryTable1-box1-font"
                  style={{ color: "#3489ac", margin: "0 20px" }}
                />
                <div>希望使用您的目录文件添加商品？</div>
              </div>
              <div className="shopupDL-rBox-warnnav">
                <div >
                  使用<a style={{ color: " #0066c0" }}>准备您的商品</a>工具，通过上传任意Excel、文本或CSV
                  文件来批量添加商品。您也可以上传在自己的电子商务网站
                  或其他商城中用来销售商品的文件。
                </div>
              </div>
            </div>
            <div className="shopupDL-boxHed">
              <div className="fz24">美国模板-8美金<span className="fz13">默认模板</span></div>
              <div>
                <select>
                  <option>编辑模板</option>
                </select>
              </div>
            </div>
            <div className="rightBox">
              <div className="rightTitle">
                <p className="fz20">国内配送</p>
                <p className="col296">标准配送</p>
              </div>
              <div className="a-section-expander-container">
                <p className="leftTitle">默认配送地址</p>
              </div>
              <div className="tableBox">
                <table border="1" cellspacing="0" cellpadding="0" bordercolor="#EEEEEE">
                  <tr>
                    <th>地区</th>
                    <th>地址类型</th>
                    <th>运输时间</th>
                    <th>运输费用</th>
                  </tr>
                  <tr className="coupons1">
                    <td>缅因州, 佛蒙特州, 新罕布什尔州, 马萨诸塞州, 康涅狄格州, 罗德岛州, 纽约州, 宾夕法尼亚州, 哥伦比亚特区, 新泽西州, 北达科他州, 南达科塔州, 内布拉斯加州, 堪萨斯州, 明尼苏达州, 爱荷华州, 威斯康辛州, 伊利诺伊州, 密苏里州, 印第安纳州, 俄亥俄州, 密歇根州, 俄克拉荷马州, 德克萨斯州, 西佛吉尼亚州, 弗吉尼亚州, 特拉华州, 肯塔基州, 田纳西州, 北卡罗来纳州, 南卡罗来纳州, 乔治亚州, 阿拉巴马州, 密西西比州, 佛罗里达州, 路易斯安那州, 阿肯色州, 马里兰州, 亚利桑那州, 蒙大拿州, 爱达荷州, 怀俄明州, 科罗拉多州, 犹他州, 内华达州, 俄勒冈州, 华盛顿州, 新墨西哥州</td>
                    <td>
                      <p>街道邮</p>
                      <p>邮政信箱</p>
                    </td>
                    <td>5-8天</td>
                    <td>
                      <p>$0.00 每订单 + </p>
                      <p>$8.00 每商品</p>
                    </td>
                  </tr>
                  <tr className="coupons1">
                    <td>缅因州, 佛蒙特州, 新罕布什尔州, 马萨诸塞州, 康涅狄格州, 罗德岛州, 纽约州, 宾夕法尼亚州, 哥伦比亚特区, 新泽西州, 北达科他州, 南达科塔州, 内布拉斯加州, 堪萨斯州, 明尼苏达州, 爱荷华州, 威斯康辛州, 伊利诺伊州, 密苏里州, 印第安纳州, 俄亥俄州, 密歇根州, 俄克拉荷马州, 德克萨斯州, 西佛吉尼亚州, 弗吉尼亚州, 特拉华州, 肯塔基州, 田纳西州, 北卡罗来纳州, 南卡罗来纳州, 乔治亚州, 阿拉巴马州, 密西西比州, 佛罗里达州, 路易斯安那州, 阿肯色州, 马里兰州, 亚利桑那州, 蒙大拿州, 爱达荷州, 怀俄明州, 科罗拉多州, 犹他州, 内华达州, 俄勒冈州, 华盛顿州, 新墨西哥州</td>
                    <td>
                      <p>街道邮</p>
                      <p>邮政信箱</p>
                    </td>
                    <td>5-8天</td>
                    <td>
                      <p>$0.00 每订单 + </p>
                      <p>$8.00 每商品</p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Modal title="创建新模板"
          className="shopupUL_modal"
          visible={isModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          cancelText="取消"
          okText="确定"
        >
          <p>创建新配送模板</p>
          <strong>不，我想要复制以下模板的内容</strong>
          <Select defaultValue="1" className="select-after" style={{ width: 424 }} >
            <Select.Option value="1" >美国版本-8美金</Select.Option>
          </Select>
        </Modal>
        <TipsIcon zIndex='9999' clicks={this.awakenTips} />
        <Reactours routePath={routePath} isActive={isActive} clicks={this.awakenTips} steps={steps1} isOpen={isTourOpen} />
      </div>
    )
  }
}
export default container(ShopupUL)
