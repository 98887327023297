import Apis from "@api/apis";
import { ASwitch, AButton, AText } from "@a-components";
import { Link } from "react-router-dom";

const columns = [
  {
    title: "有效",
    key: "advertisingStatus",
    dataIndex: "advertisingStatus",
    fixed: "left",
    render: (advertisingStatus, { advertisingId, event$ }) => {
      const updateAdvertisingStatus = (checked) => {
        return Apis.advertising
          .updateAdvertisingStatus({
            advertisingIds: advertisingId,
            advertisingStatus: checked ? 1 : 2,
          })
          .then((res) => {
            if (res.code === 1) event$.emit({ type: "reFetch" });
          });
      };
      return (
        <ASwitch
          disabled={advertisingStatus == 3 ? true : false}
          checked={advertisingStatus === 1}
          onChange={updateAdvertisingStatus}
        />
      );
    },
  },
  {
    title: "广告活动",
    dataIndex: "advertisingName",
    key: "1",
    render(value, record) {
      const queryString = new URLSearchParams({
        advertisingId: record.advertisingId,
      }).toString();
      return (
        <Link
          to={`/Advertisement/advertisingDetail?${queryString}`}
          title={value}
          className="text-ellipsis-1"
        >
          {value}
        </Link>
      );
    },
  },
  {
    title: "状态",
    dataIndex: "advertisingStatusName",
    key: "2",
  },
  {
    title: "类型",
    dataIndex: "advertisingTypeName",
    key: "3",
    render(value, record) {
      return (
        <>
          <AText className="fs-12">{value}</AText> <br />
          <AText className="fs-12 c-999">{record.positioningName}</AText>
        </>
      );
    },
  },
  {
    title: "开始日期",
    dataIndex: "startTime",
    key: "4",
  },
  {
    title: "结束日期",
    dataIndex: "endTime",
    key: "5",
  },
  {
    title: "预算",
    dataIndex: "dailyBudgetPrice",
    key: "6",
  },
  // {
  //   title: "花费",
  //   dataIndex: "dailyBudgetPrice",
  //   key: "dailyBudgetPrice",
  // },
  // {
  //   title: "点击次数",
  //   dataIndex: "clicksNum",
  //   key: "7",
  // },
  // {
  //   title: "点击率(CTR)",
  //   dataIndex: "clicksRate",
  //   key: "8",
  // },
  {
    title: "花费",
    dataIndex: "costPrice",
    key: "9",
  },
  // {
  //   title: "每次点击费用(CPC)",
  //   dataIndex: "singleCostPrice",
  //   key: "10",
  // },
  {
    title: "订单",
    dataIndex: "orderNumber",
    key: "11",
  },
  {
    title: "销售额",
    dataIndex: "salesPrice",
    key: "12",
  },
  {
    title: "ROAS",
    dataIndex: "roasRate",
    key: "13",
  },
  {
    title: "复制",
    key: "operation",
    render: () => (
      <AButton type="link" className="p-0">
        复制
      </AButton>
    ),
  },
];
const filterConditionOptions = [
  {
    label: "筛选条件",
    value: "筛选条件",
  },
  {
    label: "活跃状态",
    value: "活跃状态",
  },
  {
    label: "类型",
    value: "类型",
  },
  {
    label: "投放",
    value: "投放",
  },
  {
    label: "预算",
    value: "预算",
  },
  {
    label: "曝光量",
    value: "曝光量",
  },
  {
    label: "点击次数",
    value: "点击次数",
  },
  {
    label: "点击率（CTR）",
    value: "点击率（CTR）",
  },
  {
    label: "花费",
    value: "花费",
  },
  {
    label: "每次点击费用(CPC)",
    value: "每次点击费用(CPC)",
  },
  {
    label: "订单",
    value: "订单",
  },
  {
    label: "销售额",
    value: "销售额",
  },
  {
    label: "广告投入产出比(ACOS)",
    value: "广告投入产出比(ACOS)",
  },
  {
    label: "广告支出回报(ROAS)",
    value: "广告支出回报(ROAS)",
  },
  {
    label: "新买家订单数",
    value: "新买家订单数",
  },
  {
    label: "新买家订单比",
    value: "新买家订单比",
  },
  {
    label: "新买家销量",
    value: "新买家销量",
  },
  {
    label: "新买家销量比",
    value: "新买家销量比",
  },
  {
    label: "几乎或超出预算",
    value: "几乎或超出预算",
  },
  {
    label: "广告组合",
    value: "广告组合",
  },
];
const campaignConfig = {
  createBtn: (_, history) => {
    return {
      children: "创建广告活动",
      onClick() {
        history.push("/NewAdvertisingCampaign");
      },
    };
  },
  searchInput: {
    placeholder: "查找广告活动",
  },
  
  tableColumns: columns,
  tableDataKey: "advertisingId",
  filterConditionOptions,
  queryFetcher({ searchValue, businessId, ...otherParams }) {
    return Apis.advertising.getAdvertisingList({
      ...otherParams,
      advertisingCombinationId: businessId || "",
      advertisingName: searchValue,
    });
  },
};

export default campaignConfig;
