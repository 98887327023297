import React, { useRef, useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from "components/home_header";
import Footer from "components/addlisting_footer/FooterCom";
import AJAX from "api/apis";
import { useHistory } from "react-router";
import "./DepositMethod.less"
import { Button, Col, Row, Modal, Radio, Space } from 'antd';
import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"

function DepositMethod() {
  const [shopData, setShopData] = useState([]);
  const [shopType, setShopType] = useState(0);
  const [shopTiData, setShopTiData] = useState([]);
  const [storeAreaIdTi, setStoreAreaIdTi] = useState('');
  const [list, setList] = useState([]);
  const [isModalVisible, setisModalVisible] = useState(false)
  const history = useHistory();
  let [addressM, setAddressM] = useState(null)
  const steps = [
    {
      selector: '.stepGuidanceBox0',
      content: '选择Amazon.com点击分配',
    },
  ];
  useEffect(() => {
    getShoppingOption()
    getDistributionShoppingList()
  }, [])
  const getShoppingOption = useCallback(() => {
    AJAX.store.getShoppingOption().then((res) => {
      if (res.code == 1) {
        setShopData(res.data)
      }
    })
  }, [])
  const getDistributionShoppingList = useCallback(() => {
    AJAX.store.getDistributionShoppingList().then((res) => {
      if (res.code == 1) {
        setList(res.data)
      }
    })
  }, [])
  const cancelModal = () => {
    setisModalVisible(false)
    setAddressM(null)
    setShopType(0)
  }
  const okModel = useCallback(() => {
    if (shopType == 0) {
      let ele = shopData.find(v => v.address == addressM)
      linkSet(addressM, ele.storeAreaId, ele.storeDepositRelId, '2')
      cancelModal()
    } else {
      let ele = shopTiData.find(v => v.storeDepositRelId == addressM)
      linkSet(ele.address, ele.storeAreaId, ele.storeDepositRelId, '3')
      cancelModal()
    }

  }, [addressM])
  const linkSet = (addressM, storeAreaId, storeDepositRelId = '', type = '1') => {
    history.push({
      pathname: '/accountinfo/setDepositMethod',
      search: `?addressM=${addressM}&storeAreaId=${storeAreaId}&storeDepositRelId=${storeDepositRelId}&type=${type}`,
      state: { addressM, storeAreaId, storeDepositRelId, type }
    })
  }
  const tihuan = (item) => {
    if (item.storeShoppingDepositRelVoList.length == 1) {
      linkSet(item.storeShoppingDepositRelVoList[0].address, item.storeShoppingDepositRelVoList[0].storeAreaId, item.storeShoppingDepositRelVoList[0].storeDepositRelId, '3')
    } else {
      setShopType(1)
      setShopTiData(item.storeShoppingDepositRelVoList)
      setisModalVisible(true)
      // setStoreAreaIdTi(item.)
      // linkSet(item.address, item.storeAreaId, item.storeDepositRelId, '3')
    }
  }
  const [isActive, setIsActive] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(true);
  const awakenTips = (type) => {
    setIsActive(type);
  }

  return (
    <React.Fragment>
      <Header />
      <TipsIcon clicks={awakenTips} />
      <Reactours routePath="deposit_Method" isActive={isActive} clicks={awakenTips} steps={steps} isOpen={isTourOpen} />
      <div className='deposit_Method'>
        <h2>
          <span>存款方式</span>
          <Link to="/accountinfo" >账户信息</Link>
        </h2>
        <div className='deposit_Method_set' >
          <a onClick={() => {
            setisModalVisible(true)
          }} >添加新的付款方式</a>
          <Link to="/accountinfo/manageDepositMethod" >管理存款方式</Link>
        </div>
        <div className='distribution'>
          <Row>
            <Col span={16} className='left'>
              <p>分配存款方法</p>
              <div>您必须为这些商城指定一个银行账户，以便在正常付款期结束后接收付款。</div>
            </Col>
            <Col span={8}>
              <div className="deposit_Method_body_right right" >
                <p>未分配的商城</p>
                {
                  shopData.map(item => {
                    if (item.depositMethodNum == 0) {
                      return (
                        <div key={item.address} className="stepGuidanceBox0" >
                          <img src={require(`@assets/images/${item.address}.png`).default} />
                          <span>{item.address}</span>
                          <span className='button' onClick={() => { linkSet(item.address, item.storeAreaId) }}>分配</span>
                        </div>
                      )
                    }
                  })
                }
              </div>
            </Col>
          </Row>
        </div>
        <div>
          {
            list.map(item => {
              if (item.defaultFlag) {
                return (
                  <div className="deposit_Method_body" key={item.depositBankNumber}>
                    <div className="deposit_Method_body_left" >
                      <div>银行账号</div>
                      <div>结束 <span style={{ fontSize: 18 }} >{item.depositBankNumber.substring(item.depositBankNumber.length - 3)}</span></div>
                      <Button onClick={() => { tihuan(item) }}>替换默认存储方式</Button>
                    </div>
                    <div className="deposit_Method_body_center" >
                      <p className="deposit_Method_body_title" >银行所在国家/地区</p>
                      <div>{item.depositState}</div>
                      <p>账户持有人姓名</p>
                      <div>{item.depositPersonName}</div>
                    </div>
                    <div className="deposit_Method_body_right" >
                      <p>分配的商城</p>
                      {
                        item.storeShoppingDepositRelVoList.length ? item.storeShoppingDepositRelVoList.map(v => {
                          return (
                            <div key={v.address}>
                              <img src={require(`@assets/images/${v.address}.png`).default} />
                              <span>{v.address}</span>
                              {/* <span className='button'>分配</span> */}
                            </div>
                          )
                        }) : null
                      }
                    </div>
                  </div>
                )
              } else {
                return null
              }

            })
          }

        </div>
        <Modal
          title="添加新的存款方式"
          visible={isModalVisible}
          onCancel={cancelModal}
          style={{ top: '36%' }}
          width="500px"
          className='ymx-modal deposit_modal'
          footer={false}
        >
          <Radio.Group style={{ width: '100%' }} value={addressM} onChange={(e) => {
            setAddressM(e.target.value)
          }}>
            {
              shopType == 0 && shopData.map(item => {
                if (item.depositMethodNum != 2) {
                  return (
                    <div key={item.address}>
                      <Radio value={item.address}>{item.address}</Radio>
                    </div>
                  )
                } else {
                  return null
                }

              })
            }
            {
              shopType == 1 && shopTiData.map(item => {
                return (
                  <div key={item.address}>
                    <Radio value={item.storeDepositRelId}>{item.address}</Radio>
                  </div>
                )
              })
            }
          </Radio.Group>
          <div className='btn'>
            <Button onClick={cancelModal}>取消</Button>
            <Button onClick={okModel} disabled={!addressM}>{shopType == 0 ? '添加' : '替换'}</Button>
          </div>
          <p>如果该商城具有现有的银行账户，则该账户将被替换为新银行账户。</p>
        </Modal>
      </div>

      <Footer />
    </React.Fragment >
  )
}

export default DepositMethod