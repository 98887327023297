import React, { useState, useCallback, useEffect } from "react";
import AJAX from "api/apis";
import "./Commodity-collect.css"
import { List } from 'antd';
import IconFont from "../../../../components/Icon/Icon";
import { Link } from "react-router-dom";
let breadcrumbList = []
class CommodityCollect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                list: []
            }
        }
    }
    componentWillMount() {
        this.getCollectGoodsClassify()
    }
    componentWillReceiveProps(newProps) {  //监听参数变化
        if (newProps.isgit) {
            this.getCollectGoodsClassify()
        }
    }
    getCollectGoodsClassify = () => {
        AJAX.addProduct.getCollectGoodsClassify().then((res) => {
            if (res.code == 1) {
                if (res.data) {
                    this.setState({
                        data: {
                            ...this.state.data,
                            list: res.data
                        }
                    })
                } else {
                    this.setState({
                        data: {
                            ...this.state.data,
                            list: []
                        }
                    })
                }
            }
        })
    }
    removeCollectGoodsClassify = (classifyId, index) => {  //取消分类收藏
        let { list } = this.state.data
        AJAX.addProduct.removeCollectGoodsClassify({ classifyId }).then((res) => {
            if (res.code == 1) {
                this.props.getquxiao()
                list.splice(index, 1)
                this.setState({
                    data: {
                        ...this.state.data,
                        list: list
                    }
                })
            }
        })
    }
    ChildrenClick = (item) => {  //判断某分类下是否有子分类
        if (item.classifyIfAddBranch) {
            // 没有子分类
            this.props.props.history.push({
                pathname: '/createListing',
                search: `?id=${item.id}`,
                state: {
                    id: item.id
                }
            })
        } else {
            // 有子分类，获取子分类数据
            breadcrumbList.push({
                id: item.id,
                name: item.classifyName
            })
            this.getGoodsClassify(item.id)
        }
    }
    getGoodsClassify = (classifyParentId = "") => {
        AJAX.addProduct.getGoodsClassify({ classifyParentId }).then((res) => {
            if (res.code == 1) {
                this.setState({
                    data: {
                        ...this.state.data,
                        list: res.data
                    }
                })
            }
        })
    }
    render() {
        let { data } = this.state
        return <div className="commodity-container CollectionBox">
            <h2 className="commodity-h2">选择商品类别</h2>
            <p className="commodity-hint">选择最佳类别，这样可以确保您看到的是最合适您商品的数据输入项。直接浏览类别或使用搜索。<span>看看您的商品是否已经存在于亚马逊上。</span></p>
            <p className="commodity-collect">收藏的商品数量({data?.list?.length})</p>
            <div className="collectionList">
                {data.list.length > 0 ? <List size="large" dataSource={data.list}
                    renderItem={(item, index) =>
                        <List.Item key={index}>
                            {item.isCollect ?
                                <IconFont type="icon-kongwujiaoxing" className="commodity-icon" onClick={() => { this.addCollectGoodsClassify(item.id, index) }} />
                                :
                                <IconFont type="icon-xingzhuang60kaobei2" className="commodity-icon" onClick={() => { this.removeCollectGoodsClassify(item.id, index) }} />}
                            <div onClick={() => { this.ChildrenClick(item) }} style={{ display: 'contents' }}>
                                <List.Item.Meta
                                    title={item.classifyName}
                                    description={item.classifyParentNameBunch}
                                />
                                {item.classifyIfAddBranch == false ? <div>
                                    <IconFont type="icon-fanhui-copy-copy" className="commodity-icon" />
                                </div> : <div className="commodity-select">
                                    <button>选择类别</button>
                                </div>}
                            </div>
                        </List.Item>
                    }
                /> : <div className="noCollectionClass">您尚未添加任何收藏分类</div>}
            </div>
        </div>
    }
}

export default CommodityCollect