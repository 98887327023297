// 获取时间，便于注册等页面组件使用


// 获取年
export const getYear = () => {
    let yearList = [];
    let date = new Date();
    let currentYear = date.getFullYear(); //获取完整的年份(4位)
    // 循环年
    for (let i = 1970; i < currentYear; i++) {
        yearList.push(
            { label: `${i}年`, value: `${i}年` },
        )
    }
    return yearList;

}
// 获取月
export const getMonth = () => {
    let monthList = [];
    for (let m = 1; m <= 12; m++) {
        monthList.push(
            { label: `${m}月`, value: `${m}月` },
        )
    }
    return monthList;
}
// 获取日
export const getDay = () => {
    let dayList = [];
    for (let d = 1; d <= 31; d++) {
        dayList.push(
            { label: `${d}日`, value: `${d}日` },
        )
    }
    return dayList

}

// 获取有效期年
export const getYearValidity = () => {
    let yearValidityList = []
    let date = new Date();
    let currentYear = date.getFullYear(); //获取完整的年份(4位)
    for (let v = currentYear; v <= 2100; v++) {
        yearValidityList.push(
            { label: `${v}年`, value: `${v}年` },
        )
    }
    return yearValidityList;

}
// 国家
export const getSellerNationality = () => {
    return [
        { label: '中国', value: '中国' },
        { label: '中国台湾', value: '中国台湾' },
        { label: '中国香港', value: '中国香港' },
        { label: '丹麦', value: '丹麦' },
        { label: '乌克兰', value: '乌克兰' },
        { label: '乌干达', value: '乌干达' },
        { label: '乍得', value: '乍得' },
        { label: '亚美尼亚', value: '亚美尼亚' },
        { label: '以色列', value: '以色列' },
        { label: '俄罗斯', value: '俄罗斯' },
        { label: '保加利亚', value: '保加利亚' },
        { label: '克罗地亚', value: '克罗地亚' },
        { label: '冰岛', value: '冰岛' },
        { label: '几内亚', value: '几内亚' },
        { label: '列支敦士登', value: '列支敦士登' },
        { label: '加拿大', value: '加拿大' },
        { label: '加蓬', value: '加蓬' },
        { label: '南非', value: '南非' },
        { label: '博茨瓦纳', value: '博茨瓦纳' },
        { label: '卡塔尔', value: '卡塔尔' },
        { label: '卢森堡', value: '卢森堡' },
        { label: '印度', value: '印度' },
        { label: '印度尼西亚', value: '印度尼西亚' },
        { label: '哥伦比亚', value: '哥伦比亚' },
        { label: '哥斯达黎加', value: '哥斯达黎加' },
        { label: '喀麦隆', value: '喀麦隆' },
        { label: '土耳其', value: '土耳其' },
        { label: '埃及', value: '埃及' },
        { label: '塞内加尔', value: '塞内加尔' },
        { label: '塞尔维亚', value: '塞尔维亚' },
        { label: '塞浦路斯', value: '塞浦路斯' },
        { label: '墨西哥', value: '墨西哥' },
        { label: '多哥', value: '多哥' },
        { label: '多米尼加共和国', value: '多米尼加共和国' },
        { label: '奥地利', value: '奥地利' },
        { label: '孟加拉国', value: '孟加拉国' },
        { label: '安哥拉', value: '安哥拉' },
        { label: '尼日尔', value: '尼日尔' },
        { label: '尼泊尔', value: '尼泊尔' },
        { label: '巴拉圭', value: '巴拉圭' },
        { label: '巴拿马', value: '巴拿马' },
        { label: '巴西', value: '巴西' },
        { label: '布基纳法索', value: '布基纳法索' },
        { label: '希腊', value: '希腊' },
        { label: '德国', value: '德国' },
        { label: '意大利', value: '意大利' },
        { label: '拉脱维亚', value: '拉脱维亚' },
        { label: '挪威', value: '挪威' },
        { label: '捷克共和国', value: '捷克共和国' },
        { label: '摩洛哥', value: '摩洛哥' },
        { label: '文莱', value: '文莱' },
        { label: '斯洛伐克', value: '斯洛伐克' },
        { label: '斯洛文尼亚', value: '斯洛文尼亚' },
        { label: '斯里兰卡', value: '斯里兰卡' },
        { label: '新加坡', value: '新加坡' },
        { label: '新西兰', value: '新西兰' },
        { label: '日本', value: '日本' },
        { label: '智利', value: '智利' },
        { label: '柬埔寨', value: '柬埔寨' },
        { label: '格鲁吉亚', value: '格鲁吉亚' },
        { label: '比利时', value: '比利时' },
        { label: '毛里求斯', value: '毛里求斯' },
        { label: '法国', value: '法国' },
        { label: '波兰', value: '波兰' },
        { label: '泰国', value: '泰国' },
        { label: '洪都拉斯', value: '洪都拉斯' },
        { label: '海地', value: '海地' },
        { label: '澳大利亚', value: '澳大利亚' },
        { label: '爱尔兰', value: '爱尔兰' },
        { label: '爱沙尼亚', value: '爱沙尼亚' },
        { label: '特立尼亚和多巴哥', value: '特立尼亚和多巴哥' },
        { label: '玻利维亚', value: '玻利维亚' },
        { label: '瑞典', value: '瑞典' },
        { label: '瑞士', value: '瑞士' },
        { label: '白俄罗斯', value: '白俄罗斯' },
        { label: '科威特', value: '科威特' },
        { label: '科特迪瓦', value: '科特迪瓦' },
        { label: '秘鲁', value: '秘鲁' },
        { label: '立陶宛', value: '立陶宛' },
        { label: '约旦', value: '约旦' },
        { label: '纳米比亚', value: '纳米比亚' },
        { label: '罗马尼亚', value: '罗马尼亚' },
        { label: '美国', value: '美国' },
        { label: '肯尼亚', value: '肯尼亚' },
        { label: '芬兰', value: '芬兰' },
        { label: '英国', value: '英国' },
        { label: '荷兰', value: '荷兰' },
        { label: '莫桑比克', value: '莫桑比克' },
        { label: '菲律宾', value: '菲律宾' },
        { label: '萨尔瓦多', value: '萨尔瓦多' },
        { label: '葡萄牙', value: '葡萄牙' },
        { label: '西班牙', value: '西班牙' },
        { label: '贝宁', value: '贝宁' },
        { label: '赤道几内亚', value: '赤道几内亚' },
        { label: '越南', value: '越南' },
        { label: '阿塞拜疆', value: '阿塞拜疆' },
        { label: '阿尔及利亚', value: '阿尔及利亚' },
        { label: '阿尔巴尼亚', value: '阿尔巴尼亚' },
        { label: '阿拉伯联合酋长国', value: '阿拉伯联合酋长国' },
        { label: '阿曼', value: '阿曼' },
        { label: '阿根廷', value: '阿根廷' },
        { label: '韩国', value: '韩国' },
        { label: '马其顿', value: '马其顿' },
        { label: '马耳他', value: '马耳他' },
        { label: '马来西亚', value: '马来西亚' },
        { label: '马达加斯加', value: '马达加斯加' },
        { label: '马里', value: '马里' }
    ];
}
export const getCheckAddressList = () => {
    return [
        { value: '中国,+86', label: '中国' },
        { value: '中国台湾,+852', label: '中国台湾' },
        { value: '中国香港,+886', label: '中国香港' },
        { value: '丹麦,+45', label: '丹麦' },
        { value: '乌克兰,+380', label: '乌克兰' },
        { value: '乌干达,+256', label: '乌干达' },
        { value: '乍得,+235', label: '乍得' },
        { value: '亚美尼亚,+374', label: '亚美尼亚' },
        { value: '以色列,+972', label: '以色列' }
    ];
}
export const getCheckAddressList2 = () => {
    return [
        { value: 'CN +86', label: 'CN +86' },
        { value: 'DK +45', label: 'DK +45' },
        { value: 'UA +380', label: 'UA +380' },
        { value: 'UG +256', label: 'UG +256' },
        { value: 'TD +235', label: 'TD +235' },
        { value: 'AM +374', label: 'AM +374' },
        { value: 'IL +972', label: 'IL +972' },
        { value: 'US +1', label: 'US +1' }
    ];
}
export const jiguoLityList = () => {
    let list = ["Agriculture Bank of China",
        "Allied Irish Bank",
        "American Express",
        "BBVA",
        "Banca Monte dei Paschi di  Siena",
        "Banco BPM",
        "Banco Santander",
        "Banco de Sabadell",
        "Bank of America",
        "Bank of China",
        "Bank of Communications",
        "Bank of Ireland",
        "Bank of Scotland",
        "Bankia",
        "Banque Populaire",
        "Barclaycard",
        "Barclays",
        "Barclays Business",
        "Caisse d'Epargne",
        "CaixaBank",
        "Central Bancorp",
        "China Citic Bank Corp",
        "China Construction Bank",
        "China Development Bank",
        "China Merchant Bank",
        "Citi",
        "Commerzbank",
        "Crédit Agricole",
        "Crédit Mutuel",
        "Danske",
        "First Direct",
        "HSBC",
        "HSBC Business",
        "Halifax",
        "Hello bank!",
        "Hometown Bancorp - Personal",
        "Hyperwallet",
        "HypoVereinsbank (UniCredit  Bank AG)",
        "Industrial Bank Co Ltd",
        "Industrial and Commercial Bank of China (ICBC)",
        "Intesa Sanpaolo",
        "JPMorgan Chase",
        "Lianlian Pay",
        "Lloyds",
        "Lloyds Business",
        "M&S Bank",
        "MBNA",
        "Mabanque",
        "Mabanque Privée",
        "Mabanque Pro",
        "Marcus by Goldman Sachs",
        "Monzo",
        "Morgan Stanley Client Serv",
        "NatWest",
        "Nationwide",
        "Payoneer",
        "Permanent TSB",
        "PingPong",
        "Postal Savings Bank of China (PSBC)",
        "Revolut",
        "Royal Bank of Scotland",
        "Santander",
        "Société Générale",
        "Starling",
        "TSB",
        "The Bancorp Employee Bank",
        "UBI Banca",
        "Ulster Bank",
        "Ulster Bank Northern Ireland",
        "UniCredit",
        "Wells Fargo",
        "WorldFirst",
        "Others"]
    let list2 = []
    list2 = list.map(item => {
        return { value: item, label: item }
    })
    return list2;
}
export const getIdentityList = () => {
    return [
        { label: '身份证', value: '身份证' },
        { label: '护照', value: '护照' },
    ];
}
export const timeDifference = () => {
    return [
        { label: '(UTC-11:00) 太平洋/中途岛', value: '(UTC-11:00) 太平洋/中途岛' },
        { label: '(UTC-11:00) 太平洋/帕果 - 帕果', value: '(UTC-11:00) 太平洋/帕果 - 帕果' },
        { label: '(UTC-10:00) 太平洋/檀香山', value: '(UTC-10:00) 太平洋/檀香山' },
        { label: '(UTC-10:00) 美国/夏威夷', value: '(UTC-10:00) 美国/夏威夷' },
        { label: '(UTC-9:00) 美洲/艾德克', value: '(UTC-9:00) 美洲/艾德克' },
        { label: '(UTC-8:00) 美洲/安克雷奇', value: '(UTC-8:00) 美洲/安克雷奇' },
        { label: '(UTC-8:00) 美国/阿拉斯加', value: '(UTC-8:00) 美国/阿拉斯加' },
        { label: '(UTC-7:00) 美洲/洛杉矶', value: '(UTC-7:00) 美洲/洛杉矶' },
        { label: '(UTC-7:00) 美洲/菲尼克斯', value: '(UTC-7:00) 美洲/菲尼克斯' },
        { label: '(UTC-7:00) 美洲/提华纳', value: '(UTC-7:00) 美洲/提华纳' },
        { label: '(UTC-7:00) 加拿大/太平洋', value: '(UTC-7:00) 加拿大/太平洋' },
        { label: '(UTC-7:00) 美国/亚利桑那', value: '(UTC-7:00) 美国/亚利桑那' },
        { label: '(UTC-7:00) 美国/太平洋', value: '(UTC-7:00) 美国/太平洋' },
        { label: '(UTC-6:00) 美洲/博伊西', value: '(UTC-6:00) 美洲/博伊西' },
        { label: '(UTC-6:00) 美洲/奇瓦瓦', value: '(UTC-6:00) 美洲/奇瓦瓦' },
        { label: '(UTC-6:00) 美洲/丹佛', value: '(UTC-6:00) 美洲/丹佛' },
        { label: '(UTC-6:00) 美洲/危地马拉', value: '(UTC-6:00) 美洲/危地马拉' },
        { label: '(UTC-6:00) 加拿大/山区', value: '(UTC-6:00) 加拿大/山区' },
        { label: '(UTC-6:00) 加拿大/萨斯喀彻温', value: '(UTC-6:00) 加拿大/萨斯喀彻温' },
        { label: '(UTC-6:00) 美国/山脉', value: '(UTC-6:00) 美国/山脉' },
        { label: '(UTC-5:00) 美洲/波哥大', value: '(UTC-5:00) 美洲/波哥大' },
        { label: '(UTC-5:00) 美洲/芝加哥', value: '(UTC-5:00) 美洲/芝加哥' },
        { label: '(UTC-5:00) 美洲/墨西哥城', value: '(UTC-5:00) 美洲/墨西哥城' },
        { label: '(UTC-5:00) 加拿大/中部', value: '(UTC-5:00) 加拿大/中部' },
        { label: '(UTC-4:00) 美洲/加拉加斯', value: '(UTC-4:00) 美洲/加拉加斯' },
        { label: '(UTC-4:00) 美洲/玛瑙斯', value: '(UTC-4:00) 美洲/玛瑙斯' },
        { label: '(UTC-4:00) 美洲/纽约', value: '(UTC-4:00) 美洲/纽约' },
        { label: '(UTC-4:00) 美洲/圣地亚哥', value: '(UTC-4:00) 美洲/圣地亚哥' },
        { label: '(UTC-4:00) 美洲/圣托马斯', value: '(UTC-4:00) 美洲/圣托马斯' },
        { label: '(UTC-4:00) 加拿大/东部', value: '(UTC-4:00) 加拿大/东部' },
        { label: '(UTC-4:00) 美国/东印第安娜', value: '(UTC-4:00) 美国/东印第安娜' },
        { label: '(UTC-4:00) 美国/东部', value: '(UTC-4:00) 美国/东部' },
        { label: '(UTC-3:00) 美洲/布宜诺斯艾利斯', value: '(UTC-3:00) 美洲/布宜诺斯艾利斯' },
        { label: '(UTC-3:00) 美洲/蒙得维的亚', value: '(UTC-3:00) 美洲/蒙得维的亚' },
        { label: '(UTC-3:00) 巴西/东部', value: '(UTC-3:00) 巴西/东部' },
        { label: '(UTC-3:00) 加拿大/大西洋', value: '(UTC-3:00) 加拿大/大西洋' },
        { label: '(UTC-2:30) 加拿大/纽芬兰', value: '(UTC-2:30) 加拿大/纽芬兰' },
        { label: '(UTC-2:00) 美洲/努克', value: '(UTC-2:00) 美洲/努克' },
        { label: '(UTC-2:00) 大西洋/南乔治亚岛', value: '(UTC-2:00) 大西洋/南乔治亚岛' },
        { label: '(UTC-1:00) 大西洋/佛得角', value: '(UTC-1:00) 大西洋/佛得角' },
        { label: '(UTC+0:00) 大西洋/亚速尔群岛', value: '(UTC+0:00) 大西洋/亚速尔群岛' },
        { label: '(UTC+1:00) 非洲/阿尔及尔', value: '(UTC+1:00) 非洲/阿尔及尔' },
        { label: '(UTC+1:00) 非洲/卡萨布兰卡', value: '(UTC+1:00) 非洲/卡萨布兰卡' },
        { label: '(UTC+1:00) 欧洲/伦敦', value: '(UTC+1:00) 欧洲/伦敦' },
        { label: '(UTC+2:00) 非洲/开罗', value: '(UTC+2:00) 非洲/开罗' },
        { label: '(UTC+2:00) 非洲/哈拉雷', value: '(UTC+2:00) 非洲/哈拉雷' },
        { label: '(UTC+2:00) 非洲/温特和克', value: '(UTC+2:00) 非洲/温特和克' },
        { label: '(UTC+2:00) 欧洲/阿姆斯特丹', value: '(UTC+2:00) 欧洲/阿姆斯特丹' },
        { label: '(UTC+2:00) 欧洲/贝尔格莱德', value: '(UTC+2:00) 欧洲/贝尔格莱德' },
        { label: '(UTC+2:00) 欧洲/柏林', value: '(UTC+2:00) 欧洲/柏林' },
        { label: '(UTC+2:00) 欧洲/布鲁塞尔', value: '(UTC+2:00) 欧洲/布鲁塞尔' },
        { label: '(UTC+2:00) 欧洲/马德里', value: '(UTC+2:00) 欧洲/马德里' },
        { label: '(UTC+2:00) 欧洲/巴黎', value: '(UTC+2:00) 欧洲/巴黎' },
        { label: '(UTC+2:00) 欧洲/罗马', value: '(UTC+2:00) 欧洲/罗马' },
        { label: '(UTC+2:00) 欧洲/斯德哥尔摩', value: '(UTC+2:00) 欧洲/斯德哥尔摩' },
        { label: '(UTC+2:00) 欧洲/华沙', value: '(UTC+2:00) 欧洲/华沙' },
        { label: '(UTC+3:00) 非洲/内罗比', value: '(UTC+3:00) 非洲/内罗比' },
        { label: '(UTC+3:00) 亚洲/安曼', value: '(UTC+3:00) 亚洲/安曼' },
        { label: '(UTC+3:00) 亚洲/巴格达', value: '(UTC+3:00) 亚洲/巴格达' },
        { label: '(UTC+3:00) 亚洲/贝鲁特', value: '(UTC+3:00) 亚洲/贝鲁特' },
        { label: '(UTC+3:00) 亚洲/耶路撒冷', value: '(UTC+3:00) 亚洲/耶路撒冷' },
        { label: '(UTC+3:00) 亚洲/科威特', value: '(UTC+3:00) 亚洲/科威特' },
        { label: '(UTC+3:00) 亚洲/利雅得', value: '(UTC+3:00) 亚洲/利雅得' },
        { label: '(UTC+3:00) 欧洲/雅典', value: '(UTC+3:00) 欧洲/雅典' },
        { label: '(UTC+3:00) 欧洲/赫尔辛基', value: '(UTC+3:00) 欧洲/赫尔辛基' },
        { label: '(UTC+3:00) 欧洲/明斯克', value: '(UTC+3:00) 欧洲/明斯克' },
        { label: '(UTC+3:00) 欧洲/莫斯科', value: '(UTC+3:00) 欧洲/莫斯科' },
        { label: '(UTC+3:00) 欧洲/伊斯坦布尔', value: '(UTC+3:00) 欧洲/伊斯坦布尔' },
        { label: '(UTC+3:00) 土耳其', value: '(UTC+3:00) 土耳其' },
        { label: '(UTC+4:00) 亚洲/巴库', value: '(UTC+4:00) 亚洲/巴库' },
        { label: '(UTC+4:00) 亚洲/迪拜', value: '(UTC+4:00) 亚洲/迪拜' },
        { label: '(UTC+4:00) 亚洲/马斯喀特', value: '(UTC+4:00) 亚洲/马斯喀特' },
        { label: '(UTC+4:00) 亚洲/第比利斯', value: '(UTC+4:00) 亚洲/第比利斯' },
        { label: '(UTC+4:00) 亚洲/耶烈万', value: '(UTC+4:00) 亚洲/耶烈万' },
        { label: '(UTC+4:30) 亚洲/喀布尔', value: '(UTC+4:30) 亚洲/喀布尔' },
        { label: '(UTC+4:30) 亚洲/德黑兰', value: '(UTC+4:30) 亚洲/德黑兰' },
        { label: '(UTC+5:00) 亚洲/卡拉奇', value: '(UTC+5:00) 亚洲/卡拉奇' },
        { label: '(UTC+5:00) 亚洲/叶卡捷琳堡', value: '(UTC+5:00) 亚洲/叶卡捷琳堡' },
        { label: '(UTC+5:30) 亚洲/加尔各答', value: '(UTC+5:30) 亚洲/加尔各答' },
        { label: '(UTC+5:30) 亚洲/科伦坡', value: '(UTC+5:30) 亚洲/科伦坡' },
        { label: '(UTC+5:30) 亚洲/加尔各答', value: '(UTC+5:30) 亚洲/加尔各答' },
        { label: '(UTC+5:45) 亚洲/加德满都', value: '(UTC+5:45) 亚洲/加德满都' },
        { label: '(UTC+6:00) 亚洲/达卡', value: '(UTC+6:00) 亚洲/达卡' },
        { label: '(UTC+6:30) 亚洲/仰光', value: '(UTC+6:30) 亚洲/仰光' },
        { label: '(UTC+7:00) 亚洲/曼谷', value: '(UTC+7:00) 亚洲/曼谷' },
        { label: '(UTC+7:00) 亚洲/克拉斯诺亚尔斯克', value: '(UTC+7:00) 亚洲/克拉斯诺亚尔斯克' },
        { label: '(UTC+7:00) 亚洲/新西伯利亚', value: '(UTC+7:00) 亚洲/新西伯利亚' },
        { label: '(UTC+8:00) 亚洲/伊尔库茨克', value: '(UTC+8:00) 亚洲/伊尔库茨克' },
        { label: '(UTC+8:00) 亚洲/吉隆坡', value: '(UTC+8:00) 亚洲/吉隆坡' },
        { label: '(UTC+8:00) 亚洲/上海', value: '(UTC+8:00) 亚洲/上海' },
        { label: '(UTC+8:00) 亚洲/台北', value: '(UTC+8:00) 亚洲/台北' },
        { label: '(UTC+8:00) 澳大利亚/珀斯', value: '(UTC+8:00) 澳大利亚/珀斯' },
        { label: '(UTC+9:00) 亚洲/首尔', value: '(UTC+9:00) 亚洲/首尔' },
        { label: '(UTC+9:00) 亚洲/东京', value: '(UTC+9:00) 亚洲/东京' },
        { label: '(UTC+9:00) 亚洲/雅库茨克', value: '(UTC+9:00) 亚洲/雅库茨克' },
        { label: '(UTC+9:00) 太平洋/帕劳', value: '(UTC+9:00) 太平洋/帕劳' },
        { label: '(UTC+9:30) 澳大利亚/阿德莱德', value: '(UTC+9:30) 澳大利亚/阿德莱德' },
        { label: '(UTC+9:30) 澳大利亚/达尔文', value: '(UTC+9:30) 澳大利亚/达尔文' },
        { label: '(UTC+10:00) 亚洲/符拉迪沃斯托克', value: '(UTC+10:00) 亚洲/符拉迪沃斯托克' },
        { label: '(UTC+10:00) 澳大利亚/布里斯班', value: '(UTC+10:00) 澳大利亚/布里斯班' },
        { label: '(UTC+10:00) 澳大利亚/霍巴特', value: '(UTC+10:00) 澳大利亚/霍巴特' },
        { label: '(UTC+10:00) 澳大利亚/墨尔本', value: '(UTC+10:00) 澳大利亚/墨尔本' },
        { label: '(UTC+10:00) 澳大利亚/悉尼', value: '(UTC+10:00) 澳大利亚/悉尼' },
        { label: '(UTC+10:00) 太平洋/关岛', value: '(UTC+10:00) 太平洋/关岛' },
        { label: '(UTC+10:00) 太平洋/塞班', value: '(UTC+10:00) 太平洋/塞班' },
        { label: '(UTC+11:00) 亚洲/马加丹', value: '(UTC+11:00) 亚洲/马加丹' },
        { label: '(UTC+11:00) 太平洋/科斯瑞', value: '(UTC+11:00) 太平洋/科斯瑞' },
        { label: '(UTC+12:00) 太平洋/奥克兰', value: '(UTC+12:00) 太平洋/奥克兰' },
        { label: '(UTC+12:00) 太平洋/斐济', value: '(UTC+12:00) 太平洋/斐济' },
        { label: '(UTC+13:00) 太平洋/汤加塔布岛', value: '(UTC+13:00) 太平洋/汤加塔布岛' }
    ];
}
export const benefit = () => {
    return [
        {name: '公司'},
        {name: '财产'},
        {name: '政府'},
        {name: '国际组织'},
        {name: '央行发行'},
        {name: '兔税组织'},
        {name: '私人基金会'},
        {name: '复合信托'},
        {name: '简单信托',s:'代表底层受益人接收付款的过渡信托'},
        {name: '托人信托',s:'代表底层受益人接收付款的过渡信托'},
        {name: '合伙企业',s:'代表合伙人接收付款的过渡实体'},
        {name: '被忽略的实体',s:'具有单个所有人且按照规章”第301.7701-2(b)条并非企业的业务实体'},
    ]
}