import React from "react";
import "./SetUp.css";
import { Input } from "antd";
import IconFont from 'components/Icon/Icon'
import Sidebar from '../sidebar/index'
function SetUp() {
  return (
    <div className="SetUp-body">
      <div><Sidebar/></div>
    <div className="SetUp-box">
      <div className="SetUp-BudgetCap">
        <span>商品推广预算上线 </span>
       <IconFont type="icon-jinggao5" />
      </div>
      <div className="SetUp-BudgetSelection">
        <div className="SetUp-BudgetSelection-content">
        <input
          type="radio"
          onChange={onChange}
          checked="checked"
          name="choice"
        />
       
          使用广告系列顶级预算
        </div>
        <div className="SetUp-BudgetSelection1">
          <input type="radio" name="choice" />
          
          使用每日预算上限
          &nbsp;&nbsp;
          <div className="SetUp-BudgetSelection2">
            <div>$</div>
          <Input/>
          </div>
          <div className="SetUp-BudgetSelection2_button">
          <button>保存</button>
          <button>取消</button>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
export default SetUp;
function onChange() {}
