import React from 'react'
import AJAX from "api/apis";
import './setQuantity.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"
import '../default.css/default.css'
import { Link } from 'react-router-dom';
import { Button, Collapse, Steps, Radio, Input, Modal, Pagination } from 'antd';
import { fbaAddress, getUrlData } from "@/utils/utils";
let planId = null;
class setQuantity extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            planId: getUrlData('planId'),
            goodsList: {
                amazonStoragePlanGoodsInfoList: []
            },
            chooseList: [],
            addressDetails: '',
            isDisabled: true,
            isModalVisible: false,
            ModalText: '',
            isReturn: false,
            isDeliveryStock: false,
            stepControl: false,  //步骤控制
            keyWord: '',
            page: 1,
            size: 10,
            total: 10,
            delTipsModal: false,
            isTourOpen: true,
            steps1: [
                {
                    selector: '.setQuantityBox .inputStep',
                    content: '输入每款商品发货数量等',
                },
                {
                    selector: '.setQuantityBox .btn-r .body-box2_button2',
                    content: '点击继续',
                }
            ],
            isActive: false
        }
    }
    componentWillMount() {
        let planId = getUrlData('planId')
        planId && this.getStoragePlanInfo(planId)
        this.getStoreRegisterInfo()
    }
    awakenTips = (type) => {
        this.setState({
            isActive: type
        })
    }
    getStoragePlanInfo = (planId) => {
        let { goodsList } = this.state
        AJAX.fba.getStoragePlanInfo({ id: planId }).then((res) => {
            if (res.code == 1 && res.data) {
                goodsList = res.data
                goodsList.goodsAllTotal0 = 0;
                goodsList.goodsAllTotal1 = 0;
                if (goodsList.amazonStoragePlanGoodsInfoList) {
                    for (let items of goodsList.amazonStoragePlanGoodsInfoList) {
                        items.goodsTotal = 0
                        items.isDel = false
                    }
                }

                this.setState({
                    goodsList: goodsList,
                    stepControl: goodsList.storagePlanStep == 1 ? false : true,
                    isDisabled: goodsList.storagePlanStep == 1 ? true : false,
                })
            }
        })
    }
    getStoragePlanInfoLast = (planId) => {
        let { goodsList } = this.state
        AJAX.fba.getStoragePlanInfo({ id: planId }).then((res) => {
            if (res.code == 1 && res.data) {
                if (res.data.amazonStoragePlanGoodsInfoList) {
                    for (let items of res.data.amazonStoragePlanGoodsInfoList) {
                        items.goodsTotal = 0
                        items.isDel = false
                    }
                }
                goodsList.amazonStoragePlanGoodsInfoList = res.data.amazonStoragePlanGoodsInfoList;
                this.setState({
                    goodsList: goodsList
                })
            }
        })
    }
    getStoreRegisterInfo = () => {
        AJAX.login.getStoreRegisterInfo([{
            propertyKey: 'paytaxes_organization_site'
        }], 'isRegister=0').then((res) => {
            if (res.code == 1 && res.data) {
                this.setState({
                    addressDetails: res.data[0].propertyValue
                })
            }
        })
    }
    getAllGoodsList(goodsDispatchingType = 0, goodsStatus = null, goodsNameGoodsSkuGoodsAsinGoodsIsbn = null, page = this.state.page, size = this.state.size) {
        let { goodsList } = this.state;
        AJAX.fba.getAmazonEntrepotGoodsList({ page, size, goodsDispatchingType, goodsStatus, goodsNameGoodsSkuGoodsAsinGoodsIsbn }).then((res) => {
            if (res.code == 1 && res.data) {
                let datas = res.data.list;
                for (let goodsItem of goodsList.amazonStoragePlanGoodsInfoList) {
                    for (let items of datas) {
                        if (goodsItem.storagePlanGoodsId == items.goodsId) {
                            items.isAdd = true;
                        }
                    }
                }
                this.setState({
                    chooseList: datas,
                    page: res.data.pageNum,
                    size: res.data.pageSize,
                    total: res.data.total
                })
            }
        })
    }
    typeSwitchClick = () => {
        let { goodsList } = this.state;
        goodsList.storagePlanPackType = goodsList.storagePlanPackType == 0 ? 1 : 0;
        this.setState({
            goodsList: goodsList
        })
    }
    sumChange = (e, index) => {
        let { goodsList, isDisabled } = this.state;
        let sum = 0;
        goodsList.amazonStoragePlanGoodsInfoList[index].storagePlanGoodsCount = e.target.value
        for (let item of goodsList.amazonStoragePlanGoodsInfoList) {
            sum += Number(item.storagePlanGoodsCount)
            if (item.storagePlanGoodsCount == 0 || item.storagePlanGoodsCount == null) {
                isDisabled = true
            } else {
                isDisabled = false
            }
        }
        goodsList.goodsAllTotal0 = sum
        this.setState({
            goodsList: goodsList,
            isDisabled: isDisabled
        })
    }
    storagePlanBoxGoodsCountChange = (e, index) => {
        let { goodsList, isDisabled } = this.state;
        let sum = 0;
        goodsList.amazonStoragePlanGoodsInfoList[index].storagePlanBoxGoodsCount = e.target.value
        goodsList.amazonStoragePlanGoodsInfoList[index].goodsTotal = Number(goodsList.amazonStoragePlanGoodsInfoList[index].storagePlanGoodsBoxCount * goodsList.amazonStoragePlanGoodsInfoList[index].storagePlanBoxGoodsCount)
        for (let item of goodsList.amazonStoragePlanGoodsInfoList) {
            sum += Number(item.storagePlanGoodsBoxCount * item.storagePlanBoxGoodsCount)
            if (item.goodsTotal == 0) {
                isDisabled = true
            } else {
                isDisabled = false
            }
        }
        goodsList.goodsAllTotal1 = sum
        this.setState({
            goodsList: goodsList,
            isDisabled: isDisabled,
        })
    }
    storagePlanGoodsBoxCountChange = (e, index) => {
        let { goodsList, isDisabled } = this.state;
        let sum = 0;
        goodsList.amazonStoragePlanGoodsInfoList[index].storagePlanGoodsBoxCount = e.target.value
        goodsList.amazonStoragePlanGoodsInfoList[index].goodsTotal = Number(goodsList.amazonStoragePlanGoodsInfoList[index].storagePlanGoodsBoxCount * goodsList.amazonStoragePlanGoodsInfoList[index].storagePlanBoxGoodsCount)
        for (let item of goodsList.amazonStoragePlanGoodsInfoList) {
            sum += Number(item.storagePlanGoodsBoxCount * item.storagePlanBoxGoodsCount)
            if (item.goodsTotal == 0) {
                isDisabled = true
            } else {
                isDisabled = false
            }
        }
        goodsList.goodsAllTotal1 = sum
        this.setState({
            goodsList: goodsList,
            isDisabled: isDisabled
        })
    }
    showModal = () => {
        this.getAllGoodsList(0, null, null)
        this.setState({
            isModalVisible: true
        })
    };

    handleOk = () => {
        this.setState({
            isModalVisible: false
        })
    };

    handleCancel = () => {
        this.setState({
            isModalVisible: false
        })
    };

    // 添加商品
    addGoods = (index, items) => {
        let { goodsList, chooseList } = this.state
        AJAX.fba.setStoragePlanGoodsInfo({ storagePlanId: goodsList.id, goodsId: items.goodsId }).then((res) => {
            if (res.code == 1) {
                chooseList[index].isAdd = true;
                let datas = res.data;
                datas.goodsTotal = 0
                datas.isDel = false
                goodsList.amazonStoragePlanGoodsInfoList.push(datas)
                this.setState({
                    isDisabled: true,
                    goodsList: goodsList
                })
            }
        })
    }
    goodsSearch = (e) => {
        this.setState({
            keyWord: e.target.value
        })
    }
    searchBtn = () => {
        this.getAllGoodsList(0, null, this.state.keyWord)
    }
    pageChonge = (page, size) => {
        this.setState({
            page: page,
            size: size
        })
        this.getAllGoodsList(0, null, this.props.keyWord, page, size)
    }
    ShowSizeChange = (page, size) => {
        this.setState({
            page: page,
            size: size
        })
        this.getAllGoodsList(0, null, this.props.keyWord, page, size)
    }

    // 操作是否删除该商品
    goodsOperation = (index, content) => {
        let { goodsList } = this.state;
        goodsList.goodsAllTotal1 = 0;
        goodsList.amazonStoragePlanGoodsInfoList[index].isDel = content;
        for (let items of goodsList.amazonStoragePlanGoodsInfoList) {
            if (goodsList.amazonStoragePlanGoodsInfoList[index].isDel == false) {
                goodsList.goodsAllTotal1 += items.goodsTotal
            } else {

            }
        }
        this.setState({
            goodsList: goodsList
        })
    }

    // 删除商品信息
    deleteStoragePlanGoodsCount = (storagePlanId, id) => {
        AJAX.fba.deleteStoragePlanGoodsCount({ storagePlanId, id }).then((res) => { })
    }

    // 进行下一步将数据传给服务器
    nextEvent = () => {
        let { goodsList, addressDetails } = this.state;
        let obj = {
            addStoragePlanGoodsInfoReqList: [],
            storagePlanAddRess: addressDetails,
            storagePlanId: goodsList.id,
            storagePlanPackType: goodsList.storagePlanPackType == true ? 1 : 0
        }
        for (let items of goodsList.amazonStoragePlanGoodsInfoList) {
            if (items.isDel == false) {
                obj.addStoragePlanGoodsInfoReqList.push({
                    storagePlanBoxGoodsCount: goodsList.storagePlanPackType == 0 ? null : items.storagePlanBoxGoodsCount,
                    storagePlanGoodsBoxCount: goodsList.storagePlanPackType == 0 ? null : items.storagePlanGoodsBoxCount,
                    storagePlanGoodsCount: goodsList.storagePlanPackType == 0 ? items.storagePlanGoodsCount : items.goodsTotal,
                    storagePlanGoodsId: items.storagePlanGoodsId,
                    id: items.id
                })
            } else {
                this.deleteStoragePlanGoodsCount(goodsList.id, items.id)
            }
        }
        AJAX.fba.upStoragePlanGoodsCount(obj).then((res) => {
            if (res.code == 1 && res.data) {
                this.props.history.push({ pathname: '/Pretreatment', state: { planId: res.data }, search: `?planId=${res.data}` })
            }
        })

    }
    deleteSchedule = () => {
        this.setState({
            delTipsModal: true
        })
    }
    tipshandleOk = () => {
        let { goodsList } = this.state
        AJAX.fba.deleteStoragePlan({ id: goodsList.id }).then((res) => {
            if (res.code == 1) {
                this.props.history.push('/inboundQueue')

            }
        })
    }
    render() {
        let { goodsList, addressDetails, total, isModalVisible, chooseList, isDisabled, page, stepControl, delTipsModal, steps1, isTourOpen, isActive } = this.state
        return <div className="setQuantityBox">
            <Header />
            <div>
                <div>
                    <div className="kc12_banner">
                        <ul>
                            <li><a href="#">Send to Amazon </a><span>新功能</span></li>
                            <li><a href="#">货件处理进度</a></li>
                            <li><a href="#">入库问题一览</a></li>
                            <li><a href="#">扫描和贴标</a></li>
                            <li className="qx"><a href="#">上传入库计划文件</a></li>
                        </ul>
                    </div>
                    <div className="kc12_content">
                        <h2>发/补货 <span>了解更多信息</span></h2>
                        <div className="body-border">
                            <ul className="body-ul">
                                <li>设置数量<img src={require("@/assets/img/home/kc12_topsj.png").default} /></li>
                                <li>预处理商品</li>
                                <li>为商品贴标</li>
                                <li>查看货件</li>
                            </ul>
                            <ul className="body-ul2">
                                <li>预处理货件</li>
                                <li>一览</li>
                            </ul>
                            <div className="body-div"></div>
                        </div>
                        <div className="body-table">
                            <table className="table1">
                                <thead>
                                    <tr>
                                        <th width="160">发货地址</th>
                                        <th>目标商城</th>
                                        <th width="70"></th>
                                        <th className="what" colSpan="1">包装类型<span>这是什么？</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{fbaAddress(addressDetails)}</td>
                                        <td>{goodsList.storagePlanTargetStore}</td>
                                        <td></td>
                                        <td>
                                            <p>{goodsList.storagePlanPackType == 0 ? '混装商品' : '原厂包装发货商品'}</p>
                                            <p className="typeSwitch" onClick={this.typeSwitchClick}>{goodsList.storagePlanPackType == 1 ? '更改为并箱装运' : '更改为单件商品'}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="body-tab">
                            <ul className="tab-ul">
                                <li className="active">所有商品</li>
                                <li>需要准备</li>
                                <li>可能需要准备</li>
                            </ul>
                            <ul className="tab-ul2">
                                <li className="mj">
                                    <button onClick={this.showModal} disabled={stepControl} className="body-box2_button2 ">添加商品</button>
                                </li>
                            </ul>
                            <p>请查看预处理指导（如果预处理指导未知，则选择商品类型），并且选择准备方。亚马逊可以提供<span>预处理服务</span>，但需要收费。</p>
                            <p className="xs">显示<span>1</span>到<span>{goodsList.amazonStoragePlanGoodsInfoList.length || 0}</span>件<span></span>商品。</p>
                        </div>
                        <div id="mybox">
                            <ul>
                                <li>卖家SKU</li>
                                <li>商品名称</li>
                                <li>状况</li>
                                <li>信息/所需操作</li>
                                {goodsList.storagePlanPackType == 0 ? <><li>商品数量</li>
                                    <li>准备成本</li></> : <><li>每个装运箱的商品数量</li>
                                    <li>装运箱数量</li><li>商品总数</li></>}
                                <li>删除</li>
                            </ul>
                            <ul>
                                <li></li>
                                <li><input type="checkbox" style={{ marginRight: '5px' }} />显示 ASIN/FNSKU</li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>

                            </ul>
                            {goodsList.amazonStoragePlanGoodsInfoList ? goodsList.amazonStoragePlanGoodsInfoList.map((item, index) => {
                                return <ul className={item.isDel ? 'isDel' : null} key={index}>
                                    <li>{item.goodsSku}</li>
                                    <li className="text-ellipsis-3">{item.goodsName}</li>
                                    <li>{item.goodsCondition}</li>
                                    <li>--</li>
                                    {!item.isDel ? goodsList.storagePlanPackType == 0 ?
                                        <>
                                            <li>
                                                <Input disabled={stepControl} className="inputStep" value={item.storagePlanGoodsCount} onChange={(e) => { this.sumChange(e, index) }} style={{ width: '50px', height: '30px' }} />
                                            </li>
                                            <li>--</li>
                                        </> : <>
                                            <li>
                                                <Input disabled={stepControl} value={item.storagePlanGoodsBoxCount} onChange={(e) => { this.storagePlanGoodsBoxCountChange(e, index) }} style={{ width: '50px', height: '30px' }} />
                                            </li>
                                            <li>
                                                <Input disabled={stepControl} className="inputStep" value={item.storagePlanBoxGoodsCount} onChange={(e) => { this.storagePlanBoxGoodsCountChange(e, index) }} style={{ width: '50px', height: '30px' }} />
                                            </li>
                                            <li>{item.goodsTotal}</li>
                                        </> : goodsList.storagePlanPackType == 0 ? <><li></li><li>--</li></> : <><li></li><li></li><li></li></>}
                                    <li>{item.isDel ? <span style={{ cursor: 'pointer' }} onClick={() => { this.goodsOperation(index, false) }}>撤销</span> : <img onClick={() => { this.goodsOperation(index, true) }} src={require("@/assets/img/public/del.png").default} />}</li>
                                </ul>
                            }) : null}
                            <ul>
                                <li>总计</li>
                                <li></li>
                                <li></li>
                                <li></li>
                                {goodsList.storagePlanPackType == 0 ? <><li>{goodsList.goodsAllTotal0}</li>
                                    <li>$0.00</li></> : <><li></li><li></li><li>{goodsList.goodsAllTotal1}</li></>}
                                <li></li>
                            </ul>
                        </div>
                        <div className="body-box2">
                            <div className="btn-l">
                                <button onClick={this.deleteSchedule}>删除计划</button>
                                <button>复制计划</button>
                            </div>
                            <div className="btn-r">
                                <Button className="body-box2_button2" onClick={this.nextEvent} disabled={isDisabled}>
                                    继续<img className="kc_10_btn2" src={require("@/assets/img/home/btns.png").default} />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal title="提示" visible={delTipsModal} onOk={this.tipshandleOk} style={{ width: '50%' }} cancelText="取消" okText="确认" onCancel={() => { this.setState({ delTipsModal: false }) }} >
                <p>是否确定？删除计划操作无法撤销</p>
            </Modal>
            <Modal title="添加商品" visible={isModalVisible} onOk={this.handleOk} className="goodsModalBox" style={{ width: '50%' }} onCancel={this.handleCancel} footer={null} >
                <div>
                    <div className="flex0">
                        <Input placeholder="输入商品名称，卖家SKU，ASIN或FNSKU" onChange={this.goodsSearch} />
                        <Button onClick={this.searchBtn}>搜索我的库存</Button>
                    </div>
                    <div>
                        <table>
                            <thead>
                                <tr>
                                    <th>卖家SKU</th>
                                    <th>商品名称</th>
                                    <th>信息</th>
                                    <th>可售数量</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {chooseList ? chooseList.map((items, index) => {
                                    return <tr key={index}>
                                        <td>{items.goodsSku}</td>
                                        <td><div className="text-ellipsis-3">{items.goodsName}</div></td>
                                        <td>--</td>
                                        <td>0</td>
                                        <td>
                                            {items.isAdd ? <div>已添加</div> : <div onClick={() => { this.addGoods(index, items) }} className="addGoods">添加商品</div>}
                                        </td>
                                    </tr>
                                }) : null}
                            </tbody>
                        </table>
                        <Pagination
                            current={page}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            className="pageBox"
                            onChange={this.pageChonge}
                            onShowSizeChange={this.ShowSizeChange}
                        />
                    </div>
                </div>
            </Modal>
            <Footer />
            {/* 引导步骤 */}
            <TipsIcon clicks={this.awakenTips} />
            <Reactours routePath="setQuantity" isActive={isActive} clicks={this.awakenTips} steps={steps1} isOpen={isTourOpen} />
        </div>
    }
}


export default setQuantity