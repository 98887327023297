import { useMemo, useState, useReducer, useCallback, useEffect } from "react";
import Header from "@components/home_header";
import Footer from "@components/addlisting_footer/FooterCom";

import { AText, AButton, ATabs } from "@a-components/";
import { Typography } from "antd";
import Apis from "@api/apis";
import useQuery from "@hooks/useQuery";
import GoodsItem from "./../CreateCoupon/GoodsSearchAdd/GoodsItem";

import style from "./style.module.css";
import classNames from "classnames";
import { useHistory } from "react-router";

const renderMoney = (value) => {
  return `$${(value || 0).toFixed(2)}`;
};

const CouponDetail = () => {
  const { couponsId } = useQuery();

  const [couponInfo, setCouponInfo] = useState({ goodsCouponsRespList: [] });

  const getCouponsDetail = useCallback(() => {
    return Apis.coupons
      .getCouponsDetail({
        couponsId,
      })
      .then((res) => {
        if (res.code !== 1) return;
        setCouponInfo(res.data);
      });
  }, [couponsId]);

  useEffect(() => {
    getCouponsDetail();
  }, [getCouponsDetail]);

  const {
    startTime,
    endTime,
    budgetPrice,
    expenditureAmount,
    collectionNum,
    exchangeNum,
    targetCustomersName,
    couponsStatusName,
    couponFullName,
    goodsCouponsRespList,
  } = couponInfo;

  const history = useHistory();

  const handleRerun = useCallback(() => {
    const queryString = new URLSearchParams({
      couponsId,
      type: "rerun",
    }).toString();
    history.push("/Coupons/CreateCoupon?" + queryString);
  }, [couponsId, history]);

  return (
    <div>
      <Header />
      <div className="p-24 ">
        <AText className="fs-24">{couponFullName}</AText>
        <br />

        <AButton className="mt-24 mb-24" onClick={handleRerun}>
          再次运行
        </AButton>

        <div style={{ maxWidth: 320 }}>
          <div className="fs-22 mb-24">优惠券详情</div>
          <div className={classNames(style.descList, "pb-24")}>
            <div className="fs-12">
              <AText>开始日期</AText>
              <AText>{startTime}</AText>
            </div>
            <div className="fs-12">
              <AText>结束日期</AText>
              <AText>{endTime}</AText>
            </div>
            <div className="fs-12">
              <AText>预算</AText>
              <AText>{renderMoney(budgetPrice)}</AText>
            </div>
            <div className="fs-12">
              <AText>支出</AText>
              <AText>{renderMoney(expenditureAmount)}</AText>
            </div>
            <div className="fs-12">
              <AText>领取</AText>
              <AText>{collectionNum || "-"}</AText>
            </div>
            <div className="fs-12">
              <AText>已兑换</AText>
              <AText>{exchangeNum || "-"}</AText>
            </div>
            <div className="fs-12">
              <AText>目标</AText>
              <AText>{targetCustomersName}</AText>
            </div>
            <div className="fs-12">
              <AText>状态</AText>
              <AText>{couponsStatusName}</AText>
            </div>
          </div>
        </div>

        <div className="df">
          <ATabs type="card" className="f-1" style={{ minWidth: 360 }}>
            <ATabs.TabPane tab="商品优惠券">
              {goodsCouponsRespList.length} 件商品
              <div>
                {goodsCouponsRespList.map((item) => (
                  <GoodsItem key={item.goodId} info={item} />
                ))}
              </div>
            </ATabs.TabPane>
          </ATabs>

          <div className="f-1" style={{ marginLeft: 120 }}>
            <div className=" mt-12" style={{ width: 158 }}>
              <div className="border-ccc border-radius-4 p-12">
                <img
                  src={goodsCouponsRespList[0]?.imgUrl ?? ""}
                  alt=""
                  style={{ height: 156, width: "100%", objectFit: "contain" }}
                />

                <Typography.Link className="p-0 fs-12 text-center d-ib mt-12 w-100percent">
                  {couponFullName || "sdasd asd asd asd asd as"}
                </Typography.Link>
                <div className="fs-12 mt-12 d-ib text-center w-100percent bg-f8 border-ccc border-radius-4 p-12 pt-6 pb-6">
                  领取优惠券
                </div>
              </div>
              <AText className="fs-12 d-ib text-center mt-6" strong>
                <i>仅供说明。实际的优惠券图片可能与网站上的图片不同。</i>
              </AText>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CouponDetail;
