import React, { useRef, useEffect, useCallback, useState } from 'react';
import './index.less'
import { Form, Input, Modal } from 'antd';
import Template from '../Template';
import AJAX from "api/apis";


export default (props) => {
  let sbuFormRef = useRef();
  const [value, onChange] = useState('');
  const [address, setAddress] = useState('');
  const [isModalVisible, setisModalVisible] = useState(false);
  const [identity_company_english_name, set_identity_company_english_name] = useState(null)
  const [randoms, setRandoms] = useState();
  useEffect(() => {
    getRegInfo()
    MathRand()
  }, [])
  const MathRand = useCallback(() => {
    let Num = "";
    for (let i = 0; i < 6; i++) {
      Num += Math.floor(Math.random() * 10);
    }
    setRandoms(Num)
  })
  const getRegInfo = useCallback(() => {
    AJAX.login.getStoreRegisterInfo([]).then((data) => {
      if (data.code == 1) {
        if (data.data) {
          for (let item of data.data) {
            if (item.propertyValue) {
              if (item.propertyKey == "company_business_site") {
                setAddress(item.propertyValue)
              }
              if (item.propertyKey == "company_english_name") {
                set_identity_company_english_name(item.propertyValue)
              }
            }
          }
        }
      }
    }).catch((err) => {
    })
  })

  const collapseList = [
    {
      header: '为什么需要地址验证？',
      html: '<p>为了让我们的商城为买家和卖家提供保护，我们会验证企业和个人信息。</p>'
    },
    {
      header: '地址输入指导原则是什么？',
      html: `<div>您必须输入亚马逊能够找到的准确地址。不接受虚拟邮箱。需要记住的一些指南：
      <ul>
        <li style="color: #949494;">• 请确保输入您所在位置的完整且准确的街道地址。</li>
        <li style="color: #949494;">• 包括楼号、楼层以及门牌号码等。</li>
        <li style="color: #949494;">• 只包含属于您正式地址的信息。</li>
        <li style="color: #949494;">• 对于设在中国的卖家，不接受拼音输入的地址。</li>
      </ul>
      </div> `
    },
    {
      header: '如何追踪明信片配送状态？',
      html: '<p>您可以通过登录您的卖家平台帐户来查看明信片配送状态。地址验证页面将显示明信片的配送状态。</p> '
    },
    {
      header: '我如何知道我已完成地址验证？',
      html: '<p>一经在屏幕上输入您的代码，您会得到一个实时验证结果。如果您的地址已通过验证，那么您可以继续提交您的身份证件。如果我们无法验证您的地址，那么您可以通过向同一地址或不同地址申请新明信片来重新启动此过程。</p> '
    },
    {
      header: '没有收到明信片？',
      html: '<p>大部分明信片会在 5-8 个工作日内送达。但是，验证明信片偶尔会丢失。要申请一张新的明信片，请点击屏幕底部的<b>申请新的明信片</b>链接。<b>注意</b>： 申请新明信片的尝试次数有限制。此外，如果您在明信片送达之前更新您的公司地址，该代码将不再有效，您需要再申请一个代码。</p> '
    },
    {
      header: '等待完成地址验证时，我可以做些什么？',
      html: '<p>当您等待地址验证完成时，请查看在亚马逊全球销售商品的成功案例。<a>点击此处</a>。</p> '
    },
    {
      header: '我为什么无法编辑地址？',
      html: '<p>您在注册过程中提供地址后，将无法对其进行编辑，因为正在验证中。如果您希望在注册过程中更改地址，将需要创建一个新账户。但是，一旦地址经过验证，就可以更改该地址。请注意，您提供的任何新地址都将完成地址验证。</p> '
    },
  ]

  let nextEvent = useCallback(async () => {
    const res = await sbuFormRef.current.validateFields()
    if (res.code) {
      return {
        arr: [],
        localArr: null,
        whichPage: 'videoAuthentication/AddressVerification',
        step: 13
      }
    } else {
      return null
    }
  })
  const hideModalVisible = useCallback(() => {
    setisModalVisible(false)
  })

  return (
    <Template
      collapseList={collapseList}
      prePath="/videoAuthentication/VideoVerification"
      nextPath="/videoAuthentication/CompleteVerification"
      nextEvent={nextEvent}
      current={5}
      disabled={false}
      className='AddressVerification register_style'
      Pstyle={{
        width: '45%',
        margin: '0 auto'
      }}
    >
      <React.Fragment>
        <h3>地址验证</h3>
        <div className='vi-divider'></div>
        <div className='a-alert'>
          <i className="a-icon a-icon-alert"></i>
          <div className='a-alert-content'>由于有新冠肺炎，明信片送达时间可能比下面提到的预计送达日期要晚。</div>
        </div>
        <div className="a-column a-span12 a-text-center a-spacing-top-extra-large">
          <span className="a-size-medium a-color-secondary ng-binding">
            包含您的代码的明信片已邮寄到以下地址。该明信片需要 5-8 个工作日才能送达，您可以在下面追踪状态。
          </span>
        </div>
        <div className='address a-size-medium'>
          {identity_company_english_name}
          <p>{address.replace(/------/g, " ")}</p>
        </div>
        <span className="a-size-medium a-color-state a-text-bold ng-binding">
          已送达
        </span>
        <ul className="progressbar">
          <li className="active ng-binding">• 正在准备</li>
          <li className="active ng-binding">• 已派送</li>
          <li className="active ng-binding">• 在途中</li>
          <li className="active ng-binding">• 已送达</li>
        </ul>
        <h6 className='a-size-medium'>请输入地址验证码</h6>
        <Form ref={sbuFormRef}>
          <Form.Item
            name="code"
            rules={[{
              required: true,
              validator: (rule, value, cbfn) => {
                if (!value) {
                  cbfn('请输入6位数验证码')
                } else if (value.length < 6) {
                  cbfn('验证码应为6位数')
                } else if (Number(value) !== Number(randoms)) {
                  cbfn('无效的验证码. 请检查您的验证码并重试。')
                }
                cbfn()
              }
            }]}>
            <Input maxLength="6" />
          </Form.Item>
        </Form>
        {/* <Input value={value} onChange={(e) => { onChange(e.target.value) }}></Input> */}
        <div className='a-spacing-top-large a-size-medium'>未在 7 天内收到您的明信片吗？ <a onClick={MathRand}>请求新的一次性密码。</a></div>

        <div className="elailTips" onClick={() => { setisModalVisible(true) }}>
          <img src={require("@/assets/img/register/phone_pinComing.png").default} />
        </div>


        <Modal title="验证码" className="meailModalBox" centered visible={isModalVisible} footer={null} onCancel={hideModalVisible}>
          <p className="otp">{randoms}</p>
          <p>【亚马逊】Message from Amazon:Your one-time PIN is {randoms}</p>
        </Modal>
      </React.Fragment>
    </Template>
  )
}