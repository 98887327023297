import React, { useState, useCallback, useEffect } from "react";
import AJAX from "api/apis";
import "./Commodity-select.css"
import { List, Breadcrumb, Input } from "antd";
import IconFont from "../../../../components/Icon/Icon";
const { Search } = Input;
class CommoditySelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                list: []
            }
        }
    }
    // componentWillMount() {
    //     this.getCollectGoodsClassify()
    // }
    componentWillReceiveProps(newProps) {  //监听参数变化
        if (newProps.isgit) {
            // this.getCollectGoodsClassify()
        }
    }

    searchClass = (e) => {
        AJAX.addProduct.getGoodsClassify({ classifyName: e }).then((res) => {
            if (res.code == 1) {
                this.setState({
                    data: {
                        ...this.state.data,
                        list: res.data

                    }
                })
            }
        })
    }
    addCollectGoodsClassify = (classifyId, index) => {  //添加分类收藏
        AJAX.addProduct.addCollectGoodsClassify({ classifyId }).then((res) => {
            if (res.code == 1) {
                this.props.getList()
                this.setState({
                    data: {
                        ...this.state.data,
                        list: this.state.data.list.map((item, _index) => _index == index ? { ...item, isCollect: false } : item)
                    }
                })
            }
        })
    }
    removeCollectGoodsClassify = (classifyId, index) => {  //取消分类收藏
        AJAX.addProduct.removeCollectGoodsClassify({ classifyId }).then((res) => {
            if (res.code == 1) {
                this.props.getList()
                this.setState({
                    data: {
                        ...this.state.data,
                        list: this.state.data.list.map((item, _index) => _index == index ? { ...item, isCollect: true } : item)
                    }
                })
            }
        })
    }
    ChildrenClick = (item) => {  //判断某分类下是否有子分类
        if (item.classifyIfAddBranch) {
            // 没有子分类
            this.props.props.history.push({
                pathname: '/createListing',
                search: `?id=${item.id}`,
                state: {
                    id: item.id
                }
            })
        } else {
            this.getGoodsClassify(item.id)
        }
    }
    getGoodsClassify = (classifyParentId = "") => {
        AJAX.addProduct.getGoodsClassify({ classifyParentId }).then((res) => {
            if (res.code == 1) {
                this.setState({
                    data: {
                        ...this.state.data,
                        list: res.data
                    }
                })
            }
        })
    }
    render() {
        let { data } = this.state
        return <div className="commodity-container">
            <div className="commodity-collect-box">
                <p className="commodity-collect">搜索</p>
                <span>什么是商品类型？</span>
            </div>
            <Search className="stepSearchBox" placeholder="搜索类别" onPressEnter={this.searchClass} enterButton="搜索" onSearch={this.searchClass} />
            <div className="commodity-container commodity-select-box">
                {data.list.length > 0 ? <List size="large" dataSource={data.list}
                    renderItem={(item, index) =>
                        <List.Item key={index}>
                            {item.isCollect ?
                                <IconFont type="icon-kongwujiaoxing" className="commodity-icon" onClick={() => { this.addCollectGoodsClassify(item.id, index) }} />
                                :
                                <IconFont type="icon-xingzhuang60kaobei2" className="commodity-icon" onClick={() => { this.removeCollectGoodsClassify(item.id, index) }} />}
                            <div onClick={() => { this.ChildrenClick(item) }} style={{ display: 'contents' }}>
                                <List.Item.Meta title={item.classifyName} />
                                {item.classifyIfAddBranch == false ? <div>
                                    <IconFont type="icon-fanhui-copy-copy" className="commodity-icon" />
                                </div> : <div className="commodity-select">
                                    <button>选择类别</button>
                                </div>}
                            </div>
                        </List.Item>
                    }
                /> : <div className="commodity-select-icon">
                    <IconFont type="icon-zhinanzhen" />
                    <p>搜索类别</p>
                </div>}
            </div>
        </div>

    }
}
export default CommoditySelect