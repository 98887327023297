import { renderTooltip } from "@a-components/viewFragment";
import withProps from "@a-components/withProps";
import { Table as AntTable } from "antd";
import { useMemo } from "react";

const Table = withProps(
  ({ columns, ...otherProps }) => {
    const innerColumns = useMemo(() => {
      return columns.map((item) => {
        const { title, tooltip } = item;
        return {
          ...item,
          title: (
            <>
              {title} {renderTooltip(tooltip)}
            </>
          ),
        };
      });
    }, [columns]);

    return <AntTable {...otherProps} columns={innerColumns} />;
  },
  {
    className: "a-table",
  }
);

export default Table;
