import classNames from "classnames";
import React from "react";
import ReactQuilOrigin from "react-quill";
import "react-quill/dist/quill.snow.css";

function ReactQuill({ className, ...otherProps }) {
  return (
    <ReactQuilOrigin
      theme="snow"
      className={classNames(className, "a-react-quill")}
      {...otherProps}
    />
  );
}

export default ReactQuill;
