import { useCallback, useState } from "react";
import { Button } from "antd";
import { AText, AInput } from "@a-components/";
import KeywordCondition, { matchTypesMapping } from "../KeywordCondition";
import style from "./style.module.css";
import classNames from "classnames";

const KeywordsInput = ({ onAddKeywords, selectedKeywordsList }) => {
  const [keywords, setKeywords] = useState("");
  const [tipsInfo, setTipsInfo] = useState({
    isShow: false,
    errorMsg: "",
    type: "", // warning | danger
  });
  const [conditionValues, setConditionValues] = useState({
    biddingType: "建议竞价",
    matchTypes: [1, 2, 3],
  });
  const { biddingType, matchTypes } = conditionValues;

  const handleAdd = useCallback(() => {
    const keywordsArr = Array.from(
      new Set(keywords.split("\n").filter(Boolean))
    );

    const list = keywordsArr
      .map((keyword) => {
        return matchTypes.map((matchType) => {
          return {
            biddingPrice: 0.75,
            keyword,
            matchType,
            matchTypeName: matchTypesMapping[matchType],
            suggestedBidding: "",
            suggestedBiddingEnd: "",
            suggestedBiddingStart: "",
          };
        });
      })
      .flat();

    const returnValue = onAddKeywords?.(list);

    if (!returnValue) return;
    const { allSuccess, successList, failList } = returnValue;

    setKeywords(
      keywordsArr
        .filter((keyword) => {
          return !successList.find((item) => item.keyword === keyword);
        })
        .join("\n")
    );

    const failLength = failList.length;
    const successLength = successList.length;
    const totalLength = successLength + failLength;
    const str = `${successLength}/${totalLength} 已成功添加关键词。`;

    if (allSuccess) {
      return setTipsInfo({
        isShow: true,
        type: "success",
        errorMsg: str,
      });
    }

    if (successList.length) {
      return setTipsInfo({
        isShow: true,
        type: "warning",
        errorMsg: str,
      });
    }

    return setTipsInfo({
      isShow: true,
      type: "danger",
      errorMsg: str,
    });

  }, [keywords, onAddKeywords, matchTypes]);

  return (
    <div>
      <KeywordCondition
        value={conditionValues}
        onChange={setConditionValues}
        type="input"
      />

      <div className="pl-12 pr-12 pb-12 pt-12">
        <AInput.TextArea
          type="textarea"
          rows={10}
          value={keywords}
          onChange={(e) => setKeywords(e.target.value)}
        />

        {tipsInfo.isShow ? (
          <AText type={tipsInfo.type}>{tipsInfo.errorMsg}</AText>
        ) : null}
        <div className="df jc-fe mt-12">
          <Button size="small" onClick={handleAdd}>
            添加关键词
          </Button>
        </div>
      </div>
    </div>
  );
};

export default KeywordsInput;
