import React from 'react'
import './imageText.css'
import IconFont from 'components/Icon/Icon'
import {Link} from 'react-router-dom'
import Footer from 'components/addlisting_footer/FooterCom'
import Header from 'components/home_header'
function imageText(){
    return(
      <div>
        <div className="information_body">
            <Header/>
             <div className="Details_head">
             <div className="Details_headI">
              <div >
                <Link to="/Aa" style={{ color: "#008296" }}>Home</Link>
              </div>
            <span>
              <IconFont type="icon-fanhui-copy-copy" />
            </span>
            <div>type</div>
            <span>
              <IconFont type="icon-fanhui-copy-copy" />
            </span>
            <div>ebc</div>
            <span>
              <IconFont type="icon-fanhui-copy-copy" />
            </span>
            <div>content</div>
            <span>
              <IconFont type="icon-fanhui-copy-copy" />
            </span>
            <div>new</div>
            <span>
              <IconFont type="icon-fanhui-copy-copy" />
            </span>
            <div>revision</div>
            <span>
              <IconFont type="icon-fanhui-copy-copy" />
            </span>
            <div>draft</div>
            <span>
              <IconFont type="icon-fanhui-copy-copy" />
            </span>
            <div>edit</div>
          </div>

        <div className="informationHead_headII">
           <div className="informationHead_headII_btn"><button>取消</button></div>
           <div  className="informationHead_headII_btnI"><button>保存为草稿</button></div>
           <div  className="informationHead_headII_btnII"><button><Link to="/informationASIN">下一步：应用ASIN</Link></button></div>
        </div>
          </div>


          <div>
              <div className="informationHead_titel">商品描述详情</div>
          </div>

          <div className="Details_Steps">
          <div className="Details_StepsBox">
            <div className="Details_StepsImg"></div>
            <div className="Details_StepsFont">
              <div style={{ width: "18%" }}>创建内容</div>
              <div style={{ width: "28%" }}>
                <div>应用亚马逊商品编码</div>
                <div style={{ padding: "0 0 0 38px" }}>(ASIN)</div>
              </div>
              <div>已提交</div>
              <div>已核准</div>
              <div>
                <div>已发布<IconFont type="icon-tishi21" className="Details_Font_tishi" /></div>
                <div>在12/12/2020</div>
              </div>
            </div>
          </div>
          <div className="informationHead_Steps_li">
            <div className="informationHead_Steps_liI">
              <div className="informationHead_Steps_divI">商品描述名称</div>
              <div className="informationHead_Steps_divII">语言</div>
              <div style={{width:"16%"}}>商品描述类型</div>
              <div style={{width:"14%"}}>状态</div>
              <div style={{width:"18%"}}>已应用的ASIN</div>
              <div >提交日期</div>
            </div>
            <div className="informationHead_Steps_liII">
              <div className="informationHead_head_inp informationHead_Steps_divI"><input/></div>
              <div className="informationHead_Steps_divII">
                <div>美国  英语</div>
              </div>
              <div style={{width:"16%"}}>EBC</div>
              <div style={{width:"14%"}}>已批准</div>
              <div style={{width:"18%"}}>1</div>
              <div>12/7/2020</div>
            </div>
          </div>
         
        </div>

          
            
            <div className="imageText_navBox">
              <div className="imageText_nav">
                <div className="imageText_navHead">
                  <div className="imageText_navHead_titel">标准三个图和文本</div>
                  <div className="imageText_navHead_icon">
                    <div><IconFont type="icon-jiantou1"/></div>
                    <div><IconFont type="icon-jiantou" /></div>
                    <div><IconFont type="icon-guanbi"/></div>
                  </div>
                </div>
              </div>
                <div className="imageText_navHead_text">
                  <input placeholder="输入标题文本" />
                  <div className="imageText_navHead_image">

                    <div className="imageText_image_boxI">
                      <div className="imageText_image_img">图</div>
                      <div><input placeholder="Use of steam engine" /></div>
                    </div>

                    <div className="imageText_image_boxI">
                      <div className="imageText_image_img">图</div>
                      <div><input placeholder="Use of steam engine" /></div>
                    </div>

                    <div className="imageText_image_boxI" style={{margin:"0"}}>
                      <div className="imageText_image_img">图</div>
                      <div><input placeholder="输入文本标题" /></div>
                    </div>
                    
                  </div>

                  <div className="imageText_image_fontBoxI">
                    <div className="imageText_image_fontBox">
                      <div className="imageText_fontBox_head">
                        <IconFont type="icon-jiacu" />
                        <IconFont type="icon-xieti" />
                        <IconFont type="icon-Underline" />
                        <IconFont type="icon-list" />
                        <IconFont type="icon-ol" />
                      </div>
                      <div>
                      <textarea>When using the Steam Sauna Box Black , insert the steam engine on the left</textarea>
                      </div>
                    </div>
                    <div className="imageText_image_fontBox">
                      <div className="imageText_fontBox_head">
                        <IconFont type="icon-jiacu" />
                        <IconFont type="icon-xieti" />
                        <IconFont type="icon-Underline" />
                        <IconFont type="icon-list" />
                        <IconFont type="icon-ol" />
                      </div>
                      <div>
                      <textarea>The red bracket is placed at the bottom, and the rest can be assembled according to the picture</textarea>
                      </div>
                    </div>
                    <div className="imageText_image_fontBox"  style={{margin:"0"}}>
                      <div className="imageText_fontBox_head">
                        <IconFont type="icon-jiacu" />
                        <IconFont type="icon-xieti" />
                        <IconFont type="icon-Underline" />
                        <IconFont type="icon-list" />
                        <IconFont type="icon-ol" />
                      </div>
                      <div>
                      <textarea>The height, length and width of the steam sauna box are 38.9'', 33.8'' and 39.9'' respectively</textarea>
                      </div>
                    </div>
                  </div>

                </div>
            <div className="infDetails_ModalBox">
                 <div className="infDetails_ModalBtn"><button>添加模块</button></div>
            </div>
            </div>
            
            </div>
            <Footer/>
        </div>
    )
}
export default imageText












           
           