import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
    display: inline-block;
    min-width: 682px;
    &>ul{
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0;
        &>li{
            list-style: none;
            padding: 0 10px;
            cursor: pointer;
            color: #002f36;
            height: 24px;
            line-height: 24px;
            &:nth-child(1){
                padding-left: 0;
            }
            &:nth-last-child(1){
                padding-left: 0;
            }
        }
        ul{
            padding: 0;
            &>li{
                list-style: none;
                cursor: pointer;
                &:hover{
                    background-color: #eee;
                }
            }
        }
        .ant-popover-inner-content{
            padding: 6px;
            li div,
            li a{
                display: inline-block;
                width: 100%;
                padding: 6px 6px;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.85);
            }
        }
    }
`;