import moment from "moment";

export const settingFormInitialValues = {
  adActiveName: "",
  adGroup: "",
  adStartTime: moment(),
  adEndTime: null,
  budget: undefined,
  orientationStrategyType: "automatic", // automatic | manual
};
