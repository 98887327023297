import { ATabs } from "@a-components";
import { useState } from "react";
const { TabPane } = ATabs;
const GoodsSelector = () => {
  const [categoryType, setCategoryType] = useState("category");

  return (
    <ATabs value={categoryType} onChange={(e) => setCategoryType(e)}>
      <TabPane tab="分类" key="category">
        {categoryType}
      </TabPane>
      <TabPane tab="各个商品" key="goods">
        {categoryType}
      </TabPane>
    </ATabs>
  );
};

export default GoodsSelector