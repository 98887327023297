export default {
    'header_nav_Catalog':'Catalog', //目录
    'header_nav_Inventory':'Inventory', //库存
    'header_nav_Pricing':'Pricing', //确定价格
    'header_nav_Orders':'Orders', //订单
    'header_nav_Advertising':'Advertising', //广告
    'header_nav_Stores':'Stores', //品牌旗舰店
    'header_nav_Growth':'Growth', //增长
    'header_nav_Reports':'Reports', //数据报告
    'header_nav_Performance':'Performance', //绩效
    'header_nav_Apps_Services':'Apps & Services', //应用商店
    'header_nav_B2B':'B2B', //B2B
    'header_nav_Brands':'Brands', //品牌
    
};