import React from "react";
import AJAX from "api/apis";
import "./shopupDL.css";
import IconFont from "../../../../components/Icon/Icon";
import { List, Breadcrumb, message } from "antd";
import { Link } from 'react-router-dom';
import Reactours from "components/reactours";
import TipsIcon from "components/tipsIcon";
class shopupDL extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbData: [],
      data: {
        list: [],
        childList: [],
      },
      SelectedProduct: false,
      oneIsClick: false,
      twoIsClick: false,
      threeIsClick: false,
      fourIsClcik: false,
      isActive: false,
      isTourOpen: true,
      steps1: [
        {
          selector: '.ant-tabs-tab-active',
          content: '在下载库存文件页面，下载批量上传表格',
        },
        {
          selector: '.shopupDL-slideBox-dianzi',
          content: '选择商品分类',
        },
        {
          selector: '.shopupDL-boxBtn-btm button',
          content: '点击生产模板',
        },
      ],
      routePath: "shopupDL"
    }
  }
  componentWillMount() {
    // this.getGoodsClassify()
  }
  // 获取最顶级分类
  getGoodsClassify = (classifyParentId = null) => {
    AJAX.addProduct.getGoodsClassify({ classifyParentId }).then((res) => {
      if (res.code == 1) {
        if (classifyParentId) {
          this.setState({
            data: {
              ...this.state.data,
              childList: res.data
            }
          })
        } else {
          this.setState({
            data: {
              ...this.state.data,
              list: res.data
            }
          })
        }
      }
    })
  }
  awakenTips = (type) => {
    let steps1 = []
    if (this.state.fourIsClcik) {
      steps1 = [
        {
          selector: '.ant-tabs-tab-active',
          content: '在下载库存文件页面，下载批量上传表格',
        },
        {
          selector: '.xuanz',
          content: '.点击选择',
        },
        {
          selector: '.shopupDL-boxBtn-btm button',
          content: '点击生产模板',
        },
      ]
    } else {
      steps1 = [
        {
          selector: '.ant-tabs-tab-active',
          content: '在下载库存文件页面，下载批量上传表格',
        },
        {
          selector: '.shopupDL-slideBox-dianzi',
          content: '选择商品分类',
        },
        {
          selector: '.shopupDL-boxBtn-btm button',
          content: '点击生产模板',
        },
      ]
    }
    this.setState({
      isActive: type,
      steps1,
      routePath: "shopupDL-xuanz",
    })
  }
  // 选择该分类或获取下级分类
  selectChlid = (item, index) => {
    if (!item.classifyIfAddBranch) {
      this.getGoodsClassify(item.id)
    } else {

    }
  }
  download = () => {
    let { SelectedProduct } = this.state;
    if (!SelectedProduct) {
      message.warning('请选择分类');
      return;
    }
    AJAX.addProduct.getGoodsImportUrl().then((res) => {
      if (res.code == 1 && res.data) {
        const eleLink = document.createElement('a');
        eleLink.style.display = 'none';
        eleLink.href = res.data;
        document.body.appendChild(eleLink);
        eleLink.click();
        document.body.removeChild(eleLink);
      }
    })
  };
  scrollToBottom = () => {
    setTimeout(() => {
      let messagesEndRef = document.querySelector('#messagesEndRef');
      messagesEndRef.scrollLeft = messagesEndRef.scrollWidth - messagesEndRef.clientWidth;
    }, 200)
  };
  threeClick = () => {
    this.setState({
      threeIsClick: true
    })
    this.scrollToBottom();
  }
  fourClcik = () => {
    this.setState({
      fourIsClcik: true,
      routePath: "shopupDL-xuanz",
      isActive: false
    }, () => {
      let isActive = JSON.parse(localStorage.getItem("stepList"))["shopupDL-xuanz"]
      console.log(isActive, '222')
      if (this.state.fourIsClcik) {
        setTimeout(() => {
          this.setState({
            steps1: [
              {
                selector: '.xuanz',
                content: '点击选择',
              },
            ],

            isActive: true
          })
        }, 500)
      }

    })
    this.scrollToBottom();
  }
  selectClick = () => {
    this.setState({
      SelectedProduct: true
    })
  }
  render() {
    let { oneIsClick, twoIsClick, threeIsClick, fourIsClcik, SelectedProduct, isActive, steps1, isTourOpen, routePath } = this.state
    return <div className="shopupDL-body">
      <div className="shopupDL-lbox">
        <div className="shopupDL-titel">
          <div className="shopupDL-fontSize">创建库存文件模板</div>
          <div>您现在可以专门针对您销售的商品类型生成自定义库模板</div>
        </div>
        <div className="shopupDL-merit">
          <div className="shopupDL-fontSize">主要优点：</div>
          <div>-在一个模板中列出多个商品分类下不同类型商品的信息</div>
          <div>-根据具体的业务需求选择要添加到模板中的信息</div>
        </div>
        <div>
          您需要先获得亚马逊的批准，才能将商品发布<a style={{ color: "#0066c0" }}>某些商品分类下</a>
          。要针对特定分类申请批准，请点击连接并按照说明提交您的申请。
        </div>
        {/* 商品类型盒子 */}
        <div className="shopupDL-bor">
          {/* 第1步：选择您要销售的商品的类型 */}
          <div className="shopupDL-boxHed">第1步：选择您要销售的商品的类型</div>
          <div className="shopupDL-boxPD">
            <div className="shopupDL-inpTitel">
              <div>搜索工具： </div>
              <div style={{ color: "#0066c0" }}>
                什么是商品类型？<span>Variation Wizard</span>
              </div>
            </div>
            <div className="shopupDL-inpB">
              <div className="shopupDL-inp">
                <IconFont type="icon-sousuo" className="Replenish-inpFont" />
                <input type="text" placeholder="示例：mp3播放器、棒球手套、DVD、吉他等等" />
              </div>
              <button className="Replenish-inpBtn">搜索</button>
              <div style={{ color: "#0066c0" }}>清除搜索记录</div>
            </div>
            <div className="shopupDL-part">
              <div className="shopupDL-line"></div>要么
              <div className="shopupDL-line"></div>
            </div>
            <div className="shopupDL-titelF">商品分类工具：</div>
            {oneIsClick ? <div>
              <span>电子产品</span>
              {twoIsClick ? <><strong>
                <IconFont type="icon-fanhui-copy-copy" />
              </strong>
                <span>手机和配件</span></> : null}
              {threeIsClick ? <><strong>
                <IconFont type="icon-fanhui-copy-copy" />
              </strong>
                <span>配件</span></> : null}
              {fourIsClcik ? <><strong>
                <IconFont type="icon-fanhui-copy-copy" />
              </strong>
                <span>头戴式耳机</span></> : null}
            </div> : null}
            <div className="shopupDL-slide" id="messagesEndRef">
              <div className="shopupDL-slideBox">
                <div className="shopupDL-slideBox-dianzi">
                  {/* {list ? list.map((item, index) => {
                    return <div key={index} onClick={() => { this.selectChlid(item, index) }} className="shopupDL-slideNav">
                      <div className="classifyName">{item.classifyName}</div>
                      <div>
                        {!item.classifyIfAddBranch ? <IconFont type="icon-fanhui-copy-copy" /> : <button>选择</button>}
                      </div>
                    </div>
                  }) : null} */}

                  <div onClick={() => { this.setState({ oneIsClick: true }) }} className={oneIsClick ? 'shopupDL-slideNav b-col-f6' : 'shopupDL-slideNav'}>
                    <div className="classifyName">电子产品</div>
                    <div>
                      <IconFont type="icon-fanhui-copy-copy" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="shopupDL-slideBox">
                <div>
                  {/* {childList ? childList.map((item, index) => {
                    return <div key={index} onClick={() => { this.selectChlid(item, index) }} className="shopupDL-slideNav">
                      <div className="classifyName">{item.classifyName}</div>
                      <div>
                        {!item.classifyIfAddBranch ? <IconFont type="icon-fanhui-copy-copy" /> : <button>选择</button>}
                      </div>
                    </div>
                  }) : null} */}

                  {oneIsClick ? <div onClick={() => { this.setState({ twoIsClick: true }) }} className={twoIsClick ? 'shopupDL-slideNav b-col-f6' : 'shopupDL-slideNav'}>
                    <div className="classifyName">手机和配件</div>
                    <div >
                      <IconFont type="icon-fanhui-copy-copy" />
                    </div>
                  </div> : null}
                </div>
              </div>
              <div className="shopupDL-slideBox">
                <div>
                  {twoIsClick ? <div onClick={this.threeClick} className={threeIsClick ? 'shopupDL-slideNav b-col-f6' : 'shopupDL-slideNav'}>
                    <div className="classifyName">配件</div>
                    <div>
                      <IconFont type="icon-fanhui-copy-copy" />
                    </div>
                  </div> : null}
                </div>
              </div>
              {threeIsClick ? <div className="shopupDL-slideBox">
                <div>
                  <div onClick={this.fourClcik} className={fourIsClcik ? 'shopupDL-slideNav b-col-f6' : 'shopupDL-slideNav'}>
                    <div className="classifyName">头戴式耳机</div>
                    <div>
                      <IconFont type="icon-fanhui-copy-copy" />
                    </div>
                  </div>
                </div>
              </div> : null}
              {fourIsClcik ? <div className="shopupDL-slideBox">
                <div>
                  <div className="shopupDL-slideNav">
                    <div className="classifyName">Earbud Headdphones</div>
                    <div>
                      <button className="xuanz" onClick={this.selectClick}>选择</button>
                    </div>
                  </div>
                </div>
              </div> : null}
            </div>
            <div className="shopupDL-titelF">已选商品一览</div>
            <div className="shopupDL-delBox">
              <div className="shopupDL-delBox-top">
                <div style={{ paddingLeft: "20px", color: "#0066c0" }}>全部删除</div>
                <div>所选分类</div>
                <div>商品类型</div>
                <div>节点编号</div>
                <div>分类路径</div>
              </div>
              {SelectedProduct ? <div className="shopupDL-delBox-nav">
                <div onClick={() => { this.setState({ SelectedProduct: false }) }} style={{ paddingLeft: "40px" }}>
                  <IconFont type="icon-guanbi" />
                </div>
                <div>Earbud Headdphones</div>
                <div>BEAUTY</div>
                <div>1</div>
                <div>电子产品/手机和配件/配件/头戴式耳机/Earbud Headdphones</div>
              </div> : null}
            </div>
          </div>
        </div>
        {/* 第2步：选择模板的类型 */}
        <div className="shopupDL-bor">
          <div className="shopupDL-boxHed">第2步：选择模板的类型</div>
          <div className="shopupDL-boxPD">
            <div style={{ marginBottom: "30px" }}>
              <div className="shopupDL-box2Top">
                <div className="shopupDL-titelF">模板语言</div>
                <div style={{ paddingLeft: "10px" }}>
                  <label>
                    <select
                      className="adminV-label"
                      style={{
                        background: "#eff1f3",
                        border: "1px solid #a2a6ac",
                      }}
                    >
                      <option>中文</option>
                    </select>
                  </label>
                </div>
              </div>
              <div>
                <span>i</span>您仍需要提供英文商品信息内容
              </div>
            </div>
            <div style={{ marginBottom: "30px" }}>
              <div className="shopupDL-titelF">请选择一种模式</div>
              <div className="shopupDL-boxBtn">
                <div>高级</div>
                <div>自定义</div>
              </div>
            </div>
            <div style={{ marginBottom: "30px" }}>
              <div>
                "高级"选项包含与以上所选商品关联的{" "}
                <span style={{ fontWeight: "bold" }}>所有</span>
                属性组。该选项包含"必填"、"首选"和"可选"属性组。
              </div>
            </div>
            <div onClick={this.download} className="shopupDL-boxBtn-btm">
              <button>生成模板</button>
            </div>
          </div>
        </div>

        {/* 过去生成的模板 */}
        {/* <div className="shopupDL-bor">
          <div className="shopupDL-boxHed-btm">
            <div>过去生成的模板</div>
            <div>
              <IconFont type="icon-fanhui-copy-copy" style={{ transform: "rotate(-90deg)", fontSize: "24px" }} />
            </div>
          </div>
          <div className="shopupDL-boxBef">
            <div style={{ margin: "15px 0" }}>
              <div>要下载过去生成的模板，请点击以下任一连接</div>
              <div className="shopupDL-boxBef-nav">
                <div className="shopupDL-boxBef-navShop">
                  <div>女士围巾</div>
                  <div>Tumblers & Water Glasses</div>
                  <div>Gaming Mice</div>
                  <div>Brush Sets</div>
                  <div>Makeup Sets</div>
                  <div>防滑床垫</div>
                  <div>地毯及室内装饰品清洁替换零件；便携式烘干机</div>
                  <div>Furniture Sets</div>
                  <div>主脚桌</div>
                </div>
                <div>
                  <div>
                    <span>(高级)</span>生成日期 12/22/2020 10:46:00 GMT +0800
                    （中国标准时间）
                  </div>
                  <div>
                    <span>(高级)</span>生成日期 12/22/2020 10:46:00 GMT +0800
                    （中国标准时间）
                  </div>
                  <div>
                    <span>(高级)</span>生成日期 12/22/2020 10:46:00 GMT +0800
                    （中国标准时间）
                  </div>
                  <div>
                    <span>(高级)</span>生成日期 12/22/2020 10:46:00 GMT +0800
                    （中国标准时间）
                  </div>
                  <div>
                    <span>(高级)</span>生成日期 12/22/2020 10:46:00 GMT +0800
                    （中国标准时间）
                  </div>
                  <div>
                    <span>(高级)</span>生成日期 12/22/2020 10:46:00 GMT +0800
                    （中国标准时间）
                  </div>
                  <div>
                    <span>(高级)</span>生成日期 12/22/2020 10:46:00 GMT +0800
                    （中国标准时间）
                  </div>
                  <div>
                    <span>(高级)</span>生成日期 12/22/2020 10:46:00 GMT +0800
                    （中国标准时间）
                  </div>
                  <div>
                    <span>(高级)</span>生成日期 12/22/2020 10:46:00 GMT +0800
                    （中国标准时间）
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* 库存文件 */}
        {/* <div className="shopupDL-bor">
          <div className="shopupDL-boxHed-btm">
            <div>库存文件</div>
            <div>
              <IconFont type="icon-fanhui-copy-copy" style={{ transform: "rotate(90deg)", fontSize: "24px" }} />
            </div>
          </div>
        </div> */}
      </div>
      <div className="shopupDL-rbox">
        {/* 通过增强的处理报告快速修复商品信息错误 */}
        <div
          className="shopupDL-warnBox"
          style={{ border: "1px solid #3479ac" }}
        >
          <div className="shopupDL-rBox-warnHed">
            <IconFont
              type="icon-jinggao1"
              className="RepertoryTable1-box1-font"
              style={{ color: "#3489ac", margin: "0 20px" }}
            />
            <div>通过增强的处理报告快速修复商品信息错误</div>
          </div>
          <div className="shopupDL-rBox-warnnav">
            <div>
              增强的处理报告旨在帮助您快速识别商品信息错误，以便进行修复。相比以前的处理报告，新的 Excel 格式报告在出现错误的单元格中对错误和警告进行了突出显示。通过这种格式，您能一目了然地找出提交内容中的问题，从而更轻松地纠正商品信息错误。
            </div>
          </div>
        </div>

        {/* 常见问题 */}
        <div className="shopupDL-bor">
          <div className="shopupDL-boxHed">常见问题</div>
          <div className="shopupDL-boxPD">
            <div style={{ padding: "10px 20px" }}>
              <ul>
                <li><Link to="#">如何选择商品分类并创建新的目录商品？</Link></li>
                <li><Link to="#">如何使用库存模板添加匹配商品？</Link></li>
                <li><Link to="#">如何申请销售亚马逊限制的商品？</Link></li>
                <li><Link to="#">如何更改商品的状况？</Link></li>
                <li><Link to="#">如何创建商品的变体关系？</Link></li>
                <li><Link to="#">如何创建或更改包含变体的商品？</Link></li>
                <li><Link to="#">如果库存文件模板没有变体字段，我可以添加变体信息吗？</Link></li>
              </ul>
            </div>
          </div>
        </div>
        {/* 相关主题 */}
        <div className="shopupDL-bor">
          <div className="shopupDL-boxHed">相关主题</div>
          <div className="shopupDL-boxPD">
            <div style={{ padding: "10px 20px" }}>
              <ul>
                <li><Link to="#">构建我的库存文件</Link></li>
                <li><Link to="#">使用商品分类文具</Link></li>
                <li><Link to="#">库存文件模板</Link></li>
                <li><Link to="#">选择和下载您的库存文件模板</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <TipsIcon clicks={this.awakenTips} />
      <Reactours routePath={routePath} isActive={isActive} clicks={this.awakenTips} steps={steps1} isOpen={isTourOpen} />
    </div>
  }
}
export default shopupDL;
