import React, { useCallback, useMemo, useState, useEffect } from "react";
import Header from "components/home_header";
import Footer from "components/addlisting_footer/FooterCom";
import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"
import { Typography } from "antd";
import { AText, AButton, ATabs } from "@a-components";

import style from "./style.module.css";
import { useHistory } from "react-router";

import PromotionManage from "./PromotionManage";
import GoodsListManage from "./GoodsListManage";
import useQuery from "@hooks/useQuery";

const { Paragraph } = Typography;

const paragraphInfo = {
  promotion: {
    title: "社交媒体促销代码",
    content:
      "创建自定义促销代码，获得一个独特的营销页面，通过社交媒体、电子邮件或名人营销将此页面分享给买家。您可以控制如何推广促销活动，以及买家可以获得多少商品和交易的优惠。",
    toPath: "/Promotions/PromotionDetail?promotionType=1&operatorType=create",
    classNames: 'stepGuidanceBox0'
  },
  discount: {
    title: "购买折扣",
    content:
      "使用高级选项，以设置促销的折扣分段，来鼓励买家购买更多，因为他们可以获得更高的折扣（不会影响购买按钮）。要提供单件购买折扣，请考虑实施限时促销价格（会影响购买按钮）。",
    toPath: "/Promotions/PromotionDetail?promotionType=2&operatorType=create",
    classNames: 'stepGuidanceBox1'
  },
  gifts: {
    title: "买一赠一",
    content:
      "如果您在整个商品目录或子目录中的购物符合既定的条件，即可享受一个或多个免费商品。",
    toPath: "/Promotions/PromotionDetail?promotionType=3&operatorType=create",
    classNames: 'stepGuidanceBox2'
  },
};

function Promotions() {
  const { tab = 'create' } = useQuery();
  let isTourOpen = true;
  if (tab == 'create') {
    var steps1 = [
      {
        selector: '.stepGuidanceBox0',
        content: '点击创建社交媒体促销代码',
      },
      {
        selector: '.stepGuidanceBox1',
        content: '点击创建购买折扣',
      },
      {
        selector: '.stepGuidanceBox2',
        content: '点击创建买一赠一',
      },
    ]
  } else if (tab == 'manage') {
    var steps1 = [
      {
        selector: '.stepGuidanceBox3',
        content: '点击搜索促销状态查看促销详情，找不到促销时，可选择状态进行搜索',
      },
      {
        selector: '.stepGuidanceBox4',
        content: '选择一次性优惠码的折扣需点击查看设置一次性优惠码',
      },
    ]
  } else if (tab == 'goods') {
    var steps1 = [
      {
        selector: '.stepGuidanceBox10',
        content: '创建完毕后可使用，返回创建折扣促销页面刷新',
      },
    ]
  }
  const [isActive, setIsActive] = useState(false);
  const awakenTips = (type) =>{
      setIsActive(type);
  }
  const history = useHistory();
  useEffect(() => {

  }, []);
  const renderParagraphInfo = useCallback(
    (info) => {
      const { title, content, toPath, classNames } = info;

      const toJump = () => {
        history.push(toPath);
      };

      return (
        <div>
          <div className="mt-12">
            <AText className="fs-18" strong>
              {title}
            </AText>
            <Paragraph
              style={{ width: 270 }}
              ellipsis={{ rows: 2, expandable: true, symbol: "查看更多" }}
            >
              {content}
            </Paragraph>
          </div>
          <AButton className={classNames} onClick={toJump}>创建</AButton>
        </div>
      );
    },
    [history]
  );

  const handleTabClick = useCallback(
    (value) => {
      history.replace(
        `/Promotions?${new URLSearchParams({
          tab: value,
        })}`
      );
    },
    [history]
  );
  return (
    <div>
      <Header />
      <div className="p-24">
        <div>
          <AText className="fs-24">促销</AText>
          <AButton type="link">了解更多信息</AButton>
        </div>

        <ATabs type="card" onTabClick={handleTabClick} activeKey={tab}>
          <ATabs.TabPane tab="创建促销" key="create">
            <div>
              <AText className="fs-22" strong>
                创建促销{ }
              </AText>
            </div>
            <div className="df">
              <div className="mr-24">
                {renderParagraphInfo(paragraphInfo.promotion)}
              </div>

              <div>{renderParagraphInfo(paragraphInfo.discount)}</div>
            </div>
            <div>{renderParagraphInfo(paragraphInfo.gifts)}</div>
          </ATabs.TabPane>
          <ATabs.TabPane tab="管理你的促销" key="manage">
            <PromotionManage />
          </ATabs.TabPane>
          <ATabs.TabPane tab="管理商品列表" key="goods">
            <GoodsListManage />
          </ATabs.TabPane>
        </ATabs>
      </div>
      <Footer />
      {/* 引导步骤 */}
      <TipsIcon clicks={awakenTips} />
      <Reactours routePath='Promotions' isActive={isActive} clicks={awakenTips} discountStep={tab} steps={steps1} isOpen={isTourOpen} />
    </div>
  );
}

export default Promotions;
