import React from 'react';
import {Container,Logo} from './style';
import {Link} from 'react-router-dom';
import {Popover,Button,Checkbox} from 'antd';
import { injectIntl } from "react-intl";

const Nav = ({intl}) => {
    let data = [
        {
            key:'brand_registered_header.administration', //管理
            children:[
                {
                    text:'管理商标',
                    path:'/BrandRegistered',
                },
                {
                    text:'可获得销售权益',
                    path:'/BrandRegistered',
                },
                {
                    text:'注册品牌',
                    path:'/BrandRegistered',
                },
                {
                    text:'品牌申请',
                    path:'/BrandRegistered',
                },
            ]
        },
        {
            key:'brand_registered_header.protect', //保护
            children:[
                {
                    text:'违规举报',
                    path:'/BrandRegistered',
                },
            ]
        },
        {
            key:'brand_registered_header.monitor', //监控
            children:[
                {
                    text:'提交记录',
                    path:'/BrandRegistered',
                }
            ]
        },
        {
            key:'brand_registered_header.support', //支持
            children:[
                {
                    text:'支持案例日志',
                    path:'/BrandRegistered',
                },
                {
                    text:'联系品牌支持',
                    path:'/BrandRegistered',
                },
                {
                    text:'帮助中心',
                    path:'/BrandRegistered',
                },
            ]
        },
    ];

    return <Container>
            <ul>
                {data.map((item,index)=>{
                    return <li key={index}>
                        <Popover 
                            getPopupContainer={trigger => trigger.parentNode}
                            placement="bottom" 
                            trigger="hover"
                            content={
                                <ul>
                                    {
                                        item.children.map((child,index2)=>{
                                            if(child.path){
                                                return child.key ? 
                                                <li key={index2}><Link to={child.path}>{intl.formatMessage({id:child.key})}</Link></li>
                                                :
                                                <li key={index2}><Link to={child.path}>{child.text}</Link></li>
                                            }else{
                                                return <li key={index2}><div>{child.text}</div></li>
                                            }
                                        })
                                    }
                                </ul>
                            } >
                            {intl.formatMessage({id:item.key})}
                        </Popover>
                    </li>
                })}
            </ul>
    </Container>
}

export default injectIntl(Nav);