import React,{Component} from 'react'
import './MakePlan.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'


class MakePlan extends Component{
    render(){
        return(
            <div>
                <Header/>
                <div className="MakePlan_body">
                    <div className="MakePlan_contentBox">
                        <div>
                        <div className="MakePlan_head_titel">销售可定制商品</div>
                        <div className="MakePlan_head_titel_content">允许买家通过注册亚马逊制定计划,通过商品详情页面个性化和定制商品。<span>详细了解亚马逊定制计划。</span></div>
                        </div>
                        <div>
                            <div className="MakePlan_head_titelII">福利</div>
                            <div className="MakePlan_head_titelII_ul">
                            <ul>
                                <li><span>卖家可以向您的商品添加文本.</span></li>
                                <li><span>买家可以上传自己的图片</span></li>
                                <li><span>卖家可以从一系列下拉选项进行选择,以构建自己的商品</span></li>
                                <li><span>没有额外的卖家费用.亚马逊定制计划包含在您现有的专业销售账户中.</span></li>
                                <li><span>在美国、英国、德国、法国、意大利和西班牙，几乎所有亚马逊类别都支持亚马逊定制计划。立即免费注册以解锁这些福利</span></li>
                            </ul>
                            </div>
                            <div className="MakePlan_bottom">
                                <input type="checkbox" />
                                我已阅读并接受<span>亚马逊制定计划商品退货政策</span>和<span>亚马逊指定条款和条件。</span>
                                <div><button>注册</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}
export default MakePlan