import Header from "components/home_header";
import {
  AButton,
  ASelect,
  AInputNumber,
  AInput,
  ADatePicker,
  ACheckbox,
  ARadio,
  ARadioGroup,
  AReactQuill,
} from "@a-components/";
import { Row, Col } from "antd";

import CardCollapsible from "./../children/CardCollapsible";
import { useMemo, useCallback, useEffect } from "react";

import MessageBox from "./../children/MessageBox";
import Description from "./../children/Description";
import classNames from "classnames";
import {arrayFlat} from "@/utils/utils";

import Apis from "@api/apis";

import { useKeep, useEventCallback } from "@hooks/";

const discountCodeOptions = [
  {
    label: "一次性",
    value: 1,
  },
  {
    label: "无限制",
    value: 2,
  },
  {
    label: "无",
    value: 3,
  },
];

const discountCodeCombinationTypeOptions = [
  {
    label: "优先型优惠码",
    value: 1,
  },
  {
    label: "无限制型优惠码",
    value: 2,
  },
];
let discountCodess = ""
const moreOptions = [
  {
    label: "优惠码",
    key: "discountCodeType",
    content: [
      {
        label: "每位买家只能使用一次优惠码",
        key: "discountCodeUseFlag",
        render(value, { record, setState, store: { showTypeSelector } }) {
          return showTypeSelector({
            edit: () => (
              <ACheckbox
                disabled={record.discountCodeType === 3}
                value={value}
                onChange={(e) =>
                  setState({ discountCodeUseFlag: e.target.checked })
                }
              />
            ),
            preview: () => (value ? "是" : "否"),
            modify: () => (value ? "是" : "否"),
          });
        },
      },
      {
        label: "优惠码",
        key: "discountCode",
        render(
          value,
          { record, setState, store: { showTypeSelector, getDiscountCode } }
        ) {
          const isDisabled = record.discountCodeType !== 2;
          return (
            <Row>
              <Col span={4}>
                {showTypeSelector({
                  edit: () => (
                    <AInput
                      disabled={isDisabled}
                      value={value}
                      onChange={(e) =>
                        setState({ discountCode: e.target.value })
                      }
                      rlues={[]}
                    ></AInput>
                  ),
                  preview: () => value,
                })}
              </Col>
              <Col offset={1}>
                {showTypeSelector({
                  edit: () => (
                    <AButton disabled={isDisabled} onClick={getDiscountCode}>
                      推荐一个优惠码
                    </AButton>
                  ),
                  preview: null,
                })}
              </Col>
            </Row>
          );
        },
      },
      {
        label: "折扣码组合类型",
        key: "discountCombinationType",
        render(value, { setState, store: { showTypeSelector } }) {
          return (
            <Row>
              <Col>
                {showTypeSelector({
                  edit: () => (
                    <ARadioGroup
                      className="df"
                      options={discountCodeCombinationTypeOptions}
                      value={value}
                      onChange={(e) =>
                        setState({ discountCombinationType: e.target.value })
                      }
                    ></ARadioGroup>
                  ),
                  preview: () =>
                    discountCodeCombinationTypeOptions.find(
                      (v) => v.value === value
                    ).label,
                  modify: () =>
                    discountCodeCombinationTypeOptions.find(
                      (v) => v.value === value
                    ).label,
                })}
              </Col>
            </Row>
          );
        },
      },
    ],
    render(
      value,
      { record, setState, store: { showTypeSelector, operatorType } }
    ) {
      if (operatorType === "modify") return null;
      return (
        <Row>
          <Col className="stepGuidanceBox88" span={8}>
            {showTypeSelector({
              edit: () => (
                <ARadioGroup
                  className="df"
                  options={discountCodeOptions}
                  value={value}
                  onChange={(e) => {
                    const { value } = e.target;
                    setState({
                      discountCodeType: value,
                      goodsDetailsTextFlag: value === 1 ? false : true,
                      discountCode: value !== 2 ? "" : discountCodess
                      // value === 1 ? false : record.goodsDetailsTextFlag,
                    });
                  }}
                ></ARadioGroup>
              ),
              preview: () =>
                discountCodeOptions.find((v) => v.value === value).label,
            })}
          </Col>
          <Col offset={1}>
            {showTypeSelector({
              edit: () => <MessageBox message="无限制促销代码没有数量限制。" />,
            })}
          </Col>
        </Row>
      );
    },
  },
  {
    label: "自定义信息",
    content: [
      {
        label: "结算显示文本",
        key: "settlementDisplayText",
        render(value, { setState, store: { showTypeSelector } }) {
          return (
            <Row>
              <Col span={4}>
                {showTypeSelector({
                  edit: () => (
                    <><AInput
                      value={value}
                      onChange={(e) =>
                        setState({ settlementDisplayText: e.target.value })
                      }
                    ></AInput>
                      {/* {!value ? <p>请输入文本</p> : null} */}
                    </>
                  ),
                  preview: () => value,
                })}
              </Col>
            </Row>
          );
        },
      },
      {
        label: "短显示文本",
        key: "shortDisplayText",
        render(value, { setState, store: { showTypeSelector } }) {
          return (
            <Row>
              <Col span={4}>
                {showTypeSelector({
                  edit: () => (
                    <AInput
                      value={value}
                      onChange={(e) =>
                        setState({ shortDisplayText: e.target.value })
                      }
                    ></AInput>
                  ),
                  preview: () => value,
                })}
              </Col>
            </Row>
          );
        },
      },
      {
        label: "商品详情页面显示文本",
        key: "goodsDetailsTextFlag",
        render(value = true, { record, setState, store: { showTypeSelector } }) {
          return (
            <Row>
              <Col span={4}>
                {showTypeSelector({
                  edit: () => (
                    <ACheckbox
                      disabled={record.discountCodeType === 1}
                      checked={value}
                      onChange={(e) =>
                        setState({ goodsDetailsTextFlag: e.target.checked })
                      }
                    />
                  ),
                  preview: () => (value ? "是" : "否"),
                })}
              </Col>
            </Row>
          );
        },
      },

      {
        label: "须购买商品显示文本",
        key: "purchaseDisplayText",
        $visible(_, { record }) {
          return record.goodsDetailsTextFlag;
        },
        render(value, { setState, store: { showTypeSelector } }) {
          console.log(value, "record")
          return (
            <Row>
              <Col span={4}>
                {showTypeSelector({
                  edit: () => (
                    <AInput
                      value={value}
                      onChange={(e) =>
                        setState({ purchaseDisplayText: e.target.value })
                      }

                    />
                  ),
                  preview: () => value,
                })}
              </Col>
            </Row>
          );
        },
      },
      {
        label: "商品详情页面显示文本",
        key: "goodsDetailsTextType",
        $visible(_, { record }) {
          return record.goodsDetailsTextFlag;
        },
        render(value, { setState, record, store: { showTypeSelector } }) {
          const textList = [
            record.goodsDetailsTextFirst,
            record.goodsDetailsTextSecond,
          ].filter(Boolean);
          return showTypeSelector({
            edit: () => (
              <ARadioGroup
                className="w-100percent"
                value={value}
                onChange={(e) =>
                  setState({ goodsDetailsTextType: e.target.value })
                }
              >
                <ARadio value={1}>标准文本</ARadio>
                {textList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="p-12 border-ccc bg-f8 c-999 fs-12 border-radius-4 mt-6"
                    >
                      {item}
                    </div>
                  );
                })}
                <ARadio className="mt-12" value={2}>
                  自定义
                </ARadio>
                <AInput
                  className="mt-6"
                  value={record.customGoodsDetailsText}
                  onChange={(e) =>
                    setState({ customGoodsDetailsText: e.target.value })
                  }
                ></AInput>
              </ARadioGroup>
            ),
            preview: () =>
              value === 1
                ? record.goodsDetailsTextFirst
                : record.customGoodsDetailsText,
          });
        },
      },

      {
        label: "显示优先级",
        key: "displayPriority",
        $visible(_, { record }) {
          return record.goodsDetailsTextFlag;
        },
        render(value, { setState, store: { showTypeSelector } }) {
          return (
            <Row>
              <Col span={4}>
                {showTypeSelector({
                  edit: () => (
                    <ASelect
                      options={Array(99)
                        .fill(null)
                        .map((_, i) => ({ label: i + 1, value: i + 1 }))}
                      className="w-100percent"
                      value={value}
                      onChange={(value) => setState({ displayPriority: value })}
                    ></ASelect>
                  ),
                  preview: () => value,
                })}
              </Col>
            </Row>
          );
        },
      },

      {
        label: "条款和条件",
        key: "termsConditions",
        render(value, { setState, store: { showTypeSelector } }) {
          return (
            <div style={{ height: 440 }}>
              {showTypeSelector({
                edit: () => (
                  <AReactQuill
                    style={{ height: 400 }}
                    value={value}
                    onChange={(value) => setState({ termsConditions: value })}
                  />
                ),
                preview: () => (
                  <AReactQuill
                    style={{ height: 400 }}
                    modules={{ toolbar: false }}
                    value={value}
                    readOnly
                  />
                ),
              })}

            </div>
          );
        },
      },
    ],
  },
];

const PromotionMoreCard = ({
  value,
  onChange,
  className,
  showType = "edit",
  operatorType,
}) => {
  const showTypeSelector = useCallback(
    (arg) => {
      const _showType =
        operatorType === "modify" && showType === "edit" ? "modify" : showType;

      let value = arg[_showType] || arg[showType];

      if (typeof value === "function") {
        return value();
      }

      return value;
    },
    [operatorType, showType]
  );

  const keepChange = useEventCallback(onChange, []);
  const innerValue = useKeep(value);

  const getDiscountCode = useCallback(() => {
    return Apis.promotion.getDiscountCode().then((res) => {
      if (res.code !== 1) return res;
      discountCodess = res.data
      keepChange?.({
        ...innerValue.current,
        discountCode: res.data,
      });
    });
  }, [keepChange, innerValue]);
  useEffect(() => {
    getDiscountCode();
  }, [getDiscountCode]);

  const store = useMemo(() => {
    return {
      showTypeSelector,
      getDiscountCode,
      operatorType,
    };
  }, [getDiscountCode, operatorType, showTypeSelector]);

  const innerOptions = useMemo(() => {
    if (showType === "edit") return moreOptions;
    return moreOptions
      .map((item) => {
        if (item.content?.length) {
          return item.render
            ? [{ ...item, content: undefined }, ...item.content]
            : item.content;
        }

        return item;
      })
      .flat()
    
  }, [showType]);

  return (
    <CardCollapsible
      title="第3步:更多选项"
      className={classNames("mt-12 promotion-more__card", className)}
    >
      <Description
        bordered
        column={1}
        labelStyle={{ width: 240, textAlign: "right" }}
        options={innerOptions}
        dataSource={value}
        store={store}
        onChange={onChange}
      ></Description>
    </CardCollapsible>
  );
};

export default PromotionMoreCard;
