import React, { useRef, useEffect, useCallback, useState } from 'react';
import './completeValidation.less';
import Header from 'components/home_header';
import Footer from 'components/addlisting_footer/FooterCom';
import { Button, Collapse, Space, Radio, Input } from 'antd';
import RegisterSteps from 'components/registerSteps';
import RegisterCollapse from 'components/registerCollapse';
import { regSubmit } from 'utils/PublicLibrary';

export default (props) => {
    const [value, setValue] = useState(1);
    if (sessionStorage.getItem('regInfo')) {
        var obj = sessionStorage.getItem('regInfo');
        obj = JSON.parse(obj);
    }
    let nextEvent = () => {
        sessionStorage.removeItem("regInfo");
        // props.history.push('/')
        regSubmit([], null, 'completeValidation', 9).then(res => {
            if (res.code == 1) {
                props.history.push('/videoAuthentication/MeetingTime')
            }
        })

    }
    const collapseList = [
        {
            header: '为什么需要验证？',
            html: '<p>为了保护买家和卖家在我们商城的权益，我们会验证公司和个人的信息。</p>'
        },
        {
            header: '我如何知道我的验证状态？',
            html: '<p>我们将发送电子邮件至您注册的地址，告知您的验证状态。</p> '
        },
        {
            header: '验证期间会发生什么？',
            html: '<p>我们的代表将与您会面，审核您上传文件的原件，并审核您在注册期间提交的信息。</p>'
        },
        {
            header: '需要为会面做哪些准备？',
            html: '<p>预约成功后，请您在预约时间的24小时之前查看注册电子邮箱以获取关于预约当天的流程说明和如何准备的建议。</p>'
        },
    ]


    const onChange = (e) => {
        setValue(e.target.value);
    };
    return (
        <div className="agreementsBox completeValidationBox register_style">
            <Header />
            <div style={{ display: 'flex' }}>
                <div className="a-section program-overview-values">
                    <RegisterSteps current={5} />
                    <h3>身份验证</h3>
                    <div className='vi-divider'></div>
                    <h5>请选择一个可用选项来联系亚马逊员工完成验证（您所在地可能只支持部分验证方式）。</h5>
                    <div className='Group'>
                        <Radio.Group onChange={onChange} value={value}>
                            <Space direction="vertical">
                                <Radio value={1}>
                                    <p>实时视频通话</p>
                                    <div style={{fontSize: '12px'}}>您可以于选定的日期和时间在任何地点加入视频电话来进行视频审核。另外，我们将向您的注册地址邮寄包含一次性密码和相关说明的明信片来指导您进行地址验证。</div>
                                </Radio>
                                <Radio disabled value={2}>
                                    <p>在您的公司进行验证（目前您所在地不支持此方式）</p>
                                    <div style={{fontSize: '12px'}}>一位亚马逊工作人员将前往您的公司地址</div>
                                </Radio>
                                <Radio disabled value={3}>
                                    <p>在当地的亚马逊办公室会面（目前您所在地不支持此方式）</p>
                                    <div style={{fontSize: '12px'}}>您可以前往您所在城市的亚马逊办公室，与我们的工作人员会面，完成验证。</div>
                                </Radio>
                            </Space>
                        </Radio.Group>
                    </div>
                    <div className="operationBtn" style={{ textAlign: 'center' }}>
                        <Button className='next' onClick={nextEvent} type="primary">下一步</Button>
                    </div>
                </div>
                <div className="rightCollapse">
                    <p >常见问题</p>
                    <RegisterCollapse list={collapseList} />
                </div>
            </div>
            <Footer />
        </div>
    )
}