import Apis from "@api/apis";
import { AButton } from "@a-components";
import { Link } from "react-router-dom";
import { uglifyValue } from "@utils/utils";
const columns = [
  {
    title: "广告组合",
    dataIndex: "advertisingCombinationName",
    key: "advertisingCombinationName",
    render(value, record) {
      const str = uglifyValue({
        advertisingCombinationId: record.advertisingCombinationId,
      });

      return <Link to={`/Advertisement?tab=${str}`}>{value}</Link>;
    },
  },
  {
    title: "状态",
    dataIndex: "statusName",
    key: "2",
  },
  {
    title: "预算",
    dataIndex: "budgetAmount",
    key: "3",
  },
  {
    title: "预算开始日期",
    dataIndex: "budgetStartDate",
    key: "4",
  },
  {
    title: "预算结束日期",
    dataIndex: "budgetEndDate",
    key: "5",
  },
  {
    title: "曝光量",
    dataIndex: "exposure",
    key: "7",
  },
  {
    title: "点击次数",
    dataIndex: "clicksNum",
    key: "9",
  },
  {
    title: "点击率(CTR)",
    dataIndex: "clicksRate",
    key: "8",
  },
  {
    title: "花费",
    dataIndex: "costPrice",
    key: "9",
  },
  {
    title: "每次点击费用(CPC)",
    dataIndex: "singleCostPrice",
    key: "10",
  },
  {
    title: "订单",
    dataIndex: "orderNumber",
    key: "11",
  },
  {
    title: "销售额",
    dataIndex: "salesPrice",
    key: "12",
  },
  {
    title: "ACOS",
    dataIndex: "acosRate",
    key: "13",
  },
  {
    title: "ROAS",
    dataIndex: "roasRate",
    key: "14",
  },
  {
    title: "新买家订单数",
    dataIndex: "newBuyerOrdersNum",
    key: "15",
  },
  {
    title: "新买家订单比",
    dataIndex: "newBuyerOrderRatio",
    key: "16",
  },
  {
    title: "新买家销量",
    dataIndex: "newBuyerSales",
    key: "17",
  },
  {
    title: "新买家销量比",
    dataIndex: "newBuyerSalesRatio",
    key: "18",
  },
  {
    title: "操作",
    key: "19",
    render() {
      return (
        <AButton type="link" className="fs-12 h-12 p-0">
          修改
        </AButton>
      );
    },
  },
];
const filterConditionOptions = [
  {
    label: "筛选条件",
    value: "筛选条件",
  },
  {
    label: "活跃状态",
    value: "活跃状态",
  },
  {
    label: "类型",
    value: "类型",
  },
  {
    label: "投放",
    value: "投放",
  },
  {
    label: "预算",
    value: "预算",
  },
  {
    label: "曝光量",
    value: "曝光量",
  },
  {
    label: "点击次数",
    value: "点击次数",
  },
  {
    label: "点击率（CTR）",
    value: "点击率（CTR）",
  },
  {
    label: "花费",
    value: "花费",
  },
  {
    label: "每次点击费用(CPC)",
    value: "每次点击费用(CPC)",
  },
  {
    label: "订单",
    value: "订单",
  },
  {
    label: "销售额",
    value: "销售额",
  },
  {
    label: "广告投入产出比(ACOS)",
    value: "广告投入产出比(ACOS)",
  },
  {
    label: "广告支出回报(ROAS)",
    value: "广告支出回报(ROAS)",
  },
  {
    label: "新买家订单数",
    value: "新买家订单数",
  },
  {
    label: "新买家订单比",
    value: "新买家订单比",
  },
  {
    label: "新买家销量",
    value: "新买家销量",
  },
  {
    label: "新买家销量比",
    value: "新买家销量比",
  },
  {
    label: "几乎或超出预算",
    value: "几乎或超出预算",
  },
  {
    label: "广告组合",
    value: "广告组合",
  },
];
const adCombinationConfig = {
  createBtn: ({ event$ }) => {
    return {
      children: "Create portfolio",
      onClick() {
        event$.emit({ type: "showCreateAdGroupModal" });
      },
    };
  },
  searchInput: {
    placeholder: "查找广告组合",
  },
  tableColumns: columns,
  tableDataKey: "advertisingCombinationId",
  filterConditionOptions,
  queryFetcher({ searchValue, businessId, ...otherParams }) {
    return Apis.advertisingCombination.queryAdvertisingCombinationList({
      ...otherParams,
      advertisingCombinationName: searchValue,
    });
  },

  $dependencies: [],
};

export default adCombinationConfig;
