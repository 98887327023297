import { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { AText, ARadioGroup, AInput, AButton, ATableForm } from "@a-components";
import style from "./style.module.css";
import { CloseOutlined } from "@ant-design/icons";
import {
  negateKeywordMatchTypeOptions,
  negateKeywordMatchTypeMapping,
  computedNegateKeywordId,
} from "./config";

const tooltipReactNode = (
  <>
    匹配类型让您能够微调哪些顾客搜索内容会触发您的广告。 <br />
    <AText strong>广泛匹配：</AText>
    包含以任何顺序排列的所有关键词，包括复数、变体和相关关键词。
    <br />
    <AText strong>词组匹配：</AText>包含确切的词组或词序相同的关键词。
    <br />
    <AText strong>精准匹配：</AText>与相应关键词或关键词的词序完全匹配。
  </>
);

const columns = [
  {
    title: "关键词",
    name: "keywords",
    width: "55%",
  },
  {
    title: "匹配类型",
    width: "30%",
    titleTooltip: tooltipReactNode,
    name: "matchTypeName",
  },
  {
    title: " ",
    render(_, { remove }) {
      return <CloseOutlined onClick={remove} />;
    },
  },
];

const NegativeKeywordsSelector = ({
  selectedNegateKeywordList = [],
  updateNegateKeywordsList,
}) => {
  const [keywords, setKeywords] = useState("");
  const [matchType, setMatchType] = useState(1);
  const [tipsInfo, setTipsInfo] = useState({
    isShow: false,
    errorMsg: "",
    type: "", // warning | danger
  });

  const keywordsArr = useMemo(() => {
    return Array.from(new Set(keywords.split("\n").filter(Boolean)));
  }, [keywords]);

  const addSelectedNegateKeywordList = useCallback(
    (values) => {
      values = [].concat(values);

      const successList = [];
      const failList = [];

      const selectedIds = selectedNegateKeywordList.map((item) => item.$id);
      values.forEach((item) => {
        if (selectedIds.includes(item.$id)) {
          failList.push(item);
        } else {
          successList.push(item);
        }
      });
      updateNegateKeywordsList?.([
        ...successList,
        ...selectedNegateKeywordList,
      ]);

      return {
        allSuccess: failList.length === 0,
        successList: successList,
        failList,
      };
    },
    [selectedNegateKeywordList, updateNegateKeywordsList]
  );

  const computedTipsInfo = useCallback((info) => {
    if (!info) return;
    const { allSuccess, successList, failList } = info;

    const failLength = failList.length;
    const successLength = successList.length;
    const totalLength = successLength + failLength;

    const str = `${successLength}/${totalLength} 已成功添加关键词。`;

    if (allSuccess) {
      return setTipsInfo({
        isShow: true,
        type: "success",
        errorMsg: str,
      });
    }

    if (successList.length) {
      return setTipsInfo({
        isShow: true,
        type: "warning",
        errorMsg: str,
      });
    }

    return setTipsInfo({
      isShow: true,
      type: "danger",
      errorMsg: str,
    });
  }, []);

  const handleAdd = useCallback(() => {
    const list = keywordsArr.map((keywords) => {
      return {
        $id: computedNegateKeywordId({ keywords, matchType }),
        keywords,
        matchType,
        matchTypeName: negateKeywordMatchTypeMapping[matchType],
      };
    });

    const returnValue = addSelectedNegateKeywordList(list);
    const { successList } = returnValue;
    computedTipsInfo(returnValue);
    setKeywords(
      keywordsArr
        .filter((keywords) => {
          return !successList.find((item) => item.keywords === keywords);
        })
        .join("\n")
    );
  }, [addSelectedNegateKeywordList, computedTipsInfo, keywordsArr, matchType]);

  const handleRemoveAll = useCallback(() => {
    updateNegateKeywordsList([]);
  }, [updateNegateKeywordsList]);

  return (
    <div className={classNames(style.container, "df")}>
      <div className="f-1 pl-12 pr-12 border-r-ccc">
        <div className="h-44 df ai-c">
          <AText strong tooltip={tooltipReactNode}>
            匹配类型
          </AText>
          <ARadioGroup
            value={matchType}
            onChange={(e) => setMatchType(e.target.value)}
            className="df ml-24"
            options={negateKeywordMatchTypeOptions}
          />
        </div>

        <AInput.TextArea
          rows={10}
          value={keywords}
          onChange={(e) => setKeywords(e.target.value)}
        ></AInput.TextArea>
        <div className="df jc-sb mt-12">
          <AText type={tipsInfo.type}>
            {tipsInfo.isShow ? tipsInfo.errorMsg : ""}
          </AText>
          <AButton disabled={!keywordsArr.length} onClick={handleAdd}>
            添加关键词
          </AButton>
        </div>
      </div>
      <div className="f-1 bg-f7">
        <div className="df jc-sb pl-12 pr-12 ai-c h-44 border-b-ccc">
          <AText strong>已添加{selectedNegateKeywordList.length}个</AText>

          <AButton
            type="link"
            disabled={!selectedNegateKeywordList.length}
            onClick={handleRemoveAll}
          >
            全部删除
          </AButton>
        </div>
        <ATableForm
          height={596}
          wrapperBorder={false}
          dataSource={selectedNegateKeywordList}
          onChange={updateNegateKeywordsList}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default NegativeKeywordsSelector;
