import React, { useRef, useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from "components/home_header";
import Footer from "components/addlisting_footer/FooterCom";
import "./SetDepositMethod.css"
import AJAX from "api/apis";
import { Button, Select, Input, Form, Space, Checkbox, Radio, Modal, Col, Row } from 'antd';
import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"
import { UpOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { getSellerNationality } from 'utils/regDate';
import getUrlData from "@/utils/getUrlData";

function SetDepositMethod(props) {
  let formRef = useRef()
  const [addressM, setAddressM] = useState(getUrlData('addressM') || 'Amazon.com');
  const [pageForm, setPageForm] = useState(1);
  const [type, setType] = useState('1');
  const [list, setList] = useState([]);
  const [value, setValue] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activation, setActivation] = useState({});
  const [activateFlag, setActivateFlag] = useState(false);
  const [verificationBankAccount, setVerificationBankAccount] = useState()
  const [bankAccount, setBankAccount] = useState()
  const [deposit, setDeposit] = useState(null)
  const [loading, setLoading] = useState(false);
  const countrylity = getSellerNationality()
  const steps = useCallback(() => {
    let arr = [
      {
        selector: '.stepGuidanceBox0',
        content: '点击激活存款账户',
      },
      {
        selector: '.stepGuidanceBox2',
        content: '点击设置存款方式',
      },
    ];
    return !activateFlag ? [arr[1]] : arr
  }, [activateFlag])
  useEffect(() => {
    getStoreDepositOption()
    // console.log(window.location);
    let type = getUrlData('type')
    setType(type)
    if (type == '2') setPageForm(2)
    if (type == '3') {
      getStoreShoppingDeposit()
    }
  }, [])
  const getStoreDepositOption = useCallback(() => {
    AJAX.store.getStoreDepositOption().then((res) => {
      if (res.code == 1) {
        setList(res.data)
        let index = ''
        if (res.data.length) {
          let ele = res.data.find(i => i.defaultFlag == true)
          index = ele?.storeDepositId
          let ac = res.data.find(i => i.activateFlag == true)
          if (ac) {
            setActivateFlag(false)
          } else {
            setActivateFlag(true)
          }
        }
        setValue(index || res.data[0]?.storeDepositId)
      }
    })
  }, [])
  const getStoreShoppingDeposit = () => {
    AJAX.store.getStoreShoppingDeposit({ storeDepositRelId: getUrlData('storeDepositRelId') }).then(res => {
      if (res.code == 1) {
        setDeposit(res.data)
      }
    })
  }
  const sbu = useCallback(() => {
    if (pageForm == 2) {
      add()
    } else {
      setDef(value)
    }
  }, [pageForm, addressM, value])
  const add = useCallback(() => {
    formRef.current.validateFields().then((res) => {
      if (loading) return
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 3500)
      let params = {
        defaultFlag: res.defaultFlag || false,
        depositBankNumber: res.depositBankNumber,
        depositPersonName: res.depositPersonName,
        depositRemittanceNumber: res.depositRemittanceNumber,
        depositState: res.depositState,
        storeAreaId: getUrlData('storeAreaId'),
        // storeDepositRelId: getUrlData('storeDepositRelId'),
      }
      AJAX.store.addStoreDeposit(params).then((res) => {
        if (res.code == 1) {
          getStoreDepositOption()
          setPageForm(1)
        }
      })
    }).catch((err) => { })
  }, [loading]);
  const setDef = async (value) => {
    const ress = await AJAX.login.getStoreRegisterProgress()
    const { isDeposit, isTaxation } = ress.data
    let params = {
      "storeAreaId": type == '3' ? deposit.storeAreaId : getUrlData('storeAreaId'),
      "storeDepositId": value,
      "storeDepositRelId": getUrlData('storeDepositRelId') || ''
    }
    AJAX.store.setStoreDeposit({ params, isShow: isTaxation ? 1 : 0 }).then((res) => {
      if (res.code == 1) {
        getStoreDepositOption()
        props.history.push('/accountinfo/depositMethod')
      }
    })
  }
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const handleOk = () => {

    let param = {
      "storeAreaId": getUrlData('storeAreaId'),
      "storeDepositId": activation.storeDepositId
    }
    AJAX.store.activationStoreDeposit(param).then(res => {
      if (res.code == 1) {
        getStoreDepositOption()
        setIsModalVisible(false);
      }
    })
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const openModal = (value) => {
    setIsModalVisible(true);
    setActivation(value)
  }
  const verificationBankAccountChange = useCallback((e) => {
    setVerificationBankAccount(e.target.value)
  }, [bankAccount, verificationBankAccount])
  const bankAccountChange = useCallback((e) => {
    setBankAccount(e.target.value)
  }, [verificationBankAccount, bankAccount])
  const [isActive, setIsActive] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(true);
  const awakenTips = (type) => {
    setIsActive(type);
  }

  return (
    <React.Fragment>
      <Header />
      {
        pageForm != 2 ? (
          <React.Fragment>
            <TipsIcon clicks={awakenTips} />
            <Reactours routePath="setdepositMethod" isActive={isActive} clicks={awakenTips} steps={steps()} isOpen={isTourOpen} />
          </React.Fragment>
        ) : null
      }
      <div className="setdeposit_Method" >
        <div className="setdeposit_Method_inner" >
          <div className="deposit_Method" >
            <h2>
              <span>存款方式</span>
              <Link to="/accountinfo" >账户信息</Link>
            </h2>
            <div className='deposit_Method_set' >
              {
                type != 3 ? '分配默认存款方式' : '替换默认存款方式'
              }

            </div>
            <div className="deposit_Method_body" >
              <div className="deposit_Method_body_right" >
                <p>用于亚马逊商城</p>
                <div>
                  <img src={require(`@assets/images/${addressM}.png`).default} />
                  <span>{addressM}</span>
                </div>
              </div>
            </div>
            <div className="setdeposit_Method_bank" >
              <h3>银行账户</h3>
              {
                pageForm == 1 ? (
                  <div className='setdeposit_Method_bank_title'>
                    <div><ExclamationCircleFilled style={{ color: 'rgb(17,150,171)' }} />选择您要分配的存款方式。</div>
                    <div className="setdeposit_Method_bank_addText" onClick={() => {
                      setPageForm(2)
                    }} >添加新的存款方式</div>
                  </div>
                ) : (
                  <div className='setdeposit_Method_bank_title'>
                    <div>添加新的存款方式</div>
                    <div className="setdeposit_Method_bank_addText" onClick={() => {
                      setPageForm(1)
                    }} >选择现有存款方式</div>
                  </div>
                )
              }

              {
                pageForm == 1 ? (
                  <div className='setdeposit_Method_bank_Radio'>
                    <Radio.Group onChange={onChange} value={value}>
                      <Space direction="vertical">
                        {
                          list.map(item => {
                            return <Radio key={item.storeDepositId} value={item.storeDepositId} >
                              <div className='item'>
                                <div>结束 <span style={{ fontWeight: 700 }} >{item.depositBankNumber.substring(item.depositBankNumber.length - 3)}</span></div>
                                <div>{item.depositState}</div>
                                <div>
                                  <span>{item.depositPersonName}</span>
                                </div>
                                <div>
                                  {
                                    item.storeDepositRelVoList.length ? (
                                      item.storeDepositRelVoList.map(v => {
                                        return (
                                          <span className='shiyongmoren' key={v.address}>
                                            <span>{v.defaultFlag ? '默认设置，' : ''}适用于</span>
                                            <span>{v.address}</span>
                                          </span>
                                        )
                                      })
                                    )
                                      : null
                                  }
                                </div>
                                {
                                  activateFlag && <div className='stepGuidanceBox0'><a onClick={() => { openModal(item) }}>激活，以用于{item.address || addressM}</a></div>
                                }
                              </div>
                            </Radio>
                          })
                        }
                      </Space>
                    </Radio.Group>
                  </div>
                ) : null
              }
              {
                pageForm == 2 ? (
                  <Form layout="vertical" name="complex-form" ref={formRef} >
                    <Form.Item label="银行所在地">
                      <Space>
                        <Form.Item
                          name="depositState"
                          noStyle
                          rules={[{ required: true, message: <span><ExclamationCircleFilled color='red' /> 必填字段</span> }]}
                          initialValue='中国'
                        >
                          <Select style={{ width: 330, marginRight: 20 }} showArrow={false} placeholder="选择国家/地区" options={countrylity} />
                        </Form.Item>
                        <span><b><ExclamationCircleFilled style={{ color: 'rgb(17,150,171)' }} /></b> 您的银行可能会对支付收取费用</span>
                      </Space>

                    </Form.Item>
                    <strong>银行账户必须由银行开立。或者由已加入支付服务商计划的支付服务商管理。</strong>
                    <Form.Item
                      label="账户持有人姓名"
                      name="depositPersonName"
                      rules={[
                        // { required: true, message: <span><ExclamationCircleFilled color='red' /> 必填字段</span> }
                        {
                          required: true,
                          validator: (rule, value, fun) => {
                            let reg = /^[ a-zA-Zu4e00-u9fa5]+$/;
                            if (!value) {
                              fun('请输入英文')
                            } else if (!reg.test(value)) {
                              fun('持有人姓名验证失败')
                            }
                            fun()
                          }
                        }
                      ]}
                    >
                      <Input maxLength={20} placeholder="姓名应与银行证件.上的相同" />
                    </Form.Item>
                    <Form.Item
                      label="9位数的银行识别代码"
                      name="depositRemittanceNumber"
                      rules={[
                        // { required: true, message: <span><ExclamationCircleFilled color='red' /> 必填字段</span> }
                        {
                          required: true,
                          validator: (rule, value, fun) => {
                            let reg = /^[0-9]*$/;
                            if (!value) {
                              fun('请输入汇款号码')
                            } else if (!reg.test(value) || value.length < 9) {
                              fun('必须是九个数字')
                            }
                            fun()
                          }
                        }
                      ]}
                    >
                      <Input placeholder="9位数" maxLength={9} />
                    </Form.Item>
                    <Form.Item
                      label="银行账号"
                      name="depositBankNumber"
                      rules={[
                        // { required: true, message: <span><ExclamationCircleFilled color='red' /> 必填字段</span> }
                        {
                          required: false,
                          validator: (rule, value, fun) => {
                            let reg = /^[0-9]*$/;
                            if (!value) {
                              fun('请输入银行账号')
                            } else if (verificationBankAccount && value != verificationBankAccount) {
                              fun('银行账号不一致')
                            } else if (!reg.test(value) || value.length < 17) {
                              fun('必须是十七位数字')
                            }
                            fun()
                          }
                        }
                      ]}
                    >
                      <Input value={bankAccount} onChange={bankAccountChange} maxLength="17" placeholder="" />
                    </Form.Item>
                    <Form.Item
                      label="重新输入银行账号"
                      name="depositBankNumber_c"
                      rules={[
                        // { required: true, message: <span><ExclamationCircleFilled color='red' /> 必填字段</span> }
                        {
                          required: false,
                          validator: (rule, value, fun) => {
                            let reg = /^[0-9]*$/;
                            if (!value) {
                              fun('请输入银行账号')
                            } else if (bankAccount && value != bankAccount) {
                              fun('银行账号不一致')
                            } else if (!reg.test(value) || value.length < 17) {
                              fun('必须是十七位数字')
                            }
                            fun()
                          }
                        }
                      ]}
                    >
                      <Input value={verificationBankAccount} onChange={verificationBankAccountChange} maxLength="17" placeholder="" />
                    </Form.Item>
                    {
                      type != 3 && (
                        <Form.Item
                          label="账户类型"
                          valuePropName="checked"
                          name="defaultFlag"
                        // rules={[
                        //   {
                        //     validator: (_, value) =>
                        //       value ? Promise.resolve() : Promise.reject(new Error('! 请确认您的选择是否正确')),
                        //   },
                        // ]}
                        >
                          <Checkbox>默认账户</Checkbox>
                        </Form.Item>
                      )
                    }

                    <div style={{ fontSize: 12, marginBottom: 20 }} >
                      我们可能会通过向您的银行或支付服务商发送您的银行账户和销售账户信息，以及从您的支付服务商处接收有关您的身份和银行账户的信息,来验证您的银行账户信息，并防止欺诈、非法活动和滥用。有关我们的支付服务商计划以及我
                      们如何管理您的个人信息的更多信息，请参阅<a>可接受的银行账户和支付服务商</a>以及我们的<a>隐私声明</a>。
                    </div>
                  </Form>
                ) : null
              }

            </div>
            {/* {
            pageForm == 2 ? (
              <div className="setdeposit_Method_bank_distinguish" >
                <div>识别您的银行账户</div>
                <div>为防止滥用您的银行账户，您需要验证来尾数字为615的账户详情，并提供完整的账号。</div>
                <Link to="#" >了解更多信息</Link>
                <strong>银行账号</strong>
                <Input placeholder="" />
              </div>
            ) : null
          } */}

            <div className="setdeposit_Method_bank_but" >
              <Button><Link to="/accountinfo/depositMethod" >取消</Link></Button>
              <Button className='stepGuidanceBox2' disabled={pageForm == 1 ? activateFlag : false} onClick={sbu}>设置存款方式</Button>
            </div>
          </div>
          <div className="setdeposit_Method_tips" >
            <div><UpOutlined />什么是支付服务商计划?</div>
            <p>支付服务商计划维护一个参加该计划的支付服务商列表. 您可以通过该列表中的支付服务商接收您的亚马逊商城销售收益。<Link to="#" >了解更多信息</Link></p>
            <div><UpOutlined />什么是参加该计划的支付服务商?</div>
            <p>
              参加支付服务商计划的支付服务商是符合该计划要求并且已加入该计划的支付服务商。常望通过支付服务商接收亚马逊商城销售
              收益的卖家必须选择一个参加该计划的支付服务商。可在<Link to="#" >此处</Link>查看参加该计划的支付服务商的完整列表和其他可接受的收款选项。
            </p>
            <div><UpOutlined />如何从参加支付服务商计划的支付服务商那里获取银行账户?</div>
            <p>
              可先在<Link to="#" >此处</Link>选择一个参加该计划的支付服务商, 然后访问该支付服务商的网站，按照相关说明申请-个支持您所需收款币种的银行账户。
            </p>
          </div>
        </div>


        <Modal
          title={`为${addressM} 激活存款方式`}
          className='ymx-modal setdeposit_Method_modal'
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={(<div className="footer" style={{ textAlign: 'right' }}>
            <Button onClick={handleCancel} className='previousPage'>
              取消
            </Button>
            <Button onClick={handleOk} className='sbu' type="primary">
              激活
            </Button>
          </div>)}
        >
          <p>您想要为{addressM} 激活以下账户吗?激活此账户后，您将可以在{addressM} 使用该账户，且不受三天保留期限制。</p>
          <div>注意:您在{addressM} 商城中总共可以有2个有效存款方式(包括您的默认存款方式)。</div>
          <p>{activation.depositPersonName}</p>
          <p>结束 <strong style={{ fontSize: 15 }} >{activation.depositBankNumber && activation.depositBankNumber.substring(activation.depositBankNumber.length - 3)}</strong></p>
          <p>{activation.depositState}</p>
        </Modal>
      </div>
      <Footer />
    </React.Fragment>

  )
}

export default SetDepositMethod