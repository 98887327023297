import { useCallback, useEffect, useMemo, useState } from "react";
import { AButton, AText } from "@a-components/";
import { Spin } from "antd";

import classNames from "classnames";

import Apis from "@api/apis";
import { useHistory } from "react-router";

const AdvertisingCombinationCard = ({
  advertisingCombinationId,
  className,
}) => {
  const history = useHistory();
  const [adCombinationInfo, setAdCombinationInfo] = useState({
    advertisingCombinationName: "--",
    budgetLimitName: "--",
    budgetLimit: 0,
    statusName: "--",
  });
  const [isLoading, setLoadingStatus] = useState(false);

  const getAdvertisingCombinationHead = useCallback(() => {
    setLoadingStatus(true);
    return Apis.advertisingCombination
      .getAdvertisingCombinationHead({
        advertisingCombinationId,
      })
      .then((res) => {
        if (res.code === 1) {
          setAdCombinationInfo(res.data);
        }
      })
      .finally(() => setLoadingStatus(false));
  }, [advertisingCombinationId]);

  useEffect(() => {
    getAdvertisingCombinationHead();
  }, [getAdvertisingCombinationHead]);

  const {
    advertisingCombinationName,
    budgetLimit,
    budgetStartDate,
    budgetEndDate,
    budgetAmount,
    endSign,
    statusName,
  } = adCombinationInfo;

  const handleModifyAdCombination = useCallback(() => {
    history.push(
      `/Advertisement/ModifyAdCombination?advertisingCombinationId=${advertisingCombinationId}`
    );
  }, [advertisingCombinationId, history]);

  const realLimitText = useMemo(() => {
    const mapping = {
      0: () => "--",
      1: () => "无预算上限",
      2: () => {
        return `${
          budgetAmount ? `$${budgetAmount.toFixed(2)}` : ""
        } 总支出，从${budgetStartDate}
          ${budgetEndDate ? ` 起止 日期${budgetEndDate}` : "开始"}`;
      },
      3: () => {
        const isDisabled = endSign === 1;
        return `${budgetAmount ? `$${budgetAmount.toFixed(2)}每月` : ""} ${
          isDisabled ? "" : `，于 ${budgetEndDate} 结束`
        }`;
      },
    };
    return mapping[budgetLimit || 0]();
  }, [budgetAmount, budgetEndDate, budgetLimit, budgetStartDate, endSign]);

  return (
    <Spin spinning={isLoading}>
      <div className={classNames("border-ccc bg-fb p-18", className)}>
        <div className="df ai-c jc-sb">
          <AText strong className="fs-18">
            {advertisingCombinationName}
          </AText>
          <AButton type="link" onClick={handleModifyAdCombination}>
            修改广告组合
          </AButton>
        </div>
        <div>
          状态: {statusName} 预算上限：{realLimitText}
        </div>
      </div>
    </Spin>
  );
};

export default AdvertisingCombinationCard;
