import React from "react"
import AJAX from "api/apis";
import "./formData.css"
import { Button, Form, Input, Select, DatePicker, Space, Radio, Tooltip, InputNumber } from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';
import IconFont from "../../../../../components/Icon/Icon";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import locale from 'antd/lib/date-picker/locale/zh_CN'
import 'moment/locale/zh-cn'
const { Option } = Select;
let sumReg = /^[0-9]*$/
const specialReg = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im;   //特殊字符验证
const zmReg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
class formData extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        formList: [
          {
            className: "stepGuidanceBox16",
            createTime: null,
            deleteFlag: false,
            goodsClassifyId: 0,
            goodsModule: 3,
            highView: false,
            id: 16,
            inputDescription: null,
            inputIsType: null,
            inputLock: false,
            inputName: "订单商品最大数量",
            inputPlaceholder: "10.0",
            inputRequired: false,
            inputTips: "一个整数",
            inputType: "number",
            inputValue: null,
            inputValueMax: null,
            inputValueMin: null,
            rules: [],
            runSave: null,
            sort: null,
            status: false,
            updateTime: "2021-01-07 07:06:38",
          }
        ],
        formList1: [],
        NumbetTypeList: []
      },
      goodsCodeLenth: 12,
      isDisplayCode: this.props.isDisplayCode,
      isHighView: this.props.isHighView
    }
  }
  componentWillMount() {
    this.getNumbetType();
    if (this.props.id) {
      this.getBasicsKeyList(this.props.id, this.props.type, this.props.isHighView);
    }
    if (this.props.goodsId) {
      setTimeout(() => {
        this.getGoodsExtendsValue(this.props.goodsId, this.props.type, this.props.isHighView, true)
        if (this.props.type == 1) this.getGoodsExtendsValue(this.props.goodsId, 3, this.props.isHighView, false)
      }, 500)
    }
  }
  componentWillReceiveProps(nextProps) {
    let { formList, formList1 } = this.state.data
    this.setState({
      isDisplayCode: nextProps.isDisplayCode
    })
    if (this.props.isHighView != nextProps.isHighView) {
      if (this.props.goodsId) {
        setTimeout(() => {
          this.getGoodsExtendsValue(this.props.goodsId, this.props.type, this.props.isHighView, true)
        }, 500)
      } else {
        this.getBasicsKeyList(this.props.id != null ? this.props.id : this.props.classifyId, this.props.type, nextProps.isHighView);
      }
    }
    if (nextProps.isDisplayCode == false) {
      for (let items in formList) {
        if (formList[items].id == 1) formList.splice(items, 1)
        if (formList[items].id == 26) formList.splice(items, 1)
        if (formList[items].id == 27) formList.splice(items, 1)
        if (formList[items].id == 31) formList.splice(items, 1)
      }
      this.setState({
        formList: formList
      })
    } else {
      let formList2 = JSON.parse(JSON.stringify(formList1))
      this.setState({
        data: {
          ...this.state.data,
          formList: formList2
        }
      })
    }
  }
  getGoodsExtendsValue = (goodsId, goodsModule, isHighView, isShow) => {
    let { formList1 } = this.state.data;
    AJAX.addProduct.getGoodsExtendsValue({ goodsId, goodsModule, isHighView }).then((res) => {
      let objss = {};
      let content = {};
      if (res.code == 1 && res.data) {
        if (isShow) {
          let datas = res.data;
          let extend = res.extend;
          let inputPlaceholder = ""
          for (let items of datas) {
            if (items.id == 1) {
              items.rules = [{ required: items.inputRequired, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容") } else if (!sumReg.test(value)) { cbfn("只能是数字！") } cbfn() } }]
            } else if (items.id == 24) {
              items.rules = [{ required: items.inputRequired, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容") } else if (zmReg.test(value)) { cbfn("格式验证错误！") } cbfn() } }]
            } else if (items.inputType == "select" || items.inputType == "select_more") {
              items.rules = [{ required: items.inputRequired, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容") } cbfn() } }]
            } else {
              items.rules = [{ required: items.inputRequired, message: '请输入内容' }]
            }
            if ((items.inputType == "text_more" && items.goodsValue) || (items.inputType == "select_more" && items.goodsValue)) {
              let arr = items.goodsValue.split("¦");
              let arrNames = [];
              for (let arrIndex in arr) {
                if (arrIndex != 0) arrNames.push(arr[arrIndex])
              }
              objss[`${items.id}title`] = arr[0];
              objss[`${items.id}names`] = arrNames;
            } else if (items.id == 109) {
              objss.goodsNumberType = [Number(items.goodsValue)]
              inputPlaceholder = items.inputPlaceholder
            } else if (items.id == 70 || items.id == 107 || items.id == 108) {
              if (items.goodsValue) {
                let arr = items.goodsValue.split("¦")
                let matchName1 = items.id + 'more1'
                let matchName2 = items.id + 'more2'
                let matchName3 = items.id + 'more3'
                let matchName4 = items.id + 'more4'
                objss[matchName1] = arr[0]
                objss[matchName2] = arr[1]
                objss[matchName3] = arr[2]
                if (items.id == 70) objss[matchName4] = arr[3]
              }
            } else {
              objss[items.id] = items.goodsValue
            }
          }
          for (let index in datas) {
            if (datas[index].id == 1) {
              datas[index].inputPlaceholder1 = inputPlaceholder
            }
            if (datas[index].id == 23) {
              datas.splice(index, 1)
            }
          }
          this.form.setFieldsValue(objss)
          formList1 = JSON.parse(JSON.stringify(datas))
          if (this.state.isDisplayCode == false || extend.goodsIsVariant) {
            for (let items in datas) {
              if (datas[items].id == 1) datas.splice(items, 1)
              if (datas[items].id == 26) datas.splice(items, 1)
              if (datas[items].id == 27) datas.splice(items, 1)
              if (datas[items].id == 31) datas.splice(items, 1)
            }
          }
          setTimeout(() => {
            this.setState({
              data: {
                ...this.state.data,
                formList: datas,
                formList1: formList1
              }
            })
          }, 16)
          content = this.form.getFieldsValue()
        } else {
          for (let items of res.data) {
            if (items.id == 109) {
              objss.goodsNumberType = [items.goodsValue]
            } else {
              objss[items.id] = items.goodsValue
            }
          }
          for (let keys in objss) {
            if (objss[keys]) content[keys] = objss[keys]
          }
        }
        let setContent = []
        for (let items of res.data) {
          for (let contentItem in objss) {
            if (items.id == contentItem && objss[contentItem]) {
              let obj = {};
              obj.elseExtendsId = items.extendsId
              obj.goodsBasicsKeyId = items.id
              obj.goodsId = items.goodsId
              obj.isNotNull = items.isNotNull
              obj.runSave = items.runSave
              obj.goodsElseBasicsValue = objss[contentItem]
              setContent.push(obj)
            }
          }
        }
        content = setContent
        this.props.dataInfo(content, goodsModule)
      }
    })
  }
  getNumbetType = () => {
    AJAX.addProduct.getNumbetType().then((res) => {
      if (res.code == 1 && res.data) {
        this.setState({
          data: {
            ...this.state.data,
            NumbetTypeList: res.data
          }
        })
      }
    })
  }
  getBasicsKeyList = (goodsClassifyId, goodsModule, isHighView) => {
    let { formList1 } = this.state.data;
    AJAX.addProduct.getBasicsKeyList({ goodsClassifyId, goodsModule, isHighView }).then((res) => {
      if (res.code == 1 && res.data) {
        let datas = res.data;
        let inputPlaceholder = ""
        for (let items of datas) {
          items.className = `stepGuidanceBox${items.id}`
          if (items.id == 1) {
            items.rules = [{ required: items.inputRequired, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容") } else if (!sumReg.test(value)) { cbfn("只能是数字！") } cbfn() } }]
          } else if (items.id == 24) {
            items.rules = [{ required: items.inputRequired, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容") } else if (zmReg.test(value)) { cbfn("格式验证错误！") } cbfn() } }]
          } else if (items.inputType == "select" || items.inputType == "select_more") {
            items.rules = [{ required: items.inputRequired, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容") } cbfn() } }]
          } else {
            items.rules = [{ required: items.inputRequired, message: '请输入内容' }]
          }
          if (items.id == 109) {
            inputPlaceholder = items.inputPlaceholder
          }
        }
        for (let items1 of datas) {
          if (items1.id == 1) {
            items1.inputPlaceholder1 = inputPlaceholder
          }
        }
        formList1 = JSON.parse(JSON.stringify(datas))
        if (this.state.isDisplayCode == false) {
          for (let items in datas) {
            if (datas[items].id == 1) datas.splice(items, 1)
            if (datas[items].id == 26) datas.splice(items, 1)
            if (datas[items].id == 27) datas.splice(items, 1)
            if (datas[items].id == 31) datas.splice(items, 1)
          }
        }
        this.setState({
          data: {
            ...this.state.data,
            formList: datas,
            formList1: formList1
          }
        })
      }
    })
  }
  getFormRef = el => {
    this.form = el;
  }
  formChonge = (value = null) => {
    let { formList } = this.state.data;
    let content = this.form.getFieldsValue();
    if (value && value.id == '23') {
      content['23'] = value.value
    }
    // console.log(content);
    let isValue = true;
    let typeLength = 12;
    if (content.goodsNumberType) {
      if (content.goodsNumberType == 1) {
        typeLength = 12
      } else if (content.goodsNumberType == 2) {
        typeLength = 14
      } else if (content.goodsNumberType == 3) {
        typeLength = 13
      } else {
        typeLength = 12
      }
    }
    this.setState({
      goodsCodeLenth: typeLength
    })
    for (let items of formList) {
      if (items.inputRequired == true) {
        for (let keys in content) {
          if (!content[items.id]) {
            isValue = false;
            return;
          }
          if (content.hasOwnProperty("goodsNumberType")) {
            if (!content.goodsNumberType) isValue = false;
          }
        }
      }
    }
    for (let i in content) {
      if (content[i] && Array.isArray(content[i]) && i != 'goodsNumberType') {
        let sums = i.split('names')[0];
        content[sums] = content[`${sums}title`].toString() + "¦" + content[i].join("¦")
        if (i.match(/names/)) {
          delete content[i]
        }
        delete content[`${sums}title`]
      } else if (i.match(/title/) && !content[i]) {
        delete content[i]
      } else if (i.match(/names/) && !content[i]) {
        delete content[i]
      }
      if (i == 70 || i == 107 || i == 108) {
        let matchName1 = i + 'more1'
        let matchName2 = i + 'more2'
        let matchName3 = i + 'more3'
        let matchName4 = i + 'more4'
        let text1 = " ", text2 = " ", text3 = " ", text4 = " ";
        if (content[matchName1]) text1 = content[matchName1]
        if (content[matchName2]) text2 = content[matchName2]
        if (content[matchName3]) text3 = content[matchName3]
        if (content[matchName4]) text4 = content[matchName4]
        content[i] = text1 + "¦" + text2 + "¦" + text3 + "¦" + text4
        if (i == 107 || i == 108) {
          content[i] = text1 + "¦" + text2 + "¦" + text3
        }
        delete content[matchName1]
        delete content[matchName2]
        delete content[matchName3]
        delete content[matchName4]
      }
    }
    if (isValue) {
      if (this.props.goodsId) {
        let setContent = []
        for (let items of formList) {
          for (let contentItem in content) {
            if (items.id == contentItem && content[contentItem]) {
              let obj = {};
              obj.elseExtendsId = items.extendsId
              obj.goodsBasicsKeyId = items.id
              obj.goodsId = this.props.goodsId
              obj.isNotNull = items.isNotNull
              obj.runSave = items.runSave
              obj.goodsElseBasicsValue = content[contentItem]
              setContent.push(obj)
            }
          }
        }
        content = setContent
      }
      for (let qupItem in content) {
        if (qupItem.match(/title/)) delete content[qupItem]
      }

      this.props.dataInfo(content, this.props.type)
    }
  }

  handleKeyPress = (e) => {
    const invalidChars = ['-', '+', '.', 'e', 'E']
    if (invalidChars.indexOf(e.key) !== -1) e.preventDefault()
  }
  render() {
    let { data, goodsCodeLenth } = this.state
    return <div className="tab1Banner-box">
      <Form labelCol={{ span: 4 }} autoComplete="off" wrapperCol={{ span: 14 }} ref={this.getFormRef} className="tab1-form" layout="horizontal">
        {
          data.formList.map((item, index) => {
            return (
              <div className="df" key={index}>
                {item.status == false ? <Form.Item
                  key={index} className={item.className} name={item.inputType == 'text_more' || item.inputType == 'select_more' ? null : item.id}
                  extra={item.inputDescription}
                  label={<span className="tab1-form-title">{item.inputName}&nbsp;<Tooltip className="tipsTooltip" title={item.inputTips}>
                    <QuestionCircleOutlined style={{ color: "#008296" }} />
                  </Tooltip></span>}
                  rules={item.rules}
                  initialValue={item.id == '23' ? '0' : null}
                >
                  {item.inputType == "good_code" ?
                    <div style={{ display: 'flex' }}>
                      <Input placeholder={this.props.goodsId ? item.goodsValue : item.inputPlaceholder} min={item.inputValueMin} max={item.inputValueMax} disabled={this.props.goodsId && item.inputLock ? true : false} onChange={this.formChonge} maxLength={goodsCodeLenth} style={{ width: "900px", height: '32px' }} />
                      <Form.Item className="goodsNumberType" name="goodsNumberType" style={{ margin: '0px' }} rules={[{ required: true, validator: (rule, value, cbfn) => { if (!value) { return Promise.reject(); } return Promise.resolve(); } }]}>
                        <Select placeholder={item.inputPlaceholder1 || '-选择-'} disabled={this.props.goodsId && item.inputLock ? true : false} onChange={this.formChonge}>
                          {data.NumbetTypeList ? data.NumbetTypeList.map((item1, index1) => {
                            return <Option key={index1} value={item1.id}>{item1.numberType}</Option>
                          }) : null}
                        </Select>
                      </Form.Item>
                    </div> :
                    item.inputType == "price" ? <Input prefix="USD$" type="number" min={item.inputValueMin || 1} max={item.inputValueMax} onChange={this.formChonge} className="tab3-price-input priceBox" placeholder={item.inputPlaceholder} /> :
                      item.inputType == "number" ? <Input type="number" min={item.inputValueMin || 1} max={item.inputValueMax} onChange={this.formChonge} className="tab3-price-input" placeholder={item.inputPlaceholder} /> :
                        item.inputType == "integer" ? <InputNumber style={{ width: '100%' }} controls={false} precision={0} min={item.inputValueMin || 1} max={item.inputValueMax} onChange={(e) => this.formChonge(e)} className="tab3-price-input" placeholder={item.inputPlaceholder} onKeyPress={this.handleKeyPress} /> :
                          item.inputType == "text" ? <Input onChange={this.formChonge} min={item.inputValueMin} max={item.inputValueMax} disabled={this.props.goodsId && item.inputLock ? true : false} placeholder={item.inputPlaceholder} maxLength="500" /> :
                            item.inputType == "select" ? <Select onChange={this.formChonge} className="tab3-select" placeholder={item.inputPlaceholder} >
                              {item.inputValue ? item.inputValue.split(",").map((item1, index1) => {
                                return <Option key={index1} value={item1}>{item1}</Option>
                              }) : null}
                            </Select> : item.inputType == "textarea" ? <textarea className="textareaBox" onChange={this.formChonge} placeholder={item.inputPlaceholder} /> :
                              item.inputType == "date" ? <Space direction="vertical">
                                <DatePicker locale={locale} onChange={this.formChonge} placeholder={item.inputPlaceholder} format="MM/DD/YYYY" />
                              </Space> :
                                item.inputType == "watt" ? <div className="tab4-unite">
                                  <Form.Item key={index} className={item.className} name={item.inputType == 'text_more' || item.inputType == 'select_more' ? null : item.id} style={{ width: "49%", marginBottom: 0 }} extra={item.inputDescription}>
                                    <Input min={item.inputValueMin} max={item.inputValueMax} placeholder={item.inputPlaceholder} maxLength="500" onChange={this.formChonge} />
                                  </Form.Item>
                                  <Select onChange={this.formChonge} placeholder={item.inputPlaceholder} style={{ width: "49%" }} >
                                    {item.inputValue ? item.inputValue.map((item1, index1) => {
                                      return <Option key={index1} value={item1}>{item1}</Option>
                                    }) : null}
                                  </Select>
                                </div> :
                                  item.inputType == "capacity" ? <div className="tab4-unite">
                                    <Form.Item key={index} className={item.className} name={item.inputType == 'text_more' || item.inputType == 'select_more' ? null : item.id} style={{ width: "49%", marginBottom: 0 }} extra={item.inputDescription}>
                                      <Input min={item.inputValueMin} max={item.inputValueMax} placeholder={item.inputPlaceholder} maxLength="500" onChange={this.formChonge} />
                                    </Form.Item>
                                    <Select onChange={this.formChonge} placeholder={item.inputPlaceholder} style={{ width: "49%" }} >
                                      {item.inputValue ? item.inputValue.map((item1, index1) => {
                                        return <Option key={index1} value={item1}>{item1}</Option>
                                      }) : null}
                                    </Select>
                                  </div> :
                                    item.inputType == "weight" ? <div className="tab4-unite">
                                      <Form.Item key={index} className={item.className} name={item.inputType == 'text_more' || item.inputType == 'select_more' ? null : item.id} style={{ width: "49%", marginBottom: 0 }} extra={item.inputDescription}>
                                        <Input min={item.inputValueMin} max={item.inputValueMax} placeholder={item.inputPlaceholder} maxLength="500" onChange={this.formChonge} />
                                      </Form.Item>
                                      <Select onChange={this.formChonge} placeholder={item.inputPlaceholder} style={{ width: "49%" }} >
                                        {item.inputValue ? item.inputValue.map((item1, index1) => {
                                          return <Option key={index1} value={item1}>{item1}</Option>
                                        }) : null}
                                      </Select>
                                    </div> :
                                      item.inputType == "size" ? <div className="showSizeBox">
                                        <div>
                                          <p className="bolds">长度</p>
                                          <div className="tab4-unite">
                                            <Form.Item key={index} className={item.className} name={item.inputType == 'text_more' || item.inputType == 'select_more' ? null : `${item.id}more1`} style={{ width: "49%", marginBottom: 0 }} extra={item.inputDescription}>
                                              <Input min={item.inputValueMin} max={item.inputValueMax} placeholder={item.inputPlaceholder} maxLength="500" onChange={this.formChonge} />
                                            </Form.Item>
                                            <Select onChange={this.formChonge} placeholder={item.inputPlaceholder} style={{ width: "49%" }} >
                                              {item.inputValue ? item.inputValue.map((item1, index1) => {
                                                return <Option key={index1} value={item1}>{item1}</Option>
                                              }) : null}
                                            </Select>
                                          </div>
                                        </div>
                                        <div>
                                          <p className="bolds">宽度</p>
                                          <div className="tab4-unite">
                                            <Form.Item key={index} className={item.className} name={item.inputType == 'text_more' || item.inputType == 'select_more' ? null : `${item.id}more2`} style={{ width: "49%", marginBottom: 0 }} extra={item.inputDescription}>
                                              <Input min={item.inputValueMin} max={item.inputValueMax} placeholder={item.inputPlaceholder} maxLength="500" onChange={this.formChonge} />
                                            </Form.Item>
                                            <Select onChange={this.formChonge} placeholder={item.inputPlaceholder} style={{ width: "49%" }} >
                                              {item.inputValue ? item.inputValue.map((item1, index1) => {
                                                return <Option key={index1} value={item1}>{item1}</Option>
                                              }) : null}
                                            </Select>
                                          </div>
                                        </div>
                                        <div>
                                          <p className="bolds">高度</p>
                                          <div className="tab4-unite">
                                            <Form.Item key={index} className={item.className} name={item.inputType == 'text_more' || item.inputType == 'select_more' ? null : `${item.id}more3`} style={{ width: "49%", marginBottom: 0 }} extra={item.inputDescription}>
                                              <Input min={item.inputValueMin} max={item.inputValueMax} placeholder={item.inputPlaceholder} maxLength="500" onChange={this.formChonge} />
                                            </Form.Item>
                                            <Select onChange={this.formChonge} placeholder={item.inputPlaceholder} style={{ width: "49%" }} >
                                              {item.inputValue ? item.inputValue.map((item1, index1) => {
                                                return <Option key={index1} value={item1}>{item1}</Option>
                                              }) : null}
                                            </Select>
                                          </div>
                                        </div>
                                      </div> :
                                        item.inputType == "show_size" ? <div className="showSizeBox">
                                          <div>
                                            <p className="bolds">商品显示长度</p>
                                            <div className="tab4-unite">
                                              <Form.Item key={index} className={item.className} name={item.inputType == 'text_more' || item.inputType == 'select_more' ? null : `${item.id}more1`} style={{ width: "49%", marginBottom: 0 }} extra={item.inputDescription}>
                                                <Input min={item.inputValueMin} max={item.inputValueMax} placeholder={item.inputPlaceholder} maxLength="500" onChange={this.formChonge} />
                                              </Form.Item>
                                              <Select onChange={this.formChonge} placeholder={item.inputPlaceholder} style={{ width: "49%" }} >
                                                {item.inputValue ? item.inputValue.map((item1, index1) => {
                                                  return <Option key={index1} value={item1}>{item1}</Option>
                                                }) : null}
                                              </Select>
                                            </div>
                                          </div>
                                          <div>
                                            <p className="bolds">显示屏宽度</p>
                                            <div className="tab4-unite">
                                              <Form.Item key={index} className={item.className} name={item.inputType == 'text_more' || item.inputType == 'select_more' ? null : `${item.id}more2`} style={{ width: "49%", marginBottom: 0 }} extra={item.inputDescription}>
                                                <Input min={item.inputValueMin} max={item.inputValueMax} placeholder={item.inputPlaceholder} maxLength="500" onChange={this.formChonge} />
                                              </Form.Item>
                                              <Select onChange={this.formChonge} placeholder={item.inputPlaceholder} style={{ width: "49%" }} >
                                                {item.inputValue ? item.inputValue.map((item1, index1) => {
                                                  return <Option key={index1} value={item1}>{item1}</Option>
                                                }) : null}
                                              </Select>
                                            </div>
                                          </div>
                                          <div>
                                            <p className="bolds">显示屏高度</p>
                                            <div className="tab4-unite">
                                              <Form.Item key={index} className={item.className} name={item.inputType == 'text_more' || item.inputType == 'select_more' ? null : `${item.id}more3`} style={{ width: "49%", marginBottom: 0 }} extra={item.inputDescription}>
                                                <Input min={item.inputValueMin} max={item.inputValueMax} placeholder={item.inputPlaceholder} maxLength="500" onChange={this.formChonge} />
                                              </Form.Item>
                                              <Select onChange={this.formChonge} placeholder={item.inputPlaceholder} style={{ width: "49%" }} >
                                                {item.inputValue ? item.inputValue.map((item1, index1) => {
                                                  return <Option key={index1} value={item1}>{item1}</Option>
                                                }) : null}
                                              </Select>
                                            </div>
                                          </div>
                                          <div>
                                            <p className="bolds">直径</p>
                                            <div className="tab4-unite">
                                              <Form.Item key={index} className={item.className} name={item.inputType == 'text_more' || item.inputType == 'select_more' ? null : `${item.id}more4`} style={{ width: "49%", marginBottom: 0 }} extra={item.inputDescription}>
                                                <Input min={item.inputValueMin} max={item.inputValueMax} placeholder={item.inputPlaceholder} maxLength="500" onChange={this.formChonge} />
                                              </Form.Item>
                                              <Select onChange={this.formChonge} placeholder={item.inputPlaceholder} style={{ width: "49%" }} >
                                                {item.inputValue ? item.inputValue.map((item1, index1) => {
                                                  return <Option key={index1} value={item1}>{item1}</Option>
                                                }) : null}
                                              </Select>
                                            </div>
                                          </div>
                                        </div> :
                                          item.inputType == "distribution_channels" ? <div className="distribution-box">
                                            <Radio.Group onChange={(e) => { this.formChonge({ id: '23', value: e.target.value }) }} defaultValue="0">
                                              <Radio value="1">
                                                <p>我将自行配送此商品</p>
                                                <p>（卖家自行配送）</p>
                                              </Radio>
                                              <Radio value="0">
                                                <p>亚马逊将会配送并提供客户服务</p>
                                                <p>（亚马逊配送）</p>
                                              </Radio>
                                            </Radio.Group>
                                          </div> :
                                            item.inputType == "text_more" ?
                                              <div>
                                                <Form.Item
                                                  name={`${item.id}title`}
                                                  rules={item.rules}
                                                  noStyle
                                                >
                                                  <Input min={item.inputValueMin} max={item.inputValueMax} maxLength="499" placeholder={item.inputPlaceholder} onChange={this.formChonge} style={{ width: '50%' }} />
                                                </Form.Item>
                                                <Form.List
                                                  name={`${item.id}names`}
                                                  rules={item.rules}
                                                >
                                                  {(fields, { add, remove }, { errors }) => (
                                                    <>
                                                      {fields.map((field, index) => (
                                                        <div>
                                                          <Form.Item
                                                            {...field}
                                                            noStyle
                                                          >
                                                            <Input min={item.inputValueMin} max={item.inputValueMax} maxLength="499" placeholder={item.inputPlaceholder} onChange={this.formChonge} style={{ width: '50%', marginBottom: 0 }} />
                                                          </Form.Item>
                                                          {fields.length > 0 ? (
                                                            <MinusCircleOutlined
                                                              className="dynamic-delete-button"
                                                              onClick={() => remove(field.name)}
                                                            />
                                                          ) : null}
                                                        </div>
                                                      ))}
                                                      {fields.length < 4 ? <Form.Item>
                                                        <Button
                                                          type="dashed"
                                                          onClick={() => { add() }}
                                                          style={{ width: '50%' }}
                                                          icon={<PlusOutlined />}
                                                        >
                                                          添加更多
                                                        </Button>
                                                      </Form.Item> : null}
                                                    </>
                                                  )}
                                                </Form.List></div>
                                              :
                                              item.inputType == "select_more" ?
                                                <div>
                                                  <Form.Item
                                                    name={`${item.id}title`}
                                                    rules={item.rules}
                                                    noStyle
                                                  >
                                                    <Select placeholder={item.inputPlaceholder} onChange={this.formChonge} style={{ width: '50%' }} >
                                                      {item.inputValue ? item.inputValue.split(",").map((item1, index1) => {
                                                        return <Option key={index1} value={item1}>{item1}</Option>
                                                      }) : null}
                                                    </Select>
                                                  </Form.Item>
                                                  <Form.List
                                                    name={`${item.id}names`}
                                                    rules={item.rules}
                                                  >
                                                    {(fields, { add, remove }, { errors }) => (
                                                      <>
                                                        {fields.map((field, index) => (
                                                          <div>
                                                            <Form.Item
                                                              {...field}
                                                              noStyle
                                                            >
                                                              <Select onChange={this.formChonge} placeholder={item.inputPlaceholder}
                                                                style={{ width: '50%', marginBottom: 0 }} >
                                                                {item.inputValue ? item.inputValue.split(",").map((item1, index1) => {
                                                                  return <Option key={index1} value={item1}>{item1}</Option>
                                                                }) : null}
                                                              </Select>
                                                            </Form.Item>
                                                            {fields.length > 1 ? (
                                                              <MinusCircleOutlined
                                                                className="dynamic-delete-button"
                                                                onClick={() => remove(field.name)}
                                                              />
                                                            ) : null}
                                                          </div>
                                                        ))}
                                                        {fields.length < 4 ? <Form.Item>
                                                          <Button
                                                            type="dashed"
                                                            onClick={() => add()}
                                                            style={{ width: '50%' }}
                                                            icon={<PlusOutlined />}
                                                          >添加更多
                                                          </Button>
                                                        </Form.Item> : null}
                                                      </>
                                                    )}
                                                  </Form.List>
                                                </div> : null
                  }
                </Form.Item> : null}
                {item.inputLock ? <IconFont type="icon-suo" className="icon-suo" style={{ marginLeft: "10px", fontSize: "20px", position: 'absolute', right: '230px', top: '7px' }} /> : null}
              </div>
            )
          })
        }
      </Form>
    </div>
  }
}
export default formData