import React, { useState } from "react"
import "./Aa.css";
import "../default.css/default.css";
import Header from "components/home_header";
import Footer from "components/addlisting_footer/FooterCom";
import IconFont from "components/Icon/Icon";
import { Link } from 'react-router-dom';
import { Pagination, Alert } from 'antd';

function Aa() {
    let [data] = useState({
        list: [
            { id: 1, title: '蒸汽式桑拿箱（黑色）', type: 'EBC', language: "美国 英语", ASINs: "1", last: "09/25/20 20", status: "已批准" },
            { id: 2, title: '园艺花架A＋', type: 'EBC', language: "美国 英语", ASINs: "1", last: "09/25/20 20", status: "已批准" },
            { id: 3, title: 'MENGK sofa table', type: 'EBC', language: "美国 英语", ASINs: "1", last: "09/25/20 20", status: "已批准" },
            { id: 4, title: '蒸汽桑拿A+', type: 'EBC', language: "美国 英语", ASINs: "1", last: "09/25/20 20", status: "已批准" },
            { id: 5, title: '藤架3', type: 'EBC', language: "美国 英语", ASINs: "1", last: "09/25/20 20", status: "已批准" },
            { id: 6, title: 'Juicer', type: 'EBC', language: "美国 英语", ASINs: "1", last: "09/25/20 20", status: "已批准" },
            { id: 7, title: '藤架1', type: 'EBC', language: "美国 英语", ASINs: "1", last: "09/25/20 20", status: "已批准" },
            { id: 8, title: '花架', type: 'EBC', language: "美国 英语", ASINs: "1", last: "09/25/20 20", status: "已批准" },
            { id: 9, title: '花架A+', type: 'EBC', language: "美国 英语", ASINs: "1", last: "09/25/20 20", status: "已批准" },
            { id: 10, title: 'Steam Sauna', type: 'EBC', language: "美国 英语", ASINs: "1", last: "09/25/20 20", status: "已批准" },

        ]
    });

    return (
        <div>
            <div className="A_body">
                <Header />
                <div className="A_head">

                    <div className="A_head_left">
                        <div><h2 style={{ fontSize: "24px" }}>A+ 商品描述管理器</h2></div>
                        <span>培训</span>
                    </div>

                    <div className="A_right">
                        <div className="A_right_inp">
                            <span> <IconFont type="icon-sousuo" className="A_icon_sousuo" /></span>
                            <input placeholder="搜索ASIN或商品名称" />
                        </div>
                        <span className="A_right_or">或</span>
                        <div className="A_right_btn"><button><Link to="/informationSelect">开始创建 A+ 商品描述</Link></button></div>
                    </div>

                </div>
                <div className="A_head_bottom">使用 A+ 商品描述管理器向详情页面添加丰富的商品描述，该页面展示您的品牌并指导买家了解商品功能。</div>
                <Alert
                    message={<div>A/B测试<span className="A_alert_new" >全新</span></div>}
                    description={<div>某些ASIN可能符合A/B测试条件。<span style={{color:"#008296"}} >详情了解A/B测试</span></div>}
                    type="info"
                    showIcon
                    closable={true}
                />
                <div className="A_nav_box">
                    <div className="A_nav_head">
                        <h2>商品描述(117)</h2>
                        <div className="A_nav_headII">
                            <div className="A_nav_inp">
                                <span> <IconFont type="icon-sousuo" className="A_icon_sousuo" /></span>
                                <input placeholder="查找内容" />
                            </div>
                            <div className="A_nav_check">
                                <input type="checkbox" />
                                <span>显示自动创建的内容</span>
                            </div>
                            <div className="A_nav_page">
                                {/* <IconFont className="A_page_left" type="icon-fanhui-copy-copy" /> */}
                                <Pagination size="small" defaultCurrent={1} total={60} showSizeChanger={false} ></Pagination>
                                {/* <IconFont className="A_page_right" type="icon-fanhui-copy-copy" /> */}
                            </div>
                        </div>
                    </div>
                    <div className="A_nav_name">
                        <div className="A_nav_nameI">
                            <div>商品名称</div>
                            <span><svg t="1610440325211" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4512" width="12" height="12"><path d="M719.8 384L512 735.9 304.2 384h415.6m0-64H304.2c-49.5 0-80.3 53.9-55.1 96.5l207.8 351.9c12.4 21 33.7 31.5 55.1 31.5s42.7-10.5 55.1-31.5l207.8-351.9c25.2-42.6-5.6-96.5-55.1-96.5z" p-id="4513"></path></svg></span>
                        </div>
                        <div className="A_nav_nameright">
                            <div className="A_nav_nameII">
                                <div>商品描述类型</div>
                                <span className="A_nav_svg"><svg t="1610440325211" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4512" width="21px" height="21px"><path d="M719.8 384L512 735.9 304.2 384h415.6m0-64H304.2c-49.5 0-80.3 53.9-55.1 96.5l207.8 351.9c12.4 21 33.7 31.5 55.1 31.5s42.7-10.5 55.1-31.5l207.8-351.9c25.2-42.6-5.6-96.5-55.1-96.5z" p-id="4513"></path></svg></span>

                            </div>
                            <div className="A_nav_nameII">
                                <div>语言</div>
                                <span ><svg t="1610440325211" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4512" width="21px" height="21px"><path d="M719.8 384L512 735.9 304.2 384h415.6m0-64H304.2c-49.5 0-80.3 53.9-55.1 96.5l207.8 351.9c12.4 21 33.7 31.5 55.1 31.5s42.7-10.5 55.1-31.5l207.8-351.9c25.2-42.6-5.6-96.5-55.1-96.5z" p-id="4513"></path></svg></span>

                            </div>
                            <div className="A_nav_ASIN">ASINs</div>
                            <div className="A_nav_nameII">
                                <div>最后修改日期</div>
                                <span><svg t="1610440325211" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4512" width="21px" height="21px"><path d="M719.8 384L512 735.9 304.2 384h415.6m0-64H304.2c-49.5 0-80.3 53.9-55.1 96.5l207.8 351.9c12.4 21 33.7 31.5 55.1 31.5s42.7-10.5 55.1-31.5l207.8-351.9c25.2-42.6-5.6-96.5-55.1-96.5z" p-id="4513"></path></svg></span>

                            </div>
                            <div className="A_nav_nameII">
                                <div>商品描述状态</div>
                                <span><svg t="1610440325211" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4512" width="21px" height="21px"><path d="M719.8 384L512 735.9 304.2 384h415.6m0-64H304.2c-49.5 0-80.3 53.9-55.1 96.5l207.8 351.9c12.4 21 33.7 31.5 55.1 31.5s42.7-10.5 55.1-31.5l207.8-351.9c25.2-42.6-5.6-96.5-55.1-96.5z" p-id="4513"></path></svg></span>

                            </div>
                        </div>
                    </div>
                    <div>

                        {
                            data.list.map(item => {
                                return (
                                    <div className="A_list_name" key={item.id} >
                                        <div className="A_nav_itemI" >
                                            <Link to="/ProductDetails"> {item.title}</Link>
                                        </div>
                                        <div className="A_nav_nameright">
                                            <div className="A_nav_itemII">
                                                {item.type}
                                            </div>
                                            <div className="A_nav_itemII">
                                                {item.language}
                                            </div>
                                            <div className="A_nav_ASINII">
                                                <Link to="/ProductDetails">{item.ASINs}</Link>
                                            </div>
                                            <div className="A_nav_itemII">
                                                {item.last}
                                            </div>
                                            <div className="A_nav_itemII">
                                                {item.status}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>



                </div>

            </div>
            <Footer />
        </div>
    );
}

export default Aa;
