import { useCallback, useMemo, useState } from "react";
import { useMount, useUpdateEffect } from "ahooks";
import useEventCallback from "./useEventCallback";
import useKeep from "./useKeep";

const NORMAL_PAGINATION = {
  pageIndex: 1,
  pageSize: 10,
  total: 0,
};

function isFalsityFn(value) {
  const type = typeof value;

  if (type === "number") {
    return Number.isNaN(value);
  }

  return !value;
}

function shaking(object, isFalsity = isFalsityFn) {
  const tempObject = {};

  for (let key in object) {
    const value = object[key];

    if (!isFalsity(value)) {
      tempObject[key] = value;
    }
  }

  return tempObject;
}

const noopFn = () => {};

const usePaginationTable = (
  {
    fetcher,
    fetchAtMounted = true,
    params,
    onPageIndexChange: _onPageIndexChange = noopFn,
    defaultPageInfo = {},
  },
  deps = []
) => {
  const normalPagination = useMemo(() => {
    return {
      ...NORMAL_PAGINATION,
      ...defaultPageInfo,
    };
  }, [defaultPageInfo]);

  const [pageInfo, setPageInfo] = useState(normalPagination);
  const [total, setTotal] = useState(0);
  const { pageIndex, pageSize } = pageInfo;
  const otherParams = useKeep(params);
  const onPageIndexChange = useKeep(_onPageIndexChange);
  const [tableList, setTableList] = useState([]);
  const [isTableLoading, setTableLoading] = useState(false);

  // const fetcher = useEventCallback(_fetcher, []);

  const innerFetcher = useCallback(
    (partParams = {}) => {
      let params = {
        page: pageInfo.pageIndex,
        size: pageInfo.pageSize,
        ...otherParams.current,
      };
      if (typeof partParams === "function") {
        params = partParams(params);
      } else {
        params = { ...params, ...partParams };
      }
      setTableLoading(true);
      return fetcher(params)
        .then((res) => {
          if (res.code === 1) {
            const { list, total } = res.data;
            setTotal(total);
            setTableList(list);
          }
        })
        .finally(() => {
          setTableLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageInfo, fetcher, ...deps]
  );

  useMount(() => {
    if (fetchAtMounted) {
      innerFetcher();
    }
  });

  useUpdateEffect(() => {
    innerFetcher();
  }, [innerFetcher]);

  const updatePageInfo = useCallback(
    (pageIndex, pageSize) => {
      if (pageIndex <= 0) return;

      setPageInfo((pre) => {
        // 数据不足时下一页
        if (pre.pageIndex < pageIndex && tableList.length < pre.pageSize)
          return pre;
        if (pre.pageIndex !== pageIndex) {
          onPageIndexChange.current();
        }
        return {
          ...pre,
          ...shaking({
            pageIndex,
            pageSize,
          }),
        };
      });
    },
    [onPageIndexChange, tableList.length]
  );

  const resetPageIndex = useCallback(() => {
    updatePageInfo(1);
  }, [updatePageInfo]);

  const reFetch = useEventCallback(
    (params) => {
      innerFetcher(params);
    },
    [innerFetcher]
  );

  const clearTableList = useEventCallback(() => {
    setTableList([]);
    setPageInfo(normalPagination);
    setTotal(normalPagination.total);
  }, []);
  return {
    isTableLoading,
    tableList,
    pagination: {
      current: pageIndex,
      pageSize,
      total,
      onChange: updatePageInfo,
    },
    operator: {
      resetPageIndex,
      updatePageInfo,
      reFetch,
      clearTableList,
    },
  };
};

export default usePaginationTable;
