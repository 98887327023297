import { useCallback, useState, useRef } from "react";
import { Modal } from "antd";
import { AButton } from "@a-components/";
import Apis from "@api/apis";
import NegativeTable from "./../NegativeTable";
import NegativeGoodsSelector from "@view/advertisement/NegativeGoodsSelector";
import "./style.module.css";
const columns = [
  {
    title: "商品",
    dataIndex: "brandOrGoods",
  },
];

const apiMapping = {
  // 广告的查询和更新
  advertising: {
    get: ({ businessId, searchValue, ...otherParams }) => {
      return Apis.advertising.getAdvertisingNegativeGoodsList({
        ...otherParams,
        advertisingId: businessId,
        goodsName: searchValue,
      });
    },
    post: ({ businessId, negativeList }) => {
      return Apis.advertising.addAdvertisingNegativeGoods({
        advertisingId: businessId,
        advertisingNegativeGoodsReqList: negativeList.map((item) => ({
          goodsId: item.goodsId,
        })),
      });
    },
  },
  // 广告组的查询和更新
  advertisingGroup: {
    get: ({ businessId, searchValue, otherParams }) => {
      return Apis.advertising.getAdvertisingGroupNegativeDirectionalList({
        ...otherParams,
        advertisingGroupId: businessId,
        brandOrGoods: searchValue,
      });
    },
    post: ({ businessId, negativeList }) => {
      return Apis.advertising.addAdvertisingGroupNegativeDirectional({
        advertisingGroupId: businessId,
        advertisingNegativeDirectionalReqList: negativeList.map((item) => ({
          directionalId: item.goodsId,
          negativeDirectionalType: 2,
        })),
      });
    },
  },
};

const NegativeGoods = ({ businessId, businessType }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [negativeList, setNegativeList] = useState([]);
  const negativeTable = useRef();

  const _fetcher = apiMapping[businessType];

  const fetcher = useCallback(
    ({ page, size, searchValue }) => {
      return _fetcher.get({ businessId, page, size, searchValue });
    },
    [_fetcher, businessId]
  );

  const handleShowModal = useCallback(() => {
    setModalVisible(true);
    setNegativeList([]);
  }, []);

  const handleHideModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const handleSaveGoodsList = useCallback(() => {
    return _fetcher.post({ businessId, negativeList }).then((res) => {
      if (res.code === 1) {
        handleHideModal();
        // eslint-disable-next-line no-undef
        negativeTable.current?.updatePageInfo(1);
      }
    });
  }, [_fetcher, businessId, handleHideModal, negativeList]);

  return (
    <div>
      <NegativeTable
        ref={negativeTable}
        columns={columns}
        fetcher={fetcher}
        createBtn={{ text: "添加否定商品", onClick: handleShowModal }}
        searchInput={{ placeholder: "查找否定商品" }}
      />

      <Modal
        title="添加否定商品"
        visible={modalVisible}
        style={{ width: "80%", minWidth: 1000 }}
        className="negative-goods__modal"
        onCancel={handleHideModal}
        destroyOnClose
        footer={
          <>
            <AButton onClick={handleHideModal}>取消</AButton>
            <AButton
              type="primary"
              disabled={!negativeList.length}
              onClick={handleSaveGoodsList}
            >
              添加否定商品
            </AButton>
          </>
        }
      >
        <NegativeGoodsSelector
          selectedNegativeList={negativeList}
          updateSelectedNegativeList={setNegativeList}
          searchType={2}
        />
      </Modal>
    </div>
  );
};

export default NegativeGoods;
