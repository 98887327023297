import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from "components/home_header";
import Footer from "components/addlisting_footer/FooterCom";
import "./CompanyAddress.css"
import { Button, Alert, Form, Input, Col, Row, Select } from 'antd';
const { Option } = Select;

function CompanyAddress() {
  let [addressShow, setAddressShow] = useState(true)
  let [successShow, setSuccessShow] = useState(false)
  return (
    <div className="company_address" >
      <Header />
      <div className="company_address_inner" >
        <h2>
          <span>公司地址</span>
          <Link to="/accountinfo" >账户信息</Link>
        </h2>
        {
          successShow && (
            <Alert
              message="Business Address submitted successfully"
              type="success"
              showIcon
              className="company_address_success"
            />
          )
        }

        <Alert message={<div>您的注册企业地址将显示在您的公开卖家资料页面上。点击此处以了解更多信息。</div>} type="info" showIcon />
        {
          addressShow && (
            <div className="company_address_show" >
              <p>当前企业地址</p>
              <div>
                <div>Shenzhenshi Yumi Ecommerce Co., Ltd.</div>
                <div>中国</div>
                <div>518000</div>
                <div>广东深圳</div>
                <div>shenzhenshilonghuaqudalangjiedaoxin shishequlangjinglu10hao6dong3cengA305</div>
                <div>13128658239</div>
                <div className="company_address_show_but" ><span>选择地址</span><span onClick={() => { setAddressShow(false) }} >添加新地址</span></div>
              </div>
            </div>
          )
        }

        {
          !addressShow && (
            <div className="company_address_add" >
              <h3>
                <span>添加新地址</span>
                <span>选择地址</span>
              </h3>
              <div className="company_address_add_from" >
                <Form layout="vertical">
                  <Form.Item label="姓名" >
                    <Input />
                  </Form.Item>
                  <Row>
                    <Col span={11}>
                      <Form.Item label="国家地区" >
                        <Select defaultValue={"1"} >
                          <Option value="1">中国</Option>
                          <Option value="2">印度</Option>
                          <Option value="3">埃及</Option>
                          <Option value="4">墨西哥</Option>
                          <Option value="5">意大利</Option>
                          <Option value="6">新加坡</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={11} offset={2}  >
                      <Form.Item label="地址" >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={11} >
                      <Form.Item label="地址行2" >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={11} offset={2}  >
                      <Form.Item label="省/地区/州" >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={11} >
                      <Form.Item label="地区/市" >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={11} offset={2}  >
                      <Form.Item label="邮编" >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={11} >
                      <Form.Item label="主要电话" >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="setdeposit_Method_bank_but" >
                <Button onClick={() => { setAddressShow(true) }} >取消</Button>
                <Button onClick={() => { setAddressShow(true);setSuccessShow(true) }} >提交</Button>
              </div>
            </div>
          )
        }

      </div>
      <Footer />
    </div>
  )
}

export default CompanyAddress