import React from "react";
import "./DraftIncomplete.css";
import Header from "components/home_header";
import Footer from "components/addlisting_footer/FooterCom";
import IconFont from "components/Icon/Icon";
import { Tabs } from "antd";
import { Link } from 'react-router-dom'
import Unshipped from './module/Unshipped/Unshipped'

function DraftIncomplete() {
  return (
    <div className="DraftIncompleteBox">
      <Header />
      <div className="order_body">
        <div className="order_head">
          <div className="order_headI">
            <div>
              <h2 style={{ fontSize: '30px' }}>补全您的草稿</h2>
            </div>
            <div className="order_headII">
              <div><Link to="/">了解更多信息</Link></div>
            </div>
          </div>
        </div>
        <div>
          <div className="bolds">显示403件商品中的50件</div>
          <div className="order_headR_tab">
            <Unshipped />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default DraftIncomplete;
