import AdvertisingPageLayout from "@view/advertisement/module/AdvertisingPageLayout";
import {
  ACard,
  AInput,
  AText,
  ASelect,
  AInputMoney,
  ADatePicker,
  ARadioGroup,
  AButton,
} from "@a-components";
import FormCollection, { FormItem } from "@components/FormCollection";
import { useCallback, useEffect, useMemo } from "react";
import moment from "moment";
import Apis from "@api/apis";
import useQuery from "@hooks/useQuery";
import { useHistory } from "react-router";

const budgetOptions = [
  {
    label: "无预算上限",
    value: 1,
  },
  {
    label: "日期范围",
    value: 2,
  },
  {
    label: "每月定期",
    value: 3,
  },
];

const endSignOptions = [
  {
    label: "永不",
    value: 1,
  },
  {
    label: "开启",
    value: 2,
  },
];

const formatRule = "YYYY年MM月DD日";

function momentString(momentInstance) {
  return momentInstance.format(formatRule);
}

function minToday(current, minDate = moment()) {
  return current && current.endOf("day") < minDate.endOf("day");
}

const formRules = {
  advertisingCombinationName: [
    { required: true, message: "请输入广告组合名称" },
  ],
  budgetAmountOfMonth: [{ required: true, message: "请输入每月预算上限" }],
  budgetAmountOfDateRange: [
    { required: true, message: "请输入日期范围预算上限" },
  ],
};

const getValidDate = (v) => {
  return v ? moment(v) : null;
};

const ModifyAdCombination = () => {
  const { advertisingCombinationId } = useQuery();
  const [form] = FormCollection.useForm();
  const history = useHistory();

  const formInitValue = useMemo(() => {
    return {
      advertisingCombinationName: "",
      budgetLimit: 1,
      budgetStartDate: moment(),
      budgetEndDate: null,
      endSign: 1,
      budgetAmountOfMonth: "",
      budgetAmountOfDateRange: "",
      budgeEndDateOfDateRange: moment().add(6, "months").endOf("month"),
    };
  }, []);

  const handleFormValueChange = useCallback(
    (changedValues) => {
      const changedKeys = Object.keys(changedValues);
      if (changedKeys.includes("budgetStartDate")) {
        form.setFieldsValue({ budgetEndDate: null });
      }
    },
    [form]
  );

  const getAdvertisingCombinationDetail = useCallback(() => {
    return Apis.advertisingCombination
      .getAdvertisingCombinationDetail({
        advertisingCombinationId,
      })
      .then((res) => {
        if (res.code !== 1) return res;

        const {
          advertisingCombinationName,
          budgetAmount,
          budgetEndDate,
          budgetLimit,
          budgetStartDate,
          endSign,
        } = res.data;

        form.setFieldsValue({
          advertisingCombinationName,
          budgetLimit: budgetLimit || 1,
          budgetStartDate: budgetStartDate ? moment(budgetStartDate) : moment(),
          budgetEndDate:
            budgetLimit === 2 && budgetEndDate ? moment(budgetEndDate) : null,
          endSign: endSign || 1,
          budgetAmountOfMonth: budgetLimit === 3 ? budgetAmount : "",
          budgetAmountOfDateRange: budgetLimit === 2 ? budgetAmount : "",
          budgeEndDateOfDateRange:
            endSign === 2 && budgetLimit === 3 && budgetEndDate
              ? moment(budgetEndDate)
              : moment().add(6, "months").endOf("month"),
        });
      });
  }, [advertisingCombinationId, form]);

  useEffect(() => {
    getAdvertisingCombinationDetail();
  }, [getAdvertisingCombinationDetail]);

  const updateAdvertisingCombination = useCallback(
    (params) => {
      const {
        budgetEndDate,
        budgetLimit,
        budgetAmountOfDateRange,
        budgetAmountOfMonth,
        budgeEndDateOfDateRange,
        budgetStartDate,
        endSign,
        advertisingCombinationName,
      } = params;

      const budgetAmountMapping = {
        1: "",
        2: budgetAmountOfDateRange,
        3: budgetAmountOfMonth,
      };

      const budgetEndDateMapping = {
        1: null,
        2: budgetEndDate?.format("YYYY-MM-DD") ?? null,
        3: endSign === 1 ? null : budgeEndDateOfDateRange?.format("YYYY-MM-DD") ?? null,
      };

      return Apis.advertisingCombination.updateAdvertisingCombination({
        advertisingCombinationId,
        budgetLimit,
        endSign,
        advertisingCombinationName,
        budgetStartDate: budgetStartDate?.format("YYYY-MM-DD") ?? null,
        budgetAmount: budgetAmountMapping[budgetLimit],
        budgetEndDate: budgetEndDateMapping[budgetLimit],
      });
    },
    [advertisingCombinationId]
  );

  const handleFinish = useCallback(
    (values) => {
      updateAdvertisingCombination(values).then((res) => {
        if (res.code === 1) {
          history.goBack();
        }
      });
    },
    [history, updateAdvertisingCombination]
  );

  const handleSubmit = useCallback(() => {
    form.submit();
  }, [form]);

  const renderDateRangeBudget = useCallback(() => {
    return (
      <div>
        <div className="border-l-ccc pl-24 pr">
          <FormItem
            label="日期范围预算上限"
            name="budgetAmountOfDateRange"
            rules={formRules.budgetAmountOfDateRange}
          >
            <AInputMoney
              placeholder="输入金额"
              style={{ width: 120 }}
            ></AInputMoney>
          </FormItem>
          <div className="df ">
            <FormItem label="预算开始日期" name="budgetStartDate">
              <ADatePicker
                format={formatRule}
                allowClear={false}
                disabledDate={minToday}
              ></ADatePicker>
            </FormItem>
            <FormItem noStyle shouldUpdate>
              {(form) => {
                const budgetStartDate = form.getFieldValue("budgetStartDate");

                return (
                  <FormItem
                    label="预算结束日期"
                    className="ml-12"
                    name="budgetEndDate"
                  >
                    <ADatePicker
                      placeholder="无结束日期"
                      format={formatRule}
                      disabledDate={(current) =>
                        minToday(current, budgetStartDate)
                      }
                    ></ADatePicker>
                  </FormItem>
                );
              }}
            </FormItem>
          </div>
        </div>

        <FormItem noStyle shouldUpdate>
          {(form) => {
            const { budgetStartDate, budgetEndDate, budgetAmountOfDateRange } =
              form.getFieldsValue(true);
            return (
              <div className="mt-12 p-12 bg-f8 border-ccc border-radius-4">
                预算上限: 
                {budgetAmountOfDateRange
                  ? `$${budgetAmountOfDateRange.toFixed(2)}`
                  : ""}
                总支出，从{momentString(budgetStartDate)}
                {budgetEndDate
                  ? ` 起止 日期${momentString(budgetEndDate)}`
                  : "开始"}
              </div>
            );
          }}
        </FormItem>
      </div>
    );
  }, []);

  const renderMonthBudget = useCallback(() => {
    return (
      <div>
        <div className="border-l-ccc pl-24 pb-24 pr">
          <FormItem
            label="每月预算上限"
            name="budgetAmountOfMonth"
            rules={formRules.budgetAmountOfMonth}
          >
            <AInputMoney
              placeholder="输入金额"
              style={{ width: 120 }}
              min={1}
            ></AInputMoney>
          </FormItem>
          <FormItem name="endSign">
            <ARadioGroup options={endSignOptions}></ARadioGroup>
          </FormItem>
        </div>
        <FormItem noStyle shouldUpdate>
          {(form) => {
            const { endSign, budgeEndDateOfDateRange, budgetAmountOfMonth } =
              form.getFieldsValue(true);

            const isDisabled = endSign === 1;
            return (
              <div>
                <FormItem noStyle name="budgeEndDateOfDateRange">
                  <ADatePicker
                    allowClear={false}
                    disabled={isDisabled}
                    className="p-a"
                    style={{ top: 400, left: 74, zIndex: 1 }}
                    format={formatRule}
                    disabledDate={minToday}
                  ></ADatePicker>
                </FormItem>
                <div className="mt-12 p-12 bg-f8 border-ccc border-radius-4">
                  预算上限: -
                  {budgetAmountOfMonth
                    ? `$${budgetAmountOfMonth.toFixed(2)}`
                    : ""}
                  每月
                  {isDisabled
                    ? ""
                    : `，于 ${budgeEndDateOfDateRange.format(
                        "YYYY年MM月DD日"
                      )} 结束`}
                </div>
              </div>
            );
          }}
        </FormItem>
      </div>
    );
  }, []);

  return (
    <AdvertisingPageLayout>
      <div
        className="fs-32 df ai-c pl-24 bg-f8 w-100percent box-shadow-cd"
        style={{ height: 90 }}
      >
        修改广告组合
      </div>
      <ACard title="设置" style={{ width: 1060 }} className="mh-auto mt-24">
        <FormCollection
          onFinish={handleFinish}
          form={form}
          style={{ width: 670 }}
          initialValues={formInitValue}
          onValuesChange={handleFormValueChange}
        >
          <FormItem
            label="广告组合名称"
            name="advertisingCombinationName"
            rules={formRules.advertisingCombinationName}
          >
            <AInput style={{ width: 240 }} />
          </FormItem>
          <AText>
            为某一日期范围设置预算上限或设置为每月重复。当您的支出达到预算上限金额或达到预算结束日期时，您的广告活动将停止投放。進一步瞭解
          </AText>
          <FormItem label="预算上限" name="budgetLimit">
            <ASelect options={budgetOptions} style={{ width: 120 }}></ASelect>
          </FormItem>
          <FormItem noStyle shouldUpdate>
            {(form) => {
              const budgetLimit = form.getFieldValue("budgetLimit") || 1;
              const renderItemMapping = {
                1: () => {
                  return null;
                },
                2: renderDateRangeBudget,
                3: renderMonthBudget,
              };
              return renderItemMapping[budgetLimit]();
            }}
          </FormItem>
        </FormCollection>
      </ACard>

      <div className="df jc-fe ai-c mh-auto mt-24" style={{ width: 1070 }}>
        <AButton type="link" onClick={() => history.goBack()}>
          取消
        </AButton>
        <AButton type="primary" onClick={handleSubmit}>
          保存更改
        </AButton>
      </div>
    </AdvertisingPageLayout>
  );
};

export default ModifyAdCombination;
