import React, { useState } from "react"
import AJAX from "api/apis";
import "./Tab1-banner.css"
import BannerHeader from "../Banner-header";
import Reactours from "components/reactours"
import TipsIcon from "components/tipsIcon"
import { connect } from "react-redux";
import 'moment/locale/zh-cn'
import FormData from "../formData/formdata";

let sumReg = /^[0-9]*$/
class Tab1Banner extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                formList: [
                    { id: 999, basicsKey: 'goodsNumber', rules: [{ required: true, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容！") } else if (this.props.specialReg.test(value)) { cbfn("包含非法字符") } else if (!sumReg.test(value)) { cbfn("只能是数字！") } cbfn() } }], title: "商品编码", placeholder: "5279173125000", extra: "提交后，无法更改此字段" },
                    { id: 998, basicsKey: 'goodsName', rules: [{ required: true, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容！") } else if (this.props.specialReg.test(value)) { cbfn("包含非法字符") } cbfn() } }], title: "商品名称", placeholder: "Olympus Camedia C-50 Digital Camera", extra: "" },
                    { id: 997, basicsKey: 'goodsBrand', rules: [{ required: true, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容！") } else if (this.props.specialReg.test(value)) { cbfn("包含非法字符") } cbfn() } }], title: "品牌", placeholder: "Sonny Brook Hams", extra: "品牌注册成功后，若要发布商品，其品牌名称的拼写和大小写必须与提交申请品牌批准时完全一致。对于您在提交品牌注册申请时选择的关键属性，请指定一个唯一的值。之后，您可以通过‘管理库存’随时编辑商品详情。" }
                ],
                NumbetTypeList: [],
                isDisplayCode: true,
            },
            isTourOpen: true,
            steps1: [
                {
                    selector: '.tab1-header>div',
                    content: '打开高级视图',
                },
                {
                    selector: '.stepGuidanceBox1',
                    content: '输入商品编码，并选择编码类型，若提示商品编码重复，可在库存管理列表删除该商品',
                },
                // {
                //     selector: '.goodsNumberType',
                //     content: '选择UPC',
                // },
                {
                    selector: '.stepGuidanceBox2',
                    content: '输入商品标题',
                },
                {
                    selector: '.stepGuidanceBox4',
                    content: '输入商品品牌',
                },
                {
                    selector: '.stepGuidanceBox5',
                    content: '制造商与品牌相同（有品牌写品牌名，无品牌写店铺名）',
                },
                {
                    selector: '.stepGuidanceBox6',
                    content: '自定义零件编号',
                },

            ],
            isActive: false
        }
    }
    Switchse = (e) => {
        this.props.Switchse(e)
    }
    awakenTips = (type) => {
        this.setState({
            isActive: type
        })
    }
    componentDidMount() {
        if (this.props.id && String(this.props.id).includes('140')) {
            let list = this.state.steps1
            list.push({
                selector: '.stepGuidanceBox10',
                content: '一个listing包含多少个产品（如：8件套化妆品，此处填8）',
            },
            {
                selector: '.stepGuidanceBox11',
                content: '单位名称（如：8件套化妆品，此处填单位count）',
            })
        }
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            isDisplayCode: nextProps.isDisplayCode
        })
        if (nextProps.isSubmit) {
            this.form.validateFields().then((res) => {

            }).catch((errr) => {
                console.log(errr, "errr")
            })
        }

    }
    getFormRef = el => {
        this.form = el;
    }
    formChonge = () => {
        let { formList } = this.state.data;
        let content = this.form.getFieldsValue()
        let isValue = true;
        for (let items of formList) {
            for (let keys in content) {
                if (items.rules.length > 0) {
                    if (!content[items.basicsKey]) {
                        isValue = false;
                        return;
                    }
                }
            }
        }
        if (isValue) {
            this.props.submitData(content, 1)
        }
    }
    dataInfo = (data, type) => {
        this.props.submitData(data, type)
    }
    render() {
        let { data, isDisplayCode, steps1, isTourOpen, isActive } = this.state
        return <div className="tab1Banner-box">
            {/* 引导步骤 */}
            <TipsIcon clicks={this.awakenTips} />
            <Reactours routePath="Tab1-banner" isActive={isActive} clicks={this.awakenTips} steps={steps1} isOpen={isTourOpen} />
            <BannerHeader checked={this.props.isSenior} Switchse={this.Switchse} />
            <FormData id={this.props.id} classifyId={this.props.classifyId} goodsId={this.props.goodsId} isDisplayCode={isDisplayCode} type="1" isHighView={this.props.isSenior} dataInfo={this.dataInfo}></FormData>
        </div>
    }
}
export default connect(({ addProduct }) => {
    return addProduct;
})(Tab1Banner);