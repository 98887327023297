import {combineReducers} from 'redux';
import language from './language';
import loading from './loading';
import userInfo from './userInfo';
import addProduct from './addProduct'; //添加商品

export default combineReducers({
    language,
    loading,
    userInfo,
    addProduct,
})