import React from 'react';
import AJAX from "api/apis";
import "./Tab3-banner.css"
import TipsIcon from "components/tipsIcon"
import BannerHeader from "../Banner-header";
import Reactours from "components/reactours"
import moment from 'moment'
import 'moment/locale/zh-cn'
import FormData from "../formData/formdata";

moment.locale('zh-cn')
let sumReg = /^[0-9]*$/
class Tab3Banner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                formList: [],
                formList1: [
                    { id: 2, input_type: 'date', basicsKey: 'goodsSku', rules: [{ required: true, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容！") } else if (this.props.specialReg.test(value)) { cbfn("包含非法字符") } else if (!sumReg.test(value)) { cbfn("只能是数字！") } cbfn() } }], title: "时间", placeholder: "101MyCollectible1", extra: "提交后，无法更改此字段" },
                    { id: 999, input_type: 'number', basicsKey: 'goodsMoney', rules: [{ required: true, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容！") } else if (this.props.specialReg.test(value)) { cbfn("包含非法字符") } cbfn() } }], title: "数值类型", placeholder: "例如：50.00", extra: "" },
                    { id: 1000, input_type: 'select', basicsKey: 'goodsCondition', rules: [{ required: true, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容！") } cbfn() } }], title: "下拉框", placeholder: "-选择-", isSelect: true, basicsKeyValue: [{ id: 1, name: '全新' }, { id: 2, name: '二手；好' }, { id: 3, name: '二手；尚可' }, { id: 4, name: '二手；类似新品' }, { id: 5, name: '二手；非常好' }] },
                    { id: 2, input_type: 'select_more', basicsKey: 'goodsInventory', rules: [{ required: true, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容！") } else if (this.props.specialReg.test(value)) { cbfn("包含非法字符") } else if (!sumReg.test(value)) { cbfn("只能是数字！") } cbfn() } }], title: "多选下拉框", placeholder: "请输入库存" },
                    { id: 2, input_type: 'text', basicsKey: 'goodsInventory', rules: [{ required: true, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容！") } else if (this.props.specialReg.test(value)) { cbfn("包含非法字符") } else if (!sumReg.test(value)) { cbfn("只能是数字！") } cbfn() } }], title: "普通文本", placeholder: "请输入库存" },
                    { id: 2, input_type: 'textarea', basicsKey: 'goodsInventory', rules: [{ required: true, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容！") } else if (this.props.specialReg.test(value)) { cbfn("包含非法字符") } else if (!sumReg.test(value)) { cbfn("只能是数字！") } cbfn() } }], title: "文本域", placeholder: "请输入库存" },
                    { id: 2, input_type: 'text_more', basicsKey: 'goodsInventory', rules: [{ required: true, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容！") } else if (this.props.specialReg.test(value)) { cbfn("包含非法字符") } else if (!sumReg.test(value)) { cbfn("只能是数字！") } cbfn() } }], title: "多选文本框", placeholder: "请输入库存" },
                    { id: 2, input_type: 'price', basicsKey: 'goodsInventory', rules: [{ required: true, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容！") } else if (this.props.specialReg.test(value)) { cbfn("包含非法字符") } else if (!sumReg.test(value)) { cbfn("只能是数字！") } cbfn() } }], title: "价格", placeholder: "请输入库存" },
                    { id: 2, input_type: 'watt', basicsKey: 'goodsInventory', rules: [{ required: true, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容！") } else if (this.props.specialReg.test(value)) { cbfn("包含非法字符") } else if (!sumReg.test(value)) { cbfn("只能是数字！") } cbfn() } }], title: "瓦特", placeholder: "请输入库存" },
                    { id: 1000, input_type: 'capacity', basicsKey: 'goodsCondition', rules: [{ required: true, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容！") } cbfn() } }], title: "容量", placeholder: "-选择-", isSelect: true, basicsKeyValue: [{ id: 1, name: '全新' }, { id: 2, name: '二手；好' }, { id: 3, name: '二手；尚可' }, { id: 4, name: '二手；类似新品' }, { id: 5, name: '二手；非常好' }] },
                    { id: 1000, input_type: 'distribution_channels', basicsKey: 'goodsCondition', rules: [{ required: true, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容！") } cbfn() } }], title: "配送渠道", placeholder: "-选择-", isSelect: true, basicsKeyValue: [{ id: 1, name: '全新' }, { id: 2, name: '二手；好' }, { id: 3, name: '二手；尚可' }, { id: 4, name: '二手；类似新品' }, { id: 5, name: '二手；非常好' }] },
                    { id: 1000, input_type: 'weight', basicsKey: 'goodsCondition', rules: [{ required: true, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容！") } cbfn() } }], title: "重量", placeholder: "-选择-", isSelect: true, basicsKeyValue: [{ id: 1, name: '全新' }, { id: 2, name: '二手；好' }, { id: 3, name: '二手；尚可' }, { id: 4, name: '二手；类似新品' }, { id: 5, name: '二手；非常好' }] },
                    { id: 1000, input_type: 'size', basicsKey: 'goodsCondition', rules: [{ required: true, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容！") } cbfn() } }], title: "尺寸", placeholder: "-选择-", isSelect: true, basicsKeyValue: [{ id: 1, name: '全新' }, { id: 2, name: '二手；好' }, { id: 3, name: '二手；尚可' }, { id: 4, name: '二手；类似新品' }, { id: 5, name: '二手；非常好' }] },
                    { id: 1000, input_type: 'show_size', basicsKey: 'goodsCondition', rules: [{ required: true, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容！") } cbfn() } }], title: "显示尺寸", placeholder: "-选择-", isSelect: true, basicsKeyValue: [{ id: 1, name: '全新' }, { id: 2, name: '二手；好' }, { id: 3, name: '二手；尚可' }, { id: 4, name: '二手；类似新品' }, { id: 5, name: '二手；非常好' }] },
                ],
                NumbetTypeList: []
            },
            isDisplayCode: this.props.isDisplayCode,
            switchseChecked: false,
            isTourOpen: true,
            steps1: [
                {
                    selector: '.tab3-box .stepGuidanceBox16',
                    content: '填写限制订购最大此商品数量（可不填）',
                },
                {
                    selector: '.stepGuidanceBox23',
                    content: '选择配送渠道（自发货/FBA）',
                },
                {
                    selector: '.stepGuidanceBox24',
                    content: '填写SKU（一般自定义），若提示SUK重复，可在库存管理列表删除该商品',
                },
                {
                    selector: '.stepGuidanceBox26',
                    content: '填写此产品售价',
                },
                {
                    selector: '.stepGuidanceBox27',
                    content: '填写此商品可售库存数量',
                },
                {
                    selector: '.stepGuidanceBox31',
                    content: '选择此商品状况（如：全新/二手等）',
                }
            ],
            isActive: false
        }
    }
    getFormRef = el => {
        this.form = el;
    }
    awakenTips = (type) => {
        this.setState({
            isActive: type
        })
    }
    Switchse = (e) => {
        this.setState({
            switchseChecked: e
        })
        this.props.Switchse(e)
    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({
                isTourOpen: true
            })
        }, 500)
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.isSubmit) this.form.validateFields().then((res) => { })
        this.setState({
            isDisplayCode: nextProps.isDisplayCode
        })
    }
    formChonge = () => {
        let { formList } = this.state.data;
        let content = this.form.getFieldsValue()
        let isValue = true;
        for (let items of formList) {
            for (let keys in content) {
                if (items.rules.length > 0) {
                    if (!content[items.basicsKey]) {
                        isValue = false;
                        return;
                    }
                }
            }
        }
        if (isValue) this.props.submitData(content, 3)
    }
    dataInfo = (data, type) => {
        console.log(data);
        this.props.submitData(data, type)
    }
    render() {
        let { steps1, isTourOpen, isDisplayCode, isActive } = this.state
        return <div className="tab3-box">
            <BannerHeader checked={this.props.isSenior} id={this.props.id} Switchse={this.Switchse} />
            <FormData
                id={this.props.id}
                type="3"
                classifyId={this.props.classifyId}
                goodsId={this.props.goodsId}
                isDisplayCode={isDisplayCode}
                isHighView={this.props.isSenior}
                dataInfo={this.dataInfo}
            ></FormData>
            {/* 引导步骤 */}
            <TipsIcon clicks={this.awakenTips} />
            <Reactours routePath="Tab3-banner" isActive={isActive} clicks={this.awakenTips} steps={steps1} isOpen={isTourOpen} />

            {/* <div className="distribution-char">
                    <IconFont type="icon-jinggao1" className="distribution-char-icon" />
                    <span className="distribution-char-title">您已选择使用亚马逊配送该商品的订单。亚马逊街道您的商品后即可开始销售。使用亚马逊配送收费标准。<span className="tab1-crumbs-title">了解更多信息</span></span>
                </div> */}
        </div>

    }
}
export default Tab3Banner