import React from 'react'
import './addCoupons.css'
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import { Radio, Steps, Input, DatePicker, Space } from 'antd';
import { Link } from 'react-router-dom';
const { Step } = Steps;
export default class extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			current: 0,
			value: '',
			buttonContent: '继续下一步',
			addCouponsList: [
				{
					id: 1,
					imgs: '/assets/images/home/card-3.jpg',
					titles: 'Eco Friendly Fitness Exercise Mat with Non-Slip Textured Surface，Large Size Work',
					contents: 'Eco Friendly',
					proNsm: '1000',
					Projg: '--'
				},
				{
					id: 2,
					imgs: '/assets/images/home/card-3.jpg',
					titles: 'Eco Friendly Fitness Exercise Mat with Non-Slip Textured Surface，Large Size Work',
					contents: 'Eco Friendly',
					proNsm: '1000',
					Projg: '--'
				}
			],
			couponsList: []
		}

	}
	addCoupons = (index, item) => {
		let { couponsList, addCouponsList } = this.state;
		couponsList.push(item)
		addCouponsList.splice(index, 1)
		this.setState({
			couponsList: couponsList,
			addCouponsList: addCouponsList
		})

	}
	removeCoupons = (index) => {
		let { couponsList } = this.state;
		couponsList.splice(index, 1)
		this.setState({
			couponsList: couponsList,
		})
	}
	delCoupon = e => {
		this.setState({
			addCouponsList: []
		})
	}
	stepChange = e => {
		if (e == 3) {
			this.setState({
				buttonContent: '提交优惠券'
			})
		} else {
			this.setState({
				buttonContent: '继续下一步'
			})
		}
		this.setState({
			current: e
		})
	}
	DatePickeronChange = (date, dateString) => { }
	onNtst = e => {
		let { current } = this.state
		current = current + 1
		if (current < 3) {
			this.setState({
				current: current
			})
		} else {
			this.setState({
				buttonContent: '提交优惠券'
			})
			this.setState({
				current: current
			})
		}
	}
	topath = e => {
		window.location = "/Coupons"
	}
	render() {
		let { value, current, couponsList, addCouponsList, buttonContent } = this.state
		const steps = [
			{
				title: '搜索和添加商品',
				content: 'First-content',
			},
			{
				title: '预算和折扣',
				content: 'Second-content',
			},
			{
				title: '价目表和目标',
				content: 'Last-content',
			},
			{
				title: '查看并提交',
				content: 'Last-content',
			},
		];
		let leftInfo = addCouponsList.map((item, index) => {
			return <div className="buttomProItem">
				<div className="buttomProLift">
					<img src={require('@/assets/img/home/card-3.jpg').default}></img>
					<div>
						<p>{item.titles}</p>
						<p>{item.contents}</p>
						<div>
							<span>有货：{item.proNsm}</span>
							<span> 商品价格: {item.Projg}</span>
						</div>
					</div>
				</div>
				<div className="addCouponBtn" onClick={() => { this.addCoupons(index, item) }}>添加至优惠券</div>
			</div>
		})
		let rightInfo = couponsList.map((item1, index1) => {
			return <div className="buttomProItem">
				<div className="buttomProLift">
					<img src={item1.imgs}></img>
					<div>
						<p>{item1.titles}</p>
						<p>{item1.contents}</p>
						<div>
							<span style={{ color: 'rgb(0, 130, 150)', cursor: 'pointer' }}>有货：{item1.proNsm}</span>
							<span style={{ color: 'rgb(0, 130, 150)', cursor: 'pointer' }}> 商品价格: {item1.Projg}</span>
						</div>
					</div>
				</div>
				<div className="addCouponBtn" onClick={() => { this.removeCoupons(index1) }}>移除</div>
			</div>
		})
		let leftInfoFour = couponsList.map((item1, index1) => {
			return <div className="buttomProItem">
				<div className="buttomProLift">
					<img src={item1.imgs}></img>
					<div>
						<p>{item1.titles}</p>
						<p>{item1.contents}</p>
						<div>
							<span>有货：{item1.proNsm}</span>
							<span> 商品价格: {item1.Projg}</span>
						</div>
					</div>
				</div>
			</div>
		})
		let rightInfoFour = couponsList.map((item1, index1) => {
			return <div className="buttomProItem buttomProItem1">
				<div className="">
					<img src={item1.imgs}></img>
					<div>
						<p>Save $1 on</p>
						<p>100</p>
					</div>
					<div className="addCouponBtn receiveCoupons">领取优惠券</div>
				</div>
			</div>
		})
		return <div>
			<Header />
			<div>
				<div className="end05_myBody addCouponsBox">
					{/* <!-- 头部 --> */}
					<div className="stepBar">
						<Steps current={current} onChange={this.stepChange}>
							{steps.map(item => (
								<Step key={item.title} title={item.title} />
							))}
						</Steps>
						<div className="end05_myBody_header_title">
							<button type="button" onClick={current == 3 ? this.topath : this.onNtst}>{buttonContent}</button>
						</div>
					</div>
					{/* <!-- 内容 --> */}
					{
						current == 0 ?
							<div className="couponsConentBox">
								<div className="couponInfoLeft">
									<h2 className="couponTitle">商品</h2>
									<p className="couponDesc">在此处向优惠券添加商品。您最多可以向优惠券添加 200 个 ASIN。</p>
									<div className="end05_con_search_bar">
										<div>
											<svg t="1611557040850" className="end03_icon_search" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5752" width="16" height="16"><path d="M936.264796 854.339926 757.429759 675.519214c46.499034-63.167668 74.019831-141.124158 74.019831-225.397516 0-210.099089-170.944324-381.038296-381.054669-381.038296s-381.048529 170.939207-381.048529 381.038296c0 210.105229 170.939207 381.043413 381.048529 381.043413 84.275405 0 162.234965-27.519774 225.40468-74.018807l178.832991 178.818665c11.270694 11.264555 26.03598 16.902972 40.813544 16.902972 14.776542 0 29.54285-5.638417 40.818661-16.902972C958.801069 913.419486 958.801069 876.874151 936.264796 854.339926zM163.139567 450.121697c0-158.388357 128.86188-287.25126 287.256376-287.25126s287.261493 128.86188 287.261493 287.25126c0 158.390403-128.866996 287.252283-287.261493 287.252283S163.139567 608.512101 163.139567 450.121697z" p-id="5753" fill="#8a8a8a"></path></svg>
											<input type="text" placeholder="按名称或关键词搜索" />
											<select>
												<option value="关键词">关键词</option>
												<option value="SKU/ASIN">SKU/ASIN</option>
											</select>
											<button type="button">转至</button>
										</div>
										<div className="searchResultBox">
											<div>
												<span>0个结果</span>
												<span onClick={this.delCoupon} className="delSearch">清除搜索结果</span>
											</div>
											<select>
												<option value="选择">选择</option>
												<option value="名称">名称</option>
												<option value="ASIN">ASIN</option>
												<option value="商品价格">商品价格</option>

											</select>
										</div>
										<div className="buttomPro">{leftInfo}</div>
									</div>
									<div>
									</div>
								</div>
								<div className="couponInfoLeft couponInfoRight">
									<h2 className="couponTitle">已添加至优惠券</h2>
									<p className="couponDesc">管理优惠券上显示的商品和变体。</p>
									<div className="end05_con_search_bar">
										<div>
											<svg t="1611557040850" className="end03_icon_search" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5752" width="16" height="16"><path d="M936.264796 854.339926 757.429759 675.519214c46.499034-63.167668 74.019831-141.124158 74.019831-225.397516 0-210.099089-170.944324-381.038296-381.054669-381.038296s-381.048529 170.939207-381.048529 381.038296c0 210.105229 170.939207 381.043413 381.048529 381.043413 84.275405 0 162.234965-27.519774 225.40468-74.018807l178.832991 178.818665c11.270694 11.264555 26.03598 16.902972 40.813544 16.902972 14.776542 0 29.54285-5.638417 40.818661-16.902972C958.801069 913.419486 958.801069 876.874151 936.264796 854.339926zM163.139567 450.121697c0-158.388357 128.86188-287.25126 287.256376-287.25126s287.261493 128.86188 287.261493 287.25126c0 158.390403-128.866996 287.252283-287.261493 287.252283S163.139567 608.512101 163.139567 450.121697z" p-id="5753" fill="#8a8a8a"></path></svg>
											<input type="text" placeholder="按ASIN或关键词搜索" />
											<button type="button">转至</button>
										</div>
										<div className="searchResultBox">
											<div>
												<span>0个结果</span>
											</div>
											<select>
												<option value="选择">选择</option>
												<option value="名称">名称</option>
												<option value="ASIN">ASIN</option>
												<option value="商品价格">商品价格</option>
											</select>
										</div>
										<div className="buttomPro">{rightInfo}</div>
									</div>
									<div>
									</div>
								</div>
							</div> : current == 1 ?
								<div className="addCouponsTwo">
									<div className="couponInfoLeft">
										<h2 className="couponTitle">折扣</h2>
										<p className="couponDesc">输入您想对上一步中添加至优惠券的商品应用的折扣金额。</p>
										<Radio.Group className="addCouponsTwoRadio">
											<Radio checked={true} value="满减">满减</Radio>
											<Radio value="减免折扣">减免折扣</Radio>
										</Radio.Group>
										<div className="addCouponsTwoPrice">
											<Input prefix="￥" />
										</div>
										<div className="bottomQuestion">
											<p>您是否要将优惠券限制到每个买家兑换 1 次？</p>
											<Radio.Group className="addCouponsTwoRadio">
												<Radio value="是的，限制每个买家只能兑换一次">是的，限制每个买家只能兑换一次</Radio>
												<Radio checked={true} value="否，允许同一个买家多次兑换优惠券">否，允许同一个买家多次兑换优惠券</Radio>
											</Radio.Group>
										</div>
										<div>
										</div>
									</div>
									<div className="couponInfoLeft couponInfoRight">
										<h2 className="couponTitle">预算</h2>
										<div className="addCouponsTwoPrice">
											<Input prefix="￥" />
											<p style={{ fontWeight: "700" }}>$100.00最低</p>
										</div>
										<div style={{ fontWeight: 'bold', marginTop: '20px' }}>
											<p>优惠券预算没有硬性限制。</p>
											<p>优惠券预算仅供用于规划目的，超出预算应在预料之中。<span style={{ color: 'rgb(0, 130, 150)', cursor: 'pointer' }}>了解更多信息</span></p>
										</div>
										<div className="bottomDesc">
											<p>您的预算将分摊到以下 2 项费用中：</p>
											<ul>
												<li>您提供的折扣的同等金额（单位：USD）</li>
												<li>兑换费（每次兑换需支付 $0.60）</li>
											</ul>
											<p>当您优惠券的利用率达到 80% 时，它将被停用。</p>
											<p style={{ color: 'rgb(0, 130, 150)', cursor: 'pointer' }}>预算如何运作？</p>

										</div>
										<div>
										</div>
									</div>
								</div>
								: current == 2 ? <div className="addCouponsTwo addCouponsThree">
									<div className="couponInfoLeft">
										<h2 className="couponTitle">优惠券名称(买家可见内容)</h2>
										<p className="couponDesc">要获得更有效的优惠券名称，请选择一个能准确描述添加至优惠券的商品类别的定义。示例：“洗手液节省 15％”</p>
										<div className="addCouponsTwoPrice">
											<Input prefix="Save $1 on" />
											<span style={{ color: 'rgb(0, 130, 150)', cursor: 'pointer' }}>名称指南</span>
										</div>
										<h2 style={{ marginTop: '15px' }} className="couponTitle">目标买家 (可选)</h2>
										<div className="bottomDesc">您可以选择把优惠券的受众限制到下面的一个买家群体。如果您没有做出定位选择，所有买家都可以发现您的优惠券。如果您知道哪一买家群体更有可能兑换您的优惠券，把优惠券定位到这个买家群体可能会提高投资回报率。</div>
										<div className="bottomQuestion">
											<Radio.Group className="addCouponsTwoRadio">
												<Radio value="所有买家">所有买家</Radio>
												<Radio checked={true} value="Amazon Prime 计划的会员">Amazon Prime 计划的会员</Radio>
												<Radio checked={true} value="亚马逊学生计划的会员">亚马逊学生计划的会员</Radio>
												<Radio checked={true} value="亚马逊妈妈计划的会员">亚马逊妈妈计划的会员</Radio>
											</Radio.Group>
										</div>
										<div>
										</div>
									</div>
									<div className="couponInfoLeft couponInfoRight">
										<h2 className="couponTitle">安排优惠券并确定受众</h2>
										<p className="couponDesc">为您的优惠券选择一个在 1-90 天之间的持续时间。</p>
										<div>
											<p>开始时间</p>
											<Space direction="vertical">
												<DatePicker placeholder="请选择开始时间" onChange={this.DatePickeronChange} />
											</Space>
										</div>
										<div style={{ marginTop: '20px' }}>
											<p>结束时间</p>
											<Space direction="vertical">
												<DatePicker placeholder="请选择结束时间" onChange={this.DatePickeronChange} />
											</Space>
										</div>
										<div>
										</div>
									</div>
								</div> : <div className="couponsConentBox couponsConentFour">
									<div className="couponInfoLeft">
										<h2 className="couponTitle">查看并提交</h2>
										<div className="couponInfo">
											<div className="couponInfoItem">
												<div>
													<span>优惠券名称</span>
													<span>Save $1 on 100</span>
												</div>
												<div>
													<span>预算</span>
													<span>$100.00</span>
												</div>
												<div>
													<span>开始日期</span>
													<span>2021-03-09</span>
												</div>
												<div>
													<span>结束日期</span>
													<span>2021-03-26</span>
												</div>
												<div>
													<span>目标买家</span>
													<span>所有买家</span>
												</div>
												<div>
													<span>限制到每个买家兑换 1 次</span>
													<span>否</span>
												</div>
											</div>
											<h2 className="couponTitle">查看并提交</h2>
											<div>{couponsList.length}件商品</div>
											<div className="buttomPro">{leftInfoFour}</div>
										</div>
										<div>
										</div>
									</div>
									<div className="couponInfoLeft couponInfoRight">
										<p>优惠券预览</p>
										<div className="buttomPro buttomPro1">{rightInfoFour}</div>
										<div style={{ fontWeight: 'bold', marginTop: '15px' }}>仅供说明。实际的优惠券图片可能与网站上的图片不同。</div>
										<div>
										</div>
									</div>
								</div>}
					<Footer />
				</div>
			</div>
		</div>
	}
}