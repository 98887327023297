import React,{Component} from 'react'
import './DistributionPlan.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'


class DistributionPlan extends Component{
    render(){
        return(
            <div className="distribution_body">
                <Header/>
                <div className="distribution_head">
                    <div className="distribution_head_titel">配送计划</div>
                    <div>发现、注册并管理亚马逊的配送计划。</div>
                </div>

                <div className="distribution_middle">
                    <div className="distribution_middle_titel">我的计划</div>
                    <div className="distribution_middle_content">
                        <div className="distribution_middle_img"><img src={require('../../assets/images/distribution1.png').default} /></div>
                        <div>
                            <div  className="distribution_middle_font">订购省</div>
                            <div >已注册 0 件商品</div>
                        </div>
                    </div>
                </div>


                    <div className="distribution_bottomBox">
                        <div className="distribution_middle_titel">发现计划</div>
                        <div  className="distribution_bottom">
                            <div className="distribution_bottom_boxI">
                                <div className="distribution_middle_img"><img src={require('../../assets/images/distribution2.png').default} /></div>
                                <div>
                                    <div>
                                    <div className="distribution_middle_font">轻小商品计划</div>
                                    <div className="distribution_middle_fontII">降低成本、简化配送流程并扩大业务规模</div>
                                    </div>
                                    <div  className="distribution_bottom_label">
                                        <span>降低配送成本</span>
                                        <span>无最低购买要求</span>
                                        <div className="distribution_bottom_labelII">符合亚马逊Prime的要求</div>
                                        <div className="distribution_bottom_labelII">亚马逊商城交易保障</div>
                                    </div>
                                </div>
                            </div>
                                                                         
                            <div  className="distribution_bottom_boxI">
                                <div className="distribution_middle_img"><img src={require('../../assets/images/distribution3.png').default} /></div>
                                <div>
                                    <div>
                                    <div className="distribution_middle_font">亚马逊全球物流</div>
                                    <div className="distribution_middle_fontII">可以利用亚马逊通过海运或空运将库存运至海外。</div>
                                    </div>
                                    <div className="distribution_bottom_label">
                                        <span>全球扩展</span>
                                        <span>配送成本节省</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                <Footer/>
            </div>
        )
    }
}

export default DistributionPlan