import React, { useRef, useEffect, useCallback, useState } from 'react';
import AJAX from "api/apis";
import './../bill/bill.css'
import './deposit.less'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import Reactours from "components/reactours"
import TipsIcon from "components/tipsIcon"
import { Link } from 'react-router-dom';
import RegisterSteps from 'components/registerSteps';
import RegisterCollapse from 'components/registerCollapse';
import { Button, Input, Form, Select, Tooltip } from 'antd';
import { getMonth, jiguoLityList, getSellerNationality } from 'utils/regDate';
import { regSubmit, getStoreRegisterInfo } from 'utils/PublicLibrary';
import { CheckOutlined, InfoCircleOutlined, QuestionCircleFilled } from '@ant-design/icons';
export default (props) => {
    let formRef = useRef();
    let [loading, setLoading] = useState(false)
    let [pageIndex, setPageIndex] = useState(1)
    let [verificationBankAccount, setVerificationBankAccount] = useState()
    let [bankAccount, setBankAccount] = useState()
    let [isItValid, setIsItValid] = useState(false)
    const [isTourOpen, setIsTourOpen] = useState(true);
    let [isReturn, setIsReturn] = useState(props.location?.state?.isReturn || 0);
    const steps1 = [
        {
            selector: '.stepGuidanceBox1',
            content: '选择您收款账户的银行名称',
        },
        {
            selector: '.stepGuidanceBox2',
            content: '选择您收款账户所在国家',
        },
        {
            selector: '.stepGuidanceBox5',
            content: '法人的姓名，填写英文',
        },
        {
            selector: '.stepGuidanceBox6',
            content: '绑定的第三方收款平台号码/payoneer/连连支付等收款平台',
        },
        {
            selector: '.stepGuidanceBox7',
            content: '法人的银行卡账号',
        },
        {
            selector: '.stepGuidanceBox8',
            content: '再次输入法人的银行卡账号',
        }
    ];
    const [isActive, setIsActive] = useState(false);
    useEffect(() => {
        if (props.location.state) {
            setPageIndex(props.location.state.pageIndex || 1)
        }
    }, [])
    const awakenTips = (type) => {
        setIsActive(type);
    }
    const collapseList = {
        2: [
            {
                header: '什么是支付服务商计划？',
                html: '<p>支付服务商计划维护一个参加该计划的支付服务商列表，您可以通过该列表中的支付服务商接收您的亚马逊店铺销售收益。 <a>了解更多信息</a></p>'
            },
            {
                header: '未列出我的金融机构所在的国家/地区。我该怎么办？',
                html: '<p>亚马逊仅支持向以<a>下国家/地区/货币</a>付款。</p> '
            },
            {
                header: '什么是亚马逊全球收款服务？',
                html: '<p>亚马逊全球收款是一项可选服务。通过使用这项服务，亚马逊卖家可以在其账户与销售商品所在商城位于不同国家或地区的情况下，将销售收益款项存入其本地银行账户。请点击此处 <a>了解更多信息</a></p>'
            },
        ],
        1: [
            {
                header: '我为什么要提供我的银行账户？',
                html: '<p>需要银行账户才能接收亚马逊的付款。</p>'
            },
            {
                header: '我不是银行账户持有人,仍可以设置卖家账户吗?',
                html: '<p>否。账户持有人姓名必须与您银行账户上的姓名一致。此信息必须准确，才能收到您的付款。</p>'
            },
            {
                header: '以后可以更改付款方式吗？',
                html: '<p>您可以随时通过账户的设置选项卡下的账户信息提供新的银行账户或信用卡。</p>'
            },
            {
                header: '我没有信用卡，是否可以另一种付款方式支付月服务费？',
                html: '<p>我们不接受其他付款方式,如预付费信用卡、礼品券、支票或在线付款系统(如PayPal )。我们只接受以下信用卡: American Express.Diners Club, Discover, JCB, MasterCard和Visa。</p>'
            },
        ],
        3: [
            {
                header: '未列出我的金融机构所在的国家/地区。我该怎么办？',
                html: '<p>亚马逊仅支持向以下<a>国家/地区/货币</a>付款。</p>'
            },
            {
                header: '我不是银行账户持有人，仍可以设置卖家账户吗？',
                html: '<p>否。账户持有人姓名必须与您银行账户上的姓名一致。此信息必须准确，才能收到您的付款。</p>'
            },
            {
                header: '什么是亚马逊全球收款服务？',
                html: '<p>亚马逊全球收款是一项可选服务，如果该账户与销售商品的国家或地区不同，该服务允许亚马逊卖家将收入转入其本地银行账户。点击此处<a>了解更多信息</a>。</p>'
            },
        ]
    }
    const countrylity = getSellerNationality()
    const jiguoLity = jiguoLityList()
    let [billingAddress, setBillingAddress] = useState('')
    let getRegInfo = useCallback(() => {
        getStoreRegisterInfo().then((res) => {
            for (let item of res.data) {
                if (item.propertyValue) {
                    if (item.propertyKey == "deposit_state") {
                        formRef.current.setFieldsValue({
                            bankAddress: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "deposit_person_name") {
                        formRef.current.setFieldsValue({
                            CardholderNameC: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "deposit_remittance_number") {
                        formRef.current.setFieldsValue({
                            transitNumber: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "deposit_bank_number") {
                        formRef.current.setFieldsValue({
                            bankAccount: item.propertyValue,
                            verificationBankAccount: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "deposit_financial_institution") {
                        formRef.current.setFieldsValue({
                            institution: item.propertyValue,
                        })
                    }
                }
            }
        })
        AJAX.login.getStoreRegisterInfo([]).then((data) => {
            for (let item of data.data) {
                if (item.propertyValue) {
                    if (item.propertyKey == "seller_dwell_site") {
                        billingAddress = item.propertyValue
                        setBillingAddress(item.propertyValue)
                    }
                }
            }
        })
        if (isReturn) {
            AJAX.login.getStoreRegisterInfo([]).then((data) => {
                for (let item of data.data) {
                    if (item.propertyValue) {
                        if (item.propertyKey == "deposit_state") {
                            formRef.current.setFieldsValue({
                                bankAddress: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "deposit_person_name") {
                            formRef.current.setFieldsValue({
                                CardholderNameC: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "deposit_remittance_number") {
                            formRef.current.setFieldsValue({
                                transitNumber: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "deposit_bank_number") {
                            formRef.current.setFieldsValue({
                                bankAccount: item.propertyValue,
                                verificationBankAccount: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "deposit_financial_institution") {
                            formRef.current.setFieldsValue({
                                institution: item.propertyValue,
                            })
                        }
                    }
                }
            })
        }
    }, [billingAddress, isReturn])
    let nextEvent = useCallback((type = '') => {
        if (loading) return
        setLoading(true)
        formRef.current.validateFields().then(async (res) => {
            if (onSetIsItValid(res)) {
                setLoading(false)
                return
            }
            let arr = [
                { "propertyKey": "deposit_state", "propertyValue": res.bankAddress },
                { "propertyKey": "deposit_person_name", "propertyValue": res.CardholderNameC },
                { "propertyKey": "deposit_remittance_number", "propertyValue": res.transitNumber },
                { "propertyKey": "deposit_bank_number", "propertyValue": res.bankAccount },
                { "propertyKey": "deposit_financial_institution", "propertyValue": res.institution },
            ]
            let localArr = [
                { "propertyKey": "address_value", "propertyValue": res.address },
            ]
            if (type != 3) {
                regSubmit(arr, localArr, 'deposit', 5).then((data) => {
                    if (data.code == 1) {
                        setLoading(false)
                        props.history.push('/bill')
                    }
                }).catch(() => {
                    setLoading(false)
                })
            } else {
                setPageIndex(3)
                setLoading(false)
            }

        }).catch(err => {
            setLoading(false)
        });
    }, [billingAddress, loading, isItValid])
    let verificationBankAccountChange = useCallback((e) => {
        setVerificationBankAccount(e.target.value)
    }, [bankAccount, verificationBankAccount])
    let bankAccountChange = useCallback((e) => {
        setBankAccount(e.target.value)
    }, [verificationBankAccount, bankAccount])
    const onSetIsItValid = (res) => {
        let is = false
        if (res.termMonth && res.termYear) {
            let date = new Date();
            let currentYear = date.getFullYear(); //获取完整的年份(4位)
            let currentMonth = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
            let selectMonth = res.termMonth.split("月")[0];
            let selectYear = res.termYear.split("年")[0]
            if (currentYear < selectYear) {
                setIsItValid(false)
                is = false
            } else if (currentYear == selectYear) {
                if (selectMonth < currentMonth) {
                    setIsItValid(true)
                    is = true
                } else {
                    setIsItValid(false)
                    is = false
                }
            } else {
                setIsItValid(true)
                is = true
            }
        }
        return is
    }
    return (
        <div className="billBox deposit register_style">
            {/* 引导步骤 */}
            {
                pageIndex == 2 && <TipsIcon clicks={awakenTips} />
            }

            {
                pageIndex == 2 && <Reactours routePath="deposit" isActive={isActive} clicks={awakenTips} steps={steps1} isOpen={isTourOpen} />
            }
            <Header />
            <div style={{ display: 'flex' }}>
                <div className="a-section program-overview-values">
                    <RegisterSteps current={3} />
                    {
                        pageIndex == 1 ? (
                            <div className='fukuaninfo'>
                                <h5>付款信息</h5>
                                <div className='kat-divider'></div>
                                <div className="pi-instructions">您需要以下信息才能完成下一部分：</div>
                                <div className="pi-payment-instrument-details">
                                    <div className="pi-subheading">银行账户信息</div>
                                    <div className="pi-instruction">
                                        <CheckOutlined />
                                        <p >用于在您的银行账户和您的Amazon Payments账户之间轻松存款和取款。</p>
                                    </div>
                                    <div className="pi-instruction">
                                        <CheckOutlined />
                                        <p >您的银行账户应使用提供给亚马逊的<b>主要联系人</b>的名字或<b>企业名称</b>。</p>
                                    </div>
                                    <div className="pi-instruction">
                                        <CheckOutlined />
                                        <p >要验证您的银行账户，您需要您的<b>在线银行凭据</b>或向亚马逊提供<b>银行对账单。</b></p>
                                    </div>
                                    <div className='kat-divider'></div>
                                </div>
                                <div className="pi-payment-instrument-details">
                                    <div className="pi-subheading" data-lang="8">信用卡信息</div>
                                    <div className="pi-instruction">
                                        <CheckOutlined />
                                        <p data-code="3">用于支付月服务费的有效<b>信用卡号码</b></p>
                                    </div>
                                    <div className='kat-divider'></div>
                                </div>
                                <div className="operationBtn" style={{ textAlign: 'center' }}>
                                    <Button className='previousPage' type="primary">
                                        <Link to={{ pathname: '/sellerInfo', state: { isReturn: 1 } }}>返回</Link>
                                    </Button>
                                    <Button onClick={() => { setPageIndex(2); getRegInfo() }} className='next' type="primary">
                                        我了解
                                    </Button>
                                </div>
                            </div>
                        ) : null
                    }
                    <div className="CompanyAddress" style={pageIndex == 2 ? { display: 'block' } : { display: 'none' }}>
                        <h5>添加银行账户</h5>
                        <div className='kat-divider'></div>
                        <Form
                            ref={formRef}
                            initialValues={{
                                address: "1",
                                bankAddress: '美国'
                            }}
                        >
                            <div className="inpBox1">
                                <div className="depositInfo stepGuidanceBox1">
                                    <p>金融机构名称</p>
                                    <Form.Item name="institution" rules={[{ required: true, message: '未找到金融机构名称。请输入“其他”以继续。' }]}>
                                        <Select showArrow={false} style={{ width: '183px' }} showSearch placeholder="选择" options={jiguoLity} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="inpBox1">
                                <div className="depositInfo stepGuidanceBox2">
                                    <p>收款账户所在国家/地区</p>
                                    <Form.Item name="bankAddress" rules={[{ required: true, message: '请选择国家/地区' }]}>
                                        <Select showArrow={false} style={{ width: '183px' }} placeholder="选择国家/地区" options={countrylity} />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="depositInfo stepGuidanceBox5">
                                <div>
                                    <strong>账户持有人姓名 </strong>
                                    <Tooltip
                                        overlayClassName='register_tooltip' color="#fff"
                                        title="帐户持有人名称必须与您的银行帐户名称匹配。不要包括您的银行名称。例如: John Doe或XYZ公司。使用ISO基本拉丁字符匹配您的银行记录中的内容。此信息必须准确才能接收您的付款。">
                                        <QuestionCircleFilled style={{ marginLeft: '4px' }} />
                                    </Tooltip>
                                </div>
                                <Form.Item name="CardholderNameC" rules={[{
                                    required: true,
                                    validator: (rule, value, fun) => {
                                        let reg = /^[ a-zA-Zu4e00-u9fa5]+$/;
                                        if (!value) {
                                            fun('请输入英文')
                                        } else if (!reg.test(value)) {
                                            fun('持有人姓名验证失败')
                                        }
                                        fun()
                                    }
                                }]}>
                                    <Input placeholder="姓名应当和银行证件上的相同" maxLength="20" />
                                </Form.Item>
                            </div>
                            <div className="depositInfo stepGuidanceBox6">
                                <div>
                                    <strong>9位数的汇款路径号码 </strong>
                                    <Tooltip
                                        overlayClassName='register_tooltip' color="#fff"
                                        title="(联邦ABA编号)为自动票据交换所(ACH)或电子资金转帐(而不是电汇)提供一个转帐号。请与您的银行联系以获取帮助。">
                                        <QuestionCircleFilled style={{ marginLeft: '4px' }} />
                                    </Tooltip>
                                </div>
                                <Form.Item name="transitNumber" rules={[{
                                    required: true,
                                    validator: (rule, value, fun) => {
                                        let reg = /^[0-9]*$/;
                                        if (!value) {
                                            fun('请输入汇款号码')
                                        } else if (!reg.test(value) || value.length < 9) {
                                            fun('必须是九个数字')
                                        }
                                        fun()
                                    }
                                }]}>
                                    <Input placeholder="9位数" style={{ width: '183px' }} maxLength="9" />
                                </Form.Item>
                            </div>
                            <div className="inpBox1">
                                <div className="depositInfo stepGuidanceBox7">
                                    <div>
                                        <strong>银行账号 </strong>
                                        <Tooltip overlayClassName='register_tooltip' color="#fff" title="必须使您的帐户能够通过自动清算所(ACH)接收存款。请与您的银行联系以获取帮助。(最多17位数字)">
                                            <QuestionCircleFilled style={{ marginLeft: '4px' }} />
                                        </Tooltip>
                                    </div>
                                    <Form.Item
                                        name="bankAccount"
                                        rules={[{
                                            required: false,
                                            validator: (rule, value, fun) => {
                                                let reg = /^[0-9]*$/;
                                                if (!value) {
                                                    fun('请输入银行账号')
                                                } else if (verificationBankAccount && value != verificationBankAccount) {
                                                    fun('银行账号不一致')
                                                } else if (!reg.test(value) || value.length < 17) {
                                                    fun('必须是十七位数字')
                                                }
                                                fun()
                                            }
                                        }]}
                                    >
                                        <Input style={{ width: '183px' }} value={bankAccount} onChange={bankAccountChange} maxLength="17" />
                                    </Form.Item>
                                </div>
                                <div className="depositInfo stepGuidanceBox8">
                                    <p>重新输入银行账号</p>
                                    <Form.Item
                                        name="verificationBankAccount"
                                        rules={[{
                                            required: false,
                                            validator: (rule, value, fun) => {
                                                let reg = /^[0-9]*$/;
                                                if (!value) {
                                                    fun('请输入银行账号')
                                                } else if (bankAccount && value != bankAccount) {
                                                    fun('银行账号不一致')
                                                } else if (!reg.test(value) || value.length < 17) {
                                                    fun('必须是十七位数字')
                                                }
                                                fun()
                                            }
                                        }]}
                                    >
                                        <Input style={{ width: '183px' }} value={verificationBankAccount} onChange={verificationBankAccountChange} maxLength="17" />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="operationBtn" style={{ textAlign: 'center' }}>
                                <Button onClick={() => { setPageIndex(1) }} className='previousPage' type="primary">
                                    返回
                                </Button>
                                <Button onClick={() => { nextEvent(3) }} className='next' type="primary">
                                    验证银行账户
                                </Button>
                            </div>

                        </Form>
                    </div>
                    {
                        pageIndex == 3 ? (
                            <div className='fukuaninfo'>
                                <h5>银行账户验证处理中</h5>
                                <div className='kat-divider'></div>
                                <div className="pi-alert-box">
                                    <div className="pi-alert-title pi-completed-physical">
                                        <InfoCircleOutlined />
                                        <div>银行账户验证处理中</div>
                                    </div>
                                    <div className="pi-alert-content">您选择的金融机构不具备即时验证的资格。在稍后的注册过程中，您将需要上传银行对账单以完成手动银行账户验证。请单击“继续”以继续进行注册的后续步骤。</div>
                                </div>
                                <div className="operationBtn" style={{ textAlign: 'center' }}>
                                    <Button onClick={() => { setPageIndex(2); getRegInfo() }} className='previousPage' type="primary">
                                        返回
                                    </Button>
                                    <Button onClick={nextEvent} className='next' type="primary">
                                        继续
                                    </Button>
                                </div>
                            </div>
                        ) : null
                    }
                </div>
                <div className="rightCollapse">
                    <p >常见问题</p>
                    <RegisterCollapse list={collapseList[pageIndex]} />
                </div>
            </div>
            <Footer />
        </div>
    )
}