import React, { useRef, useEffect, useCallback, useState } from 'react';
import AJAX from "api/apis";
import cookieUtils from "utils/cookie";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUserInfo, login } from 'store/actions/login';
import IconFont from 'components/Icon/Icon'
import { Container, FormContainer, FormTitle, FormItemTop, KeepLogin, CreateAmazonAccount, BottomLine } from './style';
import { Form, Input, Button, Checkbox } from 'antd';
import HeaderImage from 'components/headerimage';
import getUrlData from "@/utils/getUrlData";
import './index.css'
const storeId = cookieUtils.getCookie('practiceStoreId')

const Login = ({ dispatch, history }) => {
    let formRef = useRef();
    let [loading, setLoading] = useState(false);
    let [propsRouter, setpropsRouter] = useState("/");
    useEffect(() => {
        getPcAmazonEmail()
        if (window.location.href) {
            let url = window.location.href.split("propsRouter=")[1]
            url = url.replace('/#', '')
            setpropsRouter(url)
            // console.log(url, window.location);
        }
    }, [])
    const handleLogin = useCallback(() => {
        if (loading) return

        formRef.current.validateFields().then(({ email, password }) => {
            let storeId = cookieUtils.getCookie('practiceStoreId')
            setLoading(true);
            login({ email, password, storeId }).then((res) => {
                // getUserInfo()
                // dispatch(getUserInfo).then(() => {
                // console.log(res, propsRouter);
                setTimeout(() => {
                    history.push(propsRouter);
                    // window.history.back();
                }, 1000)
                // })
            })
            setTimeout(() => {
                setLoading(false);
            }, 3000)
        })
    }, [propsRouter, storeId, loading])
    const getPcAmazonEmail = useCallback(() => {
        AJAX.login.getPcAmazonEmail().then((res) => {
            if (res.code == 1 && res.data) {
                setTimeout(() => {
                    formRef.current.setFieldsValue({
                        email: res.data.email,
                        password: res.data.pwd,
                    })
                }, 500)
            }
        })
    }, [])
    return <Container className='login'>
        <HeaderImage />
        <FormContainer>
            <FormTitle>登录</FormTitle>
            <Form ref={formRef}>
                <FormItemTop>
                    <p className='title'>地址邮箱</p>
                </FormItemTop>
                <Form.Item name="email" rules={[{
                    required: true,
                    validator: (rule, value, fun) => {
                        let reg = /^([a-zA-Z]|[0-9]|[_-])+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
                        if (!value) {
                            fun('请输入邮箱')
                        } else if (!reg.test(value)) {
                            fun('邮箱格式错误')
                        }
                        fun()
                    }
                }]}>
                    <Input placeholder="请输入邮箱" />
                </Form.Item>
                <FormItemTop>
                    <p className='title'>密码</p>
                    <p className='forgot'>忘记密码?</p>
                </FormItemTop>
                <Form.Item
                    name="password"
                    rules={[{
                        required: true, validator: (rule, value, fun) => {
                            if (!value) {
                                fun('请输入密码')
                            } else if (value.length < 6) {
                                fun('密码不得少于6位数')
                            }
                            fun()
                        }
                    }]}
                >
                    <Input.Password placeholder="至少输入6位字符" />
                </Form.Item>
                <Button className='next' type="primary" loading={loading} onClick={handleLogin}> 登录 </Button>
            </Form>
            <KeepLogin>
                <div>
                    <Checkbox />
                    <p>记住我的登录状态.</p>
                </div>
                <p>详情 <IconFont type="icon-zelvxuanzefeiyongdaosanjiaoxingfandui" style={{ fontSize: "12px", color: "#3d3d3d" }} /></p>
            </KeepLogin>
            <CreateAmazonAccount>
                <p>Amazon的新客户?</p>
                <Button>
                    <Link to='/register'>创建您的 Amazon 账户</Link>
                </Button>
            </CreateAmazonAccount>
        </FormContainer>
        <BottomLine>
            <div className="login_line"></div>
            <div className="login_foot">
                <div><Link to="">我要开店</Link></div>
                <div><Link to="">亚马逊物流</Link></div>
                <div><Link to="">Amazon Payments</Link></div>
                <div><Link to="">Amazon Advertising</Link></div>
                <div><Link to="">在欧洲销售</Link></div>
                <div><Link to="">在日本销售</Link></div>
                <div><Link to="">帮助</Link></div>
            </div>
            <p>版权所有© 1996-2022, 亚马逊公司或其关联公司</p>
        </BottomLine>
    </Container>
}

export default connect()(Login);