import React, { useRef, useEffect, useCallback, useState } from 'react';
import AJAX from "api/apis";
import { Container, } from './style';
import { Form, Input, Button, Checkbox } from 'antd';
import Item from './item'
import { Link } from 'react-router-dom';
import Reactours from "components/reactours";
import TipsIcon from "components/tipsIcon";

export default () => {
    const [isDeposit, setIsDeposit] = useState(false);
    const [isTaxation, setIsTaxation] = useState(false);
    const [isTours, setIsTours] = useState(false);
    useEffect(() => {
        getRegInfo()
    }, [])
    const getRegInfo = useCallback(() => {
        AJAX.login.getStoreRegisterProgress().then((res) => {
            const { isDeposit, isTaxation } = res.data
            setIsDeposit(isDeposit)
            setIsTaxation(isTaxation)
            setIsTours(true)
        })
    }, [])
    const steps1 = useCallback(() => {
        let arr = [];
        if (!isDeposit) {
            arr.push({
                selector: '.stepGuidanceBox0',
                content: '点击进入存款方式',
            })
        }
        if (!isTaxation) {
            arr.push({
                selector: '.stepGuidanceBox1',
                content: '点击进入税务信息',
            })
        }
        if (isDeposit && isTaxation) {
            arr.push({
                selector: '.header_set',
                content: '点击设置选择账户信息',
            })
        }
        return arr
    }, [isDeposit, isTaxation])
    const [isTourOpen, setIsTourOpen] = useState(true);
    const [isActive, setIsActive] = useState(false);
    const awakenTips = (type) => {
        setIsActive(type);
    }

    return <Container>
        <ul>
            <li className='card-1'>
                <Item title={'通知'}>
                    <div>
                        <p className='date'>十一月 25, 2020</p>
                        <p className='text'>技术问题通知</p>
                        <p className='more'>{'阅读更多 >'}</p>
                    </div>
                </Item>
            </li>
            <li className='card-2'>
                <Item title={'新闻'}>
                    <div>
                        <p className='date'>十一月 25, 2020</p>
                        <p className='text'>卖家平台技术问题</p>
                        <p className='more'>{'阅读更多 >'}</p>
                    </div>
                    <div>
                        <p className='date'>十一月 23, 2020</p>
                        <p className='text'>新增商品类型和属性</p>
                        <p className='more'>{'阅读更多 >'}</p>
                    </div>
                    <div>
                        <p className='date'>十一月 23, 2020</p>
                        <p className='text'>商品 ASIN 现已支持上传视频</p>
                        <p className='more'>{'阅读更多 >'}</p>
                    </div>
                </Item>
            </li>
            {
                !isDeposit ? (
                    <li className='card-11 stepGuidanceBox0'>
                        <Link to="/accountinfo/depositMethod">
                            <Item title={'存款方式'}>
                                <div className='image'>
                                    <p className='text'>您的存款方式缺失或无效</p>
                                    <div>要使用“我要开店"账户并接收付款，您需要一种有效的存款方式。</div>
                                    <p className='btn'>添加或更新存款方式</p>
                                </div>
                            </Item>
                        </Link>
                    </li>
                ) : null
            }

            <li className='card-11 yl'>
                <Link to="/accountinfo/returnSettings">
                    <Item title={'退货地址提醒'}>
                        <div className='image'>
                            <p className='text'>请查看您的退货地址</p>
                            <div>我们已将您的退货地址更新为您的公司地址。如果您需要更改退货地址，则可以进行更新。</div>
                            <p className='btn'>更新您的退货地址</p>
                        </div>
                    </Item>
                </Link>
            </li>
            {
                !isTaxation ? (
                    <li className='card-11 stepGuidanceBox1'>
                        <Link to={{ pathname: "/taxationInfo", state: { isReturn: 1 } }}>
                            <Item title={'注意'}>
                                <div className='image'>
                                    <p className='text'>您需要提供税务信息</p>
                                    <div>要开通“我要开店”账户，需要提供纳税身份信息。</div>
                                    <p className='btn'>提供纳税身份信息</p>
                                </div>
                            </Item>
                        </Link>
                    </li>
                ) : null
            }

            <li className='card-11 yl'>
                <Link to="/Advertisement">
                    <Item title={'需要采取操作'}>
                        <div className='image'>
                            <p className='text'>检查您的紧急联系电话</p>
                            <div>如果发生影响您能否销售商品的重大事件，我们会联系您。确保您的紧急电话号码准确无误，有助于我们联系到您。</div>
                            <p className='btn'>现在更新</p>
                        </div>
                    </Item>
                </Link>
            </li>
            <li className='card-3'>
                <Link to="/Advertisement">
                    <Item title={'展示型推广'}>
                        <div className='image'>
                            <p className='text'>吸引对您在亚马逊网站内外销售的商品感兴趣的顾客</p>
                            <div>
                                <img src={require('@/assets/img/home/card-3.jpg').default} />
                            </div>
                            <p className='btn'>创建广告活动</p>
                        </div>
                    </Item>
                </Link>
            </li>
            <li className='card-4'>
                <Link to="/addlisting">
                    <Item title={'添加更多商品'}>
                        <div className='image'>
                            <p className='text'>持续扩大您的商品目录</p>
                            <div>
                                <img src={require('@/assets/img/home/card-4.png').default} />
                            </div>
                            <p className='btn'>创建更多商品信息</p>
                        </div>
                    </Item>
                </Link>
            </li>
            <li className='card-5'>
                <Item title={'在全球发布'}>
                    <div className='image'>
                        <p className='text'>获取有关如何通过在全球发布来吸引数百万买家的帮助。</p>
                        <div>
                            <img src={require('@/assets/img/home/card-5.png').default} />
                        </div>
                        <p className='btn'>管理国际商品信息</p>
                    </div>
                </Item>
            </li>
            <li className='card-6'>
                <Item title={'卖家论坛'}>
                    <div>
                        <p className='date'>十一月 26, 2020</p>
                        <p className='text'>Seller Central technical issue</p>
                        <p className='more'>{'阅读更多 >'}</p>
                    </div>
                    <div>
                        <p className='date'>十一月 26, 2020</p>
                        <p className='text'>Back to normal!</p>
                        <p className='more'>{'阅读更多 >'}</p>
                    </div>
                </Item>
            </li>
            <li className='card-7'>
                <Item title={'亚马逊品牌分析'}>
                    <div className='image'>
                        <p className='text'>看看买家如何找到您的商品</p>
                        <div>
                            <img src={require('@/assets/img/home/card-7.jpg').default} />
                        </div>
                        <p className='btn'>查看热门搜索词</p>
                    </div>
                </Item>
            </li>
            <li className='card-8'>
                <Link style={{ color: "#fff" }} to="/Early">
                    <Item title={'获取更多评论'}>
                        <div className='image'>
                            <p className='text'>在早期评论者计划中注册更多商品</p>
                            <div>
                                <img src={require('@/assets/img/home/card-8.jpg').default} />
                            </div>
                            <p className='btn'>选择要注册的SKU</p>
                        </div>
                    </Item>
                </Link>
            </li>
            <li className='card-9'>
                <Item title={'无在售信息的亚马逊库存'}>
                    <p className='text'>您有无在售商品的亚马逊物流库存</p>
                    <div className='list1'>
                        <p className='number'>1</p>
                        <p className='intro'>无在售信息的商品总数</p>
                    </div>
                    <div className='list2'>
                        <p className='number'>1</p>
                        <p className='intro'>无在售信息超过 15 天的商品数量</p>
                    </div>
                    <p className='btn'>修复无在售信息的亚马逊库存</p>
                </Item>
            </li>
            <li className='card-10'>
                <Link style={{ color: "#fff" }} to="/register">
                    <Item title={'在亚马逊物流中测试更多商品'}>
                        <div className='image'>
                            <p className='text'>获享针对新选品的免费仓储、删除和退货处理</p>
                            <div>
                                <img src={require('@/assets/img/home/card-10.jpg').default} />
                            </div>
                            <p className='btn'>注册亚马逊物流新选品计划</p>
                        </div>
                    </Item>
                </Link>
            </li>
        </ul>
        {
            isTours && (
                <React.Fragment>
                    <TipsIcon clicks={awakenTips} />
                    <Reactours routePath="rengwuer" isActive={isActive} clicks={awakenTips} steps={steps1()} isOpen={isTourOpen} />
                </React.Fragment>
            )
        }

    </Container>
}