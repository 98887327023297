import Header from "components/home_header";
import {
  AButton,
  AText,
  ASelect,
  AInputNumber,
  ADatePicker,
  AInput,
  ACheckbox,
  ARadioGroup,
} from "@a-components/";
import { useState, useMemo, useCallback, useEffect } from "react";
import { Descriptions, Row, Col, Space } from "antd";
import { useExternal, useSafeState, useToggle } from "ahooks";
import { InfoOutlined, UpOutlined } from "@ant-design/icons";

import moment from "moment";
import Description from "@view/Promotions/children/Description";

import { Link } from "react-router-dom";

import MessageBox from "./../MessageBox";
import CardCollapsible from "./../CardCollapsible";

import PromotionTimeCard from "./../../PromotionTimeCard";
import { initValue as initTimeValue } from "./../../PromotionTimeCard/config";

import CategorySelector from "@view/Promotions/CategorySelector";
import Apis from "@api/apis";

const timeOptions = Array(25)
  .fill(null)
  .map((_, index, source) => {
    let time = "";
    if (index === 0) {
      time = "00:01";
    } else if (index === source.length - 1) {
      time = "23:59";
    } else {
      time = `${index.toString().padStart(2, "0")}:00`;
    }

    return {
      label: time,
      value: time,
    };
  });

const exchangeOptions = [
  {
    label: "一次结算中的一件商品",
    value: 1,
  },
  {
    label: "一次结算中的无限件商品",
    value: 2,
  },
  {
    label: "无限次结算中的无限件商品",
    value: 3,
  },
];

const promotionConditionOptions = [
  {
    label: "买家所购商品",
    render() {
      return (
        <Row>
          <Col span={4}>此商品的最低购买数量</Col>
          <Col span={3} offset={1}>
            1
          </Col>
        </Row>
      );
    },
  },
  {
    label: "须购买商品",
    key: "promotionGoodsId",
    render(value, { setState, store: { showTypeSelector } }) {
      return (
        <Row>
          <Col span={4} className="stepGuidanceBox0">
            {showTypeSelector({
              edit: () => (
                <CategorySelector
                  value={value}
                  onChange={(value) => setState({ promotionGoodsId: value })}
                  placeholder="选择类别"
                  className="w-100percent"
                ></CategorySelector>
              ),
              preview: () => (
                <CategorySelector
                  value={value}
                  placeholder="选择类别"
                  readOnly
                  className="w-100percent"
                ></CategorySelector>
              ),
            })}
          </Col>
          {showTypeSelector({
            edit: () => (
              <>
                <Col span={3} className="stepGuidanceBox1" offset={1}>
                  <Link to="/Promotions/ProductSelection">
                    创建新的商品选择
                  </Link>
                </Col>
                <Col>
                  <MessageBox message="影响者和联盟可能会发布您的促销消息以引导流量。参加此计划并不保证商品能够被投放。" />
                </Col>
              </>
            ),
            preview: () => null,
          })}
        </Row>
      );
    },
  },
  {
    label: "买家获得",
    key: "buyersGetValue",
    render(value, { setState, store: { showTypeSelector } }) {
      return (
        <Row>
          <Col span={4}>减免折扣%</Col>
          <Col className="stepGuidanceBox2" span={3} offset={1}>
            {showTypeSelector({
              edit: () => (
                <AInputNumber
                  value={value}
                  controls={false}
                  onChange={(e) => setState({ buyersGetValue: e })}
                ></AInputNumber>
              ),
              preview: () => value,
            })}
          </Col>
        </Row>
      );
    },
  },
  {
    label: "适用范围",
    render() {
      return "须购买商品";
    },
  },
];
const promotionMoreOptions = [
  {
    label: "优惠券类型",
    render(_, { store: { showTypeSelector } }) {
      return (
        <Row>
          <Col span={6}>无限制</Col>
          <Col>
            {showTypeSelector({
              edit: () => <MessageBox message="无限制促销代码没有数量限制。" />,
            })}
          </Col>
        </Row>
      );
    },
  },
  {
    label: "此促销代码的分享对象",
    key: "promotionShareObjFlag",
    render(value, { setState, store: { showTypeSelector } }) {
      return (
        <Row>
          <Col className="stepGuidanceBox9" span={6}>
            {showTypeSelector({
              edit: () => (
                <ACheckbox
                  checked={value}
                  onChange={(e) =>
                    setState({ promotionShareObjFlag: e.target.checked })
                  }
                >
                  亚马逊影响者和联盟
                </ACheckbox>
              ),
              preview: () => (value ? "是" : "否"),
            })}
          </Col>
          <Col>
            {showTypeSelector({
              edit: () => (
                <MessageBox message="影响者和联盟可能会发布您的促销消息以引导流量。参加此计划并不保证商品能够被投放。" />
              ),
              preview: () => null,
            })}
          </Col>
        </Row>
      );
    },
  },
  {
    label: "每位买家兑换多次",
    key: "buyersExchangeNum",
    render(value, { setState, record, store: { showTypeSelector } }) {
      return (
        <Row>
          <Col className="stepGuidanceBox10" span={6}>
            {showTypeSelector({
              edit: () => (
                <ARadioGroup
                  value={value}
                  onChange={(e) =>
                    setState({ buyersExchangeNum: e.target.value })
                  }
                  options={exchangeOptions}
                />
              ),
              preview: () =>
                exchangeOptions.find((v) => v.value === value)?.label ?? "",
              // modify: () => record.buyersExchangeNumName,
            })
            }
          </Col>
          <Col>
            {showTypeSelector({
              edit: () => (
                <MessageBox
                  className="mt-24"
                  message="影响者和联盟可能会发布您的促销消息以引导流量。参加此计划并不保证商品能够被投放。"
                />
              ),
              // modify: () => (
              //   <MessageBox
              //     message="影响者和联盟可能会发布您的促销消息以引导流量。参加此计划并不保证商品能够被投放。"
              //   />
              // ),
            })}
          </Col>
        </Row>
      );
    },
  },
  {
    label: "优惠码",
    key: "discountCode",
    render(value, { setState, store: { showTypeSelector } }) {
      return (
        <Row>
          <Col className="stepGuidanceBox13" span={5}>
            {showTypeSelector({
              edit: () => (
                <AInput
                  prefix={15}
                  value={value}
                  onChange={(e) => setState({ discountCode: e.target.value })}
                ></AInput>
              ),
              preview: () => `15${value}`,
            })}
          </Col>
          <Col offset={1}>
            {showTypeSelector({
              edit: () => (
                <MessageBox message="您可以自定义兑换码；它必须是8-12个字符，包括数字前缀。" />
              ),
            })}
          </Col>
        </Row>
      );
    },
  },
  {
    label: "查看消息",
    content: [
      {
        label: "结算显示文本",
        key: "settlementDisplayText",
      },
      {
        label: "商品详情页面显示文本",
        key: "goodsDetailsTextFirst",
      },
      {
        label: "条款和条件",
        key: "termsConditions",
      },
    ],
  },
];

const PromotionCreate = ({ showType, event$, onSubmit, operatorType }) => {
  const [dataSource, setDataSource] = useState({
    promotionType: 1,
    settlementDisplayText: "测试结算显示文本",
    goodsDetailsTextFirst: "测试商品详情页面显示文本",
    termsConditions: "测试条款和条件",
    buyersExchangeNum: 1,
    trackingCode: `社交媒体 ${moment().format("YYYY/MM/DD HH-mm-ss-SSS")}`,
    ...initTimeValue,
  });

  const showTypeSelector = useCallback(
    (arg) => {
      const _showType =
        operatorType === "modify" && showType === "edit" ? "modify" : showType;

      let value = arg[_showType] || arg[showType];

      if (typeof value === "function") {
        return value();
      }

      return value;
    },
    [operatorType, showType]
  );

  const getDiscountCode = useCallback(() => {
    return Apis.promotion.getDiscountCode();
  }, []);

  event$.useSubscription(({ type, payload }) => {
    switch (type) {
      case "submit":
        onSubmit?.(dataSource);
        break;
      case "resetDataSource":
        setDataSource(payload);
        break;
      case "reloadCode":
        getDiscountCode().then((res) => {
          if (res.code !== 1) return;
          setDataSource((pre) => {
            return {
              ...pre,
              trackingCode: `购买折扣 ${moment().format(
                "YYYY/MM/DD HH-mm-ss-SSS"
              )}`,
              discountCode: res.data,
            };
          });
        });
        break;
      default:
    }
  });

  const innerPromotionMoreOptions = useMemo(() => {
    if (showType === "edit") return promotionMoreOptions;

    return promotionMoreOptions
      .map((item) => {
        if (item.content?.length) {
          return item.render
            ? [{ ...item, content: undefined }, ...item.content]
            : item.content;
        }

        return item;
      })
      .flat();
  }, [showType]);

  return (
    <div>
      <CardCollapsible title="第1步:选择促销条件" className="mt-12">
        <Description
          bordered
          column={1}
          labelStyle={{ width: 240, textAlign: "right" }}
          options={promotionConditionOptions}
          dataSource={dataSource}
          onChange={setDataSource}
          store={{ showTypeSelector, operatorType }}
        ></Description>
      </CardCollapsible>

      <PromotionTimeCard
        businessType="media"
        showType={showType}
        value={dataSource}
        onChange={setDataSource}
        previewKeys={["trackingCode"]}
        operatorType={operatorType}
      />

      <CardCollapsible title="第3步:更多选项" className="mt-12">
        <Description
          bordered
          column={1}
          labelStyle={{ width: 240, textAlign: "right" }}
          options={innerPromotionMoreOptions}
          dataSource={dataSource}
          onChange={setDataSource}
          store={{ showTypeSelector, operatorType }}
        ></Description>
      </CardCollapsible>
    </div>
  );
};

export default PromotionCreate;
