import { SearchOutlined } from "@ant-design/icons";

import { Space, Spin } from "antd";
import { AButton, AText, AInput, ASelect } from "@a-components";
import { useCallback, useState } from "react";
import Apis from "@api/apis";
import style from "./style.module.css";

import GoodsItem from "./GoodsItem";
import useSearchGoods from "./useSearchGoods";
import classNames from "classnames";

const searchTypeOptions = [
  {
    label: "关键词",
    value: 1,
  },
  {
    label: "SKU/ASIN",
    value: 2,
  },
];

const searchTypeMapping = {
  1: {
    placeholder: "按名称或关键词搜索",
  },
  2: {
    placeholder: "按SKU或ASIN搜索",
  },
};
const sortOptions = [
  {
    label: "名称",
    value: 1,
  },
  {
    label: "ASIN",
    value: 2,
  },
  {
    label: "商品价格",
    value: 3,
  },
];

const sortFnMapping = {
  1: (list) => {
    return [...list].sort((a, b) => {
      return a.goodsName.localeCompare(b.goodsName);
    });
  },
  2: (list) => {
    return [...list].sort((a, b) => {
      return a.goodsAsin.localeCompare(b.goodsAsin);
    });
  },
  3: (list) => {
    return [...list].sort((a, b) => {
      return a.goodsMoney - b.goodsMoney;
    });
  },
};

const GoodsSearchAdd = ({
  className,
  selectedGoodsList = [],
  updateSelectedGoodsList,
}) => {
  const [searchType, setSearchType] = useState(1);

  const [remoteSortType, setRemoteSortType] = useState();
  const [localSortType, setLocalSortType] = useState();

  const remoteFetcher = useCallback(({ searchValue }) => {
    return Apis.goods.goodsCouponsList({
      searchType,
      searchValue,
    });
  }, [searchType]);

  const remoteSearch = useSearchGoods({ fetcher: remoteFetcher });

  const localSearch = useSearchGoods({ list: selectedGoodsList });

  const realGoodsList = useCallback(
    (type) => {
      const typeMapping = {
        remote: {
          list: remoteSearch.searchResult,
          sortType: remoteSortType,
        },
        local: {
          list: localSearch.searchResult,
          sortType: localSortType,
        },
      };

      const { list, sortType } = typeMapping[type];

      const sortFn = sortFnMapping[sortType] || ((v) => v);

      return sortFn(list);
    },
    [
      localSearch.searchResult,
      localSortType,
      remoteSearch.searchResult,
      remoteSortType,
    ]
  );

  const handleAdd = useCallback(
    (goodsItem) => {
      const isInclude = !!selectedGoodsList.find(
        (v) => v.goodsId === goodsItem.goodsId
      );

      if (!isInclude) {
        updateSelectedGoodsList?.([...selectedGoodsList, goodsItem]);
      }

      remoteSearch.removeGoodsItem(goodsItem.goodsId);
    },
    [remoteSearch, selectedGoodsList, updateSelectedGoodsList]
  );

  const handleRemove = useCallback(
    (goodsItem) => {
      updateSelectedGoodsList(
        selectedGoodsList.filter((v) => v.goodsId !== goodsItem.goodsId)
      );
    },
    [selectedGoodsList, updateSelectedGoodsList]
  );

  return (
    <div className={classNames(className, "df")}>
      <div
        className="f-1 border-r-ccc pr-24"
        style={{ maxWidth: 520, minHeight: 520 }}
      >
        <div className="mb-12 fs-18 fw-600">商品</div>
        <div className="mb-12 fs-14">
          在此处向优惠券添加商品。您最多可以向优惠券添加 200 个 ASIN。
        </div>
        <div className="df mb-24">
          <AInput
            placeholder={searchTypeMapping[searchType].placeholder}
            prefix={<SearchOutlined />}
            value={remoteSearch.searchValue}
            onChange={(e) => remoteSearch.setSearchValue(e.target.value)}
          />
          <ASelect
            style={{ width: 100 }}
            className="ml-12"
            value={searchType}
            options={searchTypeOptions}
            onChange={setSearchType}
          />
          <AButton onClick={remoteSearch.handleSearch}>转至</AButton>
        </div>

        <div>
          <div className="df jc-sb ai-c pb-12 mb-12 border-b-ccc">
            <div>
              <Space>
                <AText>{remoteSearch.searchResult.length}个结果</AText>
                {remoteSearch.isSearching ? (
                  <AButton
                    type="link"
                    className="p-0 h-12 fs-12"
                    onClick={remoteSearch.clearSearch}
                  >
                    清除搜索结果
                  </AButton>
                ) : null}
              </Space>
            </div>

            <Space className="df ai-c">
              <AText>排序方式: </AText>
              <ASelect
                allowClear
                style={{ width: 120 }}
                placeholder="选择"
                options={sortOptions}
                value={remoteSortType}
                onChange={setRemoteSortType}
              />
            </Space>
          </div>

          <Spin spinning={remoteSearch.isLoading}>
            <div className={style.goodsList}>
              {realGoodsList("remote").map((item) => {
                return (
                  <GoodsItem
                    key={item.goodsId}
                    info={item}
                    extra={
                      <AButton onClick={() => handleAdd(item)}>
                        添加优惠券
                      </AButton>
                    }
                  />
                );
              })}
            </div>
          </Spin>
        </div>
      </div>
      <div className="f-1 pl-24" style={{ maxWidth: 520 }}>
        <div className="mb-12 fs-18 fw-600">已添加至优惠券</div>
        <div className="mb-12 fs-14">管理优惠券上显示的商品和变体。</div>

        <div className="df mb-24">
          <AInput
            placeholder="按ASIN或名称搜索"
            prefix={<SearchOutlined />}
            value={localSearch.searchValue}
            onChange={(e) => localSearch.setSearchValue(e.target.value)}
          />
          <AButton className="ml-12" onClick={localSearch.handleSearch}>
            转至
          </AButton>
        </div>

        <div>
          <div className="df jc-sb ai-c pb-12 mb-12 border-b-ccc">
            <div>
              {localSearch.isSearching ? (
                <>
                  <AText>
                    显示 {localSearch.searchResult.length} 件商品（共{" "}
                    {selectedGoodsList.length} 件）
                  </AText>
                  <AButton
                    type="link"
                    className="p-0 h-12 fs-12"
                    onClick={localSearch.clearSearch}
                  >
                    清除搜索结果
                  </AButton>
                </>
              ) : (
                <AText>{localSearch.searchResult.length} 件商品</AText>
              )}
            </div>

            <Space className="df ai-c">
              <AText>排序方式: </AText>
              <ASelect
                allowClear
                style={{ width: 120 }}
                placeholder="选择"
                options={sortOptions}
                value={localSortType}
                onChange={setLocalSortType}
              />
            </Space>
          </div>

          <div className={style.goodsList}>
            {realGoodsList("local").map((item) => {
              return (
                <GoodsItem
                  key={item.goodsId}
                  info={item}
                  extra={
                    <AButton onClick={() => handleRemove(item)}>移除</AButton>
                  }
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoodsSearchAdd;
