import { useMemo } from "react";
import { AButton, ATableForm, AText } from "@a-components";

const negativeDirectionalTypeMapping = {
  1: "品牌",
  2: "商品",
};

const ExcludedList = ({ dataSource, onChange }) => {
  const columns = useMemo(() => {
    return [
      {
        title: "负面目标",
        width: "90%",
        render(_, { row }) {
          const title =
            negativeDirectionalTypeMapping[row.negativeDirectionalType];
          const isBrand = row.negativeDirectionalType === 1;

          return (
            <div>
              <div>
                <AText strong>{title}</AText>: {row.directionalName}
              </div>
              {isBrand ? null : (
                <>
                  <AText>ASIN</AText>: {row.goodsAsin}
                </>
              )}
            </div>
          );
        },
      },
      {
        title: ({ clearAll }) => {
          return (
            <AButton disabled={!dataSource.length} onClick={clearAll}>
              删除所有
            </AButton>
          );
        },
        render(_, { remove }) {
          return <AButton onClick={remove}>删除</AButton>;
        },
      },
    ];
  }, [dataSource]);

  return (
    <div>
      <AText strong>{dataSource.length} 添加的排除对象</AText>
      <ATableForm
        dataSource={dataSource}
        columns={columns}
        onChange={onChange}
      ></ATableForm>
    </div>
  );
};

export default ExcludedList;
