import React from 'react'
import './MengkHeader.css'

function MengkHeader({ value = false }) {

  return (
    <div className="MengkHeader" >

      {
        value ? <span></span> : <div>【品牌旗舰店】</div>
      }
      <img src={require("@assets/images/Mengk-header.png").default} ></img>
    </div>
  )
}

export default MengkHeader