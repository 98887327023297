import Header from "components/home_header";
import Footer from "components/addlisting_footer/FooterCom";
import { AButton, AInput, ASelect, AText } from "@a-components/";
import useQuery from "@hooks/useQuery";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Space } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import CategorySelector from "./../CategorySelector";
import Description from "./../children/Description";
import style from "./style.module.css";

import Apis from "@api/apis";
import { useHistory } from "react-router";

const typeListOptions = [
  {
    label: "SKU列表",
    value: 1,
  },
  {
    label: "ASIN列表",
    value: 2,
  },
  {
    label: "浏览分类节点编号列表",
    value: 3,
  },
  {
    label: "品牌名称列表",
    value: 4,
  },
  {
    label: "高级商品列表",
    value: 5,
  },
];

const typeMapping = {
  1: {
    title: "SKU 列表",
    options: [
      {
        label: "SKU 列表",
        key: "goodsList",
        render(value, { setState }) {
          return (
            <AInput.TextArea
              placeholder="最多可添加4000个SKU，每行一个"
              value={value}
              onChange={(e) => setState({ goodsList: e.target.value })}
              rows={5}
            />
          );
        },
      },
    ],
  },
  2: {
    title: "ASIN 列表",
    options: [
      {
        label: "ASIN 列表",
        key: "goodsList",
        render(value, { setState }) {
          return (
            <AInput.TextArea
              placeholder="通过输入或者剪切并粘贴的方式，最多可向此列表添加4000个ASIN。ASIN之间可以换行、空格、逗号、或分号分隔。"
              value={value}
              onChange={(e) => setState({ goodsList: e.target.value })}
              rows={8}
            />
          );
        },
      },
    ],
  },
  3: {
    title: "浏览分类节点编号列表",
    options: [
      {
        label: "浏览分类节点编号列表",
        key: "goodsList",
        render(value, { setState }) {
          return (
            <AInput.TextArea
              placeholder="通过输入或者剪切并粘贴的方式，最多可向此列表添加 250 个浏览分类节点编号。浏览分类节点编号之间可以换行、空格、逗号、或分号分隔。"
              value={value}
              onChange={(e) => setState({ goodsList: e.target.value })}
              rows={8}
            />
          );
        },
      },
    ],
  },
  4: {
    title: "品牌名称列表",
    options: [
      {
        label: "品牌名称列表",
        key: "goodsList",
        render(value, { setState }) {
          return (
            <AInput.TextArea
              placeholder="最多添加 250 个品牌名称，每行一个。"
              value={value}
              onChange={(e) => setState({ goodsList: e.target.value })}
              rows={8}
            />
          );
        },
      },
    ],
  },
  5: {
    title: "高级商品列表",
    options: [
      {
        label: "Product Selection",
        key: "goodsList",
        render(value, { setState }) {
          return (
            <CategorySelector
              style={{ width: 240 }}
              value={Number(value) || undefined}
              onChange={(e) => setState({ goodsList: e })}
            ></CategorySelector>
          );
        },
      },
    ],
  },
};

const isListOptions = [
  {
    label: "在列表中",
    value: true,
  },
  {
    label: "不在列表中",
    value: false,
  },
];

const includesOptions = [
  {
    label: "所有",
    value: 1,
  },
  {
    label: "任何",
    value: 2,
  },
];

const strListParser = (str) => {
  return str.split(/[\n\s+；;,，]/g).filter(Boolean);
};

const ProduceSelectionCreate = () => {
  const { type, promotionGoodsId } = useQuery();
  const history = useHistory();
  const [mainDataSource, setMainDataSource] = useState({
    goodsListType: Number(type),
    goodsList: "",
  });

  const isEdit = Boolean(promotionGoodsId);

  const [highGoodsOptionList, setHighGoodsOptionList] = useState([]);
  const [selectedType, setSelectedType] = useState(1);
  const [includesType, setIncludesType] = useState(1);

  const updateHighGoodsOptionItem = useCallback((key, payload) => {
    setHighGoodsOptionList((oldList) => {
      return oldList.map((item) => {
        if (item.key === key) {
          return {
            ...item,
            ...payload,
          };
        }
        return item;
      });
    });
  }, []);

  const createOptionItem = useCallback(() => {
    setHighGoodsOptionList((pre) => {
      return [
        ...pre,
        {
          key: String(Date.now()),
          inList: true,
          value: selectedType === 5 ? undefined : "",
          createType: selectedType,
        },
      ];
    });
  }, [selectedType]);

  const removeOptionItem = useCallback((key) => {
    setHighGoodsOptionList((pre) => {
      return pre.filter((v) => v.key !== key);
    });
  }, []);

  const addPromotionGoods = useCallback((params) => {
    return Apis.promotion.addPromotionGoods(params);
  }, []);

  const updatePromotionGoods = useCallback((params) => {
    return Apis.promotion.updatePromotionGoods(params);
  }, []);

  const handleSubmit = useCallback(() => {
    let isReturn = false;
    let fetcher = promotionGoodsId ? updatePromotionGoods : addPromotionGoods;
    const { sn, internalDescription, goodsListType, goodsList } =
      mainDataSource;
    const params = {
      promotionGoodsId,
      name: sn,
      internalDescription: internalDescription,
      goodsListType,
      goodsList: strListParser(String(goodsList)),
      addPromotionGoodsHeightReqList: highGoodsOptionList.map((v) => {
        return {
          containFlag: v.inList ? 1 : 2,
          goodsList: strListParser(String(v.value || '')),
          goodsListType: v.createType,
        };
      }),
      accordCondition: includesType,
    };

    if (params.goodsListType == 5) {
      if (params.addPromotionGoodsHeightReqList.length > 0) {
        params.addPromotionGoodsHeightReqList.forEach(item => {
          if (item.goodsList.length <= 0) {
            isReturn = true
          }
        })
      }
    } else {
      if (params.goodsList.length <= 0 || !params.internalDescription || !params.name){
        isReturn = true
      }
    }
    if (isReturn) return
    // console.log(params)
    fetcher(params).then((res) => {
      if (res.code === 1) {
        // history.goBack();
        history.push(`/Promotions?tab=goods`)
      }
    });
  }, [
    addPromotionGoods,
    highGoodsOptionList,
    history,
    includesType,
    mainDataSource,
    promotionGoodsId,
    updatePromotionGoods,
  ]);

  const getPromotionGoodsDetail = useCallback(() => {
    return Apis.promotion
      .getPromotionGoodsDetail({
        promotionGoodsId,
      })
      .then((res) => {
        if (res.code !== 1) return res;
        const {
          goodsList,
          name,
          promotionGoodsHeightVoList,
          internalDescription,
        } = res.data;
        setMainDataSource((pre) => ({
          ...pre,
          sn: name,
          internalDescription,
          goodsList: (goodsList || []).join("\n"),
        }));

        setHighGoodsOptionList(
          (promotionGoodsHeightVoList || []).map((item) => {
            return {
              inList: item.containFlag === 1,
              value: (item.goodsList || []).join("/n"),
              createType: item.goodsListType,
            };
          })
        );
      });
  }, [promotionGoodsId]);

  useEffect(() => {
    promotionGoodsId && getPromotionGoodsDetail();
  }, [promotionGoodsId, getPromotionGoodsDetail]);

  const highGoodsOption = useMemo(() => {
    const innerOptions = highGoodsOptionList.map((item) => {
      const { createType, inList, value, key } = item;
      const cur = typeMapping[createType];

      const update = (value) => {
        updateHighGoodsOptionItem(key, { value: Object.values(value)[0] });
      };
      const select = (value) => {
        updateHighGoodsOptionItem(key, { inList: value });
      };

      return {
        label: (
          <div className="df fd-c ai-fe">
            <AText strong>{cur.title}</AText>
            {isEdit ? (
              <div>
                {isListOptions.find((v) => v.value === inList)?.label ?? ""}
              </div>
            ) : (
              <ASelect
                options={isListOptions}
                value={inList}
                onChange={select}
              ></ASelect>
            )}
          </div>
        ),
        render() {
          return (
            <div className="df w-100percent ai-c">
              <div className="f-1">
                {cur.options[0].render(value, { setState: update })}
              </div>
              <CloseOutlined
                className="ml-12"
                onClick={() => removeOptionItem(key)}
              />
            </div>
          );
        },
      };
    });

    return {
      label: "",
      render(_, { store: { isEdit } }) {
        return (
          <div>
            <Space className="mb-12">
              包括符合下列
              {isEdit ? (
                includesOptions.find((v) => v.value === includesType)?.label ??
                ""
              ) : (
                <ASelect
                  style={{ width: 80 }}
                  options={includesOptions}
                  value={includesType}
                  onChange={setIncludesType}
                />
              )}
              项条件的商品
            </Space>

            {innerOptions.length ? (
              <Description
                labelStyle={{
                  width: 240,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                options={innerOptions}
              ></Description>
            ) : null}
          </div>
        );
      },
    };
  }, [
    highGoodsOptionList,
    includesType,
    isEdit,
    removeOptionItem,
    updateHighGoodsOptionItem,
  ]);

  const options = useMemo(() => {
    const { title, options } = typeMapping[type];

    const _options = options.map((item) => {
      if (mainDataSource.goodsListType === 5) {
        return highGoodsOption;
      }

      return item;
    });

    return [
      {
        label: "商品类表类型",
        render() {
          return title;
        },
      },
      {
        label: "商品选择列表名称/追踪编码",
        key: "sn",
        render(value, { setState, store: { isEdit } }) {
          return isEdit ? (
            value
          ) : (
            <AInput
              value={value}
              onChange={(e) => setState({ sn: e.target.value })}
              placeholder="示例：BLUE_SHOES_08。该信息用于追踪目的。"
            ></AInput>
          );
        },
      },
      {
        label: "内部描述",
        key: "internalDescription",
        render(value, { setState }) {
          return (
            <AInput
              value={value}
              onChange={(e) =>
                setState({ internalDescription: e.target.value })
              }
              placeholder="不会向买家显示此商品信息的描述。"
            ></AInput>
          );
        },
      },
      ..._options,
      {
        label: "使用下列工具添加其他条件",
        $visible(_, { store: { isEdit } }) {
          return mainDataSource.goodsListType === 5 && !isEdit;
        },
        render() {
          return (
            <div>
              <ASelect
                className="mr-24"
                style={{ width: 180 }}
                options={typeListOptions}
                value={selectedType}
                onChange={setSelectedType}
              ></ASelect>
              <AButton onClick={createOptionItem}>添加其他</AButton>
            </div>
          );
        },
      },
    ];
  }, [
    createOptionItem,
    highGoodsOption,
    mainDataSource.goodsListType,
    selectedType,
    type,
  ]);

  const mainBtnDisabled = useMemo(() => {
    return type === "5" && !highGoodsOptionList.length;
  }, [highGoodsOptionList.length, type]);

  return (
    <div className={style.produceSelectionCreate}>
      <Header />
      <div className="p-24">
        <div className="fs-32 mb-24">{isEdit ? "编辑" : "创建"}商品列表</div>

        <AButton onClick={() => history.push(`/Promotions?tab=goods`)}>
          管理商品列表
        </AButton>

        <Description
          className="mt-24"
          bordered
          options={options}
          labelStyle={{ width: 240, textAlign: "right" }}
          dataSource={mainDataSource}
          onChange={setMainDataSource}
          store={{ isEdit }}
        ></Description>

        <AButton type="primary" className="mt-12" onClick={handleSubmit} disabled={mainBtnDisabled}>
          提交
        </AButton>
      </div>
      <Footer />
    </div>
  );
};

export default ProduceSelectionCreate;
