
import React, { useRef, useEffect, useCallback, useState } from 'react';
import AJAX from "api/apis";
import './Authentication.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import Reactours from "components/reactours"
import TipsIcon from "components/tipsIcon"
import '../default.css/default.css'
import { Button, Radio, Form, Select } from 'antd';
import { getSellerNationality } from '../../utils/regDate';
import { regSubmit, getStoreRegisterInfo } from '../../utils/PublicLibrary';


export default (props) => {
    let formRef = useRef();
    const [isTourOpen, setIsTourOpen] = useState(true);
    const steps1 = [
        {
            selector: '.stepGuidanceBox0',
            content: '选择营业执照上的国家/地区',
        },
        {
            selector: '.stepGuidanceBox1',
            content: '选择销售计划/是否有营业执照',
        }
    ];
    const [isActive, setIsActive] = useState(false);
    const awakenTips = (type) => {
        setIsActive(type);
    }
    const countrylity = getSellerNationality()
    useEffect(() => {

        getRegInfo()
    }, [])
    let getRegInfo = useCallback(() => {
        getStoreRegisterInfo().then((res) => {
            for (let item of res.data) {
                if (item.propertyValue) {
                    if (item.propertyKey == "enterprise_state") {
                        formRef.current.setFieldsValue({
                            whereCountry: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "is_business_license") {
                        formRef.current.setFieldsValue({
                            role: item.propertyValue
                        })
                    }
                }
            }
        })
    }, [])
    let nextEvent = useCallback(() => {
        formRef.current.validateFields().then((res) => {
            let arr = [
                { "propertyKey": "enterprise_state", "propertyValue": res.whereCountry },
                { "propertyKey": "is_business_license", "propertyValue": res.role },
            ]
            regSubmit(arr, null, 'Authentication', 11).then((data) => {
                if (data.code == 1) {
                    props.history.push('/AuthenticationTwo')
                }
            })
        })
    }, [])
    return (
        <div className="AuthenticationBox">
            <TipsIcon clicks={awakenTips} />
            {/* 引导步骤 */}
            <Reactours steps={steps1} isActive={isActive} clicks={awakenTips} routePath="Authentication" isOpen={isTourOpen} />
            <Header />
            <div style={{ display: 'flex' }}>
                <div className="a-section program-overview-values">
                    <div className="ng-scope a-size-medium">身份验证</div>
                    <div className="ng-scope a-size-small">在我们激活您的卖家账户前，请帮助我们验证您的身份</div>
                    <div className="help">帮助</div>
                    <div className="CompanyAddress">
                        <Form ref={formRef}>
                            <div>
                                <p style={{ fontWeight: 'bold' }}>选择您的企业所在国家/地区</p>
                                <Form.Item className="stepGuidanceBox0" name="whereCountry" rules={[{ required: true, message: '请选择国家/地区' }]}>
                                    <Select placeholder="选择国家/地区" options={countrylity} />
                                </Form.Item>
                                <p style={{ fontWeight: 'bold', paddingTop: '15px', marginBottom: 0 }}>你是否代表企业管理账户</p>
                                <Form.Item className="stepGuidanceBox1" name="role" rules={[{ required: true, message: '请选择是否代表企业管理账户' }]}>
                                    <Radio.Group className="AuthenticationRadio">
                                        <Radio value="1">不，我是个人卖家。我没有营业执照。</Radio>
                                        <Radio value="2">是，我代表企业管理此账户。我有营业执照。</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <hr aria-hidden="true" className="a-spacing-top-large a-divider-normal" />
                                <div style={{ textAlign: 'center' }}>
                                    <div style={{ color: 'red' }}>注意：点击“下一步”后，您将无法更改此页面的内容。</div>
                                    <Button onClick={nextEvent} className='next' type="primary">下一步</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}