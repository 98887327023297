import { formatTime } from "./utils";
import moment from "moment";


export const initValue = {
  startTime: formatTime(moment().startOf("day").set("minute", 1)),

  endTime: formatTime(moment().endOf("day")),
  // trackingCode: ({ businessType }) => `购买折扣 ${moment().format("YYYY/MM/DD HH-mm-ss-SSS")}`,
};
