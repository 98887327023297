import Apis from "@api/apis";
import { ASwitch } from "@a-components";
import { AText } from "@a-components/";

const columns = [
  {
    title: "有效",
    dataIndex: "advertisingGoodsStatus",
    key: "advertisingGoodsStatus",
    fixed: "left",
    render: (status, { advertisingGoodsId, advertisingStatus, advertisingGroupStatus, event$ }) => {
      const updateAdvertisingStatus = (checked) => {
        return Apis.advertising
          .updateAdvertisingGoodsStatus({
            advertisingGoodsIds: advertisingGoodsId,
            advertisingGoodsStatus: checked ? 1 : 2,
          })
          .then((res) => {
            if (res.code === 1) event$.emit({ type: "reFetch" });
          });
      };
      return (
        <ASwitch
          disabled={status == 3 || advertisingStatus == 3 || advertisingGroupStatus == 3 ? true : false}
          checked={status === 1}
          onChange={updateAdvertisingStatus} />
      );
    },
  },
  {
    title: "商品",
    dataIndex: "goodsName",
    key: "2",
    render(goodsName) {
      return <p className="text-ellipsis-1">{goodsName ? goodsName : '--'}</p>;
    },
  },
  {
    title: "状态",
    dataIndex: "statusName",
    key: "3",
  },
  {
    title: "SKU/ASIN",
    dataIndex: "goodsSku",
    key: "4",
    render(goodsSku, record) {
      return (
        <AText>
          {goodsSku} {record.goodsAsin ? `| ${record.goodsAsin}` : ""}
        </AText>
      );
    },
  },
  {
    title: "曝光量",
    dataIndex: "exposure",
    key: "5",
  },
  {
    title: "点击次数",
    dataIndex: "exposure",
    key: "7",
  },
  {
    title: "点击率(CTR)",
    dataIndex: "clicksRate",
    key: "8",
  },
  {
    title: "花费",
    dataIndex: "costPrice",
    key: "9",
  },
  {
    title: "每次点击费用(CPC)",
    dataIndex: "singleCostPrice",
    key: "10",
  },
  {
    title: "订单",
    dataIndex: "orderNumber",
    key: "11",
  },
  {
    title: "销售额",
    dataIndex: "salesPrice",
    key: "12",
  },
  {
    title: "ACOS",
    dataIndex: "acosRate",
    key: "13",
  },
  {
    title: "ROAS",
    dataIndex: "roasRate",
    key: "14",
  },
];
const filterConditionOptions = [
  {
    label: "筛选条件",
    value: "筛选条件",
  },
  {
    label: "活跃状态",
    value: "活跃状态",
  },
  {
    label: "类型",
    value: "类型",
  },
  {
    label: "投放",
    value: "投放",
  },
  {
    label: "预算",
    value: "预算",
  },
  {
    label: "曝光量",
    value: "曝光量",
  },
  {
    label: "点击次数",
    value: "点击次数",
  },
  {
    label: "点击率（CTR）",
    value: "点击率（CTR）",
  },
  {
    label: "花费",
    value: "花费",
  },
  {
    label: "每次点击费用(CPC)",
    value: "每次点击费用(CPC)",
  },
  {
    label: "订单",
    value: "订单",
  },
  {
    label: "销售额",
    value: "销售额",
  },
  {
    label: "广告投入产出比(ACOS)",
    value: "广告投入产出比(ACOS)",
  },
  {
    label: "广告支出回报(ROAS)",
    value: "广告支出回报(ROAS)",
  },
  {
    label: "新买家订单数",
    value: "新买家订单数",
  },
  {
    label: "新买家订单比",
    value: "新买家订单比",
  },
  {
    label: "新买家销量",
    value: "新买家销量",
  },
  {
    label: "新买家销量比",
    value: "新买家销量比",
  },
  {
    label: "几乎或超出预算",
    value: "几乎或超出预算",
  },
  {
    label: "广告组合",
    value: "广告组合",
  },
];
const adGroupAdvertisingConfig = {
  createBtn: ({ event$ }) => {
    return {
      children: "添加要推广的商品",
      onClick() {
        event$.emit({ type: "onPrimaryBtnClick" });
      },
    };
  },
  searchInput: {
    placeholder: "查找商品",
  },
  tableColumns: columns,
  tableDataKey: "advertisingGoodsId",
  tableConfig: { scroll: { x: 1000 } },
  filterConditionOptions,
  queryFetcher({ searchValue, businessId, ...otherParams }) {
    return Apis.advertising.getAdvertisingGoodsList({
      ...otherParams,
      goodsNameGoodsSkuGoodsAsin: searchValue,
      advertisingGroupId: businessId,
    });
  },

  $dependencies: [],
};

export default adGroupAdvertisingConfig;
