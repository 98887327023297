import React, { useRef, useEffect, useCallback, useState } from 'react';
import AJAX from "api/apis";
import './taxationInfo.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"
import '../default.css/default.css'
import { Button, Input, Divider, Form, Select, Checkbox } from 'antd';
import { getSellerNationality, benefit } from '../../utils/regDate';
import { regSubmit, getStoreRegisterInfo } from '../../utils/PublicLibrary';


export default (props) => {
    let formRef = useRef();
    let formRefPre = useRef();
    const [taxType, setTaxType] = useState(1)
    const [usaTaxType, setUsaTaxType] = useState(1)
    const [addressValue, setAddressValue] = useState(['Pear'])
    const [isTourOpen, setIsTourOpen] = useState(true);
    const [detaultDate, setDetaultDate] = useState()
    const [yearList, setYearList] = useState([])
    const [pageIndex, setPageIndex] = useState(0)
    const [topData, setTopData] = useState([])
    const [addressShow, setAddressShow] = useState(false);
    const [addressShow2, setAddressShow2] = useState(false);
    const [benefitValue, setBenefit] = useState(null);
    const [first, setFirst] = useState(false)
    const benefitList = benefit()
    const steps1 = [
        {
            selector: '.stepGuidanceBox0',
            content: '选择个人税收还是业务，根据注册的公司类型选择',
        },
        {
            selector: '.stepGuidanceBox1',
            content: '根据注册公司法人的国籍',
        },
        {
            selector: '.benefit',
            content: '选择受益所有人类型',
        },
        {
            selector: '.stepGuidanceBox2',
            content: '公司的名称，填写英文',
        },
        {
            selector: '.stepGuidanceBox3',
            content: '公司注册所在的国家',
        },
        {
            selector: '.stepGuidanceBox4',
            content: '可不填写',
        },
        {
            selector: '.stepGuidanceBox5',
            content: '填写公司地址/邮箱等信息，填写英文',
        },
        {
            selector: '.stepGuidanceBox6',
            content: '勾选/提供电子签名',
        },
        {
            selector: '.stepGuidanceBox7',
            content: '法人签名，填写英文',
        },
        {
            selector: '.stepGuidanceBox8',
            content: '勾选实体签名',
        },
        {
            selector: '.stepGuidanceBox9',
            content: '选择日期',
        }
    ];
    const [isActive, setIsActive] = useState(false);
    const awakenTips = (type) => {
        setIsActive(type);
        if (type) {
            setPageIndex(1)
            if (formRefPre.current.getFieldValue('organizationName')) {
                if (!benefitValue) setBenefit('公司')
            } else {
                setBenefit(null)
            }
        } else {
            if (!formRefPre.current.getFieldValue('organizationName')) {
                setBenefit(null)
            } else {
                if (!benefitValue) setBenefit('公司')
            }
            setPageIndex(0)
        }
    }
    const countrylity = getSellerNationality()
    const options = [
        { label: '同永久地址', value: 'Pear' },
    ];
    function onCheckboxChange(e) {
        setAddressValue(e)
    }
    let taxBtn = useCallback((num) => {
        setTaxType(num)
    }, [])
    let usaBtn = useCallback((num) => {
        setUsaTaxType(num)
    }, [])
    useEffect(() => {
        getRegInfo()
        getDate()
        let step = localStorage.getItem('stepList') || ''
        if (!step.includes('taxationInfo')) {
            // setFirst(true)
            // setTaxType(2)
            // setUsaTaxType(2)
            setBenefit('公司')
            setPageIndex(1)
        }
    }, [])
    let getRegInfo = useCallback(() => {
        AJAX.login.getStoreRegisterInfo([]).then((res) => {
            for (let item of res.data) {
                if (item.propertyValue) {
                    if (item.propertyKey == "personal_info_revenue_type") {
                        setTaxType(item.propertyValue)
                    }
                    if (item.propertyKey == "personal_info_is_America_person") {
                        setUsaTaxType(item.propertyValue)
                    }
                    if (item.propertyKey == "paytaxes_organization_name") {
                        formRef.current.setFieldsValue({
                            organizationName: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "paytaxes_organization_alias") {
                        formRef.current.setFieldsValue({
                            EntityName: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "paytaxes_organization_state") {
                        formRef.current.setFieldsValue({
                            whereCountry: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "paytaxes_organization_site") {
                        formRef.current.setFieldsValue({
                            PermanentAddress: item.propertyValue.split("------")[0],
                            province: item.propertyValue.split("------")[1],
                            city: item.propertyValue.split("------")[2],
                            area: item.propertyValue.split("------")[3],
                            postalCode: item.propertyValue.split("------")[4],
                            detailedAddress1: item.propertyValue.split("------")[5],
                            detailedAddress2: item.propertyValue.split("------")[6]
                        })
                    }
                    if (item.propertyKey == "paytaxes_organization_site_option") {
                        setAddressValue(JSON.parse(item.propertyValue))
                    }
                    if (item.propertyKey == "paytaxes_organization_mail_site") {
                        formRef.current.setFieldsValue({
                            mailingPermanentAddress: item.propertyValue.split("------")[0],
                            mailingProvince: item.propertyValue.split("------")[1],
                            mailingCity: item.propertyValue.split("------")[2],
                            mailingArea: item.propertyValue.split("------")[3],
                            mailingPostalCode: item.propertyValue.split("------")[4],
                            mailingDetailedAddress1: item.propertyValue.split("------")[5],
                            mailingDetailedAddress2: item.propertyValue.split("------")[6]
                        })
                    }
                    if (item.propertyKey == "paytaxes_organization_name") {
                        formRefPre.current.setFieldsValue({
                            organizationName: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "paytaxes_organization_alias") {
                        formRefPre.current.setFieldsValue({
                            EntityName: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "paytaxes_organization_state") {
                        formRefPre.current.setFieldsValue({
                            whereCountry: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "is_electronic_sign") {
                        formRefPre.current.setFieldsValue({
                            agreeCheckbox: [item.propertyValue]
                        })
                    }
                    if (item.propertyKey == "personal_sign") {
                        formRefPre.current.setFieldsValue({
                            userName: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "is_sign_true") {
                        formRefPre.current.setFieldsValue({
                            signCheckbox: [item.propertyValue]
                        })
                    }
                    if (item.propertyKey == "survey_sign_date") {
                        formRefPre.current.setFieldsValue({
                            termYear: item.propertyValue
                        })
                        setDetaultDate(item.propertyValue)
                    }
                    if (item.propertyKey == "beneficial_owner_type") {
                        setBenefit(item.propertyValue)
                    }
                }
            }
            // console.log(formRef.current.getFieldsValue(['province', 'city', 'area', 'postalCode']));
        })
    }, [])
    const getDate = useCallback(() => {
        let myDate = new Date();
        let year = myDate.getFullYear();
        let month = myDate.getMonth() + 1;
        let date = Number(myDate.getDate());
        setDetaultDate(year + "年" + month + "月" + date + "日")
        let list = []
        list.push(
            { label: `${year}年${month}月${date - 1}日`, value: `${year}年${month}月${date - 1}日` },
            { label: `${year}年${month}月${date}日`, value: `${year}年${month}月${date}日` },
            { label: `${year}年${month}月${date + 1}日`, value: `${year}年${month}月${date + 1}日` },
        )
        setYearList(list)
    }, [])
    const yongjiu = (type) => {
        let obj = {
            1: ['province', 'city', 'area', 'detailedAddress1', 'detailedAddress2', 'PermanentAddress', 'postalCode'],
            2: ['mailingPermanentAddress', 'mailingProvince', 'mailingCity', 'mailingArea', 'mailingDetailedAddress1', 'mailingDetailedAddress2', 'mailingPostalCode']
        }
        const data = formRef.current.getFieldsValue(obj[type])
        // console.log(data);
        return (
            <div>
                {/* <p>{data.province}{data.city}{data.area}{data.detailedAddress1}{data.detailedAddress2}{data.postalCode}</p> */}
                <p style={{ wordBreak: 'break-all' }}>
                    {
                        Object.keys(data).map((v, i) => {
                            return <React.Fragment>
                                <span key={i} style={{ paddingRight: '4px', color: '#111', fontWeight: '400', fontSize: '13px' }}>{v != 'PermanentAddress' ? data[v] : ''}</span>
                                {/* {(i == 4 || i == 1) ? <br /> : ''} */}
                            </React.Fragment>
                        })
                    }
                </p>
                <p style={{ paddingRight: '4px', color: '#111', fontWeight: '400', fontSize: '13px' }}>{data.PermanentAddress}</p>
                <Button onClick={() => {
                    if (type == 1) {
                        setAddressShow(false)
                    } else {
                        setAddressShow2(false)
                    }

                }}>编辑</Button>
            </div>
        )
    }
    let next = useCallback(() => {
        formRef.current.validateFields().then((res) => {
            let arr = [
                { "propertyKey": "personal_info_revenue_type", "propertyValue": taxType },
                { "propertyKey": "personal_info_is_America_person", "propertyValue": usaTaxType },
                { "propertyKey": "paytaxes_organization_name", "propertyValue": res.organizationName },
                { "propertyKey": "paytaxes_organization_alias", "propertyValue": res.EntityName || "" },
                { "propertyKey": "paytaxes_organization_state", "propertyValue": res.whereCountry },
                { "propertyKey": "paytaxes_organization_site", "propertyValue": res.PermanentAddress + "------" + res.province + "------" + res.city + "------" + res.area + "------" + res.postalCode + "------" + res.detailedAddress1 + "------" + res.detailedAddress2 },
                { "propertyKey": "paytaxes_organization_mail_site", "propertyValue": addressValue.length > 0 ? res.PermanentAddress + "------" + res.province + "------" + res.city + "------" + res.area + "------" + res.postalCode + "------" + res.detailedAddress1 + "------" + res.detailedAddress2 : res.mailingPermanentAddress + "------" + res.mailingProvince + "------" + res.mailingCity + "------" + res.mailingArea + "------" + res.mailingPostalCode + "------" + res.mailingDetailedAddress1 + "------" + res.mailingDetailedAddress2 },
                { "propertyKey": "paytaxes_organization_site_option", "propertyValue": JSON.stringify(addressValue) },
                { "propertyKey": "beneficial_owner_type", "propertyValue": benefitValue },
            ]
            setTopData(arr)
            setPageIndex(1)
            // regSubmit(arr, null, 'taxationInfo', 6).then((data) => {
            //     if (data.code == 1) {
            //         props.history.push('/previewTaxationInfo')
            //     }
            // })
        })
    }, [taxType, usaTaxType, addressValue, benefitValue])
    const nextEvent = useCallback(() => {
        formRefPre.current.validateFields().then((res) => {
            let arr = [
                // { "propertyKey": "personal_info_revenue_type", "propertyValue": taxType },
                // { "propertyKey": "personal_info_is_America_person", "propertyValue": usaTaxType },
                // { "propertyKey": "paytaxes_organization_name", "propertyValue": res.organizationName },
                // { "propertyKey": "paytaxes_organization_alias", "propertyValue": res.EntityName || "" },
                // { "propertyKey": "paytaxes_organization_state", "propertyValue": res.whereCountry },
                { "propertyKey": "is_electronic_sign", "propertyValue": res.agreeCheckbox[0] },
                { "propertyKey": "personal_sign", "propertyValue": res.userName },
                { "propertyKey": "is_sign_true", "propertyValue": res.signCheckbox[0] },
                { "propertyKey": "survey_sign_date", "propertyValue": res.termYear || detaultDate },

            ]
            regSubmit([...topData, ...arr], null, 'taxationInfo', 21).then((data) => {
                if (data.code == 1) props.history.push('/TaxInvestigation')
            })
        })
    }, [topData, detaultDate])
    return (
        <div className="taxationInfoBox">
            {/* 引导步骤 */}
            <TipsIcon clicks={awakenTips} />
            <Reactours routePath="taxationInfo" isActive={isActive} clicks={awakenTips} steps={steps1} isOpen={isTourOpen} />
            <Header />
            <div style={{ display: 'flex' }}>
                <div className="a-section program-overview-values">
                    <div className="CompanyAddress">
                        <div>
                            <h1 className="topTitle">税务信息调查</h1>
                            <hr aria-hidden="true" className="a-spacing-top-large a-divider-normal" />
                            <div className="stepGuidanceBox0">
                                <p style={{ fontWeight: 'bold', marginTop: '15px' }}>您的税收分类是什么？</p>
                                <div className="btnClass">
                                    <span onClick={() => { taxBtn(1) }} className={taxType == 1 ? 'selectClass' : ''}>个人</span>
                                    <span onClick={() => { taxBtn(2) }} className={taxType == 2 ? 'selectClass' : ''}>业务</span>
                                </div>
                                <p style={{ marginTop: '40px' }}>“个人”包括独资经营人或所有人为个人单一成员有限责任公司</p>
                            </div>
                            <hr aria-hidden="true" className="a-spacing-top-large a-divider-normal" />
                            <div className="stepGuidanceBox1">
                                <p style={{ fontWeight: 'bold', marginTop: '15px' }}>出于美国税务目的，您是美国人吗？</p>
                                <div className="btnClass">
                                    <span className={usaTaxType == 1 ? 'selectClass' : ''} onClick={() => { usaBtn(1) }}>是</span>
                                    <span className={usaTaxType == 2 ? 'selectClass' : ''} onClick={() => { usaBtn(2) }}>否</span>
                                </div>
                            </div>
                            <hr aria-hidden="true" className="a-spacing-top-large a-divider-normal" />
                            {/* style={{ display: (usaTaxType == 2 && taxType == 2) ? 'block' : 'none' }} */}
                            <div className="benefit" >
                                <p style={{ fontWeight: 'bold', margin: '15px 0' }}>受益所有人类型</p>
                                <div className="">
                                    <Select value={benefitValue} placeholder="选择一个" onChange={e => { setBenefit(e) }} >
                                        {
                                            benefitList.map(item => {
                                                return <Select.Option value={item.name} key={item.name}>
                                                    <div>
                                                        {item.name}
                                                        {item.s && <p style={{ fontSize: '12px', color: '#767676' }}>{item.s}</p>}
                                                    </div>
                                                </Select.Option>
                                            })
                                        }

                                    </Select>
                                </div>
                            </div>
                            <hr style={{ display: (usaTaxType == 2 && taxType == 2) ? 'block' : 'none' }} aria-hidden="true" className="a-spacing-top-large a-divider-normal" />
                            <h1 className="topTitle nsInfoTitle">纳税身份信息</h1>
                            <div className="nsInfoBox" style={{ display: benefitValue ? 'block' : 'none' }}>
                                <Form ref={formRef}>
                                    <div className="inpBox1">
                                        <div className="depositInfo stepGuidanceBox2">
                                            <p>组织名称</p>
                                            <Form.Item name="organizationName" rules={[{ required: true, message: '请输入组织名称' }]}>
                                                <Input placeholder="请输入组织名称" />
                                            </Form.Item>
                                        </div>
                                        <div className="depositInfo stepGuidanceBox3">
                                            <p>组织所在国家</p>
                                            <Form.Item name="whereCountry" rules={[{ required: true, message: '请选择国家/地区' }]}>
                                                <Select placeholder="选择国家/地区" options={countrylity} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="inpBox1">
                                        <div className="depositInfo stepGuidanceBox4">
                                            <p>无视实体名称（可选）</p>
                                            <Form.Item name="EntityName">
                                                <Input placeholder="请输入无视实体名称" maxLength="30" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <Divider>地址</Divider>
                                    <div className="inpBox1">
                                        <div className="depositInfo stepGuidanceBox5">
                                            <p>永久地址</p>
                                            <div style={{ display: addressShow ? 'none' : 'block' }}>
                                                <Form.Item name="PermanentAddress" rules={[{ required: true, message: '请选择国家/地区' }]}>
                                                    <Select placeholder="选择国家/地区" options={countrylity} />
                                                </Form.Item>
                                                <Form.Item name="province" rules={[{ required: true, message: '请输入省/自治区/直辖市' }]}>
                                                    <Input maxLength="20" placeholder="请输入省/自治区/直辖市" />
                                                </Form.Item>
                                                <Form.Item name="city" rules={[{ required: true, message: '请输入市' }]}>
                                                    <Input maxLength="20" placeholder="请输入市" />
                                                </Form.Item>
                                                <Form.Item name="area" rules={[{ required: true, message: '请输入区' }]}>
                                                    <Input maxLength="50" placeholder="请输入区" />
                                                </Form.Item>

                                                <Form.Item name="detailedAddress1" rules={[{ required: true, message: '请输入详细地址，如道路、街道、门牌号等' }]}>
                                                    <Input maxLength="50" placeholder="请输入详细地址，如道路、街道、门牌号等" />
                                                </Form.Item>
                                                <Form.Item name="detailedAddress2" rules={[{ required: true, message: '请输入详细地址，如大厦、楼层、房间号等' }]}>
                                                    <Input maxLength="50" placeholder="请输入详细地址，如大厦、楼层、房间号等" />
                                                </Form.Item>
                                                <Form.Item name="postalCode" rules={[{
                                                    required: true,
                                                    validator: (rule, value, fun) => {
                                                        let reg = /^[1-9][0-9]{5}$/;
                                                        if (!value) {
                                                            fun('请输入邮政编码')
                                                        } else if (!reg.test(value)) {
                                                            fun('邮政编码验证失败')
                                                        }
                                                        fun()
                                                    }
                                                }]}>
                                                    <Input maxLength="6" placeholder="请输入邮政编码" />
                                                </Form.Item>
                                                <Button onClick={() => { setAddressShow(true) }}>已完成</Button>
                                            </div>
                                            {addressShow && yongjiu(1)}
                                        </div>
                                        <div className="depositInfo">
                                            <p>邮寄地址</p>
                                            {addressValue.length == 0 ?
                                                <React.Fragment>
                                                    <div style={{ display: addressShow2 ? 'none' : 'block' }}>
                                                        <Form.Item name="mailingPermanentAddress" rules={[{ required: true, message: '请选择国家/地区' }]}>
                                                            <Select placeholder="选择国家/地区" options={countrylity} />
                                                        </Form.Item>
                                                        <Form.Item name="mailingProvince" rules={[{ required: true, message: '请输入省/自治区/直辖市' }]}>
                                                            <Input maxLength="255" placeholder="请输入省/自治区/直辖市" />
                                                        </Form.Item>
                                                        <Form.Item name="mailingCity" rules={[{ required: true, message: '请输入市' }]}>
                                                            <Input maxLength="255" placeholder="请输入市" />
                                                        </Form.Item>
                                                        <Form.Item name="mailingArea" rules={[{ required: true, message: '请输入区' }]}>
                                                            <Input maxLength="255" placeholder="请输入区" />
                                                        </Form.Item>
                                                        <Form.Item name="mailingDetailedAddress1" rules={[{ required: true, message: '请输入详细地址，如道路、街道、门牌号等' }]}>
                                                            <Input maxLength="255" placeholder="请输入详细地址，如道路、街道、门牌号等" />
                                                        </Form.Item>
                                                        <Form.Item name="mailingDetailedAddress2" rules={[{ required: true, message: '请输入详细地址，如大厦、楼层、房间号等' }]}>
                                                            <Input maxLength="255" placeholder="请输入详细地址，如大厦、楼层、房间号等" />
                                                        </Form.Item>
                                                        <Form.Item name="mailingPostalCode" rules={[{
                                                            required: true,
                                                            validator: (rule, value, fun) => {
                                                                let reg = /^[1-9][0-9]{5}$/;
                                                                if (!value) {
                                                                    fun('')
                                                                } else if (!reg.test(value)) {
                                                                    fun('邮政编码验证失败')
                                                                }
                                                                fun()
                                                            }
                                                        }]}>
                                                            <Input maxLength="6" placeholder="请输入邮政编码" />
                                                        </Form.Item>
                                                        <Button onClick={() => { setAddressShow2(true) }}>已完成</Button>
                                                    </div>
                                                    {addressShow2 && yongjiu(2)}
                                                </React.Fragment>
                                                : null}
                                            <Checkbox.Group options={options} value={addressValue} onChange={onCheckboxChange} />
                                        </div>
                                    </div>
                                </Form>
                                <div className="operationBtn" style={{ textAlign: 'center' }}>
                                    {
                                        !pageIndex && <Button onClick={next} className='next' type="primary">继续</Button>
                                    }
                                </div>
                            </div>

                        </div>
                        <h1 className="topTitle">保存并预览</h1>
                        <Form ref={formRefPre} style={{ display: pageIndex ? 'block' : 'none' }} className='previewtaxationInfoBox'>
                            <hr aria-hidden="true" className="a-spacing-top-large a-divider-normal" />
                            <div className="CheckboxClass stepGuidanceBox6">
                                <Form.Item name="agreeCheckbox" rules={[{ required: true, message: '请勾选该选项' }]}>
                                    <Checkbox.Group>
                                        <Checkbox value="1" onChange={(e) => { }}>我同意为依据IRS表格W-8BEN-E提供的信息提供电子签名</Checkbox>
                                    </Checkbox.Group>
                                </Form.Item>
                                <p>如果你能提供电子签名，就能够立即提交您的税务信息</p>
                            </div>
                            <div className="xyTable">
                                <p className="bolds">在充分了解做伪证可能承担全部不良后果的前提下，我已尽我所能检查本表格中的信息，井确认信息详实无误。此外，在充分了解做伪证可能承担全部不良后果的前提下，本人进一步证明：</p>
                                <p>1.本表第1行所列之法人是本表涉及的全部收入的受益所有人，使用本表证明其在第4章的身份，或因美国国家税务法案第6050W款之目的提交此表的商人，</p>
                                <p>2.本表第1行所列之法人不是美国境内居民，</p>
                                <p>3.本表所涉及的收入(a)不是与美国贸易或商业有实际联系的所得收益: (b) 虽有联系但根掘所得税协定不应缴税:或(c)合伙企业的实际关联收入中的合作伙伴的份额，</p>
                                <p>4.对于中间交易或实物交易，收益人根据说明中的定义是免税的外国人。以及</p>
                                <p>5.我同意如果此表上的任何证明有误，我将在30天内提交-份新表。</p>
                                <p>此外，我授权可以将本表提供给任何对第1行所列法人为受益所有人的收入有控制、接收和监督功能的税务机构，或任何对以第1行所列法人为受益所有人的收入进行减退税实际支付的机构。</p>
                                <p className="bolds">除了用以确立您非美国义务纳税人身份的此份证明外，美国国税局不需要征得您对此份文件所载条款的同意声明，(如果需要) 请确立您第四章的身份，如果适用，则可以享受降低的预扣税率。</p>
                                <p style={{ fontWeight: 'bold', paddingTop: '30px' }}>签名（请使用您的全名）</p>
                                <div className="stepGuidanceBox7">
                                    <Form.Item name="userName" rules={[{ required: true, message: '请输入您的全名' }]}>
                                        <Input placeholder="请输入您的全名" maxLength="20" />
                                    </Form.Item>
                                </div>
                                <div>通过在指定日期键入我的姓名，我确认正在签署税务文件，如有不实，愿以伪证罪接受处罚。</div>
                                <Form.Item className="stepGuidanceBox8" name="signCheckbox" rules={[{ required: true, message: '请勾选该选项' }]}>
                                    <Checkbox.Group>
                                        <Checkbox value="1" onChange={(e) => { }}>兹证明我有能力代表此表第一行中显示的实体签字。</Checkbox>
                                    </Checkbox.Group>
                                </Form.Item>
                                <p style={{ fontWeight: 'bold', paddingTop: '30px' }}>日期</p>
                                <div className="stepGuidanceBox9">
                                    <Form.Item name="termYear" rules={[{ required: true, message: '请选择日期' }]}>
                                        <Select placeholder={detaultDate} options={yearList} />
                                    </Form.Item>
                                </div>
                                <div>您可以将日期修改为前一天或之后一天来适应您的时区。</div>
                            </div>
                            <div className="operationBtn" style={{ textAlign: 'center' }}>
                                <Button onClick={nextEvent} className='next' type="primary">保存并预览</Button>
                            </div>
                        </Form>


                    </div>
                </div>
                {/* <div className="rightCollapse">
                    <p >常见问题</p>
                    <Collapse defaultActiveKey={['1']} onChange={callback}>
                        <Panel header="电话号码的正确格式是什么？" key="1">
                            <p>该数字的前缀必须是国家/地区代码。 下面是正确的格式：+1 1298279287</p>
                        </Panel>
                        <Panel header="如果我没有可接收 SMS 的手机号码，应该怎么办？" key="2">
                            <p>您可以输入您的固定电话号码，然后选择拨打电话选项以接收电话验证 PIN。</p>
                        </Panel>
                        <Panel header="如果我没有收到含有 PIN 的 SMS，应该怎么办？" key="3">
                            <p>检查您输入的电话号码格式是否正确。 再次请求 PIN。</p>
                        </Panel>
                        <Panel header="如果“呼叫”选项在我所在的国家/地区未列出，我该怎么办？" key="4">
                            <p>如果在您所在的国家/地区中，“呼叫”选项不可用，请选择“短信”选项。</p>
                        </Panel>
                        <Panel header="主要联系人是如何定义的？" key="5">
                            <p>主要联系人是指有权访问“亚马逊销售”付款帐户，代表帐户持有人（注册卖方）提供注册信息并发起交易（如付款和退款）的人。主要联系人采取的行动被视为帐户持有人采取的行动。</p>
                        </Panel>
                    </Collapse>
                </div> */}
            </div>
            <Footer />
        </div>
    )
}