import React from 'react';
import {Link} from 'react-router-dom';
import {Item} from './style';

export default ({title,list}) => {
    return <Item>
        <p>{title}</p>
        <ul>
            {list.map((item,index)=>{
                if(item.path){
                    return <li key={index}><Link to={item.path}>{item.text}</Link></li>
                }else{
                    return <li key={index}><div>{item}</div></li>
                }
            })}
        </ul>
    </Item>
}