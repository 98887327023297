import { ASelect, AInput, ADatePicker } from "@a-components/";
import { Row, Col } from "antd";

import CardCollapsible from "./../children/CardCollapsible";
import { useCallback, useMemo, useState } from "react";

import moment from "moment";

import { minDateSelect } from "@utils/utils";

import Description from "./../children/Description";

const timePrefixMapping = {
  discount: "购买折扣",
  gifts: "买一赠一",
  media: "社交媒体",
};

const timeOptions = Array(25)
  .fill(null)
  .map((_, index, source) => {
    let time = "";
    if (index === 0) {
      time = "00:01";
    } else if (index === source.length - 1) {
      time = "23:59";
    } else {
      time = `${index.toString().padStart(2, "0")}:00`;
    }

    return {
      label: time,
      value: time,
    };
  });

const promotionDateTimeOptions = [
  {
    label: "开始日期",
    key: "startTime",
    render(value, { setState, store: { showTypeSelector } }) {
      const currentMoment = moment(value);
      const currentHour = currentMoment.hour();
      const currentMinute = currentMoment.minute();

      const curTimeValue = `${currentHour
        .toString()
        .padStart(2, "0")}:${currentMinute.toString().padStart(2, "0")}`;

      const handleUpdateDate = (newValue) => {
        setState({
          startTime: formatTime(
            newValue.set("hour", currentHour).set("minute", currentMinute)
          ),
        });
      };

      const handleUpdateTime = (optionValue) => {
        const [hour, minute] = optionValue.split(":").map(Number);

        setState({
          startTime: formatTime(
            currentMoment.set("hour", hour).set("minute", minute)
          ),
        });
      };

      return (
        <Row>
          <Col className="stepGuidanceBox3" span={3}>
            {showTypeSelector({
              edit: () => (
                <ADatePicker
                  allowClear={false}
                  disabledDate={minDateSelect}
                  value={currentMoment}
                  onChange={handleUpdateDate}
                ></ADatePicker>
              ),
              preview: () => currentMoment.format("YYYY-MM-DD"),
              modify: () => currentMoment.format("YYYY-MM-DD"),
            })}
          </Col>
          <Col className="stepGuidanceBox4" span={3} offset={1}>
            {showTypeSelector({
              edit: () => (
                <ASelect
                  className="w-100percent"
                  options={timeOptions}
                  value={curTimeValue}
                  onChange={handleUpdateTime}
                ></ASelect>
              ),
              preview: () => curTimeValue,
              modify: () => curTimeValue,
            })}
          </Col>
          <Col offset={1}>PDT</Col>
        </Row>
      );
    },
  },
  {
    label: "结束日期",
    key: "endTime",
    render(value, { record, setState, store: { showTypeSelector } }) {
      const currentMoment = moment(value);

      const currentHour = currentMoment.hour();
      const currentMinute = currentMoment.minute();

      const curTimeValue = `${currentHour
        .toString()
        .padStart(2, "0")}:${currentMinute.toString().padStart(2, "0")}`;

      const handleUpdateDate = (newValue) => {
        setState({
          endTime: formatTime(
            newValue.set("hour", currentHour).set("minute", currentMinute)
          ),
        });
      };

      const handleUpdateTime = (optionValue) => {
        const [hour, minute] = optionValue.split(":").map(Number);

        setState({
          endTime: formatTime(
            currentMoment.set("hour", hour).set("minute", minute)
          ),
        });
      };

      return (
        <Row>
          <Col className="stepGuidanceBox5" span={3}>
            {showTypeSelector({
              edit: () => (
                <ADatePicker
                  allowClear={false}
                  // value={currentMoment}
                  disabledDate={(date) =>
                    minDateSelect(date, moment(record.startTime))
                  }
                  placeholder="结束日期"
                  onChange={handleUpdateDate}
                ></ADatePicker>
              ),
              preview: () => currentMoment.format("YYYY-MM-DD"),
            })}
          </Col>
          <Col className="stepGuidanceBox6" span={3} offset={1}>
            {showTypeSelector({
              edit: () => (
                <ASelect
                  className="w-100percent"
                  options={timeOptions}
                  value={curTimeValue}
                  onChange={handleUpdateTime}
                ></ASelect>
              ),
              preview: () => curTimeValue,
            })}
          </Col>
          <Col offset={1}>PDT</Col>
        </Row>
      );
    },
  },
  {
    label: "内部描述",
    key: "description",
    render(value, { setState, store: { showTypeSelector } }) {
      return ( 
        <Row>
          <Col className="stepGuidanceBox7" span={24}>
            {showTypeSelector({
              edit: () => (
                <AInput
                  placeholder="示例: 母亲节购物免运费"
                  value={value}
                  onChange={(e) => setState({ description: e.target.value })}
                  rules={[{
                    required: true, validator: (rule, value, cbfn) => {
                      if (!value) {
                        cbfn('请输入促销识别名称，最多50个字符')
                      }else if(value.length > 50){
                        cbfn('请输入促销识别名称，最多50个字符')
                      } cbfn()
                    }
                  }]}
                ></AInput>
              ),
              preview: () => value,
            })}
          </Col>
        </Row>
      );
    },
  },
  {
    label: "追踪编码",
    key: "trackingCode",
    render(value, { setState, store: { showTypeSelector, previewKeys = [] } }) {
      return (
        <Row>
          <Col className="stepGuidanceBox8" span={24}>
            {previewKeys.includes("trackingCode")
              ? value
              : showTypeSelector({
                edit: () => (
                  <AInput
                    placeholder=""
                    value={value}
                    onChange={(e) =>
                      setState({ trackingCode: e.target.value })
                    }
                  ></AInput>
                ),
                preview: () => value,
                modify: () => value,
              })}
          </Col>
        </Row>
      );
    },
  },
];

const formatTime = (moment) => {
  return moment.format("YYYY-MM-DD HH:mm");
};

/**
 *
 * @param {{ showType: 'edit' | 'preview' }} param0
 * @returns
 */
const PromotionTimeCard = ({
  value,
  onChange = () => { },
  showType = "edit",
  previewKeys = [],
  operatorType,
}) => {
  const showTypeSelector = useCallback(
    (arg) => {
      const _showType =
        operatorType === "modify" && showType === "edit" ? "modify" : showType;

      let value = arg[_showType] || arg[showType];

      if (typeof value === "function") {
        return value();
      }

      return value;
    },
    [operatorType, showType]
  );

  const store = useMemo(() => {
    return {
      showTypeSelector,
      previewKeys,
      operatorType,
    };
  }, [operatorType, previewKeys, showTypeSelector]);

  return (
    <CardCollapsible title="第2步:设置促销时间" className="mt-12">
      <Description
        bordered
        column={1}
        labelStyle={{ width: 240, textAlign: "right" }}
        options={promotionDateTimeOptions}
        dataSource={value}
        store={store}
        onChange={onChange}
      ></Description>
    </CardCollapsible>
  );
};

export default PromotionTimeCard;
