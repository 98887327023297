import { useCallback, useMemo } from "react";
import { Button, Space } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const SimplePagination = ({
  total = 10,
  current = 1,
  pageSize = 10,
  onChange,
}) => {
  const startNumber = useMemo(() => {
    return (current - 1) * pageSize + 1;
  }, [current, pageSize]);

  const endNumber = useMemo(() => {
    const curMaxCount = current * pageSize;

    return Math.min(curMaxCount, total);
  }, [current, pageSize, total]);

  const handleCurrentChange = useCallback(
    (newCurrent) => {
      onChange?.(newCurrent, pageSize);
    },
    [pageSize, onChange]
  );

  const isFirstPage = useMemo(() => {
    return current === 1;
  }, [current]);
  const isLastPage = useMemo(() => {
    return current === Math.ceil(total / pageSize);
  }, [current, total, pageSize]);

  if (!total) return null;
  return (
    <Space>
      {startNumber}-{endNumber}，共 {total} 个结果
      <Button
        size="small"
        icon={<LeftOutlined />}
        disabled={isFirstPage}
        onClick={() => handleCurrentChange(current - 1)}
      ></Button>
      <Button
        size="small"
        icon={<RightOutlined />}
        disabled={isLastPage}
        onClick={() => handleCurrentChange(current + 1)}
      ></Button>
    </Space>
  );
};

export default SimplePagination;
