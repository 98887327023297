import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    border-bottom: 1px solid #ccc;
    text-align: left;
    background: #fff;
    `;
    export const Main = styled.div`    
    display: flex;
    justify-content: space-between;
    padding: 0px 2.5%;
    height: 48px;
    &>div{
        display: flex;
    }
`;
export const Logo = styled.div`
    width: 160px;
    height: 38px;
    background: url('@assets/images/BranchRegisteredLogo.png') no-repeat;
    background-size: 100% auto;
    background-position: 0 6px;
    margin-right: 20px;
`;