import React, { useCallback, useEffect, useMemo, useState } from "react";
import Header from "components/home_header";
import Footer from "components/addlisting_footer/FooterCom";

import { Space } from "antd";
import { AButton, AText, ARadioGroup, AInput, ATable } from "@a-components/";
import { SearchOutlined } from "@ant-design/icons";

import style from "./style.module.css";
import Apis from "@api/apis";
import usePaginationTable from "@hooks/usePaginationTable";
import Reactours from "components/reactours"
import TipsIcon from "components/tipsIcon"
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

function Coupons() {
  const [searchValue, setSearchValue] = useState("");
  const [list, setList] = useState(null);
  const [activeCouponType, setActiveCouponType] = useState("");
  const history = useHistory();
  const [couponsStatusNum, setCouponsStatusNum] = useState({
    allNum: 0,
    cancelledNum: 0,
    effectiveNum: 0,
    expiredNum: 0,
    failedNum: 0,
    soonExpiredNum: 0,
    submittedNum: 0,
  });
  const radioGroupOptions = useMemo(() => {
    const {
      allNum,
      cancelledNum,
      effectiveNum,
      expiredNum,
      failedNum,
      soonExpiredNum,
      submittedNum,
    } = couponsStatusNum;
    return [
      { label: `全部(${allNum})`, value: "" },
      { label: `有效(${effectiveNum})`, value: 1 },
      { label: `已过期(${expiredNum})`, value: 5 },
      { label: `失败(${failedNum})`, value: 2 },
      // { label: `失败(${1})`, value: 3 },
      { label: `已取消(${cancelledNum})`, value: 3 },
      { label: `已提交(${submittedNum})`, value: 4 },
      { label: `即将过期(${soonExpiredNum})`, value: 6 },
    ];
  }, [couponsStatusNum]);
  const isTourOpen = true;
  const steps1 = [
    {
      selector: '.couponsBox .stepGuidanceBox1',
      content: '点击创建新的优惠券',
    }
  ]
  const [isActive, setIsActive] = useState(false);
  const awakenTips = (type) => {
    setIsActive(type);
  }
  const toEdit = useCallback(
    (params) => {
      const queryString = new URLSearchParams(params).toString();
      history.push("/Coupons/CreateCoupon?" + queryString);
    },
    [history]
  );

  const tableColumns = useMemo(() => {
    return [
      {
        title: "优惠券名称",
        dataIndex: "couponFullName",
        key: "couponFullName",
        render(couponName, record) {
          return (
            <Link to={`/Coupons/CouponDetail?couponsId=${record.couponsId}`}>
              {couponName}
            </Link>
          );
        },
      },
      {
        title: "操作",
        dataIndex: "operator",
        key: "operator",
        render(_, record) {
          const editBtnDisabled = [2, 3].includes(record.couponsStatus);

          return (
            <Space>
              <AButton
                disabled={editBtnDisabled}
                onClick={() => toEdit({ couponsId: record.couponsId })}
              >
                编辑
              </AButton>
              <AButton
                onClick={() =>
                  toEdit({ couponsId: record.couponsId, type: "rerun" })
                }
              >
                再次运行
              </AButton>
              <AButton
                onClick={() =>
                  toDel(record.couponsId)
                }
              >
                删除
              </AButton>
            </Space>
          );
        },
      },
      {
        title: "状态",
        dataIndex: "couponsStatusName",
        key: "couponsStatusName",
      },
      {
        title: "开始日期",
        dataIndex: "startTime",
        key: "startTime",
      },
      {
        title: "结束日期",
        dataIndex: "endTime",
        key: "endTime",
      },
      {
        title: "预算",
        dataIndex: "budgetPrice",
        key: "budgetPrice",
        render(budgetPrice) {
          return `$${budgetPrice.toFixed(2)}`;
        },
      },
      {
        title: "折扣",
        dataIndex: "discountPrice",
        key: "discountPrice",
        render(discountPrice, record) {
          return record.discountChoice === 1
            ? `$${discountPrice.toFixed(2)}`
            : `${discountPrice}%`;
        },
      },
      {
        title: "支出",
        dataIndex: "expenditureAmount",
        key: "expenditureAmount",
      },
      {
        title: "领取",
        dataIndex: "collectionNum",
        key: "collectionNum",
      },
      {
        title: "已兑换",
        dataIndex: "exchangeNum",
        key: "exchangeNum",
      },
      {
        title: "Sales",
        dataIndex: "sales",
        key: "sales",
      },
    ];
  }, [toEdit]);

  const fetcher = useCallback(
    (params) => {
      return Apis.coupons.queryCouponsList({
        ...params,
        couponsStatus: activeCouponType,
      });
    },
    [activeCouponType]
  );

  const getCouponsStatusNum = useCallback(() => {
    return Apis.coupons.getCouponsStatusNum().then((res) => {
      if (res.code !== 1) return;

      setCouponsStatusNum(res.data);
    });
  }, []);

  useEffect(() => {
    getCouponsStatusNum();
  }, [getCouponsStatusNum]);

  const {
    tableList,
    isTableLoading,
    pagination,
    operator: { resetPageIndex },
  } = usePaginationTable({
    fetcher,
    params: { couponName: searchValue },
  });
  const toDel = useCallback((couponsId) => {
    Apis.coupons.deleteCoupons({ couponsId }).then(res => {
      if (res.code == 1) {
        getCouponsStatusNum();
        handleSearch()
      }
    })
  }, [tableList, resetPageIndex]);

  const handleSearch = useCallback(() => {
    resetPageIndex();
  }, [resetPageIndex]);

  const handleCreate = useCallback(() => {
    history.push("/Coupons/CreateCoupon");
  }, [history]);

  return (
    <div className="couponsBox">
      <Header />
      <div className="p-24">
        <div className="df jc-sb pr-24 pb-24">
          <AText className="fs-32">优惠券</AText>
          <Space>
            <AButton type="primary">批量创建</AButton>
            <AButton type="primary" className="stepGuidanceBox1" onClick={handleCreate}>
              创建新的优惠券
            </AButton>
          </Space>
        </div>

        <div className="p-12 df ai-c border-ccc border-radius-4">
          <AText>选择：</AText>
          <ARadioGroup
            className="df"
            options={radioGroupOptions}
            value={activeCouponType}
            onChange={(e) => setActiveCouponType(e.target.value)}
          ></ARadioGroup>
        </div>
        <div className="p-12 df ai-c border-ccc border-radius-4 mt-6">
          <AText>搜索：</AText>
          <AInput
            style={{ width: 360 }}
            value={searchValue}
            prefix={<SearchOutlined />}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="按优惠券名称或状态搜索"
          />
          <AButton className="ml-6" onClick={handleSearch}>
            搜索
          </AButton>
        </div>

        <ATable
          columns={tableColumns}
          dataSource={tableList}
          loading={isTableLoading}
          pagination={pagination}
        ></ATable>
      </div>
      <Footer />
      {/* 引导步骤 */}
      <TipsIcon clicks={awakenTips} />
      <Reactours steps={steps1} isActive={isActive} clicks={awakenTips} routePath="Coupons" isOpen={isTourOpen} />
    </div>
  );
}
export default Coupons;
