import React from "react"
import "./manage-inventory.css"
import cookieUtils from "utils/cookie";
import HomeHeader from 'components/home_header';
import FooterCom from "components/addlisting_footer/FooterCom";
import Superior from "./module/Superior/Superior";
import OrderForm from "./module/OrderForm/OrderForm";
import Paging from "./module/Paging/Paging";

class ManageInventory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return <div>
            <HomeHeader />
            <div className="manageInventory">
                {/* <Superior></Superior> */}
                <OrderForm props={this.props}></OrderForm>
                {/* <Paging></Paging> */}
            </div>
            <FooterCom></FooterCom>
        </div>
    }
}


export default ManageInventory