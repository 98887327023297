import React from 'react';
import './videoAuthentication/index.less'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import RegisterSteps from 'components/registerSteps';
import RegisterCollapse from 'components/registerCollapse';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { regSubmit } from 'utils/PublicLibrary';


class Template extends React.Component {
  nextEvent = async () => {
    const data = await this.props.nextEvent()
    const path = this.props.nextPath
    // console.log(data);
    if (data) {
      regSubmit(data.arr, data.localArr, data.whichPage, data.step).then(res => {
        if (res.code == 1) {
          this.props.history.push(path)
        }
      })
    } else {
      this.props.history.push(path)
    }
  }
  render() {
    const { collapseList, className, prePath, current, disabled, preText, nextText, footer = true, Pstyle } = this.props
    return <div className={`agreementsBox register_style ${className || ''}`}>
      <Header />
      <div style={{ display: 'flex' }}>
        <div className="a-section program-overview-values">
          <RegisterSteps current={current || 1} />
          <div style={Pstyle || { padding: '20px 20%' }}>

            {this.props.children}


            {
              footer && <div className="operationBtn" style={{ textAlign: 'center' }}>
                <Button className='previousPage' type="primary">
                  <Link to={{ pathname: prePath, state: { isReturn: 1 } }}>{preText || '上一步'}</Link>
                </Button>
                <Button onClick={this.nextEvent} disabled={disabled} className={`next ${disabled ? 'disabled' : ''}`} type="primary">{nextText || '下一步'}</Button>
              </div>
            }

          </div>

        </div>
        <div className="rightCollapse">
          <p >常见问题</p>
          <RegisterCollapse list={collapseList} />
        </div>
      </div>
      <Footer />
    </div>
  }
}
export default withRouter(Template)