import withProps from "@a-components/withProps";
import { Checkbox as AntCheckbox } from "antd";

const AntCheckboxGroup = AntCheckbox.Group;

const CheckboxGroup = withProps(
  (props) => {
    return <AntCheckboxGroup {...props}></AntCheckboxGroup>;
  },
  {
    className: "a-checkbox-group",
  }
);

export default CheckboxGroup;
