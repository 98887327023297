import React from 'react'
import './index.css'
import Header from 'components/home_header/index'
import Footer from 'components/addlisting_footer/FooterCom'
import { Tabs } from 'antd';
import Affirm from './module/affirm/index'
import Check from './module/check/index'
import Cancel from './module/cancel/index'
function UploadOrder(){
    const { TabPane } = Tabs;
    function callback(key) {
        console.log(key);
      }
    return(
        <div>
            <Header/>
                <div className="UploadOrder_box">
                    <div className="UploadOrder_head">
                        <div className="UploadOrder_head_titel">上传订单相关文件</div>
                        <div>通过上传以下文件，对订单进行批量更改。</div>
                    </div>

                    <div className="UploadOrder_tabBox">
                    <Tabs defaultActiveKey="1" onChange={callback}>
                      <TabPane tab="配送确认" key="1">
                        <Affirm/>
                      </TabPane>
                      <TabPane tab="盘点" key="2">
                        <Check/>
                      </TabPane>
                      <TabPane tab="订单取消" key="3">
                       <Cancel/>
                      </TabPane>
                    </Tabs>


                    </div>


                </div>


            <Footer/>
        </div>
    )
}

export default UploadOrder