import { ATabs, ATableForm, AInputMoney, AText, AForm } from "@a-components";
import { useState } from "react";
import GoodsSelector from "./GoodsSelector";
import CategorySelector from "./CategorySelector";
import { Button } from "antd";

const { TabPane } = ATabs;

const columns = [
  {
    title: "分类和商品",
    width: "30%",
    key: "1",
    name: "classifyName",
    align: "left",
    render(value, { row }) {
      const { goodsDirectionalType } = row;
      const isCategory = goodsDirectionalType === 1; // 1 - 分类 ；2 - 商品
      const createPartText = (arr) => {
        return (
          <span className="fs-12 c-666">
            {arr.map(({ title, key, render }) => {
              const value = row[key];
              if (!value && !render) return null;
              const renderValue = render ? render(row) : value;

              if (!renderValue) return null;

              return (
                <>
                  <span className="fw-600 c-333">{title}:</span>
                  {render ? render(row) : value}
                </>
              );
            })}
          </span>
        );
      };

      const arr = isCategory
        ? [
            { title: "分类", key: "directionalName" },
            { title: "品牌", key: "brandName" },
            {
              title: "商品价格",
              render({ goodsPriceStart, goodsPriceEnd }) {
                if (goodsPriceStart && goodsPriceEnd) {
                  return `$${goodsPriceStart} - $${goodsPriceEnd}`;
                }
                if (goodsPriceStart) {
                  return `不低于$${goodsPriceStart}`;
                }
                if (goodsPriceEnd) {
                  return `不高于$${goodsPriceEnd}`;
                }
                return null;
              },
            },
            {
              title: "评分",
              render({ starStart, starEnd }) {
                if (!starStart && !starEnd) return null;
                if (starStart === 0 && starEnd === 5) return null;
                return `${starStart} - ${starEnd}星`;
              },
            },
            {
              title: "配送",
              render({ distribution }) {
                const mapping = {
                  1: "",
                  2: "具备Prime资格",
                  3: "不具备Prime资格",
                };

                return mapping[distribution];
              },
            },
          ]
        : [
            { title: "商品", key: "goodsName" },
            {
              title: "ASIN",
              key: "asin",
            },
          ];

      return (
        <div>
          <div>
            <AText
              title={row.classifyPathName}
              className="fs-12 c-999 text-ellipsis-1"
            >
              {row.classifyPathName}
            </AText>
          </div>
          {createPartText(arr)}
        </div>
      );
    },
  },
  {
    title: "建议竞价",
    titleTooltip: "2333",
    width: "20%",
    align: "right",
    name: "suggestedBidding",
    key: "2",
    render(value, { row }) {
      return (
        <div>
          <div className="fs-12">${value}</div>
          <span className="fs-12 c-999">
            ${row.suggestedBiddingStart} - ${row.suggestedBiddingEnd}
          </span>
        </div>
      );
    },
  },
  {
    title({ list, updateList }) {
      const newList = list.map((item) => {
        return {
          ...item,
          biddingPrice: item.suggestedBidding,
        };
      });
      return (
        <Button disabled={!list.length} onClick={() => updateList(newList)}>
          应用全部
        </Button>
      );
    },
    width: "15%",
    key: "3",
    render(_, { index, row, updateField }) {
      return (
        <Button
          onClick={() =>
            updateField(index, "biddingPrice", row.suggestedBidding)
          }
        >
          Apply
        </Button>
      );
    },
  },
  {
    title: "竞价",
    titleTooltip: "2333",
    width: "20%",
    name: "biddingPrice",
    key: "4",
    rules: [{ required: true, message: "xxxx" }],
    render() {
      return <AInputMoney />;
    },
  },
  {
    title({ updateList }) {
      return <Button onClick={() => updateList([])}>删除所有</Button>;
    },
    align: "right",
    key: "5",
    render(_, { remove }) {
      return <Button onClick={() => remove()}>删除</Button>;
    },
  },
];

const CategoryGoodsSelector = () => {
  const [categoryType, setCategoryType] = useState("category");

  return (
    <div>
      <ATabs
        type="card"
        activeKey={categoryType}
        onTabClick={(e) => setCategoryType(e)}
      >
        <TabPane tab="分类" key="category">
          <CategorySelector />
        </TabPane>
        <TabPane tab="各个商品" key="goods">
          <GoodsSelector />
        </TabPane>
      </ATabs>

      <AForm.Item noStyle shouldUpdate>
        {(form) => {
          const categoryGoodsPosition = form.getFieldValue(
            "categoryGoodsPosition"
          );
          return <div className="fs-12 fw-600">定位数量为 {categoryGoodsPosition.length} 个</div>;
        }}
      </AForm.Item>
      <ATableForm
        name="categoryGoodsPosition"
        height={500}
        columns={columns}
        shouldUpdate
      />

      <div style={{ marginTop: 24 }}>
        <AText>
          某些商品不符合定向条件，也不会显示广告。{" "}
          <Button type="link">了解更多信息</Button>
        </AText>
      </div>
    </div>
  );
};

export default CategoryGoodsSelector;
