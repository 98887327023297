import React from 'react'
import './Fulfillment.css'
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'


function Fulfillment(){
    return(
        <div>

            <Header/>

                <div>
                <div className="end18_title">
					<span>亚马逊物流报告</span>
					<span>了解更多信息</span>
				</div>
				<div className="end18_content">
					
					<div className="end18_content_left">
						<ul className="end18_content_left_kc">
							<li> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;欢迎使用报告</li>
							<li>库存</li>
							<li>商品状态</li>
							<li>补货</li>
							<li>无在售信息的亚马逊库存</li>
							<li>"订购省"预测</li>
							<li>库存调整</li>
							<li>显示更多....</li>
						</ul>
						
						<ul className="end18_content_left_xs">
							<li>销售额</li>
							<li>奥特莱斯限时促销</li>
							<li>"订购省"绩效</li>
							<li>亚马逊配送货件</li>
							<li>所有订单</li>
							<li>显示更多....</li>
						</ul>
						
						<ul className="end18_content_left_fk">
							<li>付款</li>
							<li>费用预览</li>
							<li>库存仓储超量费用</li>
							<li>月储存费用</li>
							<li>长期仓储费</li>
							<li>显示更多....</li>
						</ul>
						
						<ul className="end18_content_left_mj">
							<li>买家优惠</li>
							<li>亚马逊物流买家退货</li>
							<li>换货</li>
						</ul>
						
						<ul className="end18_content_left_yc">
							<li>移除</li>
							<li>建议移除</li>
							<li>移除订单详情</li>
							<li>移除货件详情</li>
						</ul>
						
						<ul className="end18_content_left_bb">
							<li>亚马逊全球物流</li>
							<li>发票声明</li>
							<li>月度借记报表</li>
						</ul>
		
					</div>
					<div className="end18_content_right">
						<div className="end18_content_right_top">
							<div>
								现在，您可在库存状兄报告和移除报告的可下载饭本中查看需支付长期仓储费(6至12个月以及12个月或更长)的库存相关信息。
							</div>
							<div>
								迎使用"配送报告"页面●可在左列查看所有配送报告。报告分为'库存"s"硝留""“忖款”“买家优惠"以及"移除"这几部分·阅读下文·了解充分利用报告的一些有用技巧和帮助。
							</div>
						</div>
						<div className="end18_content_right_con">
							<div className="end18_content_right_con_txt">
								<span>下载报告</span>
								<p>
									您可以将报告下载为逗号分隔值(.csv)或文本(.txt)格式。如使用.csv格式，则可以在电子表格中打开报告。但是，MSKI
									J、ASIN和 FNSKU的前导零将被删除。如果您需要前导零，可以将文件下载为.txt格式o有关如何导入..txt文件的更多信息，
									请查看您正在使用的电子表格应用程序的帮助内容
								</p>
							</div>
							
							<div className="end18_content_right_con_txt">
								<span>为库存补货</span>
								<p>
									做出库存补货决定要求具备三个主要数据点∶销售速鹿(每周平均销里)、现有库存(可售勘里)和入库款里。
								这三个数据点用于计算您的库存在售完前可维持的时间·通常称为可维持周敌(WOC).计算方式为(现有库存＋入库数里)除以每周平均销里。
								</p>
							</div>
							<div className="end18_content_right_con_jy">
								<span>建议报告:</span>
								<p>
									<span>库存状况报告</span> (包含三个数据点和woC)、<span>管理亚马逊库存</span>和<span>亚马逊配送货件报告</span>
								</p>
							</div>
							<div className="end18_content_right_con_jy">
								<span>最佳实践:</span>
								<ul>
									<li>尽里将可维持周数保持在2(或补货所需周数)到13(销完一个季度库存所需周数)之间，为假日和其他繁忙销售时段备货时除外</li>
									<li>根据每周平均绡里不同的几个阶段。被实念的可维持同教。例加●如果您按最近30天销里得出的可维持周数大于按上周消里得出的可维持周数，贝可能意味着销里最近一直在上升。您需要尽快为库存朴货。</li>
								</ul>
							</div>
							<div className="end18_content_right_con_txt">
								<span>核对库存</span>
								<p>
									要彻底核对库存余里·有必要先了解影响期末余里的各种库存事件。每个库存事件都有一份对应的报告。可帮助您确定重要细节。我们建议您按如下方式计算库存余里:
								</p>
							</div>
							<div className="end18_content_right_con_bpld">
								期初库存余里+已接收库存-买家订单量＋买家退货量+/-盘点量-移除量=期末库存余里
							</div>
							<div className="end18_content_right_con_jy">
								<span>定义您的库存余量:</span>
								<p>
									使用 <span>每月库存历史记录</span> 报告可确定特定SKU或一组SKU上月的期末库存余里。
								</p>
							</div>
							<div className="end18_content_right_con_jy">
								<span>已接收库存:</span>
								<p>
									使用 <span>已接收库存</span> 报告确定要添加至期初库存余里的库存接收里。
								</p>
							</div>
							<div className="end18_content_right_con_jy">
								<span>买家订单量:</span>
								<p>
									买家订单通常会产生货件，导致您的总库存余里下降。使用 <span>已完成订单销售</span> 报告可确定给定时间段内特定SKU或一组SKU的已发货数里·
								</p>
							</div>
							<div className="end18_content_right_con_jy">
								<span>买家退货量:</span>
								<p>
									买家退货的商品数里重新添加至您的库存池中。使用 <span>亚马逊物流买家退货</span> 报告，了解特定SKU或一组SKU在指定时间段内退货并计入愈库存余里的商品教里。
								</p>
							</div>
							<div className="end18_content_right_con_jy">
								<span>盘点量:</span>
								<p>
									我们偶尔会因运营中心或买家突发事件而盘点您的库存余里。使用 <span>盘库</span> 报告可追踪这些盘点。
								</p>
							</div>
							<div className="end18_content_right_con_jy">
								<span>赔偿:</span>
								<p>
									盘点有时会引起赔偿。使用 <span>赔偿</span> 报告可查看您获得赔偿的商品。
								</p>
							</div>
							<div className="end18_content_right_con_jy">
								<span>移除量:</span>
								<p>
									提交从运营中心移除库存的订单将减少您的库存余里。要查看有关已移除数里的详情，请使用 <span>移除货件详情</span> 报告。
								</p>
							</div>
							
							<div className="end18_content_right_con_jy">
								<span>提示:</span>
								<ul>
									<li>核对库存的最佳方式是在每个报告中使用FNSKU字段。</li>
									<li>亚马逊会迫踪最近18个月内的详细库存交易。</li>
									<li>总库存水平将始终保持不变。</li>
									<li>要了解有关赔偿的更多信息，请参阅 <span>亚马逊物流丢失和已残损库存赔偿政策</span></li>
									<li>要获取配送报告中未解决问题的帮助，请联系 <span>销售伙伴支持</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>

                </div>

            <Footer/>
        </div>
    )
}

export default Fulfillment