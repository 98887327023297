import { useCallback, useEffect, useMemo, useState } from "react";

import { ASelect, ATable, AButton } from "@a-components";
import { Divider, Modal } from "antd";
import classNames from "classnames";

import style from "./style.module.css";
import Apis from "@api/apis";
import usePaginationTable from "@hooks/usePaginationTable";

const allOption = {
  label: "所有商品分类",
  value: -1,
  hide: true,
};

const choiceOther = {
  label: "选择其他类别",
  value: -9999,
  hide: true,
};

const tableColumns = [
  {
    title: "追踪编码",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "内部描述",
    key: "internalDescription",
    dataIndex: "internalDescription",
  },
  {
    title: "列表类型",
    key: "goodsListTypeName",
    dataIndex: "goodsListTypeName",
  },
  {
    title: "已创建",
    key: "createTime",
    dataIndex: "createTime",
  },
];

const CategorySelector = ({
  value,
  onChange,
  readOnly,
  allowSelectAll,
  ...otherProps
}) => {
  const [options, setOptions] = useState([]);

  const getPromotionGoodsList = useCallback((params = {}) => {
    return Apis.promotion.getPromotionGoodsList(params);
  }, []);

  const queryOptions = useCallback(() => {
    return getPromotionGoodsList().then((res) => {
      if (res.code !== 1) return res;
      setOptions(
        res.data.list.map((item) => ({
          label: item.name,
          value: item.promotionGoodsId,
        }))
      );
    });
  }, [getPromotionGoodsList]);

  useEffect(() => {
    queryOptions();
  }, [queryOptions]);

  const [modalVisible, setModalVisible] = useState(false);
  const [activeGoodsItem, setActiveGoodsItem] = useState({});
  const {
    tableList: _tableList,
    pagination,
    isTableLoading,
    operator: { updatePageInfo, resetPageIndex },
  } = usePaginationTable({
    fetcher: getPromotionGoodsList,
    defaultPageInfo: { pageSize: 20 },
  });

  const tableList = useMemo(() => {
    return _tableList.map((item) => ({
      ...item,
      key: item.promotionGoodsId,
    }));
  }, [_tableList]);

  const rowSelection = {
    selectedRowKeys: [activeGoodsItem.promotionGoodsId],
    onChange: (selectedRowKeys, selectedRows) => {
      setActiveGoodsItem(selectedRows[0]);
    },
    getRadioProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleShowModal = useCallback(() => {
    setModalVisible(true);
    resetPageIndex();
  }, [resetPageIndex]);

  const handleSureSelect = useCallback(() => {
    const { name, promotionGoodsId } = activeGoodsItem;

    const newOptions = options.find((v) => v.value === promotionGoodsId)
      ? options
      : options.concat({
          label: name,
          value: promotionGoodsId,
        });

    setOptions(newOptions);
    setActiveGoodsItem({});
    setModalVisible(false);
    onChange?.(promotionGoodsId);
  }, [activeGoodsItem, onChange, options]);

  const { current } = pagination;

  useEffect(() => {
    setActiveGoodsItem({});
  }, [current]);

  const tableFooter = useMemo(() => {
    return (
      <>
        <AButton onClick={() => updatePageInfo(pagination.current - 1)}>
          上一页
        </AButton>
        <AButton onClick={() => updatePageInfo(pagination.current + 1)}>
          下一页
        </AButton>
        <AButton onClick={() => setModalVisible(false)}>取消</AButton>
        <AButton type="primary" onClick={handleSureSelect}>
          确定
        </AButton>
      </>
    );
  }, [handleSureSelect, pagination, updatePageInfo]);

  if (readOnly) {
    return options.find((v) => v.value === value)?.label ?? "-";
  }

  return (
    <div>
      <ASelect
        options={options}
        value={value}
        onChange={onChange}
        {...otherProps}
        dropdownRender={(menu) => (
          <div>
            {menu}
            {allowSelectAll ? (
              <>
                <Divider style={{ margin: "4px 0" }} />
                <div
                  className={classNames(style.menuItem, {
                    active: value === allOption.value,
                  })}
                  onClick={() => onChange?.(allOption.value)}
                >
                  {allOption.label}
                </div>
              </>
            ) : null}

            <Divider style={{ margin: "4px 0" }} />
            <div
              className={classNames(style.menuItem, {
                active: value === choiceOther.value,
              })}
              onClick={() => handleShowModal()}
            >
              {choiceOther.label}
            </div>
          </div>
        )}
      ></ASelect>
      <Modal
        title="选择"
        width={1200}
        zIndex={2001}
        visible={modalVisible}
        footer={tableFooter}
        onCancel={() => setModalVisible(false)}
      >
        <ATable
          loading={isTableLoading}
          rowSelection={{
            type: "radio",
            ...rowSelection,
          }}
          dataSource={tableList}
          columns={tableColumns}
          scroll={{ y: 460 }}
          pagination={false}
        />
      </Modal>
    </div>
  );
};

export default CategorySelector;
