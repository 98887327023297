import React, { useCallback } from 'react';
import { Container, Btns } from './style';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { loginOut } from 'store/actions/login';
import { Select, Input, Popover } from 'antd';
import cookieUtils from "utils/cookie";

const { Option } = Select;
const { Search } = Input;
const HeaderRight = ({ dispatch }) => {
    const source = cookieUtils.getCookie('source')
    let websitData = [
        'www.amazon.com',
        'www.amazon.ca',
        'www.amazon.com.mx',
        'www.amazon.co.uk',
        'www.amazon.de',
        'www.amazon.fr',
        'www.amazon.it',
        'www.amazon.es',
        'www.amazon.jp'
    ];
    let languageData = [
        {
            value: 'zh',
            label: '中文'
        },
        {
            value: 'en',
            label: 'English'
        }
    ]

    const handleLoginOut = useCallback(() => {
        loginOut()
    }, []);
    const handleChangeLang = useCallback((lang) => {
        dispatch({
            type: 'SET_LANG',
            lang
        })
    }, []);
    return <Container>
        <span className="icon">Big Corn</span>
        {/* 网站 */}
        <Select className='websit' defaultValue="www.amazon.com">
            {websitData.map((item, index) => <Option key={index} value={item}>{item}</Option>)}
        </Select>
        {/* 语言 */}
        <Select className='language' defaultValue="zh" onChange={handleChangeLang}>
            {languageData.map((item, index) => <Option key={index} value={item.value}>{item.label}</Option>)}
        </Select>
        <Search className='search' placeholder="搜索" style={{ width: 165 }} />
        <Btns>
            <Link to="/BuyerMessage"><button><span>买家消息</span></button></Link>
            <button><span>帮助</span></button>
            <button className='header_set'>
                <Popover
                    getPopupContainer={trigger => trigger.parentNode}
                    placement="bottomRight"
                    trigger="hover"
                    content={
                        <ul>
                            {/* <li onClick={handleLoginOut}>退出</li> */}
                            {source != 0 ? null : <li onClick={handleLoginOut}>退出</li>}
                            <li><Link style={{ color: '#000' }} to="/accountinfo">账户信息</Link></li>
                            <li><Link style={{ color: '#000' }} to="/distributionSet">配送设置</Link></li>
                        </ul>
                    } >
                    设置
                </Popover>
                {/* <button><span>设置</span></button> */}
            </button>
        </Btns>
    </Container>
}

export default connect()(HeaderRight);