import React from 'react'
import './Deals.css'
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import ImageI from 'assets/images/end13_shuiguoji.png'
import ImageBtn from 'assets/images/end05ss.jpg'
import { Link } from 'react-router-dom'

function Deals() {
	return (
		<div>
			<Header />

			<div>

				<div className="gg04_cen">
					<div className="gg04_box">促销
			<span>了解更多信息</span>
					</div>
					<Link to="/Promotions">
						<div className="gg04_box2">创建新促销</div>
					</Link>
				</div>
				<div className="gg04_boxsz">
					<div>秒杀和7天促销</div>
				</div>
				<div className="gg04_flex">
					<input type="text" placeholder="按ASIN、SKU、促销名称搜索、内部描述搜索" className="gg04_flex_ss" />
					<img src={ImageBtn} alt="" />
					<div>状态：所有 <span>∨</span> </div>
					<div>类型/持续时间：所有<span>∨</span></div>
					<div>日期<span>∨</span></div>
				</div>
				<div className="gg04_bacac">
					<div>第1-15/66个秒杀</div>
					<div>
						<p>排序方式</p>
						<div>&nbsp;&nbsp;&nbsp;结束日期（从最早到最晚）<span>∨</span></div>
					</div>
				</div>
				<div className="gg04_bacac_tab">
					<div>内部描述
			<p className="gg04_brouy">主商品、SKU数量、促销类型</p>
					</div>
					<div>日期:
			<p>状态</p>
					</div>
					<div>秒杀费用</div>
					<div>销售额</div>
					<div>商品已售
			<p className="gg04_brouy">已确定参与</p>
					</div>
					<div>费用/销售额</div>
				</div>
				<div className="gg04_box-bor"></div>



				<table cellspacing="0" cellpadding="0" className="gg04_table">
					<tr>
						<td className="gg04_tdas" width="450">
							<div className="gg04_tdas_image"><img src={ImageI} alt="" /></div>
							<div className="gg04_boxtab">
								<p>秒杀-2013/11/15 8-34-35-499</p>
								<p>1 个 SKU</p>
								<p><svg t="1611388598987" className="icon gg04_hssd" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2600" width="10" height="10"><path d="M226.812517 1024l555.303105-664.605764h-230.510823L811.220779 0H420.010983L212.779221 554.897811h201.000371L226.812517 1024z" fill="#d81e06" p-id="2601"></path></svg><span>秒杀</span></p>
							</div>

						</td>
						<td width="300" className="gg04_sl">
							<p>周一，11月28 2016-12月 04 2016</p>
							<p>12:00凌晨 - 11:59晚上 PST</p>
							<span>已取消</span>
						</td>
						<td width="150">
							<span className="gg04_sp"></span>
						</td>
						<td width="150">US$0.00</td>
						<td width="150" className="gg04_sppa"><span className="gg04_sp"></span>
							<div className="gg04_topbox">43</div>

						</td>
						<td width="20"><span className="gg04_sp"></span></td>
						<td width="130">
							<Link to="/selectPromotion">
								<button className="gg04_but">查看</button>
							</Link>
						</td>
					</tr>
					<tr>
						<td className="gg04_tdas" width="450">
							<div className="gg04_tdas_image"><img src={ImageI} alt="" /></div>
							<div className="gg04_boxtab">
								<p>秒杀-2013/11/15 8-34-35-499</p>
								<p>1 个 SKU</p>
								<p><svg t="1611388598987" className="icon gg04_hssd" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2600" width="10" height="10"><path d="M226.812517 1024l555.303105-664.605764h-230.510823L811.220779 0H420.010983L212.779221 554.897811h201.000371L226.812517 1024z" fill="#d81e06" p-id="2601"></path></svg><span>秒杀</span></p>
							</div>
						</td>
						<td width="300" className="gg04_sl">
							<p>周一，11月28 2016-12月 04 2016</p>
							<p>12:00凌晨 - 11:59晚上 PST</p>
							<span>未定</span>
						</td>
						<td width="150">
							<span className="gg04_sp"></span>
						</td>
						<td width="150"><span className="gg04_sp gg04_we"></span></td>
						<td width="150" className="gg04_sppa"><span className="gg04_sp"></span>15</td>
						<td width="20"><span className="gg04_sp"></span></td>
						<td width="130">
							<Link to="/selectPromotion">
								<button className="gg04_but">&nbsp;&nbsp;&nbsp;&nbsp;查看&nbsp;&nbsp;&nbsp;<span className="gg04_spanle">&nbsp;∨&nbsp;</span></button>
							</Link>
						</td>
					</tr>
					<tr>
						<td className="gg04_tdas" width="450">
							<div className="gg04_tdas_image"><img src={ImageI} alt="" /></div>
							<div className="gg04_boxtab">
								<p>秒杀-2013/11/15 8-34-35-499</p>
								<p>1 个 SKU</p>
								<p><svg t="1611388598987" className="icon gg04_hssd" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2600" width="10" height="10"><path d="M226.812517 1024l555.303105-664.605764h-230.510823L811.220779 0H420.010983L212.779221 554.897811h201.000371L226.812517 1024z" fill="#d81e06" p-id="2601"></path></svg><span>秒杀</span></p>
							</div>
						</td>
						<td width="300" className="gg04_sl">
							<p>周一，11月28 2016-12月 04 2016</p>
							<p>12:00凌晨 - 11:59晚上 PST</p>
							<span>已结束</span>
						</td>
						<td width="150">
							<span className="gg04_sp"></span>
						</td>
						<td width="150"  >US$0.00</td>
						<td width="150" className="gg04_sppa"><span className="gg04_sp"></span>20</td>
						<td width="20"><span className="gg04_sp"></span></td>
						<td width="130">
							<Link to="/selectPromotion">
								<button className="gg04_but">查看</button>
							</Link>
						</td>
					</tr>


				</table>


			</div>




			<Footer />
		</div>
	)
}
export default Deals