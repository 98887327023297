import InformationHead from '../informationHead/informationHead'
import React, { useState } from 'react'
import './informationDetails.css'
import IconFont from 'components/Icon/Icon'
import { Link } from 'react-router-dom'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import { Modal, Button } from 'antd';

function InformationDetails() {

  function ClickBack() {
    window.history.back(-1);
  }
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <div>
      <Header />
      <div className="inforASIN_body InformationDetails">
        <div>
          <div className="Details_head">
            <div className="Details_headI">
              <div >
                <Link to="/Aa" style={{ color: "#008296" }}>A+商品描述管理器</Link>
              </div>
              <span>
                <IconFont type="icon-fanhui-copy-copy" />
              </span>
              <div>增强型商品描述</div>
              <span>
                <IconFont type="icon-fanhui-copy-copy" />
              </span>
              <div>宠物服饰</div>
            </div>

            <div className="inforASIN_head">
              <div className="informationHead_headII_btn"><button>取消</button></div>
              <div className="informationHead_headII_btn" ><button onClick={ClickBack}>返回</button></div>
              <div className="informationHead_headII_btnI"><button>保存为草稿</button></div>
              <div className="informationHead_headII_btnII"><button onClick={showModal} >提交拟供批准</button></div>
              
            </div>

          </div>
          <div>

          </div>
        </div>
        <InformationHead disable={true} />
        <div className="information_preview-box" >
          <div><Button>台式机</Button><Button style={{ marginLeft: 20 }} >移动设备</Button></div>
          <p style={{ margin: "20px 0px", color: "#008296" }} >预览链接</p>
          <div className="information_preview" >
            <img src={require("@/assets/images/information-bog.png").default}></img>
            <div>
              <p>Convenient Button</p>
              <p>share a photo</p>
              <p>The pet's exclusive air conditioner can be adjusted accordin the change of weather.</p>
              <div className="information_preview_tabs" ><span></span>Cool</div>
            </div>
            <div>
              <img src={require("@/assets/images/information-bog2.png").default}></img>
              <p>
                What will you get
                You will receive 4 pieces
                different color clothes ,
                Perfect for various
                occasions, such as seaside
                vacation, beadh party,
                traveling, camping, daily
                walking, shopping,
                etcPerfect for samll to
                medium large dogs, cats or
                other small animals .Please
                choose thesuitable size of
                shirts acc ording to the
                specifc size of yourdog

              </p>
              <div className="information_preview_tabs" ><span></span>Stay warm</div>
            </div>
          </div>
        </div>

      </div>
      <Footer />
      <Modal title="提供以供批准"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={<div><Button onClick={handleOk} >取消</Button><Button onClick={handleOk} style={{ backgroundColor: "#008296" }} ><Link to="InformationComplete" style={{ color: "#FFF" }}  >提交拟供批准</Link></Button></div>}
      >
        <p>您正在向亚马逊提交商品描述以供审核和发布。审核商品描述最长可能需要
          7个工作日，如果获得批准，该商品描述将在发布后的24小时内显示在详
          情页面上。如果您的商品描述未获得批准，我们会在商品描述中告知您原
          因。
        </p>
      </Modal>
    </div>
  )
}

export default InformationDetails