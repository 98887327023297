import React from 'react'
import './FeaturedOffer.css'
import '../../../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import { Link } from 'react-router-dom'
function FeaturedOffer() {
	return (
		<div>
			<Header />
			<div className="qd07_mytitle">
				<div className="qd07_titbox1">
					<p>定价状况<span>了解更多信息</span></p>
				</div>
				<div className="qd07_titbox2">
					<div className="qd07_con_x">X</div>
					<svg t="1610713529994" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14683"
						width="40" height="40">
						<path d="M512 93.090909c232.727273 0 418.909091 186.181818 418.909091 418.909091s-186.181818 418.909091-418.909091 418.909091-418.909091-186.181818-418.909091-418.909091 186.181818-418.909091 418.909091-418.909091m0-93.090909C228.072727 0 0 228.072727 0 512s228.072727 512 512 512 512-228.072727 512-512S795.927273 0 512 0z"
							fill="#2c2c2c" p-id="14684"></path>
						<path d="M512 279.272727m-46.545455 0a46.545455 46.545455 0 1 0 93.09091 0 46.545455 46.545455 0 1 0-93.09091 0Z"
							fill="#2c2c2c" p-id="14685"></path>
						<path d="M512 791.272727c-27.927273 0-46.545455-18.618182-46.545455-46.545454V418.909091c0-23.272727 18.618182-46.545455 46.545455-46.545455 23.272727 0 46.545455 18.618182 46.545455 46.545455v325.818182c0 27.927273-18.618182 46.545455-46.545455 46.545454z"
							fill="#2c2c2c" p-id="14686"></path>
					</svg>
					<div className="qd07_box_con">
						<p>注册亚马逊的"有竞争力的价格"规则。</p>
						<span>为了便于您使用"自动定价"自动做出定价决策，同时改善您的定价状况，亚马逊创建了多项预定义规则。您可以注册这些规则，方便管理定价。注册之前，可以先查看规则参数。要了解更多信息，请<span>点击此处</span>。</span>
					</div>
				</div>
			</div>
			<div className="qd09_zshou">
				<ul className="qd09_splist">
					<li><Link to="/PricingHealth">非在售商品</Link></li>
					<li>推荐报价</li>
					<li><Link to="">销售转换率</Link></li>
				</ul>
				<div className="qd09_gailaii">
					<p>推荐报价概览</p>
					<div className="qd09_flex2">
						<div className="qd09_glbox">
							<p>推荐报价百分比<svg t="1610713187918" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
								p-id="12436" width="18" height="18">
								<path d="M512 8a504.064 504.064 0 1 0 0.064 1008.064A504.064 504.064 0 0 0 512 8z m0 922.496A418.56 418.56 0 0 1 512 93.504a418.56 418.56 0 0 1 0 836.992z"
									p-id="12437" fill="#00BFFF"></path>
								<path d="M457.984 710.016a54.016 54.016 0 1 0 108.032 0 54.016 54.016 0 0 0-108.032 0zM484.992 584h54.016c4.928 0 8.96-4.032 8.96-8.96V268.928a9.024 9.024 0 0 0-8.96-8.96h-54.016a9.024 9.024 0 0 0-8.96 8.96v305.984c0 4.992 4.032 9.024 8.96 9.024z"
									p-id="12438" fill="#00BFFF"></path>
							</svg></p>
							<div className="qd09_gl_con">
								<p>100%</p>
								<span>
									上次评级计算时间：Dec 12 2020 16:00:00 GMT +0800
								</span>
							</div>
						</div>
						<div className="qd09_glbox">
							<p>不符合推荐报价的条件<svg t="1610713187918" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
								p-id="12436" width="18" height="18">
								<path d="M512 8a504.064 504.064 0 1 0 0.064 1008.064A504.064 504.064 0 0 0 512 8z m0 922.496A418.56 418.56 0 0 1 512 93.504a418.56 418.56 0 0 1 0 836.992z"
									p-id="12437" fill="#00BFFF"></path>
								<path d="M457.984 710.016a54.016 54.016 0 1 0 108.032 0 54.016 54.016 0 0 0-108.032 0zM484.992 584h54.016c4.928 0 8.96-4.032 8.96-8.96V268.928a9.024 9.024 0 0 0-8.96-8.96h-54.016a9.024 9.024 0 0 0-8.96 8.96v305.984c0 4.992 4.032 9.024 8.96 9.024z"
									p-id="12438" fill="#00BFFF"></path>
							</svg></p>
							<div className="qd09_gl_con">
								<p>0</p>
								<span className="qd09_gl_con_lan">
									采取措施
								</span>
							</div>
						</div>
						<div className="qd09_glbox">
							<p>符合推荐报价的条件<svg t="1610713187918" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
								p-id="12436" width="18" height="18">
								<path d="M512 8a504.064 504.064 0 1 0 0.064 1008.064A504.064 504.064 0 0 0 512 8z m0 922.496A418.56 418.56 0 0 1 512 93.504a418.56 418.56 0 0 1 0 836.992z"
									p-id="12437" fill="#00BFFF"></path>
								<path d="M457.984 710.016a54.016 54.016 0 1 0 108.032 0 54.016 54.016 0 0 0-108.032 0zM484.992 584h54.016c4.928 0 8.96-4.032 8.96-8.96V268.928a9.024 9.024 0 0 0-8.96-8.96h-54.016a9.024 9.024 0 0 0-8.96 8.96v305.984c0 4.992 4.032 9.024 8.96 9.024z"
									p-id="12438" fill="#00BFFF"></path>
							</svg></p>
							<p>但不是推荐报价</p>
							<div className="qd09_gl_con">
								<p>0</p>
								<span className="qd09_gl_con_lan">
									采取措施
								</span>
							</div>
						</div>
					</div>

					<div className="qd09_tiaojian">
						<ul className="qd09_tj_btn">
							<li>筛选条件：</li>
							<li className="qd09_timer" id="qd09_lfbor">15天</li>
							<li className="qd09_timer">30天</li>
							<li className="qd09_timer">45天</li>
							<li className="qd09_timer">60天</li>
						</ul>
						<img src="../images/qd09_baojia.png" />
						<ul className="qd09_tj_text">
							<li>销售转换率</li>
						</ul>
					</div>

				</div>
				<div className="qd09_cuos">
					<span>采取措施</span>
					<p>要解决此问题，您可以将商品总价（商品价格+运费）设置为等于或低于有竞争力的价格。以下是当前不符合成为推荐报价的条件的商品列表。要使您的报价符合成为商品详细页面上推荐报价的条件，您需要提供有竞争力的价格并满足其他资格条件，以便为买家提供卓越的购物体验。</p>
				</div>
				<p className="qd09_fizs">推荐报价</p>
				<div className="qd09_splist2box">
					<ul className="qd09_splist2">
						<li>不符合要求</li>
						<li>符合要求但未成为推荐报价</li>
					</ul>
				</div>

				<div className="qd09_tuijbox">
					<ul className="qd09_splist2 qd09_spl3">
						<li>价格没有竞争力</li>
						<li>高价</li>
					</ul>
					<ul className="qd09_fzsList">
						<li>
							<div className="qd09_miaos qd09_mar0">
								<p>商品名称</p>
								<p>ASIN</p>
							</div>
						</li>
						<li>
							<div className="qd09_miaos qd09_mar2">
								<p>定价状况问题</p>
								<p>描述</p>
							</div>
						</li>
						<li>
							<div className="qd09_miaos qd09_mar4">
								<p>自动定价<svg t="1610713529994" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
									p-id="14683" width="15" height="15">
									<path d="M512 93.090909c232.727273 0 418.909091 186.181818 418.909091 418.909091s-186.181818 418.909091-418.909091 418.909091-418.909091-186.181818-418.909091-418.909091 186.181818-418.909091 418.909091-418.909091m0-93.090909C228.072727 0 0 228.072727 0 512s228.072727 512 512 512 512-228.072727 512-512S795.927273 0 512 0z"
										fill="#2c2c2c" p-id="14684"></path>
									<path d="M512 279.272727m-46.545455 0a46.545455 46.545455 0 1 0 93.09091 0 46.545455 46.545455 0 1 0-93.09091 0Z"
										fill="#2c2c2c" p-id="14685"></path>
									<path d="M512 791.272727c-27.927273 0-46.545455-18.618182-46.545455-46.545454V418.909091c0-23.272727 18.618182-46.545455 46.545455-46.545455 23.272727 0 46.545455 18.618182 46.545455 46.545455v325.818182c0 27.927273-18.618182 46.545455-46.545455 46.545454z"
										fill="#2c2c2c" p-id="14686"></path>
								</svg></p>
								<p>状态</p>
							</div>
						</li>
						<li className="qd09_myp_right">
							<div className="qd09_miaos qd09_mar1">
								<p>价格</p>
								<p id="yunf">+ 运费</p>
							</div>
						</li>
						<li className="qd09_myp_right">
							<div className="qd09_miaos qd09_mar7">
								<p>有竞争力得价格<svg t="1610713529994" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
									p-id="14683" width="15" height="15">
									<path d="M512 93.090909c232.727273 0 418.909091 186.181818 418.909091 418.909091s-186.181818 418.909091-418.909091 418.909091-418.909091-186.181818-418.909091-418.909091 186.181818-418.909091 418.909091-418.909091m0-93.090909C228.072727 0 0 228.072727 0 512s228.072727 512 512 512 512-228.072727 512-512S795.927273 0 512 0z"
										fill="#2c2c2c" p-id="14684"></path>
									<path d="M512 279.272727m-46.545455 0a46.545455 46.545455 0 1 0 93.09091 0 46.545455 46.545455 0 1 0-93.09091 0Z"
										fill="#2c2c2c" p-id="14685"></path>
									<path d="M512 791.272727c-27.927273 0-46.545455-18.618182-46.545455-46.545454V418.909091c0-23.272727 18.618182-46.545455 46.545455-46.545455 23.272727 0 46.545455 18.618182 46.545455 46.545455v325.818182c0 27.927273-18.618182 46.545455-46.545455 46.545454z"
										fill="#2c2c2c" p-id="14686"></path>
								</svg></p>
								<p></p>
							</div>
						</li>
						<li>
							<div className="qd09_miaos qd09_mar5">
								<p>操作</p>
								<p></p>
							</div>
						</li>
					</ul>
					<div className="qd09_gengduo">
						<p>您没有不符合成为推荐报价得条件的商品。</p>
					</div>
				</div>


			</div>
			<Footer />
		</div>




	)
}

export default FeaturedOffer