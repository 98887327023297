import React from 'react'
import './Lending.css'
import Footer from 'components/addlisting_footer/FooterCom'
import Header from 'components/home_header'
import Imgjd from 'assets/images/jd.png'
import Imgjdy from 'assets/images/jd_y.png'
import Imgjde from 'assets/images/jd_e.png'
import Imgjds from 'assets/images/jd_s.png'
import Imgjd4 from 'assets/images/jd_4.png'
import ImageI from 'assets/images/Lending_list.png'
import ImageII from 'assets/images/Lending_listII.png'
import ImageIII from 'assets/images/Lending_listIII.png'


function Lending(){
    return(
        <div>
            <Header/>
            <div className="Lending_head">
                <img src={Imgjd}/>
            </div>
            <div className="Lending_head_font">
                <div>亚马逊贷款邀请合格卖家申请 3 个月、6 个月、9 个月或 12 个月的定期贷款</div>
                <div>用于营运资金需求。我们评估具有销售增长的良好记录的卖家</div>
                <div>并提供最高水平的客户满意度。</div>
            </div>

            <div className="Lending_message">
                <div className="Lending_message_titel">谁有资格？</div>
                <div className="Lending_message_content">看来您目前没有申请邀请，但我们每天评估卖家账户。出其他标准外，这些是决定资格的重要因素。</div>
                <div className="Lending_message_image">
                    <div><img src={Imgjdy} /></div>
                    <div><img src={Imgjde} /></div>
                    <div><img src={Imgjds} /></div>
                    <div><img src={Imgjd4} /></div>
                </div>
               

            </div>
            <div className="Lending_List">
                    <div className="Lending_List_titel">它是如何工作的？</div>
                    <div className="Lending_List_content">
                        <div className="Lending_List_contentBox">
                            <div className="Lending_List_img"> <img src={ImageI}/> </div>
                            <div>申请贷款邀请将通过电子邮件发送或显示在卖家平台主页上</div>
                        </div>
                        <div className="Lending_List_contentBox">
                            <div className="Lending_List_img"><img src={ImageII}/> </div>
                            <div>提交简单的一页申请-批准后，大多数贷款都会在五个工作日内发放</div>
                        </div>
                        <div className="Lending_List_contentBox">
                        <div className="Lending_List_img"><img src={ImageIII}/> </div>
                            <div>贷款还款将自动从未来的销售付款中扣除</div>
                        
                        </div>
                    </div>
                </div>
            <Footer/>
        </div>
    )
}

export default Lending