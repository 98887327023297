import React from 'react'
import AJAX from "api/apis";
import './BuyerMessage.css'
import { List, Input, Select, Button, Pagination } from 'antd';
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import Reactours from "components/reactours"
import TipsIcon from "components/tipsIcon"
import index from '@view/advertisement/module/AdvertisingCampaign/component/history';
import item from '@components/home_footer/item';
const { Search } = Input;
const { TextArea } = Input
const { Option } = Select;

class BuyerMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            msgList: [],
            msgCotent: {},
            msgId: null,
            page: 1,
            size: 10,
            total: 10,
            msgText: '',
            linkDisplay: true,
            orderDisplay: true,
            isTourOpen: true,
            isDisabled:true,
            steps1: [
                {
                    selector: '.BuyerMessage_box #messagesEndRef',
                    content: '查看买家邮件内容',
                },
                {
                    selector: '.BuyerMessage_box .text_operation_textArea',
                    content: '输入回复消息',
                },
                {
                    selector: '.BuyerMessage_box .stepGuidanceBox1',
                    content: '买家购买的订单编号，回复的邮件必须要跟操作资料的订单编号一致',
                },
                {
                    selector: '.BuyerMessage_box .stepGuidanceBox2',
                    content: '购买的商品链接',
                }
            ],
            isActive:false
        }
    }
    componentDidMount() {
        this.getOrderMsgList(this.state.page, this.state.size);
    }
    awakenTips = (type) =>{
        this.setState({
            isActive:type
        })
    }
    scrollToBottom = () => {
        let messagesEndRef = document.querySelector('#messagesEndRef');
        messagesEndRef.scrollTop = messagesEndRef.scrollHeight - messagesEndRef.clientHeight;
        // this.setState({})
    };
    // 获取消息列表，内容及订单信息
    getOrderMsgList = (page = 1, size = 10) => {
        AJAX.orderMsg.getOrderMsgList({ page, size }).then((res) => {
            if (res.code == 1 && res.data) {
                this.upOrderMsgRead(res.data.list[0].orderId);
                res.data.list[0].isRead = true
                this.setState({
                    msgList: res.data,
                    msgId: res.data.list[0].id,
                    msgCotent: res.data.list[0],
                    page: res.data.pageNum,
                    size: res.data.pageSize,
                    total: res.data.total
                })
                this.scrollToBottom()
            }
        })

    }
    // 将消息标记为已读
    upOrderMsgRead = (orderId) => {
        AJAX.orderMsg.upOrderMsgRead(orderId).then((res) => { })
    }
    // 列表分页
    pageChange = (page, size) => {
        this.setState({
            page: page,
            size: size
        })
        this.getOrderMsgList(page, size)

    }
    // 消息切换
    switchMsg = (item) => {
        let { msgList } = this.state
        this.upOrderMsgRead(item.orderId);
        for (let items of msgList.list) {
            if (item.orderId == items.orderId) items.isRead = true;
        }
        this.setState({
            msgId: item.id,
            msgCotent: item,
            msgList: msgList
        })
    }
    // 获取消息文本框内容
    msgTextArea = (e) => {
        let type = false;
        if(!e.target.value){
            type = true
        }
        this.setState({
            msgText: e.target.value,
            isDisabled:type
        })
    }
    // 发送消息
    sendMsg = () => {
        let { msgText, msgCotent, msgList,loading } = this.state;
        if(loading) return
        this.setState({loading:true})
        AJAX.orderMsg.setOrderMsg({ msgText, orderId: msgCotent.orderId }).then((res) => {
            if (res.code == 1 && res.data) {
                for (let items of msgList.list) {
                    if (items.orderId == msgCotent.orderId) {
                        items.msgInfoRespList.push(res.data);
                        // msgCotent.msgInfoRespList.push(res.data);
                    }
                }
                this.setState({
                    msgList: msgList,
                    // msgCotent:msgCotent
                    isDisabled:true,
                    loading:false,
                    msgText: ''
                })
                this.scrollToBottom()
            }
        }).catch(()=>{
        this.setState({loading:true})
        })
    }
    render() {
        let { msgList, msgId, msgCotent, total, page, linkDisplay, orderDisplay, msgText, steps1, isTourOpen,isActive,isDisabled} = this.state
        return <div>
            <Header />
            <div className="BuyerMessage_box">
                <div>
                    <div className="BuyerMessage_left">
                        {/* 消息列表，筛选 */}
                        <div className="BuyerMessage_left_head">
                            <div className="BuyerMessage_left_titel">消息</div>
                            <div className="BuyerMessage_left_select">
                                <Select style={{ width: '150px' }} defaultValue="所有消息">
                                    <Option>所有消息</Option>
                                    <Option>需要回复</Option>
                                    <Option>未读</Option>
                                    <Option>已发送消息</Option>
                                    <Option>已接收消息</Option>
                                    <Option>按日期筛选</Option>
                                    <Option>企业卖家</Option>
                                    <Option>已存档</Option>
                                </Select>
                            </div>
                        </div>
                        {/* 列表搜索 */}
                        <div className="listSearch">
                            <Search placeholder="输入订单编号或电子邮件地址" enterButton="搜索" allowClear onSearch={this.onSearch} />
                        </div> 
                        {/* 列表 */}
                        {msgList.list ? <List
                            itemLayout="horizontal"
                            dataSource={msgList.list}
                            renderItem={item => (
                                <List.Item className={msgId == item.id ? "listMsg" : null} onClick={() => { this.switchMsg(item) }}>
                                    <List.Item.Meta className={item.isRead == false ? 'titleBolds' : null}
                                        title={<div style={{ display: 'flex', justifyContent: 'space-between' }}><span>{item.userName}</span><span>{item.msgDate}</span></div>}
                                        description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                                    />
                                </List.Item>
                            )}
                        /> : <div className="minHeighg520"></div>}
                        <Pagination style={{ textAlign: 'center' }} defaultCurrent={1} current={page} total={total} onChange={this.pageChange}></Pagination>
                    </div>
                    <div className="BuyerMessage_content">

                        {/* 用户/商家信息 */}
                        {msgList.list ? <><div className="BuyerMessage_content_title">
                            <p>
                                <span className="bolds fz15">{msgCotent.userName}</span>
                            </p>
                            <p>Update from Seller on Order ID {msgCotent.orderNumber}</p>
                        </div>
                            <div className="BuyerMessage_content_text" id="messagesEndRef">
                                {msgCotent.msgInfoRespList ? msgCotent.msgInfoRespList.map((item, idnex) => {
                                    return <div key={index} className={item.msgSetPerson == 0 ? 'content_text_right' : 'content_text_left'}>
                                        <div>
                                            <p>{item.createTime}</p>
                                            <div>{item.msgText}</div>
                                        </div>
                                    </div>
                                }) : null}
                            </div>
                            <div className="text_operation">
                                <div className="text_operation_select">
                                    <Select defaultValue="选择模板" style={{ width: 200 }}>
                                        <Option value="索评">索评</Option>
                                        <Option value="Shipment Information">Shipment Information</Option>
                                        <Option value="客户">客户</Option>
                                        <Option value="客诉">客诉</Option>
                                        <Option value="发货确认有奖">发货确认有奖</Option>
                                    </Select>
                                </div>
                                <div className="text_operation_textArea">
                                    <TextArea onChange={this.msgTextArea} value={msgText} showCount maxLength={3000} ></TextArea>
                                </div>
                                <div className="text_operation_button">
                                    <div>
                                        <Button>举报信息</Button>
                                    </div>
                                    <div>
                                        <Button>存档</Button>
                                        <Button>添加附件</Button>
                                        <Button>不需要回复</Button>
                                        <Button disabled={isDisabled} onClick={this.sendMsg}>发送</Button>
                                    </div>
                                </div>
                            </div></> : <div className="noMsgBox">没有需要回复的消息。</div>}
                    </div>
                    {/* 订单信息 */}
                    <div className="BuyerMessage_right">
                        {/* 链接 */}
                        <div>
                            <div onClick={() => { this.setState({ linkDisplay: !linkDisplay }) }} className="BuyerMessage_right_title">
                                <p>链接</p>
                                <div>
                                    {!linkDisplay ? <svg t="1620295636101" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7365" width="16" height="16"><path d="M326.714 950.611l415.897-415.897c6.271-6.271 9.399-14.495 9.386-22.714 0.013-8.219-3.115-16.443-9.386-22.714L326.714 73.389c-12.516-12.516-32.809-12.516-45.325 0-5.712 5.712-8.808 13.044-9.307 20.516-0.048 0.67-0.082 1.36-0.082 2.095v832c0 0.735 0.034 1.425 0.083 2.095 0.499 7.473 3.595 14.804 9.307 20.516 12.515 12.516 32.808 12.516 45.324 0z" p-id="7366"></path></svg>
                                        : <svg t="1620295751970" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8372" width="18" height="18"><path d="M950.611 326.714L534.714 742.611c-6.271 6.271-14.495 9.399-22.714 9.386-8.219 0.013-16.443-3.115-22.714-9.386L73.389 326.714c-12.516-12.516-12.516-32.809 0-45.325 5.712-5.712 13.044-8.808 20.516-9.307 0.67-0.048 1.36-0.082 2.095-0.082h832c0.735 0 1.425 0.034 2.095 0.083 7.473 0.499 14.804 3.595 20.516 9.307 12.516 12.515 12.516 32.808 0 45.324z" p-id="8373"></path></svg>}
                                </div>
                            </div>
                            {linkDisplay ? <div className="linkBox">
                                <p>发送消息的权限</p>
                                <p>管理邮件模板</p>
                                <p>消息政策</p>
                                <p>
                                    <span>客户服务评分</span>
                                    <span className="testBox">测试版</span>
                                </p>
                            </div> : null}
                        </div>
                        {/* 订单 */}
                        {msgList.list ? <div className="orderBox">
                            <div onClick={() => { this.setState({ orderDisplay: !orderDisplay }) }} className="BuyerMessage_right_title">
                                <p style={{ color: '#000' }}>订单</p>
                                <div>
                                    {!orderDisplay ? <svg t="1620295636101" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7365" width="16" height="16"><path d="M326.714 950.611l415.897-415.897c6.271-6.271 9.399-14.495 9.386-22.714 0.013-8.219-3.115-16.443-9.386-22.714L326.714 73.389c-12.516-12.516-32.809-12.516-45.325 0-5.712 5.712-8.808 13.044-9.307 20.516-0.048 0.67-0.082 1.36-0.082 2.095v832c0 0.735 0.034 1.425 0.083 2.095 0.499 7.473 3.595 14.804 9.307 20.516 12.515 12.516 32.808 12.516 45.324 0z" p-id="7366"></path></svg>
                                        : <svg t="1620295751970" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8372" width="18" height="18"><path d="M950.611 326.714L534.714 742.611c-6.271 6.271-14.495 9.399-22.714 9.386-8.219 0.013-16.443-3.115-22.714-9.386L73.389 326.714c-12.516-12.516-12.516-32.809 0-45.325 5.712-5.712 13.044-8.808 20.516-9.307 0.67-0.048 1.36-0.082 2.095-0.082h832c0.735 0 1.425 0.034 2.095 0.083 7.473 0.499 14.804 3.595 20.516 9.307 12.516 12.515 12.516 32.808 0 45.324z" p-id="8373"></path></svg>}
                                </div>
                            </div>
                            {orderDisplay ? <div>
                                <div className="stepGuidanceBox1">
                                    <p className="bolds">编号</p>
                                    <p>{msgCotent.orderNumber}</p>
                                </div>
                                <div>
                                    <p className="bolds">购买日期</p>
                                    <p>{msgCotent.createTime}</p>
                                </div>
                                <div>
                                    <p className="bolds">发货日期</p>
                                    <p>{msgCotent.deliveryStartDate}</p>
                                </div>
                                <div>
                                    <p className="bolds">送达日期</p>
                                    <p>{msgCotent.takeStartDate}</p>
                                </div>
                                {/* <div>
                                    <p className="bolds">承运人</p>
                                    <p>fedEX</p>
                                </div>
                                <div>
                                    <p className="bolds">追踪编码</p>
                                    <p>123456465123113123131132</p>
                                </div>
                                <div>
                                    <p className="bolds">状态</p>
                                    <p>已发货</p>
                                </div>
                                <div>
                                    <p className="bolds">状态日期</p>
                                    <p>2021-5-6 下午2:34</p>
                                </div> */}
                                <div className="stepGuidanceBox2">
                                    <p className="bolds">商品（数量：1）</p>
                                    <p>{msgCotent.goodsName}</p>
                                </div>
                            </div> : null}
                        </div> : null}
                    </div>
                </div>
            </div>
            <Footer />
            {/* 引导步骤 */}
            <TipsIcon clicks={this.awakenTips} />
            <Reactours routePath="BuyerMessage" isActive={isActive} clicks={this.awakenTips} steps={steps1} isOpen={isTourOpen} />
        </div>

    }
}

export default BuyerMessage