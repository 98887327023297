import React,{Component} from 'react'
import './ReferralProgram.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import IconFont from 'components/Icon/Icon'

class ReferralProgram extends Component{
    render(){
        return(
            <div>
                <Header/>

                <div className="Referral_body">
                    <div className="Referral_head">
                    <div className="Referral_head_titel">
                        <div>推荐方案</div>
                        <span>每日刷新一次，上次刷新时间为太平洋时间凌晨 12：01</span>
                    </div>
                    <div className="Referral_head_content">
                        此页面提供动态建议，以帮助您优先处理适当任务，从而在亚马逊开创业务。
                    </div>
                    <div className="Referral_head_bottom"><div></div></div>
                    </div>
                    
                    <div className="Referral_content">
                        <div className="Referral_content_left">
                            <div className="Referral_content_left_titel">
                                <div>发布另一个广告活动</div>
                                <span><IconFont type="icon-shenglvehao"/> </span>
                            </div>
                            <div className="Referral_content_left_content">继续投放广告帮助推广您的商品</div>
                            <div className="Referral_content_left_button"><button>创建更多广告活动</button></div>
                        </div>
                        <div className="Referral_content_right">
                            <div className="Referral_content_right_img">
                                <img src={require("../../assets/images/ReferralI.jpg").default} />
                                <div className="Referral_content_right_img_icon"><IconFont type="icon-bofang" /></div>
                            </div>
                        </div>
                    </div>

                    <div className="Referral_content_bottom">

                        <div className="Referral_content_bottom_Box">
                            <div className="Referral_content_bottom_Box_titel">
                                <div>创建A+商品描述</div>
                                <span><IconFont type="icon-shenglvehao"/> </span>
                            </div>

                            <div className="Referral_content_bottom_Box_img">
                                <img src={require("../../assets/images/Referral2.jpg").default} />
                            </div>
                            
                            <div className="Referral_content_bottom_content">让商家了解您的商品和品牌</div>
                            <div className="Referral_content_bottom_btn"><button>创建 A+ 商品描述</button></div>
                        </div>

                        <div className="Referral_content_bottom_Box">
                            <div className="Referral_content_bottom_Box_titel">
                                <div>获取更多评论</div>
                                <span><IconFont type="icon-shenglvehao"/> </span>
                            </div>

                            <div className="Referral_content_bottom_Box_img">
                                <img src={require("../../assets/images/Referral3.jpg").default} />
                            </div>
                            
                            <div className="Referral_content_bottom_content">在早期评论者计划中注册更多商品</div>
                            <div className="Referral_content_bottom_btn"><button>选择要注册的SKU</button></div>
                        </div>

                        <div className="Referral_content_bottom_Box">
                            <div className="Referral_content_bottom_Box_titel">
                                <div>添加更多商品</div>
                                <span><IconFont type="icon-shenglvehao"/> </span>
                            </div>

                            <div className="Referral_content_bottom_Box_img">
                                <img src={require("../../assets/images/Referral4.jpg").default} />
                                <div className="Referral_content_right_img_iconII"><IconFont type="icon-bofang" /></div>
                            </div>
                            
                            <div className="Referral_content_bottom_content">持续扩大您的商品目录</div>
                            <div className="Referral_content_bottom_btn"><button>创建更多商品信息</button></div>
                        </div>
                    </div>



                </div>

                <Footer/>
            </div>
        )
    }
    
}

export default ReferralProgram