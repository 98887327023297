import React, { useRef, useEffect, useCallback, useState } from 'react';
import './index.less'
import { Button, Modal } from 'antd';
import Footer from 'components/addlisting_footer/FooterCom'
import { regSubmit } from 'utils/PublicLibrary';


export default (props) => {
  const [value, onChange] = useState(false);
  let [isModalVisible, setIsModalVisible] = useState(false)

  let nextEvent = useCallback(() => {
    regSubmit([], null, '', 17).then(res => {
      if (res.code == 1) {
        props.history.push('/twoStepVerification/Approve')
      }
    })

  })
  const openModal = () => {
    const modal = Modal.info();
    modal.update({
      title: <div><span>来自 1069431612031111163 的信息</span> <span onClick={() => { modal.destroy() }}>x</span></div>,
      width: '550px',
      className: 'IssuingAddress_modal',
      icon: false,
      okText: '',
      centered: true,
      content: (
        <div>
          [Amazon] <a onClick={() => { modal.destroy(); nextEvent() }}>https://amazon.com.au/a/c/r?k=StTKAvCPUWAe3UkhedBqlm33C</a>
          <div>Amaz</div>
        </div>
      ),
      onOk() {
        // console.log(props);
        props.history.push('/videoAuthentication/AddressVerification')
      },
    });
  }


  return (
    <div className='IssuingAddress register_style'>
      <div className='s_login'>
        <div className='image'><img src={require('@assets/images/IssuingAddress.png').default} /></div>
        <b>请核准发送至以下地址的通知：</b>
        <div className='num'><span>手机号码</span>***-***-**05</div>
        <br></br>
        <div className='a-text'>没有收到？</div>
        <a>重新发送通知</a>
        <div className='a-text'>您已经回复了吗？</div>
        <a>点击此处刷新页面。</a>
        <p>需要帮助？ 请联系<a>客户服务</a>。</p>
      </div>
      <div className="elailTips" onClick={() => { openModal() }}>
        <img src={require("@/assets/img/register/phone_pinComing.png").default} />
      </div>
      <Footer></Footer>
    </div>
  )
}