import AForm from "@a-components/AForm";
import AText from "@a-components/AText";
import classNames from "classnames";
import { useCallback, useMemo } from "react";
import "./style.css";

const AFormItem = AForm.Item;

const TableForm = ({
  height = "auto",
  columns = [],
  name: formFieldName,
  dependencies,
  shouldUpdate,
  empty = "暂无数据",
  wrapperBorder = true,
  rowBottomBorder = true,
  dataSource,
  onChange,
  style,
}) => {
  const computedRowStyle = useCallback(
    ({ width, align = "left" }, className) => {
      return {
        className: classNames(className, align),
        style: { width: width },
      };
    },
    []
  );

  const isFormList = !!formFieldName;
  const getDataSource = useCallback(
    (form) => {
      return form.getFieldValue(formFieldName);
    },
    [formFieldName]
  );

  const setDataSource = useCallback(
    (form, value) => {
      if (form) {
        return form.setFieldsValue({ [formFieldName]: value });
      }

      return onChange?.(value);
    },
    [formFieldName, onChange]
  );

  const updateField = useCallback(
    (form, index, name, value) => {
      const dataSource = getDataSource(form);
      const newDataSource = dataSource.map((item, _index) => {
        if (index !== _index) return item;
        return {
          ...item,
          [name]: value,
        };
      });

      setDataSource(form, newDataSource);
    },
    [getDataSource, setDataSource]
  );

  const removeItem = useCallback(
    ({ form, list, index }) => {
      if (index < 0) {
        throw new Error("The 'index' must be greater than or equal to zero!");
      }
      setDataSource(
        form,
        list.filter((_, _index) => _index !== index)
      );
    },
    [setDataSource]
  );

  const createRenderConfig = useCallback(
    ({ index, name, row, list, form }) => {
      return {
        index,
        name,
        row,
        list,
        form,
        updateField: (...args) => updateField(form, ...args),
        updateCurrentRow: (...args) => updateField(form, index, ...args),
        updateList: (v) => setDataSource(form, v),
        remove: () => removeItem({ list, index, form }),
        clearAll: () => setDataSource(form, []),
      };
    },
    [updateField, setDataSource, removeItem]
  );

  const renderTableHeader = useCallback(
    ({ form, listData }) => {
      return (
        <table>
          <thead className="a-tableform__header">
            <tr className="w-100percent">
              {columns.map((item, index) => {
                const { title } = item;
                let content = title;
                const isFuncTitle = typeof title === "function";
                if (isFuncTitle) {
                  content = title(
                    createRenderConfig({
                      row: undefined,
                      index: -1,
                      list: listData,
                      form,
                    })
                  );
                }
                const rowStyle = computedRowStyle(
                  item,
                  "a-tableform__header-item a-tableform__row-item"
                );
                return (
                  <th {...rowStyle} key={item.key || item.name || index}>
                    {isFuncTitle ? (
                      content
                    ) : (
                      <AText tooltip={item.titleTooltip}>{content}</AText>
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
        </table>
      );
    },
    [columns, computedRowStyle, createRenderConfig]
  );

  const renderRowItem = useCallback(
    ({ index, listData, form }) => {
      return (
        <tr key={index} className="a-tableform__row">
          {columns.map((item) => {
            const currentRowValue = listData[index];
            const rowItemStyle = computedRowStyle(
              item,
              "a-tableform__row-item a-tableform__body-row-item"
            );

            const currentValue = currentRowValue[item.name];

            const childNode = item.render ? (
              item.render(
                currentValue,
                createRenderConfig({
                  row: currentRowValue,
                  index,
                  list: listData,
                  form,
                })
              )
            ) : (
              <AText className={item.className}>{currentValue}</AText>
            );

            return (
              <td {...rowItemStyle} key={item.key || item.name || index}>
                {item.rules && isFormList ? (
                  <AFormItem
                    key={index}
                    name={[index, item.name]}
                    fieldKey={[index, item.name]}
                    rules={item.rules}
                  >
                    {childNode}
                  </AFormItem>
                ) : (
                  childNode
                )}
              </td>
            );
          })}
        </tr>
      );
    },
    [columns, computedRowStyle, createRenderConfig, isFormList]
  );

  const renderTableBody = useCallback(
    ({ listData, form }) => {
      const isEmpty = listData.length === 0;

      return (
        <>
          <div
            style={{ height: isEmpty ? 0 : "auto", overflow: "auto" }}
            className={isEmpty ? "" : "f-1"}
          >
            <table className="a-tableform__table">
              <tbody>
                {isFormList ? (
                  <AForm.List name={formFieldName}>
                    {(fields) => {
                      return fields.map(({ key }) =>
                        renderRowItem({ index: key, listData, form })
                      );
                    }}
                  </AForm.List>
                ) : (
                  listData.map((_, index) => {
                    return renderRowItem({ index, listData, form });
                  })
                )}
              </tbody>
            </table>
          </div>

          {isEmpty ? (
            <div
              style={{ height: height === "auto" ? undefined : height }}
              className="w-100percent df jc-c ai-c h-254 c-999 empty fs-12"
            >
              {empty}
            </div>
          ) : null}
        </>
      );
    },
    [empty, formFieldName, height, isFormList, renderRowItem]
  );

  const renderMain = useCallback(
    ({ form, listData }) => {
      return (
        <div
          className={classNames("a-tableform__container df fd-c", {
            "wrapper-border": wrapperBorder,
            "row-bottom-border": rowBottomBorder,
          })}
          style={{ height, ...(style || {}) }}
        >
          {/* 表头 */}
          {renderTableHeader({ form, listData })}

          {/* 表体 */}
          {renderTableBody({ form, listData })}
        </div>
      );
    },
    [
      height,
      renderTableBody,
      renderTableHeader,
      rowBottomBorder,
      style,
      wrapperBorder,
    ]
  );

  if (!isFormList) {
    return renderMain({ form: null, listData: dataSource });
  }

  return (
    <AFormItem noStyle shouldUpdate={shouldUpdate} dependencies={dependencies}>
      {(form) => {
        const listData = getDataSource(form);

        return renderMain({ form, listData });
      }}
    </AFormItem>
  );
};

export default TableForm;
