import React, { useRef, useEffect, useCallback, useState } from 'react';
import AJAX from "api/apis";
import './shop.less';
import RegisterSteps from 'components/registerSteps';
import Header from 'components/home_header';
import Footer from 'components/addlisting_footer/FooterCom';
import Reactours from "components/reactours";
import TipsIcon from "components/tipsIcon";
import RegisterCollapse from 'components/registerCollapse';
import { Button, Radio, Input, Form, Space } from 'antd';
import { Link } from 'react-router-dom';
import { regSubmit, getStoreRegisterInfo } from 'utils/PublicLibrary';

export default (props) => {
  let formRef = useRef();
  let [loading, setLoading] = useState(false)
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [brand, setBrand] = useState('');
  const [brandErr, setBrandErr] = useState(false);
  const [trademarkErr, setTrademarkErr] = useState(false);
  const [upcErr, setUpcErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);

  const [isReturn, setIsReturn] = useState(props.location?.state?.isReturn || 0);
  const steps1 = [
    {
      selector: '.stepGuidanceBox0',
      content: '英文填写店铺名称，可自行设置，非公司名称',
    },
    // {
    //   selector: '.stepGuidanceBox1',
    //   content: '有效期限，前面填到期月份/后面填到期年份',
    // },
    // {
    //   selector: '.stepGuidanceBox2',
    //   content: '法人的姓名，填写英文',
    // },
  ];
  const collapseList = [
    {
      header: '什么是店铺名称？',
      html: '<p>店铺名称是在您的“卖家资料”和您的亚马逊商品页面上向买家显示的名称。</p>'
    },
    {
      header: '我能否稍后更新店铺名称？',
      html: '<p>能。您可以通过访问卖家账户的“设置”选项卡提供新的公司显示名称。</p> '
    },
    {
      header: '什么是 UPC？',
      html: '<p>大多数情况下，亚马逊会要求卖家提供行业标准商品编码，如通用商品编码（UPC，或图书商品的 ISBN）。 这些编码可帮助我们将商品信息与亚马逊目录中的现有商品进行匹配。</p>'
    },
    {
      header: '为什么要提供商标信息？',
      html: '<p>亚马逊品牌注册可帮助您保护您的注册商标，并为买家打造准确且值得信赖的体验。 目前，品牌必须有注册商标才有资格注册此计划。</p>'
    },
    {
      header: '为什么我的商品信息将在欧盟的5个商城中启用？',
      html: '<p>欧盟支持统一注册，并且作为其中的一部分，我们在欧洲所有商城注册卖家。 欧盟的5个商城为英国、德国、意大利、西班牙、法国</p>'
    },
  ]
  const [isActive, setIsActive] = useState(false);
  const awakenTips = (type) => {
    setIsActive(type);
  }
  useEffect(() => {
    getRegInfo()
  }, [])
  let getRegInfo = useCallback(() => {
    getStoreRegisterInfo().then((res) => {
      for (let item of res.data) {
        if (item.propertyValue) {
          if (item.propertyKey == "store_name") {
            formRef.current.setFieldsValue({
              store_name: item.propertyValue
            })
          }
          if (item.propertyKey == "commodity_is_manufacturer_brand") {
            formRef.current.setFieldsValue({
              commodity_is_manufacturer_brand: item.propertyValue
            })
            setBrand(item.propertyValue)
          }
          if (item.propertyKey == "commodity_is_upc") {
            formRef.current.setFieldsValue({
              commodity_is_upc: item.propertyValue
            })
          }
          if (item.propertyKey == "commodity_registered_trademark") {
            formRef.current.setFieldsValue({
              commodity_registered_trademark: item.propertyValue,
            })
          }

        }
      }
    })
    if (isReturn) {
      AJAX.login.getStoreRegisterInfo([]).then((data) => {
        for (let item of data.data) {
          if (item.propertyValue) {
            if (item.propertyKey == "store_name") {
              formRef.current.setFieldsValue({
                store_name: item.propertyValue
              })
            }
            if (item.propertyKey == "commodity_is_manufacturer_brand") {
              formRef.current.setFieldsValue({
                commodity_is_manufacturer_brand: item.propertyValue
              })
              setBrand(item.propertyValue)
            }
            if (item.propertyKey == "commodity_is_upc") {
              formRef.current.setFieldsValue({
                commodity_is_upc: item.propertyValue
              })
            }
            if (item.propertyKey == "commodity_registered_trademark") {
              formRef.current.setFieldsValue({
                commodity_registered_trademark: item.propertyValue,
              })
            }
          }
        }
      })
    }
  }, [isReturn])
  const nextEvent = useCallback(() => {
    let is = false
    if (loading) return
    setLoading(true)
    let ress = formRef.current.validateFields()
    let res = formRef.current.getFieldsValue(true)
    const { store_name, commodity_is_manufacturer_brand, commodity_is_upc, commodity_registered_trademark } = res
    // console.log(store_name, commodity_is_manufacturer_brand, commodity_is_upc, commodity_registered_trademark);
    if (!store_name) {
      setNameErr(true)
      is = true
    }
    if (!commodity_is_manufacturer_brand) {
      setBrandErr(true)
      is = true
    }
    if (!commodity_is_upc) {
      setUpcErr(true)
      is = true
    }
    if (!commodity_registered_trademark && (commodity_is_manufacturer_brand == 1 || commodity_is_manufacturer_brand == 3)) {
      setTrademarkErr(true)
      is = true
    }
    if (is) {
      setLoading(false)
      return
    }

    let arr = [
      { "propertyKey": "store_name", "propertyValue": store_name },
      { "propertyKey": "commodity_is_manufacturer_brand", "propertyValue": commodity_is_manufacturer_brand },
      { "propertyKey": "commodity_is_upc", "propertyValue": commodity_is_upc },
      { "propertyKey": "commodity_registered_trademark", "propertyValue": commodity_registered_trademark || '' },
    ]
    regSubmit(arr, null, 'shop', 7).then((data) => {
      if (data.code == 1) {
        setLoading(false)
        props.history.push('/AuthenticationThree')
      }
    }).catch(() => {
      setLoading(false)
    })
  }, [loading])
  return (
    <div className='shop register_style'>
      <TipsIcon clicks={awakenTips} />
      <Reactours routePath="shop" isActive={isActive} clicks={awakenTips} steps={steps1} isOpen={isTourOpen} />
      <Header />

      <div style={{ display: 'flex' }}>
        <div className="a-section program-overview-values">
          <RegisterSteps current={4} />
          <div style={{ width: '48%', margin: '0 auto' }}>
            <h5>
              店铺信息
              <div className="ng-binding">回答几个有关您业务的问题，以便我们在新用户引导过程中为您提供最佳帮助。您提供的回答不会妨碍您注册卖家账户。</div>
              <div className='kat-divider'></div>
            </h5>
            <Form
              ref={formRef}
              layout="vertical"
            >
              <div className='form_box stepGuidanceBox0'>
                <Form.Item
                  label="店铺名称"
                  name="store_name"
                  rules={[{ required: true, message: '' }]}
                >
                  <Input onChange={(e) => {
                    setNameErr(!e.target.value)
                  }}
                    maxLength='50'
                    placeholder="请输入店铺名称" />
                </Form.Item>
                {nameErr ? <span className='box_err name'><span>！ </span>商店名称为空</span> : null}

              </div>
              <div className='kat-divider'></div>
              <div className='form_box'>
                <p className='label'>您的商品是否全都拥有通用商品编码(UPC)?</p>
                {upcErr ? <span className='box_err'><span>！ </span>请选择一个答案</span> : null}
                <Form.Item
                  label=""
                  name="commodity_is_upc"
                >
                  <Radio.Group onChange={(e) => {
                    setUpcErr(false)
                  }}>
                    <Space direction="vertical">
                      <Radio value="1">是</Radio>
                      <Radio value="2">否</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </div>
              <div className='form_box'>
                <p className='label'>对于您想要在亚马逊上销售的任何商品，您是制造商还是品牌所有者(或品牌代理或代表) ?</p>
                {brandErr ? <span className='box_err'><span>！ </span>请选择一个答案</span> : null}

                <Form.Item
                  label=""
                  name="commodity_is_manufacturer_brand"
                >
                  <Radio.Group onChange={(e) => {
                    setBrand(e.target.value)
                    setBrandErr(false)
                  }}>
                    <Space direction="vertical">
                      <Radio value="1">是</Radio>
                      <Radio value="2">否</Radio>
                      <Radio value="3">其中一部分</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </div>
              {
                brand == 1 || brand == 3 ? (
                  <div className='form_box'>
                    <p className='label'>对于您想要在亚马逊上销售的品牌商品，您是否拥有其官方注册商标</p>
                    {trademarkErr ? <span className='box_err'><span>！ </span>请选择一个答案</span> : null}
                    <Form.Item
                      label=""
                      name="commodity_registered_trademark"
                    >
                      <Radio.Group onChange={(e) => {
                        setTrademarkErr(false)
                      }}>
                        <Space direction="vertical">
                          <Radio value="1">是</Radio>
                          <Radio value="2">否</Radio>
                          <Radio value="3">其中一部分</Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </div>

                ) : null
              }

            </Form>
            <div className='kat-divider'></div>
            <div className="operationBtn" style={{ textAlign: 'center' }}>
              <Button className='previousPage' type="primary">
                <Link to={{ pathname: '/bill', state: { isReturn: 1 } }}>上一页</Link>
              </Button>
              <Button onClick={nextEvent} className='next' type="primary">
                下一页
              </Button>
            </div>
          </div>
        </div>
        <div className="rightCollapse">
          <p >常见问题</p>
          <RegisterCollapse list={collapseList} />
        </div>
      </div>
      <Footer />
    </div>
  )
}