import React from "react"
import AJAX from "api/apis";
import { Link } from 'react-router-dom';
import "./Tab2.css"
import THeaderTab from "../../THeaderTab";
import '../../../../default.css/default.css'
import IconFont from "components/Icon/Icon";
import { Pagination } from 'antd';

class Tab2 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			productList: [],
			page: 1,
			size: 10,
			total: 10
		}
	}
	componentWillMount() {
		this.getPlanList(this.state.page, this.state.size)
	}
	getPlanList = (page, size) => {
		let { productList } = this.state
		AJAX.fba.getStoragePlanInfoList({ page, size }).then((res) => {
			if (res.code == 1 && res.data) {
				productList = res.data
				this.setState({
					productList: productList,
					total: res.data.total,
					page: res.data.pageNum
				})
			}
		})

	}
	pageChonge = (page, size) => {
		this.setState({
			page: page,
			size: size
		})
		this.getPlanList(page, size)
	}
	ShowSizeChange = (page, size) => {
		this.setState({
			page: page,
			size: size
		})
		this.getPlanList(page, size)

	}
	getFormRef = el => {
		this.form = el;
	}
	render() {
		let { productList, page, total } = this.state;
		return <div>
			<THeaderTab></THeaderTab>
			<div className="kc24_shujuList">
				<ul className="kc24_headbor">
					<li >货件名称</li>
					<li className="kc24_lan">编号<IconFont type="icon-sanjiao1" className="kc24_daosanjiao" /></li>
					{/* <li >创建时间</li> */}
					<li className="kc24_lan kc24_chu">上次更新<IconFont type="icon-sanjiao" /></li>
					<li>MSKU</li>
					<li>数量</li>
					{/* <li>已收到</li> */}
					{/* <li>目的地*<img src="../images/kc24_sanjiao.png" alt="" className="kc24_daosanjiao" /></li> */}
					<li>状态</li>
				</ul>
				{productList.list ? productList.list.map((item, index) => {
					return <ul className="kc24_headbor kc24_xi">
						<li className="kc24_lan">{item.storagePlanName}</li>
						<li className="">{item.storagePlanNumber}</li>
						{/* <li className="">2020年12月12日</li> */}
						<li className="">{item.createTime}</li>
						<li>{item.storagePlanMskuCount}</li>
						<li>{item.storagePlanGoodsCount}</li>
						{/* <li></li> */}
						{/* <li className="kc24_black1">---</li> */}
						<li>{item.deleteFlag ? '已删除' : item.storagePlanStatus == 0 ? '已创建' : '处理中'}</li>
						<li className="kc24_zuihou">
							<Link to={{ pathname: '/setQuantity', state: { planId: item.id }, search: `?planId=${item.id}` }}>
								<div>处理入库计划</div>
							</Link>
						</li>
					</ul>
				}) : null}
			</div>
			<Pagination
				current={page}
				total={total}
				showSizeChanger
				showQuickJumper
				className="pageBox"
				onChange={this.pageChonge}
				onShowSizeChange={this.ShowSizeChange}
			/>


		</div>
	}
}

export default Tab2