import React from 'react'
import './Ausstehend.css'
import { Radio } from 'antd';
import {Link} from 'react-router-dom'


function Ausstehend(){
    return(
        <div className="ausste_body">
            <div className="ausste_left">
                <div className="ausste_leftP">
                <div className="ausste_left_head">
                    <div className="ausste_left_headNav">
                    <p>细化依据：</p>
                    <div>销售渠道</div>
                    </div>
                </div>

                <div className="ausste_left_middle">
                    <div>
                        <ul>
                            <li><input type="checkbox" />Amazon.com.mx</li>
                            <li><input type="checkbox" />Amazon.com lnvoicing Shadow Marketplace</li>
                            <li><input type="checkbox" />Amazon.ca</li>
                            <li><input type="checkbox" />Amazon.com</li>
                        </ul>
                    </div>
                </div>

                <div className="ausste_left_bottom">
                    <div className="ausste_left_bottomI">配送服务</div>
                    <div className="ausste_left_bottomII"><Radio/> 优先配送</div>
                    <div className="ausste_left_bottomIII">企业账户详情</div>
                    <div className="ausste_left_bottomIIII"><input type="checkbox" />企业买家</div>
                </div>

                </div>


            </div>

            <div className="ausste_right">
                <div>
                    <div className="ausste_right_headI">
                        <button>隐藏筛选条件</button>
                        <div className="ausste_right_headI_font">0  个订单</div>
                    </div>
                    <div>未找到与指定搜索条件相匹配的订单</div>
                </div>
                <div style={{width:"39%"}}>

                <div className="ausste_right_headII">
                    <div className="ausste_right_headII_midButton">
                        <label>
                            <select>
                                <option>订单日期（升序）</option>
                                <option>订单日期（降序）</option>
                                <option>发货日期（升序）</option>
                                <option>发货日期（降序）</option>
                                <option>配送日期（升序）</option>
                                <option>配送日期（降序）</option>
                                <option>状态（升序）</option>
                                <option>状态（降序）</option>
                            </select>
                        </label>
                    </div>
                    <div className="ausste_right_headII_midButton">
                        <label>
                            <select>
                                <option>每页结果：15</option>
                                <option>每页结果：25</option>
                                <option>每页结果：50</option>
                                <option>每页结果：100</option>
                            </select>
                        </label>
                    </div>
                    <div className="ausste_right_headII_midButton">
                        <button>设置表格首选项</button>
                    </div>

                    <div className="ausste_right_headII_endButton">
                        <button>刷新</button>
                    </div>
                </div>


                <div className="ausste_right_headIII">
                     <label>
                            <select>
                               <option>每页结果：15</option>
                               <option>25</option>
                               <option>50</option>
                               <option>100</option>
                            </select>
                      </label>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Ausstehend