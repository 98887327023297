import React from "react"
import "./Tab3.css"
import { Checkbox } from 'antd';
import '../../../default.css/default.css'

class Tab3 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			productList: this.props.planInfo
		}
	}
	componentWillMount() { }
	render() {
		let { productList } = this.state
		return <div className="atAglancetab3Box">
			<div>
				<table>
					<thead>
						<tr>
							<th>MSKU</th>
							<th>商品名称</th>
							<th>状况</th>
							{productList.fbaCommodityPlanGoodsInfoRespList[0].commodityPlanGoodsBoxCount ? <><th>每个装运箱的商品数量</th>
								<th>装运箱数量</th></> : <td>商品数量</td>}
							<th>已发货</th>
							<th>已收到</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td></td>
							<td>
								<Checkbox>显示 ASIN/FNSKU</Checkbox>
							</td>
							<td></td>
							{productList.fbaCommodityPlanGoodsInfoRespList[0].commodityPlanGoodsBoxCount ? <><td></td>
								<td></td></> : <td></td>}
							<td></td>
							<td></td>
						</tr>
						{productList.fbaCommodityPlanGoodsInfoRespList ? productList.fbaCommodityPlanGoodsInfoRespList.map((item, index) => {
							return <tr key={index}>
								<td>{item.goodsSku}</td>
								<td className="text-ellipsis-3">{item.goodsName}</td>
								<td>{item.goodsCondition}</td>
								{item.commodityPlanGoodsBoxCount ? <><td>{item.commodityPlanBoxGoodsCount}</td>
									<td>{item.commodityPlanGoodsBoxCount}</td></> : <><td>{item.commodityPlanGoodsCount}</td></>}
								<td>{item.commodityPlanGoodsHarvestCount}</td>
								<td>--</td>
							</tr>
						}) : null}
						<tr>
							<td>总计</td>
							<td></td>
							<td></td>
							{productList.fbaCommodityPlanGoodsInfoRespList[0].commodityPlanGoodsBoxCount ? <><td></td>
								<td></td></> : <td></td>}
							<td>0</td>
							<td>0</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	}
}

export default Tab3