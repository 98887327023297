import React, { Component } from 'react'
import './newProduct.css'
import '../../../../../default.css/default.css'
import { Switch, Radio } from 'antd';
import { Link } from 'react-router-dom'
function NewProduct() {

    const [value, setValue] = React.useState(1);

    const onChange = e => {
        setValue(e.target.value);
    };
    return (
        <div>



            <div className="kc04_header">
                <div><img src={require("../../../../../../assets/images/kc04_amazon.jpg").default} alt="" className="kc04_header-img" /></div>
                <div className="kc04_myheader">
                    <img src={require("../../../../../../assets/images/kc04_fs.jpg").default} className="kc04_myheader-img" />
                    <div className="kc04_myheader-box">
                        <div>Genesis 6-Lnch clip Convertible Table-Top & clip Fan Tow Quiet Speeds-Ldeal For The Home,
                            Office,Dorm,More White</div>
                        <div className="kc04_myheader-box-span">
                            <img src={require("../../../../../../assets/images/kc04-qz.JPG").default} className="kc04_qi" />
                            <span>USD$</span>
                            <span>14</span>
                            <span>99</span>
                            <span>√</span>
                            <span>prime</span>
                        </div>
                        <ul className="kc04_myheader-box_ul_span">
                            <li><span>ASIN:</span>B012BKZC86</li>
                            <li><span>UPC:</span>811691020709</li>
                            <li><span>亚马逊销售排名:</span>6756</li>
                        </ul>
                        <ul className="kc04_myheader-box_ul">
                            <li>竞争报价:</li>
                            <li>6新品 自$14.99+$0.00配送费</li>
                            <li>5非全新品 自 $ 13.09+$0.00配送费</li>
                            <li>在亚马孙上查看商品信息</li>
                        </ul>
                        <p className="kc04_myheader_p"><span>更多信息</span>&nbsp;&nbsp;{">"}</p>
                    </div>
                </div>
            </div>

            <div className="kc04_container">
                <h3>报价</h3>
                <div className="kc04_container_box">
                    <div className="kc04_container_box_switch">
                        高级视图
                        <Switch defaultChecked size="small" />
                    </div>
                    <div>
                        <div className="kc04_container_box_sku">
                            <p className="kc04_jzc">卖家SKU</p>
                            <span className="kc04_yu">?</span>
                            <input type="text" placeholder="5W-JCEJ-6ZF" className="kc04_inp kc04_input" />
                            <img src={require("../../../../../../assets/images/kc04_s.JPG").default} alt="" />
                        </div>
                        <p className="kc04_box_p">提交后，无法更此字段</p>
                        <div className="kc04_container_box_sku">
                            <p className="kc04_jzc">*您的价格</p>
                            <span className="kc04_yu">?</span>
                            <button className="kc04_container_but">USD$</button>
                            <input type="text" placeholder="例如：50.00" className="kc04_box_inp kc04_input" />
                        </div>
                        <div className="kc04_container_box_sku">
                            <p className="kc04_jzc zc">*状况</p>
                            <span className="kc04_yu">?</span>
                            <select className="kc04_container_select">
                                <option>-选择-</option>
                            </select>
                            <img src={require("../../../../../../assets/images/kc04_s.JPG").default} alt="" />
                        </div>
                        <div className="kc04_container_box_sku">
                            <p className="kc04_jzc">配送渠道</p>
                            <span className="kc04_yu">?</span>
                            <div className="kc04_box_bor">
                                <Radio.Group onChange={onChange} value={value}>
                                    <div className="kc04_box_bor_first">
                                        <Radio value={1} className="kc04_rad"></Radio>
                                        <p className="kc04_box_bor_p">我将自行配送此商品<br />(卖家自行配送)</p>
                                    </div>
                                    <div className="kc04_box_bor_first">
                                        <Radio value={2} className='kc04_rad'></Radio>
                                        <p className="kc04_box_bor_p">亚马孙将会配送此商品<br />(亚马孙配送)</p>
                                    </div>
                                </Radio.Group>
                            </div>
                            <div className="kc04_box_bor_foo">
                                <span className="kc04_yu kc04_tr">?</span>您已选择使用亚马逊配送该商品的订单。亚马逊接到您的的商品后即可开始销售。使用亚马逊配送收费标准。<span>了解更多信息</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="kc04_awsx">
                    <Link to="/inventory"><button>取消</button></Link>
                    <button>保存并完成</button>
                </div>
            </div>

        </div>



    )

}

export default NewProduct