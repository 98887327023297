import React, { useRef, useEffect, useCallback, useState } from 'react';
import AJAX from "api/apis";
import './AuthenticationThree.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import Reactours from "components/reactours"
import TipsIcon from "components/tipsIcon"
import RegisterSteps from 'components/registerSteps';
import RegisterCollapse from 'components/registerCollapse';
import { Button, Upload, message, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { regSubmit, getStoreRegisterInfo } from '../../../utils/PublicLibrary';


export default (props) => {
    let [isModalVisible, setisModalVisible] = useState(false)
    let [imageUrlZ, setImageUrlZ] = useState()
    let [imageUrlF, setImageUrlF] = useState()
    let [imageUrlL, setImageUrlL] = useState()
    let [imageNameZ, setImageNameZ] = useState()
    let [imageNameF, setImageNameF] = useState()
    let [imageNameL, setImageNameL] = useState()
    let [imageType, setImageType] = useState(1)
    let [loading, setLoading] = useState(false)
    let [imgErr, setImgErr] = useState(false)
    let [info, setInfo] = useState(null)

    let [identity_birth_date, set_identity_birth_date] = useState("")
    let [identity_business_license_number, set_identity_business_license_number] = useState(null)
    let [identity_company_chinese_name, set_identity_company_chinese_name] = useState(null)
    let [identity_company_english_name, set_identity_company_english_name] = useState(null)
    let [identity_date, set_identity_date] = useState(null)
    let [identity_name, set_identity_name] = useState("")
    let [seller_identity_type, set_seller_identity_type] = useState(null)
    let [is_identity_number, set_is_identity_number] = useState(null)
    let [is_personage_type, set_is_personage_type] = useState(null)
    let [sign_state, set_sign_state] = useState(null)
    const [isTourOpen, setIsTourOpen] = useState(true);
    const steps1 = [
        {
            selector: '.stepGuidanceBox0',
            content: '添加身份证扫描件正反面，清晰可见，无污渍，无遮挡。有国徽的是正面，有头像是反面',
        },
        {
            selector: '.stepGuidanceBox1',
            content: '添加营业执照正面扫描件，清晰可见，无污渍，无遮挡',
        }
    ];
    const tipList = {
        sfz: [
            '文档中的详细信息应该与给定的详细信息相符',
            '以彩色扫描原始文档或使用移动设备拍摄照片。 请勿提交屏幕截图',
            '证件图片必须为高清, 彩色且一览无余。图片必须展示完整的证件页面, 或者如果为居民身份证, 则需展示身份证的两面，并将它们合并到一个文件中进行上传',
            '您的文件语言必须是以下语言中的一种：中文、英语、法语、德语、意大利语、日语、葡萄牙语、西班牙语、瑞典语或波兰语。如果文件未采用以上任一语言，请提供一份经过公证的其中一种受支持语言的翻译文件',
            '文件必须小于 10MB',
            '接受的格式为 *.png、*.tiff、*.tif、*.jpg、*.jpeg 和 *.pdf'
        ],
        yyzz: [
            '法定代理人姓名或营业执照上的主管/代表姓名应与身份证明文件上的姓名一致。',
            '营业执照上的地址应与注册时提供的营业地址完全一致。',
            '以彩色扫描原始文档或使用移动设备拍摄照片。 请勿提交屏幕截图',
            '文件应该清晰可读和有效。我们不接受过期的文件（已超过标示的有效期）',
            '文件必须小于 10MB',
            '接受的格式为 *.png、*.tiff、*.tif、*.jpg、*.jpeg 和 *.pdf'
        ]
    }
    const collapseList = [
        {
            header: '为什么需要验证？',
            html: '<p>为了保护买家和卖家在我们商城的安全，我们会验证企业和个人信息。</p>'
        },
        {
            header: '我如何知道自己已通过验证？',
            html: '<p>验证完成后，我们将通过电子邮件告知您进程。</p> '
        },
        {
            header: '验证后该怎么办？',
            html: '<p>在等待验证时，您可以查看通过亚马逊进行全球开店的成功案例。点击<a>此处</a></p>'
        },
    ]
    const [isActive, setIsActive] = useState(false);
    const awakenTips = (type) => {
        setIsActive(type);
    }
    useEffect(() => {
        getRegInfo()
    }, [])
    let getRegInfo = useCallback(() => {
        getStoreRegisterInfo().then((res) => {
            let value = {}
            for (let item of res.data) {
                if (item.propertyKey == "seller_birth_date") {
                    set_identity_birth_date(item.propertyValue)
                }
                if (item.propertyKey == "company_number") {
                    set_identity_business_license_number(item.propertyValue)
                }
                // 企业名称
                if (item.propertyKey == "company_chinese_name") {
                    set_identity_company_chinese_name(item.propertyValue)
                }
                if (item.propertyKey == "company_english_name") {
                    set_identity_company_english_name(item.propertyValue)
                }
                // if (item.propertyKey == "seller_identity_state") {
                //     set_identity_date(item.propertyValue)
                // }
                if (item.propertyKey == "seller_identity_name") {
                    set_identity_name(item.propertyValue)
                }
                if (item.propertyKey == "seller_identity_type") {
                    set_seller_identity_type(item.propertyValue)
                }
                if (item.propertyKey == "seller_identity_number") {
                    set_is_identity_number(item.propertyValue)
                }
                // if (item.propertyKey == "seller_is_personage_type") {
                //     set_is_personage_type(item.propertyValue)
                // }
                if (item.propertyKey == "seller_identity_state") {
                    set_sign_state(item.propertyValue)
                }
                if (item.propertyKey == "identity_true_picture") {
                    setImageUrlZ(item.propertyValue)
                }
                if (item.propertyKey == "identity_false_picture") {
                    setImageUrlF(item.propertyValue)
                }
                if (item.propertyKey == "identity_business_license_picture") {
                    setImageUrlL(item.propertyValue)
                }
                if (item.propertyKey == "identity_true_picture_name") {
                    setImageNameZ(item.propertyValue)
                }
                if (item.propertyKey == "identity_false_picture_name") {
                    setImageNameF(item.propertyValue)
                }
                if (item.propertyKey == "identity_business_license_picture_name") {
                    setImageNameL(item.propertyValue)
                }
                if (item.propertyKey == "seller_birth_state") {
                    value.seller_birth_state = item.propertyValue
                }
                if (item.propertyKey == "seller_nationality") {
                    value.seller_nationality = item.propertyValue
                }
                if (item.propertyKey == "seller_dwell_site") {
                    value.seller_dwell_site = item.propertyValue
                }
                if (item.propertyKey == "company_business_site") {
                    value.company_business_site = item.propertyValue
                }
                if (item.propertyKey == "seller_identity_date") {
                    value.seller_identity_date = item.propertyValue
                }
            }
            setInfo(value)
        })
        AJAX.login.getStoreRegisterInfo([]).then((data) => {
            if (data.code == 1 && data.data) {
                let value = {}
                for (let item of data.data) {
                    if (item.propertyValue) {
                        if (item.propertyKey == "seller_birth_date") {
                            set_identity_birth_date(item.propertyValue)
                        }
                        if (item.propertyKey == "company_number") {
                            set_identity_business_license_number(item.propertyValue)
                        }
                        if (item.propertyKey == "company_chinese_name") {
                            set_identity_company_chinese_name(item.propertyValue)
                        }
                        if (item.propertyKey == "company_english_name") {
                            set_identity_company_english_name(item.propertyValue)
                        }
                        if (item.propertyKey == "identity_date") {
                            set_identity_date(item.propertyValue)
                        }
                        if (item.propertyKey == "seller_identity_name") {
                            set_identity_name(item.propertyValue)
                        }
                        if (item.propertyKey == "seller_identity_type") {
                            set_seller_identity_type(item.propertyValue)
                        }
                        if (item.propertyKey == "seller_identity_number") {
                            set_is_identity_number(item.propertyValue)
                        }
                        if (item.propertyKey == "is_personage_type") {
                            set_is_personage_type(item.propertyValue)
                        }
                        if (item.propertyKey == "sign_state") {
                            set_sign_state(item.propertyValue)
                        }
                        if (item.propertyKey == "identity_true_picture") {
                            setImageUrlZ(item.propertyValue)
                        }
                        if (item.propertyKey == "identity_false_picture") {
                            setImageUrlF(item.propertyValue)
                        }
                        if (item.propertyKey == "identity_business_license_picture") {
                            setImageUrlL(item.propertyValue)
                        }
                        if (item.propertyKey == "identity_true_picture_name") {
                            setImageNameZ(item.propertyValue)
                        }
                        if (item.propertyKey == "identity_false_picture_name") {
                            setImageNameF(item.propertyValue)
                        }
                        if (item.propertyKey == "identity_business_license_picture_name") {
                            setImageNameL(item.propertyValue)
                        }
                        if (item.propertyKey == "seller_birth_state") {
                            value.seller_birth_state = item.propertyValue
                        }
                        if (item.propertyKey == "seller_nationality") {
                            value.seller_nationality = item.propertyValue
                        }
                        if (item.propertyKey == "seller_dwell_site") {
                            value.seller_dwell_site = item.propertyValue
                        }
                        if (item.propertyKey == "company_business_site") {
                            value.company_business_site = item.propertyValue
                        }
                        if (item.propertyKey == "seller_identity_date") {
                            value.seller_identity_date = item.propertyValue
                        }
                    }
                }
                setInfo(value)
            }
        })
        // if (sessionStorage.getItem('regInfo')) {
        //     obj = JSON.parse(sessionStorage.getItem('regInfo'));
        // }
    })
    let handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            this.getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    loading: false,
                }),
            );
        }
    };
    let nextEvent = useCallback(() => {
        if (!imageNameZ && !imageNameF && !imageNameL) {
            setImgErr(true)
            return
        }
        if (loading) return
        setLoading(true)
        let arr = [
            { "propertyKey": "identity_true_picture", "propertyValue": imageUrlZ },
            { "propertyKey": "identity_false_picture", "propertyValue": imageUrlF },
            { "propertyKey": "identity_business_license_picture", "propertyValue": imageUrlL },
            { "propertyKey": "identity_true_picture_name", "propertyValue": imageNameZ },
            { "propertyKey": "identity_false_picture_name", "propertyValue": imageNameF },
            { "propertyKey": "identity_business_license_picture_name", "propertyValue": imageNameL },
        ]
        regSubmit(arr, null, 'AuthenticationThree', 8).then((data) => {
            if (data.code == 1) {
                props.history.push({
                    pathname: '/completeValidation',
                    state: {
                        seller_identity_type: seller_identity_type,
                        is_personage_type: is_personage_type,
                        is_identity_number: is_identity_number,
                        sign_state: sign_state,
                        identity_date: identity_date,
                        identity_name: identity_name,
                        identity_birth_date: identity_birth_date,
                        identity_company_chinese_name: identity_company_chinese_name,
                        identity_company_english_name: identity_company_english_name,
                        identity_business_license_number: identity_business_license_number,
                        identity_true_picture: imageUrlZ,
                        identity_false_picture: imageUrlF,
                        identity_business_license_picture: imageUrlL,
                        identity_true_picture_name: imageNameZ,
                        identity_false_picture_name: imageNameF,
                        identity_business_license_picture_name: imageNameL
                    }
                })
                setLoading(false)
            }
        }).catch(() => {
            setLoading(false)
        })
    }, [imageUrlZ, imageUrlF, imageUrlL, imageNameL, imageNameF, imageNameZ, loading])
    let beforeUpload = (file, type) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('上传的格式不正确');
            return false;
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.error('上传图片大小不能超过5MB');
            return false;
        }
        AJAX.file.upload(file)
            .then((res) => {
                if (type == 1) {
                    setImageUrlZ(res.data[0].url)
                    setImageNameZ(res.data[0].fileName)
                } else if (type == 2) {
                    setImageUrlF(res.data[0].url)
                    setImageNameF(res.data[0].fileName)
                } else if (type == 3) {
                    setImageUrlL(res.data[0].url)
                    setImageNameL(res.data[0].fileName)
                }
            }).catch((err) => {
                console.log(err, "err")
            })
    }
    const cancelModal = () => {
        setisModalVisible(false)
    }
    const okModel = () => {
        cancelModal()
    }
    return (
        <div className="agreementsBox AuthenticationThreeBox">
            <TipsIcon clicks={awakenTips} />
            {/* 引导步骤 */}
            <Reactours steps={steps1} isActive={isActive} clicks={awakenTips} routePath="AuthenticationThree" isOpen={isTourOpen} />
            <Header />
            <div style={{ display: 'flex' }}>
                <div className="a-section program-overview-values">
                    <div className="CompanyAddress">
                        <RegisterSteps current={5} />
                        <div>
                            <div className="ng-scope a-size-medium">身份验证</div>
                            {
                                imgErr ? (
                                    <div className='spacing-base'>
                                        <i className="a-icon a-icon-alert"></i>
                                        <li className="ng-binding ng-scope"> 上传文件</li>
                                    </div>
                                ) : null
                            }
                            <div className="help">帮助</div>
                            <div className="ng-scope a-size-small">我是该账户的唯一所有者或联系人</div>
                            <div className="uploadInfo">
                                <span>上传文件</span>
                            </div>
                            <table className="threeTableBox">
                                <tbody>
                                    <tr>
                                        <td>名称</td>
                                        <td>{identity_name.replace(/------/g, "") || ''}<span style={{ color: '#767676' }}>，{is_personage_type || '联系人'}</span></td>
                                    </tr>
                                    <tr>
                                        <td>出生日期</td>
                                        <td>{identity_birth_date.replace(/------/g, "")}</td>
                                    </tr>
                                    <tr>
                                        <td>出生地</td>
                                        <td>{info?.seller_birth_state}</td>
                                    </tr>
                                    <tr>
                                        <td>国籍</td>
                                        <td>{info?.seller_nationality}</td>
                                    </tr>
                                    <tr>
                                        <td>身份数据</td>
                                        <td>
                                            <p>
                                                <span style={{ fontWeight: 'bold' }}>{seller_identity_type}#</span>
                                                <span>{is_identity_number}</span>
                                            </p>
                                            <p>有效期：{info && info.seller_identity_date.replace(/------/g, "")}</p>
                                            <p>签发国：{sign_state}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="threeTableBox">
                                <tbody>
                                    <tr className="stepGuidanceBox0">
                                        <td>身份证明文件</td>
                                        <td>
                                            {
                                                imageNameZ ? (
                                                    <div style={{ fontWeight: 700 }}>
                                                        <p>身份证</p>
                                                        <p>选定的文件 :
                                                            <span style={{ fontWeight: 400 }}>{imageNameZ || 'mzi'}</span>
                                                            <span className='genghuan' onClick={() => {
                                                                setImageNameZ('')
                                                                setImageUrlZ('')
                                                            }}> 更换</span>
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <div className='but'>
                                                        <button type="button">
                                                            <div onClick={() => { setImageType(1); setisModalVisible(true) }}>上传正面</div>
                                                        </button>
                                                    </div>
                                                )
                                            }
                                            {
                                                imageNameF ? (
                                                    <div style={{ fontWeight: 700, marginTop: 20 }}>
                                                        <p>身份证</p>
                                                        <p>选定的文件 :
                                                            <span style={{ fontWeight: 400 }}>{imageNameF || 'mzi'}</span>
                                                            <span className='genghuan' onClick={() => {
                                                                setImageNameF('')
                                                                setImageUrlF('')
                                                            }}> 更换</span>
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <div className='but'>
                                                        <button type="button" style={{ marginTop: 20 }}>
                                                            <div onClick={() => { setImageType(2); setisModalVisible(true) }}>上传反面</div>
                                                        </button>
                                                    </div>
                                                )
                                            }

                                            {
                                                !imageNameZ && !imageNameF && <p>上传背面（即使它是空白的）</p>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="threeTableBox">
                                <tbody>
                                    <tr>
                                        <td>居住地址</td>
                                        <td>
                                            <p>{info ? info.seller_dwell_site.replace(/------/g, " ") : ""}</p>
                                            {/* <p>{sign_state}</p> */}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="threeTableBox">
                                <tbody>
                                    <tr>
                                        <td>公司地址</td>
                                        <td>
                                            <p>{info ? info.company_business_site.replace(/------/g, " ") : ""}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="threeTableBox">
                                <tbody>
                                    <tr>
                                        <td>企业名称</td>
                                        <td>{identity_company_chinese_name}</td>
                                    </tr>
                                    <tr>
                                        <td>公司注册号码</td>
                                        <td>{identity_business_license_number}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="threeTableBox">
                                <tbody>
                                    <tr className="stepGuidanceBox1">
                                        <td>业务文件</td>
                                        <td>
                                            {
                                                imageNameL ? (
                                                    <div style={{ fontWeight: 700 }}>
                                                        <p>营业执照</p>
                                                        <p>选定的文件 :
                                                            <span style={{ fontWeight: 400 }}>{imageNameL || 'mzi'}</span>
                                                            <span className='genghuan' onClick={() => {
                                                                setImageNameL('')
                                                                setImageUrlL('')
                                                            }}> 更换</span>
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <button type="button">
                                                        <div onClick={() => { setImageType(3); setisModalVisible(true) }}>上传 营业执照</div>
                                                    </button>
                                                )
                                            }

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="operationBtn" style={{ textAlign: 'center' }}>
                                <Button className='previousPage' type="primary">
                                    <Link to={{ pathname: '/shop', state: { isReturn: 1 } }}>上一页</Link>
                                </Button>
                                <Button disabled={imageUrlZ && imageUrlF && imageUrlL ? false : true} onClick={nextEvent} className='next' type="primary">
                                    提交
                                </Button>
                            </div>
                        </div>
                    </div>
                </div >
                <div className="rightCollapse">
                    <p >常见问题</p>
                    <RegisterCollapse list={collapseList} />
                </div>
            </div >
            <Modal
                title="上传说明"
                visible={isModalVisible}
                onOk={okModel}
                onCancel={cancelModal}
                style={{ top: '20%' }}
                width="398px"
                className='ymx-modal updata'
                closable={false}
                footer={(<div className="operationBtn" style={{ textAlign: 'center' }}>
                    <Button onClick={cancelModal} className='previousPage' type="primary">
                        取消
                    </Button>
                    <Button onClick={okModel} className='next' type="primary">
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className=""
                            showUploadList={false}
                            beforeUpload={(file) => { beforeUpload(file, imageType); return false; }}
                            onChange={handleChange}
                        >
                            <div>继续</div>
                        </Upload>
                    </Button>
                </div>)}
            >
                <ul className='tipUl'>
                    {
                        tipList[imageType == 3 ? 'yyzz' : 'sfz'].map(item => {
                            return <li key={item}>{item}</li>
                        })
                    }
                </ul>

            </Modal>
            <Footer />
        </div >
    )
}