import React, { Component } from "react";
import "./style.css";
import AdvertisementLeft from "../advertisementLeft/advertisementLeft";

import {
  BellOutlined,
  QuestionOutlined,
  FrownOutlined,
} from "@ant-design/icons";

class AdvertisingPageLayout extends Component {
  render() {
    return (
      <div className="AdvertisingPageLayout-box">
        <AdvertisementLeft />

        <div className="AdvertisingPageLayout-box-right">
          <div className="box-right-head">
            {/* 头部 */}
            <div className="box-right-head-left">广告活动</div>
            <div className="box-right-head-right">
              <div>
                <select>
                  <option>Big Corn美国</option>
                </select>
              </div>
              <div className="icons-list">
                <BellOutlined />
              </div>
              <div className="icons-list">
                <QuestionOutlined />
              </div>
              <div className="icons-list">
                <FrownOutlined />
              </div>
            </div>
          </div>

          <div style={{ marginTop: 55 }}>{this.props.children}</div>

          <div className="p-24 text-center">
            © 2021 Amazon.com, Inc. 或其附属公司。使用条款隐私声明Amazon广告协议
          </div>
        </div>
      </div>
    );
  }
}
export default AdvertisingPageLayout;
