import { AText } from "@a-components/";
import style from "./style.module.css";

import classNames from "classnames";

const GoodsItem = ({ info, extra, className }) => {
  const { goodsName, goodsInventoryCount, goodsMoney, goodsAsin, imgUrl } =
    info;
  return (
    <div className={classNames(className, "p-12 df border-ccc")}>
      <img className={style.goodsPic} src={imgUrl} alt="" />

      <div className="f-1 ml-12">
        <AText className="fs-12 text-ellipsis-2">{goodsName}</AText>
        <div>{goodsAsin}</div>
        <div>
          有货: {goodsInventoryCount} 商品价格: ${goodsMoney.toFixed(2)}
        </div>
      </div>

      {extra ? <div className="ml-12">{extra}</div> : null}
    </div>
  );
};

export default GoodsItem;
