import React, { useRef, useEffect, useCallback, useState } from 'react';
import AJAX from "api/apis";
import './agreements.css'
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import Reactours from "components/reactours"
import TipsIcon from "components/tipsIcon"
import { Button, Collapse, Form, Input, Select, Checkbox, Popover } from 'antd';
import RegisterCollapse from 'components/registerCollapse';
import { QuestionCircleFilled } from '@ant-design/icons';
import { getSellerNationality } from '../../utils/regDate';
import { regSubmit, getStoreRegisterInfo } from '../../utils/PublicLibrary';



const { Panel } = Collapse;
export default (props) => {
    let formRef = useRef();
    const companyAddressList = getSellerNationality()
    const entityType = [
        { label: '国有企业', value: '1' },
        { label: '上市企业', value: '2' },
        { label: '私有企业', value: '3' },
        { label: '慈善', value: '4' },
    ];
    const [isShow, setIsShow] = useState(2)
    const [first, setFirst] = useState(false)
    const [isTourOpen, setIsTourOpen] = useState(true);
    const [entityTypeValue, setEntityTypeValue] = useState('');
    const steps = useCallback(() => {
        let arr = [
            {
                selector: '.stepGuidanceBox0',
                content: '选择国家，注册公司地址是哪个国家的就填写哪个国家',
            },
            {
                selector: '.stepGuidanceBox1',
                content: '公司类型， 上市，私有，还是慈善',
            },
            {
                selector: '.stepGuidanceBox2',
                content: '公司名称拼音或者英文，在操作资料里面有具体信息',
            },
            {
                selector: '.stepGuidanceBox3',
                content: '公司名称的中文名',
            },
            {
                selector: '.program-overview-values',
                content: '操作资料内有各种资料信息，公司名称，法人信息，法人身份证信息，法人手机号，注册邮箱，公司营业执照，营业执照上有公司名称，社会统一 信用代码之类的信息',
            }
        ];
        // if (isShow == 0) {
        //     return [arr[0]]
        // }
        // if (isShow == 1) {
        //     return [arr[1]]
        // }
        // if (isShow == 2) {
        //     return [arr[2], arr[3], arr[4]]
        // }
        // console.log(arr);
        return arr
    }, [isShow])
    const collapseList = [
        {
            header: '如果我所在的国家/地区不在列表中，该怎么办？',
            html: '<p>如果未列出您的国家/地区，请在以后的日期重新查看，因为我们正努力扩大接受的国家/地区的数量。</p>'
        },
        {
            header: '为何需要提供我的“企业类型”？',
            html: '<p>您必须提供您的业务类型以帮助我们收集相关的业务信息，以便遵守识别和验证措施。</p> '
        },
        {
            header: '为什么要注册多个地区？',
            html: '<p>通过注册多个地区，您可以在全球销售您的商品。 您可以在日后发布商品时选择想要销售商品的商城。</p>'
        },
        {
            header: '我是否需要同时为多家店铺开设一个账户？',
            html: '<p>是的，为了更轻松地开始全球销售，我们同时在欧洲、日本和北美创建了一个销售账户。您可以在发布商品时选择您希望销售商品的特定商店。</p>'
        },
        {
            header: '我要同意的条款和条件是什么？',
            html: '<p>这些条款和条件用于制约特定地区或商城中的销售活动。默认情况下，您必须在所有地区注册。要开始注册特定商城或地区，请点击<a>此处</a>。</p>'
        },
    ]
    const [isActive, setIsActive] = useState(false);
    const awakenTips = (type) => {
        setIsActive(type);
    }

    useEffect(() => {
        getRegInfo()
        let step = localStorage.getItem('stepList') || ''
        if (!step.includes('agreements')) {
            setFirst(true)
        }
    }, [])
    let getRegInfo = useCallback(() => {
        getStoreRegisterInfo().then((res) => {
            for (let item of res.data) {
                if (item.propertyValue) {
                    if (item.propertyKey == "company_site") {
                        formRef.current.setFieldsValue({
                            companyAddress: item.propertyValue
                        })
                        setIsShow(1)
                    }
                    if (item.propertyKey == "company_type") {
                        formRef.current.setFieldsValue({
                            entityType: item.propertyValue
                        })
                        setIsShow(2)
                    }
                    if (item.propertyKey == "company_chinese_name") {
                        formRef.current.setFieldsValue({
                            companyNameZ: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "company_english_name") {
                        formRef.current.setFieldsValue({
                            companyNameY: item.propertyValue
                        })
                    }
                }
            }
        })
    })
    const nextEvent = useCallback(() => {
        formRef.current.validateFields().then((res) => {
            let arr = [
                { "propertyKey": "company_site", "propertyValue": res.companyAddress },
                { "propertyKey": "company_type", "propertyValue": res.entityType },
                { "propertyKey": "company_chinese_name", "propertyValue": res.companyNameZ },
                { "propertyKey": "company_english_name", "propertyValue": res.companyNameY }
            ]
            regSubmit(arr, null, 'agreements', 2).then((data) => {
                if (data.code == 1) {
                    props.history.push('/enterprise')
                }
            })
        }).catch((err) => { })
    }, [])
    const tipDom = useCallback((type) => {
        return (
            <div className='seller-tip'>
                {/* <div className='close'>x</div> */}
                {
                    type == '1' ? (
                        <p>
                            © 1999-2021 年，亚马逊公司或其附属公司
                        </p>
                    ) : (
                        <div>
                            <strong>请选择您的企业注册所在的国家/地区。</strong>
                            <p>您已选择注册为私营企业，即由私人控制和运营的企业。企业卖家是在商业或专业活动的背景下注册的。</p>
                            <span style={{ color: 'rgb(211,128,65)' }}>请确保您选择的业务类型正确无误。</span>
                        </div>
                    )
                }
            </div>
        )
    }, [])

    return (
        <div className="agreementsBox agreements register_style">
            <TipsIcon clicks={awakenTips} />
            {/* 引导步骤 */}
            <Reactours routePath='agreements' isActive={isActive} clicks={awakenTips} steps={steps()} isOpen={isTourOpen} />
            <Header />
            <div style={{ display: 'flex' }}>
                <div className="a-section program-overview-values">
                    <div className="ng-scope a-size-medium">在开始之前，请确保以下事项已准备就绪</div>
                    <div className="ng-scope a-size-small">我们以后可能需要额外的信息或文档</div>
                    <div className="a-row a-grid-vertical-align a-grid-top">
                        <div className="a-column a-span4 a-text-center value-box">
                            <div className="a-icon-row a-spacing-medium reviews-increase-icon"
                                style={{ backgroundImage: `url(${require("@/assets/images/Business_image._V270892899_.png").default})` }}></div>
                            <div className="a-row a-spacing-small">
                                <span className="a-size-large a-text-bold">公司和联系地址</span>
                            </div>
                        </div>
                        <div className="a-column a-span4 a-text-center value-box">
                            <div className="a-icon-row a-spacing-medium reviews-faster-icon"
                                style={{ backgroundImage: `url(${require("@/assets/images/mobile_image._V270892880_.png").default})` }}></div>
                            <div className="a-row a-spacing-small">
                                <span className="a-size-large a-text-bold">手机或固话号码</span>
                            </div>
                        </div>
                        <div className="a-column a-span4 a-text-center value-box a-span-last">
                            <div className="a-icon-row a-spacing-medium starting-easy-icon"
                                style={{ backgroundImage: `url(${require("@/assets/images/credit_card_image._V270892869_.png").default})` }}></div>
                            <div className="a-row a-spacing-small">
                                <span className="a-size-large a-text-bold">可支付信用卡</span>
                            </div>
                        </div>
                        <div className="a-column a-span4 a-text-center value-box a-span-last">
                            <div className="a-icon-row a-spacing-medium starting-four-icon starting-easy-icon"
                                style={{ backgroundImage: `url(${require("@/assets/images/tax_information_image._V270892852_.png").default})` }}></div>
                            <div className="a-row a-spacing-small">
                                <span className="a-size-large a-text-bold">身份详细信息</span>
                            </div>
                        </div>
                    </div>
                    <Form ref={formRef}>
                        <div className="CompanyAddress">
                            <div>
                                <div className="stepGuidanceBox0" >
                                    <p style={{ fontWeight: 'bold' }}>
                                        公司地址
                                        <Popover
                                            content={tipDom('1')}
                                            trigger="hover"
                                        >
                                            <QuestionCircleFilled />
                                        </Popover>
                                    </p>
                                    <Form.Item style={{ marginBottom: 0 }} name="companyAddress" rules={[{ required: true, message: '! 请选择国家/地区' }]}>
                                        <Select placeholder="选择国家/地区" onChange={() => { setIsShow(isShow > 1 ? 2 : 1); }} options={companyAddressList} />
                                    </Form.Item>
                                    <p style={{ color: '#565959', marginBottom: 0 }}>如果您未开办公司，请输入您所在的国家/地区。</p>
                                    <p style={{ color: '#565959', paddingBottom: '20px' }}>不正确的选择可能会影响您的帐户状态。</p>
                                </div>
                                {
                                    isShow >= 1 ? (
                                        <div className="stepGuidanceBox1">
                                            <p style={{ fontWeight: 'bold' }}>业务类型</p>
                                            <Popover
                                                content={tipDom('2')}
                                                trigger="contextMenu"
                                                visible={entityTypeValue ? true : false}
                                                className="center"
                                            ></Popover>
                                            <Form.Item name="entityType" rules={[{ required: true, message: '! 请选择企业类型' }]}>
                                                <Select onChange={(e) => { setIsShow(2); setEntityTypeValue(e) }} placeholder="选择企业类型" options={entityType} />
                                            </Form.Item>
                                        </div>
                                    ) : null
                                }
                                {
                                    isShow >= 2 ? (
                                        <div>
                                            <div className="stepGuidanceBox2" >
                                                <Form.Item
                                                    name="companyNameY"
                                                    rules={[{
                                                        required: true,
                                                        whitespace: true,
                                                        validator: (rule, value, cbfn) => {
                                                            let reg = /^[ 0-9a-zA-Z`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]+$/
                                                            if (!value) {
                                                                cbfn('! 请输入公司名称（英语或拼音）')
                                                            } else if (!reg.test(value)) {
                                                                cbfn('! 公司名称验证错误')
                                                            }
                                                            cbfn()
                                                        }
                                                    }]}
                                                >
                                                    <Input placeholder="公司名称（英语或拼音）" maxLength="50" />
                                                </Form.Item>
                                            </div>
                                            <div className="stepGuidanceBox3" style={{ marginBotton: '20px' }}>
                                                <Form.Item
                                                    name="companyNameZ"
                                                    rules={[{
                                                        required: true,
                                                        whitespace: true,
                                                        validator: (rule, value, cbfn) => {
                                                            let reg = /^[ 0-9\u4e00-\u9fa5a-zA-Z`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]+$/
                                                            if (!value) {
                                                                cbfn('! 请输入公司名称（中文）')
                                                            }
                                                            // else if (!reg.test(value)) {
                                                            //     cbfn('! 公司名称验证错误')
                                                            // }
                                                            cbfn()
                                                        }
                                                    }]}
                                                >
                                                    <Input placeholder="公司名称（中文）" maxLength="50" />
                                                </Form.Item>
                                            </div>
                                            <Form.Item
                                                label=""
                                                valuePropName="checked"
                                                name="agreem"
                                                rules={[
                                                    {
                                                        validator: (_, value) =>
                                                            value ? Promise.resolve() : Promise.reject(new Error('! 请确认您的选择是否正确')),
                                                    },
                                                ]}
                                            // rules={[{ required: true, message: '! 请确认您的选择是否正确' }]}
                                            >
                                                <Checkbox>我确认我的营业地点和类型正确无误，同时我也了解此信息以后无法更改。</Checkbox>
                                            </Form.Item>
                                        </div>
                                    ) : null
                                }



                                <p style={{ marginBottom: '40px' }}>点击“同意并继续”，即表示您同意<span style={{ color: '#0066c0' }}>亚马逊服务商业解决方案协议</span>和<span style={{ color: '#0066c0' }}>亚马逊隐私声明</span>。</p>
                                <p style={{ paddingBottom: '80px', borderBottom: '1px solid #00000040' }}>如果您使用亚马逊除加拿大、美国或墨西哥之外的商城提供的销售服务，便需同意<span style={{ color: '#0066c0' }}>国际销售协议</span>页面上列出</p>
                                <div style={{ textAlign: 'center' }}>
                                    <Button className='next' onClick={nextEvent} type="primary">同意并继续</Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
                <div className="rightCollapse">
                    <p >常见问题</p>
                    <RegisterCollapse list={collapseList} />
                </div>
            </div>
            <Footer />
        </div>
    )
}