import { message, Modal, Button } from "antd";
import AJAX from "api/apis";
import { continuePath } from "utils/utils";

export const regSubmit = (arr, localArr = null, whichPage, step, isShow = 1) => {
    let regInfo = JSON.parse(sessionStorage.getItem("regInfo")) || {}
    let obj = {}
    if (arr.lenght) {
        for (let item of arr) {
            obj[item.propertyKey] = item.propertyValue
        }
    }

    if (localArr) {
        for (let item of localArr) {
            obj[item.propertyKey] = item.propertyValue
        }
    }
    regInfo[whichPage] = obj
    return new Promise((resolve, reject) => {
        AJAX.login.storeRegister({ step, storeRegisterReqList: arr }, isShow).then((data) => {
            if (data.code == 1) {
                sessionStorage.setItem("regInfo", JSON.stringify(regInfo))
                resolve(data);
            } else {
                message.error(data.msg)
                reject(data)
            }
        }).catch((err) => {
            message.error(err.msg)
            reject(err)
        })
    })

}

export const getStoreRegisterInfo = () => {
    return new Promise((resolve, reject) => {
        let regEliminate = localStorage.getItem('regEliminate') || false;
        if (regEliminate == 'false') {
            AJAX.login.getStoreRegisterInfo([]).then((data) => {
                if (data.code == 1) {
                    if (data.data) {
                        resolve(data);
                    }
                } else {
                    message.error(data.msg)
                    reject(data)
                }
            }).catch((err) => {
                message.error(err.msg)
                reject(err)

            })
        }
    })
}

export const stepControl = (data, extend) => {
    const modal = Modal.info();
    let updateContent = () => {
        let paths = "";
        if (data == 3) {
            sessionStorage.removeItem("regInfo");
            paths = '/Agreements'
        } else {
            paths = continuePath(extend)
        }
        modal.destroy();
        Modal.destroyAll()
        // window.location.href = paths
        let url = `/practice_amazon/#${paths}?receptionStoreId=${window.receptionStoreId}`;
        // console.log(paths, url, extend);
        window.location.href = url
    }
    if (data == 2) {
        // window.location.href = '/sendEmails'
        window.location.href = `/practice_amazon/#/mobileNumber?receptionStoreId=${window.receptionStoreId}`;
    } if (data == 6) {
        // window.location.href = '/sendEmails'
        window.location.href = `/practice_amazon/#/sendEmails?receptionStoreId=${window.receptionStoreId}`;
    } else if (data == 5) {

    } else if (window.location.hash == "#/") {
        modal.destroy();
    } else {
        // console.log(Modal);
        // Modal.destroyAll()
        modal.update({
            centered: true,
            footer: [null],
            className: 'unregisteredCompleteTipsBox',
            content: (
                <div className="expire-modal-content">
                    <div className="expire-modal-content-text">
                        <p className="tipsTitle">提示</p>
                        <div className="p-24">
                            <div className="text-center">
                                <img src={require("@/assets/img/home/tips.png").default} />
                            </div>
                            <p style={{ marginTop: '30px' }} className="tipsContent text-center">您的店铺未完成注册，请注册完店铺后再操作！</p>
                        </div>
                    </div>
                    <div className="expire-modal-btn">
                        <Button type="primary" data="hovers-7" onClick={() => { updateContent() }} >继续注册</Button>
                    </div>
                </div>
            )
        });
        setTimeout(() => {
            var htmls = document.getElementsByClassName("ant-modal-mask")[0];
            htmls.style.zIndex = 9999999;
        }, 100)
    }
}