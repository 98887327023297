import InputNumber from "@a-components/AInputNumber";
import withProps from "@a-components/withProps";
import classNames from "classnames";
import { forwardRef, useCallback } from "react";
import "./style.css";

const InputMoney = withProps(
  forwardRef(({ style, disabled, max = 1000000, ...props }, ref) => {
    const handleChange = useCallback(
      (_value) => {
        props.onChange(Math.min(Number(_value), max));
      },
      [max, props]
    );

    return (
      <div className={classNames("a-input-money", { disabled })} style={style}>
        <span className="a-input__addon">
          <span>$</span>
        </span>
        <InputNumber
          ref={ref}
          min={0}
          max={max}
          disabled={disabled}
          {...props}
          onChange={handleChange}
          // formatter={(value) =>
          //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          // }
          // parser={(value) => value.replace(/\s?|(,*)/g, "")}
          // formatter={(value) =>
          //   value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          // }
          // parser={(value) => value.replace(/\s?|(,*)/g, "")}
        />
      </div>
    );
  })
);

export default InputMoney;
