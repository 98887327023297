import React from 'react'
import './LogisticsII.css'
import '../../../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import { Link } from 'react-router-dom'
function LogisticsII() {
	return (
		<div>

			<Header />

			<div className="kc33_tab">
				<ul className="kc33_tab_list">
					<li><img src="../images/kc33_sanjiaoxing.png" alt="" />
						<div>计划简介</div>
						<div>商品创建设置</div>
					</li>
					<li>卖家自配送设置</li>
				</ul>
				<div className="kc33_sp_text">
					<div className="kc33_tx_1">
						<h3>使用此项目在加拿大和墨西哥商城快速创建商品</h3>
						<p>您的远程配送商品信息出现在买家所在商城之前，买家将无法购买这些商品。自动创建商品信息可以让您轻松在加拿大和墨西哥商城中创建您的美国商品</p>
						<p>如果您已经从其他商品连接至加拿大和墨西哥的"建立国际商品信息"，或者使用加拿大或墨西哥作为来源商城，则自动创建商品信息将无法使用</p>
					</div>
					<div className="kc33_tx_2">
						<div className="kc33_box1">
							<p>启用自动创建商品信息功能</p>
							<div>
								<input type="checkbox" checked />加拿大
							</div>
							<input type="checkbox" checked />墨西哥
						</div>
						<div className="kc33_box2">
							<p>启用自动创建商品信息功能之后，您就允许<span>建立国际商品信息</span> 将您的美国商品报价自动复制到加拿大和墨西哥商城。您的美国商品价格将同步到加拿大和墨西哥商城，并根据配送费和外汇汇率的预计差异进行调整。</p>
							<div className="kc33_list">
								<p>自动创建商品不考虑在<span>将收益转换</span>回美元时可能产生的成本，或美国和墨西哥商城之间的销售佣金差额。（美国和加拿大商城的销售佣金没有差异。）</p>
								<div className="kc33_fenlei">
									<div>
										<p>销售佣金高于美国的墨西哥分类</p>
										<ul>
											<li>摄影摄像</li>
											<li>消费类电子产品</li>
											<li>个人电脑</li>
											<li>无线产品</li>
											<li>钟表（高于 1,500美元）</li>
										</ul>
									</div>
									<div>
										<p>销售佣金低于美国的墨西哥分类</p>
										<ul>
											<li>服装与配饰</li>
											<li>办公用品</li>
											<li>宠物用品</li>
											<li>钟表（低于 1,500美元）</li>
										</ul>
									</div>
								</div>
							</div>
							<p>对于通过亚马逊物流在加拿大和墨西哥销售但未注册亚马逊物流远程配送（即加拿大或墨西哥的运营中心）的ASIN，我们将使用并调整您在美国商城的商品价格，以反映外汇汇率。您可以在如下位置为这些商品设置不同的自动定价规则<span>建立国际商品信息</span></p>
						</div>
					</div>
				</div>
				<div className="kc33_jiage">
					<p>请参考定价示例</p>
					<p>在美国，您的商品价格为10美元，货币汇率为1美元=1.30加元。美国亚马逊物流配送费用是1美元，远程配送的亚马逊物流配送费用是2加元. </p>
					<div className="kc33_jiage_ul">
						<ul>
							<li>商品价格规则</li>
							<li>美国商品价格</li>
							<li>美国亚马逊物流配送费用</li>
							<li>远程配送的亚马逊物流配送费用</li>
							<li>商品价格计算</li>
							<li>目标商城的商品价格</li>
						</ul>
						<ul>
							<li>与来源商城相同，针对费用进行调整</li>
							<li>$10.00美元</li>
							<li>$1.00美元</li>
							<li>$2.00加元</li>
							<li>$(10-1)x 1.30 + 2</li>
							<li>$13.70加元</li>
						</ul>
					</div>
					<p>如果您未启用自动创建商品信息功能，则必须在加拿大和墨西哥商城创建商品，以使您的库存处于可售状态。</p>
				</div>
				<div className="kc33_btn">
					<button><Link to="/Logistics" style={{ color: "#000" }}>返回</Link></button>
					<button><Link to="LogisticsIII" style={{ color: "#000" }}>下一页</Link></button>
				</div>
			</div>
			<Footer />

		</div>
	)
}

export default LogisticsII