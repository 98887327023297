import { ATabs } from "@a-components/";
import { useState } from "react";

import NegativeKeywords from "./NegativeKeywords";
import NegativeGoods from "./NegativeGoods";

const NegativeDelivery = ({ advertisingId }) => {
  const [activeKey, setActiveKey] = useState("keywords");

  return (
    <div>
      <ATabs activeKey={activeKey} onTabClick={setActiveKey}>
        <ATabs.TabPane tab="否定关键词" key="keywords">
          <NegativeKeywords
            businessType="advertising"
            businessId={advertisingId}
          />
        </ATabs.TabPane>
        <ATabs.TabPane tab="否定商品" key="goods">
          <NegativeGoods
            businessType="advertising"
            businessId={advertisingId}
          />
        </ATabs.TabPane>
      </ATabs>
    </div>
  );
};

export default NegativeDelivery;
