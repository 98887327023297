import React, { useRef, useEffect, useCallback, useState } from 'react';
import AJAX from "api/apis";
import './surveyResults.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"
import '../default.css/default.css'
import { Link } from 'react-router-dom';
import { Button, Input, Form, Checkbox } from 'antd';
import { getStoreRegisterInfo } from '../../utils/PublicLibrary';

const options = [
    { label: 'Individual', value: 'Individual' },
    { label: 'Corporation', value: 'Corporation' },
    { label: 'Disregarded entity', value: 'Disregarded entity' },
    { label: 'Partnership', value: 'Partnership' },
    { label: 'Simple trust', value: 'Simple trust' },
    { label: 'Grantor trust', value: 'Grantor trust' },
    { label: 'Complex trust', value: 'Complex trust' },
    { label: 'Estate', value: 'Estate' },
    { label: 'Government', value: 'Government' },
    { label: 'International organization', value: 'International organization' },
    { label: 'Disregarded entity', value: 'Disregarded entity' },
    { label: 'International organization', value: 'International organization' },
    { label: 'Central bank of issue', value: 'Central bank of issue' },
    { label: 'Tax- exempt organizatior', value: 'Tax- exempt organizatior' },
    { label: 'Private foundation', value: 'Private foundation' },
];
export default (props) => {
    let formRef = useRef();
    const [isTourOpen, setIsTourOpen] = useState(true);
    const steps1 = [
        {
            selector: '.next',
            content: '点击退出调查',
        }
    ];
    const [isActive, setIsActive] = useState(false);
    const awakenTips = (type) => {
        setIsActive(type);
    }
    useEffect(() => {
        getRegInfo()
    }, [])
    let getRegInfo = useCallback(() => {
        getStoreRegisterInfo().then((res) => {
            for (let item of res.data) {
                if (item.propertyValue) {
                    if (item.propertyKey == "paytaxes_organization_name") {
                        formRef.current.setFieldsValue({
                            benefit_organization_name: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "paytaxes_organization_state") {
                        formRef.current.setFieldsValue({
                            company_state: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "paytaxes_organization_state") {
                        formRef.current.setFieldsValue({
                            company_state: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "paytaxes_organization_site") {
                        formRef.current.setFieldsValue({
                            perpetual_site: `${item.propertyValue.split("------")[5]}  ${item.propertyValue.split("------")[3]}`,
                            perpetual_site1: `${item.propertyValue.split("------")[2]}  ${item.propertyValue.split("------")[1]}  ${item.propertyValue.split("------")[4]}`,
                            perpetual_site2: item.propertyValue.split("------")[0],
                        })
                    }
                    if (item.propertyKey == "survey_sign_date") {
                        formRef.current.setFieldsValue({
                            survey_sign_date: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "personal_sign") {
                        formRef.current.setFieldsValue({
                            survey_sign: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "collection_true_name") {
                        formRef.current.setFieldsValue({
                            collection_true_name: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "benefit_all_person_type") {
                        formRef.current.setFieldsValue({
                            benefit_all_person_type: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "communication_site") {
                        formRef.current.setFieldsValue({
                            communication_site: item.propertyValue.split("------")[0],
                            communication_site1: item.propertyValue.split("------")[1],
                            communication_site2: item.propertyValue.split("------")[2],
                        })
                    }
                    if (item.propertyKey == "reference_number") {
                        formRef.current.setFieldsValue({
                            reference_number: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "survey_sign") {
                        formRef.current.setFieldsValue({
                            survey_sign: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "survey_sign_date") {
                        formRef.current.setFieldsValue({
                            survey_sign_date: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "survey_is_true") {
                        formRef.current.setFieldsValue({
                            survey_is_true: [item.propertyValue]
                        })
                    }
                }
            }
        })
        AJAX.login.getStoreRegisterInfo([]).then((data) => {
            if (data.code == 1 && data.data) {
                for (let item of data.data) {
                    if (item.propertyValue) {
                        if (item.propertyKey == "paytaxes_organization_name") {
                            formRef.current.setFieldsValue({
                                benefit_organization_name: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "paytaxes_organization_state") {
                            formRef.current.setFieldsValue({
                                company_state: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "paytaxes_organization_state") {
                            formRef.current.setFieldsValue({
                                company_state: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "paytaxes_organization_site") {
                            formRef.current.setFieldsValue({
                                perpetual_site: `${item.propertyValue.split("------")[5]}  ${item.propertyValue.split("------")[3]}`,
                                perpetual_site1: `${item.propertyValue.split("------")[2]}  ${item.propertyValue.split("------")[1]}  ${item.propertyValue.split("------")[4]}`,
                                perpetual_site2: item.propertyValue.split("------")[0],
                            })
                        }
                        if (item.propertyKey == "survey_sign_date") {
                            formRef.current.setFieldsValue({
                                survey_sign_date: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "personal_sign") {
                            formRef.current.setFieldsValue({
                                survey_sign: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "collection_true_name") {
                            formRef.current.setFieldsValue({
                                collection_true_name: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "benefit_all_person_type") {
                            formRef.current.setFieldsValue({
                                benefit_all_person_type: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "communication_site") {
                            formRef.current.setFieldsValue({
                                communication_site: item.propertyValue.split("------")[0],
                                communication_site1: item.propertyValue.split("------")[1],
                                communication_site2: item.propertyValue.split("------")[2],
                            })
                        }
                        if (item.propertyKey == "reference_number") {
                            formRef.current.setFieldsValue({
                                reference_number: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "survey_sign") {
                            formRef.current.setFieldsValue({
                                survey_sign: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "survey_sign_date") {
                            formRef.current.setFieldsValue({
                                survey_sign_date: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "survey_is_true") {
                            formRef.current.setFieldsValue({
                                survey_is_true: [item.propertyValue]
                            })
                        }
                    }
                }
            }
        })
    }, [])
    return (
        <div className="enterpriseBox surveyResultsBox">
            {/* 引导步骤 */}
            <TipsIcon clicks={awakenTips} />
            <Reactours routePath="surveyResults" isActive={isActive} clicks={awakenTips} steps={steps1} isOpen={isTourOpen} />
            <Header />
            <div style={{ display: 'flex' }}>
                <div className="a-section program-overview-values">
                    <div className="CompanyAddress">

                        <div>
                            <h1 className="topTitle">税务信息调查</h1>
                            <div className="suessBox">
                                <img src={require("@/assets/img/home/yes.png").default} />
                                <div >
                                    <p className="redCol">已验证</p>
                                    <p>已收到并成功验证您的税务信息</p>
                                    <p className="bolds">适用的预扣税率：0.0%</p>
                                </div>
                            </div>
                            <div className="operationBtn" style={{ textAlign: 'center' }}>
                                <Button className='next' type="primary">
                                    <Link to="/">退出调查</Link>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rightCollapse">
                    <Form ref={formRef}>
                        <table className="investigationTable">
                            <tbody>
                                <tr>
                                    <td colspan="5">Reference ld: A061 134233TR72H55JJ9S</td>
                                </tr>
                                <tr>
                                    <td colspan="5">
                                        <span>form</span>
                                        <span className="fz16">W-8BEN-E</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="fz20" colspan="5">Certificate of Status of Beneficial Owner for United States Tax Withholding and Reporting (Entities)</td>
                                </tr>
                                <tr>
                                    <td colspan="5">
                                        <span className="fz16">SUBSTITUTE</span>
                                        <span>(July 2017)</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="5" className="textAlign">
                                        <div>
                                            <div>
                                                <div className="taableTit">
                                                    <span>Do NOT use this form for:</span>
                                                    <span>Instead, use Form:</span>
                                                </div>
                                                <ul>
                                                    <li>
                                                        <span>● U.S. entity or U.S. citizen or resident</span>
                                                        <span>W-9</span>
                                                    </li>
                                                    <li>
                                                        <span>● A foreign individual</span>
                                                        <span>W- 8BEN (Individual)</span>
                                                    </li>
                                                    <li>
                                                        <span>● A foreign individual or entity claiming that income is effectively connected with the conduct of trade or business within the U.S. (unless claimingtreaty benefits)</span>
                                                        <span>W-8ECI</span>
                                                    </li>
                                                    <li>
                                                        <span>●  foreign partnership, a foreign simple trust, or a foreign grantor trust (unless claiming treaty benefits) (see instructions for exceptions)</span>
                                                        <span>W-8IMY</span>
                                                    </li>
                                                    <li>
                                                        <span>● A foreign government, international organization, foreign central bank of issue, foreign tax -exempt organization, foreign private foundation, orgovernment of a U.S. possession claiming that income is effectively connected U.S. income or that is claiming the applicability of section(s) 115(2)，501(c), 892, 895, or 1443(b) (unless claiming treaty benefits) (see instructions)</span>
                                                        <span>W-8ECI or W-8EXP</span>
                                                    </li>
                                                    <li>
                                                        <span>● Any person acting as an intermediary</span>
                                                        <span>W-8IMY</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="5" className="textAlign">
                                        <span className="bgCol">Part 1</span>
                                        <span>ldentification of Beneficial Owner</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="textAlign beneficialClass">
                                        <p>1   Name of organization that is the beneficial owner</p>
                                        <div>
                                            <Form.Item name="benefit_organization_name" rules={[{ required: true }]}>
                                                <Input disabled />
                                            </Form.Item>
                                        </div>
                                    </td>
                                    <td colSpan="3" className="textAlign beneficialClass">
                                        <p>2   Name of organization that is the beneficial owner</p>
                                        <div>
                                            <Form.Item name="company_state" rules={[{ required: true }]}>
                                                <Input disabled />
                                            </Form.Item>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="5" className="textAlign beneficialClass">
                                        <p>3   Name of organization that is the beneficial owner</p>
                                        <div>
                                            <Form.Item name="collection_true_name" rules={[{ required: true }]}>
                                                <Input disabled />
                                            </Form.Item>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="5" className="textAlign beneficialClass">
                                        <span>4   Type of beneficial owner:</span>
                                        <Form.Item name="benefit_all_person_type">
                                            <Checkbox.Group disabled options={options} defaultValue={['Pear']} />
                                        </Form.Item>
                                        <div>If you entered disregarded entity, partnership, simple trust, or grantor trust above, is the entity a hybrid making a treaty claim? If "Yes" complete Part II.
                                            <Checkbox disabled>是</Checkbox>
                                            <Checkbox disabled>否</Checkbox>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="5" className="textAlign beneficialClass">
                                        <p>3   Name of organization that is the beneficial owner</p>
                                        <div>
                                            <Form.Item name="perpetual_site" rules={[{ required: true }]}>
                                                <Input disabled />
                                            </Form.Item>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="textAlign beneficialClass">
                                        <p>1   Name of organization that is the beneficial owner</p>
                                        <div>
                                            <Form.Item name="perpetual_site1" rules={[{ required: true }]}>
                                                <Input disabled />
                                            </Form.Item>
                                        </div>
                                    </td>
                                    <td colSpan="3" className="textAlign beneficialClass">
                                        <p>2   Name of organization that is the beneficial owner</p>
                                        <div>
                                            <Form.Item name="perpetual_site2" rules={[{ required: true }]}>
                                                <Input disabled />
                                            </Form.Item>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="5" className="textAlign beneficialClass">
                                        <p>3   Name of organization that is the beneficial owner</p>
                                        <div>
                                            <Form.Item name="perpetual_site" rules={[{ required: true }]}>
                                                <Input disabled />
                                            </Form.Item>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="5" className="textAlign beneficialClass">
                                        <p>3   Name of organization that is the beneficial owner</p>
                                        <div>
                                            <Form.Item name="communication_site" rules={[{ required: true }]}>
                                                <Input disabled />
                                            </Form.Item>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="textAlign beneficialClass">
                                        <p>12   Name of organization that is the beneficial owner</p>
                                        <div>
                                            <Form.Item name="communication_site1" rules={[{ required: true }]}>
                                                <Input disabled />
                                            </Form.Item>
                                        </div>
                                    </td>
                                    <td colSpan="3" className="textAlign beneficialClass">
                                        <p>2   Name of organization that is the beneficial owner</p>
                                        <div>
                                            <Form.Item name="reference_number" rules={[{ required: true }]}>
                                                <Input disabled />
                                            </Form.Item>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="5" className="textAlign">
                                        <span className="bgCol">Part 1</span>
                                        <span>ldentification of Beneficial Owner</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="5" className="textAlign">
                                        <p>Under penalties of perjury, I declare that I have examined the information on this form and to the best of my knowledge and belief it is true, correct, and complete. I further certifyunder penalties of perjury that:</p>
                                        <ul>
                                            <li>
                                                <span>● U.S. entity or U.S. citizen or resident</span>
                                            </li>
                                            <li>
                                                <span>● A foreign individual</span>
                                            </li>
                                            <li>
                                                <span>● A foreign individual or entity claiming that income is effectively connected with the conduct of trade or business within the U.S. (unless claimingtreaty benefits)</span>
                                            </li>
                                            <li>
                                                <span>●  foreign partnership, a foreign simple trust, or a foreign grantor trust (unless claiming treaty benefits) (see instructions for exceptions)</span>
                                            </li>
                                            <li>
                                                <span>● A foreign government, international organization, foreign central bank of issue, foreign tax -exempt organization, foreign private foundation, orgovernment of a U.S. possession claiming that income is effectively connected U.S. income or that is claiming the applicability of section(s) 115(2)，501(c), 892, 895, or 1443(b) (unless claiming treaty benefits) (see instructions)</span>
                                            </li>
                                            <li>
                                                <span>● Any person acting as an intermediary</span>
                                            </li>
                                        </ul>
                                        <p>Under penalties of perjury, I declare that I have examined the information on this form and to the best of my knowledge and belief it is true, correct, and complete. I further certifyunder penalties of perjury that:</p>
                                        <div className="bottomInp">
                                            <span>Sign Here</span>
                                            <div>
                                                <Form.Item name="survey_sign" rules={[{ required: true }]}>
                                                    <Input disabled />
                                                </Form.Item>
                                                <p>Signature of individual authorized to sign for beneficial owner</p>
                                            </div>
                                            <div>
                                                <Form.Item name="survey_sign" rules={[{ required: true }]}>
                                                    <Input disabled />
                                                </Form.Item>
                                                <p>Print Name</p>
                                            </div>
                                            <div>
                                                <Form.Item name="survey_sign_date" rules={[{ required: true }]}>
                                                    <Input disabled />
                                                </Form.Item>
                                                <p>Date (MM-DD-YYYY)</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="textAlign" colSpan="5">
                                        <Form.Item name="survey_is_true" rules={[{ required: true }]}>
                                            <Checkbox.Group>
                                                <Checkbox disabled>I certify that I have the capacity to sign for the entity identified on line 1 of this form.</Checkbox>
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Form>
                </div>
            </div>
            <Footer />
        </div>
    )
}