import React from "react"
import "./share.css"
import HomeHeader from 'components/home_header';
import FooterCom from "components/addlisting_footer/FooterCom";
import TabHeader from "./module/TabHeader/TabHeader";
import Hint from "./module/Hint";
import ShareTitle from "./module/ShareTitle";
import TopNavigation from "./module/TopNavigation";

function share() {
    return (
        <div>
            <HomeHeader/>
            <TopNavigation/>
            <div className="share">
                <Hint/>
                <ShareTitle/>
                <TabHeader/>
            </div>
            <FooterCom/>
        </div>
    )
}

export default share