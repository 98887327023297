import React from 'react'
import './BrandInformation.css'
import { Link } from 'react-router-dom'
import { Form, Input, Select, Button, Upload } from 'antd';
const { Option } = Select;
const { Dragger } = Upload;

function BrandInformation() {

  return (
    <div className="BrandInformation" >
      <h3>品牌信息</h3>
      <div style={{ marginBottom: 20 }} >以下信息将有助于我们识别您的品牌，并让您开始进行品牌注册。</div>
      <Form layout="vertical">
        <Form.Item label="您的品牌名称是什么？">
          <Input></Input>
        </Form.Item>
        <Form.Item label="选择一个商标局">
          <Select defaultValue={"1"} >
            <Option value="1">美国-United States Patent and Trademark Office- USPTO</Option>
            <Option value="2">印度- Trademark Registry - TMR</Option>
            <Option value="3">埃及- Egyptian Patent Office - EGYPO</Option>
            <Option value="4">墨西哥- Marcas IMPI- IMPI</Option>
            <Option value="5">意大利- Ufficio Italiano Brevetti e Marchi - UIBM</Option>
            <Option value="6">新加坡-Intellec tual Property Office of Singapore - IPOS</Option>
          </Select>
        </Form.Item>
        <Form.Item label="请输入注册号或序列号">
          <Input />
        </Form.Item>
        <h3>商品信息</h3>
        <Form.Item label="请提供指向您品牌官方网站的 URL。提供 URL 可以帮助我们更好地识别您的品牌  （可选）">
          <Input />
        </Form.Item>
        <Form.Item label="如果您在其他电子商务网站上销售商品，请提供在那些网站上您的店铺网址URL  （可选）">
          <Input />
          <Button>添加更多</Button>
        </Form.Item>
        <h3>商品图片</h3>
        <Form.Item label="至少提供一张可清楚显示永久贴在商品上的品牌名称、徽标或其他识别标记的商品或包装的图片。图片应显示目前正在亚马逊上销售或打算在亚马逊上销售的商品，且不应由计算机生成。">
          <Dragger style={{ height: 150 }} >
            <p>可接受的文件类型为 .jpg、.png 和 .gif。文件大小不应超过 5MB</p>
            <Button style={{ margin: "20px 0" }} >上传</Button>
            <div>或拖动至此处上传</div>
          </Dragger>
        </Form.Item>
      </Form>
    </div>
  )
}

export default BrandInformation
