import React, { useRef, useEffect, useCallback, useState } from 'react';
import AJAX from "api/apis";
import './bill.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import Reactours from "components/reactours"
import TipsIcon from "components/tipsIcon"
// import '../../default.css/default.css'
import { Link } from 'react-router-dom';
import RegisterSteps from 'components/registerSteps';
import RegisterCollapse from 'components/registerCollapse';
import { Button, Collapse, Steps, Radio, Input, Form, Select, Modal, Checkbox, Tooltip } from 'antd';
import { getMonth, getYearValidity, getSellerNationality } from 'utils/regDate';
import { regSubmit, getStoreRegisterInfo } from 'utils/PublicLibrary';


const { Panel } = Collapse;
const { Step } = Steps;
export default (props) => {
    let formRef = useRef();
    let [loading, setLoading] = useState(false)
    let [isModalVisible, setisModalVisible] = useState(false)
    let [termMonthRules, setTermMonthRules] = useState([{ required: true, message: '请输入月份' }])
    let [termYearRules, setTermYearRules] = useState([{ required: true, message: '请输入年份' }])
    let [isItValid, setIsItValid] = useState(false)
    const [isTourOpen, setIsTourOpen] = useState(true);
    let [isReturn, setIsReturn] = useState(props.location?.state?.isReturn || 0);
    const steps1 = [
        {
            selector: '.stepGuidanceBox0',
            content: 'visa信用卡的卡号/必须是能外币支付的信用卡',
        },
        {
            selector: '.stepGuidanceBox1',
            content: '有效期限，前面填到期月份/后面填到期年份',
        },
        {
            selector: '.stepGuidanceBox2',
            content: '法人的姓名，填写英文',
        },
        {
            selector: '.stepGuidanceBox3',
            content: '信用卡账单地址，填写中文',
        },
        // {
        //     selector: '.stepGuidanceBox4',
        //     content: '储蓄卡，可使用中国的储蓄卡',
        // },
        // {
        //     selector: '.stepGuidanceBox5',
        //     content: '法人的姓名，填写英文',
        // },
        // {
        //     selector: '.stepGuidanceBox6',
        //     content: '绑定的第三方收款平台号码/payoneer/连连支付等收款平台',
        // },
        // {
        //     selector: '.stepGuidanceBox7',
        //     content: '法人的银行卡账号',
        // },
        // {
        //     selector: '.stepGuidanceBox8',
        //     content: '再次输入法人的银行卡账号',
        // }
    ];
    const collapseList = [
        {
            header: '我为什么要提供信用卡信息？',
            html: '<p>我们将向您收取服务费（如果适用），如果您的账户余额为负，我们将在结算周期结束时从您的信用卡中扣款。</p>'
        },
        {
            header: '我开户后能否提供其他信用卡？',
            html: '<p>您可以通过账户的“设置”选项卡下的“账户信息”随时提供新的信用卡。</p> '
        },
        {
            header: '输入信用卡详细信息后会怎样？',
            html: '<p>当您输入新卡信息或更新现有卡的信息后，亚马逊将要求您的银行确认您的身份并验证您的信用卡。亚马逊实际上并未收取费用。</p>'
        },
        {
            header: '接受哪些信用卡？',
            html: '<p>我们接受以下信用卡： AMEX、VISA和Mastercard。 我们不接受其他付款方式，如预付信用卡、礼品券、支票或在线支付系统(PayPal)。</p> '
        },
        {
            header: '如果以后我不想在商城中出售商品，怎么办？ 该费用将如何变化？',
            html: '<p>您可以在注册后随时从任何商城取消订购。促销费用适用于两个或两个以上的商城。如果您只在1个商城出售商品，则收取的费用将属于该商城。</p> '
        },
        {
            header: '何时支付服务费？',
            html: '<p>在前六个月，您将需要支付每月 39.99 美元的专业销售订阅费。从第七个月开始，无论您在何处销售，您将需要支付总计不超过 39.99 美元（或等值货币）的针对所有商城的综合专业销售全球订阅费。有关详细信息，请参<a>阅此页面</a>。</p> '
        },
    ]
    const [isActive, setIsActive] = useState(false);
    const awakenTips = (type) => {
        setIsActive(type);
    }
    const countrylity = getSellerNationality()
    const monthList = getMonth()
    const yearList = getYearValidity()
    let [addressValue, setAddressValue] = useState(1)
    let [addressM, setAddressM] = useState('1')
    let [billingAddress, setBillingAddress] = useState('')
    let addressChange = useCallback((e) => {
        setAddressValue(e.target ? e.target.value : e)
    }, [])
    useEffect(() => {
        getRegInfo()
    }, [])
    let getRegInfo = useCallback(() => {
        getStoreRegisterInfo().then((res) => {
            for (let item of res.data) {
                if (item.propertyValue) {
                    if (item.propertyKey == "proceeds_credit_card") {
                        formRef.current.setFieldsValue({
                            cardNumber: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "proceeds_credit_card_name") {
                        formRef.current.setFieldsValue({
                            CardholderNameF: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "proceeds_credit_card_date") {
                        formRef.current.setFieldsValue({
                            termMonth: item.propertyValue.split("------")[0],
                            termYear: item.propertyValue.split("------")[1],
                        })
                    }
                    // if (item.propertyKey == "address_value") {
                    //     formRef.current.setFieldsValue({
                    //         address: item.propertyValue || 1
                    //     })
                    //     setTimeout(() => {
                    //         addressChange(item.propertyValue || 1)
                    //     }, 300)
                    // }
                    if (item.propertyKey == "seller_dwell_site") {
                        billingAddress = item.propertyValue
                        setBillingAddress(item.propertyValue)
                    }
                    if (item.propertyKey == "proceeds_bill_site") {
                        if (item.propertyValue == billingAddress) {
                            formRef.current.setFieldsValue({
                                address: "1"
                            })
                        } else {
                            formRef.current.setFieldsValue({
                                address: "2"
                            })
                            setAddressValue("2")
                        }
                        formRef.current.setFieldsValue({
                            companyAddress: item.propertyValue.split("------")[6],
                            detailedAddressJ: item.propertyValue.split("------")[1],
                            detailedAddressD: item.propertyValue.split("------")[0],
                            postalCode: item.propertyValue.split("------")[5],
                            province: item.propertyValue.split("------")[4],
                            city: item.propertyValue.split("------")[3],
                            area: item.propertyValue.split("------")[2],
                        })
                    }
                }
            }
        })
        AJAX.login.getStoreRegisterInfo([]).then((data) => {
            for (let item of data.data) {
                if (item.propertyValue) {
                    if (item.propertyKey == "seller_dwell_site") {
                        billingAddress = item.propertyValue
                        setBillingAddress(item.propertyValue)
                    }
                }
            }
        })
        if (isReturn) {
            AJAX.login.getStoreRegisterInfo([]).then((data) => {
                for (let item of data.data) {
                    if (item.propertyValue) {
                        if (item.propertyKey == "proceeds_credit_card") {
                            formRef.current.setFieldsValue({
                                cardNumber: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "proceeds_credit_card_name") {
                            formRef.current.setFieldsValue({
                                CardholderNameF: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "deposit_state") {
                            formRef.current.setFieldsValue({
                                bankAddress: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "deposit_person_name") {
                            formRef.current.setFieldsValue({
                                CardholderNameC: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "deposit_remittance_number") {
                            formRef.current.setFieldsValue({
                                transitNumber: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "deposit_bank_number") {
                            formRef.current.setFieldsValue({
                                bankAccount: item.propertyValue,
                                verificationBankAccount: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "proceeds_credit_card_date") {
                            formRef.current.setFieldsValue({
                                termMonth: item.propertyValue.split("------")[0],
                                termYear: item.propertyValue.split("------")[1],
                            })
                        }
                        // if (item.propertyKey == "address_value") {
                        //     formRef.current.setFieldsValue({
                        //         address: item.propertyValue || 1
                        //     })
                        //     setTimeout(() => {
                        //         addressChange(item.propertyValue || 1)
                        //     }, 300)
                        // }
                        if (item.propertyKey == "seller_dwell_site") {
                            billingAddress = item.propertyValue
                            setBillingAddress(item.propertyValue)
                        }
                        if (item.propertyKey == "proceeds_bill_site_option") {
                            formRef.current.setFieldsValue({
                                address: item.propertyValue
                            })
                            addressChange(item.propertyValue || 1)
                        }

                        if (item.propertyKey == "proceeds_bill_site") {
                            // if (item.propertyValue == billingAddress) {
                            //     formRef.current.setFieldsValue({
                            //         address: "1"
                            //     })
                            // } else {
                            //     formRef.current.setFieldsValue({
                            //         address: "2"
                            //     })
                            //     setAddressValue("2")
                            // }
                            formRef.current.setFieldsValue({
                                companyAddress: item.propertyValue.split("------")[6],
                                detailedAddressJ: item.propertyValue.split("------")[1],
                                detailedAddressD: item.propertyValue.split("------")[0],
                                postalCode: item.propertyValue.split("------")[5],
                                province: item.propertyValue.split("------")[4],
                                city: item.propertyValue.split("------")[3],
                                area: item.propertyValue.split("------")[2],
                            })
                        }
                    }
                }
            })
        }
    }, [billingAddress, isReturn])
    let nextEvent = useCallback(() => {
        if (loading) return
        setLoading(true)
        formRef.current.validateFields().then(async (res) => {

            // console.log(onSetIsItValid(res))
            if (onSetIsItValid(res)) {
                setLoading(false)
                return
            }
            let arr = [
                { "propertyKey": "proceeds_credit_card", "propertyValue": res.cardNumber },
                { "propertyKey": "proceeds_credit_card_date", "propertyValue": res.termMonth + "------" + res.termYear },
                { "propertyKey": "proceeds_credit_card_name", "propertyValue": res.CardholderNameF },
                {
                    "propertyKey": "proceeds_bill_site", "propertyValue": res.address == 1 ?
                        billingAddress || '' :
                        res.detailedAddressD + "------" + res.detailedAddressJ + "------" + res.area + "------" + res.city + "------" + res.province + "------" + res.postalCode + "------" + res.companyAddress
                },
                { "propertyKey": "proceeds_bill_site_option", "propertyValue": res.address },
            ]
            let localArr = [
                { "propertyKey": "address_value", "propertyValue": res.address },
            ]
            regSubmit(arr, localArr, 'bill', 6).then((data) => {
                if (data.code == 1) {
                    setLoading(false)
                    props.history.push('/shop')
                    // props.history.push('/taxation')
                }
            }).catch(() => {
                setLoading(false)
            })
        }).catch(err => {
            setLoading(false)

            console.log(err, "err")
        });
    }, [billingAddress, loading, isItValid])
    const onSetIsItValid = (res) => {
        let is = false
        if (res.termMonth && res.termYear) {
            let date = new Date();
            let currentYear = date.getFullYear(); //获取完整的年份(4位)
            let currentMonth = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
            let selectMonth = res.termMonth.split("月")[0];
            let selectYear = res.termYear.split("年")[0]
            if (currentYear < selectYear) {
                setIsItValid(false)
                is = false
            } else if (currentYear == selectYear) {
                if (selectMonth < currentMonth) {
                    setIsItValid(true)
                    is = true
                } else {
                    setIsItValid(false)
                    is = false
                }
            } else {
                setIsItValid(true)
                is = true
            }
        }
        return is
    }
    const cancelModal = () => {
        setisModalVisible(false)
    }
    const okModel = () => {
        cancelModal()
    }
    return (
        <div className="billBox register_style">
            {/* 引导步骤 */}
            <TipsIcon clicks={awakenTips} />
            <Reactours routePath="bill" isActive={isActive} clicks={awakenTips} steps={steps1} isOpen={isTourOpen} />
            <Header />
            <div style={{ display: 'flex' }}>
                <div className="a-section program-overview-values">
                    <RegisterSteps current={3} />
                    <div className="CompanyAddress">
                        <h5>
                            账单信息
                            <p>信用卡详情</p>
                        </h5>
                        <div className='kat-divider'></div>
                        <Form
                            ref={formRef}
                            initialValues={{
                                address: "1"
                            }}
                        >
                            <div>
                                <div className='a-section_alert'>
                                    <div className='icon'>
                                        <img src={require("@assets/images/bill_alert.png").default} />
                                    </div>
                                    <div>
                                        <h5>全球开店费用</h5>
                                        <p>
                                            在前六个月，您将需要支付每月 39.99 美元的专业销售订阅费。从第七个月开始，无论您在何处销售，您将需要支付总计不超过 39.99 美元（或等值货币）的针对所有商城的综合专业销售全球订阅费。有关详细信息，请参阅<span>阅此页面</span> 。
                                        </p>
                                    </div>
                                </div>
                                <div className="inpBox inpBox1">
                                    <div style={{ width: '40%' }}>
                                        <p style={{ fontWeight: 'bold' }}>信用卡号</p>
                                        <div className="stepGuidanceBox0">
                                            <Form.Item name="cardNumber"
                                                rules={[{
                                                    required: true,
                                                    validator: (rule, value, fun) => {
                                                        let reg = /^[1-9]$/;
                                                        if (!value) {
                                                            fun('请输入卡号')
                                                        } else if (reg.test(value) || value.length < 17) {
                                                            fun('卡号无效')
                                                        }
                                                        fun()
                                                    }
                                                }]}
                                            >
                                                <Input maxLength="20" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="dates stepGuidanceBox1">
                                        <p>到期日</p>
                                        <div>
                                            <div>
                                                <Form.Item name="termMonth" rules={termMonthRules}>
                                                    <Select placeholder="月" onChange={e => {
                                                        formRef.current.validateFields(['termMonth', 'termYear']).then((res) => {
                                                            // console.log(res)
                                                            onSetIsItValid(res)
                                                        })
                                                    }} options={monthList} />
                                                </Form.Item>
                                                <Form.Item name="termYear" rules={termYearRules}>
                                                    <Select placeholder="年" onChange={e => {
                                                        formRef.current.validateFields(['termMonth', 'termYear']).then((res) => {
                                                            // console.log(res)
                                                            onSetIsItValid(res)
                                                        })
                                                    }} options={yearList} />
                                                </Form.Item>

                                            </div>
                                            {isItValid ? <div style={{ color: '#ff4d4f' }}>有效期无效</div> : null}
                                        </div>
                                    </div>
                                </div>
                                <p style={{ fontWeight: 'bold', marginTop: '30px' }}>持卡人姓名</p>
                                <div>
                                    <Form.Item className="stepGuidanceBox2" name="CardholderNameF" rules={[{ required: true, message: "请输入持卡人姓名" }]}>
                                        <Input placeholder="请输入持卡人姓名" max="20" />
                                    </Form.Item>
                                </div>
                                <p style={{ fontWeight: 'bold', marginTop: '30px' }}>收费地址</p>
                                <Form.Item className="stepGuidanceBox3" style={{ marginBottom: 0 }} name="address" rules={[{ required: true, message: "请选择账单地址" }]}>
                                    <Radio.Group style={{ width: '100%' }} onChange={addressChange}>
                                        <div className="bottomDesc">
                                            <Radio value="1">{billingAddress.replace(/------/g, "")}</Radio>
                                        </div>
                                    </Radio.Group>
                                </Form.Item>
                                <div onClick={() => { formRef.current.setFieldsValue({ address: 3 }); setAddressValue(3); setisModalVisible(true) }} style={{ width: '100%', paddingLeft: '10px', color: '#0066c0' }}>查看保存的所有地址</div>
                                <div onClick={() => { formRef.current.setFieldsValue({ address: 2 }); setAddressValue(2) }} style={{ width: '100%', paddingLeft: '10px', color: '#0066c0' }}>+ 添加新地址</div>
                                {addressValue == 2 ? <div>
                                    <div className="inpBox addressBox">
                                        <Form.Item name="companyAddress" rules={[{ required: true, message: '请选择国家/地区' }]}>
                                            <Select placeholder="选择国家/地区" options={countrylity} />
                                        </Form.Item>
                                        <Form.Item
                                            name="postalCode"
                                            rules={[{
                                                required: true,
                                                validator: (rule, value, fun) => {
                                                    let reg = /^[1-9][0-9]{5}$/;
                                                    if (!value) {
                                                        fun('请输入邮政编码')
                                                    } else if (!reg.test(value)) {
                                                        fun('邮政编码验证失败')
                                                    }
                                                    fun()
                                                }
                                            }]}>
                                            <Input placeholder="邮政编码" maxLength="6" />
                                        </Form.Item>
                                    </div>
                                    <div className="inpBox addressBox">
                                        <Form.Item name="province" rules={[{
                                            required: true,
                                            validator: (rule, value, fun) => {
                                                let reg = /[\u4E00-\u9FA5\uFE30-\uFFA0]/;
                                                if (!value) {
                                                    fun('请输入省/自治区/直辖市')
                                                } else if (!reg.test(value)) {
                                                    fun('请用中文填写')
                                                }
                                                fun()
                                            }
                                        }]}>
                                            <Input placeholder="省/自治区/直辖市" maxLength="50" />
                                        </Form.Item>
                                        <Form.Item name="city" rules={[{
                                            required: true,
                                            validator: (rule, value, fun) => {
                                                let reg = /[\u4E00-\u9FA5\uFE30-\uFFA0]/;
                                                if (!value) {
                                                    fun('请输入城市')
                                                } else if (!reg.test(value)) {
                                                    fun('请用中文填写')
                                                }
                                                fun()
                                            }
                                        }]}>
                                            <Input placeholder="城市" maxLength="50" />
                                        </Form.Item>
                                    </div>
                                    <div className="inpBox addressBox">
                                        <Form.Item name="area" rules={[{
                                            required: true,
                                            validator: (rule, value, fun) => {
                                                let reg = /[\u4E00-\u9FA5\uFE30-\uFFA0]/;
                                                if (!value) {
                                                    fun('请输入区')
                                                } else if (!reg.test(value)) {
                                                    fun('请用中文填写')
                                                }
                                                fun()
                                            }
                                        }]}>
                                            <Input placeholder="区" maxLength="50" />
                                        </Form.Item>
                                        <Form.Item name="detailedAddressJ" rules={[{
                                            required: true,
                                            validator: (rule, value, fun) => {
                                                let reg = /[\u4E00-\u9FA5\uFE30-\uFFA0]/;
                                                if (!value) {
                                                    fun('请输入详细地址，如道路、街道、门牌号等')
                                                } else if (!reg.test(value)) {
                                                    fun('请用中文填写')
                                                }
                                                fun()
                                            }
                                        }]}>
                                            <Input placeholder="详细地址，如道路、街道、门牌号等" maxLength="100" />
                                        </Form.Item>
                                    </div>
                                    <div className="inpBox addressBox">
                                        <Form.Item name="detailedAddressD" rules={[{
                                            required: true,
                                            validator: (rule, value, fun) => {
                                                let reg = /[\u4E00-\u9FA5\uFE30-\uFFA0]/;
                                                if (!value) {
                                                    fun('请输入详细地址，如大厦、楼层、房间号等')
                                                } else if (!reg.test(value)) {
                                                    fun('请用中文填写')
                                                }
                                                fun()
                                            }
                                        }]}>
                                            <Input placeholder="详细地址，如大厦、楼层、房间号等" maxLength="100" />
                                        </Form.Item>
                                    </div>
                                </div> : null}

                                <div className="operationBtn" style={{ textAlign: 'center' }}>
                                    <Button className='previousPage' type="primary">
                                        <Link to={{ pathname: '/deposit', state: { isReturn: 1} }}>上一页</Link>
                                    </Button>
                                    <Button onClick={nextEvent} className='next' type="primary">
                                        下一页
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <div className="rightCollapse">
                    <p >常见问题</p>
                    <RegisterCollapse list={collapseList} />
                </div>
            </div>
            <Modal
                title="您的地址"
                visible={isModalVisible}
                onOk={okModel}
                onCancel={cancelModal}
                style={{ top: '36%' }}
                width="400px"
                className='ymx-modal xuandizhi'
                footer={(<div className="operationBtn" style={{ textAlign: 'center' }}>
                    <Button onClick={cancelModal} className='previousPage' type="primary">
                        取消
                    </Button>
                    <Button onClick={okModel} className='next' type="primary">
                        使用此地址
                    </Button>
                </div>)}
            >
                <Radio.Group style={{ width: '100%' }} value={addressM} onChange={(e) => {
                    setAddressM(e.target.value)
                }}>
                    <div className="bottomDesc action">
                        <Radio value='1'>{billingAddress.replace(/------/g, "")}</Radio>
                    </div>
                    <div className="bottomDesc">
                        <Radio value='2'>{billingAddress.replace(/------/g, "")}</Radio>
                    </div>
                </Radio.Group>
            </Modal>
            <Footer />
        </div>
    )
}