import React,{useState,useEffect,useRef} from 'react';
import {Item,} from './style';

export default (props) => {

    return <Item>
        <div className='positive'>
            <div className='title'>
                <p>{props.title}</p>
                {/* <button>...</button> */}
            </div>
            {props.children}
        </div>
    </Item>
}