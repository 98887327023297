import { ASelect, AButton, AText, AInput, ATable } from "@a-components/";
import Apis from "@api/apis";
import usePaginationTable from "@hooks/usePaginationTable";
import { Row, Col } from "antd";
import { useCallback, useState } from "react";
import { Link, useHistory } from "react-router-dom";

const columns = [
  {
    title: "追踪编码",
    key: "name",
    dataIndex: "name",
    render(text, { promotionGoodsId, goodsListType }) {
      const info = {
        promotionGoodsId,
        type: goodsListType,
      };
      return (
        <Link
          to={`/Promotions/CreateProduceSelection?${new URLSearchParams(
            info
          ).toString()}`}
        >
          {text}
        </Link>
      );
    },
  },
  {
    title: "内部描述",
    key: "internalDescription",
    dataIndex: "internalDescription",
  },
  {
    title: "创建日期",
    key: "createTime",
    dataIndex: "createTime",
  },
];

const typeListOptions = [
  {
    label: "SKU列表",
    value: 1,
  },
  {
    label: "ASIN列表",
    value: 2,
  },
  {
    label: "浏览分类节点编号列表",
    value: 3,
  },
  {
    label: "品牌名称列表",
    value: 4,
  },
  {
    label: "高级商品列表",
    value: 5,
  },
];


const filterTypeOptions = [
  {
    label: '追踪编码',
    value: 1,
  },
  {
    label: '创建日期',
    value: 2,
  },
]

const GoodsListManage = () => {
  const [createType, setCreateType] = useState(1);
  const history = useHistory();
  const [trackingCode, setTrackingCode] = useState("");

  const fetcher = useCallback((params) => {
    return Apis.promotion.getPromotionGoodsList(params);
  }, []);

  const {
    tableList,
    isTableLoading,
    pagination,
    operator: { reFetch },
  } = usePaginationTable({
    fetcher,
    params: { name: trackingCode },
  });

  const handleCreateGoodsList = useCallback(() => {
    history.push(`/Promotions/CreateProduceSelection?type=${createType}`);
  }, [history, createType]);

  return (
    <div>
      <Row className="pv-12 border-b-ccc">
        <Col span={4}>
          <ASelect
            className="w-100percent"
            options={typeListOptions}
            value={createType}
            onChange={setCreateType}
          ></ASelect>
        </Col>
        <Col offset={1}>
          <AButton className="stepGuidanceBox10" type="primary" onClick={handleCreateGoodsList}>
            创建商品列表
          </AButton>
        </Col>
      </Row>

      <div className="pv-12">
        <AText className="fs-24 " strong>
          搜索
        </AText>

        <Row className="mt-6">
          <Col span={4}>
            <ASelect className="mr-24 w-100percent" options={filterTypeOptions} value={1}></ASelect>
          </Col>
          <Col offset={1}>
            <AInput
              placeholder="需要部分追踪编码"
              value={trackingCode}
              onChange={(e) => setTrackingCode(e.target.value)}
            ></AInput>
          </Col>
          <Col offset={1}>
            <AButton onClick={() => reFetch()}>搜索</AButton>
          </Col>
        </Row>
      </div>

      <div>{pagination.total} 商品列表</div>

      <ATable
        className="mt-12"
        dataSource={tableList}
        columns={columns}
        pagination={pagination}
        loading={isTableLoading}
      ></ATable>
    </div>
  );
};

export default GoodsListManage;
