import React from 'react'
import './addGive.css'
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import { Radio, Input } from 'antd';
import { Link } from 'react-router-dom';

const { TextArea } = Input;
function Coupons() {
    const [value, setValue] = React.useState(2);

    const onChange = e => {
        setValue(e.target.value);
    };
    let isshow = true
    return (
        <div>
            <Header />
            <div>
                <div className="end05_myBody addDiscountBox">
                    {/* <!-- 头部 --> */}
                    <div className="end05_myBody_header">
                        <div className="end05_myBody_header_title">
                            <h1>创建促销: 买一赠一</h1>
                        </div>
                    </div>
                    <div className="propotionSelect">
                        <span>查看“管理促销”页面</span>
                        <span className="selectBtn">
                            <Link to="/selectPromotion">查看</Link>
                        </span>
                    </div>
                    <div>
                        <div className="a-section-expander-container">第 1 步：选择促销条件</div>
                        <div className="tableBox">
                            <table border="1" cellspacing="0" cellpadding="0" bordercolor="#EEEEEE">
                                <tr className="coupons1">
                                    <td>买家所购商品</td>
                                    <td colspan="2">
                                        <select style={{ width: '90%', height: '35px' }}>
                                            <option value="此商品的最低购买数量">此商品的最低购买数量</option>
                                            <option value="每购买商品（数量）">每购买商品（数量）</option>
                                        </select>
                                    </td>
                                    <td><input style={{ width: '80%', height: '35px' }} value="1" />
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr className="coupons1">
                                    <td>须购买商品</td>
                                    <td colspan="2">
                                        <select style={{ width: '90%', height: '35px' }}>
                                            <option value="B08Y5QW649">B08Y5QW649</option>
                                            <option value="3.8儿童玩具买一送一">3.8儿童玩具买一送一</option>
                                            <option value="录像B076H3SRXG">录像B076H3SRXG</option>
                                            <option value="B08D9GMVF1买一赠一">B08D9GMVF1买一赠一</option>
                                            <option value="所有商品分类">所有商品分类</option>
                                        </select>
                                    </td>
                                    <td>
                                        <Link style={{ color: '#0066c0' }} to="">创建新的商品选择</Link>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr className="coupons1">
                                    <td>买家获得</td>
                                    <td colspan="2">
                                        <select style={{ width: '90%', height: '35px' }}>
                                            <option value="减免折扣%">减免折扣%</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input style={{ width: '80%', height: '35px' }} value="1" />
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr className="coupons1">
                                    <td>适用范围</td>
                                    <td colspan="2">
                                        <select style={{ width: '90%', height: '35px' }}>
                                            <option value="须购买商品">须购买商品</option>
                                            <option value="额外购买的商品">额外购买的商品</option>
                                        </select>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr className="coupons1">
                                    <td>买家优惠的适用商品数量</td>
                                    <td>
                                        <input style={{ width: '80%', height: '35px' }} value="" />
                                    </td>
                                    <td>
                                        <span className="cengjiTitles">当买家购买</span>
                                    </td>
                                    <td>
                                        <input disabled style={{ width: '80%', height: '35px' }} value="" />
                                    </td>
                                    <td>
                                        <span className="cengjiTitles">件商品</span>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr className="coupons1">
                                    <td style={{ color: '#0066c0' }}>更多选项</td>
                                    <td colspan="6"></td>
                                </tr>
                            </table>
                        </div>
                        <div className="tipsBox">
                            <h4 className="a-alert-heading">对同一商品组创建多个促销可能会造成买家混合多个促销来实现大幅优惠。</h4>
                            <p>请阅读<span style={{ color: '#0066c0' }}>优惠码和混合促销</span>了解更多信息。</p>
                        </div>
                        <div className="a-section-expander-container">第 2 步：设置促销时间</div>
                        <div className="tableBox">
                            <table border="1" cellspacing="0" cellpadding="0" bordercolor="#EEEEEE">
                                <tr className="coupons1">
                                    <td>开始日期</td>
                                    <td>
                                        <input style={{ width: '95%', height: '35px' }} value="2021-3-3" />
                                    </td>
                                    <td>
                                        <select style={{ width: '80%', height: '35px' }}>
                                            <option value="01:00">01:00</option>
                                            <option value="02:00">02:00</option>
                                            <option value="03:00">03:00</option>
                                            <option value="04:00">04:00</option>
                                        </select>
                                    </td>
                                    <td>PST</td>
                                    <td></td>

                                </tr>
                                <tr className="coupons1">
                                    <td>结束日期</td>
                                    <td>
                                        <input style={{ width: '95%', height: '35px' }} value="2021-3-3" />
                                    </td>
                                    <td>
                                        <select style={{ width: '80%', height: '35px' }}>
                                            <option value="01:00">01:00</option>
                                            <option value="02:00">02:00</option>
                                            <option value="03:00">03:00</option>
                                            <option value="04:00">04:00</option>
                                        </select>
                                    </td>
                                    <td>PST</td>
                                    <td></td>
                                </tr>
                                <tr className="coupons1">
                                    <td>内部描述</td>
                                    <td colspan="4">
                                        <input style={{ width: '95%', height: '35px' }} value="示例：母亲节购物免运费" />
                                    </td>
                                </tr>
                                <tr className="coupons1">
                                    <td>追踪编码</td>
                                    <td colspan="4">
                                        <input style={{ width: '95%', height: '35px' }} value="购买折扣 2021/03/09 1-23-39-508" />

                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="a-section-expander-container">第 3 步：更多选项</div>
                        <div className="tableBox">
                            <table border="1" cellspacing="0" cellpadding="0" bordercolor="#EEEEEE">
                                <tr className="coupons1">
                                    <td>优惠码类型</td>
                                    <td className="counpType" colspan="2">
                                        <Radio.Group value={value}>
                                            <Radio value="一次性">一次性</Radio>
                                            <Radio value="无限制">无限制</Radio>
                                            <Radio checked value="无">无</Radio>
                                        </Radio.Group>
                                    </td>
                                    <td colspan="2">
                                        <div>
                                            无限制促销代码没有数量限制。<span style={{ color: '#0066c0' }}>了解更多信息</span>
                                        </div>
                                    </td>
                                    <td></td>

                                </tr>
                                <tr className="coupons1">
                                    <td className="zdytips" onClick={() => {
                                        isshow = !isshow
                                    }} style={{ color: '#0066c0' }}>自定义消息</td>
                                    <td colspan="6"></td>
                                </tr>
                                {/* className={isshow ? "hides" : "coupons1"} */}
                                <tr className="coupons1">
                                    <td>结算显示文本</td>
                                    <td colspan="2">
                                        <input style={{ width: '95%', height: '35px' }} value="Promotion Applied" />
                                    </td>
                                    <td colspan="2"></td>
                                    <td></td>
                                </tr>
                                <tr className="coupons1">
                                    <td>短显示文本</td>
                                    <td colspan="2">
                                        <input disabled style={{ width: '95%', height: '35px' }} value="Promotion Applied" />
                                    </td>
                                    <td colspan="2"></td>
                                    <td></td>
                                </tr>
                                <tr className="coupons1">
                                    <td>商品详情页面显示文本</td>
                                    <td>
                                        <input checked type="checkbox" />
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr className="coupons1">
                                    <td>需购买商品显示文本</td>
                                    <td colspan="5">
                                        <Radio.Group value={value}>
                                            <Radio checked style={{ display: "block" }} value="标准文本">标准文本
                                                <input disabled style={{ width: '95%', height: '35px', display: "block" }} value="Qualifying items" />
                                            </Radio>
                                            <Radio style={{ display: "block" }} value="自定义文本">自定义文本
                                                <input style={{ width: '95%', height: '35px', display: 'block' }} value="" />
                                            </Radio>
                                        </Radio.Group>
                                    </td>
                                </tr>
                                <tr className="coupons1">
                                    <td>显示优先级</td>
                                    <td colspan="2">
                                        <select style={{ width: '80%', height: '35px' }}>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                        </select>
                                    </td>
                                    <td colspan="2"></td>
                                    <td></td>
                                </tr>
                                <tr className="coupons1">
                                    <td>条款和条件</td>
                                    <td colspan="5">
                                        <TextArea rows={10} value="To receive the Best Value discount:
Add all products described in the promotion to your Shopping Cart in one of two ways:
Via the “Add both to Cart” button in the promotion description, OR
Via the “Add to Shopping Cart” button on each respective product information page.
The amount of the Best Value discount will be reflected on the final order checkout page and will be allocated proportionally to all promotion items in the Shopping Cart, including the Best Value item itself. For example, if the promotion offers $5 off one item worth $10 when you purchase two qualifying items worth $20 each, the $5 will be divided proportionately between the three items, so that the Best Value item will appear with a $1 discount, and each of the two other qualifying items will appear with a $2 discount.
Terms and Conditions:
The promotion is valid for a limited time only. Amazon reserves the right to cancel it at any time.
If you do not purchase the qualifying and Best Value items added to your Shopping Cart when the promotion is in effect, the discount will not apply.
The promotion applies only to qualifying items displaying the offer message on their product information pages.
The promotion applies only to products sold by the seller indicated in the offer message. It does not apply to the same products sold by other sellers. For example, if the promotion applies to cookware offered by Amazon.com, the same cookware offered by other sellers on the Amazon.com website (e.g. Macy’s, etc.) do not qualify.
Unless the promotion indicates otherwise, it applies to the lowest priced qualifying item and may not be combined with other offers.
All qualifying and Best Value items must be purchased in one order and shipped to a single address.
If you return any of the promotion items, we will subtract your Best Value discount from your return credit.
Shipping and handling charges apply to all products, including Best Value items.
Add-on Items require a minimum purchase. See amazon.com/addon for details.
This promotion may not be combined with other offers, including promotional certificates.
Does not apply to orders placed with 1-Click.
Offer good while supplies last.
Void where prohibited.
If you remove any of the promotion items from your Shopping Cart or violate any of the Terms and Conditions, the promotion will be invalid, and the discount will not apply." />
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div style={{ marginTop: "20px" }} className="propotionSelect">
                            <span>查看“管理促销”页面</span>
                            <span className="selectBtn">
                                <Link to="/selectPromotion">查看</Link>

                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Coupons