import withProps from "@a-components/withProps";
import { Form as AntForm } from "antd";

const ErrorList = withProps((props) => {
  return <AntForm.ErrorList {...props}/>
}, {
  className: "a-form-error-list",
});

export default ErrorList;
