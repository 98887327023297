import React, { useState } from 'react'
import './index.css'
import BrandRegisteredHeader from "./module/BrandRegisteredHeader"
import BrandRegisteredFooter from "./module/BrandRegisteredFooter/BrandRegisteredFooter"
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Divider } from 'antd';
import { Link } from 'react-router-dom'

function BrandRegistered() {
  const [isBranch, setIsBranch] = useState(false);
  return (
    <div className="BrandRegistered" style={{ background: isBranch ? "#fff" : 'rgb(231,231,231)' }} >
      {
        isBranch ? (
          <div className="BrandRegistered_Tips" >
            <h2>注册品牌</h2>
            <div className="BrandRegistered_Tips_body" >
              <strong>在您开始之前，请查看在申请流程中需要提供哪些信息</strong>
              <p><span className="BrandRegistered_Tips_spot" ></span>商标申请号</p>
              <p><span className="BrandRegistered_Tips_spot" ></span>清晰地显示商品和/或包装上的品牌的图片</p>
              <p><span className="BrandRegistered_Tips_spot" ></span>如果您与亚马逊之间存在供应商关系，则需要提供供应商代码</p>
              <p><span className="BrandRegistered_Tips_spot" ></span>显示您在亚马逊上的品牌商品的 ASIN 示例列表</p>
              <p><span className="BrandRegistered_Tips_spot" ></span>有关您分销商品所在国家/地区的信息</p>
            </div>
            <div className="BrandRegistered_Tips_butn" >
              <Button>管理您的申请</Button>
              <Button><Link to="BrandRegisteredForm" >注册您的品牌</Link></Button>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <BrandRegisteredHeader />
            <div className="BrandRegistered_body" >
              <div className="BrandRegistered_your_branch" >
                <h3>你的品牌</h3>
                <div>
                  <span>注册品牌</span>
                  <span>1 <ExclamationCircleOutlined /></span>
                </div>
                <Divider />
                <Link onClick={() => { setIsBranch(true) }} to="#" >注册新品牌</Link>
              </div>
              <div className="BrandRegistered_use_branch" >
                <h3>欢迎使用品牌注册</h3>
                <div className="BrandRegistered_use_item" >
                  <div>想要管理对您品牌的品牌独家销售权益的访问？<span style={{ color: "rgb(196,85,0)" }} >新功能！</span></div>
                  <div>现在，您可以通过 <Link to="#" >“可获得销售权益”</Link> 页面管理与您的品牌关联的销售账户对这些权益的访问。</div>
                </div>
                <div className="BrandRegistered_use_item" >
                  <div>想要管理获得您品牌授权的人员？<span style={{ color: "rgb(196,85,0)" }} >新功能！</span></div>
                  <div>请访问 <Link to="#" >用户权限</Link> 页面，查看、编辑和撤销用户对您品牌的访问权限。此外，您还可以授予和撤销管理权限。</div>
                </div>
                <div className="BrandRegistered_use_item" >
                  <div>想要举报潜在的侵权行为问题？</div>
                  <div>使用 <Link to="#" >举报违规行</Link> 为工具搜索和举报可能侵犯您版权或注册商标的物品。请访问 <Link to="#" >“举报违规行为” 帮助页面</Link> 了解更多有关提交完整、准确举报的信息。</div>
                </div>
                <div className="BrandRegistered_use_item" >
                  <div>正在查找您的违规举报的状态？<span style={{ color: "rgb(196,85,0)" }} >新功能！</span></div>
                  <div>现在，您可以在 <Link to="#" >提交历史记录</Link> 页面上查看您的违规举报及其状态的历史记录。</div>
                </div>
                <div className="BrandRegistered_use_item" >
                  <div>需要帮助？</div>
                  <div>我们的 <Link to="#" >品牌注册客服人员</Link> 随时在此协助您。联系我们为了能够协助保护您在亚马逊上的品牌。<Link to="#" >了解更多</Link></div>
                </div>
                <div className="BrandRegistered_use_item" >
                  <div>有关于现有申请的问题？</div>
                  <div>如果您对现有的申请状态或先前申请的决定有任何疑问，请在 <Link to="#" >问题日志</Link> 中回复该案例。</div>
                </div>
              </div>
            </div>

          </React.Fragment>
        )
      }
      <BrandRegisteredFooter />
    </div>
  )
}

export default BrandRegistered