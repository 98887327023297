import React from 'react'
import './ManageApp.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'


function ManageApp(){
    return(
        <div>
            <Header/>
            <div>
            <div className="ManageApp_headBox">
                <div className="ManageApp_head_font">Appstore</div>
                <div className="ManageApp_head_fontII">管理您的应用</div>
                <div className="ManageApp_head_fontIII">管理对您卖家数据的访问权限</div>
                <div className="ManageApp_head_btn"><button>为新开发者授权</button></div>
            </div>
            <div className="ManageApp_contentBox">
                <div className="ManageApp_type">
                    <div className="ManageApp_type_Id">
                        <div>开发者名称</div>
                        <div>开发者编号</div>
                    </div>
                    <div className="ManageApp_type_name">
                        <div>应用名称</div>
                    </div>
                    <div className="ManageApp_type_state">
                        <div>状态</div>
                    </div>
                    <div className="ManageApp_type_date">
                        <div>授权日期</div>
                        <div>有效期</div>
                    </div>
                    <div className="ManageApp_type_MWS">
                        <div>MWS 授权令牌</div>
                    </div>
                    <div className="ManageApp_type_handle">
                        <div>操作</div>
                    </div>
                </div>

        <div className="ManageApp_type">
            <div className="ManageApp_content_Id">
                <div>4kmiles</div>
                <div>442608768848</div>
            </div>
            <div className="ManageApp_content_name"> 
                <div>（完全MWS访问权）</div>
            </div>
            <div className="ManageApp_content_state">
                <div>已禁用</div>
            </div>
            <div className="ManageApp_content_date">
                <div>2019/3/21 下午6:41</div>
            </div>
            <div className="ManageApp_content_MWS"></div>
            <div className="ManageApp_content_handle"> 
                <div><button>启用</button></div>
            </div>
        </div>
            


        </div>


            </div>
            <Footer/>
        </div>
    )
}

export default ManageApp