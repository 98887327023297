import np from "number-precision";

const VALUE = Symbol("value");

class NP {
  [VALUE] = 0;
  constructor(value) {
    this[VALUE] = value;
  }
  valueOf() {
    return this[VALUE];
  }

  plus(...nums) {
    const value = np.plus(this[VALUE], ...nums);
    this[VALUE] = value;
    return this;
  }
  minus(...nums) {
    const value = np.minus(this[VALUE], ...nums);
    this[VALUE] = value;
    return this;
  }
  times(...nums) {
    const value = np.times(this[VALUE], ...nums);
    this[VALUE] = value;
    return this;
  }
  divide(...nums) {
    const value = np.divide(this[VALUE], ...nums);
    this[VALUE] = value;
    return this;
  }
  round(ratio) {
    const value = np.round(this[VALUE], ratio);
    this[VALUE] = value;
    return this;
  }
}

export default function NumberPrecision(value) {
  return new NP(value);
}
