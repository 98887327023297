import Apis from "@api/apis";

const columns = [
  {
    title: "买家搜索词",
    dataIndex: "searchTerms",
    key: "2",
  },
  {
    title: "展示次数",
    dataIndex: "exposure",
    key: "3",
  },
  {
    title: "点击次数",
    dataIndex: "clicksNum",
    key: "7",
  },
  {
    title: "点击率(CTR)",
    dataIndex: "clicksRate",
    key: "8",
  },
  {
    title: "花费",
    dataIndex: "costPrice",
    key: "9",
  },
  {
    title: "每次点击费用(CPC)",
    dataIndex: "singleCostPrice",
    key: "10",
  },
  {
    title: "订单",
    dataIndex: "orderNumber",
    key: "11",
  },
  {
    title: "销售额",
    dataIndex: "salesPrice",
    key: "12",
  },
  {
    title: "ACOS",
    dataIndex: "acosRate",
    key: "13",
  },
];
const filterConditionOptions = [
  {
    label: "筛选条件",
    value: "筛选条件",
  },
  {
    label: "活跃状态",
    value: "活跃状态",
  },
  {
    label: "类型",
    value: "类型",
  },
  {
    label: "投放",
    value: "投放",
  },
  {
    label: "预算",
    value: "预算",
  },
  {
    label: "曝光量",
    value: "曝光量",
  },
  {
    label: "点击次数",
    value: "点击次数",
  },
  {
    label: "点击率（CTR）",
    value: "点击率（CTR）",
  },
  {
    label: "花费",
    value: "花费",
  },
  {
    label: "每次点击费用(CPC)",
    value: "每次点击费用(CPC)",
  },
  {
    label: "订单",
    value: "订单",
  },
  {
    label: "销售额",
    value: "销售额",
  },
  {
    label: "广告投入产出比(ACOS)",
    value: "广告投入产出比(ACOS)",
  },
  {
    label: "广告支出回报(ROAS)",
    value: "广告支出回报(ROAS)",
  },
  {
    label: "新买家订单数",
    value: "新买家订单数",
  },
  {
    label: "新买家订单比",
    value: "新买家订单比",
  },
  {
    label: "新买家销量",
    value: "新买家销量",
  },
  {
    label: "新买家销量比",
    value: "新买家销量比",
  },
  {
    label: "几乎或超出预算",
    value: "几乎或超出预算",
  },
  {
    label: "广告组合",
    value: "广告组合",
  },
];
const adGroupPutConfig = {
  searchInput: {
    placeholder: "查找搜索词",
  },
  tableColumns: columns,
  tableDataKey: 'searchTerms',
  tableConfig: { scroll: { x: 1000 } },
  filterConditionOptions,
  queryFetcher({ searchValue, businessId, ...otherParams }) {
    return Apis.advertising.getAdvertisingGroupSearchTerms({
      ...otherParams,
      searchTerms: searchValue,
      advertisingGroupId: businessId,
    });
  },

  $dependencies: [],
};

export default adGroupPutConfig;
