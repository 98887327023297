import React, { Component } from 'react'
import AJAX from "api/apis";
import './planning.css'
import '../default.css/default.css'
import IconFont from 'components/Icon/Icon'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import { Link } from 'react-router-dom'
import { Radio, Checkbox, Divider, Select, Input, Form, Pagination } from 'antd';
const { Option } = Select;

class Planning extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			chooseList: [
				// { id: 1, state: '禁止显示', img: '', number: "BT-MXAX-4XUK", condition: "新品", name: "Unique Peach Blossom and Panda Bookmark with Lovely Chinese Tassel Specially Good Daily Gifts for Kids Women Student", ASIN: "B08XBDBKTT", last: "02/23/2021 23:34:47", StatusUp: "02/23/2021 23:35:11", FNSKU: "X002TDF8Y5" },
				// { id: 2, state: '禁止显示', img: '', number: "BT-MXAX-4XUK", condition: "新品", name: "Unique Peach Blossom and Panda Bookmark with Lovely Chinese Tassel Specially Good Daily Gifts for Kids Women Student", ASIN: "B08XBDBKTT", last: "02/23/2021 23:34:47", StatusUp: "02/23/2021 23:35:11", FNSKU: "X002TDF8Y5" },

			],
			chooseCheck: 0,
			Allchoose: false,
			reverseCheck: false,
			isAuto: false,
			statusValue: null,
			typeValue: 0,
			keyWord: null,
			page: 1,
			size: 10,
			total: 10,
		}
	}
	componentDidMount() {
		this.getAllGoodsList(this.state.typeValue, this.state.statusValue, this.state.keyWord)
	}
	getAllGoodsList(goodsDispatchingType = 0, goodsStatus = null, goodsNameGoodsSkuGoodsAsinGoodsIsbn = null, page = this.state.page, size = this.state.size) {
		AJAX.addProduct.getAllGoodsList({ page, size, goodsDispatchingType, goodsStatus, goodsNameGoodsSkuGoodsAsinGoodsIsbn }).then((res) => {
			if (res.code == 1 && res.data) {
				let datas = res.data.list
				for (let items of datas) {
					items.plainOptions = [];
					items.plainOptions.push(items.goodsId.toString())
				}
				this.setState({
					chooseList: datas,
					page: res.data.pageNum,
					total: res.data.total
				})
			}
		})
	}
	Allchoose(e) {
		if (this.state.Allchoose) {
			this.setState({
				chooseCheck: 0,
				Allchoose: false,
				isAuto: false,
			})
		} else {
			this.setState({
				Allchoose: true,
				chooseCheck: this.state.chooseList.length,
				isAuto: false,
			});
		}
	}
	checkAllChoose(ItemChecked) {
		if (this.state.isAuto = true) this.state.isAuto = false;
		ItemChecked ? this.state.chooseCheck++ : this.state.chooseCheck--;
		if (this.state.chooseCheck == this.state.chooseList.length) {
			this.setState({
				Allchoose: true,
				isAuto: true
			})
		} else {
			if (this.state.Allchoose == true) {
				this.setState({
					Allchoose: false,
					isAuto: true
				});
			}
		}
	}
	Reversechoose(e) {
		let newNum = this.state.chooseList.length - this.state.chooseCheck;
		if (newNum == this.state.chooseList.length) {
			this.setState({
				Allchoose: true,
				isAuto: true
			})
		} else {
			this.setState({
				Allchoose: false,
				isAuto: true
			})
		}
		this.setState({
			reverseCheck: !this.state.reverseCheck,
			chooseCheck: this.state.chooseList.length - this.state.chooseCheck
		});
	}
	selectChonge = (e) => {
		if (e != '...') {
			this.props.history.push(e)
		}
	}
	statusChonge = (e) => {
		let { keyWord, typeValue } = this.state
		if (e.target.value == 0) {
			this.getAllGoodsList(typeValue, null, keyWord)
		} else {
			this.getAllGoodsList(typeValue, e.target.value, keyWord)
		}
		this.setState({
			statusValue: e.target.value
		})
	}
	inputChange = (e) => {
		let { typeValue, statusValue } = this.state
		this.getAllGoodsList(typeValue, statusValue, e.target.value)
		this.setState({
			keyWord: e.target.value
		})
	}
	getFormRef = el => {
		this.form = el;
	}
	pageChonge = (page, size) => {
		let { typeValue, statusValue, keyWord } = this.state
		this.setState({
			page: page,
			size: size
		})
		this.getAllGoodsList(typeValue, statusValue, keyWord, page, size)
	}
	ShowSizeChange = (page, size) => {
		let { typeValue, statusValue, keyWord } = this.state
		this.setState({
			page: page,
			size: size
		})
		this.getAllGoodsList(typeValue, statusValue, keyWord, page, size)

	}

	render() {
		let { statusValue, page, total } = this.state
		return (
			<div>
				<Header />
				<div className="kc30_header-nav">
					<ul>
						<li><Link to="/inventory">所有库存</Link></li>
						<li><Link to="/NoActivity">不活动(135)</Link></li>
						<li><Link to="/repair">修复无在售的亚马逊库存(2)</Link></li>
						<li><Link to="/RepairPriceReport">修复价格警告(1)</Link></li>
						<li><Link>品牌健康度(0)</Link></li>
						<li><Link>禁止显示</Link></li>
						<li><Link to="/ManagePricing">管理定价</Link></li>
						<li><Link>亚马逊库存</Link></li>
						<li><Link>货件处理进度</Link></li>
						<li><Link to="/repertory">库存控制面板</Link></li>
						<li>
							<Link to="/Improve">商品信息质量控制面板</Link>
							<span style={{ color: "#E3995A", marginLeft: "5px" }}>
								新功能
					</span>
						</li>
					</ul>
				</div>

				<div className="kc30_myBody">
					<div className="kc30_m-header">
						<div className="kc30_m-left">
							<h2>亚马逊库存</h2>
							<ul>
								<li>了解更多信息</li>
								<li>浏览</li>
							</ul>
						</div>
						<div className="kc30_m-right">
							<Link to="/addlisting"><button type="button">添加新商品</button></Link>
							<button type="button">首选项：隐藏2列</button>
						</div>
					</div>
					<div className="kc30_search">
						<select>
							<option placeholder="">应用于0件选定商品</option>
						</select>
						<div className="kc30_SKUinp">
							<input type="text" onChange={this.inputChange} placeholder="搜索SKU,标题,ISBN" />
							<button type="button">搜索</button>
							<span>
								{this.state.chooseList.length}&nbsp;件商品
							</span>
						</div>
					</div>
					<div className="kc30_content">
						<div className="kc30_c-header">
							<p>筛选条件:</p>
							<div className="kc30_c-header_radio">
								<Radio.Group onChange={this.statusChonge} defaultValue={0} value={statusValue}>
									<Radio value={0}>所有</Radio>
									<Radio value={1}>在售</Radio>
									<Radio value={2}>不可售</Radio>
									<Radio value={3}>不完整</Radio>
									<Radio value={4}>商品信息已删除</Radio>
								</Radio.Group>
							</div>
							<select>
								<option placeholder="">其他筛选条件</option>
							</select>
						</div>
						<div className="kc30_c-con">
							<table className="kc30_table" border="" cellSpacing="0" cellPadding="0">
								<thead>
									<tr className="kc30_c-tr">
										<th className="kc30_th1"><input type="checkbox" value='全选' onChange="" onClick={(e) => { this.Allchoose(e) }} checked={this.state.Allchoose} /></th>
										<th className="kc30_th2">
											<p className="kc30_blueFont">状态</p>
										</th>
										<th className="kc30_th3">
											<p>图片</p>
										</th>
										<th>
											<p className="kc30_blueFont">SKU</p>
											<p>状况</p>
										</th>
										<th>
											<p className="kc30_blueFont">商品名称</p>
											<p>ASIN</p>
										</th>
										<th className="kc30_th4">
											<p className="kc30_th4_p">创建日期</p> <IconFont style={{ transform: "rotate(90deg)", color: "#E47A16" }} type="icon-fanhui-copy-copy" />
											<p>状况更新日期</p>
										</th>
										<th className="kc30_th5">
											<p>FNSKU</p>
										</th>
										<th className="kc30_th6">
											<p className="kc30_blueFont kc30_th6_p">价格</p>
											<p>+配送费</p>
										</th>
										<th className="kc30_th7">
											<p>企业商品价格</p>
											<p>+配送费</p>
										</th>
										<th className="kc30_th2">
											<p className="kc30_blueFont">入库数量</p>
										</th>
										<th className="kc30_th2">
											<p className="kc30_blueFont">可售</p>
										</th>
										<th className="kc30_th8">
											<p className="kc30_blueFont">不可售数量</p>
										</th>
										<th className="kc30_th2">
											<p className="kc30_blueFont">预留数量</p>
										</th>
										<th className="kc30_th9">
											<p className="kc30_th6">每件售出商品的预计费用</p>
										</th>
										<th className="kc30_th10">
											<button className="kc30_c-btn" type="button">保存所有</button>
										</th>
									</tr>
								</thead>
								<tbody>
									{
										this.state.chooseList.map((item, id) => {
											return (

												<tr className="kc30_border-b" key={id}>
													<td><CheckboxItemcomponent value={item} checkAllChoose={(ItemChecked) => { this.checkAllChoose(ItemChecked) }} Allchoose={this.state.Allchoose} Reverse={this.state.reverseCheck} isAuto={this.state.isAuto} /></td>
													<td className="kc30_blueFont">
														{item.state}
													</td>
													<td className="tableImg">
														<img src={item.imgUrl}></img>
													</td>
													<td>
														<p className="kc30_blueFont">{item.goodsSku}</p>
														<p>{item.condition}</p>
													</td>
													<td className="kc30_th11">
														<p className="kc30_blueFont">{item.goodsName}</p>
														<p> {item.goodsAsin}</p>
													</td>
													<td>
														<p>{item.createTime}</p>
														<p className="font-gray">{item.updateTime}</p>
													</td>
													<td className="kc30_th5">
														<p>{item.FNSKU}</p>
													</td>
													<td className="kc30_align-r">
														<input className="kc30_inp kc30_th10" type="text" placeholder="18.99" />
														<p>+--</p>
													</td>
													<td className="kc30_align-r">
														<input className="kc30_inp kc30_th10" type="text" placeholder="" />
														<p>+--</p>
														<div style={{ display: "flex", alignItems: "center" }}><span className="colorBlue">添加数量折扣</span> <IconFont style={{ transform: "rotate(90deg)" }} type="icon-fanhui-copy-copy" /> </div>

													</td>
													<td className="kc30_align-r">
														<p>0</p>
													</td>
													<td className="kc30_align-r">
														<p>0</p>
													</td>
													<td className="kc30_align-r">
														<p>0</p>
													</td>
													<td className="kc30_align-r">
														<p>0</p>
													</td>
													<td className="kc30_align-r blueFont">
														<p>$17.54包括$13.62亚马逊物流费用 <IconFont style={{ transform: "rotate(90deg)" }} type="icon-fanhui-copy-copy" /></p>

													</td>
													<td>
														<Select defaultValue="编辑" style={{ width: 120 }} onChange={this.selectChonge}>
															<Option value={`/createListing?goodsId=${item.goodsId}`}>编辑</Option>
															<Option value="...">停售商品</Option>
															<Option value="...">管理图片</Option>
															<Option value="...">复制到新的商品</Option>
															<Option value="...">添加其他状况</Option>
															<Option value={`/fbaInbound?goodsId=${item.goodsId}`}>转换为"亚马逊配送"</Option>
															<Option value="...">匹配最低价</Option>
															<Option value="...">删除商品和报价</Option>
															<Option value="...">合并复制商品</Option>
														</Select>
													</td>
												</tr>
											)
										})
									}
								</tbody>
							</table>
						</div>
					</div>
					<Pagination
						current={page}
						total={total}
						showSizeChanger
						showQuickJumper
						className="pageBox"
						onChange={this.pageChonge}
						onShowSizeChange={this.ShowSizeChange}
					/>
				</div>


				<Footer />






			</div>
		)


	}
}
class CheckboxItemcomponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: false,
		}
	}
	_clickHandle(e) {
		this.props.checkAllChoose(!this.state.checked);
		this.setState({
			checked: this.state.checked ? false : true
		});
	}
	componentWillReceiveProps(nextprops) {
		if (nextprops.isAuto == false) this.state.checked = nextprops.Allchoose;
		if (nextprops.Reverse != this.props.Reverse) {
			this.setState({
				checked: !this.state.checked
			})
		}
	}
	render() {
		return (
			<input type="checkbox" value={this.props.value} checked={this.props.Allchoose ? this.props.Allchoose : this.state.checked} onClick={(e) => { this._clickHandle(e) }} />
		)
	}
}
export default Planning