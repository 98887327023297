import InformationHead from '../informationHead/informationHead'
import React from 'react'
import './informationSelect.css'
import IconFont from 'components/Icon/Icon'
import {Link} from 'react-router-dom'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
function informationSelect(){

 function ClickBack(){
    window.history.back(-1);
}

  return(
    <div className="A_information_select" >
      <Header />
      <h2>A+ 商品描述管理器</h2>
      <div className="A_infor_select_body" >
        <h3>A+ 商品描述选择</h3>
        <div className="A_infor_select_inner" >
          <p>选择要创建的A+内容类型</p>
          <strong>增强型商品描述</strong>
          <div className="A_select_basie" >
            <div>基础</div>
            <div> <span className="A_select_rounddot" ></span> 最多创建五个增强内容模块以突出显示产品的功能</div>
            <button><Link to="/information" >创建基础</Link></button>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default informationSelect