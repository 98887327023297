import React, { useState } from 'react'
import './MengkGenerator.css'
import AdvertisementLeft from '@view/advertisement/module/advertisementLeft/advertisementLeft'
import MengkHeader from '../MengkHeader/MengkHeader'
import MengkSiderLeft from '../MengkSiderLeft/MengkSiderLeft'
import MengkSiderRight from '../MengkSiderRight/MengkSiderRight'
import MengkSiderContent from '../MengkSiderContent/MengkSiderContent'
import { Layout } from 'antd';

const { Header } = Layout;

function MengkGenerator() {
  const [tabsNum, setTabsNum] = useState(1);
  const next = () => {
    setTabsNum(tabsNum + 1)
  }
  const pre = () => {
    setTabsNum(tabsNum - 1)
  }
  return (
    <Layout className="MengkGenerator" style={{ paddingTop: 40 }} >
      <AdvertisementLeft />
      <Layout style={{ marginLeft: "2%" }} >
        <Header>
          <MengkHeader value={true} />
          <h3>Mengk</h3>
          <div className="MengkGenerator-tabs" >
            <div>亚马逊品牌旗舰店</div>
            <div style={{ background: "#232f3e" }} >品牌旗舰店生成器</div>
            <div>帮助</div>
          </div>
        </Header>
        {/* <Layout>
          <Sider>
            <MengkSiderLeft />
          </Sider>
          <Content>Content</Content>
          <Sider>Sider</Sider>
        </Layout> */}
        <div className="MengkGenerator-body" >
          <div className="MengkGenerator-body-left" style={{ width: tabsNum == 2 ? "200px" : "360px" }} ><MengkSiderLeft tabsNum={tabsNum} next={next} pre={pre} /></div>
          <div className="MengkGenerator-body-content" style={{ left: tabsNum == 2 ? "200px" : "360px" }} ><MengkSiderContent /></div>
          <div className="MengkGenerator-body-right" ><MengkSiderRight /></div>
        </div>
      </Layout>
    </Layout>

  )
}

export default MengkGenerator