import React from 'react'
import './Unshipped.css'
import { Radio, DatePicker, Space, Select } from 'antd';
import IconFont from "../../../../components/Icon/Icon";
import { Link } from 'react-router-dom'
const { RangePicker } = DatePicker;
const { Option } = Select;


function Unshipped() {
    const [value, setValue] = React.useState(1);
    const onChange = e => {
        setValue(e.target.value);
    };
    let toPath = (e) => {
        if (e != '...') window.location = e;
    }

    return (
        <div className="UnshippedBox">
            <div className="Unshipped_navBox">
                <div className="ausste_left">
                    <div className="ausste_leftP">
                        <div className="ausste_left_head">
                            <div className="ausste_left_headNav">
                                <p>细化依据：</p>
                            </div>
                            <div className="ausste_left_dateTitel bolds">商品属性：</div>
                        </div>

                        <div className="ausste_left_datenav">
                            <div>批次数目</div>
                            <div>商品尺寸</div>
                        </div>

                        <div className="ausste_left_middle">
                            <div className="ausste_left_titel bolds">向我显示存在以下问题的草稿：</div>
                            <div>
                                <ul>
                                    <li>缺失信息</li>
                                    <li>无效信息</li>
                                    <li>匹配冲突</li>
                                </ul>
                            </div>
                        </div>

                        <div className="ausste_left_bottom">
                            <div className="ausste_left_bottomI bolds">修改日期</div>
                            <div className="ausste_left_bottomII">
                                <RangePicker renderExtraFooter={() => 'extra footer'} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ausste_right">
                    <div
                        className="shopupDL-warnBox"
                        style={{ border: "1px solid #3479ac", padding: "10px 0" }}
                    >
                        <div className="shopupDL-rBox-warnHed">
                            <IconFont
                                type="icon-jinggao1"
                                className="RepertoryTable1-box1-font"
                                style={{ color: "#3489ac", margin: "0 20px" }}
                            />
                            <div>欢迎使用草稿体验！</div>
                        </div>
                        <div className="shopupDL-rBox-warnnav">
                            <div >
                                在提交时不符合有效数据要求的商品信息现在会存储为草稿。您可以通过“补全您的草稿”页面查看和增量式处理这些商品信息。您可以为您的草稿（一次一个或批量）添加缺失的数据或更正无效数据。点击<a style={{ color: " #0066c0" }}>“了解更多信息”</a>可了解详细信息。
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <div>
                            <div className="ausste_right_headI">
                                <div className="order_headR_inp">
                                    <input placeholder="搜索" />
                                    <button>搜索</button>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "420px", textAlign: 'right' }}>
                            <div className="ausste_right_headII">
                                <div className="ausste_right_headII_midButton ausste_right_headII_select">
                                    <label>
                                        <Select placeholder="对0件选定商品执行批量操作">
                                            <Option>生成模板</Option>
                                            <Option>重新提交</Option>
                                        </Select>
                                    </label>
                                </div>
                                <div className="ausste_right_headII_midButton">
                                    <button>添加新商品</button>
                                </div>

                                <div className="ausste_right_headII_endButton addAll">
                                    <button>保存所有</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <input type="checkbox" />
                                </th>
                                <th>批次编号</th>
                                <th>修改日期</th>
                                <th>图片</th>
                                <th>商品名称</th>
                                <th>SKU</th>
                                <th>需要修复的问题</th>
                                <th>操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <input type="checkbox" />
                                </td>
                                <td>11116b2d82f3-0a27-4141-8fce-251d43670dd3</td>
                                <td>04/06/2021 05:41:34</td>
                                <td>
                                    <img src={require("@/assets/img/home/card-3.jpg").default} />
                                </td>
                                <td>
                                    <p className="col102">Syntus Makeup Brush Set 16 Makeup Brushes & 4Blender Sponges & 1 Cleaning Pad Premium Synthetic Foundation Powder Kabuki Blush Concealer Eye ShadowMakeup Brush Kit, Black Golden</p>
                                </td>
                                <td>asdad</td>
                                <td></td>
                                <td className="operation">
                                    <Select defaultValue="编辑草稿" onChange={toPath}>
                                        <Option value="/createListing">编辑草稿</Option>
                                        <Option value="...">删除商品和报价</Option>
                                        <Option value="...">重新提交草稿</Option>
                                    </Select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default Unshipped