import React from 'react'
import './informationHead.css'
import IconFont from 'components/Icon/Icon'
import { Link } from 'react-router-dom'
function informationHead({ disable = false }) {
  return (
    <div>
      <div className="Details_titel">
        <h2>商品描述详情</h2>
      </div>

      <div className="Details_Steps">
        <div className="Details_StepsBox">
          <div className="Details_StepsImg"></div>
          <div className="Details_StepsFont">
            <div style={{ width: "18%" }}>创建内容</div>
            <div style={{ width: "28%" }}>
              <div>应用亚马逊商品编码</div>
              <div style={{ padding: "0 0 0 38px" }}>(ASIN)</div>
            </div>
            <div>已提交</div>
            <div>已核准</div>
            <div>
              <div>已发布<IconFont type="icon-tishi21" className="Details_Font_tishi" /></div>
              <div>在12/12/2020</div>
            </div>
          </div>
        </div>
        <div className="informationHead_Steps_li">
          <div className="informationHead_Steps_liI">
            <div className="informationHead_Steps_divI">商品描述名称</div>
            <div className="informationHead_Steps_divII">语言</div>
            <div style={{ width: "16%" }}>商品描述类型</div>
            <div style={{ width: "14%" }}>状态</div>
            <div style={{ width: "18%" }}>已应用的ASIN</div>
            <div >提交日期</div>
          </div>
          <div className="informationHead_Steps_liII">
            <div className="informationHead_head_inp informationHead_Steps_divI">
              {
                !disable ? <input /> : <div>mzi</div>
              }
            </div>
            <div className="informationHead_Steps_divII">
              <label className="informationHead_head_label ">
                {
                  !disable ? (
                    <select>
                      <option>美国 英语</option>
                      <option>美国 西班牙语</option>
                      <option>（以色列）希伯来语</option>
                    </select>
                  ) : <div>美国 英语</div>
                }

              </label>
            </div>
            <div style={{ width: "16%" }}>EBC</div>
            <div style={{ width: "14%" }}>已批准</div>
            <div style={{ width: "18%" }}>1</div>
            <div>12/7/2020</div>
          </div>
        </div>

      </div>
    </div>

  )
}

export default informationHead