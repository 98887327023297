
import React from 'react'
import './BrandDashbOard.css'
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'



function BrandDashbOard(){
    return(
        <div>
            <Header/>
                <div>


                <div className="end15_content">
			<div className="end15_sidebar">
				<ul>
					<li>品牌主页</li>
					<li>品牌健康度</li>
					<li>搜索词优化器</li>
					<li>品牌福利</li>
					<li>买家评论</li>
				</ul>
				<div className="end15_chakanv">
					<p>Viewing</p>
					<p>MengK</p>
					<p>查看品牌和关系</p>
				</div>
				<div className="end15_chakanv2">
					<p>品牌保护</p>
					<p>有 0 个待处理的违规报告</p>
					<p>有 1 个未解决的品牌支持问题</p>
					<p>在亚马逊品牌注册中查看</p>
				</div>
				<div className="end15_chakanv2">
					<p>编辑通知设置</p>
				</div>
			</div>
			<div className="end15_maijia">
				<p className="end15_pinglun">品牌控制面板<span>为帮助品牌所有者成长而创建的工具、程序和服务</span> <span className="end15_gengd">了解更多信息</span><span
					 className="end15_zhongxian">|</span><span className="end15_gengd">为此页评级</span></p>
				<div className="end15_qingzy">
					<svg t="1610713529994" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14683"
					 width="20" height="15">
						<path d="M512 93.090909c232.727273 0 418.909091 186.181818 418.909091 418.909091s-186.181818 418.909091-418.909091 418.909091-418.909091-186.181818-418.909091-418.909091 186.181818-418.909091 418.909091-418.909091m0-93.090909C228.072727 0 0 228.072727 0 512s228.072727 512 512 512 512-228.072727 512-512S795.927273 0 512 0z"
						 fill="#2c2c2c" p-id="14684"></path>
						<path d="M512 279.272727m-46.545455 0a46.545455 46.545455 0 1 0 93.09091 0 46.545455 46.545455 0 1 0-93.09091 0Z"
						 fill="#2c2c2c" p-id="14685"></path>
						<path d="M512 791.272727c-27.927273 0-46.545455-18.618182-46.545455-46.545454V418.909091c0-23.272727 18.618182-46.545455 46.545455-46.545455 23.272727 0 46.545455 18.618182 46.545455 46.545455v325.818182c0 27.927273-18.618182 46.545455-46.545455 46.545454z"
						 fill="#2c2c2c" p-id="14686"></path>
					</svg>
				</div>
				<div className="end15_jiankang">
					<p className="end15_pinglun end15_pinglun2">品牌健康度<span>性能与标准</span> <span className="end15_gengd">了解更多信息</span></p>
					<ul>
						<li>
							<div className="end15_litit">
								价格竞争力<svg t="1610713529994" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
								 p-id="14683" width="20" height="15">
									<path d="M512 93.090909c232.727273 0 418.909091 186.181818 418.909091 418.909091s-186.181818 418.909091-418.909091 418.909091-418.909091-186.181818-418.909091-418.909091 186.181818-418.909091 418.909091-418.909091m0-93.090909C228.072727 0 0 228.072727 0 512s228.072727 512 512 512 512-228.072727 512-512S795.927273 0 512 0z"
									 fill="#2c2c2c" p-id="14684"></path>
									<path d="M512 279.272727m-46.545455 0a46.545455 46.545455 0 1 0 93.09091 0 46.545455 46.545455 0 1 0-93.09091 0Z"
									 fill="#2c2c2c" p-id="14685"></path>
									<path d="M512 791.272727c-27.927273 0-46.545455-18.618182-46.545455-46.545454V418.909091c0-23.272727 18.618182-46.545455 46.545455-46.545455 23.272727 0 46.545455 18.618182 46.545455 46.545455v325.818182c0 27.927273-18.618182 46.545455-46.545455 46.545454z"
									 fill="#2c2c2c" p-id="14686"></path>
								</svg>
							</div>
							<p className="end15_litit_p1">we didn't find any prices for your products on other retailers.</p>
							<p className="end15_litit_p2">查看商品定价</p>
						</li>
						<li>
							<div className="end15_litit">
								亚马逊 Prime参与资格<svg t="1610713529994" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
								 p-id="14683" width="20" height="15">
									<path d="M512 93.090909c232.727273 0 418.909091 186.181818 418.909091 418.909091s-186.181818 418.909091-418.909091 418.909091-418.909091-186.181818-418.909091-418.909091 186.181818-418.909091 418.909091-418.909091m0-93.090909C228.072727 0 0 228.072727 0 512s228.072727 512 512 512 512-228.072727 512-512S795.927273 0 512 0z"
									 fill="#2c2c2c" p-id="14684"></path>
									<path d="M512 279.272727m-46.545455 0a46.545455 46.545455 0 1 0 93.09091 0 46.545455 46.545455 0 1 0-93.09091 0Z"
									 fill="#2c2c2c" p-id="14685"></path>
									<path d="M512 791.272727c-27.927273 0-46.545455-18.618182-46.545455-46.545454V418.909091c0-23.272727 18.618182-46.545455 46.545455-46.545455 23.272727 0 46.545455 18.618182 46.545455 46.545455v325.818182c0 27.927273-18.618182 46.545455-46.545455 46.545454z"
									 fill="#2c2c2c" p-id="14686"></path>
								</svg>
							</div>
							<p className="end15_litit_p1">在亚马逊物流或卖家自配送Prime中注册商品，使其符合Prime资格。</p>
							<p className="end15_litit_p2">您的所有商品都符合亚马逊Prime的要求。</p>
						</li>
						<li>
							<div className="end15_litit">
								有存货率<svg t="1610713529994" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
								 p-id="14683" width="20" height="15">
									<path d="M512 93.090909c232.727273 0 418.909091 186.181818 418.909091 418.909091s-186.181818 418.909091-418.909091 418.909091-418.909091-186.181818-418.909091-418.909091 186.181818-418.909091 418.909091-418.909091m0-93.090909C228.072727 0 0 228.072727 0 512s228.072727 512 512 512 512-228.072727 512-512S795.927273 0 512 0z"
									 fill="#2c2c2c" p-id="14684"></path>
									<path d="M512 279.272727m-46.545455 0a46.545455 46.545455 0 1 0 93.09091 0 46.545455 46.545455 0 1 0-93.09091 0Z"
									 fill="#2c2c2c" p-id="14685"></path>
									<path d="M512 791.272727c-27.927273 0-46.545455-18.618182-46.545455-46.545454V418.909091c0-23.272727 18.618182-46.545455 46.545455-46.545455 23.272727 0 46.545455 18.618182 46.545455 46.545455v325.818182c0 27.927273-18.618182 46.545455-46.545455 46.545454z"
									 fill="#2c2c2c" p-id="14686"></path>
								</svg>
							</div>
							<p className="end15_litit_p1">保持商品有货。</p>
							<p className="end15_litit_p2">一个问题</p>
						</li>
					</ul>
					<p className="end15_xjb">Review our <span>Standards for Brands Selling in the Amazon Store</span> policy to learn more
						about our standards for brands.</p>
				</div>
				<div className="end15_jiankang">
					<p className="end15_pinglun end15_pinglun2">品牌优势<span>专注于增长业务的品牌工具</span></p>
					<ul className="end15_jkul2">
						<li>
							<div className="end15_jkul2_p1">买家评论</div>
							<p className="end15_jkul2_p2">管理和回复买家评论，包括关键评论。</p>
							<p className="end15_jkul2_p3">管理评论</p>
						</li>
						<li>
							<div className="end15_jkul2_p1">品牌分析</div>
							<p className="end15_jkul2_p2">了解有关客户的更多信息，包括搜索词、人口统计信息等。</p>
							<p className="end15_jkul2_p3">查看报告</p>
						</li>
						<li>
							<div className="end15_jkul2_p1">管理您的试验</div>
							<p className="end15_jkul2_p2">为符合条件的商品创建和管理A/B测试。</p>
							<p className="end15_jkul2_p3">管理试验</p>
						</li>
						<li>
							<div className="end15_jkul2_p1">搜索词优化器</div>
							<p className="end15_jkul2_p2">优化您的搜索词以获取影响力较高的商品。</p>
							<p className="end15_jkul2_p3">优化搜索词</p>
						</li>
						<li>
							<div className="end15_jkul2_p1">虚拟捆绑商品</div>
							<p className="end15_jkul2_p2">使用亚马逊物流库存创建和销售补充商品的捆绑商品。</p>
							<p className="end15_jkul2_p3">添加捆绑商品</p>
						</li>
						<li>
							<div className="end15_jkul2_p1">关于品牌优势的更多信息</div>
							<p className="end15_jkul2_p2">作为品牌所有者，您可以享受超过十几个品牌专享优惠。</p>
							<p className="end15_jkul2_p3">查看所有品牌优势</p>
						</li>
					</ul>
					<div className="end15_xjb end15_xjb2">
						<p>更新时间为2021年1月16日</p>
						<p>系统每天都会计算并更新一次所有指标。这些更改需要48小时才会显示在此页面上，请耐心等待。</p>
					</div>
				</div>
			</div>
		</div>




                </div>
            <Footer/>
        </div>
    )
}

export default BrandDashbOard