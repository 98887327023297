export const DEFAULT_AMOUNT = 0.75;

export const deliveryGroupMapping = {
  1: {
    title: "紧密匹配",
    tooltip:
      "顾客使用与您的商品紧密相关的搜索词进行搜索时，我们会向其展示您的广告。如果您的商品是“Doppler 400-count Cotton Sheets”，我们将在顾客使用“cotton sheets”和“400-count sheets”等搜索词时展示广告。",
  },
  2: {
    title: "宽泛匹配",
    tooltip:
      "顾客使用与您的商品并不密切相关的搜索词进行搜索时，我们会向其展示您的广告。如果您的商品是“Doppler 400-count Cotton Sheets”，我们将在顾客使用“bed sheets”、“bath sheets”和“bath towels”等搜索词时展示广告。",
  },
  3: {
    title: "同类商品",
    tooltip:
      "买家查看与您的商品类似的商品的详情页面时，我们会向其展示您的广告。如果您的商品是“Doppler 400-count Cotton Sheets”，我们将在包含“300-count Cotton Sheets”和“Queen 400-count Sheets”的商品详情页上展示广告。",
  },
  4: {
    title: "关联商品",
    tooltip:
      "买家查看与您的商品类似的商品的详情页面时，我们会向其展示您的广告。如果您的商品是“Doppler 400-count Cotton Sheets”，我们将在包含“300-count Cotton Sheets”和“Queen 400-count Sheets”的商品详情页上展示广告。",
  },
};

export const orientationGroupBidding = [
  {
    deliveryGroupType: "1",
    status: true,
    biddingPrice: 0.75,
    suggestedBidding: 12,
    suggestedBiddingStart: 1,
    suggestedBiddingEnd: 3,
  },
  {
    deliveryGroupType: "2",
    status: true,
    biddingPrice: 0.75,
    suggestedBidding: 12,
    suggestedBiddingStart: 1,
    suggestedBiddingEnd: 3,
  },
  {
    deliveryGroupType: "3",
    status: true,
    biddingPrice: 0.75,
    suggestedBidding: 12,
    suggestedBiddingStart: 1,
    suggestedBiddingEnd: 3,
  },
  {
    deliveryGroupType: "4",
    status: true,
    biddingPrice: 0.75,
    suggestedBidding: 12,
    suggestedBiddingStart: 1,
    suggestedBiddingEnd: 3,
  },
];

export const RECOMMEND_AMOUNT = 1.29;

export const autoDeliveryInitValues = {
  orientationGroupBidding,
  defaultBidding: DEFAULT_AMOUNT,
  autoDeliveryType: 1,
};
