import {
  useState,
  Fragment,
  useCallback,
  createElement,
  useEffect,
} from "react";
import AdvertisingPageLayout from "../AdvertisingPageLayout";
import { Space } from "antd";
import { ASwitch, AText } from "@a-components/";
import classNames from "classnames";
import style from "./style.module.css";
import Apis from "@api/apis";
import useQuery from "@hooks/useQuery";

import Advertising from "./Advertising";
import Put from "./Put";
import PutNegative from "./PutNegative";
import SearchWords from "./SearchWords";
import AdGroupSetting from "./AdGroupSetting";
import OperaHistory from "./OperaHistory";

const menuList = [
  {
    title: "广告",
    type: "advertising",
  },
  {
    title: "投放",
    type: "put",
  },
  {
    title: "否定投放",
    type: "putNegative",
  },
  {
    title: "搜索词",
    type: "searchWords",
  },
  {
    title: "广告组设置",
    type: "adGroupSetting",
  },
  {
    title: "历史",
    type: "adGroupHistory",
  },
];

const AdGroupDetail = () => {
  const [activeMenu, setActiveMenu] = useState("advertising");
  const [adGroupInfo, setAdGroupInfo] = useState({});

  const { advertisingGroupId } = useQuery();

  const getAdvertisingGroupDetailHead = useCallback(() => {
    return Apis.advertising
      .getAdvertisingGroupDetailHead({
        advertisingGroupId,
      })
      .then((res) => {
        if (res.code !== 1) return res;

        setAdGroupInfo(res.data);
      });
  }, [advertisingGroupId]);

  useEffect(() => {
    getAdvertisingGroupDetailHead();
  }, [getAdvertisingGroupDetailHead]);

  const renderMainContent = useCallback(() => {
    const mapping = {
      advertising: Advertising,
      put: Put,
      putNegative: PutNegative,
      searchWords: SearchWords,
      adGroupSetting: AdGroupSetting,
      adGroupHistory: OperaHistory,
    };

    const cur = mapping[activeMenu];

    return createElement(cur, {
      advertisingGroupId,
      onAdvertisingGroupUpdated: getAdvertisingGroupDetailHead,
    });
  }, [activeMenu, advertisingGroupId, getAdvertisingGroupDetailHead]);

  const {
    statusName,
    advertisingGroupName,
    advertisingGroupStatus,
  } = adGroupInfo;
  return (
    <AdvertisingPageLayout>
      <div className="df fd-c pl-24 pt-24 pb-24 bg-f8 w-100percent box-shadow-cd p-r">
        <div className="fs-32 ">广告组:{advertisingGroupName}</div>
        <div className="df ai-c mt-12">
          <ASwitch
            size="small"
            checked={advertisingGroupStatus === 1}
          ></ASwitch>
          <AText className="ml-6">状态: {statusName}</AText>
        </div>
      </div>

      <div>
        <div className="df">
          <div className="bg-f8 p-12" style={{ minWidth: 220 }}>
            {menuList.map((item) => {
              return (
                <div
                  className={classNames(
                    style.menuItem,
                    "df ai-c ph-12 border-radius-4",
                    {
                      active: activeMenu === item.type,
                    }
                  )}
                  key={item.type}
                  onClick={() => setActiveMenu(item.type)}
                >
                  {item.title}
                </div>
              );
            })}
          </div>
          <div className="f-1 p-12">{renderMainContent()}</div>
        </div>
      </div>
    </AdvertisingPageLayout>
  );
};

export default AdGroupDetail;
