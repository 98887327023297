import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from "components/home_header";
import Footer from "components/addlisting_footer/FooterCom";
import Notice from "./module/Notice/Notice"
import "./index.css"
import { Tabs, Table, Space } from 'antd';
const { TabPane } = Tabs;

function NotificationOptions() {
  const columns = [
    {
      title: '卖家默认联系人',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '',
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle" style={{ color: "rgb(0,102,192" }} >
          编辑
        </Space>
      ),
    },
  ];

  const data = [
    {
      key: '1',
      name: '电子邮件',
      age: 32,
      address: 'Corn_0708@163.com',
    },
    {
      key: '2',
      name: '电话号码',
      age: 42,
      address: '',
    },
  ];
  return (
    <div className="notification_options" >
      <Header />
      <Tabs defaultActiveKey="1">
        <TabPane tab="通知选项" key="1">
          <Notice />
        </TabPane>
        <TabPane tab="联系人" key="2" style={{ padding: 20 }} >
          <Table columns={columns} dataSource={data} />
        </TabPane>
      </Tabs>
      <Footer />
    </div>
  )
}

export default NotificationOptions