import React, { useRef, useEffect, useCallback, useState } from 'react';
import './index.less'
import { Button, Modal } from 'antd';
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import RegisterSteps from 'components/registerSteps';
import { regSubmit } from 'utils/PublicLibrary';


export default (props) => {
  const [value, onChange] = useState(false);


  let nextEvent = useCallback(() => {
    regSubmit([], null, '', 14).then(res => {
      if (res.code == 1) {
        props.history.push('/twoStepVerification/Enable')
      }
    })

  })


  return (
    <div className='CompleteVerification'>
      <Header></Header>
      <RegisterSteps current={6}></RegisterSteps>
      <div style={{ width: '66%', margin: '0 auto 120px' }}>
        <div style={{ width: '39%', margin: '0 auto' }}>
          <div className='vi-divider'></div>
          <div className='a-alert'>
            <i className="a-icon a-icon-alert"></i>
            <div className='a-alert-content'>
              我们验证您的信用卡时，您可以开始浏览“卖家平台” 北美
            </div>
          </div>
          <h6 className='a-text-bold'>开始销售</h6>
          <div>关联至商城</div>
          <hr style={{ marginTop: '20px' }}></hr>
          <Button onClick={nextEvent} type="primary" className='next'>转至北美商城</Button>
          <hr></hr>
          <Button disabled={true} className='next'>转至欧洲商城</Button>
          <hr></hr>
          <Button disabled={true} className='next'>转至日本商城</Button>
          <hr></hr>
          <Button disabled={true} className='next'>转到澳大利亚商城</Button>
          <hr></hr>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}