import { Typography } from "antd";
import { renderTooltip } from "@a-components/viewFragment";
import withProps from "@a-components/withProps";
import './style.css'

const Text = withProps(
  ({ tooltip, children, ...otherProps }) => {
    return (
      <Typography.Text {...otherProps}>
        {children}
        {renderTooltip(tooltip)}
      </Typography.Text>
    );
  },
  {
    className: "a-text",
  }
);

export default Text;
