import React from 'react'
import './ASIN.css'
import IconFont from 'components/Icon/Icon'

function ASIN(){
    return(
        <div className="ASIN_body">
            <div className="ASIN_head"><h2>已应用的 ASIN(1)</h2></div>
            <div className="ASIN_box">
                <div className="ASIN_inpBox">
                <div className="ASIN_inp">
                    <IconFont className="ASIN_inp_font" type="icon-sousuo"/>
                    <input placeholder="查找" />
                </div>
                </div>
                <div className="ASIN_inpBox_head">
                    <div className="ASIN_inp_title">
                    <div>商品名称</div>
                    <div>应用日期</div>
                    <div>ASIN状态</div>
                    </div>
                    <div style={{marginLeft:"8%"}}>ASIN</div>
                </div>
                <div className="ASIN_list">
                    <div className="ASIN_listNav">
                    <div className="ASIN_listNav_img">图片</div>
                    <div className="ASIN_listNav_font">
                        <p>MengK Steam Sauna Box Black, 2 Liters Steam Engine with Remote Control, Relaxing Time for One Person Full Body Sauna in Environmental Protection Room (Black Cloth Cover, Blue Edging)</p>
                        <p>B08PVSS9B1</p>
                        <div>父ASIN</div>
                    </div>
                    <div>12/12/2020</div>
                    <div>已发布</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ASIN