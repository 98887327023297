import { useEffect, useRef } from "react";

const useKeep = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
};

export default useKeep;
