import { Tabs as AntTabs } from "antd";
import withProps from "@a-components/withProps";
import "./style.css";

const Tabs = (props) => {
  return <AntTabs {...props} />;
};

const TabsWithProps = withProps(Tabs, {
  className: "a-tabs",
});

TabsWithProps.TabPane = AntTabs.TabPane;

export default TabsWithProps;
