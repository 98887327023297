import React, { useState } from 'react'
import './contactBuyer.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import Reactours from "components/reactours"
import TipsIcon from "components/tipsIcon"
import '../default.css/default.css'
import { Link } from 'react-router-dom';
import { Button, Collapse, Steps, Radio, Input, Divider, Checkbox } from 'antd';

const { TextArea } = Input;
function Early() {
    let isTourOpen = true;
    let steps1 = [
        {
            selector: '.contactBuyerBox .stepGuidanceBox1',
            content: '选择买家原因',
        },
        {
            selector: '.contactBuyerBox .stepGuidanceBox2',
            content: '输入联系买家内容，用英文',
        },
        {
            selector: '.contactBuyerBox .newsBox',
            content: '无需填写内容，亚马逊自动生成',
        },
        {
            selector: '.contactBuyerBox .addfile',
            content: '可添加附件',
        }
    ]
    const [isActive, setIsActive] = useState(false);
    const awakenTips = (type) => {
        setIsActive(type);
    }
    return (
        <div className="contactBuyerBox">
            <Header />
            <div className="contactBuyerItem">
                <p className="fz24">联系买家</p>
                <div className="flex0 col102 m10 fz14">
                    <Link to="/ManageOrders">
                        <span>《 返回管理订单</span>
                    </Link>
                    <span>帮助</span>
                </div>
                <div className="stepGuidanceBox1">
                    <p className="fz20 bolds mb0">向Sara发送消息</p>
                    <p className="fz16 bolds">1.选择联系原因</p>
                    <div className="radioBox">
                        <div className="flexs">
                            <input type="radio" />
                            <div>
                                <p>确认订单详情</p>
                                <p>请在发货之前询问客户特定的订请在发货之前询问客户特定的订</p>
                            </div>
                        </div>
                        <div className="flexs">
                            <input type="radio" />
                            <div>
                                <p>协调大件或重型商品的配送</p>
                                <p>联系您的买家以安排订单配送或确认完成配送所需的详细联系方式</p>
                            </div>
                        </div>
                        <div className="flexs">
                            <input type="radio" />
                            <div>
                                <p>发送发票</p>
                                <p>向买家发送订单的发票。</p>
                            </div>
                        </div>
                        <div className="flexs">
                            <input type="radio" />
                            <div>
                                <p>礼貌的退款</p>
                                <p>提供全额礼貌的退款或换货商品。</p>
                            </div>
                        </div>
                        <div className="flexs">
                            <input type="radio" />
                            <div>
                                <p>通知配送订单存在问题</p>
                                <p>通知买家在配送其订单时出现意外问题。</p>
                            </div>
                        </div>
                        <div className="flexs">
                            <input type="radio" />
                            <div>
                                <p>其他</p>
                                <p>请您的客户澄清其他联系原因未涵盖的主题。</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="stepGuidanceBox2">
                    <p className="fz16 bolds mt20">2.完整消息</p>
                    <p>买家的首选语言：英文</p>
                    <p className="fz16 bolds">在下面的框中键入您的消息。我们会将其转发至买家。请不要在您的消息中包含 HTML 或链接 (URL)。如果您需要咨询某个特定订单或商品相关的问题，请在邮件中包含订单号，商品名称和/或 ASIN/ISBN。</p>
                    <TextArea placeholder="字符数上限: 4000个。仅允许使用与完成订单相关的链接，不得使用HTML或电子邮件地址。" showCount maxLength={4000} />
                </div>
                <div className="addfile">
                    <button>添加附件</button>
                    <span>您可以上传文本文件、PDF、Word、及jpg/.gif/.png图片。 附件大小必须小于10 MB。.</span>
                </div>
                <p className="fz16 bolds mt20">3.查看消息</p>
                <div className="selectInfo">
                    <div className="flex0">
                        <div>
                            <div>
                                <span className="bolds">收件人：</span>
                                <span>Sara</span>
                            </div>
                            <div>
                                <span className="bolds">主题：</span>
                                <span>亚马逊订单111-9917323-6738608的发票</span>
                            </div>
                            <div>
                                <span className="bolds">订单编号: </span>
                                <span>111-9917323-6738608</span>
                            </div>
                            <div>
                                <span className="bolds">商品: </span>
                                <span>20 LED Photo Clips Star String Lights, MengK 8.2ft Battery Po...</span>
                            </div>
                        </div>
                        <img src={require("@/assets/img/home/logosss.png").default} />
                    </div>
                    <p>尊敬的Sara:</p>
                    <p>感谢您的惠顾。亚马逊卖家Big Corn正在针对您最近的订单向您发送附加发票文档。</p>
                    <div className="newsBox">
                        <span>来自卖家 Big Corn 的消息：请问可以开发票吗？</span>
                    </div>
                    <p>通过回复此电子邮件，您可以向卖家Big Corn发送匿名消息，该消息不会显示您的真实电子邮件地址。</p>
                    <p>如果卖家以不当方式联系您，请举报此消息。</p>
                    <p>我们希望能很快与您再次相见。</p>
                </div>
                <p className="col102">重要通知</p>
                <div className="caozuoBtn">
                    <span>
                        <Link to="/ManageOrders">取消</Link>
                    </span>
                    <span>
                        <Link to="/ManageOrders">发送</Link>
                    </span>
                </div>
            </div>
            <Footer />
            {/* 引导步骤 */}
            <TipsIcon clicks={awakenTips} />
            <Reactours steps={steps1} isActive={isActive} clicks={awakenTips} routePath="contactBuyer" isOpen={isTourOpen} />
        </div>
    )
}


export default Early