import { useState } from "react";
import classNames from "classnames";
import { UpOutlined } from "@ant-design/icons";

import style from "./style.module.css";

const CardCollapsible = ({ title, children, className }) => {
  const [visible, setVisible] = useState(true);

  return (
    <div className={classNames("border-ccc", className)}>
      <div
        onClick={() => setVisible(!visible)}
        className={classNames(
          style.header,
          "card-collapsible__header p-12 df jc-sb ai-c",
          {
            "border-b-ccc": visible,
          }
        )}
      >
        <span className="fs-18">{title}</span>

        <UpOutlined
          className={classNames(style.closeIcon, { [style.close]: visible })}
        />
      </div>

      {visible ? (
        <div
          className={classNames(
            style.content,
            "card-collapsible__content p-12"
          )}
        >
          {children}
        </div>
      ) : null}
    </div>
  );
};

export default CardCollapsible;
