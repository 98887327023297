import React from 'react'
import './AdvertisingReports.css'
import '../default.css/default.css'
import {Link} from 'react-router-dom'
import AdvertisementLeft from '../advertisement/module/advertisementLeft/advertisementLeft'
import IconFont from 'components/Icon/Icon'
function AdvertisingReports(){

    return(
        <div>
            





            <div className="gg01_container">
		


			<AdvertisementLeft/>




			<div className="gg01_main">
				<div className="gg01_header">
					<div className="gg01_header_bg">报告</div>
					<div className="gg01_header_gj">
						<select className="gg01_header_gj_s">
							<option>Bule Rose_USA<span>美国</span></option>
						</select>
						<svg t="1610538951259" className="icon gg01_icn1" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
						 p-id="10591" width="200" height="200">
							<path d="M512 256c117.76 0 213.504 95.744 213.504 213.504v149.504c0 13.824 4.608 27.136 12.8 38.4l51.2 68.096H234.496l51.2-68.096c8.192-11.264 12.8-24.576 12.8-38.4V469.504C298.496 351.744 394.24 256 512 256m0-64c-153.088 0-277.504 124.416-277.504 277.504v149.504l-64 85.504v42.496c0 23.552 19.456 42.496 42.496 42.496h597.504c23.552 0 42.496-19.456 42.496-42.496v-42.496l-64-85.504V469.504c0.512-153.6-123.904-277.504-276.992-277.504z m0 661.504c-35.328 0-64-28.672-64-64H384c0 70.656 57.344 128 128 128s128-57.344 128-128h-64c0 35.328-28.672 64-64 64z"
							 fill="#FFFFFF" p-id="10592"></path>
							<path d="M512 256c-23.552 0.512-43.008-17.92-43.52-41.472s17.92-43.008 41.472-43.52h2.048c23.552 0.512 41.984 19.968 41.472 43.52-0.512 22.528-18.432 40.96-41.472 41.472h97.792c6.144-13.312 9.216-28.16 9.216-42.496 0-58.88-47.616-106.496-106.496-106.496s-106.496 47.616-106.496 106.496c0 15.36 3.072 29.696 9.216 42.496H512z"
							 fill="#FFFFFF" p-id="10593"></path>
						</svg>
						<svg t="1610538671947" className="icon gg01_icn2" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
						 p-id="3317" width="200" height="200">
							<path d="M512 230.4c155.392 0 281.6 126.208 281.6 281.6s-126.208 281.6-281.6 281.6S230.4 667.392 230.4 512 356.608 230.4 512 230.4m0-51.2C328.192 179.2 179.2 328.192 179.2 512s148.992 332.8 332.8 332.8 332.8-148.992 332.8-332.8S695.808 179.2 512 179.2z"
							 fill="#D3D3D3" p-id="3318"></path>
							<path d="M603.648 336.896c20.992 19.2 31.488 45.568 31.488 79.36 0 24.832-6.912 46.848-20.736 65.792-5.376 6.4-20.736 20.736-46.08 43.264-11.776 9.728-20.224 19.968-25.856 30.464-7.168 12.032-10.752 25.856-10.752 41.728v13.056h-45.568v-13.056c0-18.688 3.584-35.072 10.752-48.896 6.656-16.128 25.6-37.888 56.832-65.28 7.424-7.424 13.824-14.336 19.2-20.224 11.008-14.336 16.384-29.184 16.384-44.544 0-22.528-6.144-39.936-18.688-52.48-13.056-13.056-31.744-19.712-55.808-19.712-27.648 0-48.64 9.216-62.464 27.648-12.032 16.128-17.92 37.632-17.92 64.768h-45.056c0-38.912 11.008-70.656 33.28-94.72 23.296-24.832 54.784-37.12 94.72-37.12 36.352 0 65.024 9.984 86.272 29.952z m-70.4 322.56c6.4 6.4 9.472 14.336 9.472 23.552 0 9.728-3.328 17.92-9.472 24.32-7.168 6.4-15.104 9.472-24.32 9.472-9.728 0-17.92-3.328-24.32-9.472-6.656-6.656-10.24-14.848-10.24-24.32 0-9.728 3.328-17.664 10.24-23.552 5.888-6.4 14.08-9.472 24.32-9.472 9.728 0 17.92 3.328 24.32 9.472z"
							 fill="#D3D3D3" p-id="3319"></path>
						</svg>
						<svg t="1610539027110" className="icon gg01_icn3" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
						 p-id="14414" width="200" height="200">
							<path d="M512 1024C229.225412 1024 0 794.774588 0 512S229.225412 0 512 0s512 229.225412 512 512-229.225412 512-512 512zM512 20.078431C240.318745 20.078431 20.078431 240.318745 20.078431 512s220.240314 491.921569 491.921569 491.921569 491.921569-220.240314 491.921569-491.921569S783.681255 20.078431 512 20.078431z m261.019608 793.09804a30.117647 30.117647 0 0 1-30.117647-30.117647c0-68.838902-30.157804-130.600157-77.944471-172.905412l2.017883-2.278902A30.017255 30.017255 0 0 1 652.54902 582.27451a30.07749 30.07749 0 1 1 57.514666-12.378353C767.257098 623.053804 803.137255 698.819765 803.137255 783.058824a30.117647 30.117647 0 0 1-30.117647 30.117647zM512 552.156863c-127.528157 0-230.901961 103.373804-230.901961 230.901961a30.117647 30.117647 0 1 1-60.235294 0c0-119.858196 72.44298-222.760157 175.917177-267.404549C345.088 479.332392 311.215686 419.358118 311.215686 351.372549c0-110.893176 89.891137-200.784314 200.784314-200.784314s200.784314 89.891137 200.784314 200.784314-89.891137 200.784314-200.784314 200.784314z m0-341.333334c-77.623216 0-140.54902 62.925804-140.54902 140.54902s62.925804 140.54902 140.54902 140.54902 140.54902-62.925804 140.54902-140.54902-62.925804-140.54902-140.54902-140.54902z m-20.078431 411.607844a20.078431 20.078431 0 1 1 40.146823-0.01004A20.078431 20.078431 0 0 1 491.921569 622.431373z m-0.953726 43.068235c2.640314-6.344784 5.89302-11.103373 9.406745-14.506667 3.584 0.993882 7.499294 1.556078 11.625412 1.556079 4.838902 0 9.356549-0.833255 13.412392-2.188549 3.543843 3.272784 6.796549 7.961098 9.436863 14.285804l41.200941 98.93647c3.955451 9.497098-1.616314 22.90949-11.153569 30.127686l-43.921568 33.249883a15.088941 15.088941 0 0 1-17.859765-0.140549l-42.696784-33.350275c-9.115608-7.107765-14.315922-20.239059-10.440785-29.545412l40.990118-98.42447z"
							 fill="#ffffff" p-id="14415"></path>
						</svg>
					</div>
				</div>
				<div className="gg01_search">
					<div>
					 <Link to="/CreateReport"><button className="gg01_search_b">创建报告</button></Link>
					<input className="gg01_search_i" type="text" placeholder="按报告名称搜索报告" />
					</div>
					<div className="gg01_search_b1Box">
					<button className="gg01_search_b1">列</button>
					<div  className="gg01_search_b1II">
					<div><input type="checkbox" />上次运行时间</div>
					<div><input type="checkbox" />广告活动类型</div>
					<div><input type="checkbox" />报告类型</div>
					<div><input type="checkbox" />报告期</div>
					<div><input type="checkbox" />时间单位</div>
					</div>
					</div>
				</div>
				<div className="gg01_tablebox">
					<table className="gg01_tablebox-t">
						<tr>
							<td>状态</td>
							<td>上次运行时间</td>
							<td>下载</td>
							<td>下次运行时间</td>
							<td>报告名称</td>
							<td>广告活动类型</td>
							<td>报告类型</td>
							<td>报告期</td>
							<td>时间单位</td>
							<td>操作</td>
						</tr>
						<tr>
							<td>
								<p>完成</p>
								<p>一次</p>
							</td>
							<td>
								<p>Sep 17,2020</p>
								<p>1:57 am POT</p>
							</td>
							<td><svg t="1610539467912" className="icon gg01_icn4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
								 p-id="23192" width="200" height="200"/>
									<path d="M746 819.5c0-9.344-3.414-17.43-10.242-24.258C728.93 788.414 720.844 785 711.5 785s-17.43 3.414-24.258 10.242C680.414 802.07 677 810.156 677 819.5s3.414 17.43 10.242 24.258C694.07 850.586 702.156 854 711.5 854s17.43-3.414 24.258-10.242C742.586 836.93 746 828.844 746 819.5z m138 0c0-9.344-3.414-17.43-10.242-24.258C866.93 788.414 858.844 785 849.5 785s-17.43 3.414-24.258 10.242C818.414 802.07 815 810.156 815 819.5s3.414 17.43 10.242 24.258C832.07 850.586 840.156 854 849.5 854s17.43-3.414 24.258-10.242C880.586 836.93 884 828.844 884 819.5z m70-120.562v173.124c0 14.428-5.048 26.69-15.144 36.79C928.76 918.95 916.5 924 902.076 924H105.924c-14.423 0-26.683-5.05-36.779-15.148C59.048 898.752 54 886.49 54 872.062V698.938c0-14.428 5.048-26.69 15.144-36.79C79.24 652.05 91.5 647 105.924 647h251.501l73.017 73.578c20.914 20.198 45.433 30.297 73.558 30.297 28.125 0 52.644-10.099 73.558-30.297L651.115 647h250.962c14.423 0 26.683 5.05 36.779 15.148 10.096 10.1 15.144 22.362 15.144 36.79zM777.743 391.29c6.13 14.797 3.606 27.428-7.573 37.895l-242.329 242.53C521.351 678.57 513.236 682 503.5 682s-17.85-3.429-24.341-10.286l-242.33-242.53c-11.178-10.466-13.703-23.097-7.572-37.894 6.13-14.076 16.768-21.114 31.914-21.114h138.474V127.647c0-9.384 3.425-17.504 10.277-24.361C416.774 96.429 424.887 93 434.263 93h138.474c9.376 0 17.49 3.429 24.341 10.286 6.852 6.857 10.277 14.977 10.277 24.361v242.53H745.83c15.146 0 25.784 7.037 31.914 21.113z"
									 fill="#000000" p-id="23193"/>
							</td>
							<td>---</td>
							<td>商品推广 搜索 词报告</td>
							<td>商品推广</td>
							<td>搜索词</td>
							<td>最近30天</td>
							<td>一览</td>
							<td>
								<div className="gg01_caozuo">
									<div className="gg01_caozuo_btn">
										<button>操作 <IconFont type="icon-sanjiaoxing" /> </button>
									</div>
									<div className="gg01_win">
										<div><a>运行报告</a></div>
										<div><a>创建副本</a></div>
										<div className="gg01_delete"><a>删除</a></div>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<p>完成</p>
								<p>一次</p>
							</td>
							<td>
								<p>Sep 17,2020</p>
								<p>1:57 am POT</p>
							</td>
							<td><svg t="1610539467912" className="icon gg01_icn4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
								 p-id="23192" width="200" height="200"/>
									<path d="M746 819.5c0-9.344-3.414-17.43-10.242-24.258C728.93 788.414 720.844 785 711.5 785s-17.43 3.414-24.258 10.242C680.414 802.07 677 810.156 677 819.5s3.414 17.43 10.242 24.258C694.07 850.586 702.156 854 711.5 854s17.43-3.414 24.258-10.242C742.586 836.93 746 828.844 746 819.5z m138 0c0-9.344-3.414-17.43-10.242-24.258C866.93 788.414 858.844 785 849.5 785s-17.43 3.414-24.258 10.242C818.414 802.07 815 810.156 815 819.5s3.414 17.43 10.242 24.258C832.07 850.586 840.156 854 849.5 854s17.43-3.414 24.258-10.242C880.586 836.93 884 828.844 884 819.5z m70-120.562v173.124c0 14.428-5.048 26.69-15.144 36.79C928.76 918.95 916.5 924 902.076 924H105.924c-14.423 0-26.683-5.05-36.779-15.148C59.048 898.752 54 886.49 54 872.062V698.938c0-14.428 5.048-26.69 15.144-36.79C79.24 652.05 91.5 647 105.924 647h251.501l73.017 73.578c20.914 20.198 45.433 30.297 73.558 30.297 28.125 0 52.644-10.099 73.558-30.297L651.115 647h250.962c14.423 0 26.683 5.05 36.779 15.148 10.096 10.1 15.144 22.362 15.144 36.79zM777.743 391.29c6.13 14.797 3.606 27.428-7.573 37.895l-242.329 242.53C521.351 678.57 513.236 682 503.5 682s-17.85-3.429-24.341-10.286l-242.33-242.53c-11.178-10.466-13.703-23.097-7.572-37.894 6.13-14.076 16.768-21.114 31.914-21.114h138.474V127.647c0-9.384 3.425-17.504 10.277-24.361C416.774 96.429 424.887 93 434.263 93h138.474c9.376 0 17.49 3.429 24.341 10.286 6.852 6.857 10.277 14.977 10.277 24.361v242.53H745.83c15.146 0 25.784 7.037 31.914 21.113z"
									 fill="#000000" p-id="23193"/>
							</td>
							<td>---</td>
							<td>商品推广 搜索 词报告</td>
							<td>商品推广</td>
							<td>搜索词</td>
							<td>最近30天</td>
							<td>一览</td>
							<td>
							<div className="gg01_caozuo">
									<div className="gg01_caozuo_btn">
										<button>操作 <IconFont type="icon-sanjiaoxing" /> </button>
									</div>
									<div className="gg01_win">
										<div><a>运行报告</a></div>
										<div><a>创建副本</a></div>
										<div className="gg01_delete"><a>删除</a></div>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<p>完成</p>
								<p>一次</p>
								<div className="gg01_bnt">
									<div></div>
									<div></div>
								</div>
							</td>
							<td>
								<p>Sep 17,2020</p>
								<p>1:57 am POT</p>
							</td>
							<td><svg t="1610539467912" className="icon gg01_icn4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
								 p-id="23192" width="200" height="200">
									<path d="M746 819.5c0-9.344-3.414-17.43-10.242-24.258C728.93 788.414 720.844 785 711.5 785s-17.43 3.414-24.258 10.242C680.414 802.07 677 810.156 677 819.5s3.414 17.43 10.242 24.258C694.07 850.586 702.156 854 711.5 854s17.43-3.414 24.258-10.242C742.586 836.93 746 828.844 746 819.5z m138 0c0-9.344-3.414-17.43-10.242-24.258C866.93 788.414 858.844 785 849.5 785s-17.43 3.414-24.258 10.242C818.414 802.07 815 810.156 815 819.5s3.414 17.43 10.242 24.258C832.07 850.586 840.156 854 849.5 854s17.43-3.414 24.258-10.242C880.586 836.93 884 828.844 884 819.5z m70-120.562v173.124c0 14.428-5.048 26.69-15.144 36.79C928.76 918.95 916.5 924 902.076 924H105.924c-14.423 0-26.683-5.05-36.779-15.148C59.048 898.752 54 886.49 54 872.062V698.938c0-14.428 5.048-26.69 15.144-36.79C79.24 652.05 91.5 647 105.924 647h251.501l73.017 73.578c20.914 20.198 45.433 30.297 73.558 30.297 28.125 0 52.644-10.099 73.558-30.297L651.115 647h250.962c14.423 0 26.683 5.05 36.779 15.148 10.096 10.1 15.144 22.362 15.144 36.79zM777.743 391.29c6.13 14.797 3.606 27.428-7.573 37.895l-242.329 242.53C521.351 678.57 513.236 682 503.5 682s-17.85-3.429-24.341-10.286l-242.33-242.53c-11.178-10.466-13.703-23.097-7.572-37.894 6.13-14.076 16.768-21.114 31.914-21.114h138.474V127.647c0-9.384 3.425-17.504 10.277-24.361C416.774 96.429 424.887 93 434.263 93h138.474c9.376 0 17.49 3.429 24.341 10.286 6.852 6.857 10.277 14.977 10.277 24.361v242.53H745.83c15.146 0 25.784 7.037 31.914 21.113z"
									 fill="#000000" p-id="23193"></path>
								</svg></td>
							<td>
								<p>Dec 31,2020</p>
								<p>6:07 am PST</p>
							</td>
							<td>商品推广 搜索 词报告</td>
							<td>商品推广</td>
							<td>搜索词</td>
							<td>Macrh 1,2020 - March 31,2020</td>
							<td>一览</td>
							<td>
							<div className="gg01_caozuo">
									<div className="gg01_caozuo_btn">
										<button>操作 <IconFont type="icon-sanjiaoxing" /> </button>
									</div>
									<div className="gg01_win">
										<div><a>运行报告</a></div>
										<div><a>创建副本</a></div>
										<div className="gg01_delete"><a>删除</a></div>
									</div>
								</div>
							</td>
						</tr>
					</table>
				</div>
				<div className="gg01_footer">
					<div className="gg01_footer_a">
						<div className="gg01_footer_a_con">
							<span>转到页面</span>
							<input type="text" placeholder="1" />
							<button>◀</button><button>▶</button>
							<span>1-50/133条结果</span>
							<div className="gg01_footer_a_con_a">
								<span>每页结果数:</span>
								<select>
									<option>50</option>
									<option>100</option>
									<option>200</option>
									<option>300</option>
								</select>
							</div>
						</div>
					</div>
					<div className="gg01_footer_b"><img src={require("../../assets/images/gg01_logo.png").default} />某些产品和关键字不符合定位条件，因此不会展示广告。<span>进一步了解</span></div>
					<div className="gg01_footer_c">
						© 2020 Amazon.com,Inc.或附属公司。<span>使用条款</span><span>隐私声明</span><span>Amazon广告协议</span>
					</div>
				</div>
			</div>
		</div>


        </div>
    )
}
export default AdvertisingReports