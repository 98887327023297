import React from "react"
import AJAX from "api/apis";
import "./Tab4.css"

class Tab4 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productList: []
        }
    }
    componentWillMount() {
    }
    getFormRef = el => {
        this.form = el;
    }
    render() {
        return <div className="atAglancetab4Box">
            <div className="warningBox">
                <div>我们想要做好。</div>
                <p>此货件没有任何活动。在亚马逊运营中心扫描您的货件后，根据符合调查条件的日期，您的货件将符合调查条件。</p>
                <p className="blurs">了解更多信息</p>
            </div>
            <div>
                如果您对货件仍有疑问，请联系卖家支持。您可能需要提供其他支持文件。
            </div>
        </div>
    }
}

export default Tab4