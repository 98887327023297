import { Rate } from "antd";
import style from "./style.module.css";
import { ATitleTooltip } from "@a-components";
import { WarningOutlined } from "@ant-design/icons";
import GoodsStatus from "./GoodsStatus";
import classNames from "classnames";
import "./style.css";

const GoodsItem = ({ extra, className, info = {} }) => {
  const goodStatus = info.goodsInventoryCount === 0 ? "outStock" : "inStock";

  return (
    <div className={classNames(className, "goods-item df jc-sb")}>
      <div className="df" style={{ maxWidth: 310 }}>
        <img className={style.goodsImg} src={info.imgUrl} alt="" />
        <div className={style.goodsInfo}>
          <div className={classNames(style.goodsName, "text-ellipsis-2")}>
            {info.goodsName}
          </div>
          <div className={classNames(style.priceRow, "df ai-c")}>
            {info.goodsInventoryCount ? (
              <Rate className="fs-12" disabled defaultValue={2} />
            ) : null}

            <span className="c-primary">
              ${info.goodsMoney?.toFixed(2) ?? ""}
            </span>
            <span className={style.line}></span>
            <GoodsStatus type={goodStatus} />
          </div>
          <div className={classNames(style.codeRow, "c-999 text-ellipsis-1")}>
            ASIN:{info.goodsAsin} {info.goodsSku ? `| ${info.goodsSku}` : ""}
          </div>
        </div>
      </div>

      <div className="df fd-c ai-fe">
        <div style={{ marginBottom: 12 }}>
          {typeof extra === "function" ? extra(info) : extra}
        </div>

        {info.isCaveat ? (
          <ATitleTooltip
            title="广告可能已暂停"
            desc={[
              "商品必须可供购买。缺货是导致商品不可购买的常见原因。审核商品库存中的此信息。",
            ]}
          >
            <WarningOutlined style={{ fontSize: 16 }} />
          </ATitleTooltip>
        ) : null}
      </div>
    </div>
  );
};

export default GoodsItem;
