import React from "react";
import "./repertory.css";
import Header from 'components/home_header';
import Footer from '../../components/addlisting_footer/FooterCom'
import { Tabs } from "antd";
import RepertoryTable1 from './module/RepertoryTable1/RepertoryTable1'
import ReplenishStock from './module/replenishStock/index'
import Redundance from './module/redundance/redundance'
import RepertoryAge from './module/repertoryAge/repertoryAge'
import Repair from './module/repair/repair'
import Performance from './module/performance/performance'
import ReturnGoods from './module/returnGoods/index'
function Repertory() {
  const { TabPane } = Tabs;
  return (
    <div>
      <Header />
      <div >
        <div className="TabHeader repertoryTable"> 
            <Tabs defaultActiveKey="1" className="tab2">
              <TabPane tab="控制面板" key="1">
                <RepertoryTable1/>
              </TabPane>
              <TabPane tab="绩效" key="2"> 
              <Performance/>
              </TabPane>
              <TabPane tab="补充库存" key="3">
                <ReplenishStock/>
              </TabPane>
              <TabPane tab="管理冗余库存" key="4">
               <Redundance/>
              </TabPane>
              <TabPane tab="库龄" key="5">
                <RepertoryAge/>
              </TabPane>
              <TabPane tab="修复无在售信息的亚马逊库存" key="6">
                <Repair/>
              </TabPane>
              <TabPane tab="管理亚马逊物流退货" key="7">
              <ReturnGoods/>
              </TabPane>
            </Tabs>
        </div>
        </div>
        <Footer/>
    </div>
  );
}
export default Repertory;
