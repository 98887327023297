import { useCallback, useEffect, useRef, useState } from "react";
import { AText, AButton } from "@a-components/";
import classNames from "classnames";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import * as echarts from "echarts";
import moment from "moment";

import style from "./style.module.css";

const postfixMapping = {
  total: "总计",
  average: "平均",
};

const list = [
  {
    title: "花费",
    postfixType: "total",
    value: "$1.00",
    allowDelete: false,
  },
  {
    title: "销售额",
    postfixType: "total",
    value: "$1.00",
    allowDelete: true,
  },
  {
    title: "ACOS",
    postfixType: "average",
    value: "33.87%",
    allowDelete: true,
  },
  {
    title: "曝光量",
    postfixType: "total",
    value: "8,157,871",
    allowDelete: true,
  },
];

const getSomeDays = (daysCount = 15) => {
  const now = moment().startOf("month").add(1, "months");

  return Array(daysCount)
    .fill(null)
    .map((item) => {
      return now.add(-1, "months").format("YYYY/MM/DD");
    })
    .reverse();
};

const getSomeFakeNumber = (count = 15) => {
  return Array(count)
    .fill(null)
    .map(() => {
      return Math.floor(Math.random() * 1000);
    });
};

const createOptions = () => ({
  tooltip: {
    trigger: "axis",
  },
  legend: {
    data: ["花费", "销售额"],
  },
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    data: getSomeDays(),
  },
  yAxis: {
    type: "value",
  },
  series: [
    {
      name: "花费",
      type: "line",
      stack: "总量",
      data: getSomeFakeNumber(),
    },
    {
      name: "销售额",
      type: "line",
      stack: "总量",
      data: getSomeFakeNumber(),
    },
  ],
});

const StatisticsCard = ({ dataSource = list, type, className }) => {
  const [activeCard, setActiveCard] = useState([]);
  function resizeAll() {
    const chart = echarts.init(graphEl.current);
    chart && chart.resize(); //调用子组件resize方法
    // 如果有多个子组件，就挨个调用
  }
  const graphEl = useRef(null);

  const renderAddButton = useCallback(() => {
    return (
      <div className={classNames(style.addButton, "df jc-c ai-c fs-14")}>
        <PlusOutlined />
        添加指标
      </div>
    );
  }, []);
  useEffect(() => {
    window.addEventListener('resize', () => {
      if (graphEl.current) {
        const chart = echarts.init(graphEl.current);
        if (!graphEl.current) { // 如果切换到其他页面，这里获取不到对象，删除监听。否则会报错
          window.removeEventListener('resize', resizeAll);
          console.log('12')
          return;
        }
        // console.log(chart)
        chart && chart.resize();
      }

    });
  }, [type])

  useEffect(() => {
    const chart = echarts.init(graphEl.current);

    chart.setOption(createOptions());
  }, [type]);

  return (
    <div className={classNames(className)}>
      <div className={classNames(style.cardList)}>
        {dataSource.map((item) => {
          const { title, value, postfixType, allowDelete, tooltip } = item;

          const contentPostfix = postfixMapping[postfixType];

          return (
            <div
              key={item.title}
              className={classNames(style.cardItem, "p-12 df fd-c jc-sb")}
            >
              <AText tooltip={tooltip}>{title}</AText>
              <div>
                <AText className="fs-24" strong>
                  {value || "—"}
                </AText>
                <AText>{" " + contentPostfix}</AText>
              </div>

              {allowDelete ? (
                <CloseOutlined className={style.closeIcon} />
              ) : null}
            </div>
          );
        })}

        {renderAddButton()}
      </div>

      <div ref={graphEl} className={classNames(style.graph)}></div>
    </div>
  );
};

export default StatisticsCard;
