import React, { useRef, useEffect, useCallback, useState } from 'react';
import './index.less'
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Template from '../Template'


export default (props) => {
  const [dateList, setDateList] = useState([]);
  const [tAction, settAction] = useState('');
  const [bAction, setbAction] = useState('');


  const collapseList = [
    {
      header: '如果有需要，可以重新安排预约吗？',
      html: '<p>可以。您可以重新安排预约。</p>'
    },
    {
      header: '如果找不到合适的日期，该怎么办？',
      html: '<p>我们每天都会添加新的预约选项，请返回到此页面查找适合您的日期和时间。</p> '
    },
    {
      header: '我可以取消预约吗？',
      html: '<p>可以。您可以取消预约。但是，您必须先完成验证，才有资格在亚马逊上销售商品。</p>'
    },
    {
      header: '会面将持续多久？',
      html: '<p>我们预计会面会持续 30 分钟。</p>'
    },
  ]
  const times = [
    { name: '8 AM', disabled: true }, { name: '8:30 AM', disabled: true }, { name: '9 AM', disabled: false }, { name: '9:30 AM', disabled: false }, { name: '10 AM', disabled: false },
    { name: '10:30 AM', disabled: false }, { name: '11 AM', disabled: false }, { name: '11:30 AM', disabled: false }, { name: '12 PM', disabled: true }, { name: '12:30 PM', disabled: true },
    { name: '1 PM', disabled: false }, { name: '1:30 PM', disabled: false }, { name: '2 PM', disabled: false }, { name: '2:30 PM', disabled: false },
    { name: '3 PM', disabled: false }, { name: '3:30 PM', disabled: false }, { name: '4 PM', disabled: false }, { name: '4:30 PM', disabled: false },
    { name: '5 PM', disabled: false }, { name: '5:30 PM', disabled: true }, { name: '6 PM', disabled: true }, { name: '6:30 PM', disabled: true },
    { name: '7 PM', disabled: true }, { name: '7:30 PM', disabled: true },
  ]
  useEffect(() => {
    getDat()
  }, [])
  const getDat = () => {
    let days = []
    let today = new Date()
    // today = new Date(today.setDate(today + 1))
    let date2 = new Date(today)
    let ac = ''
    for (let i = 0; i < 7; i++) {
      date2.setDate(today.getDate() + (i + 1))
      let date = new Date(date2.setDate(today.getDate() + (i + 1)))
      days.push(date)
      if (!['Sat', 'Sun'].includes(date.toDateString().split(" ")[0]) && !ac) {
        ac = date.toDateString().split(" ")[2]
      }
    }
    settAction(ac)
    setDateList(days)
    // console.log(days)
  }
  let nextEvent = useCallback(() => {
    let str = ''
    let time = ''
    let date = ''
    dateList.map(item => {
      let day = item.toDateString().split(" ")
      if (day[2] == tAction) {
        let month = item.getMonth() + 1
        let num = bAction.split(" ")[0] + ':00'
        str = `${day[3]}-${month}-${day[2]} ${num}`
        date = new Date(str)
        if (bAction.includes('PM')) {
          date.setHours(date.getHours() + 12)
        }
        time = date.getTime()
      }

    })
    let obj = {
      date,
      time,
      bAction,
      tAction
    }
    let arr = [
      { "propertyKey": "authentication_videoconferencing_time", "propertyValue": JSON.stringify(obj) },
    ]
    return {
      arr: arr,
      localArr: null,
      whichPage: 'videoAuthentication/MeetingTime',
      step: 10
    }
  }, [dateList, tAction, bAction])

  return (
    <Template
      collapseList={collapseList}
      prePath="/completeValidation"
      nextPath="/videoAuthentication/meetingMatters"
      nextEvent={nextEvent}
      current={5}
      disabled={!bAction || !tAction}
      className='meetingTime'
    >
      <React.Fragment>
        <h3>身份验证</h3>
        <div className='vi-divider'></div>
        <h4>视频通话验证</h4>

        <p>请选择一个日期和时间以重新安排您的预约，然后单击“下一步”以确认您的预约。所有预约以<strong>Asia/Shanghai 时区</strong>的时间显示。请安排<strong>30分钟的会议时间</strong>。</p>
        <div className='vi-slot-sel-container'>
          <div className='common-cal'>
            <LeftOutlined />
            <div className='calendar-date-container'>
              {
                dateList.map(item => {
                  return (
                    <div key={item}
                      className={[
                        'item',
                        tAction == item.toDateString().split(" ")[2] ? 'tAction' : '',
                        ['Sat', 'Sun'].includes(item.toDateString().split(" ")[0]) ? 'disabled' : ''
                      ].join(' ')}>
                      <span>{item.toDateString().split(" ")[1]}</span>
                      <div onClick={() => {
                        !['Sat', 'Sun'].includes(item.toDateString().split(" ")[0]) && settAction(item.toDateString().split(" ")[2])
                      }}>
                        {item.toDateString().split(" ")[2]}
                        <p>{item.toDateString().split(" ")[0]}</p>
                      </div>
                    </div>
                  )
                })
              }
            </div>

            <RightOutlined />
          </div>
          <div className='common-calendar-container'>
            {
              times.map(item => {
                return <div className={[
                  'item',
                  bAction == item.name ? 'bAction' : '',
                  item.disabled ? 'disabled' : ''
                ].join(' ')}
                  key={item.name}
                  onClick={() => {
                    !item.disabled && setbAction(item.name)
                  }}
                >{item.name}</div>
              })
            }
          </div>
        </div>
      </React.Fragment>
    </Template>
  )
}