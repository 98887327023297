import React, { useState } from 'react';
import "./Notice.css"
import { Alert, Divider, Checkbox, Row, Col, Input, Button, Table } from 'antd';
import { UpOutlined } from '@ant-design/icons';

function Notice() {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '40%',
      render: (text, row, index) => {
        return (
          <div>
            <div>{text}</div>
            {
              row.tip && (<div style={{ fontSize: 12, color: "rgb(119,119,119)" }}  >{row.tip}</div>)
            }
          </div>
        )
      },
    },
    {
      title: 'type',
      dataIndex: 'type',
      width: '15%',
    },
    {
      title: 'content',
      dataIndex: 'content',
    },
  ];
  const list = [
    {
      title: "Brand Health Notificat ion Preferences",
      tip: "Receive notifications to inform you of your brand health.",
      key: 1,
      data: [
        {
          key: '1',
          name: '品牌健康通知',
          tip: '',
          type: '电子邮件:',
          content: "Corn_0708@163.com 该值来自您的卖家默认联系人"
        },
        {
          key: '2',
          name: '品牌健康通知',
          tip: '',
          type: '短信:',
          content: "要更改您的短信联系人信息，请转至“联系人”选项卡并编辑卖家默认联系人。"
        },
      ]
    },
    {
      title: "退货和索赔通知",
      tip: "接收有关退货请求和客户索赔的通知。",
      key: 2,
      data: [
        {
          key: '1',
          name: '待处理的退货',
          tip: '退货条款',
          type: '电子邮件:',
          content: "sales@ymall05.com"
        },
        {
          key: '2',
          name: '索赔通知',
          tip: '有关买家具有争议的费用通知。',
          type: '电子邮件:',
          content: "sale s@ymall05.com"
        },
        {
          key: '3',
          name: '退款通知',
          tip: '有关向买家发放退款的通知',
          type: '电子邮件:',
          content: "Corn_ 0708@1 63.com"
        },
      ]
    },
    {
      title: "商品通知",
      tip: "请选择在您的商品和库存发生变动时如何通知您。",
      key: 3,
      data: [
        {
          key: '1',
          name: '已创建“我的商品"',
          tip: '当我创建新商品信息时通知我',
          type: '电子邮件:',
          content: "Corn 0708@163.com i该值来自您的卖家默认联系人"
        },
        {
          key: '2',
          name: '已关闭“我的商品”',
          tip: '当您的商品不可售时将会通知您。不可售的原因可能包括:缺货、过期或其他原因。',
          type: '电子邮件:',
          content: "Corn 0708@163.com i该值来自您的卖家默认联系人"
        },
        {
          key: '3',
          name: '口我的商品有 合规性要求',
          tip: '当您的某件商品具有合规性要求时，您将收到通知',
          type: '电子邮件:',
          content: "Corn 0708@163.com i该值来自您的卖家默认联系人"
        },
      ]
    },
    {
      title: "报告",
      tip: "请选择在您的报告准备完毕时如何通知您。如果您不指定邮箱，我们将通知您的注册邮箱。",
      key: 4,
      data: [
        {
          key: '1',
          name: '捆绑商品销售报告"',
          tip: '捆绑商品销售报告包含虚拟捆绑商品ASIN的销售情况',
          type: '电子邮件:',
          content: "Corn 0708@163.com i该值来自您的卖家默认联系人"
        },
        {
          key: '2',
          name: '可售商品报告',
          tip: '可售商品报告包含在生成报告时可供购买的所有当前商品。',
          type: '电子邮件:',
          content: "Corn 0708@163.com i该值来自您的卖家默认联系人"
        },
        {
          key: '3',
          name: '订单履行报告',
          tip: '订单配送报告包含所有已售出、已付款的商品。',
          type: '电子邮件:',
          content: "Corn 0708@163.com i该值来自您的卖家默认联系人"
        },
        {
          key: '4',
          name: '已售出商品报告',
          tip: '已售商品报告包含通过亚马逊网站售出的所有商品。',
          type: '电子邮件:',
          content: "Corn 0708@163.com i该值来自您的卖家默认联系人"
        },
        {
          key: '5',
          name: '已取消的商品报告',
          tip: '已取消的商品报告中包含由亚马逊取消的所有商品，不包含已告商品、由库存加载工具删除的商品，或者卖家取消的商品。',
          type: '电子邮件:',
          content: "Corn 0708@163.com i该值来自您的卖家默认联系人"
        },
      ]
    },
    {
      title: "亚马逊销售指导通知",
      tip: "亚马逊销告指导通知包含各种推荐，帮助卖家增长不同领域的业务。",
      key: 5,
      data: [
        {
          key: '1',
          name: '刁亚马逊销售指导通知"',
          tip: '',
          type: '电子邮件:',
          content: "Corn 0708@163.com i该值来自您的卖家默认联系人"
        },
      ]
    },
    {
      title: "帐户通知",
      tip: "选择希望如何接收有关可能会影响您业务的问题的通知。",
      key: 6,
      data: [
        {
          key: '1',
          name: '公司最新信息',
          tip: '有关您的商品、商品发布、账户等方面的业务关键信息和更新。',
          type: '电子邮件:',
          content: "Corn 0708@163.com i该值来自您的卖家默认联系人"
        },
        {
          key: '2',
          name: '技术通知',
          tip: '可能对您的业务造成影响的计划更改或服务中断。:缺货、过期或其他原因。',
          type: '电子邮件:',
          content: "Corn 0708@163.com i该值来自您的卖家默认联系人"
        },
      ]
    },
    {
      title: "紧急通知",
      tip: "如果出现需要立即采取措施的重要问题，客户支持将使用紧急联系信息来联系卖家。",
      key: 7,
      data: [
        {
          key: '1',
          name: '紧急通知',
          tip: '如果出现需要立即采取措施的重要问题，客户支持将使用紧急联系信息来联系卖家。',
          type: '电子邮件:',
          content: "i单击“编辑”添加电话号码"
        },
      ]
    },
    {
      title: "亚马逊企业通知",
      tip: "选择希望如何接收有关与您的亚马逊企业帐户相关的销售和活动的通知。",
      key: 8,
      data: [
        {
          key: '1',
          name: '报价请求',
          tip: '当买家请求您销售的商品的报价时，会收到相应通知。',
          type: '电子邮件:',
          content: "Corn 0708@163.com i该值来自您的卖家默认联系人"
        },
      ]
    },
    {
      title: "消息",
      tip: "消息首选项控制您如何收到来自买家的有关您的商品详情和交易的信息。",
      key: 9,
      data: [
        {
          key: '1',
          name: '买家消息',
          tip: '亚马逊买家向您发送消息时，您会收到通知。',
          type: '电子邮件:',
          content: "Corn 0708@163.com i该值来自您的卖家默认联系人"
        },
        {
          key: '2',
          name: '确认通知',
          tip: '来自卖家平台网页的消息送达亚马逊买家时，进行确认。',
          type: '电子邮件:',
          content: "Corn 0708@163.com i该值来自您的卖家默认联系人"
        },
        {
          key: '3',
          name: '配送失败',
          tip: '消息无法送达亚马逊买家时，收到相关通知。',
          type: '电子邮件:',
          content: "Corn 0708@163.com i该值来自您的卖家默认联系人"
        },
        {
          key: '4',
          name: '买家退出',
          tip: '将消息发送给不再接收卖家信息的买家时收到通知',
          type: '电子邮件:',
          content: "Corn 0708@163.com i该值来自您的卖家默认联系人"
        },
      ]
    },
    {
      title: "亚马逊贷款通知",
      tip: "接收有关未处理贷款邀请函的通知",
      key: 10,
      data: [
        {
          key: '1',
          name: '贷款邀请函',
          tip: '有关未处理贷款邀请函的通知',
          type: '电子邮件:',
          content: "Corn 0708@163.com i该值来自您的卖家默认联系人"
        },
      ]
    },
    {
      title: "定价和优惠通知",
      tip: "选择您希望如何接收定价和优惠通知",
      key: 11,
      data: [
        {
          key: '1',
          name: '精选优事推荐',
          tip: '当您的优惠不符合精选优惠资格时收到通知',
          type: '电子邮件:',
          content: "Corn 0708@163.com i该值来自您的卖家默认联系人"
        },
      ]
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div className="n_o_notice" >
      <Alert
        message="在下方查看您的设置。点击“编辑”按钮来更改信息。如果您尚未提交资质审核材料，请先了解所需提交的资质材料清单及如何提交。 了解更多信息"
        type="info"
        showIcon
      />
      <div className="n_o_notice_item" >
        <div className="n_o_notice_item_header" >
          <div>
            <strong>订单通知</strong>
            <br />
            <span>每当有买家购买您的商品时收到通知。</span>
          </div>
          <div>
            <Button>编辑</Button>
            <UpOutlined />
          </div>
        </div>
        <div className="n_o_notice_item_body" >
          <div>选择通过短信接收的通知:</div>
          <Row>
            <Col span={11} >
              <Checkbox>卖家订单通知(已出告、现在配送)</Checkbox>
            </Col>
            <Col span={11} offset={2} >
              <span>短信：</span>
              <span>添加</span>
            </Col>
          </Row>
          <Divider />
          <div>选择通过电子由件接收的通知:</div>
          <Row>
            <Col span={11} >
              <Checkbox>卖家订单通知(已出售.现在配送)</Checkbox>
              <br />
              <Checkbox>亚马逊物流订单通知</Checkbox>
              <br />
              <Checkbox>多渠道配送通知</Checkbox>
              <br />
              <Checkbox>入库贷件通知</Checkbox>
              <br />
              <Checkbox>入库货件问题通知</Checkbox>
            </Col>
            <Col span={11} offset={2} >
              <Row>
                <Col span={6} ><span>电子邮件：</span></Col>
                <Col span={6} offset={2} >
                  <Input style={{ width: 200 }} />
                  <Input style={{ width: 200 }} />
                  <Input style={{ width: 200 }} />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>

      {
        list.map(item => {
          return (
            <div className="n_o_notice_item" key={item.key} >
              <div className="n_o_notice_item_header" >
                <div>
                  <strong>{item.title}</strong>
                  <br />
                  <span style={{ fontSize: 12 }} >{item.tip}</span>
                </div>
                <div>
                  <Button>编辑</Button>
                  <UpOutlined />
                </div>
              </div>
              <div className="n_o_notice_item_body" >
                <Table
                  rowSelection={{
                    type: "Checkbox",
                    ...rowSelection,
                  }}
                  columns={columns}
                  dataSource={item.data}
                  pagination={false}
                  showHeader={false}
                />
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default Notice