import React, { useState, useCallback, useEffect } from "react";
import "./Commodity-browse.css";
import AJAX from "api/apis";
import { connect } from "react-redux";
import { queryCommodityClassification } from "store/actions/addProduct";
import { List, Breadcrumb } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import IconFont from "components/Icon/Icon";
import { Link } from "react-router-dom";

let breadcrumbList = []
class CommodityBrowse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbData: [],
      data: {
        list: []
      }
    }
  }
  componentWillMount() {
    this.setState({
      breadcrumbData: []
    })
    this.getGoodsClassify()
  }
  componentWillReceiveProps(newProps) {  //监听参数变化
    this.getGoodsClassify()
  }
  getGoodsClassify = (classifyParentId = "") => {
    AJAX.addProduct.getGoodsClassify({ classifyParentId }).then((res) => {
      if (res.code == 1) {
        this.setState({
          data: {
            ...this.state.data,
            list: res.data
          }
        })
      }
    })
  }
  handleBreadcrumbClick = (item, index) => {  //点击面包屑中的某个分类
    let { breadcrumbData } = this.state
    if (index === breadcrumbData.length - 1) return;
    let id = breadcrumbData[index].id;
    breadcrumbData.splice(index + 1, breadcrumbData.length + 1 - index);
    // setBreadcrumbData(breadcrumbData);
    this.getGoodsClassify(id)
    this.setState({
      breadcrumbData: breadcrumbData
    })
  };

  handleClear = () => {  //删除面包屑选中分类
    let { breadcrumbData } = this.state

    breadcrumbList = []
    this.setState({
      breadcrumbData: [...breadcrumbList]
    })
    // setBreadcrumbData([...breadcrumbList]);
    this.getGoodsClassify()
  }

  renderBreadcrumb = () => {// 面包屑
    let { breadcrumbData } = this.state
    return <Breadcrumb separator=">">
      {breadcrumbData ?
        breadcrumbData.map((item, index) => {
          return (
            <Breadcrumb.Item
              key={index}
              onClick={() => {
                this.handleBreadcrumbClick(item, index);
              }}
            >
              <a>{item.name}</a>
            </Breadcrumb.Item>
          );
        }) : null}
    </Breadcrumb>
  }

  addCollectGoodsClassify = (classifyId, index) => {  //添加分类收藏
    AJAX.addProduct.addCollectGoodsClassify({ classifyId }).then((res) => {
      if (res.code == 1) {
        this.props.getList()
        this.setState({
          data: {
            ...this.state.data,
            list: this.state.data.list.map((item, _index) => _index == index ? { ...item, isCollect: false } : item)
          }
        })
      }
    })
  }
  removeCollectGoodsClassify = (classifyId, index) => {  //取消分类收藏
    AJAX.addProduct.removeCollectGoodsClassify({ classifyId }).then((res) => {
      if (res.code == 1) {
        this.props.getList()
        this.setState({
          data: {
            ...this.state.data,
            list: this.state.data.list.map((item, _index) => _index == index ? { ...item, isCollect: true } : item)
          }
        })
      }
    })
  }

  ChildrenClick = (item) => {  //判断某分类下是否有子分类
    let { breadcrumbData } = this.state
    if (item.classifyIfAddBranch) {
      // 没有子分类
      this.props.props.history.push({
        pathname: '/createListing',
        search: `?id=${item.id}`,
        state: {
          id: item.id
        }
      })
    } else {
      // 有子分类，获取子分类数据
      breadcrumbData.push({
        id: item.id,
        name: item.classifyName
      })
      this.setState({
        breadcrumbData: breadcrumbData
      })
      this.getGoodsClassify(item.id)
    }
  }
  render() {
    let { data, breadcrumbData } = this.state
    return <div className="commodity-container">
      <div className="commodity-collect-box">
        <p className="commodity-collect">浏览</p>
        <span>什么是商品类型？</span>
      </div>
      <div className="breadcrumb-container">
        {!breadcrumbData.length && <p className="empty">选择类别</p>}
        {this.renderBreadcrumb()}
        {!!breadcrumbData.length && (
          <div className="clear">
            <CloseOutlined onClick={this.handleClear} />
          </div>
        )}
      </div>
      <div className="daddemo-infinite-container">
        <List size="large" dataSource={data.list}
          renderItem={(item, index) =>
            <List.Item key={index}>
              {item.isCollect ?
                <IconFont type="icon-kongwujiaoxing" className="commodity-icon" onClick={() => { this.addCollectGoodsClassify(item.id, index) }} />
                :
                <IconFont type="icon-xingzhuang60kaobei2" className="commodity-icon" onClick={() => { this.removeCollectGoodsClassify(item.id, index) }} />}
              <div onClick={() => { this.ChildrenClick(item) }} style={{ display: 'contents' }}>
                <List.Item.Meta
                  title={item.classifyName}
                />
                {item.classifyIfAddBranch == false ? <div>
                  <IconFont type="icon-fanhui-copy-copy" className="commodity-icon" />
                </div> : <div className="commodity-select">
                  <button>选择类别</button>
                </div>}
              </div>
            </List.Item>
          }
        />
      </div>
    </div>
  }
}

export default connect(({ addProduct }) => {
  return addProduct;
})(CommodityBrowse);
