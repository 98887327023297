import React, { useRef, useEffect, useCallback, useState } from 'react';
import AJAX from "api/apis";
import BannerHeader from "../Banner-header";
import { Button, Input, Form, Select, Modal, Checkbox, DatePicker, Space } from 'antd';
import "./Tab2-banner.css"
import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"
import item from 'components/home_footer/item';
const CheckboxGroup = Checkbox.Group;
const { Option } = Select;
let sumReg = /^[0-9]*$/

const rules = [{ required: true, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容！") } else if (this.props.specialReg.test(value)) { cbfn("包含非法字符") } cbfn() } }];
class Tab2Banner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formRef: "",
            data: {
                variantList: [],
                NumbetTypeList: []
            },
            upData: [],
            disabledState: false,
            themeValue: '',
            isAdd: true,
            istable: false,
            tableList: [],
            variantTerms: false,
            variantNameList: [],
            upDelList: [],
            SubvariantsValue: '',
            switchseChecked: '',
            rulesInput: [{ required: true, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容！") } else if (this.props.specialReg.test(value)) { cbfn("包含非法字符") } cbfn() } }],
            rulesSelect: [{ required: true, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容！") } cbfn() } }],
            rulesSum: [{ required: true, validator: (rule, value, cbfn) => { if (!value) { cbfn("请输入内容！") } else if (this.props.specialReg.test(value)) { cbfn("包含非法字符") } else if (!sumReg.test(value)) { cbfn("只能是数字！") } cbfn() } }],
            isModalVisible: false,
            statusStatement: false,
            concessionalRate: false,
            checkedList: [],
            indeterminate: true,
            checkAll: false,
            isTourOpen: true,
            steps1: [
                {
                    selector: '.tab2-main',
                    content: '选择变体主题（如：颜色、尺寸等），必须要填写变体才算变体商品',
                }
            ],
            isActive: false,
            subjectObj: {},
            variantShow: false
        }
        this.form = null
        this.form1 = null

    }
    componentWillMount() {
        this.getGoodsVariantList(this.props.id || this.props.classifyId)
        this.getNumbetType()
    }
    awakenTips = (type) => {
        this.setState({
            isActive: type
        })
    }
    getUpGoodsVariantList = () => {
        let { variantList } = this.state.data
        AJAX.addProduct.getUpGoodsVariantList({ goodsId: this.props.goodsId }).then((res) => {
            if (res.code == 1) {
                let data = res.data;
                let tableLists = [], variantIdArr = [];
                for (let index in data) {
                    tableLists.push({
                        disableds: false,
                        isAdd: false,
                        value: [],
                        goodsId: data[index].goodsId,
                        classifyId: data[index].classifyId
                    })
                    for (let i of data[index].goodsVariantVoList) {
                        tableLists[index].value.push(i.variantValue)
                        variantIdArr.push(i.variantId)
                    }
                    data[index].value = tableLists[index].value.toString()
                }
                let obj = {}
                for (let item of data) {
                    for (let variantItem of item.goodsVariantElseBasicsInfoVoList) {
                        console.log(variantItem, "variantItem-----------------")
                        if (variantItem.goodsBasicsKeyId == 109) {
                            obj[`${item.value}goodsNsumberType`] = Number(variantItem.goodsElseBasicsValue)
                        }
                        if (variantItem.goodsBasicsKeyId == 1) {
                            obj[`${item.value}goodsNumber`] = variantItem.goodsElseBasicsValue
                        }
                        if (variantItem.goodsBasicsKeyId == 24) {
                            obj[`${item.value}goodsSku`] = variantItem.goodsElseBasicsValue
                        }
                        if (variantItem.goodsBasicsKeyId == 26) {
                            obj[`${item.value}goodsMoney`] = variantItem.goodsElseBasicsValue
                        }
                        if (variantItem.goodsBasicsKeyId == 27) {
                            obj[`${item.value}goodsInventory`] = variantItem.goodsElseBasicsValue
                        }
                        if (variantItem.goodsBasicsKeyId == 999) {
                            obj[`${item.value}statusStatement`] = variantItem.goodsElseBasicsValue
                        }
                        if (variantItem.goodsBasicsKeyId == 31) {
                            obj[`${item.value}goodsCondition`] = variantItem.goodsElseBasicsValue
                        }
                    }

                }
                // let content = this.form1.getFieldsValue();
                // [checkedItem + 'concessionalRate']: content.concessionalRate,
                // [checkedItem + 'discountStartTime']: content.discountStartTime,
                // [checkedItem + 'discountEndTime']: content.discountEndTime,
                // })
                variantIdArr = [...new Set(variantIdArr)];
                for (let idx in variantList) {
                    if (variantList[idx].variantId.toString() == variantIdArr.toString()) this.themeChonge(idx)
                }
                this.setState({
                    upData: data,
                    subjectObj: res.extend,
                    variantShow: res.extend.goodsIsVariant,
                    istable: res.extend.goodsIsVariant ? false : true,
                    variantTerms: true,
                    tableList: tableLists
                }, () => {
                    if (!res.extend.goodsIsVariant) {
                        this.form1.setFieldsValue(obj);
                        this.formChonge()
                    }
                })
            }
        })

    }
    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            this.getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    loading: false,
                }),
            );
        }
    };
    getNumbetType = () => {
        AJAX.addProduct.getNumbetType().then((res) => {
            if (res.code == 1 && res.data) {
                this.setState({
                    data: {
                        ...this.state.data,
                        NumbetTypeList: res.data
                    }
                })
            }
        })
    }
    getGoodsVariantList = (classify = "") => {
        AJAX.addProduct.getGoodsVariantList({ classify }).then((res) => {
            if (res.code == 1 && res.data) {
                let arr = []
                res.data.map((item, index) => {
                    let variantName = "";
                    item.variantName.map((item1, index1) => {
                        variantName += index1 == item.variantName.length - 1 ? item1 : item1 + "/"
                    })
                    arr.push({
                        variantId: item.variantId,
                        variantName: variantName,
                        variantInfo: item.variantInfo
                    })
                })
                this.setState({
                    data: {
                        ...this.state.data,
                        variantList: arr
                    }
                }, () => {
                    if (this.props.goodsId) this.getUpGoodsVariantList()
                })
            }
        })
    }
    getFormRef = el => {
        this.form = el;
    }
    getFormRef1 = el => {
        this.form1 = el;
    }
    tableShow = () => {
        let { tableList, variantNameList } = this.state
        let arr = [];
        let res = this.form.getFieldsValue();
        console.log(res, "res----")
        console.log(JSON.stringify(variantNameList), "variantNameList----")
        console.log(variantNameList, "variantNameList----")
        for (let items in variantNameList) {
            arr.push({ name: variantNameList[items].formName, value: [] })
            for (let keys in res) {
                let reg = RegExp(`${variantNameList[items].formName}`)
                if (res[keys]) {
                    if (keys.match(reg)) {
                        arr[items].value.push(res[keys]);
                    }
                }
            }
        }
        let array = []
        for (let arrItem in arr) {
            array[arrItem] = arr[arrItem].value
        }

        var a = array[0];
        for (var i = 1; i < array.length; i++) {
            fff(array[i], i);
        }

        function fff(array, index) {
            var ar = a;
            a = new Array();
            for (var i = 0; i < ar.length; i++) {
                for (var j = 0; j < array.length; j++) {
                    a.push(ar[i] + "_" + array[j]);
                }
            }
        }
        let tableLists = [];
        for (let keyss of a) {
            tableLists.push({ disableds: false, isAdd: this.props.goodsId ? true : false, goodsId: '', classifyId: '', value: keyss.split("_") })
        }
        console.log(tableLists, "tableLists")
        this.props.isVariantClick(true)
        if (this.props.goodsId) {
            tableLists = tableList.concat(tableLists)
            this.form.resetFields();
        }
        this.setState({
            istable: true,
            tableList: tableLists
        })
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            switchseChecked: nextProps.isSenior
        })
        if (nextProps.isSubmit) {
            this.form.validateFields().then((res) => { }).catch((errr) => { })
        }
    }
    themeChonge = (e) => {
        let { variantList } = this.state.data;
        console.log(e, "e-----------")
        let arr = JSON.parse(JSON.stringify(variantList[e]));
        arr.variantName = arr.variantName.split("/")
        let arr1 = [];
        arr.variantName.map((item, index) => {
            arr1.push({
                isCombination: arr.variantName.length > 1 ? true : false,
                CombinationId: arr.variantId,
                id: arr.variantId[index],
                name: item,
                formName: item,
                variantInfo: arr.variantInfo[index],
                isshow: [false, false, false, false, false, false, false, false, false, false]
            })
        })
        // this.formChonge()
        this.setState({
            themeValue: variantList[e].variantName,
            variantNameList: arr1,
            variantTerms: true,
            istable: false
        })
        this.props.displayCode(false)
        if (!this.props.goodsId) this.props.submitData([], 2)
    }
    SubvariantsChange = (item, index, showIndex = null) => {
        let { variantNameList } = this.state
        variantNameList[index].isshow[0] = true;
        if (showIndex != null) {
            variantNameList[index].isshow[showIndex + 1] = true;
        }
        this.setState({
            variantNameList: variantNameList
        })
        let res = this.form.getFieldsValue()

        if (item.isCombination) {
            for (let keys in res) {
                // if(index < 3){
                if (res[keys]) {
                    this.setState({
                        isAdd: false
                    })
                }
            }
        } else {
            this.setState({
                isAdd: false
            })
        }
    }
    formChonge = () => {
        let { tableList, variantNameList, upData } = this.state
        let content = this.form1.getFieldsValue();
        console.log(content, "content")
        console.log(variantNameList, "variantNameList")
        delete content.goodsCondition;
        delete content.goodsMoney;
        delete content.goodsInventory;
        delete content.statusStatement;
        delete content.concessionalRate;
        delete content.discountStartTime;
        delete content.discountEndTime;
        let isValue = true;
        let arr = [], goodsIdList = [];
        for (let variantItem in variantNameList) {
            for (let item in tableList) {
                if (tableList[item].disableds == false) {
                    arr[item] = { item: tableList[item].value, id: variantNameList[variantItem].id, CombinationId: variantNameList[variantItem].CombinationId, content: [] }
                    for (let keys in content) {
                        let reg = RegExp(`${tableList[item].value.toString()}`);
                        if (tableList[item].disableds == false && keys.match(reg)) {
                            if (content[keys]) {
                                let obj = {};
                                obj[keys] = content[keys];
                                arr[item].content.push(obj);
                                arr[item].isAdd = tableList[item].isAdd
                            }
                            else {
                                if (keys.match(/goodsSku/)) {
                                    isValue = true
                                } else {
                                    this.props.submitData([], 2)
                                    isValue = false
                                }
                            }

                        }
                    }
                } else {
                    for (let upItem of upData) {
                        if (upItem.value == tableList[item].value.toString()) goodsIdList.push(upItem.goodsId)
                    }
                }

            }
        }
        if (isValue) {
            let regGoodsInventory = RegExp(/goodsInventory/)  //检测库存
            let regGoodsMoney = RegExp(/goodsMoney/)  //检测价格
            let regGoodsSku = RegExp(/goodsSku/)  //检测sku
            let regGoodsNumber = RegExp(/goodsNumber/)  //检测编码
            let regGoodsNumberType = RegExp(/goodsNsumberType/)  //编码类型
            let regGoodsCondition = RegExp(/goodsCondition/)  //检测状况
            for (let arrItem of arr) {
                let obj = {};
                if (arrItem) {
                    for (let contentItem of arrItem.content) {
                        for (let objkey in contentItem) {
                            if (objkey.match(regGoodsInventory)) { obj.goodsInventory = contentItem[objkey] }
                            if (objkey.match(regGoodsMoney)) { obj.goodsMoney = contentItem[objkey] }
                            if (objkey.match(regGoodsSku)) { obj.goodsSku = contentItem[objkey] }
                            if (objkey.match(regGoodsNumber)) { obj.goodsNumber = contentItem[objkey] }
                            if (objkey.match(regGoodsNumberType)) { obj.goodsNumberType = contentItem[objkey] }
                            if (objkey.match(regGoodsCondition)) { obj.goodsCondition = contentItem[objkey] }
                        }
                        arrItem.content = obj
                    }
                }
            }
            for (var i = 0; i < arr.length; i++) {
                if (arr[i]) {
                    for (var j = i + 1; j < arr.length; j++) {
                        if (arr[i].item.toString() == arr[j].item.toString()) {
                            arr.splice(j, 1);
                            j--;
                        }
                    }
                }
            }
            console.log(arr, "arr-----------------")
            if (this.props.goodsId) {
                let upArr = [], arrContent = [];
                for (let item of upData) {
                    for (let arrItem of arr) {
                        console.log(arrItem, "11111")
                        if (arrItem) {
                            if (item.value == arrItem.item.toString()) {
                                arrItem.goodsId = item.goodsId
                                upArr.push({
                                    ...arrItem,
                                    goodsVariantElseBasicsInfoVoList: item.goodsVariantElseBasicsInfoVoList,
                                    // content: arrItem.content,
                                    isAdd: arrItem.isAdd
                                })
                            }
                        }
                    }

                }
                for (let arrItem of arr) {
                    if (arrItem && arrItem.isAdd) {
                        upArr.push({
                            ...arrItem,
                            goodsVariantElseBasicsInfoVoList: [],
                            isAdd: arrItem.isAdd,
                        })
                    }
                }
                for (let item of upArr) {
                    for (let i of item.goodsVariantElseBasicsInfoVoList) {
                        if (i.goodsBasicsKeyId == 109) i.goodsElseBasicsValue = item.content.goodsNumberType
                        if (i.goodsBasicsKeyId == 1) i.goodsElseBasicsValue = item.content.goodsNumber
                        if (i.goodsBasicsKeyId == 24) i.goodsElseBasicsValue = item.content.goodsSku
                        if (i.goodsBasicsKeyId == 26) i.goodsElseBasicsValue = item.content.goodsMoney
                        if (i.goodsBasicsKeyId == 27) i.goodsElseBasicsValue = item.content.goodsInventory[0]
                        if (i.goodsBasicsKeyId == 31) i.goodsElseBasicsValue = item.content.goodsCondition[0]
                    }
                    // arrContent.push(...item.goodsVariantElseBasicsInfoVoList)
                }
                arr = upArr
                console.log(upArr, "----------")
                this.props.upDelGoodsList([...new Set(goodsIdList)])

            }
            console.log(arr, "123333333")
            this.props.submitData(arr, 2)
        }
    }
    delVariant = () => {

    }
    Switchse = (e) => {
        this.setState({
            switchseChecked: e
        })
        this.props.Switchse(e)
    }
    showModal = () => {
        this.setState({
            isModalVisible: true
        })
    };

    handleOk = () => {
        this.form.resetFields();
        this.props.displayCode(true)
        let obj = this.form1.getFieldsValue()
        this.setState({
            istable: false,
            themeValue: '',
            variantTerms: false,
            variantNameList: [],
            isModalVisible: false
        })
        this.props.isVariantClick(false)
    };

    handleCancel = () => {
        this.setState({
            isModalVisible: false
        })
    };
    statusStatementClick = () => {
        let { statusStatement } = this.state;
        this.setState({
            statusStatement: !statusStatement
        })
    }
    concessionalRateClick = () => {
        let { concessionalRate } = this.state;
        this.setState({
            concessionalRate: !concessionalRate
        })
    }
    checkboxChange = (e, item) => {
        let { checkedList, indeterminate, checkAll, tableList } = this.state
        if (e.length == 0) {
            for (let items in checkedList) {
                if (checkedList[items] == item.toString()) {
                    checkedList.splice(items, 1)
                } else {
                    if (e[0]) {
                        checkedList.push(e[0])
                    }
                }
            }
        } else {
            checkedList.push(e[0])
        }
        indeterminate = !!checkedList.length && checkedList.length < tableList.length
        checkAll = checkedList.length === tableList.length
        this.setState({
            checkedList: checkedList,
            indeterminate: indeterminate,
            checkAll: checkAll
        })
    };

    onCheckAllChange = e => {
        let { checkedList, checkAll, tableList } = this.state
        let plainOptions = [];
        for (let items of tableList) {
            plainOptions.push(items.value.toString())
        }
        checkedList = e.target.checked ? plainOptions : [];
        checkAll = e.target.checked
        this.setState({
            checkedList: checkedList,
            indeterminate: false,
            checkAll: checkAll,
        })
    };
    deleteSelected = () => {
        let { checkedList, tableList } = this.state;
        // upDelList
        if (checkedList.length > 0) {
            for (let checkedItem of checkedList) {
                for (let tableItem of tableList) {
                    if (checkedItem == tableItem.value.toString()) {
                        tableItem.disableds = true
                    }
                }
            }
            this.setState({
                tableList: tableList
            })
            setTimeout(() => {
                this.formChonge()
            }, 300)
        }

    }
    restoreSelected = () => {
        let { checkedList, tableList } = this.state;
        if (checkedList.length > 0) {
            for (let checkedItem of checkedList) {
                for (let tableItem of tableList) {
                    if (checkedItem == tableItem.value.toString()) {
                        tableItem.disableds = false
                    }
                }
            }
            this.formChonge()
        }
        this.setState({
            tableList: tableList
        })

    }
    application = () => {
        let { checkedList, tableList } = this.state;
        let content = this.form1.getFieldsValue();
        if (checkedList.length > 0) {
            for (let checkedItem of checkedList) {
                for (let tableItem of tableList) {
                    if (checkedItem == tableItem.value.toString()) {
                        this.form1.setFieldsValue({
                            [checkedItem + 'goodsCondition']: content.goodsCondition,
                            [checkedItem + 'goodsMoney']: content.goodsMoney,
                            [checkedItem + 'goodsInventory']: content.goodsInventory,
                            [checkedItem + 'statusStatement']: content.statusStatement,
                            [checkedItem + 'concessionalRate']: content.concessionalRate,
                            [checkedItem + 'discountStartTime']: content.discountStartTime,
                            [checkedItem + 'discountEndTime']: content.discountEndTime,
                        })
                    }
                }
            }
        }
        this.formChonge()
    }

    editClick = (item) => {
        console.log(item, "items")
        // console.log(this.props.props.props, "this.props.history")
        this.props.props.props.history.push({
            pathname: '/createListing',
            search: `?goodsId=${item.goodsId}&classifyId=${item.classifyId}`,
            state: {
                goodsId: item.goodsId,
                classifyId: item.classifyId
            }
        })
        window.location.reload()
    }

    toSubject = () => {
        let { subjectObj } = this.state
        this.props.props.props.history.push({
            pathname: '/createListing',
            search: `?goodsId=${subjectObj.goodsId}&classifyId=${subjectObj.classifyId}`,
            state: {
                goodsId: subjectObj.goodsId,
                classifyId: subjectObj.classifyId
            }
        })
        window.location.reload()
    }
    render() {
        let { data, istable, isAdd, disabledState, themeValue, variantTerms, isActive, variantNameList, tableList, rulesSum, isModalVisible, statusStatement, concessionalRate, checkedList, indeterminate, checkAll, steps1, isTourOpen, rulesSelect, subjectObj, variantShow } = this.state;
        return <div className="tab2Banner-box">
            <BannerHeader checked={this.props.isSenior} id={this.props.id} flag={this.props.goodsId ? true : false} Switchse={this.Switchse} />
            {variantShow ? <div className="variantShow formBox">
                <div className="futiSku">本产品是SKU的变体：<span onClick={this.toSubject}>{subjectObj.goodsSku}</span></div>
                <div className="tab2-main">
                    <p>变体主题</p>
                    <div>{subjectObj.variantKeyName}</div>
                </div>
            </div> :
                <Form ref={this.getFormRef} className="formBox">
                    <Form.Item rules={rules}>
                        <div className="tab2-main">
                            <p>变体主题</p>
                            {data.variantList.length > 0 ? <div><Select placeholder="-选择-" disabled={disabledState} value={themeValue} onChange={this.themeChonge} style={{ width: "490px" }}>
                                {data.variantList.map((item, index) => {
                                    return <Option key={index} value={index}>{item.variantName}</Option>
                                })}
                            </Select>{istable ? <Button onClick={this.showModal} style={{ marginLeft: '15px' }}>edit</Button> : null}</div> : <div>该类型不适合变体</div>}
                        </div>
                        {variantTerms ? <div className="tab2-main">
                            <p>列出以下主题的所有变体术语。</p>
                            <div>在以下输入项中，列出您的商品已有的变体。 例如，如果您正在销售海盗衬衫，尺寸为小号、中号和大号，颜色为黑色和白色，请列出所有这些术语。 即使您并不拥有所有组合，或者某些变体临时缺货，上述操作也是必需的。 在下一页，您将能够清除任何无效的变体。 请参阅示例并<span style={{ color: '#008296' }}>了解更多信息 </span>。</div>
                            <div className="SubvariantsBox">
                                {variantNameList ? variantNameList.map((item, index) => {
                                    return <div key={item.id}>
                                        <p>{item.name}</p>
                                        <div>
                                            <div>
                                                <div className="flex1">
                                                    <Form.Item name={item.formName}>
                                                        <Input onChange={() => { this.SubvariantsChange(item, index) }} maxLength="20" />
                                                    </Form.Item>
                                                    {item.isshow ? item.isshow.map((showItem, showIndex) => {
                                                        if (showItem) {
                                                            return <Form.Item key={showIndex} name={`${item.formName}${showIndex}`} rules={[{ required: false }]}>
                                                                <Input onChange={() => { this.SubvariantsChange(item, index, showIndex) }} maxLength="20" />
                                                            </Form.Item>
                                                        }
                                                    }) : null}
                                                </div>
                                                <div style={{ color: '#667575' }}>实例：{item.variantInfo}</div>
                                            </div>
                                        </div>
                                    </div>
                                })
                                    : null}
                                <Button type="primary" onClick={this.tableShow} disabled={isAdd}>新增变体</Button>
                            </div>
                        </div > : null}
                    </Form.Item>
                </Form>}
            {istable ? <div className="tab2-main">
                <div>您的变体子ASIN已创建。要完成您的商品，请在库存中查找变体子商品，并选择【编辑】，为每个变体子商品添加图片。请注意，每个变体子商品需要一个在亚马逊网站上处于活动状态的图片。</div>
                <div>
                    <div className="flex0">
                        <div className="flex0">
                            <Button type="primary" onClick={this.application} >应用更改</Button>
                            <Button type="primary" onClick={this.deleteSelected} >删除已选</Button>
                            <Button type="primary" onClick={this.restoreSelected} >恢复已选</Button>
                            <p>{tableList.length || 0}个商品变体</p>
                        </div>
                        <div>
                            <Button type="primary" onClick={this.statusStatementClick}>状况说明</Button>
                            <Button type="primary" onClick={this.concessionalRateClick} >优惠价</Button>
                        </div>
                    </div>
                    <Form ref={this.getFormRef1}>
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    {variantNameList ? variantNameList.map((item) => {
                                        return <th>{item.name}</th>
                                    }) : null}
                                    <th>卖家SKU</th>
                                    <th>商品编码</th>
                                    <th>商品编码类型</th>
                                    <th>状况</th>
                                    <th>您的价格</th>
                                    <th>数量</th>
                                    {statusStatement ? <th>状况说明</th> : null}
                                    {concessionalRate ? <><th>优惠价</th><th>优惠价开始日期</th><th>优惠价结束日期</th></> : null}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <Checkbox indeterminate={indeterminate} onChange={this.onCheckAllChange} checked={checkAll} />
                                    </td>
                                    {variantNameList ? variantNameList.map((item) => {
                                        return <td></td>
                                    }) : null}
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <Form.Item name="goodsCondition">
                                            <Select onChange={this.formChonge}>
                                                <Option value="收藏品；非常好">收藏品；非常好</Option>
                                                <Option value="收藏品；好">收藏品；好</Option>
                                                <Option value="全新">全新</Option>
                                                <Option value="二手；好">二手；好</Option>
                                                <Option value="收藏品；尚可">收藏品；尚可</Option>
                                                <Option value="二手；类似新品">二手；类似新品</Option>
                                            </Select>
                                        </Form.Item>
                                    </td>
                                    <td>
                                        <Form.Item name="goodsMoney">
                                            <Input prefix="USD$" maxLength="20" />
                                        </Form.Item>
                                    </td>
                                    <td>
                                        <Form.Item name="goodsInventory">
                                            <Input maxLength="20" />
                                        </Form.Item>
                                    </td>
                                    {statusStatement ? <td>
                                        <Form.Item name="statusStatement">
                                            <Input disabled={item.disableds} onChange={this.formChonge} maxLength="100" />
                                        </Form.Item>
                                    </td> : null}
                                    {concessionalRate ? <>
                                        <td>
                                            <Form.Item name="concessionalRate">
                                                <Input disabled={item.disableds} prefix="USD$" maxLength="20" onChange={this.formChonge} />
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item className="discountStartTime">
                                                <DatePicker disabled={item.disableds} onChange={this.formChonge} placeholder="请选择优惠价开始日期" />
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item className="discountEndTime">
                                                <DatePicker disabled={item.disableds} onChange={this.formChonge} placeholder="请选择优惠价结束日期" />
                                            </Form.Item>
                                        </td>
                                    </> : null}

                                </tr>
                                {tableList ? tableList.map((item, index) => {
                                    return <tr key={index}>
                                        <td className="checkboxGroupBox">
                                            <CheckboxGroup options={[item.value.toString()]} value={checkedList} onChange={(e) => { this.checkboxChange(e, item.value) }} />
                                        </td>
                                        {/* 变体名称 */}
                                        {item.value.map((item1) => {
                                            return <td className={item.disableds ? 'delLine' : ''}>{item1}</td>
                                        })}
                                        <td>
                                            <Form.Item name={`${item.value.toString()}goodsSku`} >
                                                <Input disabled={item.disableds} onChange={this.formChonge} maxLength="20" />
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item name={`${item.value.toString()}goodsNumber`} rules={rulesSum}>
                                                <Input disabled={item.disableds} onChange={this.formChonge} maxLength="20" />
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item name={`${item.value.toString()}goodsNsumberType`} rules={rulesSelect}>
                                                <Select disabled={item.disableds} placeholder="-选择-" onChange={this.formChonge}>
                                                    {data.NumbetTypeList ? data.NumbetTypeList.map((item1, index1) => {
                                                        return <Option key={index1} value={item1.id}>{item1.numberType}</Option>
                                                    }) : null}
                                                </Select>
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item name={`${item.value.toString()}goodsCondition`} rules={rulesSelect}>
                                                <Select disabled={item.disableds} placeholder="-选择-" onChange={this.formChonge}>
                                                    <Option value="收藏品；非常好">收藏品；非常好</Option>
                                                    <Option value="收藏品；好">收藏品；好</Option>
                                                    <Option value="全新">全新</Option>
                                                    <Option value="二手；好">二手；好</Option>
                                                    <Option value="收藏品；尚可">收藏品；尚可</Option>
                                                    <Option value="二手；类似新品">二手；类似新品</Option>
                                                </Select>
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item name={`${item.value.toString()}goodsMoney`} rules={rulesSelect}>
                                                <Input disabled={item.disableds} type="number" min="0.1" onChange={this.formChonge} prefix="USD$" maxLength="9" />
                                            </Form.Item>
                                        </td>
                                        <td>
                                            <Form.Item name={`${item.value.toString()}goodsInventory`} rules={rulesSum}>
                                                <Input type="number" min="1" disabled={item.disableds} onChange={this.formChonge} maxLength="7" />
                                            </Form.Item>
                                        </td>
                                        {statusStatement ? <td>
                                            <Form.Item name={`${item.value.toString()}statusStatement`}>
                                                <Input disabled={item.disableds} onChange={this.formChonge} maxLength="100" />
                                            </Form.Item>
                                        </td> : null}
                                        {concessionalRate ? <>
                                            <td>
                                                <Form.Item name={`${item.value.toString()}concessionalRate`}>
                                                    <Input disabled={item.disableds} prefix="USD$" min="0.1" maxLength="9" onChange={this.formChonge} />
                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item className={`${item.value.toString()}discountStartTime`}>
                                                    <DatePicker disabled={item.disableds} onChange={this.formChonge} placeholder="请选择优惠价开始日期" />
                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item className={`${item.value.toString()}discountEndTime`}>
                                                    <DatePicker disabled={item.disableds} onChange={this.formChonge} placeholder="请选择优惠价结束日期" />
                                                </Form.Item>
                                            </td>
                                        </> : null}
                                        {!item.isAdd ? <td onClick={() => { this.editClick(item) }} className="edit">编辑</td> : null}
                                    </tr>
                                }) : null}
                            </tbody>
                        </table>
                    </Form>
                </div>
            </div> : null}
            {/* </Form> */}
            <Modal title="编辑主题" visible={isModalVisible} onOk={this.handleOk} cancelText="取消" okText="编辑主题并删除所有变体" onCancel={this.handleCancel}>
                <p>更改变体主题将删除当前ASIN的所有变体。 您希望继续吗？</p>
            </Modal>
            {/* 引导步骤 */}
            <TipsIcon clicks={this.awakenTips} />
            <Reactours routePath="Tab2-banner" isActive={isActive} clicks={this.awakenTips} steps={steps1} isOpen={isTourOpen} />
        </div>
    }
}

export default Tab2Banner