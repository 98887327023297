import React from "react"
import "./Tab2.css"
import '../../../default.css/default.css'

class Tab2 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			productList: this.props.planInfo
		}
	}
	componentWillMount() {
		// let { productList } = this.state;
		// productList = this.props.planInfo;
		// console.log(this.state.productList)
	}
	render() {
		let { productList } = this.state;
		return <div className="atAglancetab2Box">
			<div>
				<table>
					<thead>
						<tr>
							<th>箱号</th>
							<th>亚马逊物流包装箱标签编号</th>
							<th>追踪编码</th>
							<th>承运人状态</th>
							<th>重量（lb.）</th>
							<th>尺寸（in.）</th>
						</tr>
					</thead>
					<tbody>
						{productList ? productList.amazonCommodityPlanBoxInfoList.map((item, index) => {
							return <tr key={index}>
								<td>{item.commodityPlanBoxId}</td>
								<td>
									<div className="tagNumber">
										<div>{item.commodityPlanBoxTagNumber}</div>
										{/* <div>Apr 22, 2021 11:02:00 PM - - 配送员在途</div>
										<div>Apr 22, 2021 11:02:08 PM - US - 包裹已发货</div> */}
									</div>
								</td>
								<td>{item.commodityPlanBoxTraceNumber}</td>
								<td>{item.commodityPlanBoxPersonStatus}</td>
								<td>{item.commodityPlanBoxWeight}</td>
								<td>{item.commodityPlanBoxSizi}</td>
							</tr>
						})
							: null}
					</tbody>
				</table>
			</div>
		</div>
	}
}

export default Tab2