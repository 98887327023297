import React from "react";
import AJAX from "api/apis";
import "./examine.css";
import IconFont from "../../../../components/Icon/Icon";
import { Link } from 'react-router-dom';
import { Pagination } from 'antd';
import Reactours from "components/reactours";
import TipsIcon from "components/tipsIcon";

class examine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      goodsBatchList: [],
      page: 1,
      size: 10,
      total: 10,
      isActive: false,
      isTourOpen: true,
      steps1: [
        {
          selector: '.ant-tabs-tab-active',
          content: '点击监控上传状态查看上传情况',
        },
      ],
    }
  }
  componentWillMount() {
    this.getGoodsBatchList(this.state.page, this.state.size)
  }
  componentWillReceiveProps(newProps) {
    this.getGoodsBatchList(this.state.page, this.state.size)
  }
  getGoodsBatchList = (page, size) => {
    AJAX.addProduct.getGoodsBatchList({ page, size }).then(res => {
      if (res.code = 1 && res.data) {
        this.setState({
          goodsBatchList: res.data.list,
          total: res.data.total,
          page: res.data.pageNum,
          size: res.data.pageSize,
        }, () => {
          let isActive = JSON.parse(localStorage.getItem("stepList"))["examine"]
          this.setState({
            isActive: isActive ? false : true,
            steps1: [
              {
                selector: '.ant-tabs-tab-active',
                content: '点击“监控上传状态”查看上传情况',
              },
              {
                selector: '.examine-listNav-1',
                content: '下载处理报告，可查看上传情况',
              },
            ],
          })
        })
      }
    })
  }
  chongePagination = (page, size) => {
    this.getGoodsBatchList(page, size)
    this.setState({
      page: page,
      size: size
    })

  }
  downloadTxtFile = (item) => {
    const element = document.createElement("a");
    const file = new Blob([item.goodsBatchResults ? item.goodsBatchResults : null], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = "处理报告.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }
  awakenTips = (type) => {
    this.setState({ isActive: type })
  }
  render() {
    let { goodsBatchList, total, isActive, steps1, isTourOpen } = this.state;
    return <div className="examine-body">
      <div className="shopupDL-lbox" style={{ width: "67%" }}>
        <div className="examine-Lwarn">
          <div className="examine-icon">
            <IconFont style={{ color: " #3489ac" }} type="icon-jinggao1" />
          </div>
          <div>
            查看您最近50次的库存文件上传状态。如果处理报告出现任何错误，请修改您的库存文件，然后重新上传。
            <span>了解更多信息</span>
          </div>
        </div>

        {/* 上传日期时间 */}
        <div className="examine-list">
          {/* 头部 */}
          <div className="examine-listHed">
            <div style={{ width: "15%" }}>上传日期/时间</div>
            <div style={{ width: "18%" }}>批量编号</div>
            <div style={{ width: "39%" }}>上传状态</div>
            <div style={{ width: "20%" }}>操作</div>
          </div>

          {/* 内容 */}
          {goodsBatchList.length > 0 ? goodsBatchList.map((item, index) => {
            return <div key={index} className={`examine-listNav ${index == 0 && "examine-listNav-1"}`}>
              <div style={{ width: "15%" }}>{item.createTime}</div>
              <div style={{ width: "18%" }}>{item.batchNumber}</div>
              <div style={{ width: "39%" }}>
                <div>上传状态  {item.goodsBatchStatus ? '已完成' : '上传中'}</div>
                {item.goodsBatchStatus ? <div>已提交记录的总数：{item.goodsBatchRecordCount}</div> : null}
                <div>需要您采取进一步操作的记录已保存为草稿</div>
              </div>
              <div style={{ width: "20%", color: "#0066c0", cursor: 'pointer' }}>
                {/* <span>
                <Link to="/DraftIncomplete">完成草稿</Link>
                </span> <span style={{ color: "#c45500", fontWeight: "bold" }}>
                  <IconFont style={{ color: "#3489ac" }} type="icon-jinggao1" />测试版
                  </span>
                  <br /> */}
                {item.goodsBatchStatus ? <span onClick={() => { this.downloadTxtFile(item) }}>下载处理报告</span> : null}
              </div>
            </div>
          }) : null}

        </div>
        <Pagination onChange={this.chongePagination} defaultCurrent={1} total={total} />
      </div>
      {/* 右边盒子 */}
      <div className="shopupDL-rbox">
        <div
          className="shopupDL-warnBox"
          style={{ border: "1px solid #3479ac", padding: "10px 0" }}
        >
          <div className="shopupDL-rBox-warnHed">
            <IconFont
              type="icon-jinggao1"
              className="RepertoryTable1-box1-font"
              style={{ color: "#3489ac", margin: "0 20px" }}
            />
            <div>通过增强的处理报告快速修复商品信息错误</div>
          </div>
          <div className="shopupDL-rBox-warnnav">
            <div >
              增强的处理报告皆在帮助您快速识别商品信息错误，以便进行修复。
              相比以前的处理报告，新的Excel格式报告在出现错误的单元格中对错误和警告进行了突出显示。
              通过这种格式，您能一目了然地找出提交内容中的问题，从而更加轻松地纠正商品信息错误。
            </div>
          </div>
        </div>

        {/* 常见问题 */}
        <div className="shopupDL-bor">
          <div className="shopupDL-boxHed">常见问题</div>
          <div className="shopupDL-boxPD">
            <div style={{ padding: "10px 20px" }}>
              <ul>
                <li><Link>什么是处理报告？</Link></li>
                <li><Link>如何下载库存报告？</Link></li>
                <li><Link>处理报告中的错误的含义是什么以及如何解决这些错误？</Link></li>
                <li><Link>如何查看已上传的库存文件结果？</Link></li>
                <li><Link>如何修复亚马逊品牌注册上传错误？</Link></li>
                <li><Link>如何阅读处理报告？</Link></li>
              </ul>
            </div>
          </div>
        </div>
        {/* 相关主题 */}
        <div className="shopupDL-bor">
          <div className="shopupDL-boxHed">常见问题</div>
          <div className="shopupDL-boxPD">
            <div style={{ padding: "10px 20px" }}>
              <ul>
                <li><Link>查看处理报告</Link></li>
                <li><Link>最快处理上传数据</Link></li>
                <li><Link>错误代码说明</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <TipsIcon clicks={this.awakenTips} />
      <Reactours routePath="examine" isActive={isActive} clicks={this.awakenTips} steps={steps1} isOpen={isTourOpen} />
    </div>
  }
}
export default examine;
