import { computedKeywordsId } from "./config";

export const createKeyword = (keywords) => {
  return [].concat(keywords).map((item) => {
    const { keyword, matchType } = item;

    return {
      ...item,
      $id: computedKeywordsId({ keyword, matchType }),
    };
  });
};

export const updateSelectedKeywordsList = (form, keywords) => {
  const newKeywordsList = createKeyword(keywords);
  const selectedKeywords = form.getFieldValue("selectedKeywordsList");
  const selectedKeywordsIds = selectedKeywords.map((v) => v.$id);
  let okList = [];
  let failList = [];
  newKeywordsList.forEach((item) => {
    if (!selectedKeywordsIds.includes(item.$id)) {
      okList.push(item);
    } else {
      failList.push(item);
    }
  });

  form.setFieldsValue({ selectedKeywordsList: [...okList, ...selectedKeywords] });

  return {
    allSuccess: failList.length === 0,
    successList: okList,
    failList,
  };
};

export const removeSelectedKeywords = (form, $ids) => {
  $ids = [].concat($ids);
  const selectedKeywords = form.getFieldValue("selectedKeywordsList");

  const newKeywordsList = selectedKeywords.filter((v) => !$ids.includes(v.$id));

  form.setFieldsValue({ selectedKeywordsList: newKeywordsList });
};
