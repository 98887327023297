import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
    margin: 0 auto;
    background-color: #131921;
    height: 60px;
    display: flex;
    // justify-content: center;
    align-items: center;
    
`;
export const Logo = styled.div`
    width: 113px;
    height: 30px;
    background: url(${require('@/assets/img/public/icons2.png').default}) no-repeat;
    background-position: 0 -50px;
`;
export const Deliver = styled.div`
    width: 88px;
    height: 50px;
    padding: 0 9px 0 9px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &::before{
        content: '';
        width: 15px;
        height: 18px;
        position: absolute;
        left: 10px;
        bottom: 13px;
        background: url(${require('@/assets/img/public/icons2.png').default}) no-repeat;
        background-position: -71px -377px;

    }
    p:nth-child(1){
        color: #ccc;
        font-size: 12px;
        line-height: 14px;
        height: 14px;
        font-weight: 400;
        margin: 0;
        padding-left: 15px;
        font-family: "Amazon Ember",Arial,sans-serif;
    }
    p:nth-child(2){
        color: #fff;
        font-size: 14px;
        line-height: 15px;
        font-weight: 700;
        margin: 0;
        padding-left: 5px;
        font-family: "Amazon Ember",Arial,sans-serif;
    }
`;