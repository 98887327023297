import React from 'react'
import AJAX from "api/apis";
import './Pretreatment.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"
import '../default.css/default.css'
import { Link } from 'react-router-dom';
import { Button, Collapse, Steps, Modal, Select } from 'antd';
import { getUrlData } from "@/utils/utils";
const { Option } = Select
class Pretreatment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            goodsList: [],
            GoodsPackTypeList: [],
            addressDetails: '',
            classContent: '',
            classType: 1,
            classIndex: null,
            isModalVisible: false,
            ModalText: '',
            isReturn: false,
            packPerformer: '',
            packId: '',
            stepControl: false,
            delTipsModal: false,
            planId: null,
            isTourOpen: true,
            steps1: [
                {
                    selector: '.PretreatmentBox .kc06_table1 tr td:nth-child(5)',
                    content: '选择预处理方式（一般选择卖家，若是选择亚马逊需要缴一定的费用）',
                }
            ],
            isActive: false
        }
    }
    componentWillMount() {
        let planId = getUrlData('planId')
        planId && this.getStoragePlanInfo(planId)
        this.getStoreRegisterInfo()
        this.getGoodsPackTypeAll()
        this.setState({
            planId: planId
        })
    }
    awakenTips = (type) => {
        this.setState({
            isActive: type
        })
    }
    getStoragePlanInfo = (planId) => {
        let { goodsList } = this.state
        AJAX.fba.getStoragePlanInfo({ id: planId }).then((res) => {
            if (res.code == 1 && res.data) {
                goodsList = res.data
                goodsList.storageAllPlanPackMoney = 0
                for (let items of goodsList.amazonStoragePlanGoodsInfoList) {
                    items.preparationCost = 0;
                    items.isDel = false
                    if (items.storagePlanPackName) {
                        items.packPerformer = true
                    } else {
                        items.packPerformer = false
                    }
                }
                this.setState({
                    goodsList: goodsList,
                    stepControl: goodsList.storagePlanStep == 2 ? false : true,
                })
            }
        })
    }
    getGoodsPackTypeAll = () => {
        AJAX.fba.getGoodsPackTypeAll().then((res) => {
            if (res.code == 1 && res.data) {
                this.setState({
                    GoodsPackTypeList: res.data
                })
            }
        })

    }
    getStoreRegisterInfo = () => {
        AJAX.login.getStoreRegisterInfo([{
            propertyKey: 'paytaxes_organization_site'
        }], 'isRegister=0').then((res) => {
            if (res.code == 1 && res.data) {
                this.setState({
                    addressDetails: res.data[0].propertyValue
                })
            }
        })
    }
    pretreatmentClassClick = (type, index = null) => {
        let { stepControl } = this.state
        if (stepControl) return;
        this.setState({
            isModalVisible: true,
            classType: type,
            classIndex: index
        })
    }
    handleOk = () => {
        this.setState({
            isModalVisible: false
        })
    }
    handleCancel = () => {
        this.setState({
            isModalVisible: false
        })
    }
    pretreatmentChange = (e) => {
        let { GoodsPackTypeList, classContent, packPerformer } = this.state
        for (let item of GoodsPackTypeList) {
            if (e == item.id) {
                classContent = item.packName
                packPerformer = item.packPerformer
            }
        }
        this.setState({
            classContent: classContent,
            packId: e,
            packPerformer: packPerformer
        })
    }
    pretreatmentPartyChange = e => {
        let { goodsList, stepControl } = this.state;
        if (stepControl) return;
        goodsList.storageAllPlanPackMoney = 0;

        for (let items of goodsList.amazonStoragePlanGoodsInfoList) {
            if (!items.isDel) {
                items.storagePlanPackAction = e
                if (items.storagePlanPackAction == '1' && items.packPerformer == true) {
                    items.preparationCost = 1.5
                } else {
                    items.preparationCost = 0
                }
                items.storagePlanPackMoney = Number(items.preparationCost * items.storagePlanGoodsCount)
                goodsList.storageAllPlanPackMoney += items.storagePlanPackMoney
            }
        }
        this.setState({
            goodsList: goodsList
        })
    }
    selectClass = () => {
        let { classContent, goodsList, classIndex, classType, packPerformer, packId } = this.state;
        goodsList.storageAllPlanPackMoney = 0;
        if (classType == 1) {
            for (let items of goodsList.amazonStoragePlanGoodsInfoList) {
                if (!items.isDel) {
                    items.storagePlanPackName = classContent
                    items.packPerformer = packPerformer
                    items.packId = packId
                    if (items.packPerformer == true && items.storagePlanPackAction == '1') {
                        items.preparationCost = 1.5
                    } else {
                        items.preparationCost = 0
                    }
                    items.storagePlanPackMoney = Number(items.preparationCost * items.storagePlanGoodsCount)
                    goodsList.storageAllPlanPackMoney += items.storagePlanPackMoney
                }
            }
        } else {
            goodsList.amazonStoragePlanGoodsInfoList[classIndex].storagePlanPackName = classContent
            goodsList.amazonStoragePlanGoodsInfoList[classIndex].packPerformer = packPerformer
            goodsList.amazonStoragePlanGoodsInfoList[classIndex].packId = packId
            if (packPerformer == true && goodsList.amazonStoragePlanGoodsInfoList[classIndex].storagePlanPackAction == '1') {
                goodsList.amazonStoragePlanGoodsInfoList[classIndex].preparationCost = 1.5
            } else {
                goodsList.amazonStoragePlanGoodsInfoList[classIndex].preparationCost = 0
            }
            for (let items of goodsList.amazonStoragePlanGoodsInfoList) {
                items.storagePlanPackMoney = Number(items.preparationCost * items.storagePlanGoodsCount)
                goodsList.storageAllPlanPackMoney += items.storagePlanPackMoney

            }
        }
        this.setState({
            goodsList: goodsList,
            isModalVisible: false,

        })
    }
    // 操作是否删除该商品
    goodsOperation = (index, content) => {
        let { goodsList } = this.state;
        goodsList.storageAllPlanPackMoney = 0;
        goodsList.amazonStoragePlanGoodsInfoList[index].isDel = content;
        for (let items of goodsList.amazonStoragePlanGoodsInfoList) {
            if (items.storagePlanPackAction == '1' && items.packPerformer == true && !items.isDel) {
                items.preparationCost = 1.5
            } else if (items.storagePlanPackAction == '1' && items.isDel) {
                items.preparationCost = 0
            }
            items.storagePlanPackMoney = Number(items.preparationCost * items.storagePlanGoodsCount)
            goodsList.storageAllPlanPackMoney += items.storagePlanPackMoney
        }
        this.setState({
            goodsList: goodsList
        })
    }
    // 删除商品信息
    deleteStoragePlanGoodsCount = (storagePlanId, id) => {
        AJAX.fba.deleteStoragePlanGoodsCount({ storagePlanId, id }).then((res) => { })
    }

    nextEvent = () => {
        let { goodsList, addressDetails } = this.state
        goodsList.totalPreparationCost = 0;
        let obj = {
            id: goodsList.id,
            storagePlanAddRess: addressDetails,
            storagePlanPackType: goodsList.storagePlanPackType,
            upStoragePlanGoodsPackInfoReqList: []
        }
        for (let items of goodsList.amazonStoragePlanGoodsInfoList) {
            if (items.isDel == false) {
                obj.upStoragePlanGoodsPackInfoReqList.push({
                    id: items.id,
                    storagePlanPackAction: items.storagePlanPackAction ? Number(items.storagePlanPackAction) : 0,
                    storagePlanPackMoney: items.preparationCost,
                    storagePlanPackName: items.storagePlanPackName
                })
            } else {
                this.deleteStoragePlanGoodsCount(goodsList.id, items.id)
            }
        }
        AJAX.fba.upStoragePlanGoodsPack(obj).then((res) => {
            if (res.code == 1 && res.data) {
                this.props.history.push({ pathname: '/Labeling', state: { planId: res.data }, search: `?planId=${res.data}` })
            }
        })
    }

    deleteSchedule = () => {
        this.setState({
            delTipsModal: true
        })
    }
    tipshandleOk = () => {
        let { goodsList } = this.state
        AJAX.fba.deleteStoragePlan({ id: goodsList.id }).then((res) => {
            if (res.code == 1) {
                this.props.history.push('/inboundQueue')

            }
        })
    }

    render() {
        let { goodsList, addressDetails, isModalVisible, GoodsPackTypeList, stepControl, delTipsModal, steps1, isTourOpen, isActive } = this.state
        return <div className="PretreatmentBox">
            <Header />
            <div>
                <div>
                    <div className="kc12_banner">
                        <ul>
                            <li><a href="#">Send to Amazon </a><span>新功能</span></li>
                            <li><a href="#">货件处理进度</a></li>
                            <li><a href="#">入库问题一览</a></li>
                            <li><a href="#">扫描和贴标</a></li>
                            <li className="qx"><a href="#">上传入库计划文件</a></li>
                        </ul>
                    </div>
                    <div className="kc12_content">
                        <h2>发/补货 <span>了解更多信息</span></h2>
                        <div className="body-border">
                            <ul className="body-ul">
                                <li>设置数量</li>
                                <li>预处理商品<img src={require("@/assets/img/home/kc12_topsj.png").default} /></li>
                                <li>为商品贴标</li>
                                <li>查看货件</li>
                            </ul>
                            <ul className="body-ul2">
                                <li>预处理货件</li>
                                <li>一览</li>
                            </ul>
                            <div className="body-div"></div>
                        </div>
                        <div className="body-table">
                            <table className="table1">
                                <tr>
                                    <th width="160">发货地址</th>
                                    <th>目标商城</th>
                                    <th className="what" colspan="1">包装类型<span>这是什么？</span></th>
                                </tr>
                                <tr>
                                    <td>
                                        <p>{goodsList.storagePlanPackType == 1 ? '混装商品' : '原厂包装发货商品'}</p>
                                    </td>
                                    <td>US</td>
                                    <td>混装商品</td>
                                </tr>
                            </table>
                        </div>
                        <div className="body-tab">
                            <ul className="tab-ul">
                                <li className="active">所有商品</li>
                                <li>需要准备</li>
                                <li>可能需要准备</li>
                            </ul>
                            <ul className="tab-ul2">
                                <li className="mj">卖家大学视频</li>
                                <li className="ck">查看准备帮助</li>
                            </ul>
                            <p>请查看预处理指导（如果预处理指导未知，则选择商品类型），并且选择准备方。亚马逊可以提供<span>预处理服务</span>，但需要收费。</p>
                            <div className="body-tab-div">
                                <img src={require("@/assets/img/home/kc12_i-blue.png").default} className="i" />
                                <p>是否需要包装和预处理材料？查看<span>亚马逊首选商品预处理和配送用品商店</span>，了解有关亚马逊如何帮助您满足配送需求的更多信息。</p>
                            </div>
                            <p className="xs">显示<span>1</span>到<span>1</span>件<span>1</span>商品。</p>
                        </div>
                        <div id="mybox">
                            <table className="kc06_table1">
                                <thead>
                                    <tr>
                                        <th>卖家SKU</th>
                                        <th>商品名称</th>
                                        <th>状况</th>
                                        <th>准备指导</th>
                                        <th>预处理方</th>
                                        <th>商品数量</th>
                                        <th>准备成本</th>
                                        <th>删除</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td><input type="checkbox" />显示 ASINFNSKU</td>
                                        <td></td>
                                        <td className="forAllBox" onClick={() => { this.pretreatmentClassClick(1) }}>适用于全部</td>
                                        <td>
                                            <Select disabled={stepControl} style={{ width: '100px' }} defaultValue="适用于全部" onChange={this.pretreatmentPartyChange}>
                                                <Option value="1">亚马逊</Option>
                                                <Option value="0">卖家</Option>
                                            </Select>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    {goodsList.amazonStoragePlanGoodsInfoList ? goodsList.amazonStoragePlanGoodsInfoList.map((item, index) => {
                                        return <tr className={item.isDel ? 'isDel' : null} key={index}>
                                            <td>{item.goodsSku}</td>
                                            <td>
                                                <div className="text-ellipsis-3">{item.goodsName}</div>
                                            </td>
                                            <td>{item.goodsCondition}</td>
                                            <td onClick={() => { this.pretreatmentClassClick(2, index) }}>{item.storagePlanPackName ? item.storagePlanPackName : '--'}</td>
                                            <td>{!item.packPerformer ? '卖家' : item.storagePlanPackAction ? item.storagePlanPackAction == true ? '亚马逊' : '卖家' : '--'}</td>
                                            <td>{item.storagePlanGoodsCount}</td>
                                            <td>{item.storagePlanPackMoney || 0}</td>
                                            <td>{item.isDel ? <span style={{ cursor: 'pointer' }} onClick={() => { this.goodsOperation(index, false) }}>撤销</span> : <img onClick={() => { this.goodsOperation(index, true) }} src={require("@/assets/img/public/del.png").default} />}</td>
                                        </tr>
                                    }) : null}
                                    <tr>
                                        <td>总计</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>{goodsList.total}</td>
                                        <td>${goodsList.storageAllPlanPackMoney}</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="body-box2">
                            <div className="btn-l">
                                <button>
                                    <Link to={{ pathname: '/setQuantity', state: { planId: this.state.planId }, search: `?planId=${this.state.planId}` }}>返回<img className="kc_10_btn2" src={require("@/assets/img/home/btns.png").default} /></Link>
                                </button>
                                <button onClick={this.deleteSchedule}>删除计划</button>
                                <button>复制计划</button>
                            </div>
                            <div className="btn-r">
                                <button className="body-box2_button2" onClick={this.nextEvent}>
                                    继续<img className="kc_10_btn2" src={require("@/assets/img/home/btns.png").default} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal title="提示" visible={delTipsModal} onOk={this.tipshandleOk} style={{ width: '50%' }} cancelText="取消" okText="确认" onCancel={() => { this.setState({ delTipsModal: false }) }} >
                <p>是否确定？删除计划操作无法撤销</p>
            </Modal>
            <Modal title="准备活动" visible={isModalVisible} onCancel={this.handleCancel} footer={null}>
                <p>在此页面为所有的商品定义预处理分类</p>
                <div>
                    <span>选择分类：</span>
                    <Select style={{ width: '70%' }} defaultValue="选择一项" onChange={this.pretreatmentChange}>
                        {GoodsPackTypeList ? GoodsPackTypeList.map((item, index) => {
                            return <Option key={index} value={item.id}>{item.packName}</Option>
                        }) : null}
                    </Select>
                    <Button onClick={this.selectClass}>选择</Button>
                </div>
            </Modal>
            <Footer />
            {/* 引导步骤 */}
            <TipsIcon clicks={this.awakenTips} />
            <Reactours routePath="Pretreatment" isActive={isActive} clicks={this.awakenTips} steps={steps1} isOpen={isTourOpen} />
        </div>
    }
}


export default Pretreatment