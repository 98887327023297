import "./SearchList.css"
import HomeHeader from 'components/home_header';
import FooterCom from "components/addlisting_footer/FooterCom";
import { Button, Input, Table, Pagination, Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'
const { Option } = Select;

function SearchList() {
  const columns = [
    {
      title: '',
      dataIndex: 'image',
      with: "10%",
      render: (text) => {
        return (
          <div >
            <img alt="example" src={require("@/assets/images/search_list.png").default} />
          </div>
        )
      },
    },
    {
      title: '商品名称 ASIN',
      dataIndex: 'name',
      with: "30%",
      render: (text) => {
        return (
          <div style={{ width: 500, color: "#008296", wordWrap: "break-word" }} >
            专业搅拌器mengk 2000 W高速电动全面营养食品处理器与1.899.4 gram不含BPA壶适用
            于求水果蔬菜smoothles soups mayonnaise,等一(商业7厨房)
          </div>
        )
      },
    },
    {
      title: '应用日期',
      dataIndex: 'age',
      with: "10%",
      render: (text) => {
        return (
          <div style={{ color: "#008296" }}  >
            <div>UPC: 723610200874</div>
            <div>EAN: 0723610200874</div>
          </div>
        )
      },
    },
    {
      title: 'ASIN 状态',
      dataIndex: 'status',
      with: "10%",
      render: (text) => {
        return (
          <div style={{ color: "#008296" }}  >
            <div>销售排名402,811</div>
            <div>商品:0已使用，全新</div>
          </div>
        )
      },
    },
    {
      title: 'ASIN 状态',
      dataIndex: '',
      with: "10%",
      render: (text) => {
        return (
          <div style={{ color: "#008296" }}  >
            <Select defaultValue="选择状态" size="small" style={{ width: 120 }}>
              <Option value="jack">全新</Option>
              <Option value="lucy">二手</Option>
            </Select>
            <Button className="search_table_but" size="small" ><Link to="/AddCommodityOffer" >销售此商品</Link></Button>
            <p>显示限制<DownOutlined /></p>
          </div>
        )
      },
    },
  ];

  const data = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      status: '未发布',
      image: "@/assets/img/home/card-4.png",
    },
  ];

  return (
    <div className="searchList">
      <HomeHeader />
      <div className="searchList_search" >
        <p>请首先在亚马逊目录中查找您的商品</p>
        <Input.Search style={{ width: 500 }} ></Input.Search>
      </div>
      <div className="search_list_body" >
        <div className="search_body_left" >
          <strong>按类别缩小范围</strong>
          <strong>所有分类</strong>
          <p>无法在亚马逊目录中找到您的商品?</p>
          <Button>创健新的商品信息</Button>
        </div>
        <div className="search_body_rigth" >
          <div style={{ display: "flex", justifyContent: "space-between" }} className="search_body_rigth_header" >
            <span>当前显示1-1条结果，共1祭</span>
            <Pagination defaultCurrent={1} total={1} size="small" />
          </div>
          <Table
            columns={columns}
            dataSource={data}
            showHeader={false}
            pagination={false}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }} className="search_body_rigth_header" >
            <span>页码<Input style={{ width: 30, marginLeft: 5 }} value={1} ></Input>，共1页 <span>前进</span></span>
            <Pagination defaultCurrent={1} total={1} size="small" />
            <span></span>
          </div>
        </div>
      </div>
      <FooterCom />
    </div>
  );
}

export default SearchList;
