import React from "react";
import "./Add.css";
import "../../../default.css/default.css";
import Header from "components/home_header";
import Footer from "components/addlisting_footer/FooterCom";

function Add() {
  return (
    <div>
      <Header />

     	<div className="qd12_tjmj">
			<div className="qd12_daoh">
				<span>协议定价</span>
				<svg t="1610765555688" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2527"
				 width="20" height="20">
					<path d="M624.86499999 512.247l-292.15499999-292.159c-12.28-12.27-12.28-32.186 0-44.457 12.27-12.28 32.186-12.28 44.457 0l314.388 314.388c12.28 12.27 12.28 32.186 0 44.457l-314.388 314.387c-6.136 6.14-14.183 9.211-22.228 9.211s-16.092-3.071-22.228-9.211c-12.28-12.27-12.28-32.186 0-44.457l292.155-292.16z"
					 p-id="2528" fill="#707070"></path>
				</svg>
				<span>添加买家</span>
			</div>
			<h2>添加买家</h2>
			<div className="qd12_bianhao">
				<p>输入您要为其创建协议定价的买家的企业编号 <span>在哪里可以找到此编号？</span> <svg t="1610710586242" className="icon" viewBox="0 0 1024 1024" version="1.1"
						 xmlns="http://www.w3.org/2000/svg" p-id="4145" width="15" height="15">
							<path d="M512.18365184 668.00877702l-403.42645487-403.41955064c-16.9429169-16.9567253-44.44374272-16.9567253-61.38804048 0-16.95810612 16.9429169-16.95810614 44.44374272 0 61.38804046l434.11978468 434.11978467c16.9429169 16.9567253 44.44374272 16.9567253 61.38804047 0l434.11840384-434.11978467c8.47836265-8.4728393 12.71892482-19.58446539 12.71892481-30.69332981s-4.24056217-22.22049053-12.71892479-30.69332982c-16.9429169-16.9567253-44.44374272-16.9567253-61.38804049-1e-8l-403.42507402 403.41955068z"
							 p-id="4146" fill="#2c2c2c"></path>
						</svg></p>

				<div>
					<p>企业编号</p>
					<input type="text" /> <span>搜索</span>
				</div>

		</div>



		</div>


      <Footer />
    </div>
  );
}

export default Add;
