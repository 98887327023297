import React from 'react'
import './Feedback.css'
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import IconFont from 'components/Icon/Icon'


function Feedback(){
    return(
        <div className='feedback'>
            <Header/>
            <div style={{paddingBottom:"300px"}}>

            <div className="end07_mybody">
			<p className="p1">反馈管理器</p>
			<p className="p2">使用反馈管理器可跟踪买家对您服务的满意度。您可以查看短期和长期指标，以及详细的反馈条目，包括买方电子邮件和订单编号。点击"订单标号"以查看卖家平台的"管理订单"部分中的交易详细信息。<a href="#">了解更多信息</a></p>
			<p className="p3">反馈评级：<img src={require("../../assets/images/5-07.png").default} /></p>
			<p className="p4">自卖家开店以来共获得<span>4.8</span>颗星。(<span>233</span>次评级)</p>
			<table border="1" cellSpacing="0" cellPadding="0">
				<tr>
					<th></th>
					<th className="num">30天</th>
					<th className="num">90天</th>
					<th className="num">365天</th>
					<th className="num">累计</th>
				</tr>
				<tr>
					<td className="pj">好评</td>
					<td><span>0</span>%(0)</td>
					<td><span>0</span>%(0)</td>
					<td><span>0</span>%(0)</td>
					<td><span>98</span>%(229)</td>
				</tr>
				<tr>
					<td className="pj">中立</td>
					<td><span>0</span>%(0)</td>
					<td><span>0</span>%(0)</td>
					<td><span>0</span>%(0)</td>
					<td><span>0</span>%(1)</td>
				</tr>
				<tr>
					<td className="pj">差评</td>
					<td><span>0</span>%(0)</td>
					<td><span>0</span>%(0)</td>
					<td><span>0</span>%(0)</td>
					<td><span>1</span>%(3)</td>
				</tr>
				<tr>
					<td className="pj">计数</td>
					<td>0</td>
					<td>0</td>
					<td>0</td>
					<td>233</td>
				</tr>
			</table>
			<div className="table-p">
				<p className="p5">此表显示相应的反馈百分比和反馈计数。<a href="#">了解如何在亚马逊商城向买家显示您的反馈。</a></p>
				<p className="p6">由于四舍五入的原因，显示的值之和可能不等于100%。</p>
			</div>
			<div className="mybody-div2">
				<p className="p7">最新反馈</p>
				<div className="mybody-div">
					<div className="mybody_div2_warn">
					<svg t="1611548815905" className="icon i" viewBox="0 0 1024 1024" 
					version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2093" 
					width="30" height="30"><path d="M512 360.917333c33.642667 0 42.666667-4.949333 42.666667-42.090666 0-37.76-9.024-41.493333-42.666667-41.493334s-42.666667 3.712-42.666667 41.493334c0 37.141333 9.024 42.090667 42.666667 42.090666zM549.248 746.666667V412.309333h-75.093333V746.666667h75.093333z" p-id="2094"></path></svg>
					
					<div className="p8">显示过去365天内的反馈。要请求反馈报告，请执行以下操作：<a href="#">下载反馈报告</a></div>
					</div>
					<img src={require("../../assets/images/5-07-2.png").default} className="pj"/>
				</div>
			</div>
			
			<table border="1" cellSpacing="0" cellPadding="0" className="tab2">
				<tr>
					<th className="date">日期</th>
					<th className="num">评级</th>
					<th className="num">订单编号</th>
					<th className="num">评论</th>
					<th className="num">操作</th>
				</tr>
				<tr>
					<td>2019/03/06</td>
					<td><span>5</span></td>
					<td>111-5240507-7493814</td>
					<td>Ya he comprado 3 licuadoras! Una en mi cocina y 2 que he regalado a amistades.Selas recomiendo</td>
					<td className="last">
					<div className="down">
						<div className="down_btn">
						<button>选择一个</button>
						<div className="downIcon"><IconFont type="icon-sanjiaoxing" /></div>
						</div>
						<div className="down_list">
							<a>选择一个</a>
							<a>发布公开回复</a>
							<a>请求删除</a>
						</div>
						</div>
					
					</td>
				</tr>
				<tr>
					<td>2019/03/06</td>
					<td><span>5</span></td>
					<td>111-5240507-7493814</td>
					<td>Ya he comprado 3 licuadoras! Una en mi cocina y 2 que he regalado a amistades.Selas recomiendo</td>
					<td className="last">
					<div className="down">
						<div className="down_btn">
						<button>选择一个</button>
						<div className="downIcon"><IconFont type="icon-sanjiaoxing" /></div>
						</div>
						<div className="down_list">
							<a>选择一个</a>
							<a>发布公开回复</a>
							<a>请求删除</a>
						</div>
						</div>
					
					</td>
				</tr>
				<tr>
					<td>2019/03/06</td>
					<td><span>5</span></td>
					<td>111-5240507-7493814</td>
					<td>Ya he comprado 3 licuadoras! Una en mi cocina y 2 que he regalado a amistades.Selas recomiendo</td>
					<td className="last">
						<div className="down">
						<div className="down_btn">
						<button>选择一个</button>
						<div className="downIcon"><IconFont type="icon-sanjiaoxing" /></div>
						</div>
						<div className="down_list">
							<a>选择一个</a>
							<a>发布公开回复</a>
							<a>请求删除</a>
						</div>
						</div>
					</td>
				</tr>
				
			</table>
		</div>



            </div>
            <Footer/>
        </div>
    )
}

export default Feedback