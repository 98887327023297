import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import React from 'react';
import './index.css'

const { Panel } = Collapse;

export default (props) => {
  const { list } = props
  return (
    <Collapse
      bordered={false}
      // defaultActiveKey={['1']}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      className="register-collapse"
    >
      {
        list.map(item => {
          return <Panel header={item.header} key={item.txt || item.html} className="site-collapse-custom-panel">
            {
              item.txt ? <p>{item.txt}</p> : <div dangerouslySetInnerHTML={{ __html: item.html }}></div>
            }


          </Panel>
        })
      }

    </Collapse>
  )
}