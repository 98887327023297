import React from 'react'
import './Commentator.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import '../default.css/default.css'
import { Link } from 'react-router-dom';


function Early() {
    return (
        <div>
            <Header />
            <div>
                <div className="end06_mybody CommentatorBox">
                    <ul className="mybody-ul">
                        <li>早期评论者计划</li>
                        <li>
                            <Link to="/RegCommentator">注册加入计划</Link>
                        </li>
                        <li>
                            <Link to="/Early">控制面板</Link>
                        </li>
                        <li>常见问题</li>
                    </ul>
                </div>
                <div className="commentBg">
                    <div className="a-row program-overview-title-box aok-float-left">
                        <h1 className="a-spacing-small heading-text">早期评论者计划</h1>
                        <p className="a-spacing-medium a-size-large">更快地获得第一条评论</p>
                        <span className="a-button a-button-primary">
                            <Link to="/RegCommentator">开始使用</Link>
                        </span>
                    </div>
                    <div className="a-row program-overview-diagonal aok-float-left"></div>
                </div>
                <div className="a-section program-overview-values">
                    <div className="a-row a-grid-vertical-align a-grid-top">
                        <div className="a-column a-span4 a-text-center value-box">
                            <div className="a-icon-row a-spacing-medium reviews-increase-icon"
                                style={{ backgroundImage: `url(${require("@/assets/images/comment1.png").default})` }}></div>
                            <div className="a-row a-spacing-small">
                                <span className="a-size-large a-text-bold">评论最多可将销量提高 3.5 倍</span>
                            </div>
                            <div className="a-row text-justify">
                                <span className="a-size-medium">评论会影响曝光率、搜索排名和买家的信任。经证明，商品的第一条评论最多可将销量提高 3.5 倍。</span>
                            </div>
                        </div>
                        <div className="a-column a-span4 a-text-center value-box">
                            <div className="a-icon-row a-spacing-medium reviews-faster-icon"
                                style={{ backgroundImage: `url(${require("@/assets/images/comment2.png").default})` }}></div>
                            <div className="a-row a-spacing-small">
                                <span className="a-size-large a-text-bold">获取您的第一条评论</span>
                            </div>
                            <div className="a-row text-justify">
                                <span className="a-size-medium">早期评论者计划通过向购买已注册商品的买家提供小奖励（价值 US$3.00 美元的礼品卡）来换取他们对您的商品提供反馈，从而帮助您获得多达 5 条评论。</span>
                            </div>
                        </div>
                        <div className="a-column a-span4 a-text-center value-box a-span-last">
                            <div className="a-icon-row a-spacing-medium starting-easy-icon"
                                style={{ backgroundImage: `url(${require("@/assets/images/comment3.png").default})` }}></div>
                            <div className="a-row a-spacing-small">
                                <span className="a-size-large a-text-bold">轻松入门</span>
                            </div>
                            <div className="a-row text-justify">
                                <span className="a-size-medium">直到通过此计划收到评论后您才需要支付费用，注册包含整个 SKU 变体系列。亚马逊最多将在一年内或在您通过此计划收到 5 条评论之前，向购买过您的注册商品的买家提供奖励。</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="a-section a-text-center program-overview-footer">
                    <div className="a-row">
                        <p className="a-spacing-base a-size-large">商品注册非常简单</p>
                        <span className="a-button a-button-primary" style={{ fontWeight: '400' }}>
                            <Link to="/RegCommentator">开始使用</Link>
                        </span>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default Early