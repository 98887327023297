import React from 'react'
import AJAX from "api/apis";
import './freightEdit.css'
import { Button, Checkbox } from 'antd';
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import { Link } from 'react-router-dom';
import Reactours from "components/reactours";
import TipsIcon from "components/tipsIcon";
import getUrlData, { buildRouteSearch } from "@/utils/getUrlData";

class freightEdit extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      data: {
        standard: '',
        standardIsselect: false,
        standard1: '',
        standard1Isselect: false,
        fastDelivery: '',
        fastDeliveryIsselect: false,
        fastDelivery1: '',
        fastDelivery1Isselect: false,
        twoDaysService: '',
        twoDaysServiceIsselect: false,
        oneDayDelivery: '',
        oneDayDeliveryIsselect: false,
        internationalDistribution: '',
        internationalDistributionIsselect: false,
        internationalExpress: '',
        internationalExpressIsselect: false,
      },
      id: getUrlData('id'),
      isFreightEdit: getUrlData('isFreightEdit') == 'true',
      isActive: false,
      isTourOpen: true,
      steps1: [
        {
          selector: '.tableBox',
          content: '根据要求填写不同运输时间',
        },
        {
          selector: '.operationBtn .ant-btn-primary',
          content: '填写完毕之后点击提交',
        },
      ],
    }
  }
  componentWillMount() {
    if (getUrlData('isFreightEdit') == 'true') {
      this.setState({
        data: getUrlData('distributionFreightJson'),
        id: getUrlData('id')
      })
    }
  }
  awakenTips = (type) => {
    this.setState({ isActive: type })
  }
  CheckboxChange = (e, type) => {
    switch (type) {
      case 1:
        this.setState({
          data: {
            ...this.state.data,
            standard: e.target.checked ? e.target.value : "",
            standardIsselect: e.target.checked,
          }
        })
        break;
      case 2:
        this.setState({
          data: {
            ...this.state.data,
            standard1: e.target.checked ? e.target.value : "",
            standard1Isselect: e.target.checked,
          }
        })
        break;
      case 3:
        this.setState({
          data: {
            ...this.state.data,
            fastDelivery: e.target.checked ? e.target.value : "",
            fastDeliveryIsselect: e.target.checked,
          }
        })
        break;
      case 4:
        this.setState({
          data: {
            ...this.state.data,
            fastDelivery1: e.target.checked ? e.target.value : "",
            fastDelivery1Isselect: e.target.checked,
          }
        })
        break;
      case 5:
        this.setState({
          data: {
            ...this.state.data,
            twoDaysService: e.target.checked ? e.target.value : "",
            twoDaysServiceIsselect: e.target.checked,
          }
        })
        break;
      case 6:
        this.setState({
          data: {
            ...this.state.data,
            oneDayDelivery: e.target.checked ? e.target.value : "",
            oneDayDeliveryIsselect: e.target.checked,
          }
        })
        break;
      case 7:
        this.setState({
          data: {
            ...this.state.data,
            internationalDistribution: e.target.checked ? e.target.value : "",
            internationalDistributionIsselect: e.target.checked,
          }
        })
        break;
      case 8:
        this.setState({
          data: {
            ...this.state.data,
            internationalExpress: e.target.checked ? e.target.value : "",
            internationalExpressIsselect: e.target.checked,
          }
        })
        break;
    }
  }
  submitClick = () => {
    let { data, isFreightEdit, id, loading } = this.state;
    if (loading) return
    this.setState({ loading: true })
    if (isFreightEdit == false) {
      AJAX.distribution.addDistributionFreight({
        distributionFreightJson: JSON.stringify(data)
      }).then((res) => {
        console.log(res, "res")
        this.setState({ loading: false })
        if (res.code == 1) {
          this.props.history.push('/distributionSet')
        }
      }).catch(() => {
        this.setState({ loading: false })
      })
    } else {
      AJAX.distribution.upDistributionFreight({
        id,
        distributionFreightJson: JSON.stringify(data)
      }).then((res) => {
        this.setState({ loading: false })
        if (res.code == 1) {
          this.props.history.push('/distributionSet')
        }
      }).catch(() => {
        this.setState({ loading: false })
      })

    }
  }
  render() {
    let { data, isActive, steps1, isTourOpen, } = this.state
    return <div className="freightEdit">
      <Header />
      <div className="shopup-body">
        <div className="shopup-titel">
          <div>默认运费</div>
        </div>
        <div className="end05_myBody">
          <div>
            <div >
              <span>注意：如果您对Standard（标准）和Expedited（加急）配送缩短了配送时间，则“订单报告”中将永久显示额外的4列。</span>
            </div>
            <div className="tableBox">
              <table border="1" cellspacing="0" cellpadding="0" bordercolor="#EEEEEE">
                <tr className="coupons1">
                  <td>标准</td>
                  <td colSpan="3">
                    <Checkbox checked={data.standardIsselect} onChange={(e) => { this.CheckboxChange(e, 1) }} value="在途时间：22-26工作日" style={{ lineHeight: '32px' }}>在途时间：22-26工作日</Checkbox><br />
                    <Checkbox checked={data.standard1Isselect} onChange={(e) => { this.CheckboxChange(e, 2) }} value="在途时间：3-5工作日" style={{ lineHeight: '32px' }}>在途时间：3-5工作日</Checkbox><br />
                    <span>符合条件的卖家可以缩短配送时间。（了解更对信息。）（查看费率表）</span>
                  </td>
                </tr>
                <tr className="coupons1">
                  <td>快速配送</td>
                  <td colSpan="3">
                    <Checkbox checked={data.fastDeliveryIsselect} onChange={(e) => { this.CheckboxChange(e, 3) }} value="在途时间：2-6工作日" style={{ lineHeight: '32px' }}>在途时间：2-6工作日</Checkbox><br />
                    <Checkbox checked={data.fastDelivery1Isselect} onChange={(e) => { this.CheckboxChange(e, 4) }} value="在途时间：1-3工作日" style={{ lineHeight: '32px' }}>在途时间：1-3工作日</Checkbox><br />
                    <span>符合条件的卖家可以缩短配送时间。（了解更对信息。）（查看费率表）</span>
                  </td>
                </tr>
                <tr className="coupons1">
                  <td>
                    <p>两日送达（了解更多信息）</p>
                    <p>1天处理时间 +2天运输时间</p>
                  </td>
                  <td colSpan="3">
                    <Checkbox checked={data.twoDaysServiceIsselect} onChange={(e) => { this.CheckboxChange(e, 5) }} value="在途时间：2工作日（查看费率表）" style={{ lineHeight: '32px' }}>在途时间：2工作日（查看费率表）</Checkbox>
                  </td>
                </tr>
                <tr className="coupons1">
                  <td>一日送达（已禁用）</td>
                  <td colSpan="3">
                    <Checkbox checked={data.oneDayDeliveryIsselect} onChange={(e) => { this.CheckboxChange(e, 6) }} value="在途时间：24小时（查看费率表）" style={{ lineHeight: '32px' }}>在途时间：24小时（查看费率表）</Checkbox>
                  </td>
                </tr>
                <tr className="coupons1">
                  <td>国际配送</td>
                  <td colSpan="3">
                    <Checkbox checked={data.internationalDistributionIsselect} onChange={(e) => { this.CheckboxChange(e, 7) }} value="在途时间：3-6周（查看费率表）" style={{ lineHeight: '32px' }}>在途时间：3-6周（查看费率表）</Checkbox>
                  </td>
                </tr>
                <tr className="coupons1">
                  <td>国际加急</td>
                  <td colSpan="3">
                    <Checkbox checked={data.internationalExpressIsselect} onChange={(e) => { this.CheckboxChange(e, 8) }} value="在途时间：3-7工作日（查看费率表）" style={{ lineHeight: '32px' }}>在途时间：3-7工作日（查看费率表）</Checkbox>
                  </td>
                </tr>
              </table>
            </div>
            <div className="operationBtn">
              <Link to="/distributionSet"><Button>取消</Button></Link>
              <Button onClick={this.submitClick} type="primary">提交</Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <TipsIcon zIndex='9999' clicks={this.awakenTips} />
      <Reactours routePath="freightEdit" isActive={isActive} clicks={this.awakenTips} steps={steps1} isOpen={isTourOpen} />
    </div>
  }
}
export default freightEdit;
