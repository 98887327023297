import React, { useRef, useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from "components/home_header";
import Footer from "components/addlisting_footer/FooterCom";
import AJAX from "api/apis";
import "./ManageDepositMethod.less"
import { Button, Select, Table } from 'antd';
import { UpOutlined } from '@ant-design/icons';
const { Option } = Select;

function SetDepositMethod() {
  const [list, setList] = useState([]);

  const columns = [
    {
      title: '银行账户',
      dataIndex: 'depositBankNumber',
      key: 'depositBankNumber',
      width: '120px',
      render: (text) => <div className='depositBankNumber'>结束 <span>{text.substring(text.length - 3)}</span></div>,
    },
    {
      title: 'location',
      dataIndex: 'depositState',
      key: '',
      width: '120px',
    },
    {
      title: '账户持有人',
      dataIndex: 'depositPersonName',
      key: 'depositPersonName',
      width: '120px',
    },
    {
      title: '商城',
      dataIndex: 'storeDepositRelVoList',
      key: '',
      render: (text) => {
        return (
          <div>
            {
              text.length ? (
                text.map(v => {
                  return (
                    <span className='shiyongmoren' key={v.address}>
                      <span>{v.defaultFlag ? '默认设置，' : ''}适用于</span>
                      <span>{v.address} <span className='delShop' onClick={() => { deleteShoppingDeposit(v.storeDepositRelId) }}>{v.defaultFlag ? '从默认中，' : ''}移除</span></span>
                    </span>
                  )
                })
              )
                : null
            }
          </div>
        )
      },
    },
    {
      title: '',
      dataIndex: 'storeDepositId',
      width: '60px',
      render: (text, row, index) => {
        if (row.storeDepositRelVoList.length == 0) {
          return <div><span onClick={() => { del(text) }} className='delBtn'>删除</span></div>
        } else {
          return null
          // return (
          //   <div>
          //     {
          //       row.storeDepositRelVoList.map(v => {
          //         return (
          //           <span onClick={() => { deleteShoppingDeposit(v.storeDepositRelId) }} key={v.address}>
          //             {v.defaultFlag ? '从默认中，' : ''}移除
          //           </span>
          //         )
          //       })
          //     }
          //   </div>)
        }
      },
    },
  ]
  useEffect(() => {
    getStoreDepositList()
  }, [])
  const getStoreDepositList = useCallback(() => {
    AJAX.store.getStoreDepositList().then((res) => {
      if (res.code == 1) {
        setList(res.data)
      }
    })
  }, [])
  const deleteShoppingDeposit = (value) => {
    AJAX.store.deleteShoppingDeposit({ storeDepositRelId: value }).then((res) => {
      if (res.code == 1) {
        getStoreDepositList()
      }
    })
  }
  const del = (value) => {
    // console.log(value);
    AJAX.store.deleteDepositInfo({ storeDepositId: value }).then((res) => {
      if (res.code == 1) {
        getStoreDepositList()
      }
    })
  }

  return (
    <div className="ManageDepositMethod" >
      <Header />
      <div className="setdeposit_Method_inner" >
        <div className="deposit_Method" >
          <h2>
            <span>存款方式</span>
            <Link to="/accountinfo" >账户信息</Link>
          </h2>
          <div className='deposit_Method_set' >
            管理存款方式
          </div>
          <Table columns={columns} dataSource={list} pagination={false} />

          <div className="setdeposit_Method_bank_but" >
            <Button><Link to="/accountinfo/depositMethod" >返回</Link></Button>
          </div>
        </div>

      </div>

      <Footer />
    </div>
  )
}

export default SetDepositMethod