    import React from 'react';
    import './GlobalSales.css'
    import '../default.css/default.css'
    import Header from '../../components/home_header'
    import Footer from 'components/addlisting_footer/FooterCom'
    import IconFont from 'components/Icon/Icon';

    function GlobalSales(){
        return(
            <div>
                <Header/>

                <div>
                <div className="kc23_cennav">
            <ul>
                <li><img src={require("../../assets/images/kc23_quanqiu.png").default} alt=""/>全球销售</li>
                <li className="kc23_huojian">了解基础知识</li>
                <li>建立国际商品信息</li>
                <li>寻找提供商</li>
            </ul>
        </div>
        {/* <!--tab--> */}
        <div className="kc23_tab">
            {/* <!--管理冗余库存--> */}
            <div className="kc23_rongyu">
                <div className="kc23_lllff">
                    <ul className="kc23_rongyutit">
                        <li>
                            <h2>全球销售</h2>
                        </li>
                        <li>观看演示</li>
                        <li>了解更多信息</li>
                        <li>为此页评级</li>
                    </ul>
                </div>
                {/* <!--表格--> */}
                <div className="kc23_mytable">
                    <div className="kc23_tab_l">
                        <p className="kc23_biaoti">
                            借助亚马逊全球开店，您可以吸引数以百万的新买家。在北美、欧洲、亚太、中东、北非地区的任何商城中发布和销售商品。使用以下选项卡来帮助您开始使用此服务。注意：您加入亚马逊物流出口计划后，国际买家便可在亚马逊上订购您的亚马逊物流商品并将其配送至国际地址。<span>了解更多信息</span>
                        </p>
                        <div className="kc23_diqu">
                            <ul className="kc23_diqu_tab">
                                <li>美州<img src="../../assets/images/kc23_dagou.png" alt=""/></li>
                                <li>欧州<img src="../../assets/images/kc23_dagou.png" alt=""/></li>
                                <li>亚太(1/4)</li>
                                <li>中东和北非</li>
                            </ul>
                            <div className="kc23_xiaosohu">
                                <p>亚马逊可以帮助您在美国,加拿大,墨西哥销售商品</p>
                                <div className="kc23_guoqi">
                                    <div><img src="../../assets/images/kc23_meiguoguoqi.png" alt=""/>
                                        <p>美国</p>
                                    </div>
                                    <div><img src="../../assets/images/kc23_jiananda.png" alt=""/>
                                        <p>加拿大</p>
                                    </div>
                                    <div><img src="../../assets/images/kc23_moxige.png" alt=""/>
                                        <p>墨西哥</p>
                                    </div>
                                </div>
                            </div>
                            <div className="kc23_houxu">
                                <p>后续步骤</p>
                                <p>要向另一北美商城添加现有商品信息, 请使用<span>构建国际商品信息</span>创建关联。</p>
                                <p>要为独特商品创建新商品信息, 请使用<span>添加新商品</span>页面</p>
                            </div>
                        </div>
                        <div className="kc23_biaoge">
                            <div className="kc23_biaoge_l">
                                <div className="kc23_guo">
                                    <div>美洲 <img src="../../assets/images/kc23_shangla.png" alt=""/></div>
                                    <div className="kc23_act">美国</div>
                                    <div>加拿大</div>
                                    <div>墨西哥</div>
                                </div>
                                <div className="kc23_guo">
                                    <div>欧洲 <img src="../../assets/images/kc23_shangla.png" alt=""/></div>
                                    <div>英国</div>
                                    <div>德国</div>
                                    <div>法国</div>
                                    <div>意大利</div>
                                    <div>西班牙</div>
                                </div>
                                <div className="kc23_guo">
                                    <div>亚太 <img src="../../assets/images/kc23_shangla.png" alt=""/></div>
                                    <div>日本</div>
                                    <div>韩国</div>
                                </div>
                            </div>
                            <div className="kc23_biaoge_r">
                                <div className="kc23_xiaosohu">
                                    <p>销售摘要</p>
                                    <div className="kc23_r_box2">
                                        <div>
                                            <p>日期范围</p>
                                            <span>7 天</span>
                                            <span>15 天</span>
                                            <span>30 天</span>
                                        </div>
                                        <div>
                                            <span>货币</span>
                                            <div>
                                                <select>
                                                    <option>USD</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="kc23_r_box3">
                                        <table  cellSpacing="0" cellPadding="0">
                                            <thead>
                                                <tr>
                                                    <td className="kc23_bal"><span className="kc23_to1o">10 -</span></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="kc23_bal"><span>8 -</span></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td className="kc23_bal"><span>6 -</span></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td className="kc23_bal"><span>4 -</span></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr className="kc23_last_1">
                                                    <td className="kc23_bal dian"><p>Dec 14</p><span>2 -</span><span className="kc23_to0">0 -</span></td>
                                                    <td className="kc23_dian"><p>Dec 15</p><span className="kc23_toshu">|</span><span className="kc23_toshu2">|</span></td>
                                                    <td className="kc23_dian"><p>Dec 16</p><span className="kc23_toshu">|</span></td>
                                                    <td className="kc23_dian"><p>Dec 17</p><span className="kc23_toshu">|</span></td>
                                                    <td className="kc23_dian"><p>Dec 18</p><span className="kc23_toshu">|</span></td>
                                                    <td className="kc23_dian"><p>Dec 19</p><span className="kc23_toshu">|</span></td>
                                                    <td className="kc23_dian"><p>Dec 20</p><span className="kc23_toshu">|</span></td>
                                                    <td className="kc23_dian"><p>Dec 21</p><span className="kc23_toshu">|</span><span className="kc23_toshu3">|</span></td>
                                                </tr>
                                            </tbody>
                                            </table>
                                    </div>
                                    <div className="kc23_r_box4">
                                        <p><input type="checkbox"/> 比较商城间的销售</p>
                                        <p>货币汇率和转换仅供参考。 货币汇率有可能发生变化，预计的销售数据可能并反映您的销售所适用的税收或费用。</p>
                                    </div>
                                    <div className="kc23_r_box5">
                                        <div>
                                            <p>销售</p>
                                            <p>USD<span>0.00</span></p>
                                        </div>
                                        <div>
                                            <p>已订阅商品数量</p>
                                            <p><span>0</span></p>
                                        </div>
                                    <p className="kc23_xiangxi">查看更多详细</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--消息--> */}
                        <div className="kc23_mynews">
                            <div className="kc23_news">
                                <div className="kc23_news_box">
                                    <div>订单 <img src={require("../../assets/images/kc23_kuozhan.png").default} alt=""/></div>
                                </div>
                                <div className="kc23_news_box2">
                                    <img src={require("../../assets/images/kc23_meiguoguoqi.png").default} alt=""/>
                                    <select>
                                        <option>美国</option>
                                    </select>
                                </div>
                                <div className="kc23_news_box3">
                                    <div>
                                        <p>未发货</p>
                                        <span>0</span>
                                    </div>
                                    <div>
                                        <p>等待着</p>
                                        <span>0</span>
                                    </div>
                                    <div>
                                        <p>退货请求</p>
                                        <span>0</span>
                                    </div>
                                </div>
                                <div className="kc23_news_box4">
                                    <p><img src="../../assets/images/kc23_dagou.png" alt=""/>意大利, 日本, 英国, 墨西哥, 德国, 西班牙, 法国, 加拿大, 美国<span> 总共有 0
                                            个未配送订单</span></p>
                                    <p>目前,<span> 意大利, 日本, 英国, 德国, 西班牙, 法国</span> 没有可用信息</p>
                                </div>
                            </div>
                            <div className="kc23_news">
                                <div className="kc23_news_box">
                                    <div>买家消息 <img src="../../assets/images/kc23_kuozhan.png" alt=""/></div>
                                </div>
                                <div className="kc23_news_box2">
                                <img src={require("../../assets/images/kc23_meiguoguoqi.png").default} alt=""/>
                                    <select>
                                        <option>美国</option>
                                    </select>
                                </div>
                                <div className="kc23_news_box3">
                                    <div>
                                        <p>不到24小时</p>
                                        <span>0</span>
                                    </div>
                                    <div>
                                        <p>超过24小时</p>
                                        <span>0</span>
                                    </div>

                                </div>
                                <div className="kc23_news_box4">
                                    <p><img src="../../assets/images/kc23_dagou.png" alt=""/><span> 您已回复了至少24小时内的所有消息。</span></p>
                                    <p>目前,<span> 日本, 英国, 德国, 法国, 意大利, 西班牙</span> 没有可用信息</p>
                                </div>
                            </div>
                            <div className="kc23_news">
                                <div className="kc23_news_box">
                                    <div>卖家状态 <img src="../../assets/images/kc23_kuozhan.png" alt=""/></div>
                                </div>
                                <div className="kc23_news_box2">
                                <img src={require("../../assets/images/kc23_meiguoguoqi.png").default} alt=""/>
                                    <select>
                                        <option>美国</option>
                                    </select>
                                </div>
                                <div className="kc23_news_box3">
                                    <div>
                                        <p>账户状况</p>
                                    </div>
                                </div>
                                <div className="kc23_news_box4">
                                    <p><img src={require("../../assets/images/kc23_dagou.png").default} alt=""/><span> 普通: </span>加拿大, 墨西哥, 日本</p>
                                    <p className="kc23_zhanting">已暂停:<span>英国, 德国, 法国, 意大利, 西班牙</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kc23_tab_r">
                        <p className="kc23_biaoti2">在北美销售商品的指南</p>
                        <div className="kc23_xiangguan">
                            <ul>
                                <li>北美联合账户 <IconFont type="icon-sanjiaoxing"  /></li>
                                <li className="kc23_xiala">
                                    <p>通过北美联合账户, 您可以创建并管理商品定价、定制商品信息并通过一个界面Amazon.com、Amazon.ca和Amazon.com.mx商城的定价和库存事宜。</p>
                                    <p>了解更多信息</p>
                                </li>
                                <li>北美税务和相关法规 <IconFont type="icon-sanjiaoxing"  /></li>
                                <li>管理北美报价<IconFont type="icon-sanjiaoxing"/></li>
                                <li>使用亚马逊物流<IconFont type="icon-sanjiaoxing"/></li>
                                <li>客户支撑和退货<IconFont type="icon-sanjiaoxing"/></li>
                                <li>全球账户的付款<IconFont type="icon-sanjiaoxing"/></li>
                                <li>更多工具<IconFont type="icon-sanjiaoxing"/></li>
                            </ul> 
                        </div>
                        <div className="kc23_spxinxi">
                            <p>您的商品信息</p>
                            <div className="kc23_xinxi_ul">
                                美洲
                                <ul>
                                    <li><img src={require("../../assets/images/kc23_meiguoguoqi.png").default} alt=""/><span>美国</span><span>150
                                            条商品信息</span><span><img src={require("../../assets/images/kc23_chakan.png").default} alt=""/> 查看</span></li>
                                    <li><img src={require("../../assets/images/kc23_jiananda.png").default} alt=""/><span>加拿大</span><span>47 条商品信息</span><span>
                                        <img src={require("../../assets/images/kc23_chakan.png").default} alt=""/> 查看</span>
                                        </li>
                                    <li><img src={require("../../assets/images/kc23_moxige.png").default} alt=""/><span>墨西哥</span>
                                    <span>1条商品信息</span><span><img src={require("../../assets/images/kc23_chakan.png").default} alt=""/> 查看</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="kc23_xinxi_ul">
                                欧洲
                                <ul>
                                    <li><img src={require("../../assets/images/kc23_yingguo.png").default} alt=""/><span>英国</span><span>13 条商品信息</span><span>
                                        <img src={require("../../assets/images/kc23_chakan.png" ).default} alt=""/> 查看</span></li>
                                    <li><img src={require("../../assets/images/kc23_deguo.png"  ).default} alt=""/><span>德国</span><span>19 条商品信息</span><span>
                                        <img src={require("../../assets/images/kc23_chakan.png" ).default} alt=""/> 查看</span></li>
                                    <li><img src={require("../../assets/images/kc23_faguo.png"  ).default} alt=""/><span>法国</span><span>30 条商品信息</span><span>
                                        <img src={require("../../assets/images/kc23_chakan.png" ).default} alt=""/> 查看</span></li>
                                    <li><img src={require("../../assets/images/kc23_yidali.png" ).default} alt=""/><span>意大利</span><span>16 条商品信息</span><span>
                                        <img src={require("../../assets/images/kc23_chakan.png" ).default} alt=""/> 查看</span></li>
                                    <li><img src={require("../../assets/images/kc23_xibanya.png").default} alt=""/><span>西班牙</span><span>14 条商品信息</span><span>
                                        <img src={require("../../assets/images/kc23_chakan.png" ).default} alt=""/> 查看</span></li>
                                </ul>
                            </div>
                            <div className="kc23_xinxi_ul">
                                亚太
                                <ul>
                                    <li><img src={require("../../assets/images/kc23_riben.png" ).default}alt=""/><span>日本</span><span>28 条商品信息</span><span>
                                        <img src={require("../../assets/images/kc23_chakan.png").default} alt=""/> 查看</span></li>
                                </ul>
                            </div>
                            <div className="kc23_zhanghu">
                                <p>您连接的账户<span> 了解更多信息</span></p>
                                <div className="kc23_lianjie">
                                    <div><span>美洲</span><img src={require("../../assets/images/kc23_lianjie.png").default} alt=""/><span
                                            className="kc23_lianjie_lan">欧洲</span></div>
                                    <div><span>美洲</span><img src={require("../../assets/images/kc23_lianjie.png").default} alt=""/><span
                                            className="kc23_lianjie_lan">日本</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>


                </div>

                <Footer/>
            </div>
        )
    }
    export default GlobalSales