import { ATable, AButton, AInput } from "@a-components";
import { Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useState, useImperativeHandle, forwardRef } from "react";
import { usePaginationTable } from "@hooks";
import { useUpdateEffect } from "ahooks";

const NegativeTable = forwardRef(
  (
    { fetcher, columns = [], createBtn = {}, searchInput = {}, exportBtn = {} },
    ref
  ) => {
    const { placeholder } = searchInput;
    const [searchValue, setSearchValue] = useState("");

    const {
      tableList,
      pagination,
      operator: { updatePageInfo, reFetch },
    } = usePaginationTable({ fetcher, params: { searchValue } });

    useUpdateEffect(() => {
      if (!searchValue) reFetch();
    }, [reFetch, searchValue]);

    useImperativeHandle(ref, () => ({
      updatePageInfo,
    }));

    return (
      <div>
        <div className="h-56 bg-f8 border-f0 df jc-sb ph-12 pt-12 pb-12">
          <Space>
            <AButton type="primary" onClick={createBtn.onClick}>
              {createBtn.text}
            </AButton>
            <AInput
              allowClear
              value={searchValue}
              placeholder={placeholder}
              prefix={<SearchOutlined />}
              onChange={(e) => setSearchValue(e.target.value)}
              onPressEnter={() => reFetch()}
            />
          </Space>

          <AButton onClick={exportBtn.onClick}>导出</AButton>
        </div>
        <ATable
          columns={columns}
          dataSource={tableList}
          pagination={pagination}
        />
      </div>
    );
  }
);

export default NegativeTable;
