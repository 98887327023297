import React from "react";
import { Button } from "antd";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // 你同样可以将错误日志上报给服务器
    console.error(error, errorInfo);
  }

  reload = () => {
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      // 你可以自定义降级后的 UI 并渲染
      return (
        <h1>
          页面报错了，<Button onClick={this.reload}>刷新</Button>
        </h1>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
