import React from 'react'
import Tour from 'reactour'
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import './reactours.css'
import 'animate.css/animate.min.css'

class Reactours extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTourOpen: false,
      // isTourOpen: false,
      spareIsTourOpen: this.props.isOpen,
      discountStep: 0
    }
  }
  disableBody = target => {
    disableBodyScroll(target)
  }
  enableBody = target => {
    enableBodyScroll(target)
  }
  componentWillMount() {
    let stepList = localStorage.getItem('stepList') || {};
    if (JSON.stringify(stepList) != "{}") {
      stepList = JSON.parse(stepList)
      if (this.props.discountStep) {
        if (stepList[this.props.routePath + this.props.discountStep]) {
          this.setState({
            isTourOpen: false
          })
        } else {
          this.setState({
            isTourOpen: this.props.isOpen
          })
        }
        stepList[this.props.routePath + this.props.discountStep] = true;
        this.setState({
          discountStep: this.props.discountStep
        })
      } else {
        if (stepList[this.props.routePath]) {
          this.setState({
            isTourOpen: false
          })
        } else {
          this.setState({
            isTourOpen: this.props.isOpen
          })
          stepList[this.props.routePath] = true
        }
      }
    } else {
      this.setState({
        isTourOpen: this.props.isOpen
      })
      if (this.props.discountStep) {
        stepList[this.props.routePath + this.props.discountStep] = true
      } else {
        stepList[this.props.routePath] = true

      }
    }
    setTimeout(() => {
      localStorage.setItem('stepList', JSON.stringify(stepList));
    }, 500)
  }
  componentDidMount() { }
  componentWillReceiveProps(newProps) {
    let { discountStep } = this.state;
    // if (!newProps.isTourOpen) {
    //   this.setState({
    //     isTourOpen: false
    //   })
    // }
    if (newProps.isActive) {
      this.setState({
        isTourOpen: true
      })
    } else {
      let stepList = localStorage.getItem('stepList') || {};
      if (JSON.stringify(stepList) != "{}") {
        stepList = JSON.parse(stepList)
      }
      if (newProps.discountStep) {
        if (discountStep != newProps.discountStep) {
          this.setState({
            isTourOpen: JSON.stringify(stepList) != "{}" && !stepList[newProps.routePath + newProps.discountStep] ? newProps.isOpen : false,
            discountStep: newProps.discountStep
          })
        }
      }
      if (newProps.discountStep) {
        stepList[newProps.routePath + newProps.discountStep] = true
      } else {
        stepList[newProps.routePath] = true
      }
      setTimeout(() => {
        localStorage.setItem('stepList', JSON.stringify(stepList));
      }, 500)
    }

  }
  requestClose = () => {
    var obj = document.getElementsByClassName("dQPfSq")[0];
    var tourBoxObj = document.getElementsByClassName("tourBox")[0];
    obj.style.animationName = "fadeInRightBig2";
    tourBoxObj.style.animationDuration = ".3s";
    tourBoxObj.style.animationName = "fadeInRightBig2";
    setTimeout(() => {
      this.setState({ isTourOpen: false })
    }, 200)
    document.body.style.overflowY = 'auto'
    this.props.clicks(false)
  }
  render() {
    let { isTourOpen } = this.state
    const accentColor = "#28bbac";
    return <Tour
      className="tourBox"
      {...this.props}
      isOpen={isTourOpen}
      onRequestClose={this.requestClose}
      accentColor={accentColor}
      closeWithMask={false}
      disableInteraction={false}
      startAt={0}
      onAfterOpen={target => (document.body.style.overflowY = 'hidden')}
      onBeforeClose={target => (document.body.style.overflowY = 'auto')}
    />
  }
}

export default Reactours