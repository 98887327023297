import { Form as AntForm } from "antd";
import withProps from "./../withProps";

const Form = ({ layout = "vertical", ...otherProps }) => {
  return <AntForm layout={layout} {...otherProps} />;
};

const AForm = withProps(Form, {
  className: "a-form",
});

AForm.useForm = (...args) => {
  return AntForm.useForm(...args);
};

export default AForm;
