import React, { useRef, useEffect, useCallback, useState } from 'react';
import AJAX from "api/apis";
import './previewTaxationInfo.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"
import '../default.css/default.css'
import { Button, Collapse, Input, Divider, Form, Select, Checkbox } from 'antd';
import { getSellerNationality } from '../../utils/regDate';
import { regSubmit, getStoreRegisterInfo } from '../../utils/PublicLibrary';

export default (props) => {
    let formRef = useRef();
    let [taxType, setTaxType] = useState(1)
    let [usaTaxType, setUsaTaxType] = useState(2)
    let [address, setAddress] = useState("")
    let [detaultDate, setDetaultDate] = useState()
    let [yearList, setYearList] = useState([])
    let [paytaxes_organization_mail_site, setPaytaxes_organization_mail_site] = useState("")
    const countrylity = getSellerNationality()
    const [isTourOpen, setIsTourOpen] = useState(true);
    const steps1 = [
        {
            selector: '.CheckboxClass',
            content: '勾选/提供电子签名',
        },
        {
            selector: '.stepGuidanceBox0',
            content: '法人签名，填写英文',
        },
        {
            selector: '.stepGuidanceBox1',
            content: '勾选实体签名',
        },
        {
            selector: '.stepGuidanceBox2',
            content: '选择日期',
        }
    ];
    const [isActive, setIsActive] = useState(false);
    const awakenTips = (type) => {
        setIsActive(type);
    }
    useEffect(() => {
        getContent()
        getDate()
    }, [])
    let getDate = useCallback(() => {
        let myDate = new Date();
        let year = myDate.getFullYear();
        let month = myDate.getMonth() + 1;
        let date = Number(myDate.getDate());
        setDetaultDate(year + "年" + month + "月" + date + "日")
        let list = []
        list.push(
            { label: `${year}年${month}月${date - 1}日`, value: `${year}年${month}月${date - 1}日` },
            { label: `${year}年${month}月${date}日`, value: `${year}年${month}月${date}日` },
            { label: `${year}年${month}月${date + 1}日`, value: `${year}年${month}月${date + 1}日` },
        )
        setYearList(list)
    }, [])
    const getContent = useCallback(() => {
        getStoreRegisterInfo().then((res) => {
            for (let item of res.data) {
                if (item.propertyValue) {
                    if (item.propertyKey == "personal_info_revenue_type") {
                        setTaxType(item.propertyValue)
                    }
                    if (item.propertyKey == "personal_info_is_America_person") {
                        setUsaTaxType(item.propertyValue)
                    }
                    if (item.propertyKey == "paytaxes_organization_name") {
                        formRef.current.setFieldsValue({
                            organizationName: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "paytaxes_organization_alias") {
                        formRef.current.setFieldsValue({
                            EntityName: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "paytaxes_organization_state") {
                        formRef.current.setFieldsValue({
                            whereCountry: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "paytaxes_organization_site") {
                        let arr = item.propertyValue.split("------")
                        setAddress(arr[6] + arr[5] + arr[3] + arr[2] + arr[1] + arr[4] + arr[0])
                    }
                    if (item.propertyKey == "paytaxes_organization_mail_site") {
                        setPaytaxes_organization_mail_site(item.propertyValue)
                    }
                    if (item.propertyKey == "is_electronic_sign") {
                        formRef.current.setFieldsValue({
                            agreeCheckbox: [item.propertyValue]
                        })
                    }
                    if (item.propertyKey == "personal_sign") {
                        formRef.current.setFieldsValue({
                            userName: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "is_sign_true") {
                        formRef.current.setFieldsValue({
                            signCheckbox: [item.propertyValue]
                        })
                    }
                    if (item.propertyKey == "survey_sign_date") {
                        formRef.current.setFieldsValue({
                            termYear: item.propertyValue
                        })
                        setDetaultDate(item.propertyValue)
                    }
                }
            }
        })
        AJAX.login.getStoreRegisterInfo([]).then((data) => {
            if (data.code == 1 && data.data) {
                for (let item of data.data) {
                    if (item.propertyValue) {
                        if (item.propertyKey == "personal_info_revenue_type") {
                            setTaxType(item.propertyValue)
                        }
                        if (item.propertyKey == "personal_info_is_America_person") {
                            setUsaTaxType(item.propertyValue)
                        }
                        if (item.propertyKey == "survey_sign_date") {
                            formRef.current.setFieldsValue({
                                termYear: item.propertyValue
                            })
                            setDetaultDate(item.propertyValue)
                        }
                        if (item.propertyKey == "paytaxes_organization_name") {
                            formRef.current.setFieldsValue({
                                organizationName: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "paytaxes_organization_alias") {
                            formRef.current.setFieldsValue({
                                EntityName: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "paytaxes_organization_state") {
                            formRef.current.setFieldsValue({
                                whereCountry: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "paytaxes_organization_site") {
                            let arr = item.propertyValue.split("------")
                            setAddress(arr[6] + arr[5] + arr[3] + arr[2] + arr[1] + arr[4] + arr[0])
                        }
                        if (item.propertyKey == "paytaxes_organization_mail_site") {
                            setPaytaxes_organization_mail_site(item.propertyValue)
                        }
                    }
                }
            }
        })
    }, [])
    let nextEvent = useCallback(() => {
        formRef.current.validateFields().then((res) => {
            let arr = [
                { "propertyKey": "personal_info_revenue_type", "propertyValue": taxType },
                { "propertyKey": "personal_info_is_America_person", "propertyValue": usaTaxType },
                { "propertyKey": "paytaxes_organization_name", "propertyValue": res.organizationName },
                { "propertyKey": "paytaxes_organization_alias", "propertyValue": res.EntityName || "" },
                { "propertyKey": "paytaxes_organization_state", "propertyValue": res.whereCountry },
                { "propertyKey": "is_electronic_sign", "propertyValue": res.agreeCheckbox[0] },
                { "propertyKey": "personal_sign", "propertyValue": res.userName },
                { "propertyKey": "is_sign_true", "propertyValue": res.signCheckbox[0] },
                { "propertyKey": "survey_sign_date", "propertyValue": res.termYear || detaultDate },

            ]
            regSubmit(arr, null, 'previewTaxationInfo', 7).then((data) => {
                if (data.code == 1) props.history.push('/TaxInvestigation')
            })
        })
    }, [taxType, usaTaxType, detaultDate])
    let taxBtn = useCallback((num) => {
        setTaxType(num)
    }, [])
    let usaBtn = useCallback((num) => {
        setUsaTaxType(num)
    }, [])
    function onCheckboxChange() { }
    return (
        <div className="previewtaxationInfoBox">
            {/* 引导步骤 */}
            <TipsIcon clicks={awakenTips} />
            <Reactours routePath="previewTaxationInfo" isActive={isActive} clicks={awakenTips} steps={steps1} isOpen={isTourOpen} />
            <Header />
            <div style={{ display: 'flex' }}>
                <div className="a-section program-overview-values">
                    <div className="CompanyAddress">
                        <Form ref={formRef}>
                            <div>
                                <h1 className="topTitle">个人信息</h1>
                                <hr aria-hidden="true" className="a-spacing-top-large a-divider-normal" />
                                <div>
                                    <p style={{ fontWeight: 'bold', paddingTop: '15px' }}>您的税收分类是什么？</p>
                                    <div className="btnClass">
                                        <span onClick={() => { taxBtn(1) }} className={taxType == 1 ? 'selectClass' : ''}>个人</span>
                                        <span onClick={() => { taxBtn(2) }} className={taxType == 2 ? 'selectClass' : ''}>业务</span>
                                    </div>
                                    <p style={{ paddingTop: '40px' }}>“个人”包括独资经营人或所有人为个人单一成员有限责任公司</p>
                                </div>
                                <hr aria-hidden="true" className="a-spacing-top-large a-divider-normal" />
                                <div>
                                    <p style={{ fontWeight: 'bold', paddingTop: '15px' }}>出于美国税务目的，您是美国人吗？</p>
                                    <div className="btnClass">
                                        <span className={usaTaxType == 1 ? 'selectClass' : ''} onClick={() => { usaBtn(1) }}>是</span>
                                        <span className={usaTaxType == 2 ? 'selectClass' : ''} onClick={() => { usaBtn(2) }}>否</span>
                                    </div>
                                </div>
                                <h1 className="topTitle nsInfoTitle">纳税身份信息</h1>
                                <div className="nsInfoBox">
                                    <div className="inpBox1">
                                        <div className="depositInfo">
                                            <p>组织名称</p>
                                            <Form.Item name="organizationName" rules={[{ required: true, message: '请输入组织名称' }]}>
                                                <Input placeholder="请输入组织名称" />
                                            </Form.Item>
                                        </div>
                                        <div className="depositInfo">
                                            <p>组织所在国家</p>
                                            <Form.Item name="whereCountry" rules={[{ required: true, message: '请选择国家/地区' }]}>
                                                <Select placeholder="选择国家/地区" options={countrylity} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="inpBox1">
                                        <div className="depositInfo">
                                            <p>无视实体名称（可选）</p>
                                            <Form.Item name="EntityName">
                                                <Input placeholder="请输入无视实体名称" maxLength="30" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <Divider>地址</Divider>
                                    <div className="inpBox1">
                                        <div className="depositInfo">
                                            <p>永久地址</p>
                                            <div>{address.replace(/------/g, "")}</div>
                                            <div>编辑</div>
                                        </div>
                                        <div className="depositInfo">
                                            <p>邮寄地址</p>
                                            <div>{paytaxes_organization_mail_site.replace(/------/g, "")}</div>
                                            {/* <Form.Item name="PermanentAddressCheckbox">
                                                <Checkbox.Group>
                                                    <Checkbox onChange={onCheckboxChange}>同永久地址</Checkbox>
                                                </Checkbox.Group>
                                            </Form.Item> */}
                                        </div>
                                    </div>
                                </div>
                                <h1 className="topTitle">保存预览</h1>
                                <hr aria-hidden="true" className="a-spacing-top-large a-divider-normal" />
                                <div className="CheckboxClass">
                                    <Form.Item name="agreeCheckbox" rules={[{ required: true, message: '请勾选该选项' }]}>
                                        <Checkbox.Group>
                                            <Checkbox value="1" onChange={onCheckboxChange}>我同意为依据IRS表格W-8BEN-E提供的信息提供电子签名</Checkbox>
                                        </Checkbox.Group>
                                    </Form.Item>
                                    <p>如果你能提供电子签名，就能够立即提交您的税务信息</p>
                                </div>
                                <div className="xyTable">
                                    <p className="bolds">在充分了解做伪证可能承担全部不良后果的前提下，我已尽我所能检查本表格中的信息，井确认信息详实无误。此外，在充分了解做伪证可能承担全部不良后果的前提下，本人进一步证明：</p>
                                    <p>1.本表第1行所列之法人是本表涉及的全部收入的受益所有人，使用本表证明其在第4章的身份，或因美国国家税务法案第6050W款之目的提交此表的商人，</p>
                                    <p>2.本表第1行所列之法人不是美国境内居民，</p>
                                    <p>3.本表所涉及的收入(a)不是与美国贸易或商业有实际联系的所得收益: (b) 虽有联系但根掘所得税协定不应缴税:或(c)合伙企业的实际关联收入中的合作伙伴的份额，</p>
                                    <p>4.对于中间交易或实物交易，收益人根据说明中的定义是免税的外国人。以及</p>
                                    <p>5.我同意如果此表上的任何证明有误，我将在30天内提交-份新表。</p>
                                    <p>此外，我授权可以将本表提供给任何对第1行所列法人为受益所有人的收入有控制、接收和监督功能的税务机构，或任何对以第1行所列法人为受益所有人的收入进行减退税实际支付的机构。</p>
                                    <p className="bolds">除了用以确立您非美国义务纳税人身份的此份证明外，美国国税局不需要征得您对此份文件所载条款的同意声明，(如果需要) 请确立您第四章的身份，如果适用，则可以享受降低的预扣税率。</p>
                                    <p style={{ fontWeight: 'bold', paddingTop: '30px' }}>签名（请使用您的全名）</p>
                                    <div className="stepGuidanceBox0">
                                        <Form.Item name="userName" rules={[{ required: true, message: '请输入您的全名' }]}>
                                            <Input placeholder="请输入您的全名" maxLength="20" />
                                        </Form.Item>
                                    </div>
                                    <div>通过在指定日期键入我的姓名，我确认正在签署税务文件，如有不实，愿以伪证罪接受处罚。</div>
                                    <Form.Item className="stepGuidanceBox1" name="signCheckbox" rules={[{ required: true, message: '请勾选该选项' }]}>
                                        <Checkbox.Group>
                                            <Checkbox value="1" onChange={onCheckboxChange}>兹证明我有能力代表此表第一行中显示的实体签字。</Checkbox>
                                        </Checkbox.Group>
                                    </Form.Item>
                                    <p style={{ fontWeight: 'bold', paddingTop: '30px' }}>日期</p>
                                    <div className="stepGuidanceBox2">
                                        <Form.Item name="termYear" rules={[{ required: true, message: '请选择日期' }]}>
                                            <Select placeholder={detaultDate} options={yearList} />
                                        </Form.Item>
                                    </div>
                                    <div>您可以将日期修改为前一天或之后一天来适应您的时区。</div>
                                </div>
                                <div className="operationBtn" style={{ textAlign: 'center' }}>
                                    <Button onClick={nextEvent} className='next' type="primary">保存并预览</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                {/* <div className="rightCollapse">
                    <p >常见问题</p>
                    <Collapse defaultActiveKey={['1']} onChange={callback}>
                        <Panel header="电话号码的正确格式是什么？" key="1">
                            <p>该数字的前缀必须是国家/地区代码。 下面是正确的格式：+1 1298279287</p>
                        </Panel>
                        <Panel header="如果我没有可接收 SMS 的手机号码，应该怎么办？" key="2">
                            <p>您可以输入您的固定电话号码，然后选择拨打电话选项以接收电话验证 PIN。</p>
                        </Panel>
                        <Panel header="如果我没有收到含有 PIN 的 SMS，应该怎么办？" key="3">
                            <p>检查您输入的电话号码格式是否正确。 再次请求 PIN。</p>
                        </Panel>
                        <Panel header="如果“呼叫”选项在我所在的国家/地区未列出，我该怎么办？" key="4">
                            <p>如果在您所在的国家/地区中，“呼叫”选项不可用，请选择“短信”选项。</p>
                        </Panel>
                        <Panel header="主要联系人是如何定义的？" key="5">
                            <p>主要联系人是指有权访问“亚马逊销售”付款帐户，代表帐户持有人（注册卖方）提供注册信息并发起交易（如付款和退款）的人。主要联系人采取的行动被视为帐户持有人采取的行动。</p>
                        </Panel>
                    </Collapse>
                </div> */}
            </div>
            <Footer />
        </div>
    )
}