import React, { useRef, useEffect, useCallback, useState } from 'react';
import AJAX from "api/apis";
import './ProductInfoTwo.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"
import '../default.css/default.css'
import { Link } from 'react-router-dom';
import { Button, Steps, Input, Form, Checkbox } from 'antd';
import { regSubmit, getStoreRegisterInfo } from '../../utils/PublicLibrary';


const { Step } = Steps;
export default (props) => {
    let formRef = useRef();
    const [isTourOpen, setIsTourOpen] = useState(true);
    const steps1 = [
        {
            selector: '.skip',
            content: '可跳过选择',
        },
        {
            selector: '.classTypeBox',
            content: '选择想要销售的类目',
        }
    ];
    const [isActive, setIsActive] = useState(false);
    const awakenTips = (type) => {
        setIsActive(type);
    }
    useEffect(() => {
        // getRegInfo()
    }, [])
    let getRegInfo = useCallback(() => {
        getStoreRegisterInfo().then((res) => {
            for (let item of res.data) {
                if (item.propertyValue) {
                    if (item.propertyKey == "commodity_type_id") {
                        formRef.current.setFieldsValue({
                            books: item.propertyValue,
                            tool: item.propertyValue,
                            motion: item.propertyValue,
                            toys: item.propertyValue,
                            cosmetology: item.propertyValue,
                            electronicProduct: item.propertyValue,
                            clothing: item.propertyValue,
                            automobile: item.propertyValue,
                            AmazonProducts: item.propertyValue,
                            collection: item.propertyValue
                        })
                    }
                }
            }
        })
    }, [])
    let nextEvent = useCallback(() => {
        formRef.current.validateFields().then((res) => {
            let commodity_type_ids = [
                ...res.books || [],
                ...res.tool || [],
                ...res.motion || [],
                ...res.toys || [],
                ...res.cosmetology || [],
                ...res.electronicProduct || [],
                ...res.clothing || [],
                ...res.automobile || [],
                ...res.AmazonProducts || [],
                ...res.collection || []
            ]
            let arr = [
                { "propertyKey": "commodity_type_id", "propertyValue": JSON.stringify(commodity_type_ids) }
            ]
            if (res.otherClass) {
                arr.push({ "propertyKey": "commodity_type_txt", "propertyValue": res.otherClass })
            }
            regSubmit(arr, null, 'ProductInfoTwo', 10).then((data) => {
                if (data.code == 1) {
                    props.history.push('/Authentication')
                }
            })
        })
    }, [])
    return (
        <div className="ProductInfoTwoBox">
            {/* 引导步骤 */}
            <TipsIcon clicks={awakenTips} />
            <Reactours routePath="ProductInfoTwo" isActive={isActive} clicks={awakenTips} steps={steps1} isOpen={isTourOpen} />
            <Header />
            <div>
                <div className="a-section program-overview-values">
                    <Steps current={4}>
                        <Step title="企业资讯" />
                        <Step title="卖家信息" />
                        <Step title="账单/存款" />
                        <Step title="税务信息" />
                        <Step title="商品信息" />
                    </Steps>
                    <div className="CompanyAddress">
                        <Form ref={formRef}>

                            <div className="ProductInfoTitle">告诉我们您的商品分类。您也可以稍后添加或编辑您的选择。</div>
                            <div className="skip">
                                <Link to="/Authentication">跳过</Link>
                            </div>
                            <div className="classTypeBox">
                                <div className="classType">
                                    <img src={require("@/assets/img/home/cipan.png").default}></img>
                                    <div className="classTypeChecbox">
                                        <p>图书、电影、音乐和游戏</p>
                                        <Form.Item name="books">
                                            <Checkbox.Group>
                                                <Checkbox value="图书">图书</Checkbox>
                                                <Checkbox value="音乐">音乐</Checkbox>
                                                <Checkbox value="乐器">乐器</Checkbox>
                                                <Checkbox value="视频游戏">视频游戏</Checkbox>
                                                <Checkbox value="视频、DVD和蓝光光盘">视频、DVD和蓝光光盘</Checkbox>
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="classType">
                                    <img src={require("@/assets/img/home/cipan.png").default}></img>
                                    <div className="classTypeChecbox">
                                        <p>家居、园艺和工具</p>
                                        <Form.Item name="tool">
                                            <Checkbox.Group>
                                                <Checkbox value="家居和园艺">家居和园艺</Checkbox>
                                                <Checkbox value="工具与家居装饰">工具与家居装饰</Checkbox>
                                                <Checkbox value="大型家电">大型家电</Checkbox>
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="classType">
                                    <img src={require("@/assets/img/home/cipan.png").default}></img>
                                    <div className="classTypeChecbox">
                                        <p>运动与户外用品</p>
                                        <Form.Item name="motion">
                                            <Checkbox.Group>
                                                <Checkbox value="户外运动">户外运动</Checkbox>
                                                <Checkbox value="体育用品">体育用品</Checkbox>
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="classType">
                                    <img src={require("@/assets/img/home/cipan.png").default}></img>
                                    <div className="classTypeChecbox">
                                        <p>玩具、婴幼儿用品</p>
                                        <Form.Item name="toys">
                                            <Checkbox.Group>
                                                <Checkbox value="婴儿用品">婴儿用品</Checkbox>
                                                <Checkbox value="玩具与游戏">玩具与游戏</Checkbox>
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="classType">
                                    <img src={require("@/assets/img/home/cipan.png").default}></img>
                                    <div className="classTypeChecbox">
                                        <p>美容、健康和杂货</p>
                                        <Form.Item name="cosmetology">
                                            <Checkbox.Group>
                                                <Checkbox value="美容">美容</Checkbox>
                                                <Checkbox value="杂货美食">杂货美食</Checkbox>
                                                <Checkbox value="健康与个人护理">健康与个人护理</Checkbox>
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="classType">
                                    <img src={require("@/assets/img/home/cipan.png").default}></img>
                                    <div className="classTypeChecbox">
                                        <p>电子产品和计算机</p>
                                        <Form.Item name="electronicProduct">
                                            <Checkbox.Group>
                                                <Checkbox value="照相机和照片">照相机和照片</Checkbox>
                                                <Checkbox value="手机和配件">手机和配件</Checkbox>
                                                <Checkbox value="消费类电子产品">消费类电子产品</Checkbox>
                                                <Checkbox value="办公用品">办公用品</Checkbox>
                                                <Checkbox value="个人电脑">个人电脑</Checkbox>
                                                <Checkbox value="软件">软件</Checkbox>
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="classType">
                                    <img src={require("@/assets/img/home/cipan.png").default}></img>
                                    <div className="classTypeChecbox">
                                        <p>服装、鞋和珠宝饰品</p>
                                        <Form.Item name="clothing">
                                            <Checkbox.Group>
                                                <Checkbox value="服饰及配件">服饰及配件</Checkbox>
                                                <Checkbox value="珠宝首饰">珠宝首饰</Checkbox>
                                                <Checkbox value="箱包和旅行配件">箱包和旅行配件</Checkbox>
                                                <Checkbox value="鞋类、手提包和太阳镜">鞋类、手提包和太阳镜</Checkbox>
                                                <Checkbox value="钟表">钟表</Checkbox>
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="classType">
                                    <img src={require("@/assets/img/home/cipan.png").default}></img>
                                    <div className="classTypeChecbox">
                                        <p>汽车和工业</p>
                                        <Form.Item name="automobile">
                                            <Checkbox.Group>
                                                <Checkbox value="汽车和动力运动">汽车和动力运动</Checkbox>
                                                <Checkbox value="工业科技">工业科技</Checkbox>
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="classType">
                                    <img src={require("@/assets/img/home/cipan.png").default}></img>
                                    <div className="classTypeChecbox">
                                        <p>亚马逊商品</p>
                                        <Form.Item name="AmazonProducts">
                                            <Checkbox.Group>
                                                <Checkbox value="亚马逊设备配件">亚马逊设备配件</Checkbox>
                                                <Checkbox value="Amazon Kindle">Amazon Kindle</Checkbox>
                                                <Checkbox value="Kindle配件和亚马逊Fire TV配件">Kindle配件和亚马逊Fire TV配件</Checkbox>
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="classType">
                                    <img src={require("@/assets/img/home/cipan.png").default}></img>
                                    <div className="classTypeChecbox">
                                        <p>收藏品</p>
                                        <Form.Item name="collection">
                                            <Checkbox.Group>
                                                <Checkbox value="硬币收藏品">硬币收藏品</Checkbox>
                                                <Checkbox value="娱乐收藏品">娱乐收藏品</Checkbox>
                                                <Checkbox value="体育收藏品">体育收藏品</Checkbox>
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="classType">
                                    <img src={require("@/assets/img/home/cipan.png").default}></img>
                                    <div className="classTypeChecbox">
                                        <p>为列出分类</p>
                                        <div>
                                            <p>您的分类是什么？</p>
                                            <Form.Item name="otherClass">
                                                <Input placeholder="可选" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="operationBtn" style={{ textAlign: 'center' }}>
                                <Button className='previousPage' type="primary">
                                    <Link to={{ pathname: '/ProductInfo', state: { isReturn: 1 } }}>返回</Link>

                                </Button>
                                <Button onClick={nextEvent} className='next' type="primary">完成注册</Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}