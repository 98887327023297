import React, { useRef, useEffect, useCallback, useState } from 'react';
import AJAX from "api/apis";
import "./index.css";
import cookieUtils from "utils/cookie";
import { Container, FormContainer, FormTitle, FormItemTop, BottomLine, ToLogin } from './style';
import { Form, Input, Button, Modal, message } from 'antd';
import HeaderImage from 'components/headerimage';
import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"
import { Link } from 'react-router-dom';


export default (props) => {
    let formRef = useRef();
    let [loading, setLoading] = useState(false);
    let [randoms, setRandoms] = useState();
    let [randomsR, setRandomsR] = useState(false);
    let [isTourOpen, setIsTourOpen] = useState(true);
    let steps = [
        {
            selector: '.stepGuidanceBox0',
            content: '亚马逊会给你提供的邮箱发一个验证码,输入验证码即可',
        }
    ];
    const [isActive, setIsActive] = useState(false);
    const awakenTips = (type) => {
        setIsActive(type);
    }
    console.log(props);
    if (props.location.state) {
        var [userEmial, setUserEmial] = useState(props.location.state.userEmial)
    } else {
        var [userEmial, setUserEmial] = useState('')
    }
    let [isModalVisible, setIsModalVisible] = useState(false)
    useEffect(() => {
        MathRand()
    }, [])
    let MathRand = useCallback(() => {
        let Num = "";
        for (let i = 0; i < 6; i++) {
            Num += Math.floor(Math.random() * 10);
        }
        setRandoms(Num)
    })
    let handleRegister = useCallback(() => {
        if (loading) return;
        setLoading(true);
        formRef.current.validateFields().then(({ userEmial }) => {
            setLoading(false);
            if (userEmial == randoms) {
                // props.history.push('/Agreements');
                setTimeout(() => {
                    // window.location.href = "/Agreements"
                    window.location.href = `/practice_amazon/#/mobileNumber?receptionStoreId=${window.receptionStoreId}`;
                }, 100)
            } else {
                message.error("验证码不一致")
            }
        }).catch(() => {
            setLoading(false);
        })
    }, [randoms])
    const emailModal = useCallback(() => {
        setIsModalVisible(true)
    })
    const hideModalVisible = useCallback(() => {
        setIsModalVisible(false)
    })
    return <Container className="sendEmailBox">
        {/* 引导步骤 */}
        <TipsIcon clicks={awakenTips} />
        <Reactours routePath="sendEmails" isActive={isActive} clicks={awakenTips} steps={steps} isOpen={isTourOpen} />
        <HeaderImage />
        <FormContainer>
            <FormTitle>输入确认码</FormTitle>
            <p>为了确认email，我们向{userEmial}发送了一个代码<Link to="/register" style={{ color: '#0066c0', cursor: "pointer" }}>(更改收件人地址)</Link></p>
            <Form ref={formRef}>
                <div className="stepGuidanceBox0">
                    <FormItemTop>
                        <p className='title'>输入验证码</p>
                    </FormItemTop>
                    <Form.Item
                        name="userEmial"
                        rules={[{
                            required: true,
                            validator: (rule, value, cbfn) => {
                                if (!value) {
                                    cbfn('请输入6位数验证码')
                                } else if (value.length < 6) {
                                    cbfn('验证码应为6位数')
                                } else if (Number(value) !== Number(randoms)) {
                                    cbfn('无效的验证码. 请检查您的验证码并重试。')
                                }
                                cbfn()
                            }
                        }]}>
                        <Input maxLength="6" />
                    </Form.Item>
                </div>
                {
                    randomsR && <div className='cxin'><i className='a-icon a-icon-alert' ></i>新验证码已发送至您的电子邮件。</div>
                }

                <Button className='next' type="primary" loading={loading} onClick={handleRegister}> 确认 </Button>
            </Form>
            <p onClick={() => {
                MathRand()
                setRandomsR(true)
            }} className='link_a' style={{ color: '#0066c0', cursor: "pointer", textAlign: 'center', marginTop: '20px' }}>重新发送验证码</p>
        </FormContainer>
        <div className="elailTips" onClick={emailModal}>
            <img src={require("@/assets/img/register/emailComing.png").default} />
        </div>
        <Modal className="meailModalBox" centered visible={isModalVisible} footer={null} onCancel={hideModalVisible}>
            <div className="titleLogo">
                <img src={require("@/assets/img/register/emailLogo.png").default} />
                <span>Password assistance</span>
            </div>
            <p className="verificationMsg">To authenticate, please use the following One Time Password (OTP):</p>
            <p className="otp">{randoms}</p>
            <p>Do not share this OTP with anyone. Amazon takes your account security very seriously. Amazon Customer Service will never ask you to disclose or verify your Amazon password, OTP, credit card, or banking account number. If you receive a suspicious email with a link to update your account information, do not click on the link—instead, report the email to Amazon for investigation.</p>
            <p>We hope to see you again soon.</p>
        </Modal>
        <BottomLine>
            <p>© 1996-2022, Amazon.com, Inc. or its affiliates</p>
        </BottomLine>
    </Container>
}