import React,{useCallback} from 'react';
import {Container,Btns} from './style';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import {loginOut} from 'store/actions/login';
import {Select,Input,Popover} from 'antd';
 
const { Option } = Select;
const { Search } = Input;

const HeaderRight = ({dispatch}) => {

    let languageData = [
        {
            value:'zh',
            label:'中文'
        },
        {
            value:'en',
            label:'English'
        }
    ]   

    const handleLoginOut = useCallback(() => {
        loginOut()
    },[]);
    const handleChangeLang = useCallback((lang) => {
        dispatch({
            type:'SET_LANG',
            lang
        })
    },[]);

    return <Container>
        {/* 语言 */}
        <Select className='language' defaultValue="zh" onChange={handleChangeLang}>
            {languageData.map((item,index)=><Option key={index} value={item.value}>{item.label}</Option>)}
        </Select>
        <Btns>
            <Link to="/BuyerMessage"><button><span>买家消息</span></button></Link>
            <button><span>帮助</span></button>
            <button>
                <Popover 
                    getPopupContainer={trigger => trigger.parentNode}
                    placement="bottomRight" 
                    trigger="hover"
                    content={
                        <ul>
                            <li onClick={handleLoginOut}>退出</li>
                            <li><Link style={{color:'#000'}} to="/accountinfo">账户信息</Link></li>
                            <li><Link style={{color:'#000'}} to="/distributionSet">配送设置</Link></li>
                        </ul>
                    } >
                    设置
                </Popover>
            </button>
        </Btns>
    </Container>
}

export default connect()(HeaderRight);