import { Descriptions } from "antd";
import { dataMixin } from "@utils/utils";
import { useCallback, useMemo, useState } from "react";
import { AButton } from "@a-components/";
import { UpOutlined } from "@ant-design/icons";

const computedKey = ({ label, key, index }) => {
  return `${key || label}-${index}`;
};

const Description = ({
  options = [],
  dataSource = {},
  onChange,
  store = {},
  ...otherParams
}) => {
  const innerOptions = useMemo(() => {
    return dataMixin(options, dataSource);
  }, [dataSource, options]);

  const [collapsibleInfo, setCollapsibleInfo] = useState(() => {
    const getInfo = (arr) => {
      return arr.reduce((r, item, index) => {
        if (item.content && item.content.length) {
          const key = computedKey({ ...item, index });
          Object.assign(r, getInfo(item.content));

          r[key] = false;
        }
        return r;
      }, {});
    };
    return getInfo(options);
  });

  const toggleCollapsibleInfo = useCallback((key) => {
    setCollapsibleInfo((pre) => {
      const value = pre[key];

      return {
        ...pre,
        [key]: !value,
      };
    });
  }, []);

  const setState = useCallback(
    (partValue) => {
      if (typeof partValue === "function") {
        return onChange(partValue(Object.freeze(dataSource)));
      }

      onChange?.({ ...dataSource, ...partValue });
    },
    [dataSource, onChange]
  );

  const renderDescriptionItems = useCallback(
    (options) => {
      return options.map(
        (
          {
            render,
            value,
            content = [],
            label,
            $visible = () => true,
            ...otherItem
          },
          index
        ) => {
          const key = computedKey({ label, key: otherItem.key, index });
          const isVisible = $visible(value, { record: dataSource, store });

          if (!isVisible) return null;

          const childrenNodes = collapsibleInfo[key]
            ? renderDescriptionItems(content)
            : null;
          const innerLabel = content.length ? (
            <div>
              <UpOutlined
                style={{
                  transform: collapsibleInfo[key]
                    ? "rotate(0deg)"
                    : "rotate(180deg)",
                }}
              />
              <AButton
                type="link"
                onClick={() => toggleCollapsibleInfo(key)}
                className="p-0 pl-12"
              >
                {label}
              </AButton>
            </div>
          ) : (
            label
          );

          return (
            <>
              <Descriptions.Item {...otherItem} key={key} label={innerLabel}>
                {render
                  ? render(value, { key, record: dataSource, setState, store })
                  : value}
              </Descriptions.Item>
              {childrenNodes}
            </>
          );
        }
      );
    },
    [collapsibleInfo, dataSource, setState, store, toggleCollapsibleInfo]
  );

  return (
    <Descriptions {...otherParams} column={1}>
      {renderDescriptionItems(innerOptions)}
    </Descriptions>
  );
};

export default Description;
