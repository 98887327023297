import { useCallback, useEffect, useMemo, useState } from "react";
import { Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import classNames from "classnames";

import { AInput, ASimplePagination } from "@a-components/";
import GoodsItem from "@b-components/GoodsItem";
import Apis from "@api/apis";
import { usePaginationTable } from "@hooks/";
import style from "./searchPane.module.css";
import AButton from "@a-components/AButton";
import { uglifyValue } from "@utils/utils";

function computedId(directionalId) {
  return uglifyValue({ directionalId, negativeDirectionalType: 2 });
}

const SearchPane = ({
  advertisingId,
  searchType = 1,
  advertisingGroupId,
  selectedList = [],
  updateList,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const selectedIds = useMemo(() => {
    return selectedList.map((v) => v.$id || computedId(v));
  }, [selectedList]);

  const queryGoodsPutNegativeList = useCallback(
    (params) => {
      if (!params.goodsNameGoodsSkuGoodsAsin)
        return Promise.resolve({ code: 2 });
      return Apis.goods.queryGoodsPutNegativeList({
        advertisingId,
        negativeOrPut: searchType,
        advertisingGroupId,
        ...params,
      });
    },
    [advertisingGroupId, advertisingId, searchType]
  );

  const {
    isTableLoading,
    tableList,
    pagination,
    operator: { resetPageIndex, clearTableList },
  } = usePaginationTable({
    fetcher: queryGoodsPutNegativeList,
    params: { goodsNameGoodsSkuGoodsAsin: searchValue },
    fetchAtMounted: false,
  });

  const realTableList = useMemo(() => {
    return tableList.map((item) => {
      return {
        ...item,
        directionalId: item.goodsId,
        directionalName: item.goodsName,
        negativeDirectionalType: 2,
        $id: computedId(item.goodsId),
      };
    });
  }, [tableList]);

  useEffect(() => {
    if (!searchValue) {
      clearTableList();
    }
  }, [clearTableList, searchValue]);

  const excludeGoodsItem = useCallback(
    (goodsInfo) => {
      return updateList([...selectedList, goodsInfo]);
    },
    [selectedList, updateList]
  );

  const renderExtra = useCallback(
    (goodsInfo) => {
      const isDisabled = selectedIds.includes(goodsInfo.$id);

      return (
        <AButton
          size="small"
          disabled={isDisabled}
          onClick={() => excludeGoodsItem(goodsInfo)}
        >
          {isDisabled ? "已" : ""}
          排除
        </AButton>
      );
    },
    [excludeGoodsItem, selectedIds]
  );

  return (
    <div className="">
      <div className="ph-12 pb-6 border-b-f0">
        <AInput.Search
          allowClear
          value={searchValue}
          prefix={<SearchOutlined />}
          placeholder="按商品名称或ASIN进行搜索"
          onChange={(e) => setSearchValue(e.target.value)}
          onSearch={() => resetPageIndex()}
          onPressEnter={() => resetPageIndex()}
        ></AInput.Search>
      </div>

      <Spin tip="Loading..." spinning={isTableLoading}>
        <div className={classNames(style.goodsList, "ph-12")}>
          {realTableList.map((item) => {
            return (
              <GoodsItem
                key={item.directionalId}
                info={item}
                extra={renderExtra}
              ></GoodsItem>
            );
          })}
          {!realTableList.length && (<div className="mt-24 text-center c-999">搜索要排除的商品。</div>)}
        </div>
      </Spin>

      <div className="df jc-fe pt-12">
        <ASimplePagination {...pagination} />
      </div>
    </div>
  );
};

export default SearchPane;
