import AdvertisingSuperTable from "@view/advertisement/AdvertisingSuperTable";
import StatisticsCard from "@view/advertisement/module/AdvertisingCampaign/component/StatisticsCard";

const Put = ({ advertisingGroupId }) => {
  return (
    <div>
      <StatisticsCard className="mb-24" />
      <AdvertisingSuperTable
        className="mt-12"
        businessType="adGroupPut"
        businessId={advertisingGroupId}
      />
    </div>
  );
};

export default Put;
