import { useCallback, useMemo, useState } from "react";
import { FormItem } from "@components/FormCollection";
import {
  ARadio,
  ARadioGroup,
  AInputMoney,
  ATable,
  ASwitch,
  AText,
  AForm,
  ATableForm,
} from "@a-components";
import { Button } from "antd";
import classNames from "classnames";
import style from "./style.module.css";
import {
  DEFAULT_AMOUNT,
  orientationGroupBidding,
  RECOMMEND_AMOUNT,
  deliveryGroupMapping,
} from "./config";

const DeliveryGroupTable = ({ form }) => {
  const budget = form.getFieldValue("budget");

  const amountRules = useMemo(() => {
    return [
      {
        validateTrigger: ["onChange", "onBlur"],
        validator(_, value) {
          if (value < 0.02) {
            return Promise.reject("竞价应至少为 $0.02");
          }

          if (value > budget) {
            return Promise.reject("竞价不应超过每日预算");
          }

          if (value > 1000) {
            return Promise.reject("竞价不应超过 $1,000.00");
          }

          return Promise.resolve();
        },
      },
    ];
  }, [budget]);

  const tableFormColumns = useMemo(
    () => [
      {
        title: "定向组",
        titleTooltip:
          "您可以为与广告中的商品匹配的搜索词和商品组选择不同的竞价。",
        name: "status",
        width: "30%",
        render(status, { row, updateField, index }) {
          const handleSwitch = (checked) => {
            updateField(index, "status", checked);
          };

          const { title, tooltip } = deliveryGroupMapping[
            row.deliveryGroupType
          ];

          return (
            <>
              <ASwitch
                checked={status}
                onChange={handleSwitch}
                size="small"
              ></ASwitch>
              <AText style={{ marginLeft: 6 }} tooltip={tooltip}>
                {title}
              </AText>
            </>
          );
        },
      },
      {
        title: "建议竞价",
        titleTooltip: "当此定向触发您的广告时您愿意为一次点击支付的最高金额。",
        width: "15%",
        name: "suggestedBidding",
        render(suggestedBidding, { row, updateField, index }) {
          return (
            <>
              {row.suggestedBidding ? (
                <>
                  <Button
                    onClick={() =>
                      updateField(index, "biddingPrice", suggestedBidding)
                    }
                    disabled={
                      !row.status || suggestedBidding === row.biddingPrice
                    }
                    type="link"
                    className="f-1 p-0 fs-12 h-12"
                  >
                    {suggestedBidding.toFixed(2)}
                  </Button>
                  <br />
                  <AText className="f-1 fs-12 c-999" disabled={!row.status}>
                    ${row.suggestedBiddingStart.toFixed(2)} - $
                    {row.suggestedBiddingEnd.toFixed(2)}
                  </AText>
                </>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
      {
        title: "竞价",
        titleTooltip: "当此定向触发您的广告时您愿意为一次点击支付的最高金额。",
        name: "biddingPrice",
        width: "15%",
        rules: amountRules,
        render(biddingPrice, { row, updateCurrentRow }) {
          return (
            <AInputMoney
              value={biddingPrice}
              onChange={(v) => updateCurrentRow("biddingPrice", v)}
              disabled={!row.status}
            />
          );
        },
      },
    ],
    [amountRules]
  );

  return (
    <ATableForm
      style={{ width: 600 }}
      columns={tableFormColumns}
      name="orientationGroupBidding"
      dependencies={["orientationGroupBidding"]}
      wrapperBorder={false}
    />
  );
};

const AutoDelivery = () => {
  return (
    <div className="auto-delivery">
      <AForm.Item
        noStyle
        dependencies={["budget", "autoDeliveryType", "orientationGroupBidding"]}
      >
        {(form) => {
          const autoDeliveryType = form.getFieldValue("autoDeliveryType");
          return (
            <AForm.Item noStyle name="autoDeliveryType">
              <ARadioGroup className="df fd-c">
                <div
                  className={classNames(
                    "auto-delivery__radio-item w-100percent"
                  )}
                  style={{ marginBottom: 8 }}
                >
                  <ARadio
                    tooltip={
                      "默认竞价适用于所有点击，除非您为关键词设置不同的竞价。您的竞价可能会发生变化，具体取决于您选择的竞价策略和广告位竞价涨幅。"
                    }
                    value={1}
                  >
                    设置默认竞价
                  </ARadio>
                  {autoDeliveryType === 1 ? (
                    <div className={classNames(style.bottomContainerBox, "")}>
                      <AForm.Item
                        noStyle
                        name="defaultBidding"
                        rules={[
                          {
                            type: "number",
                            max: "10000",
                            message: "竞价不应超过 $1,000.00",
                          },
                        ]}
                      >
                        <AInputMoney style={{ width: '25%', whiteSpace: 'nowrap' }} />
                      </AForm.Item>
                      <AForm.Item noStyle shouldUpdate>
                        {(form) => {
                          const defaultBidding = form.getFieldValue(
                            "defaultBidding"
                          );
                          const isSameAmount =
                            defaultBidding === RECOMMEND_AMOUNT;

                          const showAmountStr = RECOMMEND_AMOUNT.toFixed(2);

                          return (
                            <div className="fs-12">
                              建议竞价 : $
                              <Button
                                type={isSameAmount ? "text" : "link"}
                                className="p-0 fs-12 lh-12 h-12"
                                onClick={() =>
                                  form.setFieldsValue({
                                    defaultBidding: RECOMMEND_AMOUNT,
                                  })
                                }
                              >
                                {showAmountStr}
                              </Button>
                              ($0.95-$2.58)
                            </div>
                          );
                        }}
                      </AForm.Item>
                    </div>
                  ) : null}
                </div>
                <div
                  className={classNames(
                    "auto-delivery__radio-item w-100percent"
                  )}
                >
                  <ARadio value={2}>按定向组设置竞价</ARadio>

                  {/* {renderFormList(form, autoDeliveryType)} */}

                  {autoDeliveryType === 2 ? (
                    <div
                      style={{ width: 600 }}
                      className={classNames(
                        style.bottomContainerBox,
                        style.bottomContainerBoxBtm,
                        "df"
                      )}
                    >
                      <DeliveryGroupTable form={form} />
                    </div>
                  ) : null}
                </div>
              </ARadioGroup>
            </AForm.Item>
          );
        }}
      </AForm.Item>
      {/* </AForm> */}
    </div>
  );
};

export default AutoDelivery;
