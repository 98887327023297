import React, { Component } from 'react';
import './index.css'
class tests extends Component {
  constructor(props) {
    super(props);
    this.state = { 
        show:true,
        text:'隐藏'
     }
  }
  render() { 
    return ( 
      <div>
        <p className={this.state.show?'show':'hide'}>文字</p>
        <button onClick={this.toggle}>{this.state.text}</button>
      </div>
     );
  }
  toggle =()=>{
    var show = this.state.show;
    var text = this.state.text;
    if(show){
      show =false
      text = '显示'
    }else{
      show = true 
      text = '隐藏'
    }
    this.setState({
      show:show,
      text:text
    })
  }
}
 
export default tests;