import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from "components/home_header";
import Footer from "components/addlisting_footer/FooterCom";
import Reactours from "components/reactours";
import TipsIcon from "components/tipsIcon";
import "./index.css"

function Accountinfo() {
  let [accountInfoLeft] = useState([
    {
      title: "商品状态",
      tip: "假期设置",
      key: 1,
      list: [
        { id: 1, title: '商品的当前状态：', text: '在售 (商品在亚马逊上可售)' },
      ]
    },
    {
      title: "您的服务",
      tip: "管理",
      key: 2,
      list: [
        { id: 1, title: '我要开店', text: '专业', url: "( Amazon.com, Amazon.com.mx, Amazon.ca )" },
        { id: 2, title: '亚马逊企业购', text: '已注册', url: "( Amazon.com, Amazon.ca )" },
        { id: 3, title: '亚马逊物流', text: '已注册', url: "( Amazon.com, Amazon.com.mx, Amazon.ca )" },
        { id: 4, title: '商品推广', text: '商品推广', url: "( Amazon.com, Amazon.com.mx, Amazon.ca )" },
      ]
    },
  ]);

  let [accountInfoCenter] = useState([
    {
      title: "付款信息",
      key: 1,
      list: [
        { id: 1, title: '存款方式', path: "/accountinfo/depositMethod", class: 'stepGuidanceBox0' },
        { id: 2, title: '付费方式', path: "#" },
        { id: 3, title: '已开发票订单付款设置', path: "#" },
        { id: 4, title: '广告付费信息', path: "#" },
      ]
    },
    {
      title: "业务信息",
      key: 2,
      list: [
        { id: 1, title: '办公地址', path: "/accountinfo/companyAddress" },
        { id: 2, title: '公司名称', path: "#" },
        { id: 3, title: '正式注册地址', path: "#" },
        { id: 4, title: '卖家记号', path: "#" },
        { id: 5, title: '上传数据处理报告语言', path: "#" },
        { id: 6, title: '显示名称', path: "#" },
      ]
    },
    {
      title: "发货和退货信息",
      key: 3,
      list: [
        { id: 1, title: '退货信息', path: "/accountinfo/returnSettings" },
        { id: 2, title: '配送设置', path: "#" },
        { id: 3, title: '国际退货提供商', path: "#" },
        { id: 4, title: '“购买配送”偏好设置', path: "#" },
      ]
    },
    {
      title: "税务信息",
      key: 4,
      list: [
        { id: 1, title: '税务信息', path: "/taxationInfo", class: 'stepGuidanceBox1' },
        { id: 2, title: 'VAT 信息', path: "#" },
        { id: 3, title: 'RFC ID', path: "#" },
      ]
    },
  ]);
  let [accountInfoRight] = useState([
    {
      title: "常见问题",
      key: 1,
      list: [
        { id: 1, title: '如何更新税务信息和法定名称？', path: "#" },
        { id: 2, title: '我不小心注册了一个账户或者不再需要我的账户了。如何关闭账户？', path: "#" },
        { id: 3, title: '我要去度假。如何暂时停售商品？', path: "#" },
        { id: 4, title: '为什么不再销售我的商品？', path: "#" },
        { id: 5, title: '为何我的信用卡会发生错误或提示“信息无效”？', path: "#" },
      ]
    },
    {
      title: "账户管理",
      key: 2,
      list: [
        { id: 1, title: '通知首选项', path: "/accountinfo/notificationOptions" },
        { id: 2, title: '登录设置（更改姓名、电子邮件、密码和安全设置）', path: "#" },
        { id: 3, title: '用户权限', path: "#" },
        { id: 4, title: '降级账户', path: "#" },
        { id: 5, title: '关闭帐户', path: "#" },
      ]
    },
  ]);
  const steps = [
    {
      selector: '.stepGuidanceBox0',
      content: '点击进入存款方式',
    },
    {
      selector: '.stepGuidanceBox1',
      content: '点击进入税务信息',
    }
  ];
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const awakenTips = (type) => {
    setIsActive(type);
  }
  return (
    <div className="account_info" >
      <Header />
      <React.Fragment>
        <TipsIcon clicks={awakenTips} />
        <Reactours routePath="account_info" isActive={isActive} clicks={awakenTips} steps={steps} isOpen={isTourOpen} />
      </React.Fragment>
      <h2>卖家账户信息</h2>
      <div className="account_info_body" >
        <div className="account_info_left" >
          <div className="account_info_left_title" >
            <div>欢迎 Big Corn<span>（编辑）</span></div>
            <div>您的卖家资料</div>
          </div>
          {
            accountInfoLeft.map(item => {
              return (
                <div className="account_info_item" key={item.key} >
                  <div className="account_info_item_title" >
                    <div>{item.title}</div>
                    <div>{item.tip}</div>
                  </div>
                  {
                    item.list.map(val => {
                      return (
                        <div className="account_info_left_text" key={val.id} >
                          <div>{val.title}</div>
                          <div>
                            <p>{val.text}</p>
                            <div>{val.url}</div>
                          </div>
                        </div>
                      )
                    })
                  }

                </div>
              )
            })
          }

        </div>
        <div className="account_info_center" >
          {
            accountInfoCenter.map(item => {
              return (
                <div className="account_info_item" key={item.key} >
                  <div className="account_info_item_title" >
                    <div>{item.title}</div>
                  </div>
                  <div className="account_info_center_text">
                    {
                      item.list.map(val => {
                        return (
                          <div key={val.id} ><Link to={val.path} className={val.class || ''} >{val.title}</Link></div>
                        )
                      })
                    }
                  </div>


                </div>
              )
            })
          }
        </div>
        <div className="account_info_right" >
          {
            accountInfoRight.map(item => {
              return (
                <div className="account_info_item" key={item.key} >
                  <div className="account_info_item_title" >
                    <div>{item.title}</div>
                  </div>
                  <div className="account_info_right_text">
                    {
                      item.list.map(val => {
                        return (
                          <div key={val.id} ><Link to={val.path} >{val.title}</Link></div>
                        )
                      })
                    }
                  </div>


                </div>
              )
            })
          }
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Accountinfo