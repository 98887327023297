import React from 'react';
import {Container,} from './style';
import ShoppingHeader from 'components/shopping_header';

export default () => {

    return <Container>
        <ShoppingHeader/>
        
    </Container>
}