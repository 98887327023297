import React from 'react'
import './performance.css'
import '../../../default.css/default.css'
import IconFont from 'components/Icon/Icon'
function performance() {
    return (
        <div>

            <div className="kc20_content">

                <div className="kc20_navChild">
                    <p className="kc20_navChild-p1">库存绩效</p>
                    <ul>
                        <li>了解更多信息</li>
                        <li>为此页评价</li>
                        <li>观看演示</li>
                    </ul>
                </div>
                <div className="kc20_body-box">
                    <p className="kc20_body-p1">您的库存绩效指标以您在保持畅销商品有货、维持健康的库存水平和修复商品信息问题方面的表现为基础。</p>
                    <ul className="kc20_body-maxul">
                        <li>
                            <p className="kc20_body-li-p1">您的库存绩效指标</p>
                            <p className="kc20_body-li-p1">在 年的第 周</p>
                            <div className="kc20_tooltip">
                                <p className="kc20_triangle-p">没有足够的历史数据来计算您的IPIㅇ</p>
                                <span className="kc20_triangle"></span>
                            </div>
                            <div className="kc20_shadebox">
                                0
                                <div className="kc20_shade"></div>
                                1000
                            </div>
                            <p className="kc20_body-li-p1">基于历史绩效每周更新</p>
                        </li>
                        <li>
                            <p className="kc20_body-li-p1">重要影响因素</p>
                            <ul className="kc20_body-minul">
                                <li>
                                    <p>冗余库存</p>
                                    <div className="kc20_shadebox-r">
                                        <div className="kc20_shade2"></div>
                                    </div>
                                    {/* <img className="kc20_icon" src="../../../../assets/images/kc20_gou.png" /> */}
                                    <IconFont type="icon-wancheng" className="kc20_shade2_icon" />
                                </li>
                                <li>
                                    <p>售出率</p>
                                    <div className="kc20_shadebox-l">
                                        <div className="kc20_shade2"></div>
                                    </div>
                                    {/* <img className="kc20_icon" src="../../../../assets/images/kc20_jg.png" /> */}
                                    <IconFont type="icon-jinggao3-copy" className="kc20_shade2_icon" />

                                </li>
                                <li>
                                    <p>无在售信息的亚马逊库存</p>
                                    <div className="kc20_shadebox-l">
                                        <div className="kc20_shade2"></div>
                                    </div>
                                    {/* <img className="kc20_icon" src="../../../../assets/images/kc20_jg.png" /> */}
                                    <IconFont type="icon-jinggao3-copy" className="kc20_shade2_icon" />

                                </li>
                                <li>
                                    <p>有存货库存</p>
                                    <div className="kc20_shadebox-r">
                                        <div className="kc20_shade2"></div>
                                    </div>
                                    {/* <img className="kc20_icon" src="../../../../assets/images/kc20_gou.png" /> */}
                                    <IconFont type="icon-wancheng" className="kc20_shade2_icon" />

                                </li>
                            </ul>
                        </li>
                        <li>
                            <p className="kc20_body-li-p1">
                                <div className="kc20_dialogbox">
                                    <img className="kc20_icon" src="../../../../assets/images/kc20_qian.png" alt="" />
                                    在--中，您支付了--的亚马逊物流仓储费，或支付了您的亚马逊物流销售收入的--%。请按照建议提高您的指标，您将会发现您的盈利能力也会随之提高。
                                </div>
                            </p>
                        </li>
                    </ul>
                </div>
                <div className="kc20_body-box2">
                    <p className="kc20_box2-p1">改善绩效的方法</p>
                    <ul className="kc20_box2-ul">
                        <li className="kc20_lv">
                            <div className="kc20_box-l">
                                <p className="kc20_box2-li-p1">冗余库存百分比:<span className="kc20_lv-font">0.00%</span></p>
                                <p className="kc20_box2-li-p2">请按照建议措施提高盈利能力并节省仓储费。</p>
                                <div className="kc20_easier">
                                    <input type="checkbox" />
                                    <span >显示更多详情</span>
                                </div>
                            </div>
                            <div className="kc20_box-r">
                                <button>减少冗余库存(0)</button>
                            </div>
                        </li>
                        <li className="kc20_red">
                            <div className="kc20_box-l">
                                <p className="kc20_box2-li-p1"><IconFont type="icon-jinggao3-copy" className="kc20_shade2_icon" />亚马逊物流售出率:<span className="kc20_red-font">0.0</span></p>
                                <p className="kc20_box2-li-p2">采取措施,帮助提升商品的流量和销量</p>
                                <div className="kc20_easier">
                                    <input type="checkbox" />
                                    <span >显示更多详情</span>
                                </div>
                            </div>
                            <div className="kc20_box-r">
                                <button>提高出售率</button>
                            </div>
                        </li>
                        <li className="kc20_red">
                            <div className="kc20_box-l">
                                <p className="kc20_box2-li-p1"><IconFont type="icon-jinggao3-copy" className="kc20_shade2_icon" />无在售信息的亚马逊库存百分比:<span className="kc20_red-font">100.00%</span></p>
                                <p className="kc20_box2-li-p2">请修复商品信息，以让您的亚马逊物流库存在亚马逊上可供销售。</p>
                                <div className="kc20_easier">
                                    <input type="checkbox" />
                                    <span >显示更多详情</span>
                                </div>
                            </div>
                            <div className="kc20_box-r">
                                <button>修复商品信息(2)</button>
                            </div>
                        </li>
                        <li className="kc20_lv">
                            <div className="kc20_box-l">
                                <p className="kc20_box2-li-p1">亚马逊物流有存货率:<span className="kc20_lv-font">100.00%</span></p>
                                <p className="kc20_box2-li-p2">确保您的畅销商品有货，以免销售损失。</p>
                                <div className="kc20_easier">
                                    <input type="checkbox" />
                                    <span >显示更多详情</span>
                                </div>
                            </div>
                            <div className="kc20_box-r">
                                <button>今天补货(0)</button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>


        </div>
    )
}
export default performance