export default function (key) {
    //key存在先通过search取值如果取不到就通过hash来取
    let after = window.location.href.split("?")[1];
    if (after) {
        const reg = new RegExp("(^|&)" + key + "=([^&]*)(&|$)");
        let r = after.match(reg);
        if (r != null) {
            return decodeURIComponent(r[2]);
        } else {
            return null;
        }
    }
}

export function getRouteParams() {
    const search = window.location.search.substring(1); // 获取URL中的查询参数部分，去掉开头的"?"
    // 将查询参数字符串转换为对象形式
    const params = {};
    search.split('&').forEach(param => {
        const [key, value] = param.split('=');
        params[decodeURIComponent(key)] = decodeURIComponent(value);
    });
    return params;
}

export function buildRouteSearch(params) {
    // 将params对象转换为查询字符串形式的参数
    const queryParams = Object.keys(params)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');

    // 拼接路由和查询参数
    return `?${queryParams}`;
} 