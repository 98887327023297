import { useCallback, useEffect } from "react";
import {
  AText,
  AInputMoney,
  ARadioGroup,
  AInputRate,
  AButton,
} from "@a-components/";
import { WarningOutlined, InfoCircleOutlined } from "@ant-design/icons";
import classNames from "classnames";

const discountRadioOptions = [
  {
    label: "满减",
    value: 1,
  },
  {
    label: "减免折扣",
    value: 2,
  },
];
const limitRadioOptions = [
  {
    label: "是的，限制每个买家只能兑换一次",
    value: 1,
  },
  {
    label: "否，允许同一个买家多次兑换优惠券",
    value: 2,
  },
];

const BudgetDiscount = ({
  className,
  discountChoice,
  discountPrice,
  budgetPrice,
  exchangeTimes,
  onChange,
  onPassStatusChange,
}) => {
  const handleOutput = useCallback(
    (params) => {
      onChange?.({
        discountChoice,
        discountPrice,
        budgetPrice,
        exchangeTimes,
        ...params,
      });
    },
    [budgetPrice, discountChoice, discountPrice, exchangeTimes, onChange]
  );

  const handleDiscountChoiceChange = useCallback(
    (value) => {
      handleOutput({ discountChoice: value, discountPrice: "" });
    },
    [handleOutput]
  );

  const handleDiscountPriceChange = useCallback(
    (value) => {
      console.log(value)
      handleOutput({ discountPrice: value });
    },
    [handleOutput]
  );

  const handleExchangeTimesChange = useCallback(
    (value) => {
      handleOutput({ exchangeTimes: value });
    },
    [handleOutput]
  );
  const handleBudgetPriceChange = useCallback(
    (value) => {
      handleOutput({ budgetPrice: value });
    },
    [handleOutput]
  );

  useEffect(() => {
    const arr = [discountChoice, discountPrice, budgetPrice, exchangeTimes];
    onPassStatusChange?.(arr.every(Boolean));
  }, [
    budgetPrice,
    discountChoice,
    discountPrice,
    exchangeTimes,
    onPassStatusChange,
  ]);

  return (
    <div className={classNames(className, "df")}>
      <div className="f-1 border-r-ccc pr-24">
        <div className="mb-12 fs-18 fw-600">折扣</div>
        <div className="mb-12 fs-14">
          输入您想对上一步中添加至优惠券的商品应用的折扣金额。
        </div>

        <ARadioGroup
          className="mb-24"
          options={discountRadioOptions}
          value={discountChoice}
          onChange={(e) => handleDiscountChoiceChange(e.target.value)}
        />
        <br />

        {discountChoice === 1 ? (
          <AInputMoney
            style={{ width: 120 }}
            value={discountPrice}
            onChange={handleDiscountPriceChange}
          />
        ) : (
          <AInputRate
            style={{ width: 120 }}
            value={discountPrice}
            defaultValue={discountPrice}
            onChange={handleDiscountPriceChange}
          />
        )}

        <div className="mt-24 pt-24 border-t-ccc">
          <div className="mb-24">
            <AText strong>您是否要将优惠券限制到每个买家兑换 1 次？</AText>
          </div>

          <ARadioGroup
            options={limitRadioOptions}
            value={exchangeTimes}
            onChange={(e) => handleExchangeTimesChange(e.target.value)}
          />
        </div>
      </div>
      <div className="f-1 pl-24">
        <div className="mb-12 fs-18 fw-600">预算</div>

        <AInputMoney
          style={{ width: 120 }}
          min={100}
          value={budgetPrice}
          onChange={handleBudgetPriceChange}
        ></AInputMoney>
        <br />
        <AText strong>$100.00最低</AText>

        <div className="df mt-12">
          <WarningOutlined className="fs-24" />
          <AText strong className="ml-12">
            优惠券预算没有硬性限制。 <br />
            优惠券预算仅供用于规划目的，超出预算应在预料之中。了解更多信息
          </AText>
        </div>

        <div className="bg-f8 border-ccc border-radius-4 p-12 df mt-24">
          <InfoCircleOutlined className="fs-18 mr-12" />
          <div>
            <AText className="fs-12">您的预算将分摊到以下 2 项费用中：</AText>
            <br />
            <AText className="fs-12">
              您提供的折扣的同等金额（单位：USD） 兑换费（每次兑换需支付 $0.60）
            </AText>
            <br />
            <AText className="fs-12">
              当您优惠券的利用率达到 80% 时，它将被停用。
            </AText>
            <br />

            <AButton type="link" className="h-12 fs-12 p-0">
              预算如何运作？
            </AButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BudgetDiscount;
