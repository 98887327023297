import React from 'react';
import './RepairPriceReport.css';
import HomeHeader from 'components/home_header';
import Footer from '../../components/addlisting_footer/FooterCom';
import  '../default.css/default.css'
import IconFont from 'components/Icon/Icon';
import {Link} from 'react-router-dom'
function RepairPriceReport(){
    return(
        <div>
            <HomeHeader/>
            <div>
                
            <div className="kc28_content">
        <div className="kc28_tab">
            <ul className="kc28_tab-ul">
                <li>所有库存</li>
                <li>不活动(135)</li>
                <li>修复无在售信息的亚马逊库存(2)</li>
                <li>修复价格警告(1)</li>
                <li>品牌健康度(0)</li>
                <li>禁止显示</li>
                <li>管理定价</li>
                <li>亚马逊库存</li>
                <li>货件处理进度</li>
                <li>库存控制面板</li>
                <li>商品信息质量控制面板 <span>新功能</span></li>
            </ul>
        </div>
        <div className="kc28_title-box">
            <p className="kc28_title-p-l">修复价格警告<span>了解更多信息</span></p>
            <div className="kc28_title-box-r">
                <Link to="addlisting"><button>添加新商品</button></Link>
                <button>首选项: 隐藏 <span>0</span> 列</button>
            </div>
        </div>
        <div className="kc28_warnbox">  
		<IconFont type="icon-jinggao4" /> 
            <div>请检查并更新您的价格</div>
        </div>
        <div className="kc28_inpbox">
            <select>
                <option placeholder="volvo">应用于0件 选定商品</option>
            </select>
            <input type="text" placeholder="搜索 SKU、标题、ISBN" />
            <button>搜索</button>
             <span>1 件商品</span>
        </div>
		<div className="kc28_screen">筛选条件：</div>
		<div className="kc28_screen kc28_sn1">
			<ul className="kc28_screen-ul-l">
				<li>
					<div className="kc28_ct">
						<input  type="checkbox"/>
						状态
					</div>
				</li>
				<li>SKU</li>
				<li>
					商品名称
					<p className="kc28_gray">ASIN</p>
				</li>
			</ul>
			<ul className="kc28_screen-ul-r">
				<li>
					状况
				</li>
				<li className="kc28_orange">
					<div>创建日期<span className="kc28_orange_xsj"></span></div> 
					{/* <img src="../../assets/images/kc28_xsj.png" /> */}
					<p className="kc28_gray">状况更新日期</p>
				</li>
				<li className="kc28_blue">可售</li>
				<li className="kc28_blue">
					价格
					<p className="kc28_gray">+ 配送费</p>
				</li>
				<li>
					企业商品价格
					<p className="kc28_gray">+ 配送费</p>
				</li>
				<li>价格下限</li>
				<li>价格上限</li>
				<li className="kc28_blue">订单配送方</li>
			</ul>
			<button>保存所有</button>
		</div>
		<div className="kc28_screen kc28_sn2">
			<ul className="kc28_screen-ul-l">
				<li>
					<div className="kc28_ct">
						<input  type="checkbox"/>
						不可售
					</div>
					<div className="kc28_cuowu">
						{/* <img src="../../assets/images/kc28_js.png" /> */}
						<div className="kc28_cuowu_js"></div>
						可能的高定价错误
					</div>
				</li>
				<li>minhoushen</li>
				<li>
					Bingcute 6 Type of 30Pcs Bronze Vintage Large Skeleton Keys -Vintage Keys Charms Skeleton Key Set
					<p className="kc28_gray">B01HOLUsoY</p>
				</li>
			</ul>
			<ul className="kc28_screen-ul-r">
				<li>新品</li>
				<li >
					12/10/2020 07:13:43
					<p className="kc28_gray">12/13/2020 02:38:49</p>
				</li>
				<input type="text" placeholder="0"/>
				<input className="kc28_q" type="text" placeholder="50.00"/>
				<input className="kc28_q" type="text" placeholder="-"/>
				<input className="kc28_q" type="text" placeholder="-"/>
				<input className="kc28_q" type="text" placeholder="-"/>
				<li>卖家</li>
				<div className="kc28_sele">
					重新激活
				</div>
				<select>
					<option placeholder="重新激活"></option>
				</select>
			</ul>
			<ul className="kc28_position-ul">
				<li>
					<span className="kc28_hei">
						+ --
					</span>
					<p className="kc28_blue">标准美国模板-100美金</p>
				</li>
				<li>
					<span className="kc28_hei">
						+ --
					</span>
					<div >
						<div className="kc28_blue">添加数量</div>
						 {/* <img src="../../assets/images/kc28_xl.png" /> */}
						 <div className="kc28_img_xl"></div>
					</div>
				</li>
			</ul>
		</div>
	</div>



    {/* <div className="kc28_myfoot">
		<div className="kc28_page">
			<div className="kc28_page-l">
				<span >页面</span>
				<input type="text" placeholder="1" />
				<span>共 1 页</span>
				<button type="button">前往</button>
			</div>
			<div className="kc28_page-r">
				<div className="kc28_sele2">
					每页搜索10个结果
				</div>
				<select>
					<option></option>
				</select>
			</div>
		</div>
		<ul>
			<li>帮助</li>
			<li>计划政策</li>
			<li>
				<select>
					<option placeholder="volvo">中文</option>
				</select>
			</li>
			<li>©1999-2020 年，亚马逊公司或其附属公司</li>
		</ul>
	</div> */}

                
            </div>
            <Footer/>
        </div>
    )
}
export default RepairPriceReport
