import Apis from "@api/apis";
import { ASwitch } from "@a-components";
import { AText } from "@a-components/";

const columns = [
  {
    title: "有效",
    dataIndex: "status",
    key: "status",
    fixed: "left",
    render: (status, { advertisingDeliveryId, event$ }) => {
      const updateAdvertisingStatus = (checked) => {
        return Apis.advertising
          .updateAdvertisingDeliveryStatus({
            advertisingDeliveryIds: advertisingDeliveryId,
            status: checked,
          })
          .then((res) => {
            if (res.code === 1) event$.emit({ type: "reFetch" });
          });
      };
      return (
          <ASwitch checked={status} disabled={status == 3 ? true : false} onChange={updateAdvertisingStatus} />
      );
    },
  },
  {
    title: "自动定位组",
    dataIndex: "deliveryGroupTypeName",
    key: "2",
  },
  {
    title: "匹配类型",
    dataIndex: "matchTypeName",
    key: "2",
  },
  {
    title: "状态",
    dataIndex: "statusName",
    key: "3",
  },
  {
    title: "建议竞价",
    dataIndex: "suggestedBidding",
    key: "4",
  },
  {
    title: "竞价",
    dataIndex: "biddingPrice",
    key: "4",
  },
  {
    title: "曝光量",
    dataIndex: "exposure",
    key: "5",
  },
  {
    title: "点击次数",
    dataIndex: "clicksNum",
    key: "7",
  },
  {
    title: "点击率(CTR)",
    dataIndex: "clicksRate",
    key: "8",
  },
  {
    title: "花费",
    dataIndex: "costPrice",
    key: "9",
  },
  {
    title: "每次点击费用(CPC)",
    dataIndex: "singleCostPrice",
    key: "10",
  },
  {
    title: "订单",
    dataIndex: "orderNumber",
    key: "11",
  },
  {
    title: "销售额",
    dataIndex: "salesPrice",
    key: "12",
  },
  {
    title: "ACOS",
    dataIndex: "acosRate",
    key: "13",
  },
  {
    title: "ROAS",
    dataIndex: "roasRate",
    key: "14",
  },
];

const adGroupPutConfig = {
  tableColumns: columns,
  tableDataKey: "advertisingGoodsId",
  tableConfig: { scroll: { x: 1000 } },
  queryFetcher({ searchValue, businessId, ...otherParams }) {
    return Apis.advertising.getAdvertisingDeliveryList({
      ...otherParams,
      advertisingGroupId: businessId,
    });
  },

  $dependencies: [],
};

export default adGroupPutConfig;
