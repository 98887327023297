import clone from 'utils/clone';
let initState = {
    loading:{
        commodityClassificationLoading:false
    },
    commodityClassification:[]
};

export default (state = initState, action) => {
    switch(action.type) {
        //商品分类数据
        case 'SET_COMMODITY_CLASSIFICATION':
            return {
                ...state,
                commodityClassification:clone(action.commodityClassification)
            }
        case 'SET_COMMODITY_CLASSIFICATION_LOADING':
            return {
                ...state,
                loading:{
                    ...state.loading,
                    commodityClassificationLoading:action.loading
                }
            }
        default:
            return state;
   } 
}