import demo from './demo';
import headerNav from './header_nav';
import headerChild from './header_chlid';
import BrandRegistered from './brand_registered_header';

export default{
    ...demo,
    ...headerNav,
    ...headerChild,
    ...BrandRegistered,
}