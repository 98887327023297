import React, { useState } from 'react'
import InformationHead from './module/informationHead/informationHead'
import './information.css'
import IconFont from 'components/Icon/Icon'
import { Link } from 'react-router-dom'
import Footer from 'components/addlisting_footer/FooterCom'
import Header from 'components/home_header'
import { Modal, Button, Input, Card, Col, Row, Upload } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, CloseOutlined, InboxOutlined } from '@ant-design/icons';
const { Dragger } = Upload;

const Information = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [templateVisible, setTemplateVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setTemplateVisible(true);
  };
  return (
    <div className="information_body">
      <Header />
      <div className="Details_head">
        <div className="Details_headI">
          <div >
            <Link to="/Aa" style={{ color: "#008296" }}>A+商品描述管理器</Link>
          </div>
          <span>
            <IconFont type="icon-fanhui-copy-copy" />
          </span>
          <div>增强型商品描述</div>
          <span>
            <IconFont type="icon-fanhui-copy-copy" />
          </span>
          <div>编辑</div>
        </div>

        <div className="informationHead_headII">
          <div className="informationHead_headII_btn"><Link to="/Aa"><button>取消</button></Link></div>
          <div className="informationHead_headII_btnI"><button>保存为草稿</button></div>
          <div className="informationHead_headII_btnII"><Link to="/informationASIN"><button>下一步：应用ASIN</button></Link></div>
        </div>
      </div>



      <InformationHead />
      {
        !templateVisible && (
          <div className="infDetails_ModalBox">
            <div className="infDetails_ModalBtn"><button onClick={showModal} >添加模块</button></div>
          </div>
        )
      }
      {
        templateVisible && (
          <div className="infDetails_tempate" >
            <div className="infDetails_tempate-inner" >
              <h3>
                <span>带文本的标准图片标题</span>
                <span><ArrowUpOutlined /><ArrowDownOutlined /><CloseOutlined /></span>
              </h3>
              <Input placeholder="输入标题文本" style={{ marginBottom: 20 }} ></Input>
              <Dragger>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click to add image</p>
              </Dragger>
              <Input placeholder="输入标题文本" style={{ marginBottom: 20 }} ></Input>
              <div className="imageText_image_fontBox">
                <div className="imageText_fontBox_head">
                  <IconFont type="icon-jiacu" />
                  <IconFont type="icon-xieti" />
                  <IconFont type="icon-Underline" />
                  <IconFont type="icon-list" />
                  <IconFont type="icon-ol" />
                </div>
                <div>
                  <textarea>When using the Steam Sauna Box Black , insert the steam engine on the left</textarea>
                </div>
              </div>
            </div>
          </div>
        )
      }



      <Modal title="添加模板" width="95%" visible={isModalVisible} className="information_modal" onCancel={handleOk} footer={<Button onClick={handleOk} >取消</Button>} >
        <Input placeholder="搜索" style={{ marginBottom: 20 }} ></Input>
        <Row wrap >
          <Col onClick={handleOk} >
            <Card title="带文本的标准图片标签"
              cover={<img alt="example" src={require("@/assets/img/home/card-4.png").default} />}
              style={{ width: 300 }}>
              <p>Card content</p>
              <p>Card content</p>
            </Card>
          </Col>
          <Col style={{ display: 'flex' }} onClick={handleOk} >
            <div className="card-box" >
              <img alt="example" style={{ width: 200 }} src={require("@/assets/img/home/card-4.png").default} />
              <h4>bog--1</h4>
              <div>
                this bog
              </div>
            </div>
            <div className="card-box" >
              <img alt="example" style={{ width: 200 }} src={require("@/assets/img/home/card-4.png").default} />
              <h4>bog--1</h4>
              <div>
                this bog
              </div>
            </div>
            <div className="card-box" >
              <img alt="example" style={{ width: 200 }} src={require("@/assets/img/home/card-4.png").default} />
              <h4>bog--1</h4>
              <div>
                this bog
              </div>
            </div>
          </Col>
          <Col onClick={handleOk} >
            <Card title="标准公司徽标"
              cover={<img alt="example" src={require("@/assets/img/home/card-4.png").default} />}
              style={{ width: 300 }}>
            </Card>
          </Col>
        </Row>
      </Modal>
      <Footer />
    </div>
  )
}
export default Information