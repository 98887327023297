import React from 'react'
import AJAX from "api/apis";
import './fbaInbound.css'
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"
import { Checkbox, Modal, Button, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import getUrlData from "@/utils/getUrlData";

class fbaInbound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            btnLoading: false,
            goodsList: [],
            isModalVisible: false,
            ModalText: '',
            isReturn: false,
            isDeliveryStock: false,
            type: getUrlData('type'),
            urlType: 0,
            isTourOpen: true,
            steps1: [
                {
                    selector: '.fbaInboundBox .sendOut',
                    content: '选择转换并发送库存(如果选的FBM时，也转换为FBA)',
                }
            ],
            isActive: false
        }
    }
    componentWillMount() {
        let goodsList = JSON.parse(sessionStorage.getItem('goodsList'))
        let isDeliveryStock = true
        for (let items of goodsList.list) {
            items.checked = false
            if (items.goodsDispatchingType) {
                isDeliveryStock = false
            }
        }
        this.setState({
            goodsList: goodsList,
            isDeliveryStock: isDeliveryStock
        })
    }
    awakenTips = (type) => {
        this.setState({
            isActive: type
        })
    }
    checkedChange = (e, index) => {
        let { goodsList } = this.state
        goodsList.list[index].checked = e.target.checked
        this.setState({
            goodsList: goodsList
        })
    }
    sendInventory = (type) => {
        console.log(this.state.btnLoading, "this.state.btnLoading")
        if (this.state.btnLoading) return
        this.setState({
            btnLoading: true
        })
        let { goodsList } = this.state;
        let checkedSum = 0;
        for (let items of goodsList.list) {
            if (items.checked) {
                checkedSum += 1
            }
        }
        if (checkedSum == 0) {
            let urls = '/fbaConfirm'
            if (type == 2) urls = '/deliverGoods'
            this.props.history.push({
                pathname: urls,
                search: `?type=${type}`,
                state: {
                    type: type
                }
            })
            this.setState({
                btnLoading: false
            })
        } else if (checkedSum == goodsList.list.length) {
            this.setState({
                ModalText: '您已标记了所有商品准备清除，之后将返回库存列表，确定要清除这些商品吗？',
                isModalVisible: true,
                isReturn: true,
                urlType: type,
                btnLoading: false
            })
        } else {
            this.setState({
                ModalText: `您已标记了${checkedSum}件商品准备清除，确定要清除这些商品？`,
                isModalVisible: true,
                urlType: type,
                btnLoading: false
            })
        }
        console.log(checkedSum, "checkedSum")
        console.log(goodsList.list, "goodsList.list")

    }
    sendInventorymj = () => {
        let { goodsList } = this.state;
        let arr = [];
        if (goodsList.list.length > 0) {
            for (let items of goodsList.list) {
                if (!items.goodsDispatchingType) {
                    arr.push(items.goodsId)
                }
            }
            if (arr.length > 0) {
                AJAX.fba.updateGoodsDispatchingTypeThis(arr).then((res) => {
                    if (res.code == 1) {
                        this.props.history.push('/inventory')
                    }
                })

            } else {
                this.props.history.push('/inventory')
            }
        }
    }
    handleOk = () => {
        let { isReturn, goodsList, urlType } = this.state
        if (isReturn) {
            this.props.history.push('/inventory')
        } else {
            let arr = goodsList.list.filter((item, index) => {
                return !item.checked
            })
            goodsList.list = arr
            sessionStorage.setItem('goodsList', JSON.stringify(goodsList))
            let urls = '/fbaConfirm'
            if (urlType == 2) urls = '/deliverGoods'
            this.props.history.push(urls)
        }
    }
    handleCancel = () => {
        this.setState({
            isModalVisible: false
        })
    }
    render() {
        let { goodsList, isModalVisible, ModalText, isActive, isDeliveryStock, type, isTourOpen, steps1 } = this.state;
        return <div className="fbaInboundBox">
            <Header />
            <div>
                <div className="end05_myBody">
                    {/* <!-- 头部 --> */}
                    <div>
                        {type == 1 ? <div className="tableBox fbaBox fbaConfirm">
                            <div className="fbaTitle">转换为“亚马逊配送”</div>
                            <p>亚马逊物流要求具有能够由我们的扫描仪清晰读取的可扫描的唯一正确条形码。有两种类型的条形码可供您使用： </p>
                            <ul className="desc">
                                <li>当 ASIN 符合条件时，可使用商品上已有的制造商条形码 (UPC、EAN、JAN 或 ISBN)</li>
                                <li>在您的卖家账户中打印亚马逊条形码 (FNSKU) 并亲自粘贴</li>
                            </ul>
                            <p>有关更多信息，请参阅  或观看卖家大学上的  视频。</p>
                            <p className="desc">虽然使用制造商条形码追踪的库存是网络中无需贴标签的共享库存，但是我们仍会将不同卖家的库存置放在不同位置。可通过我们的配送系统追踪库存的来源，出现库存问题时，可以考虑这一因素。</p>
                            <div className="a-section-expander-container fbaTablle">显示 {goodsList.list.length || 0} 个　SKU</div>
                            <table border="1" className="stepGuidanceBox1" cellSpacing="0" cellPadding="0" bordercolor="#EEEEEE">
                                <thead>
                                    <tr>
                                        <th>卖家 SKU</th>
                                        <th>商品名称</th>
                                        <th>当前的订单配送方</th>
                                        <th>条形码类型</th>
                                        <th style={{ width: '20px' }}>标记为删除</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {goodsList.list ? goodsList.list.map((item, index) => {
                                        return <tr key={index}>
                                            <td style={{ width: '5%' }}>{item.goodsSku}</td>
                                            <td className="text-ellipsis-3">{item.goodsName}</td>
                                            <td style={{ width: '5px' }}>{
                                                !item.goodsDispatchingType ? <div><span>亚马逊<i> - 不需要转换</i></span></div> : '卖家'
                                            }</td>
                                            <td style={{ width: '50px' }}>仅限亚马逊条形码
                                                <Tooltip title="由于以下其中一种原因，无法使用制造商条形码追踪此商品：不是新品它对应亚马逊目录中的多个 ASIN亚马逊要求此商品必须具有亚马逊条形码亚马逊目录中不存在此条形码此 SKU 已分配有条形码首选项有关更多信息，请参阅 亚马逊物流商品条形码要求。">
                                                    <span style={{ color: 'blue' }}>为什么？</span>
                                                </Tooltip>
                                            </td>
                                            <td style={{ width: '1%' }}>
                                                <Checkbox checked={item.checked} onChange={(e) => { this.checkedChange(e, index) }}></Checkbox>
                                            </td>
                                        </tr>
                                    }) : null}
                                </tbody>
                            </table>
                            <div className="returnclass">
                                <span className="returnBtn">
                                    <Link to="/inventory"><img className="kc_10_btn2" src={require("@/assets/img/home/btns.png").default} />返回</Link>
                                </span>
                                {isDeliveryStock ? <div>
                                    <span className="transformation" onClick={() => { this.sendInventory(0) }}>只转换
                                        {/* <Link to="/fbaConfirm">只转换</Link> */}
                                    </span>
                                    <span className="sendOut" onClick={() => { this.sendInventory(2) }}>
                                        {/* <Link to="/deliverGoods">发送库存<img className="kc_10_btn2" src={require("@/assets/img/home/btns.png").default} /></Link> */}
                                        发送库存<img className="kc_10_btn2" src={require("@/assets/img/home/btns.png").default} />

                                    </span>
                                </div> : <div>
                                    <span className="transformation" onClick={() => { this.sendInventory(0) }}>只转换</span>
                                    <span className="sendOut" onClick={() => { this.sendInventory(1) }}>
                                        转换并发送库存<img className="kc_10_btn2" src={require("@/assets/img/home/btns.png").default} />
                                    </span>
                                </div>}
                            </div>
                        </div> : <div className="tableBox fbaBox">
                            <div className="fbaTitle">转换为“卖家自配送”</div>
                            <p>您已经选择当前由亚马逊配送的商品。如果将商品转换为“卖家自配送”，您将负责自行配送订单。注意：如果您在亚马逊运营中心中有这些商品的任何库存，这些商品将继续储存在那里，除非您请求将它们运回给您。</p>
                            <a className="moreBox">了解更多信息</a>
                            <div className="a-section-expander-container fbaTablle">显示 {goodsList.list.length || 0} 个　SKU</div>
                            <table border="1" cellSpacing="0" cellPadding="0" bordercolor="#EEEEEE">
                                <thead>
                                    <tr>
                                        <th>卖家 SKU</th>
                                        <th>商品名称</th>
                                        <th>当前的订单配送方</th>
                                        <th style={{ width: '20px' }}>标记为删除</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {goodsList.list ? goodsList.list.map((item, index) => {
                                        return <tr key={index}>
                                            <td style={{ width: '5%' }}>{item.goodsSku}</td>
                                            <td className="text-ellipsis-3">{item.goodsName}</td>
                                            <td style={{ width: '5px' }}>{
                                                !item.goodsDispatchingType ? '亚马逊' : <div><span>卖家<i> - 不需要转换</i></span></div>
                                            }</td>
                                            <td style={{ width: '1%' }}>
                                                <Checkbox checked={item.checked} onChange={(e) => { this.checkedChange(e, index) }}></Checkbox>
                                            </td>
                                        </tr>
                                    }) : null}
                                </tbody>
                            </table>
                            <div className="returnclass">
                                <span className="returnBtn">
                                    <Link to="/inventory"><img className="kc_10_btn2" src={require("@/assets/img/home/btns.png").default} />返回</Link>
                                </span>
                                <span className="transformation" onClick={this.sendInventorymj}>转换</span>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            <Footer />
            <Modal title="提示" visible={isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel} okText="确定" cancelText="取消">
                <p>{ModalText}</p>
            </Modal>
            {/* 引导步骤 */}
            <TipsIcon clicks={this.awakenTips} />
            <Reactours routePath="fbaInbound" isActive={isActive} clicks={this.awakenTips} steps={steps1} isOpen={isTourOpen} />
        </div >
    }
}
export default fbaInbound