import Apis from "@api/apis";
import { ASwitch } from "@a-components";
import { Link } from "react-router-dom";

const columns = [
  {
    title: "有效",
    dataIndex: 'advertisingGroupStatus',
    key: "advertisingGroupStatus",
    render: (status, { advertisingGroupId,advertisingStatus,event$ }) => {
      const updateAdvertisingStatus = (checked) => {
        return Apis.advertising
          .updateAdvertisingGroupStatus({
            advertisingGroupIds: advertisingGroupId,
            advertisingGroupStatus: checked ? 1 : 2,
          })
          .then((res) => {
            if (res.code === 1) event$.emit({ type: "reFetch" });
          });
      };
      return <ASwitch
      disabled={advertisingStatus == 3 || status == 3 ? true : false}
      checked={status === 1}
      onChange={updateAdvertisingStatus} />;
    },
    
  },
  {
    title: "广告组",
    dataIndex: "advertisingGroupName",
    key: "1",
    render(value, record) {
      return (
        <Link
          to={`/Advertisement/adGroupDetail?advertisingGroupId=${record.advertisingGroupId}`}
        >
          {value}
        </Link>
      );
    },
  },
  {
    title: "状态",
    dataIndex: "statusName",
    key: "2",
  },
  {
    title: "建议竞价",
    dataIndex: "suggestedBidding",
    key: "3",
  },
  {
    title: "默认竞价",
    dataIndex: "defaultPrice",
    key: "4",
  },
  {
    title: "定位总数",
    dataIndex: "positionNum",
    key: "5",
  },
  {
    title: "商品",
    dataIndex: "goodsNum",
    key: "6",
  },
  {
    title: "曝光量",
    dataIndex: "exposure",
    key: "7",
  },
  {
    title: "点击次数",
    dataIndex: "clicksNum",
    key: "9",
  },
  {
    title: "点击率(CTR)",
    dataIndex: "clicksRate",
    key: "8",
  },
  {
    title: "花费",
    dataIndex: "costPrice",
    key: "9",
  },
  {
    title: "每次点击费用(CPC)",
    dataIndex: "singleCostPrice",
    key: "10",
  },
  {
    title: "订单",
    dataIndex: "orderNumber",
    key: "11",
  },
  {
    title: "销售额",
    dataIndex: "salesPrice",
    key: "12",
  },
];
const filterConditionOptions = [
  {
    label: "筛选条件",
    value: "筛选条件",
  },
  {
    label: "活跃状态",
    value: "活跃状态",
  },
  {
    label: "类型",
    value: "类型",
  },
  {
    label: "投放",
    value: "投放",
  },
  {
    label: "预算",
    value: "预算",
  },
  {
    label: "曝光量",
    value: "曝光量",
  },
  {
    label: "点击次数",
    value: "点击次数",
  },
  {
    label: "点击率（CTR）",
    value: "点击率（CTR）",
  },
  {
    label: "花费",
    value: "花费",
  },
  {
    label: "每次点击费用(CPC)",
    value: "每次点击费用(CPC)",
  },
  {
    label: "订单",
    value: "订单",
  },
  {
    label: "销售额",
    value: "销售额",
  },
  {
    label: "广告投入产出比(ACOS)",
    value: "广告投入产出比(ACOS)",
  },
  {
    label: "广告支出回报(ROAS)",
    value: "广告支出回报(ROAS)",
  },
  {
    label: "新买家订单数",
    value: "新买家订单数",
  },
  {
    label: "新买家订单比",
    value: "新买家订单比",
  },
  {
    label: "新买家销量",
    value: "新买家销量",
  },
  {
    label: "新买家销量比",
    value: "新买家销量比",
  },
  {
    label: "几乎或超出预算",
    value: "几乎或超出预算",
  },
  {
    label: "广告组合",
    value: "广告组合",
  },
];
const adGroupConfig = {
  createBtn: ({ advertisingId }, history) => {
    return {
      children: "创建广告组",
      onClick() {
        history.push(
          `/advertisement/CreateAdvertisingGroup?${new URLSearchParams({
            advertisingId,
          }).toString()}`
        );
      },
    };
  },
  searchInput: {
    placeholder: "查找广告组",
  },
  tableColumns: columns,
  tableDataKey: 'advertisingGroupId',
  filterConditionOptions,
  queryFetcher({ searchValue, businessId, otherParams }) {
    return Apis.advertising.getAdvertisingGroupList({
      ...otherParams,
      advertisingId: businessId || "",
      advertisingGroupName: searchValue,
    });
  },

  $dependencies: ["advertisingId"],
};

export default adGroupConfig;
