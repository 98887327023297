import withProps from "@a-components/withProps";
import { Switch as AntSwitch } from "antd";

const Switch = withProps(
  (props) => {
    return <AntSwitch {...props} />;
  },
  {
    className: "a-switch",
  }
);

export default Switch;
