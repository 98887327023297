import React from "react"
import AJAX from "api/apis";
import "./Tab1.css"
import { Timeline } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import item from "components/home_footer/item";

class Tab1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productList: {},
            TimelineList: [
                {
                    titles: '已创建',
                    tesp: '货件货件详情已确认，标签已生成 正在等待承运人提供追踪信息。'
                },
                {
                    titles: '在途',
                    tesp: '当您提供承运人提供给您的追踪编码时，您的货件将进入在途状态。提供追踪编码有助于避免您的货件接收出现延迟。 了解更多信息'
                },
                {
                    titles: '已完成配送',
                    tesp: '亚马逊已收到来自承运人的信息，告知您的货件处于待配送状态，或已运送至运营中心院内，但运营中心尚未登记。'
                },
                {
                    titles: '已登记',
                    tesp: '当您的货件从场地移至垛口门并可以卸货时，货件将进入已登记状态。'
                },
                {
                    titles: '正在接收',
                    tesp: '当亚马逊扫描您的第一个货件编号标签时，您的货件将进入正在接收状态。已接收商品可供销售。商品可能会转运至距离买家更近的其他运营中心。 了解更多信息'
                }, {
                    titles: '已完成',
                    tesp: '当亚马逊已根据发货数量确认收到货件的所有商品，或者您的货件自创建之日起已打开超过 90 天时（以时间较早者为准），您的货件将进入已停售状态。亚马逊将继续接收已经在运输途中的货件。您不能为已完成的货件运送更多商品。'
                },
            ],
        }
    }
    componentWillReceiveProps(newProps) {
        if (newProps.planInfo) {
            this.setState({
                productList: newProps.planInfo
            })
        }

    }
    getFormRef = el => {
        this.form = el;
    }
    render() {
        let { TimelineList, productList } = this.state
        return <div className="atAglancetab1Box">
            <div className="warningBox">
                <svg t="1619337266171" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2038" width="32" height="32"><path d="M1001.661867 796.544c48.896 84.906667 7.68 157.013333-87.552 157.013333H110.781867c-97.834667 0-139.050667-69.504-90.112-157.013333l401.664-666.88c48.896-87.552 128.725333-87.552 177.664 0l401.664 666.88zM479.165867 296.533333v341.333334a32 32 0 1 0 64 0v-341.333334a32 32 0 1 0-64 0z m0 469.333334v42.666666a32 32 0 1 0 64 0v-42.666666a32 32 0 1 0-64 0z" fill="#1afa29" p-id="2039"></path></svg>
                <span>由于货件数量很多，接收货件所需的时间可能比平时长。 请注意： 我们无法手动加快货件接收流程，销售伙伴支持团队没有更进一步的指导信息。</span>
            </div>
            <div>
                “货件活动”页面提供有关从创建到完成货件的逐步更新，让您能够更好地查看库存状态。<span className="blurs" >了解更多信息</span>
            </div>
            <div className="TimelineBox">
                <Timeline mode="left">
                    {TimelineList.map((item, index) => {
                        return <Timeline.Item key={index} label={Number(productList.commodityPlanActivityStatus) == index ? productList.updateTime : null} dot={Number(productList.commodityPlanActivityStatus) == index ? <ClockCircleOutlined className="timeline-clock-icon" /> : null} color="red">
                            <div>
                                <p>{item.titles}</p>
                                <p>{item.tesp}</p>
                            </div>
                        </Timeline.Item>
                    })}
                </Timeline>
            </div>
        </div>
    }
}

export default Tab1