import React from 'react'
import './AutoPricing.css'
import '../../../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import IconFont from 'components/Icon/Icon'
function AutoPricing() {
    return (
        <div>
            <Header />

            <div >


                <div className="qd01_content">
                    <p className="qd01_navigation"> <span>自动定价</span> {">"} <span>创建定价规则</span> </p>
                    <div>
                        <span>自动定价</span>
                        <p><span>了解更多信息</span> | <span>提供反馈</span></p>
                    </div>
                    <h2>创建新定价规则</h2>
                    <div>
                        <div>
                            <h2>1 选择规则类型和名称</h2>
                            <p>
                                选择您想要创建的规则逻辑类型和规则名称。
                            </p>
                        </div>
                        <div>
                            <div>
                                <div>你想创建什么类型的规则?</div>
                                <select className="qd01_select">
                                    <option>有竞争力的购买按钮价格 </option>
                                </select>
                            </div>
                            <div className="qd01_select_middle">
                                <div>你想如何命名此规则?</div>
                                <input type="text" placeholder=" 定价" />
                            </div>
                            <div>
                                <button>开始选择商城</button>
                            </div>
                        </div>
                        <div className="qd01_rightBox" >
                            <div className="qd01_rightBox_titel">有哪些不同的规则?哪种规则适合您?</div>
                            <ul>
                                <li> <IconFont type="icon-sanjiaoxing" />  什么是有竞争力的最低价格规则?</li>
                                <li> <IconFont type="icon-sanjiaoxing" />  什么是有竞争力的购买按扭规则?</li>
                                <li> <IconFont type="icon-sanjiaoxing" />  什么是有竟争力的外部价格规则?</li>
                                <li> <IconFont type="icon-sanjiaoxing" />  什么是基于销量的规则?</li>
                                <li> <IconFont type="icon-sanjiaoxing" />  什么是企业商品价格的商品价格同步规则? </li>
                            </ul>
                            <p>
                                <span>注意</span> :创建好规则后，您将无法更改规划类型，需要其他类型的规则?创建新规则!
                            </p>

                        </div>
                    </div>
                    <div className="qd01_shopping">
                        2 商城
                    </div>
                    <div className="qd01_rule">
                        3 规则参数
                    </div>
                </div>



            </div>


            <Footer />

        </div>
    )
}

export default AutoPricing