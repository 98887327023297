import Header from "components/home_header";
import Footer from "components/addlisting_footer/FooterCom";

import { AButton, ASelect } from "@a-components/";
import { Space } from "antd";
import { useState, useCallback } from "react";
import { useHistory } from "react-router";

const typeListOptions = [
  {
    label: "SKU列表",
    value: 1,
  },
  {
    label: "ASIN列表",
    value: 2,
  },
  {
    label: "浏览分类节点编号列表",
    value: 3,
  },
  {
    label: "品牌名称列表",
    value: 4,
  },
  {
    label: "高级商品列表",
    value: 5,
  },
];

const ProductSelection = () => {
  const [activeType, setActiveType] = useState(1);

  const history = useHistory();

  const handleCreateGoodsList = useCallback(() => {
    history.push(`/Promotions/CreateProduceSelection?type=${activeType}`);
  }, [history, activeType]);

  return (
    <div>
      <Header />
      <div className="p-24">
        <div className="fs-24 ">创建商品列表</div>

        <div className="mt-12">
          <AButton onClick={() => history.push("/Promotions")}>
            管理商品列表
          </AButton>
        </div>

        <Space className="mt-12">
          <ASelect
            style={{ width: 200 }}
            options={typeListOptions}
            value={activeType}
            onChange={setActiveType}
          ></ASelect>
          <AButton type="primary" onClick={handleCreateGoodsList}>
            创建商品列表
          </AButton>
        </Space>
      </div>
      <Footer />
    </div>
  );
};

export default ProductSelection;
