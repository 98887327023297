import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 14px 18px;
`;

export const FormContainer = styled.div`
    width: 348px;
    padding: 20px 26px 10px 26px;
    border-radius: 4px;
    border: 1px #ddd solid;
    background-color: #fff;
    .ant-form .ant-form-item input#username,
    .ant-form .ant-form-item input#code,
    .ant-form .ant-form-item .ant-input-password{
        border: 1px solid #d9d9d9;
    }
    .ant-form .ant-form-item input#username:hover,
    .ant-form .ant-form-item input#code:hover,
    .ant-form .ant-form-item .ant-input-password{
        border: 1px solid #d9d9d9;
    }
    .ant-form .ant-form-item input#username:focus,
    .ant-form .ant-form-item input#code:focus,
    .ant-form .ant-form-item .ant-input-password.ant-input-affix-wrapper-focused{
        border-color: #d9d9d9;
        box-shadow: 0 0 3px 2px rgba(228,121,17,.5);
    }
    .ant-form .ant-form-item.ant-form-item-has-error{
        // margin-bottom: 14px;
    }
    .next{
        width: 100%;
        color: #111;border-color: #e77600;
        background: linear-gradient(to bottom,#f7dfa5,#f0c14b);
    }
    .next:hover{
        background: linear-gradient(to bottom,#f0c14b,#f0c14b);
    }
    .next:focus{
        box-shadow: 0 0 3px 2px rgba(228,121,17,.5);
    }
    div.code{
        position: relative;
    }
    button.get-message{
        position: absolute;
        right: 1px;
        top: 1px;
        height: 30px;
        cursor: pointer;
        border: none;
        outline: none;
        background-color: transparent;
    }
`;

export const FormTitle = styled.h1`    
    font-weight: 400;
    font-size: 28px;
    line-height: 1.2;
    margin-bottom: 10px;
    font-family: "Hiragino Sans GB","Microsoft Yahei",Arial,sans-serif;
    padding-bottom: 4px;
`;


export const FormItemTop = styled.div`
    display: flex;
    justify-content: space-between;
    &>p{
        margin: 0;
    }
    p.title{
        font-size: 13px;
        line-height: 19px;
        color: #111;
        padding: 0 0 2px 2px;
        font-weight: 700;
        font-family: "Hiragino Sans GB","Microsoft Yahei",Arial,sans-serif;
        margin: 20px 0 10px;
    }
`;

export const BottomLine = styled.div`
    width: 100%;
    height: 44px;
    margin-top: 25px;
    background: -webkit-linear-gradient(to bottom,rgba(0,0,0,.14),rgba(0,0,0,.03) 3px,transparent);
    background: linear-gradient(to bottom,rgba(0,0,0,.14),rgba(0,0,0,.03) 3px,transparent);
    z-index: 0;
    zoom: 1;
    &::after{
        display: block;
        width: 100%;
        height: 44px;
        content: "";
        background: linear-gradient(to right,#fff,rgba(255,255,255,0),#fff);    
    }
    p{
        font-size: 11px;
        text-align: center;
        padding-top: 50px;
    }
`;

export const ToLogin = styled.div`
    padding-top: 40px;
    &::before{
        display: block;
        width: 100%;
        height: 2px;
        background-color: #e7e7e7;
        content: "";
        margin-bottom: 30px;
    }
    p{
        margin: 0;
    }
`;
