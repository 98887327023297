import React from 'react';
import AJAX from "api/apis";
import { Link } from 'react-router-dom';
import { Container, } from './style';
import { Form, Input, Button, Checkbox } from 'antd';
import { stepControl } from "utils/PublicLibrary";

export default class Tab4Banner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [
                {
                    key: 'mall',
                    text: '商城',
                    value: 9
                },
                {
                    key: 'order',
                    text: '打开订单',
                    value: 0
                },
                {
                    key: 'toDaySales',
                    text: '今天的销售额',
                    value: 'US$0.00'
                },
                {
                    key: 'buyerNews',
                    text: '买家消息',
                    value: '0',
                    paths: '/BuyerMessage'
                },
                {
                    key: 'buyButtonWinningRate',
                    text: '购买按钮赢得率',
                    value: '--'
                },
                {
                    key: 'balance',
                    text: '总余额',
                    value: 'US$0.00'
                },
                {
                    key: 'ipi',
                    text: 'IPI',
                    value: '-'
                },
                {
                    key: 'globalSalesPromotion',
                    text: '全球促销销售',
                    value: '--'
                },
            ]
        }
    }
    componentDidMount() {
        this.getOrderMsgUnreadCount()
    }
    getOrderMsgUnreadCount = async () => {
        let { listData } = this.state
        const step = await AJAX.login.getStoreStep()
        // console.log(step);
        if (step.code == 1) {
            // stepControl()
            if (step.data == 5 || step.data >= 4 && step.extend >= 20) {
                AJAX.orderMsg.getOrderMsgUnreadCount().then((res) => {
                    if (res.code == 1 && res.data) {
                        this.setState({
                            listData: listData.map((item) => item.key == "buyerNews" ? { ...item, value: res.data } : item)
                        })
                    }
                })
            } else {
                if (step.extend != 20) stepControl(step.data, step.extend)
            }
        }
    }
    render() {
        let { listData } = this.state
        return <Container>
            <ul>
                {
                    listData.map((item) => <li key={item.key}>
                        <Link to={item.paths ? item.paths : '/'}><p className='text'>{item.text}</p>
                            <p className='value'>{item.value}</p></Link>
                    </li>)
                }
            </ul>
        </Container>

    }
}