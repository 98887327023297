import React, { useCallback, useEffect, useState } from "react";
import { AButton, AText, AInput } from "@a-components";
import { message, Modal, Space } from "antd";
import { MenuFoldOutlined } from "@ant-design/icons";
import classNames from "classnames";
import Apis from "@api/apis";

import style from "./style.module.css";
import { List } from "rc-field-form";

const normalGroups = [
  {
    text: "所有广告活动",
    id: 0,
  },
  {
    text: "广告组合",
    id: "adGroup",
  },
];
function Sidebar({ value, onChange, event$ }) {
  const [adGroups, setAdGroups] = useState([]);
  const [isShowGroups, setGroupsDisplayStatus] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalCombinationName, setModalCombinationName] = useState("");

  const renderGroups = useCallback(
    (groups) => {
      return groups.map((group) => {
        const isActive = group.id === value;
        return (
          <div
            key={group.id}
            className={classNames(
              style.groupItem,
              "lh-24 text-ellipsis-1 pv-6 ph-24",
              {
                active: isActive,
              }
            )}
            title={group.text}
            onClick={() => onChange?.(group.id)}
          >
            {group.text}
          </div>
        );
      });
    },
    [onChange, value]
  );

  const queryGroups = useCallback(() => {
    return Apis.advertisingCombination
      .getAdvertisingCombinationList()
      .then((res) => {
        if (res.code !== 1) return res;
        const adGroups = res.data.map((item) => ({
          text: item.advertisingCombinationName,
          id: item.advertisingCombinationId,
        }));
        setAdGroups(adGroups);
        return res;
      });
  }, []);

  const handleCreateCombination = useCallback(() => {
    setModalVisible(true);
  }, []);

  const handleModalCancel = useCallback(() => {
    setModalVisible(false);
    setModalCombinationName("");
  }, []);

  const handleModalSure = useCallback(() => {
    Apis.advertisingCombination
      .addAdvertisingCombination({
        advertisingCombinationName: modalCombinationName,
      })
      .then((res) => {
        event$?.emit({ type: "createAdGroupSuccess" });
        handleModalCancel();
        queryGroups();
        return res;
      });
  }, [event$, handleModalCancel, modalCombinationName, queryGroups]);

  useEffect(() => {
    queryGroups();
  }, [queryGroups]);

  event$?.useSubscription?.(({ type, payload }) => {
    switch (type) {
      case "showCreateAdGroupModal":
        handleCreateCombination();
        break;
      default:
    }
  });

  return (
    <div
      className={classNames(style.container, "border-r-ccc p-r h-100percent")}
    >
      <div style={{ display: isShowGroups ? "none" : "block" }}>
        <div className={classNames(style.groupList, "")}>
          {renderGroups(normalGroups)}
          <div className="border-b-ccc mh-12">
            <AButton
              type="link"
              className="p-0 fs-12 h-auto lh-22"
              onClick={handleCreateCombination}
            >
              +创建广告组合
            </AButton>
          </div>
          {renderGroups(adGroups)}
        </div>
      </div>
      <div className="p-a" style={{ right: -16, top: 200, zIndex: 1 }}>
        <MenuFoldOutlined
          className="fs-16"
          onClick={() => setGroupsDisplayStatus((p) => !p)}
        />
      </div>

      <Modal
        title="创建广告组合"
        visible={modalVisible}
        okText="创建广告组合"
        onCancel={handleModalCancel}
        footer={
          <Space>
            <AButton type="link" onClick={handleModalCancel}>
              取消
            </AButton>
            <AButton
              type="primary"
              disabled={!modalCombinationName}
              onClick={handleModalSure}
            >
              创建广告组合
            </AButton>
          </Space>
        }
      >
        <AText className="fs-12">
          按业务线、商品分类或季节整理广告活动，并使用预算上限管理总支出。
        </AText>
        <AButton type="link" className="fs-12 h-12 p-0">
          進一步瞭解
        </AButton>
        <br />

        <Space className="mt-12">
          <AText>广告组合名称</AText>
          <AInput
            style={{ width: 174 }}
            value={modalCombinationName}
            onChange={(e) => setModalCombinationName(e.target.value)}
          ></AInput>
        </Space>
      </Modal>
    </div>
  );
}
export default Sidebar;
