import { ATabs, AText, AButton } from "@a-components";
import "./style.css";
import KeywordSuggestion from "./KeywordSuggestion";
import SelectedKeywordsList from "./SelectedKeywordsList";
import KeywordsInput from "./KeywordsInput";
import { FormItem } from "@components/FormCollection";
import { updateSelectedKeywordsList } from "./keywordsList";

const KeywordDeliveryCard = () => {
  return (
    <div className="keyword-delivery-card df">
      <div className="f-1 left-box">
        <ATabs defaultActiveKey="1">
          <ATabs.TabPane tab="建议" key="1">
            <FormItem
              noStyle
              dependencies={["selectedGoodsList", "selectedKeywordsList"]}
            >
              {(form) => {
                const { selectedGoodsList, selectedKeywordsList } =
                  form.getFieldsValue([
                    "selectedGoodsList",
                    "selectedKeywordsList",
                  ]);

                const goodsIds = selectedGoodsList.map((v) => v.goodsId);
                const handleAddKeywords = (value) => {
                  updateSelectedKeywordsList(form, value);
                };

                return (
                  <KeywordSuggestion
                    goodsIds={goodsIds}
                    selectedKeywordsList={selectedKeywordsList}
                    onAddKeywords={handleAddKeywords}
                  />
                );
              }}
            </FormItem>
          </ATabs.TabPane>
          <ATabs.TabPane tab="输入列表" key="2">
            <FormItem
              noStyle
              dependencies={["selectedGoodsList", "selectedKeywordsList"]}
            >
              {(form) => {
                const handleAddKeywords = (value) => {
                  return updateSelectedKeywordsList(form, value);
                };

                return <KeywordsInput onAddKeywords={handleAddKeywords} />;
              }}
            </FormItem>
          </ATabs.TabPane>
          <ATabs.TabPane tab="上传" key="3">
            <div className="p-24">
              <AText>使用模板来确保您上传的信息准确无误。</AText>
              <br />
              <AButton className="mt-12">下载CSV模板</AButton>
              <div
                className="bg-f8 border-ccc border-radius-4 mt-12 df ai-c jc-c text-center c-999"
                style={{ height: 240 }}
              >
                拖放文件以添加 <br /> 或单击从您的计算机中选择 <br />
                可接受的格式：CSV, TSV, XLSX
              </div>
            </div>
          </ATabs.TabPane>
        </ATabs>
      </div>
      <div className="f-1">
        <SelectedKeywordsList />
      </div>
    </div>
  );
};

export default KeywordDeliveryCard;
