import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
    margin: 10px 20px 10px 20px;
    ul{
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        li{
            border: 1px solid rgb(151, 151, 151);
            margin: 0 20px 20px 0;
        }
        .card-1,
        .card-2,
        .card-6{
            .date{
                font-size: 10px;
                line-height: 1.6;
                color: rgb(155, 155, 155);
                text-transform: uppercase;
            }
            .text{
                color: #002f36;
                font-size: 13px;
                font-weight: 500;
                line-height: 1.23077;
                letter-spacing: 0.07px;
                margin: 0px;
                height: 32px;
                overflow: hidden;
                text-overflow: ellipsis;
                font-family: Amazon Ember,Arial,sans-serif!important;
            }
            .more{
                color: #008296;
            }
        }
        .card-3,
        .card-4,
        .card-5,
        .card-8,
        .card-10,
        .card-7{
            position: relative;
            .image{
                padding: 0;
                .text{
                    font-weight: 400;
                    line-height: 18px;
                    color: #002f36;
                    background-color: rgb(227, 236, 237);
                    padding: 10px 15px;
                }
                div{
                    height: 152px;
                    img{
                        disolay: block;
                        width: 100%;
                    }
                }
                .btn{
                    width: 100%;
                    height: 34px;
                    line-height: 34px;
                    text-align: center;
                    background-color: rgb(0, 130, 150);
                    color: rgb(255, 255, 255);
                    position: absolute;
                    bottom: 0;
                    left: 0;

                }
            }
        }
        .card-9{
            position: relative;
            p{
                margin: 0;
            }
            .text{
                font-weight: 400;
                line-height: 18px;
                color: #002f36;
                background-color: rgb(227, 236, 237);
                padding: 10px 15px;
            }
            div.list1{
                padding: 0;
                height: 85px;
                line-height: 85px;
                display: flex;
                border-bottom: 1px solid rgb(151, 151, 151);
                background-color: #fff;
                .number{
                    width: 50px;
                    font-size: 30px;
                    text-align: center;
                }
                .intro{
                    padding-left: 10px;
                    border-left: 1px solid rgb(151, 151, 151);
                }
            }
            div.list2{
                height: 85px;
                line-height: 85px;
                display: flex;
                padding: 0;
                .number{
                    width: 50px;
                    font-size: 30px;
                    text-align: center;
                }
                .intro{
                    padding-left: 10px;
                    border-left: 1px solid rgb(151, 151, 151);
                }
            }
            .btn{
                width: 100%;
                height: 34px;
                line-height: 34px;
                text-align: center;
                background-color: rgb(0, 130, 150);
                color: rgb(255, 255, 255);
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
        .card-11{
            background-color: rgb(250,234,234);
            position: relative;
            p{
                margin: 0;
            }
            .title{
                background-color: rgb(250,234,234) !important;
            }
            .text{
                font-weight: 800;
                line-height: 18px;
                color: #111;
                padding: 10px 15px;
            }
            div{
                color: #333;
            }
            
            .btn{
                width: 100%;
                height: 34px;
                line-height: 34px;
                text-align: center;
                background-color: rgb(178,10,50);
                color: rgb(255, 255, 255);
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
        .card-11.yl{
            background-color: rgb(255,245,209);
            .title{
                background-color: rgb(255,245,209) !important;
            }
            .btn{
                background-color: rgb(255,196,0);
                color: #333;
            }
        }
    }
`;

export const Item = styled.div`
    width: 280px;
    height: 290px;
    &>div{
        transform-origin:50% 50%;
        &.other-side{
            display: none;
        }
        div.title{
            display: flex;
            justify-content: space-between;
            padding: 10px 30px 10px 15px;
            color: rgb(0, 47, 54);
            font-size: 20px;
            font-weight: 700;
            line-height: 1.4;
            background-color: #fff !important;
            p{
                margin-bottom: 0px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            button{
                font-size: 20px;
                font-weight: 700;
                cursor: pointer;
                border: none;
                outline: none;
                background-color: #fff;
            }
        }
    }
    .positive>div{
        padding: 3px 30px 3px 15px;
        &:nth-child(odd){
            background-color: rgb(245, 249, 250);
        }
        p{
            margin: 0;
        }
    }
`;