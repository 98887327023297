import React, { useRef, useEffect, useCallback, useState } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import './index.css'

export default (props) => {
  const list = [
    {
      title: '企业资讯',
      key: 1,
    },
    {
      title: '卖家信息',
      key: 2,
    },
    {
      title: '账单',
      key: 3,
    },
    {
      title: '店铺',
      key: 4,
    },
    {
      title: '验证',
      key: 5,
    },
  ]
  const { current } = props
  return (
    <div className='registerSteps'>
      {
        list.map(item => {
          return (
            <div className='Steps-item' key={item.key}>
              <div className='line'></div>
              {
                item.key >= current ? (
                  <div className={['icon', current === item.key ? 'action' : ''].join(' ')}>
                    {item.key >= current ? item.key : null}
                  </div>
                ) : <CheckCircleFilled />
              }
              <div className={['title', current === item.key ? 'action' : '', current > item.key ? 'filled' : ''].join(' ')}>{item.title}</div>
            </div>
          )
        })
      }
    </div>
  )
}