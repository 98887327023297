import React, { useRef, useEffect, useCallback, useState } from 'react';
import './index.less'
import { Button, Modal } from 'antd';
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import { regSubmit } from 'utils/PublicLibrary';


export default (props) => {
  const [value, onChange] = useState(false);


  let nextEvent = useCallback(() => {
    regSubmit([], null, '', 15).then(res => {
      if (res.code == 1) {
        props.history.push('/twoStepVerification/SimulateLogin')
      }
    })
  })


  return (
    <div className='Enable'>
      <Header></Header>
      <div className='kat-col-xs-12'>
        <h1>启用两步验证</h1>
        <div>访问您的账户前，您必须为账户添加两步验证来进行保护。<Button>获取帮助</Button></div>
        <Button onClick={nextEvent} type='primary'>启用两步验证</Button>
      </div>
      <p>NCID: A3ILCWCF6LZT19 ZCID: A3ILCWCF6LZT19 PAID: A5VTOJR0ZRX72B MCID: A3ILCWCF6LZT19 MKID: A35IBJ27TRP1C1 When: May 25, 2021 6:49:59 AM</p>
      <Footer></Footer>
    </div>
  )
}