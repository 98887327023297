import React from "react"
import AJAX from "api/apis";
import { Pagination } from 'antd';
import { Link } from 'react-router-dom';
import "./Tab1.css"
import THeaderTab from "../../THeaderTab";

class Tab1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productList: [],
            page: 1,
            size: 10,
            total: 10
        }
    }
    componentWillMount() {
        this.getPlanList(this.state.page, this.state.size)
    }
    getPlanList = (page, size) => {
        let { productList } = this.state
        AJAX.fba.getCommodityPlanInfoList({ page, size }).then((res) => {
            if (res.code == 1 && res.data) {
                productList = res.data.list
                this.setState({
                    productList: productList,
                    total: res.data.total,
                    page: res.data.pageNum
                })
            }
        })

    }
    pageChonge = (page, size) => {
        this.setState({
            page: page,
            size: size
        })
        this.getPlanList(page, size)
    }
    ShowSizeChange = (page, size) => {
        this.setState({
            page: page,
            size: size
        })
        this.getPlanList(page, size)

    }
    getFormRef = el => {
        this.form = el;
    }
    render() {
        let { productList, page, total } = this.state
        return <div className="TabHeader-Tab1">
            <div className="TabHeader-Tab3-scope">
                <div>日期范围:</div>
                <select>
                    <option value="...">所有</option>
                    <option value="...">最近1天</option>
                    <option value="...">最近3天</option>
                    <option value="...">最近7天</option>
                    <option value="...">最近14天</option>
                    <option value="...">最近30天</option>
                    <option value="...">最近90天</option>
                    <option value="...">最近180天</option>
                    <option value="...">最近365天</option>
                    <option value="...">自定义</option>
                </select>
            </div>
            <THeaderTab></THeaderTab>
            <div className="THeader-Tab1-productList-title">
                <div className="TH-T1-pList-name TH-T1-pList-global">
                    货件名称
                </div>
                <div className="TH-T1-pList-number TH-T1-pList-global">
                    货件编号
                </div>
                <div className="TH-T1-pList-time TH-T1-pList-global">
                    创建时间
                </div>
                <div className="TH-T1-pList-update TH-T1-pList-global">
                    上次更新
                </div>
                <div className="TH-T1-pList-maku TH-T1-pList-global">
                    MSKU
                </div>
                <div className="TH-T1-pList-shipments TH-T1-pList-global">
                    与发货
                </div>
                <div className="TH-T1-pList-receive TH-T1-pList-global">
                    与收到
                </div>
                <div className="TH-T1-pList-destination TH-T1-pList-global">
                    目的地
                </div>
                <div className="TH-T1-pList-status TH-T1-pList-global">
                    状态
                </div>
            </div>
            {productList.map(item => {
                return (
                    <div className="THeader-Tab1-productList" key={item.id}>
                        <div className="TH-T1-pList-name TH-T1-pList-global">
                            {item.commodityPlanName}
                        </div>
                        <div className="TH-T1-pList-number TH-T1-pList-global">
                            {item.commodityPlanNumber}
                        </div>
                        <div className="TH-T1-pList-time TH-T1-pList-global">
                            {item.createTime}
                        </div>
                        <div className="TH-T1-pList-update TH-T1-pList-global">
                            {item.updateTime}
                        </div>
                        <div className="TH-T1-pList-maku TH-T1-pList-global">
                            {item.commodityPlanMskuCount}
                        </div>
                        <div className="TH-T1-pList-shipments TH-T1-pList-global">
                            {item.planAllGoodsCount}
                        </div>
                        <div className="TH-T1-pList-receive TH-T1-pList-global">
                            {item.commodityPlanHarvestCount}
                        </div>
                        <div className="TH-T1-pList-destination TH-T1-pList-global">
                            {item.commodityPlanAmazonAddRess}
                        </div>
                        <div className="TH-T1-pList-status TH-T1-pList-global">
                            {item.deleteFlag ? '已删除' : item.commodityStatus == 1 ? '处理中' : item.commodityStatus == 2 ? '即将发货' : item.commodityStatus == 3 ? '已发货' : null}
                        </div>
                        <div className="TH-T1-pList-button TH-T1-pList-global">
                            {item.deleteFlag ?
                                <Link to={{ pathname: '/atAglance', state: { id: item.id }, search: `?id=${item.id}`, }}><button>查看货件</button></Link>
                                : item.commodityStatus == 1 ?
                                    <Link to={{ pathname: '/completeShipment', state: { id: item.id }, search: `?id=${item.id}` }}><button>处理货件</button></Link>
                                    : item.commodityStatus == 2 ?
                                        <Link to={{ pathname: '/atAglance', state: { id: item.id }, search: `?id=${item.id}` }}><button>追踪货件</button></Link>
                                        : <Link to={{ pathname: '/atAglance', state: { id: item.id }, search: `?id=${item.id}` }}><button>追踪货件</button></Link>}
                        </div>
                    </div>
                )
            })}
            <Pagination
                current={page}
                total={total}
                showSizeChanger
                showQuickJumper
                className="pageBox"
                onChange={this.pageChonge}
                onShowSizeChange={this.ShowSizeChange}
            />
            <div style={{ fontSize: "12px" }}>
                *请注意，您必须自行根据库存所在地的规定进行报告、支付销售、使用、财产、收入、其他税务方面的费用。
            </div>
        </div>
    }
}

export default Tab1