import React, { useRef, useEffect, useCallback, useState } from 'react';
import AJAX from "api/apis";
import $ from 'jquery'
import "./shopupUL.css";
import IconFont from "../../../../components/Icon/Icon";
import { Upload, Button, Radio, message } from 'antd';
import { Link } from 'react-router-dom';
import Reactours from "components/reactours";
import TipsIcon from "components/tipsIcon";
class shopupUL extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 1,
      fileList: [],
      files: null,
      uploadLoading: false,
      isActive: false,
      isTourOpen: true,
      steps1: [
        {
          selector: '.ant-tabs-tab-active',
          content: '表格填写完毕，点击“上传您的库存文件”进行上传',
        },
        {
          selector: '.type-select',
          content: '选择相应的文件类型',
        },
        {
          selector: '.shopupDL-boxPD .shopupUL-type:nth-child(3) button',
          content: '点击选择文件，上传填写完毕的文件',
        },
        {
          selector: '.shopupDL-boxPD .shopupUL-type:nth-child(5) .shopupUL-checkbox',
          content: '选择处理报告形式，Excel/文本',
        },
        {
          selector: '.shopupUL-upBtn button',
          content: '点击上传即可',
        },
      ],
    }
  }
  componentWillMount() { }

  // 告知后端已上传
  addGoodsBatch = (readUrl) => {
    AJAX.addProduct.addGoodsBatch({ readUrl }).then(res => {
      if (res.code == 1) this.props.childToFatherValue("3")
    })
  }
  toFatherValues = () => {
    let { files, uploadLoading } = this.state
    if (uploadLoading) return
    this.setState({
      uploadLoading: true
    })
    AJAX.file.getFileTemporaryUploadUrl({ suffix: '.xlsm' }).then(res => {
      if (res.code == 1 && res.data) {
        $.ajax({
          type: "put",
          url: res.data.url,
          data: files,
          headers: { "Content-Type": "multipart/form-data", "x-oss-meta-author": res.data.author, "Access-Control-Allow-Origin": "*" }, //请求头类型
          processData: false,
          success: (data) => {
            this.setState({
              uploadLoading: false,
              fileList: [],
              files: null
            })
            this.addGoodsBatch(res.data.readUrl)
          },
          error: (err) => {
            this.setState({
              uploadLoading: false
            })
            if (err.status == 200) {
              this.setState({
                fileList: [],
                files: null
              })
              this.addGoodsBatch(res.data.readUrl)
            }
          }
        })
      }
    })
  }
  awakenTips = (type) => {
    this.setState({ isActive: type })
  }
  render() {
    const { uploading, fileList, uploadLoading, isActive, steps1, isTourOpen, } = this.state;
    const props = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: [],
            files: null
          };
        });
      },
      beforeUpload: file => {
        const isJpgOrPng = file.type === 'application/vnd.ms-excel.sheet.macroEnabled.12';
        if (!isJpgOrPng) {
          message.error('上传文件格式错误！');
          return;
        }
        this.setState(state => ({
          fileList: [],
          fileList: [file],
          files: file
        }));
        return false;
      },
      fileList,
    };
    return <div className="shopupDL-body" style={{ paddingBottom: "100px" }}>
      <div className="shopupDL-lbox">
        <div className="shopupDL-bor">
          <div className="shopupDL-boxHed">上传文件</div>
          <div className="shopupUL-navBody">
            <div className="shopupDL-boxPD">
              <div className="shopupUL-type">
                <div>文件类型</div>
                <div>
                  <div className="shopupUL-label" className="type-select">
                    <label>
                      <select
                        style={{
                          background: "#eff1f3",
                          border: "1px solid #000",
                          width: "183px",
                        }}
                      >
                        <option>用于特定分类的库存文件</option>
                        <option>标准图书加载工具文件</option>
                        <option>图书的UIEE文件</option>
                        <option>音乐加载工具文件</option>
                        <option>视频加载文件</option>
                        <option>库存加载工具文件</option>
                        <option>价格和数量文件</option>
                        <option>商品信息质量和禁止显示商品文件（新建）</option>
                        <option>自动定价文件（新建）</option>
                        <option>非亚马逊库存文件</option>
                      </select>
                    </label>
                  </div>
                </div>
              </div>
              <div className="shopupUL-labelBtm">
                库存文件可用于创建新品种，并将商品添加到亚马逊目录中
                <a>了解更多信息</a>
              </div>
              <div className="shopupUL-type">
                <div>文件上传</div>
                <div className="UploadBox">
                  <Upload {...props}>
                    <Button>选择文件</Button>
                  </Upload>
                  <div>
                    <span style={{ color: "#d57a84" }}>新建</span>
                    您可以上传Excel 格式的库存文件。
                  </div>
                </div>
              </div>
              <div className="shopupUL-type">
                <div>邮件提醒</div>
                <div>
                  <div>
                    发送邮件提醒 <input placeholder="email@example.com" />{" "}
                    (当上传完成时) 。
                  </div>
                </div>
              </div>
              <div className="shopupUL-type">
                <div>处理报告格式</div>
                <div className="shopupUL-checkbox">
                  <Radio.Group name="radiogroup" defaultValue={1}>
                    <Radio value={1}> Excel - 推荐</Radio><br />
                    <Radio value={2}> 文本</Radio>
                  </Radio.Group>
                </div>
                <div>Excel 格式会在相应单元格中突出显示错误和警告，使您能够一目了然地找出提交内容中的问题。</div>
              </div>
              <div className="shopupUL-upBtn">
                <Button loading={uploadLoading} disabled={fileList.length > 0 ? false : true} onClick={this.toFatherValues}>上传</Button>
              </div>
            </div>
          </div>
        </div>

        <div className="shopupDL-bor">
          <div className="shopupDL-boxHed-btm">
            <div style={{ color: "rgb(200, 50, 0)" }}>清除/替换您的库存</div>
            <div>
              <IconFont type="icon-fanhui-copy-copy" style={{ transform: "rotate(90deg)", fontSize: "24px" }} />
            </div>
          </div>
        </div>
      </div>
      <div className="shopupDL-rbox">
        <div
          className="shopupDL-warnBox"
          style={{ border: "1px solid #3479ac", padding: "10px 0" }}
        >
          <div className="shopupDL-rBox-warnHed">
            <IconFont
              type="icon-jinggao1"
              className="RepertoryTable1-box1-font"
              style={{ color: "#3489ac", margin: "0 20px" }}
            />
            <div>想要使用自己的目录文件添加商品？</div>
          </div>
          <div className="shopupDL-rBox-warnnav">
            <div >
              使用<a style={{ color: " #0066c0" }}>准备您的商品</a>工具，通过上传任意Excel、文本或CSV
              文件来批量添加商品。您也可以上传在自己的电子商务网站
              或其他商城中用来销售商品的文件。
            </div>
          </div>
        </div>

        {/* 常见问题 */}
        <div className="shopupDL-bor">
          <div className="shopupDL-boxHed">常见问题</div>
          <div className="shopupDL-boxPD">
            <div style={{ padding: "10px 20px" }}>
              <ul>
                <li><Link>如何批量修复禁止显示的商品？</Link></li>
                <li><Link>上传库存文件？</Link></li>
                <li><Link>如何使用不同的库存文件模板？</Link></li>
                <li><Link>修改我的库存文件？</Link></li>
              </ul>
            </div>
          </div>
        </div>
        {/* 相关主题 */}
        <div className="shopupDL-bor">
          <div className="shopupDL-boxHed">常见问题</div>
          <div className="shopupDL-boxPD">
            <div style={{ padding: "10px 20px" }}>
              <ul>
                <li><Link>使用库存文件</Link></li>
                <li><Link>构建我的库存文件</Link></li>
                <li><Link>修改我的库存文件</Link></li>
                <li><Link>创建库存文件模板</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <TipsIcon clicks={this.awakenTips} />
      <Reactours routePath="shopupUL" isActive={isActive} clicks={this.awakenTips} steps={steps1} isOpen={isTourOpen} />

    </div>
  }
}
export default shopupUL;
