import React from 'react'
import './custom.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import { Tabs } from "antd";
import Activity from './module/activity/activity'
import Archlve from './module/Archive/Archlve'
function custom(){
  const { TabPane } = Tabs;

    return(
        <div className="Custom_body">
            <Header/>
            <div className="Custom_titelBox">
            <div className="Custom_titel">自定义报告</div>
            <div className="TabHeader "> 
            <Tabs defaultActiveKey="1" className="tab2">
              <TabPane tab="活动报告" key="1">
                <Activity/>
              </TabPane>
              <TabPane tab="存档报告" key="2"> 
                    <Archlve/>
              </TabPane>
            </Tabs>
            </div>
        </div>
        <Footer/>
        </div>
    )
}

export default custom