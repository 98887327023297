import React, { useRef, useEffect, useCallback, useState } from 'react';
import AJAX from "api/apis";
import './TaxInvestigation.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import '../default.css/default.css'
import { Link } from 'react-router-dom';
import { Button, Collapse, Input, Form, Checkbox } from 'antd';
import { regSubmit, getStoreRegisterInfo } from '../../utils/PublicLibrary';

const options = [
    { label: 'Individual', value: 'Individual' },
    { label: 'Corporation', value: 'Corporation' },
    { label: 'Disregarded entity', value: 'Disregarded entity' },
    { label: 'Partnership', value: 'Partnership' },
    { label: 'Simple trust', value: 'Simple trust' },
    { label: 'Grantor trust', value: 'Grantor trust' },
    { label: 'Complex trust', value: 'Complex trust' },
    { label: 'Estate', value: 'Estate' },
    { label: 'Government', value: 'Government' },
    { label: 'International organization', value: 'International organization' },
    { label: 'Central bank of issue', value: 'Central bank of issue' },
    { label: 'Tax- exempt organizatior', value: 'Tax- exempt organizatior' },
    { label: 'Private foundation', value: 'Private foundation' },
];
export default (props) => {
    let formRef = useRef();
    useEffect(() => {
        getRegInfo()
    }, [])
    let getRegInfo = useCallback(() => {
        getStoreRegisterInfo().then((res) => {
            for (let item of res.data) {
                if (item.propertyValue) {
                    if (item.propertyKey == "paytaxes_organization_name") {
                        formRef.current.setFieldsValue({
                            benefit_organization_name: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "paytaxes_organization_state") {
                        formRef.current.setFieldsValue({
                            company_state: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "paytaxes_organization_state") {
                        formRef.current.setFieldsValue({
                            company_state: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "paytaxes_organization_site") {
                        formRef.current.setFieldsValue({
                            perpetual_site: `${item.propertyValue.split("------")[5]}  ${item.propertyValue.split("------")[3]}`,
                            perpetual_site1: `${item.propertyValue.split("------")[2]}  ${item.propertyValue.split("------")[1]}  ${item.propertyValue.split("------")[4]}`,
                            perpetual_site2: item.propertyValue.split("------")[0],
                        })
                    }
                    if (item.propertyKey == "survey_sign_date") {
                        formRef.current.setFieldsValue({
                            survey_sign_date: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "personal_sign") {
                        formRef.current.setFieldsValue({
                            survey_sign: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "collection_true_name") {
                        formRef.current.setFieldsValue({
                            collection_true_name: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "benefit_all_person_type") {
                        formRef.current.setFieldsValue({
                            benefit_all_person_type: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "communication_site") {
                        formRef.current.setFieldsValue({
                            communication_site: item.propertyValue.split("------")[0],
                            communication_site1: item.propertyValue.split("------")[1],
                            communication_site2: item.propertyValue.split("------")[2],
                        })
                    }
                    if (item.propertyKey == "reference_number") {
                        formRef.current.setFieldsValue({
                            reference_number: item.propertyValue
                        })
                    }
                    // if (item.propertyKey == "survey_sign") {
                    //     formRef.current.setFieldsValue({
                    //         survey_sign: item.propertyValue
                    //     })
                    // }
                    if (item.propertyKey == "survey_sign_date") {
                        formRef.current.setFieldsValue({
                            survey_sign_date: item.propertyValue
                        })
                    }
                    // if (item.propertyKey == "survey_is_true") {
                    //     formRef.current.setFieldsValue({
                    //         survey_is_true: [item.propertyValue]
                    //     })
                    // }

                }
            }
        })
        AJAX.login.getStoreRegisterInfo([]).then((data) => {
            for (let item of data.data) {
                if (item.propertyValue) {
                    if (item.propertyKey == "paytaxes_organization_name") {
                        formRef.current.setFieldsValue({
                            benefit_organization_name: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "paytaxes_organization_state") {
                        formRef.current.setFieldsValue({
                            company_state: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "paytaxes_organization_state") {
                        formRef.current.setFieldsValue({
                            company_state: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "paytaxes_organization_site") {
                        formRef.current.setFieldsValue({
                            perpetual_site: `${item.propertyValue.split("------")[5]}  ${item.propertyValue.split("------")[3]}`,
                            perpetual_site1: `${item.propertyValue.split("------")[2]}  ${item.propertyValue.split("------")[1]}  ${item.propertyValue.split("------")[4]}`,
                            perpetual_site2: item.propertyValue.split("------")[0],
                        })
                    }
                    if (item.propertyKey == "survey_sign_date") {
                        formRef.current.setFieldsValue({
                            survey_sign_date: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "personal_sign") {
                        formRef.current.setFieldsValue({
                            survey_sign: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "collection_true_name") {
                        formRef.current.setFieldsValue({
                            collection_true_name: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "benefit_all_person_type") {
                        formRef.current.setFieldsValue({
                            benefit_all_person_type: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "communication_site") {
                        formRef.current.setFieldsValue({
                            communication_site: item.propertyValue.split("------")[0],
                            communication_site1: item.propertyValue.split("------")[1],
                            communication_site2: item.propertyValue.split("------")[2],
                        })
                    }
                    if (item.propertyKey == "reference_number") {
                        formRef.current.setFieldsValue({
                            reference_number: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "personal_sign") {
                        formRef.current.setFieldsValue({
                            survey_sign: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "survey_sign_date") {
                        formRef.current.setFieldsValue({
                            survey_sign_date: item.propertyValue
                        })
                    }
                    // if (item.propertyKey == "survey_is_true") {
                    //     formRef.current.setFieldsValue({
                    //         survey_is_true: ["1"]
                    //     })
                    // }

                }
            }
        })
    }, [])
    let nextEvent = useCallback(async () => {

        formRef.current.validateFields().then(async (res) => {
            const ress = await AJAX.login.getStoreRegisterProgress()
            const { isDeposit, isTaxation } = ress.data
            let communication_sites = ""
            if (res.communication_site) {
                communication_sites = res.communication_site
            }
            if (res.communication_site1) {
                communication_sites += res.communication_site1
            } else {
                communication_sites += ""
            }
            if (res.communication_site2) {
                communication_sites += res.communication_site2
            } else {
                communication_sites += ""
            }
            let arr = [
                { "propertyKey": "benefit_organization_name", "propertyValue": res.benefit_organization_name || '' },
                { "propertyKey": "company_state", "propertyValue": res.company_state || '' },
                { "propertyKey": "collection_true_name", "propertyValue": res.collection_true_name || '' },
                { "propertyKey": "benefit_all_person_type", "propertyValue": toString(res.benefit_all_person_type) || '' },
                { "propertyKey": "perpetual_site", "propertyValue": res.perpetual_site + "------" + res.perpetual_site1 + "------" + res.perpetual_site2 || '' },
                { "propertyKey": "communication_site", "propertyValue": communication_sites || '' },
                { "propertyKey": "reference_number", "propertyValue": res.reference_number || '' },
                { "propertyKey": "survey_sign", "propertyValue": res.survey_sign || '' },
                { "propertyKey": "survey_sign_date", "propertyValue": res.survey_sign_date || '' },
                { "propertyKey": "survey_is_true", "propertyValue": res.survey_is_true ? res.survey_is_true[0] : '' }
            ]
            regSubmit([], null, 'TaxInvestigation', 0, isDeposit ? 1 : 0).then((data) => {
                if (data.code == 1) {
                    props.history.push('/surveyResults')
                }
            })
        })
    }, [])
    return (
        <div className="enterpriseBox TaxInvestigationBox">
            <Header />
            <div style={{ display: 'flex' }}>
                <div className="a-section program-overview-values">
                    <div className="CompanyAddress">
                        <Form ref={formRef}>

                            <div>
                                <h1 className="topTitle">税务信息调查</h1>
                                <table className="investigationTable">
                                    <tbody>
                                        <tr>
                                            <td colSpan="5">Reference ld: A061 134233TR72H55JJ9S</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span>form</span>
                                                <span className="fz16">W-8BEN-E</span>
                                            </td>
                                            <td className="fz20" colSpan="3">Certificate of Status of Beneficial Owner for United States Tax Withholding and Reporting (Entities)</td>
                                            <td>
                                                <span className="fz16">SUBSTITUTE</span>
                                                <span>(July 2017)</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5" className="textAlign">
                                                <div>
                                                    <div>
                                                        <div className="taableTit">
                                                            <span>Do NOT use this form for:</span>
                                                            <span>Instead, use Form:</span>
                                                        </div>
                                                        <ul>
                                                            <li>
                                                                <span>● U.S. entity or U.S. citizen or resident</span>
                                                                <span>W-9</span>
                                                            </li>
                                                            <li>
                                                                <span>● A foreign individual</span>
                                                                <span>W- 8BEN (Individual)</span>
                                                            </li>
                                                            <li>
                                                                <span>● A foreign individual or entity claiming that income is effectively connected with the conduct of trade or business within the U.S. (unless claimingtreaty benefits)</span>
                                                                <span>W-8ECI</span>
                                                            </li>
                                                            <li>
                                                                <span>●  foreign partnership, a foreign simple trust, or a foreign grantor trust (unless claiming treaty benefits) (see instructions for exceptions)</span>
                                                                <span>W-8IMY</span>
                                                            </li>
                                                            <li>
                                                                <span>● A foreign government, international organization, foreign central bank of issue, foreign tax -exempt organization, foreign private foundation, orgovernment of a U.S. possession claiming that income is effectively connected U.S. income or that is claiming the applicability of section(s) 115(2)，501(c), 892, 895, or 1443(b) (unless claiming treaty benefits) (see instructions)</span>
                                                                <span>W-8ECI or W-8EXP</span>
                                                            </li>
                                                            <li>
                                                                <span>● Any person acting as an intermediary</span>
                                                                <span>W-8IMY</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5" className="textAlign">
                                                <span className="bgCol">Part I</span>
                                                <span>ldentification of Beneficial Owner</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" className="textAlign beneficialClass">
                                                <p>1   Name of organization that is the beneficial owner</p>
                                                <div>
                                                    <Form.Item name="benefit_organization_name" rules={[{ required: true, message: '' }]}>
                                                        <Input disabled maxLength="30" />
                                                    </Form.Item>
                                                </div>
                                            </td>
                                            <td colSpan="3" className="textAlign beneficialClass">
                                                <p>2   Country of incorporation or organization</p>
                                                <div>
                                                    <Form.Item name="company_state" rules={[{ required: true, message: '' }]}>
                                                        <Input disabled maxLength="30" />
                                                    </Form.Item>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5" className="textAlign beneficialClass">
                                                <p>3   Name of disregarded entity receiving the payments (if applicable)</p>
                                                <div>
                                                    <Form.Item name="collection_true_name">
                                                        <Input disabled maxLength="30" />
                                                    </Form.Item>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5" className="textAlign beneficialClass">
                                                <span>4   Type of beneficial owner:</span>
                                                <Form.Item name="benefit_all_person_type">
                                                    <Checkbox.Group disabled options={options} defaultValue={['Pear']} />
                                                </Form.Item>
                                                <div>If you entered disregarded entity, partnership, simple trust, or grantor trust above, is the entity a hybrid making a treaty claim? If "Yes" complete Part II.
                                                    <Checkbox>Yes</Checkbox><Checkbox>No</Checkbox>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5" className="textAlign beneficialClass">
                                                <p>5 Permanent residence address (tret apt. or suite no., or rural route). Do not use a P.O. box or in-care-of address (other than a registered address).</p>
                                                <div>
                                                    <Form.Item name="perpetual_site" rules={[{ required: true, message: '' }]}>
                                                        <Input disabled maxLength="30" />
                                                    </Form.Item>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" className="textAlign beneficialClass">
                                                <p>City or town, state or province. Include postal code where appropriate.</p>
                                                <div>
                                                    <Form.Item name="perpetual_site1" rules={[{ required: true, message: '' }]}>
                                                        <Input disabled maxLength="30" />
                                                    </Form.Item>
                                                </div>
                                            </td>
                                            <td colSpan="3" className="textAlign beneficialClass">
                                                <p>Country</p>
                                                <div>
                                                    <Form.Item name="perpetual_site2" rules={[{ required: true, message: '' }]}>
                                                        <Input disabled maxLength="30" />
                                                    </Form.Item>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5" className="textAlign beneficialClass">
                                                <p>6 Mailing address (if different from above)</p>
                                                <div>
                                                    <Form.Item name="communication_site">
                                                        <Input disabled maxLength="30" />
                                                    </Form.Item>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" className="textAlign beneficialClass">
                                                <p>City or town, state or province. Include postal code where appropriate.</p>
                                                <div>
                                                    <Form.Item name="communication_site1">
                                                        <Input disabled maxLength="30" />
                                                    </Form.Item>
                                                </div>
                                            </td>
                                            <td colSpan="3" className="textAlign beneficialClass">
                                                <p>Country</p>
                                                <div>
                                                    <Form.Item name="communication_site2">
                                                        <Input disabled maxLength="30" />
                                                    </Form.Item>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5" className="textAlign beneficialClass">
                                                <p>7 Reference number(s) (see instructions)</p>
                                                <div>
                                                    <Form.Item name="reference_number">
                                                        <Input disabled maxLength="30" />
                                                    </Form.Item>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5" className="textAlign">
                                                <span className="bgCol">Part II</span>
                                                <span>ldentification of Beneficial Owner</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5" className="textAlign textAlignFooter">
                                                <p>Under penalties of perjury, I declare that I have examined the information on this form and to the best of my knowledge and belief it is true, correct, and complete. I further certifyunder penalties of perjury that:</p>
                                                <ul>
                                                    <li>
                                                        <span>● . The entity identifled on line 1 of this form is the beneficial owner of all the income to which this form relates, is using this form to certify its status for chapter 4 purposes, or is a merchant submitting this form for purposes of section 6050W,</span>
                                                    </li>
                                                    <li>
                                                        <span>● The entity identifled on line 1 of this form is not a U.S. person,</span>
                                                    </li>
                                                    <li>
                                                        <span>● The income to which this form relates is: (a) not effectively connected with the conduct of a trade or business in the United States, (b) effectively connected but is not subject to tax under an income tax treaty, or (C) the partner's share of a partnership's effectively connected income, and</span>
                                                    </li>
                                                    <li>
                                                        <span>●  For broker transactions or barter exchanges, the beneficial owner is an exempt foreign person as defined in the instructions.</span>
                                                    </li>
                                                    {/* <li>
                                                        <span>● A foreign government, international organization, foreign central bank of issue, foreign tax -exempt organization, foreign private foundation, orgovernment of a U.S. possession claiming that income is effectively connected U.S. income or that is claiming the applicability of section(s) 115(2)，501(c), 892, 895, or 1443(b) (unless claiming treaty benefits) (see instructions)</span>
                                                    </li>
                                                    <li>
                                                        <span>● Any person acting as an intermediary</span>
                                                    </li> */}
                                                </ul>
                                                <p className="bolds">The lteral Revenue Sevice does not re| The lteral Revenue Sevice does not rerequire your consent tperson, establish rou hapter 4 satuos“eup an.n epi aDP, osioni r dcme oer han the cenmeeoss ngeis.ew。status as a non-U.s.if aplcale, obtaion a reduced rate。f withholding</p>
                                                <p>I agree that I will submit a neW form within 30 days if any certification on this form becomes incorrect.</p>
                                                <div className="bottomInp">
                                                    <span>Sign Here</span>
                                                    <div>
                                                        <Form.Item name="survey_sign" extra="Signature of individual authorized to sign for beneficial owner" rules={[{ required: true, message: '' }]}>
                                                            <Input disabled maxLength="30" />
                                                        </Form.Item>
                                                    </div>
                                                    <div>
                                                        <Form.Item extra="Print Name" name="survey_sign">
                                                            <Input disabled maxLength="30" />
                                                        </Form.Item>
                                                    </div>
                                                    <div>
                                                        <Form.Item extra="Date (MM-DD-YYYY)" name="survey_sign_date" rules={[{ required: true, message: '' }]}>
                                                            <Input disabled maxLength="30" />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="textAlign" colSpan="5">
                                                <Form.Item name="survey_is_true" initialValue={"1"} rules={[{ required: true, message: '请勾选' }]}>
                                                    <Checkbox.Group>
                                                        <Checkbox disabled value="1">I certify that I have the capacity to sign for the entity identified on line 1 of this form.</Checkbox>
                                                    </Checkbox.Group>
                                                </Form.Item>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <hr aria-hidden="true" className="a-spacing-top-large a-divider-normal" />
                                <p>以上是依据您提供的信息对您的税务表格的预览。请查看并提交表格，或者根据需要进行更改。</p>
                                <div className="operationBtn" style={{ textAlign: 'center' }}>
                                    <Button className='previousPage' type="primary">
                                        <Link to={{ pathname: '/taxationInfo', state: { isReturn: 1 } }}>进行更改</Link>
                                    </Button>
                                    <Button onClick={nextEvent} className='next' type="primary">提交表格</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                {/* <div className="rightCollapse">
                    <p >常见问题</p>
                    <Collapse defaultActiveKey={['1']} onChange={callback}>
                        <Panel header="电话号码的正确格式是什么？" key="1">
                            <p>该数字的前缀必须是国家/地区代码。 下面是正确的格式：+1 1298279287</p>
                        </Panel>
                        <Panel header="如果我没有可接收 SMS 的手机号码，应该怎么办？" key="2">
                            <p>您可以输入您的固定电话号码，然后选择拨打电话选项以接收电话验证 PIN。</p>
                        </Panel>
                        <Panel header="如果我没有收到含有 PIN 的 SMS，应该怎么办？" key="3">
                            <p>检查您输入的电话号码格式是否正确。 再次请求 PIN。</p>
                        </Panel>
                        <Panel header="如果“呼叫”选项在我所在的国家/地区未列出，我该怎么办？" key="4">
                            <p>如果在您所在的国家/地区中，“呼叫”选项不可用，请选择“短信”选项。</p>
                        </Panel>
                        <Panel header="主要联系人是如何定义的？" key="5">
                            <p>主要联系人是指有权访问“亚马逊销售”付款帐户，代表帐户持有人（注册卖方）提供注册信息并发起交易（如付款和退款）的人。主要联系人采取的行动被视为帐户持有人采取的行动。</p>
                        </Panel>
                    </Collapse>
                </div> */}
            </div>
            <Footer />
        </div>
    )
}