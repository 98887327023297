import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
`;

export const NotFound = styled.div`
    width: 500px;
    height: 80px;
    margin: 20px 0;
    padding: 20px 0 0 20px;
    border: 1px solid #c40000;
    border-radius: 4px;
    box-shadow: 0 0 0 4px #fcf4f4 inset;
    font-size: 18px;
`;