import React, { useRef, useEffect, useCallback, useState } from 'react';
import './index.less'
import { Button, Checkbox, Form, Input } from 'antd';
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import IconFont from 'components/Icon/Icon'
import AJAX from "api/apis";
import { encryptBy } from "utils/encryption"
import cookieUtils from "utils/cookie";
import { regSubmit } from 'utils/PublicLibrary';


export default (props) => {
  let formRef = useRef();
  const [email, setemail] = useState('');
  const [userName, setSserName] = useState('');
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    getPcAmazonEmail()
  }, [])
  const getPcAmazonEmail = useCallback(() => {
    AJAX.login.getPcAmazonEmail().then((res) => {
      if (res.code == 1 && res.data) {
        setemail(res.extend.email)
        setSserName(res.extend.userName)
        formRef.current.setFieldsValue({
          password: res.data.pwd
        })
      }
    })
  }, [])

  let nextEvent = useCallback(() => {
    // props.history.push('/twoStepVerification/IssuingAddress')
    // return
    if (loading) return
    setTimeout(() => {
      setLoading(false);
    }, 3500)
    formRef.current.validateFields().then(({ password }) => {
      setLoading(true)
      regSubmit([], null, '', 16).then(data => {
        if (data.code == 1) {
          props.history.push('/twoStepVerification/IssuingAddress')
        }
      })
    })

  }, [email, loading])


  return (
    <div className='SimulateLogin register_style'>
      <div className='s_login'>
        <h1>登录</h1>
        <hr></hr>
        <Form ref={formRef}>
          <div style={{ textAlign: 'right', marginBottom: '10px' }}><a>切换账户</a></div>
          <div className='email'>{userName}</div>
          <div className='email-2'>{email}</div>
          <div className='pwdName'><span className='a_link'>密码</span><a>忘记密码</a></div>
          <Form.Item
            name="password"
            rules={[{
              required: true, validator: (rule, value, fun) => {
                if (!value) {
                  fun('请输入密码')
                } else if (value.length < 6) {
                  fun('密码不得少于6位数')
                }
                fun()
              }
            }]}
          >
            <Input.Password placeholder="至少输入6位字符" />
          </Form.Item>
          <Button className='sbu' type="primary" onClick={nextEvent}> 登录 </Button>
        </Form>
        <Checkbox>记住登录状态。<a>详情 <IconFont type="icon-zelvxuanzefeiyongdaosanjiaoxingfandui" style={{ fontSize: "12px", color: "#3d3d3d" }} /></a></Checkbox>
      </div>
      <Footer></Footer>
    </div>
  )
}