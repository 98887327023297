import Apis from "@api/apis";
import campaignConfig from "./campaignConfig";
import adGroupConfig from "./adGroupConfig";
import adPositionConfig from "./adPositionConfig";
import adCombinationConfig from "./adCombinationConfig";
import adGroupAdvertisingConfig from "./adGroupAdvertisingConfig";
import adGroupPutConfig from "./adGroupPutConfig";

import adGroupSearchWordsConfig from "./adGroupSearchWordsConfig";

export const BusinessTypeMapping = {
  Campaign: "campaign",
  Goods: "goods",
  Group: "group",
  Position: "position",
  Combination: "combination",
  AdGroupGoods: "adGroupGoods",
  AdGroupPut: "adGroupPut",
  AdGroupSearchWords: "adGroupSearchWords",
};

export const SuperTableConfigMapping = {
  [BusinessTypeMapping.Campaign]: campaignConfig,
  [BusinessTypeMapping.Group]: adGroupConfig,
  [BusinessTypeMapping.Position]: adPositionConfig,
  [BusinessTypeMapping.Combination]: adCombinationConfig,
  [BusinessTypeMapping.AdGroupGoods]: adGroupAdvertisingConfig,
  [BusinessTypeMapping.AdGroupPut]: adGroupPutConfig,
  [BusinessTypeMapping.AdGroupSearchWords]: adGroupSearchWordsConfig,
};

export const customColumnsOptions = [
  {
    label: "列",
    value: "列",
  },
  {
    label: "自定义列",
    value: "自定义列",
  },
  {
    label: "重置为默认值",
    value: "重置为默认值",
  },
];
