import React from 'react'
import './ImagesNeeds.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'

function ImagesNeeds(){
    return(
        <div>
            <Header/>
            <div className="imgN_Box">
                <div className="imgN_lBox">
                    <div className="imgN_lBox_tab">
                        <div>Help</div><span>/</span>
                        <div>管理库存</div><span>/</span>
                        <div>创建和管理库存</div><span>/</span>
                        <div>图片上传指南</div><span>/</span>
                        <div>商品图片要求</div>
                    </div>

                    <div className="imgN_lBox_head">
                        <div className="imgN_lBox_head_titel">商品图片需求</div>

                        <div className="imgN_lBox_head_titelII">
                        亚马逊商城的每件商品都需要配有一张或多张商品图片。商品的主要图片被称作“主图片”。 商品的主图片显示在搜索结果和浏览页中，也是买家在商品详情页面上看到的第一张图片。
                        </div>

                        <div className="imgN_lBox_head_titelIII">图片对于买家非常重要，因此其质量不容忽视。应选择清晰、易懂、信息丰富并且外观吸引人的图片。</div>

                        <div className="imgN_lBox_head_titelIV">
                        注意： 此页面上列出的所有图片标准均为基本要求。您可在相应的分类风格指南中找到特定于您的商品分类的完整亚马逊图片标准。请仔细阅读，因为您上传到亚马逊商城的图片必须符合这些要求。
                        </div>
                        <div className="imgN_lBox_head_titelIV">
                        重要： 如果发生重合或冲突，每个分类的指南的优先级最高。我们保留移除不符合我们图片标准的图片这一权利。您必须始终确保您对自己提交的图片拥有必要权利。
                        </div>
                    </div>

                    <div className="imgN_lBox_navBox">
                        <div className="imgN_lBox_navTitel">通用图片标准</div>

                        <div className="imgN_lBox_nav">您可在相应的分类<span>风格指南</span>中找到特定于您的商品分类的完整亚马逊图片标准。请仔细阅读，因为您上传到亚马逊商城的图片必须符合这些要求。请注意，如果发生重合或冲突，每个分类的指南的优先级最高。</div>

                        <div className="imgN_lBox_nav_ul">
                            <ul>
                                <li>图片必须准确展示商品，且仅展示待售商品。</li>
                                <li>商品及其所有特色都必须清晰可见。</li>
                                <li>主图片应该采用纯白色背景（纯白色可与亚马逊搜索和商品详情页面融为一体 - RGB 色值为 255、255、255）。</li>
                                <li>主图片必须是实际商品的专业照片（不得是图形、插图、实物模型或占位符），且不得展示不出售的配件、可能令买家产生困惑的支撑物、不属于商品一部分的文字，或者标志/水印/内嵌图片。</li>
                                <li>图片必须与商品名称相符。</li>
                                <li>图片的最长边不应低于 1,600 像素。满足此最小尺寸要求可在网站上实现缩放功能。事实证明，提供缩放功能可以提高销量。</li>
                                <li>图片最长边不得超过 10,000 像素。</li>
                                <li>亚马逊接受 JPEG (.jpg)、TIFF (.tif) 或 GIF (.gif) 文件格式，但首选 JPEG。</li>
                                <li>我们的服务器不支持 .gif 格式的动图。</li>
                                <li>图片不得包含裸体或有性暗示意味。</li>
                                <li>鞋靴主图片应采用单只鞋靴，呈 45 度角朝向左侧。</li>
                                <li>女装和男装主图片应采用模特照。</li>
                                <li>所有儿童和婴儿服装图片均应采用平放拍摄照（不借助模特）。</li>
                            </ul>
                        </div>

                        <div className="imgN_lBox_navTitel">主图片标准中不允许出现的问题（适用于所有商店分类）</div>
                        <div className="imgN_lBox_nav_ul"> 
                            <ul>
                                <li>商品图片不应包含任何亚马逊徽标或商标、亚马逊徽标或商标的变体，或者任何容易让人混淆的与亚马逊徽标或商标相似的内容。这包括但不限于任何含有 AMAZON、PRIME、ALEXA 或 Amazon Smile 设计的文字或徽标。</li>
                                <li>商品图片不能包含亚马逊商城使用的任何标记、标记的变体、任何容易让人混淆的与标记相似的内容。这包括但不限于“Amazon’s Choice”、“优质的选择”、“Amazon Alexa”、“与 Alexa 合作”、“畅销商品”或“热卖商品”。请参阅商标使用指南了解更多详情。</li>
                                <li>裸体或带有性暗示意味的图片。</li>
                                <li>商品图片必须清晰，不得有马赛克或锯齿边缘。</li>
                                <li>最长边放大到最大允许尺寸时，商品占画面不到 85%。</li>
                            </ul>
                        </div>

                        <div className="imgN_lBox_navTitel">主图片中不允许出现的其他问题（适用于服装、服装配饰、鞋靴、手提包、箱包和珠宝首饰类商品）</div>

                        <div className="imgN_lBox_nav_ul">
                            <ul>
                                <li>采用非纯白色背景的图片（RGB 低于 255）。</li>
                                <li>商品上或背景中有文字、标志、边框、色块、水印或其他图形。</li>
                                <li>同一商品展示多张图片。</li>
                                <li>待售商品在图片中没有完整展示，珠宝首饰（尤其是项链）除外。</li>
                                <li>有过多的内部或外部道具覆盖/围绕商品，或道具易被误认为待售商品的一部分。</li>
                                <li>模特处于跪坐、斜靠姿势或睡姿（模特必须采取站姿）。</li>
                                <li>图片中的商品带有包装、品牌或吊牌。</li>
                                <li>包含可见人体模型（长袜或短袜除外）。</li>
                                <li>将儿童和婴儿紧身衣、内衣或泳衣穿戴在模特身上拍摄的图片。</li>
                            </ul>
                        </div>

                        <div className="imgN_lBox_navTitel">附加图片</div>
                        
                        <div className="imgN_lBox_head_titelII">
                        “主图片”应配有一些附加的图片，以从不同的角度来展示商品、展示使用中的商品和在“主图片”中没有显示的细节。请遵循上述图片标准，以保证您的所有商品图片的质量和一致性。
                        </div>

                        <div className="imgN_lBox_head_titelIII">如要自行创建图片，您会发现我们的自助成像产品或摄影视频十分有用。</div>
                    </div>

                    <div className="imgN_lBox_imgBox">
                        <div className="imgN_lBox_navTitel">图片示例：主题片中不预序出现的问题</div>

                        <div className="imgN_lBox_nav_ul">
                            <ul >
                                <li>图片必须采用纯白背景。</li>
                            </ul>
                            <div className="imgN_lBox_imgNavBox">
                               <div className="imgN_lBox_imgNav"></div>
                            </div>
                        </div>
                        
                        <div className="imgN_lBox_nav_ul">
                            <ul >
                                <li>图片中、商品上或背景中不得有文字、标志、边框、色块、水印或其他图形。</li>
                            </ul>
                            <div className="imgN_lBox_imgNavBox">
                               <div className="imgN_lBox_imgNavI"></div>
                            </div>
                        </div>

                        <div className="imgN_lBox_nav_ul">
                            <ul >
                                <li>主图片不能包含同一商品的多张图片。</li>
                            </ul>
                            <div className="imgN_lBox_imgNavBox">
                               <div className="imgN_lBox_imgNavII"></div>
                            </div>
                        </div>

                        <div className="imgN_lBox_nav_ul">
                            <ul >
                                <li>最长边放大到最大允许尺寸时，商品必须至少占画面的 85%。</li>
                            </ul>
                            <div className="imgN_lBox_imgNavBox">
                               <div className="imgN_lBox_imgNavIII"></div>
                            </div>
                        </div>


                        <div className="imgN_lBox_nav_ul">
                            <ul >
                                <li>图片必须清晰，不得有马赛克或锯齿边缘。</li>
                            </ul>
                            <div className="imgN_lBox_imgNavBox">
                               <div className="imgN_lBox_imgNavIV"></div>
                            </div>
                        </div>


                        <div className="imgN_lBox_nav_ul">
                            <ul >
                                <li>主图片中不得有人，成人服装除外。</li>
                            </ul>
                            <div className="imgN_lBox_imgNavBox">
                               <div className="imgN_lBox_imgNavV"></div>
                            </div>
                        </div>


                        <div className="imgN_lBox_navTitel">
                        图片示例：主图片中不允许出现的其他问题（适用于服装、服装配饰、鞋靴、手提包、箱包和珠宝首饰类商品）
                        </div>

                        <div className="imgN_lBox_nav_ul">
                            <ul >
                                <li>待售商品必须完整展示在图片中，珠宝首饰（尤其是项链）除外。</li>
                            </ul>
                            <div className="imgN_lBox_imgNavBox">
                               <div className="imgN_lBox_imgNav"></div>
                            </div>
                        </div>

                        <div className="imgN_lBox_nav_ul">
                            <ul >
                                <li>图片中不得有过多的内部或外部道具覆盖/围绕商品，道具不会被误认为待售商品的一部分。</li>
                            </ul>
                            <div className="imgN_lBox_imgNavBox">
                               <div className="imgN_lBox_imgNav"></div>
                            </div>
                        </div>


                        <div className="imgN_lBox_nav_ul">
                            <ul >
                                <li>模特处于跪坐、斜靠姿势或睡姿（模特必须采取站姿）。</li>
                            </ul>
                            <div className="imgN_lBox_imgNavBox">
                               <div className="imgN_lBox_imgNav"></div>
                            </div>
                            <ul >
                                <li>模特处于坐姿、跪姿、靠姿或睡姿（模特必须采用站姿，婴儿除外）。</li>
                            </ul>
                        </div>


                        <div className="imgN_lBox_nav_ul">
                            <ul >
                                <li>图片中的商品带有包装、品牌或吊牌（长袜或短袜除外）。</li>
                            </ul>
                            <div className="imgN_lBox_imgNavBox">
                               <div className="imgN_lBox_imgNav"></div>
                            </div>
                            <ul >
                                <li>包含可见人体模型（长袜或短袜除外）。</li>
                            </ul>
                        </div>


                        <div className="imgN_lBox_nav_ul">
                          
                            <div className="imgN_lBox_imgNavBox">
                               <div className="imgN_lBox_imgNav"></div>
                            </div>
                            <ul >
                                <li>将儿童和婴儿内衣或泳衣穿戴在模特身上拍摄的图片。</li>
                            </ul>
                        </div>

                        <div className="imgN_lBox_nav_ul">
                          
                          <div className="imgN_lBox_imgNavBox">
                             <div className="imgN_lBox_imgNav"></div>
                          </div>
                         
                      </div>

                        

                    </div>

                </div>
                <div className="imgN_RBox"></div>


            </div>

            <Footer/>

        </div>
    )
}

export default ImagesNeeds