import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { AButton, AInput, ASelect, AText } from "@a-components/";
import Sidebar from "../sidebar/index";
import { useHistory } from "react-router-dom";
import "./index.css"
import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"
import StatisticsCard from "./../StatisticsCard";
import classNames from "classnames";
import { beautifyValue, uglifyValue } from "@utils/utils";

import style from "./style.module.css";
import AdvertisingSuperTable from "@view/advertisement/AdvertisingSuperTable";
import AdvertisingCombinationCard from "./AdvertisingCombinationCard";
import useQuery from "@hooks/useQuery";
import { useEventEmitter } from "ahooks";

function Administration() {
  const { tab } = useQuery();

  const currentAdCombinationId = useMemo(() => {
    return beautifyValue(tab || uglifyValue({ advertisingCombinationId: 0 }))
      .advertisingCombinationId;
  }, [tab]);

  const [advertisingCombinationId, setAdvertisingCombinationId] = useState(
    () => currentAdCombinationId
  );

  const history = useHistory();
  const isTourOpen = true;
  const awakenTips = (type) => {
    setIsActive(type);
  }
  const steps1 = [
    {
      selector: '.Administration .ant-btn-primary',
      content: '点击创建广告活动',
    }
  ]
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setAdvertisingCombinationId(currentAdCombinationId);
  }, [currentAdCombinationId]);

  const handleChangeAdCombinationId = useCallback(
    (advertisingCombinationId) => {
      history.replace(
        `/Advertisement?tab=${uglifyValue({ advertisingCombinationId })}`
      );
    },
    [history]
  );
  const isAdGroupTable = advertisingCombinationId === "adGroup";

  const event$ = useEventEmitter();
  event$.useSubscription(({ type, payload }) => {
    switch (type) {
      case "createAdGroupSuccess":
        isAdGroupTable && event$.emit({ type: "resetPageIndex" });
        break;
      case "setAdvertisingCombinationActive":
        setAdvertisingCombinationId(payload);
        break;
      default:
    }
  });

  return (
    <div className={classNames(style.container, "df fd-c bg-white Administration")}>
      <div className="df">
        <div>
          <Sidebar
            event$={event$}
            value={advertisingCombinationId}
            onChange={handleChangeAdCombinationId}
          />
        </div>

        {/* 导航内容页 */}
        <div className="mt-24 ml-24 mr-24 mb-24 Administration-body">
          {typeof advertisingCombinationId === "number" &&
            advertisingCombinationId !== 0 ? (
            <AdvertisingCombinationCard
              className="mb-24"
              advertisingCombinationId={advertisingCombinationId}
            />
          ) : null}

          <div className="mb-24">
            <StatisticsCard type={advertisingCombinationId} />
          </div>

          {/* 列表头部 */}
          <AdvertisingSuperTable
            event$={event$}
            businessId={advertisingCombinationId}
            businessType={isAdGroupTable ? "combination" : "campaign"}
          />

          <div>
            <div>
              广告活动状态是最新的，但广告效果数据最多可延迟12小时。某写产品和关键字不符合定位条件，因此不会展示广告。
              <AButton type="link">进一步了解</AButton>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center">
        © 2020
        Amazon.com,lnc.或其他附属公司。使用条款&nbsp;&nbsp;&nbsp;&nbsp;隐私条款&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Amazon广告协议
      </div>
      {/* 引导步骤 */}
      <TipsIcon clicks={awakenTips} />
      <Reactours steps={steps1} isActive={isActive} clicks={awakenTips} routePath="/Advertisement" isOpen={isTourOpen} />
    </div >
  );
}
export default Administration;
