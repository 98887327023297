import React from 'react'
import './index.css'
import IconFont from 'components/Icon/Icon'


function Check(){
    return(
        <div>
            <div className="Affirm_body">
                <div className="Affirm_body_titel">盘点<span>了解更多信息</span></div>

                <div className="Affirm_boxI">
                    <div className="Affirm_boxI_head">
                        <div className="Affirm_boxI_head_titel">1.准备您的配送确认文件</div>
                        <div className="Affirm_boxI_head_icon"><IconFont type="icon-sanjiaoxing" /></div>
                    </div>
                    <div className="Affirm_boxI_content">
                        <div>使用我们的模板来准备您的配送确认文件。保存一份副本，以备稍后编辑之用。<span>了解更多信息</span></div>
                    </div>
                    <div className="Affirm_boxI_Btn">
                        <button>下载模板</button>
                    </div>
                </div>

                <div className="Affirm_boxII">
                    <div className="Affirm_boxI_head">
                        <div className="Affirm_boxI_head_titel">2.上传您的盘点文件</div>
                        <div className="Affirm_boxI_head_icon"><IconFont type="icon-sanjiaoxing" /></div>
                    </div>
                    <div className="Affirm_boxI_content">
                        <div>上传您从盘点文件模板创建的、以制表符分隔的盘点文件。</div>
                    </div>
                    <div className="Affirm_boxII_btn">
                        <div className="Affirm_boxII_btnI">查找要上传的文件位置： <button>选择文件</button>没有选择文件</div>
                        <div className="Affirm_boxII_btnII"><button >立即上传</button></div>
                    </div>
                </div>

                <div className="Affirm_boxIII">
                    <div className="Affirm_boxIII_head">
                        <div>3.检查文件状态和历史记录</div>
                        <div><button>刷新</button></div>
                    </div>
                    <div className="Affirm_boxIII_content">
                        <div>
                        查看您最近 10 次的上传状态。如果处理报告出现任何错误，请修改您的盘点文件，并返回上述步骤 2。<span>了解更多信息</span>
                        </div>
                    </div>
                </div>

                <div className="Affirm_boxIV">
                    <div className="Affirm_boxIV_head">
                        <div className="Affirm_boxIV_head_date">日期和时间</div>
                        <div className="Affirm_boxIV_head_id">批量编号</div>
                        <div className="Affirm_boxIV_head_state">状态/结果</div>
                        <div className="Affirm_boxIV_head_operation">操作</div>
                    </div>
                    <div className="Affirm_boxIV_content">
                        <div className="Affirm_boxIV_head_date" style={{borderTop:"1px solid #dddddd",borderBottom:"1px solid #dddddd",padding:"6px 0"}}>您尚未上传任何文件</div>
                    </div>
                </div>



            </div>


        </div>
    )
}

export default Check