import React from 'react';
import {Container,Logo} from './style';
import {Link} from 'react-router-dom';
import {Popover,Button,Checkbox} from 'antd';
import { injectIntl } from "react-intl";

const Nav = ({intl}) => {
    let data = [
        {
            key:'header_nav_Catalog', //目录
            children:[
                {
                    text:'添加商品',
                    path:'/addlisting',
                    key:'header_child_Addlisting'
                },
                {
                    text:'补全您的草稿',
                    path:'/DraftIncomplete',
                    key:'header_child_DraftIncomplete'
                },
                {
                    text:'查看销售申请',
                    path:'/SellingApplications',
                    key:'header_child_Sales'
                },
            ]
        },
        {
            key:'header_nav_Inventory', //库存
            children:[
                {
                    text:'管理库存',
                    path:'/inventory',
                    key:'header_child_Inventory'
                },
                {
                    text:'管理亚马逊库存',
                    path:'/planning',
                    key:'header_child_Repertory'
                },
                {
                    text:'改进商品信息质量',
                    path:'/Improve',
                    key:'header_child_Improve'
                },
                {
                    text:'库存规划',
                    path:'/repertory',
                    key:'header_child_Planning'
                },
                {
                    text:'亚马逊物流远程配送',
                    path:'/Logistics',
                    key:'header_child_Logistics'
                },
                {
                    text:'添加新商品',
                    path:'/addlisting',
                    key:'header_child_Addlisting'
                },
                {
                    text:'批量上传商品',
                    path:'/Shopup',
                    key:'header_child_Shopup'
                },
                {
                    text:'库存报告',
                    path:'/Report',
                    key:'header_child_Report'
                },
                {
                    text:'全球销售',
                    path:'/GlobalSales',
                    key:'header_child_GlobalSales'
                },
                {
                    text:'管理亚马逊货件',
                    path:'/inboundQueue',
                    key:'header_child_InboundQueue'
                },
                {
                    text:'上传图片',
                    path:'/Imageup',
                    key:'header_child_Imageup'
                
                },
                {
                    text:'上传和管理视频',
                    path:'/AdminVideo',
                    key:'header_child_AdminVideo'
                },
            ]
        },
        {
            key:'header_nav_Pricing', //确定价格
            children:[
                {
                    text:'定价状况',
                    path:'/PricingHealth',
                    key:'header_child_PricingStatus'
                },
                {
                    text:'管理定价',
                    path:'/ManagePricing',
                    key:'header_child_PricingManagement'
                },
                {
                    text:'修复价格警告',
                    path:'/RepairPriceReport',
                    key:'header_child_RepairPriceReport'
                },
                {
                    text:'自动定价',
                    path:'/AutomatePricing',
                    key:'header_child_AutomaticPricing'
                },
                {
                    text:'佣金折扣',
                    path:'/Commission',
                    key:'header_child_CommissionDiscount'
                },
                {
                    text:'协议定价',
                    path:'/Negotiated',
                    key:'header_child_AgreementPricing'
                },
            ]
        },
        {
            key:'header_nav_Orders', //订单
            children:[
                {
                    text:'管理订单',
                    path:'/ManageOrders',
                    key:'header_child_ManageOrders'
                },
                {
                    text:'订单报告',
                    path:'/OrderReport',
                    key:'header_child_OrderReport'
                },
                {
                    text:'上传订单相关文件',
                    path:'/UploadOrder',
                    key:'header_child_UploadOrder'
                },
                {
                    text:'管理退货',
                    path:'/ManageReturns',
                    key:'header_child_ManageReturns'
                },
                {
                    text:'管理SAFE-T索赔',
                    path:'/SAFET',
                    key:'header_child_ManageSAFE-T'
                }
            ]
        },
        {
            key:'header_nav_Advertising', //广告
            children:[
                {
                    text:'广告活动管理',
                    path:'/Advertisement',
                    key:'header_child_Advertising'
                },
                {
                    text:'A+页面',
                    path:'/Aa',
                    key:'header_child_A+page'
                },
                {
                    text:'早期评论者计划',
                    path:'/Early',
                    key:'header_child_reviewerProgram'
                },
                {
                    text:'Vine',
                    path:'/registerToVine',
                    key:'header_child_Vine'
                },
                {
                    text:'秒杀',
                    path:'/Deals',
                    key:'header_child_Deals'
                },
                {
                    text:'优惠卷',
                    path:'/Coupons',
                    key:'header_child_Coupons'
                },
                {
                    text:'Prime专享折扣',
                    path:'/Prime',
                    key:'header_child_Discounts'
                },
                {
                    text:'管理促销',
                    path:'/Promotions',
                    key:'header_child_Promotions'
                }
            ]
        },
        {
            key:'header_nav_Stores',  //品牌旗舰店
            children:[
                {
                    text:'管理店铺',
                    path:'/Mengk',
                    key:'header_child_ManageStores'
                },
            ]
        },
        {
            key:'header_nav_Growth', //增长
            children:[
                {
                    text:'配送计划',
                    path:'/DistributionPlan',
                    key:'header_child_distribution'
                },
                {
                    text:'推荐计划',
                    path:'/ReferralProgram',
                    key:'header_child_Recommended'
                },
                {
                    text:'制定计划',
                    path:'/MakePlan',
                    key:'header_child_Custom'
                },
                {
                    text:'借贷',
                    path:'/Lending',
                    key:'header_child_Lending'
                },
            ]
        },
        {
            key:'header_nav_Reports',  //数据报告
            children:[
                {
                    text:'付款',
                    path:'/Payments',
                    key:'header_child_Payments'
                },
                {
                    text:'亚马逊销售指导',
                    path:'/Selling',
                    key:'header_child_SellingCoach'
                },
                {
                    text:'业务报告',
                    path:'/Business',
                    key:'header_child_BusinessReports'
                },
                {
                    text:'库存和销售报告',
                    path:'/Fulfillment',
                    key:'header_child_Fulfillment'
                },
                {
                    text:'广告',
                    path:'/AdvertisingReports',
                    key:'header_child_advertising'
                },
                {
                    text:'退货报告',
                    path:'/ReturnReports',
                    key:'header_child_ReturnReport'
                },
                {
                    text:'自定义报告',
                    path:'/Custom',
                    key:'header_child_CustomReport'
                },
                {
                    text:'税务文件库',
                    path:'/TaxDocument',
                    key:'header_child_TaxDocument'
                },
            ]
        },
        {
            key:'header_nav_Performance',   //绩效
            children:[
                {
                    text:'账户状况',
                    path:'/AccountHealth',
                    key:'header_child_Account'
                },
                {
                    text:'反馈',
                    path:'/Feedback',
                    key:'header_child_Feedback'
                },
                {
                    text:'亚马逊商城交易保障索赔',
                    path:'/AToZGuarantee',
                    key:'header_child_GuaranteeClaims'
                },
                {
                    text:'信用卡拒付索赔',
                    path:'/ChargebackClaims',
                    key:'header_child_ChargebackClaims'
                },
               
                {
                    text:'业绩通知',
                    path:"/Preformance"
                },
                {
                    text:'买家之声',
                    path:"/VicoOfTheCustomer"
                },
                {
                    text:'卖家大学',
                    path:"/SellerUniversity"
                },
            ]
        },
        {
            key:'header_nav_Apps_Services',  //应用商店
            children:[
                {
                    text:'官方移动应用',
                    path:"MobileApps"
                },
                {
                    text:'发现应用',
                    path:"/DiscoverApps"
                },
                {
                    text:'管理您的应用',
                    path:"/ManageApp"
                },
                {
                    text:'探索服务'
                },
                {
                    text:'开发应用程序',
                    path:'/DevelopApp'
                },
            ]
        },
        {
            key:'header_nav_B2B',  //B2B
            children:[
                {
                    text:'B2B平台'
                },
                {
                    text:'商品机会'
                },
                {
                    text:'管理报价',
                    path:"/ManageQuofile"
                },
                {
                    text:'企业资料'
                },
            ]
        },
        {
            key:'header_nav_Brands',    //品牌
            children:[
                {
                    text:'品牌分析'
                },
                {
                    text:'管理实验',
                    path:"/manageYourExperiments"
                },
                {
                    text:'品牌控制面板',
                    path:"/BrandDashbOard"
                },
                {
                    text:'品牌目录管理器',
                    path:"/BrandCataLogManage"
                },
                {
                    text:'卖家评论',
                    path:"/VirtualBundles"
                },
                {
                    text:'虚拟捆绑商品',
                    path:"/NewBundle"
                },
            ]
        },

    ];

    return <Container>
            <ul>
                {data.map((item,index)=>{
                    return <li key={index}>
                        <Popover 
                            getPopupContainer={trigger => trigger.parentNode}
                            placement="bottom" 
                            trigger="hover"
                            content={
                                <ul>
                                    {
                                        item.children.map((child,index2)=>{
                                            if(child.path){
                                                return child.key ? 
                                                <li key={index2}><Link to={child.path}>{intl.formatMessage({id:child.key})}</Link></li>
                                                :
                                                <li key={index2}><Link to={child.path}>{child.text}</Link></li>
                                            }else{
                                                return <li key={index2}><div>{child.text}</div></li>
                                            }
                                        })
                                    }
                                </ul>
                            } >
                            {intl.formatMessage({id:item.key})}
                        </Popover>
                    </li>
                })}
            </ul>
    </Container>
}

export default injectIntl(Nav);