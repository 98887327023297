import React from 'react'
import AJAX from "api/apis";
import './Unshipped.css'
import { Radio } from 'antd';
import { Link } from 'react-router-dom'
import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"
import { compose } from 'redux';

class Unshipped extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            radioValue: 1,
            page: 1,
            size: 10,
            total: 10,
            orderList: [],
            isTourOpen: true,
            steps1: [
                {
                    selector: '.Unshipped .stepGuidanceBox1',
                    content: '点击买家名称',
                }
            ],
            isActive: false
        }
    }
    componentWillMount() {
        this.getOrderList()
    }
    getOrderList = () => {
        AJAX.order.getOrderList({ page: 1, size: 100 }).then((res) => {
            if (res.code == 1 && res.data) {
                this.setState({
                    orderList: res.data.list
                })
            }
        })
    }

    radioChange = e => {
        this.setState({
            radioValue: e.target.value
        })
    };
    awakenTips = (type) => {
        this.setState({
            isActive: type
        })
    }
    render() {
        let { radioValue, orderList, steps1, isTourOpen, isActive } = this.state;
        return <div className="Unshipped">
            <div>
                <div className="Unshipped_head">
                    <div style={{ color: "#767676" }}>快速筛选：</div>
                    <div><Link><span>0</span> 今天配送</Link></div>
                    <div><Link><span>0</span> 未发货的优先配送订单</Link></div>
                    <div><Link><span>0</span> 未发货企业买家订单</Link></div>
                </div>
            </div>

            <div className="Unshipped_navBox">
                <div className="ausste_left">
                    <div className="ausste_leftP">
                        <div className="ausste_left_head">
                            <div className="ausste_left_headNav">
                                <p>细化依据：</p>
                            </div>
                            <div className="ausste_left_dateTitel">配送日期</div>
                        </div>

                        <div className="ausste_left_datenav">
                            <Radio.Group onChange={this.radioChange} value={radioValue}>
                                <div><Radio value={1} />所有日期</div>
                                <div><Radio value={2} />今天配送</div>
                                <div><Radio value={3} />明天配送</div>
                            </Radio.Group>
                        </div>

                        <div className="ausste_left_middle">
                            <div className="ausste_left_titel">销售渠道</div>
                            <div>
                                <ul>
                                    <li><input type="checkbox" />Amazon.com.mx</li>
                                    <li><input type="checkbox" />Amazon.com lnvoicing Shadow Marketplace</li>
                                    <li><input type="checkbox" />Amazon.ca</li>
                                    <li><input type="checkbox" />Amazon.com</li>
                                </ul>
                            </div>
                        </div>

                        <div className="ausste_left_bottom">
                            <div className="ausste_left_bottomI">配送服务</div>
                            <div className="ausste_left_bottomII"><Radio>Radio</Radio>优先配送</div>
                            <div className="ausste_left_bottomIII">企业账户详情</div>
                            <div className="ausste_left_bottomIIII"><input type="checkbox" />企业买家</div>
                        </div>
                    </div>
                </div>

                <div className="ausste_right">
                    <div style={{ display: "flex" }}>
                        <div>
                            <div className="ausste_right_headI">
                                <button>隐藏筛选条件</button>
                                <div className="ausste_right_headI_font">{orderList.length || 0}  个订单</div>
                                <span className="canceled_right_sp">最近七天</span>
                            </div>
                            {/* <div>未找到与指定搜索条件相匹配的订单</div> */}
                        </div>
                        <div style={{ width: "52%" }}>

                            <div className="ausste_right_headII">
                                <div className="ausste_right_headII_midButton ausste_right_headII_select">
                                    <label>
                                        <select>
                                            <option>日期范围：最近一天</option>
                                            <option>日期范围：最近3天</option>
                                            <option>日期范围：最近7天</option>
                                            <option>日期范围：最近14天</option>
                                            <option>日期范围：最近30天</option>
                                            <option>日期范围：最近90天</option>
                                            <option>日期范围：最近180天</option>
                                            <option>日期范围：最近365天</option>
                                            <option>日期范围：自定义日期范围</option>
                                        </select>
                                    </label>
                                </div>
                                <div className="ausste_right_headII_midButton">
                                    <label>
                                        <select>
                                            <option>订单日期（升序）</option>
                                            <option>订单日期（降序）</option>
                                            <option>发货日期（升序）</option>
                                            <option>发货日期（降序）</option>
                                            <option>配送日期（升序）</option>
                                            <option>配送日期（降序）</option>
                                            <option>状态（升序）</option>
                                            <option>状态（降序）</option>
                                        </select>
                                    </label>
                                </div>
                                <div className="ausste_right_headII_midButton">
                                    <label>
                                        <select>
                                            <option>每页结果：15</option>
                                            <option>每页结果：25</option>
                                            <option>每页结果：50</option>
                                            <option>每页结果：100</option>
                                        </select>
                                    </label>
                                </div>

                                <div className="ausste_right_headII_midButton">
                                    <button>设置表格首选项</button>
                                </div>

                                <div className="ausste_right_headII_endButton ">
                                    <button>刷新</button>
                                </div>
                            </div>


                            {/* <div className="ausste_right_headIII">
                            <label>
                                <select>
                                    <option>每页结果：15</option>
                                    <option>25</option>
                                    <option>50</option>
                                    <option>100</option>
                                </select>
                            </label>
                        </div> */}
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <input type="checkbox" />订单日期
                                </th>
                                <th>订单详情</th>
                                <th>图片</th>
                                <th>商品名称</th>
                                <th>买家选项</th>
                                <th>订单状态</th>
                                <th>操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderList.length > 0 ? orderList.map((item, index) => {
                                return <tr key={index}>
                                    <td>
                                        <input type="checkbox" />2个月前
                                        <p>{item.createTime}</p>
                                        {/* <p>上午9:23 PST</p> */}

                                    </td>
                                    <td>
                                        <p className="lanCol">{item.orderNumber}</p>
                                        <p>买家姓名</p>
                                        <p className="lanCol stepGuidanceBox1">
                                            <Link className="lanCol" to="/contactBuyer">LAST</Link>
                                        </p>
                                        <p>配送渠道:{item.orderDispatchingType == 1 ? '卖家' : '亚马逊'}</p>
                                        <p>销售渠道: {item.sellChannel}</p>
                                        <p>卖家订单编号: uawLcdh ESR</p>
                                    </td>
                                    <td>
                                        <img src={item.goodsImg ? item.goodsImg : require("@/assets/img/home/card-3.jpg").default} />
                                    </td>
                                    <td>
                                        <p className="col102">{item.goodsName}</p>
                                        <p>ASIN: {item.goodsAsin}</p>
                                        <p>SKU: {item.goodsSku}</p>
                                        <p>数量: {item.goodsCount}</p>
                                        <p>商品小计: {item.orderMoney}</p>
                                    </td>
                                    <td>
                                        <p>标准/平邮</p>
                                        <p>配送日期:{item.deliveryStartDate}</p>
                                        <p>送达日期:{item.takeStopDate}</p>
                                    </td>
                                    <td>
                                        <span className="sueess">{item.orderStatus == 1 ? '已发货' : item.orderStatus == 2 ? '已取消' : item.orderStatus == 3 ? '未发货' : '等待中'}</span>
                                    </td>
                                    <td className="operation">
                                        <button>购买配送</button>
                                        <button>确认发送</button>
                                        <button>打印装填单</button>
                                        <button>取消订单</button>
                                        <button>更多信息</button>
                                    </td>
                                </tr>
                            }) : null}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* 引导步骤 */}
            <TipsIcon clicks={this.awakenTips} />
            <Reactours routePath="Unshipped" isActive={isActive} clicks={this.awakenTips} steps={steps1} isOpen={isTourOpen} />
        </div>
    }
}

export default Unshipped