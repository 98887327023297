import { useCallback, useMemo, useState } from "react";
import { Card as AntCard } from "antd";
import { renderTooltip } from "@a-components/viewFragment";
import classNames from "classnames";
import { UpOutlined } from "@ant-design/icons";

import withProps from "./../withProps";

import "./style.css";

const Card = ({
  title,
  titleTooltip,
  extra,
  description,
  collapsible,
  className,
  ...otherProps
}) => {
  const [isFold, setFoldStatus] = useState(false);

  const toggle = useCallback(() => {
    if (!collapsible) return;
    setFoldStatus((p) => !p);
  }, [collapsible]);

  const realTitle = useMemo(() => {
    return (
      <>
        <span className="a-card__title lh-24">
          {collapsible ? (
            <UpOutlined
              className="fs-14"
              style={{
                marginRight: 4,
                transform: isFold ? "rotate(180deg)" : "",
              }}
            />
          ) : null}
          <span onClick={toggle}>{title}</span>
          {renderTooltip(titleTooltip, { icon: { className: "fs-14" } })}
        </span>
        {description ? <span className="fs-12 ml-6">{description}</span> : null}
      </>
    );
  }, [collapsible, isFold, toggle, title, titleTooltip, description]);

  return (
    <AntCard
      className={classNames(className, { collapsible, "is-folding": isFold })}
      title={realTitle}
      extra={extra}
      {...otherProps}
    ></AntCard>
  );
};

export default withProps(Card, {
  className: "a-card",
});
