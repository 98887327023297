import React from 'react'
import './BrandRegisteredFooter.css'
import { Link } from 'react-router-dom'
import IconFont from "components/Icon/Icon";

function BrandRegisteredFooter (){
  return (
    <div className="BrandRegisteredFooter" >
        <Link to="#" >使用条款</Link>
        <div className="feedback">
          反馈
          <span>x</span>
        </div>
    </div>
  )
}

export default BrandRegisteredFooter
