import React from "react";
import "./DiscoverApps.css";
import Header from "components/home_header";
import Footer from "components/addlisting_footer/FooterCom";
import IconFont from "components/Icon/Icon";
import { Row, Col, Divider } from "antd";
import ImageI from "../../assets/images/yysd_spxx.png";
import ImageII from 'assets/images/yysd_zddj.png'
import ImageIII from 'assets/images/yysd_kchdd.png'
import ImageIV from 'assets/images/ydyy_psjj.png'
import ImageV from 'assets/images/yysd_gg.png'
import ImageVI from 'assets/images/yysd_cx.png'
import ImageVII from 'assets/images/yysd_spyj.png'
import ImageVIII from 'assets/images/yys_fkpl.png'
import ImageIX from 'assets/images/ydyy_mjmj.png'
import ImageX from 'assets/images/yysd_fxhbg.png'
import ImageXI from 'assets/images/yysd_kjhsw.png'
import ImageXII from 'assets/images/yysd_dzsw.png'
import ImageXIII from 'assets/images/yysd_qfw.png'
import ImageXIV from 'assets/images/yysd_rz.png'





function DiscoverApps() {
  return (
    <div>
      <Header />
      <div>
        <div className="DiscoverApps_nav">
          <div className="DiscoverApps_nav_left">商城应用商店</div>
          <div className="DiscoverApps_nav_int">
            <input placeholder="在应用商店内搜索" />
            <IconFont type="icon-sousuo" />
          </div>
          <div className="DiscoverApps_nav_sele">
            <div className="DiscoverApps_nav_seleII">货币</div>
            <div>
              <select>
                <option>$ USD</option>
                <option>$ USD</option>
              </select>
            </div>
          </div>
        </div>

        <div className="DiscoverApps_head_image"></div>

        <div className="DiscoverApps_card">
          <div className="DiscoverApps_cardII">
            <Row gutter={16}>
              <Col className="gutter-row" span={6}>
                <div className="DiscoverApps_card_box">
                  <div className="DiscoverApps_card_img">
                    <img src={ImageI} />
                  </div>
                  <div>商品信息</div>
                  <div>
                      借助各种工具管理亚马逊商品信息，这些工具可以转换内部商品信息、与现有 ASIN 匹配，并减少商品信息中的错误</div>
                </div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div className="DiscoverApps_card_box">
                  <div  className="DiscoverApps_card_img">
                    <img src={ImageII} />
                  </div>
                  <div>自动定价</div>
                  <div>
                  借助工具使您的商品价格保持竞争力，这些工具可以根据您的业务规则自动对商品进行定价。
                  </div>
                </div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div className="DiscoverApps_card_box">
                  <div className="DiscoverApps_card_img">
                    <img src={ImageIII} />
                  </div>
                  <div>库存和订单管理</div>
                  <div>
                  自动管理您的亚马逊订单，并使您的库存水平在各个系统中保持同步。
                  </div>
                </div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div className="DiscoverApps_card_box">
                  <div className="DiscoverApps_card_img">
                    <img src={ImageIV} />
                  </div>
                  <div>配送解决方案</div>
                  <div>使用有助于加快运费购买、标签打印和物流进程的工具，使运输和配送流程实现自动化。</div>
                </div>
              </Col>
            </Row>
          </div>


          <div className="DiscoverApps_cardII">
            <Row gutter={16}>
              <Col className="gutter-row" span={6}>
                <div className="DiscoverApps_card_box">
                  <div className="DiscoverApps_card_img">
                    <img src={ImageV} />
                  </div>
                  <div>广告</div>
                  <div>优化您的按点击次数付费的广告预算和策略，提高商品曝光率和业务销售额。</div>
                </div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div className="DiscoverApps_card_box">
                  <div className="DiscoverApps_card_img">
                    <img src={ImageVI} />
                  </div>
                  <div>促销</div>
                  <div>通过创建有针对性且具有吸引力的促销来提高商品曝光率。</div>
                </div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div className="DiscoverApps_card_box">
                  <div className="DiscoverApps_card_img">
                    <img src={ImageVII} />
                  </div>
                  <div>商品研究和调查</div>
                  <div>使用买家需求等标准来筛选潜在商品，从而做出更多以数据为导向的采购决策。</div>
                </div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div className="DiscoverApps_card_box">
                  <div className="DiscoverApps_card_img">
                    <img src={ImageVIII} />
                  </div>
                  <div>反馈和评论</div>
                  <div>获取买家撰写的已经过验证的评论和反馈，以帮助提高您的亚马逊卖家评分并为买家提供有用的洞察信息。</div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="DiscoverApps_cardII">
            <Row gutter={16}>
              <Col className="gutter-row" span={6}>
                <div className="DiscoverApps_card_box">
                  <div className="DiscoverApps_card_img">
                    <img src={ImageIX} />
                  </div>
                  <div>买家与卖家消息服务</div>
                  <div>
                    获取有关回答买家问题方面的帮助，以便在规定时间内做出回复并提供高水准的客户服务。
                  </div>
                </div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div className="DiscoverApps_card_box">
                  <div className="DiscoverApps_card_img">
                    <img src={ImageX} />
                  </div>
                  <div>分析和报告</div>
                  <div>分析您的收益和每件商品的成本，以便您可以更好地将业务策略和关注点向那些发挥了效用的方面倾斜。</div>
                </div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div className="DiscoverApps_card_box">
                  <div className="DiscoverApps_card_img">
                    <img src={ImageXI} />
                  </div>
                  <div>会计和税务汇款</div>
                  <div>借助各种工具在单一位置跟踪财务状况、会计和税费，这些工具可以整合您的在线销售数据，并帮助您遵守税务法规。</div>
                </div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div className="DiscoverApps_card_box">
                  <div className="DiscoverApps_card_img">
                    <img src={ImageXII} />
                  </div>
                  <div>电子商务解决方案连接器</div>
                  <div>
                  将您的电子商务网站与亚马逊集成，以便更快地发布商品、改善订单管理、同步跨渠道库存等。
                  </div>
                </div>
              </Col>
            </Row>
          </div>


          <div className="DiscoverApps_cardII">
            <Row gutter={16}>
              <Col className="gutter-row" span={6}>
                <div className="DiscoverApps_card_box">
                  <div className="DiscoverApps_card_img">
                    <img src={ImageXIII} />
                  </div>
                  <div>全服务解决方案</div>
                  <div>整合整个销售生命周期内的业务管理。</div>
                </div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div className="DiscoverApps_card_box">
                  <div className="DiscoverApps_card_img">
                    <img src={ImageXIV} />
                  </div>
                  <div>融资和信贷</div>
                  <div>获得融资，为大宗采购提供资金并为您的业务日常运营提供支持。</div>
                </div>
              </Col>
            </Row>
          </div>

          
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default DiscoverApps;
