import React from 'react'
import './index.css'
import IconFont from 'components/Icon/Icon'
import Footer from 'components/addlisting_footer/FooterCom'
import Header from 'components/home_header'
import { Tabs } from 'antd';
import All from './module/all/index'
function Safet(){
    const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}
    return(
    <div>
        <Header/>
        <div classNam="SAFET_box">
            <div className="SAFET_head">
                <div>管理SATFE-T索赔<span>了解更多</span></div>
                <div><button>提出新的SAFE-T索赔</button></div>
            </div>

            <div className="SAFET_tab">
            <div className="TabHeader">
                 <Tabs defaultActiveKey="1" onChange={callback}>
                   <TabPane tab="全部" key="1">
                  <All/>
                   </TabPane>
                   <TabPane tab="等待卖家回应" key="2">
                   <All/>
                   </TabPane>
                   <TabPane tab="解决" key="3">
                   <All/>
                   </TabPane>
                   <TabPane tab="在调查中" key="4">
                   <All/>
                   </TabPane>
                 </Tabs>
            </div>
            </div>

        </div>

        <Footer/>
    </div>
    )
} 
export default Safet