import React, { useRef, useEffect, useCallback, useState } from 'react';
import './index.less'
import { Checkbox } from 'antd';
import Template from '../Template'
import AJAX from "api/apis";


export default (props) => {
  const [value, onChange] = useState(false);
  const [date, setDtae] = useState(null);
  const [dateObj, setDateObj] = useState(null);


  const collapseList = [
    {
      header: '什么是有效的身份证件？',
      html: '<p>您应该提供带照片的有效身份证件原件，必须显示您的照片、全名、出生日期、有效期（如适用）和签名（如适用）。确保带照片的身份证件中的照片与您的相貌相符。</p>'
    },
    {
      header: '什么是有效的营业执照？',
      html: '<p>中国的卖家应该提供显示法人代表姓名的营业执照，其上的姓名应与身份证件上的姓名一致。营业执照有效期不得少于自亚马逊账户注册之日起45天，该公司不能处于已经被当地政府撤销或关闭的状态或被列入“经营异常名录”。</p> '
    },
  ]
  const list = useCallback(() => {
    let time = date ? date.toDateString().split(" ") : null
    return [
      {
        title: '会面时间',
        img: 'meetingMatters-1',
        p: '',
        list: [
          `${time && time[2]}/${time && time[1]}/${time && time[3]} ${dateObj && dateObj.bAction} Asia/Shanghai`, '除身份验证以外，我们会将向您注册时填写的注册地址邮寄包含一次性密码的明信片来进行地址验证。当您完成视频电话预约后，明信片和相关说明将在5-8个工作日寄到您的邮寄地址。'
        ]
      },
      {
        title: '文件',
        img: 'meetingMatters-2',
        p: '会面时要携带原件',
        list: [
          '政府签发的带照片的有效身份证件',
          '显示法人代表姓名的中国营业执照原件，其上的姓名应与身份证件上的姓名一致。营业执照有效期不得少于自亚马逊账户注册之日起45天，该公司不能处于已经被当地政府撤销或关闭的状态或被列入“经营异常名录”。',
          '点击<a>此处</a>查看有关文件的其他指南。'
        ]
      },
      {
        title: '指南',
        img: 'meetingMatters-3',
        p: '',
        list: [
          '在安静的地方准时接入视频通话',
          '您和亚马逊工作人员将通过视频互相看见对方',
          '我们将在 24 小时内发送一封包含更多详情的电子邮件',
          '如有任何疑问或顾虑，<a>请联系我们</a>'
        ]
      },
      {
        title: '隐私政策',
        img: 'meetingMatters-4',
        p: '',
        list: [
          '我们可能会录制您的视频通话以确保审核质量',
          '我们会根据亚马逊隐私声明来处理您的数据',
          '我们将使用您上传的身份证件来验证您的身份',
        ]
      },
      {
        title: '设备和软件',
        img: 'meetingMatters-5',
        p: '',
        list: [
          '您可选择在具备网络摄像头和麦克风的桌面电脑或手提电脑上使用最新版本的Chrome/Firefox/QQ/Sogou浏览器进入视频会议',
          '您也可使用苹果iPhone或iPad并<a>下载</a>Chime移动应用进入会议。安卓移动设备暂不支持该视频会议',
        ]
      },
      {
        title: '语言',
        img: 'meetingMatters-6',
        p: '',
        list: [
          '我们将支持Chinese和English。',
          '如果这不是您首选的语言，请带一名口译人员一起参加会面。',
        ]
      },
      {
        title: '录制通知',
        img: 'meetingMatters-7',
        p: '',
        list: [],
        checkbox: '单击“下一步”，即表示您确认可以出与质量保证和审计目的录制您的视频通话'
      },
    ]
  }, [date, dateObj])
  useEffect(() => {
    getRegInfo()
  }, [])
  const getRegInfo = useCallback(() => {
    AJAX.login.getStoreRegisterInfo([]).then((res) => {
      for (let item of res.data) {
        if (item.propertyValue) {
          if (item.propertyKey == "authentication_videoconferencing_time") {
            let data = JSON.parse(item.propertyValue)
            setDtae(new Date(data.date))
            setDateObj(data)
          }
        }
      }
    })
  }, [])

  const nextEvent = useCallback(() => {
    return {
      arr: [],
      localArr: null,
      whichPage: 'videoAuthentication/meetingMatters',
      step: 11
    }
  })

  return (
    <Template
      collapseList={collapseList}
      prePath="/videoAuthentication/MeetingTime"
      nextPath="/videoAuthentication/VideoVerification"
      nextEvent={nextEvent}
      current={5}
      disabled={!value}
      className='meetingMatters'
      nextText="接受"
    >
      <React.Fragment>
        <h3>身份验证</h3>
        <div className='vi-divider'></div>
        <ul>
          {
            list().map(item => {
              return (
                <li key={item.img} className='item'>
                  <h6>{item.title}</h6>
                  <img src={require(`@/assets/images/${item.img}.jpg`).default}></img>
                  {
                    item.p && <p>{item.p}</p>
                  }
                  {
                    item.list.map(v => {
                      if (v.includes('<a>')) {
                        return <div key={v} className="list">
                          <div>•</div>
                          <div dangerouslySetInnerHTML={{ __html: v }}></div>
                        </div>
                      } else {
                        return <div key={v} className="list">
                          <div>•</div>
                          <div>{v}</div>
                        </div>
                      }

                    })
                  }
                  {
                    item.checkbox && (
                      <div>
                        <Checkbox onChange={(e) => {
                          // console.log(e);
                          onChange(e.target.checked)
                        }}>{item.checkbox}</Checkbox>
                      </div>
                    )
                  }
                </li>
              )
            })
          }
        </ul>

      </React.Fragment>
    </Template>
  )
}