import { FormItem as StyledFormItem } from "./styled";
import withProps from "./../withProps";
import { useMemo } from "react";
import { renderTooltip } from "@a-components/viewFragment";

const FormItem = ({ label, tooltip, ...otherProps }) => {
  const innerLabel = useMemo(() => {
    if (!label) return null;
    return (
      <>
        {label} {renderTooltip(tooltip)}
      </>
    );
  }, [label, tooltip]);

  return <StyledFormItem label={innerLabel} {...otherProps} />;
};

export default withProps(FormItem, {
  className: "a-form-item",
});
