import React, { useState } from 'react'
import './A-to-zGuarantee.css'
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import IconFont from 'components/Icon/Icon'
import { Tabs, Row, Col } from 'antd';
import { CaretRightOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'
const { TabPane } = Tabs;

function AToZGuarantee() {
	let [detailsShow, setDetailsShow] = useState(false)

	return (
		<div className="AToZGuarantee">
			<Header />

			<div>

				<div className="end10_mybody">
					<p className="gl">管理亚马逊商城交易索赔</p>
					<div className="mybody-div">
						<svg t="1611544312504" className="icon i" viewBox="0 0 1024 1024"
							version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1812"
							width="30" height="30"><path d="M511.04 0a511.04 511.04 0 1 1 0 1022.08 511.04 511.04 0 0 1 0-1022.08z m0 81.472a429.568 429.568 0 1 0 0 859.136 429.568 429.568 0 0 0 0-859.136z m2.816 287.616c27.52 0 49.856 22.272 49.856 49.792v343.168c0 27.52-22.336 49.856-49.856 49.856h-4.992a49.856 49.856 0 0 1-49.792-49.856V418.88c0-27.52 22.272-49.792 49.792-49.792h4.992zM511.36 212.096a52.288 52.288 0 1 1 0 104.64 52.288 52.288 0 0 1 0-104.64z" fill="#000000" p-id="1813"></path></svg>
						<p>权限更改提醒</p>
						<p>我们最近进行了重置辅助用户权限的更改，导致辅助用户无法在工具中执行操作。如果您无法执行操作，您的主账户管理员需要在"设置"、"用户权限"、"管理权限"下授予您权限，然后选择"操作报告"的 <br /> "查看和编辑"单选按钮。有关编辑用户权限的更多信息，请参阅https://sellercentral.amazon.com/gp/help/help-popup.html/?itemID=901</p>
						<svg t="1611545446953" className="icon x" viewBox="0 0 1024 1024"
							version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2564"
							width="20" height="20"><path d="M256 810.666667c-12.8 0-21.333333-4.266667-29.866667-12.8-17.066667-17.066667-17.066667-42.666667 0-59.733334l512-512c17.066667-17.066667 42.666667-17.066667 59.733334 0s17.066667 42.666667 0 59.733334l-512 512c-8.533333 8.533333-17.066667 12.8-29.866667 12.8z" p-id="2565"></path><path d="M768 810.666667c-12.8 0-21.333333-4.266667-29.866667-12.8l-512-512c-17.066667-17.066667-17.066667-42.666667 0-59.733334s42.666667-17.066667 59.733334 0l512 512c17.066667 17.066667 17.066667 42.666667 0 59.733334-8.533333 8.533333-17.066667 12.8-29.866667 12.8z" p-id="2566"></path></svg>
					</div>
					<div className="end10_mybody_tab">
						<Tabs defaultActiveKey="4" >
							<TabPane tab="操作" key="1">
								<div className="end10_mybody_tab_inp">
									<div className="end10_mybody_tab_inpII"><input placeholder="按订单编号搜索" /></div>
									<div className="end10_mybody_tab_inp_btn">
										<a><IconFont type="icon-sousuo" /></a>
									</div>
								</div>

								<div className="mybody-div">
									<svg t="1611544312504" className="icon i" viewBox="0 0 1024 1024"
										version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1812"
										width="30" height="30"><path d="M511.04 0a511.04 511.04 0 1 1 0 1022.08 511.04 511.04 0 0 1 0-1022.08z m0 81.472a429.568 429.568 0 1 0 0 859.136 429.568 429.568 0 0 0 0-859.136z m2.816 287.616c27.52 0 49.856 22.272 49.856 49.792v343.168c0 27.52-22.336 49.856-49.856 49.856h-4.992a49.856 49.856 0 0 1-49.792-49.856V418.88c0-27.52 22.272-49.792 49.792-49.792h4.992zM511.36 212.096a52.288 52.288 0 1 1 0 104.64 52.288 52.288 0 0 1 0-104.64z" fill="#000000" p-id="1813"></path></svg>
									<p>未找到索赔</p>
									<p>目前没有需要回复的索赔。<a href="/gp/help/G1781">了解更多信息</a></p>
									<svg t="1611545446953" className="icon x" viewBox="0 0 1024 1024"
										version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2564"
										width="20" height="20"><path d="M256 810.666667c-12.8 0-21.333333-4.266667-29.866667-12.8-17.066667-17.066667-17.066667-42.666667 0-59.733334l512-512c17.066667-17.066667 42.666667-17.066667 59.733334 0s17.066667 42.666667 0 59.733334l-512 512c-8.533333 8.533333-17.066667 12.8-29.866667 12.8z" p-id="2565"></path><path d="M768 810.666667c-12.8 0-21.333333-4.266667-29.866667-12.8l-512-512c-17.066667-17.066667-17.066667-42.666667 0-59.733334s42.666667-17.066667 59.733334 0l512 512c17.066667 17.066667 17.066667 42.666667 0 59.733334-8.533333 8.533333-17.066667 12.8-29.866667 12.8z" p-id="2566"></path></svg>

								</div>
							</TabPane>
							<TabPane tab="审核中" key="2">
								<div className="end10_mybody_tab_inp">
									<div className="end10_mybody_tab_inpII"><input placeholder="按订单编号搜索" /></div>
									<div className="end10_mybody_tab_inp_btn">
										<a><IconFont type="icon-sousuo" /></a>
									</div>
								</div>

								<div className="mybody-div">
									<svg t="1611544312504" className="icon i" viewBox="0 0 1024 1024"
										version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1812"
										width="30" height="30"><path d="M511.04 0a511.04 511.04 0 1 1 0 1022.08 511.04 511.04 0 0 1 0-1022.08z m0 81.472a429.568 429.568 0 1 0 0 859.136 429.568 429.568 0 0 0 0-859.136z m2.816 287.616c27.52 0 49.856 22.272 49.856 49.792v343.168c0 27.52-22.336 49.856-49.856 49.856h-4.992a49.856 49.856 0 0 1-49.792-49.856V418.88c0-27.52 22.272-49.792 49.792-49.792h4.992zM511.36 212.096a52.288 52.288 0 1 1 0 104.64 52.288 52.288 0 0 1 0-104.64z" fill="#000000" p-id="1813"></path></svg>
									<p>未找到索赔</p>
									<p>目前没有需要回复的索赔。<a href="/gp/help/G1781">了解更多信息</a></p>
									<svg t="1611545446953" className="icon x" viewBox="0 0 1024 1024"
										version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2564"
										width="20" height="20"><path d="M256 810.666667c-12.8 0-21.333333-4.266667-29.866667-12.8-17.066667-17.066667-17.066667-42.666667 0-59.733334l512-512c17.066667-17.066667 42.666667-17.066667 59.733334 0s17.066667 42.666667 0 59.733334l-512 512c-8.533333 8.533333-17.066667 12.8-29.866667 12.8z" p-id="2565"></path><path d="M768 810.666667c-12.8 0-21.333333-4.266667-29.866667-12.8l-512-512c-17.066667-17.066667-17.066667-42.666667 0-59.733334s42.666667-17.066667 59.733334 0l512 512c17.066667 17.066667 17.066667 42.666667 0 59.733334-8.533333 8.533333-17.066667 12.8-29.866667 12.8z" p-id="2566"></path></svg>

								</div>
							</TabPane>
							<TabPane tab="选择申诉" key="3">
								<div className="end10_mybody_tab_inp">
									<div className="end10_mybody_tab_inpII"><input placeholder="按订单编号搜索" /></div>
									<div className="end10_mybody_tab_inp_btn">
										<a><IconFont type="icon-sousuo" /></a>
									</div>
								</div>

								<div className="mybody-div">
									<svg t="1611544312504" className="icon i" viewBox="0 0 1024 1024"
										version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1812"
										width="30" height="30"><path d="M511.04 0a511.04 511.04 0 1 1 0 1022.08 511.04 511.04 0 0 1 0-1022.08z m0 81.472a429.568 429.568 0 1 0 0 859.136 429.568 429.568 0 0 0 0-859.136z m2.816 287.616c27.52 0 49.856 22.272 49.856 49.792v343.168c0 27.52-22.336 49.856-49.856 49.856h-4.992a49.856 49.856 0 0 1-49.792-49.856V418.88c0-27.52 22.272-49.792 49.792-49.792h4.992zM511.36 212.096a52.288 52.288 0 1 1 0 104.64 52.288 52.288 0 0 1 0-104.64z" fill="#000000" p-id="1813"></path></svg>
									<p>未找到索赔</p>
									<p>目前没有需要回复的索赔。<a href="/gp/help/G1781">了解更多信息</a></p>
									<svg t="1611545446953" className="icon x" viewBox="0 0 1024 1024"
										version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2564"
										width="20" height="20"><path d="M256 810.666667c-12.8 0-21.333333-4.266667-29.866667-12.8-17.066667-17.066667-17.066667-42.666667 0-59.733334l512-512c17.066667-17.066667 42.666667-17.066667 59.733334 0s17.066667 42.666667 0 59.733334l-512 512c-8.533333 8.533333-17.066667 12.8-29.866667 12.8z" p-id="2565"></path><path d="M768 810.666667c-12.8 0-21.333333-4.266667-29.866667-12.8l-512-512c-17.066667-17.066667-17.066667-42.666667 0-59.733334s42.666667-17.066667 59.733334 0l512 512c17.066667 17.066667 17.066667 42.666667 0 59.733334-8.533333 8.533333-17.066667 12.8-29.866667 12.8z" p-id="2566"></path></svg>

								</div>
							</TabPane>
							<TabPane tab="全部" key="4">
								<div className="end10_mybody_tab_inp">
									<div className="end10_mybody_tab_inpII"><input placeholder="按订单编号搜索" /></div>
									<div className="end10_mybody_tab_inp_btn">
										<a><IconFont type="icon-sousuo" /></a>
									</div>
								</div>

								<Row className="Ato_claimDemage_body" >
									<Col span={7} >
										<h3>已完成</h3>
										<div>索赔拒绝日期：八月 8</div>
										<div>无需采取进一步操作</div>
										<div>订单编号： <span style={{ color: "rgb(0,130,150)" }} >114-3804720-9568264</span></div>
										<div>索赔金额： USD 129.99</div>
										<div>索赔日期： 2018年8月6日</div>
									</Col>
									<Col span={2} >
										<img src={require("@assets/images/Atotable1.png").default} />
									</Col>
									<Col span={11} >
										<div>买家问题：</div>
										<div style={{ fontSize: 16, fontWeight: 700 }} >包裹未到达</div>
										<div>预计送达时间：  2018年6月27日 - 2018年7月24日</div>
										<Link to="#" >MengK 1400W B lender, Professional Industrial K itchen Juicer Blenders for Drinks and Smoothies with 67 oZ BPA-Free Pitcher,Comm ercial Heavy Duty Blender</Link>
										<div>买家备注：  [自动生成的评价]： 买家报告他们没有收到此订单。买家已请求该订单的亚马逊商城交易保障退款。</div>
										{
											detailsShow ?
												<div style={{ cursor: "pointer", fontWeight: 700, fontSize: 14 }} onClick={() => { setDetailsShow(false) }} ><span>隐藏索赔详情</span> <CaretDownOutlined /></div>
												: <div style={{ cursor: "pointer", fontWeight: 700, fontSize: 14 }} onClick={() => { setDetailsShow(true) }} ><span>显示索赔详情  </span><CaretRightOutlined /></div>
										}
									</Col>
								</Row>
								{
									detailsShow && (
										<div className="Ato_claimDemage_details" >
											<div><strong>买家问题：</strong><span>包裹未到达</span></div>
											<div><strong>买家备注：</strong><span>[自动生成的评价]： 买家报告他们没有收到此订单。买家已请求该订单的亚马逊商城交易保障退款。</span></div>
											<div><strong>索赔商品：</strong><span>1 of 1 MengK 1400W Blender,Professional Industrial Kitchen Juicer Blenders for Drinks and Smoothies with 67oz BPA-Free Pitcher,Commercial Heavy Duty Blender </span></div>
											<div><strong>索赔金额：</strong><span>USD 129.99</span></div>
											<div><strong>订单详情</strong><span> USD 129.99 ( 114-3804720-9568264 )  2018年5月22日</span></div>
											<div><strong>退款：</strong><span>USD 0.00</span></div>
											<div><strong>配送详情：</strong><span>包裹 1 - 4PX (EA338119507CN)  2018年5月30日</span></div>
											<div className="Ato_claimDemage_yourState" >
												<strong>您的陈述：</strong>
												<div>
													<span>2018年8月7日</span>
													<p>
														在 2018.08.03，Big corn 收到一封来自 Tiffany Grant的电子邮件，邮件中表示他未收到此包裹。 Big corn的客户服务部门在 2018.08.04 就此作出了回复，
														并对客户带来的不便表示了歉意。在信中客户服务部门还表示客户所购买的商品已经送达，快递已经妥投，并附上物流追踪编号（EA338119507CN）及配送详情
														（2018.06.05妥投）。因此要求驳回客户的申诉。 Tiffany Grant在2018.08.06未回复该邮件，开启申诉表示自己从未收到包裹。在订单详情里表示已发货，
														这个说明表示商品已经发货。Big corn 在收到退货后仍会提供退款。请见来自和寄给Tiffany Grant的电子邮件作为参考。
														谢谢Big corn
													</p>
												</div>
												<div>
													<span>2018年8月7日</span>
													<p>
														At 2018.08.03, Big Corn received an email from Tiffany Grant stating that he did not receive the package.

														Big Corn's customer service department responded to this in 2018.08.04 and apologized for the inconvenience caused by the customer.

														In the letter, the customer service department also stated that the goods purchased by the customer have been delivered, the courier has been properly placed, and the logistics tracking number (EA338119507CN) and the delivery details (2018.06.05 duly) are attached. Therefore, it is required to reject the customer's complaint.

														Tiffany Grant did not respond to this email at 2011.08.06 and opened the complaint that he had never received the package. In the order details, it indicates that the goods have been shipped. This description indicates that the goods have been shipped.

														Big corn is accompanied by an attachment description. Please see the email from and to Tiffany Grant for reference.

														Thank you
														Big corn
													</p>
												</div>
												<div>
													<span>2018年8月7日</span>
													<p>Logistics inquiry URL: http://express.4px.com/#trackTracking code: EA338119507CN</p>
												</div>
												<div>
													<span>2018年8月8日</span>
													<p>
														At 2018.08.03, Big Corn received an email from Tiffany Grant stating that he did not receive the package. Big Corn's customer service depa
														rtment responded to this in 2018.08.04 and apologized for the inconvenience caused by the customer. In the letter, the customer service de
														partment also stated that the goods purchased by the customer have been delivered, the courier has been properly placed, and the logistics
														tracking number (EA338119507CN) and the delivery details (2018.06.05 duly) are attached. Therefore, it is required to reject the custo
														mer's complaint. Tiffany Grant did not respond to this email at 2011.08.06 and opened the complaint that he had never received the pa
														ckage. In the order details, it indicates that the goods have been shipped. This description indicates that the goods have been shipp
														ed. Big corn is accompanied by an attachment description. Please see the email from and to Tiffany Grant for reference.
														Thank you
														Big corn
														Tuesday, August 7, 2018 Logistics inquiry URL: http://express.4px.com/#track
														Tracking code: EA338119507CN
													</p>
												</div>
											</div>
										</div>
									)
								}


							</TabPane>
						</Tabs>


					</div>

				</div>


			</div>




			<Footer />
		</div>
	)
}

export default AToZGuarantee