import React, { useState } from "react"
import AJAX from "api/apis";
import "./Tab-header.css"
import { Tabs, Button, message } from 'antd';
import { Link } from 'react-router-dom';
import Tab1Banner from "../components/Tab1-banner/Tab1-banner";
import Tab2Banner from "../components/Tab2-banner/Tab2-banner";
import Tab3Banner from "../components/Tab3-banner/Tab3-banner";
import Tab4Banner from "../components/Tab4-banner/Tab4-banner";
import Tab5Banner from "../components/Tab5-banner/Tab5-banner";
import Tab6Banner from "../components/Tab6-banner/Tab6-banner";
import Tab7Banner from "../components/Tab7-banner/Tab7-banner";
import Tab8Banner from "../components/Tab8-banner/Tab8-banner";
import IconFont from "../../../../components/Icon/Icon";


const { TabPane } = Tabs;
const specialReg = /[`~!@#$%^&*()_\-+=<>?:"{}|,\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im;   //特殊字符验证
const zmReg = /^[a-zA-Z_\u4e00-\u9fa5]+$/
class TabHeader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                tabList: [
                    { id: 1, title: "重要信息", iconfont: true },
                    { id: 2, title: "变体", iconfont: false },
                    { id: 3, title: "报价", iconfont: true },
                    { id: 4, title: "图片", iconfont: false }
                ],
                tabList2: [
                    { id: 1, title: "重要信息", iconfont: true },
                    { id: 2, title: "变体", iconfont: false },
                    { id: 3, title: "报价", iconfont: false },
                    { id: 4, title: "图片", iconfont: false },
                    { id: 5, title: "合规信息", iconfont: false },
                    { id: 6, title: "描述", iconfont: false },
                    { id: 7, title: "关键字", iconfont: false },
                    { id: 8, title: "更多详情", iconfont: false }
                ],
                tabCount: "",
            },
            addInfo: [],
            goodsIdList: [],
            isSenior: false,
            isSubmit: false,
            isClick: false,
            submitList: [],
            isDisplayCode: true,
            disabledBtn: true,
            isVariant: false,
        }
    }
    componentDidMount() {

    }
    displayCode = (type) => {
        this.setState({
            isDisplayCode: type
        })
    }
    addGoodsInfo = () => {
        let goodsBrand = null, goodsCondition = null, goodsDispatchingType = 1, goodsInventory = null, goodsMoney = null, goodsName = null, goodsNumber = null, goodsNumberType = null, goodsSku = null;
        let { addInfo, isClick } = this.state;
        if (isClick) return false;
        // this.setState({
        //     isClick: true
        // })
        console.log(addInfo);

        let addGoodsReqList = []
        // 默认传卖家配送，即使选的是亚马逊

        let goodsElseBasicsInfoReqList = [
            {
                goodsBasicsKeyId: "23",
                goodsElseBasicsValue: "0",
            }
        ];

        //其他属性
        // {
        //     goodsBasicsKeyId: "23",
        //     goodsElseBasicsValue: "0",
        // }
        for (let infoItem in addInfo) {
            for (let infoKey in addInfo[infoItem]) {
                if (infoItem != 2 && infoItem != 5) {
                    if (infoKey == 1) goodsNumber = addInfo[infoItem][infoKey]
                    if (infoKey == "goodsNumberType") goodsNumberType = addInfo[infoItem][infoKey]
                    if (infoKey == 24) goodsSku = addInfo[infoItem][infoKey]
                    if (addInfo[infoItem][infoKey]) {
                        if (infoKey == "goodsNumberType") {
                            goodsElseBasicsInfoReqList.push({
                                goodsBasicsKeyId: "109",
                                goodsElseBasicsValue: addInfo[infoItem][infoKey] + "",
                            })
                        } else if (infoKey == 24) {
                            goodsElseBasicsInfoReqList.push({
                                goodsBasicsKeyId: infoKey,
                                goodsElseBasicsValue: addInfo[infoItem][infoKey].toUpperCase(),
                            })
                        } else if (infoKey == 23) {
                            // goodsElseBasicsInfoReqList.push({
                            //     goodsBasicsKeyId: infoKey,
                            //     goodsElseBasicsValue: addInfo[infoItem][infoKey] + "",
                            // })
                        } else {
                            goodsElseBasicsInfoReqList.push({
                                goodsBasicsKeyId: infoKey,
                                goodsElseBasicsValue: String(addInfo[infoItem][infoKey]).trim(),
                            })
                        }
                    }
                }
            }
        }
        goodsElseBasicsInfoReqList.push({
            goodsBasicsKeyId: "1100",
            goodsElseBasicsValue: true,
        })
        goodsElseBasicsInfoReqList.push({
            goodsBasicsKeyId: "1200",
            goodsElseBasicsValue: false,
        })
        if (addInfo[3]) {
            goodsElseBasicsInfoReqList = goodsElseBasicsInfoReqList.map(item => {
                if (item.goodsBasicsKeyId == '23') {
                    item.goodsElseBasicsValue = addInfo[3]['23'] + '' || "0"
                }
                return item
            })
        }

        let obj = {
            "addGoodsReqList": addGoodsReqList,
            "goodsReq": {
                "classifyId": this.props.id || null,  //分类id
                "goodsBrand": goodsBrand,  //商品品牌
                "goodsCondition": goodsCondition,  //商品状况
                "goodsDispatchingType": goodsDispatchingType,  //配送渠道 0-亚马逊配送 1- 自行配送 默认0 ,
                "goodsInventory": goodsInventory,  //商品库存
                "goodsMoney": goodsMoney,  //商品价格
                "goodsName": goodsName,  //商品名称
                "goodsSku": goodsSku,  //卖家sku
                "goodsElseBasicsInfoReqList": goodsElseBasicsInfoReqList || null,  //其他属性
                "goodsInfoStatus": 1,  //商品信息状态 0-信息已完善 1-信息未完善 ,
                "goodsIsMaster": true,  // 商品是否是主干父体 0-不是 1-是 ,
                "goodsIsVariant": addInfo[2] && addInfo[2].length ? true : false,  //商品是否是变体商品 0-不是 1-是 ,
            },
            "isVariant": addInfo[2] && addInfo[2].length > 0 ? false : true
        }
        if (addInfo[2] && addInfo[2].length > 0) {
            for (let item of addInfo[2]) {
                if (item) {
                    let goodsVariantReqList = [], goodsVariantElseBasicsInfoReqList = JSON.parse(JSON.stringify(goodsElseBasicsInfoReqList));  //变体属性
                    for (let elseBasicsInfo in item.item) {
                        goodsVariantReqList.push({
                            "variantId": item.CombinationId[elseBasicsInfo] || null,
                            "variantValue": item.item[elseBasicsInfo] || null
                        })
                    }
                    //    商品编码id：1
                    //    商品编码类型：109
                    //    状态：31
                    //    价格：26
                    //    数量：27
                    //    SKU：24
                    for (let goodsVariantItem of goodsVariantElseBasicsInfoReqList) {
                        if (goodsVariantItem.goodsBasicsKeyId == 1) goodsVariantItem.goodsElseBasicsValue = item.content.goodsNumber.trim()
                        if (goodsVariantItem.goodsBasicsKeyId == 109) goodsVariantItem.goodsElseBasicsValue = item.content.goodsNumberType
                        if (goodsVariantItem.goodsBasicsKeyId == 31) goodsVariantItem.goodsElseBasicsValue = item.content.goodsCondition
                        if (goodsVariantItem.goodsBasicsKeyId == 26) goodsVariantItem.goodsElseBasicsValue = item.content.goodsMoney
                        if (goodsVariantItem.goodsBasicsKeyId == 27) goodsVariantItem.goodsElseBasicsValue = item.content.goodsInventory
                        if (goodsVariantItem.goodsBasicsKeyId == 24) goodsVariantItem.goodsElseBasicsValue = item.content.goodsSku
                    }
                    if (JSON.stringify(goodsVariantElseBasicsInfoReqList).indexOf('"goodsBasicsKeyId":"24",') == -1) {
                        goodsVariantElseBasicsInfoReqList.push({
                            goodsBasicsKeyId: 24,
                            goodsElseBasicsValue: item.content.goodsSku
                        })
                    }
                    if (JSON.stringify(goodsVariantElseBasicsInfoReqList).indexOf('"goodsBasicsKeyId":"1",') == -1) {
                        goodsVariantElseBasicsInfoReqList.push({
                            goodsBasicsKeyId: 1,
                            goodsElseBasicsValue: item.content.goodsNumber.trim()
                        })
                    }
                    if (JSON.stringify(goodsVariantElseBasicsInfoReqList).indexOf('"goodsBasicsKeyId":"109",') == -1) {
                        goodsVariantElseBasicsInfoReqList.push({
                            goodsBasicsKeyId: 109,
                            goodsElseBasicsValue: item.content.goodsNumberType
                        })
                    }
                    if (JSON.stringify(goodsVariantElseBasicsInfoReqList).indexOf('"goodsBasicsKeyId":"31",') == -1) {
                        goodsVariantElseBasicsInfoReqList.push({
                            goodsBasicsKeyId: 31,
                            goodsElseBasicsValue: item.content.goodsCondition
                        })
                    }
                    if (JSON.stringify(goodsVariantElseBasicsInfoReqList).indexOf('"goodsBasicsKeyId":"26",') == -1) {
                        goodsVariantElseBasicsInfoReqList.push({
                            goodsBasicsKeyId: 26,
                            goodsElseBasicsValue: item.content.goodsMoney
                        })
                    }
                    if (JSON.stringify(goodsVariantElseBasicsInfoReqList).indexOf('"goodsBasicsKeyId":"27",') == -1) {
                        goodsVariantElseBasicsInfoReqList.push({
                            goodsBasicsKeyId: 27,
                            goodsElseBasicsValue: item.content.goodsInventory
                        })
                    }
                    addGoodsReqList.push({
                        "classifyId": this.props.id || '',  //分类id
                        "goodsBrand": addInfo[1].goodsBrand || '',  //商品品牌
                        "goodsCondition": item.content.goodsCondition || '',  //商品状况
                        // "goodsDispatchingType": addInfo[3].goodsDispatchingType ? addInfo[3].goodsDispatchingType == 0 ? false : true : false,  //配送渠道 0-亚马逊配送 1- 自行配送 默认0 ,
                        "goodsDispatchingType": goodsDispatchingType,
                        "goodsElseBasicsInfoReqList": goodsVariantElseBasicsInfoReqList || '',  //其他属性
                        "goodsVariantReqList": goodsVariantReqList,  //变体属性
                        "goodsInfoStatus": 1,  //商品信息状态 0-信息已完善 1-信息未完善 ,
                        "goodsInventory": item.content.goodsInventory || '',  //商品库存
                        "goodsIsMaster": false,  // 商品是否是主干父体 0-不是 1-是 ,
                        "goodsIsVariant": false,  //商品是否是变体商品 0-不是 1-是 ,
                        "goodsMoney": item.content.goodsMoney || '',  //商品价格
                        "goodsName": `${addInfo[1][2]}` || '',  //商品名称
                        // "goodsName": `${addInfo[1][2]}（${item.item}）` || '',  //商品名称
                        "goodsNumber": item.content.goodsNumber.trim() || '',  //商品编码
                        "goodsNumberType": item.content.goodsNumberType,  //商品编码类型id
                        "goodsSku": item.content.goodsSku || '',  //卖家sku
                        "imgUrl": []
                    })
                }
            }
        } else {
            obj.goodsReq.goodsNumber = goodsNumber  //商品编码
            obj.goodsReq.goodsNumberType = goodsNumberType  //商品编码类型id
        }
        if (addInfo[5]) {
            for (let keys in addInfo[5]) {
                if (keys != null) {
                    obj.goodsReq.imgUrl = addInfo[5]
                    for (let keyItem in obj.addGoodsReqList) {
                        obj.addGoodsReqList[keyItem].imgUrl = addInfo[5]
                    }
                }
            }
        }
        console.log(obj);
        AJAX.addProduct.addGoodsInfo(obj).then((res) => {
            message.success(res.msg);
            this.setState({
                isClick: false
            })
            if (res.code == 1) {

                setTimeout(() => {
                    this.props.props.history.push('/inventory')
                }, 1000)
            }
        }).catch((err) => {
            this.setState({
                isClick: false
            })
        })
        setTimeout(() => {
            this.setState({
                isClick: false
            })
        }, 3000)
    }
    updateGoodsElseExtendsValue = () => {
        let { addInfo, goodsIdList, isClick } = this.state;
        if (isClick) return;
        this.setState({ isClick: true })
        let arr = [], addGoodsReqList = [];
        for (let items in addInfo) {
            if (addInfo[items] && items != 2) {
                for (let childItems of addInfo[items]) {
                    arr.push(childItems)
                }
            }
        }
        if (addInfo[2] && addInfo[2].length > 0) {
            for (let item of addInfo[2]) {
                let goodsVariantReqList = [], goodsVariantElseBasicsInfoReqList = item.goodsVariantElseBasicsInfoVoList;  //变体属性
                if (item) {
                    for (let elseBasicsInfo in item.item) {
                        goodsVariantReqList.push({
                            "variantId": item.CombinationId[elseBasicsInfo] || null,
                            "variantValue": item.item[elseBasicsInfo] || null
                        })
                    }
                    //    商品编码id：1
                    //    商品编码类型：109
                    //    状态：31
                    //    价格：26
                    //    数量：27
                    //    SKU：24
                    if (item.isAdd) goodsVariantElseBasicsInfoReqList = JSON.parse(JSON.stringify(arr))
                    console.log(arr, "arr")
                    for (let goodsVariantItem of goodsVariantElseBasicsInfoReqList) {
                        if (item.isAdd) { delete goodsVariantItem.elseExtendsId }
                        if (goodsVariantItem.goodsBasicsKeyId == 1) goodsVariantItem.goodsElseBasicsValue = item.content.goodsNumber.trim()
                        if (goodsVariantItem.goodsBasicsKeyId == 109) goodsVariantItem.goodsElseBasicsValue = item.content.goodsNumberType
                        if (goodsVariantItem.goodsBasicsKeyId == 31) goodsVariantItem.goodsElseBasicsValue = item.content.goodsCondition
                        if (goodsVariantItem.goodsBasicsKeyId == 26) goodsVariantItem.goodsElseBasicsValue = item.content.goodsMoney
                        if (goodsVariantItem.goodsBasicsKeyId == 27) goodsVariantItem.goodsElseBasicsValue = item.content.goodsInventory
                        if (goodsVariantItem.goodsBasicsKeyId == 24) goodsVariantItem.goodsElseBasicsValue = item.content.goodsSku
                    }
                    console.log(JSON.stringify(goodsVariantElseBasicsInfoReqList), "goodsVariantElseBasicsInfoReqList-----------------")
                    console.log(JSON.stringify(goodsVariantElseBasicsInfoReqList).indexOf('"goodsBasicsKeyId":1'), "---------------------")
                    if (JSON.stringify(goodsVariantElseBasicsInfoReqList).indexOf('"goodsBasicsKeyId":24,') == -1) {
                        goodsVariantElseBasicsInfoReqList.push({
                            goodsBasicsKeyId: 24,
                            goodsElseBasicsValue: item.content.goodsSku
                        })
                    }
                    if (JSON.stringify(goodsVariantElseBasicsInfoReqList).indexOf('"goodsBasicsKeyId":109,') == -1) {
                        goodsVariantElseBasicsInfoReqList.push({
                            goodsBasicsKeyId: 109,
                            goodsElseBasicsValue: item.content.goodsNumberType
                        })
                    }
                    if (JSON.stringify(goodsVariantElseBasicsInfoReqList).indexOf('"goodsBasicsKeyId":1,') == -1) {
                        goodsVariantElseBasicsInfoReqList.push({
                            goodsBasicsKeyId: 1,
                            goodsElseBasicsValue: item.content.goodsNumber.trim()
                        })
                    }
                    if (JSON.stringify(goodsVariantElseBasicsInfoReqList).indexOf('"goodsBasicsKeyId":31,') == -1) {
                        goodsVariantElseBasicsInfoReqList.push({
                            goodsBasicsKeyId: 31,
                            goodsElseBasicsValue: item.content.goodsCondition
                        })
                    }
                    if (JSON.stringify(goodsVariantElseBasicsInfoReqList).indexOf('"goodsBasicsKeyId":26,') == -1) {
                        goodsVariantElseBasicsInfoReqList.push({
                            goodsBasicsKeyId: 26,
                            goodsElseBasicsValue: item.content.goodsMoney
                        })
                    }
                    if (JSON.stringify(goodsVariantElseBasicsInfoReqList).indexOf('"goodsBasicsKeyId":27,') == -1) {
                        goodsVariantElseBasicsInfoReqList.push({
                            goodsBasicsKeyId: 27,
                            goodsElseBasicsValue: item.content.goodsInventory
                        })
                    }
                    addGoodsReqList.push({
                        "classifyId": this.props.classifyId || '',  //分类id
                        "goodsBrand": '',  //商品品牌
                        "goodsCondition": item.content.goodsCondition || '',  //商品状况
                        "goodsId": item.goodsId,
                        "goodsDispatchingType": 1,
                        "goodsElseBasicsInfoReqList": goodsVariantElseBasicsInfoReqList || '',  //其他属性
                        "goodsVariantReqList": goodsVariantReqList,  //变体属性
                        "goodsInfoStatus": 1,  //商品信息状态 0-信息已完善 1-信息未完善 ,
                        "goodsInventory": item.content.goodsInventory || '',  //商品库存
                        "goodsIsMaster": false,  // 商品是否是主干父体 0-不是 1-是 ,
                        "goodsIsVariant": false,  //商品是否是变体商品 0-不是 1-是 ,
                        "goodsMoney": item.content.goodsMoney || '',  //商品价格
                        "goodsName": '',  //商品名称
                        "goodsNumber": item.content.goodsNumber.trim() || '',  //商品编码
                        "goodsNumberType": item.content.goodsNumberType,  //商品编码类型id
                        "goodsSku": item.content.goodsSku || '',  //卖家sku
                        "imgUrl": []
                    })
                }
            }
        }
        console.log(addGoodsReqList, "addGoodsReqList----------------")
        AJAX.addProduct.updateGoodsElseExtendsValue({
            elseExtendsInfoReqList: arr,
            goodsIdList: goodsIdList,
            addGoodsReqList: addGoodsReqList,
            goodsId: this.props.goodsId
        }).then((res) => {
            message.success(res.msg);
            if (res.code == 1) {
                this.setState({ isClick: false })
                setTimeout(() => {
                    this.props.props.history.push('/inventory')
                }, 1000)
            }
        }).catch((err) => {
            this.setState({ isClick: false })
        })
    }
    addProducts = () => {

        let { addInfo } = this.state
        if (this.props.goodsId) {
            this.updateGoodsElseExtendsValue()
        } else {
            if (addInfo[1] && addInfo[3]) {
                this.addGoodsInfo()
            } else if (addInfo[1] && addInfo[2] && JSON.stringify(addInfo[2]) != '[]') {
                this.addGoodsInfo()

            }
        }
    }
    Switchse = (e) => {
        this.setState({
            isSenior: e
        })
    }
    isVariantClick = (banner) => {
        let { addInfo } = this.state
        if (!banner) {
            if (addInfo[1] && addInfo[3]) {
                this.setState({
                    disabledBtn: true
                })
            }
        } else if (!addInfo[2]) {
            this.setState({
                disabledBtn: true
            })
        } else if (addInfo[2].length > 0 && addInfo[1] && addInfo[3]) {
            this.setState({
                disabledBtn: true
            })
        }
        this.setState({
            isVariant: banner,
            // disabledBtn: banner ? false : true
        })
    }
    receiveData = (data, type) => {
        let { addInfo, isVariant } = this.state;
        let { tabList, tabList2 } = this.state.data
        // if(tabList[Number(type)-1].iconfont){}
        if (type == 1 || type == 3) {
            tabList[Number(type) - 1].iconfont = false;
            tabList2[Number(type) - 1].iconfont = false;
        }

        addInfo[type] = data;
        if (isVariant && addInfo[2].length > 0 && addInfo[1]) {
            this.setState({
                disabledBtn: false
            })
        } else if (addInfo[1] && addInfo[3] && type != 2) {
            if (addInfo[2] && JSON.stringify(addInfo[2]) != '[]') {
                this.setState({
                    disabledBtn: false
                })
            } else {
                this.setState({
                    disabledBtn: false
                })
            }
        } else if (addInfo[1] && addInfo[3] && addInfo[2].length > 0) {
            this.setState({
                disabledBtn: false
            })
        } else {
            this.setState({
                disabledBtn: true
            })
        }
        console.log(addInfo, "addInfo")
        this.setState({
            addInfo: addInfo,
            tabList: tabList,
            tabList2: tabList2
        })
    }
    upDelGoodsList = (goodsIdList) => {
        this.setState({
            goodsIdList: goodsIdList
        })
    }
    render() {
        let { data, isSenior, isSubmit, isDisplayCode, disabledBtn } = this.state
        return <div className="tabs-box">
            <div className="nav-box tab-logo-box">
                <Link to='/'>
                    <div className="home-logo">
                    </div>
                    <div className="logo-char">seller central</div>
                </Link>
            </div>
            <div className="tabs-box-change">
                <Tabs defaultActiveKey='1' centered tabBarGutter={10} tabBarStyle={{ color: "#002F36" }}>
                    {!isSenior ? data.tabList.map((item, index) => {
                        return (
                            <TabPane className="tabs-title" tab={
                                <span style={{ display: "flex", alignItems: "center" }}>
                                    {item.iconfont ? <svg t="1624519421147" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2421" width="24" height="24"><path d="M510.976694 146.304134c-201.970968 0-365.695866 163.728992-365.695866 365.695866s163.728992 365.695866 365.695866 365.695866 365.695866-163.728992 365.695866-365.695866S712.947661 146.304134 510.976694 146.304134L510.976694 146.304134zM480.489332 329.151555c0-16.82827 13.631462-30.475082 30.475082-30.475082 16.844643 0 30.472012 13.646811 30.472012 30.475082l0 216.70146c0 16.82827-13.627369 30.475082-30.472012 30.475082-16.84362 0-30.475082-13.646811-30.475082-30.475082L480.489332 329.151555 480.489332 329.151555zM510.976694 694.847421c-23.663956 0-42.846854-19.178805-42.846854-42.842761s19.182898-42.846854 42.846854-42.846854c23.663956 0 42.846854 19.182898 42.846854 42.846854C553.823548 675.664523 534.64065 694.847421 510.976694 694.847421L510.976694 694.847421zM510.976694 694.847421" p-id="2422" fill="#d81e06"></path></svg> : ""}
                                    {item.title}
                                </span>} key={item.id}>
                                {index === 0 ? <Tab1Banner classifyId={this.props.classifyId || null} goodsId={this.props.goodsId} isDisplayCode={isDisplayCode} specialReg={specialReg} zmReg={zmReg} canSubmit={this.canSubmit} isSenior={isSenior} submitData={this.receiveData} isSubmit={isSubmit} Switchse={this.Switchse} id={this.props.id} /> : null}
                                {index === 1 ? <Tab2Banner props={this.props} classifyId={this.props.classifyId || null} goodsId={this.props.goodsId} isVariantClick={this.isVariantClick} displayCode={this.displayCode} specialReg={specialReg} zmReg={zmReg} canSubmit={this.canSubmit} isSenior={isSenior} submitData={this.receiveData} upDelGoodsList={this.upDelGoodsList} isSubmit={isSubmit} Switchse={this.Switchse} id={this.props.id} /> : null}
                                {index === 2 ? <Tab3Banner classifyId={this.props.classifyId || null} goodsId={this.props.goodsId} isDisplayCode={isDisplayCode} specialReg={specialReg} zmReg={zmReg} canSubmit={this.canSubmit} isSenior={isSenior} submitData={this.receiveData} isSubmit={isSubmit} Switchse={this.Switchse} id={this.props.id} /> : null}
                                {index === 3 ? <Tab5Banner classifyId={this.props.classifyId || null} goodsId={this.props.goodsId} specialReg={specialReg} zmReg={zmReg} canSubmit={this.canSubmit} isSenior={isSenior} submitData={this.receiveData} isSubmit={isSubmit} Switchse={this.Switchse} id={this.props.id} /> : null}
                            </TabPane>
                        )
                    }) : data.tabList2.map((item, index) => {
                        return (
                            <TabPane className="tabs-title" tab={
                                <span style={{ display: "flex", alignItems: "center" }}>
                                    {item.iconfont ? <svg t="1624519421147" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2421" width="24" height="24"><path d="M510.976694 146.304134c-201.970968 0-365.695866 163.728992-365.695866 365.695866s163.728992 365.695866 365.695866 365.695866 365.695866-163.728992 365.695866-365.695866S712.947661 146.304134 510.976694 146.304134L510.976694 146.304134zM480.489332 329.151555c0-16.82827 13.631462-30.475082 30.475082-30.475082 16.844643 0 30.472012 13.646811 30.472012 30.475082l0 216.70146c0 16.82827-13.627369 30.475082-30.472012 30.475082-16.84362 0-30.475082-13.646811-30.475082-30.475082L480.489332 329.151555 480.489332 329.151555zM510.976694 694.847421c-23.663956 0-42.846854-19.178805-42.846854-42.842761s19.182898-42.846854 42.846854-42.846854c23.663956 0 42.846854 19.182898 42.846854 42.846854C553.823548 675.664523 534.64065 694.847421 510.976694 694.847421L510.976694 694.847421zM510.976694 694.847421" p-id="2422" fill="#d81e06"></path></svg> : ""}
                                    {item.title}
                                </span>} key={item.id}>
                                {index === 0 ? <Tab1Banner classifyId={this.props.classifyId || null} goodsId={this.props.goodsId} isDisplayCode={isDisplayCode} specialReg={specialReg} zmReg={zmReg} canSubmit={this.canSubmit} isSenior={isSenior} submitData={this.receiveData} isSubmit={isSubmit} Switchse={this.Switchse} id={this.props.id} /> : null}
                                {index === 1 ? <Tab2Banner props={this.props} classifyId={this.props.classifyId || null} goodsId={this.props.goodsId} isVariantClick={this.isVariantClick} displayCode={this.displayCode} specialReg={specialReg} zmReg={zmReg} canSubmit={this.canSubmit} isSenior={isSenior} submitData={this.receiveData} upDelGoodsList={this.upDelGoodsList} isSubmit={isSubmit} Switchse={this.Switchse} id={this.props.id} /> : null}
                                {index === 2 ? <Tab3Banner classifyId={this.props.classifyId || null} goodsId={this.props.goodsId} isDisplayCode={isDisplayCode} specialReg={specialReg} zmReg={zmReg} canSubmit={this.canSubmit} isSenior={isSenior} submitData={this.receiveData} isSubmit={isSubmit} Switchse={this.Switchse} id={this.props.id} /> : null}
                                {index === 3 ? <Tab5Banner classifyId={this.props.classifyId || null} goodsId={this.props.goodsId} specialReg={specialReg} zmReg={zmReg} canSubmit={this.canSubmit} isSenior={isSenior} submitData={this.receiveData} isSubmit={isSubmit} Switchse={this.Switchse} id={this.props.id} /> : null}
                                {index === 4 ? <Tab4Banner classifyId={this.props.classifyId || null} goodsId={this.props.goodsId} specialReg={specialReg} zmReg={zmReg} canSubmit={this.canSubmit} isSenior={isSenior} submitData={this.receiveData} isSubmit={isSubmit} Switchse={this.Switchse} id={this.props.id} /> : null}
                                {index === 5 ? <Tab6Banner classifyId={this.props.classifyId || null} goodsId={this.props.goodsId} specialReg={specialReg} zmReg={zmReg} canSubmit={this.canSubmit} isSenior={isSenior} submitData={this.receiveData} isSubmit={isSubmit} Switchse={this.Switchse} id={this.props.id} /> : null}
                                {index === 6 ? <Tab7Banner classifyId={this.props.classifyId || null} goodsId={this.props.goodsId} specialReg={specialReg} zmReg={zmReg} canSubmit={this.canSubmit} isSenior={isSenior} submitData={this.receiveData} isSubmit={isSubmit} Switchse={this.Switchse} id={this.props.id} /> : null}
                                {index === 7 ? <Tab8Banner classifyId={this.props.classifyId || null} goodsId={this.props.goodsId} specialReg={specialReg} zmReg={zmReg} canSubmit={this.canSubmit} isSenior={isSenior} submitData={this.receiveData} isSubmit={isSubmit} Switchse={this.Switchse} id={this.props.id} /> : null}
                            </TabPane>
                        )
                    })
                    }
                </Tabs>
                <div>
                    <div className="tab1-button">
                        <Button>
                            <Link to={this.props.id ? '/commodity' : '/inventory'}>取消</Link>
                        </Button>
                        <Button disabled={disabledBtn} onClick={this.addProducts}>保存并完成</Button>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default TabHeader