import React from 'react'
import './CamipnManage.css'


function CamipnManage(){
    return(
        <div>

        </div>
    )
}
export default CamipnManage