import React from 'react'
import './ManagePricing.css'
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import IconFont from 'components/Icon/Icon'


function ManagePricing(){

    return(
        <div>
            <Header/>

                <div>
                <div className="qd08_content">
			<div className="qd08_tab">
				<ul className="qd08_tab-ul">
					<li>所有库存</li>
					<li>不活动(137)</li>
					<li>修复无在售信息的亚马逊库存(2)</li>
					<li>修复价格警告(1)</li>
					<li>品牌健康度(0)</li>
					<li>禁止显示</li>
					<li>管理定价</li>
					<li>亚马逊库存</li>
					<li>货件处理进度</li>
					<li>库存控制面板</li>
					<li>商品信息质量控制面板 <span>新功能</span></li>
				</ul>
			</div>
			<div className="qd08_title-box">
				<p className="qd08_title-p-l">管理定价<span>了解更多信息</span></p>
				<div className="qd08_title-box-r">
					<button>添加新商品</button>
					<button>首选项: 隐藏 <span>11</span> 列</button>
				</div>
			</div>
			<div className="qd08_inpbox">
				<select>
					<option placeholder="volvo">应用于0 件选定商品</option>
				</select>
				<input type="text" placeholder="搜索 SKU、标题、ISBN"/>
				<button>搜索</button>
				<span>130 件商品</span>
			</div>
			<div className="qd08_screen">
				<p className="qd08_sn-p">筛选条件：</p>
				<div className="qd08_active">
					商品状态:
					<input type="radio" />所有
					<input type="radio" />在售
					<input type="radio" />不可售
					<input type="radio" />不完整
					<input type="radio" />商品信息已删除
				</div>
				<div className="qd08_active">
					配送类型:
					<input type="radio" />所有
					<input type="radio" />亚马逊
					<input type="radio" />卖家
				</div>
				<div className="acse">
					<select>
						<option placeholder="">其他筛选条件</option>
					</select>
				</div>
			</div>
			<div className=" qd08_sn1">
				<ul className="qd08_screen-ul">
					<li>
						<input type="checkbox"/>
					</li>
					<li className="qd08_wfont">图片</li>
					<li className="qd08_wfont">SKU<p className="qd08_gray">状况</p>
					</li>
					<li className="qd08_wfont">商品名称<p className="qd08_gray">ASIN</p>
					</li>
					<li className="qd08_wfont">销量排名</li>
					<li className="qd08_wfont">销售历史记录<p className="qd08_gray">过去30天</p>
					</li>
					<li className="qd08_wfont">每件售出商品的预计费用</li>
					<li className="qd08_wfont">
						<div className="qd08_jz">价格<p className="qd08_gray">+配送费</p>
						</div>
					</li>
					<li className="qd08_wfont">企业商品价格<p className="qd08_gray">+配送费</p>
					</li>
					<li className="qd08_wfont">最低价<p className="qd08_gray">+配送费</p>
					</li>
					<li className="qd08_wfont">购买按钮价格<p className="qd08_gray">+配送费</p>
					</li>
					<li><button className="qd08_qdjg">保存所有</button></li>
				</ul>

			</div>
			<div className=" qd08_sn2">
				<ul className="qd08_screen-ul">
					<li>
						<input type="checkbox"/>
					</li>
					<li className="qd08_tu"><img src={require("../../assets/images/qd08_tu1.png").default}/></li>
					<li>skulvchangqian1029<p className="qd08_gray">新品</p>
					</li>
					<li>Fanta dishwasher<p className="qd08_gray">B08R9HVD1D</p>
					</li>
					<li>-<p className="qd08_gray">-</p>
					</li>
					<li className="qd08_xl"><span className="qd08_blueft">0</span><IconFont style={{paddingTop:"4px"}} type="icon-sanjiaoxing"/> </li>
					<li>--</li>
					<li><input className="qd08_q" type="text" placeholder="100.00" />
						<p className="qd08_hcolor">+--</p>
						<p>标准美国模板-100美金</p>
					</li>
					<li><input className="qd08_q" type="text" placeholder="" />
						<p>+--</p>
						<div className="qd08_xl">
							<span className="qd08_blueft">添加数量折扣</span><IconFont style={{paddingTop:"4px"}} type="icon-sanjiaoxing"/>
						</div>
					</li>
					<li>-<p>+--</p>
					</li>
					<li>-<p>+--</p>
					</li>
					<li className="qd08_sele-li">
						<div className="qd08_sele-l">
							匹配最低价
						</div>
						<select className="qd08_sele-r">
							<option placeholder="重新激活"></option>
						</select>
					</li>
				</ul>
			</div>
			<div className=" qd08_sn2">
				<ul className="qd08_screen-ul">
					<li>
						<input type="checkbox"/>
					</li>
					<li className="qd08_tu"><img src={require("../../assets/images/qd08_tu1.png").default}/></li>
					<li>20201225-001<p className="qd08_gray">新品</p>
					</li>
					<li>MengK Houschold Small Retro Desktop Heater Portable MiniHeater Office Foot Wamer<p className="qd08_gray">B08R9HVD1D</p>
					</li>
					<li>-<p className="qd08_gray">-</p>
					</li>
					<li className="qd08_xl"><span className="qd08_blueft">0</span><IconFont style={{paddingTop:"4px"}} type="icon-sanjiaoxing"/></li>
					<li>--</li>
					<li><input className="qd08_q" type="text" placeholder="18.38" />
						<p className="qd08_hcolor">+--</p>
						<p>标准美国模板-100美金</p>
					</li>
					<li><input className="qd08_q" type="text" placeholder="" />
						<p>+--</p>
						<div className="qd08_xl">
							<span className="qd08_blueft">添加数量折扣</span> <IconFont style={{paddingTop:"4px"}} type="icon-sanjiaoxing"/>
						</div>
					</li>
					<li>-<p>+--</p>
					</li>
					<li>-<p>+--</p>
					</li>
					<li className="qd08_sele-li">
						<div className="qd08_sele-l">
							匹配最低价
						</div>
						<select className="qd08_sele-r">
							<option placeholder="重新激活"></option>
						</select>
					</li>
				</ul>
			</div>
			<div className=" qd08_sn2">
				<ul className="qd08_screen-ul">
					<li>
						<input type="checkbox"/>
					</li>
					<li className="qd08_tu"><img src={require("../../assets/images/qd08_tu1.png").default}/></li>
					<li>makeupbrush<p className="gray">新品</p>
					</li>
					<li>-<p className="qd08_gray">B08QZG57C1</p>
					</li>
					<li>-<p className="qd08_gray">-</p>
					</li>
					<li className="qd08_xl"><span className="qd08_blueft">0</span> <IconFont style={{paddingTop:"4px"}} type="icon-sanjiaoxing"/></li>
					<li>--</li>
					<li><input className="qd08_q" type="text" placeholder="0.00" />
						<p className="qd08_hcolor">+--</p>
						<p>标准美国模板-100美金</p>
					</li>
					<li><input className="qd08_q" type="text" placeholder="" />
						<p>+--</p>
						<div className="qd08_xl">
							<span className="qd08_blueft">添加数量折扣</span> <IconFont style={{paddingTop:"4px"}} type="icon-sanjiaoxing"/>
						</div>
					</li>
					<li>-<p>+--</p>
					</li>
					<li>-<p>+--</p>
					</li>
					<li className="qd08_sele-li">
						<div className="qd08_sele-l">
							匹配最低价
						</div>
						<select className="qd08_sele-r">
							<option placeholder="重新激活"></option>
						</select>
					</li>
				</ul>
			</div>
			<div className=" qd08_sn2">
				<ul className="qd08_screen-ul">
					<li>
						<input type="checkbox"/>
					</li>
					<li className="qd08_tu"><img src={require("../../assets/images/qd08_tu1.png").default}/></li>
					<li>9D-Q9JQ-TJHY<p className="qd08_gray">新品</p>
					</li>
					<li>menqk makeup brush<p className="qd08_gray">B08QRVKPCX</p>
					</li>
					<li>-<p className="qd08_gray">-</p>
					</li>
					<li className="qd08_xl"><span className="qd08_blueft">0</span> <IconFont style={{paddingTop:"4px"}} type="icon-sanjiaoxing"/></li>
					<li>--</li>
					<li><input className="qd08_q" type="text" placeholder="15.00" />
						<p className="qd08_hcolor">+--</p>
						<p>标准美国模板-100美金</p>
					</li>
					<li><input className="qd08_q" type="text" placeholder="" />
						<p>+--</p>
						<div className="qd08_xl">
							<span className="qd08_blueft">添加数量折扣</span><IconFont style={{paddingTop:"4px"}} type="icon-sanjiaoxing"/>
						</div>
					</li>
					<li>-<p>+--</p>
					</li>
					<li>-<p>+--</p>
					</li>
					<li className="qd08_sele-li">
						<div className="qd08_sele-l">
							匹配最低价
						</div>
						<select className="qd08_sele-r">
							<option placeholder="重新激活"></option>
						</select>
					</li>
				</ul>
			</div>
			<div className=" qd08_sn2">
				<ul className="qd08_screen-ul">
					<li>
						<input type="checkbox"/>
					</li>
					<li className="qd08_tu"><img src={require("../../assets/images/qd08_tu1.png").default}/></li>
					<li>POLO11<p className="qd08_gray">新品</p>
					</li>
					<li>Gaden Trelis 4 Pack 4i6"'x 16"Telis fo Climbing Metal with BlackPowderCoated Finish is ustproof for Climbing Cutdoor Plants SuppotTall Wall Metaifrellis for Rose Vine Vegetable Cucumber
						<p className="qd08_gray">B08QGM5HYR</p>
					</li>
					<li>-<p className="qd08_gray">-</p>
					</li>
					<li className="qd08_xl"><span className="qd08_blueft">0</span> <IconFont style={{paddingTop:"4px"}} type="icon-sanjiaoxing"/></li>
					<li>--</li>
					<li><input className="qd08_q" type="text" placeholder="0.00" />
						<p className="qd08_hcolor">+--</p>
						<p>标准美国模板-100美金</p>
					</li>
					<li><input className="qd08_q" type="text" placeholder="" />
						<p>+--</p>
						<div className="qd08_xl">
							<span className="qd08_blueft">添加数量折扣</span><IconFont style={{paddingTop:"4px"}} type="icon-sanjiaoxing"/>
						</div>
					</li>
					<li>-<p>+--</p>
					</li>
					<li>-<p>+--</p>
					</li>
					<li className="qd08_sele-li">
						<div className="qd08_sele-l">
							匹配最低价
						</div>
						<select className="qd08_sele-r">
							<option placeholder="重新激活"></option>
						</select>
					</li>
				</ul>
			</div>
			<div className=" qd08_sn2 qd08_border-l">
				<ul className="qd08_screen-ul ">
					<li>
						<div className="qd08_radiobox">
							<input type="checkbox"/>
							{/* <img className="qd08_radiobox-r" src={require("../../assets/images/qd08_r.png").default} /> */}
                            <IconFont type="icon-sanjiaoxing" style={{transform:"rotate(-90deg)",fontSize:"19px",fontWeight:"bold"}} />
						</div>
					</li>
					<li className="qd08_tu"></li>
					<li>lijiao</li>
					<li>Power Elite Blender<p className="qd08_gray">B08QGN1YJV</p>
					</li>
					<li></li>
					<li className="qd08_xl"></li>
					<li>-</li>
					<li></li>
					<li>
						<div className="qd08_xl">
							<span className="qd08_blueft">添加数量折扣</span> <IconFont style={{paddingTop:"4px"}} type="icon-sanjiaoxing"/>
						</div>
					</li>
					<li></li>
					<li></li>
					<li className="qd08_sele-li">
						<div className="qd08_sele-l">
							匹配最低价
						</div>
						<select className="qd08_sele-r">
							<option placeholder="重新激活"></option>
						</select>
					</li>
				</ul>
			</div>
			<div className=" qd08_sn2">
				<ul className="qd08_screen-ul">
					<li>
						<input type="checkbox"/>
					</li>
					<li className="qd08_tu"><img src={require("../../assets/images/qd08_tu2.png").default}/></li>
					<li>CYJ1213-B<p className="qd08_gray">新品</p>
					</li>
					<li>Manual Fruit Juicer Professionl - JuicerPomegranate Juicer Squeezer - Premtum Quality Heary Duty Manual omearanate lucer and Lime Squeezer Press Stand, pink
						<p className="qd08_gray">B08QD7BCV9</p>
					</li>
					<li>-<p className="qd08_gray">-</p>
					</li>
					<li className="qd08_xl"><span className="qd08_blueft">0</span> <IconFont style={{paddingTop:"4px"}} type="icon-sanjiaoxing"/></li>
					<li>--</li>
					<li><input className="qd08_q" type="text" placeholder="40.00" />
						<p className="qd08_hcolor">+--</p>
						<p>标准美国模板-100美金</p>
					</li>
					<li><input className="qd08_q" type="text" placeholder="" />
						<p>+--</p>
						<div className="qd08_xl">
							<span className="qd08_blueft">添加数量折扣</span><IconFont style={{paddingTop:"4px"}} type="icon-sanjiaoxing"/>
						</div>
					</li>
					<li>-<p>+--</p>
					</li>
					<li>-<p>+--</p>
					</li>
					<li className="qd08_sele-li">
						<div className="qd08_sele-l">
							匹配最低价
						</div>
						<select className="qd08_sele-r">
							<option placeholder="重新激活"></option>
						</select>
					</li>
				</ul>
			</div>
			<div className=" qd08_sn2">
				<ul className="qd08_screen-ul">
					<li>
						<input type="checkbox"/>
					</li>
					<li className="qd08_tu"><img src={require("../../assets/images/qd08_tu2.png").default}/></li>
					<li>CYJ123-A<p className="qd08_gray">新品</p>
					</li>
					<li>Manual Fruit Juicer Professionl - JuicerPomegranate Juicer Squeezer - Premtum Quality Heary Duty Manual omearanate lucer and Lime Squeezer Press Stand, pink
						<p className="qd08_gray">B08QD7BCV9</p>
					</li>
					<li>-<p className="qd08_gray">-</p>
					</li>
					<li className="qd08_xl"><span className="qd08_blueft">0</span> <IconFont style={{paddingTop:"4px"}} type="icon-sanjiaoxing"/></li>
					<li>--</li>
					<li><input className="qd08_q" type="text" placeholder="40.00" />
						<p className="qd08_hcolor">+--</p>
						<p>标准美国模板-100美金</p>
					</li>
					<li><input className="qd08_q" type="text" placeholder="" />
						<p>+--</p>
						<div className="qd08_xl">
							<span className="qd08_blueft">添加数量折扣</span><IconFont style={{paddingTop:"4px"}} type="icon-sanjiaoxing"/>
						</div>
					</li>
					<li>-<p>+--</p>
					</li>
					<li>-<p>+--</p>
					</li>
					<li className="qd08_sele-li">
						<div className="qd08_sele-l">
							匹配最低价
						</div>
						<select className="qd08_sele-r">
							<option placeholder="重新激活"></option>
						</select>
					</li>
				</ul>
			</div>
			<div className=" qd08_sn2">
				<ul className="qd08_screen-ul">
					<li>
						<input type="checkbox"/>
					</li>
					<li className="qd08_tu"><img src={require("../../assets/images/qd08_tu1.png").default}/></li>
					<li>CYJ1213<p className="qd08_gray">新品</p>
					</li>
					<li>Manual Fruit Juicer Professionl - JuicerPomegranate Juicer Squeezer - Premtum Quality Heary Duty Manual omearanate lucer and Lime Squeezer Press Stand, 
						<p className="qd08_gray">B08QGLL5D7</p>
					</li>
					<li>-<p className="qd08_gray">-</p>
					</li>
					<li className="qd08_xl"><span className="qd08_blueft">0</span> <IconFont style={{paddingTop:"4px"}} type="icon-sanjiaoxing"/></li>
					<li>--</li>
					<li><input className="qd08_q" type="text" placeholder="0.00" />
						<p className="qd08_hcolor">+--</p>
						<p>标准美国模板-100美金</p>
					</li>
					<li><input className="qd08_q" type="text" placeholder="" />
						<p>+--</p>
						<div className="qd08_xl">
							<span className="qd08_blueft">添加数量折扣</span> <IconFont style={{paddingTop:"4px"}} type="icon-sanjiaoxing"/>
						</div>
					</li>
					<li>-<p>+--</p>
					</li>
					<li>-<p>+--</p>
					</li>
					<li className="qd08_sele-li">
						<div className="qd08_sele-l">
							匹配最低价
						</div>
						<select className="qd08_sele-r">
							<option placeholder="重新激活"></option>
						</select>
					</li>
				</ul>
			</div>
			<div className=" qd08_sn2 qd08_border-l">
				<ul className="qd08_screen-ul ">
					<li>
						<div className="qd08_radiobox">
							<input type="checkbox"/>
							{/* <img className="qd08_radiobox-r" src="/images/qd08_r.png" /> */}
                            <IconFont type="icon-sanjiaoxing" style={{transform:"rotate(-90deg)",fontSize:"19px",fontWeight:"bold"}} />

						</div>
					</li>
					<li className="qd08_tu"></li>
					<li>lijiao</li>
					<li>Power Elite Blender<p className="qd08_gray">B08QGN1YJV</p>
					</li>
					<li></li>
					<li className="qd08_xl"></li>
					<li>-</li>
					<li></li>
					<li>
						<div className="qd08_xl">
							<span className="qd08_blueft">添加数量折扣</span> <IconFont style={{paddingTop:"4px"}} type="icon-sanjiaoxing"/>
						</div>
					</li>
					<li></li>
					<li></li>
					<li className="qd08_sele-li">
						<div className="qd08_sele-l">
							匹配最低价
						</div>
						<select className="qd08_sele-r">
							<option placeholder="重新激活"></option>
						</select>
					</li>
				</ul>
			</div>
			<div className="qd08_pagination">
				<span>← 上一页</span>
				<button>1</button>
				<button>2</button>
				<button>3</button>
				<button>4</button>
				<span>...</span>
				<button>13</button>
				<button>下一页 →</button>
				<div className="qd08_page-r">
					<div className="qd08_sele2">
						每页搜索10个结果
					</div>
					<select>
						<option></option>
					</select>
				</div>
			</div>
		</div>


                </div>





            <Footer/>


        </div>
    )
}


export default ManagePricing