import { DatePicker as AntDatePicker } from "antd";
import { forwardRef } from "react";
import withProps from "./../withProps";

const DatePicker = forwardRef((props, ref) => {
  return <AntDatePicker {...props} ref={ref} />;
});

export default withProps(DatePicker, {
  className: "a-date-picker",
});
