import withProps from "@a-components/withProps";
import { Slider as AntSlider } from "antd";

const Slider = withProps(
  (props) => {
    return <AntSlider {...props} />;
  },
  {
    className: "a-slider",
  }
);

export default Slider;
