export default {
    'header_child_Addlisting':'添加商品',  //添加商品
    'header_child_DraftIncomplete':'补全您的草稿',//补全您的草稿
    'header_child_Sales':'销售申请',//销售申请
    'header_child_Inventory':'管理库存',//管理库存
    'header_child_Repertory':'管理亚马逊库存',//管理亚马逊库存
    'header_child_Improve':'改进商品信息质量',//改进商品信息质量
    'header_child_Planning':'库存规划',//库存规划
    'header_child_Logistics':'亚马逊物流远程配送',//亚马逊物流配送
    'header_child_Shopup':'批量上传商品',//批量上传商品
    'header_child_Report':'库存报告',//库存报告
    'header_child_GlobalSales':'全球销售',//全球销售
    'header_child_InboundQueue':'管理亚马逊货件',//管理亚马逊货件
    'header_child_Imageup':'上传图片 ',//上传图片
    'header_child_AdminVideo':'上传和管理视频',//上传和管理视频
    'header_child_PricingStatus':' 定价状况',//定价状况
    'header_child_PricingManagement':'管理定价',//管理定价
    'header_child_RepairPriceReport':'修复价格警告',//修复价格警告
    'header_child_AutomaticPricing':'自动定价',//自动定价
    'header_child_CommissionDiscount':'佣金折扣',//佣金折扣
    'header_child_AgreementPricing':'协议定价',//协议定价
    'header_child_ManageOrders':'管理订单',//管理订单
    'header_child_OrderReport':'订单报告',//订单报告
    'header_child_UploadOrder':'上传订单相关文件',//上传订单相关文件
    'header_child_ManageReturns':'管理退货',//管理退货
    'header_child_ManageSAFE-T':'管理SAFE-T索赔',//管理SAFE-T索赔
    'header_child_Advertising':'广告活动管理',//广告活动管理
    'header_child_A+page':'A+页面',//A+页面
    'header_child_reviewerProgram':'早期评论者计划',//早期评论者计划
    'header_child_Vine':'Vine',//Vine
    'header_child_Deals':'秒杀',//秒杀
    'header_child_Coupons':'优惠卷',//优惠卷
    'header_child_Discounts':'Prime专享折扣',//Prime专享折扣
    'header_child_Promotions':'管理促销',//管理促销
    'header_child_ManageStores':'管理店铺',//管理店铺
    'header_child_distribution':'配送计划',//配送计划
    'header_child_Recommended':'推荐计划',//推荐计划
    'header_child_Custom':'制定计划',//制定计划
    'header_child_Lending':'借贷',//借贷
    'header_child_Payments':'付款',//付款
    'header_child_SellingCoach':'亚马逊销售指导',//亚马逊销售指导
    'header_child_BusinessReports':'业务报告',//业务报告
    'header_child_Fulfillment':'库存和销售报告',//库存和销售报告
    'header_child_advertising':'广告',//广告
    'header_child_ReturnReport':'退货报告',//退货报告
    'header_child_CustomReport':'自定义报告',//自定义报告
    'header_child_TaxDocument':'税务文件库',//税务文件库
    'header_child_Account':'账户状况',//账户状况
    'header_child_Feedback':'反馈',//反馈
    'header_child_GuaranteeClaims':'亚马逊商城交易保障索赔',//亚马逊商城交易保障索赔
    'header_child_ChargebackClaims':'信用卡拒付索赔'//信用卡拒付索赔
};