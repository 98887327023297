import React from 'react';
import './index.css'
import { Link } from 'react-router-dom';

export default class tipsIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbData: [],
      data: {
        list: []
      }
    }
  }
  componentWillMount() { }
  componentWillReceiveProps(newProps) {
    //监听参数变化
  }
  render() {
    return <div style={this.props.zIndex && { zIndex: this.props.zIndex }} onClick={() => { this.props.clicks(true) }} className="tipsIcon">
      <img src={require("@/assets/img/public/tipsIcon.png").default} />
    </div>
  }
}