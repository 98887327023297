import React from 'react'
import AJAX from "api/apis";
import './shopupDL.css'
import { getSellerNationality, timeDifference } from '../../../../utils/regDate';
import { Button, Radio, Input, Modal, Form, Select } from 'antd';
import { Link } from 'react-router-dom';
import Reactours from "components/reactours";
import TipsIcon from "components/tipsIcon";
import { buildRouteSearch } from "@/utils/getUrlData";

class shopupDL extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    this.state = {
      storeAreaList: [],
      defaultData: {
        standard: '',
        standard1: '',
        fastDelivery: '',
        fastDelivery1: '',
        twoDaysService: '',
        oneDayDelivery: '',
        internationalDistribution: '',
        internationalExpress: '',
        fullSite: ''
      },
      defaultFreight: {},
      isModalVisible: false,
      addressIsModalVisible: false,
      isEdit: false,
      isEditLoading: true,
      isFreightEdit: false,
      id: null,
      timezone: '(UCT-11:00)太平洋/中途岛',
      isActive: false,
      isTourOpen: true,
      steps1: [
        {
          selector: '.ant-tabs-tab-active',
          content: '点击“一键配送设置”设置默认地址',
        },
        {
          selector: '.a-section-expander-container .songdizhi',
          content: '点击编辑',
        },
        {
          selector: '.a-section-expander-container .yunfeieidt',
          content: '点击编辑设置默认运费',
        },
      ],
      steps: [
        {
          selector: '.ant-tabs-tab-active',
          content: '点击“一键配送设置”设置默认地址',
        },
        {
          selector: '.a-section-expander-container .songdizhi',
          content: '点击编辑',
        },
        {
          selector: '.a-section-expander-container .yunfeieidt',
          content: '点击编辑设置默认运费',
        },
      ],
      routePath: "distributionSet_shopupDL",
    }
  }
  componentWillMount() {
    this.getDistributionSiteStoreArea()
    this.getDistributionFreight()
  }
  awakenTips = (type) => {
    this.setState({ isActive: type })
  }
  getDistributionSiteStoreArea = () => {
    AJAX.distribution.getDistributionSiteStoreArea().then((res) => {
      if (res.code == 1 && res.data?.length > 0) {
        this.setState({
          defaultData: res.data[0]
        })
      }
    })
  }
  getDistributionFreight = () => {
    let { defaultFreight } = this.state
    AJAX.distribution.getDistributionFreight().then((res) => {
      this.setState({
        isFreightEdit: res.data?.length > 0 ? true : false
      })
      if (res.code == 1 && res.data?.length > 0) {
        this.setState({
          defaultFreight: res.data[0]
        })
      }
    })
  }
  getDistributionSiteStoreAreaList = async () => {
    let { id } = this.state;
    await AJAX.distribution.getDistributionSiteStoreAreaList().then((res) => {
      if (res.code === 1 && res.data) {
        for (let items of res.data) {
          if (!items.status) id = items.id
        }
        this.setState({
          storeAreaList: res.data,
          id: id,
        })
      }
      // setTimeout(() => {
      //   let stepList = JSON.parse(localStorage.getItem('stepList'))
      //   this.setState({
      //     isActive: stepList.distributionSet_shopupDL1 ? false : true,
      //     routePath: "distributionSet_shopupDL1",
      //     steps1: [
      //       {
      //         selector: '.ant-tabs-tab-active',
      //         content: '点击“一键配送设置”设置默认地址',
      //       },
      //       {
      //         selector: '.editressModalBox .addressBtn',
      //         content: '点击新增地址',
      //       },
      //       {
      //         selector: '.editressModalBox .ant-btn-primary',
      //         content: '点击保存',
      //       },
      //     ],
      //   })
      //   if (!stepList['distributionSet_shopupDL1']) {
      //     stepList["distributionSet_shopupDL1"] = true
      //     localStorage.setItem('stepList', JSON.stringify(stepList));
      //   }
      // }, 100)

    })
  }

  isModalVisibleShow = () => {
    // console.log(this.state)
    this.setState({
      isModalVisible: true,
      isTourOpen: false
    })
    this.getDistributionSiteStoreAreaList()
    this.state.isActive && this.refs.shopupDLReactours.requestClose()
  }
  addAddress = (type, item = null) => {
    this.setState({
      isEdit: type,
      isModalVisible: false,
      addressIsModalVisible: true,
      isTourOpen: true,
      steps1: [
        {
          selector: '.addressModalBox .ant-form-item:nth-child(1)',
          content: '填写地址名称，使用对应国家的语言填写',
        },
        {
          selector: '.addressModalBox .ant-form-item:nth-child(2)',
          content: '选择国家/地区',
        },
        {
          selector: '.addressModalBox .ant-form-item:nth-child(3)',
          content: '填写详细地址',
        },
        {
          selector: '.addressModalBox .ant-form-item:nth-child(5)',
          content: '填写邮政编码',
        },
        {
          selector: '.addressModalBox .addressModalBox_flex1 .ant-form-item:nth-child(1)',
          content: '填写城市/镇',
        },
        {
          selector: '.addressModalBox .addressModalBox_flex1 .ant-form-item:nth-child(2)',
          content: '填写州、省、直辖市或者自治区',
        },
        {
          selector: '.addressModalBox .addressModalBox_flex2 .ant-form-item:nth-child(1)',
          content: '填写联系人电话联系方式',
        },
        {
          selector: '.addressModalBox .addressModalBox_flex2 .ant-form-item:nth-child(2)',
          content: '填写联系人电子邮件地址（选填）',
        },
        {
          selector: '.addressModalBox .ant-btn-primary',
          content: '填写完毕之后点击保存',
        },
      ],
    })
    let stepList = JSON.parse(localStorage.getItem('stepList'))
    setTimeout(() => {
      this.setState({
        isActive: stepList.distributionSet_shopupDL2 ? false : true,
        routePath: "distributionSet_shopupDL2"
      })
      if (!stepList['distributionSet_shopupDL2']) {
        stepList["distributionSet_shopupDL2"] = true
        localStorage.setItem('stepList', JSON.stringify(stepList));
      }
    }, 500)
    if (item) {
      this.setState({
        id: item.id
      })
      setTimeout(() => {
        this.formRef.current.setFieldsValue({
          addressName: item.siteName,
          province: item.fullSite.split("------")[0],
          city: item.fullSite.split("------")[1],
          address: item.fullSite.split("------")[2],
          address1: item.fullSite.split("------")[3],
          Postcode: item.fullSite.split("------")[4],
          diqu: item.fullSite.split("------")[5],
          phone: item.phone,
          Email: item.email_site
        })
      }, 100)
    }
  }
  // 编辑默认地址操作按钮
  handleOk = () => {
    let { id, timezone, isEditLoading } = this.state
    if (!id) {
      this.setState({
        isModalVisible: false
      })
      return
    }
    if (!isEditLoading) return
    this.setState({ isEditLoading: false })
    AJAX.distribution.upDistributionSiteStoreArea({
      id,
      timezone,
      status: false
    }).then((data) => {
      if (data.code == 1) {
        this.getDistributionSiteStoreArea()
        this.setState({
          isModalVisible: false,
          steps1: this.state.steps,
          isEditLoading: true
        })
      }
    })
  }
  handleCancel = () => {
    this.setState({
      isModalVisible: false,
      steps1: this.state.steps,
    })
  }
  // 新增/编辑地址操作按钮
  handleAddressOk = () => {
    let { isEdit, id, isEditLoading } = this.state;
    if (!isEditLoading) return
    this.formRef.current.validateFields().then((res) => {
      let address1 = ""
      let email_site = ""
      if (res.address1 && res.address1 != undefined) {
        address1 = res.address1
      }
      if (res.Email && res.Email != undefined) {
        email_site = res.Email
      }
      // 如果isEdit为true就是修改，否则就是添加
      if (isEdit) {
        this.setState({ isEditLoading: false })
        AJAX.distribution.upDistributionSiteStoreArea({
          id,
          fullSite: res.province + "------" + res.city + "------" + res.address + "------" + address1 + "------" + res.Postcode + "------" + res.diqu,
          siteName: res.addressName,
          phone: res.phone,
          email_site: email_site
        }).then((data) => {
          if (data.code == 1) {
            this.setState({
              addressIsModalVisible: false,
              steps1: this.state.steps,
              isEditLoading: true
            })
          }
        })
      } else {
        this.setState({ isEditLoading: false })
        AJAX.distribution.addDistributionSiteStoreArea({
          fullSite: res.province + "------" + res.city + "------" + res.address + "------" + address1 + "------" + res.Postcode + "------" + res.diqu,
          siteName: res.addressName,
          phone: res.phone,
          email_site: email_site
        }).then((data) => {
          if (data.code == 1) {
            this.setState({
              addressIsModalVisible: false,
              steps1: this.state.steps,
              isEditLoading: true
            })
          }
        })
      }
    })
  }
  // 删除地址api
  delAddress = (item) => {
    let { storeAreaList } = this.state
    AJAX.distribution.deleteDistributionSiteStoreArea(item.id).then((res) => {
      if (res.code == 1) {
        for (let index in storeAreaList) {
          if (storeAreaList[index].id == item.id) storeAreaList.splice(index, 1);
        }
        this.setState({
          storeAreaList: storeAreaList
        })
      }
    })
  }
  handleAddressCancel = () => {
    this.setState({
      addressIsModalVisible: false,
      steps1: this.state.steps,
    })
  }
  defaultAddressSwiht = (e) => {
    this.setState({
      id: e.target.value
    })
  }
  selectDiqu = (e) => {
    this.setState({
      timezone: e || '(UCT-11:00)太平洋/中途岛'
    })
  }
  render() {
    let { isModalVisible, addressIsModalVisible, storeAreaList, id, defaultData, defaultFreight, isFreightEdit, isActive, steps1, steps, isTourOpen, routePath, startAt } = this.state;
    let distributionFreightJson = JSON.stringify(defaultFreight) != "{}" ? JSON.parse(defaultFreight.distributionFreightJson) : {}
    return <div className="oneDelivery">
      <div>
        <div className="end05_myBody">
          <div>
            <div className="a-section-expander-container">
              <span className="leftTitle">默认配送地址</span>
              <span onClick={this.isModalVisibleShow} className="rightBtn songdizhi">编辑</span>
            </div>
            <div className="tableBox">
              <table border="1" cellspacing="0" cellpadding="0" bordercolor="#EEEEEE">
                <tr className="coupons1">
                  <td>地址名称</td>
                  <td colSpan="3">{defaultData.siteName}</td>
                </tr>
                <tr className="coupons1">
                  <td>完整地址</td>
                  <td colSpan="3">{defaultData.fullSite.replace(/------/g, ",")}</td>
                </tr>
                <tr className="coupons1">
                  <td>时区</td>
                  <td colSpan="3">{defaultData.timezone}</td>
                </tr>
              </table>
            </div>

            <div className="a-section-expander-container">
              <span className="leftTitle">默认运费</span>
              {isFreightEdit ? <Link to={{ pathname: "/freightEdit", search: buildRouteSearch({ isFreightEdit: isFreightEdit, id: defaultFreight.id, distributionFreightJson: distributionFreightJson }) }} className="rightBtn yunfeieidt">编辑</Link> :
                <Link to={{ pathname: "/freightEdit", search: buildRouteSearch({ isFreightEdit: isFreightEdit }) }} className="rightBtn yunfeieidt">添加</Link>
              }
            </div>
            <div className="tableBox">
              <table border="1" cellspacing="0" cellpadding="0" bordercolor="#EEEEEE">
                {distributionFreightJson.standard || distributionFreightJson.standard1 ? <tr className="coupons1">
                  <td>标准</td>
                  <td colSpan="3">
                    <p>{distributionFreightJson.standard}</p>
                    <p>{distributionFreightJson.standard1}</p>
                  </td>
                </tr> : null}
                {distributionFreightJson.fastDelivery || distributionFreightJson.fastDelivery1 ? <tr className="coupons1">
                  <td>快速配送</td>
                  <td colSpan="3">
                    <p>{distributionFreightJson.fastDelivery}</p>
                    <p>{distributionFreightJson.fastDelivery1}</p>
                  </td>
                </tr> : null}
                {distributionFreightJson.twoDaysService ? <tr className="coupons1">
                  <td>
                    <p>两日送达（了解更多信息）</p>
                    <p>1天处理时间 +2天运输时间</p>
                  </td>
                  <td colSpan="3">{distributionFreightJson.twoDaysService}</td>
                </tr> : null}
                {distributionFreightJson.oneDayDelivery ? <tr className="coupons1">
                  <td>一日送达（已禁用）</td>
                  <td colSpan="3">{distributionFreightJson.oneDayDelivery}</td>
                </tr> : null}
                {distributionFreightJson.internationalDistribution ? <tr className="coupons1">
                  <td>国际配送</td>
                  <td colSpan="3">{distributionFreightJson.internationalDistribution}</td>
                </tr> : null}
                {distributionFreightJson.internationalExpress ? <tr className="coupons1">
                  <td>国际加急</td>
                  <td colSpan="3">{distributionFreightJson.internationalExpress}</td>
                </tr> : null}
              </table>
            </div>


            {/* <div className="a-section-expander-container">
              <span className="leftTitle">订单配送设置</span>
              <span className="rightBtn">编辑</span>
            </div>
            <div className="tableBox">
              <table border="1" cellspacing="0" cellpadding="0" bordercolor="#EEEEEE">
                <tr className="coupons1" rowSpan="3">
                  <td>General Fulfillment Settings</td>
                  <td colspan="3" className="bolds">当日达</td>
                  <td colspan="2">订单截止时间:10:00AM</td>
                </tr>
                <tr className="coupons1">
                  <td></td>
                  <td colspan="3" className="bolds">一日、两日、三日、四日、标准、加急和安排配送</td>
                  <td colspan="2">订单截止时间:10:00AM</td>
                </tr>
                <tr className="coupons1">
                  <td></td>
                  <td colspan="5">
                    <div>
                      <p className="bolds">营业日</p>
                      <p>星期一,星期二,星期三，星期四，星期五</p>
                      <p className="bolds">Weekend Delivery Settings</p>
                      <p>Monday through Saturday will be considered as a delivery day for Seller Fulfilled</p>
                      <p>Prime orders using Premium Shipping Options</p>
                    </div>
                  </td>
                </tr>
                <tr className="coupons1">
                  <td>
                    <div>
                      <p>Fulfillment Settings for Shipping Region</p>
                      <p>Automation</p>
                    </div>
                  </td>
                  <td colSpan="5">
                    <div>
                      <p className="bolds">新功能:配送设置自动化</p>
                      <ul>
                        <li>利用自动化功能，消除设置配送区域时的猜测。</li>
                        <li>这让您能够配置营业日和一周中每天的截止时间，并更好地控制您的订单配送流程。</li>
                        <li>了解有关启用<span className="col296">配送设置自动化</span>的更多信息。</li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </table>
            </div> */}
            <div className="a-section-expander-container">
              <span className="leftTitle">备货时间</span>
            </div>
            <div className="tableBox">
              <table border="1" cellspacing="0" cellpadding="0" bordercolor="#EEEEEE">
                <tr className="coupons1">
                  <td>默认备货时间</td>
                  <td colSpan="3">1 - 2 天</td>
                  <td className="col296">什么是默认备货时间？</td>

                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal title="编辑默认配送地址" className="editressModalBox" closable={false} maskClosable={false} visible={isModalVisible} cancelText="取消" okText="保存" onOk={this.handleOk} onCancel={this.handleCancel}>
        <div>
          <div className="modalLeft">
            <p>选择默认配送地址</p>
            <Radio.Group onChange={this.onChange} value={id}>
              {storeAreaList ? storeAreaList.map((item, index) => {
                return <Radio checked key={index} value={item.id} onChange={this.defaultAddressSwiht}>
                  <>
                    <span className="bolds">{item.siteName}</span>
                    <span>{item.fullSite.replace(/------/g, ",")}</span>
                    <Button onClick={() => { this.addAddress(true, item) }} type="link">编辑</Button>
                    {id == item.id ? null : <Button onClick={() => { this.delAddress(item) }} type="link">删除</Button>}
                  </>
                </Radio>
              }) : null}
            </Radio.Group>
            <div className="addressBtn color06" onClick={() => { this.addAddress(false) }}>+新增地址</div>
            <p className="select">选择正确的时区作为您的默认配送地址</p>
            <div>
              <Select defaultValue="(UCT-11:00)太平洋/中途岛" style={{ width: 250 }} options={timeDifference()} onChange={this.selectDiqu} >
              </Select>
            </div>
          </div>
          <div className="modalRight"></div>
        </div>

      </Modal>
      <Modal title="添加新的送货地址" closable={false} destroyOnClose={true} maskClosable={false} className="addressModalBox" cancelText="取消" okText="保存" visible={addressIsModalVisible} onOk={this.handleAddressOk} onCancel={this.handleAddressCancel}>
        <div>
          <Form ref={this.formRef}>
            <Form.Item
              name="addressName"
              label="地址名称"
              rules={[{ required: true, message: '请输入地址名称' }]}
            >
              <Input maxLength={100} placeholder="例如，西雅图仓库" />
            </Form.Item>
            <Form.Item
              name="diqu"
              label="国家/地区"
              rules={[{ required: true, message: '请选择国家/地区' }]}
            >
              <Select options={getSellerNationality()} />
            </Form.Item>
            <Form.Item
              name="address"
              label="地址"
              rules={[{ required: true, message: '请输入地址' }]}
            >
              <Input maxLength={100} placeholder="地址1" />
            </Form.Item>
            <Form.Item
              name="address1"
            >
              <Input maxLength={100} placeholder="地址2" />
            </Form.Item>
            <Form.Item
              name="Postcode"
              className="Postcode"
              label="邮政编码"
              rules={[{
                required: true,
                validator: (rule, value, fun) => {
                  let reg = /^[1-9][0-9]{5}$/;
                  if (!value) {
                    fun('请输入邮政编码')
                  } else if (!reg.test(value)) {
                    fun('邮政编码验证失败')
                  }
                  fun()
                }
              }]}
            >
              <Input maxLength="6" />
            </Form.Item>
            {/* <span>检查邮政编码</span> */}
            <div className="flex1 addressModalBox_flex1">
              <Form.Item
                name="city"
                label="市/镇"
                rules={[{ required: true, message: '请输入市/镇' }]}
              >
                <Input maxLength={100} />
              </Form.Item>
              <Form.Item
                name="province"
                label="州、省、直辖市或自治区"
                rules={[{ required: true, message: '请输入州、省、直辖市或自治区' }]}
              >
                <Input maxLength={100} />
              </Form.Item>
            </div>
            <div className="flex1 addressModalBox_flex2">
              <Form.Item
                name="phone"
                label="主要电话"
                rules={[{
                  required: true,
                  validator: (rule, value, fun) => {
                    let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                    if (!value) {
                      fun('请输入主要电话')
                    } else if (!reg.test(value)) {
                      fun('主要电话格式验证失败')
                    }
                    fun()
                  }
                }]}
              >
                <Input maxLength="11" />
              </Form.Item>
              <Form.Item
                name="Email"
                label="电子邮件地址"
                rules={[{
                  required: false, validator: (rule, value, cbfn) => {
                    let reg = /^([a-zA-Z]|[0-9]|[_-])+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
                    if (value && !reg.test(value)) {
                      cbfn('邮箱格式不正确')
                    } cbfn()
                  }
                }]}
              >
                <Input maxLength={30} />
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>
      <TipsIcon zIndex='9999' clicks={this.awakenTips} />
      <Reactours ref="shopupDLReactours" routePath={routePath} isActive={isActive} clicks={this.awakenTips} steps={steps1} isOpen={isTourOpen} />
    </div>
  }
}
export default shopupDL;
