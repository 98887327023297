import Ajax from "./request";
//   请求类型后面有1的为需要弹出打分弹框的接口判断，务删除
const Apis = {
  login: {
    login(params) {
      return Ajax.ajaxs(`/user/login`, params);
    },
    loginToken(params) {
      //授权令牌登录
      return Ajax.ajaxs(`/user/loginToken`, params, "GET");
    },
    getUserInfo() {
      //获取用户信息
      return Ajax.ajaxs(`/user/getUserInfo`, {}, "GET");
    },
    getPcAmazonEmail() {
      return Ajax.ajaxs(`/user/getPcAmazonEmail`, {}, "GET");
    },
    getLoginInfo() {
      return Ajax.ajaxs(`/getLoginInfo`, {}, "GET");
    },
    register(params) {
      return Ajax.ajaxs(`/user/register`, params);
    },
    verifyEmail(params) {
      return Ajax.ajaxs(`/user/verifyEmail`, params, "GET");
    },
    activateUser(params) {
      return Ajax.ajaxs(`/user/activateUser`, params);
    },
    storeRegister(params, isShow = 1) {
      // console.log(params, isShow);
      return Ajax.ajaxs(`/store/register`, params, "POST", isShow);
    },
    sendCodeForMobile(params) {
      return Ajax.ajaxs(`/baseVerify/sendCodeForMobile`, params);
    },
    loginOut() {
      return Ajax.ajaxs(`/user/loginOut`, {}, "GET");
    },
    getStoreRegisterInfo(params, type = null) {
      if (type) {
        return Ajax.ajaxs(`/store/getStoreRegisterInfo?${type}`, params);
      } else {
        return Ajax.ajaxs(`/store/getStoreRegisterInfo`, params);
      }
    },
    getStoreStep(params) {
      return Ajax.ajaxs(`/store/getStoreStep`, params);
    },
    getStoreRegisterProgress(params) {
      return Ajax.ajaxs(`/store/getStoreRegisterProgress`, params, "GET");
    },
    verifyPassword(params) {
      return Ajax.ajaxs(`/user/verifyPassword`, params, 'PUT');
    },
  },
  //添加商品
  addProduct: {
    // 商品分类查询
    queryCommodityClassification(goodsCategoryId) {
      return Ajax.ajaxs(
        `/goodsCategory/getAllGoodsCategory?goodsCategoryId=${goodsCategoryId}`,
        {},
        "GET"
      );
    },
    // 获取商品分类
    getGoodsClassify(params) {
      return Ajax.ajaxs(`/goods/classify/getGoodsClassify`, params);
    },
    // 获取收藏商品分类列表
    getCollectGoodsClassify() {
      return Ajax.ajaxs(
        `/goods/classify/collect/getCollectGoodsClassify`,
        {}
      );
    },
    // 添加收藏商品分类
    addCollectGoodsClassify(params) {
      return Ajax.ajaxs(
        `/goods/classify/collect/addCollectGoodsClassify`,
        params
      );
    },
    // 取消收藏商品分类
    removeCollectGoodsClassify(params) {
      return Ajax.ajaxs(
        `/goods/classify/collect/removeCollectGoodsClassify`,
        params
      );
    },
    // 获取分类变体列表
    getGoodsVariantList(params) {
      return Ajax.ajaxs(`/goods/variant/getGoodsVariantList`, params, "GET");
    },
    // 获取商品分类ALL父级名称
    getGoodsClassifyParentName(params) {
      return Ajax.ajaxs(
        `/goods/classify/getGoodsClassifyParentName`,
        params,
        "GET"
      );
    },
    // 获取商品其他属性KEY服务
    getBasicsKeyList(params) {
      return Ajax.ajaxs(`/goods/extends/getExtendsList`, params, "GET");
    },
    // 商品编码类型服务
    getNumbetType() {
      return Ajax.ajaxs(`/goods/number/getNumbetType`, {}, "GET");
    },
    // 添加商品
    addGoodsInfo(params) {
      return Ajax.ajaxs(`/goods/addGoodsInfo`, params, "POST", 1);
    },
    // 商品库存列表
    getAllGoodsList(params) {
      return Ajax.ajaxs(`/goods/getAllGoodsList`, params, "POST");
    },
    // 删除商品
    deleteGoods(params) {
      return Ajax.ajaxs(`/goods/deleteGoods`, params, "DELETE");
    },
    // 获取指定商品分类属性
    getGoodsExtendsValue(params) {
      return Ajax.ajaxs(`/goods/extends/getGoodsExtendsValue`, params, "GET");
    },
    // 获取变体列表
    getUpGoodsVariantList(params) {
      return Ajax.ajaxs(`/goods/getGoodsVariantList`, params, "GET");
    },
    // 修改商品其他属性Value
    updateGoodsElseExtendsValue(params) {
      return Ajax.ajaxs(`/goods/extends/updateGoodsElseExtendsValue`, params);
    },
    // 获取图片接口
    getGoodsImg(params) {
      return Ajax.ajaxs(`/goods/getGoodsImg`, params, "GET");
    },
    // 商品总数接口
    getAllGoodsCount() {
      return Ajax.ajaxs(`/goods/getAllGoodsCount`, {}, "GET");
    },
    // 新增||修改图片接口
    setGoodsImg(params) {
      return Ajax.ajaxs(`/goods/setGoodsImg`, params);
    },
    // 设置商品价格||企业价格
    upGoodsInventoryAndMoney(params) {
      return Ajax.ajaxs(`/goods/upGoodsInventoryAndMoney`, params);
    },
    // 商品投放-商品分类搜索列表(默认)
    getGoodsClassifyList(params) {
      return Ajax.ajaxs("/goods/classify/getGoodsClassifyList", params, "GET");
    },
    // 商品投放-商品分类搜索(带搜索)
    getGoodsClassifySearch(params) {
      return Ajax.ajaxs(
        "/goods/classify/getGoodsClassifySearch",
        params,
        "GET"
      );
    },
    // 商品投放-建议分类(通过商品id获取分类)
    getSuggestClassifyList(params) {
      return Ajax.ajaxs("/goods/classify/suggestClassifyList", params, "GET");
    },
    // 库存商品搜索列表
    goodsStockList(params) {
      return Ajax.ajaxs(`/goods/goodsStockList`, params, "GET");
    },
    // 获取商品批量导入模板Url
    getGoodsImportUrl() {
      return Ajax.ajaxs(`/goods/getGoodsImportUrl`, {}, "GET");
    },
    // 批量导入商品
    addGoodsBatch(params) {
      return Ajax.ajaxs(`/goods/addGoodsBatch`, params, "GET");
    },
    // 获取商品批量导入记录
    getGoodsBatchList(params) {
      return Ajax.ajaxs(`/goods/getGoodsBatchList`, params);
    }
  },
  //FBA配送
  fba: {
    //转fba配送
    updateGoodsDispatchingTypeAmazon(params) {
      return Ajax.ajaxs(`/fba/updateGoodsDispatchingTypeAmazon`, params);
    },
    //转卖家配送
    updateGoodsDispatchingTypeThis(params) {
      return Ajax.ajaxs(`/fba/updateGoodsDispatchingTypeThis`, params);
    },
    //亚马逊库存补发货-商品列表
    getAmazonEntrepotGoodsList(params) {
      return Ajax.ajaxs(`/goods/getAmazonEntrepotGoodsList`, params);
    },
    //发补货-删除||取消货件
    deleteCommdityPlan(params) {
      return Ajax.ajaxs(`/fba/deleteCommdityPlan`, params, "GET");
    },
    //发补货-删除入库计划
    deleteStoragePlan(params) {
      return Ajax.ajaxs(`/fba/deleteStoragePlan`, params, "GET");
    },
    //获取打包类型列表
    getGoodsPackTypeAll() {
      return Ajax.ajaxs(`/fba/getGoodsPackTypeAll`, {});
    },
    //亚马逊货件列表
    getCommodityPlanInfoList(params) {
      return Ajax.ajaxs(`/fba/getCommodityPlanInfoList`, params);
    },
    //亚马逊入库计划列表
    getStoragePlanInfoList(params) {
      return Ajax.ajaxs(`/fba/getStoragePlanInfoList`, params);
    },
    //标记为已发货
    upCommodityPlanInfoCommodityStatus(params) {
      return Ajax.ajaxs(
        `/fba/upCommodityPlanInfoCommodityStatus`,
        params,
        "GET"
      );
    },
    //创建入库计划
    addStoragePlan(params) {
      return Ajax.ajaxs(`/fba/addStoragePlan`, params);
    },
    //获取商品信息
    getStoragePlanInfo(params) {
      return Ajax.ajaxs(`/fba/getStoragePlanInfo`, params, "GET");
    },
    //发补货-添加入库计划商品信息
    setStoragePlanGoodsInfo(params) {
      return Ajax.ajaxs(`/fba/setStoragePlanGoodsInfo`, params, "GET");
    },
    //发补货-获取预处理货件信息-预览
    getCommodityPlanInfoView(params) {
      return Ajax.ajaxs(`/fba/getCommodityPlanInfoView`, params, "GET");
    },
    //发补货-移除入库计划商品信息
    deleteStoragePlanGoodsCount(params) {
      return Ajax.ajaxs(`/fba/deleteStoragePlanGoodsCount`, params, "GET");
    },
    //发补货-移除入库计划商品信息
    getCommodityPlanInfo(params) {
      return Ajax.ajaxs(`/fba/getCommodityPlanInfo`, params, "GET");
    },
    //发补货-预处理货件
    upCommodityPlan(params) {
      return Ajax.ajaxs(`/fba/upCommodityPlan`, params, "POST", 1);
    },
    //发补货-设置数量
    upStoragePlanGoodsCount(params) {
      return Ajax.ajaxs(`/fba/upStoragePlanGoodsCount`, params);
    },
    //发补货-打包设置
    upStoragePlanGoodsPack(params) {
      return Ajax.ajaxs(`/fba/upStoragePlanGoodsPack`, params);
    },
    //分仓--修改货件名称
    upCommodityPlanName(params) {
      return Ajax.ajaxs(`/fba/upCommodityPlanName`, params);
    },
    //发补货-贴标设置
    upStoragePlanGoodsTag(params) {
      return Ajax.ajaxs(`/fba/upStoragePlanGoodsTag`, params);
    },
    //分仓 -- 获取分仓数据
    separateStoragePlan(params) {
      return Ajax.ajaxs(`/fba/separateStoragePlan`, params, "GET");
    },
    //分仓 -- 获取分仓数据
    getSeparateInfo(params) {
      return Ajax.ajaxs(`/fba/getSeparateInfo`, params, "GET");
    },
    //获取货件编号
    getPlanNumber(params) {
      return Ajax.ajaxs(`/fba/getPlanNumber`, params);
    },
    //保存货件计划
    addPlan(params) {
      return Ajax.ajaxs(`/fba/addPlan`, params);
    },
    //货件列表
    getPlanList(params) {
      return Ajax.ajaxs(`/fba/getPlanList`, params);
    },
  },
  //订单
  order: {
    // 获取订单列表
    getOrderList(params) {
      return Ajax.ajaxs(`/order/getOrderList`, params);
    },
  },
  //订单消息
  orderMsg: {
    // 获取订单列表
    getOrderMsgList(params) {
      return Ajax.ajaxs(`/orderMsg/getOrderMsgList`, params);
    },
    // 发送消息
    setOrderMsg(params) {
      return Ajax.ajaxs(`/orderMsg/setOrderMsg`, params, "POST", 1);
    },
    // 获取未读信息
    getOrderMsgUnreadCount() {
      return Ajax.ajaxs(`/orderMsg/getOrderMsgUnreadCount`, {}, "GET");
    },
    // 已读消息
    upOrderMsgRead(params) {
      return Ajax.ajaxs(`/orderMsg/upOrderMsgRead/${params}`, {});
    }
  },
  // 配送设置
  distribution: {
    // 获取默认地址
    getDistributionSiteStoreArea() {
      return Ajax.ajaxs(`/distribution/getDistributionSiteStoreArea`, {}, "GET");
    },
    // 获取地址列表
    getDistributionSiteStoreAreaList() {
      return Ajax.ajaxs(`/distribution/getDistributionSiteStoreAreaList`, {}, "GET");
    },
    // 添加地址
    addDistributionSiteStoreArea(params) {
      return Ajax.ajaxs(`/distribution/addDistributionSiteStoreArea`, params);
    },
    // 修改地址
    upDistributionSiteStoreArea(params) {
      return Ajax.ajaxs(`/distribution/upDistributionSiteStoreArea`, params);
    },
    // 获取运费信息
    getDistributionFreight() {
      return Ajax.ajaxs(`/distribution/getDistributionFreight`, {});
    },
    // 获取运费信息
    getDistributionFreight() {
      return Ajax.ajaxs(`/distribution/getDistributionFreight`, {});
    },
    // 添加运费信息
    addDistributionFreight(params) {
      return Ajax.ajaxs(`/distribution/addDistributionFreight`, params, "POST", 1);
    },
    // 修改运费信息
    upDistributionFreight(params) {
      return Ajax.ajaxs(`/distribution/upDistributionFreight`, params, "POST", 1);
    },
    // 删除配送地址
    deleteDistributionSiteStoreArea(params) {
      return Ajax.ajaxs(`/distribution/deleteDistributionSiteStoreArea/${params}`, {}, "GET");
    },
  },
  //上传文件
  file: {
    // 上传单个文件
    upload(props) {
      return Ajax.uploadFile(`/file/upload`, props);
    },
    // 获取上传oss临时url
    getFileTemporaryUploadUrl(params) {
      return Ajax.ajaxs(`/file/getFileTemporaryUploadUrl`, params, "GET");
    },
  },
  advertising: {
    addAdvertising(params) {
      return Ajax.ajaxs("/advertising/addAdvertising", params, "POST", 1);
    },
    getAdvertisingDetail(params) {
      return Ajax.ajaxs("/advertising/getAdvertisingDetail", params, "GET");
    },
    getAdvertisingSuggestKeywordList(params) {
      return Ajax.ajaxs(
        "/advertising/getAdvertisingSuggestKeywordList",
        params,
        "GET"
      );
    },
    updateAdvertising(params) {
      return Ajax.ajaxs("/advertising/updateAdvertising", params, "PUT");
    },
    /**
     * 根据广告活动id获取广告活动信息(编辑页面头信息)
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/getAdvertisingDetailHeadUsingGET
     */
    getAdvertisingDetailHead(params) {
      return Ajax.ajaxs("/advertising/getAdvertisingDetailHead", params, "GET");
    },
    /**
     * 根据广告组合id 查询下面的广告活动列表
     * @param {*} params
     * @returns
     *
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/getAdvertisingListUsingGET
     */
    getAdvertisingList(params) {
      return Ajax.ajaxs("/advertising/getAdvertisingList", params, "GET");
    },
    /**
     * 更新广告活动状态
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/updateAdvertisingStatusUsingPUT
     */
    updateAdvertisingStatus(params) {
      return Ajax.ajaxs("/advertising/updateAdvertisingStatus", params, "PUT");
    },
    /**
     * 删除广告活动
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/deleteAdvertisingUsingDELETE
     */
    deleteAdvertising(params) {
      return Ajax.ajaxs("/advertising/deleteAdvertising", params, "DELETE");
    },
    /**
     * 根据广告活动id获取广告组列表
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/getAdvertisingGroupListUsingGET
     */
    getAdvertisingGroupList(params) {
      return Ajax.ajaxs("/advertising/getAdvertisingGroupList", params, "GET");
    },
    /**
     * 根据广告活动id获取广告位列表
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/getAdvertisingSpaceListUsingGET
     */
    getAdvertisingSpaceList(params) {
      return Ajax.ajaxs("/advertising/getAdvertisingSpaceList", params, "GET");
    },
    /**
     * 根据广告活动id获取广告活动设置信息
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/getAdvertisingDetailSetUsingGET
     */
    getAdvertisingDetailSet(params) {
      return Ajax.ajaxs("/advertising/getAdvertisingDetailSet", params, "GET");
    },
    /**
     * 根据广告活动id更新广告活动名称
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/updateAdvertisingNameUsingPUT
     */
    updateAdvertisingName(params) {
      return Ajax.ajaxs("/advertising/updateAdvertisingName", params, "PUT");
    },
    /**
     * 根据广告活动id更新广告组合选项
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/updateAdvertisingCombinationOptionUsingPUT
     */
    updateAdvertisingCombinationOption(params) {
      return Ajax.ajaxs(
        "/advertising/updateAdvertisingCombinationOption",
        params,
        "PUT"
      );
    },
    /**
     * 根据广告活动id更新广告活动状态
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/updateAdvertisingStatusUsingPUT
     */
    updateAdvertisingStatus(params) {
      return Ajax.ajaxs("/advertising/updateAdvertisingStatus", params, "PUT");
    },
    /**
     * 根据广告活动id更新广告活动结束日期
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/updateAdvertisingEndTimeUsingPUT
     */
    updateAdvertisingEndTime(params) {
      return Ajax.ajaxs("/advertising/updateAdvertisingEndTime", params, "PUT");
    },
    /**
     * 根据广告活动id更新广告活动预算
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/updateAdvertisingBudgetUsingPUT
     */
    updateAdvertisingBudget(params) {
      return Ajax.ajaxs("/advertising/updateAdvertisingBudget", params, "PUT");
    },
    /**
     * 根据广告活动id更新广告活动竞价策略
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/updateAdvertisingBiddingTypeUsingPUT
     */
    updateAdvertisingBiddingType(params) {
      return Ajax.ajaxs(
        "/advertising/updateAdvertisingBiddingType",
        params,
        "PUT"
      );
    },
    /**
     * 根据广告活动id查询广告组关键词列表
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/getAdvertisingGroupKeywordsListUsingGET
     */
    getAdvertisingGroupKeywordsList(params) {
      return Ajax.ajaxs(
        "/advertising/getAdvertisingGroupKeywordsList",
        params,
        "GET"
      );
    },
    /**
     * 根据广告活动id查询广告否定商品列表
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/getAdvertisingNegativeGoodsListUsingGET
     */
    getAdvertisingNegativeGoodsList(params) {
      return Ajax.ajaxs(
        "/advertising/getAdvertisingNegativeGoodsList",
        params,
        "GET"
      );
    },
    /**
     * 根据广告活动id查询广告否定关键词列表
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/getAdvertisingNegativeKeywordsListUsingGET_1
     */
    getAdvertisingNegativeKeywordsList(params) {
      return Ajax.ajaxs(
        "/advertising/getAdvertisingNegativeKeywordsList",
        params,
        "GET"
      );
    },
    /**
     * 根据广告活动id更新广告活动商品页面竞价调整比例
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/updateAdvertisingBiddingPriceGoodsRateUsingPUT
     */
    updateAdvertisingBiddingPriceGoodsRate(params) {
      return Ajax.ajaxs(
        "/advertising/updateAdvertisingBiddingPriceGoodsRate",
        params,
        "PUT"
      );
    },
    /**
     * 根据广告活动id更新广告活动搜索结果顶部（首页）竞价调整比例
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/updateAdvertisingBiddingPriceSearchTopRateReqUsingPUT
     */
    updateAdvertisingBiddingPriceSearchTopRateReq(params) {
      return Ajax.ajaxs(
        "/advertising/updateAdvertisingBiddingPriceSearchTopRateReq",
        params,
        "PUT"
      );
    },
    /**
     * 根据广告活动id新增广告否定关键词
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/addAdvertisingNegativeKeywordsUsingPOST
     */
    addAdvertisingNegativeKeywords(params) {
      return Ajax.ajaxs(
        "/advertising/addAdvertisingNegativeKeywords",
        params,
        "POST"
      );
    },
    /**
     * 根据广告组id新增广告组否定关键词
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/addAdvertisingGroupNegativeKeywordsUsingPOST
     */
    addAdvertisingGroupNegativeKeywords(params) {
      return Ajax.ajaxs(
        "/advertising/addAdvertisingGroupNegativeKeywords",
        params,
        "POST"
      );
    },
    /**
     * 根据广告活动id新增广告否定商品
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/addAdvertisingNegativeGoodsUsingPOST
     */
    addAdvertisingNegativeGoods(params) {
      return Ajax.ajaxs(
        "/advertising/addAdvertisingNegativeGoods",
        params,
        "POST"
      );
    },
    /**
     * 根据广告活动id 创建广告组
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/addAdvertisingGroupUsingPOST
     */
    addAdvertisingGroup(params) {
      return Ajax.ajaxs("/advertising/addAdvertisingGroup", params, "POST");
    },
    /**
     * 获取广告活动草案列表
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/getAdvertisingDraftListUsingGET
     */
    getAdvertisingDraftList(params) {
      return Ajax.ajaxs("/advertising/getAdvertisingDraftList", params, "GET");
    },
    /**
     * 批量删除广告活动草案
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/deleteAdvertisingDraftUsingDELETE
     */
    deleteAdvertisingDraft(params) {
      return Ajax.ajaxs(
        "/advertising/deleteAdvertisingDraft",
        params,
        "DELETE"
      );
    },
    /**
     * 获取广告组头信息
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/getAdvertisingGroupDetailHeadUsingGET
     */
    getAdvertisingGroupDetailHead(params) {
      return Ajax.ajaxs(
        "/advertising/getAdvertisingGroupDetailHead",
        params,
        "GET"
      );
    },
    /**
     * 获取广告组商品列表
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/getAdvertisingGoodsListUsingGET
     */
    getAdvertisingGoodsList(params) {
      return Ajax.ajaxs("/advertising/getAdvertisingGoodsList", params, "GET");
    },
    /**
     * 获取广告组投放组列表
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/getAdvertisingDeliveryListUsingGET
     */
    getAdvertisingDeliveryList(params) {
      return Ajax.ajaxs(
        "/advertising/getAdvertisingDeliveryList",
        params,
        "GET"
      );
    },
    /**
     * 查询广告组否定关键词列表
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/getAdvertisingNegativeKeywordsListUsingGET
     */
    getAdvertisingGroupNegativeKeywordsList(params) {
      return Ajax.ajaxs(
        "/advertising/getAdvertisingGroupNegativeKeywordsList",
        params,
        "GET"
      );
    },
    /**
     * 查询广告组否定商品定向列表
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/getAdvertisingGroupNegativeDirectionalListUsingGET
     */
    getAdvertisingGroupNegativeDirectionalList(params) {
      return Ajax.ajaxs(
        "/advertising/getAdvertisingGroupNegativeDirectionalList",
        params,
        "GET"
      );
    },
    /**
     * 新增广告组否定商品定向
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/addAdvertisingGroupNegativeDirectionalUsingPOST
     */
    addAdvertisingGroupNegativeDirectional(params) {
      return Ajax.ajaxs(
        "/advertising/addAdvertisingGroupNegativeDirectional",
        params,
        "POST"
      );
    },
    /**
     * 查询广告组搜索词
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/getAdvertisingGroupSearchTermsUsingGET
     */
    getAdvertisingGroupSearchTerms(params) {
      return Ajax.ajaxs(
        "/advertising/getAdvertisingGroupSearchTerms",
        params,
        "GET"
      );
    },
    /**
     * 获取广告组详情设置信息
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/getAdvertisingGroupDetailSetUsingGET
     */
    getAdvertisingGroupDetailSet(params) {
      return Ajax.ajaxs(
        "/advertising/getAdvertisingGroupDetailSet",
        params,
        "GET"
      );
    },
    /**
     * 更新广告组名称
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/updateAdvertisingGroupNameUsingPUT
     */
    updateAdvertisingGroupName(params) {
      return Ajax.ajaxs(
        "/advertising/updateAdvertisingGroupName",
        params,
        "PUT"
      );
    },
    /**
     * 更新广告组状态
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/updateAdvertisingGroupStatusUsingPUT
     */
    updateAdvertisingGroupStatus(params) {
      return Ajax.ajaxs(
        "/advertising/updateAdvertisingGroupStatus",
        params,
        "PUT"
      );
    },
    /**
     * 更新广告组商品状态
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/updateAdvertisingGoodsStatusUsingPUT
     */
    updateAdvertisingGoodsStatus(params) {
      return Ajax.ajaxs(
        "/advertising/updateAdvertisingGoodsStatus",
        params,
        "PUT"
      );
    },
    /**
     * 更新广告投放组状态
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/updateAdvertisingDeliveryStatusUsingPUT
     */
    updateAdvertisingDeliveryStatus(params) {
      return Ajax.ajaxs(
        "/advertising/updateAdvertisingDeliveryStatus",
        params,
        "PUT"
      );
    },
    /**
     * 更新广告组默认竞价
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/updateAdvertisingGroupBiddingUsingPUT
     */
    updateAdvertisingGroupBidding(params) {
      return Ajax.ajaxs(
        "/advertising/updateAdvertisingGroupBidding",
        params,
        "PUT"
      );
    },

    /**
     * 新增广告组商品
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/addAdvertisingGroupGoodsUsingPOST
     */
    addAdvertisingGroupGoods(params) {
      return Ajax.ajaxs(
        "/advertising/addAdvertisingGroupGoods",
        params,
        "POST"
      );
    },
    /**
     *
     * @param {*} params
     * @returns
     * @see
     */
    getAdvertisingStartUpPage(params) {
      return Ajax.ajaxs(
        "/advertising/getAdvertisingStartUpPage",
        params,
        "GET"
      );
    },
  },
  advertisingCombination: {
    /**
     * 新增广告组合
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E7%BB%84%E5%90%88%E6%9C%8D%E5%8A%A1/addAdvertisingCombinationUsingPOST
     */
    addAdvertisingCombination(params) {
      return Ajax.ajaxs(
        "/advertisingCombination/addAdvertisingCombination",
        params,
        "POST"
      );
    },
    /**
     * 查询广告组合列表
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E7%BB%84%E5%90%88%E6%9C%8D%E5%8A%A1/queryAdvertisingCombinationListUsingGET
     */
    queryAdvertisingCombinationList(params) {
      return Ajax.ajaxs(
        "/advertisingCombination/queryAdvertisingCombinationList",
        params,
        "GET"
      );
    },
    /**
     * 获取广告组合详情
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E7%BB%84%E5%90%88%E6%9C%8D%E5%8A%A1/getAdvertisingCombinationDetailUsingGET
     */
    getAdvertisingCombinationDetail(params) {
      return Ajax.ajaxs(
        "/advertisingCombination/getAdvertisingCombinationDetail",
        params,
        "GET"
      );
    },
    /**
     * 获取广告组合详情头
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E7%BB%84%E5%90%88%E6%9C%8D%E5%8A%A1/getAdvertisingCombinationHeadUsingGET
     */
    getAdvertisingCombinationHead(params) {
      return Ajax.ajaxs(
        "/advertisingCombination/getAdvertisingCombinationHead",
        params,
        "GET"
      );
    },
    /**
     * 修改广告组合
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E7%BB%84%E5%90%88%E6%9C%8D%E5%8A%A1/updateAdvertisingCombinationUsingPUT
     */
    updateAdvertisingCombination(params) {
      return Ajax.ajaxs(
        "/advertisingCombination/updateAdvertisingCombination",
        params,
        "PUT"
      );
    },
    getAdvertisingCombinationList(params) {
      return Ajax.ajaxs(
        "/advertisingCombination/getAdvertisingCombinationList",
        params,
        "GET"
      );
    },
    getOption(params) {
      return Ajax.ajaxs(
        "/advertisingCombination/getOption",
        params,
        "GET"
      ).then((res) => {
        if (res.code !== 1) return res;

        const newData = res.data.map((item) => {
          return {
            ...item,
            label: item.advertisingCombinationName,
            value: item.advertisingCombinationId,
          };
        });

        return {
          ...res,
          data: newData,
        };
      });
    },
  },
  brand: {
    getBrandByClassifyId(params) {
      return Ajax.ajaxs("/brand/getBrandByClassifyId", params, "GET");
    },
    queryBrand(params) {
      return Ajax.ajaxs("/brand/queryBrand", params, "GET");
    },
  },
  goods: {
    /**
     * 根据广告活动id 查询广告否定商品列表
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%B9%BF%E5%91%8A%E6%9C%8D%E5%8A%A1/getAdvertisingNegativeGoodsListUsingGET
     */
    queryGoodsPutNegativeList(params) {
      return Ajax.ajaxs("/goods/queryGoodsPutNegativeList", params, "GET");
    },
    /**
     * 库存商品-优惠券搜索列表
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E5%95%86%E5%93%81%E6%9C%8D%E5%8A%A1/goodsCouponsListUsingGET
     */
    goodsCouponsList(params) {
      return Ajax.ajaxs("/goods/goodsCouponsList", params, "GET");
    },
  },
  coupons: {
    /**
     * 优惠券列表
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E4%BC%98%E6%83%A0%E5%88%B8%E6%9C%8D%E5%8A%A1/queryCouponsListUsingGET
     */
    queryCouponsList(params) {
      return Ajax.ajaxs("/coupons/queryCouponsList", params, "GET");
    },
    /**
     * 删除优惠券
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E4%BC%98%E6%83%A0%E5%88%B8%E6%9C%8D%E5%8A%A1/deleteCouponsUsingDELETE
     */
    deleteCoupons(params) {
      return Ajax.ajaxs("/coupons/deleteCoupons", params, "DELETE");
    },
    /**
     * 获取优惠券状态数
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E4%BC%98%E6%83%A0%E5%88%B8%E6%9C%8D%E5%8A%A1/getCouponsStatusNumUsingGET
     */
    getCouponsStatusNum(params) {
      return Ajax.ajaxs("/coupons/getCouponsStatusNum", params, "GET");
    },
    /**
     * 添加优惠券
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E4%BC%98%E6%83%A0%E5%88%B8%E6%9C%8D%E5%8A%A1/addCouponsUsingPOST
     */
    addCoupons(params) {
      return Ajax.ajaxs("/coupons/addCoupons", params, "POST", 1);
    },
    /**
     * 修改优惠券
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E4%BC%98%E6%83%A0%E5%88%B8%E6%9C%8D%E5%8A%A1/updateCouponsUsingPUT
     */
    updateCoupons(params) {
      return Ajax.ajaxs("/coupons/updateCoupons", params, "PUT");
    },
    /**
     * 获取优惠券编辑详情
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E4%BC%98%E6%83%A0%E5%88%B8%E6%9C%8D%E5%8A%A1/getCouponsEditDetailUsingGET
     */
    getCouponsEditDetail(params) {
      return Ajax.ajaxs("/coupons/getCouponsEditDetail", params, "GET");
    },
    /**
     * 获取优惠券详情
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E4%BC%98%E6%83%A0%E5%88%B8%E6%9C%8D%E5%8A%A1/getCouponsDetailUsingGET
     */
    getCouponsDetail(params) {
      return Ajax.ajaxs("/coupons/getCouponsDetail", params, "GET");
    },
  },
  promotion: {
    /**
     * 创建商品管理
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E4%BF%83%E9%94%80%E7%AE%A1%E7%90%86%E6%9C%8D%E5%8A%A1/addPromotionGoodsUsingPOST
     */
    addPromotionGoods(params) {
      return Ajax.ajaxs("/promotion/addPromotionGoods", params, "POST");
    },
    /**
     * 获取商品管理详情
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E4%BF%83%E9%94%80%E7%AE%A1%E7%90%86%E6%9C%8D%E5%8A%A1/getPromotionGoodsDetailUsingGET
     */
    getPromotionGoodsDetail(params) {
      return Ajax.ajaxs("/promotion/getPromotionGoodsDetail", params, "GET");
    },
    /**
     * 修改商品管理
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E4%BF%83%E9%94%80%E7%AE%A1%E7%90%86%E6%9C%8D%E5%8A%A1/updatePromotionGoodsUsingPUT
     */
    updatePromotionGoods(params) {
      return Ajax.ajaxs("/promotion/updatePromotionGoods", params, "PUT");
    },
    /**
     * 促销列表
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E4%BF%83%E9%94%80%E6%9C%8D%E5%8A%A1/getAllPromotionListUsingPOST
     */
    getAllPromotionList(params) {
      return Ajax.ajaxs("/promotion/getAllPromotionList", params, "GET");
    },
    /**
     * 获取商品管理列表
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E4%BF%83%E9%94%80%E7%AE%A1%E7%90%86%E6%9C%8D%E5%8A%A1/getPromotionGoodsListUsingGET
     */
    getPromotionGoodsList(params) {
      return Ajax.ajaxs("/promotion/getPromotionGoodsList", params, "GET");
    },
    /**
     * 获取管理促销列表
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E4%BF%83%E9%94%80%E7%AE%A1%E7%90%86%E6%9C%8D%E5%8A%A1/getPromotionListUsingGET
     */
    getPromotionList(params) {
      return Ajax.ajaxs("/promotion/getPromotionList", params, "GET");
    },
    /**
     * 删除促销
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E4%BF%83%E9%94%80%E7%AE%A1%E7%90%86%E6%9C%8D%E5%8A%A1/deletePromotionUsingDELETE
     */
    deletePromotion(params) {
      return Ajax.ajaxs("/promotion/deletePromotion", params, "DELETE");
    },
    /**
     * 添加促销
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E4%BF%83%E9%94%80%E7%AE%A1%E7%90%86%E6%9C%8D%E5%8A%A1/addPromotionUsingPOST
     */
    addPromotion(params) {
      return Ajax.ajaxs("/promotion/addPromotion", params, "POST", 1);
    },
    /**
     * 修改促销
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E4%BF%83%E9%94%80%E7%AE%A1%E7%90%86%E6%9C%8D%E5%8A%A1/updatePromotionUsingPUT
     */
    updatePromotion(params) {
      return Ajax.ajaxs("/promotion/updatePromotion", params, "PUT");
    },
    /**
     * 结束促销
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E4%BF%83%E9%94%80%E7%AE%A1%E7%90%86%E6%9C%8D%E5%8A%A1/updatePromotionEndUsingPUT
     */
    updatePromotionEnd(params) {
      return Ajax.ajaxs("/promotion/updatePromotionEnd", params, "PUT");
    },
    /**
     * 获取促销编辑查阅详情
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E4%BF%83%E9%94%80%E7%AE%A1%E7%90%86%E6%9C%8D%E5%8A%A1/getPromotionDetailUsingGET
     */
    getPromotionDetail(params) {
      return Ajax.ajaxs("/promotion/getPromotionDetail", params, "GET");
    },
    /**
     * 获取优惠码
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E4%BF%83%E9%94%80%E7%AE%A1%E7%90%86%E6%9C%8D%E5%8A%A1/getDiscountCodeUsingGET
     */
    getDiscountCode(params) {
      return Ajax.ajaxs("/promotion/getDiscountCode", params, "GET");
    },
    /**
     * 获取管理优惠码详情
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E4%BF%83%E9%94%80%E7%AE%A1%E7%90%86%E6%9C%8D%E5%8A%A1/getManagementDiscountCodeDetailUsingGET
     */
    getManagementDiscountCodeDetail(params) {
      return Ajax.ajaxs("/promotion/getManagementDiscountCodeDetail", params, "GET");
    },
    /**
     * 获取管理优惠码组列表
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E4%BF%83%E9%94%80%E7%AE%A1%E7%90%86%E6%9C%8D%E5%8A%A1/getManagementDiscountCodeGroupListUsingGET
     */
    getManagementDiscountCodeGroupList(params) {
      return Ajax.ajaxs("/promotion/getManagementDiscountCodeGroupList", params, "GET");
    },
    /**
     * 创建优惠码组
     * @param {*} params
     * @returns
     * @see http://192.168.0.126:9005/doc.html#/default/%E4%BF%83%E9%94%80%E7%AE%A1%E7%90%86%E6%9C%8D%E5%8A%A1/addPromotionDiscountCodeGroupUsingPOST
     */
    addPromotionDiscountCodeGroup(params) {
      return Ajax.ajaxs("/promotion/addPromotionDiscountCodeGroup", params, "POST", 1);
    },
  },
  //存款方式
  store: {
    // 获取管理存款方式列表
    getDistributionShoppingList(params) {
      return Ajax.ajaxs(`/store/getDistributionShoppingList`, params, 'GET');
    },
    // 激活存款方式
    activationStoreDeposit(params) {
      return Ajax.ajaxs(`/store/activationStoreDeposit`, params, 'PUT');
    },
    // 新增存款方式
    addStoreDeposit(params) {
      return Ajax.ajaxs(`/store/addStoreDeposit`, params, 'POST');
    },
    // 删除存款方式信息
    deleteDepositInfo(params) {
      return Ajax.ajaxs(`/store/deleteDepositInfo`, params, 'DELETE');
    },
    // 移除商城存款方式信息
    deleteShoppingDeposit(params) {
      return Ajax.ajaxs(`/store/deleteShoppingDeposit`, params, 'DELETE');
    },
    // 获取分配商城列表
    getDistributionShoppingList(params) {
      return Ajax.ajaxs(`/store/getDistributionShoppingList`, params, 'GET');
    },
    // 获取商城选项
    getShoppingOption(params) {
      return Ajax.ajaxs(`/store/getShoppingOption`, params, 'GET');
    },
    // 获取管理存款方式列表
    getStoreDepositList(params) {
      return Ajax.ajaxs(`/store/getStoreDepositList`, params, 'GET');
    },
    // 获取存款方式选项
    getStoreDepositOption(params) {
      return Ajax.ajaxs(`/store/getStoreDepositOption`, params, 'GET');
    },
    // 获取指定KEY 店铺注册信息
    getStoreRegisterInfo(params) {
      return Ajax.ajaxs(`/store/getStoreRegisterInfo`, params, 'POST');
    },
    // 获取店铺注册进度
    getStoreRegisterProgress(params) {
      return Ajax.ajaxs(`/store/getStoreRegisterProgress`, params, 'GET');
    },
    // 获取商城存款方式详情
    getStoreShoppingDeposit(params) {
      return Ajax.ajaxs(`/store/getStoreShoppingDeposit`, params, 'GET');
    },
    // 设置存款方式
    setStoreDeposit({ params, isShow }) {
      return Ajax.ajaxs(`/store/setStoreDeposit`, params, 'POST', isShow);
    },
  },
};

export default Apis;
