import { Button } from "antd";
import { ATitleTooltip } from "@a-components";
import GoodsItem from "@b-components/GoodsItem";
import { CloseOutlined } from "@ant-design/icons";

import style from "./style.module.css";
import classNames from "classnames";

const SelectedGoodsList = ({ value, onChange }) => {
  return (
    <div className={classNames(style.selectedGoodsList, "df fd-c")}>
      <div
        className={classNames(style.operaBar, "df jc-sb ai-c")}
        style={{ height: 40 }}
      >
        <span>{value.length}件商品</span>
        <div>
          <Button size="small" type="link">
            导出
          </Button>
          <ATitleTooltip></ATitleTooltip>
          <Button size="small" type="link" onClick={() => onChange([])}>
            全部删除
          </Button>
        </div>
      </div>
      <div className={classNames(style.goodsList)}>
        {value.map((item) => {
          const goodsItemExtra = (
            <CloseOutlined
              onClick={() =>
                onChange?.(value.filter((v) => v.goodsId !== item.goodsId))
              }
            />
          );

          return (
            <GoodsItem key={item.goodsId} info={item} extra={goodsItemExtra} />
          );
        })}
      </div>
    </div>
  );
};

export default SelectedGoodsList;
