import React from 'react'
import './Archlve.css'
import IconFont from 'components/Icon/Icon'

function Archlve(){
    return(
        <div >

            <div className="Archlve_navBox">
                <div className="Archlve_navBox_inp"><input placeholder="Search by report name" /><button>Search</button></div>
                <div className="Archlve_seleBox">
                    <div className="Archlve_seleBoxII">
                    <div  className="Archlve_selectI">
                        <select className="Archlve_sele">
                            <option>报告细分</option>
                        </select>
                    </div>
                    <div className="Archlve_select">
                        <select className="Archlve_sele">
                            <option>发布日期</option>
                        </select>
                    </div>
                    <div  className="Archlve_selectII">
                        <select className="Archlve_sele">
                            <option>存档日期</option>
                        </select>
                    </div>
                    </div>

                    
                    <div className="Archlve_btn"><button>Clear Filters</button></div>
                </div>
            </div>

            <div className="activity_navBox">
            <div className="activity_navBoxII">
            <div>No.</div>
            <div>Name</div>
            <div>Segment</div>
            <div>date from</div>
            <div>Date to</div>
            <div>Gener ated on</div>
            <div>Notes</div>
            <div>Download</div>
            </div>
        </div>


        </div>
    )
}

export default Archlve