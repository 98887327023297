import React, { useState } from 'react'
import './MengkSiderLeft.css'
import { Button, Input, Radio, Divider  } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

function MengkSiderLeft({
  tabsNum,
  next,
  pre
}) {
  return (
    <div className="MengkSiderLeft" >
      {
        tabsNum == 1 && (
          <React.Fragment>
            <h3><span onClick={next} >页面<RightOutlined /></span></h3>
            <div>店铺设置</div>
            <Divider  />
            <div className="MengkSiderLeft-title" >品牌信息</div>
            <div className="MengkSiderLeft-content" >
              您的品牌名称和徽标显示在您的品牌旗舰店导航中,
              可用于帮助推广您在亚马逊上的品牌旗舰店。
            </div>

            <div className="MengkSiderLeft-title" >品牌显示名称</div>
            <div className="MengkSiderLeft-tips" >遗漏了“立即购买”等额外短语或"LLC”等信息。</div>
            <Input property="Mengk" />
            <div className="MengkSiderLeft-content" >剩余25个字符</div>

            <div className="MengkSiderLeft-title" >品牌logo</div>
            <div className="MengkSiderLeft-tips" >用于在 Amazon 推广您的商店。 请参阅策略</div>
            <div className="MengkSiderLeft-tailoring" ><Button>剪裁</Button></div>
            <div className="MengkSiderLeft-content" >图像必须至少为 400 x 400 像素。</div>
            <div><Button>上传新的 从素材库中选择</Button></div>

            <div className="MengkSiderLeft-title" >资产库标签 （可选）</div>
            <div className="MengkSiderLeft-tips" >请用逗号分隔标签。</div>
            <Input property="Mengk" />

            <div className="MengkSiderLeft-title" >在热门资源区域显示徽标</div>
            <div className="MengkSiderLeft-tips" >徽标将在所有您的商店页面显示热门资源。</div>
            <Radio.Group>
              <Radio value={1}>是的，显示</Radio>
              <Radio value={2}>不，不显示</Radio>
            </Radio.Group>
            <div className="MengkSiderLeft-title" >商品网格显示</div>
            <div className="MengkSiderLeft-tips" >选择最适合您的商品的默认格式。 更改将应用于所有商品网格。</div>
            <Radio.Group className="MengkSiderLeft-set" >
              <Radio.Button value={1}></Radio.Button>
              <Radio.Button value={2}></Radio.Button>
            </Radio.Group>
            <div><Button>保存</Button></div>
          </React.Fragment>
        )
      }
      {
        tabsNum == 2 && (
          <React.Fragment>
            <h3 style={{ textAlign: "left", padding: 0 }} ><span onClick={pre} ><LeftOutlined />店铺设置</span></h3>
            <div className="MengkSiderLeft-title" >页面</div>

            <div className="MengkSiderLeft-add-but" ><Button onClick={next} >添加页面</Button></div>
            <div className="MengkSiderLeft-tag" >
              <span>主页</span>
              <img src={require(`@assets/images/mengk-dian.png`).default}></img>
            </div>

          </React.Fragment>
        )
      }
      {
        tabsNum == 3 && (
          <React.Fragment>
            <h3 style={{ textAlign: "left", padding: 0 }} ><span onClick={pre} ><LeftOutlined />取消</span></h3>
            <div>添加页面</div>
            <div className="MengkSiderLeft-title" >页面导航标题</div>
            <Input property="Mengk" />

            <div className="MengkSiderLeft-title" >页面的元描述</div>
            <Input.TextArea property="Mengk" />

            <div className="MengkSiderLeft-title" >模板</div>
            <div className="MengkSiderLeft-tips" >您可以通过移动、添加和删除内容部分自定义任意模板。</div>
            <Radio.Group  defaultValue="a" className="MengkSiderLeft-add" >
              <Radio.Button value="a"></Radio.Button>
              <Radio.Button value="b"></Radio.Button>
              <Radio.Button value="c"></Radio.Button>
              <Radio.Button value="d"></Radio.Button>
            </Radio.Group>
            <div><Button >创建页面</Button></div>
          </React.Fragment>
        )
      }

    </div>

  )
}

export default MengkSiderLeft