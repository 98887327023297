import React from 'react'
import './content.css'
import Desktop from './module/desktop/desktop'
import Moblie from './module/mobile/mobile'
function content(){
    return(
        <div className="Content_body">
            {/* <div><button>台式机</button></div>
            <div><button>移动设备</button></div> */}
             <div className="Content_box">
                <ul className="Content_nav">
                    <li><a href="#content1">台式机</a></li>
                    <li><a href="#content2">移动设备</a></li>
                </ul>
                <div className="content">
                    <div className="content1" id="content1">
                        <Desktop/>
                    </div>
                    <div className="content2" id="content2">
                        <Moblie/>
                    </div>    
                </div>
            </div>
        </div>
    )
}

export default content