
 const clone = (Obj) => {
	let buf;
	if(Obj instanceof Array){
		buf=[];
		let i=Obj.length;
		while(i--){
			buf[i]=clone(Obj[i]);
		}
		return buf;
	}else if(Obj instanceof Object){
		buf={};
		for(let k in Obj){
			buf[k]=clone(Obj[k]);
		}
		return buf;
	}else{
		return Obj;
	}
}
export default clone;
