import { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "antd";
import { AText } from "@a-components";
import classNames from "classnames";
import Apis from "@api/apis";
import { computedInnerId } from "./../../../config";

import style from "./style.module.css";

import RefineDrawer from "./../../RefineDrawer";

const CategoryRecommend = ({
  selectedGoodsIds,
  onAddPosition,
  categoryGoodsPositionList,
}) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [activeCategoryInfo, setActiveCategoryInfo] = useState({});

  const handleAddPosition = useCallback(
    (data) => {
      const createBase = (item) => {
        const value = {
          ...item,
          directionalId: item.classifyId,
          directionalName: item.classifyName,
          biddingPrice: 0.75,
          goodsDirectionalType: 1, // 1 表示分类， 2 表示商品
        };

        value.$id = computedInnerId(value);
        return value;
      };
      onAddPosition?.([].concat(data).map(createBase));
    },
    [onAddPosition]
  );

  const selectedGoodsIdsStr = JSON.stringify(selectedGoodsIds);

  const innerSelectedGoodsIds = useMemo(() => {
    return JSON.parse(selectedGoodsIdsStr);
  }, [selectedGoodsIdsStr]);

  const queryCategoryByGoodsIds = useCallback(() => {
    return Apis.addProduct
      .getSuggestClassifyList({
        goodsIds: innerSelectedGoodsIds.join(","),
      })
      .then((res) => {
        if (res.code === 1) {
          setCategoryList(
            res.data.map((item) => {
              return {
                ...item,
                directionalId: item.classifyId,
                directionalName: item.classifyName,
              };
            })
          );
        } else {
          setCategoryList([]);
        }
      });
  }, [innerSelectedGoodsIds, setCategoryList]);

  const handleToRefine = useCallback((item) => {
    setDrawerVisible(true);
    return setActiveCategoryInfo(item);
  }, []);

  const selectedCategoryGoodsIds = useMemo(() => {
    return categoryGoodsPositionList.map((v) => v.directionalId);
  }, [categoryGoodsPositionList]);

  const realCategoryList = useMemo(() => {
    return categoryList.map((item) => {
      return {
        ...item,
        isPositioned: selectedCategoryGoodsIds.includes(item.classifyId),
      };
    });
  }, [categoryList, selectedCategoryGoodsIds]);

  const unSelectedCategoryList = useMemo(() => {
    return realCategoryList.filter((v) => !v.isPositioned);
  }, [realCategoryList]);

  useEffect(() => {
    queryCategoryByGoodsIds();
  }, [queryCategoryByGoodsIds]);

  return (
    <div className={classNames(style.container, "p-r")}>
      <div className={classNames(style.header, "df jc-sb ai-c")}>
        <AText>{categoryList.length}建议</AText>
        <Button
          disabled={!unSelectedCategoryList.length}
          onClick={() => handleAddPosition(categoryList)}
        >
          定位全部
        </Button>
      </div>
      <div className={classNames(style.list, "df fd-c")}>
        {realCategoryList.map((item) => {
          return (
            <div
              key={item.classifyId}
              className={classNames(style.listItem, "df jc-sb ai-c")}
            >
              <div className="df fd-c f-1">
                <div>
                  <AText className="fs-12 c-999">{item.classifyPathName}</AText>
                </div>
                <AText className="fs-12 lh-16 fw-600">
                  {item.classifyName}
                </AText>
              </div>
              <div className="df ai-c">
                <Button
                  disabled={item.isPositioned}
                  size="small"
                  onClick={() => handleAddPosition(item)}
                >
                  {item.isPositioned ? "已" : ""}定位
                </Button>
                <Button type="link" onClick={() => handleToRefine(item)}>
                  细化
                </Button>
              </div>
            </div>
          );
        })}
      </div>

      <RefineDrawer
        width="75%"
        visible={drawerVisible}
        onCancel={() => setDrawerVisible(false)}
        onOk={handleAddPosition}
        categoryInfo={activeCategoryInfo}
        categoryGoodsPositionList={categoryGoodsPositionList}
      />
    </div>
  );
};

export default CategoryRecommend;
