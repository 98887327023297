import React, { Component } from "react";
import "./index.css";
import { Tabs } from "antd";
import SetUp from "./component/SetUp/SetUp.js";
import Administration from "./component/Administration/Administration";
import History from "./component/history/index.js";
import AdvertisementLeft from "../advertisementLeft/advertisementLeft";
import Advertising from "../advertising/advertising";

import {
  BellOutlined,
  QuestionOutlined,
  FrownOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import { changeConfirmLocale } from "antd/lib/modal/locale";
const { TabPane } = Tabs;
class AdvertisingCampaign extends Component {
  render() {
    return (
      <div className="AdvertisingCampaign-box">
        <AdvertisementLeft />

        <div className="AdvertisingCampaign-box-right">
          <div className="box-right-head">
            {/* 头部 */}
            <div className="box-right-head-left">广告活动</div>
            <div className="box-right-head-right">
              <div>
                <select>
                  <option>Big Corn美国</option>
                </select>
              </div>
              <div className="icons-list">
                <BellOutlined />
              </div>
              <div className="icons-list">
                <QuestionOutlined />
              </div>
              <div className="icons-list">
                <FrownOutlined />
              </div>
            </div>
          </div>
          {/* 导航 */}

          <div className="main-box">
            <div className="AdvertisingCampaign-Navigation-box">
              <Tabs className="AdvertisingCampaign-Navigation">
                <TabPane
                  tab="管理"
                  key="1"
                  className="AdvertisingCampaign_tab1"
                >
                  <Administration />
                </TabPane>
                <TabPane tab="草稿" key="2">
                  <Advertising />
                </TabPane>
                <TabPane tab="设置" key="3">
                  <SetUp />
                </TabPane>
                <TabPane tab="历史" key="4">
                  <History />
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AdvertisingCampaign;
