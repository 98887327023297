import React from 'react'
import AJAX from "api/apis";
import './fbaConfirm.css'
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"
import { Radio, Modal, Input, Checkbox, Row, Col, Form, Select } from 'antd';
import { Link } from 'react-router-dom';
import getUrlData from "@/utils/getUrlData";
const { Option } = Select;
export default class extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isInfo: false,
            value1: '',
            value2: '',
            isModalVisibleTips: false,
            goodsId: '',
            goodsList: [],
            isModalVisible: false,
            ModalText: '',
            isReturn: false,
            dangerousGoodsList: [
                { value: '压缩气体' },
                { value: '爆炸物' },
                { value: '健康危害' },
                { value: '易燃物' },
                { value: '有毒' },
                { value: '腐蚀物' },
                { value: '氧化剂' },
                { value: '对环境有害' },
                { value: '刺激性' },
                { value: '无图形符号，仅文本' }
            ],
            BatteryInfo: [],
            isBatteryInfo: false,
            isHazardRegulations: false,
            conversionType: 1,
            discountSteps: 0,
            isTourOpen: false,
            steps1: [
                {
                    selector: '.proInfoBox',
                    content: '选择填写商品信息（若要发货的商品含有电池和危险品，则需要审核一段时间）',
                }
            ],
            isActive: false
        }
    }
    componentWillMount() {
        let type = getUrlData('type')
        let goodsList = JSON.parse(sessionStorage.getItem('goodsList'))
        for (let items of goodsList.list) { items.checked = false; items.isTable = false; items.hazardInfo = null }
        this.setState({
            goodsList: goodsList,
            conversionType: type
        })
    }
    componentDidUpdate(prevProps, prevState) { }
    awakenTips = (type) => {
        if (type == true) {
            this.setState({
                isModalVisible: true
            })
            setTimeout(() => {
                this.setState({
                    isActive: type
                })
            }, 300)
        } else {
            this.setState({
                isActive: type
            })
        }
    }
    getFormRef = el => {
        this.form = el;
    }
    checkedChange = (e, index) => {
        let { goodsList } = this.state
        goodsList.list[index].checked = e.target.checked
        this.setState({
            goodsList: goodsList
        })
    }
    sendInventory = () => {
        let { goodsList, BatteryInfo } = this.state;
        let incompleteInfo = 0;
        let checkedSum = 0;
        for (let index in goodsList.list) {
            if (goodsList.list[index].checked) {
                checkedSum += 1
            }
            if (goodsList.list[index].goodsDispatchingType) {
                if (BatteryInfo.length == 0) {
                    goodsList.list[index].isTable = true;
                    incompleteInfo = 0
                } else {
                    if (goodsList.list[index].hazardInfo) {
                        goodsList.list[index].isTable = false
                        incompleteInfo = incompleteInfo + 1

                    } else {
                        goodsList.list[index].isTable = true
                        incompleteInfo = 0
                    }
                }
            }
        }
        let arr1 = goodsList.list.filter((item, index) => item.goodsDispatchingType)
        if (incompleteInfo == 0) {
            this.setState({
                goodsList: goodsList
            })
        } else if (incompleteInfo == arr1.length) {
            if (checkedSum == 0) {
                this.updateGoodsDispatchingTypeAmazon()
            } else if (checkedSum == goodsList.list.length) {
                this.setState({
                    ModalText: '您已标记了所有商品准备清除，之后将返回库存列表，确定要清除这些商品吗？',
                    isModalVisibleTips: true,
                    isReturn: true
                })
            } else {
                this.setState({
                    ModalText: `您已标记了${checkedSum}件商品准备清除，确定要清除这些商品？`,
                    isModalVisibleTips: true
                })
            }
        }
        this.setState({
            goodsList: goodsList
        })
    }
    updateGoodsDispatchingTypeAmazon = () => {
        let { BatteryInfo, conversionType } = this.state;
        AJAX.fba.updateGoodsDispatchingTypeAmazon(BatteryInfo).then((res) => {
            if (res.code == 1) {
                if (conversionType == 0) {
                    this.props.history.push('/inventory')

                } else {
                    this.props.history.push('/deliverGoods')
                }
            }
        })

    }
    handleOkTips = () => {
        let { isReturn, goodsList } = this.state
        this.setState({
            isModalVisibleTips: false
        })
        if (isReturn) {
            this.props.history.push('/inventory')
        } else {
            for (let index in goodsList.list) {
                if (goodsList.list[index].checked) {
                    goodsList.list.splice(index, 1)
                }
            }
            sessionStorage.setItem('goodsList', JSON.stringify(goodsList))
            this.props.history.push('/deliverGoods')
        }
    }
    onChange = e => { };
    handleOk = () => {
        let { BatteryInfo, goodsId, goodsList } = this.state;
        // let content = this.form.getFieldsValue()
        this.form.validateFields().then((content) => {
            for (let items of goodsList.list) {
                if (items.goodsId == goodsId) {
                    items.hazardInfo = content;
                    items.isTable = false
                }
            }
            if (BatteryInfo.length == 0) {
                BatteryInfo.push({
                    goodsId: goodsId,
                    json: JSON.stringify(content)
                })

            } else {
                for (let index in BatteryInfo) {
                    if (BatteryInfo[index].goodsId == goodsId) {
                        BatteryInfo[index] = {
                            goodsId: goodsId,
                            json: JSON.stringify(content)
                        }
                    } else {
                        BatteryInfo.push({
                            goodsId: goodsId,
                            json: JSON.stringify(content)
                        })
                    }
                }
            }
            for (var i = 0; i < BatteryInfo.length; i++) {
                for (var j = i + 1; j < BatteryInfo.length; j++) {
                    if (BatteryInfo[i].goodsId == BatteryInfo[j].goodsId) {         //第一个等同于第二个，splice方法删除第二个
                        BatteryInfo.splice(j, 1);
                        j--;
                    }
                }
            }
            this.setState({
                isModalVisible: false,
                goodsList: goodsList
            })

        })
    }
    handleCancel = e => {
        this.setState({
            isModalVisible: false
        })
    }
    isshow = (e, item) => {
        let { BatteryInfo } = this.state
        let obj = null
        for (let i of BatteryInfo) {
            if (i.goodsId == item.goodsId) obj = JSON.parse(i.json)
        }
        setTimeout(() => {
            this.setState({
                goodsId: item.goodsId,
                isModalVisible: true,
                discountSteps: 1,
                isInfo: true,
            }, () => {
                setTimeout(() => {
                    if (obj) this.form.setFieldsValue(obj)
                }, 500)
            })
        }, 500)
        this.setState({
            isTourOpen: true,
        })
        setTimeout(() => {
            this.setState({
                isTourOpen: true,
            })
        }, 700)
    }
    handleChange = () => { }
    BatteryChange = (e) => {
        let content = this.form.getFieldsValue()
        if (content.battery == 1) {
            this.setState({
                isBatteryInfo: true
            })
        } else {
            this.setState({
                isBatteryInfo: false
            })
        }
    }
    hazardRegulationsChange = (e) => {
        let content = this.form.getFieldsValue()
        if (content.batterySpeInfo != 2) {
            this.setState({
                isHazardRegulations: true
            })
        } else {
            this.setState({
                isHazardRegulations: false
            })
        }
    }
    render() {
        let { discountSteps, isModalVisible, goodsList, dangerousGoodsList, isBatteryInfo, isActive, isModalVisibleTips, ModalText, isHazardRegulations, conversionType, isTourOpen, steps1 } = this.state
        return <div className="fbaConfirmBox">
            <Header />
            <div>
                <div className="end05_myBody">
                    {/* <!-- 头部 --> */}
                    <div>
                        <div className="tableBox fbaBox fbaConfirm">
                            <div className="fbaTitle">转换为“亚马逊配送”</div>
                            <p className="titles">为何我会看到此页面？</p>
                            <p className="desc">当您选择转换新商品信息或现有商品信息时，亚马逊物流要求您提供有关商品信息的更多信息，以确保合规性。</p>
                            <p className="titles">此表格适用于所有商品吗？</p>
                            <p className="desc">是的，您必须为下表中的所有商品提供更多信息。为了确保亚马逊遵守所有适用法规，您将需要提供商品信息，说明商品是否使用电池、是否被视为危险品、是否受运输、储存、废弃物或其他标准的管制。</p>
                            <p className="titles">什么类型的消费商品会被视为危险品？</p>
                            <p className="desc">危险品存在于多种消费品分类中，包括个护用品（如易燃香氛）、食品（例如含气溶胶的烹饪喷雾）、家居用品（如腐蚀性浴室清洁剂）以及使用电池的商品（如手机）。它不包括可能会以危险的方式使用的刀具、尖锐商品、重型商品或其他商品。</p>
                            <p>普通危险品的更多示例可以从<a> 此处 </a>中找到。</p>
                            <div className="a-section-expander-container fbaTablle">显示 0 个　SKU</div>
                            <table border="1" cellSpacing="0" cellPadding="0" bordercolor="#EEEEEE">
                                <thead>
                                    <tr>
                                        <th>卖家 SKU</th>
                                        <th>商品名称</th>
                                        <th>状况</th>
                                        <th>商品例外情况</th>
                                        <th>必填信息</th>
                                        <th style={{ width: '20px' }}>标记为删除</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {goodsList.list ? goodsList.list.map((item, index) => {
                                        return <tr key={index}>
                                            <td style={{ width: '5%' }}>{item.goodsSku}</td>
                                            <td className="text-ellipsis-3">{item.goodsName}</td>
                                            <td style={{ width: '5px' }}>{item.goodsCondition}</td>
                                            <td style={{ width: '20%' }}>
                                                {item.isTable ? <div>
                                                    <div className="reds">未填完表格。</div>
                                                    <div>请填写必要的信息表格。</div>
                                                </div> : null}
                                            </td>
                                            <td style={{ width: '25%', cursor: 'pointer' }}>
                                                {item.hazardInfo ? <div className="flex0" onClick={(e) => { this.isshow(e, item) }}>
                                                    <span>已完成 --</span>
                                                    <span style={{ color: '#004b91' }}>编辑信息</span>
                                                </div> : item.goodsDispatchingType ? <div className="flex0" onClick={(e) => { this.isshow(e, item) }}>
                                                    <img src={require("@/assets/img/home/addInfo.png").default}></img>
                                                    <span style={{ color: 'red' }}>添加危险信息</span>
                                                </div> : null}
                                            </td>
                                            <td style={{ width: '1%' }}>
                                                <Checkbox checked={item.checked} onChange={(e) => { this.checkedChange(e, index) }}></Checkbox>
                                            </td>
                                        </tr>
                                    }) : null}
                                </tbody>
                            </table>
                            <div className="returnclass">
                                <span className="returnBtn">
                                    <Link to="/inventory"><img className="kc_10_btn2" src={require("@/assets/img/home/btns.png").default} />返回</Link>
                                </span>
                                <div>
                                    <span className="sendOut" onClick={this.sendInventory}>
                                        {conversionType == 1 ? '转换并发送库存' : '保存并继续'}<img className="kc_10_btn2" src={require("@/assets/img/home/btns.png").default} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal title="需要的商品信息" className="fbaConfirmModal" destroyOnClose={true} cancelText="取消" okText="确认" visible={isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
                <div>
                    <div className="request-info-popup-desc">
                        <p className="desc">为确保所有商品的合规性，亚马逊物流要求您在转换新的或现有商品时提供更多商品相关信息。</p>
                        <ul className="desc">
                            <li>您需要为电池、包含电池的商品或可能被视为危险品（也称为“危险物质”）的任何商品提供相关信息。危险品存在于多种消费品分类中，包括个护用品（如易燃香氛）、食品（例如含气溶胶的烹饪喷雾）和家居用品（如腐蚀性浴室清洁剂）。
                                <span className="request-info-popup-help-link">请查看可能属于受管制危险品的亚马逊商品示例。</span>
                            </li>
                            <li>如果您需要制造商提供信息，请<span>下载此调查表</span>并将其发送给制造商。注意：您必须继续在该页面提交答案。</li>
                            <li>要了解更多信息，请参阅<span>危险品简介</span>或<span>危险品识别指南</span>。</li>
                        </ul>
                    </div>
                    <div className="request-info-popup-skudata">
                        2pcs Easter Gnome Bunny with Easter Egg, Handmade Plush Easter Faceless Ornaments Holding Egg, Bunny Gnomes Ornaments, Easter Desktop Bunny Easter Gnome, Indoor Spring Decor - Rabbit Ears<br />
                        状况: 新品<br />
                        卖家 SKU: zhangnan-20210305-GM<br />
                        ASIN: B08X1M6TXR
                    </div>
                    <div className="request-info-required-responses"> * 需要回应</div>
                    <div className="proInfoBox">
                        <Form ref={this.getFormRef}>
                            <div className="proInfo">
                                <div className="proInfoText">
                                    <p>电池信息*</p>
                                    <div>内部安装的不可更换的可充电电池（如笔记本电脑电池）也视为电池。</div>
                                </div>
                                <div>
                                    <Form.Item name="battery" rules={[{ required: true, message: '请选择' }]}>
                                        <Radio.Group onChange={this.BatteryChange} >
                                            <Radio value={1}>是</Radio>
                                            <Radio value={2}>否</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            </div>
                            {isBatteryInfo ? <><div className="proInfo">
                                <div className="proInfoText">
                                    <p>电池如何在商品上使用？*</p>
                                </div>
                                <div>
                                    <Form.Item name="batteryUsage" initialValue={'商品是电池'} rules={[{ required: true, message: '请选择' }]}>
                                        <Select defaultValue="商品是电池" getPopupContainer={triggerNode => triggerNode.parentElement} style={{ width: 220 }} onChange={this.handleChange}>
                                            <Option value="商品是电池">商品是电池</Option>
                                            <Option value="商品需要安装电池，电池随附在包装中">商品需要安装电池，电池随附在包装中</Option>
                                            <Option value="商品需要安装电池，电池包含在商品中">商品需要安装电池，电池包含在商品中</Option>
                                            <Option value="商品需要安装电池，电池单独销售">商品需要安装电池，电池单独销售</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div></> : null}
                            <div className="proInfo">
                                <div className="proInfoText">
                                    <p>商品法规信息*</p>
                                    <div>此商品是否属于在运输、废弃处置和/或储存方面受到管制的危险品/危险物质？</div>
                                </div>
                                <div>
                                    <Form.Item name="batterySpeInfo" rules={[{ required: true, message: '请选择' }]}>
                                        <Radio.Group onChange={this.hazardRegulationsChange}>
                                            <Radio value={1}>是</Radio>
                                            <Radio value={2}>否</Radio>
                                            <Radio value={3}>不确定</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            </div>
                            {isHazardRegulations ? <><div className="proInfo">
                                <div className="proInfoText">
                                    <p>请说明危险品类型或适用于商品的危险品法规*</p>
                                    <p>(您可以选择最大值 5)</p>
                                </div>
                                <div style={{ width: '20%' }}>
                                    <Form.Item name="dangerousGoodsType" rules={[{ required: true, message: '请选择' }]}>
                                        <Checkbox.Group style={{ width: '100%' }}>
                                            <Row>
                                                <Col span={24}>
                                                    <Checkbox value="储存">储存</Checkbox>
                                                </Col>
                                                <Col span={24}>
                                                    <Checkbox value="废弃处理">废弃处理</Checkbox>
                                                </Col>
                                                <Col span={24}>
                                                    <Checkbox value="运输">运输</Checkbox>
                                                </Col>
                                                <Col span={24}>
                                                    <Checkbox value="GHS">GHS</Checkbox>
                                                </Col>
                                                <Col span={24}>
                                                    <Checkbox value="其他">其他</Checkbox>
                                                </Col>
                                            </Row>
                                        </Checkbox.Group>
                                    </Form.Item>
                                </div>
                            </div>
                                <div className="proInfo">
                                    <div className="proInfoText">
                                        <p>联合国危险货物编号*</p>
                                    </div>
                                    <div>
                                        <Form.Item name="CargoNumber" rules={[{ required: true, message: '请输入' }]}>
                                            <Input placeholder="例如 UN1950" />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="proInfo">
                                    <div className="proInfoText">
                                        <p>安全数据表 (SDS) URL</p>
                                    </div>
                                    <div>
                                        <Form.Item name="securityUrl">
                                            <Input placeholder="例如 https://" />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="proInfo">
                                    <div className="proInfoText">
                                        <p>商品重量（千克）*</p>
                                        <p>或</p>
                                        <p>商品容积（毫升）</p>
                                    </div>
                                    <div>
                                        <div className="flex0">
                                            <Form.Item name="weight" rules={[{ required: true, message: '请输入' }]}>
                                                <Input placeholder="例如 5" type="number" maxLength="10" />
                                            </Form.Item>
                                            <span>千克</span>
                                        </div>
                                        <div></div>
                                        <div className="flex0">
                                            <Form.Item name="milliliter" rules={[{ required: true, message: '请输入' }]}>
                                                <Input placeholder="例如 1000" type="number" maxLength="10" />
                                            </Form.Item>
                                            <span>毫升</span>
                                        </div>

                                    </div>
                                </div>
                                <div className="proInfo">
                                    <div className="proInfoText">
                                        <p>闪点 (°C)？</p>
                                    </div>
                                    <div className="flex0">
                                        <Form.Item name="flashPoint">
                                            <Input placeholder="例如 90" type="number" maxLength="5" />
                                        </Form.Item>
                                        <span>°C</span>
                                    </div>
                                </div>
                                <div className="dangerousGoodsBox">
                                    <div className="proInfoText">
                                        <p>请说明危险品类型或适用于商品的危险品法规*</p>
                                        <p>(您可以选择最大值 5)</p>
                                    </div>
                                    <div className="dangerousGoodsContent">
                                        <Form.Item name="hazardRegulations" rules={[{ required: true, message: '请选择' }]}>
                                            <Checkbox.Group style={{ width: '100%' }}>
                                                <Row>
                                                    {dangerousGoodsList.map((item, index) => {
                                                        return <Col key={index} span={6}>
                                                            <Checkbox value={item.value}>{item.value}</Checkbox>
                                                            <div><img src={require("@/assets/img/home/zysx.jpg").default} /></div>
                                                        </Col>
                                                    })}
                                                </Row>
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </div>
                                </div>
                            </> : null}
                        </Form>
                    </div>
                    <div className="request-info-required-responses"> * 需要回应</div>
                    <div className="request-info-warning">请确保您的选择是正确的。有关危险品安全的更多信息，请前往危险品识别指南。</div>
                </div>
            </Modal>
            <Modal title="提示" visible={isModalVisibleTips} onOk={this.handleOkTips} onCancel={() => { this.setState({ isModalVisibleTips: false }) }} okText="确定" cancelText="取消">
                <p>{ModalText}</p>
            </Modal>
            <Footer />
            {/* 引导步骤 */}
            <TipsIcon clicks={this.awakenTips} />
            <Reactours routePath="fbaConfirm" isActive={isActive} clicks={this.awakenTips} discountStep={discountSteps} steps={steps1} isOpen={isTourOpen} />
        </div>
    }
    // )
    // }
}