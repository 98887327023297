import { useCallback } from "react";
import { Radio, Tooltip } from "antd";
import ARadio from "@a-components/ARadio";
import { InfoCircleOutlined } from "@ant-design/icons";
import withProps from "@a-components/withProps";
import "./style.css";

const RadioGroup = ({
  options = [],
  radioStyle = { display: "block" },
  children,
  ...otherProps
}) => {
  const renderTooltip = useCallback((tooltip) => {
    if (!tooltip) return null;
    return (
      <Tooltip title={tooltip} placement="right">
        <InfoCircleOutlined />
      </Tooltip>
    );
  }, []);

  return (
    <Radio.Group name="radiogroup" {...otherProps}>
      {options.map((item) => {
        return (
          <ARadio
            value={item.value}
            key={item.value}
            style={radioStyle}
            description={item.description}
            extra={item.extra}
            onFocus={otherProps.onFocus}
          >
            {item.label} &nbsp; {renderTooltip(item.tooltip)}
          </ARadio>
        );
      })}

      {children}
    </Radio.Group>
  );
};

export default withProps(RadioGroup, {
  className: "a-radio-group",
});
