import React from 'react'
import './TreasuryPlan.css'
import '../../view/default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'

function TreasuryPlan(){
    return(
        <div>
            <Header/>

            <div>


            <div className="kc14_header-nav">
			<ul>
				<li>
					<a href="javascript:;">Send to Amazon</a>
					<span>新功能</span>
				</li>
				<li>
					<a href="javascript:;">货件处理进度</a>
				</li>
				<li>
					<a href="javascript:;">入库问题一览</a>
				</li>
				<li>
					<a href="javascript:;">扫描和贴标</a>
				</li>
				<li>
					<a href="javascript:;">上传入库计划</a>
				</li>
			</ul>
		</div>

        <div className="kc14_myBody">
			<div className="kc14_content">
				<h2>上传入库计划文件</h2>
				<ul>
					<li>
						<a href="javascript:;">创建入库计划</a>
					</li>
					<li>
						<a href="javascript:;">更新入库计划</a>
					</li>
				</ul>
				<div className="kc14_c-con">
					<h3>1.kc_14_准备您的更新入库计划文件</h3>
					<div>
						<p>使用新的创建入库计划文件模板来准备您的创建入库计划文件·点击该按钮下载模板。<a href="javascript:;">了解更多信息</a>，<br />更新:无法完成包含多于2000个卖家SKU的入库计划。</p>
						<p>注意:如果您之前使用旧版的"创建入库计划模版"·请通过以下链接下载并使用适用于新'"配送创建工作流程“的新模板文件。</p>
						<button type="button">下载模板</button>
					</div>
				</div>
				<div className="kc14_c-con">
					<h3>2.kc_14_上传您的更新入库计划文件</h3>
					<ul>
						<li>上传您从创建入库计划文件模板创建的、以制表符分隔的创建入库计划文件。</li>
						<li>
							<div>查找要上传的文件位置:</div>
							<div>
								<button type="button">选择文件</button>
								<span>没有选择文件</span>
							</div>
						</li>
						<li>
							<button type="button">立即上传</button>
						</li>
					</ul>
				</div>
				<div className="kc14_c-con">
					<h3>3.kc_14_检查文件状态和历史记录
						<button type="button">刷新</button>
					</h3>
					<ul>
						<li>查看您最近10次的上传状态。如果处理报告出现任何错误，请修改您的创建入库计划文件，并返回上述步骤2。<a href="javascript:;">了能更多信息，</a></li>
					</ul>
					<p>您尚未上传任何文件。</p>
				</div>
			</div>
		</div>


            </div>


            <Footer/>
        </div>
    )
}

export default TreasuryPlan