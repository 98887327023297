import React from "react"
import AJAX from "api/apis";
import "./Tab5-banner.css"
import BannerHeader from "../Banner-header";
import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"
import { Upload, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

class Tab5Banner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                loading: "",
                imageUrl: [],
                getImgList: [],
                list: [1, 2, 3, 4, 5, 6, 7, 8, 9],
                isGuide: false
            },
            isTourOpen: true,
            steps1: [
                {
                    selector: '.tab5-suggest-uploading-box',
                    content: '除特殊类目产品外，主图必须白底，不能出现与此产品无关的信息(如:不能出现logo,文字，其他无关商品等),建议图片像素≥1000*1000，上传的图片必须要在对应流程中下载，操作资料第一张为主图',
                }
            ],
            isActive: false
        }
    }
    componentDidMount() {
        if (this.props.goodsId) {
            this.getGoodsImg(this.props.goodsId)
        }
    }
    awakenTips = (type) => {
        this.setState({
            isActive: type
        })
    }
    getGoodsImg = (goodsId) => {
        AJAX.addProduct.getGoodsImg({ goodsId }).then((res) => {
            let arrImg = []

            for (let items of res.data) {
                arrImg[Number(items.goodsIndex) - 1] = items.goodsImgUrl
            }
            this.setState({
                data: {
                    ...this.state.data,
                    imageUrl: arrImg,
                    getImgList: res.data
                }
            })
        })
    }
    setGoodsImg = (imgArrs) => {
        AJAX.addProduct.setGoodsImg(imgArrs).then((res) => {
            console.log(res, "res")
        })
    }
    getFormRef = el => {
        this.form = el;
    }
    Switchse = (e) => {
        this.props.Switchse(e)
    }
    beforeUpload = (file, index) => {
        let { imageUrl, getImgList } = this.state.data
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('上传的格式不正确');
            return false;
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.error('上传图片大小不能超过5MB');
            return false;
        }
        AJAX.file.upload(file).then((res) => {
            imageUrl[index] = res.data[0].url
            this.setState({
                data: {
                    ...this.state.data,
                    imageUrl: imageUrl
                }
            })
            if (this.props.goodsId) {
                let imgArrs = []
                let obj = {}
                if (getImgList.length > 0) {
                    for (let items of getImgList) {
                        if (items.goodsIndex == Number(index) + 1) {
                            obj.goodsIndex = items.goodsIndex
                            obj.goodsImgUrl = res.data[0].url
                            obj.goodsId = this.props.goodsId
                            obj.id = items.id
                        } else {
                            obj.goodsIndex = Number(index) + 1
                            obj.goodsImgUrl = res.data[0].url
                            obj.goodsId = this.props.goodsId
                        }
                    }
                } else {
                    obj.goodsIndex = Number(index) + 1
                    obj.goodsImgUrl = res.data[0].url
                    obj.goodsId = this.props.goodsId
                }
                imgArrs.push(obj)
                this.setGoodsImg(imgArrs)
            } else {
                this.props.submitData(imageUrl, 5)
            }
        }).catch((err) => {
            console.log(err, "err")
        })
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            isSenior: nextProps.isSenior
        })
    }
    render() {
        let { data, isGuide, steps1, isTourOpen, isActive } = this.state
        return <div className="tab5-box">
            {/* 引导步骤 */}
            <TipsIcon clicks={this.awakenTips} />
            <Reactours routePath="Tab5-banner" isActive={isActive} clicks={this.awakenTips} steps={steps1} isOpen={isTourOpen} />
            <BannerHeader checked={this.props.isSenior} id={this.props.id} Switchse={this.Switchse} />
            <div className="tab5-suggest">
                <p className="tab5-suggest-img">您的图片建议</p>
                <p className="tab5-uploading">上传您的商品图片建议</p>
                <h5>查看图片指南
                    <div onClick={() => { this.setState({ isGuide: !isGuide }) }}>
                        {!isGuide ? <svg t="1620295636101" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7365" width="16" height="16"><path d="M326.714 950.611l415.897-415.897c6.271-6.271 9.399-14.495 9.386-22.714 0.013-8.219-3.115-16.443-9.386-22.714L326.714 73.389c-12.516-12.516-32.809-12.516-45.325 0-5.712 5.712-8.808 13.044-9.307 20.516-0.048 0.67-0.082 1.36-0.082 2.095v832c0 0.735 0.034 1.425 0.083 2.095 0.499 7.473 3.595 14.804 9.307 20.516 12.515 12.516 32.808 12.516 45.324 0z" p-id="7366"></path></svg>
                            : <svg t="1620295751970" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8372" width="18" height="18"><path d="M950.611 326.714L534.714 742.611c-6.271 6.271-14.495 9.399-22.714 9.386-8.219 0.013-16.443-3.115-22.714-9.386L73.389 326.714c-12.516-12.516-12.516-32.809 0-45.325 5.712-5.712 13.044-8.808 20.516-9.307 0.67-0.048 1.36-0.082 2.095-0.082h832c0.735 0 1.425 0.034 2.095 0.083 7.473 0.499 14.804 3.595 20.516 9.307 12.516 12.515 12.516 32.808 0 45.324z" p-id="8373"></path></svg>}
                    </div>
                </h5>
                {isGuide ? <div className="guideBox">
                    <p className="styleGuide" style={{ color: '#008296' }}>产品图像风格指南</p>
                    <p></p>
                    <p>缺少主图片的商品将不会出现在搜索或浏览中，除非您为商品添加了主图片。</p>
                    <p>请选择清晰、信息丰富且引人注目的图片。</p>
                    <p>图片必须满足以下要求：</p>
                    <ul>
                        <li>商品必须至少填满 85% 的图片。图片必须仅显示要出售的商品，可包含部分或不能包含其他道具，不能包含商标、水印或内嵌图片。图片包含的文本内容只能是商品信息。</li>
                        <li>主图片必须为背景是纯白色的商品照片（而不是画出来的图），且不能包含其他搭配品。</li>
                        <li>图片缩放时最长边必须在 1000 像素以上，最短边必须在 500 像素以上。</li>
                        <li>图片最长边不能超过 10000 像素。</li>
                        <li>首选 JPEG 格式的图片，但您也可使用 TIFF 和 GIF 格式的图片。</li>
                    </ul>
                </div> : null}
            </div>

            <div className="tab5-suggest-uploading-box">
                {
                    data.list.map((item, index) => {
                        return (
                            <Upload key={item}
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action=""
                                beforeUpload={(file) => { this.beforeUpload(file, index); return false; }}
                            >
                                {data.imageUrl[index] ? <img src={data.imageUrl[index]} alt="avatar" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }} /> : <div><PlusOutlined /><div>上传</div></div>}
                            </Upload>
                        )
                    })
                }
            </div>
        </div>
    }
}

export default Tab5Banner