import { Descriptions } from "antd";
import Apis from "@api/apis";
import { useCallback, useEffect, useState, useRef } from "react";
import {
  AInput,
  ASelect,
  ASwitch,
  AButton,
  ADatePicker,
  AInputMoney,
  AText,
  ARadioGroup,
  AInputRate,
} from "@a-components";
import { Space } from "antd";
import NumberPrecision from "@utils/NumberPrecision";
import moment from "moment";

const advertisingStatusMapping = {
  1: "有效",
  2: "已暂停",
  3: "已存档",
  4: "仅剩存档未完成 ",
  5: "目标页面不可用",
};

const radioOptions = [
  {
    label: "动态竞价-只降低",
    value: 1,
    description: "当您的广告不太可能带来销售时，我们将实时降低您的竞价。",
  },
  {
    label: "动态竞价-提高和降低",
    value: 2,
    description:
      "当您的广告很有可能带来销售时，我们将实时提高您的竞价（最高可达 100%），并在您的广告不太可能带来销售时降低您的竞价。",
    tooltip: "历史上基于相同广告支出促成更多销售的竞价策略。",
  },
  {
    label: "固定竞价",
    value: 3,
    description:
      "我们将使用您的确切竞价和您设置的任何手动调整，而不会根据售出可能性对您的竞价进行更改。",
  },
];

const AdActivitySetting = ({ advertisingId, onAdvertisingUpdated }) => {
  const [detail, setDetail] = useState({});
  const [combinationList, setCombinationList] = useState([]);

  // 已归档
  const isDisabled = detail.advertisingStatus === 3;

  const createResponseFormat = useCallback((value) => {
    return (res) => {
      if (res.code === 1) {
        setDetail((pre) => {
          return {
            ...pre,
            ...value,
          };
        });
        onAdvertisingUpdated?.();
        return { type: "success" };
      }
      return {
        type: "fail",
      };
    };
  }, [onAdvertisingUpdated]);

  const queryAdvertisingDetailSet = useCallback(() => {
    return Apis.advertising
      .getAdvertisingDetailSet({ advertisingId })
      .then((res) => {
        if (res.code !== 1) return res;

        setDetail(res.data);
      });
  }, [advertisingId]);

  useEffect(() => {
    queryAdvertisingDetailSet();
  }, [queryAdvertisingDetailSet]);

  /* ******************************* 广告活动名称 - begin ******************************* */

  const updateAdvertisingName = useCallback(
    (advertisingName) => {
      return Apis.advertising
        .updateAdvertisingName({
          advertisingId,
          advertisingName,
        })
        .then(createResponseFormat({ advertisingName }));
    },
    [advertisingId, createResponseFormat]
  );
  /* ******************************* 广告活动名称 - end ******************************* */

  /* ******************************* 广告组合 - begin ******************************* */
  const queryCombinationList = useCallback(() => {
    return Apis.advertisingCombination.getOption().then((res) => {
      setCombinationList(res.data);
    });
  }, []);

  useEffect(() => {
    queryCombinationList();
  }, [queryCombinationList]);

  const updateAdvertisingCombinationOption = useCallback(
    (advertisingCombinationId) => {
      return Apis.advertising
        .updateAdvertisingCombinationOption({
          advertisingIds: advertisingId,
          advertisingCombinationId,
        })
        .then(createResponseFormat({ advertisingCombinationId }));
    },
    [advertisingId, createResponseFormat]
  );
  /* ******************************* 广告组合 - end ******************************* */

  /* ******************************* 广告状态 - begin ******************************* */

  const updateAdvertisingStatus = useCallback(
    (advertisingStatus) => {
      return Apis.advertising
        .updateAdvertisingStatus({
          advertisingIds: advertisingId,
          advertisingStatus,
        })
        .then(createResponseFormat({ advertisingStatus }));
    },
    [advertisingId, createResponseFormat]
  );
  /* ******************************* 广告状态 - end ******************************* */

  /* ******************************* 广告安排 - begin ******************************* */

  const endTimeEl = useRef(null);

  const updateAdvertisingEndTime = useCallback(
    (time) => {
      const endTime = time ? moment(time).format("YYYY-MM-DD") : null;

      return Apis.advertising
        .updateAdvertisingEndTime({
          endTime,
          advertisingIds: advertisingId,
        })
        .then(createResponseFormat({ endTime }));
    },
    [advertisingId, createResponseFormat]
  );

  const handleSetNoEndTime = useCallback(() => {
    endTimeEl.current?.blur();
    updateAdvertisingEndTime(null);
  }, [updateAdvertisingEndTime]);
  /* ******************************* 广告安排 - end ******************************* */

  /* ******************************* 广告活动预算 - begin ******************************* */
  const updateAdvertisingBudget = useCallback(
    (dailyBudgetPrice) => {
      return Apis.advertising
        .updateAdvertisingBudget({
          adjustmentType: 3,
          advertisingIds: advertisingId,
          dailyBudgetPrice,
        })
        .then(createResponseFormat({ dailyBudgetPrice }));
    },
    [advertisingId, createResponseFormat]
  );
  /* ******************************* 广告活动预算 - end ******************************* */

  /* ******************************* 更新竞价策略 - begin ******************************* */
  const updateAdvertisingBiddingType = useCallback(
    (biddingType) => {
      return Apis.advertising
        .updateAdvertisingBiddingType({ biddingType, advertisingId })
        .then(createResponseFormat({ biddingType }));
    },
    [advertisingId, createResponseFormat]
  );
  /* ******************************* 更新竞价策略 - end ******************************* */

  /* ******************************* 用展示位置调整出价（替换竞价+） - begin ******************************* */
  const updateAdvertisingBiddingPriceGoodsRate = useCallback(
    (commodityPage) => {
      return Apis.advertising
        .updateAdvertisingBiddingPriceGoodsRate({
          advertisingId,
          commodityPage,
        })
        .then(createResponseFormat({ commodityPage }));
    },
    [advertisingId, createResponseFormat]
  );
  const updateAdvertisingBiddingPriceSearchTopRateReq = useCallback(
    (searchTopProportion) => {
      return Apis.advertising
        .updateAdvertisingBiddingPriceSearchTopRateReq({
          advertisingId,
          searchTopProportion,
        })
        .then(createResponseFormat({ searchTopProportion }));
    },
    [advertisingId, createResponseFormat]
  );
  /* ******************************* 用展示位置调整出价（替换竞价+） - end ******************************* */

  return (
    <div>
      <Descriptions bordered column={1}>
        <Descriptions.Item label="广告活动名称" labelStyle={{ width: 412 }}>
          <AInput
            disabled={isDisabled}
            confirmMode
            value={detail.advertisingName}
            style={{ width: 174 }}
            $Sure={updateAdvertisingName}
          />
        </Descriptions.Item>
        <Descriptions.Item label="广告活动编号">
          {detail.advertisingNum}
        </Descriptions.Item>
        <Descriptions.Item label="广告组合">
          <ASelect
            disabled={isDisabled}
            confirmMode
            value={detail.advertisingCombinationId}
            options={combinationList}
            style={{ width: 174 }}
            placeholder="请选择"
            $Sure={updateAdvertisingCombinationOption}
          />
        </Descriptions.Item>
        <Descriptions.Item label="类型">
          {detail.advertisingTypeName}
        </Descriptions.Item>
        <Descriptions.Item label="有效/已暂停">
          <Space>
            <ASwitch
              disabled={isDisabled}
              checked={detail.advertisingStatus === 1}
              onChange={(checked) => updateAdvertisingStatus(checked ? 1 : 2)}
            />
            {advertisingStatusMapping[detail.advertisingStatus]}
          </Space>
          <br />
          <AButton
            disabled={isDisabled}
            type="link"
            className="p-0"
            onClick={() => updateAdvertisingStatus(3)}
          >
            存档此广告活动
          </AButton>
        </Descriptions.Item>
        <Descriptions.Item label="状态">
          {detail.advertisingStatusName}
        </Descriptions.Item>
        <Descriptions.Item label="安排">
          <Space>
            <ADatePicker
              showToday={false}
              disabled
              value={moment(detail.startTime)}
            />
            -
            <ADatePicker
              ref={endTimeEl}
              disabled={isDisabled}
              showToday={false}
              onChange={updateAdvertisingEndTime}
              disabledDate={(current) => {
                if (!current) return false;
                return (
                  current.endOf("day") < moment(detail.startTime).endOf("day")
                );
              }}
              placeholder="无结束日期"
              renderExtraFooter={() => (
                <div className="df ai-c jc-c">
                  <AButton type="link" onClick={handleSetNoEndTime}>
                    无结束日期
                  </AButton>
                </div>
              )}
              value={detail.endTime ? moment(detail.endTime) : null}
            />
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="预算">
          <AInputMoney
            confirmMode
            disabled={isDisabled}
            placeholder="请输入预算"
            value={detail.dailyBudgetPrice}
            $Sure={updateAdvertisingBudget}
          />
          <AText className="fs-12 c-999">每天</AText>
        </Descriptions.Item>
        <Descriptions.Item label="广告活动投放">
          {detail.positioningName}
        </Descriptions.Item>
        <Descriptions.Item label="广告活动的竞价策略">
          <ARadioGroup
            confirmMode
            disabled={isDisabled}
            options={radioOptions}
            value={detail.biddingType}
            $Sure={updateAdvertisingBiddingType}
          ></ARadioGroup>
        </Descriptions.Item>
        <Descriptions.Item label="用展示位置调整出价（替换竞价+）">
          <div>
            <AText className="c-999">
              除了您的竞价策略外，您还可以将出价提高高达900%。
            </AText>
            <AButton type="link" className="p-0 h-14">
              進一步瞭解
            </AButton>
          </div>

          <div className="mt-12 df">
            <AText className="f-1">搜索结果顶部（首页）</AText>
            <div className="df fd-c f-3">
              <AInputRate
                confirmMode
                $Sure={updateAdvertisingBiddingPriceSearchTopRateReq}
                disabled={isDisabled}
                value={detail.searchTopProportion}
              />
              <AText className="fs-12 c-999">
                示例： 对于此广告位，$1.00 竞价将保持 $
                {NumberPrecision(detail.searchTopProportion || 0)
                  .divide(100)
                  .plus(1)
                  .valueOf()
                  .toFixed(2)}{" "}
                不变。
              </AText>
            </div>
          </div>
          <div className="mt-12 df">
            <AText className="f-1">商品页面</AText>
            <div className="df fd-c f-3">
              <AInputRate
                confirmMode
                $Sure={updateAdvertisingBiddingPriceGoodsRate}
                disabled={isDisabled}
                value={detail.commodityPage}
              />
              <AText className="fs-12 c-999">
                示例： 对于此广告位，$1.00 竞价将保持 $
                {NumberPrecision(detail.commodityPage || 0)
                  .divide(100)
                  .plus(1)
                  .valueOf()
                  .toFixed(2)}{" "}
                不变。
              </AText>
            </div>
          </div>

          {/* {detail.searchTopProportion}
          {detail.commodityPage} */}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default AdActivitySetting;
