import React from "react";
import "./addTemplate.css";
import IconFont from "../../components/Icon/Icon";
import { Radio, Switch, Checkbox, Input, Divider } from 'antd';
import { Link } from 'react-router-dom'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import { DownOutlined } from '@ant-design/icons';
import Reactours from "components/reactours";
import TipsIcon from "components/tipsIcon";
class addTemplate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isModalVisible: false,
            isActive: false,
            isTourOpen: true,
            steps1: [
                {
                    selector: '.templateName',
                    content: '设置配送模板名称',
                },
                {
                    selector: '.addTemplateBox-radioBox',
                    content: '选择配送模型，每件商品/基于重量或者选择商品价格分段式配送',
                },
                {
                    selector: '.templateTableBox .ant-switch',
                    content: '可选择是否配送区域自动化',
                },
                {
                    selector: '.youzheng-type',
                    content: '选择地址类型',
                },
                {
                    selector: '.yunshu-time',
                    content: '选择运输时间，运输时间不包含处理时间',
                },
                {
                    selector: '.mianfei',
                    content: '选择免运费费用',
                },
                {
                    selector: '.a-section-expander-container .jiaji',
                    content: '是否选择其他配送方式',
                },
                {
                    selector: '.baochun',
                    content: '设置完毕之后点击保存',
                },
            ],
            routePath: "addTemplate"
        }
    }
    onChange(checked) {
    }
    awakenTips = (type) => {
        this.setState({ isActive: type })
    }
    render() {
        const { isActive, steps1, isTourOpen, routePath } = this.state
        return (
            <div className="addTemplateBox" style={{ paddingBottom: "100px" }}>
                <Header />
                <div>
                    <div className="fz26 addTemplateTitle">创建新的配送模板<span className="fz13 c46">观看演示<DownOutlined /></span></div>
                    <div className="templateTableBox">
                        <div className="leftTips">
                            <p>配送模板名称:</p>
                            {/* <a className="c46">了解更多信息</a> */}
                        </div>
                        <input className="templateName" value="美国模板" />
                    </div>
                    <div className="templateTableBox">
                        <div className="leftTips">
                            <p>费用模型:</p>
                            <a className="c46">了解更多信息</a>
                        </div>
                        <div className="addTemplateBox-radioBox">
                            <div className="radioBox">
                                <input type="radio" name="templateTable" />
                                <div>
                                    <p className="bolds">每件商品/基于重量</p>
                                    <p>运费包含每个商品或每磅的运费以及每个货件的固定费用。</p>
                                </div>
                            </div>
                            <div className="radioBox">
                                <input type="radio" name="templateTable" />
                                <div>
                                    <p className="bolds">商品价格分段式配送</p>
                                    <p>运费由订单总金额决定。</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="templateTableBox">
                        <div className="leftTips">
                            <p>配送区域自动化:</p>
                            <a className="c46">了解更多信息</a>
                        </div>
                        <Switch defaultChecked onChange={this.onChange} />
                    </div>
                    <div className="templateTableBox">
                        <div className="leftTips">
                            <p>配送选项、地区和运费:</p>
                            <a className="c46">了解更多信息</a>
                        </div>
                        <div>
                            <div className="rightBox">
                                <div className="rightTitle">
                                    <p className="fz24 bolds">国内配送</p>
                                </div>
                                <div className="tableBox">
                                    <div className="a-section-expander-container">
                                        <Checkbox checked className="leftTitle" onChange={this.onChange}> 免费经济型 </Checkbox>
                                    </div>
                                    <div className="a-section-expander-container">
                                        <span className="requirement"> 要求</span>
                                        <span>需要配送至美国大陆的所有州和街道地址 (不包括夏威夷、阿拉斯加和美属保护地)。</span>
                                    </div>
                                    <table border="1" cellspacing="0" cellpadding="0" bordercolor="#EEEEEE">
                                        <tr>
                                            <th>地区</th>
                                            <th>地址类型</th>
                                            <th>
                                                <div>运输时间</div>
                                                <div>不包括处理时间</div>
                                            </th>
                                            <th>运输费用</th>
                                            <th>操作</th>
                                        </tr>
                                        <tr className="coupons1">
                                            <td>缅因州</td>
                                            <td className="youzheng-type">
                                                <p>
                                                    <Checkbox disabled checked onChange={this.onChange}> 街道 </Checkbox>
                                                </p>
                                                <p>
                                                    <Checkbox checked onChange={this.onChange}>  邮政信箱 </Checkbox>
                                                </p>
                                            </td>
                                            <td className="yunshu-time">
                                                <select>
                                                    <option>3 - 5 天</option>
                                                    <option>5 - 8 天</option>
                                                </select>
                                            </td>
                                            <td className="mianfei">免运费</td>
                                            <td></td>
                                        </tr>
                                        <tr className="coupons1">
                                            <td>缅因州, 佛蒙特州, 新罕布什尔州, 马萨诸塞州, 康涅狄格州, 罗德岛州, 纽约州, 宾夕法尼亚州, 哥伦比亚特区, 新泽西州, 北达科他州, 南达科塔州, 内布拉斯加州, 堪萨斯州, 明尼苏达州, 爱荷华州, 威斯康辛州, 伊利诺伊州, 密苏里州, 印第安纳州, 俄亥俄州, 密歇根州, 俄克拉荷马州, 德克萨斯州, 西佛吉尼亚州, 弗吉尼亚州, 特拉华州, 肯塔基州, 田纳西州, 北卡罗来纳州, 南卡罗来纳州, 乔治亚州, 阿拉巴马州, 密西西比州, 佛罗里达州, 路易斯安那州, 阿肯色州, 马里兰州, 亚利桑那州, 蒙大拿州, 爱达荷州, 怀俄明州, 科罗拉多州, 犹他州, 内华达州, 俄勒冈州, 华盛顿州, 新墨西哥州</td>
                                            <td>
                                                <p>
                                                    <Checkbox disabled checked onChange={this.onChange}> 街道 </Checkbox>
                                                </p>
                                                <p>
                                                    <Checkbox checked onChange={this.onChange}>  邮政信箱 </Checkbox>
                                                </p>
                                            </td>
                                            <td>
                                                <select>
                                                    <option>3 - 5 天</option>
                                                    <option>5 - 8 天</option>
                                                </select>
                                            </td>
                                            <td>免运费</td>
                                            <td></td>
                                        </tr>
                                        <tr className="coupons1">
                                            <td colSpan="5">
                                                <div className="addRule">+ 添加配送规则</div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="tableBox">
                                    <div className="a-section-expander-container">
                                        <Checkbox disabled checked className="leftTitle jiaji" onChange={this.onChange}> 标准配送 </Checkbox>
                                    </div>
                                    <div className="a-section-expander-container">
                                        <span className="requirement"> 要求</span>
                                        <span>配送至美国大陆的所有州（夏威夷、阿拉斯加和美国保护国/地区除外）的运费必须相同。</span>
                                    </div>
                                    <table border="1" cellspacing="0" cellpadding="0" bordercolor="#EEEEEE">
                                        <tr>
                                            <th>地区</th>
                                            <th>地址类型</th>
                                            <th>
                                                <div>运输时间</div>
                                                <div>不包括处理时间</div>
                                            </th>
                                            <th>运输费用</th>
                                            <th>操作</th>
                                        </tr>
                                        <tr className="coupons1">
                                            <td>
                                                <div className="flex0">

                                                    <span>缅因州</span>
                                                    <span>编辑</span>
                                                </div>
                                            </td>
                                            <td>
                                                <p>
                                                    <Checkbox disabled checked onChange={this.onChange}> 街道 </Checkbox>
                                                </p>
                                                <p>
                                                    <Checkbox checked onChange={this.onChange}>  邮政信箱 </Checkbox>
                                                </p>
                                            </td>
                                            <td>
                                                <select>
                                                    <option>3 - 5 天</option>
                                                    <option>5 - 8 天</option>
                                                </select>
                                            </td>
                                            <td>
                                                <div>
                                                    <Input prefix="$" value="0.00" />每订单
                                                    <Divider>加</Divider>
                                                    <Input prefix="$" value="0.00" />每
                                                    <select>
                                                        <option>商品</option>
                                                        <option>LB</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td className="cc0">删除</td>
                                        </tr>
                                        <tr className="coupons1">
                                            <td>
                                                <div className="flex0">
                                                    <span>缅因州, 佛蒙特州, 新罕布什尔州, 马萨诸塞州, 康涅狄格州, 罗德岛州, 纽约州, 宾夕法尼亚州, 哥伦比亚特区, 新泽西州, 北达科他州, 南达科塔州, 内布拉斯加州, 堪萨斯州, 明尼苏达州, 爱荷华州, 威斯康辛州, 伊利诺伊州, 密苏里州, 印第安纳州, 俄亥俄州, 密歇根州, 俄克拉荷马州, 德克萨斯州, 西佛吉尼亚州, 弗吉尼亚州, 特拉华州, 肯塔基州, 田纳西州, 北卡罗来纳州, 南卡罗来纳州, 乔治亚州, 阿拉巴马州, 密西西比州, 佛罗里达州, 路易斯安那州, 阿肯色州, 马里兰州, 亚利桑那州, 蒙大拿州, 爱达荷州, 怀俄明州, 科罗拉多州, 犹他州, 内华达州, 俄勒冈州, 华盛顿州, 新墨西哥州</span>
                                                    <span>编辑</span>
                                                </div>
                                            </td>
                                            <td>
                                                <p>
                                                    <Checkbox disabled checked onChange={this.onChange}> 街道 </Checkbox>
                                                </p>
                                                <p>
                                                    <Checkbox checked onChange={this.onChange}>  邮政信箱 </Checkbox>
                                                </p>
                                            </td>
                                            <td>
                                                <select>
                                                    <option>3 - 5 天</option>
                                                    <option>5 - 8 天</option>
                                                </select>
                                            </td>
                                            <td>
                                                <div>
                                                    <Input prefix="$" value="0.00" />每订单
                                                    <Divider>加</Divider>
                                                    <Input prefix="$" value="0.00" />每
                                                    <select>
                                                        <option>商品</option>
                                                        <option>LB</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td className="cc0">删除</td>
                                        </tr>
                                        <tr className="coupons1">
                                            <td colSpan="5">
                                                <div className="addRule">+ 添加配送规则</div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="tableBox">
                                    <div className="a-section-expander-container">
                                        <Checkbox disabled checked className="leftTitle" onChange={this.onChange}> 加急配送 </Checkbox>
                                    </div>
                                    <table border="1" cellspacing="0" cellpadding="0" bordercolor="#EEEEEE">
                                        <tr>
                                            <th>地区</th>
                                            <th>地址类型</th>
                                            <th>
                                                <div>运输时间</div>
                                                <div>不包括处理时间</div>
                                            </th>
                                            <th>运输费用</th>
                                            <th>操作</th>
                                        </tr>
                                        <tr className="coupons1">
                                            <td>
                                                <div className="flex0">

                                                    <span>缅因州</span>
                                                    <span>编辑</span>
                                                </div>
                                            </td>
                                            <td>
                                                <p>
                                                    <Checkbox disabled checked onChange={this.onChange}> 街道 </Checkbox>
                                                </p>
                                                <p>
                                                    <Checkbox checked onChange={this.onChange}>  邮政信箱 </Checkbox>
                                                </p>
                                            </td>
                                            <td>
                                                <select>
                                                    <option>3 - 5 天</option>
                                                    <option>5 - 8 天</option>
                                                </select>
                                            </td>
                                            <td>
                                                <div>
                                                    <Input prefix="$" value="0.00" />每订单
                                                    <Divider>加</Divider>
                                                    <Input prefix="$" value="0.00" />每
                                                    <select>
                                                        <option>商品</option>
                                                        <option>LB</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td className="cc0">删除</td>
                                        </tr>
                                        <tr className="coupons1">
                                            <td>
                                                <div className="flex0">
                                                    <span>缅因州, 佛蒙特州, 新罕布什尔州, 马萨诸塞州, 康涅狄格州, 罗德岛州, 纽约州, 宾夕法尼亚州, 哥伦比亚特区, 新泽西州, 北达科他州, 南达科塔州, 内布拉斯加州, 堪萨斯州, 明尼苏达州, 爱荷华州, 威斯康辛州, 伊利诺伊州, 密苏里州, 印第安纳州, 俄亥俄州, 密歇根州, 俄克拉荷马州, 德克萨斯州, 西佛吉尼亚州, 弗吉尼亚州, 特拉华州, 肯塔基州, 田纳西州, 北卡罗来纳州, 南卡罗来纳州, 乔治亚州, 阿拉巴马州, 密西西比州, 佛罗里达州, 路易斯安那州, 阿肯色州, 马里兰州, 亚利桑那州, 蒙大拿州, 爱达荷州, 怀俄明州, 科罗拉多州, 犹他州, 内华达州, 俄勒冈州, 华盛顿州, 新墨西哥州</span>
                                                    <span>编辑</span>
                                                </div>
                                            </td>
                                            <td>
                                                <p>
                                                    <Checkbox disabled checked onChange={this.onChange}> 街道 </Checkbox>
                                                </p>
                                                <p>
                                                    <Checkbox checked onChange={this.onChange}>  邮政信箱 </Checkbox>
                                                </p>
                                            </td>
                                            <td>
                                                <select>
                                                    <option>3 - 5 天</option>
                                                    <option>5 - 8 天</option>
                                                </select>
                                            </td>
                                            <td>
                                                <div>
                                                    <Input prefix="$" value="0.00" />每订单
                                                    <Divider>加</Divider>
                                                    <Input prefix="$" value="0.00" />每
                                                    <select>
                                                        <option>商品</option>
                                                        <option>LB</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td className="cc0">删除</td>
                                        </tr>
                                        <tr className="coupons1">
                                            <td colSpan="5">
                                                <div className="addRule">+ 添加配送规则</div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="tableBox">
                                    <div className="a-section-expander-container">
                                        <Checkbox checked className="leftTitle" onChange={this.onChange}>
                                            <span> 隔日达 </span>
                                            <span className="fz14"> 订单截止时间 2:00 p.m. </span>
                                            <span className="cc0 fz14"> 了解更多信息 </span>
                                        </Checkbox>
                                    </div>
                                    <table border="1" cellspacing="0" cellpadding="0" bordercolor="#EEEEEE">
                                        <tr>
                                            <th>地区</th>
                                            <th>地址类型</th>
                                            <th>
                                                <div>运输时间</div>
                                                <div>不包括处理时间</div>
                                            </th>
                                            <th>运输费用</th>
                                            <th>操作</th>
                                        </tr>
                                        <tr className="coupons1">
                                            <td>
                                                <div className="flex0">

                                                    <span>缅因州</span>
                                                    <span>编辑</span>
                                                </div>
                                            </td>
                                            <td>
                                                <p>
                                                    <Checkbox disabled checked onChange={this.onChange}> 街道 </Checkbox>
                                                </p>
                                                <p>
                                                    <Checkbox checked onChange={this.onChange}>  邮政信箱 </Checkbox>
                                                </p>
                                            </td>
                                            <td>
                                                <select>
                                                    <option>3 - 5 天</option>
                                                    <option>5 - 8 天</option>
                                                </select>
                                            </td>
                                            <td>
                                                <div>
                                                    <Input prefix="$" value="0.00" />每订单
                                                    <Divider>加</Divider>
                                                    <Input prefix="$" value="0.00" />每
                                                    <select>
                                                        <option>商品</option>
                                                        <option>LB</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td className="cc0">删除</td>
                                        </tr>
                                        <tr className="coupons1">
                                            <td>
                                                <div className="flex0">
                                                    <span>缅因州, 佛蒙特州, 新罕布什尔州, 马萨诸塞州, 康涅狄格州, 罗德岛州, 纽约州, 宾夕法尼亚州, 哥伦比亚特区, 新泽西州, 北达科他州, 南达科塔州, 内布拉斯加州, 堪萨斯州, 明尼苏达州, 爱荷华州, 威斯康辛州, 伊利诺伊州, 密苏里州, 印第安纳州, 俄亥俄州, 密歇根州, 俄克拉荷马州, 德克萨斯州, 西佛吉尼亚州, 弗吉尼亚州, 特拉华州, 肯塔基州, 田纳西州, 北卡罗来纳州, 南卡罗来纳州, 乔治亚州, 阿拉巴马州, 密西西比州, 佛罗里达州, 路易斯安那州, 阿肯色州, 马里兰州, 亚利桑那州, 蒙大拿州, 爱达荷州, 怀俄明州, 科罗拉多州, 犹他州, 内华达州, 俄勒冈州, 华盛顿州, 新墨西哥州</span>
                                                    <span>编辑</span>
                                                </div>
                                            </td>
                                            <td>
                                                <p>
                                                    <Checkbox disabled checked onChange={this.onChange}> 街道 </Checkbox>
                                                </p>
                                                <p>
                                                    <Checkbox checked onChange={this.onChange}>  邮政信箱 </Checkbox>
                                                </p>
                                            </td>
                                            <td>
                                                <select>
                                                    <option>3 - 5 天</option>
                                                    <option>5 - 8 天</option>
                                                </select>
                                            </td>
                                            <td>
                                                <div>
                                                    <Input prefix="$" value="0.00" />每订单
                                                    <Divider>加</Divider>
                                                    <Input prefix="$" value="0.00" />每
                                                    <select>
                                                        <option>商品</option>
                                                        <option>LB</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td className="cc0">删除</td>
                                        </tr>
                                        <tr className="coupons1">
                                            <td colSpan="5">
                                                <div className="addRule">+ 添加配送规则</div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="tableBox">
                                    <div className="a-section-expander-container">
                                        <Checkbox checked className="leftTitle" onChange={this.onChange}>
                                            <span> 当天送达 </span>
                                            <span className="fz14"> 订单截止时间 2:00 p.m. </span>
                                            <span className="cc0 fz14"> 了解更多信息 </span>
                                        </Checkbox>
                                    </div>
                                    <table border="1" cellspacing="0" cellpadding="0" bordercolor="#EEEEEE">
                                        <tr>
                                            <th>地区</th>
                                            <th>地址类型</th>
                                            <th>
                                                <div>运输时间</div>
                                                <div>不包括处理时间</div>
                                            </th>
                                            <th>运输费用</th>
                                            <th>操作</th>
                                        </tr>
                                        <tr className="coupons1">
                                            <td>
                                                <div className="flex0">

                                                    <span>缅因州</span>
                                                    <span>编辑</span>
                                                </div>
                                            </td>
                                            <td>
                                                <p>
                                                    <Checkbox disabled checked onChange={this.onChange}> 街道 </Checkbox>
                                                </p>
                                                <p>
                                                    <Checkbox checked onChange={this.onChange}>  邮政信箱 </Checkbox>
                                                </p>
                                            </td>
                                            <td>
                                                <select>
                                                    <option>3 - 5 天</option>
                                                    <option>5 - 8 天</option>
                                                </select>
                                            </td>
                                            <td>
                                                <div>
                                                    <Input prefix="$" value="0.00" />每订单
                                                    <Divider>加</Divider>
                                                    <Input prefix="$" value="0.00" />每
                                                    <select>
                                                        <option>商品</option>
                                                        <option>LB</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td className="cc0">删除</td>
                                        </tr>
                                        <tr className="coupons1">
                                            <td>
                                                <div className="flex0">
                                                    <span>缅因州, 佛蒙特州, 新罕布什尔州, 马萨诸塞州, 康涅狄格州, 罗德岛州, 纽约州, 宾夕法尼亚州, 哥伦比亚特区, 新泽西州, 北达科他州, 南达科塔州, 内布拉斯加州, 堪萨斯州, 明尼苏达州, 爱荷华州, 威斯康辛州, 伊利诺伊州, 密苏里州, 印第安纳州, 俄亥俄州, 密歇根州, 俄克拉荷马州, 德克萨斯州, 西佛吉尼亚州, 弗吉尼亚州, 特拉华州, 肯塔基州, 田纳西州, 北卡罗来纳州, 南卡罗来纳州, 乔治亚州, 阿拉巴马州, 密西西比州, 佛罗里达州, 路易斯安那州, 阿肯色州, 马里兰州, 亚利桑那州, 蒙大拿州, 爱达荷州, 怀俄明州, 科罗拉多州, 犹他州, 内华达州, 俄勒冈州, 华盛顿州, 新墨西哥州</span>
                                                    <span>编辑</span>
                                                </div>
                                            </td>
                                            <td>
                                                <p>
                                                    <Checkbox disabled checked onChange={this.onChange}> 街道 </Checkbox>
                                                </p>
                                                <p>
                                                    <Checkbox checked onChange={this.onChange}>  邮政信箱 </Checkbox>
                                                </p>
                                            </td>
                                            <td>
                                                <select>
                                                    <option>3 - 5 天</option>
                                                    <option>5 - 8 天</option>
                                                </select>
                                            </td>
                                            <td>
                                                <div>
                                                    <Input prefix="$" value="0.00" />每订单
                                                    <Divider>加</Divider>
                                                    <Input prefix="$" value="0.00" />每
                                                    <select>
                                                        <option>商品</option>
                                                        <option>LB</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td className="cc0">删除</td>
                                        </tr>
                                        <tr className="coupons1">
                                            <td colSpan="5">
                                                <div className="addRule">+ 添加配送规则</div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div className="rightBox">
                                <div className="rightTitle">
                                    <p className="fz24 bolds">国际配送</p>
                                </div>
                                <div className="tableBox">
                                    <div className="a-section-expander-container">
                                        <Checkbox disabled checked className="leftTitle" onChange={this.onChange}> 标准配送 </Checkbox>
                                    </div>
                                    <div className="a-section-expander-container">
                                        <span className="requirement"> 要求</span>
                                        <span>配送至美国大陆的所有州（夏威夷、阿拉斯加和美国保护国/地区除外）的运费必须相同。</span>
                                    </div>
                                    <table border="1" cellspacing="0" cellpadding="0" bordercolor="#EEEEEE">
                                        <tr>
                                            <th>地区</th>
                                            <th>地址类型</th>
                                            <th>
                                                <div>运输时间</div>
                                                <div>不包括处理时间</div>
                                            </th>
                                            <th>运输费用</th>
                                            <th>操作</th>
                                        </tr>
                                        <tr className="coupons1">
                                            <td>
                                                <div className="flex0">

                                                    <span>缅因州</span>
                                                    <span>编辑</span>
                                                </div>
                                            </td>
                                            <td>
                                                <p>
                                                    <Checkbox disabled checked onChange={this.onChange}> 街道 </Checkbox>
                                                </p>
                                                <p>
                                                    <Checkbox checked onChange={this.onChange}>  邮政信箱 </Checkbox>
                                                </p>
                                            </td>
                                            <td>
                                                <select>
                                                    <option>3 - 5 天</option>
                                                    <option>5 - 8 天</option>
                                                </select>
                                            </td>
                                            <td>
                                                <div>
                                                    <Input prefix="$" value="0.00" />每订单
                                                    <Divider>加</Divider>
                                                    <Input prefix="$" value="0.00" />每
                                                    <select>
                                                        <option>商品</option>
                                                        <option>LB</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td className="cc0">删除</td>
                                        </tr>
                                        <tr className="coupons1">
                                            <td>
                                                <div className="flex0">
                                                    <span>缅因州, 佛蒙特州, 新罕布什尔州, 马萨诸塞州, 康涅狄格州, 罗德岛州, 纽约州, 宾夕法尼亚州, 哥伦比亚特区, 新泽西州, 北达科他州, 南达科塔州, 内布拉斯加州, 堪萨斯州, 明尼苏达州, 爱荷华州, 威斯康辛州, 伊利诺伊州, 密苏里州, 印第安纳州, 俄亥俄州, 密歇根州, 俄克拉荷马州, 德克萨斯州, 西佛吉尼亚州, 弗吉尼亚州, 特拉华州, 肯塔基州, 田纳西州, 北卡罗来纳州, 南卡罗来纳州, 乔治亚州, 阿拉巴马州, 密西西比州, 佛罗里达州, 路易斯安那州, 阿肯色州, 马里兰州, 亚利桑那州, 蒙大拿州, 爱达荷州, 怀俄明州, 科罗拉多州, 犹他州, 内华达州, 俄勒冈州, 华盛顿州, 新墨西哥州</span>
                                                    <span>编辑</span>
                                                </div>
                                            </td>
                                            <td>
                                                <p>
                                                    <Checkbox disabled checked onChange={this.onChange}> 街道 </Checkbox>
                                                </p>
                                                <p>
                                                    <Checkbox checked onChange={this.onChange}>  邮政信箱 </Checkbox>
                                                </p>
                                            </td>
                                            <td>
                                                <select>
                                                    <option>3 - 5 天</option>
                                                    <option>5 - 8 天</option>
                                                </select>
                                            </td>
                                            <td>
                                                <div>
                                                    <Input prefix="$" value="0.00" />每订单
                                                    <Divider>加</Divider>
                                                    <Input prefix="$" value="0.00" />每
                                                    <select>
                                                        <option>商品</option>
                                                        <option>LB</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td className="cc0">删除</td>
                                        </tr>
                                        <tr className="coupons1">
                                            <td colSpan="5">
                                                <div className="addRule">+ 添加配送规则</div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="tableBox">
                                    <div className="a-section-expander-container">
                                        <Checkbox disabled checked className="leftTitle" onChange={this.onChange}> 加急配送 </Checkbox>
                                    </div>
                                    <table border="1" cellspacing="0" cellpadding="0" bordercolor="#EEEEEE">
                                        <tr>
                                            <th>地区</th>
                                            <th>地址类型</th>
                                            <th>
                                                <div>运输时间</div>
                                                <div>不包括处理时间</div>
                                            </th>
                                            <th>运输费用</th>
                                            <th>操作</th>
                                        </tr>
                                        <tr className="coupons1">
                                            <td>
                                                <div className="flex0">

                                                    <span>缅因州</span>
                                                    <span>编辑</span>
                                                </div>
                                            </td>
                                            <td>
                                                <p>
                                                    <Checkbox disabled checked onChange={this.onChange}> 街道 </Checkbox>
                                                </p>
                                                <p>
                                                    <Checkbox checked onChange={this.onChange}>  邮政信箱 </Checkbox>
                                                </p>
                                            </td>
                                            <td>
                                                <select>
                                                    <option>3 - 5 天</option>
                                                    <option>5 - 8 天</option>
                                                </select>
                                            </td>
                                            <td>
                                                <div>
                                                    <Input prefix="$" value="0.00" />每订单
                                                    <Divider>加</Divider>
                                                    <Input prefix="$" value="0.00" />每
                                                    <select>
                                                        <option>商品</option>
                                                        <option>LB</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td className="cc0">删除</td>
                                        </tr>
                                        <tr className="coupons1">
                                            <td>
                                                <div className="flex0">
                                                    <span>缅因州, 佛蒙特州, 新罕布什尔州, 马萨诸塞州, 康涅狄格州, 罗德岛州, 纽约州, 宾夕法尼亚州, 哥伦比亚特区, 新泽西州, 北达科他州, 南达科塔州, 内布拉斯加州, 堪萨斯州, 明尼苏达州, 爱荷华州, 威斯康辛州, 伊利诺伊州, 密苏里州, 印第安纳州, 俄亥俄州, 密歇根州, 俄克拉荷马州, 德克萨斯州, 西佛吉尼亚州, 弗吉尼亚州, 特拉华州, 肯塔基州, 田纳西州, 北卡罗来纳州, 南卡罗来纳州, 乔治亚州, 阿拉巴马州, 密西西比州, 佛罗里达州, 路易斯安那州, 阿肯色州, 马里兰州, 亚利桑那州, 蒙大拿州, 爱达荷州, 怀俄明州, 科罗拉多州, 犹他州, 内华达州, 俄勒冈州, 华盛顿州, 新墨西哥州</span>
                                                    <span>编辑</span>
                                                </div>
                                            </td>
                                            <td>
                                                <p>
                                                    <Checkbox disabled checked onChange={this.onChange}> 街道 </Checkbox>
                                                </p>
                                                <p>
                                                    <Checkbox checked onChange={this.onChange}>  邮政信箱 </Checkbox>
                                                </p>
                                            </td>
                                            <td>
                                                <select>
                                                    <option>3 - 5 天</option>
                                                    <option>5 - 8 天</option>
                                                </select>
                                            </td>
                                            <td>
                                                <div>
                                                    <Input prefix="$" value="0.00" />每订单
                                                    <Divider>加</Divider>
                                                    <Input prefix="$" value="0.00" />每
                                                    <select>
                                                        <option>商品</option>
                                                        <option>LB</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td className="cc0">删除</td>
                                        </tr>
                                        <tr className="coupons1">
                                            <td colSpan="5">
                                                <div className="addRule">+ 添加配送规则</div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footerBtn">
                        <Link to="/distributionSet">
                            <button>返回</button>
                        </Link>
                        <Link to="/distributionSet" className="baochun">
                            <button>保存</button>
                        </Link>
                    </div>
                </div>
                <Footer />
                <TipsIcon clicks={this.awakenTips} />
                <Reactours routePath={routePath} isActive={isActive} clicks={this.awakenTips} steps={steps1} isOpen={isTourOpen} />
            </div>
        )
    }
}

export default addTemplate;
