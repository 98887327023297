import React, { useState } from 'react';
import './taxation.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"
import '../default.css/default.css'
import { Link } from 'react-router-dom';
import { Button, Steps } from 'antd';

const { Step } = Steps;
function Early() {
    const [isTourOpen, setIsTourOpen] = useState(true);
    const steps1 = [
        {
            selector: '.stepGuidanceBox0',
            content: '点击开始添加税务信息',
        }
    ];
    const [isActive, setIsActive] = useState(false);
    const awakenTips = (type) =>{
        setIsActive(type);
    }
    return (
        <div className="taxationBox">
            {/* 引导步骤 */}
            <TipsIcon clicks={awakenTips} />
            <Reactours routePath="taxation" isActive={isActive} clicks={awakenTips} steps={steps1} isOpen={isTourOpen} />
            <Header />
            <div style={{ display: 'flex' }}>
                <div className="a-section program-overview-values">
                    <Steps current={3}>
                        <Step title="企业资讯" />
                        <Step title="卖家信息" />
                        <Step title="账单/存款" />
                        <Step title="税务信息" />
                        <Step title="商品信息" />
                    </Steps>
                    <div className="CompanyAddress">
                        <div>
                            <h1 className="topTitle">请提供您的税务信息</h1>
                            <p>添加您的税务信息，并验证您的W-9或W-8BEN编号，需要进行税务调查，才能允许亚马逊买家购买您的商品</p>

                            <div className="operationBtn" style={{ textAlign: 'center' }}>
                                <Button className='previousPage' type="primary">
                                    <Link to={{pathname:'/bill',state:{isReturn:1}}}>上一页</Link>
                                </Button>
                                <Button className='next stepGuidanceBox0' type="primary">
                                    <Link to="/taxationInfo">开始</Link>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="rightCollapse">
                    <p >常见问题</p>
                    <Collapse defaultActiveKey={['1']} onChange={callback}>
                        <Panel header="电话号码的正确格式是什么？" key="1">
                            <p>该数字的前缀必须是国家/地区代码。 下面是正确的格式：+1 1298279287</p>
                        </Panel>
                        <Panel header="如果我没有可接收 SMS 的手机号码，应该怎么办？" key="2">
                            <p>您可以输入您的固定电话号码，然后选择拨打电话选项以接收电话验证 PIN。</p>
                        </Panel>
                        <Panel header="如果我没有收到含有 PIN 的 SMS，应该怎么办？" key="3">
                            <p>检查您输入的电话号码格式是否正确。 再次请求 PIN。</p>
                        </Panel>
                        <Panel header="如果“呼叫”选项在我所在的国家/地区未列出，我该怎么办？" key="4">
                            <p>如果在您所在的国家/地区中，“呼叫”选项不可用，请选择“短信”选项。</p>
                        </Panel>
                        <Panel header="主要联系人是如何定义的？" key="5">
                            <p>主要联系人是指有权访问“亚马逊销售”付款帐户，代表帐户持有人（注册卖方）提供注册信息并发起交易（如付款和退款）的人。主要联系人采取的行动被视为帐户持有人采取的行动。</p>
                        </Panel>
                    </Collapse>
                </div> */}
            </div>
            <Footer />
        </div>
    )
}


export default Early