import React from 'react';
import './shopup.css'
import { Tabs } from "antd";
import ShopupDL from './module/shopupDL/shopupDL'
import ShopupUL from './module/shopupUL/shopupUL'
import Examine from './module/examine/examine'
import HomeHeader from 'components/home_header';
import Footer from '../../components/addlisting_footer/FooterCom'
import { Link } from 'react-router-dom'
const { TabPane } = Tabs
class shopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKey: "1"
        }
    }
    tabsChange = (e) => {
        this.setState({
            activeKey: e
        })
    }
    childToFatherValue = (data) => {
        this.setState({
            activeKey: data
        })
    }
    render() {
        return (
            <div className="shopupBox">
                <HomeHeader />
                <div className="shopup-body">
                    <div className="shopup-titel">
                        <div>批量上传商品</div>
                        <div className="shopup-a">
                            <Link to="/">了解更多信息</Link>
                            <Link to="/">卖家大学</Link>
                            <Link to="/">销售申请状态</Link>
                        </div>
                    </div>
                    <div className="shopup-aI">
                        <Link to="/commodity">添加单个商品</Link>
                        <div><a>为商品上传增强内容</a></div>
                    </div>
                    <div className="TabHeader">
                        {/* defaultActiveKey="1" */}
                        <Tabs activeKey={this.state.activeKey} onChange={this.tabsChange} className="tab2">
                            <TabPane tab="下载库存文件" key="1">
                                <ShopupDL />
                            </TabPane>
                            <TabPane tab="上传您的库存文件" key="2" >
                                <ShopupUL childToFatherValue={this.childToFatherValue} />
                            </TabPane>
                            <TabPane tab="监控上传状态" key="3">
                                <Examine />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
export default shopup