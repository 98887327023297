import { memo } from "react";
import { AForm } from "@a-components";
import FormCollectionContext from "./context";
import FormItems from "./FormItems";

const FormCollection = memo(({ options, children, form, ...otherProps }) => {
  const [innerForm] = FormCollection.useForm(form);

  return (
    <FormCollectionContext.Provider value={{ form: innerForm }}>
      <AForm {...otherProps} form={innerForm}>
        {options ? <FormItems options={options} /> : null}
        {children}
      </AForm>
    </FormCollectionContext.Provider>
  );
});

FormCollection.useForm = (...args) => {
  return AForm.useForm(...args);
};

export default FormCollection;
