export default {
    'header_child_Addlisting':'Add Products',  //添加商品
    'header_child_DraftIncomplete':'Complete Your Drafts',//补全您的草稿
    'header_child_Sales':'View Selling Applications',//销售申请
    'header_child_Inventory':'Managing inventory',//管理库存
    'header_child_Repertory':'Manage FBA Inventory',//管理亚马逊库存
    'header_child_Improve':'Improve Listing Quality',//改进商品信息质量
    'header_child_Planning':'Inventory Planning',//库存规划
    'header_child_Logistics':'Remote Fulfillment with FBA',//亚马逊物流配送
    'header_child_Shopup':'Add Products via Upload',//批量上传商品
    'header_child_Report':'Inventory Report',//库存报告
    'header_child_GlobalSales':'Sell Globally',//全球销售
    'header_child_InboundQueue':'Manage FBA Shipments',//管理亚马逊货件
    'header_child_Imageup':'Upload Images',//上传图片
    'header_child_AdminVideo':'Upload & Manage videos',//上传和管理视频
    'header_child_PricingStatus':'Pricing Health',//定价状况
    'header_child_PricingManagement':'Manage Pricing',//管理定价
    'header_child_RepairPriceReport':'Fix Price Alerts',//修复价格警告
    'header_child_AutomaticPricing':'Automate Pricing',//自动定价
    'header_child_CommissionDiscount':'Fee Discounts',//佣金折扣
    'header_child_AgreementPricing':'Negotiated Pricing',//协议定价
    'header_child_ManageOrders':'Manage Orders',//管理订单
    'header_child_OrderReport':'Order Report',//订单报告
    'header_child_UploadOrder':'Upload Order Related Files',//上传订单相关文件
    'header_child_ManageReturns':'Manage Returns',//管理退货
    'header_child_ManageSAFE-T':'Manage SAFE-T Claims',//管理SAFE-T索赔
    'header_child_Advertising':'Campaign Manager',//广告活动管理
    'header_child_A+page':'A+ Content Manager',//A+页面
    'header_child_reviewerProgram':'Early Reviewer Program',//早期评论者计划
    'header_child_Vine':'Vine',//Vine
    'header_child_Deals':'Deals',//秒杀
    'header_child_Coupons':'Coupons',//优惠卷
    'header_child_Discounts':'Prime Exclusive Discounts',//Prime专享折扣
    'header_child_Promotions':'Promotions',//管理促销
    'header_child_ManageStores':'Manage Stores',//管理店铺
    'header_child_distribution':'Fulfillment Programs',//配送计划
    'header_child_Recommended':'Recommended Programs',//推荐计划
    'header_child_Custom':'Custom Program',//制定计划
    'header_child_Lending':'Lending',//借贷
    'header_child_Payments':'Payments',//付款
    'header_child_SellingCoach':'Amazon Selling Coach',//亚马逊销售指导
    'header_child_BusinessReports':'Business Reports',//业务报告
    'header_child_Fulfillment':'Fulfillment',//库存和销售报告
    'header_child_advertising':'Advertising Reports',//广告
    'header_child_ReturnReport':'Return Report',//退货报告
    'header_child_CustomReport':'Custom Report',//自定义报告
    'header_child_TaxDocument':'Tax Document Library',//税务文件库
    'header_child_Account':'Account Health',//账户状况
    'header_child_Feedback':'Feedback',//反馈
    'header_child_GuaranteeClaims':'A-to-Z Guarantee Claims',//亚马逊商城交易保障索赔
    'header_child_ChargebackClaims':'Chargeback Claims',//信用卡拒付索赔
    
};