import React,{components} from 'react'
import './MobileApps.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'



function MobileApps(){
    return(
        <div>
            <Header/>
            <div className="MobileApps_Box">
                <div className="MobileApps_header"><img src={require('../../assets/images/MobileAppsHeader.png').default} /></div>
                <div className="MobileApps_promptBox">
                <div className="MobileApps_prompt">
                    要下载应用，请通过手机摄像头扫描二维码即可直接进入应用商店。应用功能的可能性因销售商城而异。
                </div>
                <div className="MobileApps_prompt_QrCodeBox">
                    <div className="MobileApps_prompt_QrCodeContent">
                        <div className="MobileApps_QrCode_app"><img src={require('../../assets/images/MobileApps-m.png').default} /></div>
                        <div className="MobileApps_QrCode_appII"><img src={require('../../assets/images/MobileApps_app.png').default} /></div>
                    </div>

                    <div className="MobileApps_prompt_QrCodeContent">
                        <div className="MobileApps_QrCode_app"><img src={require('../../assets/images/MobileApps-m.png').default} /></div>
                        <div className="MobileApps_QrCode_appII"><img src={require('../../assets/images/MobileApps_olay.png').default} /></div>
                    </div>

                    <div className="MobileApps_prompt_QrCodeContent">
                        <div className="MobileApps_QrCode_app"><img src={require('../../assets/images/MobileApps-m.png').default} /></div>
                        <div className="MobileApps_QrCode_appII"><img src={require('../../assets/images/MobileApps_yyb.png').default} /></div>
                    </div>
                </div>
                </div>

                <div className="MobileApps_listBox">

                    <div className="MobileApps_listI">
                            <div className="MobileApps_list_img"><img src={require('../../assets/images/MobileApps_zb.png').default} /></div>
                            <div className="MobileApps_list_content">
                               <div className="MobileApps_list_titel">监控您业务的关键绩效指标</div>
                               <div className="MobileApps_list_ul">
                                   <ul>
                                       <li>可按周、月、按过去的七天、过去的 30 天或 12 个月的周期分析销售趋势，并同历史时期进行比较</li>
                                       <li>查看商品级别销售趋势并于历史时期进行比较</li>
                                   </ul>
                               </div>
                            </div>
                    </div>



                    <div className="MobileApps_listI">
                        <div className="MobileApps_list_content">
                        <div>
                            <div className="MobileApps_list_titel">调整商品推广广告活动</div>
                        </div>
                        <div className="MobileApps_list_ul">
                            <ul>
                                <li>追踪首选时间范围内所有广告活动的广告支出、ACoS、曝光量、点击量以及平均每次点击费用</li>
                                <li>更新各个广告活动的每日预算并修改关键词设置</li>
                                <li>点击一项广告活动即可编辑广告组、广告和关键词</li>
                                <li>更新竞价并暂停个别广告活动</li>
                            </ul>
                        </div>
                        </div>
                        <div style={{marginLeft:"19%"}}><img src={require('../../assets/images/MobileApps_hd.png').default} /></div>
                    </div>




                    <div className="MobileApps_listI">
                            <div className="MobileApps_list_img"><img src={require('../../assets/images/MobileApps_hf.png').default} /></div>
                            <div className="MobileApps_list_content">
                               <div className="MobileApps_list_titel">即时收到通知并快速回复买家消息</div>
                               <div className="MobileApps_list_ul">
                                   <ul>
                                       <li>在买家发送商品相关咨询时接收推送通知</li>
                                       <li>在您的移动设备上获取有关新订单、配送提醒以及账户状况问题的信息</li>
                                       <li>根据需求设置推送通知首选项</li>
                                   </ul>
                               </div>
                            </div>
                    </div>



                    <div className="MobileApps_listI">
                        <div className="MobileApps_list_content">
                        <div>
                            <div className="MobileApps_list_titel">保护您的业务数据和账户状况</div>
                        </div>
                        <div className="MobileApps_list_ul" style={{width:"125%"}}>
                            <ul>
                                <li>此移动应用遵循与卖家平台网站相同的用户权限和角色。无论是通过移动应用还是 PC 端登录，您的员工将拥有相同的亚马逊卖家平台账户访问权限。</li>
                                <li>使用此移动应用的卖家表示其账户状况良好，且账户暂停率较低</li>
                            </ul>
                        </div>
                        </div>
                        <div style={{marginLeft:"19%"}}><img src={require('../../assets/images/MobileApps_jg.png').default} /></div>
                    </div>


                    <div className="MobileApps_listI">
                            <div className="MobileApps_list_img"><img src={require('../../assets/images/MobileApps_kc.png').default} /></div>
                            <div className="MobileApps_list_content">
                               <div className="MobileApps_list_titel">管理并优化您的库存</div>
                               <div className="MobileApps_list_ul">
                                   <ul>
                                       <li>全天候监控库存状态</li>
                                       <li>调整商品定价、数量、最低价和最高价</li>
                                       <li>使用盈利能力计算工具估算各商品的利润率</li>
                                       <li>一键即可在社交媒体上与关注者分享商品</li>
                                   </ul>
                               </div>
                            </div>
                    </div>



                    <div className="MobileApps_listI">
                        <div className="MobileApps_list_content">
                        <div>
                            <div className="MobileApps_list_titel">使用单点登录高效管理全球业务</div>
                        </div>
                        <div className="MobileApps_list_ul">
                            <ul>
                                <li><span>支持多个国家/地区单点登录，并启用一键切换国家/地区，以简化您的跨境亚马逊业务运营</span></li>
                                <li><span>在一个集中全球销售的控制面板中监控所有国家/地区的业务数据</span></li>
                            </ul>
                        </div>
                        </div>
                        <div style={{marginLeft:"19%"}}><img src={require('../../assets/images/MobileApps_qq.png').default} /></div>
                    </div>


                    <div className="MobileApps_listI">
                            <div className="MobileApps_list_img"><img src={require('../../assets/images/MobileApps_xx.png').default} /></div>
                            <div className="MobileApps_list_content">
                               <div className="MobileApps_list_titel">联系卖家支持并管理实例日志</div>
                               <div className="MobileApps_list_ul">
                                   <ul>
                                       <li><span>通过此应用快速将问题发送至卖家支持团队</span></li>
                                       <li><span>通过实例日志回复任意正在进行的支持实例，随时随地上传图片和文件，并快速解决问题</span></li>
                                   </ul>
                               </div>
                            </div>
                    </div>



                    <div className="MobileApps_listI">
                        <div className="MobileApps_list_content">
                        <div>
                            <div className="MobileApps_list_titel">使用手机拍摄商品图片、扫描商品条码并上传文件</div>
                        </div>
                        <div className="MobileApps_list_ul" style={{width:"123%"}}>
                            <ul>
                                <li><span>使用此应用的商品摄影工作室直接从移动设备捕获、编辑并保存专业级商品图片</span></li>
                                <li><span>使用可视化搜索功能，用手机相机来扫描带有条形码或不带条形码的商品，即刻获取亚马逊店铺上架商品的相关详情</span></li>
                            </ul>
                        </div>
                        </div>
                        <div style={{marginLeft:"19%"}}><img src={require('../../assets/images/MobileApps_xj.png').default} /></div>
                    </div>



                </div>

            </div>
            <Footer/>
        </div>
    )
}

export default MobileApps