import { useCallback, useMemo, useState } from "react";
import { AInput, ASelect, ASimplePagination } from "@a-components";
import { Button, Spin } from "antd";
import classnames from "classnames";
import GoodsItem from "@b-components/GoodsItem";
import { ATitleTooltip } from "@a-components";
import Apis from "@api/apis";

import style from "./style.module.css";
import usePaginationTable from "@hooks/usePaginationTable";

const options = [
  {
    label: "最新优先",
    value: 1,
  },
  {
    label: "最早优先",
    value: 2,
  },
  {
    label: "优选广告商品",
    value: 3,
  },
];

const GoodsSearchList = ({ onAdd, selectedGoodsList }) => {
  const [searchValue, setSearchValue] = useState("");
  const [selectValue, setSelectValue] = useState(1);

  const queryGoodsList = useCallback((params) => {
    return Apis.addProduct.goodsStockList({
      ...params,
    });
  }, []);

  const {
    tableList,
    isTableLoading,
    pagination,
    operator: { resetPageIndex },
  } = usePaginationTable({
    fetcher: queryGoodsList,
    params: { goodsNameGoodsSkuGoodsAsin: searchValue, goodsSort: selectValue },
    defaultPageInfo: { pageSize: 50 },
  });

  const realGoodsList = useMemo(() => {
    const selectedGoodsIds = selectedGoodsList.map((item) => item.goodsId);
    return tableList.map((item) => {
      return {
        ...item,
        isSelected: selectedGoodsIds.includes(item.goodsId),
      };
    });
  }, [selectedGoodsList, tableList]);

  const unSelectGoodsList = useMemo(() => {
    return realGoodsList.filter((v) => !v.isSelected && v.isPutaway);
  }, [realGoodsList]);

  const goodsItemExtra = (item) => {
    return item.isPutaway ? (
      <Button
        disabled={item.isSelected}
        size={"small"}
        onClick={() => onAdd?.(item)}
      >
        {item.isSelected ? "已" : ""}添加
      </Button>
    ) : (
      <ATitleTooltip
        desc={[
          "该商品不能推广，因为：",
          "该商品缺少重要信息或包含错误信息。查看您的商品库存。",
        ]}
      >
        <span>不符合条件</span>
      </ATitleTooltip>
    );
  };

  const handleSortChange = useCallback(
    (value) => {
      setSelectValue(value);
      resetPageIndex();
    },
    [resetPageIndex]
  );

  return (
    <div className="goods-search-list">
      <div className={style.searchBox}>
        <AInput.Search
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="按照商品名称、ASIN或SKU进行搜索"
          onPressEnter={() => resetPageIndex()}
          onSearch={() => resetPageIndex()}
        />
      </div>
      <div className={classnames(style.operaLine, "df jc-sb")}>
        <div>
          显示：
          <ASelect
            options={options}
            style={{ width: 120 }}
            value={selectValue}
            onChange={handleSortChange}
          />
        </div>
        <div>
          <Button
            type="link"
            onClick={() => onAdd?.(unSelectGoodsList)}
            disabled={!unSelectGoodsList.length}
          >
            在此页面上添加全部
          </Button>
        </div>
      </div>

      <Spin spinning={isTableLoading}>
        <div className={style.goodsList}>
          {realGoodsList.map((item) => {
            const extra = goodsItemExtra(item);
            return (
              <GoodsItem
                key={item.goodsId}
                info={item}
                className={style.goodsItem}
                extra={extra}
              />
            );
          })}
        </div>
      </Spin>
      <div className={classnames(style.paginationContainer, "df jc-fe")}>
        <ASimplePagination {...pagination} />
      </div>
    </div>
  );
};

export default GoodsSearchList;
