import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 14px 18px;
`;

export const EnableCookieTip = styled.div`
    width: 348px;
    height: 74px;
    background-color: #fff;
    box-shadow: 0 0 0 4px #fffae7 inset;
    padding: 14px 18px 0 63px;
    margin-bottom: 14px;
    border-radius: 4px;
    border: 1px #8b6e00 solid;
    position: relative;
    &::before{
        position: absolute;
        left: 15px;
        top: 10px;
        content: '';
        width: 30px;
        height: 27px;
        background: url(${require('@/assets/img/public/icons.png').default}) no-repeat;
        background-position: -283px -35px;
    }
    h4{
        color: #7c6200;
        font-weight: 400;
        font-size: 17px;
        line-height: 1.255;
        margin: 0;font-family: "Hiragino Sans GB","Microsoft Yahei",Arial,sans-serif;
        padding-bottom: 4px;
    }
`;

export const FormContainer = styled.div`
    width: 348px;
    padding: 20px 26px;
    border-radius: 4px;
    border: 1px #ddd solid;
    background-color: #fff;
    .ant-form .ant-form-item input#username,
    .ant-form .ant-form-item .ant-input-password{
        border: 1px solid #d9d9d9;
    }
    .ant-form .ant-form-item input#username:hover,
    .ant-form .ant-form-item .ant-input-password{
        border: 1px solid #d9d9d9;
    }
    .ant-form .ant-form-item input#username:focus,
    .ant-form .ant-form-item .ant-input-password.ant-input-affix-wrapper-focused{
        border-color: #e77600;
        box-shadow: 0 0 3px 2px rgba(228,121,17,.5);
    }
    .ant-form .ant-form-item.ant-form-item-has-error{
        margin-bottom: 14px;
    }
    .next{
        width: 100%;
        color: #111;border-color: #e77600;
        background: linear-gradient(to bottom,#f7dfa5,#f0c14b);
    }
    .next:hover{
        background: linear-gradient(to bottom,#f0c14b,#f0c14b);
    }
    .next:focus{
        box-shadow: 0 0 3px 2px rgba(228,121,17,.5);
    }
    }
`;

export const FormTitle = styled.h1`    
    font-weight: 400;
    font-size: 28px;
    line-height: 1.2;
    margin-bottom: 10px;
    font-family: "Hiragino Sans GB","Microsoft Yahei",Arial,sans-serif;
    padding-bottom: 4px;
`;


export const FormItemTop = styled.div`
    display: flex;
    justify-content: space-between;
    &>p{
        margin: 0;
    }
    p.title{
        font-size: 13px;
        line-height: 19px;
        color: #111;
        padding: 0 0 2px 2px;
        font-weight: 700;
        font-family: "Hiragino Sans GB","Microsoft Yahei",Arial,sans-serif;
    }
`;


export const KeepLogin = styled.div`
display: flex;
margin-top: 36px;
    div{
        display: flex;
        margin-right: 5px;
        p{
            color: #111;
            margin-left: 5px;
            font-size: 13px;
            color: #111;
            font-family: "Hiragino Sans GB","Microsoft Yahei",Arial,sans-serif;
        }
    }
    &>p{
        color: #0066c0;
    }
`;


export const CreateAmazonAccount = styled.div`
    p{
        text-align: center;
        line-height: 1;
        font-size: 12px;
        color: #767676;
        font-weight: 400;
        z-index: 2;
        position: relative;
        background-color: #fff;
        padding: 0 8px 0 7px;
        position: relative;
    }
    p::before{
        content: '';
        width: 90px;
        height: 1px;
        background-color: #e7e7e7;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
    p::after{
        content: '';
        width: 90px;
        height: 1px;
        background-color: #e7e7e7;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }
    button{
        width: 100%;
        color: #111;
        background: #e7e9ec;
        border-radius: 3px;
        border-color: #adb1b8 #a2a6ac #8d9096;
        border-style: solid;
        border-width: 1px;
        box-shadow: 0 1px 0 rgba(255,255,255,.6) inset;
        background-color: transparent;
        font-family: "Hiragino Sans GB","Microsoft Yahei",Arial,sans-serif;
        font-size: 13px;
        line-height: 29px;
        margin: 0;
        outline: 0;
        padding: 0 10px 0 11px;
        text-align: center;
        white-space: nowrap;
        background: linear-gradient(to bottom,#f7f8fa,#e7e9ec);
        cursor: pointer;
    }
    button:hover{
        background: #e7e9ec;
        color: #111;
        border-radius: 3px;
        border-color: #adb1b8 #a2a6ac #8d9096;
        border-style: solid;
        border-width: 1px;
    }
`;

export const BottomLine = styled.div`
    width: 100%;
    height: 44px;
    margin-top: 25px;
    z-index: 0;
    zoom: 1;
    &::after{
        display: block;
        width: 100%;
        height: 44px;
        content: "";
    }
    p{
        font-size: 11px;
        text-align: center;
    }
`;
