import { AText } from "@a-components/";
import { InfoOutlined } from "@ant-design/icons";

const MessageBox = ({ message, tooltips, className }) => {
  return (
    <div className={className}>
      <InfoOutlined style={{ color: "#3489ac" }} />

      <AText>{message}</AText>
    </div>
  );
};

export default MessageBox;
