import "./style.css";
import { FormItem } from "@components/FormCollection";

import GoodsSelector from "@view/advertisement/GoodsSelector";

const GoodsCard = () => {
  return (
    <FormItem noStyle shouldUpdate>
      {(form) => {
        const selectedGoodsList = form.getFieldValue("selectedGoodsList");

        const updateSelectedGoodsList = (list) => {
          form.setFieldsValue({ selectedGoodsList: list });
        };

        return (
          <GoodsSelector
            value={selectedGoodsList}
            onChange={updateSelectedGoodsList}
          />
        );
      }}
    </FormItem>
  );
};

export default GoodsCard;
