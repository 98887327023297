import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, HashRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ScrollToTop from "utils/scrollToTop";
import store from "store";
import "./registryFormCollection";
import getUrlData from "@/utils/getUrlData";


import ErrorBoundary from "./utils/ErrorBoundary";

import "./common.css";

// console.log(top.location.href)
if (!window.receptionStoreId) {
  // console.log(getUrlData('practiceStoreId'), null);
  window.receptionStoreId = getUrlData('practiceStoreId') ? getUrlData('practiceStoreId') : ''
  if (window.receptionStoreId == 'undefined') {
    window.receptionStoreId = ''
  }
}

// es6重写数组扁平化，兼容低版本浏览器
Array.prototype.flat = function (count) {
  let c = count || 1;
  let len = this.length;
  let exe = [];
  if (this.length == 0) return this;
  while (c--) {
    let _arr = [];
    let flag = false;
    if (exe.length == 0) {
      flag = true;
      for (let i = 0; i < len; i++) {
        if (this[i] instanceof Array) {
          exe.push(...this[i]);
        } else {
          exe.push(this[i]);
        }
      }
    } else {
      for (let i = 0; i < exe.length; i++) {
        if (exe[i] instanceof Array) {
          flag = true;
          _arr.push(...exe[i]);
        } else {
          _arr.push(exe[i]);
        }
      }
      exe = _arr;
    }
    if (!flag && c == Infinity) {
      break;
    }
  }
  return exe;
}

ReactDOM.render(
  <ErrorBoundary>
    {/* <React.StrictMode> */}
    <HashRouter>
      <Provider store={store}>
        <ScrollToTop />
        <App />
      </Provider>
    </HashRouter>
    {/* </React.StrictMode> */}
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
