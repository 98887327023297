import React from 'react'
import './SellerUniversity.css'
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'


function SellerUniversity(){
    return(

        <div>
            <Header/>
            <div>

            <div className="end08_mybody">
			<div className="nav">
				<div className="mybody-div">
					<p className="p1">卖家大学</p>
					<p className="p2">您希望了解什么内容？</p>
					<input type="text" className="ipt" />
					<svg t="1611555558886" className="icon ss" viewBox="0 0 1024 1024" 
					version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3644" 
					width="13" height="13"><path d="M614.144 647.744c16.64-9.6 34.112-4.928 43.712 11.712l163.2 282.688c12.8 22.144 8.128 39.68-8.512 49.28-16.64 9.6-34.112 4.864-46.912-17.28l-163.2-282.688c-9.6-16.64-4.928-34.112 11.712-43.712z" p-id="3645"></path><path d="M467.2 742.4c-192 0-352-160-352-352S275.2 38.4 467.2 38.4s352 160 352 352-160 352-352 352z m0-627.2c-160 0-281.6 128-281.6 281.6s128 281.6 281.6 281.6 281.6-128 281.6-281.6-121.6-281.6-281.6-281.6z" p-id="3646"></path>
					</svg>
					<button type="button" className="btn">搜索</button>
					<br />
					<span className="sp1">刚刚开始使用“我要开店”？</span>
					<span className="sp2">从此处开始</span>
				</div>
			</div>
			
			<div className="nr">
				<div className="left">
					<p className="p3">浏览主题</p>
					<ul className="ul-left">
						<li className="tip">
							<svg t="1611557182621" className="icon sl" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13377" width="15" height="15"><path d="M482.24256 316.7232c11.25888-11.264 26.54208-21.71904 41.02144-18.50368 14.47936-2.41152 28.15488 8.85248 39.41888 18.50368l348.30336 349.91104c17.69472 17.69984 17.69472 46.65344 0 64.35328-17.69984 17.69472-46.65344 17.69472-64.35328 0l-324.16768-333.02016001-324.98688 333.02016001c-17.69984 17.69472-46.65344 17.69472-64.34816 0-17.69984-17.69984-17.69984-46.65344 0-64.35328L482.24256 316.7232z" p-id="13378"></path></svg>
							快速入门指南
							<svg t="1611557126475" className="icon by" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12590" width="20" height="20"><path d="M169.87345499 173.49384171c188.6221481-188.6221481 495.63094192-188.6221481 684.25309002 0s188.6221481 495.63094192 0 684.25309002-495.63094192 188.6221481-684.25309002 0c-10.13708282-10.13708282-10.13708282-26.06678438 0-36.2038672s26.06678438-10.13708282 36.2038672 0c168.71002114 168.71002114 443.13533449 168.71002114 611.84535562 0s168.71002114-443.13533449 0-611.84535562-443.13533449-168.71002114-611.84535562 0c-10.13708282 10.13708282-26.06678438 10.13708282-36.2038672 0s-10.13708282-26.06678438 0-36.2038672z" fill="#1F7481" p-id="12591"></path></svg>
						</li>
						<li className="ul1-tip">
							卖家大学简介
							<svg t="1611558789196" className="icon g" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16339" width="20" height="20"><path d="M87.04 501.76L404.48 819.2 936.96 281.6l-71.68-71.68-460.8 460.8-245.76-245.76-71.68 76.8z" p-id="16340" fill="#65944C"></path></svg>
						</li>
						<li className="ul1-tip">
							“我要开店”的运作原理
							<svg t="1611558789196" className="icon g" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16339" width="20" height="20"><path d="M87.04 501.76L404.48 819.2 936.96 281.6l-71.68-71.68-460.8 460.8-245.76-245.76-71.68 76.8z" p-id="16340" fill="#65944C"></path></svg>
						</li>
						<li className="ul1-tip">
							了解亚马逊卖家平台
							<svg t="1611558789196" className="icon g" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16339" width="20" height="20"><path d="M87.04 501.76L404.48 819.2 936.96 281.6l-71.68-71.68-460.8 460.8-245.76-245.76-71.68 76.8z" p-id="16340" fill="#65944C"></path></svg>
						</li>
						<li className="ul1-tip">
							账户信息
							<svg t="1611558789196" className="icon g" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16339" width="20" height="20"><path d="M87.04 501.76L404.48 819.2 936.96 281.6l-71.68-71.68-460.8 460.8-245.76-245.76-71.68 76.8z" p-id="16340" fill="#65944C"></path></svg>
						</li>
						<li className="ul1-tip">
							了解我们的发布指南
							<svg t="1611558789196" className="icon g" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16339" width="20" height="20"><path d="M87.04 501.76L404.48 819.2 936.96 281.6l-71.68-71.68-460.8 460.8-245.76-245.76-71.68 76.8z" p-id="16340" fill="#65944C"></path></svg>
						</li>
						<li className="ul1-tip">
							需要批准的商品
							<svg t="1611558789196" className="icon g" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16339" width="20" height="20"><path d="M87.04 501.76L404.48 819.2 936.96 281.6l-71.68-71.68-460.8 460.8-245.76-245.76-71.68 76.8z" p-id="16340" fill="#65944C"></path></svg>
						</li>
						<li>采购要在亚马逊上销售的商品的指南</li>
						<li className="ul1-tip">
							在亚马逊上发布商品的方法
							<svg t="1611558789196" className="icon g" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16339" width="20" height="20"><path d="M87.04 501.76L404.48 819.2 936.96 281.6l-71.68-71.68-460.8 460.8-245.76-245.76-71.68 76.8z" p-id="16340" fill="#65944C"></path></svg>
						</li>
						<li className="ul1-tip">
							了解产品ID
							<svg t="1611558789196" className="icon g" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16339" width="20" height="20"><path d="M87.04 501.76L404.48 819.2 936.96 281.6l-71.68-71.68-460.8 460.8-245.76-245.76-71.68 76.8z" p-id="16340" fill="#65944C"></path></svg>
						</li>
						<li className="ul1-tip">
							如何创建卓越亚马逊商品信息
							<svg t="1611558789196" className="icon g" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16339" width="20" height="20"><path d="M87.04 501.76L404.48 819.2 936.96 281.6l-71.68-71.68-460.8 460.8-245.76-245.76-71.68 76.8z" p-id="16340" fill="#65944C"></path></svg>
						</li>
						<li>买家产品评论政策(PDF)</li>
						<li>使用亚马逊配送方式</li>
						<li className="ul1-tip">
							亚马逊物流(FBA)新商品选择计划
							<svg t="1611558789196" className="icon g" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16339" width="20" height="20"><path d="M87.04 501.76L404.48 819.2 936.96 281.6l-71.68-71.68-460.8 460.8-245.76-245.76-71.68 76.8z" p-id="16340" fill="#65944C"></path></svg>
						</li>
						<li>付款流程如何在亚马逊上运行</li>
						<li className="ul1-tip">
							通过亚马逊进行广告宣传的方式
							<svg t="1611557126475" className="icon g" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12590" width="20" height="20"><path d="M169.87345499 173.49384171c188.6221481-188.6221481 495.63094192-188.6221481 684.25309002 0s188.6221481 495.63094192 0 684.25309002-495.63094192 188.6221481-684.25309002 0c-10.13708282-10.13708282-10.13708282-26.06678438 0-36.2038672s26.06678438-10.13708282 36.2038672 0c168.71002114 168.71002114 443.13533449 168.71002114 611.84535562 0s168.71002114-443.13533449 0-611.84535562-443.13533449-168.71002114-611.84535562 0c-10.13708282 10.13708282-26.06678438 10.13708282-36.2038672 0s-10.13708282-26.06678438 0-36.2038672z" fill="#1F7481" p-id="12591"></path></svg>
						</li>
						<li>通过亚马逊构建您的品牌</li>
						<li>维护您的账户状况</li>
						<li>使用亚马逊卖家移动应用</li>
					</ul>
						
					<ul className="ul-left2">
						<li className="tip">
							<svg t="1611558570170" className="icon sl" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15449" width="15" height="15"><path d="M545.98826667 754.53546667c-12.8128 12.8128-30.20266667 24.7104-46.67733334 21.0496-16.4736 2.7456-32.032-10.0672-44.8448-21.0496L58.1728 356.41173333c-20.1344-20.13546667-20.1344-53.08373333 0-73.21813333s53.08266667-20.13546667 73.21813333 0l368.83626667 378.90346667 369.75146667-378.90346667c20.1344-20.13546667 53.08266667-20.13546667 73.21813333 0s20.1344 53.08266667 0 73.21813333l-397.20853333 398.12266667z" fill="" p-id="15450"></path></svg>
							<p>中国卖家必修课：亚马逊运营指导大全</p>
							<svg t="1611557126475" className="icon by" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12590" width="20" height="20"><path d="M169.87345499 173.49384171c188.6221481-188.6221481 495.63094192-188.6221481 684.25309002 0s188.6221481 495.63094192 0 684.25309002-495.63094192 188.6221481-684.25309002 0c-10.13708282-10.13708282-10.13708282-26.06678438 0-36.2038672s26.06678438-10.13708282 36.2038672 0c168.71002114 168.71002114 443.13533449 168.71002114 611.84535562 0s168.71002114-443.13533449 0-611.84535562-443.13533449-168.71002114-611.84535562 0c-10.13708282 10.13708282-26.06678438 10.13708282-36.2038672 0s-10.13708282-26.06678438 0-36.2038672z" fill="#1F7481" p-id="12591"></path></svg>
						</li>
						<li className="tip">
							<svg t="1611558570170" className="icon sl" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15449" width="15" height="15"><path d="M545.98826667 754.53546667c-12.8128 12.8128-30.20266667 24.7104-46.67733334 21.0496-16.4736 2.7456-32.032-10.0672-44.8448-21.0496L58.1728 356.41173333c-20.1344-20.13546667-20.1344-53.08373333 0-73.21813333s53.08266667-20.13546667 73.21813333 0l368.83626667 378.90346667 369.75146667-378.90346667c20.1344-20.13546667 53.08266667-20.13546667 73.21813333 0s20.1344 53.08266667 0 73.21813333l-397.20853333 398.12266667z" fill="" p-id="15450"></path></svg>
							<p>使用卖家平台</p>
							<svg t="1611557126475" className="icon by" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12590" width="20" height="20"><path d="M169.87345499 173.49384171c188.6221481-188.6221481 495.63094192-188.6221481 684.25309002 0s188.6221481 495.63094192 0 684.25309002-495.63094192 188.6221481-684.25309002 0c-10.13708282-10.13708282-10.13708282-26.06678438 0-36.2038672s26.06678438-10.13708282 36.2038672 0c168.71002114 168.71002114 443.13533449 168.71002114 611.84535562 0s168.71002114-443.13533449 0-611.84535562-443.13533449-168.71002114-611.84535562 0c-10.13708282 10.13708282-26.06678438 10.13708282-36.2038672 0s-10.13708282-26.06678438 0-36.2038672z" fill="#1F7481" p-id="12591"></path></svg>
						</li>
						<li className="tip">
							<svg t="1611558570170" className="icon sl" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15449" width="15" height="15"><path d="M545.98826667 754.53546667c-12.8128 12.8128-30.20266667 24.7104-46.67733334 21.0496-16.4736 2.7456-32.032-10.0672-44.8448-21.0496L58.1728 356.41173333c-20.1344-20.13546667-20.1344-53.08373333 0-73.21813333s53.08266667-20.13546667 73.21813333 0l368.83626667 378.90346667 369.75146667-378.90346667c20.1344-20.13546667 53.08266667-20.13546667 73.21813333 0s20.1344 53.08266667 0 73.21813333l-397.20853333 398.12266667z" fill="" p-id="15450"></path></svg>
							<p>商品详情页面</p>
							<svg t="1611557126475" className="icon by" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12590" width="20" height="20"><path d="M169.87345499 173.49384171c188.6221481-188.6221481 495.63094192-188.6221481 684.25309002 0s188.6221481 495.63094192 0 684.25309002-495.63094192 188.6221481-684.25309002 0c-10.13708282-10.13708282-10.13708282-26.06678438 0-36.2038672s26.06678438-10.13708282 36.2038672 0c168.71002114 168.71002114 443.13533449 168.71002114 611.84535562 0s168.71002114-443.13533449 0-611.84535562-443.13533449-168.71002114-611.84535562 0c-10.13708282 10.13708282-26.06678438 10.13708282-36.2038672 0s-10.13708282-26.06678438 0-36.2038672z" fill="#1F7481" p-id="12591"></path></svg>
						</li>
						<li className="tip">
							<svg t="1611558570170" className="icon sl" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15449" width="15" height="15"><path d="M545.98826667 754.53546667c-12.8128 12.8128-30.20266667 24.7104-46.67733334 21.0496-16.4736 2.7456-32.032-10.0672-44.8448-21.0496L58.1728 356.41173333c-20.1344-20.13546667-20.1344-53.08373333 0-73.21813333s53.08266667-20.13546667 73.21813333 0l368.83626667 378.90346667 369.75146667-378.90346667c20.1344-20.13546667 53.08266667-20.13546667 73.21813333 0s20.1344 53.08266667 0 73.21813333l-397.20853333 398.12266667z" fill="" p-id="15450"></path></svg>
							<p>发布商品</p>
							<svg t="1611557126475" className="icon by" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12590" width="20" height="20"><path d="M169.87345499 173.49384171c188.6221481-188.6221481 495.63094192-188.6221481 684.25309002 0s188.6221481 495.63094192 0 684.25309002-495.63094192 188.6221481-684.25309002 0c-10.13708282-10.13708282-10.13708282-26.06678438 0-36.2038672s26.06678438-10.13708282 36.2038672 0c168.71002114 168.71002114 443.13533449 168.71002114 611.84535562 0s168.71002114-443.13533449 0-611.84535562-443.13533449-168.71002114-611.84535562 0c-10.13708282 10.13708282-26.06678438 10.13708282-36.2038672 0s-10.13708282-26.06678438 0-36.2038672z" fill="#1F7481" p-id="12591"></path></svg>
						</li>
						<li className="tip">
							<svg t="1611558570170" className="icon sl" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15449" width="15" height="15"><path d="M545.98826667 754.53546667c-12.8128 12.8128-30.20266667 24.7104-46.67733334 21.0496-16.4736 2.7456-32.032-10.0672-44.8448-21.0496L58.1728 356.41173333c-20.1344-20.13546667-20.1344-53.08373333 0-73.21813333s53.08266667-20.13546667 73.21813333 0l368.83626667 378.90346667 369.75146667-378.90346667c20.1344-20.13546667 53.08266667-20.13546667 73.21813333 0s20.1344 53.08266667 0 73.21813333l-397.20853333 398.12266667z" fill="" p-id="15450"></path></svg>
							<p>定价</p>
							<svg t="1611557126475" className="icon by" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12590" width="20" height="20"><path d="M169.87345499 173.49384171c188.6221481-188.6221481 495.63094192-188.6221481 684.25309002 0s188.6221481 495.63094192 0 684.25309002-495.63094192 188.6221481-684.25309002 0c-10.13708282-10.13708282-10.13708282-26.06678438 0-36.2038672s26.06678438-10.13708282 36.2038672 0c168.71002114 168.71002114 443.13533449 168.71002114 611.84535562 0s168.71002114-443.13533449 0-611.84535562-443.13533449-168.71002114-611.84535562 0c-10.13708282 10.13708282-26.06678438 10.13708282-36.2038672 0s-10.13708282-26.06678438 0-36.2038672z" fill="#1F7481" p-id="12591"></path></svg>
						</li>
						<li className="tip">
							<svg t="1611558570170" className="icon sl" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15449" width="15" height="15"><path d="M545.98826667 754.53546667c-12.8128 12.8128-30.20266667 24.7104-46.67733334 21.0496-16.4736 2.7456-32.032-10.0672-44.8448-21.0496L58.1728 356.41173333c-20.1344-20.13546667-20.1344-53.08373333 0-73.21813333s53.08266667-20.13546667 73.21813333 0l368.83626667 378.90346667 369.75146667-378.90346667c20.1344-20.13546667 53.08266667-20.13546667 73.21813333 0s20.1344 53.08266667 0 73.21813333l-397.20853333 398.12266667z" fill="" p-id="15450"></path></svg>
							<p>亚马逊物流(Fulfillment by Amazon,FBA)</p>
							<svg t="1611557126475" className="icon by" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12590" width="20" height="20"><path d="M169.87345499 173.49384171c188.6221481-188.6221481 495.63094192-188.6221481 684.25309002 0s188.6221481 495.63094192 0 684.25309002-495.63094192 188.6221481-684.25309002 0c-10.13708282-10.13708282-10.13708282-26.06678438 0-36.2038672s26.06678438-10.13708282 36.2038672 0c168.71002114 168.71002114 443.13533449 168.71002114 611.84535562 0s168.71002114-443.13533449 0-611.84535562-443.13533449-168.71002114-611.84535562 0c-10.13708282 10.13708282-26.06678438 10.13708282-36.2038672 0s-10.13708282-26.06678438 0-36.2038672z" fill="#1F7481" p-id="12591"></path></svg>
						</li>
						<li className="tip">
							<svg t="1611558570170" className="icon sl" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15449" width="15" height="15"><path d="M545.98826667 754.53546667c-12.8128 12.8128-30.20266667 24.7104-46.67733334 21.0496-16.4736 2.7456-32.032-10.0672-44.8448-21.0496L58.1728 356.41173333c-20.1344-20.13546667-20.1344-53.08373333 0-73.21813333s53.08266667-20.13546667 73.21813333 0l368.83626667 378.90346667 369.75146667-378.90346667c20.1344-20.13546667 53.08266667-20.13546667 73.21813333 0s20.1344 53.08266667 0 73.21813333l-397.20853333 398.12266667z" fill="" p-id="15450"></path></svg>
							<p>使用赞助商品进行广告</p>
							<svg t="1611557126475" className="icon by" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12590" width="20" height="20"><path d="M169.87345499 173.49384171c188.6221481-188.6221481 495.63094192-188.6221481 684.25309002 0s188.6221481 495.63094192 0 684.25309002-495.63094192 188.6221481-684.25309002 0c-10.13708282-10.13708282-10.13708282-26.06678438 0-36.2038672s26.06678438-10.13708282 36.2038672 0c168.71002114 168.71002114 443.13533449 168.71002114 611.84535562 0s168.71002114-443.13533449 0-611.84535562-443.13533449-168.71002114-611.84535562 0c-10.13708282 10.13708282-26.06678438 10.13708282-36.2038672 0s-10.13708282-26.06678438 0-36.2038672z" fill="#1F7481" p-id="12591"></path></svg>
						</li>
						<li className="tip">
							<svg t="1611558570170" className="icon sl" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15449" width="15" height="15"><path d="M545.98826667 754.53546667c-12.8128 12.8128-30.20266667 24.7104-46.67733334 21.0496-16.4736 2.7456-32.032-10.0672-44.8448-21.0496L58.1728 356.41173333c-20.1344-20.13546667-20.1344-53.08373333 0-73.21813333s53.08266667-20.13546667 73.21813333 0l368.83626667 378.90346667 369.75146667-378.90346667c20.1344-20.13546667 53.08266667-20.13546667 73.21813333 0s20.1344 53.08266667 0 73.21813333l-397.20853333 398.12266667z" fill="" p-id="15450"></path></svg>
							<p>优惠券、优惠、促销</p>
							<svg t="1611557126475" className="icon by" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12590" width="20" height="20"><path d="M169.87345499 173.49384171c188.6221481-188.6221481 495.63094192-188.6221481 684.25309002 0s188.6221481 495.63094192 0 684.25309002-495.63094192 188.6221481-684.25309002 0c-10.13708282-10.13708282-10.13708282-26.06678438 0-36.2038672s26.06678438-10.13708282 36.2038672 0c168.71002114 168.71002114 443.13533449 168.71002114 611.84535562 0s168.71002114-443.13533449 0-611.84535562-443.13533449-168.71002114-611.84535562 0c-10.13708282 10.13708282-26.06678438 10.13708282-36.2038672 0s-10.13708282-26.06678438 0-36.2038672z" fill="#1F7481" p-id="12591"></path></svg>
						</li>
						<li className="tip">
							<svg t="1611558570170" className="icon sl" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15449" width="15" height="15"><path d="M545.98826667 754.53546667c-12.8128 12.8128-30.20266667 24.7104-46.67733334 21.0496-16.4736 2.7456-32.032-10.0672-44.8448-21.0496L58.1728 356.41173333c-20.1344-20.13546667-20.1344-53.08373333 0-73.21813333s53.08266667-20.13546667 73.21813333 0l368.83626667 378.90346667 369.75146667-378.90346667c20.1344-20.13546667 53.08266667-20.13546667 73.21813333 0s20.1344 53.08266667 0 73.21813333l-397.20853333 398.12266667z" fill="" p-id="15450"></path></svg>
							<p>建立你的品牌</p>
							<svg t="1611557126475" className="icon by" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12590" width="20" height="20"><path d="M169.87345499 173.49384171c188.6221481-188.6221481 495.63094192-188.6221481 684.25309002 0s188.6221481 495.63094192 0 684.25309002-495.63094192 188.6221481-684.25309002 0c-10.13708282-10.13708282-10.13708282-26.06678438 0-36.2038672s26.06678438-10.13708282 36.2038672 0c168.71002114 168.71002114 443.13533449 168.71002114 611.84535562 0s168.71002114-443.13533449 0-611.84535562-443.13533449-168.71002114-611.84535562 0c-10.13708282 10.13708282-26.06678438 10.13708282-36.2038672 0s-10.13708282-26.06678438 0-36.2038672z" fill="#1F7481" p-id="12591"></path></svg>
						</li>
						<li className="tip">
							<svg t="1611558570170" className="icon sl" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15449" width="15" height="15"><path d="M545.98826667 754.53546667c-12.8128 12.8128-30.20266667 24.7104-46.67733334 21.0496-16.4736 2.7456-32.032-10.0672-44.8448-21.0496L58.1728 356.41173333c-20.1344-20.13546667-20.1344-53.08373333 0-73.21813333s53.08266667-20.13546667 73.21813333 0l368.83626667 378.90346667 369.75146667-378.90346667c20.1344-20.13546667 53.08266667-20.13546667 73.21813333 0s20.1344 53.08266667 0 73.21813333l-397.20853333 398.12266667z" fill="" p-id="15450"></path></svg>
							<p>监管合规性</p>
							<svg t="1611557126475" className="icon by" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12590" width="20" height="20"><path d="M169.87345499 173.49384171c188.6221481-188.6221481 495.63094192-188.6221481 684.25309002 0s188.6221481 495.63094192 0 684.25309002-495.63094192 188.6221481-684.25309002 0c-10.13708282-10.13708282-10.13708282-26.06678438 0-36.2038672s26.06678438-10.13708282 36.2038672 0c168.71002114 168.71002114 443.13533449 168.71002114 611.84535562 0s168.71002114-443.13533449 0-611.84535562-443.13533449-168.71002114-611.84535562 0c-10.13708282 10.13708282-26.06678438 10.13708282-36.2038672 0s-10.13708282-26.06678438 0-36.2038672z" fill="#1F7481" p-id="12591"></path></svg>
						</li>
						<li className="tip">
							<svg t="1611558570170" className="icon sl" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15449" width="15" height="15"><path d="M545.98826667 754.53546667c-12.8128 12.8128-30.20266667 24.7104-46.67733334 21.0496-16.4736 2.7456-32.032-10.0672-44.8448-21.0496L58.1728 356.41173333c-20.1344-20.13546667-20.1344-53.08373333 0-73.21813333s53.08266667-20.13546667 73.21813333 0l368.83626667 378.90346667 369.75146667-378.90346667c20.1344-20.13546667 53.08266667-20.13546667 73.21813333 0s20.1344 53.08266667 0 73.21813333l-397.20853333 398.12266667z" fill="" p-id="15450"></path></svg>
							<p>卖家绩效</p>
							<svg t="1611557126475" className="icon by" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12590" width="20" height="20"><path d="M169.87345499 173.49384171c188.6221481-188.6221481 495.63094192-188.6221481 684.25309002 0s188.6221481 495.63094192 0 684.25309002-495.63094192 188.6221481-684.25309002 0c-10.13708282-10.13708282-10.13708282-26.06678438 0-36.2038672s26.06678438-10.13708282 36.2038672 0c168.71002114 168.71002114 443.13533449 168.71002114 611.84535562 0s168.71002114-443.13533449 0-611.84535562-443.13533449-168.71002114-611.84535562 0c-10.13708282 10.13708282-26.06678438 10.13708282-36.2038672 0s-10.13708282-26.06678438 0-36.2038672z" fill="#1F7481" p-id="12591"></path></svg>
						</li>
						<li className="tip">
							<svg t="1611558570170" className="icon sl" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15449" width="15" height="15"><path d="M545.98826667 754.53546667c-12.8128 12.8128-30.20266667 24.7104-46.67733334 21.0496-16.4736 2.7456-32.032-10.0672-44.8448-21.0496L58.1728 356.41173333c-20.1344-20.13546667-20.1344-53.08373333 0-73.21813333s53.08266667-20.13546667 73.21813333 0l368.83626667 378.90346667 369.75146667-378.90346667c20.1344-20.13546667 53.08266667-20.13546667 73.21813333 0s20.1344 53.08266667 0 73.21813333l-397.20853333 398.12266667z" fill="" p-id="15450"></path></svg>
							<p>全球销售</p>
							<svg t="1611557126475" className="icon by" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12590" width="20" height="20"><path d="M169.87345499 173.49384171c188.6221481-188.6221481 495.63094192-188.6221481 684.25309002 0s188.6221481 495.63094192 0 684.25309002-495.63094192 188.6221481-684.25309002 0c-10.13708282-10.13708282-10.13708282-26.06678438 0-36.2038672s26.06678438-10.13708282 36.2038672 0c168.71002114 168.71002114 443.13533449 168.71002114 611.84535562 0s168.71002114-443.13533449 0-611.84535562-443.13533449-168.71002114-611.84535562 0c-10.13708282 10.13708282-26.06678438 10.13708282-36.2038672 0s-10.13708282-26.06678438 0-36.2038672z" fill="#1F7481" p-id="12591"></path></svg>
						</li>
						<li className="tip">
							<svg t="1611558570170" className="icon sl" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15449" width="15" height="15"><path d="M545.98826667 754.53546667c-12.8128 12.8128-30.20266667 24.7104-46.67733334 21.0496-16.4736 2.7456-32.032-10.0672-44.8448-21.0496L58.1728 356.41173333c-20.1344-20.13546667-20.1344-53.08373333 0-73.21813333s53.08266667-20.13546667 73.21813333 0l368.83626667 378.90346667 369.75146667-378.90346667c20.1344-20.13546667 53.08266667-20.13546667 73.21813333 0s20.1344 53.08266667 0 73.21813333l-397.20853333 398.12266667z" fill="" p-id="15450"></path></svg>
							<p>亚马逊加速2020年</p>
							<svg t="1611557126475" className="icon by" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12590" width="20" height="20"><path d="M169.87345499 173.49384171c188.6221481-188.6221481 495.63094192-188.6221481 684.25309002 0s188.6221481 495.63094192 0 684.25309002-495.63094192 188.6221481-684.25309002 0c-10.13708282-10.13708282-10.13708282-26.06678438 0-36.2038672s26.06678438-10.13708282 36.2038672 0c168.71002114 168.71002114 443.13533449 168.71002114 611.84535562 0s168.71002114-443.13533449 0-611.84535562-443.13533449-168.71002114-611.84535562 0c-10.13708282 10.13708282-26.06678438 10.13708282-36.2038672 0s-10.13708282-26.06678438 0-36.2038672z" fill="#1F7481" p-id="12591"></path></svg>
						</li>
						<li className="tip">
							<svg t="1611558570170" className="icon sl" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15449" width="15" height="15"><path d="M545.98826667 754.53546667c-12.8128 12.8128-30.20266667 24.7104-46.67733334 21.0496-16.4736 2.7456-32.032-10.0672-44.8448-21.0496L58.1728 356.41173333c-20.1344-20.13546667-20.1344-53.08373333 0-73.21813333s53.08266667-20.13546667 73.21813333 0l368.83626667 378.90346667 369.75146667-378.90346667c20.1344-20.13546667 53.08266667-20.13546667 73.21813333 0s20.1344 53.08266667 0 73.21813333l-397.20853333 398.12266667z" fill="" p-id="15450"></path></svg>
							<p>亚马逊搜索</p>
							<svg t="1611557126475" className="icon by" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12590" width="20" height="20"><path d="M169.87345499 173.49384171c188.6221481-188.6221481 495.63094192-188.6221481 684.25309002 0s188.6221481 495.63094192 0 684.25309002-495.63094192 188.6221481-684.25309002 0c-10.13708282-10.13708282-10.13708282-26.06678438 0-36.2038672s26.06678438-10.13708282 36.2038672 0c168.71002114 168.71002114 443.13533449 168.71002114 611.84535562 0s168.71002114-443.13533449 0-611.84535562-443.13533449-168.71002114-611.84535562 0c-10.13708282 10.13708282-26.06678438 10.13708282-36.2038672 0s-10.13708282-26.06678438 0-36.2038672z" fill="#1F7481" p-id="12591"></path></svg>
						</li>
						<li className="tip">
							<svg t="1611558570170" className="icon sl" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15449" width="15" height="15"><path d="M545.98826667 754.53546667c-12.8128 12.8128-30.20266667 24.7104-46.67733334 21.0496-16.4736 2.7456-32.032-10.0672-44.8448-21.0496L58.1728 356.41173333c-20.1344-20.13546667-20.1344-53.08373333 0-73.21813333s53.08266667-20.13546667 73.21813333 0l368.83626667 378.90346667 369.75146667-378.90346667c20.1344-20.13546667 53.08266667-20.13546667 73.21813333 0s20.1344 53.08266667 0 73.21813333l-397.20853333 398.12266667z" fill="" p-id="15450"></path></svg>
							<p>Prime日和假日准备</p>
							<svg t="1611557126475" className="icon by" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12590" width="20" height="20"><path d="M169.87345499 173.49384171c188.6221481-188.6221481 495.63094192-188.6221481 684.25309002 0s188.6221481 495.63094192 0 684.25309002-495.63094192 188.6221481-684.25309002 0c-10.13708282-10.13708282-10.13708282-26.06678438 0-36.2038672s26.06678438-10.13708282 36.2038672 0c168.71002114 168.71002114 443.13533449 168.71002114 611.84535562 0s168.71002114-443.13533449 0-611.84535562-443.13533449-168.71002114-611.84535562 0c-10.13708282 10.13708282-26.06678438 10.13708282-36.2038672 0s-10.13708282-26.06678438 0-36.2038672z" fill="#1F7481" p-id="12591"></path></svg>
						</li>
						<li className="tip">
							<svg t="1611558570170" className="icon sl" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15449" width="15" height="15"><path d="M545.98826667 754.53546667c-12.8128 12.8128-30.20266667 24.7104-46.67733334 21.0496-16.4736 2.7456-32.032-10.0672-44.8448-21.0496L58.1728 356.41173333c-20.1344-20.13546667-20.1344-53.08373333 0-73.21813333s53.08266667-20.13546667 73.21813333 0l368.83626667 378.90346667 369.75146667-378.90346667c20.1344-20.13546667 53.08266667-20.13546667 73.21813333 0s20.1344 53.08266667 0 73.21813333l-397.20853333 398.12266667z" fill="" p-id="15450"></path></svg>
							<p>案例研究</p>
							<svg t="1611557126475" className="icon by" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12590" width="20" height="20"><path d="M169.87345499 173.49384171c188.6221481-188.6221481 495.63094192-188.6221481 684.25309002 0s188.6221481 495.63094192 0 684.25309002-495.63094192 188.6221481-684.25309002 0c-10.13708282-10.13708282-10.13708282-26.06678438 0-36.2038672s26.06678438-10.13708282 36.2038672 0c168.71002114 168.71002114 443.13533449 168.71002114 611.84535562 0s168.71002114-443.13533449 0-611.84535562-443.13533449-168.71002114-611.84535562 0c-10.13708282 10.13708282-26.06678438 10.13708282-36.2038672 0s-10.13708282-26.06678438 0-36.2038672z" fill="#1F7481" p-id="12591"></path></svg>
						</li>
						<li className="tip">
							<svg t="1611558570170" className="icon sl" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15449" width="15" height="15"><path d="M545.98826667 754.53546667c-12.8128 12.8128-30.20266667 24.7104-46.67733334 21.0496-16.4736 2.7456-32.032-10.0672-44.8448-21.0496L58.1728 356.41173333c-20.1344-20.13546667-20.1344-53.08373333 0-73.21813333s53.08266667-20.13546667 73.21813333 0l368.83626667 378.90346667 369.75146667-378.90346667c20.1344-20.13546667 53.08266667-20.13546667 73.21813333 0s20.1344 53.08266667 0 73.21813333l-397.20853333 398.12266667z" fill="" p-id="15450"></path></svg>
							<p>亚马逊企业购卖家计划</p>
							<svg t="1611557126475" className="icon by" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12590" width="20" height="20"><path d="M169.87345499 173.49384171c188.6221481-188.6221481 495.63094192-188.6221481 684.25309002 0s188.6221481 495.63094192 0 684.25309002-495.63094192 188.6221481-684.25309002 0c-10.13708282-10.13708282-10.13708282-26.06678438 0-36.2038672s26.06678438-10.13708282 36.2038672 0c168.71002114 168.71002114 443.13533449 168.71002114 611.84535562 0s168.71002114-443.13533449 0-611.84535562-443.13533449-168.71002114-611.84535562 0c-10.13708282 10.13708282-26.06678438 10.13708282-36.2038672 0s-10.13708282-26.06678438 0-36.2038672z" fill="#1F7481" p-id="12591"></path></svg>
						</li>
						<li className="tip">
							<svg t="1611558570170" className="icon sl" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15449" width="15" height="15"><path d="M545.98826667 754.53546667c-12.8128 12.8128-30.20266667 24.7104-46.67733334 21.0496-16.4736 2.7456-32.032-10.0672-44.8448-21.0496L58.1728 356.41173333c-20.1344-20.13546667-20.1344-53.08373333 0-73.21813333s53.08266667-20.13546667 73.21813333 0l368.83626667 378.90346667 369.75146667-378.90346667c20.1344-20.13546667 53.08266667-20.13546667 73.21813333 0s20.1344 53.08266667 0 73.21813333l-397.20853333 398.12266667z" fill="" p-id="15450"></path></svg>
							<p>亚马逊手工和定制</p>
							<svg t="1611557126475" className="icon by" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12590" width="20" height="20"><path d="M169.87345499 173.49384171c188.6221481-188.6221481 495.63094192-188.6221481 684.25309002 0s188.6221481 495.63094192 0 684.25309002-495.63094192 188.6221481-684.25309002 0c-10.13708282-10.13708282-10.13708282-26.06678438 0-36.2038672s26.06678438-10.13708282 36.2038672 0c168.71002114 168.71002114 443.13533449 168.71002114 611.84535562 0s168.71002114-443.13533449 0-611.84535562-443.13533449-168.71002114-611.84535562 0c-10.13708282 10.13708282-26.06678438 10.13708282-36.2038672 0s-10.13708282-26.06678438 0-36.2038672z" fill="#1F7481" p-id="12591"></path></svg>
						</li>
						<li className="tip">
							<svg t="1611558570170" className="icon sl" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15449" width="15" height="15"><path d="M545.98826667 754.53546667c-12.8128 12.8128-30.20266667 24.7104-46.67733334 21.0496-16.4736 2.7456-32.032-10.0672-44.8448-21.0496L58.1728 356.41173333c-20.1344-20.13546667-20.1344-53.08373333 0-73.21813333s53.08266667-20.13546667 73.21813333 0l368.83626667 378.90346667 369.75146667-378.90346667c20.1344-20.13546667 53.08266667-20.13546667 73.21813333 0s20.1344 53.08266667 0 73.21813333l-397.20853333 398.12266667z" fill="" p-id="15450"></path></svg>
							<p>亚马逊商城网络服务(MWS)</p>
							<svg t="1611557126475" className="icon by" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12590" width="20" height="20"><path d="M169.87345499 173.49384171c188.6221481-188.6221481 495.63094192-188.6221481 684.25309002 0s188.6221481 495.63094192 0 684.25309002-495.63094192 188.6221481-684.25309002 0c-10.13708282-10.13708282-10.13708282-26.06678438 0-36.2038672s26.06678438-10.13708282 36.2038672 0c168.71002114 168.71002114 443.13533449 168.71002114 611.84535562 0s168.71002114-443.13533449 0-611.84535562-443.13533449-168.71002114-611.84535562 0c-10.13708282 10.13708282-26.06678438 10.13708282-36.2038672 0s-10.13708282-26.06678438 0-36.2038672z" fill="#1F7481" p-id="12591"></path></svg>
						</li>
						<li className="tip">
							<svg t="1611558570170" className="icon sl" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15449" width="15" height="15"><path d="M545.98826667 754.53546667c-12.8128 12.8128-30.20266667 24.7104-46.67733334 21.0496-16.4736 2.7456-32.032-10.0672-44.8448-21.0496L58.1728 356.41173333c-20.1344-20.13546667-20.1344-53.08373333 0-73.21813333s53.08266667-20.13546667 73.21813333 0l368.83626667 378.90346667 369.75146667-378.90346667c20.1344-20.13546667 53.08266667-20.13546667 73.21813333 0s20.1344 53.08266667 0 73.21813333l-397.20853333 398.12266667z" fill="" p-id="15450"></path></svg>
							<p>宣传你的品牌</p>
							<svg t="1611557126475" className="icon by" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12590" width="20" height="20"><path d="M169.87345499 173.49384171c188.6221481-188.6221481 495.63094192-188.6221481 684.25309002 0s188.6221481 495.63094192 0 684.25309002-495.63094192 188.6221481-684.25309002 0c-10.13708282-10.13708282-10.13708282-26.06678438 0-36.2038672s26.06678438-10.13708282 36.2038672 0c168.71002114 168.71002114 443.13533449 168.71002114 611.84535562 0s168.71002114-443.13533449 0-611.84535562-443.13533449-168.71002114-611.84535562 0c-10.13708282 10.13708282-26.06678438 10.13708282-36.2038672 0s-10.13708282-26.06678438 0-36.2038672z" fill="#1F7481" p-id="12591"></path></svg>
						</li>
						<li className="tip">
							<svg t="1611558570170" className="icon sl" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15449" width="15" height="15"><path d="M545.98826667 754.53546667c-12.8128 12.8128-30.20266667 24.7104-46.67733334 21.0496-16.4736 2.7456-32.032-10.0672-44.8448-21.0496L58.1728 356.41173333c-20.1344-20.13546667-20.1344-53.08373333 0-73.21813333s53.08266667-20.13546667 73.21813333 0l368.83626667 378.90346667 369.75146667-378.90346667c20.1344-20.13546667 53.08266667-20.13546667 73.21813333 0s20.1344 53.08266667 0 73.21813333l-397.20853333 398.12266667z" fill="" p-id="15450"></path></svg>
							<p>品牌分析和报告</p>
							<svg t="1611557126475" className="icon by" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12590" width="20" height="20"><path d="M169.87345499 173.49384171c188.6221481-188.6221481 495.63094192-188.6221481 684.25309002 0s188.6221481 495.63094192 0 684.25309002-495.63094192 188.6221481-684.25309002 0c-10.13708282-10.13708282-10.13708282-26.06678438 0-36.2038672s26.06678438-10.13708282 36.2038672 0c168.71002114 168.71002114 443.13533449 168.71002114 611.84535562 0s168.71002114-443.13533449 0-611.84535562-443.13533449-168.71002114-611.84535562 0c-10.13708282 10.13708282-26.06678438 10.13708282-36.2038672 0s-10.13708282-26.06678438 0-36.2038672z" fill="#1F7481" p-id="12591"></path></svg>
						</li>
					</ul>
				</div>
				<div className="right">
					<p className="p4">特别推荐</p>
					<ul className="ul-right">
						<li>
							<img src={require("../../assets/images/5-05-1.png").default} />
							<div className="ul-right-div">
								<p className="p5">从0到1做品牌1.1-品牌如何影响销量</p>
								<p className="p6">您将学会：</p>
								<svg t="1611560068419" className="icon g" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16528" width="16" height="16"><path d="M87.04 501.76L404.48 819.2 936.96 281.6l-71.68-71.68-460.8 460.8-245.76-245.76-71.68 76.8z" p-id="16529" fill="#003348"></path></svg>
								<p className="p7">本章节可以帮助卖家破除认为品牌太高。太远的认识，并详细介绍更多...</p>
								<span className="sp1">5,867浏览量</span>
								<span className="sp1 sp2">02-十一月-20</span>
								<p className="p8">查看模块→</p>
							</div>
						</li>
						<li>
							<img src={require("../../assets/images/5-05-1.png" ).default}/>
							<div className="ul-right-div">
								<p className="p5">从0到1做品牌1.1-品牌如何影响销量</p>
								<p className="p6">您将学会：</p>
								<svg t="1611560068419" className="icon g" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16528" width="16" height="16"><path d="M87.04 501.76L404.48 819.2 936.96 281.6l-71.68-71.68-460.8 460.8-245.76-245.76-71.68 76.8z" p-id="16529" fill="#003348"></path></svg>
								<p className="p7">本章节可以帮助卖家破除认为品牌太高。太远的认识，并详细介绍更多...</p>
								<span className="sp1">5,867浏览量</span>
								<span className="sp1 sp2">02-十一月-20</span>
								<p className="p8">查看模块→</p>
							</div>
						</li>
						<li>
							<img src={require("../../assets/images/5-05-1.png").default} />
							<div className="ul-right-div">
								<p className="p5">从0到1做品牌1.1-品牌如何影响销量</p>
								<p className="p6">您将学会：</p>
								<svg t="1611560068419" className="icon g" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16528" width="16" height="16"><path d="M87.04 501.76L404.48 819.2 936.96 281.6l-71.68-71.68-460.8 460.8-245.76-245.76-71.68 76.8z" p-id="16529" fill="#003348"></path></svg>
								<p className="p7">本章节可以帮助卖家破除认为品牌太高。太远的认识，并详细介绍更多...</p>
								<span className="sp1">5,867浏览量</span>
								<span className="sp1 sp2">02-十一月-20</span>
								<p className="p8">查看模块→</p>
							</div>
						</li>
					</ul>
				</div>
				<div className="right">
					<p className="p4">特别推荐</p>
					<ul className="ul-right">
						<li>
							<img src={require("../../assets/images/5-05-1.png").default} />
							<div className="ul-right-div">
								<p className="p5">从0到1做品牌1.1-品牌如何影响销量</p>
								<p className="p6">您将学会：</p>
								<svg t="1611560068419" className="icon g" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16528" width="16" height="16"><path d="M87.04 501.76L404.48 819.2 936.96 281.6l-71.68-71.68-460.8 460.8-245.76-245.76-71.68 76.8z" p-id="16529" fill="#003348"></path></svg>
								<p className="p7">本章节可以帮助卖家破除认为品牌太高。太远的认识，并详细介绍更多...</p>
								<span className="sp1">5,867浏览量</span>
								<span className="sp1 sp2">02-十一月-20</span>
								<p className="p8">查看模块→</p>
							</div>
						</li>
						<li>
							<img src="../images/5-05-1.png" />
							<div className="ul-right-div">
								<p className="p5">从0到1做品牌1.1-品牌如何影响销量</p>
								<p className="p6">您将学会：</p>
								<svg t="1611560068419" className="icon g" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16528" width="16" height="16"><path d="M87.04 501.76L404.48 819.2 936.96 281.6l-71.68-71.68-460.8 460.8-245.76-245.76-71.68 76.8z" p-id="16529" fill="#003348"></path></svg>
								<p className="p7">本章节可以帮助卖家破除认为品牌太高。太远的认识，并详细介绍更多...</p>
								<span className="sp1">5,867浏览量</span>
								<span className="sp1 sp2">02-十一月-20</span>
								<p className="p8">查看模块→</p>
							</div>
						</li>
						<li>
							<img src="../images/5-05-1.png" />
							<div className="ul-right-div">
								<p className="p5">从0到1做品牌1.1-品牌如何影响销量</p>
								<p className="p6">您将学会：</p>
								<svg t="1611560068419" className="icon g" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16528" width="16" height="16"><path d="M87.04 501.76L404.48 819.2 936.96 281.6l-71.68-71.68-460.8 460.8-245.76-245.76-71.68 76.8z" p-id="16529" fill="#003348"></path></svg>
								<p className="p7">本章节可以帮助卖家破除认为品牌太高。太远的认识，并详细介绍更多...</p>
								<span className="sp1">5,867浏览量</span>
								<span className="sp1 sp2">02-十一月-20</span>
								<p className="p8">查看模块→</p>
							</div>
						</li>
					</ul>
				</div>
				<div className="right">
					<p className="p4">特别推荐</p>
					<ul className="ul-right">
						<li>
							<img src="../images/5-05-1.png" />
							<div className="ul-right-div">
								<p className="p5">从0到1做品牌1.1-品牌如何影响销量</p>
								<p className="p6">您将学会：</p>
								<svg t="1611560068419" className="icon g" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16528" width="16" height="16"><path d="M87.04 501.76L404.48 819.2 936.96 281.6l-71.68-71.68-460.8 460.8-245.76-245.76-71.68 76.8z" p-id="16529" fill="#003348"></path></svg>
								<p className="p7">本章节可以帮助卖家破除认为品牌太高。太远的认识，并详细介绍更多...</p>
								<span className="sp1">5,867浏览量</span>
								<span className="sp1 sp2">02-十一月-20</span>
								<p className="p8">查看模块→</p>
							</div>
						</li>
						<li>
							<img src="../images/5-05-1.png" />
							<div className="ul-right-div">
								<p className="p5">从0到1做品牌1.1-品牌如何影响销量</p>
								<p className="p6">您将学会：</p>
								<svg t="1611560068419" className="icon g" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16528" width="16" height="16"><path d="M87.04 501.76L404.48 819.2 936.96 281.6l-71.68-71.68-460.8 460.8-245.76-245.76-71.68 76.8z" p-id="16529" fill="#003348"></path></svg>
								<p className="p7">本章节可以帮助卖家破除认为品牌太高。太远的认识，并详细介绍更多...</p>
								<span className="sp1">5,867浏览量</span>
								<span className="sp1 sp2">02-十一月-20</span>
								<p className="p8">查看模块→</p>
							</div>
						</li>
						<li>
							<img src="../images/5-05-1.png" />
							<div className="ul-right-div">
								<p className="p5">从0到1做品牌1.1-品牌如何影响销量</p>
								<p className="p6">您将学会：</p>
								<svg t="1611560068419" className="icon g" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16528" width="16" height="16"><path d="M87.04 501.76L404.48 819.2 936.96 281.6l-71.68-71.68-460.8 460.8-245.76-245.76-71.68 76.8z" p-id="16529" fill="#003348"></path></svg>
								<p className="p7">本章节可以帮助卖家破除认为品牌太高。太远的认识，并详细介绍更多...</p>
								<span className="sp1">5,867浏览量</span>
								<span className="sp1 sp2">02-十一月-20</span>
								<p className="p8">查看模块→</p>
							</div>
						</li>
					</ul>
				</div>
			</div>
			
		</div>


            </div>
            <Footer/>
        </div>
    )
}

export default SellerUniversity