import React, { useRef, useEffect, useCallback, useState } from 'react';
import './index.less'
import { Button, Modal } from 'antd';
import Footer from 'components/addlisting_footer/FooterCom'
import { regSubmit } from 'utils/PublicLibrary';
import AJAX from "api/apis";


export default (props) => {
  const [value, onChange] = useState(false);
  const [date, setDtae] = useState([]);
  const [dateObj, setDateObj] = useState(null);
  useEffect(() => {
    getRegInfo()
  }, [])
  const getRegInfo = useCallback(() => {
    AJAX.login.getStoreRegisterInfo([]).then((res) => {
      for (let item of res.data) {
        if (item.propertyValue) {
          if (item.propertyKey == "authentication_videoconferencing_time") {
            let data = JSON.parse(item.propertyValue)
            setDtae(new Date(data.date).toDateString().split(" "))
            setDateObj(data)
          }
        }
      }
    })
  }, [])

  let nextEvent = useCallback(() => {
    regSubmit([], null, '', 18).then(res => {
      if (res.code == 1) {
        props.history.push('/twoStepVerification/Authenticator')
      }
    })

  })


  return (
    <div className='Approve'>
      <div className='s_login'>
        <div className='image'><img src={require('@assets/images/Approve.png').default} /></div>
        <b>Someone who knows your password is attempting to sign-in to your account.</b>
        <div className='txt'><span>When</span>{date[2] || ''} {date[1] || ''} {date[3] || ''} {dateObj && dateObj.bAction}</div>
        <div className='txt'><span>Device</span>Mozilla Firefox for Windows (Desktop)</div>
        <div className='txt'><span>Near</span>Same as current IP address</div>
        <div className='txt'><span></span>(47.106.140.135)</div>
        <Button onClick={nextEvent}>Approve</Button>
        <br></br>
        <Button>Deny</Button>
      </div>
      <Footer></Footer>
    </div>
  )
}