import React, { useRef, useEffect, useCallback, useState } from 'react';
import AJAX from "api/apis";
import { Switch, Checkbox } from "antd";
import IconFont from "../../../../../components/Icon/Icon";
import "./Banner-header.css"
import { render } from '@testing-library/react';
class BannerHeader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isSwitch: this.props.checked,
            crumbsList: []
        }
    }
    // function BannerHeader(props) {
    gaojiSwitch = (e) => {
        this.props.Switchse(e)
        this.setState({
            isSwitch: this.props.checked
        })
    }
    componentDidMount() {
        if (this.props.id) {
            this.getGoodsClassifyParentName()
        }
    }
    getGoodsClassifyParentName = () => {
        let arr = []
        AJAX.addProduct.getGoodsClassifyParentName({ classifyId: this.props.id }).then((res) => {
            if (res.code == 1 && res.data) {
                arr = res.data.split(">")
            }
            this.setState({
                crumbsList: arr
            })
        })
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            isSwitch: nextProps.checked
        })
        // if (nextProps.isSubmit) {
        //     this.form.validateFields().then((res) => {
        //         // this.props.canSubmit(1,true)
        //     }).catch((errr) => {
        //         // this.props.canSubmit(1,false)
        //         console.log(errr, "errr")
        //     })
        // }

    }
    render() {
        let { isSwitch, crumbsList } = this.state
        return <div>
            <div className="tab1-header">
                <div>
                    <span>高级视图</span>
                    <Switch size="small" checked={isSwitch} onChange={this.gaojiSwitch} />
                </div>
            </div>
            <div className="tab1-crumbs">
                {crumbsList.length > 0 ? crumbsList.map((item, index) => {
                    if (index < crumbsList.length - 1) {
                        return <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
                            <span className="tab1-crumbs-title">{item}</span>
                            <IconFont type="icon-fanhui-copy-copy" className="tab1-icon" />
                        </div>
                    } else {
                        return <span>{item}</span>
                    }
                }) : null}
            </div>
            <div className="tab1-hint">
                <IconFont type="icon-jinggao1" className="tab1-hint-icon" />
                {
                    this.props.flag ? <span className="tab1-hint-title">你可以选择暂时将商品保存为"不可售",稍后在添加商品报价。</span> : <span className="tab1-hint-title">当多个卖家通过单一详情页面销售相同的商品时，我们会整合最佳商品数据进行展示，以确保买家获得最佳体验。</span>
                }
                {
                    this.props.flag ? <div className="banner-checkbox-title">
                        <Checkbox>让我先跳过报价数据，我稍后在提供。</Checkbox>
                    </div> : ""
                }
            </div>
        </div>

    }
}

export default BannerHeader