import React from "react";
import "./ManageOrders.css";
import Header from "components/home_header";
import Footer from "components/addlisting_footer/FooterCom";
import IconFont from "components/Icon/Icon";
import { Tabs } from "antd";
import { Link } from 'react-router-dom'
import Ausstehend from './module/Ausstehend/Ausstehend'
import Unshipped from './module/Unshipped/Unshipped'
import Canceled from './module/Canceled/Canceled'
import Shipped from './module/Shipped/Shipped'
const { TabPane } = Tabs;

function ManageOrders() {
  return (
    <div>
      <Header />
      <div className="order_body">
        <div className="order_head">
          <div className="order_headI">
            <div>
              <h2 style={{ fontSize: '30px' }}>管理订单</h2>
            </div>
            <div className="order_headII">
              <div><Link to="/">了解更多信息</Link></div>
              <span>|</span>
              <div><Link to="/">视频教程</Link></div>
            </div>
          </div>

          <div className="order_headR">
            <div className="order_headR_lab">
              {/* <button>订单编号<IconFont type="icon-sanjiaoxing" /></button> */}
              <label>
                <select>
                  <option>订单编号</option>
                  <option>ASIN</option>
                  <option>卖家电子邮件</option>
                  <option>商品编号</option>
                  <option>SKU</option>
                  <option>商品名称</option>
                  <option>追踪编码</option>
                </select>
              </label>
            </div>
            <div className="order_headR_inp">
              <input placeholder="搜索" />
              <button>搜索</button>
            </div>
          </div>
        </div>
        <div>
          <div>卖家自配送</div>
          <div className="order_headR_tab">

            <Tabs defaultActiveKey="2" >
              <TabPane tab="0 等待中" key="1" >
                <Ausstehend />
              </TabPane>
              <TabPane tab="0 未发货" key="2">
                <Unshipped />
              </TabPane>
              <TabPane tab="已取消" key="3">
                <Canceled />
              </TabPane>
              <TabPane tab="已发货" key="4">
                <Shipped />
              </TabPane>
            </Tabs>
            <div className="order_headR_tabR"><Link to="/">查看亚马逊物流订单</Link><IconFont type="icon-fanhui-copy-copy" /></div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ManageOrders;
