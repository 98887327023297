import { Popover } from "antd";
import { useMemo } from "react";
import withProps from "@a-components/withProps";
import "./style.css";

const TitleTooltip = withProps(
  ({ title, desc, overlayClassName, ...otherProps }) => {
    const innerOverlayClassName = useMemo(() => {
      return [
        overlayClassName,
        "a-title-tooltip-overlay",
        title ? "has-title" : "",
      ].join(" ");
    }, [overlayClassName, title]);
    const content = useMemo(() => {
      const innerDesc = [].concat(desc).filter(Boolean);

      return innerDesc.map((item, index) => {
        return <div className="desc" key={index}>{item}</div>;
      });
    }, [desc]);

    return (
      <Popover
        overlayClassName={innerOverlayClassName}
        content={content}
        title={title}
        {...otherProps}
      ></Popover>
    );
  },
  {
    className: "a-title-tooltip",
  }
);

export default TitleTooltip;
