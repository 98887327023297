import React from 'react'
import './PricingHealth.css'
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import {Link} from 'react-router-dom'
import qdjg07 from 'assets/images/qdjg07_yaosi.png'
function PricingHealth(){
    return(
        <div>
            <Header/>


                <div>
                <div className="qd07_mytitle">
				<div className="qd07_titbox1">
					<p>定价状况<span>了解更多信息</span></p>
				</div>
				<div className="qd07_titbox2">
					<div className="qd07_con_x">X</div>
					<svg t="1610713529994" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14683"
					 width="40" height="40">
						<path d="M512 93.090909c232.727273 0 418.909091 186.181818 418.909091 418.909091s-186.181818 418.909091-418.909091 418.909091-418.909091-186.181818-418.909091-418.909091 186.181818-418.909091 418.909091-418.909091m0-93.090909C228.072727 0 0 228.072727 0 512s228.072727 512 512 512 512-228.072727 512-512S795.927273 0 512 0z"
						 fill="#2c2c2c" p-id="14684"></path>
						<path d="M512 279.272727m-46.545455 0a46.545455 46.545455 0 1 0 93.09091 0 46.545455 46.545455 0 1 0-93.09091 0Z"
						 fill="#2c2c2c" p-id="14685"></path>
						<path d="M512 791.272727c-27.927273 0-46.545455-18.618182-46.545455-46.545454V418.909091c0-23.272727 18.618182-46.545455 46.545455-46.545455 23.272727 0 46.545455 18.618182 46.545455 46.545455v325.818182c0 27.927273-18.618182 46.545455-46.545455 46.545454z"
						 fill="#2c2c2c" p-id="14686"></path>
					</svg>
					<div className="qd07_box_con">
						<p>注册亚马逊的"有竞争力的价格"规则。</p>
						<span>为了便于您使用"自动定价"自动做出定价决策，同时改善您的定价状况，亚马逊创建了多项预定义规则。您可以注册这些规则，方便管理定价。注册之前，可以先查看规则参数。要了解更多信息，请<span>点击此处</span>。</span>
					</div>
				</div>
			</div>

			<div className="qd07_zshou">
				<ul className="qd07_splist">
					<li>非在售商品</li>
					<li><Link to="/FeaturedOffer">推荐报价</Link></li>
					<li><Link to="">销售转换率</Link></li>
				</ul>
				<div className="qd07_gailaii">
					<p>非在售商品概览</p>
					<div className="qd07_glbox">
						<p>非在售<svg t="1610713187918" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
							 p-id="12436" width="20" height="20">
								<path d="M512 8a504.064 504.064 0 1 0 0.064 1008.064A504.064 504.064 0 0 0 512 8z m0 922.496A418.56 418.56 0 0 1 512 93.504a418.56 418.56 0 0 1 0 836.992z"
								 p-id="12437" fill="#00BFFF"></path>
								<path d="M457.984 710.016a54.016 54.016 0 1 0 108.032 0 54.016 54.016 0 0 0-108.032 0zM484.992 584h54.016c4.928 0 8.96-4.032 8.96-8.96V268.928a9.024 9.024 0 0 0-8.96-8.96h-54.016a9.024 9.024 0 0 0-8.96 8.96v305.984c0 4.992 4.032 9.024 8.96 9.024z"
								 p-id="12438" fill="#00BFFF"></path>
							</svg></p>
						<div className="qd07_gl_con">
							<p>1 <svg t="1610713084650" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
								 p-id="12295" width="15" height="15">
									<path d="M512 4.12672c280.49408 0 507.87328 227.3792 507.87328 507.87328 0 280.49408-227.3792 507.87328-507.87328 507.87328C231.50592 1019.87328 4.12672 792.49408 4.12672 512 4.12672 231.50592 231.50592 4.12672 512 4.12672zM512 685.96736c-42.47552 0-76.91264 34.42688-76.91264 76.91264 0 42.47552 34.43712 76.91264 76.91264 76.91264 42.47552 0 76.91264-34.43712 76.91264-76.91264C588.91264 720.39424 554.47552 685.96736 512 685.96736zM509.78816 625.83808c36.58752 0 66.24256-29.66528 66.24256-66.24256l0-309.1456c0-36.58752-29.65504-66.24256-66.24256-66.24256-36.58752 0-66.24256 29.66528-66.24256 66.24256l0 309.1456C443.5456 596.18304 473.20064 625.83808 509.78816 625.83808z"
									 fill="#d81e06" p-id="12296"></path>
								</svg></p>
							<span>
								采取措施
							</span>
						</div>
					</div>
				</div>
				<div className="qd07_cuos">
					<span>采取措施</span>
					<p>重新启售您的商品，并根据<span>亚马逊商城公平定价政策</span>更新您的报价，从而避免定价错误。以下售当前因潜在定价错误而处于非在售状态的商品列表。亚马逊将根据法律/合规性问题、推荐报价等因素检测是否存在潜在的定价错误。参考价可能参考推荐报价（购买按钮）、60天评价售价、14天最高价（由亚马逊配送和销售）或市场价。</p>
				</div>
				<p className="qd07_fizs">非在售商品</p>
				<ul className="qd07_splist">
					<li>商品因潜在错价而被停售</li>
				</ul>
				<ul className="qd07_fzsList">
					<li>
						<div className="qd07_miaos qd07_miaos1">
							<p>商品名称</p>
							<p>ASIN</p>
						</div>
						<div className="qd07_miaos2 qd07_flex1">
							<img src={qdjg07} id="qd07_iskey" />
							<div className="qd07_key_ms">
								<p>Bingcute 6 Type of 30Pcs Bronze Vintage</p>
								<p>Large Skeleton Keys -Vint...</p>
								<p>A SIN：B01HOLUS0Y</p>
								<p>SKU：minhoushen</p>
								<p>条件：New</p>
							</div>
						</div>
					</li>
					<li>
						<div className="qd07_miaos">
							<p>定价状况问题</p>
							<p>描述</p>
						</div>
						<div className="qd07_miaos2">
							<div className="qd07_bufuhe">
								<p>一个或多个与定价相关的字段不符合亚马逊商城公平定价政策。</p>
								<p>提示：<span>潜在的定价过高错误! 请考虑使用提供的参考价更新您的价格。</span><svg t="1610713386370" className="icon" viewBox="0 0 1024 1024"
									 version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13235" width="18" height="18">
										<path d="M512 1014.881524C234.25219 1014.881524 9.094095 789.699048 9.094095 511.975619S234.25219 9.069714 512 9.069714c277.723429 0 502.881524 225.133714 502.881524 502.857143 0 277.74781-225.158095 502.881524-502.881524 502.881524v0.073143z m0-67.096381c240.688762 0 435.785143-195.096381 435.785143-435.785143 0-240.688762-195.096381-435.785143-435.785143-435.785143-240.688762 0-435.785143 195.096381-435.785143 435.785143 0 240.688762 195.096381 435.785143 435.785143 435.785143z m41.910857-341.820953v23.478858H470.064762v-23.405715c0-53.784381 25.35619-104.594286 61.610667-143.262476 27.745524-29.525333 26.989714-28.696381 34.864761-38.034286 20.918857-24.941714 29.257143-42.73981 29.257143-63.561142a83.797333 83.797333 0 1 0-167.619047 0h-83.797334a167.594667 167.594667 0 0 1 335.189334 0c0 43.666286-16.408381 78.823619-48.835048 117.369904-5.485714 6.582857-11.361524 13.263238-18.748952 21.089524-2.779429 3.023238-5.680762 6.168381-9.581715 10.288762l-9.581714 10.215619c-22.991238 24.478476-38.936381 56.466286-38.936381 85.894095v-0.073143z m-6.436571 121.782858c9.679238 9.313524 15.067429 22.235429 14.823619 35.669333a48.079238 48.079238 0 0 1-14.823619 35.84c-9.362286 9.435429-22.186667 14.677333-35.474286 14.506667a49.030095 49.030095 0 0 1-35.474286-14.506667 48.030476 48.030476 0 0 1-14.823619-35.84c0-13.945905 4.924952-25.892571 14.823619-35.596191a48.664381 48.664381 0 0 1 35.474286-14.628571c13.750857 0 25.6 4.85181 35.474286 14.628571v-0.073142z"
										 p-id="13236" fill="#00BFFF"></path>
									</svg></p>
							</div>
						</div>
					</li>
					<li className="qd07_myp_right">
						<div className="qd07_miaos qd07_mar1">
							<p>价格</p>
							<p id="yunf">+ 运费</p>
						</div>
						<div className="qd07_miaos2 qd07_iscank">
							<div className="qd07_myjiage">
								<p>$50.00</p>
								<p>--.--</p>
							</div>
						</div>
					</li>
					<li className="qd07_myp_right">
						<div className="qd07_miaos qd07_mar2">
							<p>促销价格</p>
							<p></p>
						</div>
						<div className="qd07_miaos2 qd07_iscank">
							<div className="qd07_myto">
								--.--
							</div>
						</div>
					</li>
					<li className="qd07_myp_right">
						<div className="qd07_miaos qd07_mar3">
							<p>企业商品价格</p>
							<p>+ 运费</p>
						</div>
						<div className="qd07_miaos2 qd07_iscank">
							<div className="qd07_myshez">
								<p>未设置</p>
								<p>--.--</p>
							</div>
						</div>
					</li>
					<li className="qd07_myp_right">
						<div className="qd07_miaos qd07_mar4">
							<p>参考价</p>
							<p>参考价来源</p>
						</div>
						<div className="qd07_miaos2 qd07_iscank">
							<div className="qd07_myck">
								<p>$7.49</p>
								<p>平均售价</p>
							</div>
							<div className="qd07_myck">
								<p>$7.99</p>
								<p>推荐报价</p>
							</div>
						</div>
					</li>
					<li className="qd07_myp_right">
						<div className="qd07_miaos qd07_mar4">
							<p>您的最低价格</p>
							<p></p>
						</div>
						<div className="qd07_miaos2 qd07_iscank">
							<div className="qd07_myto">
								--.--
							</div>
						</div>
					</li>
					<li className="qd07_myp_right">
						<div className="qd07_miaos qd07_mar4">
							<p>您的最高价格</p>
							<p></p>
						</div>
						<div className="qd07_miaos2 qd07_iscank">
							<div className="qd07_myto">
								--.--
							</div>
						</div>
					</li>
					<li>
						<div className="qd07_miaos qd07_mar5">
							<p>操作</p>
							<p></p>
						</div>
						<div className="qd07_miaos2">
							<div className="qd07_gengxin">
								<p>更新</p>
							</div>
						</div>
					</li>
				</ul>
				<div className="qd07_gengduo">
					<div>显示更多</div>
				</div>
			</div>

                </div>




            <Footer/>


        </div>
    )
}

export default PricingHealth