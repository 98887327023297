import React from 'react'
import './mobile.css'


function moblie(){
    return(
        <div>
        <div className="moblie_body">
            <div className="desktop_link">
                <div>预览链接</div>
            </div>
            <div className="moblie_NavBox">
                <div className="moblie_nav">

                    <div className="moblie_NavII">
                        <div className="moblie_Nav_img">图片</div>
                        <div className="moblie_Nav_name">
                            <p>Use of steam engine</p>
                            <div>When using the Steam Sauna Box Black , insert the steam engine on the left</div>
                        </div>
                    </div>


                    <div className="moblie_NavII">
                        <div className="moblie_Nav_img">图片</div>
                        <div className="moblie_Nav_name">
                            <p>Use of steam engine</p>
                            <div>When using the Steam Sauna Box Black , insert the steam engine on the left</div>
                        </div>
                    </div>



                    <div className="moblie_NavII">
                        <div className="moblie_Nav_img">图片</div>
                        <div className="moblie_Nav_name">
                            <p>Use of steam engine</p>
                            <div>When using the Steam Sauna Box Black , insert the steam engine on the left</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


        </div>
    )
}
export default moblie