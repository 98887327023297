import React from 'react'
import './advertisementLeft.css'
import {Link} from 'react-router-dom'
import IconFont from 'components/Icon/Icon'
import Imagegg from '../../../../assets/images/gg06_logo.png'

function AdvertisementLeft(){
    return(
            
            <div className="gg5_myleft">
            <div className="g5_myleft_logo"> <IconFont type="icon-hanbao" /></div>
        
            <div className="gg06_sidebar">
			<ul>
				<Link ><li><img src={Imagegg} /></li></Link>
				<Link  to="/Advertisement"><li className="gg06_sidebar_li">广告活动</li></Link>
				<Link to="/Mengk" ><li className="gg06_sidebar_li">品牌旗舰店</li></Link>
				<Link><li className="gg06_sidebar_li">帖子<svg t="1610767341772" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="21038" width="15" height="15"><path d="M189.76 721.664c136.128-340.672 463.488-279.552 463.488-279.552v186.304L1024 348.992 653.248 69.44v139.776C143.424 316.928 189.76 721.664 189.76 721.664m559.04 153.28H79.68v-525.76h184.896c27.776-31.872 61.248-63.744 101.504-95.616H0V954.56h828.48V568.512l-79.68 64.576v241.856z" fill="#ffffff" p-id="21039"></path></svg></li></Link>
				<Link to="/AdvertisingReports"><li className="gg06_sidebar_li">报告</li></Link>
				<Link to="/BatchOperation"><li className="gg06_tx_min gg06_segmentation gg06_sidebar_li">批量操作</li></Link>
				<Link><li className="gg06_tx_min gg06_sidebar_li">账单与付款</li></Link>
				<Link to="/AccessSettings"><li className="gg06_tx_min gg06_sidebar_li">访问和设置</li></Link>
				<Link><li className="gg06_tx_min gg06_sidebar_li">支持中心</li></Link>
				<Link><li className="gg06_tx_min gg06_sidebar_li">培训课程<svg t="1610767341772" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="21038" width="15" height="15"><path d="M189.76 721.664c136.128-340.672 463.488-279.552 463.488-279.552v186.304L1024 348.992 653.248 69.44v139.776C143.424 316.928 189.76 721.664 189.76 721.664m559.04 153.28H79.68v-525.76h184.896c27.776-31.872 61.248-63.744 101.504-95.616H0V954.56h828.48V568.512l-79.68 64.576v241.856z" fill="#ffffff" p-id="21039"></path></svg></li></Link>
				<Link to="/"><li className="gg06_tx_min gg06_sidebar_li">卖家平台<svg t="1610767341772" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="21038" width="15" height="15"><path d="M189.76 721.664c136.128-340.672 463.488-279.552 463.488-279.552v186.304L1024 348.992 653.248 69.44v139.776C143.424 316.928 189.76 721.664 189.76 721.664m559.04 153.28H79.68v-525.76h184.896c27.776-31.872 61.248-63.744 101.504-95.616H0V954.56h828.48V568.512l-79.68 64.576v241.856z" fill="#ffffff" p-id="21039"></path></svg></li></Link>
			</ul>
		</div>
      </div> 



    )
}

export default AdvertisementLeft