import React from 'react'
import './DevelopApp.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import IconFont from 'components/Icon/Icon'

function DevelopApp(){
    return(
        <div>
            <Header/>
            <div className="DevelopApp_head">
                <div className="DevelopApp_head_titel">开发者中心</div>
                <div className="DevelopApp_head_titelII">您的应用程序客户端会显示在此处。<span>了解更多信息</span></div>

                <div className="DevelopApp_data">
                    <div className="DevelopApp_data_titel">
                        <div className="DevelopApp_data_color"></div>
                        <div style={{color:"#008296"}}>您的开发者资料</div>
                    </div>
                    <div className="DevelopApp_data_content">
                        <div className="DevelopApp_data_contentI">
                            <div>授权日期</div>
                            <div>6/29/2017</div>
                        </div>
                        <div className="DevelopApp_data_contentII"> 
                            <div>开发者 ID</div>
                            <div>570687757040</div>
                        </div>
                        <div className="DevelopApp_data_contentI">
                            <div>MWS 凭证</div>
                            <div style={{color:"#008296"}}>查看</div>
                        </div>
                    </div>
                </div>


                <div className="DevelopApp_warn">
                <div className="DevelopApp_warnBox">
                    <div className="DevelopApp_warn_icon"><IconFont type="icon-jinggao4" /></div>
                    <div className="DevelopApp_warn_contentBox">
                        <div className="DevelopApp_warn_contentI">
                            <span>您需要填写开发人员资料</span><br/>
                             作为开发人员注册的一部分，您需要填写您的开发人员资料。完成后，您才可添<br/>
                                加新的应用程序客户端</div>
                        <div className="DevelopApp_warn_content_btn"><button>转至"开发人员资料"</button></div>
                    </div>
                </div>
                </div>


                <div className="DevelopApp_Add">
                    <button>+添加新的 App客户端</button>
                </div>


                <div   className="DevelopApp_button">您当前没有应用程序客户端</div>

            </div>
            <Footer/>
        </div>
    )
}

export default DevelopApp