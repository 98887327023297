import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./CommodityPromotion.css";
import {
  QuestionCircleOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  DownOutlined,
  SearchOutlined,
  UpOutlined,
} from "@ant-design/icons";
import Reactours from "components/reactours"
import TipsIcon from "components/tipsIcon"
import { Input, Steps, Tabs, Space, Button, message } from "antd";
import { ACard, AButton } from "@a-components";
import FormCollection, {
  FormItem,
  FormItems,
} from "@components/FormCollection";
import moment from "moment";
import { useQuery, useMemory } from "@hooks";

import Apis from "@api/apis";

import { Link, useHistory } from "react-router-dom";
import SettingCard from "./SettingCard";
import BiddingStrategy from "./BiddingStrategy";
import GoodsCard from "./GoodsCard";
import KeywordDeliveryCard from "./KeywordDeliveryCard";
import AutoDelivery from "./AutoDelivery";
import ProductLaunch from "./ProductLaunch";
import NegateKeywordDelivery from "./NegateKeywordDelivery";
import NegateProductLaunch from "./NegateProductLaunch";

import { autoDeliveryInitValues } from "./AutoDelivery/config";
import { settingFormInitialValues } from "./SettingCard/config";
import { productLaunchFormInitValues } from "./ProductLaunch/config";
import { goodsCardInitValues } from "./GoodsCard/config";
import { KeywordDeliveryInitFormValues } from "./KeywordDeliveryCard/config";
import { biddingStrategyInitValues } from "./BiddingStrategy/config";
import { negateKeywordInitValues } from "./NegateKeywordDelivery/config";
import { negateProductLaunchInitValues } from "./NegateProductLaunch/config";

import { local2Remote, remote2Local } from "./config";

const { TabPane } = Tabs;
const { Step } = Steps;
const { TextArea } = Input;

const formInitValues = {
  ...settingFormInitialValues,
  // orientationStrategyType2: "keyword",
  ...biddingStrategyInitValues,
  adGroupName: "Ad group 1",
  ...autoDeliveryInitValues,
  ...productLaunchFormInitValues,
  ...goodsCardInitValues,
  ...KeywordDeliveryInitFormValues,
  ...negateKeywordInitValues,
  ...negateProductLaunchInitValues,
};

function CommodityPromotion() {
  const [isBtnLoading, setBtnLoading] = useState(false);
  const isTourOpen = true;
  const steps1 = [
    {
      selector: '.form-items .adActiveName',
      content: '设置广告活动名称，名称必须要跟操作资料中一致，不能重复添加',
    },
    {
      selector: '.form-items .adGroup',
      content: '选择广告组合',
    },
    {
      selector: '.form-items .adStartTime',
      content: '设置广告开始时间和结束时间',
    },
    {
      selector: '.form-items .budget',
      content: '设置每日广告费预算',
    },
    {
      selector: '.form-items .orientationStrategyType',
      content: '选择自动投放/手动投放',
    },
    {
      selector: '.a-form .strategy',
      content: '选择活动广告竞价模式',
    },
    {
      selector: '.a-form .adGroupName',
      content: '设置广告组名称，名称必须要跟操作资料中一致，不能重复添加',
    },
    {
      selector: '.a-form .card-body--no-padding',
      content: '设置要打广告的商品（直接搜索SKU、ASIN/输入ASIN添加列表/CSV模板），商品必须要跟操作资料中一致',
    },
    {
      selector: '.a-form .automaticDelivery',
      content: '设置自动投放默认出价',
    },
    {
      selector: '.a-form .card-body--no-padding .h-44',
      content: '否定关键词定位',
    },
    {
      selector: '.a-form .card-body--no-padding textarea',
      content: '添加否定关键词',
    },
  ]
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const awakenTips = (type) => {
    setIsActive(type);
  }
  const mainFormData = useMemo(
    () => ({
      ...formInitValues,
      adActiveName: `广告活动 - ${moment().format("MM/DD/YYYY HH:mm:ss")}`,
    }),
    []
  );

  const history = useHistory();
  const { type, advertisingId } = useQuery();
  const [actionType, setActionType] = useMemory("save");
  const [advertisingGroupId, setAdvertisingGroupId] = useMemory(0);

  const [form] = FormCollection.useForm();

  const saveAdvertising = useCallback(
    (type = "save") => {
      const formValues = form.getFieldsValue(true);
      setBtnLoading(true);

      const request = advertisingId
        ? Apis.advertising.updateAdvertising
        : Apis.advertising.addAdvertising;
      return request({
        ...local2Remote(formValues),
        advertisingGroupId: advertisingGroupId.current,
        advertisingId,
        isDraft: type === "draft" ? 1 : 0,
      })
        .then((res) => {
          return res;
        })
        .finally(() => {
          setBtnLoading(false);
        });
    },
    [advertisingGroupId, advertisingId, form]
  );

  const queryAdvertisingDetail = useCallback(() => {
    if (!type) return Promise.resolve();

    return Apis.advertising
      .getAdvertisingDetail({ advertisingId })
      .then((res) => {
        if (res.code !== 1) return res;
        const { advertisingGroupId } = res.data;

        setAdvertisingGroupId(advertisingGroupId);
        const formValues = remote2Local(res.data);
        form.setFieldsValue(formValues);
        return res;
      });
  }, [advertisingId, form, setAdvertisingGroupId, type]);

  useEffect(() => {
    queryAdvertisingDetail();
  }, [queryAdvertisingDetail]);

  const handleSave = useCallback(
    (type) => {
      setActionType(type);
      form.submit();
    },
    [form, setActionType]
  );

  const handleSaveAsDraft = useCallback(() => {
    return form
      .validateFields(["adActiveName", "budget"])
      .then(() => {
        handleSave("draft");
      })
      .catch(({ errorFields }) => {
        message.warn(errorFields[0].errors[0]);
      });
  }, [form, handleSave]);

  const handleFormFinish = () => {
    if (loading) return
    setLoading(true)
    saveAdvertising(actionType.current).then((res) => {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
      if (res.code !== 1) return;
      const { advertisingGroupId, advertisingId } = res.data;

      setAdvertisingGroupId(advertisingGroupId);
      history.replace(
        `/CommodityPromotion?${new URLSearchParams({
          type: "edit",
          advertisingId,
        })}`
      );

      history.push(
        `/Advertisement/CreateAdvertisingSuccess?advertisingId=${advertisingId}`
      );
    }).catch(() => {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    });
  };

  const handleFormValuesChange = useCallback(
    (changedValues, allValues) => {
      const changedKeys = Object.keys(changedValues);
      if (changedKeys.includes("orientationStrategyType")) {
        const { orientationStrategyType } = changedValues;
        // 重置定向策略板块
        form.setFieldsValue({
          orientationStrategyType2: "keyword",
          adBiddingStrategy: orientationStrategyType === "automatic" ? 2 : 1,
        });
      }

      if (changedKeys.includes("adStartTime")) {
        form.resetFields(["adEndTime"]);
      }

      if (changedKeys.includes("autoDeliveryType")) {
        if (!allValues.orientationGroupBidding) {
          form.setFieldsValue({
            orientationGroupBidding:
              autoDeliveryInitValues.orientationGroupBidding,
          });
        }
      }
    },
    [form]
  );

  return (
    <div className="p-r">
      {/* 头部 */}
      <div
        className="df jc-sb ai-c pl-24 pr-24 bg-f8 p-s t-0 l-0 r-0"
        style={{ boxShadow: "0 2px 4px 1px #f0eeee", height: 60, zIndex: 9 }}
      >
        <div className="">
          <Space>
            <span
              onClick={() => history.goBack()}
              style={{ cursor: "pointer" }}
            >
              <CloseOutlined />
            </span>
            <span className="fs-16">新建广告活动</span>
          </Space>
        </div>
        <div>
          <Space>
            <AButton type="link" onClick={() => history.push("/Advertisement")}>
              返回广告活动
            </AButton>
            <AButton
              className="fs-12"
              disabled={isBtnLoading}
              loading={isBtnLoading && actionType.current === "draft"}
              onClick={handleSaveAsDraft}
            >
              另存为草稿
            </AButton>
            <AButton
              className="fs-12"
              type="primary"
              disabled={isBtnLoading}
              loading={isBtnLoading && actionType.current === "save"}
              onClick={() => handleSave("save")}
            >
              启动广告活动
            </AButton>
            <span>
              <QuestionCircleOutlined />
            </span>
          </Space>
        </div>
      </div>

      {/* 主体 */}
      <div className="df fd-c mh-auto" style={{ width: 864 }}>
        <FormCollection
          form={form}
          initialValues={mainFormData}
          onFinish={handleFormFinish}
          onValuesChange={handleFormValuesChange}
        // onFinishFailed={handleFormFailed}
        >
          <h2>创建广告活动</h2>

          <ACard
            style={{ marginBottom: 20 }}
            title="设置"
            extra={
              <Button icon={<QuestionCircleOutlined />} type="link">
                请参阅"广告活动设置指南"
              </Button>
            }
          >
            <SettingCard />
          </ACard>
          {/* 广告活动竞价策略 */}
          <ACard
            style={{ marginBottom: 20 }}
            title="广告活动竞价策略"
            className="strategy"
            titleTooltip="选择您想要为广告点击付费的方式。"
            extra={
              <Button icon={<QuestionCircleOutlined />} type="link">
                选择竞价策略
              </Button>
            }
          >
            <BiddingStrategy />
          </ACard>

          {/* 创建广告组 */}
          <div className="Createadgroupsettings" style={{ marginBottom: 20 }}>
            <span>创建广告组</span>
            <div>
              广告组是一组共享相同关键词和商品的广告。请考虑属于相同分类和价格范围的商品分为一组。你可以在启动后编辑你的广告活动，以在"广告活动管理"中创建额外的广告组。
            </div>
          </div>

          <ACard
            style={{ marginBottom: 20 }}
            title="设置"
            extra={
              <Button icon={<QuestionCircleOutlined />} type="link">
                创建广告组
              </Button>
            }
          >
            <FormItem
              template={{
                label: "广告组名称",
                element: "AInput",
                name: "adGroupName",
                className: "adGroupName",
                style: { width: 300 },
                config: { placeholder: "季节性商品" },
                tooltip: "仅在您管理广告时可见。",
                rules: [{ required: true, message: "请提供广告组名称" }],
              }}
            />
          </ACard>

          <ACard
            style={{ marginBottom: 20 }}
            className="card-body--no-padding"
            title="商品"
            titleTooltip="添加您想要在此广告活动中推广的商品。"
            extra={
              <Button icon={<QuestionCircleOutlined />} type="link">
                添加要推广的商品
              </Button>
            }
          >
            <GoodsCard />
          </ACard>

          <FormItem noStyle shouldUpdate>
            {(form) => {
              const orientationStrategyType = form.getFieldValue(
                "orientationStrategyType"
              );

              if (orientationStrategyType !== "automatic") return;
              return (
                <ACard
                  style={{ marginBottom: 20 }}
                  title="自动投放"
                  className='automaticDelivery'
                  titleTooltip="自动投放使用关键词和商品来帮助您的广告在搜索结果和商品详情页中展示。"
                  extra={
                    <Button icon={<QuestionCircleOutlined />} type="link">
                      选择愿意为点击支付的费用
                    </Button>
                  }
                >
                  <AutoDelivery />
                </ACard>
              );
            }}
          </FormItem>

          <FormItem
            noStyle
            // dependencies={[
            //   "orientationStrategyType",
            //   "orientationStrategyType2",
            // ]}
            shouldUpdate
          >
            {(form) => {
              const orientationStrategyType = form.getFieldValue(
                "orientationStrategyType"
              );

              if (orientationStrategyType !== "manual") return;
              return (
                <ACard
                  style={{ marginBottom: 20 }}
                  title="定向策略"
                  titleTooltip="定向使用关键词和商品来帮助您的广告在搜索结果和商品详情页中展示。"
                >
                  <div style={{ marginBottom: 12 }}>
                    您可以向广告活动添加多个广告组，但只能为每个广告组选择一种投放类型。
                  </div>
                  <FormItems
                    options={[
                      {
                        element: "ARadioGroup",
                        name: "orientationStrategyType2",
                        config: {
                          className: "w-100percent",
                          options: [
                            {
                              label: "关键词投放",
                              value: "keyword",
                              description: (
                                <>
                                  选择有助于您的商品针对顾客搜索展示的关键词。
                                  <br />
                                  <Button type="link" style={{ padding: 0 }}>
                                    了解更多信息
                                  </Button>
                                </>
                              ),
                              extra:
                                "如果您知道顾客在搜索与您的商品类似的商品时所用的搜索词，请使用这一策略。",
                            },
                            {
                              label: "商品投放",
                              value: "goods",
                              description: (
                                <>
                                  选择特定商品、品类、品牌或其他商品特征来定向您的广告。
                                  <br />
                                  <Button type="link" style={{ padding: 0 }}>
                                    了解更多信息
                                  </Button>
                                </>
                              ),
                              extra:
                                "使用此策略可帮助顾客在亚马逊上浏览商品详情页和分类或搜索商品时发现您的商品。",
                            },
                          ],
                        },
                      },
                    ]}
                  />
                </ACard>
              );
            }}
          </FormItem>

          <FormItem noStyle shouldUpdate>
            {(form) => {
              const { orientationStrategyType2, orientationStrategyType } =
                form.getFieldsValue(true);
              if (orientationStrategyType !== "manual") return;
              if (orientationStrategyType2 !== "keyword") return;
              return (
                <ACard
                  style={{ marginBottom: 20 }}
                  title="关键词投放"
                  className="card-body--no-padding"
                  titleTooltip="关键词（词组和短语）用于将您的广告与顾客用来查找商品的搜索词匹配。查看关键词定向优秀实践"
                  extra={
                    <Button icon={<QuestionCircleOutlined />} type="link">
                      选择要定位的关键词
                    </Button>
                  }
                >
                  <KeywordDeliveryCard form={form} />
                </ACard>
              );
            }}
          </FormItem>

          <FormItem noStyle shouldUpdate>
            {(form) => {
              const { orientationStrategyType, orientationStrategyType2 } =
                form.getFieldsValue();

              if (orientationStrategyType !== "manual") return;
              if (orientationStrategyType2 !== "goods") return;
              return (
                <ACard
                  style={{ marginBottom: 20 }}
                  title="商品投放"
                  titleTooltip="帮助顾客通过选择与您的商品相关的品类、商品、品牌或功能来找到您的商品。"
                >
                  <ProductLaunch />
                </ACard>
              );
            }}
          </FormItem>
          <FormItem noStyle shouldUpdate>
            {(form) => {
              const { orientationStrategyType2, orientationStrategyType } =
                form.getFieldsValue(true);
              let isRender =
                orientationStrategyType === "automatic" ||
                (orientationStrategyType2 === "keyword" &&
                  orientationStrategyType === "manual");

              if (!isRender) return null;
              return (
                <ACard
                  style={{ marginBottom: 20 }}
                  title="否定关键词投放"
                  className="card-body--no-padding"
                  titleTooltip="当顾客的搜索词与您的否定关键词匹配时，否定关键词会阻止您的广告展示。您可以排除不相关的搜索，从而降低广告成本。"
                  description="可选"
                  collapsible
                >
                  <NegateKeywordDelivery form={form} />
                </ACard>
              );
            }}
          </FormItem>

          <FormItem noStyle shouldUpdate>
            {(form) => {
              const { orientationStrategyType2, orientationStrategyType } =
                form.getFieldsValue(true);

              let isRender =
                orientationStrategyType === "automatic" ||
                (orientationStrategyType2 === "goods" &&
                  orientationStrategyType === "manual");

              if (!isRender) return null;

              return (
                <ACard
                  style={{ marginBottom: 20 }}
                  title="否定商品定向"
                  titleTooltip="否定商品定向会防止您的广告在顾客的搜索内容与您的否定商品选择匹配时展示。这有助于排除不相关的搜索，从而减少广告费用。"
                  description="可选"
                  collapsible
                >
                  <NegateProductLaunch form={form} />
                </ACard>
              );
            }}
          </FormItem>
        </FormCollection>

        <div className="df jc-fe">
          <Space>
            <AButton
              className="fs-12"
              disabled={isBtnLoading}
              loading={isBtnLoading && actionType.current === "draft"}
              onClick={handleSaveAsDraft}
            >
              另存为草稿
            </AButton>
            <AButton
              className="fs-12"
              type="primary"
              disabled={isBtnLoading}
              loading={isBtnLoading && actionType.current === "save"}
              onClick={() => handleSave("save")}
            >
              启动广告活动
            </AButton>
          </Space>
        </div>
      </div>
      <div className="CommodityPromotion-foot">
        © 2020
        Amazon.com,lnc.或其他附属公司。使用条款&nbsp;&nbsp;&nbsp;&nbsp;隐私条款&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Amazon广告协议
      </div>
      <TipsIcon clicks={awakenTips} />
      {/* 引导步骤 */}
      <Reactours steps={steps1} isActive={isActive} clicks={awakenTips} routePath="CommodityPromotion" isOpen={isTourOpen} />
    </div>
  );
}

export default CommodityPromotion;
