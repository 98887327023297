import "./tabs.css"
import React from "react";
import TabHeader from "./module/Tab-header/Tab-header";
import FooterCom from "components/addlisting_footer/FooterCom";
import getUrlData from "@/utils/getUrlData";
export default class CreateListing extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        console.log(this.props);
    }
    render() {
        let classifyId = getUrlData('classifyId')
        let goodsId = getUrlData('goodsId') || ''
        let id = getUrlData('id') || ''
        console.log(classifyId, goodsId, id);
        return <div className="tabs-container">
            {
                (id || goodsId) && <TabHeader props={this.props}
                    classifyId={classifyId}
                    goodsId={goodsId}
                    id={id}
                />
            }
            <FooterCom />
        </div>
    }
}