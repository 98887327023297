import React from 'react'
import './Preformance.css'
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'


function Preformance() {
	return (
		<div>
			<Header />
			<div>

				<div className="end11_title">
					<div className="end11_title_h2">业绩通知</div>
					<div className="end11_title_p1">您将在下面看到在过去 365 天内发送给您的绩效通知。请查看这些通知，了解可能影响您的账户状况及您是否能销售的重要提醒。如有任何关于账户状况的问题，请直接回复通知。否则，请<span>联系我们</span>。</div>
				</div>

				<div className="end11_paging">
					<div className="end11_pagbox">
						<select>
							<option>每页25</option>
						</select>
						<select>
							<option>过滤</option>
						</select>
					</div>
				</div>

				<div className="end11_tab">
					<ul className="end11_tab_1">
						<li>
							<ul className="end11_tab_1_1 end11_li1">
								<li>主题</li>
								<li>日期</li>
								<li>操作</li>
							</ul>
						</li>
						<li>
							<ul className="end11_tab_1_1">
								<li>Notification of Restricted Products Removal</li>
								<li>2020年10月22日</li>
								<li></li>
							</ul>
						</li>
						<li>
							<ul className="end11_tab_1_1">
								<li>您的亚马逊买家账户</li>
								<li>2020年10月1日</li>
								<li></li>
							</ul>
						</li>
						<li>
							<ul className="end11_tab_1_1">
								<li>Notification of Restricted Products Removal</li>
								<li>2020年10月1日</li>
								<li></li>
							</ul>
						</li>
						<li>
							<ul className="end11_tab_1_1">
								<li>Notification of Restricted Products Removal</li>
								<li>2020年10月1日</li>
								<li></li>
							</ul>
						</li>
						<li>
							<ul className="end11_tab_1_1">
								<li>Notification of Restricted Products Removal</li>
								<li>2020年10月1日</li>
								<li></li>
							</ul>
						</li>
						<li>
							<ul className="end11_tab_1_1">
								<li>Notification of Restricted Products Removal</li>
								<li>2020年10月1日</li>
								<li></li>
							</ul>
						</li>
						<li>
							<ul className="end11_tab_1_1">
								<li>Notification of Restricted Products Removal</li>
								<li>2020年10月1日</li>
								<li></li>
							</ul>
						</li>
						<li>
							<ul className="end11_tab_1_1">
								<li>Notification of Restricted Products Removal</li>
								<li>2020年10月1日</li>
								<li></li>
							</ul>
						</li>
						<li>
							<ul className="end11_tab_1_1">
								<li>Notification of Restricted Products Removal</li>
								<li>2020年10月1日</li>
								<li></li>
							</ul>
						</li>

					</ul>
				</div>

				<div className="end11_jump">
					<div className="end11_jump_1">
						<input type="text" value="1" />
						<span>/2</span>
						<button type="button">开始</button>
					</div>
					<div className="end11_jump_2">
						<span><svg t="1611319298479" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
							p-id="2528" width="15" height="15">
							<path d="M737.14166666 853.78633333l-56.285 58.26-393.996-392.021 393.996-392.02099999 56.285 58.25999999-331.786 333.76100002z"
								p-id="2529"></path>
						</svg></span>
						<ul>
							<li>1</li>
							<li>2</li>
						</ul>
						<span><svg t="1611319442956" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
							p-id="2669" width="15" height="15">
							<path d="M309.58399999 63.706c-13.02300001 12.711-32.599 31.816-55.38599999 54.054C386.691 247.668 518.423 376.84 650.901 506.735c-135.675 133.566-267.109 262.952-398.507 392.311 25.771 24.939 45.768 44.286 63.432 61.382 150.09299999-147.081 301.99-295.93 455.779-446.632C616.221 362.424 463.434 213.584 309.58399999 63.706z"
								p-id="2670" fill="#46a1ad"></path>
						</svg></span>
					</div>
				</div>

			</div>
			<Footer />
		</div>
	)
}

export default Preformance