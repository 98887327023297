import React, { useRef, useEffect, useCallback, useState } from 'react';
import AJAX from "api/apis";
import './ProductInfo.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"
import '../default.css/default.css'
import { Link } from 'react-router-dom';
import { Button, Steps, Radio, Form, } from 'antd';
import { regSubmit, getStoreRegisterInfo } from '../../utils/PublicLibrary';


const { Step } = Steps;
export default (props) => {
    let formRef = useRef();
    const [isTourOpen, setIsTourOpen] = useState(true);
    let [isReturn, setIsReturn] = useState(props.location?.state?.isReturn || 0);

    const steps1 = [
        {
            selector: '.stepGuidanceBox0',
            content: '可跳过选择',
        },
        {
            selector: '.stepGuidanceBox1',
            content: '选择是否有UPC',
        },
        {
            selector: '.stepGuidanceBox2',
            content: '选择是否有品牌',
        },
        {
            selector: '.stepGuidanceBox3',
            content: '确定是否分辨企业买家',
        },
        {
            selector: '.stepGuidanceBox4',
            content: '选择你要上架的SKU',
        }
    ];
    const [isActive, setIsActive] = useState(false);
    const awakenTips = (type) => {
        setIsActive(type);
    }
    useEffect(() => {
        getRegInfo()
    }, [])
    let getRegInfo = useCallback(() => {
        getStoreRegisterInfo().then((res) => {
            for (let item of res.data) {
                if (item.propertyValue) {
                    if (item.propertyKey == "commodity_is_upc") {
                        formRef.current.setFieldsValue({
                            commodity_is_upc: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "is_own_brand") {
                        formRef.current.setFieldsValue({
                            is_own_brand: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "is_enterprise_purchase") {
                        formRef.current.setFieldsValue({
                            is_enterprise_purchase: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "plan_commodity_count") {
                        formRef.current.setFieldsValue({
                            plan_commodity_count: item.propertyValue
                        })
                    }
                }
            }
        })
        if (isReturn) {
            AJAX.login.getStoreRegisterInfo([]).then((data) => {
                for (let item of data.data) {
                    if (item.propertyValue) {
                        if (item.propertyKey == "commodity_is_upc") {
                            formRef.current.setFieldsValue({
                                commodity_is_upc: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "is_own_brand") {
                            formRef.current.setFieldsValue({
                                is_own_brand: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "is_enterprise_purchase") {
                            formRef.current.setFieldsValue({
                                is_enterprise_purchase: item.propertyValue
                            })
                        }
                        if (item.propertyKey == "plan_commodity_count") {
                            formRef.current.setFieldsValue({
                                plan_commodity_count: item.propertyValue
                            })
                        }
                    }
                }
            })
        }
        // let regInfo = JSON.parse(sessionStorage.getItem('regInfo')) || {}
        // if (regInfo.ProductInfo) {
        //     formRef.current.setFieldsValue({
        //         commodity_is_upc: regInfo.ProductInfo.commodity_is_upc,
        //         is_own_brand: regInfo.ProductInfo.is_own_brand,
        //         is_enterprise_purchase: regInfo.ProductInfo.is_enterprise_purchase,
        //         plan_commodity_count: regInfo.ProductInfo.plan_commodity_count,
        //     })
        // }
    }, [isReturn])
    let nextEvent = useCallback(() => {
        formRef.current.validateFields().then((res) => {
            let arr = []
            if (res.commodity_is_upc) arr.push({ "propertyKey": "commodity_is_upc", "propertyValue": res.commodity_is_upc })
            if (res.is_own_brand) arr.push({ "propertyKey": "is_own_brand", "propertyValue": res.is_own_brand })
            if (res.is_enterprise_purchase) arr.push({ "propertyKey": "is_enterprise_purchase", "propertyValue": res.is_enterprise_purchase })
            if (res.plan_commodity_count) arr.push({ "propertyKey": "plan_commodity_count", "propertyValue": res.plan_commodity_count })
            regSubmit(arr, null, 'ProductInfo', 9).then((data) => {
                if (data.code == 1) props.history.push('/ProductInfoTwo')
            })
        })
    }, [])
    return (
        <div className="agreementsBox ProductInfoBox">
            {/* 引导步骤 */}
            <TipsIcon clicks={awakenTips} />
            <Reactours routePath="ProductInfo" isActive={isActive} clicks={awakenTips} steps={steps1} isOpen={isTourOpen} />
            <Header />
            <div>
                <div className="a-section program-overview-values">
                    <Steps current={4}>
                        <Step title="企业资讯" />
                        <Step title="卖家信息" />
                        <Step title="账单/存款" />
                        <Step title="税务信息" />
                        <Step title="商品信息" />
                    </Steps>
                    <div className="CompanyAddress">
                        <Form ref={formRef}>
                            <div className="ProductInfoTitle">请告诉我们您的商品信息</div>
                            <div className="stepGuidanceBox0" style={{ color: 'rgb(36, 146, 211)', cursor: 'pointer' }}>
                                <Link to="/ProductInfoTwo">跳过</Link>
                            </div>
                            <div className="receivingMode stepGuidanceBox1">
                                <p>您的商品是否全都拥有通用商品编码(UPC)?</p>
                                <div style={{ color: 'rgb(36, 146, 211)' }}>什么是UPC？</div>
                                <Form.Item name="commodity_is_upc">
                                    <Radio.Group>
                                        <Radio value="1">是</Radio>
                                        <Radio value="2">否</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                            <div className="receivingMode stepGuidanceBox2">
                                <p>您是否拥有自己的品牌?或者您是否以您要在亚马逊上销售的任何商品的品牌代理、代表或制造商身份提供服务?</p>
                                <div style={{ color: 'rgb(36, 146, 211)' }}>这是什么意思？</div>
                                <Form.Item name="is_own_brand">
                                    <Radio.Group>
                                        <Radio value="1">是</Radio>
                                        <Radio value="2">否</Radio>
                                        <Radio value="3">其中一部分</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                            <div className="receivingMode stepGuidanceBox3">
                                <p>您是否也想通过启用企业采购商城卖家功能来定位企业买家?</p>
                                <div style={{ color: 'rgb(36, 146, 211)' }}>这是什么意思？</div>
                                <Form.Item name="is_enterprise_purchase">
                                    <Radio.Group>
                                        <Radio value="1">是</Radio>
                                        <Radio value="2">否</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                            <div className="receivingMode stepGuidanceBox4">
                                <p>您计划发布多少种不同商品？</p>
                                <Form.Item name="plan_commodity_count">
                                    <Radio.Group>
                                        <Radio value="1-10">1-10</Radio>
                                        <Radio value="11-100">11-100</Radio>
                                        <Radio value="101-500">101-500</Radio>
                                        <Radio value="多于500">多于500</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                            <div className="operationBtn" style={{ textAlign: 'center' }}>
                                <Button className='previousPage' type="primary">
                                    <Link to="/surveyResults">返回</Link>
                                </Button>
                                <Button onClick={nextEvent} className='next' type="primary">下一步</Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}