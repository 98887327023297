import { Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import classNames from "classnames";

import { AText, AButton } from "@a-components/";

import GoodsItem from "./../GoodsSearchAdd/GoodsItem";

import style from "./style.module.css";
import useQuery from "@hooks/useQuery";

const targetCustomersMapping = {
  1: "所有买家",
  2: "Amazon Prime 计划的会员",
  3: "亚马逊学生计划的会员",
  4: "亚马逊妈妈计划的会员",
};

const ReviewSubmit = ({
  className,
  selectedGoodsList = [],
  startTime,
  endTime,
  couponFullName,
  targetCustomers,
  exchangeTimes,

  budgetPrice,
}) => {
  const { type } = useQuery();
  return (
    <div className={classNames(className, "df")}>
      <div className="mr-24 f-1 discountSubmitBox" style={{ maxWidth: 460 }}>
        {type === "rerun" ? (
          <div className="bg-f8 border-ccc border-radius-4 p-12 df mt-12  mb-12">
            <InfoCircleOutlined className="fs-18 mr-12" />
            <AText className="fs-12">
              我们会根据您之前的优惠券自动填充此优惠券的详细信息。
              如果您想要编辑这些详细信息，请单击上方的相关步骤。
            </AText>
          </div>
        ) : null}

        <div className="fs-18 mb-24">查看并提交</div>

        <div className={classNames(style.descList, "pb-24")}>
          <div className="fs-12">
            <AText>优惠券名称</AText>
            <AText>{couponFullName}</AText>
          </div>
          <div className="fs-12">
            <AText>预算</AText>
            <AText>${(budgetPrice || 0).toFixed(2)}</AText>
          </div>
          <div className="fs-12">
            <AText>开始日期</AText>
            <AText>{startTime}</AText>
          </div>
          <div className="fs-12">
            <AText>结束日期</AText>
            <AText>{endTime}</AText>
          </div>
          <div className="fs-12">
            <AText>目标买家</AText>
            <AText>{targetCustomersMapping[targetCustomers]}</AText>
          </div>
          <div className="fs-12">
            <AText>限制到每个买家兑换 1 次</AText>
            <AText>{exchangeTimes === 1 ? "是" : "否"}</AText>
          </div>
        </div>

        <div className="fs-18 fw-500 mt-24 mb-24">商品优惠券</div>

        <div className="fs-12 fw-600 border-b-ccc">
          {selectedGoodsList.length}件商品
        </div>

        <div style={{ maxHeight: 520, overflow: "auto" }}>
          {selectedGoodsList.map((item) => (
            <GoodsItem key={item.goodsId} info={item} className="mt-12" />
          ))}
        </div>
      </div>

      <div className="f-2" style={{ maxWidth: 276 }}>
        <div className="fs-12 text-center fw-600 " strong>
          优惠券预览
        </div>

        <div className="border-ccc border-radius-4 p-12 mt-12">
          <img
            src={selectedGoodsList[0]?.imgUrl ?? ""}
            alt=""
            style={{ height: 156, width: "100%", objectFit: "contain" }}
          />

          <Typography.Link className="p-0 fs-12 text-center d-ib mt-12 w-100percent">
            {couponFullName}
          </Typography.Link>
          <div className="fs-12 mt-12 d-ib text-center w-100percent bg-f8 border-ccc border-radius-4 p-12 pt-6 pb-6">
            领取优惠券
          </div>
        </div>
        <AText className="fs-12 d-ib text-center mt-6" strong>
          <i>仅供说明。实际的优惠券图片可能与网站上的图片不同。</i>
        </AText>
      </div>
    </div>
  );
};

export default ReviewSubmit;
