import { Select as AntSelect } from "antd";
import classNames from "classnames";
import withProps from "./../withProps";

import style from "./style.module.css";

const Select = ({ options = [], ...otherProps }) => {
  return (
    <AntSelect {...otherProps}>
      {options.map((item) => {
        return (
          <AntSelect.Option
            value={item.value}
            disabled={item.disabled}
            className={classNames({ [style.hide]: item.hide })}
          >
            {item.label}
          </AntSelect.Option>
        );
      })}
    </AntSelect>
  );
};

export default withProps(Select, {
  className: "a-select",
});
