import React from 'react'
import './LogisticsIII.css'
import '../../../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import { Link } from 'react-router-dom'

function LogisticsIII() {
	return (
		<div>
			<Header />
			<div className="kc34_tab">
				<ul className="kc34_tab_list">
					<li><img src="../images/kc34_sjx.png" alt="" />
						<div>计划简介</div>
						<div>商品创建设置</div>
						<div>卖家自配送设置</div>
					</li>
				</ul>
				<div className="kc34_sp_text">
					<div className="kc34_tx_1">
						<h3>移除卖家自配送商品</h3>
						<p>如果参与远程配送，则您拥有的任何卖家自配送商品将无法与亚马逊物流商品共享相同的SKU。(这不会影响大多数卖家。)</p>
						<p>注册该计划后，您将无法向亚马逊物流SKU添加卖家自配送商品。但是，您可以使用单独的SKU为相同的商品维护卖家自配送商品。如果您现有SKU同时拥有亚马逑物流商品和卖家自配送商品，那么当您注册远程配送时，卖家自配送库存量将没置为零。为确保库存水平准确，以技您以后想要索婉这些更改、亚马逊不会将这些卖家自配送商品的库存数量重置至之前的水平。
							卖家自配送商品的库存数量重置至之前的水平.</p>
						<p><span>亚马逊商城网络服务用户：</span>注册亚马逊物流远程配送后，使用Inventory.xsd上传数据更新库存需要使用配送渠道(AFN或MFN)填充 <span>SwitchFulfillmentTo</span> 元素。</p>
						<p>有关更多信息，请参阅 <a href="#">更新可售商品数量-库存上传数据架构</a></p>
					</div>
					<div className="kc34_tx_2">
						<div className="kc34_box2">
							<input type="checkbox" name="" id="" value="" /> 我知道，注册远程配送时，与亚马逊物流商品具有相同SKU的卖家自配送商品的库存水平将设置为零。
						</div>
					</div>
				</div>
				<div className="kc34_btn">
					<button><Link to="/LogisticsII" style={{ color: "#000" }}>返回</Link></button>
					<button>注册</button>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default LogisticsIII