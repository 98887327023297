import { useCallback, useEffect } from "react";
import { AText, AInput, AButton, ADatePicker } from "@a-components/";
import { InfoCircleOutlined } from "@ant-design/icons";
import { minDateSelect } from "@utils/utils";
import moment from "moment";

import { ARadioGroup } from "@a-components/";
import classNames from "classnames";

const radioOptions = [
  {
    label: "所有买家",
    value: 1,
  },
  {
    label: "Amazon Prime 计划的会员",
    value: 2,
  },
  {
    label: "亚马逊学生计划的会员",
    value: 3,
  },
  {
    label: "亚马逊妈妈计划的会员",
    value: 4,
  },
];

const PriceTableTarget = ({
  className,
  startTime,
  endTime,
  couponName,
  targetCustomers,
  discountChoice,
  discountPrice,
  onChange,
  onPassStatusChange,
}) => {
  const couponPrefix = `Save ${discountChoice === 1 ? `$${discountPrice}` : `${discountPrice}%`
    } on`;

  const handleOutput = useCallback(
    (params) => {
      onChange?.({
        startTime,
        endTime,
        couponName,
        targetCustomers,
        ...params,
      });
    },
    [couponName, endTime, onChange, startTime, targetCustomers]
  );

  const handleStartTimeChange = useCallback(
    (currentTime) => {
      handleOutput({
        startTime: currentTime.format("YYYY-MM-DD"),
        endTime: null,
      });
    },
    [handleOutput]
  );

  const handleEndTimeChange = useCallback(
    (currentTime) => {
      handleOutput({ endTime: currentTime.format("YYYY-MM-DD") });
    },
    [handleOutput]
  );

  const handleCouponNameChange = useCallback(
    (value) => {
      handleOutput({ couponName: value });
    },
    [handleOutput]
  );

  const handleTargetCustomersChange = useCallback(
    (value) => {
      handleOutput({ targetCustomers: value });
    },
    [handleOutput]
  );

  useEffect(() => {
    const arr = [startTime, endTime, couponName, targetCustomers];

    onPassStatusChange?.(arr.every(Boolean));
  }, [startTime, endTime, couponName, targetCustomers, onPassStatusChange]);

  return (
    <div className={classNames(className, "df")}>
      <div className="f-1 pr-24" style={{ maxWidth: 520 }}>
        <div className="fs-24 fw-500 mb-12">
          优惠券名称<AText className="c-999">(买家可见内容)</AText>
        </div>
        <div className="fs-12">
          要获得更有效的优惠券名称，请选择一个能准确描述添加至优惠券的商品类别的定义。示例：“洗手液节省
          15％”
        </div>

        <div className="mt-12 discountName">
          <AInput
            style={{ width: 240 }}
            addonBefore={couponPrefix}
            value={couponName}
            onChange={(e) => handleCouponNameChange(e.target.value)}
          ></AInput>
          <AButton type="link">名称指南</AButton>
        </div>

        <div className="border-t-ccc targetBuyer pt-24 mt-24">
          <div className="fs-24 fw-500 mb-12">
            目标买家 <AText className="c-999">(可选)</AText>
          </div>

          <div className="bg-f8 border-ccc border-radius-4 p-12 df mt-12">
            <InfoCircleOutlined className="fs-18 mr-12" />
            <AText className="fs-12">
              您可以选择把优惠券的受众限制到下面的一个买家群体。如果您没有做出定位选择，所有买家都可以发现您的优惠券。如果您知道哪一买家群体更有可能兑换您的优惠券，把优惠券定位到这个买家群体可能会提高投资回报率。
            </AText>
          </div>

          <ARadioGroup
            options={radioOptions}
            className="mt-12"
            value={targetCustomers}
            onChange={(e) => handleTargetCustomersChange(e.target.value)}
          ></ARadioGroup>
        </div>
      </div>
      <div className="f-1 pl-24" style={{ maxWidth: 520 }}>
        <div className="fs-24 fw-500 mb-12">安排优惠券并确定受众</div>

        <div className="fs-12 mb-12">
          为您的优惠券选择一个在 1-90 天之间的持续时间。
        </div>
        <div className="times">
          <div className="mb-12">
            <AText className="fs-12">开始日期</AText>
            <br />
            <ADatePicker
              disabledDate={minDateSelect}
              value={startTime ? moment(startTime) : null}
              onChange={handleStartTimeChange}
            ></ADatePicker>
          </div>
          <div>
            <AText className="fs-12">结束日期</AText>
            <br />
            <ADatePicker
              value={endTime ? moment(endTime) : null}
              disabled={!startTime}
              disabledDate={(currentTime) =>
                minDateSelect(currentTime, moment(startTime))
              }
              onChange={handleEndTimeChange}
            ></ADatePicker>
          </div>
        </div>
        {moment(startTime).isSame(moment(), "date") ? (
          <div className="bg-f8 border-ccc border-radius-4 p-12 df mt-12">
            <InfoCircleOutlined className="fs-18 mr-12" />
            <AText className="fs-12">
              如果你选择今天作为开始日期，那么买家会在您提交优惠券后大约6个小时看到优惠券
            </AText>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PriceTableTarget;
