import Apis from "@api/apis";
import { ASwitch, AButton } from "@a-components";
import { Link } from "react-router-dom";

const columns = [
  {
    title: "广告位",
    key: "operation",
    dataIndex: "advertisingSpaceName",
    fixed: "left",
  },
  {
    title: "广告活动的竞价策略",
    dataIndex: "biddingTypeName",
    key: "1",
  },
  {
    title: "竞价调整",
    dataIndex: "biddingPriceRate",
    key: "2",
  },
  {
    title: "曝光量",
    dataIndex: "exposure",
    key: "3",
  },
  {
    title: "点击次数",
    dataIndex: "clicksNum",
    key: "4",
  },
  {
    title: "点击率 (CTR)",
    dataIndex: "clicksRate",
    key: "5",
  },
  {
    title: "花费",
    dataIndex: "costPrice",
    key: "6",
  },
  {
    title: "每次点击费用 (CPC)",
    dataIndex: "singleCostPrice",
    key: "7",
  },
  {
    title: "订单",
    dataIndex: "orderNumber",
    key: "9",
  },
  {
    title: "销售额",
    dataIndex: "salesPrice",
    key: "8",
  },
  {
    title: "ACOS",
    dataIndex: "acosRate",
    key: "9",
  },
  {
    title: "ROAS",
    dataIndex: "roasRate",
    key: "10",
  },
];
const campaignConfig = {
  tableColumns: columns,
  tableDataKey: "advertisingSpaceId",
  queryFetcher({ businessId, otherParams }) {
    return Apis.advertising.getAdvertisingSpaceList({
      ...otherParams,
      advertisingId: businessId || "",
    });
  },
};

export default campaignConfig;
