import { FormItems } from "@components/FormCollection";
import { SettingCardContainer } from "./style";
import moment from "moment";
import Apis from "@api/apis";
import { useCallback, useEffect, useState } from "react";

import { updateConfig } from "@components/FormCollection";

const noGroup = { label: "无广告组合", value: "" };

const combinationOptions = [
  // { label: "无广告组合", value: "无广告组合" },
  // { label: "火坑碗广泛匹配广告20200815", value: "火坑碗广泛匹配广告20200815" },
  // { label: "破壁机", value: "破壁机" },
  // { label: "自行车手动广告", value: "自行车手动广告" },
  // { label: "衣柜", value: "衣柜" },
  // { label: "桌椅", value: "桌椅" },
  // { label: "衣服", value: "衣服" },
  // { label: "西红柿的广告", value: "西红柿的广告" },
  // { label: "儿童桌椅0720", value: "儿童桌椅0720" },
  // { label: "Advertising fro book shelf", value: "Advertising fro book shelf" },
  // { label: "珠宝镜柜", value: "珠宝镜柜" },
  // { label: "椅子促销", value: "椅子促销" },
  // { label: "跑步机1700", value: "跑步机1700" },
  // { label: "女鬼", value: "女鬼" },
];

const settingOptions = [
  {
    element: "AInput",
    name: "adActiveName",
    label: "广告活动名称",
    className:'adActiveName',
    config: {
      style: { width: 300 },
      placeholder: "示例：假日经典收藏",
    },
    tooltip:
      "广告活动名称仅对您可见，因此请选择一个您可以轻松识别并在日后参考的名称。",
    required: true,
    rules: [{ required: true, message: "请提供广告活动名称" }],
  },
  {
    element: "ASelect",
    name: "adGroup",
    label: "组合",
    className:'adGroup',
    config: {
      style: { width: 300 },
      placeholder: "",
      options: [],
      showSearch: true,
      filterOption: (input, option) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    },
    tooltip:
      "组合是一组可以设置预算上限的广告活动。组合使您可以对广告活动进行分组和组织。",
  },
  {
    element: "Wrapper",
    className: "adStartTime",
    content: [
      {
        element: "ADatePicker",
        name: "adStartTime",
        label: "开始",
        config: {
          allowClear: false,
          format: (m) => m.format("YYYY年MM月DD日"),
          disabledDate(current) {
            return current && current.endOf("day") < moment().endOf("day");
          },
        },
      },
      {
        element: "ADatePicker",
        label: "结束",
        name: "adEndTime",
        config: {
          placeholder: "无结束日期",
          format: (m) => m.format("YYYY年MM月DD日"),
        },
        $computeProps(curProps, _, form) {
          const adStartTime = form.getFieldValue("adStartTime") || moment();

          return {
            ...curProps,
            disabledDate(current) {
              if (!current) return false;
              return current.endOf("day") < adStartTime.endOf("day");
            },
          };
        },
        dependencies: ["adStartTime"],
      },
    ],
  },
  {
    element: "AInputNumber",
    label: "每日预算",
    name: "budget",
    className: "budget",
    config: {
      style: { width: 120 },
      min: 0,
      formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      parser: (value) => value.replace(/\$\s?|(,*)/g, ""),
    },
    required: true,
    rules: [
      {
        required: true,
        message: "广告活动预算应至少为 $1.00",
      },
      {
        type: "number",
        validateTrigger: ["onChange"],
        min: 1,
        message: "广告活动预算应至少为 $1.00",
      },
      {
        type: "number",
        validateTrigger: ["onChange"],
        max: 1000000,
        message: "广告活动预算不应超过 $1,000,000.00",
      },
    ],
  },
  {
    element: "ARadioGroup",
    label: "定向策略",
    name: "orientationStrategyType",
    className: "orientationStrategyType",
    config: {
      options: [
        {
          label: "自动投放",
          value: "automatic",
          description: "亚马逊将定向到关键词和与您广告中的商品类似的商品。",
        },
        {
          label: "手动投放",
          value: "manual",
          description: "选择可定向到顾客搜索的关键词或商品并设置自定义竞价。",
        },
      ],
    },
  },
];

const SettingCard = () => {
  const [options, setOptions] = useState(settingOptions);

  const getGroupOptions = useCallback(() => {
    return Apis.advertisingCombination.getOption().then((res) => {
      if (res.code === 1) {
        setOptions((options) => {
          const newOptions = updateConfig(options, "adGroup", {
            options: [noGroup, ...res.data],
          });
          return newOptions;
        });
      }
    });
  }, []);

  useEffect(() => {
    getGroupOptions();
  }, [getGroupOptions]);

  return (
    <SettingCardContainer>
      <FormItems options={options} style={{ width: 400 }} />
    </SettingCardContainer>
  );
};

export default SettingCard;
