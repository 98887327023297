import "./home.css"
import HomeHeader from 'components/home_header';
import Body from "./module/Body/Body";
import About from "./module/About/About";
import FooterCom from "components/addlisting_footer/FooterCom";

function App() {
    return (
        <div className="App">
            <HomeHeader/>
            <Body/>
            <About/>
            <FooterCom/>
        </div>
    );
}

export default App;
