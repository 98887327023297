import React from 'react'
import './BrandCataLogManage.css'
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import IconFont from 'components/Icon/Icon'

function BrandCataLogManage(){
    return(
        <div>
            <Header/>
            <div>

            <div className="end16_content">
			<div className="end16_title">
				<div className="end16_title_head">
					<div>品牌目录管理器</div>
					<div>了解更多信息</div>
				</div>
				<div>
					<div>
						此视图可以列出亚马逊商店中销售的来自您品牌的所有商品。可从中了解谁在销售您的商品，
						买家有什么样的体验，以及每种商品预计会有什么销售机会。
					</div>
					<div>
						在这里看不到您的品牌?请 <span>复查您的资格</span> 以更正您的身份并亨受您的所有品牌权益。
					</div>
				</div>
			</div>
			<div className="end16_function">
				<div>
					<div>故ASIN或商品名称搜素</div>
					<div><IconFont type="icon-sousuo" /></div>
					<div>当前显示1个ASINo</div>
				</div>
				<div>
					<div>筛选依据:</div>
					<div className="end16_function_btn">品牌</div>
					<div className="end16_function_btn">品牌报价</div>
					<div className="end16_function_btn">类别</div>
				</div>
			</div>
			<div className="end16_commodity">
				<div className="end16_commodity_top">
					<div className="end16_commodity_con">
						<div>
							商品名称
						</div>
						<div>
							ASIN、品牌﹑类别、变体
						</div>
					</div>  
					<div className="end16_commodity_right">
						<ul>
							<li><IconFont type="icon-jiantou" /></li>
							<li>零售报价</li>
							<li>品牌报价</li>
							<li>其他卖家计数 <IconFont type="icon-jiantou" /></li>
							<li>平均评分</li>
							<li>潜在机会 <IconFont type="icon-jiantou" /></li>
							<li>后续步骤</li>
						</ul>
						<p>% 重要（ 1-3星 )</p>
					</div>
				</div>
				<div className="end16_commodity_bottom">
					<div>
						<div className="end16_commodity_bottom_txt">
							<p>Park Outdoor Dench Cast Aluminum Chair with Arms Rose Reinforced Steel Designed 2 eater Garden Chair,38.5"
								for Garden, Courtyard, Patio</p>
							<p>BO8FHSF332、MengK. Lawn and Garden</p>
						</div>

					</div>
					<div>
						<ul>
							<li>否</li>
							<li>非在售</li>
							<li>
								1 <br />
								查看买家
							</li>
						</ul>
						<div>

						</div>
						<div>
							<button type="button">激活报价</button>
						</div>
					</div>
				</div>
			</div>
			<div className="end16_transit">
				<div className="end16_transit_zuo"><IconFont  type="icon-xiajiantou" /></div>
				<div><IconFont type="icon-xiajiantou" /></div>
			</div>
			<div className="end16_page">
				<p>上次更新日期:2021午1月19日 <br />
					所有指标均会每天计算并更新一次。请等待48小时·以使更改反映在此页面上。</p>
				<div>
					<div>
						<span>第 </span>
						<div>
							1
						</div>
						<span> 页,共1页</span>
					</div>
					<div>
						<p> <span>{"<"}</span> 1  <span>{">"}</span></p>
					</div>
					<div>
						<select>
						  <option value ="volvo">每页查看10个</option>
						</select>
					</div>
				</div>
			</div>
		</div>


            </div>
            <Footer/>
        </div>
    )
}

export default BrandCataLogManage