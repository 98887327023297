import withProps from "@a-components/withProps";
import { Checkbox as AntCheckbox } from "antd";

const Checkbox = withProps(
  (props) => {
    return <AntCheckbox {...props} />;
  },
  {
    className: "a-checkbox",
  }
);

export default Checkbox;
