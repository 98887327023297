import React from "react";
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import NotFound from "components/not_found";
import Login from "view/login";
import Register from "view/register";
import Home from "view/home";
import Shopping from "view/shopping";
import Addlisting from "view/addlisting";
import SearchList from "view/addlisting/module/SearchList/SearchList";
import AddCommodityOffer from "view/addlisting/module/AddCommodityOffer/AddCommodityOffer";//跟卖
import Commodity from "view/commodity";
import CreateListing from "view/createListing";
import AdminVideo from "view/adminVideo/adminVideo"; //管理视频
import Video from "view/Video/Video"; //上传视频
import Imageup from "view/imgup/imgup"; //批量图片上传
import ImagesNeeds from "view/imgup/module/ImagesNeeds/ImagesNeeds";
import Shopup from "view/shopup/shopup"; //批量上传商品
import Report from "view/report/report"; //上传图片
import Repertory from "view/repertory/index"; // 库存规划
import Inventory from "view/manageInventory"; // 货件处理进度
import InboundQueue from "view/inboundQueue";
import RepairPriceReport from "view/RepairPriceReport/RepairPriceReport"; //28-库存-修复价格警告，29-库存-修复无在售信息的亚马逊库存
import GlobalSales from "view/GlobalSales/GlobalSales"; //23-库存-全球销售
import TreasuryPlan from "view/TreasuryPlan/TreasuryPlan"; //14-库存-更新入库计划
import NoSale from "view/NoSale/NoSale"; //04库存创建无在售信息的商品
import QualityInformation from "view/QualityInformation/QualityInformation"; //26-库存-商品信息质量控制面板，27-库存-上传入库计划
import ScanLabel from "view/ScanLabel/ScanLabel"; //25-库存扫描和贴标
import BrandHealth from "view/BrandHealth/BrandHealth"; //22-库存-品牌健康码
import Product from "view/product/product"; //03-库存-查看商品
// import AmazonInventory from 'view/AmazonInventory/AmazonInventory';
// 04,14,15,16,18,20,22,23,24,25,26,27,28,29,30,32,33,34
import Aa from "view/A+/Aa";
import ProductDetails from "view/A+/module/ProductDetails/ProductDetails";
import Information from "view/A+/module/A+information/information";
import informationASIN from "view/A+/module/A+information/module/informationASIN/informationASIN";
import informationSelect from "view/A+/module/A+information/module/informationSelect/informationSelect";
import informationDetails from "@view/A+/module/A+information/module/informationDetails/informationDetails"
import InformationComplete from "@view/A+/module/A+information/module/informationComplete/informationComplete"
import ImageText from "view/A+/module/ProductDetails/module/imageText/imageText";
import Commission from "view/Commission/Commission"; //佣金折扣
import Planning from "view/planning/planning"; //亚马逊库存
import Logistics from "view/Logistics/Logistics"; //亚马逊远程配送
import LogisticsII from "view/Logistics/modle/LogisticsII/LogisticsII"; //亚马逊远程配送
import LogisticsIII from "view/Logistics/modle/LogisticsIII/LogisticsIII"; //亚马逊远程配送
import ManagePricing from "view/ManagePricing/ManagePricing"; //管理定价
import PricingHealth from "view/PricingHealth/PricingHealth"; //定价状况
import FeaturedOffer from "view/PricingHealth/module/FeaturedOffer/FeaturedOffer"; //定价状况
import ManageOrders from "view/ManageOrders/ManageOrders"; //管理订单
import AutomatePricing from "view/AutomatePricing/AutomatePricing"; //自动定价
import ManageSKUs from "view/AutomatePricing/module/ManageSKUs/ManageSKUs"; //管理SKU
import AutoPricing from "view/AutomatePricing/module/AutoPricing/AutoPricing"; //自动定价
import ManageReturns from "view/manageReturns/index"; //管理退货
import OrderReport from "view/orderReport/index"; //订单报告！
import Improve from "view/improve/improve"; //商品信息质量控制面板
import Negotiated from "view/Negotiated/Negotiated"; //协议定价
import Add from "view/Negotiated/moudel/Add/Add"; //添加买家
import Selling from "view/selling/selling"; //库存建议
import Advertising from "@view/advertisement/module/advertising/advertising"; //广告活动
import Custom from "view/custom/custom"; //自定义报告
import Business from "view/business/business"; //销售图表
import ManageApp from "view/ManageApp/ManageApp"; //管理您的应用
import DevelopApp from "view/DevelopApp/DevelopApp"; //开发者中心
import DiscoverApps from "view/DiscoverApps/DiscoverApps"; //商城应用商店
import Lending from "view/Lending/Lending"; //借贷
import Early from "view/Early/Early"; //注册控制面板
import Commentator from "view/Commentator/index"; //评论者计划
import RegCommentator from "view/RegCommentator/index"; //注册评论者计划SKU
import uploadSKU from "view/uploadSKU/index"; //上传SKU
import Prime from "view/Prime/Prime"; //Prime 专享折扣
import Deals from "view/Deals/Deals"; //促销
import Coupons from "view/Coupons/Coupons"; //优惠券
import addCoupons from "view/addCoupons/addCoupons"; //优惠券
import Promotions from "@view/Promotions"; //促销
import Payments from "view/Payments/Payments"; //付款控制面板
import CamipnManage from "view/CamipnManage/CamipnManage"; //
import RegisterToVine from "view/registerToVine/index"; //vine
import NewBundle from "view/newBundle/index"; //新捆绑商品
import Fulfillment from "view/Fulfillment/Fulfillment"; //亚马逊物流报告
import ReturnReports from "view/ReturnReports/ReturnReports"; //退货报告
import TaxDocument from "view/TaxDocument/TaxDocument"; //税务文件库
import AccountHealth from "view/AccountHealth/AccountHealth"; //账户状况
import Feedback from "view/Feedback/Feedback"; //反馈管理器
import AToZGuarantee from "view/A-to-zGuarantee/A-to-zGuarantee"; //管理亚马逊商城交易索赔
import ChargebackClaims from "view/ChargebackClaims/ChargebackClaims"; //顾客中止付款
import Preformance from "view/Preformance/Preformance"; //业绩通知
import VicoOfTheCustomer from "view/VicoOfTheCustomer/VicoOfTheCustomer"; //买家之声
import SellerUniversity from "view/SellerUniversity/SellerUniversity"; //卖家大学
import manageYourExperiments from "view/manageYourExperiments/index"; //管理实验
import ManageQuofile from "view/ManageQuofile/ManageQuofile"; //管理报价
import VirtualBundles from "view/VirtualBundles/VirtualBundles"; //买家评论
import BrandDashbOard from "view/BrandDashbOard/BrandDashbOard"; //品牌控制面板
import BrandCataLogManage from "view/BrandCataLogManage/BrandCataLogManage"; //品牌目录管理器
import AdvertisingReports from "view/AdvertisingReports/AdvertisingReports"; //广告报告
import Advertisement from "view/advertisement/index"; //广告活动
import BatchOperation from "view/advertisement/module/BatchOperation/index"; //广告活动
import BatchOperation1 from "view/advertisement/module/BatchOperation/BatchOperation1/index"; //广告活动
import BrandPromotion2 from "view/advertisement/module/NewAdvertisingCampaign/BrandPromotion2/BrandPromotion2"; //广告活动
import NewAdvertisingCampaign from "view/advertisement/module/NewAdvertisingCampaign/index"; //广告活动
import CommodityPromotion from "view/advertisement/module/NewAdvertisingCampaign/CommodityPromotion/CommodityPromotion"; //广告活动
import BrandPromotion1 from "view/advertisement/module/NewAdvertisingCampaign/BrandPromotion1/BrandPromotion1"; //广告活动
import AccessSettings from "view/advertisement/module/AccessSettings/AccessSettings"; //广告活动
import ReferralProgram from "view/ReferralProgram/ReferralProgram"; //推荐计划
import MakePlan from "view/MakePlan/MakePlan"; //制定计划
import DistributionPlan from "view/DistributionPlan/DistributionPlan"; //配送计划
import MobileApps from "view/MobileApps/MobileApps"; //官方移动应用
import BuyerMessage from "view/BuyerMessage/BuyerMessage"; //买家消息
import RepertoryAge from "view/repertory/module/repertoryAge/repertoryAge"; //库龄
import ReturnGoods from "view/repertory/module/returnGoods/index"; //库龄
import Repair from "view/repertory/module/repair/repair"; //修复无在售的亚马孙库存(
import RepertoryTable1 from "view/repertory/module/RepertoryTable1/RepertoryTable1"; //库存控制面板
import NewProduct from "view/repertory/module/repair/module/newProduct/newProduct"; //查看商品
import NoActivity from "view/NoActivity/index"; //修复商品
import CreateReport from "view/AdvertisingReports/module/CreateReport/CreateReport"; //新报告
import SellingApplications from "view/SellingApplications/index"; //查看销售申请
import UploadOrder from "view/UploadOrder/index"; //上传订单相关文件
import Safet from "view/Safet/index"; //SAFET索赔
import selectPromotion from "view/selectPromotion/index"; //查看媒体促销
import addDiscount from "view/addDiscount/addDiscount"; //创建折扣促销
import addGive from "view/addGive/addGive"; //创建折扣促销
import fbaConfirm from "view/fbaConfirm/fbaConfirm"; //创建折扣促销
import fbaInbound from "view/fbaInbound/index"; //转fab
import sendEmails from "@view/Verify/sendEmails/index"; //发邮件
import mobileNumber from "@view/Verify/mobileNumber"; //发手机号
import Agreements from "view/agreements/agreements"; //注册协议
import sellerInfo from "@view/agreements/sellerInfo/sellerInfo"; //企业咨询
import enterprise from "@view/agreements/enterprise/enterprise"; //卖家信息
import bill from "@view/agreements/bill/bill"; //账单
import deposit from "@view/agreements/deposit/deposit"; //账单-收款方式
import shop from "@view/agreements/shop/shop"; //店铺
import taxation from "view/taxation/taxation"; //税务
import taxationInfo from "view/taxationInfo/taxationInfo"; //税务信息
import previewTaxationInfo from "view/previewTaxationInfo/previewTaxationInfo"; //预览税务信息
import TaxInvestigation from "view/TaxInvestigation/TaxInvestigation"; //税务信息调查
import surveyResults from "view/surveyResults/surveyResults"; //税务信息调查结果
import ProductInfo from "view/ProductInfo/ProductInfo"; //商品信息
import ProductInfoTwo from "view/ProductInfoTwo/ProductInfoTwo"; //商品信息第二步
import Authentication from "view/Authentication/Authentication"; //身份验证
import AuthenticationTwo from "view/AuthenticationTwo/AuthenticationTwo"; //身份验证第二步
import AuthenticationThree from "@view/agreements/AuthenticationThree/AuthenticationThree"; //身份验证第三步
import completeValidation from "@view/agreements/completeValidation/completeValidation"; //完成验证
import deliverGoods from "view/deliverGoods/deliverGoods"; //发/补货
import setQuantity from "view/setQuantity/setQuantity"; //设置数量
import Pretreatment from "view/Pretreatment/Pretreatment"; //预处理Labeling
import Labeling from "view/Labeling/Labeling"; //为商品贴标
import viewShipment from "view/viewShipment/viewShipment"; //查看货件
import handlingCargo from "view/handlingCargo/handlingCargo"; //处理货件
import completeShipment from "view/completeShipment/completeShipment"; //处理货件
import atAglance from "view/atAglance/atAglance"; //一览
import contactBuyer from "view/contactBuyer/contactBuyer"; //联系买家
import managePromotions from "view/managePromotions/managePromotions"; //联系买家
import distributionSet from "view/distributionSet/distributionSet"; //配送设置
import addTemplate from "view/addTemplate/addTemplate"; //新增配送模板
import freightEdit from "view/freightEdit/freightEdit"; //运费编辑
import DraftIncomplete from "view/DraftIncomplete/DraftIncomplete"; //补全草稿
import AdvertisingDetail from "@view/advertisement/module/AdvertisingDetail";
import CreateAdvertisingGroup from "@view/advertisement/module/NewAdvertisingCampaign/CommodityPromotion/CreateAdvertisingGroup";
import ModifyAdCombination from "@view/advertisement/module/AdvertisingCampaign/component/ModifyAdCombination";
import AdGroupDetail from "@view/advertisement/module/AdGroupDetail";
import Tests from "@view/tests";
import CreateAdvertisingSuccess from "@view/advertisement/module/CreateAdvertisingSuccess";
import Mengk from "@view/Mengk"; //品牌旗舰店
import MengkGenerator from "@view/Mengk/module/MengkGenerator/MengkGenerator";//品牌旗舰店
import BrandRegistered from "@view/BrandRegistered";//品牌注册
import BrandRegisteredForm from "@view/BrandRegistered/module/BrandRegisteredForm/BrandRegisteredForm";//品牌注册
import Accountinfo from "@view/Accountinfo"; //账户信息页面
import DepositMethod from "@view/Accountinfo/module/DepositMethod/DepositMethod"; //账户信息-存款方式
import SetDepositMethod from "@view/Accountinfo/module/DepositMethod/module/SetDepositMethod/SetDepositMethod"; //账户信息-存款方式
import ManageDepositMethod from "@view/Accountinfo/module/DepositMethod/module/ManageDepositMethod/ManageDepositMethod"; //账户信息-存款方式
import CompanyAddress from "@view/Accountinfo/module/CompanyAddress/CompanyAddress"; //账户信息-公司地址
import NotificationOptions from "@view/NotificationOptions"; //通知首选项
import ReturnSettings from "@view/ReturnSettings"; //退货设定

/************************ 优惠券 - begin ******************************/
/* 创建优惠券 */
import CreateCoupon from "@view/Coupons/CreateCoupon";
/* 优惠券详情 */
import CouponDetail from "@view/Coupons/CouponDetail";

/************************ 优惠券 - end ******************************/

/************************ 促销 - begin ******************************/

/* 创建促销 */
import PromotionDetail from "@view/Promotions/children/PromotionDetail"; //促销

/* 管理优惠码 */
import DiscountCodeManage from "@view/Promotions/children/DiscountCodeManage"; //促销

/* 创建商品列表 */
import ProductSelection from "@view/Promotions/children/ProductSelection"; //促销

/* 创建商品列表 */
import CreateProduceSelection from "@view/Promotions/ProduceSelectionCreate";

/************************ 促销 - end ******************************/

/************************ 视频认证 ******************************/
import MeetingTime from "@view/agreements/videoAuthentication/meetingTime";
import MeetingMatters from "@view/agreements/videoAuthentication/meetingMatters";
import VideoVerification from "@view/agreements/videoAuthentication/VideoVerification";
import AddressVerification from "@view/agreements/videoAuthentication/AddressVerification";
import CompleteVerification from "@view/agreements/videoAuthentication/CompleteVerification";
import Enable from "@view/agreements/TwoStepVerification/Enable";
import SimulateLogin from "@view/agreements/TwoStepVerification/SimulateLogin";
import Authenticator from "@view/agreements/TwoStepVerification/Authenticator";
import Approve from "@view/agreements/TwoStepVerification/Approve";
import Complete from "@view/agreements/TwoStepVerification/Complete";
import IssuingAddress from "@view/agreements/TwoStepVerification/IssuingAddress";

export default () => {
  return (
    <Switch>
      <PrivateRoute exact path={"/login"} component={Login} />
      <PrivateRoute exact path={"/register"} component={Register} />
      <PrivateRoute exact path={"/tests"} component={Tests} />
      <PrivateRoute exact path={"/"} component={Home} />
      <PrivateRoute exact path={"/shopping"} component={Shopping} />
      <PrivateRoute exact path={"/Addlisting"} component={Addlisting} />
      <PrivateRoute exact path={"/SearchList"} component={SearchList} />
      <PrivateRoute exact path={"/AddCommodityOffer"} component={AddCommodityOffer} />
      <PrivateRoute exact path={"/commodity"} component={Commodity} />
      <PrivateRoute exact path={"/createListing"} component={CreateListing} />
      <PrivateRoute exact path={"/inventory"} component={Inventory} />
      <PrivateRoute exact path={"/inboundQueue"} component={InboundQueue} />
      <PrivateRoute exact path={"/AdminVideo"} component={AdminVideo} />
      <PrivateRoute exact path={"/Video"} component={Video} />
      <PrivateRoute exact path={"/Imageup"} component={Imageup} />
      <PrivateRoute exact path={"/Shopup"} component={Shopup} />
      <PrivateRoute exact path={"/Report"} component={Report} />
      <PrivateRoute exact path={"/Repertory"} component={Repertory} />
      {/* <PrivateRoute exact component={NotFound} /> */}
      <PrivateRoute
        exact
        path={"/RepairPriceReport"}
        component={RepairPriceReport}
      />
      <PrivateRoute exact path={"/GlobalSales"} component={GlobalSales} />
      <PrivateRoute exact path={"/TreasuryPlan"} component={TreasuryPlan} />
      <PrivateRoute exact path={"/NoSale"} component={NoSale} />
      <PrivateRoute
        exact
        path={"/QualityInformation"}
        component={QualityInformation}
      />
      <PrivateRoute exact path={"/ScanLabel"} component={ScanLabel} />
      <PrivateRoute exact path={"/BrandHealth"} component={BrandHealth} />
      <PrivateRoute exact path={"/Aa"} component={Aa} />
      <PrivateRoute exact path={"/ProductDetails"} component={ProductDetails} />
      <PrivateRoute exact path={"/Product"} component={Product} />
      <PrivateRoute exact path={"/Information"} component={Information} />
      <PrivateRoute
        exact
        path={"/informationASIN"}
        component={informationASIN}
      />
      <PrivateRoute exact path={"/informationSelect"} component={informationSelect} />
      <PrivateRoute exact path={"/informationDetails"} component={informationDetails} />
      <PrivateRoute exact path={"/InformationComplete"} component={InformationComplete} />
      <PrivateRoute exact path={"/ImageText"} component={ImageText} />
      <PrivateRoute exact path={"/Commission"} component={Commission} />
      <PrivateRoute exact path={"/Planning"} component={Planning} />
      <PrivateRoute exact path={"/Logistics"} component={Logistics} />
      <PrivateRoute exact path={"/LogisticsII"} component={LogisticsII} />
      <PrivateRoute exact path={"/LogisticsIII"} component={LogisticsIII} />
      <PrivateRoute exact path={"/ManagePricing"} component={ManagePricing} />
      <PrivateRoute exact path={"/PricingHealth"} component={PricingHealth} />
      <PrivateRoute exact path={"/FeaturedOffer"} component={FeaturedOffer} />
      <PrivateRoute exact path={"/ManageOrders"} component={ManageOrders} />
      <PrivateRoute
        exact
        path={"/AutomatePricing"}
        component={AutomatePricing}
      />
      <PrivateRoute exact path={"/ManageReturns"} component={ManageReturns} />
      <PrivateRoute exact path={"/OrderReport"} component={OrderReport} />
      <PrivateRoute exact path={"/Improve"} component={Improve} />
      <PrivateRoute exact path={"/Negotiated"} component={Negotiated} />
      <PrivateRoute exact path={"/Add"} component={Add} />
      <PrivateRoute exact path={"/ManageSKUs"} component={ManageSKUs} />
      <PrivateRoute exact path={"/AutoPricing"} component={AutoPricing} />
      <PrivateRoute exact path={"/ImagesNeeds"} component={ImagesNeeds} />
      <PrivateRoute exact path={"/Selling"} component={Selling} />
      <PrivateRoute exact path={"/Advertising"} component={Advertising} />
      <PrivateRoute exact path={"/Custom"} component={Custom} />
      <PrivateRoute exact path={"/Business"} component={Business} />
      <PrivateRoute exact path={"/ManageApp"} component={ManageApp} />
      <PrivateRoute exact path={"/DevelopApp"} component={DevelopApp} />
      <PrivateRoute exact path={"/DiscoverApps"} component={DiscoverApps} />
      <PrivateRoute exact path={"/Lending"} component={Lending} />
      <PrivateRoute exact path={"/Early"} component={Early} />
      <PrivateRoute exact path={"/Prime"} component={Prime} />
      <PrivateRoute exact path={"/Deals"} component={Deals} />
      <PrivateRoute exact path={"/Coupons"} component={Coupons} />

      <PrivateRoute exact path={"/Promotions"} component={Promotions} />
      <PrivateRoute
        exact
        path={"/Promotions/PromotionDetail"}
        component={PromotionDetail}
      />
      <PrivateRoute
        exact
        path={"/Promotions/DiscountCodeManage"}
        component={DiscountCodeManage}
      />
      <PrivateRoute
        exact
        path={"/Promotions/ProductSelection"}
        component={ProductSelection}
      />
      {/* <PrivateRoute
        exact
        path={"/Promotions/PromotionCreate"}
        component={PromotionCreate}
      />
      <PrivateRoute
        exact
        path={"/Promotions/DiscountCreate"}
        component={DiscountCreate}
      />
      <PrivateRoute
        exact
        path={"/Promotions/GiftsCreate"}
        component={GiftsCreate}
      /> */}
      <PrivateRoute
        exact
        path={"/Promotions/CreateProduceSelection"}
        component={CreateProduceSelection}
      />

      <PrivateRoute exact path={"/Payments"} component={Payments} />
      <PrivateRoute exact path={"/CamipnManage"} component={CamipnManage} />
      <PrivateRoute exact path={"/RegisterToVine"} component={RegisterToVine} />
      <PrivateRoute exact path={"/NewBundle"} component={NewBundle} />
      <PrivateRoute exact path={"/Fulfillment"} component={Fulfillment} />
      <PrivateRoute exact path={"/ReturnReports"} component={ReturnReports} />
      <PrivateRoute exact path={"/TaxDocument"} component={TaxDocument} />
      <PrivateRoute exact path={"/AccountHealth"} component={AccountHealth} />
      <PrivateRoute exact path={"/Feedback"} component={Feedback} />
      <PrivateRoute exact path={"/AToZGuarantee"} component={AToZGuarantee} />
      <PrivateRoute
        exact
        path={"/ChargebackClaims"}
        component={ChargebackClaims}
      />
      <PrivateRoute exact path={"/Preformance"} component={Preformance} />
      <PrivateRoute
        exact
        path={"/VicoOfTheCustomer"}
        component={VicoOfTheCustomer}
      />
      <PrivateRoute
        exact
        path={"/SellerUniversity"}
        component={SellerUniversity}
      />
      <PrivateRoute
        exact
        path={"/manageYourExperiments"}
        component={manageYourExperiments}
      />
      <PrivateRoute exact path={"/ManageQuofile"} component={ManageQuofile} />
      <PrivateRoute exact path={"/VirtualBundles"} component={VirtualBundles} />
      <PrivateRoute exact path={"/BrandDashbOard"} component={BrandDashbOard} />
      <PrivateRoute
        exact
        path={"/BrandCataLogManage"}
        component={BrandCataLogManage}
      />
      <PrivateRoute
        exact
        path={"/AdvertisingReports"}
        component={AdvertisingReports}
      />
      <PrivateRoute exact path={"/Advertisement"} component={Advertisement} />
      <PrivateRoute exact path={"/BatchOperation"} component={BatchOperation} />
      <PrivateRoute
        exact
        path={"/BatchOperation1"}
        component={BatchOperation1}
      />
      <PrivateRoute
        exact
        path={"/BrandPromotion2"}
        component={BrandPromotion2}
      />
      <PrivateRoute
        exact
        path={"/NewAdvertisingCampaign"}
        component={NewAdvertisingCampaign}
      />
      <PrivateRoute
        exact
        path={"/CommodityPromotion"}
        component={CommodityPromotion}
      />
      <PrivateRoute
        exact
        path={"/BrandPromotion1"}
        component={BrandPromotion1}
      />
      <PrivateRoute exact path={"/AccessSettings"} component={AccessSettings} />
      <PrivateRoute
        exact
        path={"/ReferralProgram"}
        component={ReferralProgram}
      />
      <PrivateRoute exact path={"/MakePlan"} component={MakePlan} />
      <PrivateRoute
        exact
        path={"/DistributionPlan"}
        component={DistributionPlan}
      />
      <PrivateRoute exact path={"/MobileApps"} component={MobileApps} />
      <PrivateRoute exact path={"/BuyerMessage"} component={BuyerMessage} />
      <PrivateRoute exact path={"/RepertoryAge"} component={RepertoryAge} />
      <PrivateRoute exact path={"/ReturnGoods"} component={ReturnGoods} />
      <PrivateRoute exact path={"/Repair"} component={Repair} />
      <PrivateRoute
        exact
        path={"/RepertoryTable1"}
        component={RepertoryTable1}
      />
      <PrivateRoute exact path={"/NewProduct"} component={NewProduct} />
      <PrivateRoute exact path={"/NoActivity"} component={NoActivity} />
      <PrivateRoute exact path={"/CreateReport"} component={CreateReport} />
      <PrivateRoute
        exact
        path={"/SellingApplications"}
        component={SellingApplications}
      />
      <PrivateRoute exact path={"/UploadOrder"} component={UploadOrder} />
      <PrivateRoute exact path={"/Safet"} component={Safet} />
      <PrivateRoute exact path={"/Commentator"} component={Commentator} />
      <PrivateRoute exact path={"/RegCommentator"} component={RegCommentator} />
      <PrivateRoute exact path={"/uploadSKU"} component={uploadSKU} />
      <PrivateRoute exact path={"/addCoupons"} component={addCoupons} />
      <PrivateRoute
        exact
        path={"/selectPromotion"}
        component={selectPromotion}
      />
      <PrivateRoute exact path={"/addDiscount"} component={addDiscount} />
      <PrivateRoute exact path={"/addDiscount"} component={addDiscount} />
      <PrivateRoute exact path={"/addGive"} component={addGive} />
      <PrivateRoute exact path={"/fbaConfirm"} component={fbaConfirm} />
      <PrivateRoute exact path={"/fbaInbound"} component={fbaInbound} />
      <PrivateRoute exact path={"/sendEmails"} component={sendEmails} />
      <PrivateRoute exact path={"/mobileNumber"} component={mobileNumber} />
      <PrivateRoute exact path={"/Agreements"} component={Agreements} />
      <PrivateRoute exact path={"/sellerInfo"} component={sellerInfo} />
      <PrivateRoute exact path={"/enterprise"} component={enterprise} />
      <PrivateRoute exact path={"/bill"} component={bill} />
      <PrivateRoute exact path={"/deposit"} component={deposit} />
      <PrivateRoute exact path={"/shop"} component={shop} />
      <PrivateRoute exact path={"/taxation"} component={taxation} />
      <PrivateRoute exact path={"/taxationInfo"} component={taxationInfo} />
      <PrivateRoute exact path={"/freightEdit"} component={freightEdit} />
      <PrivateRoute
        exact
        path={"/previewTaxationInfo"}
        component={previewTaxationInfo}
      />
      <PrivateRoute
        exact
        path={"/TaxInvestigation"}
        component={TaxInvestigation}
      />
      <PrivateRoute exact path={"/surveyResults"} component={surveyResults} />
      <PrivateRoute exact path={"/ProductInfo"} component={ProductInfo} />
      <PrivateRoute exact path={"/ProductInfoTwo"} component={ProductInfoTwo} />
      <PrivateRoute exact path={"/Authentication"} component={Authentication} />
      <PrivateRoute
        exact
        path={"/AuthenticationTwo"}
        component={AuthenticationTwo}
      />
      <PrivateRoute
        exact
        path={"/AuthenticationThree"}
        component={AuthenticationThree}
      />
      <PrivateRoute
        exact
        path={"/completeValidation"}
        component={completeValidation}
      />
      <PrivateRoute exact path={"/deliverGoods"} component={deliverGoods} />
      <PrivateRoute exact path={"/setQuantity"} component={setQuantity} />
      <PrivateRoute exact path={"/Pretreatment"} component={Pretreatment} />
      <PrivateRoute exact path={"/Labeling"} component={Labeling} />
      <PrivateRoute exact path={"/viewShipment"} component={viewShipment} />
      <PrivateRoute exact path={"/handlingCargo"} component={handlingCargo} />
      <PrivateRoute
        exact
        path={"/completeShipment"}
        component={completeShipment}
      />
      <PrivateRoute exact path={"/contactBuyer"} component={contactBuyer} />
      <PrivateRoute
        exact
        path={"/managePromotions"}
        component={managePromotions}
      />
      <PrivateRoute
        exact
        path={"/distributionSet"}
        component={distributionSet}
      />
      <PrivateRoute exact path={"/addTemplate"} component={addTemplate} />
      <PrivateRoute
        exact
        path={"/DraftIncomplete"}
        component={DraftIncomplete}
      />
      <PrivateRoute exact path={"/atAglance"} component={atAglance} />
      <PrivateRoute
        exact
        path="/Advertisement/AdvertisingDetail"
        component={AdvertisingDetail}
      />
      <PrivateRoute
        exact
        path="/Advertisement/CreateAdvertisingGroup"
        component={CreateAdvertisingGroup}
      />
      <PrivateRoute
        exact
        path="/Advertisement/ModifyAdCombination"
        component={ModifyAdCombination}
      />
      <PrivateRoute
        exact
        path="/Advertisement/AdGroupDetail"
        component={AdGroupDetail}
      />

      <PrivateRoute
        exact
        path="/Coupons/CreateCoupon"
        component={CreateCoupon}
      />
      <PrivateRoute
        exact
        path="/Coupons/CouponDetail"
        component={CouponDetail}
      />
      <PrivateRoute
        exact
        path="/Advertisement/CreateAdvertisingSuccess"
        component={CreateAdvertisingSuccess}
      />
      <PrivateRoute exact path="/Mengk" component={Mengk} />
      <PrivateRoute exact path="/MengkGenerator" component={MengkGenerator} />

      <PrivateRoute exact path="/BrandRegistered" component={BrandRegistered} />
      <PrivateRoute exact path="/BrandRegisteredForm" component={BrandRegisteredForm} />

      <PrivateRoute exact path="/accountinfo" component={Accountinfo} />
      <PrivateRoute exact path="/accountinfo/depositMethod" component={DepositMethod} />
      <PrivateRoute exact path="/accountinfo/setDepositMethod" component={SetDepositMethod} />
      <PrivateRoute exact path="/accountinfo/manageDepositMethod" component={ManageDepositMethod} />
      <PrivateRoute exact path="/accountinfo/companyAddress" component={CompanyAddress} />
      <PrivateRoute exact path="/accountinfo/notificationOptions" component={NotificationOptions} />
      <PrivateRoute exact path="/accountinfo/returnSettings" component={ReturnSettings} />
      <PrivateRoute exact path="/videoAuthentication/MeetingTime" component={MeetingTime} />
      <PrivateRoute exact path="/videoAuthentication/MeetingMatters" component={MeetingMatters} />
      <PrivateRoute exact path="/videoAuthentication/VideoVerification" component={VideoVerification} />
      <PrivateRoute exact path="/videoAuthentication/AddressVerification" component={AddressVerification} />
      <PrivateRoute exact path="/videoAuthentication/CompleteVerification" component={CompleteVerification} />
      <PrivateRoute exact path="/twoStepVerification/Enable" component={Enable} />
      <PrivateRoute exact path="/twoStepVerification/Approve" component={Approve} />
      <PrivateRoute exact path="/twoStepVerification/SimulateLogin" component={SimulateLogin} />
      <PrivateRoute exact path="/twoStepVerification/IssuingAddress" component={IssuingAddress} />
      <PrivateRoute exact path="/twoStepVerification/Complete" component={Complete} />
      <PrivateRoute exact path="/twoStepVerification/Authenticator" component={Authenticator} />

      {/* <PrivateRoute exact path={'/AmazonInventory'} component={AmazonInventory}/> */}
    </Switch>
  );
};
