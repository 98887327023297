
import React from 'react'
import './VirtualBundles.css'
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'









function VirtualBundles(){
    return(

        <div>
            <Header/>
                <div>

                <div className="end14_content">
			<div className="end14_sidebar">
				<ul>
					<li>品牌控制面板</li>
					<li>品牌健康度</li>
					<li>搜索词优化器</li>
					<li>品牌福利</li>
					<li>买家评论</li>
				</ul>
				<p>筛选条件</p>
				<div className="end14_but">星级评定</div>
				<div className="end14_but">时间段</div>
				<div className="end14_wancheng">
					<label className="end14_switch">
						<input type="checkbox" />
						<div className="end14_slider end14_round"></div>
					</label>
					<span className="end14_biaoji">包含标记为"完成"</span>
				</div>
			</div>
			<div className="end14_maijia">
				<p className="end14_pinglun">买家评论<span>了解更多信息</span></p>
				<div className="end14_qingzy">
					<svg t="1610713529994" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14683"
					 width="20" height="20">
						<path d="M512 93.090909c232.727273 0 418.909091 186.181818 418.909091 418.909091s-186.181818 418.909091-418.909091 418.909091-418.909091-186.181818-418.909091-418.909091 186.181818-418.909091 418.909091-418.909091m0-93.090909C228.072727 0 0 228.072727 0 512s228.072727 512 512 512 512-228.072727 512-512S795.927273 0 512 0z"
						 fill="#2c2c2c" p-id="14684"></path>
						<path d="M512 279.272727m-46.545455 0a46.545455 46.545455 0 1 0 93.09091 0 46.545455 46.545455 0 1 0-93.09091 0Z"
						 fill="#2c2c2c" p-id="14685"></path>
						<path d="M512 791.272727c-27.927273 0-46.545455-18.618182-46.545455-46.545454V418.909091c0-23.272727 18.618182-46.545455 46.545455-46.545455 23.272727 0 46.545455 18.618182 46.545455 46.545455v325.818182c0 27.927273-18.618182 46.545455-46.545455 46.545454z"
						 fill="#2c2c2c" p-id="14686"></path>
					</svg>
					<span>请注意，对评价提供评论已禁用。现有的评论功能存在许多漏洞，可能会被滥用，也能用为我们的买家带来足够的价值。为了加快创新并提供卓越的购物体验，我们将删除此功能，专注于为我们商店中的买家提供更多创新功能和服务。</span>
					<svg t="1611577037862" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2544"
					 width="12" height="15">
						<path d="M936.37 989.454c-13.877 0-27.753-5.295-38.34-15.882L50.56 126.106c-21.175-21.176-21.175-55.51 0-76.685 21.177-21.174 55.51-21.174 76.684 0l847.468 847.47c21.175 21.173 21.175 55.507 0 76.683-10.587 10.587-24.465 15.88-38.341 15.88z"
						 p-id="2545"></path>
						<path d="M88.903 989.454c-13.877 0-27.754-5.295-38.342-15.882-21.175-21.175-21.175-55.508 0-76.683L898.028 49.42c21.177-21.174 55.507-21.174 76.684 0 21.175 21.175 21.175 55.51 0 76.685L127.244 973.574c-10.588 10.587-24.465 15.88-38.341 15.88z"
						 p-id="2546"></path>
					</svg>
				</div>
				<div className="end14_pinglunss">
					<input type="text" placeholder="桉子ASIN搜索" />
					<span><svg t="1611577991788" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
						 p-id="3915" width="20" height="20">
							<path d="M462.1312 805.9392A370.688 370.688 0 1 1 832.8192 435.2a371.0976 371.0976 0 0 1-370.688 370.7392z m0-677.376A306.688 306.688 0 1 0 768.8192 435.2a307.2 307.2 0 0 0-306.688-306.6368z"
							 p-id="3916"></path>
							<path d="M899.2256 959.4368a33.1776 33.1776 0 0 1-23.552-9.728l-217.9072-217.9072a33.28 33.28 0 1 1 47.0528-47.0528l217.9072 217.9072a33.28 33.28 0 0 1-23.552 56.832z"
							 p-id="3917"></path>
						</svg></span>
					<span>0 条评论</span>
				</div>
				<div className="end14_qingzy end14_qzy2">
					<span>您的品牌商品在过去90天内未收到任何未标记为已完成的买家评论。注册加入早期评论人计划，以帮助您的商品获得更多评论。</span>
				</div>
			</div>
		</div> 


                </div>
            <Footer/>

        </div>
    )
}
export default VirtualBundles