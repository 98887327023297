import React, { useRef, useEffect, useCallback, useState } from 'react';
import AJAX from "api/apis";
import './enterprise.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"
import '../../default.css/default.css'
import { Button, Collapse, Steps, Radio, Input, Form, Select, Modal, Checkbox, Popover } from 'antd';
import { CaretDownOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { getCheckAddressList } from 'utils/regDate';
import { regSubmit, getStoreRegisterInfo } from 'utils/PublicLibrary';
import RegisterSteps from 'components/registerSteps';
import RegisterCollapse from 'components/registerCollapse';


const { Panel } = Collapse;
const { Step } = Steps;
export default (props) => {
    let formRef = useRef();
    let formRef1 = useRef();
    let [corporateName, setCorporateName] = useState();
    let [isCodeVisible, setIsCodeVisible] = useState(false)
    let [verify, setVerify] = useState(false)
    let [verifyTips, setVerifyTips] = useState(false)
    let [phonePrefix, setPhonePrefix] = useState('+86')
    let [phoneCode, setPhoneCode] = useState(false)
    let [randoms, setRandoms] = useState();
    let [isCodeShow, setIsCodeShow] = useState();
    let [isSuccess, setIsSuccess] = useState(false);
    let [isReturn, setIsReturn] = useState(props.location?.state?.isReturn || 0);
    const companyAddressList = getCheckAddressList()
    const VerificationLanguage = [
        { label: '英语', value: '英语' },
        { label: '法语', value: '法语' },
        { label: '意大利语', value: '意大利语' },
        { label: '德语', value: '德语' },
        { label: '中文', value: '中文' },
        { label: '西班牙语', value: '西班牙语' }
    ]
    const [isTourOpen, setIsTourOpen] = useState(true);
    const steps = [
        {
            selector: '.stepGuidanceBox0',
            content: '公司注册号码就是社会统一信用代码',
        },
        {
            selector: '.stepGuidanceBox1',
            content: '选择国家地区，公司注册的国家地区',
        },
        {
            selector: '.stepGuidanceBox2',
            content: '邮政编码是企业注册地址的邮政编码，在操作资料一栏有',
        },
        {
            selector: '.stepGuidanceBox3',
            content: '选择注册地区的省/直辖市/自治区，填写中文',
        },
        {
            selector: '.stepGuidanceBox4',
            content: '选择城市，地级市，填写中文',
        },
        {
            selector: '.stepGuidanceBox5',
            content: '选择城市的区/县，填写中文',
        },
        {
            selector: '.stepGuidanceBox6',
            content: '街道/道路/门牌号，填写中文',
        },
        {
            selector: '.stepGuidanceBox7',
            content: '具体到楼层房间号，填写中文',
        },
        {
            selector: '.stepGuidanceBox8',
            content: '信息准确无误之后勾选此选项',
        },
        {
            selector: '.stepGuidanceBox9',
            content: '选择电话接收还是短信接收，电话语音接收亚马逊会通过电话语音通知验证码，短信接收会以短信形式发送验证码。',
        },
        {
            selector: '.yanPhone',
            content: '输入手机号码',
        },
        {
            selector: '.stepGuidanceBox12',
            content: '选择接收验证语言，选择中文',
        },
        // {
        //     selector: '.stepGuidanceBox13',
        //     content: '点击发送短信',
        // },
        {
            selector: '.stepGuidanceBox14',
            content: '填写法人的名字，最后一个字，填写英文',
        },
        {
            selector: '.stepGuidanceBox15',
            content: '填写法人名字中间那个字，没有的可不填写，填写英文',
        },
        {
            selector: '.stepGuidanceBox16',
            content: '填写法人的名字姓氏，第一个字，填写英文',
        },
        {
            selector: '.nextEvent',
            content: '填写信息准确无误之后点击同意并继续',
        }
    ];
    const [isActive, setIsActive] = useState(false);
    const awakenTips = (type) => {
        setIsActive(type);
    }
    const collapseList = [
        {
            header: '电话号码的正确格式是什么？',
            html: '<p>该数字的前缀必须是国家/地区代码。 下面是正确的格式：+1 1298279287</p>'
        },
        {
            header: '如果我没有可接收 SMS 的手机号码，应该怎么办？',
            html: '<p>您可以输入您的固定电话号码，然后选择拨打电话选项以接收电话验证 PIN。</p> '
        },
        {
            header: '如果我没有收到含有 PIN 的 SMS，应该怎么办？',
            html: '<p>检查您输入的电话号码格式是否正确。 再次请求 PIN。</p>'
        },
        {
            header: '如果“呼叫”选项在我所在的国家/地区未列出，我该怎么办？',
            html: '<p>如果在您所在的国家/地区中，“呼叫”选项不可用，请选择“短信”选项。</p>'
        },
        {
            header: '主要联系人是如何定义的？',
            html: '<p>主要联系人是指有权访问“亚马逊销售”付款帐户，代表帐户持有人（注册卖方）提供注册信息并发起交易（如付款和退款）的人。主要联系人采取的行动被视为帐户持有人采取的行动。</p>'
        },
    ]
    useEffect(() => {
        getRegInfo()
        MathRand()
    }, [])
    let MathRand = useCallback(() => {
        let Num = "";
        for (let i = 0; i < 6; i++) {
            Num += Math.floor(Math.random() * 10);
        }
        setRandoms(Num)
    })
    let nextEvent = useCallback(() => {
        formRef.current.validateFields().then((res) => {
            if (verify) {
                let arr = [
                    { "propertyKey": "company_number", "propertyValue": res.companyRegistrationNumber },
                    { "propertyKey": "company_business_site", "propertyValue": res.detailedAddressD + "------" + res.detailedAddressJ + "------" + res.area + "------" + res.city + "------" + res.province + "------" + res.postalCode + "------" + res.companyAddress.split(",")[0] },
                    { "propertyKey": "company_pin_verify", "propertyValue": res.codeMode },
                    { "propertyKey": "company_phone", "propertyValue": res.phone },
                    { "propertyKey": "company_district_number", "propertyValue": '中国' },
                    { "propertyKey": "company_note_language_state", "propertyValue": res.VerificationLanguage },
                    { "propertyKey": "company_legal_person_name", "propertyValue": res.middleName ? res.userName + "------" + res.middleName + "------" + res.surname : res.userName + "------" + res.surname },
                ]
                let localArr = [
                    { "propertyKey": "codeModeCheckbox", "propertyValue": res.codeModeCheckbox },
                ]
                regSubmit(arr, localArr, 'enterprise', 3).then((data) => {
                    if (data.code == 1) {
                        props.history.push('/sellerInfo')
                    }
                })
            } else {
                setTimeout(() => {
                    setVerifyTips(true);
                }, 300)
            }
        }).catch(err => {
            // setTimeout(() => {
            //     setVerifyTips(true);
            // }, 300)
        });
    }, [verify, isSuccess])
    let getRegInfo = useCallback(() => {
        getStoreRegisterInfo().then((res) => {
            for (let item of res.data) {
                console.log(item);
                if (item.propertyValue) {
                    if (item.propertyKey == "company_number") {
                        formRef.current.setFieldsValue({
                            companyRegistrationNumber: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "company_business_site") {
                        formRef.current.setFieldsValue({
                            companyAddress: item.propertyValue.split("------")[6],
                            detailedAddressJ: item.propertyValue.split("------")[1],
                            detailedAddressD: item.propertyValue.split("------")[0],
                            postalCode: item.propertyValue.split("------")[5],
                            province: item.propertyValue.split("------")[4],
                            city: item.propertyValue.split("------")[3],
                            area: item.propertyValue.split("------")[2],
                        })
                    }
                    if (item.propertyKey == "company_pin_verify") {
                        formRef.current.setFieldsValue({
                            codeMode: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "company_phone") {
                        formRef.current.setFieldsValue({
                            phone: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "company_district_number") {
                        formRef.current.setFieldsValue({
                            verificationArea: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "company_note_language_state") {
                        formRef.current.setFieldsValue({
                            VerificationLanguage: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "codeModeCheckbox") {
                        formRef.current.setFieldsValue({
                            codeModeCheckbox: item.propertyValue
                        })
                    }
                    if (item.propertyKey == "company_chinese_name") {
                        setCorporateName(item.propertyValue)
                    }
                    if (item.propertyKey == "company_legal_person_name") {
                        if (item.propertyValue.split("------").length === 2) {
                            formRef.current.setFieldsValue({
                                userName: item.propertyValue.split("------")[0],
                                surname: item.propertyValue.split("------")[1]
                            })
                        } else {
                            formRef.current.setFieldsValue({
                                userName: item.propertyValue.split("------")[0],
                                middleName: item.propertyValue.split("------")[1],
                                surname: item.propertyValue.split("------")[2]
                            })
                        }
                    }
                }
            }
        })
        AJAX.login.getStoreRegisterInfo([]).then((data) => {
            if (data.code == 1 && data.data) {
                for (let item of data.data) {
                    if (item.propertyValue) {
                        if (item.propertyKey == "company_chinese_name") {
                            setCorporateName(item.propertyValue)
                        }
                    }
                }
            }
        })
        if (isReturn) {
            AJAX.login.getStoreRegisterInfo([]).then((data) => {
                if (data.code == 1 && data.data) {
                    for (let item of data.data) {
                        if (item.propertyValue) {
                            if (item.propertyKey == "company_number") {
                                formRef.current.setFieldsValue({
                                    companyRegistrationNumber: item.propertyValue
                                })
                            }
                            if (item.propertyKey == "company_business_site") {
                                formRef.current.setFieldsValue({
                                    companyAddress: item.propertyValue.split("------")[6],
                                    detailedAddressJ: item.propertyValue.split("------")[1],
                                    detailedAddressD: item.propertyValue.split("------")[0],
                                    postalCode: item.propertyValue.split("------")[5],
                                    province: item.propertyValue.split("------")[4],
                                    city: item.propertyValue.split("------")[3],
                                    area: item.propertyValue.split("------")[2],
                                })
                            }
                            if (item.propertyKey == "company_pin_verify") {
                                formRef.current.setFieldsValue({
                                    codeMode: item.propertyValue
                                })
                            }
                            if (item.propertyKey == "company_phone") {
                                formRef.current.setFieldsValue({
                                    phone: item.propertyValue
                                })
                            }
                            if (item.propertyKey == "company_district_number") {
                                formRef.current.setFieldsValue({
                                    verificationArea: item.propertyValue
                                })
                            }
                            if (item.propertyKey == "company_note_language_state") {
                                formRef.current.setFieldsValue({
                                    VerificationLanguage: item.propertyValue
                                })
                            }
                            if (item.propertyKey == "codeModeCheckbox") {
                                formRef.current.setFieldsValue({
                                    codeModeCheckbox: item.propertyValue
                                })
                            }
                            if (item.propertyKey == "company_chinese_name") {
                                setCorporateName(item.propertyValue)
                            }
                            if (item.propertyKey == "company_legal_person_name") {
                                if (item.propertyValue.split("------").length === 2) {
                                    formRef.current.setFieldsValue({
                                        userName: item.propertyValue.split("------")[0],
                                        surname: item.propertyValue.split("------")[1]
                                    })
                                } else {
                                    formRef.current.setFieldsValue({
                                        userName: item.propertyValue.split("------")[0],
                                        middleName: item.propertyValue.split("------")[1],
                                        surname: item.propertyValue.split("------")[2]
                                    })
                                }
                            }
                        }
                    }
                }
            })
        }
    }, [isReturn])
    let showCodeVisible = useCallback(() => {
        formRef.current.validateFields(['phone']).then(() => {
            MathRand()
            setIsCodeShow(true)
            setIsCodeVisible(true)
        }).catch((err) => {
            console.log(err, "err")
        })
    }, [])
    let VerifyCode = useCallback(() => {
        formRef1.current.validateFields().then((res) => {
            setIsCodeVisible(false)
            setVerify(true)
            setVerifyTips(false);
            setIsSuccess(true)
        })
    }, [])
    const hideModalVisible = useCallback(() => {
        setIsCodeVisible(false)
    })
    const hideModalVisibleCode = useCallback(() => {
        setPhoneCode(false)
    })
    let languageSwitching = useCallback((e) => {
        setPhonePrefix(e.split(",")[1])
    }, [])
    let emailModal = useCallback(() => {
        setPhoneCode(true)
    }, [])
    const tipDom = useCallback((type) => {
        return (
            <div className='seller-tip'>
                <div className='close'>x</div>
                {
                    type == '1' ? (
                        <p>
                            营业执照是由政府机构发放的允许个人或公司开展业务的许可证。提供您的营业执照上的识别号码 - 中国大陆：统一的社会信用代码或注册号码， 中国台湾：公司统一号码， 中国香港：注册号码。
                        </p>
                    ) : (
                        <p>
                            我确认我的地址正确无误，并且我知道，在完成地址验证之前不能更改此信息。
                        </p>
                    )
                }
            </div>
        )
    }, [])
    return (
        <div className="enterpriseBox register_style">
            {/* 引导步骤 */}
            <TipsIcon clicks={awakenTips} />
            <Reactours routePath="enterprise" isActive={isActive} clicks={awakenTips} steps={steps} isOpen={isTourOpen} />
            <Header />
            <div style={{ display: 'flex' }}>
                <div className="a-section program-overview-values">
                    <RegisterSteps current={1} />
                    <div className="CompanyAddress">
                        <Form ref={formRef} layout="vertical">
                            <div>
                                <p style={{ fontWeight: 'bold' }}>公司名称：{corporateName}</p>
                                <div className='kat-divider'></div>
                                <p style={{ fontWeight: 'bold', marginTop: '30px' }}>
                                    公司注册号码
                                    <Popover
                                        content={tipDom('1')}
                                        trigger="hover"
                                    >
                                        <QuestionCircleFilled />
                                    </Popover>
                                </p>
                                <div className="stepGuidanceBox0">
                                    <Form.Item
                                        name="companyRegistrationNumber"
                                        rules={[{
                                            required: true,
                                            validator: (rule, value, fun) => {
                                                let reg = /(^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$)|(^\d{15}$)/
                                                if (!value) {
                                                    fun('请输入公司注册码')
                                                } else if (!reg.test(value)) {
                                                    fun('公司注册码无效')
                                                }
                                                fun()
                                            }
                                        }]}>
                                        <Input maxLength="18" />
                                    </Form.Item>
                                </div>
                                <div className='kat-divider'></div>
                                <p style={{ fontWeight: 'bold', marginTop: '30px' }}>
                                    公司营业执照注册地址或实际经营地址
                                    <Popover
                                        content={tipDom('2')}
                                        trigger="hover"
                                    >
                                        <QuestionCircleFilled />
                                    </Popover>
                                </p>
                                <div className="bottomDesc">
                                    <i className='a-icon-alert'></i>
                                    我们将邮寄一个地址验证码到您提供的地址，请用中文输入您的详细地址。英文或汉语拼音输入可能会导致邮寄延迟或失败。
                                </div>
                                <div>
                                    <div className="inpBox">
                                        <Form.Item className="stepGuidanceBox1" name="companyAddress" rules={[{ required: true, message: '请选择国家/地区' }]}>
                                            <Select placeholder="选择国家/地区" options={companyAddressList} />
                                        </Form.Item>
                                        <Form.Item
                                            className="stepGuidanceBox2"
                                            name="postalCode"
                                            rules={[{
                                                required: true,
                                                validator: (rule, value, fun) => {
                                                    let reg = /^[1-9][0-9]{5}$/;
                                                    if (!value) {
                                                        fun('请输入邮政编码')
                                                    } else if (!reg.test(value)) {
                                                        fun('邮政编码验证失败')
                                                    }
                                                    fun()
                                                }
                                            }]}>
                                            <Input placeholder="邮政编码" maxLength="6" />
                                        </Form.Item>
                                    </div>
                                    <div className="inpBox">
                                        <Form.Item className="stepGuidanceBox3" name="province" rules={[
                                            // { required: true, message: '请输入省/自治区/直辖市' },
                                            {
                                                required: true,
                                                validator: (rule, value, fun) => {
                                                    let reg = /[\u4E00-\u9FA5\uFE30-\uFFA0]/;
                                                    if (!value) {
                                                        fun('请输入省/自治区/直辖市')
                                                    } else if (!reg.test(value)) {
                                                        fun('请用中文填写')
                                                    }
                                                    fun()
                                                }
                                            }
                                        ]}>
                                            <Input placeholder="省/自治区/直辖市" maxLength="50" />
                                        </Form.Item>
                                        <Form.Item className="stepGuidanceBox4" name="city" rules={[{
                                            required: true,
                                            validator: (rule, value, fun) => {
                                                let reg = /[\u4E00-\u9FA5\uFE30-\uFFA0]/;
                                                if (!value) {
                                                    fun('请输入城市')
                                                } else if (!reg.test(value)) {
                                                    fun('请用中文填写')
                                                }
                                                fun()
                                            }
                                        }]}>
                                            <Input placeholder="城市" maxLength="50" />
                                        </Form.Item>
                                    </div>
                                    <div className="inpBox">
                                        <Form.Item className="stepGuidanceBox5" name="area" rules={[
                                            {
                                                required: true,
                                                validator: (rule, value, fun) => {
                                                    let reg = /[\u4E00-\u9FA5\uFE30-\uFFA0]/;
                                                    if (!value) {
                                                        fun('请输入区')
                                                    } else if (!reg.test(value)) {
                                                        fun('请用中文填写')
                                                    }
                                                    fun()
                                                }
                                            }
                                        ]}>
                                            <Input placeholder="区" maxLength="50" />
                                        </Form.Item>
                                        <Form.Item className="stepGuidanceBox6" name="detailedAddressJ" rules={[
                                            // { required: true, message: '请输入详细地址，如道路、街道、门牌号等' }
                                            {
                                                required: true,
                                                validator: (rule, value, fun) => {
                                                    let reg = /[\u4E00-\u9FA5\uFE30-\uFFA0]/;
                                                    if (!value) {
                                                        fun('请输入详细地址，如道路、街道、门牌号等')
                                                    } else if (!reg.test(value)) {
                                                        fun('请用中文填写')
                                                    }
                                                    fun()
                                                }
                                            }
                                        ]}>
                                            <Input placeholder="详细地址，如道路、街道、门牌号等" maxLength="100" />
                                        </Form.Item>
                                    </div>
                                    <div className="inpBox">
                                        <Form.Item className="stepGuidanceBox7" name="detailedAddressD" rules={[
                                            // { required: true, message: '请输入详细地址，如大厦、楼层、房间号等' }
                                            {
                                                required: true,
                                                validator: (rule, value, fun) => {
                                                    let reg = /[\u4E00-\u9FA5\uFE30-\uFFA0]/;
                                                    if (!value) {
                                                        fun('请输入详细地址，如大厦、楼层、房间号等')
                                                    } else if (!reg.test(value)) {
                                                        fun('请用中文填写')
                                                    }
                                                    fun()
                                                }
                                            }
                                        ]}>
                                            <Input placeholder="详细地址，如大厦、楼层、房间号等" maxLength="100" />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="aCheckbox">
                                    <Form.Item className="stepGuidanceBox8" name="codeModeCheckbox" rules={[{ required: true, message: '请确认地址是否有误' }]}>
                                        <Checkbox.Group>
                                            <Checkbox value="true" style={{ lineHeight: '32px' }}>
                                                我确认我的地址正确无误，并且我知道，在完成地址验证之前不能更改此信息。
                                            </Checkbox>
                                        </Checkbox.Group>
                                    </Form.Item>
                                </div>
                                <div className='kat-divider'></div>
                                <div className="receivingMode">
                                    <div>
                                        <p>PIN 接收方式</p>
                                        <Form.Item className="stepGuidanceBox9" initialValue={'1'} name="codeMode" rules={[{ required: true, message: '请选择 PIN接收方式' }]}>
                                            <Radio.Group>
                                                <Radio value="1">短信</Radio>
                                                <Radio value="2">电话</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>
                                    <div style={{ marginBottom: '18px' }}>
                                        <p>用于验证的电话号码</p>
                                        <div className="yanPhone" style={{ width: '50%' }}>
                                            {/* <Form.Item name="verificationArea" style={{ width: '100px' }} help='示例：+86 133 4546 6455' rules={[{ required: true, message: '请选择选择国家/地区' }]}>
                                                <Select onChange={languageSwitching} placeholder="选择国家/地区" value="中国" options={companyAddressList} />
                                            </Form.Item> */}
                                            <Form.Item name="phone"
                                                extra="示例：+86 133 4546 6455"
                                                rules={[{
                                                    required: true,
                                                    validator: (rule, value, fun) => {
                                                        let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                                                        if (!value) {
                                                            fun('请输入电话号码')
                                                        } else if (!reg.test(value)) {
                                                            fun('手机号码格式验证失败')
                                                        }
                                                        fun()
                                                    }
                                                }]}>
                                                <Input
                                                    prefix={<div className='yanzPhone_prefix'>
                                                        <img src={require("@/assets/images/cn.png").default} ></img>
                                                        <CaretDownOutlined />
                                                        +86
                                                    </div>}
                                                    maxLength="11"
                                                    disabled={isSuccess}
                                                />
                                            </Form.Item>
                                        </div>
                                        {/* <p style={{ color: '#565959', position: 'relative', color: '#767676', fontSize: '12px' }}>示例：+86 133 4546 6455</p> */}
                                    </div>
                                </div>
                                <p style={{ fontWeight: 'bold', display: isSuccess ? 'none' : 'block' }}>SMS 验证语言</p>
                                <div className="inpBox" style={{ display: isSuccess ? 'none' : 'flex' }}>
                                    <Form.Item className="stepGuidanceBox12" name="VerificationLanguage" initialValue={'中文'} rules={[{ required: true, message: '请选择国家/地区' }]}>
                                        <Select placeholder="选择国家/地区" options={VerificationLanguage} />
                                    </Form.Item>
                                    <div className="stepGuidanceBox13" style={{ width: '50%' }}>
                                        <Button className='next' onClick={showCodeVisible} type="primary"> 发送短信 </Button>
                                        {verifyTips ? <p style={{ marginLeft: '2%', color: '#ff4d4f', fontSize: '12px' }}>请验证手机号码</p> : null}
                                    </div>
                                </div>
                                <div style={{ display: !isSuccess ? 'none' : 'block' }} className="successBox">
                                    <svg t="1622017579677" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1190" width="14" height="14"><path d="M892.723 203.709c15.624-15.617 40.951-15.611 56.568 0.014 15.617 15.624 15.611 40.951-0.014 56.568L390.962 818.333c-15.68 15.673-41.116 15.603-56.71-0.155L76.568 557.783c-15.539-15.702-15.406-41.029 0.296-56.568 15.703-15.539 41.029-15.406 56.568 0.296L362.84 733.332 892.723 203.71z" fill="#007600" p-id="1191"></path></svg>
                                    <span>验证成功完成</span>
                                </div>

                                <div className='kat-divider'></div>
                                <p style={{ fontWeight: 'bold', marginTop: '30px' }}>法定代表人</p>
                                <div className="representative">
                                    <div>
                                        <Form.Item className="stepGuidanceBox14" name="userName" rules={[{
                                            required: true,
                                            validator: (rule, value, fun) => {
                                                let reg = /^[ a-zA-Z\u4e00-\u9fa5]+$/;
                                                if (!value) {
                                                    fun('请输入名字')
                                                } else if (!reg.test(value)) {
                                                    fun('请输入有效名字')
                                                }
                                                fun()
                                            }
                                        }]}>
                                            <Input placeholder="名字" maxLength="10" />
                                        </Form.Item>
                                        <Form.Item className="stepGuidanceBox15" name="middleName" rules={[{
                                            required: false,
                                            pattern: '^[ a-zA-Z\u4e00-\u9fa5]+$',
                                            message: '请输入有效中间名'
                                        }]}>
                                            <Input placeholder="中间名" maxLength="10" />
                                        </Form.Item>
                                        <Form.Item className="stepGuidanceBox16" name="surname" rules={[{
                                            required: true,
                                            validator: (rule, value, fun) => {
                                                let reg = /^[ a-zA-Z\u4e00-\u9fa5]+$/;
                                                if (!value) {
                                                    fun('请输入姓氏')
                                                } else if (!reg.test(value)) {
                                                    fun('请输入有效姓氏')
                                                }
                                                fun()
                                            }
                                        }]}>
                                            <Input placeholder="姓氏" maxLength="10" />
                                        </Form.Item>
                                    </div>
                                    <p>输入显示在您的护照或身份证上的完整姓名</p>
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    {/* <Link to="/sellerInfo"> */}
                                    <Button className='next nextEvent' onClick={nextEvent} type="primary">下一页</Button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </Form>
                    </div>
                    {isCodeShow ? <div className="elailTips" onClick={emailModal}>
                        <img src={require("@/assets/img/register/phone_pinComing.png").default} />
                    </div> : null}
                </div>
                <div className="rightCollapse">
                    <p >常见问题</p>
                    <RegisterCollapse list={collapseList} />
                </div>
            </div>
            <Modal className="codeModalBox" maskClosable={true} centered visible={isCodeVisible} footer={null} onCancel={hideModalVisible}>
                <p className="codeTitle">输入通过 SMS 收到的 PIN</p>
                <div className="codeContentBox">
                    <p>一次性 PIN</p>
                    <div className="verificationBox">
                        <Form ref={formRef1}>
                            <Form.Item name="verificationCode" rules={[{ required: true, validator: (rule, value, cbfn) => { if (!value) { return Promise.reject('请输入一次性验证码'); } else if (value != randoms) { return Promise.reject('验证码错误'); } return Promise.resolve(); } }]}>
                                <Input />
                            </Form.Item>
                        </Form>
                        <button onClick={VerifyCode} className="VerifyBtn">验证</button>
                    </div>
                    <p>是更改号码还是希望我们改用电话联系？ <span style={{ color: '#4f7dd1', cursor: 'pointer' }} onClick={() => { setIsCodeVisible(false) }}>取消</span></p>
                </div>
            </Modal>
            <Modal title="验证码" className="meailModalBox" centered visible={phoneCode} footer={null} onCancel={hideModalVisibleCode}>
                <p className="otp">{randoms}</p>
                <p>【亚马逊】Message from Amazon:Your one-time PIN is {randoms}</p>
            </Modal>
            <Footer />
        </div>
    )
}
