import InputNumber from "@a-components/AInputNumber";
import withProps from "@a-components/withProps";

const InputRate = withProps(
  (props) => {
    return (
      <InputNumber
        min={0}
        defaultValue={0}
        formatter={(value) => `${value}%`}
        parser={(value) => value.replace("%", "")}
        {...props}
      />
    );
  },
  {
    className: "a-input-money",
  }
);

export default InputRate;
