import { ATabs, AInput, AText, AButton } from "@a-components";
import classNames from "classnames";
import { useState } from "react";
import SearchPane from "./SearchPane";
import style from "./style.module.css";
import ExcludeList from "./ExcludedList";

const NegativeGoodsSelector = ({
  searchType,
  selectedNegativeList: selectedExcludeList = [],
  updateSelectedNegativeList: setSelectedExcludeList,
}) => {
  const [activeKey, setActiveKey] = useState("1");

  return (
    <div className={classNames(style.container, "ph-12")}>
      <div className="border-f0 ph-12 mb-12">
        <ATabs activeKey={activeKey} onTabClick={setActiveKey}>
          <ATabs.TabPane tab="搜索" key="1">
            <SearchPane
              searchType={searchType}
              selectedList={selectedExcludeList}
              updateList={setSelectedExcludeList}
            />
          </ATabs.TabPane>
          <ATabs.TabPane tab="输入列表" key="2">
            <div className="p-24">
              <AInput.TextArea rows={10}></AInput.TextArea>
              <div className="df jc-fe mt-12">
                <AButton>添加</AButton>
              </div>
            </div>
          </ATabs.TabPane>
          <ATabs.TabPane tab="上传" key="3">
            <div className="p-24">
              <AText>使用模板来确保您上传的信息准确无误。</AText>
              <br />
              <AButton className="mt-12">下载CSV模板</AButton>
              <div
                className="bg-f8 border-ccc border-radius-4 mt-12 df ai-c jc-c text-center c-999"
                style={{ height: 240 }}
              >
                拖放文件以添加 <br /> 或单击从您的计算机中选择 <br />
                可接受的格式：CSV, TSV, XLSX
              </div>
            </div>
          </ATabs.TabPane>
        </ATabs>
      </div>

      <ExcludeList
        dataSource={selectedExcludeList}
        onChange={setSelectedExcludeList}
      />
    </div>
  );
};

export default NegativeGoodsSelector;
