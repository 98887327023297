import React, { useState } from 'react';
import './Related Content.css'
import { Modal, Button } from 'antd';
import { Radio } from 'antd';
import IconFont from 'components/Icon/Icon'
function RelatedContent() {



    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };



    return (
        <div className="Related_head">
            <div className="Related_title">
                亚马逊可能会自动创建语言变体。
            </div>
            <div className="Related_navBox">
                <div>
                    <div className="Related_navhead">
                        <div className="Related_navtitle"><h3>语言变体</h3></div>
                        <Button type="primary" onClick={showModal}>
                            创建语言变体
                        </Button>
                        <Modal title="创建语言变体" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                            <div>创建该商品描述的语言变体，以确保您与广泛的受众建立联系。要创建语言变体，请从以下可用语言中选择并点击“创建”。然后，系统会复制您的布局及应用的 ASIN 并保存为草稿供您翻译。</div>
                            <div>创建商品描述语言变体：</div>
                            <Radio.Group name="radiogroup">
                                <div> <Radio value={1} disabled="none">美国  英语</Radio></div>
                                <div> <Radio value={2}>美国 西班牙语</Radio></div>
                                <div> <Radio value={3}>（以色列）希伯来语</Radio></div>
                            </Radio.Group>
                        </Modal>
                    </div>
                    <div className="Related_inpBox">
                        <div className="Related_inp">
                            <IconFont className="Related_inp_font" type="icon-sousuo" />
                            <input />
                        </div>
                    </div>

                    <div className="Related_bar">
                        <div className="Related_bar_head">
                            <div className="Related_bar_navI">
                                <div>商品描述名称</div>
                                <div style={{ width: "30px" }}><svg t="1610530444354" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2587" width="23" height="23"><path d="M719.8 384L512 735.9 304.2 384h415.6m0-64H304.2c-49.5 0-80.3 53.9-55.1 96.5l207.8 351.9c12.4 21 33.7 31.5 55.1 31.5s42.7-10.5 55.1-31.5l207.8-351.9c25.2-42.6-5.6-96.5-55.1-96.5z" p-id="2588"></path></svg></div>
                            </div>
                            <div className="Related_bar_navI Related_bar_navII">
                                <div>语言</div>
                                <div><svg t="1610530444354" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2587" width="14" height="14"><path d="M719.8 384L512 735.9 304.2 384h415.6m0-64H304.2c-49.5 0-80.3 53.9-55.1 96.5l207.8 351.9c12.4 21 33.7 31.5 55.1 31.5s42.7-10.5 55.1-31.5l207.8-351.9c25.2-42.6-5.6-96.5-55.1-96.5z" p-id="2588"></path></svg></div>
                            </div>
                            <div className="Related_bar_navI  Related_bar_navII">
                                <div>最后修改日期</div>
                                <div><svg t="1610530444354" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2587" width="14" height="14"><path d="M719.8 384L512 735.9 304.2 384h415.6m0-64H304.2c-49.5 0-80.3 53.9-55.1 96.5l207.8 351.9c12.4 21 33.7 31.5 55.1 31.5s42.7-10.5 55.1-31.5l207.8-351.9c25.2-42.6-5.6-96.5-55.1-96.5z" p-id="2588"></path></svg></div>
                            </div>
                            <div className="Related_bar_navII">
                                <div>商品描述状态</div>
                                <div><svg t="1610530444354" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2587" width="23" height="23"><path d="M719.8 384L512 735.9 304.2 384h415.6m0-64H304.2c-49.5 0-80.3 53.9-55.1 96.5l207.8 351.9c12.4 21 33.7 31.5 55.1 31.5s42.7-10.5 55.1-31.5l207.8-351.9c25.2-42.6-5.6-96.5-55.1-96.5z" p-id="2588"></path></svg></div>
                            </div>
                        </div>
                    </div>

                    <div className="Related_bar_nav">
                        <div className="Related_bar_navII">
                            <div>蒸汽式桑拿箱（黑色）</div>
                            <div>美国 英语</div>
                            <div>12/07/2020</div>
                            <div>已批准</div>
                        </div>
                    </div>





                </div>
            </div>
        </div>
    )
}
export default RelatedContent