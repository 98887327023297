import moment from "moment";

export const uglifyValue = (value = {}) => {
  return window.btoa(encodeURIComponent(JSON.stringify(value)));
};

export const beautifyValue = (string = "") => {
  return JSON.parse(decodeURIComponent(window.atob(string)));
};

export function minDateSelect(current, minDate = moment()) {
  return current && current.endOf("day") < minDate.endOf("day");
}

export function dataMixin(template, data, _format) {
  return template.map((item) => {
    const { format, key, ...otherItem } = item;

    const $_format = format || _format || ((v) => v);

    const base = {
      ...otherItem,
      key,
      value: $_format(data[key]),
    };

    if (item.children || item.content) {
      const key = base.children ? "children" : "content";
      base[key] = dataMixin(item[key], data, _format);
    }

    return base;
  });
}
export function getRequest(val) {
  const url = val ? val : window.location.search; //获取url中"?"符后的字串
  let theRequest = new Object();
  if (url.indexOf("?") != -1) {
    let str = url.substr(1);
    let strs = str.split("&");
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
    }
  }
  return theRequest;
}

export function fbaAddress(val) {
  let str = JSON.parse(JSON.stringify(val))
  str = str.replace(/------/g, '，')
  return str
}

// 数组扁平化
export function arrayFlat(arr) {
  return arr.reduce(function (pre, cur) {
    return pre.concat(Array.isArray(cur) ? arrayFlat(cur) : cur)
  }, [])
}
export function continuePath(path) {
  let paths = "/";
  switch (path) {
    // default:
    //   sessionStorage.removeItem("regInfo");
    //   paths = "/Agreements"
    //   break;
    case 1:
      sessionStorage.removeItem("regInfo");
      // 协议同意
      paths = "/Agreements"
      break;
    case 2:
      // 企业资讯
      paths = "/enterprise"
      break;
    case 3:
      // 卖家信息
      paths = "/sellerInfo"
      break;
    case 4:
      // 账单-付款信息
      paths = "/deposit"
      break;
    case 5:
      // 账单-信用卡
      paths = "/bill"
      break;
    case 6:
      // 店铺
      paths = "/shop"
      break;
    case 7:
      // 身份验证-上传文件-完成
      paths = "/AuthenticationThree"
      break;
    case 8:
      // 身份验证-视频
      paths = "/completeValidation"
      break;
    case 9:
      // 身份验证-选择时间
      paths = "/videoAuthentication/MeetingTime"
      break;
    case 10:
      // 身份验证-注意事项
      paths = "/videoAuthentication/meetingMatters"
      break;
    case 11:
      // 身份验证-视频通话验证
      paths = "/videoAuthentication/VideoVerification"
      break;
    case 12:
      // 身份验证-地址验证
      paths = "/videoAuthentication/AddressVerification"
      break;
    case 13:
      // 身份验证-关联至商城
      paths = "/videoAuthentication/CompleteVerification"
      break;
    case 14:
      // 身份验证-启用两步验证
      paths = "/twoStepVerification/Enable"
      break;
    case 15:
      // 身份验证-模拟登录
      paths = "/twoStepVerification/SimulateLogin"
      break;
    case 16:
      // 身份验证-地址的通知
      paths = "/twoStepVerification/SimulateLogin"
      break;
    case 17:
      // 身份验证-Approve
      paths = "/twoStepVerification/Approve"
      break;
    case 18:
      // 身份验证-验证认证器
      paths = "/twoStepVerification/Authenticator"
      break;
    case 19:
      // 身份验证-最后确认
      paths = "/twoStepVerification/Complete"
      break;
    case 20:
      // 身份验证-验证完成
      paths = "/"
      break;
    // case 21:
    //   // 税务信息调查
    //   paths = "/taxationInfo"
    //   break;
  }
  return paths
}

export function getUrlData(key) {
  //key存在先通过search取值如果取不到就通过hash来取
  let after = window.location.href.split("?")[1];
  if (after) {
    const reg = new RegExp("(^|&)" + key + "=([^&]*)(&|$)");
    let r = after.match(reg);
    if (r != null) {
      return decodeURIComponent(r[2]);
    } else {
      return null;
    }
  }
}
