export default {
    'header_nav_Catalog':'目录',
    'header_nav_Inventory':'库存',
    'header_nav_Pricing':'确定价格',
    'header_nav_Orders':'订单',
    'header_nav_Advertising':'广告',
    'header_nav_Stores':'品牌旗舰店',
    'header_nav_Growth':'增长',
    'header_nav_Reports':'数据报告',
    'header_nav_Performance':'绩效',
    'header_nav_Apps_Services':'应用商店',
    'header_nav_B2B':'B2B',
    'header_nav_Brands':'品牌',
};
  
  