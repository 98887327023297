import { ATabs, AInput, AButton, AText } from "@a-components";
import GoodsSearchList from "./GoodsSearchList";
import SelectedGoodsList from "./SelectedGoodsList";
import { useCallback } from "react";
import "./style.css";

const GoodsSelector = ({ value: selectedGoodsList = [], onChange }) => {
  const onAdd = useCallback(
    (item) => {
      item = [].concat(item);

      onChange?.([...selectedGoodsList, ...item]);
    },
    [onChange, selectedGoodsList]
  );
  return (
    <div className="goods-card df">
      <div className="f-1 left-box">
        <ATabs defaultActiveKey="1">
          <ATabs.TabPane tab="搜索" key="1">
            <GoodsSearchList
              onAdd={onAdd}
              selectedGoodsList={selectedGoodsList}
            />
          </ATabs.TabPane>
          <ATabs.TabPane tab="输入列表" key="2">
            <div className="p-24">
              <AInput.TextArea rows={10}></AInput.TextArea>
              <div className="df jc-fe mt-12">
                <AButton>添加</AButton>
              </div>
            </div>
          </ATabs.TabPane>
          <ATabs.TabPane tab="上传" key="3">
            <div className="p-24">
              <AText>使用模板来确保您上传的信息准确无误。</AText>
              <br />
              <AButton className="mt-12">下载CSV模板</AButton>
              <div
                className="bg-f8 border-ccc border-radius-4 mt-12 df ai-c jc-c text-center c-999"
                style={{ height: 240 }}
              >
                拖放文件以添加 <br /> 或单击从您的计算机中选择 <br />
                可接受的格式：CSV, TSV, XLSX
              </div>
            </div>
          </ATabs.TabPane>
        </ATabs>
      </div>
      <div className="f-1">
        <SelectedGoodsList value={selectedGoodsList} onChange={onChange}/>
      </div>
    </div>
  );
};

export default GoodsSelector;
