import useQuery from "@hooks/useQuery";
import {
  useMemo,
  useState,
  createElement,
  useCallback,
  useEffect,
} from "react";

import PromotionCreate from "./../PromotionCreate";
import DiscountCreate from "./../DiscountCreate";
import GiftsCreate from "./../GiftsCreate";
import { useEventEmitter } from "ahooks";
import { AButton } from "@a-components/";
import { message, Space } from "antd";
import Header from "components/home_header";
import Footer from "components/addlisting_footer/FooterCom";

import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"

import { useHistory } from "react-router-dom";
import Apis from "@api/apis";

// operatorType: 'create' | 'detail' | 'modify' | 'copy'

const PromotionDetail = () => {
  const {
    promotionType,
    operatorType = "detail",
    promotionId = "",
    isPreview: _isPreview = "0",
  } = useQuery();
  const history = useHistory();
  const isPreview = _isPreview === "1";
  const event$ = useEventEmitter();
  const showType = isPreview ? "preview" : "edit";
  const historyForward = useCallback(
    ({
      operatorType: innerOperatorType = operatorType,
      isPreview: innerPreview = isPreview,
      promotionId: innerPromotionId = promotionId,
    }) => {
      history.push(
        `/Promotions/PromotionDetail?${new URLSearchParams({
          promotionType,
          promotionId: innerPromotionId,
          operatorType: innerOperatorType,
          isPreview: innerPreview ? "1" : "0",
        }).toString()}`
      );
    },
    [history, isPreview, operatorType, promotionId, promotionType]
  );
  const isTourOpen = true;
  const [onSubmitLoading, setonSubmitLoading] = useState(true)
  const [steps1, setSteps1] = useState([
    {
      selector: '.stepGuidanceBox0',
      content: '选择需要折扣的商品',
    },
    {
      selector: '.stepGuidanceBox1',
      content: '如果没有的可以进行创建，创建商品时，【商品选择列表名称/追踪编码】，必须要跟操作资料中的产品名称对应',
    },
    {
      selector: '.stepGuidanceBox2',
      content: '设置买家获得的折扣（15%的折扣就是八五折）',
    },
    {
      selector: '.stepGuidanceBox3',
      content: '设置折扣开始的日期',
    },
    {
      selector: '.stepGuidanceBox4',
      content: '设置折扣开始的时间',
    },
    {
      selector: '.stepGuidanceBox5',
      content: '设置折扣结束的日期',
    },
    {
      selector: '.stepGuidanceBox6',
      content: '设置折扣结束的时间',
    },
    {
      selector: '.stepGuidanceBox7',
      content: '设置折扣的内部描述',
    },
    {
      selector: '.stepGuidanceBox8',
      content: '设置折扣的追踪编码，方便卖家分辨',
    },
    {
      selector: '.stepGuidanceBox9',
      content: '是否勾选亚马逊影响着和联盟，即亚马逊合作的设置媒体',
    },
    {
      selector: '.stepGuidanceBox10 .a-radio:first-child',
      content: '设置每位买家的兑换次数，每个买家单个订单的仅可使用一次',
    },
    {
      selector: '.stepGuidanceBox10 .a-radio:nth-child(2)',
      content: '每个买家仅单个订单可使用多次',
    },
    {
      selector: '.stepGuidanceBox10 .a-radio:nth-child(3)',
      content: '每个买家多个订单可使用多次',
    },
    {
      selector: '.stepGuidanceBox13',
      content: '系统推荐的优惠码',
    },
    {
      selector: '.promotionDetails .stepGuidanceBox99:last-child .selectBtn',
      content: '点击查看进行下一步',
    }
  ]);
  const setPreviewStatus = useCallback(
    (isPreview) => {
      historyForward({ isPreview });
    },
    [historyForward]
  );

  const handlePreview = useCallback(() => {
    // console.log(document.getElementById('formBox'),"thissss")
    setPreviewStatus(true);
  }, [setPreviewStatus]);

  const handleSubmit = useCallback(() => {
    event$.emit({ type: "submit" });
  }, [event$]);

  const operaCreateBtnRow = useMemo(() => {
    return (
      <Space className="mt-12 stepGuidanceBox99">
        <AButton onClick={() => history.push("/Promotions")}>
          查看 “管理促销” 页面
        </AButton>
        {isPreview ? (
          <Space>
            <AButton onClick={() => setPreviewStatus(false)}>返回</AButton>
            <AButton type="primary" onClick={handleSubmit}>
              提交
            </AButton>
          </Space>
        ) : (
          <AButton type="primary" className="selectBtn" onClick={handlePreview}>
            查看
          </AButton>
        )}
      </Space>
    );
  }, [handlePreview, handleSubmit, history, isPreview, setPreviewStatus]);

  const handleClosePromotion = useCallback(() => {
    return Apis.promotion.updatePromotionEnd({ promotionId }).then((res) => {
      if (res.code !== 1) return res;

      message.success("促销已结束");
    });
  }, [promotionId]);

  const operaDetailBtnRow = useMemo(() => {
    return (
      <Space className="mt-12">
        <AButton onClick={() => history.push("/Promotions")}>
          查看 “管理促销” 页面
        </AButton>
        <AButton
          onClick={() =>
            history.push(
              `/Promotions/DiscountCodeManage?promotionId=${promotionId}`
            )
          }
        >
          管理优惠码
        </AButton>
        <AButton onClick={() => historyForward({ operatorType: "modify" })}>
          编辑促销
        </AButton>
        <AButton
          onClick={() => {
            event$.emit({ type: "reloadCode" });
            historyForward({ operatorType: "create" });
          }}
        >
          复制促销
        </AButton>
        <AButton onClick={handleClosePromotion}>结束促销</AButton>
        <AButton>查看更改历史记录</AButton>
      </Space>
    );
  }, [event$, handleClosePromotion, history, historyForward, promotionId]);

  const operaModifyBtnRow = useMemo(() => {
    return (
      <Space className="mt-12">
        <AButton onClick={() => history.goBack()}>返回</AButton>
        {isPreview ? (
          <AButton type="primary" onClick={handleSubmit}>
            提交
          </AButton>
        ) : (
          <AButton type="primary" onClick={() => setPreviewStatus(true)}>
            查看
          </AButton>
        )}
      </Space>
    );
  }, [handleSubmit, history, isPreview, setPreviewStatus]);

  const operatorRowMapping = useMemo(() => {
    const createTitle = {
      1: "社交媒体促销代码",
      2: "购买折扣",
      3: "买一赠一",
    };

    return {
      create: {
        title: `创建促销: ${createTitle[promotionType]}`,
        operatorRow: operaCreateBtnRow,
      },
      detail: {
        title: "查看促销",
        operatorRow: operaDetailBtnRow,
      },
      modify: {
        title: "编辑促销",
        operatorRow: operaModifyBtnRow,
      },
    };
  }, [promotionType, operaCreateBtnRow, operaDetailBtnRow, operaModifyBtnRow]);

  const currentOperatorConfig = operatorRowMapping[operatorType];

  const handleModifyPromotion = useCallback(
    (params) => {
      return Apis.promotion.updatePromotion({ ...params, promotionId });
    },
    [promotionId]
  );

  const handleCreatePromotion = useCallback((params) => {
    return Apis.promotion.addPromotion(params);
  }, []);

  const handleQueryPromotion = useCallback(() => {
    return Apis.promotion.getPromotionDetail({ promotionId }).then((res) => {
      if (res.code !== 1) return res;
      event$.emit({ type: "resetDataSource", payload: res.data });
    });
  }, [event$, promotionId]);

  useEffect(() => {
    promotionId && handleQueryPromotion();
    judgmentSteps();
  }, [handleQueryPromotion, promotionId]);
  const judgmentSteps = useCallback(() => {
    if (promotionType == 1) {
      setSteps1([
        {
          selector: '.stepGuidanceBox0',
          content: '选择需要折扣的商品',
        },
        {
          selector: '.stepGuidanceBox1',
          content: '如果没有的可以进行创建，创建商品时，【商品选择列表名称/追踪编码】，必须要跟操作资料中的产品名称对应',
        },
        {
          selector: '.stepGuidanceBox2',
          content: '设置买家获得的折扣（15%的折扣就是八五折）',
        },
        {
          selector: '.stepGuidanceBox3',
          content: '设置折扣开始日期',
        },
        {
          selector: '.stepGuidanceBox4',
          content: '设置折扣开始时间',
        },
        {
          selector: '.stepGuidanceBox5',
          content: '设置折扣结束日期',
        },
        {
          selector: '.stepGuidanceBox6',
          content: '设置折扣结束时间',
        },
        {
          selector: '.stepGuidanceBox7',
          content: '设置折扣的内部描述',
        },
        {
          selector: '.stepGuidanceBox8',
          content: '设置折扣的追踪编码，方便卖家分辨',
        },
        {
          selector: '.stepGuidanceBox9',
          content: '是否勾选亚马逊影响着和联盟，即亚马逊合作的设置媒体',
        },
        {
          selector: '.stepGuidanceBox10 .a-radio:first-child',
          content: '设置每位买家的兑换次数，每个买家单个订单的仅可使用一次',
        },
        {
          selector: '.stepGuidanceBox10 .a-radio:nth-child(2)',
          content: '每个买家仅单个订单可使用多次',
        },
        {
          selector: '.stepGuidanceBox10 .a-radio:nth-child(3)',
          content: '每个买家多个订单可使用多次',
        },
        {
          selector: '.stepGuidanceBox13',
          content: '系统推荐的优惠码',
        }
        // {
        //   selector: '.promotionDetails .stepGuidanceBox99:last-child .selectBtn',
        //   content: '点击查看进行下一步',
        // }
      ])
    } else if (promotionType == 2) {
      setSteps1([
        {
          selector: '.stepGuidanceBox1',
          content: '选择卖家所购买商品',
        },
        {
          selector: '.stepGuidanceBox2',
          content: '选择最低购买的数量',
        },
        {
          selector: '.stepGuidanceBox10',
          content: '点击创建需要折扣的产品，创建商品时，【商品选择列表名称/追踪编码】，必须要跟操作资料中的产品名称对应',
        },
        {
          selector: '.stepGuidanceBox9',
          content: '创建完成之后选择类别',
        },
        {
          selector: '.stepGuidanceBox11',
          content: '选择减免折扣%/金额',
        },
        {
          selector: '.stepGuidanceBox12',
          content: '输入减免折扣%数/金额数量',
        },
        {
          selector: '.stepGuidanceBox13',
          content: '选择需购买商品/额外购买的商品',
        },
        {
          selector: '.stepGuidanceBox14',
          content: '买家需购买商品数量',
        },
        {
          selector: '.stepGuidanceBox15',
          content: '买家可获得折扣',
        },
        {
          selector: '.stepGuidanceBox3',
          content: '设置折扣开始日期',
        },
        {
          selector: '.stepGuidanceBox4',
          content: '设置折扣开始时间',
        },
        {
          selector: '.stepGuidanceBox5',
          content: '设置折扣结束日期',
        },
        {
          selector: '.stepGuidanceBox6',
          content: '设置折扣结束时间',
        },
        {
          selector: '.stepGuidanceBox7',
          content: '设置折扣的内部描述（详情页可见）',
        },
        {
          selector: '.stepGuidanceBox8',
          content: '设置促销的追踪编码（卖家追踪使用）',
        },
        {
          selector: '.stepGuidanceBox88 .a-radio:nth-child(2)',
          content: '选择无限制优惠码，一个优惠码可重复使用',
        }
        // {
        //   selector: '.promotionDetails .stepGuidanceBox99:last-child .selectBtn',
        //   content: '点击查看进行下一步',
        // }
      ])
    } else if (promotionType == 3) {
      setSteps1([
        {
          selector: '.stepGuidanceBox1',
          content: '选择买家所购买商品，此商品最低购买数量',
        },
        {
          selector: '.stepGuidanceBox2',
          content: '输入最低购买的数量',
        },
        {
          selector: '.stepGuidanceBox9',
          content: '选择想要折扣的商品，促销商品必须要跟操作资料中的一致',
        },
        {
          selector: '.stepGuidanceBox10',
          content: '如果没有需要创建，创建商品时，【商品选择列表名称/追踪编码】，必须要跟操作资料中的产品名称对应',
        },
        {
          selector: '.stepGuidanceBox11',
          content: '选择买家获得赠品',
        },
        {
          selector: '.stepGuidanceBox12',
          content: '选择需购买商品或者额外购买的商品，需购买商品（买A折扣AA为折扣的商品，选择需额外购买商品，买B折扣A，B为需要额外购买的商品，A为折扣的商品）',
        },
        {
          selector: '.stepGuidanceBox13',
          content: '选择买家优惠获得的赠品数量',
        },
        {
          selector: '.stepGuidanceBox3',
          content: '设置折扣开始日期',
        },
        {
          selector: '.stepGuidanceBox4',
          content: '设置折扣开始时间，促销时间必须要精确到小时',
        },
        {
          selector: '.stepGuidanceBox5',
          content: '设置折扣结束日期',
        },
        {
          selector: '.stepGuidanceBox6',
          content: '设置折扣结束时间，促销时间必须要精确到小时',
        },
        {
          selector: '.stepGuidanceBox7',
          content: '设置折扣的内部描述（详情页可见）',
        },
        {
          selector: '.stepGuidanceBox8',
          content: '设置促销的追踪编码（卖家追踪使用），提示重复时，可在管理你的促销中删除',
        },
        {
          selector: '.stepGuidanceBox88 .a-radio:nth-child(2)',
          content: '选择无限制优惠码，一个优惠码可重复使用',
        }
        // {
        //   selector: '.promotionDetails .stepGuidanceBox99:last-child .selectBtn',
        //   content: '点击查看进行下一步',
        // }
      ])
    }
  }, [promotionType])
  const [isActive, setIsActive] = useState(false);
  const awakenTips = (type) => {
    setIsActive(type);
  }
  const handleToSubmitData = useCallback(
    (dataSource) => {
      if (!onSubmitLoading) return
      console.log(dataSource)
      setonSubmitLoading(false)
      const fetcher =
        promotionId && operatorType !== "create"
          ? handleModifyPromotion
          : handleCreatePromotion;

      return fetcher(dataSource).then((res) => {
        if (res.code !== 1) return res;
        history.push("/Promotions?tab=manage");
      }).catch(err => {
        setTimeout(() => {
          setonSubmitLoading(true)
        }, 3000)
      });
    },
    [
      handleCreatePromotion,
      handleModifyPromotion,
      history,
      operatorType,
      promotionId,
      onSubmitLoading,
      setonSubmitLoading
    ]
  );

  const curComponent = useMemo(() => {
    const mapping = {
      1: PromotionCreate,
      2: DiscountCreate,
      3: GiftsCreate,
    };

    const cur = mapping[promotionType || 1];
    return createElement(cur, {
      showType: operatorType === "detail" ? "preview" : showType,
      onSubmit: handleToSubmitData,
      event$,
      operatorType,
    });
  }, [event$, handleToSubmitData, operatorType, promotionType, showType]);
  return (
    <div>
      <Header />

      <div className="p-24 promotionDetails">
        <div className="fs-24 ">{currentOperatorConfig?.title}</div>
        {currentOperatorConfig.operatorRow}
        {curComponent}
        {currentOperatorConfig.operatorRow}
      </div>

      <Footer />
      {/* 引导步骤 */}
      <TipsIcon clicks={awakenTips} />
      <Reactours routePath="promotionDetails" isActive={isActive} clicks={awakenTips} discountStep={promotionType} steps={steps1} isOpen={isTourOpen} />
    </div>
  );
};

export default PromotionDetail;
