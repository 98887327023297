import React from 'react'
import './desktop.css'


function desktop(){
    return(
        <div className="desktop_body">
            <div className="desktop_link">
                <div>预览链接</div>
            </div>
            <div className="desktop_Nav">
                <div className="desktop_NavII">
                    <div className="desktop_Nav_img">图片</div>
                    <div className="desktop_Nav_name">
                        <p>Use of steam engine</p>
                        <div>When using the Steam Sauna Box Black , insert the steam engine on the left</div>
                    </div>
                </div>

                
                <div className="desktop_NavII">
                    <div className="desktop_Nav_img">图片</div>
                    <div className="desktop_Nav_name">
                        <p>Use of steam engine</p>
                        <div>When using the Steam Sauna Box Black , insert the steam engine on the left</div>
                    </div>
                </div>


                <div className="desktop_NavII">
                    <div className="desktop_Nav_img">图片</div>
                    <div className="desktop_Nav_name">
                        <p>Use of steam engine</p>
                        <div>When using the Steam Sauna Box Black , insert the steam engine on the left</div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default desktop