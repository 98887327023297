import Header from "components/home_header";
import Description from "@view/Promotions/children/Description";
import CardCollapsible from "./../CardCollapsible";

import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"

import { AButton, AInput, ATable, AInputNumber } from "@a-components/";
import { Row, Col, message, Spin } from "antd";

import { useCallback, useEffect, useState } from "react";
import useQuery from "@hooks/useQuery";

import Apis from "@api/apis";
import { useHistory } from "react-router";
import { baseURL } from "@api/config";
const detailOptions = [
  {
    label: "追踪编码",
    key: "trackingCode",
  },
  {
    label: "内部描述",
    key: "description",
  },
  {
    label: "开始日期",
    key: "startTime",
  },
  {
    label: "结束日期",
    key: "endTime",
  },
  {
    label: "优惠码总数量",
    key: "discountCodeNum",
  },
];

const createDiscountCodeGroupOptions = [
  {
    label: "组名称",
    key: "groupName",
    render(value, { setState }) {
      return (
        <Row>
          <Col className="stepGuidanceBox0" span={6}>
            <AInput
              value={value}
              onChange={(e) => setState({ groupName: e.target.value })}
              placeholder="输入组名称"
            />
          </Col>
        </Row>
      );
    },
  },
  {
    label: "数量",
    key: "generatedQuantity",
    render(value, { setState }) {
      return (
        <Row>
          <Col className="stepGuidanceBox1" span={6}>
            <AInputNumber
              className="w-100percent"
              value={value}
              onChange={(value) => setState({ generatedQuantity: value })}
              placeholder="输入数量"
            />
          </Col>
        </Row>
      );
    },
  },
  {
    label: "",
    render(_, { store: { handleCreate } }) {
      return <AButton onClick={handleCreate}>创建</AButton>;
    },
  },
];
const isTourOpen = true;
const steps1 = [
  {
    selector: '.stepGuidanceBox0',
    content: '编辑一次性优惠码名称',
  },
  {
    selector: '.stepGuidanceBox1',
    content: '设置一次性优惠码数量',
  }
];

const tableColumns = [
  {
    title: "优惠码组",
    key: "groupName",
    dataIndex: "groupName",
  },
  {
    title: "数量",
    key: "generatedQuantity",
    dataIndex: "generatedQuantity",
  },
  {
    title: "创建日期",
    key: "createTime",
    dataIndex: "createTime",
  },
  {
    title: "生成者",
    key: "generatedUserName",
    dataIndex: "generatedUserName",
  },
  {
    title: "状态",
    key: "generatedStatusName",
    dataIndex: "generatedStatusName",
  },
  {
    title: "下载",
    key: "promotionCodeGroupId",
    dataIndex: "promotionCodeGroupId",
    render(promotionCodeGroupId) {
      const url = `${baseURL}/promotion/discountCodeDownload?promotionCodeGroupId=${promotionCodeGroupId}`;
      return (
        <a href={url}>
          下载
        </a>
      );
    },
  },
];

const DiscountCodeManage = () => {
  const { promotionId } = useQuery();
  const history = useHistory();
  const [discountCodeDetail, setDiscountCodeDetail] = useState({});
  const [discountCodeGroupList, setDiscountCodeGroupList] = useState([]);
  const [createInfo, setCreateInfo] = useState({});

  const [isCreateLoading, setCreateLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const awakenTips = (type) => {
    setIsActive(type);
  }

  /* 获取详情 */
  const getManagementDiscountCodeDetail = useCallback(() => {
    return Apis.promotion
      .getManagementDiscountCodeDetail({ promotionId })
      .then((res) => {
        if (res.code !== 1) return res;
        setDiscountCodeDetail(res.data || {});
      });
  }, [promotionId]);

  useEffect(() => {
    promotionId && getManagementDiscountCodeDetail();
  }, [promotionId, getManagementDiscountCodeDetail]);

  /* 获取列表 */
  const getManagementDiscountCodeGroupList = useCallback(() => {
    return Apis.promotion
      .getManagementDiscountCodeGroupList({ promotionId })
      .then((res) => {
        if (res.code !== 1) return res;

        setDiscountCodeGroupList(res.data);
      });
  }, [promotionId]);

  useEffect(() => {
    promotionId && getManagementDiscountCodeGroupList();
  }, [promotionId, getManagementDiscountCodeGroupList]);

  /* 创建优惠码组 */
  const addPromotionDiscountCodeGroup = useCallback(() => {
    setCreateLoading(true);
    return Apis.promotion
      .addPromotionDiscountCodeGroup({
        ...createInfo,
        promotionId,
      })
      .then((res) => {
        if (res.code !== 1) return res;

        message.success("创建成功");
        setCreateInfo({});
        getManagementDiscountCodeDetail();
        getManagementDiscountCodeGroupList();
      })
      .finally(() => {
        setCreateLoading(false);
      });
  }, [
    createInfo,
    getManagementDiscountCodeDetail,
    getManagementDiscountCodeGroupList,
    promotionId,
  ]);

  const handleCreate = useCallback(() => {
    addPromotionDiscountCodeGroup();
  }, [addPromotionDiscountCodeGroup]);

  const handlePreviewPromotion = useCallback(() => {
    const info = {
      promotionType: discountCodeDetail.promotionType,
      operatorType: "detail",
      promotionId,
    };
    history.push(
      `/Promotions/PromotionDetail?${new URLSearchParams(info).toString()}`
    );
  }, [discountCodeDetail.promotionType, history, promotionId]);

  return (
    <div>
      <Header />
      <div className="p-24">
        <div className="fs-24">管理优惠码</div>
        <div className="mt-12">
          <AButton onClick={handlePreviewPromotion}>查看促销</AButton>
        </div>
        <CardCollapsible className="mt-12" title="促销一览">
          <Description
            bordered
            column={1}
            labelStyle={{ width: 240, textAlign: "right" }}
            options={detailOptions}
            dataSource={discountCodeDetail}
          />
        </CardCollapsible>

        <Spin spinning={isCreateLoading}>
          <CardCollapsible className="mt-12" title="创建优惠码组">
            <Description
              bordered
              column={1}
              labelStyle={{ width: 240, textAlign: "right" }}
              options={createDiscountCodeGroupOptions}
              dataSource={createInfo}
              onChange={setCreateInfo}
              store={{ handleCreate }}
            />
          </CardCollapsible>
        </Spin>

        <div className="mt-24">{discountCodeGroupList.length} 优惠码组</div>
        <ATable
          className="mt-12"
          columns={tableColumns}
          dataSource={discountCodeGroupList}
          pagination={false}
        ></ATable>
      </div>
      {/* 引导步骤 */}
      <TipsIcon clicks={awakenTips} />
      <Reactours routePath="DiscountCodeManage" isActive={isActive} clicks={awakenTips} steps={steps1} isOpen={isTourOpen} />
    </div>
  );
};

export default DiscountCodeManage;
