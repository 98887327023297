import { Input as AntInput } from "antd";
import withProps from "./../withProps";

const Input = withProps(
  (props) => {
    return <AntInput min={1} {...props} />;
  },
  {
    className: "a-input",
  }
);

Input.Search = withProps(AntInput.Search, {
  className: "a-input-search",
});
Input.TextArea = withProps(AntInput.TextArea, {
  className: "a-input-textarea",
});

export default Input;
