import { useMemo } from "react";
import { Button } from "antd";
import { ATableForm, AInputMoney } from "@a-components";
import { FormItem } from "@components/FormCollection";
import { CloseOutlined } from "@ant-design/icons";

const SelectedKeywordList = () => {
  const columns = useMemo(() => {
    return [
      {
        title: "关键词",
        name: "keyword",
        key: "name",
        className: "fs-12",
        width: "25%",
      },
      {
        title: "匹配类型",
        name: "matchTypeName",
        key: "matchTypeName",
        className: "fs-12",
        width: "20%",
      },
      {
        title: "建议竞价",
        key: "suggestedBidding",
        name: "suggestedBidding",
        width: "25%",
        render(suggestedBidding, { row }) {
          if (
            !suggestedBidding ||
            !row.suggestedBiddingStart ||
            !row.suggestedBiddingEnd
          )
            return "-";

          return (
            <div>
              <div className="fs-12 c-999">${suggestedBidding.toFixed(2)}</div>
              <div className="fs-12 c-999">
                ${row.suggestedBiddingStart.toFixed(2)} - $
                {row.suggestedBiddingEnd.toFixed(2)}
              </div>
            </div>
          );
        },
      },
      {
        title: "竞价",
        name: "biddingPrice",
        key: "biddingPrice",
        width: "25%",
        render(biddingPrice, { updateCurrentRow }) {
          return (
            <AInputMoney
              value={biddingPrice}
              onChange={(value) => updateCurrentRow("biddingPrice", value)}
            />
          );
        },
      },
      {
        title: " ",
        key: "opera",
        render(_, { remove }) {
          return <CloseOutlined onClick={remove} />;
        },
      },
    ];
  }, []);

  return (
    <div style={{ height: 648 }}>
      <FormItem noStyle dependencies={["selectedKeywordsList"]}>
        {(form) => {
          const selectedKeywordsList = form.getFieldValue(
            "selectedKeywordsList"
          );
          return (
            <div className="df jc-sb ai-c h-44" style={{ padding: "0 12px" }}>
              <span>已添加{selectedKeywordsList.length}个</span>
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={() =>
                  form.setFieldsValue({ selectedKeywordsList: [] })
                }
              >
                全部删除
              </Button>
            </div>
          );
        }}
      </FormItem>

      <ATableForm
        height={606}
        name="selectedKeywordsList"
        columns={columns}
        dependencies={["selectedKeywordsList"]}
        wrapperBorder={false}
      />
    </div>
  );
};

export default SelectedKeywordList;
