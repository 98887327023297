import React from "react";
import "./AutomatePricing.css";
import "../default.css/default.css";
import Header from "components/home_header";
import Footer from "components/addlisting_footer/FooterCom";
import {Link} from 'react-router-dom'
function AutomatePricing() {
  return (
    <div className="automate-pricing">
      <Header />

      <div className="qd_15_body">
        <div className="qd_15_bigcontent">
          <div className="qd_15_content">
            <p className="qd_15_content_h1">
              自动定价
              <span>
                了解更多信息 <span id="qd_15_tigong">| </span> &nbsp; 提供反馈
              </span>
            </p>
          </div>
          <div className="qd_15_content1">
            <p>
              使用亚马逊免费的工具能快速更新您整个目录的产品价格。使用预定义规则能更快更简单的开始定价，或者创建白定义定价规则满足您特定的业务需求,
            </p>
          </div>
          <table
            border="0"
            cellspacing=""
            cellpadding=""
            className="qd_15_xingdong"
          >
            <tr>
              <th>快速行动</th>
              <th>有竞争力的价格</th>
              <th>保持控制</th>
              <th>完成更多</th>
            </tr>
            <tr>
              <th>根据您选择的事件自动定价，是商品在动态坏境中保持竞争力</th>
              <th>提升您商品成为特色商品的潜在可能性。</th>
              <th>在您设定的最高价格和最低价格之间定价。</th>
              <th>
                自动执行某些价格决策可以使您有更多时间关注其他重要业务方面。
              </th>
            </tr>
          </table>
          <div className="qd_15_content2">
            <p>按商城进行筛选</p>
            <div id="qd_15_cont">
              <select className="qd_15_cont2_sel" name="">
                <option value="">Amazon.com</option>
              </select>
            </div>
          </div>
          <div className="qd_15_content3">
            <p className="qd_15_cont3_yu">
              预定义的自动定价规则
              <select className="qd_15_cont3_seleI" name="">
                <option>这是什么？</option>
              </select>
              <button className="qd_15_cont3_btn" type="button">
                <Link to="/AutoPricing" >创建自定义定价规则</Link>
              </button>
            </p>
          </div>
        </div>

        <div className="qd_15_bigcontent1">
          <table
            border="0"
            cellspacing="0"
            cellpadding="1"
            className="qd_15_big1"
          >
            <tr className="qd_15_tr1">
              <th className="qd_15_th1">规则名</th>
              <th className="qd_15_th2">商城</th>
              <th className="qd_15_th3">Amazon.com 中的SKU</th>
              <th className="qd_15_th4">类型</th>
              <th className="qd_15_th5">规则一览</th>
              <th className="qd_15_th6">状态</th>
              <th className="qd_15_th7">操作</th>
            </tr>
            <tr className="qd_15_tr2">
              <td>Competitive Price Rule by Amazon</td>
              <td>
                Amazon.com和 <br />
                <select className="qd_15_tr2_sel">
                  <option value="">其他 2 个商城？</option>
                </select>
              </td>
              <td>0</td>
              <td>有竞争力的购买按钮规则</td>
              <td>
                匹配特色商品价格针对相同ASIN和状况的所有商品。也与亚马逊以外零售商的价格进行比较。在其他位置更新价格后，继续自动定价。
              </td>
              <td>在售</td>
              <td>
                <button id="qd_15_tr2_btn" type="button">
                 <Link to="/ManageSKUs" > 编辑SKU</Link>
                </button>
              </td>
            </tr>
            <tr className="qd_15_tr2">
              <td>Competitive Price Rule by Amazon</td>
              <td>
                Amazon.com和 <br />
                <select className="qd_15_tr2_sel">
                  <option value="">其他 2 个商城？</option>
                </select>
              </td>
              <td>0</td>
              <td>有竞争力的购买按钮规则</td>
              <td>
                匹配特色商品价格针对相同ASIN和状况的所有商品。也与亚马逊以外零售商的价格进行比较。在其他位置更新价格后，继续自动定价。
              </td>
              <td>在售</td>
              <td>
                <button id="qd_15_tr2_btn" type="button">
                  <Link to="/ManageSKUs" >编辑SKU</Link>
                </button>
              </td>
            </tr>
          </table>
        </div>
        <div className="qd_15_content4">
          <p className="qd_15_con4_p">自定义的自动定价规则</p>
          <p className="qd_15_con4_p2">
            希望设定特定的价格策略来满足您个人的业务需求?
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default AutomatePricing;
