import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./ReturnAddressSetting.css"
import { Button, Divider, Modal, Form, Input, Checkbox, Select, Row, Col } from 'antd';
import { BorderOutlined } from '@ant-design/icons';
const { Option } = Select;
function ReturnAddressSetting() {
  const [setAddress, setSetAddress] = useState(false);
  const [administration, setAdministration] = useState(false);
  const [addAddress, setAddAddress] = useState(false);


  return (
    <div className="return_address_setting" >
      <div className="return_address_setting_left" >
        <div className="return_setting_item" >
          <h3>默认退货地址</h3>
          <div className="return_setting_item" >
            <Button onClick={() => { setSetAddress(true) }} >设置地址</Button>
            <Divider />
            <div>所有退货都将发送到此地址，除非在下面的商城例外中指定了其他地址</div>
          </div>
        </div>
        <div className="return_setting_item return_setting_box" >
          <strong>Amazon.com.mx 覆盖</strong>
          <span className="return_span" >添加新的覆盖内容</span>
        </div>
        <div className="return_setting_item return_setting_box" >
          <strong>Amazon.ca 覆盖</strong>
          <span className="return_span" >添加新的覆盖内容</span>
        </div>
        <div className="return_setting_item return_setting_box" >
          <strong>Amazon.com 覆盖</strong>
          <span className="return_span" >添加新的覆盖内容</span>
        </div>
      </div>
      <div className="return_address_setting_right" >
        <div className="return_address_setting_right_item" >
          <h3>您是否有多个退货地址？</h3>
          <div>单击此链接添加新的退货地址或编辑现有退货地址 </div>
          <span className="return_span" onClick={() => { setAdministration(true) }} >管理您的退货地址</span>
        </div>
        <div className="return_address_setting_right_item" >
          <h3>通过批量上传设置特定SKU的退货地址</h3>
          <span className="return_span" >下载退货属性模板</span>
          <br />
          <span className="return_span" >在退货属性覆盖页面上上传文件.</span>
          <br />
          <span className="return_span" >有关更多信息，请访问帮助页面</span>
        </div>
      </div>

      <Modal
        title="&nbsp;&nbsp;" style={{ top: "40%" }}
        visible={setAddress} footer={false}
        onCancel={() => { setSetAddress(false) }}
        // closeIcon={<BorderOutlined style={{ color: "rgb(196,85,0)" }} />}
        className="return_address_setting return_address_setting_modal"
        width="800px"
      >
        <strong>从地址中选择</strong>
        <Divider />
        <div>没有地址可供选择。请使用以下链接来管理地址: <span className="return_span" onClick={() => {
          setSetAddress(false)
          setAdministration(true)
        }} >管理您的退 货地址</span></div>
        <div style={{ textAlign: "right", marginTop: 20, width: 800 }} ><Button>使用该地址</Button></div>
      </Modal>
      <Modal
        title="管理您的退货地址" style={{ top: "40%" }}
        visible={administration} footer={false}
        onCancel={() => { setAdministration(false) }}
        // closeIcon={<BorderOutlined style={{ color: "rgb(196,85,0)" }} />}
        className="return_address_setting return_address_setting_modal"
        width="800px"
      >
        <div style={{ textAlign: "center", padding: "30px" }} >
          ← Previous page &nbsp;&nbsp;&nbsp;&nbsp; Next page →
        </div>
        <div style={{ textAlign: "left", marginTop: 20, width: 800 }} > <span className="return_span" onClick={() => {
          setAdministration(false)
          setAddAddress(true)
        }} >+ 添加新的地址</span></div>
      </Modal>
      <Modal
        title="添加新的地址" style={{ top: "1%" }}
        visible={addAddress} footer={false}
        onCancel={() => { setAddAddress(false) }}
        // closeIcon={<BorderOutlined style={{ color: "rgb(196,85,0)" }} />}
        className="return_address_setting return_address_setting_modal"
        width="800px"
      >
        <Form name="addAddress" layout="vertical" style={{ width: 800 }} >
          <Form.Item label="地址名称 ">
            <Input />
          </Form.Item>

          <Form.Item label="国家/地区">
            <Select defaultValue={"1"} >
              <Option value="1">中国</Option>
              <Option value="2">印度</Option>
              <Option value="3">埃及</Option>
              <Option value="4">墨西哥</Option>
              <Option value="5">意大利</Option>
              <Option value="6">新加坡</Option>
            </Select>
          </Form.Item>
          <Form.Item label="姓名 ">
            <Input />
          </Form.Item>
          <Form.Item label="电话 ">
            <Input />
          </Form.Item>
          <Form.Item label="地址 ">
            <Input placeholder="街道地址或者邮政邮箱" />
            <Input placeholder="公寓、套房、单元、大楼、楼层等" />
          </Form.Item>
          <Row>
            <Col span={7} >
              <Form.Item label="城市 ">
                <Input />
              </Form.Item>
            </Col>
            <Col span={7} offset={1} >
              <Form.Item label="州 ">
                <Select defaultValue={"1"} >
                  <Option value="1">中国</Option>
                  <Option value="2">印度</Option>
                  <Option value="3">埃及</Option>
                  <Option value="4">墨西哥</Option>
                  <Option value="5">意大利</Option>
                  <Option value="6">新加坡</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={7} offset={1} >
              <Form.Item label="邮编 ">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>将此作为我的默认地址</Checkbox>
          </Form.Item>
          <Form.Item label="我们需要额外的指示来找到这个地方吗？ ">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="是否需要安全密码或呼叫箱号码才能进入这栋楼？ ">
            <Input />
          </Form.Item>
          <Form.Item label="周末配送 ">
            <span className="return_span" >您在什么日期可以接收包裹？</span>
          </Form.Item>
          <Form.Item label="">
            <Button className="return_set_address" onClick={() => { setAddAddress(false) }} >添加地址</Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default ReturnAddressSetting