import React, { useState } from 'react'
import './BrandAccountSale.css'
import { Form, Input, Select, Row, Col, Checkbox, Table } from 'antd';
const { Option } = Select;

function BrandAccountSale() {
  const selectBefore = (
    <Select defaultValue="1" className="select-before">
      <Option value="1">Amazon.com</Option>
      <Option value="2">Amazon.com</Option>
      <Option value="3">Amazon.co.uk</Option>
      <Option value="4">Amazon.com.tr</Option>
    </Select>
  );
  const [checkboxValue, setCheckboxValue] = useState([]);
  const [markValue, setMarkValue] = useState([]);
  const onChange = (e) => {
    setCheckboxValue(e);
  }
  const columns = [
    {
      title: '全选',
      dataIndex: 'name',
      render: (text) => <a>{text}</a>,
    },
    {
      title: '',
      dataIndex: 'address',
      render: (text) => {
        return (
          <div>
            {
              text.map((item, index) => {
                return <div key={index} >
                  <img src={require(`@assets/images/${item.img}.png`).default} style={{ marginRight: 10 }} ></img>
                  Big Corn
                </div>
              })
            }
          </div>
        )
      }
    },
  ];


  const data = [
    {
      key: '1',
      name: 'A1JMHKUY4MOA0X',
      address: [
        {
          img: "BranchBig1"
        },
        {
          img: "BranchBig2"
        },
        {
          img: "BranchBig3"
        }
      ],
    },
    {
      key: '2',
      name: 'A1RWIDASUPN315',
      address: [
        {
          img: "BranchMengk"
        },
      ],
    },
    {
      key: '3',
      name: 'AFCEUM2GY0WJU',
      address: [
        {
          img: "BranchBig4"
        },
        {
          img: "BranchBig5"
        },
        {
          img: "BranchBig6"
        },
        {
          img: "BranchBig7"
        },
        {
          img: "BranchBig8"
        }
      ],
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setMarkValue(selectedRows)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  return (
    <div className="BrandAccountSale" >
      <h3>销售账户信息</h3>
      <div style={{ marginBottom: 20 }} >以下信息将有助于我们了解您与正在注册的品牌 zwlong 之间的关系</div>
      <Form layout="vertical">
        <strong>对于正在注册的品牌，您是卖家还是供应商？</strong>
        <Form.Item label="请选择描述您与亚马逊之间的销售关系的所有适用选项">
          <Checkbox.Group style={{ width: '100%' }} onChange={onChange}>
            <Row>
              <Col span={9}>
                <Checkbox value="A">卖家</Checkbox>
                <p>卖家直接向买家销售其商品:并自行配送订单
                  或使用亚马逊物流配送订单。无论您在哪个市
                  场销售您的品牌商品，都需要提供您的卖家信
                  息
                </p>
              </Col>
              <Col span={9}>
                <Checkbox value="B">供应商</Checkbox>
                <p>供应商作为第三方向亚马逊销售其商品，通过
                  亚马逊运营中心存储和配送订单
                </p>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>

        {
          checkboxValue.includes("A") && (
            <React.Fragment>
              <strong>哪些销售账户管理您品牌的商品？ （可选）</strong>
              <Form.Item label="请选择管理品牌商品的所有销售账户。详细了解销售账户和卖家记号。" >
                <Table
                  rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                  }}
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                />
                <strong>已选择 {markValue.length} 个销售账户，共 3 个：</strong>
                {
                  markValue.length > 0 ? (
                    <div style={{ color: "#ccc" }} >
                      {
                        markValue.map(item => {
                          return <span key={item.key} >{item.name},</span>
                        })
                      }
                    </div>
                  ) : <div style={{ color: "#ccc" }} >未选择任何销售账户。</div>
                }
                <div>如果您想要关联的销售账户未列出，那么一旦申请获得批准，您将有机会添加该账户。</div>
              </Form.Item>
            </React.Fragment>
          )
        }
        {
          checkboxValue.includes("B") && (
            <Form.Item label="请提供与您的品牌相关的供应商代码  ">
              <Input placeholder="例如：EAYX6" />
            </Form.Item>
          )
        }





        <strong>请提供最符合您品牌的类别</strong>
        <Form.Item label="如果您的品牌未在亚马逊上销售，请从以下列表中选择适用于您品牌的商品分类。" placeholder="选择分类" >
          <Select placeholder="选择分类" >
            <Option value="1">DVD</Option>
            <Option value="2">Kindle配件</Option>
            <Option value="3">MP3下载</Option>
            <Option value="4">VHS</Option>
            <Option value="5">个人计算机</Option>
          </Select>
        </Form.Item>
        <Form.Item label="请为您销售的品牌所属的每个商品分类提供畅销商品的 ASIN 如果您在亚马逊上销售您的品牌商品，请提供示例 ASIN，以便帮助我们更好地识别您的品牌">
          <Input addonBefore={selectBefore} placeholder="例如：B0342" />
        </Form.Item>

      </Form>
    </div >
  )
}

export default BrandAccountSale
