import { AButton, ASelect, AInput } from "@a-components/";
import { Row, Col, Alert, Space } from "antd";

import CardCollapsible from "./../CardCollapsible";
import { useCallback, useMemo, useState } from "react";

import MessageBox from "./../MessageBox";
import Description from "./../Description";

import PromotionTimeCard from "./../../PromotionTimeCard";
import { initValue as initTimeValue } from "./../../PromotionTimeCard/config";
import PromotionMoreCard from "./../../PromotionMoreCard";
import { initValue as initMoreValue } from "./../../PromotionMoreCard/config";

import CategorySelector from "@view/Promotions/CategorySelector";
import moment from "moment";

import { Link } from "react-router-dom";

import Apis from "@api/apis";

const options2Mapping = (options) => {
  return options.reduce((r, item) => {
    r[item.value] = item.label;
    return r;
  }, {});
};
const buyGoodsOptions = [
  {
    label: "最低金额($)",
    value: 1,
  },
  {
    label: "此商品的最低购买数量",
    value: 2,
  },
  {
    label: "每购买商品(数量)",
    value: 3,
  },
];

const buyGoodsMapping = options2Mapping(buyGoodsOptions);

const applyRangeOptions = [
  {
    label: "须购买商品",
    value: 1,
  },
  {
    label: "额外购买的商品",
    value: 2,
  },
];
const applyRangeMapping = options2Mapping(applyRangeOptions);

const buyerGetOptions = [{ label: "减免折扣%", value: 1 }];
const buyerGetMapping = options2Mapping(buyerGetOptions);
let isChanage = 0
const promotionConditionOptions = [
  {
    key: "buyerPurchasesGoods",
    label: "买家所购商品",
    render(value, { record, setState, store: { showTypeSelector } }) {
      return (
        <Row>
          <Col className="stepGuidanceBox1" span={4}>
            {showTypeSelector({
              edit: () => (
                <ASelect
                  className="w-100percent"
                  options={buyGoodsOptions}
                  value={value}
                  onChange={(e) =>

                    setState({
                      buyerPurchasesGoods: e
                    })
                  }
                  defaultValue={2}
                ></ASelect>
              ),
              preview: () =>
                buyGoodsOptions.find((v) => v.value === value).label,
              modify: () =>
                buyGoodsOptions.find((v) => v.value === value).label,
            })}
          </Col>
          <Col className="stepGuidanceBox2" span={3} offset={1}>
            {showTypeSelector({
              edit: () => (
                <AInput
                  className="w-100percent"
                  type="number"
                  min="1"
                  value={record.buyerPurchasesGoodsValue}
                  onChange={(e) => {
                    const defaultList = [
                      {
                        buyerPurchasesGoodsValue: e.target.value,
                        buyersGetValue: record.buyersGetValue,
                      }
                    ];
                    isChanage = 1
                    setState({ buyerPurchasesGoodsValue: e.target.value, addPromotionHierarchyReqList: defaultList })
                  }
                  }
                ></AInput>
              ),
              preview: () => record.buyerPurchasesGoodsValue,
              modify: () => record.buyerPurchasesGoodsValue,
            })}
          </Col>
        </Row>
      );
    },
  },
  {
    label: "须购买商品",
    key: "promotionGoodsId",
    render(value, { setState, store: { showTypeSelector } }) {
      return (
        <Row>
          <Col className="stepGuidanceBox9" span={4}>
            {showTypeSelector({
              edit: () => (
                <CategorySelector
                  value={value}
                  onChange={(value) => setState({ promotionGoodsId: value })}
                  placeholder="选择类别"
                  className="w-100percent"
                ></CategorySelector>
              ),
              preview: () => (
                <CategorySelector
                  readOnly
                  value={value}
                  placeholder="选择类别"
                  className="w-100percent"
                ></CategorySelector>
              ),
            })}
          </Col>
          {showTypeSelector({
            edit: () => (
              <>
                <Col className="stepGuidanceBox10" span={3} offset={1}>
                  <Link to="/Promotions/ProductSelection">
                    创建新的商品选择
                  </Link>
                </Col>
                <Col offset={1}>
                  <MessageBox message="影响者和联盟可能会发布您的促销消息以引导流量。参加此计划并不保证商品能够被投放。" />
                </Col>
              </>
            ),
            preview: () => null,
          })}
        </Row>
      );
    },
  },
  {
    key: "buyersGet",
    label: "买家获得",
    render(value, { record, setState, store: { showTypeSelector } }) {
      return (
        <Row>
          <Col className="stepGuidanceBox11" span={4}>
            {showTypeSelector({
              edit: () => (
                <ASelect
                  className="w-100percent"
                  options={buyerGetOptions}
                  value={value}
                  onChange={(e) => setState({
                    buyersGet: e,
                  })}
                ></ASelect>
              ),
              preview: () => buyerGetOptions.find((v) => v.value)?.label,
              modify: () => buyerGetOptions.find((v) => v.value)?.label,
            })}
          </Col>
          <Col className="stepGuidanceBox12" span={3} offset={1}>
            {showTypeSelector({
              edit: () => (
                <AInput
                  className="w-100percent"
                  type="number"
                  min="1"
                  value={record.buyersGetValue}
                  onChange={(e) => {
                    const defaultList = [
                      {
                        buyerPurchasesGoodsValue: record.buyerPurchasesGoodsValue,
                        buyersGetValue: e.target.value,
                      }
                    ];
                    isChanage = 1
                    setState({ buyersGetValue: e.target.value, addPromotionHierarchyReqList: defaultList })
                  }}
                ></AInput>
              ),
              preview: () => record.buyersGetValue,
              modify: () => record.buyersGetValue,
            })}
          </Col>
        </Row>
      );
    },
  },
  {
    key: "scopeApplication",
    label: "适用范围",
    render(value, { setState, record, store: { showTypeSelector } }) {
      return (
        <Row>
          <Col className="stepGuidanceBox13" span={4}>
            {showTypeSelector({
              edit: () => (
                <ASelect
                  options={applyRangeOptions}
                  value={value}
                  onChange={(e) => setState({ scopeApplication: e })}
                  className="w-100percent"
                ></ASelect>
              ),
              preview: () =>
                applyRangeOptions.find((v) => v.value === value)?.label ?? "",
              modify: () =>
                applyRangeOptions.find((v) => v.value === value)?.label ?? "",
            })}
          </Col>
        </Row>
      );
    },
  },
  {
    key: 4,
    label: "促销层级",
    $visible(value, { record }) {
      return (
        [1, 2].includes(record.buyerPurchasesGoods) &&
        record.scopeApplication === 1
      );
    },
    render(value, { record, setState, store: { showTypeSelector } }) {
      let defaultList = record.addPromotionHierarchyReqList || []
      if (record.promotionHierarchyVoList) {
        defaultList = record.promotionHierarchyVoList
      }
      // const defaultList = [
      //   {
      //     buyerPurchasesGoodsValue: record.buyerPurchasesGoodsValue,
      //     buyersGetValue: record.buyersGetValue,
      //   },
      //   ...(list),
      // ];
      const handleChangeValue = (index, key, value) => {

        const newList = record.addPromotionHierarchyReqList.map(
          (item, i) => {
            if (i == index) {
              item[key] = value
              return item
            } else {
              return item
            }
          }
        );
        // console.log(newList, record, index, key, value);
        if (index === 0) {
          let obj = {}
          obj[key] = value
          setState({ addPromotionHierarchyReqList: newList, ...obj });
        }
        else {
          setState({ addPromotionHierarchyReqList: newList });
        }
      };

      const handleAddItem = () => {
        let arrList = []
        // if (record.addPromotionHierarchyReqList) {
        //   record.addPromotionHierarchyReqList.map((item, index) => {
        //     if (index != 0) arrList.push(item)
        //   })
        // }
        arrList = record.addPromotionHierarchyReqList
        arrList = arrList.concat({ buyerPurchasesGoodsValue: "", buyersGetValue: "" })
        isChanage = 0
        setState({
          addPromotionHierarchyReqList: arrList,
        });
      };
      //.slice(0, -1),
      const handleRemoveItem = () => {
        let arrList = record.addPromotionHierarchyReqList
        arrList.pop()
        setState({
          addPromotionHierarchyReqList: arrList
        });
      };

      const renderRow = (item, index) => {
        const buyerPurchasesGoods = item?.buyerPurchasesGoodsValue || item?.buyerPurchasesGoods || '';
        const buyersGet = item?.buyersGetValue || item?.buyersGet || '';

        return (
          <Row key={index} className="mt-12">
            <Col span={4}>
              {showTypeSelector({
                edit: () => (
                  <AInput
                    placeholder={buyGoodsMapping[record.buyerPurchasesGoods]}
                    disabled
                  ></AInput>
                ),
                preview: () => buyGoodsMapping[record.buyerPurchasesGoods],
                modify: () => buyGoodsMapping[record.buyerPurchasesGoods],
              })}
            </Col>
            <Col className="stepGuidanceBox14" span={3} offset={1}>
              {showTypeSelector({
                edit: () => (
                  <AInput
                    type="number"
                    min="1"
                    value={buyerPurchasesGoods}
                    onChange={(e) =>
                      handleChangeValue(
                        index,
                        "buyerPurchasesGoodsValue",
                        e.target.value
                      )
                    }
                  ></AInput>
                ),
                preview: () => buyerPurchasesGoods,
                modify: () => buyerPurchasesGoods,
              })}
            </Col>
            <Col className="stepGuidanceBox15" span={4} offset={1}>
              {showTypeSelector({
                edit: () => (
                  <AInput
                    placeholder={buyerGetMapping[record.buyersGet]}
                    disabled
                  ></AInput>
                ),
                preview: () => buyerGetMapping[record.buyersGet],
                modify: () => buyerGetMapping[record.buyersGet],
              })}
            </Col>
            <Col span={4} offset={1}>
              {showTypeSelector({
                edit: () => (
                  <AInput
                    type="number"
                    min="1"
                    value={buyersGet}
                    onChange={(e) =>
                      handleChangeValue(index, "buyersGetValue", e.target.value)
                    }
                  ></AInput>
                ),
                preview: () => buyersGet,
                modify: () => buyersGet,
              })}
            </Col>
          </Row>
        );
      };

      return (
        <div>
          <Row>
            <Col span={12} className="fw-600">
              买家所购买商品
            </Col>
            <Col span={12} className="fw-600">
              买家获得
            </Col>
          </Row>

          {defaultList.map(renderRow)}

          {showTypeSelector({
            edit: () => (
              <Space className="mt-12">
                <AButton onClick={handleAddItem}>添加促销层级</AButton>
                {defaultList.length > 1 ? (
                  <AButton onClick={handleRemoveItem}>删除促销层级</AButton>
                ) : null}
              </Space>
            ),
            modify: () => null,
          })}
        </div>
      );
    },
  },
  {
    key: "additionalGoodsCode",
    label: "额外购买的商品",
    $visible(value, { record }) {
      return record.scopeApplication === 2;
    },
    render(value, { setState, store: { showTypeSelector } }) {
      return (
        <Row>
          <Col span={3}>
            {showTypeSelector({
              edit: () => (
                <AInput
                  value={value}
                  onChange={(e) =>
                    setState({ additionalGoodsCode: e.target.value })
                  }
                  placeholder="输入ASIN"
                  className="w-100percent"
                ></AInput>
              ),
              preview: () => value,
              modify: () => value,
            })}
          </Col>
        </Row>
      );
    },
  },
  {
    key: "discountGoodsNumber",
    label: "购买优惠的合适商品数量",
    $visible(value, { record }) {
      return record.buyerPurchasesGoods == 3
    },
    render(value, { setState, record, store: { showTypeSelector } }) {
      if (record.scopeApplication == 2) {
        return <Row>
          <Col className="stepGuidanceBox13" span={4}>
            {showTypeSelector({
              edit: () => (
                <AInput
                  value={value}
                  className="w-100percent"
                  type="number"
                  min="1"
                  onChange={(e) =>
                    setState({ discountGoodsNumber: e.target.value })
                  }
                  placeholder=""
                ></AInput>
              ),
              preview: () => value,
              modify: () => value,
            })}
          </Col>
        </Row>
      }
      return (
        <Row>
          <Col className="stepGuidanceBox13" span={4}>
            {showTypeSelector({
              edit: () => (
                <AInput
                  value={value}
                  className="w-100percent"
                  type="number"
                  min="1"
                  onChange={(e) =>
                    setState({ discountGoodsNumber: e.target.value })
                  }
                  placeholder=""
                ></AInput>
              ),
              preview: () => value,
              modify: () => value,
            })}
          </Col>
          <Col offset={1} span={7}>当卖家购买</Col>
          <Col className="stepGuidanceBox13" span={4}>
            {showTypeSelector({
              edit: () => (
                <AInput
                  className="w-100percent"
                  type="number"
                  min="1"
                  value={record.buyerPurchasesGoodsValue}
                  onChange={(e) => {
                    const defaultList = [
                      {
                        buyerPurchasesGoodsValue: record.buyerPurchasesGoodsValue,
                        buyersGetValue: record.buyersGetValue,
                      }
                    ];
                    isChanage = 1
                    setState({ buyerPurchasesGoodsValue: e.target.value, addPromotionHierarchyReqList: defaultList })
                  }
                  }
                ></AInput>
              ),
              preview: () => record.buyerPurchasesGoodsValue,
              modify: () => record.buyerPurchasesGoodsValue,
            })}
          </Col>
          <Col offset={1}>件商品</Col>
        </Row>
      );
    },
  },
  {
    label: "更多选项",
    key: "更多选项",
    content: [
      {
        label: "不参加促销的商品",
        key: "notPromotionGoodsId",
        render(value, { setState, store: { showTypeSelector } }) {
          return (
            <Row>
              <Col span={4}>
                {showTypeSelector({
                  edit: () => (
                    <CategorySelector
                      value={value}
                      onChange={(value) => {
                        setState({ notPromotionGoodsId: value })
                      }}
                      className="w-100percent"
                    ></CategorySelector>
                  ),
                  preview: () => (
                    <CategorySelector
                      value={value}
                      readOnly
                      className="w-100percent"
                    ></CategorySelector>

                  ),
                })}
              </Col>
              <Col offset={1}>
                {showTypeSelector({
                  edit: () => (
                    <Link to="/Promotions/ProductSelection">
                      创建新的商品选择
                    </Link>
                  ),
                })}
              </Col>
            </Row>
          );
        },
      },
    ],
  },
];

const DiscountCreate = ({ showType, event$, onSubmit, operatorType }) => {
  const showTypeSelector = useCallback(
    (arg) => {
      const _showType =
        operatorType === "modify" && showType === "edit" ? "modify" : showType;

      let value = arg[_showType] || arg[showType];

      if (typeof value === "function") {
        return value();
      }

      return value;
    },
    [operatorType, showType]
  );
  const [dataSource, setDataSource] = useState({
    promotionType: 2,
    buyerPurchasesGoods: 1,
    buyersGet: 1,
    scopeApplication: 1,
    ...initTimeValue,
    ...initMoreValue,
    // ...(setDataSource.promotionHierarchyVoList ? { addPromotionHierarchyReqList: dataSource.promotionHierarchyVoList } : {}),
    trackingCode: `购买折扣 ${moment().format("YYYY/MM/DD HH-mm-ss-SSS")}`,
  });

  const getDiscountCode = useCallback(() => {
    return Apis.promotion.getDiscountCode();
  }, []);

  event$.useSubscription(({ type, payload }) => {
    switch (type) {
      case "submit":
        onSubmit?.(dataSource);
        break;
      case "resetDataSource":
        setDataSource(payload);
        break;
      case "reloadCode":
        getDiscountCode().then((res) => {
          if (res.code !== 1) return;
          setDataSource((pre) => {
            return {
              ...pre,
              trackingCode: `购买折扣 ${moment().format(
                "YYYY/MM/DD HH-mm-ss-SSS"
              )}`,
              discountCode: res.data,
            };
          });
        });
        break;
      default:
    }
  });
  const innerOptions = useMemo(() => {
    if (showType === "edit") return promotionConditionOptions;

    return promotionConditionOptions
      .map((item) => {
        if (item.content?.length) {
          return item.render
            ? [{ ...item, content: undefined }, ...item.content]
            : item.content;
        }

        return item;
      })
      .flat();
  }, [showType]);

  return (
    <div>
      <CardCollapsible title="第1步:选择促销条件" className="mt-12">
        <Description
          bordered
          column={1}
          labelStyle={{ width: 240, textAlign: "right" }}
          options={innerOptions}
          dataSource={dataSource}
          onChange={setDataSource}
          store={{ showTypeSelector, operatorType }}
        />
      </CardCollapsible>

      <Alert
        className="mt-12"
        type="warning"
        message="对同一商品组创建多个促销可能会造成买家混合多个促销来实现大幅优惠。"
        description="请阅读优惠码和混合促销了解更多信息。"
      />

      <PromotionTimeCard
        businessType="discount"
        showType={showType}
        value={dataSource}
        onChange={setDataSource}
        operatorType={operatorType}
      />

      <PromotionMoreCard
        showType={showType}
        value={dataSource}
        onChange={setDataSource}
        operatorType={operatorType}
      />
    </div>
  );
};

export default DiscountCreate;
