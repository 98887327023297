import React from 'react'
import './selling.css'
import { Tabs } from "antd";
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import Reports from 'view/selling/module/Reports/Reports'
function Selling(){
  const { TabPane } = Tabs;
    return(
        <div>
            <Header/>
            <div>
            <div className="TabHeader repertoryTable"> 
            <Tabs defaultActiveKey="1" className="tab2">
              <TabPane tab="销售指导报告" key="1">
                <Reports/>
              </TabPane>
              <TabPane tab="首选项" key="2"> 

              </TabPane>
              <TabPane tab="沟通" key="3">

              </TabPane>
              <TabPane tab="搜索建议" key="4">

              </TabPane>
              <TabPane tab="筛选后的建议" key="5">

              </TabPane>
              <TabPane tab="电子邮件设置" key="6">

              </TabPane>
              
            </Tabs>
            </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Selling