import AdvertisingSuperTable from "@view/advertisement/AdvertisingSuperTable";

const AdGroup = ({ advertisingId }) => {
  return (
    <AdvertisingSuperTable
      businessType="group"
      businessId={advertisingId}
      otherData={{ advertisingId }}
    />
  );
};

export default AdGroup;
