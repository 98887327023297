import React from 'react'
import './repair.css'
import '../../../default.css/default.css'
import IconFont from 'components/Icon/Icon'
import {Link} from 'react-router-dom'
import { Tooltip, Button } from 'antd';
function repair(){
	const text = <span>prompt text</span>;

    return(
        <div>
            <div>

            <div className="kc29_navChild">
            <div className="kc29_navChild-l">
                <p className="kc29_navChild-p1">修复无在售信息的亚马逊库存</p>
                <ul>
                    <li>了解更多信息</li>
                    <li>为此页评级</li>
                </ul>
            </div>
            <div className="kc29_navChild-r">
                <button>编辑自动操作设置</button>
                <button>批量修复无在售信息的亚马逊库存</button>
                <button>下载报告</button>
                <button>首选项</button>
            </div>
        </div>

        <p className="kc29_content-p1">目前，运营中心的库存没有亚马逊在售商品。</p>
		<div className="kc29_content-p2">
			<div className="kc29_content-p2_titel">
				<IconFont type="icon-jinggao1"  className=""/>
        <p className="kc29_triangle-p1">新增功能</p>
		</div>
            <p className="kc29_triangle-p2">我们将损据您的首迭项自动移除超货龄无在售信息的亚马逊库存。要自定义您的首选项，请点击<span>编辑自动操作</span>设置按钮，然后点击弹出屏幕上的链接。</p>
            <p className="kc29_triangle-p2"><span>自动移除日期</span>列显示了计划移除商品的时间以及移除方式（弃置或退还商品）<span>。如果您更改了自动移除设置。则更新自动移除日期列最多可能需要24个小时。</span></p>
            <p className="kc29_triangle-p2">如果希望延迟移除特定的FNSKU，请使用商品右侧的下拉箭头，并选择<span>将自动移除延迟30天。</span></p>
        </div>
		</div>
        <div className="kc29_inpbox">
            <div className="kc29_page-r">
				{/* <div className="kc29_sele2">
					对 0 件 选定商品执行操作
				</div>
				<select>
					<option></option>
				</select> */}
				<button>对 0 件 选定商品执行操作<IconFont type="icon-sanjiaoxing" /></button>
			</div>
            <input type="text" placeholder="搜索 SKU、ASIN或FNSKU"/>
            <button>搜索</button>
            <div className="kc29_page-r">
				<div className="kc29_sele2">
					筛选条件: 0 项已应用
					<span><IconFont type="icon-sanjiaoxing" /></span>
				</div>
				
			</div>
             <span>2 件商品</span>
		</div>

		<div className="kc29_inpbox_btm"></div>



        <div className="kc29_indentbox">
			<ul className="">
				<li><input type="checkbox"/></li>
				<li>
					<p className="kc29_blackwft">SKU</p>
					<p>状况</p>
				</li>
				<li>
					<p className="kc29_blackwft">商品名称</p>
					<p>ASIN</p>
				</li>
				<li className="kc29_blackwft">FNSKU</li>
				<li className="kc29_blackwft">您的商品价格</li>
				<li className="kc29_bluewft">可用</li>
				<li className="kc29_bluewft">自动移除日期</li>
				<li className="kc29_active">无在售信息的事件日期 <img src={require("../../../../assets/images/kc29_ssj.png").default} alt=""/></li>
				<li className="kc29_blackwft">无在售信息的原因</li>
				<li className="kc29_blackwft">订单配送方</li>
				<li className="kc29_libtn">全部保存</li>
			</ul>

			<ul>
				<li><input type="checkbox"/></li>
				<li>
					<p className="kc29_blueft">5W-JCEJ-6ZF3</p>
					<p>-</p>
				</li>
				<li>
					<p>-</p>
					<p>B012BKZC86</p>
				</li>
				<li className="kc29_blackft">X002KT0RHL</li>
				<li className="kc29_blackft"><input className="kc29_q" type="text" placeholder="-"/></li>
				<li className="kc29_blueft">1</li>
				<li className="kc29_blackft">
					12/18/2020
					<p className="kc29_orangeft">弃置</p>
				</li>
				<li className="kc29_blackft">07/09/2020 </li>
				<li className="kc29_blueft">
				<Tooltip color="#fff" placement="bottom" title="没有与您库存关联的商品。点击创建商品以销售此库存。">
 				   <span>创建新商品</span>
 				 </Tooltip>
				</li>
				<li className="kc29_blackft">-</li>
				<li>
					<div className="kc29_sele">
						<div className="kc29_sele_btn">
						<Link to="/Newproduct">
						<button className="kc29_sele-l">
							创建新商品
						</button>
						</Link>
						<div className="kc29_sele-lI"><IconFont type="icon-sanjiaoxing" /></div>
						</div>
						<div className="kc29_sele_list">
							<a>1wqe</a>
							<a>1qweqw</a>
							<a>1qwewqe</a>
						</div>
					</div>
				</li>
			</ul>
			<ul>
				<li><input type="checkbox"/></li>
				<li>
					<p className="kc29_blueft">1213Mengk24</p>
					<p>-</p>
				</li>
				<li>
					<p>-</p>
					<p>B07VRWRV2</p>
				</li>
				<li className="kc29_blackft">X002NG4LM3</li>
				<li className="kc29_blackft"><input className="kc29_q" type="text" placeholder="-"/></li>
				<li className="kc29_blueft">1</li>
				<li className="kc29_blackft">
					01/04/2021
					<p className="kc29_orangeft">弃置</p>
				</li>
				<li className="kc29_blackft">12/20/2020 </li>
				<li className="kc29_blueft">
				<Tooltip color="#fff" placement="bottom" title="没有与您库存关联的商品。点击创建商品以销售此库存。">
 				   <span>创建新商品</span>
 				 </Tooltip>
				</li>
				<li className="kc29_blackft">-</li>
				<li>
				<div className="kc29_sele">
						<div className="kc29_sele_btn">
						<Link to="/NewProduct"><button className="kc29_sele-l">
							创建新商品
						</button></Link>
						<div className="kc29_sele-lI"><IconFont type="icon-sanjiaoxing" /></div>
						</div>
						<div className="kc29_sele_list">
							<a>1wqe</a>
							<a>1qweqw</a>
							<a>1qwewqe</a>
						</div>
					</div>
				</li>
			</ul>
		</div>



            </div>



    )
}

export default repair