import React from 'react'
import './Early.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import '../default.css/default.css'
import { Link } from 'react-router-dom';


function Early() {
	return (
		<div>
			<Header />
			<div>
				<div className="end06_mybody EarlyBox">
					<ul className="mybody-ul">
						<li>
							<Link to="/Commentator">早期评论者计划</Link>
						</li>
						<li>
							<Link to="/RegCommentator">注册加入计划</Link>
						</li>
						<li>控制面板</li>
						<li>常见问题</li>
					</ul>
					<div className="sku">
						<ul>
							<li>注册控制面板</li>
						</ul>
						<ul>
							<li>提交SKU进行注册</li>
							<li><input type="text" placeholder="输入父SKU/独立SKU" /></li>
							<li><button type="button">
								<Link to="/RegCommentator">检查资格</Link>
							</button></li>
						</ul>
					</div>
					<div className="asin">
						<ul>
							<li>55&nbsp;商品</li>
						</ul>
						<ul>
							<li>
								<svg t="1611395966594" className="icon sousuo" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
									p-id="4553" width="25" height="25">
									<path d="M369.75373212 369.80408031A158.88634984 158.88634984 0 0 0 325.98855439 512c9.46546265-42.95960643 31.71936954-84.93742286 66.38410909-119.62733652C427.06257714 357.67016277 469.04039357 335.42884295 512 325.96338028c-49.97059406-9.41511445-103.59143303 5.17327812-142.24626788 43.84070003z"
										fill="#969696" p-id="4554"></path>
									<path d="M746.74850863 664.47954068l-59.83884237-59.83884236a58.02630697 58.02630697 0 0 0-45.62806132-16.77853952c56.27670682-81.4004614 48.14547161-193.91611389-24.33077036-266.40494291-81.58926717-81.61444129-213.90435151-81.61444129-295.51879279 0-81.58926717 81.61444129-81.58926717 213.94211267 0 295.55655394 72.47624197 72.48882902 184.90378508 80.55712897 266.3545947 24.28042216-1.25870513 16.36316682 4.35511978 33.2046416 16.77853951 45.62806133l59.83884237 59.85142942c22.65669253 22.60634432 59.67521069 22.60634432 82.28155501 0 22.71962777-22.60634432 22.71962777-59.63744953 0.06293525-82.29414207zM592.68299949 592.68299949c-68.18405745 68.18405745-178.67319463 68.18405745-246.83207798 0-68.18405745-68.18405745-68.18405745-178.69836875 0-246.8824262 68.15888333-68.18405745 178.64802053-68.18405745 246.83207798 0 68.17147039 68.13370922 68.17147039 178.69836875 0 246.8824262z"
										fill="#969696" p-id="4555"></path>
								</svg><input type="text" placeholder="搜索ASIN/SKU" /></li>
							<li><button type="button">搜索</button></li>
						</ul>
					</div>
					<div className="tab">
						<table border="1" cellspacing="0" cellpadding="0" bordercolor="#EEEEEE">
							<tr className="mytr">
								<th>商品名称/父 SKU/ASIN</th>
								<th>子 SKU 已注册</th>
								<th>注册日期</th>
								<th>早期评论者计划</th>
								<th>状态</th>
								<th>账单</th>
							</tr>
							<tr className="mytr2">
								<td>
									<img src={require("../../assets/images/5-06.png").default} className="shop1" />
									<p className="nr">Makeu p Brushes 6PCs Makeu p Brushes Set with 4PCs Beauty Blender Sponge an d 2 Brush Cleaner
										Brushes Blending Face Powder Eye Shadows Make Up Brushes</p>
									<p>父 SKU tanghui 1211</p>
									<p>ASIN B08QD2GK9T</p>
								</td>
								<td>0</td>
								<td>20-12-12</td>
								<td className="xlcor">0<svg t="1611395618350" className="icon xiala" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
									p-id="3716" width="10" height="10">
									<path d="M561.873 725.165c-11.262 11.262-26.545 21.72-41.025 18.502-14.479 2.413-28.154-8.849-39.415-18.502L133.129 375.252c-17.697-17.696-17.697-46.655 0-64.352s46.655-17.696 64.351 0l324.173 333.021 324.977-333.02c17.696-17.697 46.655-17.697 64.351 0s17.697 46.655 0 64.351L561.873 725.165z"
										fill="#707070" p-id="3717"></path>
								</svg>
								</td>
								<td>
									已注册
									<p>正在进行</p>
								</td>
								<td>-</td>
							</tr>
							<tr className="mytr2">
								<td>
									<img src={require("../../assets/images/5-06.png").default} className="shop1" />
									<p className="nr">Standard Replacement Toothbruch Head,Tooth whiteming,Easy Clean Remove Plaque</p>
									<p>父 SKU Tangyan201011</p>
									<p>ASIN B08QD267BR</p>
								</td>
								<td>0</td>
								<td>20-12-12</td>
								<td className="xlcor">0<svg t="1611395618350" className="icon xiala" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
									p-id="3716" width="10" height="10">
									<path d="M561.873 725.165c-11.262 11.262-26.545 21.72-41.025 18.502-14.479 2.413-28.154-8.849-39.415-18.502L133.129 375.252c-17.697-17.696-17.697-46.655 0-64.352s46.655-17.696 64.351 0l324.173 333.021 324.977-333.02c17.696-17.697 46.655-17.697 64.351 0s17.697 46.655 0 64.351L561.873 725.165z"
										fill="#707070" p-id="3717"></path>
								</svg>
								</td>
								<td>
									已注册
									<p>正在进行</p>
								</td>
								<td>-</td>
							</tr>
							<tr className="mytr2">
								<td>
									<img src={require("../../assets/images/5-06.png").default} className="shop1" />
									<p className="nr">Panier Lit Pour Chien Chat avec Coussin Réversible,Velours Cotele Velours Perle Coton en Duvet M
										Bleu</p>
									<p>父 SKU tanghui 1211</p>
									<p>ASIN B08QD2GK9T</p>
								</td>
								<td>0</td>
								<td>20-12-12</td>
								<td className="xlcor">0<svg t="1611395618350" className="icon xiala" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
									p-id="3716" width="10" height="10">
									<path d="M561.873 725.165c-11.262 11.262-26.545 21.72-41.025 18.502-14.479 2.413-28.154-8.849-39.415-18.502L133.129 375.252c-17.697-17.696-17.697-46.655 0-64.352s46.655-17.696 64.351 0l324.173 333.021 324.977-333.02c17.696-17.697 46.655-17.697 64.351 0s17.697 46.655 0 64.351L561.873 725.165z"
										fill="#707070" p-id="3717"></path>
								</svg>
								</td>
								<td>
									已注册
									<p>正在进行</p>
								</td>
								<td>-</td>
							</tr>
						</table>
					</div>
				</div>



			</div>

			<Footer />
		</div>
	)
}


export default Early