import React from 'react'
import AJAX from "api/apis";
import './viewShipment.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import TipsIcon from "components/tipsIcon"
import Reactours from "components/reactours"
import '../default.css/default.css'
import { Link } from 'react-router-dom';
import { Button, Collapse, Steps, Radio, Input, Modal } from 'antd';
import { fbaAddress, getUrlData } from "@/utils/utils";

const { Panel } = Collapse;
const { Step } = Steps;
class viewShipment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            planInfoList: [],
            planId: null,
            addressDetails: '',
            classContent: '',
            classType: 1,
            classIndex: null,
            isModalVisible: false,
            ModalText: '',
            isReturn: false,
            isTourOpen: true,
            steps1: [
                {
                    selector: '.viewShipmentBox .kc06_commodity',
                    content: '设置货件名称（有可能会被分为多个货件发往不同仓库），货件名称必须要跟操作资料中对应',
                },
                {
                    selector: '.viewShipmentBox .btn-r button',
                    content: '点击继续',
                }
            ],
            isActive: false
        }
    }
    componentWillMount() {
        let planId = getUrlData('planId')
        this.getStoreRegisterInfo()
        planId && this.getSeparateInfo(planId)
        this.setState({
            planId: planId
        })
    }
    awakenTips = (type) => {
        this.setState({
            isActive: type
        })
    }
    separateStoragePlan = (planId) => {
        let { planInfoList } = this.state
        AJAX.fba.separateStoragePlan({ id: planId }).then((res) => {
            if (res.code == 1 && res.data) {
                planInfoList = res.data
                for (let items of planInfoList.fbaAllCommodityPlanRespList) {
                    items.isShowChild = false;
                }
                this.setState({
                    planInfoList: planInfoList
                })
            }
        })
    }
    getSeparateInfo = (id) => {
        let { planInfoList } = this.state
        AJAX.fba.getSeparateInfo({ id }).then((res) => {
            if (res.code == 1 && res.data) {
                planInfoList = res.data
                for (let items of planInfoList.fbaAllCommodityPlanRespList) {
                    items.isShowChild = false;
                }
                this.setState({
                    planInfoList: planInfoList
                })
            } else if (res.code == -100 && res.data == 4) {
                this.separateStoragePlan(id)
            } else if (res.code == -100 && res.data == 3) {
                this.props.history.push({ pathname: '/Labeling', state: { planId: res.data }, search: `?planId=${res.data}` })
            } else if (res.code == -100 && res.data == 2) {
                this.props.history.push({ pathname: '/Pretreatment', state: { planId: res.data }, search: `?planId=${res.data}` })
            } else if (res.code == -100 && res.data == 1) {
                this.props.history.push({ pathname: '/setQuantity', state: { planId: res.data }, search: `?planId=${res.data}` })
            }
        })
    }
    getStoreRegisterInfo = () => {
        AJAX.login.getStoreRegisterInfo([{
            propertyKey: 'paytaxes_organization_site'
        }], 'isRegister=0').then((res) => {
            if (res.code == 1 && res.data) {
                this.setState({
                    addressDetails: res.data[0].propertyValue
                })
            }
        })
    }
    childShowClick = (index) => {
        let { planInfoList } = this.state
        planInfoList.fbaAllCommodityPlanRespList[index].isShowChild = !planInfoList.fbaAllCommodityPlanRespList[index].isShowChild
        this.setState({
            planInfoList: planInfoList
        })
    }
    shipmentInp = (e, index) => {
        let { planInfoList } = this.state
        planInfoList.fbaAllCommodityPlanRespList[index].commodityPlanName = e.target.value
        this.setState({
            planInfoList: planInfoList
        })

    }
    nextEvent = () => {
        let { planInfoList } = this.state
        let obj = {
            id: planInfoList.id,
            upCommodityPlanNameReqList: []
        }
        for (let items of planInfoList.fbaAllCommodityPlanRespList) {
            if (items.status) {
                obj.upCommodityPlanNameReqList.push({
                    commodityPlanName: items.commodityPlanName,
                    id: items.id
                })
            }
        }
        AJAX.fba.upCommodityPlanName(obj).then((res) => {
            if (res.code === 1) {
                this.props.history.push({
                    pathname: '/handlingCargo',
                    search: `?planId=${res.data.id}`,
                    state: {
                        planId: res.data.id
                    }
                })
            }
        })
    }
    deleteSchedule = () => {
        this.setState({
            delTipsModal: true
        })
    }
    tipshandleOk = () => {
        let { goodsList } = this.state
        AJAX.fba.deleteStoragePlan({ id: goodsList.id }).then((res) => {
            if (res.code == 1) {
                this.props.history.push('/inboundQueue')

            }
        })
    }
    render() {
        let { addressDetails, planInfoList, delTipsModal, steps1, isTourOpen, isActive } = this.state
        return <div className="viewShipmentBox">
            <Header />
            <div>
                <div className="kc06_box1">
                    <a href="#">Send to Amazon</a>
                    <ul className="kc06_box1-ul">
                        <li>新功能</li>
                        <li><a href="#">货件处理进度</a></li>
                        <li><a href="#">入库问题一览</a></li>
                        <li><a href="#">扫描和贴标</a></li>
                        <li><a href="#">上传入库计划文件</a></li>
                    </ul>
                </div>
                <div className="kc06_mybody">
                    <div className="kc06_center">
                        <p className="kc06_h1">
                            发/补货
                            <span>了解更多信息</span>
                        </p>
                        <div className="kc06_body-border">
                            <ul className="kc06_body-ul">
                                <li>设置数量</li>
                                <li>预处理商品</li>
                                <li>为商品贴标</li>
                                <li>查看货件<img src={require("@/assets/img/home/kc12_topsj.png").default} /></li>
                                <li>预处理货件</li>
                                <li>一览 </li>
                            </ul>
                        </div>
                        <div className="kc06_body-table">
                            <table className="kc06_table1">
                                <thead>
                                    <tr>
                                        <th>发货地址</th>
                                        <th>包装类型 <span>这是什么？</span></th>
                                        <th>主题</th>
                                        <th>可选服务费用(预计)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{fbaAddress(addressDetails)}</td>
                                        <td>混装商品</td>
                                        <td>{planInfoList.storagePlanMskuCount} MSKU</td>
                                        <td>
                                            <div>
                                                <div>准备:${planInfoList.storagePlanPackMoney}</div>
                                                <div>标签:${planInfoList.storagePlanTagMoney}</div>
                                                <div>放置:无费用</div>
                                                <div>总计:${planInfoList.storagePlanAllMoney || 0}</div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <ul className="kc06_itbox">
                                <li>货件名称</li>
                                <li>货件编号</li>
                                <li>MSKU</li>
                                <li>商品数量</li>
                                <li>配送地址</li>
                            </ul>
                            {planInfoList.fbaAllCommodityPlanRespList ? planInfoList.fbaAllCommodityPlanRespList.map((item, index) => {
                                return <div key={index} className="kc06_commodity">
                                    <ul className="kc06_itbox">
                                        <li>
                                            <p>
                                                <Radio checked>新建</Radio>
                                                <input disabled={!item.status} className="shipmentInp" value={item.commodityPlanName} onChange={(e) => { this.shipmentInp(e, index) }} />
                                            </p>
                                        </li>
                                        <li>{item.commodityPlanMskuCount}</li>
                                        <li>{item.commodityPlanAllGoodsCount}</li>
                                        <li>{item.commodityPlanAmazonAddRess}</li>
                                        <li className="shipmentShow" onClick={() => { this.childShowClick(index) }}>{item.isShowChild ? '隐藏货件内容' : '查看货件内商品'}</li>
                                    </ul>
                                    {item.isShowChild ? <div >
                                        <div className="childBox">
                                            <p className="kc_10_show">显示{item.fbaCommodityPlanGoodsInfoRespList.length}件商品</p>
                                        </div>
                                        <div className="childBox">
                                            <table className="kc_10_table1">
                                                <thead>
                                                    <tr>
                                                        <th>MSKU</th>
                                                        <th>商品名称</th>
                                                        <th>状况</th>
                                                        <th>已发货</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {item.fbaCommodityPlanGoodsInfoRespList ? item.fbaCommodityPlanGoodsInfoRespList.map((items, indexs) => {
                                                        return <tr key={indexs}>
                                                            <td>{items.goodsSku}</td>
                                                            <td><div className="text-ellipsis-3">{items.goodsName}</div></td>
                                                            <td>{items.goodsCondition}</td>
                                                            <td>{item.commodityPlanAllGoodsCount}</td>
                                                        </tr>
                                                    }) : null}
                                                    <tr>
                                                        <td>总计</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>{item.commodityPlanAllGoodsCount}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div></div> : null}
                                </div>
                            }) : null}
                            <div className="body-tab-div">
                                <img src={require("@/assets/img/home/kc12_i-blue.png").default} className="i" />
                                <p>为什么看不到我的货件？<span style={{ color: '#004b91' }}> 了解更多信息</span></p>
                            </div>
                        </div>
                        <div className="body-box2">
                            <div className="btn-l">
                                <button>
                                    <Link to={{ pathname: '/Labeling', state: { planId: this.state.planId }, search: `?planId=${this.state.planId}` }}>返回<img className="kc_10_btn2" src={require("@/assets/img/home/btns.png").default} /></Link>
                                </button>
                                <button onClick={this.deleteSchedule}>删除计划</button>
                            </div>
                            <div className="btn-r">
                                <button onClick={this.nextEvent}>
                                    批准并继续<img className="kc_10_btn2" src={require("@/assets/img/home/btns.png").default} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal title="提示" visible={delTipsModal} onOk={this.tipshandleOk} style={{ width: '50%' }} cancelText="取消" okText="确认" onCancel={() => { this.setState({ delTipsModal: false }) }} >
                    <p>是否确定？删除计划操作无法撤销</p>
                </Modal>
            </div>
            <Footer />
            {/* 引导步骤 */}
            <TipsIcon clicks={this.awakenTips} />
            <Reactours routePath="viewShipment" isActive={isActive} clicks={this.awakenTips} steps={steps1} isOpen={isTourOpen} />
        </div>
    }
}


export default viewShipment