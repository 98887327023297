 import React from 'react'
 import './Payments.css'
 import '../default.css/default.css'
 import Footer from 'components/addlisting_footer/FooterCom'
 import Header from 'components/home_header'
import PayImgSj from 'assets/images/end17_shuju.png'


 function Payments(){
     return(
        <div>
			<Header/>
<div className="end17_content">

<div className="end17_header">
			<ul className="end17_header_bar">
				<li>付款控制面板</li>
				<li>了解更多信息</li>
				<li>观看演示</li>
				<li>为此页评级</li>
			</ul>
			<ul className="end17_header_navigation">
				<li>延迟交易</li>
				<li>结算一览</li>
				<li>交易一览</li>
				<li>所有结算</li>
				<li>付款</li>
				<li>日期范围报告</li>
				<li>广告发票历史记录</li>
			</ul>
		</div>

			<div className="end17_con">
				<div className="end17_con_right">
					<table >
						<tr>
							<td className="end17_table_one end17_table_none"></td>
							<td className="end17_table_tow end17_table_bold">总余额 <img src={require("../../assets/images/end17_wenhao.png").default} /></td>
							<td ></td>
							<td className="end17_table_four">
								<div className="end17_table_four_con">
									<span>可用资金</span>
									<select>
									  <option value ="volvo">现在</option>
									</select>
									<img src={require("../../assets/images/end17_wenhao.png").default} />
								</div>
							</td>
							<td></td>
							<td className="end17_table_six end17_table_bold">
								最近的支出
								<img src={require("../../assets/images/end17_wenhao.png").default} />
							</td>
						</tr>
						<tr>
							<td className="end17_table_one end17_table_none">标准订单</td>
							<td className="end17_table_show">Us$0.00</td>
							<td ></td>
							<td className="end17_table_show end17_table_ban">&nbsp;&nbsp;&nbsp; US$0.00 <button type="button">请求付款</button></td>
							<td></td>
							<td className="end17_table_show end17_table_clr"> 转账曰期: 2020/9/3 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  uS$249.66</td>
						</tr>
						<tr>
							<td className="end17_table_one">发票支付订单</td>
							<td className="end17_table_tow">Us$0.00</td>
							<td className="end17_table_main"></td>
							<td className="end17_table_four"> &nbsp;&nbsp;&nbsp;US$0.00 <button type="button">请求付款</button></td>
							<td className="end17_table_main"></td>
							<td className="end17_table_six"> 转账曰期: 2019/4/17   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  uS$78.16 </td>

						</tr>
						<tr>
							<td className="end17_table_one end17_table_img">未结账单
							<img src={require("../../assets/images/end17_btn.png").default} />
							</td>
							<td className="end17_table_tow"> <a href="#">Us$0.00</a> </td>
							<td className="end17_table_main"></td>
							<td className="end17_table_four"> &nbsp;&nbsp;&nbsp;US$0.00 <button type="button">请求付款</button></td>
							<td className="end17_table_main"></td>
							<td className="end17_table_six"></td>
						</tr>
						<tr>
							<td className="end17_table_one">所有账户</td>
							<td className="end17_table_tow">Us$0.00</td>
							<td className="end17_table_main"></td>
							<td className="end17_table_four"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;US$0.00</td>
							<td className="end17_table_main"></td>
							<td className="end17_table_six"></td>
						</tr>
						
					</table>
				</div>
			</div>
			<div className="end17_orders">
				<ul>
					<li>
						<div>
							<p>账户类型</p>
							<select>
							  <option value ="volvo">标准订单</option>
							</select>
						</div>
					</li>
					<li>
						<div>
							<p className="end17_orders_text">结算周期</p>
							<select>
							  <option value ="volvo">2021/1/21-至今(打开）</option>
							</select>
						</div>
					</li>
					<li>
						<div>
							<p>查找某项交易</p>
							<select className="end17_orders_number">
							  <option value ="volvo">请输入订单编号</option>
							</select>
							<button type="button">搜索</button>
						</div>
					</li>
				</ul>
				<div className="end17_orders_data">
					<div className="end17_orders_data_left">
						<div className="end17_orders_data_left_con">
							<div>
								净收入   <img src={require("../../assets/images/end17_wenhao.png").default} />
							</div>
							<p>2021/1/21-至今</p>
							<p>	US$0.00</p>
						
						</div>
						<div className="end17_orders_data_left_exp">
							<div>
								期初余额 <img src={require("../../assets/images/end17_wenhao.png").default} />
							</div>
							<p>-US$40.13</p>
						</div>
						<div className="end17_orders_data_left_xs">
							<div>
								销售额 <img src={require("../../assets/images/end17_wenhao.png").default} />
							</div>
							<p>-US$40.13</p>
						</div>
						<div className="end17_orders_data_left_zc">
							<div>
							支出 <img src={require("../../assets/images/end17_wenhao.png").default} />
							</div>
							<p>-US$0.00</p>
						</div>
						<div className="end17_orders_data_left_yl">
							<div>
							账户级别的预留金额 <img src={require("../../assets/images/end17_wenhao.png").default} />
							</div>
							<p>US$0.00</p>
						</div>
					</div>
					<div className="end17_orders_data_right">
						<img src={PayImgSj} />
					</div>
				</div>
				<div className="end17_content_footer">
					<div>
						打印此向 <br/>
					查看本结算周其册的交易
					</div>
					<p>计转账日期: 2021/2/4*。但呈y如果倾的可用余额为0.000,则不会在此曰期训行转账。</p>
					<p>"特账最多需恶3-5个工作日即可完成。请以实际转账金额为维。有关向您转账的日期的更够信息，请刨阅<span>帮助页面</span>。</p>
					
				</div>
			</div>
			<div className="end17_feedback"> <span>反馈</span> <img src={require("../../assets/images/kc32_x.png").default} alt=""/></div>
		</div>

                                <Footer/>
        </div>
     )
 }
 export default Payments