import { ASelect, AButton, AText, ATable } from "@a-components/";
import Apis from "@api/apis";
import usePaginationTable from "@hooks/usePaginationTable";
import { Row, Col, Dropdown, Menu } from "antd";
import { get } from "jquery";
import { useCallback, useState, useEffect, useMemo } from "react";

import { Link } from "react-router-dom";

const promotionStatusOptions = [
  {
    label: "促销状态",
    value: 1,
  },
  {
    label: "优惠码",
    value: 2,
  },
  {
    label: "日期范围",
    value: 3,
  },
];

const pendingStatusOptions = [
  {
    label: "正在进行",
    value: 1,
  },
  {
    label: "待生效",
    value: 2,
  },
  {
    label: "已过期",
    value: 3,
  },
  {
    label: "全部",
    value: 4,
  },
];
const PromotionManage = () => {
  const columns = [
    {
      title: "追踪编码",
      key: "trackingCode",
      dataIndex: "trackingCode",
      render(value, record) {
        const info = {
          promotionType: record.promotionType,
          operatorType: "detail",
          promotionId: record.promotionId,
        };
        return (
          <Link
            to={`/Promotions/PromotionDetail?${new URLSearchParams(
              info
            ).toString()}`}
          >
            {value}
          </Link>
        );
      },
    },
    {
      title: "内部描述",
      key: "description",
      dataIndex: "description",
    },
    {
      title: "开始日期",
      key: "startTime",
      dataIndex: "startTime",
    },
    {
      title: "结束日期",
      key: "endTime",
      dataIndex: "endTime",
    },
    {
      title: "操作",
      render(_, record) {
        const editUrl = `/Promotions/PromotionDetail?${new URLSearchParams({
          promotionType: record.promotionType,
          operatorType: "modify",
          promotionId: record.promotionId,
        })}`;
        const copyUrl = `/Promotions/PromotionDetail?${new URLSearchParams({
          promotionType: record.promotionType,
          operatorType: "create",
          promotionId: record.promotionId,
        })}`;
        const previewUrl = `/Promotions/PromotionDetail?${new URLSearchParams({
          promotionType: record.promotionType,
          operatorType: "detail",
          promotionId: record.promotionId,
        })}`;

        const menu = (
          <Menu>
            <Menu.Item>
              <Link to={editUrl}>编辑</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to={copyUrl}>复制</Link>
            </Menu.Item>
          </Menu>
        );

        return (
          <><Dropdown overlay={menu} placement="bottomLeft" arrow>
            <AButton>
              <Link to={previewUrl}>查看</Link>
            </AButton>
          </Dropdown>
            <AButton onClick={() => { toDel(record.promotionId) }} className="ml-12">删除</AButton>
          </>
        );
      },
    },
  ];
  const [promotionStatus, setPromotionStatus] = useState(1);
  const [pendingStatus, setPendingStatus] = useState(1);

  const fetcher = useCallback((params) => {
    return Apis.promotion.getPromotionList(params);
  }, []);
  const {
    tableList,
    isTableLoading,
    pagination,
    operator: { reFetch },
  } = usePaginationTable({
    fetcher,
    params: { promotionStatus: pendingStatus },
  });
  const toDel = useCallback((promotionId) => {
    Apis.promotion.deletePromotion({ promotionId }).then(res => {
      if (res.code == 1) reFetch()
    })
  }, [])

  return (
    <div>
      <div className="pv-12">
        <AText className="fs-24" strong>
          搜索
        </AText>

        <Row className="mt-6">
          <Col span={4}>
            <ASelect
              className="w-100percent"
              options={promotionStatusOptions}
              value={promotionStatus}
              onChange={setPromotionStatus}
            ></ASelect>
          </Col>
          <Col span={4} offset={1}>
            <ASelect
              className="w-100percent"
              options={pendingStatusOptions}
              value={pendingStatus}
              onChange={setPendingStatus}
            ></ASelect>
          </Col>
          <Col offset={1}>
            <AButton className="stepGuidanceBox3" onClick={() => reFetch()}>搜索</AButton>
          </Col>
        </Row>
      </div>

      <div>{pagination?.total} 促销</div>

      <ATable
        className="mt-12 stepGuidanceBox4"
        dataSource={tableList}
        columns={columns}
        pagination={pagination}
        loading={isTableLoading}
      ></ATable>
    </div>
  );
};

export default PromotionManage;
