import React, { useState, useCallback, useEffect } from "react";
import "./commodityType.css"
import HeaderCom from "components/home_header";
import CommodityCollect from "./module/Commodity-collect/Commodity-collect";
import CommodityBrowse from "./module/Commodity-browse/Commodity-browse";
import CommoditySelect from "./module/Commodity-select/Commodity-select";
import FooterCom from "components/addlisting_footer/FooterCom";
import Reactours from "components/reactours"
import TipsIcon from "components/tipsIcon"


function commodity2() {
    return (
        <div className="commodity-main">
            <HeaderCom />
            <CommodityCollect />
            <CommodityBrowse />
            <CommoditySelect />
            <FooterCom />
        </div>
    )
}
class commodity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isgit: false,
            quxiao: false,
            isTourOpen: true,
            steps1: [
                {
                    selector: '.breadcrumb-container',
                    content: '选择商品核心类目',
                },
                {
                    selector: '.stepSearchBox',
                    content: '搜索关键词找到核心类目',
                }
            ],
            isActive:false
        }
    }
    getList = () => {
        this.setState({
            isgit: true
        })
    }
    awakenTips = (type) =>{
        this.setState({
            isActive:type
        })
    }
    getquxiao = () => {
        this.setState({
            quxiao: !this.state.quxiao
        })
    }
    componentDidMount(){
        setTimeout(()=>{
            this.setState({
                isTourOpen:true
            })
        },200)
    }
    render() {
        let { isgit, steps1, isTourOpen, quxiao,isActive } = this.state
        return <div className="commodity-main">
            {/* 引导步骤 */}
            <TipsIcon clicks={this.awakenTips} />
            <Reactours routePath="commodity" isActive={isActive} clicks={this.awakenTips} steps={steps1} isOpen={isTourOpen} />
            <HeaderCom />
            <CommodityCollect isgit={isgit} getquxiao={this.getquxiao} props={this.props} />
            <CommodityBrowse quxiao={quxiao} getList={this.getList} props={this.props} />
            <CommoditySelect quxiao={quxiao} getList={this.getList} props={this.props} />
            <FooterCom />
        </div>
    }
}

export default commodity
