import { AButton, ASelect, AInput, AText,AInputNumber } from "@a-components/";
import { Row, Col, Alert } from "antd";

import CardCollapsible from "./../CardCollapsible";
import { useMemo, useState, useCallback } from "react";

import MessageBox from "./../MessageBox";
import Description from "./../Description";

import CategorySelector from "./../../CategorySelector";

import PromotionTimeCard from "./../../PromotionTimeCard";
import { initValue as initTimeValue } from "./../../PromotionTimeCard/config";
import PromotionMoreCard from "./../../PromotionMoreCard";
import { initValue as initMoreValue } from "./../../PromotionMoreCard/config";
import { Link } from "react-router-dom";

import Apis from "@api/apis";
import moment from "moment";

const buyGoodsOptions = [
  {
    label: "最低金额($)",
    value: 1,
  },
  {
    label: "此商品的最低购买数量",
    value: 2,
  },
  {
    label: "每购买商品(数量)",
    value: 3,
  },
];

const applyRange = [
  {
    label: "须购买商品",
    value: 1,
  },
  {
    label: "额外购买的商品",
    value: 2,
  },
];

const promotionConditionOptions = [
  {
    label: "买家所购商品",
    key: "buyerPurchasesGoods",
    render(value, { record, setState, store: { showTypeSelector } }) {
      return (
        <Row>
          <Col className="stepGuidanceBox1" span={4}>
            {showTypeSelector({
              edit: () => (
                <ASelect
                  className="w-100percent"
                  options={buyGoodsOptions}
                  value={value}
                  onChange={(e) =>
                    setState({
                      buyerPurchasesGoods: e,
                      scopeApplication: e === 1 ? 2 : record.scopeApplication,
                    })
                  }
                ></ASelect>
              ),
              preview: () =>
                buyGoodsOptions.find((v) => v.value === value)?.label ?? "",
              modify: () =>
                buyGoodsOptions.find((v) => v.value === value)?.label ?? "",
            })}
          </Col>
          <Col className="stepGuidanceBox2" span={3} offset={1}>
            {showTypeSelector({
              edit: () => (
                <AInputNumber
                  className="w-100percent"
                  value={record.buyerPurchasesGoodsValue}
                  min={1}
                  onChange={(e) => {
                    setState({ buyerPurchasesGoodsValue: e })
                  }
                  }
                ></AInputNumber>
              ),
              preview: () => record.buyerPurchasesGoodsValue,
              modify: () => record.buyerPurchasesGoodsValue,
            })}
          </Col>
        </Row>
      );
    },
  },
  {
    label: "须购买商品",
    key: "promotionGoodsId",
    render(value, { setState, store: { showTypeSelector } }) {
      return (
        <Row>
          <Col className="stepGuidanceBox9" span={4}>
            {showTypeSelector({
              edit: () => (
                <CategorySelector
                  value={value}
                  onChange={(value) => setState({ promotionGoodsId: value })}
                  placeholder="选择类别"
                  className="w-100percent"
                ></CategorySelector>
              ),
              preview: () => (
                <CategorySelector
                  readOnly
                  value={value}
                  placeholder="选择类别"
                  className="w-100percent"
                ></CategorySelector>
              ),
            })}
          </Col>
          {showTypeSelector({
            edit: () => (
              <>
                <Col className="stepGuidanceBox10" span={3} offset={1}>
                  <Link to="/Promotions/ProductSelection">
                    创建新的商品选择
                  </Link>
                </Col>
                <Col offset={1}>
                  <MessageBox message="影响者和联盟可能会发布您的促销消息以引导流量。参加此计划并不保证商品能够被投放。" />
                </Col>
              </>
            ),
            preview: () => null,
          })}
        </Row>
      );
    },
  },
  {
    label: "买家获得",
    key: "buyersGetValue",
    render(value, { record, setState, store: { showTypeSelector } }) {
      return (
        <Row>
          <Col className="stepGuidanceBox11" span={4}>
            {showTypeSelector({
              edit: () => (
                <ASelect
                  value={record.buyersGet}
                  className="w-100percent"
                  options={[{ label: "赠品", value: 2 }]}
                ></ASelect>
              ),
              preview: () => "赠品",
              modify: () => "赠品",
            })}
          </Col>
          <Col span={3} offset={1}>
            {showTypeSelector({
              edit: () =>
                // <AInput
                //   value={value}
                //   onChange={(e) => setState({ buyersGetValue: e.target.value })}
                //   className="w-100percent"
                // ></AInput>
                null,
              preview: () => value,
              modify: () => value,
            })}
          </Col>
        </Row>
      );
    },
  },
  {
    key: "scopeApplication",
    label: "适用范围",
    render(value, { setState, record, store: { showTypeSelector } }) {
      return (
        <Row>
          <Col className="stepGuidanceBox12" span={4}>
            {showTypeSelector({
              edit: () => (
                <ASelect
                  disabled={record.buyerPurchasesGoods === 1}
                  options={applyRange}
                  value={value}
                  onChange={(e) => setState({ scopeApplication: e })}
                  className="w-100percent"
                ></ASelect>
              ),
              preview: () =>
                applyRange.find((v) => v.value === value)?.label ?? "",
              modify: () =>
                applyRange.find((v) => v.value === value)?.label ?? "",
            })}
          </Col>
        </Row>
      );
    },
  },
  {
    label: "额外购买的商品",
    key: "additionalGoodsCode",
    $visible(_, { record }) {
      return record.scopeApplication === 2;
    },
    render(value, { setState, store: { showTypeSelector } }) {
      return (
        <Row>
          <Col span={3}>
            {showTypeSelector({
              edit: () => (
                <AInput
                  placeholder="输入 ASIN"
                  value={value}
                  onChange={(e) =>
                    setState({ additionalGoodsCode: e.target.value })
                  }
                ></AInput>
              ),
              preview: () => value,
              modify: () => value,
            })}
          </Col>
        </Row>
      );
    },
  },
  {
    label: "买家优惠的适用商品数量",
    key: "discountGoodsNumber",
    render(value, { record, setState, store: { showTypeSelector } }) {
      return (
        <Row>
          <Col className="stepGuidanceBox13" span={3}>
            {showTypeSelector({
              edit: () => (
                <AInputNumber
                  value={value}
                  min={1}
                  style={{width:'100%'}}
                  onChange={(e) =>
                    setState({ discountGoodsNumber: e})
                  }
                ></AInputNumber>
              ),

              preview: () => value,
              modify: () => value,
            })}
          </Col>

          {record.scopeApplication === 1 ? (
            <>
              <Col span={3} offset={1}>
                当买家购买
              </Col>
              <Col offset={1}>
                <div className="df ai-c">
                  {showTypeSelector({
                    edit: () => (
                      <AInput
                        className="f-1 mr-24"
                        disabled
                        value={record.buyerPurchasesGoodsValue}
                      ></AInput>
                    ),

                    preview: () => record.buyerPurchasesGoodsValue,
                    modify: () => record.buyerPurchasesGoodsValue,
                  })}
                  <AText>件商品</AText>
                </div>
              </Col>
            </>
          ) : null}
        </Row>
      );
    },
  },

  {
    label: "更多选项",
    content: [
      {
        label: "不参加促销的商品",
        key: "notPromotionGoodsId",

        render(value, { setState, store: { showTypeSelector } }) {
          return (
            <Row>
              <Col span={4}>
                {showTypeSelector({
                  edit: () => (
                    <CategorySelector
                      value={value}
                      onChange={(value) =>
                        setState({ notPromotionGoodsId: value })
                      }
                      className="w-100percent"
                    ></CategorySelector>
                  ),
                  preview: () => (
                    <CategorySelector
                      value={value}
                      readOnly
                      className="w-100percent"
                    ></CategorySelector>
                  ),
                })}
              </Col>
              <Col offset={1}>
                {showTypeSelector({
                  edit: () => (
                    <Link to="/Promotions/ProductSelection">
                      创建新的商品选择
                    </Link>
                  ),
                })}
              </Col>
            </Row>
          );
        },
      },
    ],
  },
];

const GiftsCreate = ({ showType, event$, onSubmit, operatorType }) => {
  const showTypeSelector = useCallback(
    (arg) => {
      const _showType =
        operatorType === "modify" && showType === "edit" ? "modify" : showType;

      let value = arg[_showType] || arg[showType];

      if (typeof value === "function") {
        return value();
      }

      return value;
    },
    [operatorType, showType]
  );

  const [dataSource, setDataSource] = useState({
    ...initTimeValue,
    ...initMoreValue,
    promotionType: 3,
    buyerPurchasesGoods: 2,
    buyersGet: 2,
    scopeApplication: 1,
    trackingCode: `买一赠一 ${moment().format("YYYY/MM/DD HH-mm-ss-SSS")}`,
  });

  const getDiscountCode = useCallback(() => {
    return Apis.promotion.getDiscountCode();
  }, []);

  event$.useSubscription(({ type, payload }) => {
    switch (type) {
      case "submit":
        onSubmit?.(dataSource);
        break;
      case "resetDataSource":
        setDataSource(payload);
        break;
      case "reloadCode":
        getDiscountCode().then((res) => {
          if (res.code !== 1) return;
          setDataSource((pre) => {
            return {
              ...pre,
              trackingCode: `购买折扣 ${moment().format(
                "YYYY/MM/DD HH-mm-ss-SSS"
              )}`,
              discountCode: res.data,
            };
          });
        });
        break;
      default:
    }
  });

  const innerOptions = useMemo(() => {
    if (showType === "edit") return promotionConditionOptions;

    return promotionConditionOptions
      .map((item) => {
        if (item.content?.length) {
          return item.render
            ? [{ ...item, content: undefined }, ...item.content]
            : item.content;
        }

        return item;
      })
      .flat();
  }, [showType]);

  return (
    <div>
      <CardCollapsible title="第1步:选择促销条件" className="mt-12">
        <Description
          bordered
          column={1}
          labelStyle={{ width: 240, textAlign: "right" }}
          options={innerOptions}
          dataSource={dataSource}
          onChange={setDataSource}
          store={{ showTypeSelector, operatorType }}
        />
      </CardCollapsible>

      <Alert
        className="mt-12"
        type="warning"
        message="对同一商品组创建多个促销可能会造成买家混合多个促销来实现大幅优惠。"
        description="请阅读优惠码和混合促销了解更多信息。"
      />

      <PromotionTimeCard
        businessType="gifts"
        showType={showType}
        value={dataSource}
        onChange={setDataSource}
        operatorType={operatorType}
      />

      <PromotionMoreCard
        showType={showType}
        value={dataSource}
        onChange={setDataSource}
        operatorType={operatorType}
      />
    </div>
  );
};

export default GiftsCreate;
