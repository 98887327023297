import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
    margin: 10px 20px 10px 30px;
    ul{
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        li{
            width: 165px;
            height: 67px;
            border: 1px solid rgb(206, 209, 210);
            margin:  0 20px 10px 20px;
            padding: 8px;
            margin-left: -10px;
            cursor: pointer;
            &:nth-child(1){
                margin-left: -10px;
            }
            &:nth-last-child(1){
                margin-right: 0;
            }
            p{
              margin: 0;
              height: 50%;
              &.text{
                  color: #000;
                font-size: 11px;
              }
              &.value{
                color: rgb(0, 134, 120);
                font-size: 19px;
                font-weight: 400;
              }
            }
        }
    }
`;