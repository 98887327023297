import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from "components/home_header";
import Footer from "components/addlisting_footer/FooterCom";
import ReturnAddressSetting from "./module/ReturnAddressSetting/ReturnAddressSetting"
import "./index.css"
import { Divider, Tabs } from 'antd';
const { TabPane } = Tabs;

function ReturnSettings() {
  return (
    <React.Fragment>
      <Header />
      <div className="return_settings" >
        <div className="return_settings_header" >
          <strong>退货设定</strong>
          <span>了解更多信息</span>
          <Divider type="vertical" />
          <span>退货设定</span>
        </div>
        <div className="return_settings_header_tips" >
          欢迎使用新的退货设置！退货设置现在特定于商城。如果您为多个地区启用了卖家帐户，则可以使用此页面顶部的商城开关来配置每个商城的设置。
        </div>
        <Tabs defaultActiveKey="3" tabBarExtraContent={<span style={{ marginRight: 20, color: "rgb(0,102,192)" }} >退货属性覆盖</span>} >
          <TabPane tab="常规设置" key="1">
          </TabPane>
          <TabPane tab="不退货解决方案" key="2">
          </TabPane>
          <TabPane tab="退货地址设置" key="3">
            <ReturnAddressSetting />
          </TabPane>
        </Tabs>
      </div>
      <Footer />
    </React.Fragment>

  )
}

export default ReturnSettings