import ASINs from "./module/ASIN/ASIN";
import Content from "./module/content/content";
import RelatedContent from "./module/Related Content/Related Content";
import React from "react";
import "./ProductDetails.css";
import IconFont from "components/Icon/Icon";
import Header from "components/home_header";
import Footer from "components/addlisting_footer/FooterCom";
import { Tabs } from "antd";
import {Link} from 'react-router-dom'
const { TabPane } = Tabs;

function ProductDetails() {
  return (
    <div>
      <Header />
      <div className="Details_body">

        <div className="Details_head">
          <div className="Details_headI">
              <div >
                <Link to="/Aa" style={{ color: "#008296" }}>Home</Link>
              </div>
            <span>
              <IconFont type="icon-fanhui-copy-copy" />
            </span>
            <div>type</div>
            <span>
              <IconFont type="icon-fanhui-copy-copy" />
            </span>
            <div>ebc</div>
            <span>
              <IconFont type="icon-fanhui-copy-copy" />
            </span>
            <div>content</div>
            <span>
              <IconFont type="icon-fanhui-copy-copy" />
            </span>
            <div>5486fa05-2a78-4602-b7dd-5ddd007c6b25</div>
            <span>
              <IconFont type="icon-fanhui-copy-copy" />
            </span>
            <div>revision</div>
            <span>
              <IconFont type="icon-fanhui-copy-copy" />
            </span>
            <div>1607345476696</div>
            <span>
              <IconFont type="icon-fanhui-copy-copy" />
            </span>
            <div>view</div>
            <span>
              <IconFont type="icon-fanhui-copy-copy" />
            </span>
            <div>asins</div>
          </div>

          <div className="Details_headII">
            <div className="Details_headII_label">
               <label>
                <select  className="Details_headII_lab">
                  <option>操作</option>
                  <option>暂停</option>
                  <option>复制</option>
                  <option>删除</option>
                </select>
              </label>
            </div>
            <div className="Details_headII_btn">
              <button><Link to="/imageText">编辑</Link></button>
            </div>
          </div>

          
        </div>
        <div className="Details_Steps">
          <div className="Details_StepsBox">
            <div className="Details_StepsImg"></div>
            <div className="Details_StepsFont">
              <div style={{ width: "18%" }}>创建内容</div>
              <div style={{ width: "28%" }}>
                <div>应用亚马逊商品编码</div>
                <div style={{ padding: "0 0 0 38px" }}>(ASIN)</div>
              </div>
              <div>已提交</div>
              <div>已核准</div>
              <div>
                <div>已发布<IconFont type="icon-tishi21" className="Details_Font_tishi" /></div>
                <div>在12/12/2020</div>
              </div>
            </div>
          </div>
          <div className="Details_Steps_li">
            <div className="Details_Steps_liI">
              <div>商品描述名称</div>
              <div>语言</div>
              <div>商品描述类型</div>
              <div>状态</div>
              <div>已应用的ASIN</div>
              <div>提交日期</div>
            </div>
            <div className="Details_Steps_liII">
              <div>蒸汽桑拿箱（黑色）</div>
              <div>美国 英语</div>
              <div>EBC</div>
              <div>已批准</div>
              <div>1</div>
              <div>12/7/2020</div>
            </div>
          </div>
          <div>
            <Tabs defaultActiveKey="2" className="tab1">
              <TabPane tab="商品描述" key="1" >
                <Content />
              </TabPane>
              <TabPane tab="ASIN" key="2" >
                <ASINs />
              </TabPane>
              <TabPane tab="相关商品描述" key="3">
                <RelatedContent />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default ProductDetails;
