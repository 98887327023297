import { useCallback, useEffect, useRef } from "react";

const useEventCallback = (callback, deps = []) => {
  const ref = useRef(() => {
    throw new Error("晚点再用");
  });

  useEffect(() => {
    ref.current = callback;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, ...deps]);

  return useCallback((...args) => {
    return (0, ref.current)(...args);
  }, []);
};

export default useEventCallback;
