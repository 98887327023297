import React from 'react';
import { Container, } from './style';
import HomeHeader from 'components/home_header';
import HomeFooter from 'components/home_footer';
import KPI from './children/kpi';
import Cards from './children/cards';

export default (props) => {

    return <Container>
        <HomeHeader />
        <KPI />
        <Cards />
        <HomeFooter />
    </Container>
}