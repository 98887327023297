import { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { ASelect, AText, ACheckboxGroup } from "@a-components/";
import style from "./style.module.css";

const options = [
  {
    label: "建议竞价",
    value: "建议竞价",
    description: "使用过去的竞价活动来预测更有可能获胜的竞价",
  },
  {
    label: "自定义竞价",
    value: "自定义竞价",
    description: "可以自定义每个关键词的竞价",
  },
  {
    label: "默认竞价",
    value: "默认竞价",
    description: "将多个关键词与同一竞价值相关联",
  },
];

export const matchTypesMapping = {
  1: "广泛",
  2: "词组",
  3: "精准",
};

const matchTypesOptions = [
  {
    label: "广泛",
    value: 1,
  },
  {
    label: "词组",
    value: 2,
    disabled: true
  },
  {
    label: "精准",
    value: 3,
    disabled: true
  },
];

const screeningConditionsDesc = [
  "匹配类型让您能够微调哪些顾客搜索内容会触发您的广告",
  "广泛匹配： 包含以任何顺序排列的所有关键词，包括复数、变体和相关关键词。",
  "词组匹配： 包含确切的词组或词序相同的关键词。 ",
  "精准匹配： 与相应关键词或关键词的词序完全匹配。",
];

// interface KeywordConditionProps {
//   value?: { matchTypes: string[], biddingType: string },
//   onChange?: KeywordConditionProps['value'],
//   type: 'recommend' | 'input'
// }

const titleMapping = {
  recommend: "筛选条件",
  input: "匹配条件",
};

const KeywordCondition = ({ value, onChange, type = "recommend" }) => {
  const [matchTypes, setMatchTypes] = useState(() => [1]);

  const [biddingType, setBiddingType] = useState("建议竞价");

  useEffect(() => {
    if (!value) return;

    const { matchTypes, biddingType } = value;
    console.log(matchTypes, "matchTypes")
    // setMatchTypes((pre) => {
    //   console.log(pre, "pre")
    //   return JSON.stringify(pre) === JSON.stringify(matchTypes)
    //     ? pre
    //     : matchTypes;
    // });
    setBiddingType(biddingType);
  }, [value]);

  useEffect(() => {
    onChange?.({ matchTypes, biddingType });
  }, [biddingType, onChange]);

  return (
    <>
      <div className={classNames(style.filterRow)}>
        <span className={classNames(style.filterTitle)}>
          <AText
            className="fs-12"
            tooltip="选择可帮助您实现目标的竞价类型。 了解更多信息"
          >
            竞价
          </AText>
        </span>

        <ASelect
          value={biddingType}
          options={options}
          onChange={setBiddingType}
          style={{ width: 120 }}
        />
      </div>
      <div className={classNames(style.filterRow)}>
        <span className={classNames(style.filterTitle)}>
          <AText className="fs-12" tooltip={screeningConditionsDesc}>
            {titleMapping[type]}
          </AText>
        </span>
        <ACheckboxGroup
          className="conditionGroup"
          value={matchTypes}
          options={matchTypesOptions}
          onChange={setMatchTypes}
        />
      </div>
    </>
  );
};

export default KeywordCondition;
