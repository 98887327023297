import React, { useState } from "react"
import { Form, Input, Space, Select, DatePicker, Radio, Button, Switch } from 'antd';
import "./AddCommodityOffer.css"
import HomeHeader from 'components/home_header';
import FooterCom from "components/addlisting_footer/FooterCom";
import { RightOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
const { Option } = Select;

function AddCommodityOffer() {

    return (
        <div className="add-offer" >
            <HomeHeader />
            <div className="add-offer-inner" >
                <div className="add-offer-header" >
                    <div style={{ width: 200 }} >
                        <img alt="example" src={require("@/assets/images/search_list.png").default} />
                    </div>
                    <div style={{ width: 800 }} >
                        <p>MengK Smoothie Blenders Professional Blenders Heavy Duty 1400W High Speed Blender for Shakes and Smoothies with Timer 64oz Jar BPA Free Pitcher for Food, Vegetables and Frozen Fruits (HS200)</p>
                        <div>
                            <div><strong>ASIN：</strong> BOINANV312</div>
                            <div><strong>UPC：</strong>721635656525</div>
                            <div><strong>亚马逊销售排名:</strong> 402811</div>
                        </div>

                        <div style={{ color: "#000" }} >
                            <div>竞争报价</div>
                            <div>无当前报价</div>
                        </div>
                        <div >
                            <div>在亚马逊上查看商品信息</div>
                            <div>更多信息 <RightOutlined /></div>
                        </div>


                    </div>
                </div>
                <div className="add-offer-body" >
                    <div style={{ fontSize: 14, marginBottom: 20 }} >高级视图 <Switch defaultChecked size="small" ></Switch ></div>
                    <Form
                        name="add_offer_form"
                        labelCol={{ span: 7 }}
                        wrapperCol={{ span: 15 }}
                        initialValues={{ remember: true }}
                    >
                        <Form.Item
                            label="卖家 SKU"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="商品税代码"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="您的价格"
                        >
                            <Input addonBefore={<span> USD$ </span>} property="例如：50.00" />
                        </Form.Item>
                        <Form.Item
                            label="配送模板"
                        >
                            <Select defaultValue="1" className="select-after" style={{ width: 424 }} >
                                <Option value="1" >美国版本-8美金</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="优惠价格"
                        >
                            <Input addonBefore={<span> USD$ </span>} property="例如：50.00" />
                        </Form.Item>
                        <Form.Item
                            label="优惠开始日期"
                        >
                            <DatePicker />
                        </Form.Item>
                        <Form.Item
                            label="优惠结束日期"
                        >
                            <DatePicker />
                        </Form.Item>
                        <Form.Item
                            label="市场价"
                        >
                            <Input addonBefore={<span> USD$ </span>} property="例如：50.00" />
                        </Form.Item>
                        <Form.Item
                            label="库存数量"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="状态"
                        >
                            <Select defaultValue="选择" className="select-after" style={{ width: 424 }} >
                                <Option>全新</Option>
                                <Option>二手：类似全新</Option>
                                <Option>二手：非常好</Option>
                                <Option>二手：好</Option>
                                <Option>二手：尚可</Option>
                                <Option>收藏品：类似全新</Option>
                                <Option>收藏品：非常好</Option>
                                <Option>收藏品：好</Option>
                                <Option>收藏品：尚可</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="状态说明"
                        >
                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item
                            label="订单商品最大量"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="处理时间"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="可否提供礼品信息"
                        >
                            <Select defaultValue="选择" className="select-after" style={{ width: 424 }} >
                                <Option>是</Option>
                                <Option>否</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="可否提供礼品包装"
                        >
                            <Select defaultValue="选择" className="select-after" style={{ width: 424 }} >
                                <Option>是</Option>
                                <Option>否</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="商品发布日期"
                        >
                            <DatePicker />
                        </Form.Item>
                        <Form.Item
                            label="补货日期"
                        >
                            <DatePicker />
                        </Form.Item>
                        <Form.Item
                            label="配送渠道"
                        >
                            <Radio.Group>
                                <Space direction="vertical">
                                    <Radio value={1}>
                                        <div>我将自行配送此商品</div>
                                        <div>（卖家自行配送）</div>
                                    </Radio>
                                    <Radio value={2}>
                                        <div>亚马逊将会配送并提供客户服务</div>
                                        <div>（亚马逊配送）</div>
                                    </Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                    <div  >
                        <Button style={{ marginRight: 10 }} ><Link to="/SearchList" >取消</Link></Button>
                        <Button><Link to="/SearchList" >保存并完成</Link></Button>
                    </div>
                </div>


            </div>



            <FooterCom />
        </div>
    )
}
export default AddCommodityOffer