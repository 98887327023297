import React, { useState } from 'react';
import './BrandDistribution.css';
import { Form, Select, Radio } from 'antd';
const { Option } = Select;

function BrandDistribution() {
  const distributionOnChange = (e) => {
    setDistributionValue(e.target.value);
  }
  const permitOnChange = (e) => {
    setPermitValue(e.target.value);
  }
  const [distributionValue, setDistributionValue] = useState(false);
  const [permitValue, setPermitValue] = useState(false);
  return (
    <div className="BrandDistribution" >
      <h3>分销信息</h3>
      <div style={{ marginBottom: 20 }} >以下信息有助于我们确定您的品牌 zwlong 可能符合哪些保护功能的使用条件</div>
      <Form layout="vertical">
        <h3>分销信息</h3>
        <Form.Item label="您的品牌是否向分销商销售商品？">
          <Radio.Group onChange={distributionOnChange} defaultValue="b">
            <Radio.Button value="a">是</Radio.Button>
            <Radio.Button value="b">否</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {
          distributionValue == "a" && (
            <Form.Item label="您的分销商是否在亚马逊上销售商品？">
              <Radio.Group defaultValue="a">
                <Radio.Button value="a">是</Radio.Button>
                <Radio.Button value="b">否</Radio.Button>
              </Radio.Group>
            </Form.Item>
          )
        }

        <Form.Item label="您的品牌商品在哪些地方分销？ （可选）" placeholder="选择一个国家或者区域" >
          <Select defaultValue={"1"} >
            <Option value="1">中国</Option>
            <Option value="2">印度</Option>
            <Option value="3">埃及</Option>
            <Option value="4">墨西哥</Option>
            <Option value="5">意大利</Option>
            <Option value="6">新加坡</Option>
          </Select>
        </Form.Item>
        <strong>被许可方信息</strong>
        <Form.Item label="对于与您知识产权相关的商品的生产商，您的品牌是否向其发放商标许可证？">
          <Radio.Group onChange={permitOnChange} defaultValue="b">
            <Radio.Button value="a">是</Radio.Button>
            <Radio.Button value="b">否</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {
          permitValue == "a" && (
            <Form.Item label="此被许可方是否在亚马逊上销售商品？">
              <Radio.Group defaultValue="a">
                <Radio.Button value="a">是</Radio.Button>
                <Radio.Button value="b">否</Radio.Button>
              </Radio.Group>
            </Form.Item>
          )
        }

      </Form>
    </div>
  )
}

export default BrandDistribution
