import { ARadioGroup, AText, AInputRate, ARadio } from "@a-components";
import { FormItem } from "@components/FormCollection";
import { Button } from "antd";
import { useState } from "react";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import NP from "number-precision";
import NumberPrecision from "@utils/NumberPrecision";

const DEFAULT_AMOUNT = 0.75;

const radioOptions = [
  {
    label: "动态竞价-只降低",
    value: 1,
    description: "当您的广告不太可能带来销售时，我们将实时降低您的竞价。",
  },
  {
    label: "动态竞价-提高和降低",
    value: 2,
    description:
      "当您的广告很有可能带来销售时，我们将实时提高您的竞价（最高可达 100%），并在您的广告不太可能带来销售时降低您的竞价。",
    tooltip: "历史上基于相同广告支出促成更多销售的竞价策略。",
  },
  {
    label: "固定竞价",
    value: 3,
    description:
      "我们将使用您的确切竞价和您设置的任何手动调整，而不会根据售出可能性对您的竞价进行更改。",
  },
];

const BiddingStrategy = () => {
  const [isFold, setFoldStatus] = useState(false);
  return (
    <div>
      <FormItem noStyle dependencies={["orientationStrategyType"]}>
        {(form) => {
          const orientationStrategyType = form.getFieldValue(
            "orientationStrategyType"
          );
          const isAutomatic = orientationStrategyType === "automatic";
          // if (isAutomatic) {
          //   form.setFieldsValue({ adBiddingStrategy: "动态竞价-提高和降低" });
          // } else {
          //   form.setFieldsValue({ adBiddingStrategy: 1 });
          // }
          return (
            <>
              <FormItem noStyle name="adBiddingStrategy">
                <ARadioGroup>
                  {radioOptions.map((item) => {
                    return (
                      <ARadio
                        key={item.value}
                        {...item}
                        tooltip={isAutomatic ? item.tooltip : ""}
                      >
                        {item.label}
                      </ARadio>
                    );
                  })}
                </ARadioGroup>
              </FormItem>

              <div >
                <Button
                  type="link"
                  icon={isFold ? <UpOutlined /> : <DownOutlined />}
                  onClick={() => setFoldStatus(!isFold)}
                >
                  根据广告位调整竞价（取代“竞价+”）
                </Button>
                <div style={{ marginLeft: 14, display: isFold ? 'block' : 'none' }}>
                  <div className="mb-12">
                    <AText className="fs-12">
                      除了竞价策略外，您可以将竞价最多提高 900%。 了解更多信息
                    </AText>
                  </div>
                  <div className="df ai-c mb-12">
                    <AText className="f-4">搜索结果顶部（首页）</AText>
                    <FormItem className="f-2 m-0" name="searchBiddingRate">
                      <AInputRate max={900} />
                    </FormItem>
                    <FormItem
                      noStyle
                      dependencies={["searchBiddingRate", "adBiddingStrategy"]}
                    >
                      {(form) => {
                        const isDynamicBidding =
                          form.getFieldValue("adBiddingStrategy") ===
                          "动态竞价-提高和降低";
                        const searchBiddingRate =
                          form.getFieldValue("searchBiddingRate") || 0;

                        const dynamicAmount = NumberPrecision(searchBiddingRate)
                          .divide(100)
                          .plus(1)
                          .times(DEFAULT_AMOUNT)
                          .valueOf();

                        const maxDynamicAmount = NP.times(dynamicAmount, 2);

                        return (
                          <AText className="f-8 fs-12">
                            示例：
                            {`对于此广告位，$0.75 竞价将为 $${dynamicAmount.toFixed(
                              2
                            )}。`}
                            {isDynamicBidding
                              ? ` 动态竞价可以将其提高至 $${maxDynamicAmount.toFixed(
                                  2
                                )}`
                              : null}
                          </AText>
                        );
                      }}
                    </FormItem>
                  </div>
                  <div className="df ai-c mb-12">
                    <AText className="f-4">商品页面</AText>
                    <FormItem className="f-2 m-0" name="goodsBiddingRate">
                      <AInputRate max={900} />
                    </FormItem>
                    {/* <AText className="f-8 fs-12">
                      示例： 对于此广告位，$0.75 竞价将为
                      $0.75。动态竞价可以将其提高至 $1.13
                    </AText> */}
                    <FormItem
                      noStyle
                      dependencies={["goodsBiddingRate", "adBiddingStrategy"]}
                    >
                      {(form) => {
                        const isDynamicBidding =
                          form.getFieldValue("adBiddingStrategy") ===
                          "动态竞价-提高和降低";
                        const goodsBiddingRate =
                          form.getFieldValue("goodsBiddingRate") || 0;

                        const dynamicAmount = NumberPrecision(goodsBiddingRate)
                          .divide(100)
                          .plus(1)
                          .times(DEFAULT_AMOUNT)
                          .valueOf();

                        const maxDynamicAmount = NumberPrecision(dynamicAmount)
                          .times(1.5)
                          .valueOf();

                        return (
                          <AText className="f-8 fs-12">
                            示例：
                            {`对于此广告位，$0.75 竞价将为 $${dynamicAmount.toFixed(
                              2
                            )}。`}
                            {isDynamicBidding
                              ? ` 动态竞价可以将其提高至 $${maxDynamicAmount.toFixed(
                                  2
                                )}`
                              : null}
                          </AText>
                        );
                      }}
                    </FormItem>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </FormItem>
    </div>
  );
};

export default BiddingStrategy;
