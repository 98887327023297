import AdvertisingSuperTable from "@view/advertisement/AdvertisingSuperTable";
import GoodsSelector from "@view/advertisement/GoodsSelector";
import { useState, useCallback, useRef } from "react";
import { Modal } from "antd";
import { AButton } from "@a-components/";
import Apis from "@api/apis";
import { useEventEmitter } from "ahooks";
import StatisticsCard from "@view/advertisement/module/AdvertisingCampaign/component/StatisticsCard";

const AdGroupDetail = ({ advertisingGroupId }) => {
  const [selectedGoodsList, setSelectedGoodsList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  const event$ = useEventEmitter();

  const handleShowModal = useCallback(() => {
    setModalVisible(true);
    setSelectedGoodsList([]);
  }, []);

  const handleHideModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const handleSaveGoodsList = useCallback(() => {
    return Apis.advertising
      .addAdvertisingGroupGoods({
        advertisingGroupId,
        advertisingGoodsReqList: selectedGoodsList.map((item) => ({
          goodsId: item.goodsId,
        })),
      })
      .then((res) => {
        if (res.code === 1) {
          handleHideModal();
          event$.emit({ type: "resetPageIndex" });
        }
      });
  }, [advertisingGroupId, event$, handleHideModal, selectedGoodsList]);

  event$.useSubscription(({ type, payload }) => {
    switch (type) {
      case "onPrimaryBtnClick":
        handleShowModal();
        break;
      default:
    }
  });

  return (
    <div>
      <StatisticsCard className="mb-24" />

      <AdvertisingSuperTable
        event$={event$}
        className="mt-12"
        businessType="adGroupGoods"
        businessId={advertisingGroupId}
      />

      <Modal
        title="添加要推广的商品"
        visible={modalVisible}
        style={{ width: "80%", minWidth: 1000 }}
        onCancel={handleHideModal}
        destroyOnClose
        footer={
          <>
            <AButton onClick={handleHideModal}>取消</AButton>
            <AButton
              type="primary"
              disabled={!selectedGoodsList.length}
              onClick={handleSaveGoodsList}
            >
              添加要推广的商品
            </AButton>
          </>
        }
      >
        <GoodsSelector
          value={selectedGoodsList}
          onChange={setSelectedGoodsList}
        />
      </Modal>
    </div>
  );
};

export default AdGroupDetail;
