import { Descriptions } from "antd";
import Apis from "@api/apis";
import { useCallback, useEffect, useState, useRef } from "react";
import {
  AInput,
  ASelect,
  ASwitch,
  AButton,
  ADatePicker,
  AInputMoney,
  AText,
  ARadioGroup,
  AInputRate,
} from "@a-components";
import { Space } from "antd";
import NumberPrecision from "@utils/NumberPrecision";
import moment from "moment";

const advertisingStatusMapping = {
  1: "有效",
  2: "已暂停",
  3: "已存档",
  4: "仅剩存档未完成 ",
  5: "目标页面不可用",
};

const AdGroupSetting = ({ advertisingGroupId, onAdvertisingGroupUpdated }) => {
  const [detail, setDetail] = useState({});

  // 已归档

  const createResponseFormat = useCallback(
    (value) => {
      return (res) => {
        if (res.code === 1) {
          setDetail((pre) => {
            return {
              ...pre,
              ...value,
            };
          });
          onAdvertisingGroupUpdated?.();
          return { type: "success" };
        }
        return {
          type: "fail",
        };
      };
    },
    [onAdvertisingGroupUpdated]
  );

  const queryAdvertisingDetailSet = useCallback(() => {
    return Apis.advertising
      .getAdvertisingGroupDetailSet({ advertisingGroupId })
      .then((res) => {
        if (res.code !== 1) return res;

        setDetail(res.data);
      });
  }, [advertisingGroupId]);

  useEffect(() => {
    queryAdvertisingDetailSet();
  }, [queryAdvertisingDetailSet]);

  /* ******************************* 广告组名称 - begin ******************************* */

  const updateAdvertisingGroupName = useCallback(
    (advertisingGroupName) => {
      return Apis.advertising
        .updateAdvertisingGroupName({
          advertisingGroupId,
          advertisingGroupName,
        })
        .then(createResponseFormat({ advertisingGroupName }));
    },
    [advertisingGroupId, createResponseFormat]
  );
  /* ******************************* 广告组名称 - end ******************************* */

  /* ******************************* 广告状态 - begin ******************************* */

  const updateAdvertisingGroupStatus = useCallback(
    (advertisingGroupStatus) => {
      return Apis.advertising
        .updateAdvertisingGroupStatus({
          advertisingGroupIds: advertisingGroupId,
          advertisingGroupStatus,
        })
        .then(createResponseFormat({ advertisingGroupStatus }));
    },
    [advertisingGroupId, createResponseFormat]
  );
  /* ******************************* 广告状态 - end ******************************* */

  /* ******************************* 默认竞价 - begin ******************************* */

  const updateAdvertisingGroupBidding = useCallback(
    (defaultPrice) => {
      return Apis.advertising
        .updateAdvertisingGroupBidding({
          adjustmentType: 3,
          advertisingGroupIds: advertisingGroupId,
          defaultPrice,
        })
        .then(createResponseFormat({ defaultPrice }));
    },
    [advertisingGroupId, createResponseFormat]
  );
  /* ******************************* 默认竞价 - end ******************************* */

  const isDisabled =
    detail.advertisingGroupStatus === 3 || detail.advertisingStatus === 3;

  return (
    <div>
      <Descriptions bordered column={1}>
        <Descriptions.Item label="广告组名称" labelStyle={{ width: 412 }}>
          <AInput
            disabled={isDisabled}
            confirmMode
            value={detail.advertisingGroupName}
            style={{ width: 174 }}
            $Sure={updateAdvertisingGroupName}
          />
        </Descriptions.Item>
        <Descriptions.Item label="有效/已暂停">
          <Space>
            <ASwitch
              disabled={isDisabled}
              checked={detail.advertisingGroupStatus === 1}
              onChange={(checked) =>
                updateAdvertisingGroupStatus(checked ? 1 : 2)
              }
            />
            {advertisingStatusMapping[detail.advertisingGroupStatus]}
          </Space>
          <br />
          <AButton
            disabled={isDisabled}
            type="link"
            className="p-0"
            onClick={() => updateAdvertisingGroupStatus(3)}
          >
            对此广告组进行存档
          </AButton>
        </Descriptions.Item>
        <Descriptions.Item label="状态">{detail.statusName}</Descriptions.Item>
        <Descriptions.Item label="默认竞价">
          <AInputMoney
            confirmMode
            disabled={isDisabled}
            value={detail.defaultPrice}
            style={{ width: 174 }}
            $Sure={updateAdvertisingGroupBidding}
          />
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default AdGroupSetting;
