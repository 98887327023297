import React from 'react';
import './distributionSet.css';
import { Tabs } from "antd";
import ShopupDL from './module/shopupDL/shopupDL';
import ShopupUL from './module/shopupUL/shopupUL';
import HomeHeader from 'components/home_header';
import Footer from '../../components/addlisting_footer/FooterCom';
import { Link } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
function shopup() {
    const { TabPane } = Tabs;
    return (
        <div className="distributionSet">
            <HomeHeader />
            <div className="shopup-body">
                <div className="shopup-titel">
                    <div>配送设置</div>
                    <div className="shopup-a">
                        <Link>查看演示<DownOutlined /></Link>
                    </div>
                </div>
                <div className="TabHeader">
                    <Tabs defaultActiveKey="1" className="tab2">
                        <TabPane tab="一键配送设置" key="1">
                            <ShopupDL />
                        </TabPane>
                        <TabPane tab="配送模板" key="2" >
                            <ShopupUL />
                        </TabPane>
                    </Tabs>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default shopup