import React from 'react'
import './Prime.css'
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import IconFont from 'components/Icon/Icon'
function Prime(){
    return(
        <div>
            <Header/>


            <div>


            <div className="end02_myBody">
			{/* <!-- 头部 --> */}
			<div className="end02_myBody_header">
				<div className="end02_myBody_header_title">
					<h1>Prime&nbsp;专享折扣<span className="font-green">了解更多信息</span></h1>
					<p className="font-green">查看折扣上传模板</p>
				</div>
				<p>现在，您可以为单件商品或一组商品提供Prime 会员折扣。</p>
				<button type="button">创建折扣</button>
			</div>
			{/* <!-- 分页 --> */}
			<div className="end02_pageing">
				<div>
					<svg t="1611404327885" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="25433" width="16" height="16"><path d="M677.391515 873.916768c-7.86101 0-15.618586-2.999596-21.617778-8.895354L324.473535 533.721212c-11.998384-11.894949-11.998384-31.340606 0-43.235555L655.670303 159.288889c5.999192-5.999192 13.756768-8.895354 21.617778-8.895354 7.757576 0 15.618586 2.999596 21.617778 8.895354 11.894949 11.894949 11.894949 31.237172 0 43.235555L389.223434 512.103434 698.905859 821.785859c11.894949 11.998384 11.894949 31.340606 0 43.235555-5.895758 5.895758-13.756768 8.895354-21.514344 8.895354z m0 0" p-id="25434"></path></svg>
					<p>1</p>
					<p>2</p>
					<p>3</p>
					<p>4</p>
					<p>5</p>
					<p>...</p>
					<p>19</p>
					<svg t="1611404357118" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="26286" width="16" height="16"><path d="M722.438 510.815c-0.246-0.246-0.513-0.454-0.766-0.69l-299.893-299.892c-14.231-14.24-37.291-14.24-51.529 0-14.241 14.231-14.241 37.291 0 51.529l274.851 274.859-274.849 274.851c-14.241 14.237-14.241 37.301 0 51.532 7.116 7.119 16.436 10.682 25.764 10.682 9.321 0 18.644-3.563 25.763-10.682l299.905-299.901c0.246-0.234 0.51-0.438 0.751-0.678 7.129-7.13 10.685-16.469 10.674-25.804 0.006-9.337-3.55-18.676-10.674-25.804z" p-id="26287" fill="#008296"></path></svg>
				</div>
				<div>
					<select name="">
						<option value="">每页显示10个结果</option>
					</select>
					<svg t="1611404538920" className="end02_icon_bottom" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="27322" width="16" height="16"><path d="M944.083879 289.536302c14.264888 18.286482 15.462157 41.208541 2.670829 51.196009l-417.191704 325.421604c-2.885723 2.261507-12.156878 7.828292-13.732769 9.578146-15.544021 17.181311-37.893029 22.338774-49.947576 11.461029l-392.908648-354.309538c-12.054547-10.887978-9.271154-33.666774 6.272867-50.889017 15.544021-17.21201 37.893029-22.349007 49.988508-11.471262l357.584118 322.443783 408.258241-318.463122C907.910005 264.516466 929.84969 271.260054 944.083879 289.536302z" p-id="27323" fill="#8a8a8a"></path></svg>
				</div>
			</div>
			{/* <!-- 表格 --> */}
			<table className="end02_table" border="0" cellSpacing="0" cellPadding="0">
				<caption className="end02_table_title caption1">您的&nbsp;prime&nbsp;专享折扣</caption>
				<thead>
					<tr className="end02_table_header">
						<th className="end02_discount">折扣</th>
						<th className="end02_start">
							开始
							<div>
								<IconFont type="icon-paixu" />
							</div>
						</th>
						<th className="end02_end">结束
							<div>
							<IconFont type="icon-paixu" />
							</div>
						</th>
						<th className="end02_state">
							状态
							<div>
							<IconFont type="icon-paixu" />
							</div>
						</th>
						<th className="end02_change">上次更改
						<svg t="1611562300719" className="end02_icon_top" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15468" width="16" height="16"><path d="M481.856 992V154.432L76.608 558.144l-44.48-44.608L513.664 32l478.144 478.144-44.544 46.08-401.792-401.792V992h-63.616z" p-id="15469" fill="#002f36"></path></svg>
						</th>
						<th className="end02_operation">操作</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className="font-green">213123</td>
						<td>20-12-15上午12:00</td>
						<td>20-12-19下午11:59</td>
						<td>已过期</td>
						<td>20-12-12下午7:38</td>
						<td>
							<div className="end02_table_btn">
								<button type="button">查看详情</button>
								<div>
									<svg t="1611396307554" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="23000" width="16" height="16"><path d="M512 693.333333c-14.933333 0-29.866667-4.266667-40.533333-14.933333l-277.33333399-234.666667c-27.733333-23.466667-29.866667-64-8.53333301-89.6 23.466667-27.733333 64-29.866667 89.6-8.53333299L512 546.133333l236.8-200.53333299c27.733333-23.466667 68.266667-19.19999999 89.6 8.53333299 23.466667 27.733333 19.19999999 68.266667-8.53333301 89.6l-277.33333399 234.666667c-10.666667 10.666667-25.6 14.933333-40.533333 14.933333z" fill="#ffffff" p-id="23001"></path></svg>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td className="font-green">鼠标专享折扣</td>
						<td>20-12-13上午12:00</td>
						<td>20-12-15下午11:59</td>
						<td>已过期</td>
						<td>20-12-12下午7:35</td>
						<td>
							<div className="end02_table_btn">
								<button type="button">查看详情</button>
								<div>
									<svg t="1611396307554" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="23000" width="16" height="16"><path d="M512 693.333333c-14.933333 0-29.866667-4.266667-40.533333-14.933333l-277.33333399-234.666667c-27.733333-23.466667-29.866667-64-8.53333301-89.6 23.466667-27.733333 64-29.866667 89.6-8.53333299L512 546.133333l236.8-200.53333299c27.733333-23.466667 68.266667-19.19999999 89.6 8.53333299 23.466667 27.733333 19.19999999 68.266667-8.53333301 89.6l-277.33333399 234.666667c-10.666667 10.666667-25.6 14.933333-40.533333 14.933333z" fill="#ffffff" p-id="23001"></path></svg>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td className="font-green">充电宝9折</td>
						<td>20-12-13上午12:00</td>
						<td>20-12-13下午11:59</td>
						<td>已过期</td>
						<td>20-12-12下午7:33</td>
						<td>
							<div className="end02_table_btn">
								<button type="button">查看详情</button>
								<div>
									<svg t="1611396307554" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="23000" width="16" height="16"><path d="M512 693.333333c-14.933333 0-29.866667-4.266667-40.533333-14.933333l-277.33333399-234.666667c-27.733333-23.466667-29.866667-64-8.53333301-89.6 23.466667-27.733333 64-29.866667 89.6-8.53333299L512 546.133333l236.8-200.53333299c27.733333-23.466667 68.266667-19.19999999 89.6 8.53333299 23.466667 27.733333 19.19999999 68.266667-8.53333301 89.6l-277.33333399 234.666667c-10.666667 10.666667-25.6 14.933333-40.533333 14.933333z" fill="#ffffff" p-id="23001"></path></svg>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
				<tfoot>
					<tr className="end02_table-f">
						<td colSpan="6"></td>
					</tr>
				</tfoot>
			</table>
			{/* <!-- 分页 --> */}
			<div className="end02_pageing">
				<div>
					<svg t="1611404327885" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="25433" width="16" height="16"><path d="M677.391515 873.916768c-7.86101 0-15.618586-2.999596-21.617778-8.895354L324.473535 533.721212c-11.998384-11.894949-11.998384-31.340606 0-43.235555L655.670303 159.288889c5.999192-5.999192 13.756768-8.895354 21.617778-8.895354 7.757576 0 15.618586 2.999596 21.617778 8.895354 11.894949 11.894949 11.894949 31.237172 0 43.235555L389.223434 512.103434 698.905859 821.785859c11.894949 11.998384 11.894949 31.340606 0 43.235555-5.895758 5.895758-13.756768 8.895354-21.514344 8.895354z m0 0" p-id="25434"></path></svg>
					<p>1</p>
					<p>2</p>
					<p>3</p>
					<p>4</p>
					<p>5</p>
					<p>...</p>
					<p>19</p>
					<svg t="1611404357118" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="26286" width="16" height="16"><path d="M722.438 510.815c-0.246-0.246-0.513-0.454-0.766-0.69l-299.893-299.892c-14.231-14.24-37.291-14.24-51.529 0-14.241 14.231-14.241 37.291 0 51.529l274.851 274.859-274.849 274.851c-14.241 14.237-14.241 37.301 0 51.532 7.116 7.119 16.436 10.682 25.764 10.682 9.321 0 18.644-3.563 25.763-10.682l299.905-299.901c0.246-0.234 0.51-0.438 0.751-0.678 7.129-7.13 10.685-16.469 10.674-25.804 0.006-9.337-3.55-18.676-10.674-25.804z" p-id="26287" fill="#008296"></path></svg>
				</div>
				<div>
					<select name="">
						<option value="">每页显示10个结果</option>
					</select>
					<svg t="1611404538920" className="end02_icon_bottom" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="27322" width="16" height="16"><path d="M944.083879 289.536302c14.264888 18.286482 15.462157 41.208541 2.670829 51.196009l-417.191704 325.421604c-2.885723 2.261507-12.156878 7.828292-13.732769 9.578146-15.544021 17.181311-37.893029 22.338774-49.947576 11.461029l-392.908648-354.309538c-12.054547-10.887978-9.271154-33.666774 6.272867-50.889017 15.544021-17.21201 37.893029-22.349007 49.988508-11.471262l357.584118 322.443783 408.258241-318.463122C907.910005 264.516466 929.84969 271.260054 944.083879 289.536302z" p-id="27323" fill="#8a8a8a"></path></svg>
				</div>
			</div>
		</div>



            </div>



            <Footer/>
        </div>
    )
}

export default Prime