import React, { useRef, useEffect, useCallback, useState } from 'react';
import './index.less'
import { Button, Input, Modal, Radio, Space, Form } from 'antd';
import Footer from 'components/addlisting_footer/FooterCom'
import { CaretDownOutlined } from '@ant-design/icons';
import { regSubmit } from 'utils/PublicLibrary';


export default (props) => {
  let formRef = useRef();
  const [value, onChange] = useState('1');
  const [err, seterr] = useState(false);
  const [page, setPage] = useState(1);
  const [num, setNum] = useState('');
  const [code, setCode] = useState('');
  const [randoms, setRandoms] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    MathRand()
  }, [])
  const nextEvent = useCallback(() => {
    formRef.current.validateFields().then((res) => {
      regSubmit([], null, '', 19).then(res => {
        if (res.code == 1) {
          props.history.push('/twoStepVerification/Complete')
        }
      })

    })

  })
  const next = useCallback(() => {
    if (num && !err) {
      setPage(2)
      seterr(false)
    } else {
      seterr(true)
    }
  }, [num, err])
  const MathRand = useCallback(() => {
    let Num = "";
    for (let i = 0; i < 6; i++) {
      Num += Math.floor(Math.random() * 10);
    }
    setRandoms(Num)
  })
  const onPhone = (e) => {
    let txt = e.target.value
    let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if (!reg.test(txt)) {
      seterr(true)
    } else {
      seterr(false)
    }
    setNum(txt)
  }


  return (
    <div className='Authenticator register_style'>
      <div className='inner'>
        <div className='step'>第 1 步，共 2 步</div>
        <h2>注册两步验证认证器</h2>
        <div className='box'>
          <div className={`box_inner`}>
            <Radio.Group name="ee" defaultValue='1' value={value} onChange={e => { onChange(e.target.value) }}>
              <Radio className='title' value='1' >电话号码<span>将您的手机用作两步验证认证器</span></Radio>
            </Radio.Group>
            {
              page == 1 ? (
                <div style={{ display: value == '1' ? 'block' : 'none' }}>
                  <div className='txt'>告诉我们您希望用于进行两步验证的电话号码。</div>
                  <strong>我们应将一次性密码发送至何处？</strong>
                  <div className='int'>
                    <Button>CN +86 <i className='a-icon-dropdown'></i></Button>
                    <Input maxLength={11} value={num} onChange={onPhone} placeholder='（例如，201-555-5555）'></Input>
                  </div>
                  <strong>请选择一次性密码的接收方式：</strong>
                  <Radio.Group name="radiogroup" defaultValue='1'>
                    <Space direction="vertical">
                      <Radio value='1'>短信 (SMS)</Radio>
                      <Radio value='2'>语音电话接收：您将收到系统自动拨出的语音电话</Radio>
                    </Space>
                  </Radio.Group>
                  {
                    (err || !value) && <p className='err'><i>!</i>您所输入的电话号码无效。请输入一个有效的电话号码。</p>
                  }
                  <Button className='sbu' type="primary" onClick={next} >继续</Button>

                </div>
              ) : (
                <div style={{ display: value == '1' ? 'block' : 'none' }} className='page2'>
                  <p>+86 {num} <a onClick={() => { setPage(1); setNum('') }}>更换</a></p>
                  <p>代码已发送至您的手机。请将代码输入下方。</p>
                  <Form ref={formRef}>
                    <strong>输入验证码</strong>
                    <Form.Item
                      name="code"
                      rules={[{
                        required: true,
                        validator: (rule, value, cbfn) => {
                          if (!value) {
                            cbfn('请输入6位数验证码')
                          } else if (value.length < 6) {
                            cbfn('验证码应为6位数')
                          } else if (Number(value) !== Number(randoms)) {
                            cbfn('无效的验证码. 请检查您的验证码并重试。')
                          }
                          cbfn()
                        }
                      }]}>
                      <Input className='sbu' maxLength="6" />
                    </Form.Item>
                    <Button className='sbu' type="primary" onClick={nextEvent} >继续</Button>
                  </Form>
                  <div style={{ textAlign: 'center', width: '350px', margin: '0px 0 20px' }}><a onClick={MathRand}>重新发送验证码</a></div>
                </div>
              )
            }
            <p style={{ color: '#767676', fontSize: '12px' }}>可能会收取短信和数据流量费用。</p>
          </div>
          <div className={`box_inner ${value == '1' ? 'hui' : 'bai'} rzq`}>
            <Radio.Group name="eee" defaultValue='1' value={value} onChange={e => { onChange(e.target.value) }}>
              <Radio className='title' value='2' >认证器应用程序<span>使用应用程序生成一次性密码。 无需连接网络。</span></Radio>
            </Radio.Group>
            <div style={{ display: value == '2' ? 'block' : 'none' }}>
              <p>不会在每次登录时都以短信形式发送一次性密码给您，而是让您使用手机上的认证器应用程序生成一次性密码。 您将在登录时输入所产生的一次性密码，方式与使用短信接收一次性密码相同。</p>
              <div>1.打开您的认证器应用程序。 <a>需要认证器应用程序？</a><CaretDownOutlined /></div>
              <div>2.在应用程序中添加帐户，然后扫描下面的二维码。</div>
              <img src={require('@assets/images/Authenticator.png').default}></img>
              <div><a>无法扫描二维码？</a><CaretDownOutlined /></div>
              <div>3.输入一次性密码。 <span style={{ fontWeight: '400' }}>扫描二维码后，输入应用程序生成的一次性密码：</span></div>
              <div className='yzm'>
                <Input></Input>
                <Button className='sbu' type="primary">验证一次性密码并继续</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        page == 2 ? (
          <div className="elailTips" onClick={() => { setIsModalVisible(true) }}>
            <img src={require("@/assets/img/register/phone_pinComing.png").default} />
          </div>
        ) : null
      }


      <Modal title="验证码" className="meailModalBox" centered visible={isModalVisible} footer={null} onCancel={() => { setIsModalVisible(false) }}>
        <p className="otp">{randoms}</p>
        <p>【亚马逊】Message from Amazon:Your one-time PIN is {randoms}</p>
      </Modal>
      <Footer></Footer>
    </div>
  )
}