import Form from "./Form";
import FormItem from "./FormItem";
import FormList from "./FormList";
import FormErrorList from "./ErrorList";
import "./style.css";

Form.Item = FormItem;
Form.List = FormList;
Form.ErrorList = FormErrorList;

export default Form;
