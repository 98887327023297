import React, { useState,useEffect } from "react"
import Reactours from "components/reactours"
import TipsIcon from "components/tipsIcon"
import { Input, Row, Col } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import AJAX from "api/apis";
import "./Body.css"

function Body() {
    let history = useHistory();
    let [data] = useState({
        list: [
            { id: 1, title: "我要添加未在亚马逊上销售的新商品", className: 'body-col stepGuidanceBox0', path: '/commodity' },
            { id: 2, title: "我正在上传文件来添加多个商品", className: 'body-col stepGuidanceBox1', path: '/Shopup' },
            { id: 3, title: "我想要了解如何发布商品", className: 'body-col stepGuidanceBox2' }
        ]
    });
    const [isTourOpen, setIsTourOpen] = useState(true);
    const steps1 = [
        {
            selector: '.stepGuidanceBox0',
            content: '选择我要添加未在亚马逊上销售的新商品',
        }
    ];
    const [isActive, setIsActive] = useState(false);
    const awakenTips = (type) => {
        setIsActive(type);
    }
    useEffect(() => {
        AJAX.login.getStoreStep()
      }, [])
    const onSearch = (e) => {
        history.push('/SearchList')
    }
    const { Search } = Input;
    return (
        <div className="home-body-box">
            {/* 引导步骤 */}
            <TipsIcon clicks={awakenTips} />
            <Reactours routePath="addlisting" isActive={isActive} clicks={awakenTips} steps={steps1} isOpen={isTourOpen} />
            <div className="home-body">
                <div className="home-body-title">要开始添加商品</div>
                <h1>请首先在亚马逊目录中查找您的商品</h1>
                <Search placeholder="商品名称、通用产品代码(UPC)、EAN、国际标准图书编号(ISBN)或亚马逊商品编码(ASIN)" onSearch={onSearch} allowClear />
                <Row className="body-row">
                    {
                        data.list.map(item => {
                            if (item.path) {
                                return <Col className={item.className} span="8" key={item.id}><Link to={item.path}>{item.title}</Link></Col >
                            } else {
                                return <Col className={item.className} span="8" key={item.id}>{item.title}</Col >
                            }
                        })
                    }
                </Row>
            </div>
        </div>
    )
}

export default Body