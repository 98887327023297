import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getRequest } from "utils/utils";

let names = [
  '/login',
  '/register'
];

const PrivateRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => {
    let receptionStoreId = getRequest(props.location.search).receptionStoreId
    if (receptionStoreId) {
      window.receptionStoreId = receptionStoreId
    }
    // let userInfo = localStorage.getItem('userInfo');
    // let pathname = props.location.pathname;
    // if(userInfo){
    //     if(pathname === '/login'){
    //         return <Redirect to={{ pathname: '/'}}/>
    //     }
    //     return <Component {...props} />;
    // }
    // if(!names.includes(pathname)){
    //     return <Redirect to={{ pathname: '/login'}}/>
    // }
    return <Component {...props} />;
  }} />
}

export default PrivateRoute;