import React from 'react'
import './index.css'
import Header from 'components/home_header/index'
import Footer from 'components/addlisting_footer/FooterCom'
import IconFont from 'components/Icon/Icon'



function SellingApplications(){
    let ListData = [
        {id:1,
         name:"O-Cedar 在 Home and Garden (including Pet Supplies) 分类中进行了标记",
         type:"品牌",
         change:"2021-2-22",
         state:"已拒绝"
        },
        {id:2,
            name:"O-Cedar 在 Home and Garden (including Pet Supplies) 分类中进行了标记",
            type:"品牌",
            change:"2021-2-22",
            state:"已拒绝"
           },

    ]
        return(
            <div>
                <Header/>
                <div className="SellingApplications_box">
                     <div className="SellingApplications_titel">销售申请</div>
                     <div className="SellingApplications_choose">
                         <p>销售申请状态</p>
                         <div className="SellingApplications_choose_content">
                             <div className="SellingApplications_choose_content_left">
                                <div style={{marginRight:"6px",color:"#7d7d7d"}}>查看</div>
                                <button>全部</button>
                                <button>需要操作</button>
                                <button>已批准</button>
                                <button>审核中</button>
                                <button>已拒绝</button>
                                <button>草稿</button>
                                <span>|</span>
                                <button>分类</button>
                                <button>品牌</button>
                                <button>子类</button>
                                <button>ASIN</button>
                                <button>注册</button>
                                <button>申诉</button>
                             </div>
                             <div className="SellingApplications_choose_content_right">
                                 <div className="SellingApplications_choose_content_right_font">新申请</div>
                                 <div className="SellingApplications_choose_content_right_inp">
                                     <input placeholder="按商品名称或 UPC 搜索" />
                                     <button>搜索</button>
                                 </div>
                             </div>
                         </div>
                     </div>
                     <div className="SellingApplications_list">
                        <div className="SellingApplications_list_header">
                            <div className="SellingApplications_list_name">
                                <div>申请名称</div>
                                <div>问题编号</div>
                            </div>
                            <div className="SellingApplications_list_type">申请类型</div>
                            <div className="SellingApplications_list_change">已更改<IconFont type="icon-sanjiao" /></div>
                            <div className="SellingApplications_list_state">状态</div>
                        </div>
                        <div className="SellingApplications_list_content">
                            {
                            ListData.map((item,id)=>
                            <div className="SellingApplications_list_product" key={id}>
                                <div className="SellingApplications_list_name">{item.name}</div>
                                <div className="SellingApplications_list_type">{item.type}</div>
                                <div style={{width:"15%"}}>{item.change}</div>
                                <div className="SellingApplications_list_state">{item.state}</div>
                                <div className="SellingApplications_list_btn"><button>重新申请</button></div>
                            </div>)
                            }
                        </div>
                     </div>

                        <div className="SellingApplications_pageBtn">
                            <div className="SellingApplications_pageBtn_next">← 上一页</div>
                            <button>1</button>
                            <button>2</button>
                            <button>3</button>
                            <button>4</button>
                            <button>5</button>
                            <div className="SellingApplications_pageBtn_down"><button>下一页 →</button></div>
                        </div>

                </div>
                <Footer/>
            </div>
        )
}

export default SellingApplications