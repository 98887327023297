import React from 'react'
import './Negotiated.css'
import '../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import {Link} from 'react-router-dom'

function Negotiated(){


    return(
        <div>
            <Header/>

                <div>
                <div className="qd11_content">
			<div className="qd11_box1">
				<p className="qd11_h1">协议定价</p>
				<span className="qd11_blueft">Learn more</span>
			</div>
			<div className="qd11_box2">
				<div className="qd11_box2-l">
					<p className="qd11_box2-p">Connect to customers and expand sales</p>
					<p className="qd11_wfont">with customer negotiated pricing on Amazon</p>
				</div>
				{/* <img src="../../assets/images/qd11_Hlogo.png" /> */}
                <div className="qd11_box2_img"></div>
			</div>
			<p className="qd11_box3">管理您与亚马逊企业购买家的协议定价。要解除与买家的合作关系，请<span className="qd11_blueft">联系我们。</span></p>
			<div className="qd11_btn"><Link to="/Add">添加买家</Link></div>
			<ul className="qd11_ul4">
				<li className=" qd11_ul4_fis">要开始使用:</li>
				<li>1. 添加买家</li>
				<li>2. 为买家上传商品价格,以及失效日期。</li>
				<li>3. 上传后，商品价格将发送给买家以寻求批准。获得批准后，商品价格在失效日期之前将始终有效。</li>
			</ul>
		</div>
                </div>

            <Footer/>
        </div>
    )
}

export default Negotiated