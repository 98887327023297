import React from "react"
import AJAX from "api/apis";
import BannerHeader from "../Banner-header";
import FormData from "../formData/formdata";
import Reactours from "components/reactours"
import TipsIcon from "components/tipsIcon"
import "./Tab4-banner.css"

class Tab4Banner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                formList: [],
                NumbetTypeList: []
            },
            isTourOpen: true,
            steps1: [
                {
                    selector: '.tab4-box .tab1Banner-box',
                    content: '此产品包含电池可填写以下内容',
                }
            ],
            isActive:false
        }
    }
    getFormRef = el => {
        this.form = el;
    }
    awakenTips = (type) =>{
        this.setState({
            isActive:type
        })
    }
    Switchse = (e) => {
        this.props.Switchse(e)
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.isSubmit) {
            this.form.validateFields().then((res) => {}).catch((errr) => {
                console.log(errr, "errr")
            })
        }
    }
    formChonge = () => {
        let { formList } = this.state.data;
        let content = this.form.getFieldsValue();
        let isValue = true;
        for (let items of formList) {
            for (let keys in content) {
                if (items.rules.length > 0) {
                    if (!content[items.basicsKey]) {
                        isValue = false;
                        return;
                    }
                }
            }
        }
        if (isValue) {
            this.props.submitData(content, 4)
        }
    }
    dataInfo = (data, type) => {
        this.props.submitData(data, type)
    }
    render() {
        let { steps1, isTourOpen,isActive } = this.state
        return <div className="tab4-box">
            <BannerHeader checked={this.props.isSenior} id={this.props.id} Switchse={this.Switchse} />
            <FormData id={this.props.id} type="4" classifyId={this.props.classifyId} goodsId={this.props.goodsId} isHighView={this.props.isSenior} dataInfo={this.dataInfo}></FormData>
            {/* 引导步骤 */}
            <TipsIcon clicks={this.awakenTips} />
            <Reactours routePath="Tab4-banner" isActive={isActive} clicks={this.awakenTips} steps={steps1} isOpen={isTourOpen} />
            {/* <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} ref={this.getFormRef} className="formBox" layout="horizontal">
                {
                    data.formList.map((item, index) => {
                        return (
                            <Form.Item key={index} name={item.basicsKey} extra={item.extra} label={
                                <span className="tab1-form-title">{item.title}&nbsp;<QuestionCircleOutlined style={{ color: "#008296" }} /></span>}
                                rules={item.rules}>
                                {
                                    !item.isSelect ?
                                        <Input onChange={this.formChonge} placeholder={item.placeholder} />
                                        :
                                        <Select onChange={this.formChonge} className="tab3-select" placeholder="-选择-">
                                            {item.basicsKeyValue ? item.basicsKeyValue.map((item2, index2) => {
                                                return <Option value={item2}>{item2}</Option>
                                            }) : null}
                                        </Select>
                                }
                            </Form.Item>
                        )
                    })
                }
            </Form> */}
        </div>
    }
}

export default Tab4Banner