import { render } from "@testing-library/react"
import React, { Component } from "react"
import './index.css'
import Header from 'components/home_header/index'
import Footer from 'components/addlisting_footer/FooterCom'
import '../default.css/default.css'
import IconFont from 'components/Icon/Icon'

class NoActivity extends React.Component {

    render() {
        return (
            <div>
                <Header />

                <div className="xiufu">
                    <h1>修复商品</h1>
                </div>

                <div className="bfuAbttur">
                    <div className="bfuAbttur-left">
                        <div className="bfuAbttur-left-jkl">
                            <div >
                                <div className="bfuAbttur-left-jkl-titel">所有有问题的商品</div>
                            </div>

                            <div className="bfuAbttur-left-jkl-contentBox">

                                <div className="bfuAbttur-left-jkl-content">
                                    <div>非在售商品(135)<IconFont type="icon-wenhao1" /></div>
                                    <div><IconFont type="icon-sanjiao" /></div>
                                </div>
                                <div style={{ padding: "3px 10px" }}>缺货问题(132)</div>
                                <div style={{ padding: "3px 10px" }}>定价问题(1)</div>
                                <div style={{ padding: "3px 10px" }}>已停问题(1)</div>
                                <div style={{ padding: "3px 10px" }}>需要批准(1)</div>
                            </div>

                            <div className="bfuAbttur-left-jkl-contentBox">
                                <div className="bfuAbttur-left-jkl-content">
                                    <div>搜索结果中禁止显示(40)<IconFont type="icon-wenhao1" /></div>
                                    <div><IconFont type="icon-sanjiao" /></div>
                                </div>
                                <div style={{ padding: "3px 10px" }}>需要提供更多信息 (33)</div>
                                <div style={{ padding: "3px 10px" }}>无效信息 (6)</div>
                            </div>
                            <div className="bfuAbttur-left-jkl-contentBox">
                                <div className="bfuAbttur-left-jkl-content">
                                    <div>“存在风险”的商品 (0)<IconFont type="icon-wenhao1" /></div>
                                    <div><IconFont type="icon-sanjiao" /></div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="bfuAbttur-right">
                        <div>
                            <select name="" id="">
                                <option>SKU 前缀</option>
                                <option></option>
                            </select>
                            <div className="bfuAbttur-right-jkl">
                                <input placeholder="搜索" type="text" />
                                <button><img src="../../assets/images/kc02_sousuo.png" alt="" /></button>
                            </div>
                            <div className="box_qwerr">
                                <p>当前显示1-10个 SKU，共计135个SKU</p>
                            </div>
                        </div>
                        <div className="box13_uiapp">
                            <div className="box13_uiapp-jkl">
                                <div className="box13_uiapp-jkl_app">
                                    <div></div>

                                    <div className="box13_uiapp-jkl_app_qqqqq">
                                        <img src="../../assets/images/kc02_shuihu.png" alt="" />

                                        <p><span>mengk</span>Manual Fruit Juicer Professional -Juicer POmegranate Squeezer <br />
                                - Premium Quality Heavy Duty Manual POmegranate Juicer and <br />
                                Lime Squeezer Press S...</p>
                                        <p><span>ASIN</span>:808QD7BCV9</p>
                                        <p><span>SKU</span>:CYJ123-A</p>
                                        <p><span>状况</span>:New</p>

                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <button>更新数量</button>
                                        <input type="text" placeholder="0" />
                                        <p>您的库存数量为零。添加数量，然后点击“更新数量”</p>
                                        <span className="myspan">缺货</span>
                                        <p>9天前</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="myhadyj">
                            <div className="myhadyj_yi">
                                <p>页面</p>
                                <input type="text" placeholder="1" />
                                <p> /14</p>
                                <button>前往</button>
                            </div>

                            <div className="myhadyj_er">
                                <span>{"<"} 1</span>
                                <span>2</span>
                                <span>3</span>
                                <span>4</span>
                                <span>5</span>
                                <span>...</span>
                                <span>14</span>
                                <span>{">"}</span>
                            </div>

                            <div className="myhadyj_san">
                                <select name="" id="">
                                    <option>10</option>
                                    <option ></option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        )
    }
}

export default NoActivity