import { uglifyValue } from "@utils/utils";
export const productLaunchFormInitValues = {
  productLaunchDefaultBidding: 0.75,
  categoryGoodsPosition: [],
};
// defaultPrice

export const computedInnerId = ({
  brandId,
  directionalId,
  distribution,
  goodsPriceStart,
  goodsPriceEnd,
  starStart,
  starEnd,
}) => {
  return uglifyValue({
    brandId,
    directionalId,
    distribution,
    goodsPriceStart,
    goodsPriceEnd,
    starStart,
    starEnd,
  });
};
