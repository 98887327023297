import React, { useRef, useEffect, useCallback, useState } from 'react';
import './index.less'
import { Button, Checkbox } from 'antd';
import Footer from 'components/addlisting_footer/FooterCom'
import { regSubmit } from 'utils/PublicLibrary';


export default (props) => {
  const [value, onChange] = useState(false);


  let nextEvent = useCallback(() => {
    regSubmit([], null, '', 20).then(res => {
      if (res.code == 1) {
        props.history.push('/')
      }
    })
  })


  return (
    <div className='Complete'>
      <div className='inner'>
        <div className='step'>第 2 步，共 2 步</div>
        <p style={{ fontSize: '28px' }}>就快完成了...</p>
        <p style={{ fontSize: '13px', margin: '18px 0' }}>还有两个重要事项：</p>
        <h5>1.旧版设备登录方法</h5>
        <div style={{ fontSize: '13px', margin: '18px 0' }}>如果您开启了两步验证功能，在Kindle电子书阅读器上登录您的亚马逊帐号时，需要在密码输入框中输入账户密码并将认证器应用程序上出现的6位数验证码追加到密码之后</div>
        <ol start="1">
          <li>使用您的密码登录。 系统会显示一条错误消息。</li>
          <li>一次性密码将发送至您首选的电话号码。 您还可以使用认证器应用程序。</li>
          <li>在密码末尾添加（“附加”）一次性密码，然后再次点击“登录”。</li>
        </ol>
        <img src={require('@assets/images/Complete.png').default}></img>
        <div>然后，您将登录到您的亚马逊帐户。</div>
        <h5 style={{ margin: '18px 0 5px' }}>2.登录时跳过一次性密码验证步骤</h5>
        <div>您可以选择“在此浏览器上不需要一次性密码”，跳过日后的一次性密码验证步骤。 只要跳过一次性密码验证步骤的 Cookie 一直有效，登录该浏览器或应用程序时则只需输入密码。 （请注意： 您需要为您使用的每个浏览器分别启用此选项。）</div>
        <Checkbox onChange={e => { onChange(e.target.checked) }}>请勿在此浏览器上索要一次性密码</Checkbox>

        <div><Button onClick={nextEvent} className='sbu' type="primary">好。 启用两步验证</Button></div>

      </div>
    </div >
  )
}