import React from 'react'
import './ManageSKUs.css'
import '../../../default.css/default.css'
import Header from 'components/home_header'
import Footer from 'components/addlisting_footer/FooterCom'
import IconFont from 'components/Icon/Icon'


function ManageSKUs(){
    return(
        <div>
            <Header/>
            <div>





            <div className="qr6_contcent">
			<div>自动定价 <span>{">"}</span> <span>编辑SKU</span></div>
			<div>管理 SKU<span>了解更多信息</span> <span>|</span> <span>提供反馈</span></div>
			<div className="qr6_contcent-box">
				<div>将 SKU 添加到您的 "Competitive Price Rule by Amazon"规则</div>
				<div>您已选择通过商品信息界面一次添加一个商城 SKU。您可以将 SKU添加至Amazon.com,Amazon.ca,Amazon.mx中的此规则。</div>
				<div>如果您想通过上传文件夹来管理所有商城中的 SKU，<span>请点击此处。</span></div>
			</div>
			<div className="qr6_contcentII">
				<span>按商城进行筛选</span>
				<button>Amazon.com 

					<svg t="1610538962139" className="icon qr6_img" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2521" ><path d="M548.03368229 792.19053125v0c4.1855625-2.41467187 8.68514063-4.18556248 12.38442186-7.8868125 0.64870313-0.8071875 1.12415625-1.44703125 1.77089063-2.09278125 0.8071875-0.96271875 2.09278125-1.28657812 2.89209376-2.09475l434.196-450.43720312c11.57920312-11.90404688 17.37225-27.349875 17.37225-42.79373438 0-16.08960937-6.43485938-32.33770312-18.98071875-44.40220313-24.61232813-23.64960937-63.70973438-23.00385937-87.35343751 1.60846875l-391.56468749 406.35984375-385.92520314-407.9683125c-23.48817187-24.77278125-62.57967188-25.90284375-87.35343749-2.41467186-24.77376563 23.48915625-25.90284375 62.57967188-2.41467187 87.35343749l430.00945312 454.62178125c18.81829688 19.94835937 47.77565625 23.65059375 71.2648125 12.38639063 1.28460937-0.47545312 2.41467187-1.60748438 3.69928125-2.24732814v0zM548.03368229 792.19053125z" fill="#2c2c2c" p-id="2522"></path></svg>
					</button>
				<button>搜索所有字段 
					<svg t="1610538962139" className="icon qr6_img" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2521" width="15" height="15"><path d="M548.03368229 792.19053125v0c4.1855625-2.41467187 8.68514063-4.18556248 12.38442186-7.8868125 0.64870313-0.8071875 1.12415625-1.44703125 1.77089063-2.09278125 0.8071875-0.96271875 2.09278125-1.28657812 2.89209376-2.09475l434.196-450.43720312c11.57920312-11.90404688 17.37225-27.349875 17.37225-42.79373438 0-16.08960937-6.43485938-32.33770312-18.98071875-44.40220313-24.61232813-23.64960937-63.70973438-23.00385937-87.35343751 1.60846875l-391.56468749 406.35984375-385.92520314-407.9683125c-23.48817187-24.77278125-62.57967188-25.90284375-87.35343749-2.41467186-24.77376563 23.48915625-25.90284375 62.57967188-2.41467187 87.35343749l430.00945312 454.62178125c18.81829688 19.94835937 47.77565625 23.65059375 71.2648125 12.38639063 1.28460937-0.47545312 2.41467187-1.60748438 3.69928125-2.24732814v0zM548.03368229 792.19053125z" fill="#2c2c2c" p-id="2522"></path></svg>&nbsp;&nbsp;
				</button>
				<div className="qr6_inputII"><IconFont type="icon-sousuo" /><input type="text" placeholder="搜索 SKU、商品名称、ISB等"/></div> 
				<button>搜索</button>
			</div>
			<ul className="qr6_contcent-ul">
				<li>所有商品信息</li>
				<li>在其他定价规则中</li>
				<li>在此定价规则中</li>
				<li>不在任何定价规则中</li>
				<li>达到最低定价</li>
				<li>达到最高定价</li>
				<li>已暂停</li>
			</ul>
			<div className="qr6_contcent-button">
				<button>批量操作&nbsp;&nbsp;
					<svg t="1610538962139" className="icon qr6_img " viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2521" width="15" height="15"><path d="M548.03368229 792.19053125v0c4.1855625-2.41467187 8.68514063-4.18556248 12.38442186-7.8868125 0.64870313-0.8071875 1.12415625-1.44703125 1.77089063-2.09278125 0.8071875-0.96271875 2.09278125-1.28657812 2.89209376-2.09475l434.196-450.43720312c11.57920312-11.90404688 17.37225-27.349875 17.37225-42.79373438 0-16.08960937-6.43485938-32.33770312-18.98071875-44.40220313-24.61232813-23.64960937-63.70973438-23.00385937-87.35343751 1.60846875l-391.56468749 406.35984375-385.92520314-407.9683125c-23.48817187-24.77278125-62.57967188-25.90284375-87.35343749-2.41467186-24.77376563 23.48915625-25.90284375 62.57967188-2.41467187 87.35343749l430.00945312 454.62178125c18.81829688 19.94835937 47.77565625 23.65059375 71.2648125 12.38639063 1.28460937-0.47545312 2.41467187-1.60748438 3.69928125-2.24732814v0zM548.03368229 792.19053125z" fill="#2c2c2c" p-id="2522"></path></svg>&nbsp;&nbsp;
					
				</button>|
				<button>订单配送方式&nbsp;&nbsp;
					<svg t="1610538962139" className="icon qr6_img" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2521" width="15" height="15"><path d="M548.03368229 792.19053125v0c4.1855625-2.41467187 8.68514063-4.18556248 12.38442186-7.8868125 0.64870313-0.8071875 1.12415625-1.44703125 1.77089063-2.09278125 0.8071875-0.96271875 2.09278125-1.28657812 2.89209376-2.09475l434.196-450.43720312c11.57920312-11.90404688 17.37225-27.349875 17.37225-42.79373438 0-16.08960937-6.43485938-32.33770312-18.98071875-44.40220313-24.61232813-23.64960937-63.70973438-23.00385937-87.35343751 1.60846875l-391.56468749 406.35984375-385.92520314-407.9683125c-23.48817187-24.77278125-62.57967188-25.90284375-87.35343749-2.41467186-24.77376563 23.48915625-25.90284375 62.57967188-2.41467187 87.35343749l430.00945312 454.62178125c18.81829688 19.94835937 47.77565625 23.65059375 71.2648125 12.38639063 1.28460937-0.47545312 2.41467187-1.60748438 3.69928125-2.24732814v0zM548.03368229 792.19053125z" fill="#2c2c2c" p-id="2522"></path></svg>&nbsp;&nbsp;
					
				</button>|
				<button>其它筛选条件&nbsp;&nbsp;
					<svg t="1610538962139" className="icon qr6_img" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2521" width="15" height="15"><path d="M548.03368229 792.19053125v0c4.1855625-2.41467187 8.68514063-4.18556248 12.38442186-7.8868125 0.64870313-0.8071875 1.12415625-1.44703125 1.77089063-2.09278125 0.8071875-0.96271875 2.09278125-1.28657812 2.89209376-2.09475l434.196-450.43720312c11.57920312-11.90404688 17.37225-27.349875 17.37225-42.79373438 0-16.08960937-6.43485938-32.33770312-18.98071875-44.40220313-24.61232813-23.64960937-63.70973438-23.00385937-87.35343751 1.60846875l-391.56468749 406.35984375-385.92520314-407.9683125c-23.48817187-24.77278125-62.57967188-25.90284375-87.35343749-2.41467186-24.77376563 23.48915625-25.90284375 62.57967188-2.41467187 87.35343749l430.00945312 454.62178125c18.81829688 19.94835937 47.77565625 23.65059375 71.2648125 12.38639063 1.28460937-0.47545312 2.41467187-1.60748438 3.69928125-2.24732814v0zM548.03368229 792.19053125z" fill="#2c2c2c" p-id="2522"></path></svg>&nbsp;&nbsp;
				
				</button>
				<button>通过上传文件来管理SKU</button>
			</div>
			<div>SKU 按商品最近的创建日期排序。</div>
			<div className="qr6_contcent-cke">
				<div></div>
				<div>图片</div>
				<div>SKU
					<p>状况</p>
					<p>配送渠道</p>
				</div>
				<div>商品名称
					<p>ASIN</p>
				</div>
				<div>创建日期
					<p>状态更改日期</p>
				</div>
				<div>您的商品价格
					<p>(商品价格 + 运费)</p>
				</div>
				<div>您的最低商品价格</div>
				<div>您的最高商品价格</div>
				<div>规则名</div>
				<div>操作</div>
			</div>
			<div className="qr6_contcentxc">
				<div>
					<p>i</p>
					<p>此分类中无商品</p>
				</div>
			</div>
			<div>
				<button>←上一页</button>
				<button>下一页→</button>
				<button>每页10个结果&nbsp;&nbsp; 					
					<svg t="1610538962139" className="icon qr6_img" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2521" width="15" height="15"><path d="M548.03368229 792.19053125v0c4.1855625-2.41467187 8.68514063-4.18556248 12.38442186-7.8868125 0.64870313-0.8071875 1.12415625-1.44703125 1.77089063-2.09278125 0.8071875-0.96271875 2.09278125-1.28657812 2.89209376-2.09475l434.196-450.43720312c11.57920312-11.90404688 17.37225-27.349875 17.37225-42.79373438 0-16.08960937-6.43485938-32.33770312-18.98071875-44.40220313-24.61232813-23.64960937-63.70973438-23.00385937-87.35343751 1.60846875l-391.56468749 406.35984375-385.92520314-407.9683125c-23.48817187-24.77278125-62.57967188-25.90284375-87.35343749-2.41467186-24.77376563 23.48915625-25.90284375 62.57967188-2.41467187 87.35343749l430.00945312 454.62178125c18.81829688 19.94835937 47.77565625 23.65059375 71.2648125 12.38639063 1.28460937-0.47545312 2.41467187-1.60748438 3.69928125-2.24732814v0zM548.03368229 792.19053125z" fill="#2c2c2c" p-id="2522"></path></svg>&nbsp;&nbsp;
				</button>
			</div>
		</div>









            </div>
            <Footer/>
        </div>
    )
}


export default ManageSKUs